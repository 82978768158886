import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#unless this.isAlertHidden}}\n  <div class=\"px-16 pt-16\">\n    <AlertMessage @type=\"info\" @icon=\"party-horn\" @onClose={{this.hideAlert}}>\n      <:content>\n        <p>\n          <strong>{{t\n              \"candidates.candidate.candidate_modal.welcome_to_the_new_candidate_card\"\n            }}</strong>\n          {{t\n            \"candidates.candidate.candidate_modal.visit_our_support_article\"\n            href=\"https://support.teamtailor.com/en/articles/9153972-let-us-introduce-the-new-and-improved-candidate-card\"\n            htmlSafe=true\n          }}\n\n        </p>\n      </:content>\n    </AlertMessage>\n  </div>\n{{/unless}}", {"contents":"{{#unless this.isAlertHidden}}\n  <div class=\"px-16 pt-16\">\n    <AlertMessage @type=\"info\" @icon=\"party-horn\" @onClose={{this.hideAlert}}>\n      <:content>\n        <p>\n          <strong>{{t\n              \"candidates.candidate.candidate_modal.welcome_to_the_new_candidate_card\"\n            }}</strong>\n          {{t\n            \"candidates.candidate.candidate_modal.visit_our_support_article\"\n            href=\"https://support.teamtailor.com/en/articles/9153972-let-us-introduce-the-new-and-improved-candidate-card\"\n            htmlSafe=true\n          }}\n\n        </p>\n      </:content>\n    </AlertMessage>\n  </div>\n{{/unless}}","moduleName":"teamtailor/components/candidate-modal-new/main/alert/info-banner.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-modal-new/main/alert/info-banner.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

const HIDE_INFO_ALERT = 'new-candidate-card-info-alert';

export default class extends Component {
  @tracked isAlertHidden;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.isAlertHidden = localStorage.getItem(`${HIDE_INFO_ALERT}`) === 'true';
  }

  @action
  hideAlert() {
    localStorage.setItem(`${HIDE_INFO_ALERT}`, 'true');
    this.isAlertHidden = true;
  }
}
