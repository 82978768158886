import Model, { attr, hasMany, SyncHasMany } from '@ember-data/model';
import FormAnswer from './form-answer';
import FormQuestion from './form-question';

export default class ReferenceFormModel extends Model {
  @hasMany('form-question', { async: false })
  declare formQuestions: SyncHasMany<FormQuestion>;

  @hasMany('form-answer', { async: false })
  declare formAnswers: SyncHasMany<FormAnswer>;

  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'reference-form': ReferenceFormModel;
  }
}
