import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateContentPresence() {
  return (key, newValue) => {
    if (
      !newValue ||
      newValue.trim().length === 0 ||
      newValue === '<p><br></p>'
    ) {
      return buildMessage(key, { type: 'present' });
    } else {
      return true;
    }
  };
}
