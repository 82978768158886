import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import AuditLogModel from 'teamtailor/models/audit-log';

export default class IndexController extends Controller {
  @service declare router: RouterService;

  get auditLog() {
    return this.model;
  }

  @action
  handleCreate(): Promise<AuditLogModel> {
    const promise = this.auditLog.save();
    promise.then(() => {
      this.router.transitionTo('settings.audit-log.events');
    });
    return promise;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.audit-log.index': IndexController;
  }
}
