import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import { ModelSchema } from 'ember-data';

export type SubTypeClassNames =
  | 'ContentBlock::Custom'
  | 'ContentBlock::Faq'
  | 'ContentBlock::Grid'
  | 'ContentBlock::Testimonial'
  | 'ContentBlock::Infographic'
  | 'ContentBlock::Timeline'
  | 'ContentBlock::List'
  | 'ContentBlock::Picture'
  | 'ContentBlock::Text'
  | 'ContentBlock::Lead';

export const classToSubType = (type?: SubTypeClassNames) => {
  switch (type) {
    case 'ContentBlock::Custom':
      return 'content-block-custom';
    case 'ContentBlock::Faq':
      return 'content-block-faq';
    case 'ContentBlock::Grid':
      return 'content-block-grid';
    case 'ContentBlock::Testimonial':
      return 'content-block-testimonial';
    case 'ContentBlock::Infographic':
      return 'content-block-infographic';
    case 'ContentBlock::Timeline':
      return 'content-block-timeline';
    case 'ContentBlock::List':
      return 'content-block-list';
    case 'ContentBlock::Picture':
      return 'content-block-picture';
    case 'ContentBlock::Text':
      return 'content-block-text';
    case 'ContentBlock::Lead':
      return 'content-block-lead';
    default:
      return 'section';
  }
};

export default class SectionSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  payloadKeyFromModelName() {
    return 'content_block_section';
  }

  attrs = {
    pickedImages: {
      serialize: 'records',
      deserialize: 'ids',
      key: 'picked_images_attributes',
    },

    pickedVideos: {
      serialize: 'records',
      deserialize: 'ids',
      key: 'picked_videos_attributes',
    },

    company: { serialize: false },
    shadow: { serialize: false },
  };

  normalize(
    modelClass: ModelSchema,
    resourceHash: { type: SubTypeClassNames },
    prop: string
  ) {
    const sectionModelName = classToSubType(resourceHash.type);
    if (sectionModelName !== 'section') {
      const sectionModelSerializer = this.store.serializerFor(sectionModelName);
      const sectionModelClass = this.store.modelFor(sectionModelName);
      return sectionModelSerializer.normalize(
        // @ts-expect-error Either type if wrong or we are sending in the wrong thing, not sure which
        sectionModelClass,
        resourceHash,
        {}
      );
    } else {
      return super.normalize(modelClass, resourceHash, prop);
    }
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    section: SectionSerializer;
  }
}
