import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<StatBar @stats={{this.stats}} @isLoading={{@isLoading}} />", {"contents":"<StatBar @stats={{this.stats}} @isLoading={{@isLoading}} />","moduleName":"teamtailor/components/unicorn/components/stat-bar.hbs","parseOptions":{"srcName":"teamtailor/components/unicorn/components/stat-bar.hbs"}});
import Component from '@glimmer/component';

export interface StatBar {
  Args: {
    amount: number;
  };
}

export default class UnicornStatBar extends Component<StatBar> {
  get stats() {
    return [
      {
        title: 'Active jobs for candidates',
        value: 10,
        icon: 'briefcase-blank',
        infoTitle: 'Active jobs for candidates',
        infoText:
          'Active jobs for candidates refer to job openings that are currently available and actively seeking applicants. These positions are actively being advertised and are ready for candidates to apply.',
      },
      ...(this.args.amount > 1
        ? [
            {
              title: 'Candidates hired',
              value: '12%',
              icon: 'briefcase-blank',
              infoTitle: 'Percentage of candidates hired',
              infoText:
                'Candidates hired refer to the percentage of candidates who have been successfully hired for a job opening. This percentage is calculated by dividing the number of candidates hired by the total number of candidates who applied for the position. A higher percentage indicates a more successful hiring process.',
            },
          ]
        : []),
      ...(this.args.amount > 2
        ? [
            {
              title: 'Candidates',
              value: 309,
              icon: 'user',
              infoTitle: 'Total amount of candidates',
              infoText:
                'Candidates refer to individuals who have applied for a job opening or have been identified as potential recruits for a position within an organization. These individuals may be actively seeking employment or may be passively open to new opportunities.',
            },
          ]
        : []),
      ...(this.args.amount > 3
        ? [
            {
              title: 'Job applications rejected',
              value: '88%',
              icon: 'ban',
              infoTitle: 'Total amount of job applications rejected',
              infoText:
                'Job applications rejected refer to the percentage of job applications that have been declined or turned down by an employer. This percentage is calculated by dividing the number of applications rejected by the total number of applications received. A higher percentage indicates a more competitive job market.',
            },
          ]
        : []),
      ...(this.args.amount > 4
        ? [
            {
              title: 'Sourced candidates',
              value: 89,
              icon: 'user',
              infoTitle: 'Total amount of sourced candidates',
              infoText:
                'Sourced candidates refer to individuals who have been identified and contacted by a recruiter or hiring manager for a job opening. These candidates may not have applied for the position directly but have been sourced through various channels such as social media, job boards, or networking events.',
            },
          ]
        : []),
    ];
  }
}
