import Model, { attr, AsyncBelongsTo, belongsTo } from '@ember-data/model';

import { CandidateModel, SurveyModel } from 'teamtailor/models';

export enum States {
  CREATED = 'created',
  SENT = 'sent',
  VIEWED = 'viewed',
  ANSWERED = 'answered',
}

export default class FormInviteModel extends Model {
  @belongsTo('candidate')
  declare candidate: AsyncBelongsTo<CandidateModel>;

  @belongsTo('survey')
  declare survey: AsyncBelongsTo<SurveyModel>;

  @attr('string')
  declare state: States;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'form-invite': FormInviteModel;
  }
}
