import PermissionsRoute from 'teamtailor/routes/permissions';

export default class EditRoute extends PermissionsRoute {
  requiredPermissions = 'nurture_campaign/update';
  noAccessEntityNameKey = 'nurture_campaigns.nurture_campaign.this_campaign';

  model({ nurture_campaign_id }) {
    return this.store.findRecord('nurture-campaign', nurture_campaign_id);
  }
}
