import Helper from '@ember/component/helper';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';

export default class FancyFilterDatePresenter extends Helper {
  @service intl: any;

  compute([startDate, endDate]: Date[]): string {
    const yesterday = moment().subtract(1, 'day');
    const numberOfDayssSinceYesterday = yesterday.diff(startDate, 'days');
    let numberOfDaysBetweenTheDates = moment(endDate).diff(startDate, 'days');
    numberOfDaysBetweenTheDates =
      numberOfDaysBetweenTheDates === numberOfDayssSinceYesterday
        ? numberOfDaysBetweenTheDates
        : -1;

    switch (numberOfDaysBetweenTheDates) {
      case 27:
        return this.intl.t('helpers.fancy_filter_date_presenter.last_28_days');

      case 6:
        return this.intl.t('helpers.fancy_filter_date_presenter.last_7_days');

      default:
        return [startDate, endDate]
          .map((date) => moment(date))
          .map((date) => date.format('YYYY-MM-DD'))
          .join(' - ');
    }
  }
}
