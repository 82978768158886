import Store from '@ember-data/store';
import { get } from 'teamtailor/utils/get';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import EditController from 'teamtailor/controllers/settings/security/sso/show/mappings/edit';

export default class SettingsSecuritySsoShowMappingsEdit extends ScrollToTopRoute {
  @service declare store: Store;

  model({ mapping_id }: { mapping_id: string }) {
    return this.store.findRecord('sso-user-attribute-mapping', mapping_id);
  }

  resetController(controller: EditController, isExiting: boolean) {
    if (isExiting && get(controller.model, 'hasDirtyAttributes')) {
      controller.model.rollbackAttributes();
    }
  }
}
