import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class EditRoute extends ScrollToTopRoute {
  @service store;

  async model(params) {
    const team = await this.store.findRecord('team', params.team_id);
    const roles = await get(team, 'roles');

    await Promise.all(
      roles.map((role) => {
        return get(role, 'department');
      })
    );

    return team;
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    const roles = get(model, 'roles').map((role) => {
      return this.store.createRecord('department-or-role', {
        team: model,
        role,
        department: get(role, 'department'),
      });
    });

    const departments = get(model, 'departments').map((department) => {
      return this.store.createRecord('department-or-role', {
        team: model,
        department,
      });
    });

    const departmentsOrRoles = [...roles, ...departments];

    controller.teamUsers = get(model, 'users').toArray();
    controller.departmentsOrRoles = departmentsOrRoles;
  }
}
