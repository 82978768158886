import Controller from '@ember/controller';

export default class LayoutController extends Controller {
  get section() {
    return this.model;
  }

  get componentName() {
    return `section-${this.section.name}-layout`;
  }

  deactivate() {
    this.controller.model.rollbackAttributes();
  }
}
