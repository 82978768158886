import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class={{this.classes}}>\n  {{#each (range 1 @steps true) as |step|}}\n    <StepIndicatorStep\n      @steps={{@steps}}\n      @step={{step}}\n      @currentStep={{@currentStep}}\n    />\n  {{/each}}\n</div>\n{{#if @title}}\n  <p class=\"step-indicator__title\">\n    {{@title}}\n  </p>\n{{/if}}", {"contents":"<div class={{this.classes}}>\n  {{#each (range 1 @steps true) as |step|}}\n    <StepIndicatorStep\n      @steps={{@steps}}\n      @step={{step}}\n      @currentStep={{@currentStep}}\n    />\n  {{/each}}\n</div>\n{{#if @title}}\n  <p class=\"step-indicator__title\">\n    {{@title}}\n  </p>\n{{/if}}","moduleName":"teamtailor/components/step-indicator.hbs","parseOptions":{"srcName":"teamtailor/components/step-indicator.hbs"}});
import Component from '@glimmer/component';
import classnames from 'teamtailor/utils/classnames';

export default class StepIndicatorComponent extends Component {
  get classes() {
    return classnames(
      'step-indicator',
      `step-indicator--${this.args.currentStep}`
    );
  }
}
