import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AuthPopup\n  @provider={{this.partnerName}}\n  @handleSuccess={{this.onOauthSuccess}}\n  @handleFailure={{this.onOauthFailure}}\n  @params={{this.key}}\n  as |authPopup|\n>\n  <Button\n    @onClick={{queue\n      (fn authPopup.openAsync \"args.params\")\n      (fn this.handleOpenOauth authPopup.open)\n    }}\n    @appearance=\"primary\"\n    @text={{t \"settings.integrations.partner_activations.activate\"}}\n    @isDisabled={{or this.inFlight this.disabled @isDisabled}}\n  />\n</AuthPopup>", {"contents":"<AuthPopup\n  @provider={{this.partnerName}}\n  @handleSuccess={{this.onOauthSuccess}}\n  @handleFailure={{this.onOauthFailure}}\n  @params={{this.key}}\n  as |authPopup|\n>\n  <Button\n    @onClick={{queue\n      (fn authPopup.openAsync \"args.params\")\n      (fn this.handleOpenOauth authPopup.open)\n    }}\n    @appearance=\"primary\"\n    @text={{t \"settings.integrations.partner_activations.activate\"}}\n    @isDisabled={{or this.inFlight this.disabled @isDisabled}}\n  />\n</AuthPopup>","moduleName":"teamtailor/components/partner/auth-popup-config.hbs","parseOptions":{"srcName":"teamtailor/components/partner/auth-popup-config.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import { action, set } from '@ember/object';
import PartnerActivationModel from 'teamtailor/models/partner-activation';
import TtAlertService from 'teamtailor/services/tt-alert';
import IntlService from 'ember-intl/services/intl';
import RouterService from '@ember/routing/router-service';
import FlashMessageService from 'teamtailor/services/flash-message';
import { task } from 'ember-concurrency';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';

interface PartnerAuthPopupConfigInterface {
  partnerActivation: PartnerActivationModel;
  providerKeyData: Record<string, unknown>;
}

export default class PartnerAuthPopupConfigComponent extends Component<PartnerAuthPopupConfigInterface> {
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;

  @tracked key = '';

  get partner() {
    return this.args.partnerActivation.partner;
  }

  handleOauthActivation = task(async (callback) => {
    await callback();
  });

  get partnerName() {
    return this.partner.internalName;
  }

  @action async handleOpenOauth() {
    this.args.partnerActivation.providerKey = this.args.providerKeyData;
    const partnerActivation = await this.args.partnerActivation.save();
    this.key = `partnerActivationId=${partnerActivation.id}`;
  }

  @action async onOauthSuccess({
    authorization: data,
  }: {
    authorization: {
      type: 'partner-activation';
      id: string;
    };
  }) {
    try {
      const authorization = this.store.createRecord('authorization', data);

      await authorization.save();

      set(this.args.partnerActivation, 'authorization', authorization);

      await this.args.partnerActivation.save();

      this.flashMessages.success(
        this.intl.t('marketplace.finish_activation', {
          subject: this.partner.name,
        })
      );
      return this.router.transitionTo(
        'settings.integrations.marketplace-activations'
      );
    } catch (err) {
      return this.flashMessages.error(
        this.intl.t('errors.something_went_wrong')
      );
    }
  }

  @action onOauthFailure() {
    this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
  }

  @action
  delete() {
    this.ttAlert.customConfirm({
      title: this.intl.t('common.are_you_sure'),
      text: this.intl.t(
        'settings.integrations.partner_activations.deactivate_alert.text'
      ),

      confirmButtonText: this.intl.t(
        'settings.integrations.partner_activations.deactivate_alert.confirm'
      ),

      cancelButtonText: this.intl.t('common.cancel'),
      confirmCallback: async () => {
        await this.args.partnerActivation.destroyRecord();
        this.flashMessages.success(
          this.intl.t(
            'settings.integrations.partner_activations.deactivate_alert.partner_deleted'
          )
        );
        this.router.transitionTo('settings.general');
      },
    });
  }
}
