import Controller from '@ember/controller';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default class extends Controller {
  @service router;

  queryParams = ['type'];

  get cookieDetail() {
    return this.model;
  }

  type = null;

  @action
  close() {
    this.router.transitionTo('settings.cookies');
  }

  @action
  async save(model) {
    await model.save();
    if (get(model, 'isValid')) {
      this.router.transitionTo('settings.cookies');
    }
  }
}
