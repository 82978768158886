import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { PromotionModel } from 'teamtailor/models';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';

export default class JobsJobPromotePromotionActivateRoute extends Route {
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  queryParams = {
    action: { refreshModel: false },
  };

  model() {
    return this.modelFor('jobs.job.promote.promotion');
  }

  async afterModel(promotion: PromotionModel) {
    try {
      const { action } = this.paramsFor(this.routeName) as { action?: string };

      const message =
        action === 'edit'
          ? 'jobs.job.promote.external_promotion_update'
          : 'jobs.job.promote.external_promotion_create';

      promotion.externalUrl = null;
      await promotion.save();

      this.flashMessages.success(this.intl.t(message));
    } catch (error) {
      this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
    } finally {
      this.router.replaceWith('jobs.job.promote', get(promotion, 'job'));
    }
  }
}
