import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Promotion::Cards::Internal::Wrapper\n  @channel={{@channel}}\n  @job={{@job}}\n  @icon=\"bullseye-arrow\"\n  @title={{t \"components.promotion.cards.lead_page.title\"}}\n  @description={{t \"components.promotion.cards.lead_page.description\"}}\n  @onClick={{this.handleClick}}\n  @iconClass=\"text-icon-decorative-light-blue-weak\"\n/>", {"contents":"<Promotion::Cards::Internal::Wrapper\n  @channel={{@channel}}\n  @job={{@job}}\n  @icon=\"bullseye-arrow\"\n  @title={{t \"components.promotion.cards.lead_page.title\"}}\n  @description={{t \"components.promotion.cards.lead_page.description\"}}\n  @onClick={{this.handleClick}}\n  @iconClass=\"text-icon-decorative-light-blue-weak\"\n/>","moduleName":"teamtailor/components/promotion/cards/internal/lead-page.hbs","parseOptions":{"srcName":"teamtailor/components/promotion/cards/internal/lead-page.hbs"}});
import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default class PromotionCardsLeadPage extends Component {
  @service router;
  @service current;

  get companyUsingCareerSiteLanguages() {
    return this.current.company.hasFeature('career_site_languages');
  }

  @action
  async handleClick() {
    const queryParams = { pageType: 'lead', jobId: this.args.job.id };

    if (this.companyUsingCareerSiteLanguages) {
      const careerSites = await get(this.current.company, 'careerSites');
      queryParams.site = careerSites.findBy(
        'languageCode',
        this.args.job.languageCode
      )?.id;
    }

    this.router.transitionTo('content.index.new.page', {
      queryParams,
    });
  }
}
