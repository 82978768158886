import Model, { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { BufferedChangeset } from 'ember-changeset/types';
import { UserModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';

export default abstract class NurtureCampaignStepModel extends Model {
  @tracked currentChangeset: BufferedChangeset | null = null;

  @belongsTo('user', {
    inverse: 'nurtureCampaignSteps',
  })
  declare sender: AsyncBelongsTo<UserModel>;

  @attr('number') declare rowOrder: number;
  @attr('string') declare rowOrderPosition: string;

  get currentChangesetIsDirty() {
    return get(this.currentChangeset, 'isDirty');
  }

  get isNewOrHasDirtyChangeset() {
    return get(this, 'isNew') || this.currentChangesetIsDirty;
  }

  get stepType() {
    return get(this.constructor, 'modelName').split('/').pop();
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'nurture-campaign-step': NurtureCampaignStepModel;
  }
}
