import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { relationsRecordRemover } from 'teamtailor/utils/record-remover';
import { inject as service } from '@ember/service';

export default class TemplateFormBaseController extends Controller {
  @service store;

  @tracked fields = [];

  @action
  onUpdateFields(fields) {
    this.fields = fields.map((field, index) => ({
      ...field,
      sortIndex: index,
    }));
  }

  @action
  async onSave() {
    this.model.formQuestionsRaw = this.fields;
    await this.model.save();
    this.fields = [];
    relationsRecordRemover(this.model, 'uploads');
  }
}
