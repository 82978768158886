const fontOptions = {
  fontSize: '12px',
  color: '#57595f',
  fontFamily: 'Inter, sans-serif',
};

const boldFontOptions = {
  ...fontOptions,
  fontWeight: 'bold',
};

const plotLineFontOptions = {
  ...fontOptions,
  fontSize: '10px',
};

export default fontOptions;

export { plotLineFontOptions, boldFontOptions, fontOptions };
