import Store from '@ember-data/store';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import NewController from 'teamtailor/controllers/settings/security/sso/show/mappings/new';
import { get } from 'teamtailor/utils/get';

export default class SettingsSecuritySsoShowMappingsNew extends ScrollToTopRoute {
  @service declare store: Store;

  model() {
    return this.store.createRecord('sso-user-attribute-mapping');
  }

  resetController(controller: NewController, isExiting: boolean) {
    if (isExiting && get(controller.model, 'isNew')) {
      this.store.unloadRecord(controller.model);
    }
  }
}
