import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Select\n  @foo={{true}}\n  @icon={{@icon}}\n  @onSearch={{set this \"searchTerm\"}}\n  @onClose={{fn (mut this.pageSearchTerm) \"\"}}\n  @options={{this.filteredPagePublications}}\n  @onSelect={{@onSelect}}\n  @selected={{@selected}}\n  @isLoading={{this.handleMouseenter.isRunning}}\n  @optionName=\"navigationTitle\"\n  @placeholder={{t \"components.content_block.page_picker.placeholder\"}}\n  {{on \"mouseenter\" (perform this.handleMouseenter)}}\n  ...attributes\n/>", {"contents":"<Core::Select\n  @foo={{true}}\n  @icon={{@icon}}\n  @onSearch={{set this \"searchTerm\"}}\n  @onClose={{fn (mut this.pageSearchTerm) \"\"}}\n  @options={{this.filteredPagePublications}}\n  @onSelect={{@onSelect}}\n  @selected={{@selected}}\n  @isLoading={{this.handleMouseenter.isRunning}}\n  @optionName=\"navigationTitle\"\n  @placeholder={{t \"components.content_block.page_picker.placeholder\"}}\n  {{on \"mouseenter\" (perform this.handleMouseenter)}}\n  ...attributes\n/>","moduleName":"teamtailor/components/content-block/page-publication-picker.hbs","parseOptions":{"srcName":"teamtailor/components/content-block/page-publication-picker.hbs"}});
import Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import ContentCareerSiteService from 'teamtailor/services/content-career-site';
import { PagePublicationModel } from 'teamtailor/models';
import fuzzysort from 'fuzzysort';
import { task } from 'ember-concurrency';

type PagePublicationPickerComponentArgs = {
  onSelect: (pagePublication: PagePublicationModel) => void;
  selected: PagePublicationModel | null;
  icon?: string;
};

export default class PagePublicationPickerComponent extends Component<PagePublicationPickerComponentArgs> {
  @service declare contentCareerSite: ContentCareerSiteService;
  @service declare store: Store;
  @tracked searchTerm = '';
  @tracked allPagePublications? = [] as PagePublicationModel[];

  get filteredPagePublications() {
    if (!this.searchTerm) {
      return this.allPagePublications?.sortBy('navigationTitle');
    } else {
      return fuzzysort
        .go(this.searchTerm, this.allPagePublications!, {
          key: 'navigationTitle',
        })
        .map((val) => val.obj)
        .sortBy('navigationTitle');
    }
  }

  handleMouseenter = task(async () => {
    if (this.handleMouseenter.lastSuccessful) {
      return;
    }

    const careerSite = await this.contentCareerSite.current;

    this.allPagePublications = await this.store.query('page-publication', {
      not_template: true,
      published: true,
      career_site_id: careerSite?.id,
    });
  });
}
