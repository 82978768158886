import Controller from '@ember/controller';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SettingsExternalRecruitmentRecruitersNewController extends Controller {
  @service flashMessages;
  @service intl;
  @service router;

  @action
  handleModalClose() {
    this.router.transitionTo('settings.external-recruitment.recruiters');
  }

  @action
  async handleSaveExternalRecruiter(model, firmModel) {
    if (get(model, 'recruitingFirm.isNew')) {
      await firmModel.validate();
      if (get(model, 'isValid')) {
        await firmModel.save();
      } else {
        throw new Error('Validation failed');
      }
    }

    await model.validate();
    if (get(model, 'isValid')) {
      await model.save();

      this.router.transitionTo('settings.external-recruitment.recruiters');
      this.flashMessages.success(
        this.intl.t('settings.external_recruiters.new_recruiter_added')
      );
    } else {
      throw new Error('Validation failed');
    }
  }
}
