import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';

export default class CandidatesCandidateRoute extends Route {
  @service declare store: Store;

  model(params: { candidate_id: string }) {
    return this.store.findRecord('candidate', params.candidate_id);
  }
}
