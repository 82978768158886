import Route from '@ember/routing/route';
import { get, set, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ItemRoute extends Route {
  @service store;

  model() {
    return this.modelFor('content.editor.section.testimonial');
  }

  afterModel() {
    setProperties(this, {
      controllerName: 'content.editor.section.testimonial.item',
      templateName: 'content.editor.section.testimonial.item',
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    const itemId = this.paramsFor(get(this, 'routeName')).item_id;
    let item;
    if (get(model, 'itemsArray.length')) {
      item = get(model, 'itemsArray').findBy('id', itemId);
      if (get(item, 'imageId')) {
        get(this, 'store')
          .findRecord('picked-image', get(item, 'imageId'))
          .then(
            (pickedImage) => {
              set(item, 'pickedImage', pickedImage);
              set(controller, 'item', item);
            },
            () => {
              set(controller, 'item', item);
            }
          );
      } else if (get(item, 'videoId')) {
        get(this, 'store')
          .findRecord('picked-video', get(item, 'videoId'))
          .then(
            (pickedVideo) => {
              set(item, 'pickedVideo', pickedVideo);
              set(controller, 'item', item);
            },
            () => {
              set(controller, 'item', item);
            }
          );
      } else {
        set(controller, 'item', item);
      }
    }
  }
}
