import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"body-text-s flex flex-wrap gap-4\">\n  {{#if this.locations.length}}\n    {{#each this.locations as |location|}}\n      <Core::Badge\n        @text={{location.nameOrCity}}\n        @size=\"small\"\n        class=\"mix-blend-multiply dark:mix-blend-normal\"\n        as |badge|\n      >\n        {{#if location.headquarters}}\n          <badge.icon @icon=\"crown\" />\n          <EmberTooltip @text={{t \"jobs.index.headquarters\"}} @side=\"top\" />\n        {{/if}}\n      </Core::Badge>\n    {{/each}}\n    {{#if this.otherLocations}}\n      <Core::Badge\n        @text={{concat \"+\" this.otherLocations.length}}\n        @size=\"small\"\n        @tooltip={{join \", \" (map-by \"nameOrCity\" this.otherLocations)}}\n        class=\"mix-blend-multiply dark:mix-blend-normal\"\n      />\n    {{/if}}\n  {{/if}}\n</div>", {"contents":"<div class=\"body-text-s flex flex-wrap gap-4\">\n  {{#if this.locations.length}}\n    {{#each this.locations as |location|}}\n      <Core::Badge\n        @text={{location.nameOrCity}}\n        @size=\"small\"\n        class=\"mix-blend-multiply dark:mix-blend-normal\"\n        as |badge|\n      >\n        {{#if location.headquarters}}\n          <badge.icon @icon=\"crown\" />\n          <EmberTooltip @text={{t \"jobs.index.headquarters\"}} @side=\"top\" />\n        {{/if}}\n      </Core::Badge>\n    {{/each}}\n    {{#if this.otherLocations}}\n      <Core::Badge\n        @text={{concat \"+\" this.otherLocations.length}}\n        @size=\"small\"\n        @tooltip={{join \", \" (map-by \"nameOrCity\" this.otherLocations)}}\n        class=\"mix-blend-multiply dark:mix-blend-normal\"\n      />\n    {{/if}}\n  {{/if}}\n</div>","moduleName":"teamtailor/components/jobs/locations.hbs","parseOptions":{"srcName":"teamtailor/components/jobs/locations.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import LocationModel from 'teamtailor/models/location';
import CurrentService from 'teamtailor/services/current';

type JobsLocationsArgs = {
  locations: LocationModel[];
  maxLocationsToShow: number;
};

export default class JobsLocationsComponent extends Component<JobsLocationsArgs> {
  @service declare current: CurrentService;

  get locations(): LocationModel[] | undefined {
    const { locations, maxLocationsToShow } = this.args;
    if (!locations.length) {
      return undefined;
    }

    const shownLocationsAmount =
      locations.length > maxLocationsToShow
        ? maxLocationsToShow
        : locations.length;

    return locations.slice(0, shownLocationsAmount);
  }

  get otherLocations(): LocationModel[] | undefined {
    const { locations, maxLocationsToShow } = this.args;
    if (
      !locations.length ||
      (locations.length && locations.length <= this.args.maxLocationsToShow)
    ) {
      return undefined;
    }

    return locations.slice(maxLocationsToShow, locations.length);
  }
}
