import Model, {
  type AsyncBelongsTo,
  type AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import {
  CandidateModel,
  JobApplicationModel,
  JobOfferApprovalFlowModel,
  JobOfferFormModel,
  PushPayloadArg,
  RequisitionStepModel,
  UserModel,
} from 'teamtailor/models';
import Server from 'teamtailor/services/server';
import { get } from 'teamtailor/utils/get';
import recordRemover from 'teamtailor/utils/record-remover';
import { v4 as uuid } from 'ember-uuid';
import { questionsToFormFields } from 'teamtailor/utils/form-builder';
import IntlService from 'ember-intl/services/intl';

export default class JobOfferModel extends Model {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare server: Server;

  @belongsTo('user') declare answeredByUser: AsyncBelongsTo<UserModel>;
  @belongsTo('candidate') declare candidate: AsyncBelongsTo<CandidateModel>;
  @belongsTo('job-application')
  declare jobApplication: AsyncBelongsTo<JobApplicationModel>;

  @belongsTo('job-offer-approval-flow')
  declare jobOfferApprovalFlow: AsyncBelongsTo<JobOfferApprovalFlowModel>;

  @belongsTo('user') declare recruiter: AsyncBelongsTo<UserModel>;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;

  @belongsTo('job-offer-form', { async: false })
  declare jobOfferForm: JobOfferFormModel;

  @hasMany('user') declare users: AsyncHasMany<UserModel>;

  @hasMany('requisition-step')
  declare approvalSteps: AsyncHasMany<RequisitionStepModel>;

  @attr('string') declare approvalStatus: string;
  @attr('string') declare cloneJobOfferId: string;
  @attr('date') declare createdAt: Date;
  @attr('string') declare previewUrl: string;
  @attr('string') declare response: string;
  @attr('date') declare sentAt: Date;
  @attr('string') declare status: string;

  get formFields() {
    return this.jobOfferForm.sortedFormQuestions.length > 0
      ? questionsToFormFields(this.jobOfferForm.sortedFormQuestions)
      : this.defaultFormFields;
  }

  get defaultFormFields() {
    return [
      {
        uuid: uuid(),
        label: 'acceptance_message',
        type: 'textarea',
        required: true,
        answers: [{ textValue: this.defaultAcceptanceMessage }],
      },
      {
        uuid: uuid(),
        label: 'rejection_message',
        type: 'textarea',
        required: true,
        answers: [{ textValue: this.defaultRejectionMessage }],
      },
      {
        uuid: uuid(),
        label: 'salary',
        type: 'text',
        required: true,
        answers: [{ textValue: '' }],
      },
      {
        uuid: uuid(),
        label: 'start_date',
        type: 'date',
        required: false,
        answers: [{ dateValue: '' }],
        icon: 'calendar',
      },
    ];
  }

  get defaultAcceptanceMessage() {
    return this.intl.t('models.job_offer_form.default_acceptance_message');
  }

  get defaultRejectionMessage() {
    return this.intl.t('models.job_offer_form.default_rejection_message');
  }

  get isSent() {
    return !!this.sentAt;
  }

  get isDraft() {
    return (
      !this.isSent && this.status === 'pending' && !this.isWaitingForApproval
    );
  }

  get isAccepted() {
    return this.status === 'accepted';
  }

  get isDeclined() {
    return this.status === 'declined';
  }

  get isAnswered() {
    return !this.isOpenedOrPending;
  }

  get isOpened() {
    return this.status === 'opened';
  }

  get isOpenedOrPending() {
    return ['opened', 'pending'].includes(this.status);
  }

  get isWaitingForApproval() {
    return this.approvalStatus === 'pending';
  }

  get isFlowRejected() {
    return this.approvalStatus === 'rejected';
  }

  get isFlowApproved() {
    return this.approvalStatus === 'approved';
  }

  get isAnsweredByUser() {
    return isPresent(get(this.answeredByUser, 'id'));
  }

  get isAnsweredByCandidate() {
    return this.isAnswered && !this.isAnsweredByUser;
  }

  get isDeletable() {
    return !(this.isAccepted || this.isDeclined);
  }

  get name() {
    return get(this.jobOfferForm, 'name');
  }

  get currentStep() {
    if (
      !get(this.approvalSteps, 'length') ||
      !get(this.approvalSteps, 'firstObject')
    ) {
      return undefined;
    }

    return this.approvalSteps
      .sortBy('stepIndex')
      .find((step) => get(step, 'isPending'));
  }

  get isApproved() {
    if (get(this, 'status') === 'approved') {
      return true;
    }

    return (
      get(this.approvalSteps, 'length') &&
      this.approvalSteps.every((step) => get(step, 'isApproved'))
    );
  }

  async send() {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'send_offer',
    });
    this.store.pushPayload(response);
  }

  async answer() {
    const { response, status } = this;
    const data = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'answer_offer',
      options: {
        data: JSON.stringify({
          job_offer: {
            response,
            status,
          },
        }),
      },
    });

    this.store.pushPayload(data);
  }

  async sendForApproval() {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'start_approval_flow',
    });
    this.store.pushPayload(response);
  }

  async updateApprovalFlow() {
    const payload = this.serialize();
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'update_approval_flow',
      method: 'PUT',
      urlType: 'updateRecord',
      options: {
        data: JSON.stringify({
          job_offer: payload,
        }),
      },
    });

    this.store.pushPayload(response);
    recordRemover(this.store, 'requisition-step');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'job-offer': JobOfferModel;
  }
}
