import { Parser } from 'json2csv';

export default function jsonToCsvConverter(
  rows: object[],
  orderedKeys?: string[]
) {
  const rowExample: object = (rows.length && rows[0]) || {};
  const keys =
    orderedKeys ||
    Object.keys(rowExample).filter((key) => !key.startsWith('_'));

  return new Parser(keys).parse(rows.map((row) => pick(row, keys)));
}

function pick(object: { [key: string]: any }, keys: string[]) {
  return keys.reduce((acc: { [key: string]: any }, key) => {
    acc[key] = object[key];
    return acc;
  }, {});
}
