import { TableColumn } from 'teamtailor/components/table/container';

export const TABLE_COLUMNS: TableColumn[] = [
  {
    label: 'common.name',
    sortable: true,
    selectable: true,
    sortColumn: 'name',
    defaultSortOrder: 'asc',
    sortOrder: 1,
  },
  {
    label: 'common.type',
    sortable: false,
    sortOrder: 2,
  },
  {
    label: 'settings.custom_fields.times_used',
    sortable: false,
    sortOrder: 3,
  },
  {
    label: 'common.created',
    sortable: true,
    sortColumn: 'created_at',
    defaultSortOrder: 'asc',
    sortOrder: 4,
  },
];

export const TABLE_LOADING_COLUMNS = [
  { width: 'l' },
  { width: 's' },
  { width: 's' },
  { width: 'm' },
];
