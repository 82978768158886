import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import SettingsDepartmentsIndexController from 'teamtailor/controllers/settings/departments';

export default class SettingsDepartmentsRoute extends ScrollToTopRoute {
  queryParams = {
    search: { refreshModel: true },
    page: { refreshModel: true },
    sort_direction: { refreshModel: true },
  };

  resetController(
    controller: SettingsDepartmentsIndexController,
    isExiting: boolean
  ) {
    if (isExiting) {
      controller.page = 1;
    }
  }
}
