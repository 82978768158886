import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ul class=\"mb-4 mt-4\">\n  {{#each this.editableStageTypes as |stageType|}}\n    <Stage::Settings::DropdownItem {{on \"click\" (fn @onSelect stageType)}}>\n      <div class=\"flex w-full items-center\">\n        <Stage::TypeBox @category={{stageType.category}} class=\"mr-8\" />\n        <div class=\"flex-grow\">\n          {{stageType.translatedCategory}}\n        </div>\n        <div class=\"mr-8\">\n          {{#if (eq stageType.id @selectedStageType.id)}}\n            <Icon @icon=\"check\" class=\"text-icon-neutral\" />\n          {{/if}}\n        </div>\n      </div>\n    </Stage::Settings::DropdownItem>\n  {{/each}}\n</ul>", {"contents":"<ul class=\"mb-4 mt-4\">\n  {{#each this.editableStageTypes as |stageType|}}\n    <Stage::Settings::DropdownItem {{on \"click\" (fn @onSelect stageType)}}>\n      <div class=\"flex w-full items-center\">\n        <Stage::TypeBox @category={{stageType.category}} class=\"mr-8\" />\n        <div class=\"flex-grow\">\n          {{stageType.translatedCategory}}\n        </div>\n        <div class=\"mr-8\">\n          {{#if (eq stageType.id @selectedStageType.id)}}\n            <Icon @icon=\"check\" class=\"text-icon-neutral\" />\n          {{/if}}\n        </div>\n      </div>\n    </Stage::Settings::DropdownItem>\n  {{/each}}\n</ul>","moduleName":"teamtailor/components/stage/stage-type-dropdown.hbs","parseOptions":{"srcName":"teamtailor/components/stage/stage-type-dropdown.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class StageTypeDropdown extends Component {
  @service current;

  get editableStageTypes() {
    return get(this.current.company, 'editableStageTypes');
  }
}
