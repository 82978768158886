import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Candidate from 'teamtailor/models/candidate';
import User from 'teamtailor/models/user';
import MeetingRoomModel from 'teamtailor/models/meeting-room';
import MeetingModel from 'teamtailor/models/meeting';

export default class MeetingEventAttendeeModel extends Model {
  @belongsTo('user')
  declare user?: AsyncBelongsTo<User>;

  @belongsTo('candidate')
  declare candidate?: AsyncBelongsTo<Candidate>;

  @belongsTo('meeting-room')
  declare meetingRoom: AsyncBelongsTo<MeetingRoomModel>;

  @belongsTo('meeting')
  declare meeting: AsyncBelongsTo<MeetingModel>;

  @attr('boolean')
  declare hasInterview?: boolean;

  @attr('string')
  declare userId?: string;

  @attr('string')
  declare candidateId?: string;

  @attr('string')
  declare status: string;

  @attr('boolean')
  declare isOrganizer?: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'meeting-event-attendee': MeetingEventAttendeeModel;
  }
}
