import { get } from 'teamtailor/utils/get';
import HireQualitySurveyEditController from 'teamtailor/controllers/candidates/segment/candidate/hire-quality/edit';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';

type Params = {
  hire_quality_response_id: string;
};

export default class HireQualitySurveyEditRoute extends Route {
  @service declare store: Store;

  model(params: Params) {
    const record = this.store.peekRecord(
      'hire-quality-response',
      params.hire_quality_response_id
    );

    if (!record) {
      return this.store.findRecord(
        'hire-quality-response',
        params.hire_quality_response_id
      );
    }

    return record;
  }

  resetController(
    controller: HireQualitySurveyEditController,
    isExiting: boolean
  ) {
    if (
      isExiting &&
      get(controller.hireQualityResponse, 'hasDirtyAttributes')
    ) {
      controller.hireQualityResponse.rollbackAttributes();
    }
  }
}
