import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Molecules::PromotionsList\n  ...attributes\n  @data={{this.fetchData.lastSuccessful.value}}\n  @loading={{or this.fetchData.isRunning (eq 0 this.fetchData.performCount)}}\n  @onShowExportModal={{this.onShowExportModal}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>", {"contents":"<Insights::Molecules::PromotionsList\n  ...attributes\n  @data={{this.fetchData.lastSuccessful.value}}\n  @loading={{or this.fetchData.isRunning (eq 0 this.fetchData.performCount)}}\n  @onShowExportModal={{this.onShowExportModal}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>","moduleName":"teamtailor/components/insights/widgets/job-promotions.hbs","parseOptions":{"srcName":"teamtailor/components/insights/widgets/job-promotions.hbs"}});
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { action } from '@ember/object';
import RSVP from 'rsvp';
import { gql } from '@apollo/client/core';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import AverageRating, {
  fetchMissingAverageRatings,
} from 'teamtailor/utils/average-rating';

const PROMOTIONS_QUERY = gql`
  query JobPromotionsQuery($dateRange: DateRangeAttributes!, $jobIds: [ID!]) {
    applications: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [APPLIED]
      filters: { jobApplicationPromotionId: { exists: true } }
    ) {
      aggregated(groupBy: [PROMOTION_ID, CHANNEL_ID]) {
        promotionId: jobApplicationPromotionId
        channelId: jobApplicationPromotionChannelId
        count
        appliedCandidateIds: collect(field: CANDIDATE_ID)
      }
    }
    pageviewQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      filters: { promotionId: { exists: true } }
    ) {
      aggregated(groupBy: PROMOTION) {
        promotionId
        count
      }
    }
  }
`;

const EXPORT_TABLE_COLUMNS = [
  {
    type: 'text',
    propertyName: 'channelName',
    headerKey: 'insights.molecules.promotions-list.channel_name',
  },
  {
    type: 'number',
    propertyName: 'pageviews',
    headerKey: 'insights.common.pageviews',
  },
  {
    type: 'number',
    propertyName: 'applications',
    headerKey: 'job.applications',
  },
  {
    type: 'average-rating',
    propertyName: 'averageRating',
    headerKey: 'reports.avg_rating',
  },
];

export default class extends Component {
  @service store;
  @service insights;
  @service insightsExport;

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      'insights.common.promotions',
      `job-${this.args.job.id}`,
      EXPORT_TABLE_COLUMNS,
      this.retrieveExportData,
      this.args.dateRange,
      { sortKey: 'pageviews', createdAt: this.args.job.createdAt }
    );
  }

  @action
  async retrieveExportData(dateRange) {
    const rows = await this.queryForData(dateRange);
    await fetchMissingAverageRatings(rows);
    return rows;
  }

  @action
  async queryForData(dateRange) {
    const { job } = this.args;

    const {
      applications: { aggregated: applications },
      pageviewQuery: { aggregated: pageviews },
    } = await this.insights.query({
      query: PROMOTIONS_QUERY,
      variables: {
        jobIds: [this.args.job.id],
        dateRange: dateRange.asObject,
      },

      context: { jobId: job.id },
    });

    const promotions = pageviews
      .reduce(
        (acc, pageviewData) => {
          const applicationData = acc.findBy(
            'promotionId',
            pageviewData.promotionId
          );
          if (applicationData) {
            applicationData.pageviews =
              pageviewData.count + applicationData.pageviews;
          } else {
            acc.push({
              promotionId: pageviewData.promotionId,
              pageviews: pageviewData.count,
            });
          }

          return acc;
        },
        [...applications.map((data) => ({ ...data, pageviews: 0 }))]
      )
      .map((data) => ({
        id: data.promotionId,
        pageviews: data.pageviews || 0,
        applications: data.count || 0,

        channelId: data.channelId,
        appliedCandidateIds: data.appliedCandidateIds,
      }));

    if (isEmpty(promotions)) {
      return [];
    } else {
      const promotionRecords = await this.store.query('promotion', {
        ids: promotions.mapBy('id'),
      });
      await RSVP.all(promotionRecords.mapBy('channel'));

      await this.store.query('channel', {
        ids: promotionRecords.mapBy('channelId'),
      });

      return promotions.map((promotion) => {
        const promotionRecord = promotionRecords.findBy('id', promotion.id);

        const averageRating = new AverageRating(
          this.store,
          promotion.appliedCandidateIds
        );

        return {
          ...promotion,
          averageRating,
          channelName:
            promotion.channelName ||
            (promotionRecord && promotionRecord.humanName) ||
            'Deleted promotion',
        };
      });
    }
  }

  fetchData = dropTask(async () => {
    const promotions = await this.queryForData(this.args.dateRange);
    return promotions
      .sortBy('applications', 'pageviews')
      .reverse()
      .splice(0, 5);
  });
}
