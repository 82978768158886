import { helper } from '@ember/component/helper';
import ScorecardCriterium, {
  TOPIC,
} from 'teamtailor/models/scorecard-criterium';
import ScorecardPick from 'teamtailor/models/scorecard-pick';
import { get } from 'teamtailor/utils/get';

export function scorecardCriteriumColor([scorecardStuff]: (
  | ScorecardCriterium
  | ScorecardPick
)[]): string {
  return scorecardStuff
    ? get(scorecardStuff, 'topic') === TOPIC.SKILL
      ? 'light-blue'
      : 'yellow'
    : 'zinc';
}

export default helper(scorecardCriteriumColor);
