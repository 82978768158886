import Controller from '@ember/controller';

import { action, setProperties, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class SettingsPartnerHubNewController extends Controller {
  queryParams = ['type'];
  @service intl;
  @service router;
  @service flashMessages;
  @tracked type = null;

  @action
  async save() {
    try {
      if (!['partner', 'channel'].includes(this.type)) throw new Error();

      const sandboxActivation = await this.model.sandboxActivation.save();
      setProperties(this.model.activation, {
        [this.type]: get(sandboxActivation, this.type),
      });

      await this.model.activation.save();
      this.flashMessages.success(
        this.intl.t(`settings.partner_hub.${this.type}_saved`)
      );
      this.router.transitionTo('settings.partner_hub');
    } catch (error) {
      this.flashMessages.error(
        this.intl.t(`settings.partner_hub.could_not_create_${this.type}`)
      );
    }
  }
}
