import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

const emojis = {
  morning: '🌤',
  afternoon: '☕️',
  evening: '😊',
  default: '👋',
};

export default class DashboardController extends Controller {
  @service intl;
  @service notifications;
  @service pwaInstallation;
  @service router;

  timeSlot = null;

  get emoji() {
    return emojis[this.timeSlot];
  }

  get greeting() {
    return this.intl.t(`dashboard.greetings.${this.timeSlot}`);
  }

  @action
  handleWidgetSettingsClick(widget) {
    this.router.transitionTo('dashboard.dashboard-widget.edit', widget);
  }
}
