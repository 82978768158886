import ApplicationInstance from '@ember/application/instance';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';
import { gql } from '@apollo/client/core';
import DateRange from 'teamtailor/utils/date-range';
import moment, { Moment } from 'moment-timezone';
import ReportAnalyticsRequest, {
  FetchOptions,
} from './report-analytics-request';
import Store from '@ember-data/store';
import AnalyticsService from 'teamtailor/services/analytics';

export const POSSIBLE_STAGE_TYPES = [
  'inbox',
  'in_process',
  'screening',
  'interview',
  'offer',
];

const queryGenerator = (groupByWeek = false) => gql`
  query PipelineOverviewQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    all: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [REJECTED, HIRED, SOURCED, APPLIED]
      companyIds: $companyIds
    ) {
      aggregated {
        rejected: countOccurrences(filters: { eventType: { equals: REJECTED } })
        hired: countOccurrences(filters: { eventType: { equals: HIRED } })
        sourced: countOccurrences(
          filters: {
            eventType: { equals: SOURCED }
            jobApplicationId: { exists: true }
          }
        )
        applied: countOccurrences(filters: { eventType: { equals: APPLIED } })
      }
    }

    byStageType: stageSnapshotQuery(dateRange: $dateRange, jobIds: $jobIds, companyIds: $companyIds) {
      aggregated(groupBy: [STAGE_TYPE, ${
        groupByWeek ? 'ISO_YEAR_ISO_WEEK' : 'DATE'
      }]) {
        stageType
        ${groupByWeek ? 'isoYearIsoWeek' : 'date'}

        applications: sum(field: APPLICATIONS)
        ${groupByWeek ? 'duplications: distinctCount(field: DATE)' : ''}
      }
    }
  }
`;
const byStageNameQueryGenerator = (groupByWeek = false) => gql`
  query PipelineOverviewByStageNameQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    byStageName: stageSnapshotQuery(dateRange: $dateRange, jobIds: $jobIds, companyIds: $companyIds) {
      aggregated(
        groupBy: [STAGE_NAME, STAGE_TYPE, ${
          groupByWeek ? 'ISO_YEAR_ISO_WEEK' : 'DATE'
        }]
      ) {
        stageName
        stageType
        ${groupByWeek ? 'isoYearIsoWeek' : 'date'}

        applications: sum(field: APPLICATIONS)
        ${groupByWeek ? 'duplications: distinctCount(field: DATE)' : ''}
      }
    }
  }
`;

type StageTypeResultRow = {
  stageType: string;
  date?: string;
  isoYearIsoWeek?: number;
  applications: number;
  duplications?: number;
};

type StageNameResultRow = {
  stageName: string;
  stageType: string;
  date?: string;
  isoYearIsoWeek?: number;
  applications: number;
  duplications?: number;
};

type StageTypeData = {
  stageType: string;
  applications: number;
};

type StageTypeRow = {
  date: string;
  isoYearIsoWeek?: number;
  stageTypes: StageTypeData[];
};

type StageNameData = {
  dataId: string;
  name: string;
  stageType: string;
  applications: number;
};

type StageNameRow = {
  date: string;
  isoYearIsoWeek?: number;
  stages: StageNameData[];
};

type Result = {
  eventQuery: {
    aggregated: {
      rejected: number;
      hired: number;
      sourced: number;
      applied: number;
    };
  };
  byStageType:
    | {
        aggregated: StageTypeResultRow[] | undefined;
      }
    | undefined;
  byStageName:
    | {
        aggregated: StageNameResultRow[] | undefined;
      }
    | undefined;
};

interface PipelineOverviewReportArgs {
  container: ApplicationInstance;
  rows: StageTypeRow[];
  stages: StageNameRow[];
  hired?: number;
  applied?: number;
  rejected?: number;
  sourced?: number;
}
class PipelineOverviewReport {
  @service declare store: Store;
  @service declare analytics: AnalyticsService;

  container: ApplicationInstance;
  rows: StageTypeRow[] = [];
  stages: StageNameRow[] = [];
  hired!: number;
  applied!: number;
  rejected!: number;
  sourced!: number;

  constructor(args: PipelineOverviewReportArgs) {
    this.container = args.container;
    Object.assign(this, {
      hired: 0,
      applied: 0,
      rejected: 0,
      sourced: 0,
      ...args,
    });
  }

  stageTypes = [...POSSIBLE_STAGE_TYPES];
}

function fillEmptyStageTypeDates(
  dates: string[],
  rows: StageTypeRow[],
  before: StageTypeRow,
  after: StageTypeRow
) {
  const stageTypesDiffs: StageTypeData[] = before.stageTypes.map((first) => {
    let last = after.stageTypes.find((s) => s.stageType === first.stageType);
    if (!last) {
      last = first;
    }

    return {
      stageType: first.stageType,
      applications:
        (last.applications - first.applications) / (dates.length + 1),
    };
  });

  dates.forEach((date, index) => {
    const emptyRow = rows.find((r) => moment(r.date).isSame(date, 'day'));
    if (emptyRow) {
      emptyRow.stageTypes = before.stageTypes.map((stageTypeData) => {
        const diff = stageTypesDiffs.find(
          (s) => s.stageType === stageTypeData.stageType
        );
        return {
          stageType: stageTypeData.stageType,
          applications: Math.round(
            stageTypeData.applications + (diff?.applications || 0) * (index + 1)
          ),
        };
      });
    }
  });
}

export function processRows(
  rows: StageTypeResultRow[],
  dateSpan: Moment[],
  longPeriod = false
) {
  let allRows: StageTypeRow[] = dateSpan.map((date) => ({
    date: date.format('YYYY-MM-DD'),
    stageTypes: [],
  }));

  if (longPeriod) {
    const firstDayOfWeekFilter = (
      value: Moment,
      index: number,
      array: Moment[]
    ): boolean => {
      const firstDayOfWeek = value.isoWeekday(1);
      const yearWeek = parseInt(
        `${firstDayOfWeek.year()}${firstDayOfWeek.isoWeek()}`
      );
      const firstItem = array.find((v: Moment) => {
        const comparisonYearWeek = parseInt(`${v.year()}${v.isoWeek()}`);
        return comparisonYearWeek === yearWeek;
      })!;

      const isFirstDateOfWeek = array.indexOf(firstItem) === index;
      return isFirstDateOfWeek;
    };

    allRows = dateSpan.filter(firstDayOfWeekFilter).map((date) => ({
      date: date.format('YYYY-MM-DD'),
      isoYearIsoWeek: parseInt(`${date.year()}${date.isoWeek()}`),
      stageTypes: [],
    }));
  }

  let emptyDates: string[] = [];

  let previousRow: StageTypeRow = { date: '', stageTypes: [] };
  allRows.forEach((dateRow) => {
    let dataRows = [];
    if (longPeriod) {
      dataRows = rows.filter(
        (r) => r.isoYearIsoWeek === dateRow.isoYearIsoWeek
      );
    } else {
      dataRows = rows.filter((r) => moment(r.date).isSame(dateRow.date, 'day'));
    }

    if (dataRows.length) {
      dateRow.stageTypes = [];
      dataRows.forEach((row) => {
        let { applications } = row;
        if (longPeriod && row.duplications) {
          applications = Math.round(applications / row.duplications);
        }

        dateRow.stageTypes.push({
          stageType: row.stageType,
          applications,
        });
      });

      if (emptyDates.length > 0) {
        fillEmptyStageTypeDates(emptyDates, allRows, previousRow, dateRow);
      }

      previousRow = dateRow;
      emptyDates = [];
    } else if (previousRow.stageTypes.length > 0) {
      emptyDates.push(dateRow.date);
    }
  });

  return allRows;
}

function fillEmptyStagesDates(
  dates: string[],
  rows: StageNameRow[],
  before: StageNameRow,
  after: StageNameRow
) {
  const stagesDiffs: StageNameData[] = before.stages.map((first) => {
    let last = after.stages.find((s) => s.dataId === first.dataId);
    if (!last) {
      last = first;
    }

    return {
      dataId: first.dataId,
      stageType: first.stageType,
      name: first.name,
      applications:
        (last.applications - first.applications) / (dates.length + 1),
    };
  });

  dates.forEach((date, index) => {
    const emptyRow = rows.find((r) => moment(r.date).isSame(date, 'day'));
    if (emptyRow) {
      emptyRow.stages = before.stages.map((stagesData) => {
        const diff = stagesDiffs.find((s) => s.dataId === stagesData.dataId);
        return {
          dataId: stagesData.dataId,
          stageType: stagesData.stageType,
          name: stagesData.name,
          applications: Math.round(
            stagesData.applications + (diff?.applications || 0) * (index + 1)
          ),
        };
      });
    }
  });
}

export function processStageRows(
  rows: StageNameResultRow[],
  dateSpan: Moment[],
  longPeriod = false
) {
  let allRows: StageNameRow[] = dateSpan.map((date) => ({
    date: date.format('YYYY-MM-DD'),
    stages: [],
  }));

  if (longPeriod) {
    const firstDayOfWeekFilter = (
      value: Moment,
      index: number,
      array: Moment[]
    ): boolean => {
      const firstDayOfWeek = value.isoWeekday(1);
      const yearWeek = parseInt(
        `${firstDayOfWeek.year()}${firstDayOfWeek.isoWeek()}`
      );
      const firstItem = array.find((v: Moment) => {
        const comparisonYearWeek = parseInt(`${v.year()}${v.isoWeek()}`);
        return comparisonYearWeek === yearWeek;
      })!;

      const isFirstDateOfWeek = array.indexOf(firstItem) === index;
      return isFirstDateOfWeek;
    };

    allRows = dateSpan.filter(firstDayOfWeekFilter).map((date) => ({
      date: date.format('YYYY-MM-DD'),
      isoYearIsoWeek: parseInt(`${date.year()}${date.isoWeek()}`),
      stages: [],
    }));
  }

  let emptyDates: string[] = [];

  let previousRow: StageNameRow = { date: '', stages: [] };
  allRows.forEach((dateRow) => {
    let dataRows = [];
    if (longPeriod) {
      dataRows = rows.filter(
        (r) => r.isoYearIsoWeek === dateRow.isoYearIsoWeek
      );
    } else {
      dataRows = rows.filter((r) => moment(r.date).isSame(dateRow.date, 'day'));
    }

    if (dataRows.length) {
      dateRow.stages = [];
      dataRows.forEach((row) => {
        let { applications } = row;
        if (longPeriod && row.duplications) {
          applications = Math.round(applications / row.duplications);
        }

        dateRow.stages.push({
          dataId: `${row.stageType}-${row.stageName}`.replace(/\./g, ''),
          stageType: row.stageType,
          name: row.stageName,
          applications,
        });
      });

      if (emptyDates.length > 0) {
        fillEmptyStagesDates(emptyDates, allRows, previousRow, dateRow);
      }

      previousRow = dateRow;
      emptyDates = [];
    } else if (previousRow.stages.length > 0) {
      emptyDates.push(dateRow.date);
    }
  });

  return allRows;
}

interface PipelineOverviewFetchOptions extends FetchOptions {
  fetchByStageName: boolean;
}

interface PipelineOverviewReportFetcherArgs {
  container: ApplicationInstance;
  options: PipelineOverviewFetchOptions;
}

export default class PipelineOverviewReportFetcher {
  container: ApplicationInstance;
  options: PipelineOverviewFetchOptions;

  @service declare analytics: AnalyticsService;

  constructor(args: PipelineOverviewReportFetcherArgs) {
    this.container = args.container;
    this.options = args.options;
  }

  fetch = task(async (): Promise<PipelineOverviewReport> => {
    const { dateSpan } = new DateRange(
      this.analytics.startDate,
      this.analytics.endDate
    );

    const longPeriod = dateSpan.length > 91;
    const results = await new ReportAnalyticsRequest({
      container: this.container,
      query: queryGenerator(longPeriod),
      callback: (result?: Result) => result,
    }).fetch();

    if (!results) {
      return new PipelineOverviewReport({
        container: this.container,
        rows: [],
        stages: [],
      });
    }

    let stageNameResults: Result | undefined;

    if (this.options.fetchByStageName) {
      stageNameResults = await new ReportAnalyticsRequest({
        container: this.container,
        query: byStageNameQueryGenerator(longPeriod),
        callback: (result?: Result) => result,
      }).fetch();
    }

    const {
      all: { aggregated: all },
      byStageType,
    } = results;
    const { applied, hired, rejected, sourced } = all?.firstObject || {};
    const { byStageName } = stageNameResults || {};

    const rows = processRows(byStageType?.aggregated, dateSpan, longPeriod);
    const stages = processStageRows(
      byStageName?.aggregated || [],
      dateSpan,
      longPeriod
    );

    return new PipelineOverviewReport({
      container: this.container,
      rows,
      stages,
      hired,
      applied,
      rejected,
      sourced,
    });
  });

  fetchTask = trackedTask(this, this.fetch);
}
