export const emojiSupported = (function () {
  let node = document.createElement('canvas');
  if (
    !node.getContext ||
    !node.getContext('2d') ||
    typeof node.getContext('2d').fillText !== 'function'
  )
    return false;
  let ctx = node.getContext('2d');
  ctx.textBaseline = 'top';
  ctx.font = '32px Arial';
  ctx.fillText('\uD83E\uDD5D', 0, 0);
  return ctx.getImageData(16, 16, 1, 1).data[0] !== 0;
})();
