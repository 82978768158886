import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"common.requisition_archive_reason\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"reason\"\n    @options={{this.requisitionArchiveReasons}}\n    @searchEnabled={{true}}\n    @isDisabled={{@isDisabled}}\n    @onSelect={{@onChange}}\n    @selected={{if\n      @hasChangedValue\n      (find-by \"id\" @selected this.requisitionArchiveReasons)\n    }}\n    @nullOption={{unless @noAllOption (t \"search.all\")}}\n    @searchPlaceholder={{t\n      \"search.requisition_archive_reason.search_archive_reason\"\n    }}\n  />\n</label>", {"contents":"<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"common.requisition_archive_reason\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"reason\"\n    @options={{this.requisitionArchiveReasons}}\n    @searchEnabled={{true}}\n    @isDisabled={{@isDisabled}}\n    @onSelect={{@onChange}}\n    @selected={{if\n      @hasChangedValue\n      (find-by \"id\" @selected this.requisitionArchiveReasons)\n    }}\n    @nullOption={{unless @noAllOption (t \"search.all\")}}\n    @searchPlaceholder={{t\n      \"search.requisition_archive_reason.search_archive_reason\"\n    }}\n  />\n</label>","moduleName":"teamtailor/components/sidebar/filters/requisition-archive-reason.hbs","parseOptions":{"srcName":"teamtailor/components/sidebar/filters/requisition-archive-reason.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { CompanyModel } from 'teamtailor/models';

export default class extends Component {
  @service declare current: Current;

  get company(): CompanyModel {
    return this.current.company;
  }

  get requisitionArchiveReasons() {
    return this.company.sortedRequisitionArchiveReasons;
  }
}
