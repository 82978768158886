import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import { JobApplicationModel, StageModel } from 'teamtailor/models';

export default class NpsResponseModel extends Model {
  @belongsTo('job-application')
  declare jobApplication: AsyncBelongsTo<JobApplicationModel>;

  @belongsTo('stage') declare stage: AsyncBelongsTo<StageModel>;

  @attr('boolean') declare onReject: boolean;
  @attr('date') declare scoredAt: Date | null;
  @attr('date') declare sentAt: Date;
  @attr('number') declare score: number | null;
  @attr('string') declare feedback: string;

  get isPending() {
    return !this.scoredAt;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'nps-response': NpsResponseModel;
  }
}
