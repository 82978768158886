import Service from '@ember/service';

const COLORS = [
  '#f99fc2',
  '#9ddbdd',
  '#cc92fe',
  '#b0db82',
  '#79baf3',
  '#f9a07b',
  '#f099f0',
  '#b8c38c',
  '#959aec',
  '#d9b476',
  '#a2dcb2',
  '#ec9292',
];
const FALLBACK_COLOR = '#909095';

export default class CalendarColorService extends Service {
  getColor(index) {
    return COLORS[index] || FALLBACK_COLOR;
  }
}
