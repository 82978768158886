import Route from '@ember/routing/route';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CompaniesRoute extends Route {
  @service session;
  @service router;
  @service externalIntegrationOauth;
  @service store;

  get route() {
    return this.externalIntegrationOauth.hasValidOauthData()
      ? 'marketplace.partners.oauth_partner'
      : 'dashboard';
  }

  beforeModel(transition) {
    super.beforeModel(...arguments);
    this.session.requireAuthentication(transition, 'login');

    const invitationId = get(this.controllerFor('invitations'), 'invitationId');

    if (invitationId) {
      this.router.transitionTo('invitations', invitationId);
    }

    let { companies } = get(this, 'session.data.authenticated');
    if (companies?.length === 1) {
      set(
        this.store.adapterFor('application'),
        'companyUuid',
        companies[0].uuid
      );

      this.router.replaceWith(this.route, companies[0].uuid);
    }
  }

  model() {
    return this.store.findAll('available-company');
  }

  afterModel(companies) {
    if (companies.length === 1) {
      let uuid = get(companies, 'firstObject.uuid');
      set(this.store.adapterFor('application'), 'companyUuid', uuid);
      return this.router.replaceWith(this.route, uuid);
    } else {
      return companies;
    }
  }

  setupController(controller, companies) {
    super.setupController(controller, companies);
    if (companies.length === 0) {
      controller.runTimerTask.perform();
    }
  }
}
