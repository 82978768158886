import { tracked } from '@glimmer/tracking';
import {
  IPaperclip,
  IQuestion,
  IMeetingPreparedMessage,
} from 'teamtailor/components/meeting/types';

type UploadAttributes = {
  id?: string;
  file?: string;
  fileFileName?: string;
  fileContentType?: string;
  fileFileSize?: number;
  copyFromKey?: string;
};

type MeetingPreparedMessage = IMeetingPreparedMessage;
export type MeetingPreparedMessageAttributes = Pick<
  MeetingPreparedMessage,
  'subject' | 'body'
> & {
  id?: string;
  uploadsAttributes: UploadAttributes[];
  questionIds: string[];
};

export type PickedUploadFields = {
  id?: string;
  fileKey?: string;
  fileFileName: string;
  fileContentType: string;
  fileFileSize: number;
  file?: IPaperclip;
  isUploading?: boolean;
  fromTemplate?: boolean;
};

export default class PreparedMessageClass {
  @tracked declare id: string;
  @tracked declare subject: string;
  @tracked declare body: string;
  @tracked declare uploads: PickedUploadFields[];
  @tracked declare questions: IQuestion[];
  @tracked private readonly originalUploads: PickedUploadFields[];

  constructor(message?: MeetingPreparedMessage) {
    this.id = message?.id ?? '';
    this.subject = message?.subject ?? '';
    this.body = message?.body ?? '';
    this.uploads = message?.uploads ?? [];
    this.questions = message?.questions ?? [];
    this.originalUploads = message?.uploads ?? [];
  }

  static from(message: IMeetingPreparedMessage): PreparedMessageClass {
    return new PreparedMessageClass(message);
  }

  get hasAnyData(): boolean {
    return !!(
      this.id ||
      this.body ||
      this.subject ||
      this.uploads.length ||
      this.questions.length
    );
  }

  toAttributes(): MeetingPreparedMessageAttributes {
    return PreparedMessageClass.createAttributes(this);
  }

  uploadsToAttributes(): UploadAttributes[] {
    const removedUploads = this.originalUploads.filter(
      (ou) => !this.uploads.some((u) => u.id === ou.id)
    );

    const newUploads = this.uploads.filter((u) => {
      return !u.id;
    });

    return [
      ...newUploads.map((upload) => {
        return {
          copyFromKey: upload.fileKey, // always copy from key to new file to make bulk meetings work when splitting up to different meetings, since backend just clones the attributes to new meetings.
          fileFileName: upload.fileFileName,
          fileFileSize: upload.fileFileSize,
          fileContentType: upload.fileContentType,
        };
      }),
      ...removedUploads.map((upload) => {
        return { id: upload.id, _destroy: true };
      }),
    ];
  }

  private static createAttributes(
    preparedMessage: PreparedMessageClass
  ): MeetingPreparedMessageAttributes {
    return {
      id: preparedMessage.id,
      subject: preparedMessage.subject,
      body: preparedMessage.body,
      uploadsAttributes: preparedMessage.uploadsToAttributes(),
      questionIds: preparedMessage.questions.map((q) => q.id),
    };
  }
}
