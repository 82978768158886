import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<p\n  local-class={{class-names\n    \"root\"\n    root--dark=(eq @theme \"dark\")\n    root--withTooltip=@messageInTooltip\n  }}\n  ...attributes\n>\n  <span class=\"flex gap-4\">\n    <Icon\n      @icon=\"circle-exclamation\"\n      @style={{if (eq @theme \"dark\") \"solid\"}}\n      local-class=\"icon\"\n    />\n    {{#if (has-block)}}\n      {{yield}}\n    {{else}}\n      {{#if @messageInTooltip}}\n        <EmberTooltip\n          @text={{@message}}\n          @side=\"top\"\n          @popperOptions={{this.popperOptions}}\n        />\n      {{else}}\n        {{@message}}\n      {{/if}}\n    {{/if}}\n  </span>\n</p>", {"contents":"<p\n  local-class={{class-names\n    \"root\"\n    root--dark=(eq @theme \"dark\")\n    root--withTooltip=@messageInTooltip\n  }}\n  ...attributes\n>\n  <span class=\"flex gap-4\">\n    <Icon\n      @icon=\"circle-exclamation\"\n      @style={{if (eq @theme \"dark\") \"solid\"}}\n      local-class=\"icon\"\n    />\n    {{#if (has-block)}}\n      {{yield}}\n    {{else}}\n      {{#if @messageInTooltip}}\n        <EmberTooltip\n          @text={{@message}}\n          @side=\"top\"\n          @popperOptions={{this.popperOptions}}\n        />\n      {{else}}\n        {{@message}}\n      {{/if}}\n    {{/if}}\n  </span>\n</p>","moduleName":"teamtailor/components/field-error.hbs","parseOptions":{"srcName":"teamtailor/components/field-error.hbs"}});
import Component from '@glimmer/component';

export default class extends Component {
  popperOptions = Object.freeze({
    modifiers: {
      preventOverflow: {
        escapeWithReference: true,
      },
    },
  });
}
