import { IOperator } from 'teamtailor/components/fancy-filters';
import BaseOperator from './base-operator';

export default class MatchesOperator
  extends BaseOperator
  implements IOperator {}

export function prepareMatchesOperator(
  shouldMatch = true
): () => MatchesOperator {
  return () => new MatchesOperator('matches', !shouldMatch);
}
