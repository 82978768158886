import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { setProperties, set, action } from '@ember/object';
import { later } from '@ember/runloop';
import { get } from 'teamtailor/utils/get';

export default class EditController extends Controller {
  @service ttAlert;
  @service current;
  @service intl;
  @service router;
  @service flashMessages;

  get slackApp() {
    return this.model;
  }

  get onlyJob() {
    return !!get(this.model, 'job.id');
  }

  set onlyJob(value) {
    if (value) {
      if (get(this, 'model').belongsTo('job').value() === null) {
        get(this.current.company, 'jobs').then(() => {
          set(
            this,
            'model.job',
            get(this.current.company, 'notArchivedJobs.firstObject')
          );
        });
      }

      set(this, 'model.sendConnects', false);
    } else {
      setProperties(this, {
        'model.job': null,
        'model.department': null,
        'model.role': null,
      });
    }
  }

  @action
  save() {
    const promise = get(this, 'model').save();
    promise.then(() => {
      later(() => {
        this.modalClosedAction();
      }, 1000);
    });
    return promise;
  }

  @action
  delete() {
    get(this, 'ttAlert').confirm(
      '',
      () => {
        get(this, 'model')
          .destroyRecord()
          .then(() => {
            get(this, 'flashMessages').success(
              this.intl.t(
                'settings.integrations.slack.slack_integration_removed'
              )
            );
            later(() => {
              this.modalClosedAction();
            }, 1000);
          });
      },
      () => {}
    );
  }

  @action
  changeDepartment(department) {
    setProperties(this, {
      'model.department': department,
      'model.role': null,
    });
  }

  @action
  modalClosedAction() {
    this.router.transitionTo('settings.integrations.slack_apps');
  }
}
