import { get, set } from '@ember/object';
import CareerSiteLanguageBaseRoute from 'teamtailor/routes/career-site-language-base';
import { inject as service } from '@ember/service';

export default class ConsentRoute extends CareerSiteLanguageBaseRoute {
  @service store;
  @service current;

  async model() {
    const { company } = this.current;

    const termsOfServiceSetting = await this.getTermsOfServiceSetting(company);
    const dataPrivacySetting = await this.getDataPrivacySetting(company);

    return {
      termsOfServiceSetting,
      dataPrivacySetting,
    };
  }

  async getTermsOfServiceSetting(company) {
    let termsOfServiceSetting = await get(company, 'termsOfServiceSetting');

    if (!termsOfServiceSetting) {
      termsOfServiceSetting = this.store.createRecord(
        'terms-of-service-setting',
        {
          company,
          languageCode: this.defaultLanguageCode,
        }
      );
    }

    return termsOfServiceSetting;
  }

  async getDataPrivacySetting(company) {
    if (company.belongsTo('dataPrivacySetting').id()) {
      const dataPrivacySetting = await get(company, 'dataPrivacySetting');
      return dataPrivacySetting;
    } else {
      return this.store.createRecord('data-privacy-setting');
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    const keys = [
      'connect.candidates.new.signup_terms_opt_in',
      'jobsite.job_applications.new.terms.agree_to_terms_opt_in',
      'jobsite.job_applications.new.terms.agree_to_terms_opt_in_future_jobs',
      'connect.candidates.new.signup_terms_opt_in_future_jobs',
    ].join(',');

    return this.current.company.getTranslation({ keys }).then((data) => {
      set(
        controller,
        'connectTextPlaceholder',
        data.translations['connect.candidates.new.signup_terms_opt_in']
      );
      set(
        controller,
        'applyTextPlaceholder',
        data.translations[
          'jobsite.job_applications.new.terms.agree_to_terms_opt_in'
        ]
      );
      set(
        controller,
        'futureJobsTextPlaceholder',
        data.translations[
          'jobsite.job_applications.new.terms.agree_to_terms_opt_in_future_jobs'
        ]
      );
      set(
        controller,
        'connectFutureJobsTextPlaceholder',
        data.translations[
          'connect.candidates.new.signup_terms_opt_in_future_jobs'
        ]
      );
    });
  }
}
