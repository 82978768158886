import Model, { attr } from '@ember-data/model';

enum Status {
  ENQUEUED = 'enqueued',
  RUNNING = 'running',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export default class SuggestExistingCandidatesJobRunModel extends Model {
  @attr('string') declare status: Status;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'suggest-existing-candidates-job-run': SuggestExistingCandidatesJobRunModel;
  }
}
