import Model, { attr, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class PartnerActivitionModel extends Model {
  @service server;

  @tracked cachedConfig = {};

  @belongsTo('company') company;
  @belongsTo('partner', { async: false }) partner;
  @belongsTo('apiKey') apiKey;
  @belongsTo('authorization') authorization;

  @attr('raw', { defaultValue: () => ({}) }) providerKey;
  @attr('date') credentialsValidatedAt;
  @attr('date') createdAt;
  @attr('boolean') installed;

  get capabilityAssessments() {
    return this.partner.capabilityAssessments;
  }

  get capabilityCandidateManual() {
    return this.partner.capabilityCandidateManual;
  }

  get capabilityCandidateResults() {
    return this.partner.capabilityCandidateResults;
  }

  get capabilityTrigger() {
    return this.partner.capabilityTrigger;
  }

  get partnerName() {
    return this.partner.name;
  }

  get partnerId() {
    return this.partner.id;
  }

  get webhook() {
    return this.partner.webhook;
  }

  async getConfig(queryParams) {
    return this.server.memberAction(this, {
      action: 'partner_activation_configs',
      method: 'get',
      queryParams,
    });
  }

  async getWebhookActivationConfig() {
    return this.server.memberAction(this, {
      action: 'partner_webhook_activation_configs',
      method: 'get',
    });
  }

  async validateProviderKey() {
    return this.server.memberAction(this, {
      action: 'partner_webhook_activation_validations',
      method: 'get',
    });
  }

  config = async ({ job_id, stage_id, webhook_data } = {}) => {
    const result = await this.getConfig({ job_id, stage_id, webhook_data });
    const fields = result ? result.config.fields : [];
    this.cachedConfig = fields;
    return fields;
  };

  webhookActivationConfig = async () => {
    try {
      const result = await this.getWebhookActivationConfig();
      const config = result?.config;
      return {
        ...config,
        fields: this.getFieldsWithValues(config?.fields || []),
      };
    } catch (error) {
      return [
        {
          type: 'error',
          message: error?.errors[0]?.detail,
          required: true,
        },
      ];
    }
  };

  getFieldsWithValues = (fields = []) => {
    if (this.providerKey) {
      const values = this.providerKey;
      return fields.map((field) => ({
        ...field,
        value: values[field.id] || field.value,
      }));
    }

    return fields;
  };
}
