import Transform from '@ember-data/serializer/transform';

export default class Raw<T = unknown> extends Transform {
  deserialize(serialized: string) {
    return serialized as T;
  }

  serialize(deserialized: T) {
    return deserialized as string;
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    raw: Raw;
  }
}
