import { animate } from 'liquid-fire';

export default function mdModal(opts = {}, effect = 'mdEffect1') {
  const oOpts = Object.assign(
    {},
    {
      duration: 100,
    },
    opts
  );

  // 1. set hidden state when entering route
  this.newElement.classList.add('mdHidden', effect);
  // 2. fade in view and backdrop
  return animate(this.newElement, { opacity: [1, 0] }, oOpts, 'fadeIn').then(
    () => {
      // 3. control content transitions with css
      this.newElement.classList.add('mdShow');
    }
  );
}
