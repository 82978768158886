import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"no-global-styles relative flex h-32 w-200 items-center rounded-6 pl-8 focus-within:ring-2 focus-within:ring-pink-500/50\"\n>\n  <Icon @icon=\"magnifying-glass\" class=\"pointer-events-none absolute\" />\n  <label class=\"no-global-styles\">\n    <span class=\"sr-only\">{{@placeholder}}</span>\n    <input\n      class=\"no-global-styles size-full bg-transparent pl-24 pr-8 focus-visible:outline-none\"\n      type=\"search\"\n      placeholder={{@placeholder}}\n      value={{@value}}\n      {{on \"input\" this.onSearchChanged}}\n    />\n  </label>\n</div>", {"contents":"<div\n  class=\"no-global-styles relative flex h-32 w-200 items-center rounded-6 pl-8 focus-within:ring-2 focus-within:ring-pink-500/50\"\n>\n  <Icon @icon=\"magnifying-glass\" class=\"pointer-events-none absolute\" />\n  <label class=\"no-global-styles\">\n    <span class=\"sr-only\">{{@placeholder}}</span>\n    <input\n      class=\"no-global-styles size-full bg-transparent pl-24 pr-8 focus-visible:outline-none\"\n      type=\"search\"\n      placeholder={{@placeholder}}\n      value={{@value}}\n      {{on \"input\" this.onSearchChanged}}\n    />\n  </label>\n</div>","moduleName":"teamtailor/components/table-search.hbs","parseOptions":{"srcName":"teamtailor/components/table-search.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

export interface TableSearchComponentSignature {
  Args: {
    value: string;
    placeholder: string;
    onChange?: (value: string) => void;
  };
}

export default class TableSearchComponent extends Component<TableSearchComponentSignature> {
  @action
  onSearchChanged(event: InputEvent) {
    const { value } = event.target as HTMLInputElement;
    this.args.onChange?.(value);
  }
}
