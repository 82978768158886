export const getSortOptionLabelString = (sortOptionValue) => {
  switch (sortOptionValue) {
    case 'candidate.firstName':
    case 'candidate.anonymousName':
      return 'components.stage_list.settings.sort.first_name';
    case 'candidate.timeSinceLastActivity':
      return 'components.stage_list.settings.sort.last_activity';
    case 'candidate.averageRating':
      return 'components.stage_list.settings.sort.rating';
    case 'match':
      return 'components.stage_list.settings.sort.match';
    case 'candidate.lastName':
      return 'components.stage_list.settings.sort.last_name';
    case 'highestPartnerResultScore':
      return 'components.stage_list.settings.sort.score';
    case 'timeSinceCreatedAt':
      return 'components.stage_list.settings.sort.application_date';
    case 'rowOrder':
    default:
      return 'components.stage_list.settings.sort.manual';
  }
};
