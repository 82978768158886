import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { set, action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

export default class EmployeesBulkController extends Controller {
  @service store;
  @service intl;
  @service current;
  @service router;
  @service flashMessages;
  @service accessLevels;
  @service flipper;

  @tracked bulkEmployeesEmails = null;
  @tracked bulkEmployeesAccessLevelIds;
  @tracked bulkEmployeesRole = 'no_access';
  @tracked bulkEmployeesVisible = null;
  @tracked bulkEmployeesHideEmail = null;
  @tracked bulkEmployeesHidePhone = null;
  @tracked userCreated = false;
  @tracked errors = null;
  @tracked employeesCreated = false;

  get hasDirtyProperties() {
    return !isEmpty(get(this, 'bulkEmployeesEmails'));
  }

  @action
  onChangeRole(value, addons) {
    set(this, 'bulkEmployeesRole', value);
    set(
      this,
      'bulkEmployeesAccessLevelIds',
      this.accessLevels.accessLevelIdsWithDefault(value, addons)
    );
  }

  @action
  saveUsers() {
    let data = {
      emails: get(this, 'bulkEmployeesEmails'),
      role: get(this, 'bulkEmployeesRole'),
      visible: get(this, 'bulkEmployeesVisible'),
      hideEmail: get(this, 'bulkEmployeesHideEmail'),
      hidePhone: get(this, 'bulkEmployeesHidePhone'),
      accessLevelIds: get(this, 'bulkEmployeesAccessLevelIds'),
    };
    let store = get(this, 'store');
    let adapter = store.adapterFor('user');
    let url = adapter.buildURL('bulk_employees');

    let promise = adapter.ajax(url, 'POST', {
      data: { bulk_employees: data },
    });
    promise
      .then(() => {
        set(this, 'employeesCreated', true);
        this.router.transitionTo('employees.index');
        get(this, 'flashMessages').success(
          this.intl.t('employees.bulk.employe_created_info')
        );
      })
      .catch((data) => {
        set(this, 'errors', data.errors);
      });
    return promise;
  }
}
