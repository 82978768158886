import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { Transition } from 'teamtailor/utils/type-utils';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import RouterService from '@ember/routing/router-service';
import { ErrorResponse } from '@apollo/client/link/error';
import RouteHelper from 'teamtailor/services/route-helper';

export default class MeetingRoute extends Route {
  @service declare apollo: TeamtailorApolloService;
  @service declare router: RouterService;
  @service declare routeHelper: RouteHelper;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @service flashMessages: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @service intl: any;

  /**
   * Can be used for type safe lookup when using
   * eg. this.modelFor('meeting') from another route;
   */
  static readonly routeName: string = 'meetings';

  @action
  error(error: ErrorResponse, transition: Transition): void {
    if (error.graphQLErrors?.length) {
      this.flashMessages.error(
        error.graphQLErrors.map((m) => m.message).join('\n'),
        {
          sticky: true,
          preventDuplicates: false,
        }
      );
      if (transition.from) {
        transition.abort();
      } else {
        this.router.transitionTo('dashboard');
      }
    }
  }
}
