import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class LayoutComponent extends Component {
  @service router;
  @service flipper;
  @service store;

  @action
  save() {
    const { section } = this.args;

    return section.save().then(() => {
      this.router.transitionTo('content.editor.section');
    });
  }
}
