import Model, { AsyncBelongsTo, belongsTo } from '@ember-data/model';
import { TeamModel, DepartmentModel, RoleModel } from 'teamtailor/models';

export default class DepartmentOrRoleModel extends Model {
  @belongsTo('team') declare team: AsyncBelongsTo<TeamModel>;
  @belongsTo('department') declare department: AsyncBelongsTo<DepartmentModel>;
  @belongsTo('role') declare role: AsyncBelongsTo<RoleModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'department-or-role': DepartmentOrRoleModel;
  }
}
