import IntlService from 'ember-intl/services/intl';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import {
  FormBuilderField,
  requisitionFormToFormBuilderFields,
} from 'teamtailor/utils/form-builder';
import FlashMessageService from 'teamtailor/services/flash-message';
import { tracked } from '@glimmer/tracking';
import {
  DepartmentModel,
  LocationModel,
  RequisitionFlowModel,
  RequisitionModel,
  RequisitionSettingModel,
} from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';

interface CustomFormAnswer {
  [key: string]: unknown;
}

interface CustomFormError {
  [key: string]: { message: string }[];
}

export default class NewController extends Controller {
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  @tracked customFieldAnswers: CustomFormAnswer = {};
  @tracked customFieldErrors: CustomFormError = {};

  declare model: {
    requisition: RequisitionModel;
    locations: LocationModel[];
    departments: DepartmentModel[];
    requisitionSetting: RequisitionSettingModel;
    defaultFlow: RequisitionFlowModel;
  };

  get customFields() {
    const form = this.model.requisitionSetting.customForm;

    if (!form) {
      return [];
    }

    const fields = requisitionFormToFormBuilderFields(form.slice());

    return fields;
  }

  validateCustomForm(isDraft: boolean) {
    this.customFieldErrors = {};

    if (isDraft) {
      return;
    }

    const errorObj = [
      {
        message: this.intl.t('errors.cant_be_blank'),
      },
    ];

    const visibleFields = this.customFields.filter((f: FormBuilderField) => {
      if (f.show_if) {
        return (
          this.customFieldAnswers[f.show_if.ref_uuid as string] ===
          f.show_if.value
        );
      }

      return true;
    });

    const missingFieldUuids = visibleFields
      .filter((f) => !!f.required)
      .filter((f) => !this.customFieldAnswers[f.uuid])
      .map((f) => f.uuid);

    const customFieldErrors = missingFieldUuids.reduce<CustomFormError>(
      (acc, fieldUuid) => {
        return {
          ...acc,
          [fieldUuid]: errorObj,
        };
      },
      {}
    );

    this.customFieldErrors = customFieldErrors;
  }

  @action
  onCustomFieldChange(key: string, value: unknown): void {
    this.resetSelectedIfs(key);
    set(this.customFieldAnswers, key, value);
  }

  resetSelectedIfs = (key: string): void => {
    const selected = [this.customFieldAnswers[key]].flat();

    this.customFields
      .filter(
        (a) =>
          selected.includes(a.show_if?.value) && a.show_if?.ref_uuid === key
      )
      .forEach((b) => {
        this.resetSelectedIfs(b.uuid);
        this.customFieldAnswers[b.uuid] = undefined;
      });
  };

  @action
  async submitRequisition(isDraft = false): Promise<void> {
    this.validateCustomForm(isDraft);

    const { requisition } = this.model;

    set(requisition, 'isDraft', isDraft);

    const form = this.customFields;

    set(requisition, 'customForm', {
      form,
      answers: get(this, 'customFieldAnswers'),
    });

    await requisition.save();

    if (Object.keys(this.customFieldErrors).length > 0 && !isDraft) {
      throw new Error(this.intl.t('requisitions.new.missing_custom_fields'));
    }

    this.flashMessages.success(
      this.intl.t('requisitions.new.requisition_created')
    );

    await this.router.transitionTo('jobs.requisitions');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'requisitions.new': NewController;
  }
}
