import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  ...attributes\n  class=\"id-company-thumbnail inline-flex size-24 flex-shrink-0 items-center justify-center rounded bg-darkgray text-center text-16 text-white selection:text-white\"\n  style={{html-safe (concat \"background-color: \" @company.color)}}\n  aria-hidden=\"true\"\n>\n  <span>{{this.companyInitialLetter}}</span>\n</div>", {"contents":"<div\n  ...attributes\n  class=\"id-company-thumbnail inline-flex size-24 flex-shrink-0 items-center justify-center rounded bg-darkgray text-center text-16 text-white selection:text-white\"\n  style={{html-safe (concat \"background-color: \" @company.color)}}\n  aria-hidden=\"true\"\n>\n  <span>{{this.companyInitialLetter}}</span>\n</div>","moduleName":"teamtailor/components/nav-bar/company-thumbnail.hbs","parseOptions":{"srcName":"teamtailor/components/nav-bar/company-thumbnail.hbs"}});
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';

export default class extends Component {
  get companyInitialLetter() {
    return get(this, 'args.company.name').charAt(0);
  }
}
