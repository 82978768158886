import Model, { AsyncHasMany, attr, hasMany } from '@ember-data/model';
import UserModel from './user';

export default class LoginModel extends Model {
  @hasMany('user') declare users: AsyncHasMany<UserModel>;

  @attr('string') declare email: string;
  @attr('boolean') declare ssoLogin: boolean;
  @attr('boolean', { defaultValue: false }) declare twoFactorActivated: boolean;
  @attr('string') declare username: string;
  @attr('string') declare twoFactorProvisioningUri: string;
  @attr('string') declare twoFactorSecret: string;
  @attr('boolean', { defaultValue: false }) declare ssoLoginExempt: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    login: LoginModel;
  }
}
