import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    steps: {
      serialize: 'records',
      deserialize: 'ids',
      key: 'requisition_flow_steps_attributes',
    },
  },
});
