import { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import moment from 'moment-timezone';
import { NurtureCampaignModel } from 'teamtailor/models';
import NurtureCampaignStepModel from 'teamtailor/models/nurture-campaign-step';
import { get } from 'teamtailor/utils/get';

const ONE_DAY_IN_MINUTES = 1440;

export default class NurtureCampaignStepWaitModel extends NurtureCampaignStepModel {
  @service declare intl: IntlService;

  @belongsTo('nurture-campaign', { async: false, inverse: 'waitSteps' })
  declare nurtureCampaign: NurtureCampaignModel;

  @attr('number', { defaultValue: ONE_DAY_IN_MINUTES })
  declare waitMinutes: number;

  get icon() {
    return 'clock';
  }

  get prettyName() {
    const days = moment.duration(this.waitMinutes, 'minutes').asDays();
    return this.intl.t('nurture_campaigns.step.wait_time', { days });
  }

  get waitDays() {
    return get(this, 'waitMinutes') / ONE_DAY_IN_MINUTES;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'nurture-campaign-step/wait': NurtureCampaignStepWaitModel;
  }
}
