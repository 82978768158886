import Route from '@ember/routing/route';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class IndexRoute extends Route {
  @service requisitionUpdateService;

  deactivate() {
    const controller = this.controllerFor('jobs.requisition.index');
    set(controller, 'updatingRequisition', false);
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    const answers = get(model, 'customForm.answers') || {};
    set(controller, 'customFieldAnswers', answers);
  }

  resetController(controller, isExiting) {
    this.requisitionUpdateService.isEditing = false;
    set(controller, 'customFieldErrors', {});

    if (isExiting) {
      set(controller.requisition, 'removeAttachment', false);
    }
  }
}
