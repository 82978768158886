import Model, { attr } from '@ember-data/model';

export default class CopilotFeedbackModel extends Model {
  @attr('boolean') declare good: boolean;
  @attr('string') declare code: string;
  @attr('string') declare comment: string;
  @attr('raw') declare metadata: object;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'copilot-feedback': CopilotFeedbackModel;
  }
}
