import JobApplicationModel from 'teamtailor/models/job-application';
import { compare } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import ArrayProxy from '@ember/array/proxy';

export type SortDirection = 'desc' | 'asc';

type ModelWithRowOrder = JobApplicationModel;

export default function sortByRowOrder(models: ArrayProxy<ModelWithRowOrder>) {
  return models.toArray().sort((a: ModelWithRowOrder, b: ModelWithRowOrder) => {
    return compare(get(a, 'rowOrder'), get(b, 'rowOrder'));
  });
}
