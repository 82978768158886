import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button\n  type=\"button\"\n  class=\"c-button-item\n    {{if this.active 'active'}}\n    {{if\n      (eq @theme 'dark')\n      '!rounded-6 !border-none !p-0 !font-medium !normal-case text-white'\n    }}\n    {{if (and (eq @theme 'dark') this.active) '!bg-action-strong'}}\n    {{if (and (eq @theme 'dark') (not this.active)) '!bg-neutral-weak'}}\"\n  {{on \"click\" this.handleClick}}\n  ...attributes\n>\n  {{#if @icon}}\n    <Icon\n      @icon={{@icon}}\n      @style={{@iconStyle}}\n      @size={{@iconSize}}\n      class=\"c-button-item__icon\"\n    />\n  {{/if}}\n  <span>\n    {{@text}}\n  </span>\n  {{#if @tooltip}}\n    <EmberTooltip @text={{@tooltip}} />\n  {{/if}}\n  {{yield}}\n</button>", {"contents":"<button\n  type=\"button\"\n  class=\"c-button-item\n    {{if this.active 'active'}}\n    {{if\n      (eq @theme 'dark')\n      '!rounded-6 !border-none !p-0 !font-medium !normal-case text-white'\n    }}\n    {{if (and (eq @theme 'dark') this.active) '!bg-action-strong'}}\n    {{if (and (eq @theme 'dark') (not this.active)) '!bg-neutral-weak'}}\"\n  {{on \"click\" this.handleClick}}\n  ...attributes\n>\n  {{#if @icon}}\n    <Icon\n      @icon={{@icon}}\n      @style={{@iconStyle}}\n      @size={{@iconSize}}\n      class=\"c-button-item__icon\"\n    />\n  {{/if}}\n  <span>\n    {{@text}}\n  </span>\n  {{#if @tooltip}}\n    <EmberTooltip @text={{@tooltip}} />\n  {{/if}}\n  {{yield}}\n</button>","moduleName":"teamtailor/components/button-item.hbs","parseOptions":{"srcName":"teamtailor/components/button-item.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
export default class ButtonItemComponent extends Component {
  get iconStyle() {
    return this.args.iconOptions?.style;
  }

  get iconSize() {
    return this.args.iconOptions?.size;
  }

  get active() {
    if (this.args.active !== undefined) {
      return this.args.active;
    }

    return this.args.value === this.args.currentValue;
  }

  @action
  handleClick() {
    if (this.args.onClick) {
      this.args.onClick();
    } else if (this.args.click) {
      this.args.click();
    } else if (this.args.onUpdate) {
      this.args.onUpdate(this.args.value);
    } else if (this.args.currentValue) {
      this.args.currentValue = this.args.value;
    }
  }
}
