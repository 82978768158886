import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { notEmpty, not } from '@ember/object/computed';
import Controller from '@ember/controller';
import { get, set, action, computed } from '@ember/object';

const INVITATIONS_ENDPOINT = '/invitations';

@classic
export default class InvitationsController extends Controller {
  @service flashMessages;
  @service server;
  @service session;
  @service router;

  company = undefined;
  currentLogin = undefined;
  errors = undefined;
  invitationId = undefined;
  isSaving = false;
  loginCompanies = [];
  ssoRelatedCompany = false;
  user = undefined;

  @notEmpty('user.password')
  hasPassword;

  @not('hasPassword')
  missingPassword;

  @computed('company.id', 'loginCompanies')
  get signedInOnCompany() {
    return (
      get(this, 'loginCompanies') &&
      notEmpty(this, 'loginCompanies') &&
      get(this, 'loginCompanies').some(
        (company) => company.id === get(this, 'company.id')
      )
    );
  }

  @computed('missingPassword', 'user.password')
  get passwordCheck() {
    if (get(this, 'missingPassword')) {
      return 0;
    }

    return this.zxcvbn(get(this, 'user.password'));
  }

  authenticate() {
    const email = get(this, 'user.invite_email');
    const password = get(this, 'user.password');
    let promise = get(this, 'session').authenticate('authenticator:token', {
      email,
      password,
    });
    promise.catch(() => {
      get(this, 'flashMessages').error('Email or password was invalid');
    });
    return promise;
  }

  @action
  acceptInvitation() {
    this.server
      .request(
        `${INVITATIONS_ENDPOINT}/${get(this, 'invitationId')}/accept`,
        'PATCH'
      )
      .then((response) => {
        if (response.ssoLoginUrl) {
          window.location.href = response.ssoLoginUrl;
        } else {
          this.router.transitionTo('dashboard', get(this, 'company.uuid'));
        }
      })
      .catch((error) => {
        if (error.payload) {
          set(this, 'errors', error.payload.errors);
        }
      });
  }

  @action
  createLogin() {
    set(this, 'isSaving', true);
    return this.server
      .request(`${INVITATIONS_ENDPOINT}/${get(this, 'invitationId')}`, 'PUT', {
        data: {
          user: {
            name: get(this, 'user.name'),
            login_attributes: {
              email: get(this, 'user.invite_email'),
              password: get(this, 'user.password') || '',
            },
          },
        },
      })
      .then((response) => {
        if (response.ssoLoginUrl) {
          window.location.href = response.ssoLoginUrl;
        } else {
          this.authenticate().then(() => {
            this.router.transitionTo('dashboard', get(this, 'company.uuid'));
          });
        }

        return undefined;
      })
      .catch((error) => {
        set(this, 'isSaving', false);

        if (error.errors) {
          let errors = { login: { email: null, password: null } };
          error.errors.forEach((err) => {
            if (err.source?.pointer === '/data/attributes/login.email') {
              errors.login.email = err.detail;
            } else if (
              err.source?.pointer === '/data/attributes/login.password'
            ) {
              errors.login.password = err.detail;
            }
          });

          set(this, 'errors', errors);
        }
      });
  }

  @action
  invalidateSession() {
    set(this, 'invalidatingSession', true);
    get(this, 'session').invalidate();
  }
}
