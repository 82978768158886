import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { get, set } from '@ember/object';

export default class RejectRoute extends Route {
  @service intl;
  @service router;
  @service flashMessages;

  afterModel(model, transition) {
    transition.abort();
    if (!get(this, 'model.rejected')) {
      set(model, 'status', 'rejected');
      model
        .save()
        .then(() => {
          get(this, 'flashMessages').success(
            this.intl.t('jobs.job.approvals.job_was_not_approved')
          );
          this.router.transitionTo('jobs.job.stages', get(model, 'job'));
        })
        .catch(() => {
          get(this, 'flashMessages').error(
            this.intl.t('errors.something_went_wrong')
          );
          this.router.transitionTo('jobs.job.stages', get(model, 'job'));
        });
    } else {
      get(this, 'flashMessages').success(
        this.intl.t('jobs.job.approvals.job_already_rejected')
      );
      this.router.transitionTo('jobs.job.stages', get(model, 'job'));
    }
  }
}
