import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  attrs: {
    upload: { serialize: false },
    choicesString: { key: 'choices' },
  },

  serialize() {
    let json = this._super(...arguments);
    if (json.choices) {
      json.choices = json.choices.split(',');
    } else {
      json.choices = [];
    }

    if (json.id) {
      delete json.candidate_id;
      delete json.user_id;
    }

    return json;
  },
});
