import Model, {
  hasMany,
  belongsTo,
  attr,
  AsyncHasMany,
  AsyncBelongsTo,
  SyncHasMany,
} from '@ember-data/model';
import { get } from 'teamtailor/utils/get';
import CandidateCounterRoleModel from 'teamtailor/models/candidate-counter/role';
import CompanyModel from 'teamtailor/models/company';
import TranslationRoleModel from 'teamtailor/models/translation/role';
import DepartmentModel from 'teamtailor/models/department';
import PickedQuestionModel from 'teamtailor/models/picked-question';

export default class RoleModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('department') declare department: AsyncBelongsTo<DepartmentModel>;

  @hasMany('translation/role')
  declare translations: AsyncHasMany<TranslationRoleModel>;

  @hasMany('picked-question', { async: false })
  declare pickedQuestions: SyncHasMany<PickedQuestionModel>;

  @belongsTo('candidate-counter/role')
  declare candidateCounter: AsyncBelongsTo<CandidateCounterRoleModel>;

  @attr('string') declare languageCode: string;
  @attr('string') declare name: string;

  get candidateCount() {
    const candidateCounter = get(this, 'candidateCounter');
    return get(candidateCounter, 'count');
  }

  get questions() {
    return this.pickedQuestions.map((pickedQuestion: PickedQuestionModel) => {
      return get(pickedQuestion, 'question');
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    role: RoleModel;
  }
}
