import Route from '@ember/routing/route';
import { jsonToBase64 } from 'teamtailor/utils/base-64';
import { inject as service } from '@ember/service';

export default class DepartmentsRedirectRoute extends Route {
  @service router;

  beforeModel({ queryParams }) {
    this.router.replaceWith('candidates.segment', 'all', {
      queryParams: { q: jsonToBase64(queryParams) },
    });
  }
}
