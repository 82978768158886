import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  local-class=\"range-slider\"\n  class=\"flex flex-col gap-6 pt-6\"\n  {{did-insert this.initializeSlider}}\n  {{did-update this.initializeSlider @min @max}}\n  {{did-update this.valueChanged @value}}\n  ...attributes\n>\n  <div class=\"px-8\" data-nouislider-target>\n    {{! Slider will be rendered here }}\n  </div>\n  <div class=\"flex justify-between text-10 font-medium text-neutral-weak\">\n    <span class=\"pl-2\">{{this.min}}</span>\n    <span>{{this.max}}</span>\n  </div>\n</div>", {"contents":"<div\n  local-class=\"range-slider\"\n  class=\"flex flex-col gap-6 pt-6\"\n  {{did-insert this.initializeSlider}}\n  {{did-update this.initializeSlider @min @max}}\n  {{did-update this.valueChanged @value}}\n  ...attributes\n>\n  <div class=\"px-8\" data-nouislider-target>\n    {{! Slider will be rendered here }}\n  </div>\n  <div class=\"flex justify-between text-10 font-medium text-neutral-weak\">\n    <span class=\"pl-2\">{{this.min}}</span>\n    <span>{{this.max}}</span>\n  </div>\n</div>","moduleName":"teamtailor/components/range-slider.hbs","parseOptions":{"srcName":"teamtailor/components/range-slider.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import * as nouislider from 'nouislider';
import 'nouislider/dist/nouislider.min.css';

const DEFAULT_MIN = 0;
const DEFAULT_MAX = 100;
const DEFAULT_STEP = 1;

type RangeSliderValue = number | [number, number];

interface RangeSliderArgs {
  value?: RangeSliderValue;
  min?: number | string;
  max?: number | string;
  step?: number;
  /** Called when a handle is being dragged. */
  onUpdate?: (value: RangeSliderValue) => void;
  /** Called when a handle has been released. */
  onChange?: (value: RangeSliderValue) => void;
}

export default class RangeSlider extends Component<RangeSliderArgs> {
  private leftPadding = 1;
  private rightPadding = 2;
  private slider?: nouislider.API;

  get min(): number {
    if (!this.args.min) {
      return DEFAULT_MIN;
    }

    return typeof this.args.min === 'string'
      ? parseInt(this.args.min)
      : this.args.min;
  }

  get max(): number {
    if (!this.args.max) {
      return DEFAULT_MAX;
    }

    return typeof this.args.max === 'string'
      ? parseInt(this.args.max)
      : this.args.max;
  }

  get value(): RangeSliderValue {
    if (this.args.value) {
      return this.args.value;
    }

    const midpoint = (this.min + this.max) / 2;
    return midpoint;
  }

  get step(): number {
    return this.args.step || DEFAULT_STEP;
  }

  get isRange(): boolean {
    return Array.isArray(this.value);
  }

  get isDecimal(): boolean {
    return !!this.step && this.step > 0 && this.step < 1;
  }

  willDestroy() {
    super.willDestroy();
    this.slider?.destroy();
  }

  @action
  initializeSlider(containerEl: HTMLElement): void {
    if (this.slider) {
      this.slider.destroy();
    }

    const element: HTMLElement | null = containerEl.querySelector(
      '[data-nouislider-target]'
    );

    if (!element) {
      throw new Error('Could not find a nouislider target.');
    }

    element.innerHTML = '';

    if (this.min === this.max) {
      this.leftPadding = 10;
      this.rightPadding = 10;
    } else if (this.value) {
      this.leftPadding = 0;
      this.rightPadding = 0;
    }

    this.slider = nouislider.create(element, {
      padding: [this.leftPadding, this.rightPadding],
      start: this.value,
      connect: this.isRange,
      tooltips: this.getFormattersForValue(this.value, this.isDecimal),
      step: this.step,

      range: {
        min: [this.min - this.leftPadding],
        max: [this.max + this.rightPadding],
      },
    });

    this.slider.on('update', (values) => {
      if (!this.args.onUpdate) {
        return;
      }

      const value = this.mapNoUiSliderEventValue(values);
      this.args.onUpdate(value);
    });

    this.slider.on('end', (values) => {
      if (!this.args.onChange) {
        return;
      }

      const value = this.mapNoUiSliderEventValue(values);
      this.args.onChange(value);
    });
  }

  @action
  valueChanged(_el: HTMLElement, value?: RangeSliderValue): void {
    if (this.slider && value) {
      // Since we're actually not using an ember object here
      // eslint-disable-next-line ember/use-ember-get-and-set
      this.slider.set(value);
    }
  }

  private getFormattersForValue(
    value: RangeSliderValue,
    isDecimal: boolean
  ): nouislider.Formatter[] {
    return Array.isArray(value)
      ? value.map(() => this.createFormatter(isDecimal))
      : [this.createFormatter(isDecimal)];
  }

  private createFormatter(isDecimal = false): nouislider.Formatter {
    return {
      to: (value: number): string => {
        return isDecimal ? value.toString() : Math.round(value).toString();
      },

      from: (value: string): number => {
        return isDecimal ? parseFloat(value) : parseInt(value);
      },
    };
  }

  private mapNoUiSliderEventValue(
    values: (string | number)[]
  ): RangeSliderValue {
    const numberValues = values.map((value) =>
      typeof value === 'string' ? parseInt(value) : value
    );
    const value =
      numberValues.length === 1 && numberValues[0] !== undefined
        ? numberValues[0]
        : numberValues;

    return value as RangeSliderValue;
  }
}
