export default function (body: string) {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = body;

  if (
    wrapper.childElementCount === 1 &&
    wrapper.children[0] &&
    wrapper.children[0].tagName === 'DIV'
  ) {
    return wrapper.children[0].innerHTML;
  }

  return body;
}
