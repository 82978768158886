import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class NewController extends Controller {
  @service current;

  get item() {
    const index = this.model.items.length || 0;

    return {
      id: undefined,
      title: 'Your second headline',
      text: 'Take the opportunity to give a description of your company, your vision or any other relevant information. Try not to be too formal in your language.',

      rowOrderPosition: index,
      index,
    };
  }
}
