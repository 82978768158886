import TriggerModel from 'teamtailor/models/trigger';
import { attr } from '@ember-data/model';

export default class TriggerAskForFeedbackModel extends TriggerModel<'ask-for-feedback'> {
  @attr('string') declare formId: string;

  get valid() {
    return !!this.formId;
  }

  get description() {
    return this.intl.t('models.ask_for_feedback.if_there_are_references');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'trigger/ask-for-feedback': TriggerAskForFeedbackModel;
  }
}
