import RSVP from 'rsvp';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { AddonFeature } from 'types/features';

export default class InterviewKitsRoute extends ScrollToTopRoute {
  @service declare store: Store;

  requiredFeature: AddonFeature = 'interview_kit';

  model() {
    const job = this.modelFor('jobs.edit');

    return RSVP.hash({
      job,
      interviewKits: this.store.findAll('interview-kit', { reload: true }),
      pickedInterviewKits: get(job, 'jobDetail.pickedInterviewKits'),
    });
  }
}
