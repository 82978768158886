import Route from '@ember/routing/route';
import { setProperties } from '@ember/object';

export default class AnalyticsEmployeesIndexRoute extends Route {
  resetController(controller, isExiting) {
    if (isExiting) {
      setProperties(controller, {
        query: null,
        roles: [],
        department_ids: [],
        location_ids: [],
        topUserCriteria: 'totalActivities',
        page: 1,
      });
    }
  }
}
