import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.partnerResults}}\n  <CandidateModalNew::Main::Section\n    @title={{@partner.name}}\n    @id={{this.partnerDomId}}\n  >\n    <:content>\n      <div class=\"flex flex-col gap-16 p-2\">\n        {{#each this.partnerResults as |result|}}\n          <Partner::PartnerResult @partnerResult={{result}} />\n        {{/each}}\n      </div>\n\n    </:content>\n  </CandidateModalNew::Main::Section>\n{{/if}}", {"contents":"{{#if this.partnerResults}}\n  <CandidateModalNew::Main::Section\n    @title={{@partner.name}}\n    @id={{this.partnerDomId}}\n  >\n    <:content>\n      <div class=\"flex flex-col gap-16 p-2\">\n        {{#each this.partnerResults as |result|}}\n          <Partner::PartnerResult @partnerResult={{result}} />\n        {{/each}}\n      </div>\n\n    </:content>\n  </CandidateModalNew::Main::Section>\n{{/if}}","moduleName":"teamtailor/components/candidate-modal-new/main/section/partners/results.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-modal-new/main/section/partners/results.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { PartnerResultModel } from 'teamtailor/models';
import PartnerModel from 'teamtailor/models/partner';

interface Signature {
  Args: {
    partner: PartnerModel;
    results: PartnerResultModel[] | undefined;
  };
}

export default class extends Component<Signature> {
  @tracked showAssessmentsModal = false;

  get partnerDomId() {
    return `partner_${this.partner.id}`;
  }

  get partnerResults() {
    if (this.args.results === undefined) {
      return [];
    }

    return this.args.results.filter((pr) => pr.partnerId === this.partner.id);
  }

  get partner() {
    return this.args.partner;
  }

  @action
  toggleAssessmentsModal() {
    this.showAssessmentsModal = !this.showAssessmentsModal;
  }
}
