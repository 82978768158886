import Controller from '@ember/controller';
import { Router } from '@ember/routing';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import TtAlertService from 'teamtailor/services/tt-alert';
import CurrentService from 'teamtailor/services/current';
import ServerService from 'teamtailor/services/server';

import { identityAcceptExtension } from 'teamtailor/constants/twilio';

export default class UpdateDocuments extends Controller {
  @service declare router: Router;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare ttAlert: TtAlertService;
  @service declare current: CurrentService;
  @service declare server: ServerService;

  fileNames: { [key: string]: string } = {};
  identityAcceptExtension = identityAcceptExtension;

  @action closeModal() {
    this.router.transitionTo('settings.sms');
  }

  @action async save() {
    const setup = await get(this.current.company, 'phoneNumberSetup');
    if (!setup) {
      throw new Error('Phone number setup not found');
    }

    if (Object.keys(this.model.twilio.identity).length === 0) {
      return this.ttAlert.error(
        this.intl.t('settings.sms.update_documents.error')
      );
    }

    const data = {
      bundle: {
        documents: this.model.twilio.documents,
        identity: this.model.twilio.identity,
      },
    };
    this.server
      .memberAction(setup, {
        action: 'bundle',
        method: 'POST',
        options: { data },
      })
      .then(() => {
        setup.reload();
        this.router.transitionTo('settings.sms');
        this.flashMessages.success(
          this.intl.t('settings.sms.update_documents.success')
        );
      })
      .catch((e) => {
        if (e.errors[0].status === '409') {
          return this.ttAlert.error(
            this.intl.t('settings.sms.update_documents.error_conflict')
          );
        }

        this.ttAlert.error(this.intl.t('settings.sms.update_documents.error'));
      });
  }

  @action
  attachFile(key: string, url: string, fileName: string) {
    this.model.twilio.documents[key] = url;
    this.fileNames[key] = fileName;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.sms.update-documents': UpdateDocuments;
  }
}
