import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import InfinityService from 'ember-infinity/addon/services/infinity';
import { AddonFeature } from 'types/features';

type Params = {
  query: string;
  sort_column: string;
  sort_direction: string;
};
export default class IndexRoute extends ScrollToTopRoute {
  @service declare infinity: InfinityService;
  requiredFeature: AddonFeature = 'interview_kit';

  queryParams = {
    query: { refreshModel: true },
    sort_column: { refreshModel: true },
    sort_direction: { refreshModel: true },
  };

  model(params: Params) {
    return this.infinity.model('interview-kit', {
      perPage: 20,
      name: params.query,
      query: params.query,
      sort_column: params.sort_column,
      sort_direction: params.sort_direction,
      dont_show_hidden: true,
      startingPage: 1,
    });
  }
}
