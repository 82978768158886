import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label>\n  <div class=\"mb-8\">\n    {{t \"settings.security.sso.exclude_users.label\"}}\n  </div>\n  <Core::UserPicker\n    @selected={{this.selectedUsers}}\n    @onSelect={{this.handleToggleUser}}\n    @placeholder={{t \"settings.teams.select_users\"}}\n    @isMultiple={{true}}\n    @searchParams={{hash allowed_to_login=true joined=true}}\n    @size=\"large\"\n  />\n</label>", {"contents":"<label>\n  <div class=\"mb-8\">\n    {{t \"settings.security.sso.exclude_users.label\"}}\n  </div>\n  <Core::UserPicker\n    @selected={{this.selectedUsers}}\n    @onSelect={{this.handleToggleUser}}\n    @placeholder={{t \"settings.teams.select_users\"}}\n    @isMultiple={{true}}\n    @searchParams={{hash allowed_to_login=true joined=true}}\n    @size=\"large\"\n  />\n</label>","moduleName":"teamtailor/components/settings/security/sso-exempt-users.hbs","parseOptions":{"srcName":"teamtailor/components/settings/security/sso-exempt-users.hbs"}});
import Store from '@ember-data/store';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import IntlService from 'ember-intl/services/intl';
import UserModel from 'teamtailor/models/user';
import FlashMessageService from 'teamtailor/services/flash-message';

export default class SettingsSecuritySsoExemptUsers extends Component {
  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  @tracked selectedUsers: UserModel[] = [];

  constructor(owner: unknown, args = {}) {
    super(owner, args);

    (async () => {
      const users = await this.store.query('user', {
        exempt_from_sso_login: true,
      });

      this.selectedUsers = users.toArray();
    })();
  }

  async handlePickUser(user: UserModel): Promise<void> {
    if (!user.login) {
      return;
    }

    try {
      set(user.login, 'ssoLoginExempt', true);
      this.selectedUsers.pushObject(user);
      await user.login.save();
    } catch (e) {
      set(user.login, 'ssoLoginExempt', false);
      this.selectedUsers.removeObject(user);
    }
  }

  async handleRemoveUser(user: UserModel): Promise<void> {
    if (!user.login) return;

    try {
      set(user.login, 'ssoLoginExempt', false);
      this.selectedUsers.removeObject(user);
      await user.login.save();
    } catch (e) {
      set(user.login, 'ssoLoginExempt', true);
      this.selectedUsers.pushObject(user);
    }
  }

  @action
  handleToggleUser(user: UserModel): void {
    if (this.selectedUsers.findBy('id', user.id)) {
      this.handleRemoveUser(user);
    } else {
      this.handlePickUser(user);
    }
  }
}
