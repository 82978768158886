import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SettingsExternalRecruitmentRecruitingFirmsDeleteRoute extends Route {
  @service store;

  model(params) {
    return this.store.findRecord('recruiting-firm', params.recruiting_firm_id);
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.initController();
  }
}
