import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CompanyPageService extends Service {
  @tracked hideHeader = false;
}

declare module '@ember/service' {
  interface Registry {
    'company-page': CompanyPageService;
  }
}
