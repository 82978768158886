export default {
  "todoLabelAnimation": "Checkbox__todoLabelAnimation_eoj2re",
  "todoCheckbox": "Checkbox__todoCheckbox_eoj2re",
  "mainCirc": "Checkbox__mainCirc_eoj2re",
  "animateCircle": "Checkbox__animateCircle_eoj2re",
  "grp1": "Checkbox__grp1_eoj2re",
  "grp2": "Checkbox__grp2_eoj2re",
  "grp3": "Checkbox__grp3_eoj2re",
  "grp4": "Checkbox__grp4_eoj2re",
  "grp5": "Checkbox__grp5_eoj2re",
  "grp6": "Checkbox__grp6_eoj2re",
  "grp7": "Checkbox__grp7_eoj2re",
  "oval1": "Checkbox__oval1_eoj2re",
  "oval2": "Checkbox__oval2_eoj2re"
};
