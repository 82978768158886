import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import ChannelActivationModel from 'teamtailor/models/channel-activation';

export default class IntegrationsChannelActivationOauthRoute extends ScrollToTopRoute {
  @service declare store: Store;

  model() {
    return this.modelFor(
      'settings.integrations.channel-activation'
    ) as ChannelActivationModel;
  }
}
