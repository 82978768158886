import { QueryType } from '../base-query';
import { capitalize } from '@ember/string';
import AggregatedProperty, {
  AggregatedPropertyOptions,
} from './aggregated-property';

export default class SumProperty extends AggregatedProperty {
  constructor(options: AggregatedPropertyOptions) {
    super({
      ...options,
      type: 'max',
      columnType: 'number',
      translationKey: 'insights.reports.builder.aggregated_properties.sum',
    });
  }

  getField(queryType: QueryType) {
    const field = this.parentProperty?.getKey(queryType);

    return `sum(field: ${field})`;
  }

  get columnName() {
    const field = `${this.parentProperty?.columnName}`;

    return `${field}${capitalize(super.columnName)}Sum`;
  }
}
