import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { task } from 'ember-concurrency';
import PageValidations from 'teamtailor/validations/page';
import { BufferedChangeset } from 'ember-changeset/types';
import { reject } from 'rsvp';

export default class PageController extends Controller {
  @service declare intercom: any;
  @service declare router: RouterService;

  PageValidations = PageValidations;

  queryParams = ['pageType', 'jobId'];

  trackToIntercomAndRedirect = () => {
    const { id, pageType } = this.model;

    this.intercom.trackEvent(`created-page-${pageType.replace('_', '-')}`);
    this.router.transitionTo('content.editor.index', 'pages', id);
  };

  createPageTask = task(async (changeset: BufferedChangeset) => {
    if (changeset.pageType === 'template') {
      changeset.templateType = 'job_ad';
    }

    await changeset.save();

    this.trackToIntercomAndRedirect();
  });

  @action
  handleTakeoverCloseAction() {
    this.router.transitionTo('content.index');
  }

  @action
  async handleSave(changeset: BufferedChangeset): Promise<void> {
    await changeset.validate();

    if (changeset.isValid) {
      return this.createPageTask.perform(changeset);
    } else {
      return reject();
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'content.index.new.page': PageController;
  }
}
