import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Badge\n  @text={{t (concat \"components.job_offer_label.\" this.labelTranslationKey)}}\n  @color={{this.labelColor}}\n  @size=\"tiny\"\n/>", {"contents":"<Core::Badge\n  @text={{t (concat \"components.job_offer_label.\" this.labelTranslationKey)}}\n  @color={{this.labelColor}}\n  @size=\"tiny\"\n/>","moduleName":"teamtailor/components/job-offer/label.hbs","parseOptions":{"srcName":"teamtailor/components/job-offer/label.hbs"}});
import Component from '@glimmer/component';

export default class JobOfferLabel extends Component {
  get jobOffer() {
    return this.args.jobOffer;
  }

  get labelColor() {
    if (this.jobOffer.isWaitingForApproval) {
      return 'amber';
    } else if (this.jobOffer.isAccepted) {
      return 'green';
    } else if (this.jobOffer.isDeclined) {
      return 'red';
    } else if (this.jobOffer.isSent || this.jobOffer.isOpened) {
      return 'indigo';
    } else if (this.jobOffer.isFlowApproved) {
      return 'cyan';
    } else if (this.jobOffer.isFlowRejected) {
      return 'rose';
    }

    return 'yellow';
  }

  get labelTranslationKey() {
    if (this.jobOffer.isWaitingForApproval) {
      return 'waiting_for_approval';
    } else if (this.jobOffer.isOpened) {
      return 'opened';
    } else if (this.jobOffer.isAccepted) {
      return 'accepted';
    } else if (this.jobOffer.isDeclined) {
      return 'declined';
    } else if (this.jobOffer.isSent) {
      return 'sent';
    } else if (this.jobOffer.isFlowApproved) {
      return 'ready_to_send';
    } else if (this.jobOffer.isFlowRejected) {
      return 'flow_rejected';
    }

    return 'draft';
  }
}
