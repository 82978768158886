import Model, { attr, belongsTo, AsyncBelongsTo } from '@ember-data/model';
import RoleModel from '../role';

export default class CandidateCounterRoleModel extends Model {
  @belongsTo('role') declare role: AsyncBelongsTo<RoleModel>;

  @attr('number') declare count: number | null;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'candidate-counter/role': CandidateCounterRoleModel;
  }
}
