import TranslationModel from 'teamtailor/models/translation';
import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import UserModel from 'teamtailor/models/user';

export default class TranslationUserModel extends TranslationModel {
  @attr('string') declare description: string;
  @attr('string') declare signature: string;
  @attr('string') declare title: string;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'translation/user': TranslationUserModel;
  }
}
