import { isEmpty, isPresent } from '@ember/utils';

export default class InsightsJwt {
  _token = null;
  _promise = null;
  _adapter = null;

  constructor(adapter, resourceType, resourceId) {
    this._adapter = adapter;
    this._resourceType = resourceType;
    this._resourceId = resourceId;
  }

  get token() {
    return new Promise((resolve) => {
      if (this.shouldRefreshToken && !this.isRefreshingToken) {
        this._promise = this.refresh();
      }

      if (this.isRefreshingToken) {
        this._promise.then((token) => {
          this._token = token;
          this._promise = null;

          resolve(token);
        });
      } else {
        resolve(this._token);
      }
    });
  }

  async refresh() {
    const response = await this._adapter.ajax(this.tokenUrl, 'POST');
    return response.token;
  }

  get tokenUrl() {
    if (this._resourceType === 'job' && isPresent(this._resourceId)) {
      return `${this._adapter.buildURL('insight-token')}?job_id=${
        this._resourceId
      }`;
    } else if (
      this._resourceType === 'insights-report' &&
      isPresent(this._resourceId)
    ) {
      return `${this._adapter.buildURL('insight-token')}?token=${
        this._resourceId
      }`;
    } else if (this._resourceType === 'promotion-dashboard') {
      return `${this._adapter.buildURL(
        'insight-token'
      )}?token_type=promotion_dashboard`;
    } else {
      return this._adapter.buildURL('insight-token');
    }
  }

  get isExpired() {
    const timestamp = new Date().getTime() / 1000;
    return this.expiresAt < timestamp;
  }

  get expiresAt() {
    if (isEmpty(this._token)) return null;

    const [, payload] = this._token.split('.');
    const { exp } = JSON.parse(window.atob(payload));
    return exp;
  }

  get shouldRefreshToken() {
    return isEmpty(this._token) || this.isExpired;
  }

  get isRefreshingToken() {
    return isPresent(this._promise);
  }
}
