import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Editor::Adjustments::Forms::Wrapper @visible={{@visible}}>\n  <Editor::Adjustments::Blocks::People\n    @model={{@model}}\n    @onChange={{this.saveAndReload}}\n    @showNonPreviewAdjustments={{true}}\n  />\n</Editor::Adjustments::Forms::Wrapper>", {"contents":"<Editor::Adjustments::Forms::Wrapper @visible={{@visible}}>\n  <Editor::Adjustments::Blocks::People\n    @model={{@model}}\n    @onChange={{this.saveAndReload}}\n    @showNonPreviewAdjustments={{true}}\n  />\n</Editor::Adjustments::Forms::Wrapper>","moduleName":"teamtailor/components/editor/adjustments/forms/blocks/people.hbs","parseOptions":{"srcName":"teamtailor/components/editor/adjustments/forms/blocks/people.hbs"}});
import FormBaseComponent from './base';

export default class PeopleAdjustmentsFormComponent extends FormBaseComponent {
  reload() {
    if (this.model.enabled) {
      this.iframePreview.reloadSection();
    } else {
      this.iframePreview.removeSection(this.model.id);
    }
  }
}
