export default {
  "header": "User__header_bthn1u",
  "grid": "User__grid_bthn1u",
  "gridCols--six": "User__gridCols--six_bthn1u",
  "gridSpan--two": "User__gridSpan--two_bthn1u",
  "gridSpan--three": "User__gridSpan--three_bthn1u",
  "gridSpan--four": "User__gridSpan--four_bthn1u",
  "gridSpan--half": "User__gridSpan--half_bthn1u",
  "gridSpan--full": "User__gridSpan--full_bthn1u",
  "gridGap--s": "User__gridGap--s_bthn1u"
};
