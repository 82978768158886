import Store from '@ember-data/store';
import ArrayProxy from '@ember/array/proxy';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { RequisitionFlowModel } from 'teamtailor/models';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import Current from 'teamtailor/services/current';

export default class SettingsRequisitionsIndexRoute extends ScrollToTopRoute {
  @service declare store: Store;
  @service declare current: Current;
  @service declare intl: IntlService;

  model() {
    return this.modelFor(
      'settings.requisitions'
    ) as ArrayProxy<RequisitionFlowModel>;
  }
}
