import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';

export default class SettingsTagsIndexRoute extends ScrollToTopRoute {
  model() {
    return this.modelFor('settings.tags');
  }

  beforeModel() {
    this.controllerName = 'settings.tags';
  }
}
