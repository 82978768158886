import Controller from '@ember/controller';
import { set, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import {
  TABLE_COLUMNS,
  TABLE_LOADING_COLUMNS,
} from 'teamtailor/constants/question';
import Current from 'teamtailor/services/current';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import { get } from 'teamtailor/utils/get';
import { query } from 'ember-data-resources';
import { restartableTask, timeout } from 'ember-concurrency';

export default class SettingsQuestionsController extends Controller {
  queryParams = [
    'query',
    'question_tag_id',
    'type',
    'sort_column',
    'sort_direction',
  ];

  searchProxyDelay = 250;

  @computedLocalStorage(Boolean, 'Settings.questions.showFilterSidebar', false)
  declare showFilterSidebar: boolean;

  @service declare current: Current;

  @tracked sort_column = 'title';
  @tracked sort_direction = 'asc';
  @tracked query = '';
  @tracked page = 1;

  @tracked declare type: string | null;
  @tracked declare question_tag_id: string | null;

  loadingQuestionColumns = TABLE_LOADING_COLUMNS;

  questionsQuery = query(this, 'question', () => {
    return {
      per_page: 25,
      title: this.query,
      query: this.query,
      type: this.type,
      question_tag_id: this.question_tag_id,
      sort_column: this.sort_column,
      sort_direction: this.sort_direction,
      page: this.page,
    };
  });

  handleSearchInput = restartableTask(async (value) => {
    await timeout(1000);
    this.query = value;
    this.page = 1;
  });

  get questions() {
    return this.questionsQuery.records;
  }

  get columnSpan(): number {
    return this.questionColumns.length - 2;
  }

  get questionColumns() {
    const columns = [...TABLE_COLUMNS];
    if (this.showMissingTranslations) {
      columns.push({
        label: 'common.languages',
        sortOrder: 5,
      });
    }

    if (this.showSkills) {
      columns.push({
        label: 'settings.questions.evaluated_skill_or_trait',
        sortOrder: 5,
      });
    }

    return columns;
  }

  get totalCount() {
    return get(this.questions, 'meta')?.total_count;
  }

  get totalPages() {
    return get(this.questions, 'meta')?.total_pages;
  }

  get showMissingTranslations() {
    return this.current.company.hasFeature('career_site_languages');
  }

  get showSkills() {
    return this.current.company.hasFeature('interview_kit');
  }

  get careerSites() {
    return get(this.current.company, 'careerSites');
  }

  @action
  selectItem(attribute: 'question_tag_id' | 'type', selected: any) {
    set(this, attribute, selected ? get(selected, 'id') : null);
  }

  @action
  goToPage(page: number) {
    this.page = page;
  }

  @action
  resetQuery() {
    this.query = '';
    this.page = 1;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.questions': SettingsQuestionsController;
  }
}
