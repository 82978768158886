import Model, {
  type AsyncBelongsTo,
  type AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { inject as service } from '@ember/service';
import {
  CandidateModel,
  SharedItemModel,
  ShareLinksJobModel,
} from 'teamtailor/models';
import Server from 'teamtailor/services/server';

export default class ShareLinkModel extends Model {
  @service declare server: Server;

  @belongsTo('candidate') declare candidate: AsyncBelongsTo<CandidateModel>;
  @hasMany('shared-item') declare sharedItems: AsyncHasMany<SharedItemModel>;
  @hasMany('share-links-job')
  declare shareLinksJobs: AsyncHasMany<ShareLinksJobModel>;

  @attr('boolean', { defaultValue: true }) declare answers: boolean;
  @attr('raw', {
    defaultValue() {
      return [];
    },
  })
  declare emails: string[];

  @attr('string') declare anonymalIconUrl: string;
  @attr('boolean', { defaultValue: false }) declare anonymous: boolean;
  @attr('string') declare anonymousColor: string;
  @attr('string') declare anonymousName: string;
  @attr('number') declare daysToExpiration: number;
  @attr('date') declare expiresAt: Date;
  @attr('boolean', { defaultValue: false }) declare forCandidate: boolean;
  @attr('boolean') declare isExpiredOrRevoked: boolean;
  @attr('date') declare lastViewedAt: Date;
  @attr('boolean', { defaultValue: true }) declare linked_in: boolean;
  @attr('boolean', { defaultValue: true }) declare locations: boolean;
  @attr('string') declare message: string;
  @attr('string') declare name: string;
  @attr('string') declare password: string;
  @attr('boolean', { defaultValue: true }) declare personalInformation: boolean;
  @attr('boolean', { defaultValue: true }) declare pitch: boolean;
  @attr('boolean', { defaultValue: true }) declare recommendations: boolean;
  @attr('boolean', { defaultValue: false }) declare restricted: boolean;
  @attr('boolean', { defaultValue: true }) declare resume: boolean;
  @attr('boolean', { defaultValue: true }) declare resumeSummary: boolean;
  @attr('date') declare revokedAt: Date;
  @attr('string') declare subject: string;
  @attr('string') declare token: string;
  @attr('string') declare url: string;
  @attr('number') declare viewCount: number;

  get candidateDocuments() {
    return this.sharedItems.filterBy('hasUploadActivity', false);
  }

  get internalDocuments() {
    return this.sharedItems.filterBy('hasUploadActivity', true);
  }

  get coverLetters() {
    return this.sharedItems.filterBy('forCoverLetter', true);
  }

  async revokeShareLink() {
    return this.server.memberAction(this, { action: 'revoke_share_link' });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'share-link': ShareLinkModel;
  }
}
