import EditController from 'teamtailor/controllers/employees/profile/edit';
import { action, get, setProperties } from '@ember/object';
import { Promise } from 'rsvp';
import { inject as service } from '@ember/service';

export default class NotificationsSettings extends EditController {
  @service intl;
  @service current;
  @service flashMessages;

  get _settings() {
    return this.employee.notificationSettings;
  }

  get allUsersSettings() {
    return this._settings
      .filterBy('notificationConfigCategory', 'general')
      .sortBy('sortColumn');
  }

  get hiringTeamSettings() {
    return this._settings
      .filterBy('notificationConfigCategory', 'hiring_team')
      .sortBy('sortColumn');
  }

  get recruiterSettings() {
    return this._settings
      .filterBy('notificationConfigCategory', 'recruiter')
      .sortBy('sortColumn');
  }

  get gdprSettings() {
    return this._settings
      .filterBy('notificationConfigCategory', 'gdpr')
      .sortBy('sortColumn');
  }

  get storySettings() {
    return this._settings
      .filterBy('notificationConfigCategory', 'stories')
      .sortBy('sortColumn');
  }

  get slackSettings() {
    return this._settings.filterBy('notificationForSlack', true);
  }

  get _defaultNotificationConfigs() {
    return get(this.current.company, 'defaultNotificationConfigs');
  }

  get disableSave() {
    return this._settings.filterBy('hasDirtyAttributes').length === 0;
  }

  @action
  restoreDefaults() {
    this._defaultNotificationConfigs.then((defaultNotificationConfigs) => {
      defaultNotificationConfigs.forEach((defaultNotificationConfig) => {
        const setting = this._settings.findBy(
          'notificationConfig.id',
          get(defaultNotificationConfig, 'notificationConfig.id')
        );

        setProperties(setting, {
          email: get(defaultNotificationConfig, 'email'),
          browser: get(defaultNotificationConfig, 'browser'),
          mobile: get(defaultNotificationConfig, 'mobile'),
        });
      });
    });
  }

  @action
  saveNotifications() {
    const promises = [];

    this._settings.forEach((setting) => {
      if (setting.hasDirtyAttributes) {
        promises.push(setting.save());
      }
    });

    const promise = Promise.all(promises);
    promise.then().catch(() => {
      get(this, 'flashMessages').error(
        this.intl.t('errors.something_went_wrong')
      );
    });

    return promise;
  }
}
