import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @activity.user}}\n  <span class=\"c-activity-item__name\">\n    {{@activity.user.nameOrEmail}}\n  </span>\n{{/if}}\n\n{{#if @activity.isJobOfferFlowRejected}}\n  <Core::Badge\n    @color={{badge-color \"rejected\"}}\n    @text={{t \"components.activity_item.rejected\"}}\n    @size=\"tiny\"\n  />\n{{else if @activity.isJobOfferFlowVerdictApproved}}\n  <Core::Badge\n    @text={{t \"components.activity_item.approved\"}}\n    @color={{badge-color \"approved\"}}\n    @size=\"tiny\"\n  />\n{{/if}}\n\n{{t\n  this.translationKey\n  url=this.jobOfferUrl\n  jobOfferName=@activity.jobOffer.name\n  htmlSafe=true\n}}\n\n{{#if (and @activity.isJobOfferFlowRejected @activity.actionData.comment)}}\n  <div class=\"c-activity-item__reject-reason\">\n    {{concat '\"' @activity.actionData.comment '\"'}}\n  </div>\n{{/if}}", {"contents":"{{#if @activity.user}}\n  <span class=\"c-activity-item__name\">\n    {{@activity.user.nameOrEmail}}\n  </span>\n{{/if}}\n\n{{#if @activity.isJobOfferFlowRejected}}\n  <Core::Badge\n    @color={{badge-color \"rejected\"}}\n    @text={{t \"components.activity_item.rejected\"}}\n    @size=\"tiny\"\n  />\n{{else if @activity.isJobOfferFlowVerdictApproved}}\n  <Core::Badge\n    @text={{t \"components.activity_item.approved\"}}\n    @color={{badge-color \"approved\"}}\n    @size=\"tiny\"\n  />\n{{/if}}\n\n{{t\n  this.translationKey\n  url=this.jobOfferUrl\n  jobOfferName=@activity.jobOffer.name\n  htmlSafe=true\n}}\n\n{{#if (and @activity.isJobOfferFlowRejected @activity.actionData.comment)}}\n  <div class=\"c-activity-item__reject-reason\">\n    {{concat '\"' @activity.actionData.comment '\"'}}\n  </div>\n{{/if}}","moduleName":"teamtailor/components/activity-item/job-offer-flow.hbs","parseOptions":{"srcName":"teamtailor/components/activity-item/job-offer-flow.hbs"}});
import { get } from 'teamtailor/utils/get';
import Base from './base';
import { inject as service } from '@ember/service';

export default class ActivityItemJobOfferFlowComponent extends Base {
  @service router;

  get translationKey() {
    if (get(this.activity, 'isJobOfferFlowApproved')) {
      return 'components.activity_item.job_offer_approval.approved';
    } else if (get(this.activity, 'isJobOfferFlowVerdictReminder')) {
      return 'components.activity_item.job_offer_approval.reminded';
    } else if (get(this.activity, 'isJobOfferFlowVerdictRequested')) {
      return 'components.activity_item.job_offer_approval.requested';
    }

    return 'components.activity_item.job_offer_approval.the_job_offer';
  }

  get jobOfferUrl() {
    return this.router.urlFor(
      'jobs.job.stages.index.candidate.job-offer.show',
      get(this.activity, 'job'),
      get(this.activity, 'candidate'),
      get(this.activity, 'jobOffer')
    );
  }
}
