/* global Pusher */
import Service, { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { task, waitForProperty } from 'ember-concurrency';
import ENV from 'teamtailor/config/environment';
import { Promise, reject } from 'rsvp';
import { get } from 'teamtailor/utils/get';
import { PusherContext } from 'teamtailor/types/pusher-context';

const IGNORE_CODES = [1006, 4200, 4201, 4202];

export default class PusherService extends Service {
  @service current;
  @service user;
  @service flashMessages;
  @service intl;

  pusher = null;
  socketId = null;
  errorMessages = [];

  start(context = PusherContext.COMPANY) {
    if (get(this, 'pusher')) return;

    new Promise((resolve, reject) => {
      if (window.Pusher) {
        Pusher.Runtime.createXHR = function () {
          let xhr = new XMLHttpRequest();
          xhr.withCredentials = true;
          return xhr;
        };

        let endpoint = `${ENV.httpOrHttps}://tt.${ENV.HTTP_HOST}`;

        switch (context) {
          case PusherContext.COMPANY:
            // eslint-disable-next-line no-case-declarations
            const uuid = get(this.current.company, 'uuid');
            endpoint += `/app/companies/${uuid}/pusher/auth`;
            break;
          case PusherContext.VIDEO_MEETINGS:
            endpoint += `/video_meetings/pusher/auth`;
            break;
          default:
            reject();
        }

        resolve(
          new Pusher(ENV.pusher.key, {
            cluster: ENV.pusher.cluster,

            channelAuthorization: {
              endpoint,
            },
          })
        );
      } else {
        reject();
      }
    })
      .then((pusher) => {
        pusher.connection.bind('error', (err) => {
          const ignoreMessages = [
            'No current subscription',
            'Heartbeat missed',
            'Existing subscription to channel',
          ];

          const ignoredMessage = ignoreMessages.some((message) =>
            err.data?.message?.startsWith(message)
          );

          const ignoredCode = IGNORE_CODES.some(
            (code) => err.data?.code === code
          );

          if (ignoredMessage || ignoredCode) {
            return;
          }

          this.errorMessages.push(
            this.flashMessages
              .error(
                this.intl.t('services.pusher.general_error', {
                  code: err.error?.data?.code ?? err.data?.code ?? err.type,
                  message:
                    err.error?.data?.message ??
                    err.error?.message ??
                    err.data?.message,
                }),
                { sticky: true }
              )
              .getFlashObject()
          );
        });

        pusher.connection.bind('connected', () => {
          set(this, 'pusher', pusher);
          set(this, 'socketId', pusher.connection.socket_id);
          this.errorMessages.forEach((message) => message.destroyMessage());
          this.errorMessages = [];
        });
      })
      .catch(() => {
        this.flashMessages.error(
          this.intl.t('services.pusher.unable_to_connect'),
          { sticky: true }
        );
      });
  }

  pusherTask = task(
    {
      enqueue: true,
    },
    async (method, ...args) => {
      if (window.Pusher) {
        await waitForProperty(this, 'pusher', (value) => value !== null);
        return get(this, 'pusher')[method](...args);
      } else {
        return reject('Pusher not available');
      }
    }
  );

  async subscribe() {
    return new Promise((resolve) => {
      return get(this, 'pusherTask')
        .perform('subscribe', ...arguments)
        .then((result) => resolve(result))
        .catch(() => {});
    });
  }

  async channel() {
    return new Promise((resolve) => {
      return get(this, 'pusherTask')
        .perform('channel', ...arguments)
        .then((result) => resolve(result))
        .catch(() => {});
    });
  }

  unknownProperty(key) {
    return get(this, `pusher.${key}`);
  }
}
