import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import filterList from 'teamtailor/utils/filter-list';
import RouterService from '@ember/routing/router-service';
import JobOfferTemplateModel from 'teamtailor/models/job-offer-template';
import { TABLE_COLUMNS } from 'teamtailor/constants/job-offer-template-table';

type JobOfferTemplateModelFields = keyof JobOfferTemplateModel;

export default class SettingsJobOfferTemplatesController extends Controller {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @service timeFormat: any;
  @service router!: RouterService;

  @tracked search?: string;

  filterAttributes: JobOfferTemplateModelFields[] = ['name'];
  sortField: JobOfferTemplateModelFields = 'name';
  columns = TABLE_COLUMNS;

  get listItems() {
    return this.model;
  }

  get filteredList() {
    return filterList(this.filterAttributes, this.listItems, this.search);
  }

  get sortedTemplates() {
    const listCopy = [...this.filteredList];
    return listCopy.sort((a: JobOfferTemplateModel, b: JobOfferTemplateModel) =>
      a[this.sortField].localeCompare(b[this.sortField], undefined, {
        sensitivity: 'base',
      })
    );
  }

  get totalCount() {
    return this.filteredList.length;
  }

  @action
  copyJobOfferTemplate(jobOfferTemplate: JobOfferTemplateModel) {
    this.router.transitionTo('settings.job-offer-templates.new', {
      queryParams: { copiedJobOfferTemplateId: jobOfferTemplate.id },
    });
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.job-offer-templates.index': SettingsJobOfferTemplatesController;
  }
}
