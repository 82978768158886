interface ThrottleOptions {
  leading?: boolean;
  trailing?: boolean;
  useRequestAnimationFrame?: boolean;
}

// Same API as lodash's throttle
// https://lodash.com/docs/4.17.15#throttle
// (ChatGPT wrote it 🐿️)
const throttle = (
  callback: () => void,
  delay: number,
  options: ThrottleOptions = {
    leading: true,
    trailing: true,
    useRequestAnimationFrame: false,
  }
) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  let previous = 0;

  const throttled = () => {
    const now = Date.now();

    // If 'leading' option is false and this is the first call, set 'previous' to 'now'
    if (!previous && options.leading === false) {
      previous = now;
    }

    const remaining = delay - (now - previous);

    // If the delay has passed or 'remaining' is somehow greater than 'delay'
    if (remaining <= 0 || remaining > delay) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      previous = now;
      callback();
    }
    // If 'trailing' option is not false and there's no pending timeout
    else if (!timeout && options.trailing !== false) {
      // Set a new timeout to invoke the callback after the remaining delay
      timeout = setTimeout(() => {
        // If 'leading' option is false, reset 'previous', otherwise set it to 'now'
        previous = options.leading === false ? 0 : Date.now();

        timeout = null;

        if (options.useRequestAnimationFrame) {
          requestAnimationFrame(callback);
        } else {
          callback();
        }
      }, remaining);
    }
  };

  return throttled;
};

export default throttle;
