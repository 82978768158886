import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class NewController extends Controller {
  @service router;

  queryParams = ['byCompany'];
  byCompany = true;

  @action
  goBack() {
    this.router.transitionTo('settings.reject_reasons');
  }
}
