import Model, { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { CompanyModel } from 'teamtailor/models';
import Server from 'teamtailor/services/server';

export default class EeoReportSettingModel extends Model {
  @service declare server: Server;

  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @attr('boolean') declare race: boolean;
  @attr('boolean') declare gender: boolean;
  @attr('boolean') declare veteranStatus: boolean;
  @attr('boolean') declare enabled: boolean;

  async generateCsv() {
    return this.server.memberAction(this, {
      action: 'generate_csv',
      method: 'GET',
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'eeo-report-setting': EeoReportSettingModel;
  }
}
