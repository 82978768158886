import { inject as service } from '@ember/service';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';

export default class ApiKeysRoute extends ScrollToTopRoute {
  @service ttAlert;
  @service intl;
  @service user;
  @service store;

  model() {
    return this.store.findAll('api-key');
  }
}
