import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class PermissionMissingTemplateRoute extends ScrollToTopRoute {
  @service current;

  templateName = 'settings/data-privacy-settings/permission-template';
  controllerName = 'settings/data-privacy-settings/permission-template';

  async model() {
    const { company } = this.current;

    const template = await this.getConsentMissingReminderTemplate(company);

    return template;
  }

  async getConsentMissingReminderTemplate(company) {
    const templates = await get(company, 'dataPrivacyCannedResponses');
    let template = templates.findBy('consentMissingReminder', true);

    if (!template) {
      template = this.store.createRecord('canned-response', {
        company,
        consentMissingReminder: true,
      });
    }

    return template;
  }
}
