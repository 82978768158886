import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"{{this.containerClass}}\">\n  <div local-class=\"iframeContainer\">\n    <IframePreview\n      @iframeSrc={{@url}}\n      @iframeStyle=\"width: 100%;\"\n      @iframeType=\"styleguide\"\n    />\n  </div>\n</div>", {"contents":"<div local-class=\"{{this.containerClass}}\">\n  <div local-class=\"iframeContainer\">\n    <IframePreview\n      @iframeSrc={{@url}}\n      @iframeStyle=\"width: 100%;\"\n      @iframeType=\"styleguide\"\n    />\n  </div>\n</div>","moduleName":"teamtailor/components/content-block/styleguide.hbs","parseOptions":{"srcName":"teamtailor/components/content-block/styleguide.hbs"}});
import Component from '@glimmer/component';
import classnames from 'teamtailor/utils/classnames';
import { inject as service } from '@ember/service';

export default class extends Component {
  @service iframePreview;

  get containerClass() {
    return classnames('container', {
      show: this.args.isOpen === true,
      hide: this.args.isOpen === false,
    });
  }
}
