import { inject as service } from '@ember/service';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import FlashMessage from 'teamtailor/services/flash-message';
import Intl from 'ember-intl/services/intl';
import Store from '@ember-data/store';
import MessageWidget from 'teamtailor/models/message-widget';
import { get } from 'teamtailor/utils/get';
import ChatController from 'teamtailor/controllers/settings/chat';
import { AddonFeature } from 'types/features';

export default class ChatRoute extends ScrollToTopRoute {
  @service declare flashMessages: FlashMessage;
  @service declare intl: Intl;
  @service declare store: Store;

  requiredFeature: AddonFeature = 'candidate_chat';

  async model(): Promise<{
    messageWidget: MessageWidget;
    persistedUserId: string | null;
  }> {
    const globalMessageWidget = await get(
      this.current.company,
      'globalMessageWidget'
    );

    const messageWidget = globalMessageWidget
      ? globalMessageWidget
      : this.store.createRecord('message-widget', {
          greeting: this.intl.t('settings.chat.contact_us'),
          user: this.current.user,
          popupGreeting: true,
          enabled: true,
          company: this.current.company,
        });

    const user = await messageWidget.user;

    const persistedUserId = user ? get(user, 'id') : null;

    return {
      persistedUserId,
      messageWidget,
    };
  }

  resetController(controller: ChatController, isExiting: boolean) {
    if (isExiting) {
      const messageWidget = get(controller, 'messageWidget');
      if (messageWidget.isNew) {
        messageWidget.deleteRecord();
      } else {
        messageWidget.rollbackAttributes();
      }
    }
  }
}
