import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class MarketplacePartnersOauthPartnerRoute extends Route {
  @service store;
  @service externalIntegrationOauth;
  @service router;
  @service intl;

  async model() {
    const data = this.externalIntegrationOauth.getAuthorizationData();

    if (!data) {
      return this.router.transitionTo('dashboard');
    }

    return this.store
      .query('partner', {
        internal_name: data.internalName,
      })
      .then((partners) => {
        return partners[0];
      });
  }
}
