import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<DatetimePicker\n  @onReady={{@onReady}}\n  @displayCalendarIcon={{true}}\n  @enableTime={{false}}\n  @value={{@value}}\n  @onChange={{this.pickDate}}\n  @useUTC={{true}}\n  @disabled={{@field.unmodifiable}}\n  @verticalPosition=\"auto\"\n/>", {"contents":"<DatetimePicker\n  @onReady={{@onReady}}\n  @displayCalendarIcon={{true}}\n  @enableTime={{false}}\n  @value={{@value}}\n  @onChange={{this.pickDate}}\n  @useUTC={{true}}\n  @disabled={{@field.unmodifiable}}\n  @verticalPosition=\"auto\"\n/>","moduleName":"teamtailor/components/form-builder/default-field/read/date.hbs","parseOptions":{"srcName":"teamtailor/components/form-builder/default-field/read/date.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import moment from 'moment-timezone';

export default class FormFieldReadDate extends Component {
  @action
  pickDate(value) {
    const date = moment.utc(value).format('YYYY-MM-DD');
    return this.args.onChange(date);
  }
}
