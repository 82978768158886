import { getOwner } from '@ember/application';
import EmberObject, { set, setProperties } from '@ember/object';
import { relationsRecordRemover } from './record-remover';
import StageModel from 'teamtailor/models/stage';
import { AllTriggerModels } from 'teamtailor/models/trigger';
import { get } from 'teamtailor/utils/get';
import { TriggerSmartMoveModel } from 'teamtailor/models';

export function createTrigger(
  emberObject: EmberObject,
  stage: StageModel,
  type: string,
  onReject: boolean,
  partnerActivationId?: string,
  subtype?: string
) {
  const container = getOwner(emberObject);
  const store = container.lookup('service:store');
  const current = container.lookup('service:current');

  const job = get(stage, 'job');

  const partnerActivation = partnerActivationId
    ? store.peekRecord('partner-activation', partnerActivationId)
    : null;

  const trigger = store.createRecord(`trigger/${type}`);
  setProperties(trigger, {
    job,
    partnerActivation,
    stage,
    type,
    onReject,
  });

  if (type === 'smart-schedule') {
    set(trigger, 'timeZone', get(current.company, 'timeZone'));
  }

  if (type === 'smart-move') {
    const move = store.createRecord('move', {
      criteria: subtype,
    });
    set(trigger, 'moves', [move]);
  }

  if (type === 'hire-quality-survey') {
    setProperties(trigger, {
      evaluationPeriod: 6,
      evaluationPeriodUnit: 'months',
    });
  }

  return trigger;
}

export function saveTrigger(trigger?: AllTriggerModels) {
  if (!trigger) return Promise.resolve;

  return trigger.save().then((response) => {
    if (get(response as TriggerSmartMoveModel, 'moves')) {
      get(response as TriggerSmartMoveModel, 'moves')?.forEach((move) => {
        relationsRecordRemover(move, 'question', 'pickedQuestion');
        relationsRecordRemover(move, 'location', 'pickedLocation');
      });
    }

    relationsRecordRemover(
      response,
      'question',
      'pickedQuestion',
      'upload',
      'move'
    );
  });
}
