import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    jobApplicationStatus: { serialize: false },
    jobTitle: { serialize: false },
    picture: { serialize: false },
    color: { serialize: false },
    initials: { serialize: false },
    lastActivityAt: { serialize: false },
  },
});
