import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import { NotificationSettingModel } from 'teamtailor/models';

export default class NotificationConfigModel extends Model {
  @belongsTo('notification-setting')
  declare notificationSetting: AsyncBelongsTo<NotificationSettingModel>;

  @attr('boolean') declare availableForBrowser: boolean;
  @attr('boolean') declare availableForEmail: boolean;
  @attr('boolean') declare availableForMobile: boolean;
  @attr('boolean') declare availableForSlack: boolean;
  @attr('boolean') declare defaultEnabledBrowser: boolean;
  @attr('boolean') declare defaultEnabledEmail: boolean;
  @attr('boolean') declare defaultEnabledMobile: boolean;
  @attr('string') declare category: string;
  @attr('string') declare key: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'notification-config': NotificationConfigModel;
  }
}
