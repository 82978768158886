import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { CompanyModel, StageTypeModel } from 'teamtailor/models';

export default class StageNameModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('stage-type') declare stageType: AsyncBelongsTo<StageTypeModel>;
  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'stage-name': StageNameModel;
  }
}
