import Controller from '@ember/controller';
import { action } from '@ember/object';
import JobModel from 'teamtailor/models/job';
import TagModel from 'teamtailor/models/tag';
import toggleInList from 'teamtailor/utils/toggle-in-list';

export default class TemplateController extends Controller {
  declare model: JobModel;

  @action
  handleTagSelect(tag: TagModel) {
    toggleInList(this.model.tags, tag);
  }
}

declare module '@ember/controller' {
  interface Registry {
    'jobs.edit.template': TemplateController;
  }
}
