import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"h-[200px] w-full\" {{leaflet-map this.options}}></div>", {"contents":"<div class=\"h-[200px] w-full\" {{leaflet-map this.options}}></div>","moduleName":"teamtailor/components/partner/config-fields/geolocation.hbs","parseOptions":{"srcName":"teamtailor/components/partner/config-fields/geolocation.hbs"}});
import Component from '@glimmer/component';
import { tracked } from 'tracked-built-ins';
import { LeafletMouseEvent } from 'leaflet';

interface IConfigField {
  centerLat: string;
  centerLng: string;
  id: string;
  label: string;
}

export interface GeolocationFieldComponentProps {
  onChange: (value: string) => void;
  field: IConfigField;
}

export default class extends Component<GeolocationFieldComponentProps> {
  @tracked lat = 0;
  @tracked lng = 0;
  @tracked zoom = 10;

  get options() {
    return {
      mapOptions: {
        center: this.initialCenter,
        zoom: this.zoom,
        zoomControl: true,
        doubleClickZoom: true,
        scrollWheelZoom: true,
      },

      markerOptions: [this.lat, this.lng],
      callback: this.updateMarker.bind(this),
    };
  }

  get initialCenter() {
    const [defaultLat, defaultLng] = [59.3293, 18.0686];

    const lat = +this.args.field.centerLat || defaultLat;
    const lng = +this.args.field.centerLng || defaultLng;

    return {
      lat,
      lng,
    };
  }

  updateMarker(e: LeafletMouseEvent) {
    const { lat, lng } = e.latlng;
    if (this.lat !== lat) {
      this.lat = lat;
    }

    if (this.lng !== lng) {
      this.lng = lng;
    }

    this.args.onChange(JSON.stringify({ lat, lng }));
  }
}
