import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { later } from '@ember/runloop';

export default class PermissionTemplateController extends Controller {
  @service intl;
  @service flashMessages;
  @service router;

  previousRoute() {
    if (this.router.currentRouteName.includes('consent')) {
      return 'settings.consent';
    } else {
      return 'settings.data_privacy_settings';
    }
  }

  @action
  modalClosedAction() {
    this.model.rollbackAttributes();
    this.router.transitionTo(this.previousRoute());
  }

  @action
  save() {
    let promise = this.model.save();
    promise.then(
      () => {
        later(() => {
          this.flashMessages.success(
            this.intl.t('settings.data_privacy.email_template_was_saved')
          );
          this.router.transitionTo(this.previousRoute());
        }, 500);
      },
      () => {
        this.flashMessages.error(
          this.intl.t('settings.data_privacy.couldnt_save_email_template')
        );
      }
    );
    return promise;
  }
}
