import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { get, set } from '@ember/object';
import TranslationModel from 'teamtailor/models/translation';
import QuestionModel from 'teamtailor/models/question';

export default class TranslationQuestionModel extends TranslationModel {
  @attr('string') declare title: string;
  @attr('string') declare description: string;
  @attr('raw') declare alternatives: any;
  @attr('string') declare unit: string;
  @attr('raw') declare yesNoOptions: { yes: string; no: string };
  @attr('raw') declare addedByCopilot: { [k: string]: boolean };

  @belongsTo('question') declare question: AsyncBelongsTo<QuestionModel>;

  get formData(): any {
    return get(this, 'alternatives') ?? [];
  }

  set formData(data) {
    set(this, 'alternatives', data);
  }

  get titleWithTags() {
    return get(this, 'title');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'translation/question': TranslationQuestionModel;
  }
}
