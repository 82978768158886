import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';

export default class NewRoute extends ScrollToTopRoute {
  @service store;

  model() {
    return this.store.createRecord('team');
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    const departmentOrRole = this.store.createRecord('department-or-role', {
      team: model,
    });
    controller.teamUsers = [];
    controller.departmentsOrRoles = [departmentOrRole];
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.team.rollbackAttributes();
    }
  }
}
