import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { set, setProperties } from '@ember/object';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';

export default class SettingsRoute extends Route {
  @service store;

  model() {
    let nurtureCampaign = this.modelFor('nurture-campaigns.nurture-campaign');

    return hash({
      nurtureCampaign,
      segments: this.store.findAll('segment'),
    });
  }

  setupController(controller, { nurtureCampaign, segments }) {
    super.setupController(...arguments);

    setProperties(controller, {
      nurtureCampaign,
      segments,
    });

    segments.forEach((segment) => {
      next(() => {
        segment.fetchCount();
      });
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      set(controller, 'isEditingSettings', false);
    }
  }
}
