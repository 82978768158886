import Controller from '@ember/controller';
import { get } from '@ember/object';

export default class SettingsPartnerHubIndexController extends Controller {
  get sandboxActivations() {
    return this.model;
  }

  get hasSandboxPartner() {
    return this.sandboxActivations.some(
      (activation) => get(activation, 'partner') || get(activation, 'channel')
    );
  }
}
