import Model, { attr, hasMany, belongsTo } from '@ember-data/model';

export default class TermsOfServiceSettingModel extends Model {
  @belongsTo('company', { async: true }) company;

  @hasMany('translation/terms-of-service-setting') translations;

  @attr('string') connectText;
  @attr('string') connectTextFutureJobs;
  @attr('string') languageCode;
  @attr('boolean', { defaultValue: false }) optIn;
  @attr('boolean', { defaultValue: false }) optInConnect;
  @attr('boolean', { defaultValue: false }) optInFutureJobs;
  @attr('boolean', { defaultValue: false }) optInFutureJobsConnect;
  @attr('string') text;
  @attr('string') textFutureJobs;

  get optOut() {
    return !this.optIn;
  }
}
