import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex gap-12 rounded-8 bg-base-grey-9 bg-opacity-80 p-12\">\n  <Icon @icon=\"screwdriver-wrench\" class=\"text-base-grey-43\" />\n  <div>\n    <p class=\"body-text-s\">{{this.disclaimerText}}</p>\n    <Button\n      @text={{this.buttonText}}\n      @onClick={{this.toggleReadMore}}\n      @appearance=\"secondary\"\n    />\n    {{#if @supportContact}}\n      <p class=\"m-0\">{{t \"marketplace.email_us_at\"}}\n        <a href=\"mailto:{{@supportContact}}\">{{@supportContact}}</a>\n      </p>\n    {{/if}}\n  </div>\n</div>", {"contents":"<div class=\"flex gap-12 rounded-8 bg-base-grey-9 bg-opacity-80 p-12\">\n  <Icon @icon=\"screwdriver-wrench\" class=\"text-base-grey-43\" />\n  <div>\n    <p class=\"body-text-s\">{{this.disclaimerText}}</p>\n    <Button\n      @text={{this.buttonText}}\n      @onClick={{this.toggleReadMore}}\n      @appearance=\"secondary\"\n    />\n    {{#if @supportContact}}\n      <p class=\"m-0\">{{t \"marketplace.email_us_at\"}}\n        <a href=\"mailto:{{@supportContact}}\">{{@supportContact}}</a>\n      </p>\n    {{/if}}\n  </div>\n</div>","moduleName":"teamtailor/components/marketplace/disclaimer.hbs","parseOptions":{"srcName":"teamtailor/components/marketplace/disclaimer.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

interface DisclaimerComponentArgs {
  createdByTeamtailor: boolean;
  name: string;
}

export default class DisclaimerComponent extends Component<DisclaimerComponentArgs> {
  @service declare intl: IntlService;
  @tracked showMore = false;
  // Define your component's properties and methods here
  @action toggleReadMore() {
    this.showMore = !this.showMore;
  }

  get disclaimerText() {
    const disclaimer = this.args.createdByTeamtailor
      ? this.integrationDisclaimer
      : `${this.intl.t('marketplace.integration_developed_by', {
          name: this.args.name || '',
        })} ${this.integrationDisclaimer}`;

    return this.showMore
      ? disclaimer
      : `${disclaimer.slice(0, disclaimer.indexOf(' ', 120))}...`;
  }

  get integrationDisclaimer() {
    return this.args.name === 'Aboard'
      ? this.intl.t('marketplace.aboard_disclaimer')
      : this.intl.t('marketplace.integration_disclaimer');
  }

  get buttonText() {
    return this.showMore
      ? this.intl.t('marketplace.disclaimer_read_less')
      : this.intl.t('marketplace.disclaimer_read_more');
  }
}
