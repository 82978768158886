import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Editor::Adjustments::Controls::Dropdown\n  @model={{@model}}\n  @attribute={{@attribute}}\n  @displayValue={{@displayValue}}\n  @label={{@label}}\n  @tooltip={{@tooltip}}\n  @icon={{@icon}}\n  @dropdownClass={{\"w-[calc(200%+12px)]\"}}\n  as |dd|\n>\n  <label class=\"relative mb-0 flex h-[35px] items-center justify-between\">\n    <Input\n      @value={{this.value}}\n      @type=\"text\"\n      class=\"size-full rounded-8 border-0 bg-base-grey-64 text-14 text-white placeholder:text-base-grey-43\"\n      placeholder={{@placeholder}}\n      {{on \"focusout\" @onChange}}\n      {{on \"keyup\" (fn this.handleKeyup dd)}}\n    />\n  </label>\n</Editor::Adjustments::Controls::Dropdown>", {"contents":"<Editor::Adjustments::Controls::Dropdown\n  @model={{@model}}\n  @attribute={{@attribute}}\n  @displayValue={{@displayValue}}\n  @label={{@label}}\n  @tooltip={{@tooltip}}\n  @icon={{@icon}}\n  @dropdownClass={{\"w-[calc(200%+12px)]\"}}\n  as |dd|\n>\n  <label class=\"relative mb-0 flex h-[35px] items-center justify-between\">\n    <Input\n      @value={{this.value}}\n      @type=\"text\"\n      class=\"size-full rounded-8 border-0 bg-base-grey-64 text-14 text-white placeholder:text-base-grey-43\"\n      placeholder={{@placeholder}}\n      {{on \"focusout\" @onChange}}\n      {{on \"keyup\" (fn this.handleKeyup dd)}}\n    />\n  </label>\n</Editor::Adjustments::Controls::Dropdown>","moduleName":"teamtailor/components/editor/adjustments/inputs/text.hbs","parseOptions":{"srcName":"teamtailor/components/editor/adjustments/inputs/text.hbs"}});
import Base from './base';
import { action } from '@ember/object';

const KEY_CODE_ENTER = 'Enter';
const KEY_CODE_ESCAPE = 'Escape';

export default class extends Base {
  @action
  handleKeyup(dropdown, event) {
    if ([KEY_CODE_ESCAPE, KEY_CODE_ENTER].includes(event.code)) {
      if (event.code === KEY_CODE_ENTER) {
        this.args.onChange();
      }

      if (event.code === KEY_CODE_ESCAPE) {
        this.args.model.rollbackAttributes();
      }

      dropdown.toggleOpen();
      event.preventDefault();
    }
  }
}
