export const getTimeSlot = () => {
  const date = new Date();
  const hour = date.getHours();

  if (hour >= 5 && hour < 13) {
    return 'morning';
  }

  if (hour >= 13 && hour <= 17) {
    return 'afternoon';
  }

  if (hour > 17 && hour <= 23) {
    return 'evening';
  }

  return 'default';
};
