import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"common.language\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"label\"\n    @options={{this.options}}\n    @searchEnabled={{false}}\n    @isDisabled={{@isDisabled}}\n    @onSelect={{@onChange}}\n    @selected={{if @hasChangedValue (find-by \"id\" @selected this.options)}}\n    @nullOption={{unless @noAllOption (t \"search.all\")}}\n  />\n</label>", {"contents":"<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"common.language\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"label\"\n    @options={{this.options}}\n    @searchEnabled={{false}}\n    @isDisabled={{@isDisabled}}\n    @onSelect={{@onChange}}\n    @selected={{if @hasChangedValue (find-by \"id\" @selected this.options)}}\n    @nullOption={{unless @noAllOption (t \"search.all\")}}\n  />\n</label>","moduleName":"teamtailor/components/sidebar/filters/language.hbs","parseOptions":{"srcName":"teamtailor/components/sidebar/filters/language.hbs"}});
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import CareerSiteModel from 'teamtailor/models/career-site';
import { get } from 'teamtailor/utils/get';
import Current from 'teamtailor/services/current';

declare interface LanguageOption {
  id: string;
  label: string;
}

export default class JobFilterInputsLanguageComponent extends Component {
  @service declare current: Current;
  @service declare intl: IntlService;

  get publishedCareerSites(): LanguageOption[] {
    return get(this.current.company, 'publishedCareerSites').map(
      (careerSite: CareerSiteModel) => {
        return {
          id: careerSite.languageCode,
          label: careerSite.translatedLanguage,
        };
      }
    );
  }

  get options(): LanguageOption[] {
    return [...this.publishedCareerSites];
  }
}
