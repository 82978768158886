import { POSSIBLE_STAGE_TYPES } from 'teamtailor/components/insights/molecules/pipeline-conversion-chart';
import StageType from 'teamtailor/models/stage-type';

interface FormattableRow {
  stageType?: string;
  total: number;
  rejected: number;
}

export interface ProcessedStageTypeRow extends FormattableRow {
  stageType: string;
}

export interface ProcessedStageRow extends FormattableRow {
  stageId?: string;
  stageName?: string;
  stageRowOrder?: number;
}

export type DataRow = FormattableRow & {
  rejectedString?: string;
  displayName: string;
  numberOfApplicantsPassed: number;
  conversionDown?: string;
  conversionFromInbox?: string;
  stayed?: string;
};

export type ChartDataRow = {
  name: string;
  color: string;
  type: string;
  data: number[][];
};

export type PointsDataRow = DataRow & {
  coordinates: [number, number];
};

function valueAndPercentageString(value: number, max: number): string {
  const percentage = max > 0 ? (100 * value) / max : 0;
  const digits = value === 0 || percentage === 100 ? 0 : 1;
  const percentageString = `${percentage.toFixed(digits)}%`;
  return `${value} (${percentageString})`;
}

export const inflateStageTypeRows = (
  rows: ProcessedStageTypeRow[],
  stageTypes: StageType[]
): ProcessedStageTypeRow[] => {
  if (rows.length < POSSIBLE_STAGE_TYPES.length) {
    POSSIBLE_STAGE_TYPES.forEach((stageType) => {
      if (!rows.find((row) => row.stageType === stageType)) {
        rows.push({
          stageType,
          total: 0,
          rejected: 0,
        });
      }
    });
  }

  return rows.sort((a, b) => {
    let aIndex: number | undefined = stageTypes.findBy(
      'category',
      a.stageType
    )?.orderIndex;
    let bIndex: number | undefined = stageTypes.findBy(
      'category',
      b.stageType
    )?.orderIndex;
    if (aIndex === undefined) {
      aIndex = 0.5; // In process doesn't have an orderIndex
    }

    if (bIndex === undefined) {
      bIndex = 0.5; // In process doesn't have an orderIndex
    }

    return aIndex - bIndex;
  });
};

export const formatData = (
  rows: FormattableRow[],
  formatName: (row: FormattableRow) => string
): DataRow[] => {
  let data: DataRow[] = rows
    .reverse()
    .map((curr, i, arr) => {
      const stagesSoFar: FormattableRow[] = arr.slice(0, i + 1);

      const numberOfApplicantsPassed = stagesSoFar.reduce((acc, stage) => {
        return acc + stage.total;
      }, 0);

      return {
        ...curr,
        displayName: formatName(curr),
        numberOfApplicantsPassed,
      };
    })
    .reverse();

  const inboxTotal = data.firstObject?.numberOfApplicantsPassed || 0;
  data = data.map((curr: DataRow, i: number, arr: DataRow[]) => {
    const conversionDown =
      i < arr.length - 1
        ? `${
            curr.numberOfApplicantsPassed > 0
              ? (
                  (100 * arr[i + 1]!.numberOfApplicantsPassed) /
                  curr.numberOfApplicantsPassed
                ).toFixed(1)
              : '0.0'
          }%`
        : '-';

    let stayed = '-';

    if (i < arr.length - 1) {
      const stayedAmount = curr.total - curr.rejected;

      stayed = valueAndPercentageString(
        stayedAmount,
        curr.numberOfApplicantsPassed
      );
    }

    const rejectedString = valueAndPercentageString(
      curr.rejected,
      curr.numberOfApplicantsPassed
    );

    return {
      ...curr,
      rejectedString,
      conversionDown,
      stayed,

      conversionFromInbox: `${(
        (100 * curr.numberOfApplicantsPassed) /
        (inboxTotal || 1)
      ).toFixed(1)}%`,
    };
  });

  return data;
};

// clear out empty in process
export const removeEmptyInProcess = (rows: DataRow[]) => {
  return rows.filter(
    (row) => row.stageType !== 'in_process' || row.total > 0 || row.rejected > 0
  );
};
