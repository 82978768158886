import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"btn btn-apply {{@buttonClass}}\" style={{this.style}}>\n  {{yield}}\n</div>", {"contents":"<div class=\"btn btn-apply {{@buttonClass}}\" style={{this.style}}>\n  {{yield}}\n</div>","moduleName":"teamtailor/components/company-styled-button.hbs","parseOptions":{"srcName":"teamtailor/components/company-styled-button.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { getProperties } from '@ember/object';
import { htmlSafe } from '@ember/template';
import Current from 'teamtailor/services/current';
import { DesignModel } from 'teamtailor/models';

export interface CompanyStyledButtonComponentSignature {
  Args: {
    design: DesignModel;
    buttonClass: string;
    secondary: boolean;
  };
}

export default class CompanyStyledButtonComponent extends Component<CompanyStyledButtonComponentSignature> {
  @service declare current: Current;

  get style() {
    const {
      bodyFont,
      buttonBackgroundColor,
      buttonTextColor,
      buttonsOutlined,
      buttonsRadius,
      secondaryTextColor,
      secondaryBackgroundColor,
    } = getProperties(
      this.args.design,
      'bodyFont',
      'buttonBackgroundColor',
      'buttonTextColor',
      'buttonsOutlined',
      'buttonsRadius',
      'secondaryTextColor',
      'secondaryBackgroundColor'
    );

    const defaultBackgroundColor = this.args.secondary
      ? secondaryTextColor
      : buttonBackgroundColor;

    const backgroundColor = buttonsOutlined
      ? 'transparent'
      : defaultBackgroundColor;
    let style = `background-color: ${backgroundColor}; border-radius: ${buttonsRadius}px;`;

    if (buttonsOutlined) {
      style += `color: ${
        this.args.secondary ? secondaryTextColor : buttonBackgroundColor
      };`;
    } else {
      style += `color: ${
        this.args.secondary ? secondaryBackgroundColor : buttonTextColor
      }; border: none;`;
    }

    if (bodyFont) {
      style += `font-family: ${bodyFont}`;
    }

    return htmlSafe(style);
  }
}
