import Controller from '@ember/controller';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-mixins
import ContentBlockLayoutControllerMixin from 'teamtailor/mixins/content-block/layout-controller';
import { inject as service } from '@ember/service';

export default class LayoutController extends Controller.extend(
  ContentBlockLayoutControllerMixin
) {
  @service current;

  basePath = 'content.editor';
  sectionPath = 'content.editor.section.index';
}
