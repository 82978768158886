import Helper from '@ember/component/helper';

export default Helper.helper(function (params) {
  let string = params[0] || '';
  return string
    .replace(/_id$/, '')
    .replace(/_/g, ' ')
    .replace(/^\w/g, function (s) {
      return s.toUpperCase();
    });
});
