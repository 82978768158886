import Helper from '@ember/component/helper';
import moment from 'moment-timezone';

export function secondsAsDays([seconds]) {
  if (seconds === null || seconds === undefined) {
    return '-';
  }

  const days = moment.duration(seconds, 'seconds').asDays();

  if (days < 1) {
    return 'Less than a day';
  } else if (days >= 1 && days <= 2) {
    return 'About a day';
  } else {
    return `About ${Math.round(days)} days`;
  }
}

export default Helper.helper(secondsAsDays);
