import { inject as service } from '@ember/service';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';

export default class linkedinRscRoute extends ScrollToTopRoute {
  @service current;
  @service cookieConsent;
  @service router;

  beforeModel() {
    if (!this.current.company.linkedinRscIntegration) {
      this.router.transitionTo('settings');
    }
  }

  setupController(controller) {
    controller.setupController();
  }

  resetController(controller) {
    controller.resetController();
  }
}
