import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<HtmlButtonOrLink\n  ...attributes\n  class={{class-names\n    (if @disabled \"text-zinc-600 hover:cursor-not-allowed hover:text-zinc-600\")\n  }}\n  @linkToRoute={{@linkToRoute}}\n  @models={{@models}}\n  @model={{@model}}\n  @query={{@query}}\n  @href={{@href}}\n  @asSpan={{@asSpan}}\n  @disabled={{@disabled}}\n  @appearAsText={{false}}\n>\n  {{yield}}\n  {{#if (and @disabled @tooltipText)}}\n    <EmberTooltip @side=\"top\" @text={{@tooltipText}} />\n  {{/if}}\n</HtmlButtonOrLink>", {"contents":"<HtmlButtonOrLink\n  ...attributes\n  class={{class-names\n    (if @disabled \"text-zinc-600 hover:cursor-not-allowed hover:text-zinc-600\")\n  }}\n  @linkToRoute={{@linkToRoute}}\n  @models={{@models}}\n  @model={{@model}}\n  @query={{@query}}\n  @href={{@href}}\n  @asSpan={{@asSpan}}\n  @disabled={{@disabled}}\n  @appearAsText={{false}}\n>\n  {{yield}}\n  {{#if (and @disabled @tooltipText)}}\n    <EmberTooltip @side=\"top\" @text={{@tooltipText}} />\n  {{/if}}\n</HtmlButtonOrLink>","moduleName":"teamtailor/components/bulk-actions/action-button.hbs","parseOptions":{"srcName":"teamtailor/components/bulk-actions/action-button.hbs"}});
import Component from '@glimmer/component';
import Model from '@ember-data/model';

type SharedArgs = {
  icon: string;
  disabled?: boolean;
};

type LinkToArgs = {
  linkToRoute?: string;
  models?: Model[];
  query?: string;
  state?: string;
  active?: boolean;
};

type ButtonArgs = {
  typeAttr?: 'button' | 'submit';
};

export type Args = (LinkToArgs | ButtonArgs) & SharedArgs;

export default class BulkActionsActionButton extends Component<Args> {
  get typeAttr() {
    return ('typeAttr' in this.args && this.args.typeAttr) ?? 'button';
  }
}
