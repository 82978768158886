import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';

export default class SectionRoute extends Route {
  @service iframePreview;
  @service store;

  async model({ section_id }) {
    return this.store
      .query('section', { ids: section_id })
      .then((sections) => sections.firstObject);
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    get(this, 'iframePreview').scrollToSection({
      sectionId: model.id,
    });
  }
}
