import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span class=\"flex items-center\" ...attributes>\n  {{#if @value}}\n    {{#if (or @value.image.thumb @image)}}\n      <img\n        local-class=\"thumbnail\"\n        src={{or @value.image.thumb @image}}\n        alt={{t \"icon\"}}\n      />\n    {{else}}\n      <span\n        local-class=\"letter\"\n        style={{html-safe (concat \"background-color: \" this.companyColor)}}\n      >{{this.titleFirstLetter}}</span>\n    {{/if}}\n    <span local-class=\"{{if @ellipsis 'title'}}\">{{@value.title}}</span>\n  {{else}}\n    <span local-class=\"letter\"><Icon @icon=\"globe\" /></span>\n    <span local-class=\"{{if @ellipsis 'title'}}\">{{t\n        \"components.meetings.candidate_info.no_job\"\n      }}</span>\n  {{/if}}\n</span>", {"contents":"<span class=\"flex items-center\" ...attributes>\n  {{#if @value}}\n    {{#if (or @value.image.thumb @image)}}\n      <img\n        local-class=\"thumbnail\"\n        src={{or @value.image.thumb @image}}\n        alt={{t \"icon\"}}\n      />\n    {{else}}\n      <span\n        local-class=\"letter\"\n        style={{html-safe (concat \"background-color: \" this.companyColor)}}\n      >{{this.titleFirstLetter}}</span>\n    {{/if}}\n    <span local-class=\"{{if @ellipsis 'title'}}\">{{@value.title}}</span>\n  {{else}}\n    <span local-class=\"letter\"><Icon @icon=\"globe\" /></span>\n    <span local-class=\"{{if @ellipsis 'title'}}\">{{t\n        \"components.meetings.candidate_info.no_job\"\n      }}</span>\n  {{/if}}\n</span>","moduleName":"teamtailor/components/meeting/fields/job-item.hbs","parseOptions":{"srcName":"teamtailor/components/meeting/fields/job-item.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';

interface Args {
  value: { title: string };
}

export default class MeetingFieldsJobItem extends Component<Args> {
  @service declare current: Current;

  get titleFirstLetter() {
    return this.args.value.title ? this.args.value.title.substring(0, 1) : '';
  }

  get companyColor() {
    const design = get(this.current.company, 'design');
    return get(design, 'color');
  }
}
