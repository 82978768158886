import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Table::Container\n  @columns={{this.columns}}\n  @isEmpty={{is-empty @surveys}}\n  as |table|\n>\n  {{#each @surveys as |survey|}}\n    <Surveys::Row\n      @survey={{survey}}\n      @table={{table}}\n      @loadingSurveyId={{@loadingSurveyId}}\n    />\n  {{/each}}\n\n  <table.emptyState>\n    <EmptyState @emoji=\"📝\" @header={{t \"surveys.header\"}} @color=\"amber\">\n      {{t \"surveys.pitch\"}}\n    </EmptyState>\n  </table.emptyState>\n  <table.loading class=\"h-84\" @columns={{this.loadingColumns}} @repeat={{16}} />\n</Table::Container>", {"contents":"<Table::Container\n  @columns={{this.columns}}\n  @isEmpty={{is-empty @surveys}}\n  as |table|\n>\n  {{#each @surveys as |survey|}}\n    <Surveys::Row\n      @survey={{survey}}\n      @table={{table}}\n      @loadingSurveyId={{@loadingSurveyId}}\n    />\n  {{/each}}\n\n  <table.emptyState>\n    <EmptyState @emoji=\"📝\" @header={{t \"surveys.header\"}} @color=\"amber\">\n      {{t \"surveys.pitch\"}}\n    </EmptyState>\n  </table.emptyState>\n  <table.loading class=\"h-84\" @columns={{this.loadingColumns}} @repeat={{16}} />\n</Table::Container>","moduleName":"teamtailor/components/surveys/table.hbs","parseOptions":{"srcName":"teamtailor/components/surveys/table.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { LOADING_COLUMNS } from 'teamtailor/constants/surveys';
import { SurveyModel } from 'teamtailor/models';

type SurveysTableArgs = {
  surveys: SurveyModel[];
};

export default class SurveysTableComponent extends Component<SurveysTableArgs> {
  loadingColumns = LOADING_COLUMNS;

  get columns() {
    return [
      {
        label: 'common.title',
        width: '2xl',
      },
      {
        label: 'surveys.responses_type',
        width: 'm',
      },
      {
        label: 'surveys.sends',
        width: 'm',
      },
      {
        label: 'surveys.responses',
        width: 'm',
      },
      {
        label: 'surveys.response_rate',
        width: 'm',
      },
    ];
  }

  @action
  getSurveysResponseRate(survey: SurveyModel): string {
    const { sends, responses } = survey;

    if (sends > 0) {
      return `${Math.round((100 * responses) / sends)}%`;
    }

    return `0%`;
  }
}
