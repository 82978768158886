import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { all } from 'rsvp';
import { inject as service } from '@ember/service';

export default class IndexRoute extends ScrollToTopRoute {
  @service store;

  async model() {
    const teams = await this.store.findAll('team');

    await all(
      teams.map((team) => {
        return team.users;
      })
    );

    return teams;
  }
}
