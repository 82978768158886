import Store from '@ember-data/store';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import moment from 'moment-timezone';
import { RequisitionFlowStepModel } from 'teamtailor/models';
import JobsRequisitionApprovalFlowRoute from 'teamtailor/routes/jobs/requisition/approval-flow';
import Current from 'teamtailor/services/current';
import Server from 'teamtailor/services/server';
import TtAlertService from 'teamtailor/services/tt-alert';
import { get } from 'teamtailor/utils/get';
import saveFile from 'teamtailor/utils/save-file';
import { ModelFrom } from 'teamtailor/utils/type-utils';

export default class JobsRequisitionApprovalFlowController extends Controller {
  declare model: ModelFrom<JobsRequisitionApprovalFlowRoute>;

  @service declare store: Store;
  @service declare server: Server;
  @service declare current: Current;
  @service declare ttAlert: TtAlertService;
  @service declare router: RouterService;

  get requisition() {
    return this.model;
  }

  get canEditFlow() {
    if (get(this.requisition, 'isApproved')) return false;
    if (get(this.current.user, 'admin')) return true;

    return (
      get(this, 'model').needsApprovalFromUser(this.current.user) &&
      get(this.requisition.currentStep, 'isEditable')
    );
  }

  get exportUrl() {
    const adapter = get(this, 'store').adapterFor('application');
    const url = adapter.buildURL('requisition', get(this.requisition, 'id'));
    return `${url}.csv?`;
  }

  get flow() {
    return this.requisition.belongsTo('requisitionFlow').value();
  }

  handleExport = task(
    {
      drop: true,
    },
    async () => {
      const date = moment().format('YYYY-MM-DD');
      const filename = `requisition_${date}.csv`;
      const content = await this.server
        .fetch(this.exportUrl)
        .then((response) => response.text());

      saveFile(filename, content, 'text/csv');
    }
  );

  resolveUsers = async (step: RequisitionFlowStepModel) => {
    return get(step, 'users');
  };

  @action
  editFlow() {
    this.router.transitionTo('jobs.requisition.approval-flow.edit');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'jobs.requisition.approval-flow': JobsRequisitionApprovalFlowController;
  }
}
