import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable require-media-caption }}\n<audio\n  id=\"meeting-audio-mix\"\n  class=\"hidden\"\n  {{did-insert this.onInsertMeetingAudioElement}}\n></audio>", {"contents":"{{! template-lint-disable require-media-caption }}\n<audio\n  id=\"meeting-audio-mix\"\n  class=\"hidden\"\n  {{did-insert this.onInsertMeetingAudioElement}}\n></audio>","moduleName":"teamtailor/components/video-meeting/audio-mix.hbs","parseOptions":{"srcName":"teamtailor/components/video-meeting/audio-mix.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { AudioVideoFacade } from 'amazon-chime-sdk-js';

interface VideoMeetingAudioMixArgs {
  audioVideoFacade?: AudioVideoFacade;
}

export default class VideoMeetingAudioMix extends Component<VideoMeetingAudioMixArgs> {
  willDestroy(): void {
    super.willDestroy();
    this.args.audioVideoFacade?.unbindAudioElement();
  }

  @action
  onInsertMeetingAudioElement(element: HTMLAudioElement) {
    this.args.audioVideoFacade?.bindAudioElement(element);
  }
}
