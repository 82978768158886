import IntlService from 'ember-intl/services/intl';

export function getTranslations(intl: IntlService) {
  return {
    restart: intl.t('components.video_player.restart'),
    play: intl.t('components.video_player.play'),
    pause: intl.t('components.video_player.pause'),
    seek: intl.t('components.video_player.seek'),
    played: intl.t('components.video_player.played'),
    buffered: intl.t('components.video_player.buffered'),
    currentTime: intl.t('components.video_player.current_time'),
    duration: intl.t('components.video_player.duration'),
    volume: intl.t('components.video_player.volume'),
    mute: intl.t('components.video_player.mute'),
    unmute: intl.t('components.video_player.unmute'),
    enableCaptions: intl.t('components.video_player.enable_captions'),
    disableCaptions: intl.t('components.video_player.disable_captions'),
    download: intl.t('components.video_player.download'),
    enterFullscreen: intl.t('components.video_player.enter_fullscreen'),
    exitFullscreen: intl.t('components.video_player.exit_fullscreen'),
    captions: intl.t('components.video_player.captions'),
    settings: intl.t('components.video_player.settings'),
    pip: intl.t('components.video_player.pip'),
    menuBack: intl.t('components.video_player.menu_back'),
    speed: intl.t('components.video_player.speed'),
    normal: intl.t('components.video_player.normal'),
    quality: intl.t('components.video_player.quality'),
    loop: intl.t('components.video_player.loop'),
    start: intl.t('components.video_player.start'),
    end: intl.t('components.video_player.end'),
    all: intl.t('components.video_player.all'),
    reset: intl.t('components.video_player.reset'),
    disabled: intl.t('components.video_player.disabled'),
    enabled: intl.t('components.video_player.enabled'),
    advertisement: intl.t('components.video_player.advertisement'),
  };
}

export const DEFAULT_ROUNDED_TYPE = 'all';
export const ALLOWED_ROUNDED_TYPES = [
  DEFAULT_ROUNDED_TYPE,
  'bottom',
  'top',
] as const;
