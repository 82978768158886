import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class EmployeeHighlightJobController extends Controller {
  @service router;

  get isNew() {
    return get(this.model, 'isNew');
  }

  onCompleteChanges = () => {
    this.router.transitionTo('employee');
  };

  @action
  save() {
    return this.model.save().then(this.onCompleteChanges);
  }

  @action
  delete() {
    return this.model.destroyRecord().then(this.onCompleteChanges);
  }

  @action
  handleModalClosed() {
    this.router.transitionTo('employee');
  }
}
