import Model, { attr } from '@ember-data/model';

export default class OverdureJobApplicationModel extends Model {
  @attr('number', { defaultValue: 0 }) declare count: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'overdue-job-application': OverdureJobApplicationModel;
  }
}
