import Controller from '@ember/controller';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';

export const RECOMMENDED_SINGLE_ADS = 4;
export const RECOMMENDED_ALWAYS_INCLUDED = 2;
export const RECOMMENDED_FREE = 1;
export const RECOMMENDED_SOCIAL = 2;
export const RECOMMENDED_BUNDLE = 3;
export const DISAPPEAR_TIMEOUT = 250;
export const VISIBLE_ONGOING_PROMOTIONS = 9;

export default class PromoteController extends Controller {
  @service user;
  @service current;
  @service ttAlert;
  @service intl;

  @tracked selectedCountry = null;
  @tracked showAllOngoing = false;

  showPurchaseHistory = false;
  showShareLinksHistory = false;

  get savedPromotions() {
    return this.model.job.promotions.filter((promotion) => !promotion.isNew);
  }

  get purchasedPromotions() {
    return this.savedPromotions.filter(
      (promotion) => promotion.isNotShareLinkOrQrCode
    );
  }

  get shareLinksAndQrCodes() {
    return this.savedPromotions.filter(
      (promotion) => promotion.isShareLinkOrQrCode
    );
  }

  get currentSavedPromotions() {
    return this.savedPromotions.filter((promotion) => !promotion.isExpired);
  }

  get sortedPromotions() {
    return this.purchasedPromotions.sort(
      (promotionA, promotionB) => promotionB.createdAt - promotionA.createdAt
    );
  }

  get sortedShareLinksAndQrCodes() {
    return this.shareLinksAndQrCodes.sort(
      (promotionA, promotionB) => promotionB.createdAt - promotionA.createdAt
    );
  }

  get activatedChannels() {
    return this.promotableChannels
      .filter(({ isActivated }) => isActivated)
      .sort((channelA, channelB) => channelA.rowOrder - channelB.rowOrder);
  }

  get promotable() {
    const { company } = this.current;
    return (
      (get(company, 'currentLiveCustomer') && this.model.job.isPromotable) ||
      get(company, 'sandbox')
    );
  }

  isAlwaysIncludedPublished(isAlwaysIncluded, channelInternalName) {
    const isChannelPublished =
      !this.model.job.excludeFromFeeds?.includes(channelInternalName);

    return isAlwaysIncluded && isChannelPublished;
  }

  get ongoingPromotions() {
    const promotionChannelIds = this.sortedPromotions
      .filter(
        (promotion) => !promotion.isExpired && !promotion.deleteRequestSent
      )
      .map((promotion) => get(promotion, 'channel.id'));
    return this.activatedChannels
      .filter(({ id, isAlwaysIncluded, internalName }) => {
        return (
          promotionChannelIds.includes(id) ||
          this.isAlwaysIncludedPublished(isAlwaysIncluded, internalName)
        );
      })
      .sort((channel) => (channel.isAlwaysIncluded ? 1 : -1));
  }

  get alwaysVisibleOngoingPromotions() {
    return this.ongoingPromotions.slice(0, VISIBLE_ONGOING_PROMOTIONS);
  }

  get remainingOngoingPromotions() {
    return this.ongoingPromotions.slice(VISIBLE_ONGOING_PROMOTIONS);
  }

  get showViewMoreButton() {
    this.ongoingPromotions.length > VISIBLE_ONGOING_PROMOTIONS;
  }

  get viewMoreText() {
    return this.intl.t('jobs.job.promote.view_more', {
      count: this.remainingOngoingPromotions.length,
    });
  }

  get viewLessText() {
    return this.intl.t('jobs.job.promote.view_less');
  }

  get isCompanyLive() {
    const liveDate = get(this.current.company, 'went_live_at');
    return moment(liveDate).isBefore(moment());
  }

  get internalChannels() {
    return this.model.channels.filter((channel) => channel.isInternal);
  }

  get promotableChannels() {
    return this.model.channels.filter(
      ({ nonPromotable, isInternal }) => !nonPromotable && !isInternal
    );
  }

  get availableChannels() {
    return this.promotableChannels
      .filter(({ unlisted }) => {
        return !unlisted;
      })
      .sort((a, b) => a.rowOrder - b.rowOrder);
  }

  get jobCountry() {
    const location = get(this.model.job, 'locations').firstObject;
    const jobCountry = get(location, 'country');

    return (
      jobCountry || get(get(this.current.company, 'headquarters'), 'country')
    );
  }

  get relevantChannels() {
    return this.availableChannels.filter(({ countries }) => {
      return countries.find((country) =>
        ['Global', this.jobCountry].includes(country)
      );
    });
  }

  getSortedChannels(channels) {
    return channels.sort((a, b) => {
      if (a.marketingType === 'bundle' && b.marketingType !== 'bundle')
        return -1;
      else if (a.marketingType !== 'bundle' && b.marketingType === 'bundle')
        return 1;
      else return 0;
    });
  }

  get recommendedChannels() {
    const channels = this.relevantChannels
      .filter((channel) => {
        const { isAlwaysIncluded, isFree, isSocial, isBundle, isSingleAds } =
          channel;
        return (
          !this.isOngoingPromotion(channel) &&
          (isAlwaysIncluded || isFree || isSocial || isBundle || isSingleAds)
        );
      })
      .reduce((acc, channel) => {
        const { isSingleAds, marketingType } = channel;
        const type = isSingleAds ? 'single_ads' : marketingType;
        switch (type) {
          case 'always_included':
            acc.filter((el) => el.isAlwaysIncluded).length <
              RECOMMENDED_ALWAYS_INCLUDED && acc.push(channel);
            break;
          case 'free':
            acc.filter((el) => el.isFree).length < RECOMMENDED_FREE &&
              acc.push(channel);
            break;
          case 'social':
            acc.filter((el) => el.isSocial).length < RECOMMENDED_SOCIAL &&
              acc.push(channel);
            break;
          case 'single_ads':
            acc.filter((el) => el.isSingleAds).length <
              RECOMMENDED_SINGLE_ADS && acc.push(channel);
            break;
          case 'bundle':
            if (acc.filter((el) => el.isBundle).length < RECOMMENDED_BUNDLE) {
              acc.push(channel);
            }

            break;
          default:
            break;
        }

        return acc;
      }, []);

    if (channels.length < 9) {
      const fillUpChannels = this.relevantChannels.filter(
        (channel) =>
          channel.isSingleAds &&
          !this.isOngoingPromotion(channel) &&
          !channels.find(({ id }) => id === channel.id)
      );
      const channelsToAdd = fillUpChannels.slice(0, 9 - channels.length);
      channels.push(...channelsToAdd);
    }

    return this.getSortedChannels(channels);
  }

  get unusedActivatedChannels() {
    return this.activatedChannels.filter((channel) => {
      return !this.isOngoingPromotion(channel);
    });
  }

  get notActivatedChannels() {
    return this.availableChannels.filter(({ isActivated }) => !isActivated);
  }

  get showShareLinksHistoryButton() {
    return this.shareLinksAndQrCodes.length > 0;
  }

  get showViewPurchaseHistoryButton() {
    return this.purchasedPromotions.length > 0;
  }

  get failedPromotions() {
    return this.currentSavedPromotions.filter(
      (promotion) =>
        !promotion.successfullyPublished && promotion.status !== 'pending'
    );
  }

  get failedPromotionNames() {
    let failedNamesArray = get(this, 'failedPromotions').map(
      (failedPromotion) => {
        return get(failedPromotion, 'channel.name');
      }
    );
    return failedNamesArray.join(', ');
  }

  get hasServiceSpecificErrors() {
    return this.failedPromotions.filter(
      (promotion) =>
        promotion.serviceErrors && promotion.serviceErrors.length > 0
    );
  }

  get availableInternalChannels() {
    const keyMap = [
      ['share_link', 'promotion/cards/internal/share-link'],
      ['qr_code', 'promotion/cards/internal/qr-code'],
    ];

    if (get(this, 'user.admin')) {
      keyMap.unshift(['lead_page', 'promotion/cards/internal/lead-page']);
    }

    return Object.fromEntries(keyMap);
  }

  isOngoingPromotion(channel) {
    return this.ongoingPromotions.find(({ id }) => id === channel.id);
  }

  @action toggleViewMore() {
    const hiddenElements = document.querySelectorAll(
      '[data-rest-ongoing-cards]'
    );
    hiddenElements.forEach((element) => {
      element.classList.add('animate-fadeOut');
    });
    if (!this.showAllOngoing) {
      this.showAllOngoing = true;
    } else {
      setTimeout(() => {
        this.showAllOngoing = false;
      }, DISAPPEAR_TIMEOUT);
      this.scrollToButton();
    }
  }

  @action scrollToButton() {
    const button = document.querySelector('[data-show-all-button]');

    setTimeout(() => {
      button.scrollIntoView({
        block: 'end',
      });
    }, DISAPPEAR_TIMEOUT + 1);
  }

  @action
  handleDelete(promotion) {
    this.ttAlert.confirm(
      this.intl.t('jobs.job.promote.delete.confirm_message'),
      () => {
        promotion.destroyRecord();
      },
      () => {},
      {
        title: this.intl.t('jobs.job.promote.delete_promotion'),
        confirmButtonText: this.intl.t(
          'jobs.job.promote.delete.confirm_deletion'
        ),
      }
    );
  }
}
