import Route from '@ember/routing/route';
import { set, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';

export default class JobsListRoute extends Route {
  @service store;

  queryParams = {
    status: { refreshModel: true },
    query: { refreshModel: true },
    page: { refreshModel: true },
    per_page: { refreshModel: true },
    department_id: { refreshModel: true },
    location_id: { refreshModel: true },
    region_id: { refreshModel: true },
    job_tag_id: { refreshModel: true },
    user_id: { refreshModel: true },
    sort: { refreshModel: true },
    remote_status_id: { refreshModel: true },
    internal: { refreshModel: true },
    custom_fields: { refreshModel: true },
    language_code: { refreshModel: true },
    current_user_jobs: { refreshModel: true },
  };

  setupController(controller, model) {
    super.setupController(controller, model);
    if (this.paramsFor(this.routeName).job_tag_id) {
      this.store
        .query('tag', { taggable_type: 'Job' })
        .then((tags) => set(controller, 'tags', tags));
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      setProperties(controller, {
        showBulk: false,
        selectAll: false,
        deselectedJobIds: [],
        selectedJobIds: [],
      });
    }
  }
}
