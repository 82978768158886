import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"relative flex h-full flex-col\" local-class=\"container\">\n  {{#if (eq @action \"show\")}}\n    <Toolbox::Meetings::ContentShow\n      @meetingEventId={{@meetingEventId}}\n      @close={{this.close}}\n      @openEdit={{this.openEdit}}\n      @onCancelEvent={{this.refetchSidebar}}\n    />\n  {{/if}}\n  {{#if (eq @action \"edit\")}}\n    <Toolbox::Meetings::ContentEdit\n      @meetingEventId={{@meetingEventId}}\n      @close={{this.close}}\n      @onCancelEvent={{this.refetchSidebar}}\n    />\n  {{/if}}\n  {{#if (eq @action \"new\")}}\n    <Toolbox::Meetings::ContentNew\n      @close={{this.close}}\n      @onSave={{this.refetchSidebar}}\n    />\n  {{/if}}\n</div>", {"contents":"<div class=\"relative flex h-full flex-col\" local-class=\"container\">\n  {{#if (eq @action \"show\")}}\n    <Toolbox::Meetings::ContentShow\n      @meetingEventId={{@meetingEventId}}\n      @close={{this.close}}\n      @openEdit={{this.openEdit}}\n      @onCancelEvent={{this.refetchSidebar}}\n    />\n  {{/if}}\n  {{#if (eq @action \"edit\")}}\n    <Toolbox::Meetings::ContentEdit\n      @meetingEventId={{@meetingEventId}}\n      @close={{this.close}}\n      @onCancelEvent={{this.refetchSidebar}}\n    />\n  {{/if}}\n  {{#if (eq @action \"new\")}}\n    <Toolbox::Meetings::ContentNew\n      @close={{this.close}}\n      @onSave={{this.refetchSidebar}}\n    />\n  {{/if}}\n</div>","moduleName":"teamtailor/components/toolbox/meetings/content.hbs","parseOptions":{"srcName":"teamtailor/components/toolbox/meetings/content.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ToolboxMeetingsContentComponent extends Component {
  @service router;

  @action
  close(_meetingId, meetingEventId) {
    if (meetingEventId) {
      return this.router.transitionTo({
        queryParams: {
          toolbox: [this.args.query, 'show', meetingEventId].join(','),
        },
      });
    }

    return this.router.transitionTo({
      queryParams: { toolbox: this.args.query },
    });
  }

  @action
  openEdit() {
    return this.router.transitionTo({
      queryParams: {
        toolbox: [this.args.query, 'edit', this.args.meetingEventId].join(','),
      },
    });
  }

  @action
  refetchSidebar() {
    this.args.meetingEventQuery.refetchTask.perform();
  }
}
