import LinkifyIt from 'linkify-it';

const linkfyIt = new LinkifyIt();

export default function linkify(text: string | null): string {
  if (!text) return '';

  const matches = linkfyIt.match(text);
  const result = [];
  let last: number;

  if (matches) {
    last = 0;
    matches.forEach(function (match) {
      if (last < match.index) {
        result.push(text.slice(last, match.index));
      }

      result.push('<a rel="noopener noreferrer" target="_blank" href="');
      result.push(match.url);
      result.push('">');
      result.push(match.text);
      result.push('</a>');
      last = match.lastIndex;
    });
    if (last < text.length) {
      result.push(text.slice(last));
    }

    return result.join('');
  }

  return text;
}
