import { get } from 'teamtailor/utils/get';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validateUniqueness(options) {
  return async (key, newValue, oldValue, changes, content) => {
    let objects = await get(content, `${options.on}.${options.property}`);
    return (
      Object.keys(changes).length === 0 ||
      !objects.findBy(key, newValue) ||
      buildMessage(key, { type: 'unique' })
    );
  };
}
