export default {
  "wrapper": "BlockEditorItem__wrapper_mise35",
  "wrapper--locked": "BlockEditorItem__wrapper--locked_mise35",
  "handle": "BlockEditorItem__handle_mise35",
  "actionBtn": "BlockEditorItem__actionBtn_mise35",
  "addWrapper": "BlockEditorItem__addWrapper_mise35",
  "text": "BlockEditorItem__text_mise35",
  "iconWrapper": "BlockEditorItem__iconWrapper_mise35",
  "globe": "BlockEditorItem__globe_mise35",
  "content": "BlockEditorItem__content_mise35",
  "icon": "BlockEditorItem__icon_mise35",
  "previewIsHovered": "BlockEditorItem__previewIsHovered_mise35",
  "wrapper--isFixedInTemplate": "BlockEditorItem__wrapper--isFixedInTemplate_mise35",
  "wrapper--unclickable": "BlockEditorItem__wrapper--unclickable_mise35",
  "wrapper--disabled": "BlockEditorItem__wrapper--disabled_mise35",
  "addBtn": "BlockEditorItem__addBtn_mise35",
  "link": "BlockEditorItem__link_mise35",
  "inputWrapper": "BlockEditorItem__inputWrapper_mise35",
  "input": "BlockEditorItem__input_mise35"
};
