import TriggerSerializer from 'teamtailor/serializers/trigger';

export default TriggerSerializer.extend({
  attrs: {
    stage: { serialize: 'ids' },
  },

  serialize() {
    let [commonJson, extras] = this.extractCommonJson(
      this._super(...arguments)
    );
    let { partner_activation_id, ...data } = extras;

    return Object.assign(commonJson, {
      data,
      partner_activation_id,
    });
  },
});
