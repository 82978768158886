import config from 'teamtailor/config/environment';

let scriptTagPromise: null | Promise<void> = null;

export const initZiggeoScriptTag: (locale: string) => Promise<void> = (
  locale: string
) => {
  if (!scriptTagPromise) {
    window.ZiggeoDefer = true;

    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://assets.ziggeo.com/v2-r43/ziggeo.js';

    document.body.append(scriptTag);

    scriptTagPromise = new Promise<void>((resolve) => {
      scriptTag.addEventListener('load', () => {
        new ZiggeoApi.V2.Application({
          token: config.ziggeo.token,
          auth: true,
          webrtc_streaming_if_necessary: true,
          webrtc_on_mobile: true,
        });

        ZiggeoApi.V2.Locale.setLocale(locale);

        resolve();
      });
    });
  }

  return scriptTagPromise;
};
