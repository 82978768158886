import Service from 'ember-simple-auth/services/session';
import { inject as service } from '@ember/service';
import ENV from 'teamtailor/config/environment';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import { isEmpty } from '@ember/utils';

const LOGOUT_REFRESH_ROUTES = ['invitations'];

export default class SessionService extends Service {
  @service store;
  @service router;

  @computedLocalStorage() attemptedTransitionPath;

  handleAuthentication(routeAfterAuthentication) {
    const { attemptedTransitionPath } = this;
    if (!isEmpty(attemptedTransitionPath)) {
      this.attemptedTransitionPath = undefined;
      window.location.replace(attemptedTransitionPath);
    } else {
      super.handleAuthentication(routeAfterAuthentication);
    }
  }

  handleInvalidation(routeAfterInvalidation) {
    const { currentRouteName } = this.router;

    if (
      ENV.environment !== 'test' &&
      LOGOUT_REFRESH_ROUTES.includes(currentRouteName)
    ) {
      window.location.reload();
    } else {
      super.handleInvalidation(routeAfterInvalidation);
    }
  }
}
