import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class MarketplaceChannelsIndexChannelIndex extends Controller {
  @service declare router: RouterService;

  @action
  handleClose() {
    this.router.transitionTo('marketplace');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'marketplace.channels.index.channel.index': MarketplaceChannelsIndexChannelIndex;
  }
}
