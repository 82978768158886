import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Input\n  id={{this.inputId}}\n  @type=\"checkbox\"\n  name={{this.name}}\n  disabled={{@disabled}}\n  @checked={{this.checked}}\n  class=\"c-form-radio-button-input sr-only\"\n  {{on \"change\" this.handleChange}}\n/>\n\n<label for={{this.inputId}} class=\"c-form-radio-button-label\">\n  <span class=\"c-form-radio-button__check\">\n    <span class=\"c-form-radio-button__check-icon\"></span>\n  </span>\n\n  {{#if (has-block)}}\n    {{yield}}\n  {{else}}\n    {{this.label}}\n  {{/if}}\n</label>", {"contents":"<Input\n  id={{this.inputId}}\n  @type=\"checkbox\"\n  name={{this.name}}\n  disabled={{@disabled}}\n  @checked={{this.checked}}\n  class=\"c-form-radio-button-input sr-only\"\n  {{on \"change\" this.handleChange}}\n/>\n\n<label for={{this.inputId}} class=\"c-form-radio-button-label\">\n  <span class=\"c-form-radio-button__check\">\n    <span class=\"c-form-radio-button__check-icon\"></span>\n  </span>\n\n  {{#if (has-block)}}\n    {{yield}}\n  {{else}}\n    {{this.label}}\n  {{/if}}\n</label>","moduleName":"teamtailor/components/form-radio-button.hbs","parseOptions":{"srcName":"teamtailor/components/form-radio-button.hbs"}});
import Component from '@ember/component';
import { get, set, computed, action } from '@ember/object';
import { equal } from '@ember/object/computed';

export default Component.extend({
  classNames: ['c-form-radio-button'],
  classNameBindings: [
    'isDarkTheme:c-form-radio-button--dark',
    'isCheckbox:c-form-radio-button--checkbox',
    'isSecondary:c-form-radio-button--secondary',
    'noMargin:no-margin',
  ],

  noMargin: false,

  isDarkTheme: equal('theme', 'dark'),

  isSecondary: equal('appearance', 'secondary'),

  checked: computed('groupValue', 'value', {
    get() {
      return get(this, 'groupValue') === get(this, 'value');
    },

    set(key, value) {
      return value;
    },
  }),

  inputId: computed('elementId', function () {
    return `${get(this, 'elementId')}-input`;
  }),

  handleChange: action(function (event) {
    set(this, 'groupValue', get(this, 'value'));
    event.target.checked = true;
    if (get(this, 'onChange')) {
      get(this, 'onChange')(get(this, 'value'));
    }
  }),
});
