import Store from '@ember-data/store';
import { set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import SettingsRequisitionsIndexEditController from 'teamtailor/controllers/settings/requisitions/index/edit';
import { RequisitionFlowModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';

export default class SettingsRequisitionsIndexEditRoute extends Route {
  @service declare store: Store;

  model(params: { requisition_id: string }) {
    const models = this.modelFor('settings.requisitions.index');

    return models.findBy('id', params.requisition_id) as RequisitionFlowModel;
  }

  afterModel(model: RequisitionFlowModel) {
    // Make sure we have the users in the steps
    // Workaround since we don't picked users in the backend to work with
    get(model, 'steps').forEach(async (step) => {
      const users = await get(step, 'users');
      set(step, 'pickedUsers', users.toArray());
    });
  }

  async resetController(
    controller: SettingsRequisitionsIndexEditController,
    isExiting: boolean
  ) {
    if (isExiting && !get(controller.model, 'isDeleted')) {
      const steps = await get(controller.model, 'steps');

      steps.toArray().forEach((step) => {
        step.pickedUsers = [];
        step.rollbackAttributes();
      });

      controller.model.rollbackAttributes();
    }
  }
}
