import TriggerModel from 'teamtailor/models/trigger';
import StageModel from 'teamtailor/models/stage';
import { belongsTo, AsyncBelongsTo, attr } from '@ember-data/model';
import { get } from 'teamtailor/utils/get';

export default class TriggerPredictionModel extends TriggerModel<'prediction'> {
  @belongsTo('stage', {
    inverse: 'proceedStageTriggerPredictions',
  })
  declare proceedStage: AsyncBelongsTo<StageModel>;

  @attr('number', { defaultValue: 0 }) declare delayJobFor: number;

  get proceedStageId() {
    return get(this.proceedStage, 'id');
  }

  get valid() {
    return !!this.proceedStageId;
  }

  get description() {
    return this.intl.t('models.trigger.common.move_to_stage', {
      stageName: get(get(this, 'proceedStage'), 'name') || 'N/A',
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'trigger/prediction': TriggerPredictionModel;
  }
}
