import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"tt-form-group\">\n  <Core::RadioGroup\n    @legend={{t \"content.editor.section.gallery.style\"}}\n    @options={{this.layoutStyleOptions}}\n    @optionName=\"text\"\n    @onChange={{this.setLayout}}\n    @checked={{find-by \"value\" this.section.layout this.layoutStyleOptions}}\n  />\n</div>", {"contents":"<div class=\"tt-form-group\">\n  <Core::RadioGroup\n    @legend={{t \"content.editor.section.gallery.style\"}}\n    @options={{this.layoutStyleOptions}}\n    @optionName=\"text\"\n    @onChange={{this.setLayout}}\n    @checked={{find-by \"value\" this.section.layout this.layoutStyleOptions}}\n  />\n</div>","moduleName":"teamtailor/components/section-gallery-layout.hbs","parseOptions":{"srcName":"teamtailor/components/section-gallery-layout.hbs"}});
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import { SectionModel } from 'teamtailor/models';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Signature {
  Args: {
    model: SectionModel;
  };
}

type StyleOption = {
  text: string;
  value: string;
};

export default class SectionGalleryLayout extends Component<Signature> {
  @service declare intl: IntlService;

  get section() {
    return this.args.model;
  }

  get layoutStyleOptions() {
    return [
      {
        text: this.intl.t('content.editor.section.gallery.collage'),
        value: 'collage',
      },
      {
        text: this.intl.t('content.editor.section.gallery.row'),
        value: 'row',
      },
      {
        text: this.intl.t('content.editor.section.gallery.carousel'),
        value: 'carousel',
      },
    ];
  }

  @action
  setLayout(option: StyleOption) {
    this.section.layout = option.value;
  }
}
