import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Modal @isOpen={{@isOpen}} @onClose={{@onClose}} @size=\"small\" as |modal|>\n  <modal.Header\n    @text={{t \"candidates.candidate.bulk_actions.restrict_access\"}}\n  />\n  <modal.Main as |main|>\n    <main.Section>\n      <p class=\"mb-0\">\n        {{t\n          \"candidates.candidate.bulk_actions.restrict_access_hint\"\n          href=\"https://support.teamtailor.com/en/articles/5772858-restricted-candidates\"\n          htmlSafe=true\n        }}\n      </p>\n    </main.Section>\n  </modal.Main>\n  <modal.Footer>\n    <StatusButton\n      @action={{this.restrict}}\n      @text={{t \"candidates.candidate.bulk_actions.restrict_button\"}}\n    />\n  </modal.Footer>\n</Core::Modal>", {"contents":"<Core::Modal @isOpen={{@isOpen}} @onClose={{@onClose}} @size=\"small\" as |modal|>\n  <modal.Header\n    @text={{t \"candidates.candidate.bulk_actions.restrict_access\"}}\n  />\n  <modal.Main as |main|>\n    <main.Section>\n      <p class=\"mb-0\">\n        {{t\n          \"candidates.candidate.bulk_actions.restrict_access_hint\"\n          href=\"https://support.teamtailor.com/en/articles/5772858-restricted-candidates\"\n          htmlSafe=true\n        }}\n      </p>\n    </main.Section>\n  </modal.Main>\n  <modal.Footer>\n    <StatusButton\n      @action={{this.restrict}}\n      @text={{t \"candidates.candidate.bulk_actions.restrict_button\"}}\n    />\n  </modal.Footer>\n</Core::Modal>","moduleName":"teamtailor/components/bulk-actions/candidates/restrict.hbs","parseOptions":{"srcName":"teamtailor/components/bulk-actions/candidates/restrict.hbs"}});
import BaseComponent from './base';
import { action } from '@ember/object';

export default class RestrictComponent extends BaseComponent {
  @action
  restrict() {
    return this.postAction('bulk_restrict', this.postData()).then(() => {
      this.args.onClose();
    });
  }
}
