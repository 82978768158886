import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import FlipperService from 'teamtailor/services/flipper';

export default class BlockAdjustmentsService extends Service {
  @service declare flipper: FlipperService;

  closeAllEvent = 'adjustments-close-all';

  @tracked editingAttribute?: string | null;

  get isEditing() {
    return this.editingAttribute;
  }

  @action
  isDisabled(attribute: string) {
    return this.isEditing && this.editingAttribute !== attribute;
  }

  closeAll() {
    document.dispatchEvent(new Event(this.closeAllEvent));
  }

  canPreviewLayout() {
    return get(this.flipper, 'block_layout_preview');
  }
}

declare module '@ember/service' {
  interface Registry {
    'block-adjustments': BlockAdjustmentsService;
  }
}
