import Controller from '@ember/controller';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class EmployeeReferralController extends Controller {
  @service confetti;
  @service router;

  queryParams = ['jobId'];

  @tracked isOpen = true;
  @tracked resumeFilename = null;

  parentControllerName = 'employee';

  get referral() {
    return this.model;
  }

  get parentController() {
    const owner = getOwner(this);
    return owner.lookup(`controller:${this.parentControllerName}`);
  }

  get selectedJob() {
    const job = get(this.referral, 'job');
    return get(job, 'id') ? job : null;
  }

  @action
  handleModalClosed() {
    this.router.transitionTo(this.parentControllerName);
  }

  @action
  handleFileUploaded(temporaryFileUrl, filename) {
    this.referral.resume = temporaryFileUrl;
    this.resumeFilename = filename;
  }

  @action
  removeResume() {
    this.handleFileUploaded(null, null);
  }

  @action
  save() {
    this.parentController.addedReferral = true;

    return this.referral.save().then(() => {
      this.confetti.show({
        duration: 3000,
      });
      later(
        this,
        () => {
          this.router.transitionTo(this.parentControllerName);
        },
        500
      );
    });
  }
}
