import Controller from '@ember/controller';
import { action, setProperties } from '@ember/object';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';

export default class AnalyticsJobController extends Controller {
  @service analytics;

  get job() {
    return this.model.job;
  }

  @action
  handleUpdateTimeRange(startDate, endDate) {
    setProperties(this.analytics, {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    });
  }
}
