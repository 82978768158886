import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get, set, setProperties } from '@ember/object';
import { isPresent } from '@ember/utils';
import copyModel from 'teamtailor/utils/copy-model';
import { inject as service } from '@ember/service';
import recordRemover from 'teamtailor/utils/record-remover';

export default class NewJobOfferTemplateRoute extends ScrollToTopRoute {
  @service store;

  queryParams = {
    copiedJobOfferTemplateId: {
      refreshModel: true,
    },
  };

  async model(params) {
    if (isPresent(params.copiedJobOfferTemplateId)) {
      const jobOfferTemplate = await this.store.findRecord(
        'job-offer-template',
        params.copiedJobOfferTemplateId
      );

      const copiedJobOfferTemplate = copyModel({
        model: jobOfferTemplate,
        excludedRelationships: ['uploads'],
      });

      setProperties(copiedJobOfferTemplate, {
        name: `Copy of ${copiedJobOfferTemplate.name}`,
        uploads: jobOfferTemplate.uploads,
      });

      return copiedJobOfferTemplate;
    }

    return this.store.createRecord('job-offer-template');
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    set(controller, 'fields', model.formFields);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      let model = get(controller, 'model');
      if (get(model, 'isNew')) {
        model.destroyRecord();
      }

      set(controller, 'model', null);
      set(controller, 'fields', []);
      set(controller, 'copiedJobOfferTemplateId', null);

      recordRemover(this.store, 'form-question');
    }
  }
}
