export default {
  "header": "Sidebar__header_x3ksib",
  "headerTitle": "Sidebar__headerTitle_x3ksib",
  "headerIcon": "Sidebar__headerIcon_x3ksib",
  "filters": "Sidebar__filters_x3ksib",
  "isVisible": "Sidebar__isVisible_x3ksib",
  "filterList": "Sidebar__filterList_x3ksib",
  "filterBtn": "Sidebar__filterBtn_x3ksib",
  "search": "Sidebar__search_x3ksib",
  "searchLabel": "Sidebar__searchLabel_x3ksib",
  "searchInput": "Sidebar__searchInput_x3ksib",
  "active": "Sidebar__active_x3ksib",
  "searchFilterDropdown": "Sidebar__searchFilterDropdown_x3ksib",
  "container": "Sidebar__container_x3ksib",
  "containerScroll": "Sidebar__containerScroll_x3ksib",
  "badge": "Sidebar__badge_x3ksib",
  "loadingWrapper": "Sidebar__loadingWrapper_x3ksib"
};
