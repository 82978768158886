import { inject as service } from '@ember/service';
import { Promise } from 'rsvp';
import Base from 'ember-simple-auth/authenticators/base';

const SESSION_ENDPOINT = '/session';
const FROM_CODE_ENDPOINT = '/session/from_code';
const MFA_SSO_ENDPOINT = '/session/mfa_sso';
const TRUST_DEVICE_ENDPOINT = '/session/trust_device';
const IMPERSONATE_ENDPOINT = '/session/impersonate';
const DEV_LOGIN_AS_ENDPOINT = '/session/dev_login_as';

export default class Token extends Base {
  @service server;

  tokenPropertyName = 'token';

  restore(properties) {
    const propertiesObject = { ...properties };
    const isEmptyTokenObject =
      Object.keys(propertiesObject[this.tokenPropertyName]).length === 0;

    return new Promise((resolve, reject) => {
      if (isEmptyTokenObject) {
        return reject();
      } else {
        return resolve(properties);
      }
    });
  }

  authenticate(data) {
    let endPoint;
    if (data.code) {
      endPoint = data.impersonate ? IMPERSONATE_ENDPOINT : FROM_CODE_ENDPOINT;
    } else if (data.user_id) {
      endPoint = DEV_LOGIN_AS_ENDPOINT;
    } else if (data.totp && data.token) {
      endPoint = MFA_SSO_ENDPOINT;
    } else if (data.untrustedDevice) {
      endPoint = TRUST_DEVICE_ENDPOINT;
    } else {
      endPoint = SESSION_ENDPOINT;
    }

    return this.server.request(endPoint, 'POST', { data });
  }

  invalidate() {
    return this.server.request(SESSION_ENDPOINT, 'DELETE');
  }
}
