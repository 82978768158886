import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showIframe}}\n  <iframe\n    src={{this.url}}\n    local-class=\"iframe\"\n    title=\"Teamtailor admin authentication\"\n    {{! template-lint-disable no-bare-strings }}\n  ></iframe>\n{{/if}}", {"contents":"{{#if this.showIframe}}\n  <iframe\n    src={{this.url}}\n    local-class=\"iframe\"\n    title=\"Teamtailor admin authentication\"\n    {{! template-lint-disable no-bare-strings }}\n  ></iframe>\n{{/if}}","moduleName":"teamtailor/components/tt-admin-iframe.hbs","parseOptions":{"srcName":"teamtailor/components/tt-admin-iframe.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import ENV from 'teamtailor/config/environment';

const URL = `${ENV.httpOrHttps}://${ENV.RAILS_HOST}/auth/tt-admin`;

export default class TtAdminIframeComponent extends Component {
  @service TtAdmin;

  url = URL;

  get showIframe() {
    return this.TtAdmin.showIframe;
  }
}
