import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { htmlSafe } from '@ember/template';

export default class ToSentenceHelper extends Helper {
  @service declare intl: IntlService;

  compute([positional]: string[]) {
    if (!Array.isArray(positional) || positional.length === 0) {
      return '';
    } else if (positional.length === 1) {
      return positional[0];
    } else {
      const arrayCopy = [...positional];

      const andText = this.intl.t('common.and');
      const last = arrayCopy.pop();

      const sentence = `${arrayCopy.join(', ')} ${andText} ${last}`;

      return htmlSafe(sentence);
    }
  }
}
