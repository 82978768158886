import PermissionsRoute from 'teamtailor/routes/permissions';
import { inject as service } from '@ember/service';

export default class IndexRoute extends PermissionsRoute {
  @service infinity;

  queryParams = {
    sortDirection: { refreshModel: true },
    sortColumn: { refreshModel: true },
    status: { refreshModel: true },
    query: { refreshModel: true },
  };

  noAccessEntityNameKey = 'nurture_campaigns.title';
  requiredPermissions = {
    or: ['nurture_campaign/create', 'nurture_campaign/update'],
  };

  model({ sortColumn, sortDirection, status, query }) {
    return this.infinity.model('nurture-campaign', {
      startingPage: 1,
      perPage: 15,
      sort_column: sortColumn,
      sort_direction: sortDirection,
      status,
      query,
    });
  }
}
