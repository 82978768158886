export const DEFAULT_POPOVER_DELAY = 250;
export const HEIGHT_DIFF_LIMIT_BETWEEN_CONTENT = 100;
export const CURSOR_OUT_OF_BOUND_DELAY = 1500;

export const POPPER_OPTIONS = Object.freeze({
  modifiers: {
    preventOverflow: {
      escapeWithReference: false,
      boundariesElement: 'viewport',
    },
  },
});
