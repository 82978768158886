import RequisitionModel from 'teamtailor/models/requisition';

export const LOADING_COLUMNS = [
  {
    width: '2xl',
  },
  {
    width: 's',
  },
  {
    width: 'm',
  },
  {
    width: 'm',
  },
  {
    width: 's',
  },
  {
    width: 'xl',
  },
  {
    width: 'xl',
  },
];

type SearchParams = {
  [key: string]: unknown;
};

export type RequisitionsBulkArgs = {
  onClose: () => void;
  handleBulkChange: (data: unknown, action: string) => void;
  selectedRequisitions: RequisitionModel[];
  selectedCount: number;
  searchParams: SearchParams;
  deselectedIds: string[];
  selectAll: boolean;
};
