import Store from '@ember-data/store';
import { set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import SettingsJobOfferApprovalFlowsEditController from 'teamtailor/controllers/settings/job-offer-approval-flows/edit';
import { JobOfferApprovalFlowModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';

export default class SettingsJobOfferApprovalFlowsEditRoute extends Route {
  @service declare store: Store;

  model(params: { job_offer_approval_flow_id: string }) {
    const parent = this.modelFor('settings.job-offer-approval-flows');

    return parent.findBy(
      'id',
      params.job_offer_approval_flow_id
    ) as JobOfferApprovalFlowModel;
  }

  afterModel(model: JobOfferApprovalFlowModel) {
    // Make sure we have the users in the steps
    // Workaround since we don't picked users in the backend to work with
    get(model, 'steps').forEach(async (step) => {
      const users = await get(step, 'users');
      set(step, 'pickedUsers', users.toArray());
    });
  }

  async resetController(
    controller: SettingsJobOfferApprovalFlowsEditController,
    isExiting: boolean
  ) {
    if (isExiting && !get(controller.model, 'isDeleted')) {
      const steps = await get(controller.model, 'steps');

      steps.toArray().forEach((step) => {
        step.pickedUsers = [];
        step.rollbackAttributes();
      });

      controller.model.rollbackAttributes();
    }
  }
}
