import Controller from '@ember/controller';
import { set, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import applyDefaultPickedQuestionValues from 'teamtailor/utils/apply-default-picked-question-values';

const REQUIRED_PROPS = ['connectWelcomeMessage'];

export default class extends Controller {
  @service flipper;
  @service current;

  @tracked selectedTabIndex = 0;

  requiredProps = REQUIRED_PROPS;

  get tabs() {
    return this.careerSites.toArray().sort((a, b) => {
      if (a.isDefault !== b.isDefault) {
        return a.isDefault ? -1 : 1;
      }

      return a.translatedLanguage.localeCompare(b.translatedLanguage);
    });
  }

  get careerSites() {
    return this.model;
  }

  get company() {
    return this.current.company;
  }

  get careerSiteData() {
    if (this.company.hasFeature('career_site_languages')) {
      return this.tabs[this.selectedTabIndex];
    }

    return this.tabs.find((c) => c.isDefault);
  }

  get nonDeletedPickedQuestions() {
    return this.current.company.pickedQuestions.filterBy('isDeleted', false);
  }

  @action
  handleTab(tab) {
    const { index } = tab;
    this.selectedTabIndex = index;
  }

  @action
  pickQuestion(question) {
    const { pickedQuestions } = this.company;
    pickedQuestions.forEach((question, index) =>
      set(question, 'rowOrderPosition', index)
    );

    this.company.pickedQuestions.createRecord(
      applyDefaultPickedQuestionValues({
        question,
        rowOrderPosition: pickedQuestions.length,
      })
    );
  }

  @action
  save() {
    const promise = Promise.all([
      this.company.save(),
      this.careerSites.forEach((career) => {
        if (career.hasDirtyAttributes) {
          career.save();
        }
      }),
    ]);
    promise.then(([company]) => {
      company.pickedQuestions
        .filterBy('isNew', true)
        .forEach((item) => item.unloadRecord());
    });
    return promise;
  }

  @action
  removeQuestion(question) {
    question.deleteRecord();
  }
}
