import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, get, set } from '@ember/object';
import downloadFile from 'teamtailor/utils/download-file';
import moment from 'moment-timezone';

export default class extends Controller {
  @service pusher;
  @service user;

  @tracked dateStart = '';
  @tracked dateEnd = '';
  @tracked loadingCsv = false;

  get dateToday() {
    return new Date();
  }

  get canSave() {
    const noneChecked =
      !this.model.race && !this.model.gender && !this.model.veteranStatus;
    return this.model.hasDirtyAttributes && !noneChecked;
  }

  bindChannels() {
    get(this, 'pusher')
      .channel(get(this, 'user.notificationsChannel'))
      .then((channel) => {
        set(this, 'channel', channel);
        channel.bind('eeo-report-exported', (data) => {
          downloadFile(data.url);
          this.loadingCsv = false;
        });
      });
  }

  unbindChannels() {
    let channel = get(this, 'channel');
    if (channel) {
      channel.unbind('eeo-report-exported');
    }
  }

  @action
  toggleFeature() {
    set(this, 'model.enabled', !this.model.enabled);
    this.saveChanges();
  }

  @action
  onCheckQuestionType(type) {
    set(this, `model.${type}`, !get(this, `model.${type}`));
  }

  @action
  saveChanges() {
    get(this, 'model').save();
  }

  @action
  exportCsv() {
    set(this, 'loadingCsv', true);
    get(this, 'model').generateCsv({
      date_start: moment(this.dateStart).format('YYYY-MM-DD'),
      date_end: moment(this.dateEnd).format('YYYY-MM-DD'),
    });
  }
}
