import { action } from '@ember/object';
import CannedResponseBaseController from './base';
import { tracked } from '@glimmer/tracking';

export default class EditController extends CannedResponseBaseController {
  @tracked confirmDialogOpen = false;

  get isDefaultTemplate() {
    return (
      this.model.defaultReply ||
      this.model.welcomeToConnect ||
      this.model.reject ||
      this.model.externalRecruiterInvite
    );
  }

  removeCannedResponse() {
    this.model.destroyRecord().then(() => {
      this.router.transitionTo('settings.canned_responses');
    });
  }

  @action
  handleCannedResponseDelete() {
    this.hideConfirmDialog();
    this.removeCannedResponse();
  }

  @action
  hideConfirmDialog() {
    this.confirmDialogOpen = false;
  }
}
