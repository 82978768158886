import Controller from '@ember/controller';
import { action } from '@ember/object';
import { JsonValue } from 'teamtailor/utils/json-value';
import { tracked } from '@glimmer/tracking';
import RouterService from '@ember/routing/router-service';
import FlipperService from 'teamtailor/services/flipper';
import Current from 'teamtailor/services/current';
import { inject as service } from '@ember/service';
import { TabName } from 'teamtailor/utils/candidate-modal/tabs';

export default class CandidateModalController extends Controller {
  @service declare router: RouterService;
  @service declare flipper: FlipperService;
  @service declare current: Current;

  @tracked tab: TabName = 'activity-feed';
  @tracked message: string | null = null;
  @tracked jobApplicationId: string | null = null;

  queryParams = [
    'tab',
    'message',
    'jobApplicationId',
    { 'interviewFeedQuery.jsonString': 'interviewFeedQuery' },
  ];

  interviewFeedQuery = new JsonValue();
  backRoute: string | null = null;

  @action
  handleTabChange(tab: TabName) {
    this.tab = tab;
    if (this.message) {
      this.message = null;
    }
  }

  @action
  handleModalClose() {
    if (this.backRoute) {
      this.router.transitionTo(this.backRoute);
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'candidate-modal': CandidateModalController;
  }
}
