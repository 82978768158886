import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Modal @isOpen={{true}} @onClose={{@onClose}} as |modal|>\n  <modal.Header @text={{t \"modals.reject_reason.add_new_reject_reason\"}} />\n  <modal.Main>\n    <FormInput\n      @label={{t \"modals.reject_reason.title\"}}\n      @autofocus={{true}}\n      @value={{@rejectReason.reason}}\n      @errors={{@rejectReason.errors.reason}}\n      @onEnter={{this.createRejectReason}}\n    />\n  </modal.Main>\n  <modal.Footer>\n    <StatusButton\n      @action={{this.createRejectReason}}\n      @text={{t \"modals.reject_reason.add_reject_reason\"}}\n    />\n  </modal.Footer>\n</Core::Modal>", {"contents":"<Core::Modal @isOpen={{true}} @onClose={{@onClose}} as |modal|>\n  <modal.Header @text={{t \"modals.reject_reason.add_new_reject_reason\"}} />\n  <modal.Main>\n    <FormInput\n      @label={{t \"modals.reject_reason.title\"}}\n      @autofocus={{true}}\n      @value={{@rejectReason.reason}}\n      @errors={{@rejectReason.errors.reason}}\n      @onEnter={{this.createRejectReason}}\n    />\n  </modal.Main>\n  <modal.Footer>\n    <StatusButton\n      @action={{this.createRejectReason}}\n      @text={{t \"modals.reject_reason.add_reject_reason\"}}\n    />\n  </modal.Footer>\n</Core::Modal>","moduleName":"teamtailor/components/modals/reject-reason.hbs","parseOptions":{"srcName":"teamtailor/components/modals/reject-reason.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { next } from '@ember/runloop';

export default class ModalsRejectReason extends Component {
  @action async createRejectReason() {
    await this.args.rejectReason.save();
    next(() => {
      this.args.onClose();
    });
  }
}
