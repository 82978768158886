import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { CompanyModel } from '.';
import Server from 'teamtailor/services/server';
import { inject as service } from '@ember/service';

export default class LinkedinSettingModel extends Model {
  @service declare server: Server;

  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @attr('raw') declare syncErrors: Record<string, unknown>;
  @attr('raw') declare integrationSetup: Record<string, unknown>;
  @attr('boolean') declare rscEnabled: boolean;
  @attr('boolean') declare syncAsPrivate: boolean;
  @attr('boolean') declare disableInmailSync: boolean;
  @attr('date') declare lastSyncAt: Date;
  @attr('string') declare historicalSyncStatus: string;
  @attr('string') declare disableReason: string;

  historicalSync() {
    return this.server.memberAction(this, { action: 'sync_all' });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'linkedin-setting': LinkedinSettingModel;
  }
}
