import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @logotype}}\n  <img\n    src={{image-url @logotype \"channel_logotype\"}}\n    alt={{t \"components.trigger_icon.logotype\"}}\n    class=\"w-full\"\n    ...attributes\n  />\n{{else}}\n  <Icon\n    @icon={{this.icon}}\n    @style={{this.style}}\n    @size={{this.iconSize}}\n    ...attributes\n  />\n{{/if}}", {"contents":"{{#if @logotype}}\n  <img\n    src={{image-url @logotype \"channel_logotype\"}}\n    alt={{t \"components.trigger_icon.logotype\"}}\n    class=\"w-full\"\n    ...attributes\n  />\n{{else}}\n  <Icon\n    @icon={{this.icon}}\n    @style={{this.style}}\n    @size={{this.iconSize}}\n    ...attributes\n  />\n{{/if}}","moduleName":"teamtailor/components/trigger-icon.hbs","parseOptions":{"srcName":"teamtailor/components/trigger-icon.hbs"}});
import Component from '@glimmer/component';

export default class TriggerIconComponent extends Component {
  get iconSize() {
    return this.args.iconSize || '24';
  }

  get icon() {
    switch (this.args.type) {
      case 'message':
        return 'envelope';
      case 'note':
        return 'note-sticky';
      case 'nps':
        return 'chart-area';
      case 'tag':
        return 'tag';
      case 'share':
        return 'share-nodes';
      case 'webhook':
        return 'robot';
      case 'smart-move':
        return 'lightbulb';
      case 'smart-schedule':
        return 'calendar';
      case 'todo':
        return 'square-check';
      case 'prediction':
        return 'robot';
      case 'survey':
        return 'ballot-check';
      case 'ask-for-feedback':
        return 'message';
      case 'restrict':
        return 'user-secret';
      case 'nurture':
        return 'message-heart';
      case 'hire-quality-survey':
        return 'star-user';
      default:
        return '';
    }
  }

  get style() {
    switch (this.args.type) {
      case 'nurture':
      case 'smart-schedule':
      case 'hire-quality-survey':
        return 'kit';
      default:
        return 'regular';
    }
  }
}
