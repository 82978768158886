import { get } from 'teamtailor/utils/get';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import SettingsLocationEditController from 'teamtailor/controllers/settings/departments/edit';
import Current from 'teamtailor/services/current';

export default class SettingsDepartmentsEditRoute extends ScrollToTopRoute {
  @service declare store: Store;
  @service declare current: Current;

  model(params: { department_id: string }) {
    return this.store.findRecord('department', params.department_id);
  }

  resetController(
    controller: SettingsLocationEditController,
    isExiting: boolean
  ) {
    if (isExiting && !get(controller.model, 'isDeleted')) {
      controller.model.rollbackAttributes();
      get(controller.model, 'pickedQuestions').invoke('rollbackAttributes');

      if (this.current.company.hasFeature('career_site_languages')) {
        get(controller.model, 'translations').invoke('rollbackAttributes');
      }

      // The page publicatioon is being updated in a background job
      // so we need to reload the relation to get the new name.
      // Hopefully the job is done by now
      controller.model.belongsTo('pagePublication').reload();
    }
  }
}
