import Model, { belongsTo, attr } from '@ember-data/model';
import SearchModel from 'teamtailor/models/search';

export default class SearchResultModel extends Model {
  @belongsTo('search', { async: false }) declare search: SearchModel;

  @attr('number') declare draftPageId: number;
  @attr('number') declare resultId: number;
  @attr('string') declare candidateAvatarId: string;
  @attr('string') declare candidateColor: string;
  @attr('string') declare candidateInitials: string;
  @attr('string') declare careersiteUrl: string;
  @attr('string') declare departmentName: string;
  @attr('string') declare displayName: string;
  @attr('string') declare pageType: string;
  @attr('string') declare role: string;
  @attr('string') declare status: string;
  @attr('string') declare type: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'search-result': SearchResultModel;
  }
}
