import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

export default class RequisitionsController extends Controller {
  @service router;

  get currentRoute() {
    return this.router.currentRouteName.replace('settings.requisitions.', '');
  }
}
