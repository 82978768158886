import { themeColors } from '@teamtailor/design-tokens';
const DECORATIVE_COLORS = Object.keys(themeColors.background.decorative);

export function bgColor(color: string, colorVariant?: string) {
  return colorArrayToString('bg', color, colorVariant);
}

export function textColor(color: string, colorVariant?: string) {
  return colorArrayToString('text', color, colorVariant);
}

function colorArrayToString(
  prefix: string,
  color: string,
  colorVariant?: string
) {
  const decorative = DECORATIVE_COLORS.includes(color);

  return [prefix, decorative ? 'decorative' : null, color, colorVariant]
    .compact()
    .join('-');
}
