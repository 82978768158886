import Controller from '@ember/controller';
import { task, timeout } from 'ember-concurrency';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default class MarketplacePartnersIndexPartnerActivateController extends Controller {
  @service intl;
  @service store;
  @service router;
  @service current;
  @service flashMessages;

  @action
  handleClose() {
    this.router.transitionTo('marketplace');
  }

  createPartnerActivation = task(async () => {
    await timeout(2000);
    const partner = this.store.peekRecord('partner', this.model.id);
    const partnerActivation = get(
      this.current.company,
      'partnerActivations'
    ).createRecord({
      partner,
    });
    try {
      const savedPartnerActivation = await partnerActivation.save();
      this.redirectAfterSave(partner, savedPartnerActivation);
    } catch (error) {
      const message =
        error.errors[0].status === '401'
          ? 'unauthorized'
          : 'something_went_wrong';
      this.flashMessages.error(
        this.intl.t(
          `settings.integrations.partner_activations.errors.${message}`
        )
      );
      throw new Error(error);
    }
  });

  redirectAfterSave(partner, partnerActivation) {
    if (partner?.activationType === 'customer') {
      this.router.transitionTo(
        `settings.integrations.partner-activation`,
        partnerActivation
      );
    }
  }

  @action
  goBack() {
    this.router.transitionTo('marketplace');
  }
}
