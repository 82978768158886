import { FilterOption } from 'teamtailor/components/fancy-filters';
import BaseFilter from './base';
import { tracked } from '@glimmer/tracking';
export default class ContainerFilter
  extends BaseFilter
  implements FilterOption
{
  subFilters: FilterOption[] = [];
  subFiltersAllLoaded = false;
  query?: string;

  @tracked isLoadingSubFilters = false;
  isContainer = true;

  asyncLoadCallback?: (
    container: FilterOption,
    page: number,
    query: string
  ) => Promise<void>;

  constructor(
    name: string,
    subFilters: FilterOption[],
    asyncLoadCallback?: (
      container: FilterOption,
      page: number,
      query: string
    ) => Promise<void>
  ) {
    super();
    this.name = name;
    this.type = 'container';
    this.query = '';
    this.subFilters.pushObjects(subFilters);
    this.asyncLoadCallback = asyncLoadCallback;
  }

  loadSubfilters = async (page = 1, query = ''): Promise<void> => {
    if (!this.asyncLoadCallback || this.isLoadingSubFilters) {
      return;
    }

    this.isLoadingSubFilters = true;

    if (this.query !== query) {
      this.query = query;
      this.clearFilters();
    }

    await this.asyncLoadCallback(this, page, query);

    this.isLoadingSubFilters = false;
  };

  addFilters(filters: FilterOption[]): void {
    const filtersToAdd: FilterOption[] = filters.filter(
      (filter: FilterOption) => this.subfilterMissing(filter)
    );

    if (filtersToAdd.length > 0) {
      this.subFilters.pushObjects(filtersToAdd);
      filtersToAdd.forEach((filter: FilterOption) => (filter.parent = this));
    }
  }

  subfilterMissing(filter: FilterOption): boolean {
    return !this.subFilters.find(
      (subFilter: FilterOption) =>
        JSON.stringify(subFilter.nested) === JSON.stringify(filter.nested)
    );
  }

  clearFilters(): void {
    this.subFiltersAllLoaded = false;
    this.subFilters.clear();
  }
}
