/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { get, set, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { not } from '@ember/object/computed';
import { v1 as uuid } from 'ember-uuid';
import { A as a } from '@ember/array';

export default Mixin.create({
  attributeBindings: ['tabindex'],
  classNames: ['careersite-form', 'scrollbar-dark-theme'],

  router: service(),
  store: service(),
  iframePreview: service(),
  filthyContent: service(),

  tabindex: 0,

  isNewItem: not('model.id'),

  actions: {
    save() {
      const item = get(this, 'model');
      const section = get(this, 'section');
      const sectionPath = 'content.editor.section.index';

      if (get(this, 'isNewItem')) {
        set(item, 'id', uuid().split('-')[0]);

        if (!get(section, 'items.length')) {
          set(section, 'items', a());
        }

        get(section, 'items').pushObject(item);
      } else {
        get(section, 'items')
          .removeObject(
            get(section, 'items').findBy('id', get(this, 'model.id'))
          )
          .pushObject(item);
      }

      return section.save().then(
        () => {
          get(this, 'router').transitionTo(sectionPath);
        },
        () => {
          set(this, 'isAddClicked', false);
        }
      );
    },

    handleRemoveImage() {
      let section = get(this, 'section');

      set(this, 'model.pickedImage', null);

      if (get(this, 'isNewItem')) {
        setProperties(get(this, 'model'), {
          destroyImageId: get(this, 'model.imageId'),
          imageId: null,
        });
      } else {
        const item = get(section, 'items').findBy('id', get(this, 'model.id'));
        const imageId = get(
          get(section, 'items').findBy('id', get(this, 'model.id')),
          'imageId'
        );

        setProperties(get(this, 'model'), {
          destroyImageId: imageId,
          imageId: null,
        });

        setProperties(item, {
          destroyImageId: imageId,
          imageId: null,
        });
      }

      get(this, 'filthyContent').setFilthy(get(this, 'section.id'));
    },

    handlePickImage(image) {
      let section = get(this, 'section');
      let pickedImage = get(this, 'store').createRecord('picked-image', {
        section_id: section.id,
        image,
      });

      set(this, 'model.pickedImage', pickedImage);

      pickedImage.save().then((image) => {
        set(this, 'model.imageId', get(image, 'id'));

        if (!get(this, 'isNewItem')) {
          set(
            get(section, 'items').findBy('id', get(this, 'model.id')),
            'imageId',
            get(image, 'id')
          );
        }

        get(this, 'filthyContent').setFilthy(get(this, 'section.id'));
      });
    },

    handlePickVideo(video) {
      let section = get(this, 'section');
      let pickedVideo = get(this, 'store').createRecord('picked-video', {
        section,
        video,
      });

      set(this, 'model.pickedVideo', pickedVideo);

      pickedVideo.save().then((video) => {
        set(this, 'model.videoId', get(video, 'id'));

        if (!get(this, 'isNewItem')) {
          set(
            get(section, 'items').findBy('id', get(this, 'model.id')),
            'videoId',
            get(video, 'id')
          );
        }

        get(this, 'filthyContent').setFilthy(get(this, 'section.id'));
      });
    },

    handleRemoveVideo() {
      let section = get(this, 'section');

      set(this, 'model.pickedVideo', null);

      if (get(this, 'isNewItem')) {
        setProperties(get(this, 'model'), {
          destroyVideoId: get(this, 'model.videoId'),
          videoId: null,
        });
      } else {
        const item = get(section, 'items').findBy('id', get(this, 'model.id'));
        const videoId = get(
          get(section, 'items').findBy('id', get(this, 'model.id')),
          'videoId'
        );

        setProperties(get(this, 'model'), {
          destroyVideoId: videoId,
          videoId: null,
        });

        setProperties(item, {
          destroyVideoId: videoId,
          videoId: null,
        });
      }

      get(this, 'filthyContent').setFilthy(get(this, 'section.id'));
    },

    makeDirty(section) {
      get(this, 'filthyContent').setFilthy(get(section, 'id'));
    },
  },
});
