import Model, { attr } from '@ember-data/model';

export default class LatestReadJobCommentModel extends Model {
  @attr('date') declare latestReadCommentAt: Date | null;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'latest-read-job-comment': LatestReadJobCommentModel;
  }
}
