import Helper from '@ember/component/helper';
import { get, getProperties } from '@ember/object';
import { isArray } from '@ember/array';
import { isEmpty } from '@ember/utils';

export default class TranslatedResource extends Helper {
  compute([
    resources,
    languageCode,
    type,
    usePreloadedTranslations,
    preloadedTranslations,
    shouldFallbackFields = false, // implemented only for questions
  ]) {
    if (!languageCode || !resources || isEmpty(resources)) {
      return resources;
    }

    const mapResource = (resource) => {
      return transformResource(
        resource,
        languageCode,
        type,
        usePreloadedTranslations,
        preloadedTranslations,
        shouldFallbackFields
      );
    };

    if (isArray(resources)) {
      return resources.map((resource) => {
        if (resource.options && resource.groupName) {
          resource.options = resource.options.map((optionResource) => {
            return mapResource(optionResource);
          });
          return resource;
        }

        return mapResource(resource);
      });
    }

    return transformResource(
      resources,
      languageCode,
      type,
      false,
      null,
      shouldFallbackFields
    );
  }
}

function mapPreloadedTranslations(resource, type, preloadedTranslations) {
  if (!preloadedTranslations) {
    return null;
  }

  return preloadedTranslations.filter(
    (translatedResource) =>
      translatedResource.belongsTo(type).id() === resource.id
  );
}

function transformResource(
  resource,
  languageCode,
  type,
  usePreloadedTranslations,
  preloadedTranslations,
  shouldFallbackFields
) {
  const translations = usePreloadedTranslations
    ? mapPreloadedTranslations(resource, type, preloadedTranslations)
    : get(resource, 'translations');

  const translatedResource = translations?.findBy('languageCode', languageCode);
  if (translatedResource) {
    return formatResource(
      resource,
      translatedResource,
      type,
      shouldFallbackFields
    );
  }

  return resource;
}

function formatResource(
  resource,
  translatedResource,
  type,
  shouldFallbackFields
) {
  const values = {};
  if (translatedResource) {
    translatedResource.constructor.eachAttribute((key) => {
      values[key] = translatedResource[key];
    });
  }

  if (type === 'question') {
    let { unit, alternatives, title, description, ...otherAttributes } = values;
    if (shouldFallbackFields) {
      unit = unit?.length ? unit : get(resource, 'questionData').unit;
      title = title?.length ? title : get(resource, 'title');
      description = description?.length
        ? description
        : get(resource, 'description');
      alternatives = getAlternativesArrayForChoices(
        resource,
        translatedResource
      );
    }

    const questionData = get(resource, 'questionData');
    const { start_with, end_with } = questionData;

    return {
      ...resource,
      ...otherAttributes,
      title,
      description,
      unit,
      alternativesArrayForChoices: alternatives,
      titleWithTags: get(translatedResource, 'titleWithTags'),
      id: get(resource, 'id'),
      toHuman: get(resource, 'toHuman'),
      questionData: { unit, alternatives, start_with, end_with },
      icon: get(resource, 'icon'),
      type: get(resource, 'type'),
      simpleType: get(resource, 'simpleType'),
      addedByCopilot: get(resource, 'addedByCopilot'),
      createdByCopilot: get(resource, 'createdByCopilot'),
      isPrivate: get(resource, 'isPrivate'),
    };
  }

  if (type === 'canned-response') {
    const messageProperties = getProperties(
      resource,
      'name',
      'uploads',
      'questions',
      'pickedQuestions',
      'id',
      'tags',
      'nameWithTags'
    );
    return {
      ...messageProperties,
      ...values,
    };
  }

  if (type === 'interview-kit') {
    const interviewKitProperties = getProperties(
      resource,
      'name',
      'instructions'
    );

    return {
      ...interviewKitProperties,
      ...values,
    };
  }

  return resource;
}

function getAlternativesArrayForChoices(resource, translatedResource) {
  const originalAlternatives = get(resource, 'alternativesArrayForChoices');

  if (Array.isArray(originalAlternatives)) {
    return originalAlternatives.map((alternative) => {
      return getAlternativeTranslation(
        resource,
        translatedResource,
        alternative.id
      );
    });
  }

  return originalAlternatives;
}

function getAlternativeTranslation(resource, translatedResource, key) {
  const translatedAlternative = get(translatedResource, 'alternatives')?.find(
    (alternative) => alternative.id === key
  );

  const originalAlternatives = get(resource, 'alternativesArrayForChoices');
  const originalAlternative = originalAlternatives.find(
    (alternative) => alternative.id === key
  );

  const hasTranslation =
    translatedAlternative && translatedAlternative.title.length > 0;
  return hasTranslation ? translatedAlternative : originalAlternative;
}
