import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import CandidateModel from 'teamtailor/models/candidate';
import JobModel from 'teamtailor/models/job';
import JobApplicationModel from 'teamtailor/models/job-application';
import ReferenceFormModel from 'teamtailor/models/reference-form';

export default class ReferenceModel extends Model {
  @belongsTo('candidate') declare candidate: AsyncBelongsTo<CandidateModel>;
  @belongsTo('job') declare job: AsyncBelongsTo<JobModel>;
  @belongsTo('job-application')
  declare jobApplication: AsyncBelongsTo<JobApplicationModel>;

  @belongsTo('reference-form')
  declare referenceForm: AsyncBelongsTo<ReferenceFormModel>;

  @attr('string') declare email: string;
  @attr('string') declare linkedinProfile: string;
  @attr('string') declare name: string;
  @attr('string') declare phone: string;
  @attr('string') declare title: string;
  @attr('string') declare uuid: string;

  @attr('boolean', { allowNull: true }) declare endorsed: boolean | null;

  @attr('date') declare answeredAt: Date | null;
  @attr('date') declare feedbackRequestedAt: Date | null;
  @attr('date') declare fullFeedbackAt: Date | null;

  get nameOrEmail(): string {
    return this.name || this.email;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    reference: ReferenceModel;
  }
}
