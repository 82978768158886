import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n  <Core::Checkbox\n    @label={{@label}}\n    @checked={{this.checked}}\n    @onChange={{@onChange}}\n  />\n  {{#if @description}}\n    <span class=\"tt-form-help-text mb-10 block\">\n      {{@description}}\n    </span>\n  {{/if}}\n</div>", {"contents":"<div>\n  <Core::Checkbox\n    @label={{@label}}\n    @checked={{this.checked}}\n    @onChange={{@onChange}}\n  />\n  {{#if @description}}\n    <span class=\"tt-form-help-text mb-10 block\">\n      {{@description}}\n    </span>\n  {{/if}}\n</div>","moduleName":"teamtailor/components/partner/config-fields/checkbox.hbs","parseOptions":{"srcName":"teamtailor/components/partner/config-fields/checkbox.hbs"}});
import Component from '@glimmer/component';

export default class extends Component {
  get checked() {
    if (!this.args.value) return false;
    if (this.args.value === '0') return false;
    return true;
  }
}
