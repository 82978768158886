import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { CareerSiteModel, UserModel, PageModel } from 'teamtailor/models';

export default class SectionChangeModel extends Model {
  @attr('string') declare action: string;
  @attr('number') declare pageId: number;
  @attr('number') declare pagePublicationId: number;
  @attr('number') declare sectionId: number;
  @attr('number') declare designId: number;
  @attr('number') declare careerSiteHeaderId: number;
  @attr('string') declare sectionName: string;
  @attr('string') declare sectionCustomName: string;
  @attr('date') declare createdAt: string;
  @attr('date') declare updatedAt: string;
  @attr('boolean') declare hasPostContentChanges: boolean;
  @attr('boolean') declare hasPageSettingsChanges: boolean;
  @attr('boolean') declare isConnect: boolean;

  @belongsTo('page') declare page: AsyncBelongsTo<PageModel>;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;
  @belongsTo('career-site') declare careerSite: AsyncBelongsTo<CareerSiteModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'section-change': SectionChangeModel;
  }
}
