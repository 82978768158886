import Controller from '@ember/controller';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import IntlService from 'ember-intl/services/intl';
import PartnerModel from 'teamtailor/models/partner';
import FromRoute from 'teamtailor/routes/jobs/job/compare-candidates';
import { type ModelFrom } from 'teamtailor/utils/type-utils';
import JobModel from 'teamtailor/models/job';
import ToggleService from 'teamtailor/services/toggle-service';
import JobApplicationModel from 'teamtailor/models/job-application';
import JobDetail from 'teamtailor/models/job-detail';
import { Question } from 'teamtailor/constants/question';
import Current from 'teamtailor/services/current';

type Sort =
  | 'customField'
  | 'created'
  | 'rating'
  | 'name'
  | 'match'
  | 'scorecardPick'
  | 'partnerResult'
  | 'resumeSummary'
  | 'questionAnswer';
type CustomSort = (a: JobApplicationModel, b: JobApplicationModel) => number;
const NARROW = 224;
const WIDE = 448;

export default class JobsJobCompareCandidates extends Controller {
  declare model: ModelFrom<FromRoute>;
  @service declare intl: IntlService;
  @tracked isBulkSelectActive = false;
  @tracked job?: JobModel;
  @service toggleService!: ToggleService;
  @service declare current: Current;
  @tracked partners?: PartnerModel[];
  @tracked hiddenCandidates: JobApplicationModel[] = [];
  @tracked pinnedModels: JobApplicationModel[] = [];
  @tracked selectedJobApplications: JobApplicationModel[] = [];
  @tracked sort: Sort = 'created';
  @tracked sortModelId?: string;
  @tracked dir?: 'asc' | 'desc';
  @tracked customSort?: CustomSort;
  @tracked showingHiddenCandidates?: boolean = false;
  @tracked currentIdentifier?: string;
  @tracked revealedAnonymousJobApplications: JobApplicationModel[] = [];
  @tracked columnWidth = NARROW;
  @tracked hoveredJobApplication?: JobApplicationModel;

  get jobApplications(): JobApplicationModel[] {
    return this.model.rejectBy('isRejected');
  }

  get selectedCandidateIds(): number[] {
    return this.selectedJobApplications.map(
      (jobApplication) => jobApplication.candidateId
    );
  }

  get sortedModels() {
    const models = this.jobApplications;

    switch (this.sort) {
      case 'rating':
        return models.sortBy('candidate.averageRating').reverse();
      case 'name':
        return models.sortBy('candidate.nameOrEmail');
      case 'match':
        return models.sortBy('match').reverse();
      case 'resumeSummary':
        return models.sortBy('candidate.resumeSummary').reverse();
      case 'customField':
      case 'partnerResult':
      case 'scorecardPick':
      case 'questionAnswer':
        return models.toArray().sort(this.customSort);
      default:
        return models.sortBy('createdAt').reverse();
    }
  }

  get filterHiddenCandidates() {
    if (this.showingHiddenCandidates) {
      return this.sortedModels;
    } else {
      return this.sortedModels.filter(
        (model) => !this.hiddenCandidates.includes(model)
      );
    }
  }

  @action
  isVisible(identifier: string): boolean {
    return this.toggleService.isVisible(identifier);
  }

  get showQuestionAnswers(): boolean {
    const jobDetail = this.jobDetail as unknown as JobDetail;
    const pickedQuestions = get(
      jobDetail,
      'pickedQuestions' as keyof JobDetail
    ) as unknown[] as Question[];

    return pickedQuestions.length > 0;
  }

  get showPartnerResults(): boolean {
    const jobDetail = this.jobDetail as unknown as JobDetail;
    const partners = get(
      jobDetail,
      'partners' as keyof JobDetail
    ) as unknown[] as PartnerModel[];

    return partners.length > 0;
  }

  get showCustomFields(): boolean {
    return isPresent(this.current.company.visibleCandidateCustomFields);
  }

  get sortedJobApplications() {
    const withoutHidden = this.filterHiddenCandidates;

    const sorted = this.dir === 'asc' ? withoutHidden.reverse() : withoutHidden;

    const filteredModel = sorted.filter(
      (model) => !this.pinnedModels.includes(model)
    );

    const pinned = sorted.filter((model) => this.pinnedModels.includes(model));

    return [...pinned, ...filteredModel];
  }

  get pinnedCount() {
    return this.pinnedModels.length;
  }

  get hiddenCandidateIds() {
    return this.hiddenCandidates.mapBy('id');
  }

  get allSelectedCandidatesPinned() {
    return this.selectedJobApplications.every((jobApplication) => {
      return this.pinnedModels.includes(jobApplication);
    });
  }

  get pinnedCandidateExists() {
    return this.selectedJobApplications.some((jobApplication) => {
      return this.pinnedModels.includes(jobApplication);
    });
  }

  get allSelectedCandidatesHidden() {
    return this.selectedJobApplications.every((jobApplication) => {
      return this.hiddenCandidates.includes(jobApplication);
    });
  }

  get hiddenCandidateExists() {
    return this.selectedJobApplications.some((jobApplication) => {
      return this.hiddenCandidates.includes(jobApplication);
    });
  }

  get columnsAreWide(): boolean {
    return this.columnWidth === WIDE;
  }

  get anySelectedAnonymousCandidates() {
    return this.selectedStages.some((stage) => get(stage, 'anonymous'));
  }

  get selectedStages() {
    return this.selectedJobApplications.mapBy('stage');
  }

  get anyRevealedCandidates() {
    return this.revealedAnonymousJobApplications.length > 0;
  }

  get hasResumeSummaryFeature() {
    return this.current.company.hasCopilotFeature('generate_resume_summary');
  }

  @action
  sortIndicator(column: Sort, identifier?: string): string | null {
    if (column !== this.sort || identifier !== this.currentIdentifier)
      return null;

    return this.dir === 'asc' ? this.dir : 'desc';
  }

  @action
  sortBy(sort: Sort, customSort?: CustomSort, identifier?: string) {
    this.customSort = customSort;

    if (sort === this.sort) {
      this.dir = this.dir === 'asc' ? 'desc' : 'asc';
    } else {
      this.dir = undefined;
    }

    this.currentIdentifier = identifier;
    this.sort = sort;
  }

  @action
  toggleBulkSelect() {
    if (this.isBulkSelectActive) {
      this.selectedJobApplications.clear();
    }

    this.isBulkSelectActive = !this.isBulkSelectActive;
  }

  @action
  togglePinned(model: JobApplicationModel) {
    if (this.pinnedModels.includes(model)) {
      this.pinnedModels.removeObject(model);
    } else {
      this.pinnedModels.pushObject(model);
    }
  }

  @action
  hideSelectedCandidates() {
    const allHidden = this.allSelectedCandidatesHidden;
    this.selectedJobApplications.forEach((jobApplication) => {
      if (allHidden) {
        this.hiddenCandidates.removeObject(jobApplication);
      } else if (!this.hiddenCandidates.includes(jobApplication)) {
        this.hiddenCandidates.pushObject(jobApplication);
      }
    });
    if (!allHidden && !this.showingHiddenCandidates) {
      this.selectedJobApplications = [];
    }
  }

  @action
  pinSelectedCandidates() {
    const allPinned = this.allSelectedCandidatesPinned;
    this.selectedJobApplications.forEach((jobApplication) => {
      if (allPinned) {
        this.pinnedModels.removeObject(jobApplication);
      } else if (!this.pinnedModels.includes(jobApplication)) {
        this.pinnedModels.pushObject(jobApplication);
      }
    });
  }

  @action
  toggleAnonymousCandidates() {
    if (this.anyRevealedCandidates) {
      this.revealedAnonymousJobApplications.clear();
    } else {
      this.selectedJobApplications.forEach((jobApplication) => {
        if (!this.revealedAnonymousJobApplications.includes(jobApplication)) {
          this.revealedAnonymousJobApplications.pushObject(jobApplication);
        }
      });
    }
  }

  @action
  isRevealed(jobApplication: JobApplicationModel): boolean {
    return this.revealedAnonymousJobApplications.includes(jobApplication);
  }

  @action
  onSelectJobApplication(jobApplication: JobApplicationModel) {
    if (this.isBulkSelectActive) {
      if (this.selectedJobApplications.includes(jobApplication)) {
        this.selectedJobApplications.removeObject(jobApplication);
      } else {
        this.selectedJobApplications.pushObject(jobApplication);
      }
    }
  }

  @action
  offset(index: number): string {
    const candidateColumnsWidth = (index - 1) * this.columnWidth;
    const totalOffsetWidth = NARROW + candidateColumnsWidth;
    return `${totalOffsetWidth}px`;
  }

  @action toggleHidden(model: JobApplicationModel) {
    if (this.hiddenCandidates.includes(model)) {
      this.hiddenCandidates.removeObject(model);
      if (this.hiddenCandidates.length === 0) {
        this.showingHiddenCandidates = false;
      }
    } else {
      this.hiddenCandidates.pushObject(model);
    }
  }

  @action toggleShowingHiddenCandidates() {
    if (this.showingHiddenCandidates && this.selectedJobApplications.length) {
      this.hiddenCandidates.forEach((jobApplication) => {
        this.selectedJobApplications.removeObject(jobApplication);
      });
    }

    this.showingHiddenCandidates = !this.showingHiddenCandidates;
  }

  @action toggleColumnWidth() {
    this.columnWidth = this.columnWidth === NARROW ? WIDE : NARROW;
  }

  get jobDetail(): JobDetail {
    return get(
      this.jobApplications[0]?.job,
      'jobDetail'
    ) as unknown as JobDetail;
  }

  get scorecardLabel() {
    if (get(this.jobDetail, 'competenceBased' as keyof JobDetail)) {
      return this.intl.t('jobs.job.compare_candidates.job_match');
    } else {
      return this.intl.t('jobs.job.compare_candidates.scored_card');
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'jobs.job.compare-candidates': JobsJobCompareCandidates;
  }
}
