import Model, { belongsTo, attr, type AsyncBelongsTo } from '@ember-data/model';
import { CompanyModel, UserModel } from 'teamtailor/models';

export default class CookieSettingModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('user') declare cookieContactUser: AsyncBelongsTo<UserModel>;

  @attr('boolean', { defaultValue: true }) declare showCookieConsent: boolean;
  @attr('string', { defaultValue: 'opt_in_bar' }) declare variant: string;
  @attr('array') declare disabledTogglableCookies: string[];
  @attr('array') declare enabledTogglableCookies: string[];
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'cookie-setting': CookieSettingModel;
  }
}
