import { isDestroying, registerDestructor } from '@ember/destroyable';
import merge from 'deepmerge';
import Modifier from 'ember-modifier';
import { defaultOptions } from 'teamtailor/constants/highchart';
import regression from 'teamtailor/utils/highcharts-regression';

export default class DrawChartModifier extends Modifier {
  async modify(element, [chart]) {
    if (!chart) {
      return;
    }

    if (!this.highcharts) {
      await this.initializeHighcharts();
    }

    this.drawChart({ chart, element });

    if (!isDestroying(this)) {
      registerDestructor(this, this.destroyChart.bind(this));
    }
  }

  initializeHighcharts() {
    return Promise.all([
      import('highcharts').then((module) => module.default),
      import('highcharts/highcharts-more').then((module) => module.default),
    ]).then(([Highcharts, more]) => {
      more(Highcharts);
      regression(Highcharts);

      this.highcharts = Highcharts;
      this.highcharts.setOptions(defaultOptions);

      window
        .matchMedia('print')
        .addEventListener('change', this.reflow.bind(this));
      window.addEventListener('afterprint', this.reflow.bind(this));
    });
  }

  reflow() {
    this.chartInstance?.reflow();
  }

  drawChart({ chart, element }) {
    element.removeAttribute('data-render-state', 'settled');

    const chartOptions = merge(
      {
        chart: {
          events: {
            render: () => {
              element.setAttribute('data-render-state', 'settled');
            },
          },
        },
      },
      chart
    );

    this.chartInstance = this.highcharts.chart(element, chartOptions);
  }

  destroyChart() {
    this.chartInstance?.destroy();
    this.chartInstance = null;
    window.removeEventListener('afterprint', this.reflow);
    window.matchMedia('print').removeEventListener('change', this.reflow);
  }
}
