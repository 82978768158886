import Store from '@ember-data/store';
import Service, { inject as service } from '@ember/service';
import PromotionModel from 'teamtailor/models/promotion';

export default class PromotionRequestsService extends Service {
  @service declare store: Store;

  async refreshPromotion(promotionFromStore: PromotionModel) {
    const promotion = await this.store.findRecord(
      'promotion',
      promotionFromStore.id
    );

    return promotion.refresh();
  }
}

declare module '@ember/service' {
  interface Registry {
    'promotion-requests': PromotionRequestsService;
  }
}
