import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<VideoMeeting::Views::Grid::ParticipantWrapper\n  @participantCount={{@participantCount}}\n  {{did-update this.onUpdate @remoteParticipants}}\n  {{did-insert this.onInsert}}\n  ...attributes\n>\n  {{#each @remoteParticipants as |participant|}}\n    <VideoMeeting::RemoteParticipant\n      @participant={{participant}}\n      @style={{this.remoteParticipantStyle}}\n      {{! Chime }}\n      @isNewProvider={{@isNewProvider}}\n      @audioVideoFacade={{@audioVideoFacade}}\n      @tilesMap={{@tilesMap}}\n    />\n  {{/each}}\n</VideoMeeting::Views::Grid::ParticipantWrapper>", {"contents":"<VideoMeeting::Views::Grid::ParticipantWrapper\n  @participantCount={{@participantCount}}\n  {{did-update this.onUpdate @remoteParticipants}}\n  {{did-insert this.onInsert}}\n  ...attributes\n>\n  {{#each @remoteParticipants as |participant|}}\n    <VideoMeeting::RemoteParticipant\n      @participant={{participant}}\n      @style={{this.remoteParticipantStyle}}\n      {{! Chime }}\n      @isNewProvider={{@isNewProvider}}\n      @audioVideoFacade={{@audioVideoFacade}}\n      @tilesMap={{@tilesMap}}\n    />\n  {{/each}}\n</VideoMeeting::Views::Grid::ParticipantWrapper>","moduleName":"teamtailor/components/video-meeting/views/grid.hbs","parseOptions":{"srcName":"teamtailor/components/video-meeting/views/grid.hbs"}});
import { action } from '@ember/object';
import VideoMeetingViewsBase from 'teamtailor/components/video-meeting/views/base';
import { inject as service } from '@ember/service';
import ResizeObserverService from 'ember-resize-observer-service';

const GAP = 20;

export default class VideoMeetingViewsGrid extends VideoMeetingViewsBase {
  @service declare resizeObserver: ResizeObserverService;

  calculateGrid(element: HTMLElement): void {
    const { clientWidth, clientHeight } = element;
    let maxWidth = clientWidth - GAP;
    let maxHeight = clientHeight - GAP;

    const desktopTwoParticipants =
      this.participantCount === 2 && clientWidth >= 768;

    const moreThanTwoParticipants = this.participantCount > 2;

    if (desktopTwoParticipants || moreThanTwoParticipants) {
      maxWidth = clientWidth / 2 - GAP;
    }

    if (moreThanTwoParticipants) {
      maxHeight = clientHeight / 2 - GAP;
    }

    let height = (maxWidth / 16) * 9;
    let width = maxWidth;

    if (height > maxHeight) {
      height = maxHeight;
      width = (maxHeight / 9) * 16;
    }

    this.updateSizes(element, width, height);
  }

  updateSizes(element: HTMLElement, width: number, height: number): void {
    Array.from(element.children).forEach((child: Element) => {
      (child as HTMLElement).style.width = `${width}px`;
      (child as HTMLElement).style.height = `${height}px`;
    });
  }

  @action
  onInsert(element: HTMLElement): void {
    this.resizeObserver.observe(element, () => {
      this.calculateGrid(element);
    });

    this.calculateGrid(element);
  }

  @action
  onUpdate(element: HTMLElement): void {
    this.calculateGrid(element);
  }
}
