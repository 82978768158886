import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import InsightsReport from 'teamtailor/models/insights-report';
import CustomReport from 'teamtailor/models/custom-report';
import AnalyticsCustomReportsSnapshotsController from 'teamtailor/controllers/analytics/custom-reports/index';

export default class CompanyAnalyticsCustomReports extends Route {
  @service declare router: RouterService;
  @service declare store: Store;

  async model(): Promise<(CustomReport | InsightsReport)[]> {
    const reports = await this.store.findAll('insights-report');
    const snapshotReports = await this.store.findAll('custom-report');
    return [...reports.slice(), ...snapshotReports.slice()];
  }

  resetController(
    controller: AnalyticsCustomReportsSnapshotsController,
    isExiting: boolean
  ): void {
    if (isExiting) {
      controller.hideCreateReportModals();
    }
  }
}
