import Model, { AsyncHasMany, attr, hasMany } from '@ember-data/model';
import { get } from 'teamtailor/utils/get';
import { BlocketSubcategoryModel } from 'teamtailor/models';

export default class BlocketCategoryModel extends Model {
  @hasMany('blocket-subcategory')
  declare blocketSubcategories: AsyncHasMany<BlocketSubcategoryModel>;

  @attr('string') declare name: string;

  get sortedBlocketSubcategories() {
    return get(this, 'blocketSubcategories').sortBy('name');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'blocket-category': BlocketCategoryModel;
  }
}
