import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isNewDate}}\n  <time class=\"body-text-s-medium mb-8 text-left text-neutral\">\n    {{t (concat \"common.\" (underscore this.item.createdWhen))}}\n  </time>\n{{/if}}", {"contents":"{{#if this.isNewDate}}\n  <time class=\"body-text-s-medium mb-8 text-left text-neutral\">\n    {{t (concat \"common.\" (underscore this.item.createdWhen))}}\n  </time>\n{{/if}}","moduleName":"teamtailor/components/employee-dashboard/group-date.hbs","parseOptions":{"srcName":"teamtailor/components/employee-dashboard/group-date.hbs"}});
import Component from '@glimmer/component';

export default class EmployeeDashboardGroupDateComponent extends Component {
  get isNewDate() {
    const { item, all } = this.args;
    const previousIndex = all.indexOf(item) - 1;
    const previousObject = all.objectAt(previousIndex);

    return previousObject?.createdWhen !== item.createdWhen;
  }
}
