import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { tracked } from 'tracked-built-ins';
import { jsonToBase64 } from 'teamtailor/utils/base-64';
import IntlService from 'ember-intl/services/intl';
import {
  REPORT_TEMPLATES,
  ReportTemplate,
} from 'teamtailor/utils/insights/reports/templates';
import InsightsReport from 'teamtailor/models/insights-report';
import CustomReport from 'teamtailor/models/custom-report';

type Report = CustomReport | InsightsReport;

export default class AnalyticsCustomReportsIndexController extends Controller {
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare intl: IntlService;

  @tracked isLoading = false;
  @tracked isSelectReportTypeModalOpen = false;
  @tracked isSnapshotModalOpen = false;
  @tracked isSelectTemplateModalOpen = false;

  get templates() {
    return REPORT_TEMPLATES;
  }

  get reports() {
    return this.model
      .filter((report: Report) => {
        return !report.isNew;
      })
      .sort((a: Report, b: Report) => {
        return a.updatedAt < b.updatedAt ? 1 : -1;
      });
  }

  @action
  showCreateReportModal() {
    this.isSelectTemplateModalOpen = this.isSnapshotModalOpen = false;
    this.isSelectReportTypeModalOpen = true;
  }

  @action
  hideCreateReportModals() {
    this.isSelectReportTypeModalOpen =
      this.isSelectTemplateModalOpen =
      this.isSnapshotModalOpen =
        false;
  }

  @action
  onReportTypeSelected(reportType: 'historic' | 'snapshot') {
    this.isSelectReportTypeModalOpen = false;
    if (reportType === 'historic') {
      this.isSelectTemplateModalOpen = true;
    } else {
      this.isSnapshotModalOpen = true;
    }
  }

  @action
  async createNewReport(templateKey: string | null | undefined) {
    this.isSelectTemplateModalOpen = false;
    this.isLoading = true;
    const report = this.store.createRecord('insights-report');

    let template: ReportTemplate | undefined;
    if (templateKey) {
      template = this.templates.find(
        (template) => template.key === templateKey
      );
    }

    if (template) {
      const templateKeyPrefix = `insights.reports.select_template_modal.templates.${template.key}`;

      report.settings = jsonToBase64({
        chartSettings: template.chartSettings.map((setting) => ({
          ...setting,
          ...(setting.nameKey
            ? {
                name: this.intl.t(
                  `${templateKeyPrefix}.chart_names.${setting.nameKey}`
                ),
              }
            : {}),
        })),

        filterSettings: template.filterSettings,
      });

      report.emoji = template.emoji;
      report.name = this.intl.t(`${templateKeyPrefix}.name`);
    }

    await report.save();

    this.isLoading = false;
    this.router.transitionTo(
      'analytics.custom-reports.historic.edit',
      report.id
    );
  }
}

declare module '@ember/controller' {
  interface Registry {
    'analytics.custom-reports.index': AnalyticsCustomReportsIndexController;
  }
}
