/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Mixin.create({
  store: service(),

  setupController(controller, model) {
    this._super(controller, model);
    const itemId = this.paramsFor(get(this, 'routeName')).item_id;
    let item;
    if (get(model, 'itemsArray.length')) {
      item = get(model, 'itemsArray').findBy('id', itemId);
      if (get(item, 'imageId')) {
        get(this, 'store')
          .findRecord('picked-image', get(item, 'imageId'))
          .then(
            (pickedImage) => {
              set(item, 'pickedImage', pickedImage);
              set(controller, 'item', item);
            },
            () => {
              set(controller, 'item', item);
            }
          );
      } else {
        set(controller, 'item', item);
      }
    }
  },
});
