import { MeetingEventLocationType } from 'teamtailor/components/meeting/types';
import {
  MeetingEventLocationLocationAttributes,
  MeetingEventLocationLocationClass,
} from './meeting-event-location-location';
import {
  CustomLocationClass,
  MeetingEventLocationCustomLocationAttributes,
} from './meeting-event-location-custom';
import {
  MeetingEventLocationVideoMeetingAttributes,
  VideoMeetingLocationClass,
} from './meeting-event-location-video-meeting';

export type LocationType = InstanceType<
  | typeof VideoMeetingLocationClass
  | typeof MeetingEventLocationLocationClass
  | typeof CustomLocationClass
>;

export type MeetingEventLocationAttributes =
  | MeetingEventLocationLocationAttributes
  | MeetingEventLocationVideoMeetingAttributes
  | MeetingEventLocationCustomLocationAttributes;

// make this just a function?
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class MeetingEventLocationClass {
  static from(
    location: MeetingEventLocationType | undefined
  ): LocationType | undefined {
    if (location) {
      if ('location' in location) {
        return new MeetingEventLocationLocationClass(location);
      }

      if ('videoMeeting' in location) {
        return new VideoMeetingLocationClass(location);
      }

      if ('customLocation' in location) {
        return new CustomLocationClass(location);
      }
    }
  }
}
