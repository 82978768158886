// country codes that match ISO(2 letters) for Twilio
export const countries = [
  {
    name: 'Australia',
    code: 'AU',
    verifyDocuments: true,
    visible: true,
  },
  {
    name: 'Denmark',
    code: 'DK',
    visible: true,
    verifyDocuments: false,
  },
  {
    name: 'Estonia',
    code: 'EE',
    visible: true,
    verifyDocuments: true,
  },
  {
    name: 'Finland',
    code: 'FI',
    visible: false,
    verifyDocuments: false,
  },
  {
    name: 'Germany',
    code: 'DE',
    verifyDocuments: true,
    visible: false,
  },
  {
    name: 'Latvia',
    code: 'LV',
    verifyDocuments: false,
    visible: true,
  },
  {
    name: 'Lithuania',
    code: 'LT',
    verifyDocuments: true,
    visible: true,
  },
  {
    name: 'Netherlands',
    code: 'NL',
    visible: true,
    verifyDocuments: true,
  },
  {
    name: 'Norway',
    code: 'NO',
    verifyDocuments: true,
    visible: false,
  },
  {
    name: 'Poland',
    code: 'PL',
    verifyDocuments: true,
    visible: true,
  },
  {
    name: 'Sweden',
    code: 'SE',
    verifyDocuments: false,
    visible: true,
  },
  {
    name: 'United States',
    code: 'US',
    verifyDocuments: false,
    visible: true,
  },
  {
    name: 'Canada',
    code: 'CA',
    verifyDocuments: false,
    visible: true,
  },
  {
    name: 'Switzerland',
    code: 'CH',
    verifyDocuments: true,
    visible: true,
  },
  {
    name: 'United Kingdom',
    code: 'GB',
    verifyDocuments: true,
    visible: true,
  },
];

export const identityAcceptExtension = '.jpg, .png, .pdf';
