import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { set } from '@ember/object';

export default class ActivityRoute extends Route {
  @service infinity;
  @service notifications;
  @service store;

  model() {
    return this.infinity.model('activity', {
      job_id: this.modelFor('jobs.job').id,
      perPage: 15,
      startingPage: 1,
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    set(controller, 'job', this.modelFor('jobs.job'));
  }
}
