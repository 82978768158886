import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import FlipperService from 'teamtailor/services/flipper';
import Current from 'teamtailor/services/current';
import AnalyticsService from 'teamtailor/services/analytics';

export default class AnalyticsController extends Controller {
  @service declare flipper: FlipperService;
  @service declare current: Current;
  @service declare analytics: AnalyticsService;

  queryParams = ['startDate', 'endDate'];

  get startDate() {
    return this.analytics.startDateParam;
  }

  set startDate(date: string | null) {
    this.analytics.setStartDate(date);
  }

  get endDate() {
    return this.analytics.endDateParam;
  }

  set endDate(date: string | null) {
    this.analytics.setEndDate(date);
  }

  get userHasAnalyticsRole() {
    return this.current.user.hasRole('analytics');
  }
}

declare module '@ember/controller' {
  interface Registry {
    analytics: AnalyticsController;
  }
}
