import Controller from '@ember/controller';
import { marketingTypesInMarketplace } from 'teamtailor/constants/marketplace';
import { get } from 'teamtailor/utils/get';

export default class MarketplaceController extends Controller {
  get partnerCategories() {
    return get(this, 'model.partners')
      .sortBy('category')
      .reduce((acc, { category, categoryHumanName }) => {
        acc[category] = categoryHumanName;
        return acc;
      }, {});
  }

  marketingTypesInMarketplace = marketingTypesInMarketplace;
}
