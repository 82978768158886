import Service, { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import Server from 'teamtailor/services/server';
import { AllModels, ModelKey, PushPayloadArg } from 'teamtailor/models';
import { camelize } from '@ember/string';

export default class SharedReportStoreService extends Service {
  @service declare server: Server;
  @service declare store: Store;

  token?: string;

  database: { [modelName: string]: AllModels[] } = {};

  get url() {
    return `/app/api/shared_reports/${this.token}/models`;
  }

  async query(modelName: ModelKey, query: any) {
    const q = Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&');

    const response = (await this.request(
      `model=${modelName}&${q}`
    )) as PushPayloadArg;

    if (!this.database[modelName]) {
      this.database[modelName] = [];
    }

    let models = response[modelName].map((record: any) => {
      const newRecord: { [k in string]: any } = {};
      Object.keys(record).forEach((key) => {
        newRecord[camelize(key)] = record[key];
      });
      return newRecord;
    });

    models = models.sort((a, b) => a.id - b.id);

    models.forEach((record: any) => {
      const existingRecord = this.peekRecord(modelName, record.id.toString());
      if (!existingRecord) {
        this.database[modelName]!.push(record);
      }
    });

    return models;
  }

  async findAll(modelName: ModelKey) {
    return this.request(`model=${modelName}`) as Promise<PushPayloadArg>;
  }

  async findRecord(modelName: ModelKey, id: string) {
    const existing = this.peekRecord(modelName, id);
    if (existing) {
      return existing;
    }

    const result = (await this.request(
      `model=${modelName}&ids=${id}`
    )) as PushPayloadArg;
    const records = result[modelName];
    return records.length > 0 ? records[0] : null;
  }

  peekRecord(modelName: ModelKey, id: string) {
    const records = this.peekAll(modelName);
    return records.find((record) => record.id.toString() === id.toString());
  }

  peekAll(modelName: ModelKey) {
    return this.database[modelName] || [];
  }

  async request(query: string) {
    const url = `${this.url}?${query}`;

    const response = await this.server.request(url, 'GET');

    return response;
  }
}

declare module '@ember/service' {
  interface Registry {
    'shared-report-store': SharedReportStoreService;
  }
}
