import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { aniMate, setStyles } from 'ember-ani-mate';
import { get } from 'teamtailor/utils/get';

export default class EditController extends Controller {
  @service router;

  triggerCardEl;

  @action onInsert() {
    this.triggerCardEl = document.querySelector(
      `.stage-trigger-card-${get(this.model, 'id')}`
    );

    if (document.startViewTransition) {
      this.triggerCardEl.style.opacity = 0;
    }
  }

  @action async transitionToTriggerCard(triggerCardEl) {
    if (!document.startViewTransition) {
      this.router.transitionTo('jobs.job.stages.stage-triggers');
      return;
    }

    triggerCardEl.style.opacity = 0;

    const unsetTriggerCardStyles = setStyles(triggerCardEl, {
      viewTransitionName: 'stage-trigger-card',
    });
    setStyles(triggerCardEl.children, {
      opacity: 0,
    });

    const modalBoxWrapperEl = document.querySelector(
      '.stage-triggers-modal-box-wrapper'
    );
    const modalBoxWrapperRect = modalBoxWrapperEl?.getBoundingClientRect();

    const duration = 250;

    const unsetDocStyles = setStyles(document.documentElement, {
      '--view-transition-duration': `${duration}ms`,
    });

    const transition = document.startViewTransition(() =>
      this.router
        .transitionTo('jobs.job.stages.stage-triggers')
        .followRedirects()
    );

    await Promise.all([transition.ready, transition.updateCallbackDone]);

    triggerCardEl.style.opacity = '';
    const triggerCardRect = triggerCardEl.getBoundingClientRect();

    const easeOutCubicWithBounce = 'cubic-bezier(.05,.62,.15,1.09)';

    aniMate(
      document.documentElement,
      [
        {
          width: modalBoxWrapperRect.width,
          height: modalBoxWrapperRect.height,
          left: 0,
          top: 0,
          opacity: 1,
        },
        {
          opacity: 0,
          height: triggerCardRect.height,
          top: triggerCardRect.top - modalBoxWrapperRect.top,
          left: triggerCardRect.left - modalBoxWrapperRect.left,
          width: triggerCardRect.width,
        },
      ],
      {
        easing: easeOutCubicWithBounce,
        duration,
        pseudoElement: `::view-transition-old(stage-triggers-modal-box-wrapper)`,
      }
    );

    aniMate.to(
      triggerCardEl.children,
      { opacity: 1 },
      { delay: 100, duration }
    );

    aniMate(
      document.documentElement,
      [
        {
          width: modalBoxWrapperRect.width,
          height: modalBoxWrapperRect.height,
          top: modalBoxWrapperRect.top - triggerCardRect.top,
          left: modalBoxWrapperRect.left - triggerCardRect.left,
        },
        {
          height: triggerCardRect.height,
          left: 0,
          top: 0,
          width: triggerCardRect.width,
        },
      ],
      {
        easing: easeOutCubicWithBounce,
        duration,
        pseudoElement: `::view-transition-new(stage-trigger-card)`,
      }
    ).then(() => {
      unsetDocStyles();

      unsetTriggerCardStyles();
    });
  }

  @action onRegister(modalApi) {
    this.modalApi = modalApi;
  }

  @action
  async handleClose(closeReason) {
    if (closeReason === 'destroyed') {
      await this.modalApi.onClose(() => {
        return this.router
          .transitionTo('jobs.job.stages.stage-triggers')
          ?.followRedirects();
      });
    } else {
      this.transitionToTriggerCard(this.triggerCardEl);
    }
  }
}
