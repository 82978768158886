import BaseProperty from './properties/base-property';
import BaseQuery from './base-query';
import SelectedFilter from './filters/selected-filter';

export default class PageviewQuery extends BaseQuery {
  constructor(
    groupBys: BaseProperty[],
    properties: BaseProperty[],
    filters: SelectedFilter[],
    sortProperty?: BaseProperty,
    sortDirection?: 'asc' | 'desc',
    limit?: number,
    page?: number
  ) {
    const shouldFetchPageviewQuery =
      properties.some((property) =>
        property.queryTypes.some((qt) => qt === 'pageview')
      ) &&
      groupBys.some((property) =>
        property.queryTypes.some((qt) => qt === 'pageview')
      );
    if (shouldFetchPageviewQuery) {
      super(
        'pageview',
        groupBys.filter((property) => property.queryTypes.includes('pageview')),
        properties.filter((property) =>
          property.queryTypes.includes('pageview')
        ),
        filters.filter((filter) =>
          filter.filter?.queryTypes.includes('pageview')
        ),
        sortProperty?.queryTypes.includes('pageview')
          ? sortProperty
          : undefined,
        sortProperty?.queryTypes.includes('pageview')
          ? sortDirection
          : undefined,
        limit,
        page
      );
    } else {
      super('pageview');
    }
  }

  get query() {
    if (this.properties.length === 0) return undefined;

    const args = [
      this.groupByStatement,
      this.orderStatement,
      this.limitStatement,
      this.pageStatement,
    ].filter((arg) => arg !== undefined);
    const filterArg = this.filterStatement ? `, ${this.filterStatement}` : '';

    return `
    pageviewQuery(dateRange: $dateRange, companyIds: $companyIds${filterArg}) {
      ${
        this.limitStatement
          ? `totalAggregatedCount(${this.groupByStatement})`
          : ''
      }
      aggregated${args.length > 0 ? `(${args.join(',')})` : ''} {
        ${this.propertyStatements}
      }
    }
  `;
  }
}
