import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { set } from '@ember/object';
import { isPresent } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import { base64ToJson } from 'teamtailor/utils/base-64';
import { deserialize } from 'teamtailor/components/fancy-filters/serializable-filter';

export default class ReportsRoute extends Route {
  @service analytics;
  @service router;
  @service store;

  queryParams = {
    departmentId: { refreshModel: true },
    locationId: { refreshModel: true },
    filters: { refreshModel: true, as: 'q' },
  };

  beforeModel(transition) {
    const fromJob = get(transition, 'to.queryParams.fromJob');
    const jobId = get(transition, 'to.queryParams.jobId');

    if (isPresent(fromJob) && isPresent(jobId)) {
      this.router.transitionTo('jobs.job.analytics', jobId);
    }
  }

  async model({ jobId, filters: filtersParam }) {
    const analytics = get(this, 'analytics');

    if (isPresent(filtersParam)) {
      const filters = await RSVP.all(
        base64ToJson(filtersParam).map((row) =>
          deserialize(this.store, row, analytics.hasEnabledGroupAnalytics)
        )
      );

      set(analytics, 'filters', filters);
    }

    if (jobId) {
      return this.store.findRecord('job', jobId);
    } else {
      return null;
    }
  }

  async afterModel() {
    await this.analytics.fetchMatchingJobs.perform();
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      this.analytics.departmentId = null;
      this.analytics.locationId = null;
    }
  }
}
