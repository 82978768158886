import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"body-text-xs mb-24 flex w-full justify-between leading-header\"\n  ...attributes\n>\n  <time class=\"flex text-neutral\">\n    {{this.formattedStartDate}}\n  </time>\n\n  <time class=\"text-neutral\">\n    {{this.formattedEndDate}}\n  </time>\n</div>", {"contents":"<div\n  class=\"body-text-xs mb-24 flex w-full justify-between leading-header\"\n  ...attributes\n>\n  <time class=\"flex text-neutral\">\n    {{this.formattedStartDate}}\n  </time>\n\n  <time class=\"text-neutral\">\n    {{this.formattedEndDate}}\n  </time>\n</div>","moduleName":"teamtailor/components/insights/atoms/date-gutter.hbs","parseOptions":{"srcName":"teamtailor/components/insights/atoms/date-gutter.hbs"}});
import Component from '@glimmer/component';
import moment, { Moment } from 'moment-timezone';
import { argDefault } from 'teamtailor/utils/arg-default';

type Args = {
  startDate: string;
  endDate: string;
  shouldFormat?: boolean;
};

export default class InsightsAtomsDateGutter extends Component<Args> {
  @argDefault shouldFormat = true;

  get startDate(): Moment {
    return moment(this.args.startDate);
  }

  get endDate(): Moment {
    return moment(this.args.endDate);
  }

  get showYear() {
    const startYear = this.startDate.year();
    const endYear = this.endDate.year();
    const thisYear = moment().year();

    return startYear !== endYear || startYear !== thisYear;
  }

  get formattedStartDate() {
    if (!this.shouldFormat) {
      return this.args.startDate;
    }

    if (this.showYear) {
      return this.startDate.format('MMM D, YYYY');
    }

    return this.startDate.format('MMM D');
  }

  get formattedEndDate() {
    if (!this.shouldFormat) {
      return this.args.endDate;
    }

    if (this.showYear) {
      return this.endDate.format('MMM D, YYYY');
    }

    return this.endDate.format('MMM D');
  }
}
