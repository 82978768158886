import { tracked } from '@glimmer/tracking';
import { Attendee } from 'teamtailor/components/meeting/types';

export interface IAttendeeRecord {
  id: string;
  _destroy?: boolean;
}

export enum AttendeeStatusEnum {
  NEEDS_ACTION = 'NEEDS_ACTION',
  ACCEPTED = 'ACCEPTED',
  TENTATIVE = 'TENTATIVE',
  DECLINED = 'DECLINED',
}

export class AttendeeClass {
  @tracked declare id: string;
  @tracked declare status?: string;

  constructor(attendee: Attendee | undefined) {
    this.id = attendee?.id ?? '';
    this.status = attendee?.status;
  }
}
