import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  local-class={{class-names \"root\" root--absolutePosition=@absolutePosition}}\n>\n  {{#each @errors as |error|}}\n    <AlertMessage @marginY={{true}} @type=\"error\">\n      {{html-safe error.message}}\n      {{#if @onClose}}\n        <button\n          local-class=\"close\"\n          type=\"button\"\n          {{on \"click\" (fn this.closeErrorMessage error)}}\n        >\n          <Icon @icon=\"xmark\" />\n        </button>\n      {{/if}}\n    </AlertMessage>\n  {{/each}}\n</div>", {"contents":"<div\n  local-class={{class-names \"root\" root--absolutePosition=@absolutePosition}}\n>\n  {{#each @errors as |error|}}\n    <AlertMessage @marginY={{true}} @type=\"error\">\n      {{html-safe error.message}}\n      {{#if @onClose}}\n        <button\n          local-class=\"close\"\n          type=\"button\"\n          {{on \"click\" (fn this.closeErrorMessage error)}}\n        >\n          <Icon @icon=\"xmark\" />\n        </button>\n      {{/if}}\n    </AlertMessage>\n  {{/each}}\n</div>","moduleName":"teamtailor/components/error-messages.hbs","parseOptions":{"srcName":"teamtailor/components/error-messages.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

type Args = {
  errors: Record<string, string[]>;
  onClose?: (error: { [key: string]: string[] }) => void;
};

export default class ErrorMessagesComponent extends Component<Args> {
  @action
  closeErrorMessage(error: { [key: string]: string[] }) {
    this.args.onClose?.(error);
  }
}
