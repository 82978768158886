import { attr } from '@ember-data/model';
import Section from './section';

export default class ContentBlockListModel extends Section {
  @attr('string') declare customTitleType: string;
  @attr('string') declare customTextAlign: string;
  @attr('string') declare customImageShape: string;
  @attr('string') declare customIconColor: string;
  @attr('string') declare titleType: string;
  @attr('string') declare textAlign: string;
  @attr('string') declare imageShape: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'content-block-list': ContentBlockListModel;
  }
}
