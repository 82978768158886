import { tracked } from '@glimmer/tracking';
import {
  getStringErrorPath,
  IGraphQLError,
  IGraphQLErrorProblem,
} from 'teamtailor/utils/meetings/error-handling';

interface IError {
  errorMessages: IGraphQLError[];
  allProblems: IGraphQLErrorProblem[];
}

export default class ErrorClass {
  @tracked declare errorMessages: IGraphQLError[];
  @tracked declare allProblems: IGraphQLErrorProblem[];

  constructor(error?: Partial<IError>) {
    this.errorMessages = error?.errorMessages ?? [];
    this.allProblems = error?.allProblems ?? [];
  }

  private removeProblemByPath(path: string): void {
    this.allProblems = this.allProblems.filter(
      (problem) => getStringErrorPath(problem.path) !== path
    );
  }

  reset(): void {
    this.allProblems = [];
    this.errorMessages = [];
  }

  update(errors: IGraphQLError[]): void {
    this.allProblems = errors[0]?.extensions?.problems ?? [];
    this.errorMessages = errors;
  }

  onChangeProblemValue = (path: string): void => {
    const startCount = this.allProblems.length;
    this.removeProblemByPath(path);
    if (startCount > this.allProblems.length) {
      this.errorMessages = [];
    }
  };
}
