import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';
import JobModel from 'teamtailor/models/job';

export default class JobEditRequisitionRoute extends Route {
  async afterModel(job: JobModel) {
    const requisition = await get(job, 'requisition');

    return requisition
      ? Promise.all([
          get(requisition, 'department'),
          get(requisition, 'role'),
          get(requisition, 'location'),
          get(requisition, 'user'),
          get(requisition, 'recruiter'),
        ])
      : null;
  }
}
