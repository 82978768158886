import { underscore } from '@ember/string';
import { isPresent } from '@ember/utils';
import {
  FilterValue,
  IFilter,
  IOperator,
  ParseRawOptions,
  SerializableFilterValue,
} from 'teamtailor/components/fancy-filters';
import { JSONFilter } from 'teamtailor/components/fancy-filters/candidate-filters-json';

export default class BaseOperator implements IOperator {
  specialTypes: string[] = [];

  public operatorName = '';
  public name = '';
  public not = false;
  public ignoreNotStructure = false;
  public filter?: IFilter;

  public get hideInput(): boolean {
    return false;
  }

  get translationKey(): string {
    return `components.fancy_filters.operators.${this.name}`;
  }

  constructor(name: string, not = false, ignoreNotStructure = false) {
    this.operatorName = this.name = name;
    this.not = not;
    this.ignoreNotStructure = ignoreNotStructure;
    if (not) {
      this.name = `not_${name}`;
    }
  }

  public asJSON(): JSONFilter {
    if (!this.filter) {
      throw new Error('Filter is not set');
    }

    return {
      [this.filter.name]: {
        [this.operatorName]: this.sanitize(this.filter.serializedValue),
      },
    };
  }

  get stringifiedValue(): string {
    const value = this.sanitize(this.filter?.serializedValue);
    if (Array.isArray(value)) {
      return `[${value
        .map((val) =>
          this.filter?.filterType !== '[Enum]' && typeof val === 'string'
            ? `"${val.toLowerCase()}"`
            : val
        )
        .join(', ')}]`;
    }

    return typeof value === 'string'
      ? `"${value.toLowerCase()}"`
      : value?.toString() || '';
  }

  public isUsed(json: JSONFilter, options: ParseRawOptions = {}): boolean {
    return !!Object.keys(json).find((key) => {
      if (
        key === 'or' ||
        key === 'and' ||
        key === 'not' ||
        key === this.filter?.name ||
        key === this.filter?.filter?.nested?.name ||
        key === this.filter?.filter?.groupedName
      ) {
        return this.isUsed(json[key] as JSONFilter, {
          ...options,
          [key]: true,
        });
      }

      if (!!options.not !== !!this.not) {
        return false;
      }

      return key === this.operatorName;
    });
  }

  public parseRawValue(
    json: JSONFilter,
    options: ParseRawOptions = {}
  ): string | string[] | undefined | null {
    const value = Object.keys(json)
      .map((key) => {
        if (key === 'or' || key === 'and' || (key === 'not' && !!this.not)) {
          return this.parseRawValue(json[key] as JSONFilter, {
            ...options,
            [key]: true,
          });
        }

        if (
          key === this.filter?.name ||
          key === this.filter?.filter?.nested?.name ||
          key === this.filter?.filter?.groupedName
        ) {
          return this.parseRawValue(json[key] as JSONFilter, options);
        }

        if (key === this.operatorName) {
          return this.filter?.filter?.deserializeRawValue
            ? this.filter.filter.deserializeRawValue(json[key])
            : json[key];
        }

        return undefined;
      })
      .flat()
      .filter((v) => isPresent(v));

    if (value.length === 1) {
      return value[0];
    }

    return value;
  }

  public parseValue(rawValue: string | string[]): FilterValue | undefined {
    const value = this.filter?.deserialize?.(rawValue);

    return value;
  }

  public isValidValue = (value?: FilterValue): boolean => {
    return isPresent(value);
  };

  public get translationKeyForName(): string {
    return `${this.baseTranslationKey}.name`;
  }

  public get translationKeyForTrigger(): string {
    return `${this.baseTranslationKey}.trigger`;
  }

  public get filterType() {
    return this.filter?.filterType;
  }

  public formatValue(value?: FilterValue): FilterValue | undefined {
    return value;
  }

  private get baseTranslationKey(): string {
    if (this.specialTypes.some((type) => this.filterType === type)) {
      return `${this.translationKey}.${underscore(this.filterType || '')}`;
    }

    return `${this.translationKey}.default`;
  }

  protected sanitize(
    value: SerializableFilterValue | undefined
  ): SerializableFilterValue | undefined {
    if (typeof value === 'string') {
      return value.toLowerCase().trim();
    }

    return value;
  }
}
