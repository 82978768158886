import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Button\n  id=\"add-candidates-button\"\n  @icon=\"plus\"\n  @tooltip={{t \"list_actions.add_candidates\"}}\n  @ariaLabel={{t \"list_actions.add_candidates\"}}\n  @onClick={{fn (mut this.isOpen) true}}\n/>\n\n{{#if this.isOpen}}\n  <BulkCandidate::AddForm\n    @isOpen={{this.isOpen}}\n    @job={{@job}}\n    @department={{@department}}\n    @role={{@role}}\n    @onClose={{this.handleClose}}\n    @queueItems={{this.queueItems}}\n  />\n{{/if}}", {"contents":"<Button\n  id=\"add-candidates-button\"\n  @icon=\"plus\"\n  @tooltip={{t \"list_actions.add_candidates\"}}\n  @ariaLabel={{t \"list_actions.add_candidates\"}}\n  @onClick={{fn (mut this.isOpen) true}}\n/>\n\n{{#if this.isOpen}}\n  <BulkCandidate::AddForm\n    @isOpen={{this.isOpen}}\n    @job={{@job}}\n    @department={{@department}}\n    @role={{@role}}\n    @onClose={{this.handleClose}}\n    @queueItems={{this.queueItems}}\n  />\n{{/if}}","moduleName":"teamtailor/components/bulk-candidate/editor.hbs","parseOptions":{"srcName":"teamtailor/components/bulk-candidate/editor.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DepartmentModel from 'teamtailor/models/department';
import JobModel from 'teamtailor/models/job';
import { QueueItemClass } from 'teamtailor/components/bulk-candidate/queue-item';
import { inject as service } from '@ember/service';
import TtAlertService from 'teamtailor/services/tt-alert';
import IntlService from 'ember-intl/services/intl';
import RoleModel from 'teamtailor/models/role';

interface Args {
  department?: DepartmentModel;
  role?: RoleModel;
  job?: JobModel;
}

export default class BulkCandidateEditor extends Component<Args> {
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;

  @tracked isOpen = false;
  @tracked queueItems: QueueItemClass[] = [];

  get hasUnsavedCandidates(): boolean {
    if (this.queueItems.isEvery('isPersisted', true)) {
      return false;
    }

    return this.queueItems.rejectBy('isPersisted', true).isAny('isDirty', true);
  }

  @action
  handleClose(): void {
    if (this.hasUnsavedCandidates) {
      this.ttAlert.customConfirm({
        title: this.intl.t('common.leave_page_confirmation'),
        text: this.intl.t('common.unsaved_candidates'),
        confirmButtonText: this.intl.t('common.leave_this_page'),
        cancelButtonText: this.intl.t('common.stay_on_this_page'),
        confirmCallback: () => {
          this.isOpen = false;
          this.queueItems.clear();
        },

        cancelCallback: () => {
          return true;
        },
      });
    } else {
      this.isOpen = false;
      this.queueItems.clear();
    }
  }
}
