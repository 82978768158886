import { attr } from '@ember-data/model';
import TriggerModel from 'teamtailor/models/trigger';
import { isPresent } from '@ember/utils';

export default class TriggerShareModel extends TriggerModel<'share'> {
  @attr('string') declare body: string;
  @attr('string') declare emails: string;
  @attr('raw') declare shareOptions:
    | undefined
    | Record<string, boolean | string>;

  @attr('string') declare subject: string;

  get valid() {
    const emailsPresent = isPresent(this.emails);
    const bodyPresent = isPresent(this.body);
    const subjectPresent = isPresent(this.subject);
    const passwordRestrictionOn = !!this.shareOptions?.restricted;
    const passwordPresent = isPresent(this.shareOptions?.password);

    return (
      emailsPresent &&
      bodyPresent &&
      subjectPresent &&
      (!passwordRestrictionOn || passwordPresent)
    );
  }

  get description() {
    return this.emails;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'trigger/share': TriggerShareModel;
  }
}
