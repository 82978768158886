import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (and @jobApplication.job.jobDetail.competenceBased this.hasScores)}}\n  <CandidateModalNew::Main::Section\n    @title={{t\n      \"candidates.candidate.candidate_modal.job_match.job_match_breakdown\"\n    }}\n    @sectionTitle={{t\n      \"candidates.candidate.candidate_modal.job_match.job_match\"\n    }}\n    @id=\"job-match\"\n  >\n    <:content>\n      <CandidateModalNew::Main::Section::JobMatch::ScorecardResults\n        @displayWeight={{true}}\n        @picks={{sort-by \"weight:desc\" \"name:asc\" this.scorecardPicks}}\n        @interviews={{this.interviews}}\n        @scores={{flatten (map-by \"scores\" this.interviews)}}\n        @showInterviewKitName={{true}}\n        @filterEmpty={{true}}\n        @sortOnWeight={{true}}\n      />\n    </:content>\n  </CandidateModalNew::Main::Section>\n{{/if}}", {"contents":"{{#if (and @jobApplication.job.jobDetail.competenceBased this.hasScores)}}\n  <CandidateModalNew::Main::Section\n    @title={{t\n      \"candidates.candidate.candidate_modal.job_match.job_match_breakdown\"\n    }}\n    @sectionTitle={{t\n      \"candidates.candidate.candidate_modal.job_match.job_match\"\n    }}\n    @id=\"job-match\"\n  >\n    <:content>\n      <CandidateModalNew::Main::Section::JobMatch::ScorecardResults\n        @displayWeight={{true}}\n        @picks={{sort-by \"weight:desc\" \"name:asc\" this.scorecardPicks}}\n        @interviews={{this.interviews}}\n        @scores={{flatten (map-by \"scores\" this.interviews)}}\n        @showInterviewKitName={{true}}\n        @filterEmpty={{true}}\n        @sortOnWeight={{true}}\n      />\n    </:content>\n  </CandidateModalNew::Main::Section>\n{{/if}}","moduleName":"teamtailor/components/candidate-modal-new/main/section/job-match/index.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-modal-new/main/section/job-match/index.hbs"}});
import Component from '@glimmer/component';
import CandidateModel from 'teamtailor/models/candidate';
import JobApplicationModel from 'teamtailor/models/job-application';
import InterviewModel from 'teamtailor/models/interview';
import { get } from 'teamtailor/utils/get';

interface Args {
  candidate: CandidateModel;
  jobApplication: JobApplicationModel;
}

export default class extends Component<Args> {
  get candidate() {
    return this.args.candidate;
  }

  get jobApplication() {
    return this.args.jobApplication;
  }

  get job() {
    return get(this.jobApplication, 'job');
  }

  get jobId(): string {
    return get(this.job, 'id');
  }

  get scorecardPicks() {
    const jobDetail = get(this.job, 'jobDetail');
    return get(jobDetail, 'scorecardPicks').filter((scorecardPick) => {
      return !!scorecardPick.weight;
    });
  }

  get criterium() {
    return this.scorecardPicks.map((scorecardPick) => {
      return scorecardPick.belongsTo('scorecardCriterium').id();
    });
  }

  get hasScores() {
    return (
      this.interviews
        .map((interview) => {
          const scores = get(interview, 'scores');
          return scores
            .filter((score) => {
              return !!get(score, 'humanScore');
            })
            .filter((score) => {
              const scorecardPick = get(score, 'scorecardPick');
              const scorecardCriterium = get(
                scorecardPick,
                'scorecardCriterium'
              );
              const id = get(scorecardCriterium, 'id');
              return this.criterium.includes(id);
            });
        })
        .flat()
        .compact().length > 0
    );
  }

  get interviews(): InterviewModel[] {
    return get(this.candidate, 'interviews').filter((interview) => {
      return !interview.isDraft && interview.jobId === this.jobId;
    });
  }
}
