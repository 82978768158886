import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default class CustomFieldSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    company: { serialize: false },
    options: {
      serialize: 'records',
      deserialize: 'records',
      key: 'options_attributes',
    },

    rowOrder: { serialize: false },
    hasValue: { serialize: false },
  };
}
