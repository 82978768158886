import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class SettingsExternalRecruitmentRecruitersEditRoute extends Route {
  @service current;
  @service store;

  async model(params) {
    await get(this.current.company, 'recruitingFirms');
    let recruiter = await this.store.findRecord(
      'user',
      params.external_recruiter_id
    );

    return recruiter;
  }
}
