import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { SectionModel } from 'teamtailor/models';

export default class ContentEditorSectionIndexController extends Controller {
  @tracked showAdjustments = false;

  get section() {
    return this.model;
  }

  get layoutPath() {
    if (this.section.hasLayout) {
      return `content.editor.section.layout`;
    }

    return undefined;
  }

  get componentName() {
    const { name } = this.section;
    return `section-${name}-form`;
  }

  @action
  async saveCustomName(customName: string, section: SectionModel) {
    await section.setCustomName({ custom_name: customName });
  }
}

declare module '@ember/controller' {
  interface Registry {
    'content.editor.section.index': ContentEditorSectionIndexController;
  }
}
