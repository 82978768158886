import Model, {
  attr,
  belongsTo,
  hasMany,
  type AsyncBelongsTo,
  type AsyncHasMany,
  type SyncHasMany,
} from '@ember-data/model';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import moment from 'moment-timezone';
import CandidateModel from 'teamtailor/models/candidate';
import JobModel from 'teamtailor/models/job';
import QuestionModel from 'teamtailor/models/question';
import QuestionnaireModel from 'teamtailor/models/questionnaire';
import UploadModel from 'teamtailor/models/upload';
import UserModel from 'teamtailor/models/user';
import Server from 'teamtailor/services/server';
import TimeFormatService from 'teamtailor/services/time-format';

export default class MessageModel extends Model {
  @service declare intl: IntlService;
  @service declare timeFormat: TimeFormatService;
  @service declare server: Server;

  @belongsTo('candidate')
  declare candidate: AsyncBelongsTo<CandidateModel | null>;

  @belongsTo('user')
  declare user: AsyncBelongsTo<UserModel | null>;

  @belongsTo('job')
  declare job: AsyncBelongsTo<JobModel | null>;

  @belongsTo('job-application')
  declare jobApplication: AsyncBelongsTo<JobModel | null>;

  @belongsTo('questionnaire', { async: false })
  declare questionnaire: QuestionnaireModel;

  @hasMany('upload', { async: false })
  declare uploads: SyncHasMany<UploadModel>;

  @hasMany('question', { async: false })
  declare questions: SyncHasMany<QuestionModel>;

  @belongsTo('message', { inverse: 'replies' })
  declare replyTo: AsyncBelongsTo<MessageModel | null>;

  @hasMany('message', { inverse: 'replyTo' })
  declare replies: AsyncHasMany<MessageModel>;

  @attr('boolean') declare automatic: boolean;
  @attr('boolean') declare autoReply: boolean;
  @attr declare fromChat: boolean | null;
  @attr('boolean') declare hasRawBody: boolean;
  @attr('boolean') declare isInbound: boolean;
  @attr('boolean') declare isLinkedin: boolean;
  @attr('boolean') declare isScheduled: boolean;
  @attr('boolean') declare isSms: boolean;
  @attr('date') declare createdAt: Date;
  @attr('date') declare failedAt?: Date;
  @attr('date') declare sendAt?: Date;
  @attr('date') declare sentAt?: Date;
  @attr('date') declare updatedAt: Date;
  @attr('string') declare autoReplyAnswer?: string;
  @attr('string') declare body?: string;
  @attr('string') declare bodyTruncated?: string;
  @attr('string') declare cannedResponseId?: string;
  @attr('string') declare delayUntil?: string;
  @attr('string') declare jobApplicationId?: string;
  @attr('string') declare replyToId?: string;
  @attr('string') declare subject: string;

  async getRawBody() {
    return this.server.memberAction(this, {
      action: 'raw_body',
      method: 'GET',
    });
  }

  get sortBy() {
    return this.sentAt || this.createdAt;
  }

  get isHTML() {
    const tags = ['<p>', '<br>', '<figure>'];

    if (this.body !== undefined) {
      return tags.some((tag) => this.body?.includes(tag));
    }
  }

  get optOutMessage() {
    const optOutMessage = ['stop', 'cancel', 'unsubscribe', 'quit', 'stopall'];
    const message = this.bodyTruncated ? this.bodyTruncated.toLowerCase() : '';
    return optOutMessage.includes(message);
  }

  get smsSegments() {
    if (this.body) {
      return Math.ceil(this.body.length / 160);
    }

    return 0;
  }

  get failedToSheduleDeliver() {
    return this.sendAt && !this.sentAt && moment(this.sendAt).isBefore();
  }

  get hasError() {
    return this.failedAt || this.failedToSheduleDeliver;
  }

  get deliveredAt() {
    return this.sentAt || this.createdAt;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    message: MessageModel;
  }
}
