export default {
  "card": "Card__card_18b6w7",
  "recruit": "Card__recruit_18b6w7",
  "relationships": "Card__relationships_18b6w7",
  "enterprise": "Card__enterprise_18b6w7",
  "security": "Card__security_18b6w7",
  "title": "Card__title_18b6w7",
  "title--label": "Card__title--label_18b6w7",
  "featureTag": "Card__featureTag_18b6w7",
  "new": "Card__new_18b6w7",
  "desc": "Card__desc_18b6w7",
  "category": "Card__category_18b6w7",
  "activeStatus": "Card__activeStatus_18b6w7",
  "enabled": "Card__enabled_18b6w7",
  "icon": "Card__icon_18b6w7"
};
