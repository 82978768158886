import { task } from 'ember-concurrency';
import Store from '@ember-data/store';

const fetchMissingAverageRatings = async (rows: any[]) => {
  const promises: Promise<any>[] = [];
  rows.forEach(({ averageRating }) => {
    if (!averageRating.hasFetchedRating) {
      promises.push(averageRating.fetchAverageRating.perform());
    }
  });
  await Promise.all(promises);
};

export { fetchMissingAverageRatings };

export default class AverageRating {
  declare store: Store;
  declare candidateIds: string[];

  constructor(store: Store, candidateIds: string[] = []) {
    this.store = store;
    this.candidateIds = candidateIds;
  }

  get hasFetchedRating() {
    return !!this.fetchAverageRating.lastSuccessful;
  }

  get value() {
    return this.fetchAverageRating.lastSuccessful?.value?.rating;
  }

  get numberOfRatings() {
    return this.fetchAverageRating.lastSuccessful?.value?.numberOfRatings;
  }

  get valueForCsv() {
    return this.numberOfRatings === 0 ? '' : this.value?.toFixed(1);
  }

  fetchAverageRating = task(async () => {
    const adapter = this.store.adapterFor('candidate');
    const url = `${adapter.buildURL('candidate')}/average_rating`;

    const promise = adapter.ajax(url, 'POST', {
      data: { candidate_ids: this.candidateIds },
    }) as Promise<{ numberOfRatings: number; rating: number }>;

    return promise;
  });
}
