import ApplicationInstance from '@ember/application/instance';
import { gql } from '@apollo/client/core';
import ReportAnalyticsRequest, {
  FetchOptions,
} from './report-analytics-request';
import {
  AnalyticsReportBuilder,
  BuildReportArgs,
  ComparableReport,
} from './analytics-report-builder';
import { get } from 'teamtailor/utils/get';

const QUERY = gql`
  query CompanyNPSComparison($dateRange: DateRangeAttributes!) {
    comparisonQuery {
      nps(dateRange: $dateRange) {
        averageCompanyScores
        companiesPerScoreSector
      }
    }

    eventQuery(
      dateRange: $dateRange
      distinctBy: {
        fields: [JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
      eventTypes: [NPS_RESPONSE]
    ) {
      aggregated {
        averageScore: average(field: NPS_SCORE)
        responses: count
      }
    }
  }
`;

interface Result {
  comparisonQuery:
    | {
        nps: NpsComparisonRow;
      }
    | undefined;
  eventQuery:
    | {
        aggregated: [CurrentNpsScoreRow];
      }
    | undefined;
}

export interface NpsComparisonRow {
  averageCompanyScores: number;
  companiesPerScoreSector: number[];
}

export interface CurrentNpsScoreRow {
  averageScore: number;
  responses: number;
}

interface NpsComparisonReportArgs extends NpsComparisonRow, CurrentNpsScoreRow {
  compareModel?: NpsComparisonReport;
}

export const SCORE_SECTORS = [
  `score_sector_0`, // '-100 to -76',
  `score_sector_1`, // '-75 to -51',
  `score_sector_2`, // '-50 to -26',
  `score_sector_3`, // '-25 to -1',
  `score_sector_4`, // '0 to 24',
  `score_sector_5`, // '25 to 49',
  `score_sector_6`, // '50 to 74',
  `score_sector_7`, // '75 to 100',
];

class NpsComparisonReport implements ComparableReport {
  companiesPerScoreSector: number[] = [];
  averageCompanyScores?: number;
  averageScore?: number;
  responses?: number;

  compareModel: NpsComparisonReport | undefined;

  constructor(args: NpsComparisonReportArgs) {
    Object.assign(this, args);
  }

  get previousAverageScore() {
    return get(this.compareModel, 'averageScore');
  }

  get currentScoreSector() {
    if (!this.averageScore && this.averageScore !== 0) return undefined;

    switch (true) {
      case this.averageScore < -75:
        return 0;
      case this.averageScore < -50:
        return 1;
      case this.averageScore < -25:
        return 2;
      case this.averageScore < 0:
        return 3;
      case this.averageScore < 25:
        return 4;
      case this.averageScore < 50:
        return 5;
      case this.averageScore < 75:
        return 6;
    }

    return 7;
  }
}

export function buildReport(args: BuildReportArgs) {
  const { container, compareOptions } = args;
  return new AnalyticsReportBuilder<
    NpsComparisonReport,
    NpsComparisonReportArgs
  >(container, {
    query: async (options: FetchOptions = {}) => {
      return fetch(container, options);
    },

    compareOptions,

    createReport: (queryResult: NpsComparisonReportArgs) => {
      return new NpsComparisonReport(queryResult);
    },
  });
}

export async function fetch(
  container: ApplicationInstance,
  options: FetchOptions = {}
): Promise<NpsComparisonReportArgs> {
  let compareModel: NpsComparisonReport | undefined;

  const result = await new ReportAnalyticsRequest({
    container,
    query: QUERY,
    callback: (result?: Result) => result,
  }).fetch(options);

  if (!result) {
    return {} as NpsComparisonReportArgs;
  }

  const comparisonRow = result?.comparisonQuery?.nps?.[0];
  const currentNpsScoreRow = result?.eventQuery?.aggregated?.[0];

  const { averageCompanyScores, companiesPerScoreSector } = comparisonRow;
  const { averageScore, responses } = currentNpsScoreRow;

  return {
    companiesPerScoreSector,
    averageCompanyScores,
    averageScore,
    compareModel,
    responses,
  };
}
