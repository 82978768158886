import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { ScorecardPickModel, TriggerModel } from 'teamtailor/models';

export default class MappedPartnerCriteriumModel extends Model {
  @belongsTo('trigger/webhook')
  declare trigger: AsyncBelongsTo<TriggerModel<'webhook'>>;

  @belongsTo('scorecard-pick')
  declare scorecardPick: AsyncBelongsTo<ScorecardPickModel>;

  @attr('string') declare partnerIdentifier: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'mapped-partner-criterium': MappedPartnerCriteriumModel;
  }
}
