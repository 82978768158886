import Service from '@ember/service';
import { isArray } from '@ember/array';

const DEFAULT_MESSAGE_LIFETIME = 60; // seconds

export default class DismissableStatusService extends Service {
  messages = {};

  addMessage(message, group = 'global') {
    if (isNaN(parseInt(message.lifetime, 10))) {
      message.lifetime = DEFAULT_MESSAGE_LIFETIME;
    }

    message.liveUntil = new Date().getTime() + message.lifetime * 1000;

    if (isArray(this.messages[group])) {
      this.messages[group].pushObject(message);
    } else {
      this.messages[group] = [message];
    }
  }

  getMessage(group = 'global') {
    let message = this.getNextGroupMessage(group);

    if (message === null) {
      return null;
    }

    if (!this.isOverdue(message)) {
      return this.serialize(message);
    }

    return this.getMessage(group);
  }

  getNextGroupMessage(group) {
    return isArray(this.messages[group])
      ? this.messages[group].shiftObject()
      : null;
  }

  isOverdue = (message) => message.liveUntil < new Date().getTime();

  serialize(message) {
    delete message.lifetime;
    delete message.liveUntil;
    return message;
  }
}
