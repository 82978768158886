import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { dropTask, restartableTask } from 'ember-concurrency';
import { gql } from '@apollo/client/core';
import RSVP from 'rsvp';
import Current from 'teamtailor/services/current';
import AnalyticsService from 'teamtailor/services/analytics';
import ApolloService from 'ember-apollo-client/services/apollo';
import FlipperService from 'teamtailor/services/flipper';
import Store from '@ember-data/store';

interface TotalStats {
  totalJobs: number;
  rejectedCount: number;
  totalApplicants: number;
}

interface StageType {
  category: string;
  count: number;
}

interface StageTypesStats {
  inboxCount: StageType;
  inProcessCount: StageType;
  hiredCount: StageType;
  screeningCount: StageType;
  interviewCount: StageType;
  offerCount: StageType;
}

interface JobStatsForStageTypes {
  totalStats: TotalStats;
  stageTypesStats: StageTypesStats;
}

interface JobAverageTimes {
  timeToHire: number;
  timeToFill: number;
}

type DurationQueryResponse = {
  pipelineStats: JobAverageTimes;
};

const DURATIONS_QUERY = gql`
  query PipelineStatsQuery(
    $filter: JobsFilterInput
    $userId: ID!
    $companyIds: [ID!]
  ) {
    pipelineStats(
      filter: $filter
      userScope: { userId: $userId }
      groupCompanyIds: $companyIds
    ) {
      timeToHire
      timeToFill
    }
  }
`;

const JOB_STATS_QUERY = gql`
  query AggregatedJobsQuery(
    $filter: JobsFilterInput
    $userId: ID!
    $companyIds: [ID!]
  ) {
    jobs: aggregatedJobs(
      filter: $filter
      userScope: { userId: $userId }
      groupCompanyIds: $companyIds
    ) {
      totalJobsCount
      totalInboxCount
      totalInProcessCount
      totalHiredCount
      totalRejectedCount
      totalStageTypesCountsInboxCount
      totalStageTypesCountsInProcessCount
      totalStageTypesCountsScreeningCount
      totalStageTypesCountsInterviewCount
      totalStageTypesCountsOfferCount
      totalStageTypesCountsHiredCount
      totalApplicationCount
    }
  }
`;

export default class AnalyticsOverviewIndexController extends Controller {
  @service declare analytics: AnalyticsService;
  @service declare apollo: ApolloService;
  @service declare flipper: FlipperService;
  @service declare current: Current;
  @service declare store: Store;

  get hasPartnerActivationsWithResults() {
    return (
      (this.fetchPartnerActivations.lastSuccessful?.value?.length || 0) > 0
    );
  }

  fetchPartnerActivations = dropTask(async () => {
    let partnerActivations = this.store.peekAll('partner-activation');
    if (partnerActivations.length) {
      return partnerActivations;
    }

    partnerActivations = await this.store.query('partner-activation', {
      groupAnalytics: this.analytics.hasEnabledGroupAnalytics,
    });

    return partnerActivations;
  });

  fetchSummaryDataForStageTypes = restartableTask(async () => {
    return RSVP.hash({
      stats: this.fetchJobStatsForStageTypes(this.analytics.selectedJobIds),

      averageTimes: this.fetchAverageTimes(this.analytics.selectedJobIds),
    });
  });

  async fetchAverageTimes(jobIds?: string[]): Promise<JobAverageTimes> {
    const filter = jobIds === undefined ? undefined : { ids: jobIds };

    const { pipelineStats }: DurationQueryResponse = await this.apollo.query({
      query: DURATIONS_QUERY,
      variables: {
        userId: this.current.user.id,
        companyIds: this.analytics.availableCompanyIds,
        filter,
      },
    });

    return pipelineStats;
  }

  get hasNoMatchingJobs() {
    const jobIds = this.analytics.selectedJobIds;

    return Array.isArray(jobIds) && !jobIds.length;
  }

  async fetchJobStatsForStageTypes(
    jobIds?: string[]
  ): Promise<JobStatsForStageTypes> {
    const filter = jobIds === undefined ? undefined : { ids: jobIds };

    const { jobs } = await this.apollo.query({
      query: JOB_STATS_QUERY,
      variables: {
        userId: this.current.user.id,
        companyIds: this.analytics.availableCompanyIds,
        filter,
      },
    });

    return {
      totalStats: this.totalStats(jobs),
      stageTypesStats: this.stageTypesStats(jobs),
    };
  }

  totalStats(jobs: any): any {
    return {
      totalJobs: jobs.totalJobsCount,
      totalApplicants: jobs.totalApplicationCount,
      rejectedCount: jobs.totalRejectedCount,
    };
  }

  stageTypesStats(jobs: any): any {
    return {
      inboxCount: {
        count: jobs.totalStageTypesCountsInboxCount,
        category: 'inbox',
      },

      inProcessCount: {
        count: jobs.totalStageTypesCountsInProcessCount,
        category: 'in_process',
      },

      screeningCount: {
        count: jobs.totalStageTypesCountsScreeningCount,
        category: 'screening',
      },

      interviewCount: {
        count: jobs.totalStageTypesCountsInterviewCount,
        category: 'interview',
      },

      offerCount: {
        count: jobs.totalStageTypesCountsOfferCount,
        category: 'offer',
      },

      hiredCount: {
        count: jobs.totalStageTypesCountsHiredCount,
        category: 'hired',
      },
    };
  }

  calculateStageTypeCount(jobs: any, stageType: string): number {
    return jobs.reduce(
      (acc: number, job: any) => acc + job.stageTypesCounts[stageType],
      0
    );
  }
}

declare module '@ember/controller' {
  interface Registry {
    'analytics.overview.index': AnalyticsOverviewIndexController;
  }
}
