import Service, { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import { next } from '@ember/runloop';

export default class ConversationQueryService extends Service {
  @service store;
  @service user;

  perPage = 50;

  @tracked conversations = [];
  @tracked page = 1;
  @tracked totalPages = 1;

  fetchedScopeFilter = null;
  fetchedStatusFilter = null;

  fetchConversationsTask = dropTask(
    async (selectedScopeFilter, selectedStatusFilter, searchString) => {
      if (this.page !== 1 && this.page >= this.totalPages) {
        return;
      }

      if (this.page === 1) {
        this.conversations.clear();
      }

      this.fetchedScopeFilter = selectedScopeFilter;
      this.fetchedStatusFilter = selectedStatusFilter;

      let params = {
        page: this.page,
        per_page: this.perPage,
        scope: selectedScopeFilter,
        status: selectedStatusFilter,
      };

      if (searchString && searchString.length > 1) {
        params.query = searchString;
      }

      const placeholders = new Array(10).fill().map(() => {
        return { id: 'placeholder' };
      });
      this.conversations.pushObjects(placeholders);

      const conversations = await this.store.query('conversation', params);

      this.page = this.page + 1;
      this.totalPages = conversations.meta.total_pages;
      await this.conversations.removeObjects(placeholders);
      await this.conversations.pushObjects(conversations.toArray());
    }
  );

  removeConversation(conversation) {
    this.conversations.removeObject(conversation);

    next(() => {
      conversation.unloadRecord();
    });
  }

  handleConversationAction(conversation, memberAction) {
    const unloadConversation = memberAction !== 'markAsUnread';

    return conversation[memberAction]().then(() => {
      if (unloadConversation) {
        this.removeConversation(conversation);
      }
    });
  }

  handleConversationReassigned(conversation) {
    const isMine =
      get(this.user, 'id') === get(conversation, 'assignedUser.id');
    if (this.fetchedScopeFilter === 'my' && !isMine) {
      this.removeConversation(conversation);
    }
  }
}
