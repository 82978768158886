import { get } from 'teamtailor/utils/get';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';

export default class IndexRoute extends ScrollToTopRoute {
  @service current;
  @service router;
  @service store;

  model() {
    return get(this.current.company, 'auditLog').then((auditLog) => {
      if (auditLog === null) {
        const { company } = this.current;
        return this.store.createRecord('audit-log', { company });
      } else {
        return this.router.transitionTo('settings.audit-log.events');
      }
    });
  }

  deactivate() {
    super.deactivate(...arguments);
    const model = get(this, 'controller.model');

    if (get(model, 'isNew')) {
      model.deleteRecord();
    }
  }
}
