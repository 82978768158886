import ApplicationSerializer from './application';

export default class ContentBlockCustom extends ApplicationSerializer {
  payloadKeyFromModelName() {
    return 'content_block_custom';
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'content-block-custom': ContentBlockCustom;
  }
}
