import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SettingsTagsDeleteController extends Controller {
  @service flashMessages;
  @service intl;
  @service router;

  get tag() {
    return this.model;
  }

  @action
  handleDelete() {
    this.tag
      .destroyRecord()
      .then(() => {
        this.handleClose();
      })
      .catch(() => {
        this.flashMessages.error(
          this.intl.t('settings.tag_manager.couldnt_delete_tag')
        );
      });
  }

  @action
  handleCancel() {
    this.handleClose();
  }

  @action
  handleClose() {
    this.router.transitionTo('settings.tags.index');
  }
}
