import Model, { AsyncBelongsTo, belongsTo, attr } from '@ember-data/model';
import RequisitionStepVerdictModel from './requisition-step-verdict';

export default class RequisitionStepVerdictCommentModel extends Model {
  @belongsTo('requisition-step-verdict')
  declare requisitionStepVerdict: AsyncBelongsTo<RequisitionStepVerdictModel>;

  @attr('string') declare comment: string;
  @attr('date') declare createdAt: Date;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'requisition-step-verdict-comment': RequisitionStepVerdictCommentModel;
  }
}
