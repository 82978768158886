import SmsCounter from 'sms-counter';

export function calculateCredits(smsMessageData, candidatesNumbers) {
  const messageBodySms = smsMessageData.message || '';
  const messageOptOut = smsMessageData.optOutMessage || '';
  const messageReplyTo = smsMessageData.replyMessage || '';

  let totalCredits = _calculateCredits(
    messageBodySms,
    candidatesNumbers.candidatesWithMessageNoSuffix
  );
  totalCredits += _calculateCredits(
    `${messageBodySms}\n${messageOptOut}`,
    candidatesNumbers.candidatesWithMessageOptOutSuffix
  );
  totalCredits += _calculateCredits(
    `${messageBodySms}\n${messageReplyTo}`,
    candidatesNumbers.candidatesWithMessageReplySuffix
  );
  totalCredits += _calculateCredits(
    `${messageBodySms}\n${messageReplyTo}\n${messageOptOut}`,
    candidatesNumbers.candidatesWithMessageOptOutAndReplySuffix
  );

  return totalCredits;
}

function _calculateCredits(body, candidates) {
  const counter = SmsCounter.count(body);
  return counter.messages * candidates;
}

export const MAX_LENGTH = 1440;
