import { modifier } from 'ember-modifier';
import { next } from '@ember/runloop';

function isClickOutside(
  clickOutsideElements: HTMLElement[],
  { clientX, clientY }: MouseEvent
) {
  return clickOutsideElements.every((el) => {
    const rect = el.getBoundingClientRect();
    return (
      clientX < rect.left ||
      clientX > rect.right ||
      clientY < rect.top ||
      clientY > rect.bottom
    );
  });
}

function isRealClick(e: PointerEvent | MouseEvent) {
  return !('pointerType' in e) || e.pointerType !== '';
}

export default modifier<
  HTMLElement,
  [(e: PointerEvent | MouseEvent) => void],
  {
    /**
     * @deprecated Avoid this. It can cause event listeners to not be removed, try adding element(s) to `extraElements` instead?
     */
    leakyDelayAdd?: boolean;
    enabled?: boolean;
  }
>(
  (
    element,
    [handler, extraElements = []],
    { leakyDelayAdd = false, enabled = true } = {}
  ) => {
    const clickOutsideElements = [element, ...extraElements];
    function handleOutsideClick(event: PointerEvent | MouseEvent) {
      if (
        enabled &&
        isRealClick(event) &&
        isClickOutside(clickOutsideElements, event)
      ) {
        handler(event);
      }
    }

    if (leakyDelayAdd) {
      next(() => {
        document.addEventListener('click', handleOutsideClick);
      });
    } else {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }
);
