import ApplicationInstance from '@ember/application/instance';
import RouterService from '@ember/routing/router-service';
import config from 'teamtailor/config/environment';

export function initialize(application: ApplicationInstance) {
  if (config.environment !== 'test') {
    const linkHandler = function (e: MouseEvent) {
      const target = e.target as HTMLElement;
      const link = target.tagName === 'A' ? target : target.closest('a');
      if (link && link instanceof HTMLAnchorElement) {
        const handleLink = new HandleLink({
          event: e,
          target: link,
          router: application.lookup('service:router'),
        });
        handleLink.maybeHandle();
      }
    };

    document.body.addEventListener('click', linkHandler);
  }
}

export default {
  name: 'link-handler',
  after: 'ember-data',
  initialize,
};

class HandleLink {
  event: MouseEvent;
  target: HTMLAnchorElement;
  url: string | null;
  router: RouterService;

  constructor({
    event,
    target,
    router,
  }: {
    event: MouseEvent;
    target: HTMLAnchorElement;
    router: RouterService;
  }) {
    this.event = event;
    this.target = target;
    this.router = router;
    this.url = this.target.getAttribute('href');
  }

  maybeHandle() {
    if (this.shouldHandle()) {
      this.handle();
    }
  }

  shouldHandle() {
    return (
      !this.event.defaultPrevented &&
      this.isUnmodifiedLeftClick() &&
      this.hasSelfTarget() &&
      this.hasNoDownload() &&
      this.recognizeUrl()
    );
  }

  handle() {
    this.router.transitionTo(this.getUrlWithoutRoot());
    this.event.preventDefault();
  }

  isUnmodifiedLeftClick() {
    const e = this.event;

    return e.button === 0 && !e.ctrlKey && !e.metaKey;
  }

  hasSelfTarget() {
    const attr = this.target.getAttribute('target');
    return !attr || attr === '_self';
  }

  hasNoDownload() {
    return !this.target.getAttribute('download');
  }

  recognizeUrl() {
    let didRecognize = false;

    if (this.url) {
      const rootUrl = this._getRootUrl();
      const isInternal = this.url.indexOf(rootUrl) === 0;
      const urlWithoutRoot = this.getUrlWithoutRoot();

      didRecognize = isInternal && !!this.router.recognize(urlWithoutRoot);
    }

    return didRecognize;
  }

  getUrlWithoutRoot() {
    const rootUrl = this._getRootUrl();
    return this.url?.substring(rootUrl.length - 1) || '';
  }

  _getRootUrl() {
    let { rootURL } = this.router;

    if (rootURL.charAt(rootURL.length - 1) !== '/') {
      rootURL = `${rootURL}/`;
    }

    return rootURL;
  }
}
