import { capitalize } from '@ember/string';

export function triggerLabel(t, type, { webhookNoun, name = '', webhookVerb }) {
  webhookNoun = webhookNoun || 'webhook';
  name = name ? `${name} ` : '';
  const webhookDescription = webhookVerb
    ? `${capitalize(webhookVerb)} ${name}${webhookNoun}`
    : t('utils.trigger_text.send_webhook_noun', {
        webhookNoun: `${name}${webhookNoun}`,
      });

  switch (type) {
    case 'message':
      return t('utils.trigger_text.send_message');
    case 'note':
      return t('utils.trigger_text.add_comment');
    case 'nps':
      return t('utils.trigger_text.send_nps_survey');
    case 'tag':
      return t('utils.trigger_text.tag_candidate');
    case 'share':
      return t('utils.trigger_text.share_candidate');
    case 'webhook':
      return webhookDescription;
    case 'smart-move':
      return t('utils.trigger_text.smart_move');
    case 'smart-move-cv_content':
      return t('utils.trigger_text.smart_move_cv_content');
    case 'smart-move-job_offer':
      return t('utils.trigger_text.smart_move_job_offer');
    case 'smart-move-question':
      return t('utils.trigger_text.smart_move_question');
    case 'smart-move-location':
      return t('utils.trigger_text.smart_move_location');
    case 'smart-schedule':
      return t('utils.trigger_text.smart_schedule');
    case 'todo':
      return t('utils.trigger_text.todo_list');
    case 'prediction':
      return t('utils.trigger_text.prediction_smart_move');
    case 'survey':
      return t('utils.trigger_text.survey');
    case 'ask-for-feedback':
      return t('utils.trigger_text.ask_for_feedback');
    case 'restrict':
      return t('utils.trigger_text.restrict');
    case 'nurture':
      return t('utils.trigger_text.nurture');
    case 'hire-quality-survey':
      return t('utils.trigger_text.hire_quality_evaluation');
    default:
      return undefined;
  }
}
