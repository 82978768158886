import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class EditRoute extends ScrollToTopRoute {
  @service store;

  model({ slack_app_id }) {
    return this.store.findRecord('slack-app', slack_app_id);
  }

  deactivate() {
    let controller = get(this, 'controller');
    const { model } = controller;

    if (!model.isDeleted) {
      model.rollbackAttributes();
    }
  }
}
