export default {
  "eventMessage": "ShowContent__eventMessage_1xhzi5",
  "title": "ShowContent__title_1xhzi5",
  "body": "ShowContent__body_1xhzi5",
  "attachments": "ShowContent__attachments_1xhzi5",
  "questions": "ShowContent__questions_1xhzi5",
  "meetingDetails": "ShowContent__meetingDetails_1xhzi5",
  "col": "ShowContent__col_1xhzi5",
  "fieldTitle": "ShowContent__fieldTitle_1xhzi5",
  "fieldIcon": "ShowContent__fieldIcon_1xhzi5",
  "inRow": "ShowContent__inRow_1xhzi5",
  "meetingAction": "ShowContent__meetingAction_1xhzi5",
  "dateGridContainer": "ShowContent__dateGridContainer_1xhzi5"
};
