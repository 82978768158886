import { attr } from '@ember-data/model';
import Section from './section';

export default class ContentBlockTimelineModel extends Section {
  @attr('string') declare title: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'content-block-timeline': ContentBlockTimelineModel;
  }
}
