import Store from '@ember-data/store';
import { set } from '@ember/object';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { Changeset as changeset } from 'ember-changeset';
import ShowController from 'teamtailor/controllers/settings/security/sso/show';
import SingleSignOnModel from 'teamtailor/models/single-sign-on';
import Current from 'teamtailor/services/current';
import { Transition } from 'teamtailor/utils/type-utils';

export default class SettingsSecuritySsoShow extends ScrollToTopRoute {
  @service declare current: Current;
  @service declare router: RouterService;
  @service declare store: Store;

  async model(params: { sso_id: string }): Promise<SingleSignOnModel> {
    const record = this.store.peekRecord('single-sign-on', params.sso_id);

    if (!record) {
      return this.store.findRecord('single-sign-on', params.sso_id);
    }

    return record;
  }

  setupController(
    controller: ShowController,
    model: SingleSignOnModel,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);
    set(controller, 'changeset', changeset(model));
  }

  resetController(controller: ShowController, isExiting: boolean): void {
    if (isExiting) {
      controller.changeset.rollback();
    }
  }
}
