export default {
  "slide": "WrapUp__slide_1uwkkd",
  "slider": "WrapUp__slider_1uwkkd",
  "slide--reverse": "WrapUp__slide--reverse_1uwkkd",
  "masking": "WrapUp__masking_1uwkkd",
  "shadow": "WrapUp__shadow_1uwkkd",
  "border": "WrapUp__border_1uwkkd",
  "hand-wave": "WrapUp__hand-wave_1uwkkd",
  "heart-beat": "WrapUp__heart-beat_1uwkkd",
  "heartbeat": "WrapUp__heartbeat_1uwkkd",
  "feature-background": "WrapUp__feature-background_1uwkkd",
  "feature-recruit": "WrapUp__feature-recruit_1uwkkd",
  "feature-relationships": "WrapUp__feature-relationships_1uwkkd",
  "feature-enterprise": "WrapUp__feature-enterprise_1uwkkd",
  "feature-icon": "WrapUp__feature-icon_1uwkkd",
  "fade-out": "WrapUp__fade-out_1uwkkd",
  "fade": "WrapUp__fade_1uwkkd"
};
