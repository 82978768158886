import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { set, action } from '@ember/object';

export default class RecipientsRoute extends Route {
  @service infinity;

  queryParams = {
    captureType: { refreshModel: true },
    nurtureCampaignStepId: { refreshModel: true },
    sortDirection: { refreshModel: true },
    sortColumn: { refreshModel: true },
    status: { refreshModel: true },
  };

  model({
    captureType,
    nurtureCampaignStepId,
    sortColumn,
    sortDirection,
    status,
  }) {
    return {
      nurtureCampaignRecipients: this.infinity.model(
        'nurture-campaign-recipient',
        {
          capture_type: captureType,
          nurture_campaign_step_id: nurtureCampaignStepId,
          sort_column: sortColumn,
          sort_direction: sortDirection,
          status,
          nurture_campaign_id: this.modelFor(
            'nurture-campaigns.nurture-campaign'
          ).id,
        }
      ),
    };
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    set(
      controller,
      'nurtureCampaign',
      this.modelFor('nurture-campaigns.nurture-campaign')
    );
  }

  @action
  refreshAction() {
    this.refresh();
  }
}
