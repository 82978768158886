import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { AddonFeature } from 'types/features';

export default class RequisitionsRoute extends ScrollToTopRoute {
  @service declare store: Store;

  requiredFeature: AddonFeature = 'requisitions';

  model() {
    return this.store.findAll('requisition-flow');
  }
}
