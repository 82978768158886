import { helper } from '@ember/component/helper';

/*
 * The form can have many different field types (text input, select, radio...)
 * This form loops through the user's answers and displays the string
 * that makes most sense given the field type.
 */
export function getCustomFormAnswer([fieldUuid], { form, answers, answer }) {
  const fieldObj = Object.values(form).find(
    (fieldObj) => fieldObj.uuid === fieldUuid || fieldObj.id === fieldUuid
  );
  const answerValue = answer || (answers && answers[fieldUuid]);

  switch (fieldObj.type) {
    case 'checkbox': {
      return getAnswerFromCheckbox(fieldObj, answerValue);
    }

    case 'select': {
      return getAnswerFromSelect(fieldObj, answerValue);
    }

    case 'radio': {
      return getAnswerFromRadio(fieldObj, answerValue);
    }

    case 'date': {
      return answerValue && answerValue.substr(0, 10);
    }

    default:
      return answerValue;
  }
}

export default helper(getCustomFormAnswer);

const getAnswerFromSelect = (fieldObj, answerValue) => {
  if (fieldObj.allow_multiple_answers) {
    const answerLabels = answerValue?.map((a) => a.label) || [];
    return answerLabels.join(', ');
  } else {
    const options = fieldObj.optgroups
      ? fieldObj.optgroups.map((og) => og.options).flat()
      : fieldObj.options;

    const answerObj = options.find((option) => option.id === answerValue);
    return answerObj?.label;
  }
};

const getAnswerFromCheckbox = (fieldObj, answerValue) => {
  if (fieldObj.options?.length) {
    if (answerValue === null || answerValue === undefined) {
      return '-';
    }

    const answerObjs = fieldObj.options.filter((option) =>
      answerValue.includes(option.id)
    );
    const answerLabels = answerObjs?.map((a) => a.label) || [];
    return answerLabels;
  } else {
    return answerValue;
  }
};

const getAnswerFromRadio = (fieldObj, answerValue) => {
  if (typeof fieldObj.options[0] !== 'string') {
    const answerObj = fieldObj.options.find(
      (option) => option.id === answerValue || option.label === answerValue
    );

    return answerObj && answerObj.label;
  } else {
    return answerValue;
  }
};
