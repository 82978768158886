import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { set, setProperties } from '@ember/object';
import { buildFromInternalName as buildPromotion } from 'teamtailor/models/promotion';
import { inject as service } from '@ember/service';

export default class AdwordsRoute extends Route {
  @service store;

  model() {
    const page = this.modelFor('content.index.page');
    return buildPromotion('google_ads', this.store).then((promotion) => {
      setProperties(promotion, {
        page,
        invoiceInfo: this.store.createRecord('invoice-info'),
      });
      return RSVP.hash({
        channel: promotion.channel,
        promotion,
        page,
      });
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    const { currency } = model.channel;
    set(controller, 'currency', currency);
  }
}
