import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#unless this.isRedundant}}\n  <label class=\"tt-form-label\">\n    <h3 class=\"tt-form-label-text\">\n      {{humanize-string this.fieldKey}}\n    </h3>\n    {{! template-lint-disable deprecated-component }}\n    <FormInput\n      @value={{get (get this.twilio.documents this.docKey) this.fieldKey}}\n      @required={{true}}\n      @inputClass=\"text-14 h-32\"\n    />\n  </label>\n{{/unless}}", {"contents":"{{#unless this.isRedundant}}\n  <label class=\"tt-form-label\">\n    <h3 class=\"tt-form-label-text\">\n      {{humanize-string this.fieldKey}}\n    </h3>\n    {{! template-lint-disable deprecated-component }}\n    <FormInput\n      @value={{get (get this.twilio.documents this.docKey) this.fieldKey}}\n      @required={{true}}\n      @inputClass=\"text-14 h-32\"\n    />\n  </label>\n{{/unless}}","moduleName":"teamtailor/components/settings/sms/text-field.hbs","parseOptions":{"srcName":"teamtailor/components/settings/sms/text-field.hbs"}});
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';

// These fields are not necessary for the Twilio submission
// But we still need to send their keys alongside the document to the API
const REDUNDANT_FIELDS = ['address_sids'];

export default Component.extend({
  classNames: 'tt-form-group',

  didInsertElement() {
    this._super(...arguments);
    if (get(this, 'isRedundant')) {
      set(this, 'twilio.documents', {
        ...get(this, 'twilio.documents'),
        [this.docKey]: {
          [this.fieldKey]: null,
        },
      });
    }
  },

  isRedundant: computed('fieldKey', function () {
    return REDUNDANT_FIELDS.includes(get(this, 'fieldKey'));
  }),
});
