import { htmlSafe } from '@ember/template';
import { SafeString } from '@ember/template/-private/handlebars';
import { tracked } from '@glimmer/tracking';
import { TaskInstance } from 'ember-concurrency';
import { UploadFile } from 'ember-file-upload';
import { v1 as uuid } from 'ember-uuid';
import CandidateModel from 'teamtailor/models/candidate';
import DepartmentModel from 'teamtailor/models/department';
import LocationModel from 'teamtailor/models/location';
import RoleModel from 'teamtailor/models/role';
import TagModel from 'teamtailor/models/tag';

export class QueueItemClass {
  @tracked declare reviewRating: number;
  @tracked declare note?: string;
  @tracked declare hrFlowUploadFileReference?: UploadFile;
  @tracked locations: LocationModel[] = [];
  @tracked declare department?: DepartmentModel;
  @tracked declare role?: RoleModel;
  @tracked tags?: TagModel[] = [];

  @tracked declare model?: CandidateModel;

  @tracked parseError = false;

  @tracked declare candidateId?: string;

  // Candidate props
  @tracked declare email?: string | null;
  @tracked declare firstName?: string | null;
  @tracked declare lastName?: string | null;
  @tracked declare phone?: string | null;
  @tracked declare linkedinUrl?: string | null;
  @tracked declare remoteResumeUrl?: string | null; // renmae
  @tracked declare remotePictureUrl?: string | null; // from cv
  @tracked declare picture?: string | null; // manual uplaod
  @tracked declare uuid: string;

  @tracked parseInstances: TaskInstance<void | Response>[] = [];
  @tracked saveInstances: TaskInstance<void | string>[] = [];

  constructor(department?: DepartmentModel, role?: RoleModel) {
    this.department = department;
    this.role = role;
    this.uuid = uuid();
  }

  get initials(): string | undefined {
    return this.nameOrEmail
      ?.split(' ')
      ?.map((n) => n[0])
      ?.join('')
      .toString();
  }

  get nameOrEmail(): string | undefined {
    if (this.firstName) {
      return `${this.firstName} ${this.lastName ?? ''}`;
    }

    return this.email ?? undefined;
  }

  /**
   * Returns all errors reported by task instances.
   */
  get errors(): unknown[] | undefined[] {
    return this.taskInstances?.map((q) => q.error) ?? [];
  }

  /**
   * Returns the last error of a failed task instance.
   * This is an any...
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get lastError(): any {
    if (this.taskInstances?.lastObject?.isError) {
      return this.taskInstances.lastObject.error;
    }
  }

  /**
   * Collection of both parse and save task instances.
   * This is an any[]
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get taskInstances(): TaskInstance<any>[] | undefined {
    return [...this.parseInstances, ...this.saveInstances];
  }

  clearTasks() {
    this.parseInstances.clear();
    this.saveInstances.clear();
  }

  /**
   * Any of the parse or save task instances is currently running.
   */
  get isRunning(): boolean {
    if (
      this.taskInstances?.length &&
      this.taskInstances.isAny('isRunning', true)
    ) {
      return true;
    }

    return false;
  }

  /**
   * Model is already saved, but it is now also visible in the company, that is not hidden from search.
   */
  get isPersisted(): boolean {
    return this.candidateId !== undefined;
  }

  /**
   * Currently in running or queued state.
   */
  get isProcessing(): boolean {
    if (this.taskInstances?.length === 0) return false;
    if (this.isRunning) return true;
    if (this.taskInstances?.lastObject?.hasStarted === false) return true;
    return false;
  }

  get pdfUrl(): SafeString {
    return htmlSafe(
      `${this.remoteResumeUrl}#toolbar=0&navpanes=0&scrollbar=0&zoom=100&view=fitH`
    );
  }

  /**
   * Did a task instance or the backend report an error.
   */
  get hasErrors(): boolean {
    return this.lastError !== undefined;
  }

  get isDirty(): boolean {
    return !!(
      this.email ||
      this.firstName ||
      this.lastName ||
      this.hrFlowUploadFileReference
    );
  }
}
