import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import RecentVisitsService from 'teamtailor/services/recent-visits';
import RouterService from '@ember/routing/router-service';
import EditorRoute from '../editor';
import { get } from 'teamtailor/utils/get';
import { hash } from 'rsvp';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import Store from '@ember-data/store';
import { PageModel } from 'teamtailor/models';

export default class ContentEditorIndexRoute extends Route {
  @service declare recentVisits: RecentVisitsService;
  @service declare router: RouterService;
  @service declare store: Store;

  queryParams = {
    site: { refreshModel: false },
    returnedFromSectionId: {},
  };

  async model() {
    return hash({
      editorModel: this.modelFor('content.editor') as ModelFrom<EditorRoute>,
      allBlockLayouts: this.store.findAll('block-layout'),
    });
  }

  afterModel(model: Awaited<ReturnType<ContentEditorIndexRoute['model']>>) {
    const { editorModel } = model;
    this.recentVisits.log(editorModel.pageRecord);

    const pageType =
      editorModel.pageRecord &&
      'pageType' in editorModel.pageRecord &&
      get(editorModel.pageRecord, 'pageType');

    if (pageType) {
      if (pageType === 'lead') {
        const page = editorModel.pageRecord as PageModel;
        get(page, 'sections')?.then(() => {
          const sectionId = page.hasMany('sections').ids()[0];
          if (!sectionId) {
            return;
          }

          this.router.replaceWith(
            'content.editor.section.index',
            'pages',
            get(page, 'id'),
            sectionId
          );
        });
      }

      if (pageType === 'post') {
        this.router.replaceWith(
          'content.posts.edit',
          get(editorModel.pageRecord as PageModel, 'id')
        );
      }
    }
  }
}
