import Store from '@ember-data/store';
import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { RequisitionFlowModel } from 'teamtailor/models';
import SettingsRequisitionsIndexRoute from 'teamtailor/routes/settings/requisitions/index';
import Current from 'teamtailor/services/current';
import TtAlertService from 'teamtailor/services/tt-alert';
import { get } from 'teamtailor/utils/get';
import { ModelFrom } from 'teamtailor/utils/type-utils';

export default class SettingsRequisitionsIndexController extends Controller {
  declare model: ModelFrom<SettingsRequisitionsIndexRoute>;

  @service declare current: Current;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare ttAlert: TtAlertService;

  get company() {
    return this.current.company;
  }

  get hasDefaultFlow() {
    if (!this.defaultFlow) {
      return false;
    }

    return !get(this.defaultFlow, 'isNew');
  }

  get defaultFlow() {
    return this.model.findBy('isDefault', true);
  }

  get customFlows() {
    return this.model.filterBy('isDefault', false);
  }

  @action
  async toggleRequisitionSetting(value: boolean) {
    const setting = get(this.current.company, 'requisitionSetting');
    set(setting, 'enabledByCompany', value);
    await setting.save();
  }

  @action
  handleStartEditing(requisitionFlow: RequisitionFlowModel) {
    this.router.transitionTo(
      'settings.requisitions.index.edit',
      requisitionFlow.id
    );
  }

  @action
  addFlow() {
    this.router.transitionTo('settings.requisitions.index.new');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.requisitions.index': SettingsRequisitionsIndexController;
  }
}
