import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import IntlService from 'ember-intl/services/intl';
import { PagePublicationModel, RegionModel } from 'teamtailor/models';
import computeFullErrorMessages from 'teamtailor/utils/full-error-messages';
import { get } from 'teamtailor/utils/get';
import TranslationLocationModel from './translation/location';

export default class LocationModel extends Model {
  @service declare intl: IntlService;

  @belongsTo('page-publication')
  declare pagePublication: AsyncBelongsTo<PagePublicationModel>;

  @belongsTo('region') declare region: AsyncBelongsTo<RegionModel>;

  @hasMany('translation/location')
  declare translations: AsyncHasMany<TranslationLocationModel>;

  @attr('boolean') declare headquarters: boolean;
  @attr('boolean') declare hideOnCareersite: boolean;
  @attr('number') declare lat: number;
  @attr('number') declare long: number;
  @attr('string') declare address: string;
  @attr('string') declare administrativeArea: string;
  @attr('string') declare city: string;
  @attr('string') declare country: string;
  @attr('string') declare countryCode: string;
  @attr('string') declare email: string;
  @attr('string') declare languageCode: string;
  @attr('string') declare name: string;
  @attr('string') declare phone: string;
  @attr('string') declare zip: string;

  get fullErrorMessages() {
    return computeFullErrorMessages(this, this.intl);
  }

  get nameOrCity() {
    return this.name || this.city;
  }

  get withoutRegion() {
    return !get(this.region, 'id');
  }

  get fullAddress() {
    return [
      get(this, 'address'),
      `${get(this, 'zip') || ''} ${get(this, 'city') || ''}`.trim(),
      get(this, 'country'),
    ]
      .filter((row) => !isEmpty(row))
      .join(', ')
      .trim();
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    location: LocationModel;
  }
}
