import ENV from 'teamtailor/config/environment';
import AnalyticsRequestService from 'teamtailor/services/analytics-request';

export default class InsightsService extends AnalyticsRequestService {
  get host(): string {
    return ENV.INSIGHTS_HOST_URL;
  }
}

declare module '@ember/service' {
  interface Registry {
    insights: InsightsService;
  }
}
