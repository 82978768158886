import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-mixins
import SearchQuery from 'teamtailor/mixins/search-query';
import { set, get, setProperties, action } from '@ember/object';
import { task, timeout, dropTask } from 'ember-concurrency';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import { isPresent, isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { isUnfinishedAdvancedSearch } from 'teamtailor/utils/advanced-search';

export default class JobsListController extends Controller.extend(SearchQuery) {
  @service intl;
  @service user;
  @service current;
  @service router;
  @service permissions;
  @service flipper;
  @service table;
  @service store;

  @tracked showBulk = false;
  @tracked selectAll = false;
  @tracked deselectedJobIds = [];
  @tracked selectedJobIds = [];

  queryParams = [
    'department_id',
    'job_tag_id',
    'location_id',
    'region_id',
    'page',
    'per_page',
    'remote_status_id',
    'query',
    'sort',
    'status',
    'user_id',
    'internal',
    'custom_fields',
    'language_code',
    'current_user_jobs',
    'ids',
  ];

  get usingRequisitions() {
    const { requisitionSetting } = this.current.company;
    return requisitionSetting?.id && requisitionSetting?.enabledByCompany;
  }

  @tracked query = '';
  @tracked department_id = '';
  @tracked job_tag_id = '';
  @tracked location_id = '';
  @tracked region_id = '';
  @tracked remote_status_id = '';
  @tracked internal = '';
  @tracked page = 1;
  @tracked per_page = 20;
  @tracked status = '';
  @tracked user_id = '';
  @tracked ids = [];
  @tracked defaultSort = 'pinned-desc';
  @tracked sort = this.defaultSort;
  @tracked language_code = '';
  @tracked current_user_jobs = '';
  @tracked custom_fields = [];

  @computedLocalStorage(Boolean, 'JobsList.showFilterSidebar')
  showFilterSidebar;

  get hasLocationEmpty() {
    return this.location_id === '';
  }

  get hasRegionEmpty() {
    return this.region_id === '';
  }

  get hasDepartmentEmpty() {
    return this.department_id === '';
  }

  get hasJobTagEmpty() {
    return this.job_tag_id === '';
  }

  get hasStatusDefault() {
    return this.status === '';
  }

  get hasUserIdEmpty() {
    return this.user_id === '';
  }

  get hasRemoteStatusIdEmpty() {
    return this.remote_status_id === '';
  }

  get hasAudienceEmpty() {
    return this.internal === '';
  }

  get hasCurrentUserJobsEmpty() {
    return isEmpty(this.current_user_jobs);
  }

  get hasLocation() {
    return !this.hasLocationEmpty;
  }

  get hasRegion() {
    return !this.hasRegionEmpty;
  }

  get hasDepartment() {
    return !this.hasDepartmentEmpty;
  }

  get hasJobTag() {
    return !this.hasJobTagEmpty;
  }

  get hasNotStatusDefault() {
    return !this.hasStatusDefault;
  }

  get hasUserId() {
    return !this.hasUserIdEmpty;
  }

  get hasRemoteStatusId() {
    return !this.hasRemoteStatusIdEmpty;
  }

  get hasAudience() {
    return !this.hasAudienceEmpty;
  }

  get hasQueryEmpty() {
    return this.query === '';
  }

  get hasQuery() {
    return !this.hasQueryEmpty;
  }

  get hasQueryOrSearchProxy() {
    return this.hasQuery || this.searchProxy;
  }

  get hasCurrentUserJobs() {
    return !this.hasCurrentUserJobsEmpty;
  }

  get hasLanguageCode() {
    return !!this.language_code;
  }

  get searchParams() {
    return {
      ids: this.ids,
      status: this.status === '' ? 'open' : this.status,
      query: this.query,
      department_id: this.department_id,
      location_id: this.location_id,
      region_id: this.region_id,
      job_tag_id: this.job_tag_id,
      user_id: this.user_id,
      remote_status_id: this.remote_status_id,
      internal: this.internal,
      custom_fields: this.custom_fields,
      language_code: this.language_code,
      recruiter_or_team_member: this.current_user_jobs,
    };
  }

  get newJobRoute() {
    if (
      get(this.current.company, 'requisitionSetting.enabledByCompany') &&
      get(this.current.company, 'canCreateRequisition') &&
      get(this, 'permissions').has('requisition/create')
    ) {
      return 'requisitions.new';
    } else if (get(this, 'permissions').has('job/create')) {
      return 'jobs.index.jobs-list.templates';
    }

    return null;
  }

  get filterSelected() {
    return (
      this.hasLocation ||
      this.hasRegion ||
      this.hasDepartment ||
      this.hasJobTag ||
      this.hasUserId ||
      this.hasNotStatusDefault ||
      this.hasRemoteStatusId ||
      this.hasAudience ||
      this.hasCustomFieldValues ||
      this.hasLanguageCode ||
      this.hasCurrentUserJobs
    );
  }

  get customFields() {
    return get(this.current.company, 'searchableJobCustomFields');
  }

  toggleFilter = false;

  get totalPages() {
    return this.fetchJobs.last?.value?.meta?.total_pages;
  }

  get totalCount() {
    return this.fetchJobs.last?.value?.meta?.total_count;
  }

  get companyHasJobs() {
    return this.fetchJobs.last?.value?.meta?.has_jobs;
  }

  get hasCustomFieldValues() {
    return this.custom_fields.length > 0;
  }

  get showPagination() {
    return this.totalPages && this.totalPages > 1;
  }

  get companyHasRegions() {
    return isPresent(get(this.current.company, 'regions'));
  }

  get selectedCount() {
    if (this.selectAll) {
      return this.totalCount - this.deselectedJobIds.length;
    } else {
      return this.selectedJobIds.length;
    }
  }

  get selectedUser() {
    let userId = get(this, 'user_id');
    return userId ? get(this, 'store').peekRecord('user', userId) : null;
  }

  set selectedUser(value) {
    set(this, 'user_id', get(value, 'id'));
  }

  get selectedJobs() {
    if (this.selectAll) {
      return this.store
        .peekAll('job')
        .filter((job) => !this.deselectedJobIds.includes(job.id));
    } else if (this.selectedJobIds.length) {
      return this.selectedJobIds.map((id) => {
        return this.store.peekRecord('job', id);
      });
    }
  }

  getCustomFieldValue(customFieldId) {
    let cf = this.custom_fields.find(
      (cf) => cf.custom_field_id === customFieldId
    );
    let keys = Object.keys(cf).filter((k) => k !== 'custom_field_id');
    if (keys?.length > 0) {
      return cf[keys[0]];
    }

    return undefined;
  }

  handleSearchInput = task(
    {
      restartable: true,
    },
    async (value) => {
      await timeout(get(this, 'searchProxyDelay'));

      if (value && value.length) {
        if (isUnfinishedAdvancedSearch(value)) {
          return;
        }

        setProperties(this, {
          page: 1,
          query: value,
        });
      } else {
        set(this, 'query', '');
      }
    }
  );

  fetchJobs = dropTask(async (clearSelection) => {
    const params = {
      ...this.searchParams,
      page: this.page,
      per_page: this.per_page,
      sort: this.sort,
    };

    if (clearSelection) {
      this.clearSelection();
    }

    const jobs = await this.store.query('job', params);
    return jobs;
  });

  refetchJobs() {
    if (this.page !== 1) {
      set(this, 'page', 1);
      this.clearSelection();
    } else {
      this.fetchJobs.perform(true);
    }
  }

  reloadStages() {
    this.selectedJobs.forEach((job) => {
      const hiredStage = get(job, 'hiredStage');
      if (hiredStage) {
        hiredStage.reload();
      }
    });

    this.clearSelection();
  }

  @action
  didInsert() {
    if (!this.fetchJobs.last || this.table.refreshJobsTable) {
      this.fetchJobs.perform(false);
      this.table.refreshJobsTable = false;
    }
  }

  @action
  clearFilters() {
    set(this, 'location_id', '');
    set(this, 'region_id', '');
    set(this, 'department_id', '');
    set(this, 'job_tag_id', '');
    set(this, 'status', '');
    set(this, 'user_id', '');
    set(this, 'remote_status_id', '');
    set(this, 'internal', '');
    set(this, 'language_code', '');
    set(this, 'current_user_jobs', '');
    set(this, 'custom_fields', []);
  }

  @action
  selectItem(attribute, selected) {
    if (attribute === 'current_user_jobs') {
      const value = !get(this, attribute);
      set(this, attribute, value ? value : '');
    } else {
      set(this, attribute, selected ? get(selected, 'id') : '');
    }

    this.resetPage();
  }

  @action
  selectCustomFieldItem(customFieldId, query_type, value) {
    let { custom_fields } = this;
    let updated = false;
    custom_fields = custom_fields.map((cf) => {
      if (cf.custom_field_id === customFieldId) {
        cf[query_type] = value;
        updated = true;
      }

      return cf;
    });
    if (!updated) {
      custom_fields.push({
        custom_field_id: customFieldId,
        [query_type]: value,
      });
    }

    custom_fields = custom_fields.filter((cf) => cf[query_type] !== null);

    set(this, 'custom_fields', custom_fields);
  }

  @action
  selectCustomFieldOption(customFieldId, query_type, option) {
    if (Array.isArray(option)) {
      this.selectCustomFieldItem(
        customFieldId,
        query_type,
        option.map((o) => o.id)
      );
    } else {
      this.selectCustomFieldItem(
        customFieldId,
        query_type,
        option ? option.id : null
      );
    }
  }

  @action
  setCustomFieldRange(customFieldId, query_name, start_range, end_range) {
    if (start_range) {
      this.selectCustomFieldItem(
        customFieldId,
        `${query_name}_start`,
        start_range
      );
    }

    if (end_range) {
      this.selectCustomFieldItem(customFieldId, `${query_name}_end`, end_range);
    }
  }

  @action
  setPage(page) {
    set(this, 'page', page);
    window.scrollTo(0, 0);
  }

  @action
  resetPage() {
    set(this, 'page', 1);
  }

  @action
  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    this.deselectedJobIds.clear();
    this.selectedJobIds.clear();
  }

  @action
  clearSelection() {
    this.selectAll = false;
    this.deselectedJobIds.clear();
    this.selectedJobIds.clear();
  }

  @action
  onAfterBulkChange(reloadStages) {
    if (reloadStages) {
      this.reloadStages();
    } else {
      this.refetchJobs();
    }
  }

  @action
  toggleShowBulk() {
    if (this.showBulk) {
      this.clearSelection();
    }

    this.showBulk = !this.showBulk;
  }
}
