import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import { CompanyModel } from 'teamtailor/models';

export default class DashboardSettingModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @attr('string') gifSearchTerm?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'dashboard-setting': DashboardSettingModel;
  }
}
