import Controller from '@ember/controller';
import { timeout, restartableTask } from 'ember-concurrency';
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { query } from 'ember-data-resources';
import { EXTERNAL_RECRUITER } from 'teamtailor/constants/user-role';

export default class SettingsExternalRecruitmentRecruitersController extends Controller {
  @service current;

  queryParams = [
    'page',
    'query',
    {
      recruitingFirmId: 'recruiting_firm_id',
    },
  ];

  externalRecruitersQuery = query(this, 'user', () => {
    return {
      page: this.page || 1,
      query: this.query,
      role: EXTERNAL_RECRUITER,
      recruiting_firm_id: this.recruitingFirmId,
    };
  });

  @tracked _page = 1;
  @tracked _query = '';
  @tracked recruitingFirmId = '';

  get company() {
    return this.current.company;
  }

  get query() {
    return this._query;
  }

  set query(value) {
    this._query = value;
  }

  get page() {
    return this._page;
  }

  set page(value) {
    this._page = value;
  }

  get meta() {
    return this.externalRecruitersQuery.records?.meta;
  }

  get totalCount() {
    return this.meta?.total_count;
  }

  get totalPages() {
    return this.meta?.total_pages;
  }

  get showPagination() {
    return this.totalPages > 1;
  }

  get selectedRecruitingFirm() {
    return get(this.company, 'recruitingFirms').findBy(
      'id',
      this.recruitingFirmId
    );
  }

  @action
  setPage(page) {
    this._page = page;
    window.scrollTo(0, 0);
  }

  @action
  handleRecruitingFirmChanged(recruitingFirm) {
    this.recruitingFirmId = recruitingFirm ? recruitingFirm.id : '';
  }

  setQueryTask = restartableTask(async (value) => {
    await timeout(300);
    this._query = value;
    this.page = 1;
  });
}
