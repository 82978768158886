import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<h3 class=\"heading-4\" {{this.bindKeyboardEvents}}>\n  {{t\n    (concat\n      \"candidates.candidate.candidate_modal.aside.\" @currentTab.translationKey\n    )\n  }}\n</h3>", {"contents":"<h3 class=\"heading-4\" {{this.bindKeyboardEvents}}>\n  {{t\n    (concat\n      \"candidates.candidate.candidate_modal.aside.\" @currentTab.translationKey\n    )\n  }}\n</h3>","moduleName":"teamtailor/components/candidate-modal-new/sidebar-header/index.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-modal-new/sidebar-header/index.hbs"}});
import Component from '@glimmer/component';
import { filteredTabs, Tab } from 'teamtailor/utils/candidate-modal/tabs';
import isMac from 'teamtailor/utils/is-mac';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { modifier } from 'ember-modifier';
import isBetween from 'teamtailor/utils/is-between';

interface Args {
  onTabChange: (tab: string, autofocus: boolean) => void;
  currentTab: string;
}

export default class extends Component<Args> {
  @service declare current: Current;

  isMac = isMac;
  declare tabs;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.tabs = filteredTabs(this.current.company);
  }

  bindKeyboardEvents = modifier(() => {
    document.addEventListener('keydown', this.quickNavigate.bind(this));

    return () => {
      document.removeEventListener('keydown', this.quickNavigate.bind(this));
    };
  });

  quickNavigate(event: KeyboardEvent) {
    const numKey = parseInt(event.key, 10);
    const additionalKey = this.isMac ? event.ctrlKey : event.altKey;
    const tabsCount = this.tabs.length;

    if (isNaN(numKey) || !isBetween(numKey, 1, tabsCount) || !additionalKey) {
      return;
    }

    const tab = this.tabs[numKey - 1] as unknown as Tab;
    event.preventDefault();
    this.args.onTabChange(tab.name, false);
  }
}
