import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { isPresent } from '@ember/utils';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SetupRoute extends ScrollToTopRoute {
  @service current;
  @service router;

  model() {
    return get(this.current.company, 'dataPrivacySetting');
  }

  afterModel(model) {
    if (isPresent(get(model, 'setupRunAt'))) {
      this.router.transitionTo('settings.data_privacy_settings');
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    set(controller, 'pickedBulkAction', null);
    set(controller, 'step', 1);
  }
}
