import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { get, action } from '@ember/object';

export default class ApprovalSettingsController extends Controller {
  @service intl;
  @service flashMessages;

  @action
  save() {
    const promise = get(this, 'model').save();
    promise.then(
      () => {
        get(this, 'flashMessages').success(
          this.intl.t('settings.approval_settings.success_message')
        );
      },
      () => {
        get(this, 'flashMessages').error(
          this.intl.t('settings.approval_settings.error_message')
        );
      }
    );

    return promise;
  }

  @action
  toggleApprover(user) {
    if (get(this, 'model.users').findBy('id', get(user, 'id'))) {
      get(this, 'model.users').removeObject(user);
    } else {
      get(this, 'model.users').pushObject(user);
    }
  }
}
