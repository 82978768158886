// helpers/includes-url.ts
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';

export default class IncludesUrlHelper extends Helper {
  @service router!: RouterService;

  compute([substring]: [string]): boolean {
    let currentUrl = this.router.currentURL;
    return currentUrl.includes(substring);
  }
}
