import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { set, action } from '@ember/object';
import { get } from 'teamtailor/utils/get';

export default class EditController extends Controller {
  @service intl;
  @service ttAlert;
  @service current;
  @service router;

  get location() {
    return this.model;
  }

  get numberOfSelectedImages() {
    return get(this.location, 'pickedImages').rejectBy('isDeleted', true)
      .length;
  }

  get canAddMoreImages() {
    return this.numberOfSelectedImages < 6;
  }

  @action
  handleDropImage(image, position) {
    set(image, 'rowOrderPosition', position);
  }

  @action
  handleRemoveImage(image) {
    image.deleteRecord();
  }

  @action
  handlePickImage(image) {
    get(this, 'location.pickedImages').createRecord({ image });
  }

  @action
  deleteUnsavedPickedImages() {
    get(this, 'location.pickedImages')
      .filterBy('isNew', true)
      .forEach((pickedImage) => pickedImage.unloadRecord());
  }

  @action
  delete(model) {
    get(this, 'ttAlert').confirm(
      this.intl.t('content.editor.edit.are_you_sure_you_want_to_delete_this'),
      () => {
        model.destroyRecord().then(() => {
          this.router.transitionTo(
            'careersite.content.section',
            get(this.current.company, 'sectionsByName.locations')
          );
        });
      },
      () => {}
    );
  }
}
