import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';
import { UserModel } from 'teamtailor/models';

export default class GiftModel extends Model {
  @belongsTo('user', { inverse: 'gifts' })
  declare user: AsyncBelongsTo<UserModel>;

  @attr('boolean', { defaultValue: false }) declare isCompanyAddress: boolean;
  @attr('string') declare addressCity: string;
  @attr('string') declare addressCountry: string;
  @attr('string') declare addressName: string;
  @attr('string') declare addressPostalCode: string;
  @attr('string') declare addressStreet: string;
  @attr('string') declare product: string;
  @attr('string') declare status: string;
  @attr('string', { defaultValue: 'm' }) declare size: string;

  @tracked isVisible = false;

  get isNotValid() {
    return (
      isEmpty(this.addressName) ||
      isEmpty(this.addressStreet) ||
      isEmpty(this.addressPostalCode) ||
      isEmpty(this.addressCity) ||
      isEmpty(this.addressCountry) ||
      isEmpty(this.size)
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    gift: GiftModel;
  }
}
