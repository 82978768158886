import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    pickedImage: {
      serialize: 'records',
      deserialize: 'ids',
      key: 'picked_image_attributes',
    },
  },
});
