export default {
  "row": "ItemRow__row_16471x",
  "label": "ItemRow__label_16471x",
  "value": "ItemRow__value_16471x",
  "color": "ItemRow__color_16471x",
  "action": "ItemRow__action_16471x",
  "noLabel": "ItemRow__noLabel_16471x",
  "noColor": "ItemRow__noColor_16471x",
  "noLabelOrColor": "ItemRow__noLabelOrColor_16471x"
};
