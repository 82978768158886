import MeetingNewRoute from 'teamtailor/routes/meetings/new';

export default class JobsJobActivityCandidateMeetingNewRoute extends MeetingNewRoute {
  controllerName = 'meetings.new';
  templateName = 'meetings.new';

  meetingRouteRouteName = 'jobs.job.activity.candidate.meetings';
  candidateRouteRouteName = 'jobs.job.activity.candidate';
  jobRouteRouteName = 'jobs.job';
}
