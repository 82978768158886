import { inject as service } from '@ember/service';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get, set } from '@ember/object';
import DomainModel from 'teamtailor/models/domain';

export default class DomainRoute extends ScrollToTopRoute {
  @service current;
  @service pusher;
  @service user;
  @service store;
  @service server;

  channel = null;

  model() {
    return get(this.current.company, 'domain').then(async (domain) => {
      if (domain) {
        return domain;
      } else {
        return this.store.createRecord('domain', {
          company: this.current.company,
          digest: await this.fetchDigest(),
        });
      }
    });
  }

  async fetchDigest() {
    const { digest } = await this.server.collectionAction({
      modelName: DomainModel.modelName,
      action: 'digest',
      method: 'GET',
    });

    return digest;
  }

  afterModel() {
    get(this, 'pusher')
      .channel(get(this, 'user.notificationsChannel'))
      .then((channel) => {
        set(this, 'channel', channel);
        channel.bind('domain-status-changed', (domain) => {
          this.store.pushPayload('domain', domain);
        });
      });
  }

  deactivate() {
    let controller = get(this, 'controller');
    let channel = get(this, 'channel');

    if (channel) {
      channel.unbind('domain-status-changed');
    }

    get(controller, 'model').rollbackAttributes();
  }
}
