import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import CurrentService from 'teamtailor/services/current';
import RouterService from '@ember/routing/router-service';
import FlashMessageService from 'teamtailor/services/flash-message';
import FlipperService from 'teamtailor/services/flipper';
import TranslationInterviewKitModel from 'teamtailor/models/translation/interview-kit';
import { get } from 'teamtailor/utils/get';
import { InterviewKitModel } from 'teamtailor/models';

export default class NewController extends Controller {
  @service declare current: CurrentService;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;
  @service declare flipper: FlipperService;

  get interviewKit() {
    return this.model;
  }

  get currentLanguageCode() {
    return get(this.current.company.defaultCareerSite, 'languageCode');
  }

  async saveTranslations() {
    let promises: Promise<TranslationInterviewKitModel>[] = [];

    promises = this.interviewKit.translations
      .filter((translation: TranslationInterviewKitModel) => {
        return (
          get(translation, 'hasDirtyAttributes') &&
          translation.languageCode !== this.currentLanguageCode
        );
      })
      .map((translation: TranslationInterviewKitModel) => {
        return translation.save();
      });

    return Promise.all(promises);
  }

  @action
  handleSave() {
    this.interviewKit.competenceOrder = [...this.interviewKit.competenceOrder];
    return this.interviewKit
      .save()
      .then(async (interviewKit: InterviewKitModel) => {
        await this.saveTranslations();
        this.flashMessages.success('Kit created');
        this.router.transitionTo('settings.interview-kits.index');
        interviewKit.deleteNewAssociations();
        interviewKit.tags
          .filterBy('hasDirtyAttributes')
          .invoke('rollbackAttributes');
      });
  }

  @action
  handleCancel() {
    this.router.transitionTo('settings.interview-kits');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.interview-kits.new': NewController;
  }
}
