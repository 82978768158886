import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import Controller from '@ember/controller';

export default class TodoTemplatesNewRoute extends ScrollToTopRoute {
  @service declare store: Store;

  model() {
    return this.store.createRecord('todo-template');
  }

  resetController(controller: Controller, isExiting: boolean) {
    const { model } = controller;
    if (isExiting && model.isNew) {
      model.destroyRecord();
    }
  }
}
