import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @user.nameOrEmail}}\n  <UserImage\n    @user={{@user}}\n    @centered={{true}}\n    @tooltip={{this.tooltipText}}\n    @onlineBeaconPosition={{@userOnlineBeaconPosition}}\n  />\n{{else if @partner.id}}\n  <PartnerImage\n    @size=\"24\"\n    @partner={{@partner}}\n    @tooltip={{this.tooltipText}}\n  />\n{{else if @author.author}}\n  <CandidateImage\n    @size=\"24\"\n    @src={{@author.robohashUrl}}\n    @tooltip={{this.tooltipText}}\n  />\n{{else}}\n  <div class=\"flex size-24 items-center justify-center\">\n    <Icon @icon=\"user\" />\n  </div>\n{{/if}}", {"contents":"{{#if @user.nameOrEmail}}\n  <UserImage\n    @user={{@user}}\n    @centered={{true}}\n    @tooltip={{this.tooltipText}}\n    @onlineBeaconPosition={{@userOnlineBeaconPosition}}\n  />\n{{else if @partner.id}}\n  <PartnerImage\n    @size=\"24\"\n    @partner={{@partner}}\n    @tooltip={{this.tooltipText}}\n  />\n{{else if @author.author}}\n  <CandidateImage\n    @size=\"24\"\n    @src={{@author.robohashUrl}}\n    @tooltip={{this.tooltipText}}\n  />\n{{else}}\n  <div class=\"flex size-24 items-center justify-center\">\n    <Icon @icon=\"user\" />\n  </div>\n{{/if}}","moduleName":"teamtailor/components/interview-kit/interviewer-image.hbs","parseOptions":{"srcName":"teamtailor/components/interview-kit/interviewer-image.hbs"}});
import { get } from 'teamtailor/utils/get';
import Component from '@glimmer/component';
import UserModel from 'teamtailor/models/user';
import PartnerModel from 'teamtailor/models/partner';

type InterviewerImageArgs = {
  user?: UserModel;
  partner?: PartnerModel;
  author?: {
    author: string;
  };
  interviewKitName?: string;
};

export default class InterviewerImage extends Component<InterviewerImageArgs> {
  get tooltipText(): string {
    const { user, author, partner, interviewKitName } = this.args;
    const interviewKitText = interviewKitName ? ` (${interviewKitName})` : '';

    if (user && get(user, 'nameOrEmail')) {
      return `${get(user, 'nameOrEmail')}${interviewKitText}`;
    } else if (partner && get(partner, 'name')) {
      return `${get(partner, 'name')}${interviewKitText}`;
    } else if (author) {
      return `${author.author}${interviewKitText}`;
    }

    return '';
  }
}
