import Store from '@ember-data/store';
import Controller from '@ember/controller';
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { dasherize } from '@ember/string';
import IntlService from 'ember-intl/services/intl';
import FeatureModel from 'teamtailor/models/feature';
import FromRoute from 'teamtailor/routes/features/show/feature';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntercomService from 'teamtailor/services/intercom';
import ModalService from 'teamtailor/services/modal';
import PermissionsService from 'teamtailor/services/permissions';
import {
  TransitionDirection,
  TransitionHelper,
} from 'teamtailor/utils/features';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import { get } from 'teamtailor/utils/get';
import Current from 'teamtailor/services/current';

export default class FeatureShowFeatureIndexController extends Controller {
  declare model: ModelFrom<FromRoute>;

  @service declare current: Current;
  @service declare flashMessages: FlashMessageService;
  @service declare intercom: IntercomService;
  @service declare intl: IntlService;
  @service declare modal: ModalService;
  @service declare permissions: PermissionsService;
  @service declare router: RouterService;
  @service declare store: Store;

  get nestedModalTarget(): Element | undefined {
    return (
      document.getElementById('ember-teamtailor-modal-on-modal') ?? undefined
    );
  }

  get showConfigure(): boolean {
    if (!this.feature) {
      return false;
    }

    const hasConfig =
      this.feature.hasMandatoryConfiguration ||
      this.feature.hasOptionalConfiguration;

    return (
      this.userCanModify &&
      this.feature.isEnabledByCompany &&
      hasConfig &&
      this.router.currentRoute.localName === 'success'
    );
  }

  get feature(): FeatureModel | undefined {
    return this.model.feature;
  }

  get animationDirection(): TransitionDirection {
    return TransitionHelper.getInstance().direction;
  }

  get userCanModify(): boolean {
    return (
      this.permissions.has('feature/enable', 'full') &&
      !this.feature?.isUpcoming
    );
  }

  get featureEnabled(): boolean {
    return !!this.feature?.isEnabledByCompany;
  }

  get showRequestDeactivateButton(): boolean {
    if (this.userCanModify) {
      return (
        this.featureEnabled &&
        !this.feature?.notToggable &&
        !this.feature?.needContactSupport
      );
    }

    return false;
  }

  get showActivateButton(): boolean {
    if (this.userCanModify) {
      return (
        !this.featureEnabled &&
        !this.feature?.notToggable &&
        !!this.feature?.parentFeatureEnabled &&
        !this.feature.needContactSupport
      );
    }

    return false;
  }

  get showManagerButton(): boolean {
    if (this.userCanModify) {
      return (
        !!this.feature?.needContactSupport && !this.feature.isEnabledByCompany
      );
    }

    return false;
  }

  get showConfigureButton(): boolean {
    if (this.userCanModify) {
      return (
        !!this.feature?.hasSettings &&
        (this.featureEnabled || this.feature.notToggable)
      );
    }

    return false;
  }

  get showRequestFromAdminButton(): boolean {
    if (this.userCanModify || this.feature?.isSourcingExtension) {
      return false;
    }

    return !this.featureEnabled && !this.feature?.isUpcoming;
  }

  transitionToFeature(featureId?: string): void {
    assert('id must exist', featureId);

    const feature = this.store.peekRecord('feature', featureId);

    assert('feature must exist', feature);

    this.router.transitionTo(
      'features.show.feature',
      dasherize(feature.internalName)
    );
  }

  @action
  showDeactivation(): void {
    this.feature?.id &&
      this.router.transitionTo(
        'features.show.feature.index.deactivate',
        dasherize(this.feature.internalName)
      );
  }

  @action
  handleOnActivate(): void {
    this.feature
      ?.enable()
      .then(() => {
        if (this.feature) {
          get(this.current.company, 'features').pushObject(this.feature);
        }

        this.flashMessages.success(
          this.intl.t('features.feature.feature_activated')
        );
        this.feature?.id &&
          this.router.transitionTo(
            'features.show.feature.index.success',
            dasherize(this.feature.internalName)
          );
      })
      .catch(() => {
        this.flashMessages.error(this.intl.t('common.something_went_wrong'));
      });
  }

  @action
  handleOnRequestFeature(): void {
    this.feature
      ?.require()
      .then(() => {
        this.flashMessages.success(
          this.intl.t('features.feature.successfully_requested')
        );
      })
      .catch(() => {
        this.flashMessages.error(this.intl.t('common.something_went_wrong'));
      });
  }

  @action
  handleOnManagerRequest(): void {
    const message = this.intl.t('features.feature.want_to_activate_feature', {
      featureName: this.feature?.name,
    });
    this.intercom.showNewMessage(message);
  }

  @action
  handleClose(): void {
    this.router.transitionTo('features.show.index', this.model.category);
  }

  @action
  handleClickPrev(e: Event): void {
    e.preventDefault();
    TransitionHelper.getInstance().direction = 'feature-to-right';
    const currentIndex =
      this.model.feature?.id &&
      this.model.idsInCategory.indexOf(this.model.feature.id);

    if (typeof currentIndex === 'number') {
      const clone = [...this.model.idsInCategory];
      const slice = clone.splice(currentIndex, clone.length);
      const id = [...slice, ...clone].at(-1);
      this.transitionToFeature(id);
    } else {
      this.transitionToFeature(this.model.idsInCategory[0]);
    }
  }

  @action
  handleClickNext(e: Event): void {
    e.preventDefault();
    TransitionHelper.getInstance().direction = 'feature-to-left';
    const currentIndex =
      this.model.feature?.id &&
      this.model.idsInCategory.indexOf(this.model.feature.id);

    if (typeof currentIndex === 'number') {
      let nextIndex = currentIndex + 1;
      if (nextIndex > this.model.idsInCategory.length - 1) {
        nextIndex = 0;
      }

      const id = this.model.idsInCategory[nextIndex];
      this.transitionToFeature(id);
    } else {
      this.transitionToFeature(this.model.idsInCategory[0]);
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'features.show.feature.index': FeatureShowFeatureIndexController;
  }
}
