import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n  {{#each this.visibleTags as |tag|}}\n    <Core::Tag @text={{tag.name}} @color={{tag.backgroundColor}} />\n  {{/each}}\n  {{#if this.moreTagsCount}}\n    <Core::Tag\n      @text={{concat \"+\" this.moreTagsCount}}\n      @tooltip={{this.tooltipText}}\n    />\n  {{/if}}\n</div>", {"contents":"<div>\n  {{#each this.visibleTags as |tag|}}\n    <Core::Tag @text={{tag.name}} @color={{tag.backgroundColor}} />\n  {{/each}}\n  {{#if this.moreTagsCount}}\n    <Core::Tag\n      @text={{concat \"+\" this.moreTagsCount}}\n      @tooltip={{this.tooltipText}}\n    />\n  {{/if}}\n</div>","moduleName":"teamtailor/components/candidates-table/tags-column.hbs","parseOptions":{"srcName":"teamtailor/components/candidates-table/tags-column.hbs"}});
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import CandidateModel from 'teamtailor/models/candidate';
import TagModel from 'teamtailor/models/tag';

interface TagsColumnArgs {
  candidate: CandidateModel;
}

export default class TagsColumn extends Component<TagsColumnArgs> {
  get candidate(): CandidateModel {
    return this.args.candidate;
  }

  get numberOfTagsToShow(): number {
    return get(this.candidate, 'tags').length === 4 ? 4 : 3;
  }

  get visibleTags(): TagModel[] {
    return get(this.candidate, 'sortedTags').slice(
      0,
      get(this, 'numberOfTagsToShow')
    );
  }

  get hiddenTags(): TagModel[] {
    return get(this.candidate, 'sortedTags').slice(
      get(this, 'numberOfTagsToShow'),
      get(this.candidate, 'sortedTags').length
    );
  }

  get tooltipText(): string {
    return this.hiddenTags.mapBy('name').join(', ');
  }

  get moreTagsCount(): number {
    return Math.max(
      0,
      get(this.candidate, 'tags').length - get(this, 'numberOfTagsToShow')
    );
  }
}
