import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"body-text-xs flex-shrink overflow-hidden\">\n  {{#if @relativeDates}}\n    <label class=\"no-global-styles flex items-center gap-8\">\n      <span>\n        {{t \"components.todo_input.due_in\"}}\n      </span>\n      <Core::Select\n        @size=\"small\"\n        @searchEnabled={{false}}\n        @isFullWidth={{false}}\n        @optionName=\"label\"\n        @nullOption={{t \"common.no_date\"}}\n        @options={{this.dueInOptions}}\n        @selected={{find-by \"value\" @relativeDueDays this.dueInOptions}}\n        @onSelect={{@onRelativeDateChange}}\n        @placeholder={{t \"common.no_date\"}}\n      />\n    </label>\n  {{else}}\n    <EmberTooltip @text={{t this.triggerTooltip date=this.formattedDueDate}} />\n    <QuickSchedule::DatePicker\n      @due={{@due}}\n      @onDueDateSelect={{@onDueDateSelect}}\n      @onSetFocus={{@onSetFocus}}\n    />\n  {{/if}}\n</div>", {"contents":"<div class=\"body-text-xs flex-shrink overflow-hidden\">\n  {{#if @relativeDates}}\n    <label class=\"no-global-styles flex items-center gap-8\">\n      <span>\n        {{t \"components.todo_input.due_in\"}}\n      </span>\n      <Core::Select\n        @size=\"small\"\n        @searchEnabled={{false}}\n        @isFullWidth={{false}}\n        @optionName=\"label\"\n        @nullOption={{t \"common.no_date\"}}\n        @options={{this.dueInOptions}}\n        @selected={{find-by \"value\" @relativeDueDays this.dueInOptions}}\n        @onSelect={{@onRelativeDateChange}}\n        @placeholder={{t \"common.no_date\"}}\n      />\n    </label>\n  {{else}}\n    <EmberTooltip @text={{t this.triggerTooltip date=this.formattedDueDate}} />\n    <QuickSchedule::DatePicker\n      @due={{@due}}\n      @onDueDateSelect={{@onDueDateSelect}}\n      @onSetFocus={{@onSetFocus}}\n    />\n  {{/if}}\n</div>","moduleName":"teamtailor/components/todo/edit/date-picker.hbs","parseOptions":{"srcName":"teamtailor/components/todo/edit/date-picker.hbs"}});
import Component from '@glimmer/component';
import formatDate from 'teamtailor/helpers/format-date';
import { inject as service } from '@ember/service';

const DUE_IN_DAYS_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 10, 14, 21];

export default class TextComposerToolDatepickerComponent extends Component {
  @service intl;

  get dueInOptions() {
    return DUE_IN_DAYS_OPTIONS.map((i) => ({
      label: this.intl.t('common.x_days', { count: i }),
      value: i,
    }));
  }

  get triggerTooltip() {
    return this.args.due
      ? 'components.todo_input.date_selected'
      : 'components.todo_input.select_date';
  }

  get formattedDueDate() {
    return formatDate(this.args.due);
  }
}
