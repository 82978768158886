import CandidateModel from 'teamtailor/models/candidate';
import { isPresent } from '@ember/utils';
import Model, {
  AsyncBelongsTo,
  SyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import JobApplicationModel from 'teamtailor/models/job-application';
import StageModel from 'teamtailor/models/stage';
import UserModel from 'teamtailor/models/user';
import ScorecardScoreModel from 'teamtailor/models/scorecard-score';

export default class HireQualityResponseModel extends Model {
  @belongsTo('candidate') declare candidate: AsyncBelongsTo<CandidateModel>;
  @belongsTo('job-application')
  declare jobApplication: AsyncBelongsTo<JobApplicationModel>;

  @belongsTo('stage') declare stage: AsyncBelongsTo<StageModel>;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;

  @hasMany('scorecard-score', { async: false })
  declare scores: SyncHasMany<ScorecardScoreModel>;

  @attr('number') declare candidateId: number;
  @attr('string') declare feedback: string;
  @attr('boolean') declare isCompetenceBased: boolean;
  @attr('number') declare jobApplicationId: number;
  @attr('number') declare match: number;
  @attr('boolean', { allowNull: true }) declare recommended: boolean | null;
  @attr('date') declare reviewedAt: Date | null;
  @attr('number') declare reviewPeriod: number;
  @attr('string') declare reviewPeriodUnit: string;
  @attr('date') declare scheduledAt: Date | null;
  @attr('date') declare sentAt: Date | null;

  get isSent() {
    return isPresent(this.sentAt);
  }

  get isReviewed() {
    return isPresent(this.reviewedAt);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'hire-quality-response': HireQualityResponseModel;
  }
}
