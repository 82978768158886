import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

const indeedChannels = ['indeed', 'indeed_oauth'];

export default class MarketplaceChannelsIndexChannelIndexRoute extends Route {
  @service router;
  @service store;

  afterModel(model) {
    if (indeedChannels.includes(model.internalName)) {
      return this.router.replaceWith(
        'marketplace.channels.index.channel.indeed'
      );
    }

    if (model.isAlwaysIncluded) {
      return this.router.replaceWith(
        'marketplace.channels.index.channel.always_included'
      );
    }

    if (model.activationType === 'customer') {
      return this.router.replaceWith(
        'marketplace.channels.index.channel.activate'
      );
    }

    return model;
  }
}
