import Model, { attr } from '@ember-data/model';

export default class GiphyImageModel extends Model {
  @attr('number') declare height: number;
  @attr('number') declare width: number;
  @attr('string') declare previewUrl: string;
  @attr('string') declare url: string;
  @attr('string') declare title: string;

  get ratio(): number {
    return this.width / this.height;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'giphy-image': GiphyImageModel;
  }
}
