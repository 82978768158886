import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Icon\n  ...attributes\n  @icon={{@icon}}\n  @style={{@style}}\n  @prefix={{@prefix}}\n  @size={{@size}}\n  @spin={{@spin}}\n  @transform={{@transform}}\n  @flip={{@flip}}\n  {{ani-mate in=this.in out=this.out skipIn=(not @animate)}}\n/>", {"contents":"<Icon\n  ...attributes\n  @icon={{@icon}}\n  @style={{@style}}\n  @prefix={{@prefix}}\n  @size={{@size}}\n  @spin={{@spin}}\n  @transform={{@transform}}\n  @flip={{@flip}}\n  {{ani-mate in=this.in out=this.out skipIn=(not @animate)}}\n/>","moduleName":"teamtailor/components/button/icon.hbs","parseOptions":{"srcName":"teamtailor/components/button/icon.hbs"}});
import Component from '@glimmer/component';
import { aniMate } from 'ember-ani-mate';

export default class ButtonIcon extends Component {
  async in(element) {
    await aniMate.from(
      element,
      {
        width: 0,
        marginLeft: -4,
        opacity: 0,
      },
      { duration: 150 }
    );
  }

  async out(elementOrClone) {
    await aniMate.to(
      elementOrClone,
      {
        width: 0,
        marginLeft: -4,
        opacity: 0,
      },
      { duration: 150 }
    );
  }
}
