import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Input\n  class=\"body-text-s border-none py-4 hover:bg-neutral-hover focus:bg-neutral-hover\"\n  @value={{this.inputValue}}\n  placeholder={{t \"common.untitled\"}}\n  {{on \"focusin\" this.onFocusIn}}\n  {{on \"focusout\" this.onFocusOut}}\n  {{on \"change\" this.handleInputChange}}\n  {{did-insert (fn @focusInput @optionIndex)}}\n  ...attributes\n/>", {"contents":"<Input\n  class=\"body-text-s border-none py-4 hover:bg-neutral-hover focus:bg-neutral-hover\"\n  @value={{this.inputValue}}\n  placeholder={{t \"common.untitled\"}}\n  {{on \"focusin\" this.onFocusIn}}\n  {{on \"focusout\" this.onFocusOut}}\n  {{on \"change\" this.handleInputChange}}\n  {{did-insert (fn @focusInput @optionIndex)}}\n  ...attributes\n/>","moduleName":"teamtailor/components/form-builder/default-field/edit/option-input.hbs","parseOptions":{"srcName":"teamtailor/components/form-builder/default-field/edit/option-input.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

type FormBuilderDefaultFieldEditOptionInputArgs = {
  value: string;
  optionIndex: number;
  onChange: (index: number, value: string) => void;
};

export default class FormBuilderDefaultFieldEditOptionInputComponent extends Component<FormBuilderDefaultFieldEditOptionInputArgs> {
  @service declare intl: IntlService;
  @tracked inputValue = this.args.value;

  get isFirst(): boolean {
    return this.args.optionIndex === 0;
  }

  @action
  handleInputChange(e: KeyboardEvent) {
    const inputElement = e.target as HTMLInputElement;
    this.args.onChange(this.args.optionIndex, inputElement.value);
  }

  @action
  onFocusIn(): void {
    if (
      this.isFirst &&
      this.inputValue ===
        this.intl.t('settings.requisitions.custom_form.first_option')
    ) {
      this.inputValue = '';
    }
  }

  @action
  onFocusOut(): void {
    if (this.isFirst && this.inputValue === '') {
      this.args.onChange(
        0,
        this.intl.t('settings.requisitions.custom_form.first_option')
      );
    }
  }
}
