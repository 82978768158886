import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';

export default class EmployeeReferralRoute extends ScrollToTopRoute {
  @service store;

  async model(params) {
    if (params.jobId) {
      const job = await this.store.findRecord('job', params.jobId);
      return this.store.createRecord('referral', { job });
    }

    return this.store.createRecord('referral');
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.isOpen = true;
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.jobId = null;
      controller.resumeFilename = null;

      if (!controller.referral.id) {
        controller.referral.unloadRecord();
      }
    }
  }
}
