import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class HasPermission extends Helper {
  @service permissions;

  compute([permissionName, scope]) {
    return this.permissions.has(permissionName, scope);
  }
}
