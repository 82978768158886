const fontOptions = {
  fontSize: '12px',
  fontFamily: 'Inter, sans-serif',
};

export const defaultFontOptions = {
  ...fontOptions,
  color: '#57595F',
};

export const stackLabelFontOptions = {
  ...fontOptions,
  color: '#B2B3B9',
  textOutline: 'none',
  fontWeight: '400',
  marginBottom: '10px',
};
