/**
 * based on https://github.com/jmurphyau/ember-truth-helpers/blob/0783085c7996178ca1db5a060a402cecb2abaefc/packages/ember-truth-helpers/src/helpers/and.ts
 *
 * Should be the same as regular `and` helper except will always return a boolean
 * */

import Helper from '@ember/component/helper';
import truthConvert, {
  MaybeTruthy,
} from 'ember-truth-helpers/utils/truth-convert';

// We use class-based helper to ensure arguments are lazy-evaluated
export default class AndHelper<T extends MaybeTruthy[]> extends Helper {
  public compute(params: T) {
    for (let i = 0, len = params.length; i < len; i++) {
      if (!truthConvert(params[i])) {
        return false;
      }
    }

    return !!params[params.length - 1];
  }
}
