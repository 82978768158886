import { get } from 'teamtailor/utils/get';
import { next } from '@ember/runloop';
import flatten from 'teamtailor/utils/flatten';

export default function associationUnloader(
  store,
  { parentName, parentId, keepObjects },
  ...associationModels
) {
  next(() => {
    let parent = store.peekRecord(parentName, parentId);
    flatten(associationModels).forEach((modelName) => {
      store
        .peekAll(modelName)
        .filterBy(`${parentName}.id`, parentId)
        .forEach((model) => {
          if (
            keepObjects &&
            keepObjects[modelName] &&
            keepObjects[modelName].includes(get(model, 'id'))
          ) {
            return;
          }

          store.unloadRecord(model);

          if (get(parent, modelName)) {
            get(parent, modelName).removeObject(model);
          }
        });
    });
  });
}
