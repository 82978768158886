export function localizedToNumber(
  localizedString: string,
  locale: string = 'en-US'
): number {
  const numberFormat = new Intl.NumberFormat(locale);
  const parts = numberFormat.formatToParts(12345.6);

  const groupSeparator =
    parts.find((part) => part.type === 'group')?.value || ',';
  const decimalSeparator =
    parts.find((part) => part.type === 'decimal')?.value || '.';

  const cleanedString = localizedString
    .replace(new RegExp(`\\${groupSeparator}`, 'g'), '')
    .replace(new RegExp(`\\${decimalSeparator}`), '.');

  return parseFloat(cleanedString);
}
