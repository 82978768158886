import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<DataTable::Cell\n  @data={{@data}}\n  @onMouseEnterColumn={{@onMouseEnterColumn}}\n  @onMouseExitColumn={{@onMouseExitColumn}}\n  @hovering={{@hovering}}\n  @lastRow={{@lastRow}}\n>\n  <div class=\"truncate\">\n    {{#if this.value}}\n      {{this.value}}\n    {{else}}\n      <i>{{t\n          \"components.data_table.deleted_model\"\n          model=(t\n            (concat \"components.data_table.models.\" (underscore this.type))\n          )\n        }}</i>\n    {{/if}}\n  </div>\n</DataTable::Cell>", {"contents":"<DataTable::Cell\n  @data={{@data}}\n  @onMouseEnterColumn={{@onMouseEnterColumn}}\n  @onMouseExitColumn={{@onMouseExitColumn}}\n  @hovering={{@hovering}}\n  @lastRow={{@lastRow}}\n>\n  <div class=\"truncate\">\n    {{#if this.value}}\n      {{this.value}}\n    {{else}}\n      <i>{{t\n          \"components.data_table.deleted_model\"\n          model=(t\n            (concat \"components.data_table.models.\" (underscore this.type))\n          )\n        }}</i>\n    {{/if}}\n  </div>\n</DataTable::Cell>","moduleName":"teamtailor/components/data-table/preloaded-model.hbs","parseOptions":{"srcName":"teamtailor/components/data-table/preloaded-model.hbs"}});
import Component from '@glimmer/component';

type PreloadedModelCellData = {
  type: string;
  value: string;
};

type PreloadedModelCellArgs = {
  data: PreloadedModelCellData;
};

export default class DataTablePreloadedModelComponent extends Component<PreloadedModelCellArgs> {
  get value() {
    const { value } = this.args.data;
    if (parseInt(value).toString() !== value) {
      return value;
    }

    return undefined;
  }

  get type() {
    return this.args.data.type;
  }
}
