/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { get, set } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import { oneWay } from '@ember/object/computed';

export default Mixin.create({
  searchProxy: oneWay('query'),
  searchProxyDelay: 1000,

  handleSearchInput: task(function* (value) {
    yield timeout(get(this, 'searchProxyDelay'));
    set(this, 'query', value);
    set(this, 'page', 1);
  }).restartable(),
});
