import { TableColumn } from 'teamtailor/components/table/container';

export const TABLE_COLUMNS: TableColumn[] = [
  {
    label: 'common.title',
    sortable: true,
    sortColumn: 'title',
    defaultSortOrder: 'asc',
    sortOrder: 1,
  },
  {
    label: 'settings.questions.type',
    sortable: true,
    sortColumn: 'type',
    defaultSortOrder: 'asc',
    sortOrder: 2,
  },
  {
    label: 'settings.questions.answers',
    sortable: false,
    sortOrder: 3,
  },
  {
    label: 'common.created',
    sortable: true,
    sortColumn: 'created_at',
    defaultSortOrder: 'asc',
    sortOrder: 4,
  },
  {
    label: 'common.tags',
    sortable: false,
    sortOrder: 6,
  },
];

export const TABLE_LOADING_COLUMNS = [
  { width: 'm' },
  { width: 's' },
  { width: 's' },
  { width: 's' },
  { width: 's' },
  { width: 's' },
];

export type QuestionType = keyof typeof MappedTypes;

export enum MappedTypes {
  choice = 'Question::Choice',
  boolean = 'Question::Boolean',
  range = 'Question::Range',
  text = 'Question::Text',
  video = 'Question::Video',
  file = 'Question::File',
  number = 'Question::Number',
  date = 'Question::Date',
}

export type QuestionTypeAttributes = {
  icon: string;
  translationKey: string;
};

export type QuestionStruct = {
  [T in QuestionType]: QuestionTypeAttributes;
};

export type Question = {
  type: QuestionType;
  icon: string;
  value: string;
};

const translationPath = 'components.question_creator';

export const QUESTION_TYPES: QuestionStruct = {
  boolean: {
    translationKey: `${translationPath}.yes_no`,
    icon: 'circle-check',
  },

  choice: {
    translationKey: `${translationPath}.choice`,
    icon: 'ballot-check',
  },

  range: {
    translationKey: `${translationPath}.range`,
    icon: 'sliders',
  },

  video: {
    translationKey: `${translationPath}.video`,
    icon: 'video',
  },

  text: {
    translationKey: `${translationPath}.text`,
    icon: 'font',
  },

  file: {
    translationKey: `${translationPath}.file`,
    icon: 'file',
  },

  number: {
    translationKey: `${translationPath}.number`,
    icon: 'hashtag',
  },

  date: {
    translationKey: `${translationPath}.date`,
    icon: 'calendar',
  },
};
