import ScorecardCriterium, {
  TOPIC,
} from 'teamtailor/models/scorecard-criterium';
import ScorecardPick from 'teamtailor/models/scorecard-pick';

export default class ScorecardCriteriaSorter {
  declare locale: string;

  constructor(locale: string) {
    this.locale = locale;
  }

  sort = (
    a: ScorecardCriterium | ScorecardPick,
    b: ScorecardCriterium | ScorecardPick
  ) => {
    if (!a.name || !b.name) {
      // Handle case where a.name or b.name is undefined
      return 0; // or any other suitable value
    }

    if (a.topic === b.topic) {
      return a.name.localeCompare(b.name, this.locale, {
        sensitivity: 'base',
      });
    }

    return a.topic === TOPIC.SKILL ? -1 : 1;
  };
}
