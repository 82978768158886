import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';

export default class EditRoute extends Route {
  @service current;
  @service iframePreview;

  setupController(controller, model) {
    super.setupController(controller, model);
    get(this, 'iframePreview').scrollToSection({
      sectionId: get(this.current.company, 'sectionsByName.locations.id'),
    });
  }
}
