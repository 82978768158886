import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import SettingsRequisitionArchiveReasonsController from 'teamtailor/controllers/settings/requisition_archive_reasons';

export default class SettingsRequisitionArchiveReasonsRoute extends ScrollToTopRoute {
  @service declare store: Store;

  model() {
    return this.store.findAll('requisition-archive-reason');
  }

  resetController(
    controller: SettingsRequisitionArchiveReasonsController,
    isExiting: boolean
  ) {
    if (isExiting && controller.model.isNew) {
      controller.model.unloadRecord();
    }
  }
}
