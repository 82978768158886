import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import VideoMeetingTokenModel from 'teamtailor/models/video-meeting-token';
import VideoMeetingsController from 'teamtailor/controllers/video-meetings';
import Store from '@ember-data/store';
import SessionService from 'ember-simple-auth/services/session';

import { Transition } from 'teamtailor/utils/type-utils';
import TranslationsService from 'teamtailor/services/translations';
import BootData from 'teamtailor/services/boot-data';
import UserService from 'teamtailor/services/user';
import PusherService from 'teamtailor/services/pusher';
import { PusherContext } from 'teamtailor/types/pusher-context';

interface Params {
  video_meeting_id: string;
}

export default class VideoMeetingsRoute extends Route {
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare translations: TranslationsService;
  @service declare bootData: BootData;
  @service declare user: UserService;
  @service declare session: SessionService;
  @service declare pusher: PusherService;

  beforeModel(transition: Transition) {
    super.beforeModel(transition);
    // @TODO: Do something here to set correct translation for meeting in some way
    this.translations.setLocale('en');
  }

  model({ video_meeting_id }: Params): Promise<VideoMeetingTokenModel> {
    return this.store.findRecord('video-meeting-token', video_meeting_id);
  }

  afterModel() {
    // If route is outside company scope we should start pusher for the video meetings context
    if (this.router.currentURL.startsWith('/video-meetings')) {
      this.pusher.start(PusherContext.VIDEO_MEETINGS);
    }
  }

  resetController(controller: VideoMeetingsController, isExiting: boolean) {
    if (isExiting) {
      const { disconnect } = controller;
      window.removeEventListener('beforeunload', disconnect);
      window.removeEventListener('pagehide', disconnect);
    }
  }

  setupController(
    controller: VideoMeetingsController,
    model: VideoMeetingTokenModel,
    transition: Transition
  ): void {
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    viewportMeta?.setAttribute(
      'content',
      'width=device-width, initial-scale=1'
    );
    super.setupController(controller, model, transition);
    const { disconnect } = controller;

    // Listen to the "beforeunload" event on window to leave the Room
    // when the tab/browser is being closed.
    window.addEventListener('beforeunload', disconnect);

    // iOS Safari does not emit the "beforeunload" event on window.
    // Use "pagehide" instead.
    window.addEventListener('pagehide', disconnect);
  }
}
