import Store from '@ember-data/store';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import FromRoute from 'teamtailor/routes/settings/security/sso/show/mappings/new';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import { ModelFrom } from 'teamtailor/utils/type-utils';

export default class SettingsSecuritySsoShowMappingsEdit extends Controller {
  declare model: ModelFrom<FromRoute>;

  @service declare store: Store;
  @service declare current: Current;
  @service declare flashMessages: FlashMessageService;
  @service declare router: RouterService;

  @action
  handleSubmit() {
    return this.handleSave();
  }

  @action
  handleClose() {
    this.router.transitionTo('settings.security.sso.show');
  }

  @action
  async handleDelete() {
    try {
      await this.model.destroyRecord();
      this.router.transitionTo('settings.security.sso.show');
    } catch (_e) {
      this.flashMessages.error('Failed to delete SSO mapping');
    }
  }

  @action
  async handleSave() {
    try {
      await this.model.save();

      this.handleClose();
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.security.sso.show.mappings.edit': SettingsSecuritySsoShowMappingsEdit;
  }
}
