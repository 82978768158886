import { attr } from '@ember-data/model';
import { questionsToFormFields } from 'teamtailor/utils/form-builder';
import JobOfferForm from './job-offer-form';

export default class JobOfferTemplateModel extends JobOfferForm {
  @attr('string') declare name;

  get formFields() {
    return this.sortedFormQuestions.length > 0
      ? questionsToFormFields(this.sortedFormQuestions)
      : this.defaultFormFields;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'job-offer-template': JobOfferTemplateModel;
  }
}
