import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import Current from 'teamtailor/services/current';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import JobOfferApprovalFlowsRoute from 'teamtailor/routes/settings/job-offer-approval-flows';
import { get } from 'teamtailor/utils/get';
import RouterService from '@ember/routing/router-service';
import { JobOfferApprovalFlowModel } from 'teamtailor/models';

export default class SettingsJobOfferApprovalFlowsController extends Controller {
  declare model: ModelFrom<JobOfferApprovalFlowsRoute>;

  @service declare current: Current;
  @service declare router: RouterService;

  type = 'job-offer-approval-flow';

  get models() {
    return this.model.toArray();
  }

  get company() {
    return this.current.company;
  }

  get hasDefaultFlow() {
    if (!this.defaultFlow) {
      return false;
    }

    return !get(this.defaultFlow, 'isNew');
  }

  get defaultFlow() {
    return this.model.findBy('isDefault', true);
  }

  get customFlows() {
    return this.model.filterBy('isDefault', false);
  }

  @action
  async toggleSetting(value: boolean) {
    set(this.current.company, 'jobOfferApprovalsEnabled', value);
    await this.current.company.save();
  }

  @action
  handleStartEditing(model: JobOfferApprovalFlowModel) {
    this.router.transitionTo(
      'settings.job-offer-approval-flows.edit',
      model.id
    );
  }

  @action
  addFlow() {
    this.router.transitionTo('settings.job-offer-approval-flows.new');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.job-offer-approval-flows': SettingsJobOfferApprovalFlowsController;
  }
}
