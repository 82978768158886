import Service, { inject as service } from '@ember/service';
import { classify } from '@ember/string';
import {
  IconDefinition,
  IconName,
  IconPrefix,
  library,
} from '@fortawesome/fontawesome-svg-core';
import ENV from 'teamtailor/config/environment';
import BootDataService from './boot-data';

export default class EsmFaIconsService extends Service {
  @service declare bootData: BootDataService;

  async loadIcon(prefix: IconPrefix, icon: IconName) {
    if (prefix !== 'fas') return; // only load solid icons for now

    // @ts-expect-error This hack should hopefully be soon removed
    window.exports = {}; // when this issue is solved we should be able to remove this: https://github.com/FortAwesome/Font-Awesome/issues/20218

    const urlWithoutProtocol = [
      ENV.RAILS_ASSETS_HOST || ENV.RAILS_HOST,
      'assets',
      'esm-fa-icons',
      prefix,
      this.bootData.esmFaIconVersion,
      `fa${classify(icon)}.js`,
    ].join('/');

    const iconModule = (
      location.protocol === 'https:'
        ? await import(
            /* webpackIgnore: true */ `https://${urlWithoutProtocol}`
          )
        : await import(/* webpackIgnore: true */ `http://${urlWithoutProtocol}`)
    ) as { definition: IconDefinition; aliases: string[] };

    iconModule.definition.icon[2] = iconModule.aliases;
    library.add(iconModule.definition);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'esm-fa-icons': EsmFaIconsService;
  }
}
