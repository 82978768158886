export default {
  "listItemRow": "TopActivities__listItemRow_k4zncj",
  "listItemText": "TopActivities__listItemText_k4zncj",
  "listItemRowDivider": "TopActivities__listItemRowDivider_k4zncj",
  "listItemRowIcon": "TopActivities__listItemRowIcon_k4zncj",
  "grid": "TopActivities__grid_k4zncj",
  "gridCols": "TopActivities__gridCols_k4zncj",
  "gridSpan": "TopActivities__gridSpan_k4zncj",
  "gridSpan--half": "TopActivities__gridSpan--half_k4zncj"
};
