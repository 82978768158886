import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import BootDataService from 'teamtailor/services/boot-data';
import SharedReportStoreService from 'teamtailor/services/shared-report-store';
import AnalyticsService from 'teamtailor/services/analytics';

interface Params {
  token: string;
  hasEnabledGroupAnalytics?: boolean;
}

export default class SharedReportsRoute extends Route {
  @service declare store: Store;
  @service declare sharedReportStore: SharedReportStoreService;
  @service declare bootData: BootDataService;
  @service declare analytics: AnalyticsService;

  queryParams = {
    startDate: { refreshModel: true },
    endDate: { refreshModel: true },
    hasEnabledGroupAnalytics: { refreshModel: true },
  };

  async model(params: Params) {
    this.sharedReportStore.token = params.token;
    this.analytics.currentStore = this.sharedReportStore;

    await this.analytics.initializeCustomReportItems(
      params.hasEnabledGroupAnalytics || false,
      true
    );

    const model = await this.store.findRecord('shared-report', params.token);

    this.analytics.availableCompanyIds = model.companyIds;

    return model;
  }
}
