import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

class TimeZone {
  constructor({ id, name, display_name, friendly_identifier }) {
    this.id = id;
    this.name = name;
    this.displayName = display_name;
    this.friendlyIdentifier = friendly_identifier;
  }

  get searchString() {
    return `${this.id} ${this.name} ${this.displayName} ${this.friendlyIdentifier}`;
  }
}

export default class TimezonesService extends Service {
  @service store;

  @tracked timezones = [];

  fetchTimeZonesTask = task(
    {
      drop: true,
    },
    async () => {
      let adapter = this.store.adapterFor('application');
      let url = adapter.buildURL('timezones');

      await adapter.ajax(url, 'GET').then((data) => {
        this.timezones = data.timezones.map((tz) => new TimeZone(tz));
      });
    }
  );

  get all() {
    if (isEmpty(this.timezones)) {
      this.fetchTimeZonesTask.perform();
    }

    return this.timezones;
  }
}
