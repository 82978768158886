import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';

export default class ResultsRoute extends Route {
  @service store;

  queryParams = {
    jobId: { refreshModel: true },
    location_id: { refreshModel: true },
    department_id: { refreshModel: true },
    year: { refreshModel: true },
  };

  async model({ survey_id, jobId, location_id, department_id, year }) {
    const survey = await this.store.findRecord('survey', survey_id);
    const { form_result } = await survey.getResults({
      job_id: jobId,
      location_id,
      department_id,
      year,
    });

    return hash({
      survey,
      results: form_result,
      job: jobId ? this.store.findRecord('job', jobId) : null,
    });
  }
}
