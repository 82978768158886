/* eslint-disable ember/use-ember-get-and-set */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { BufferedChangeset } from 'ember-changeset/types';
import IntlService from 'ember-intl/services/intl';
import { TableColumn } from 'teamtailor/components/table/container';
import FromRoute from 'teamtailor/routes/settings/security/sso/show';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import TtAlertService from 'teamtailor/services/tt-alert';
import { get } from 'teamtailor/utils/get';
import { ModelFrom } from 'teamtailor/utils/type-utils';

export default class SettingsSecuritySsoShow extends Controller {
  declare model: ModelFrom<FromRoute>;

  @service declare flashMessages: FlashMessageService;
  @service declare ttAlert: TtAlertService;
  @service declare router: RouterService;
  @service declare current: Current;
  @service declare intl: IntlService;

  @tracked declare changeset: BufferedChangeset;

  get ssoMappings() {
    const sso = get(this.current.company, 'singleSignOn');
    if (get(sso, 'ssoUserAttributeMappings').length) {
      return get(sso, 'ssoUserAttributeMappings').toArray().reverse();
    }

    return [];
  }

  get mappingsColumns(): TableColumn[] {
    return [
      {
        label: 'Target key',
        sortable: false,
        sortColumn: 'title',
        defaultSortOrder: 'asc',
        sortOrder: 1,
      },
      {
        label: 'Target value',
        sortable: false,
        sortColumn: 'title',
        defaultSortOrder: 'asc',
        sortOrder: 1,
      },
      {
        label: 'SAML attribute key',
        sortable: false,
        sortColumn: 'title',
        defaultSortOrder: 'asc',
        sortOrder: 1,
      },
      {
        label: 'SAML attribute value',
        sortable: false,
        sortColumn: 'title',
        defaultSortOrder: 'asc',
        sortOrder: 1,
      },
    ];
  }

  private toggleEnabled = async (): Promise<void> => {
    this.changeset.set('active', !this.changeset.active);

    try {
      await this.changeset.save();
      this.router.transitionTo('settings.security.sso');
      return Promise.resolve();
    } catch (e) {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
      return Promise.reject(e);
    }
  };

  @action
  confirmChange(): Promise<void> {
    const active = this.changeset.get('active');

    return new Promise((resolve, reject) => {
      this.ttAlert.confirm(
        active
          ? this.intl.t('settings.security.sso.dialogs.disable.body')
          : this.intl.t('settings.security.sso.dialogs.enable.body'),
        async () => {
          try {
            await this.toggleEnabled();
            resolve();
          } catch (e) {
            reject(e);
          }
        },
        reject,
        {
          title: active
            ? this.intl.t('settings.security.sso.dialogs.disable.title')
            : this.intl.t('settings.security.sso.dialogs.enable.title'),

          confirmButtonText: active
            ? this.intl.t(
                'settings.security.sso.dialogs.disable.action_confirm'
              )
            : this.intl.t(
                'settings.security.sso.dialogs.enable.action_confirm'
              ),
        }
      );
    });
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.security.sso.show': SettingsSecuritySsoShow;
  }
}
