import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import EditController from 'teamtailor/controllers/jobs/job/stages/stage-triggers/edit';
import { AllTriggerModels } from 'teamtailor/models/trigger';
import TriggerWebhookModel from 'teamtailor/models/trigger/webhook';
import PermissionsService from 'teamtailor/services/permissions';
import { isSmartMove } from 'teamtailor/models/trigger/smart-move';
import { get } from 'teamtailor/utils/get';

export default class StageTriggersEditRoute extends Route {
  @service declare store: Store;
  @service declare permissions: PermissionsService;
  @service declare router: RouterService;

  beforeModel() {
    if (!this.permissions.has('trigger/update')) {
      this.router.transitionTo('jobs.job.stages.stage-triggers.index');
    }
  }

  async model({ type, id }: { type: AllTriggerModels['type']; id: string }) {
    return this.store.findRecord(`trigger/${type}`, id);
  }

  resetController(controller: EditController, isExiting: boolean) {
    if (isExiting) {
      if (controller.model instanceof TriggerWebhookModel) {
        get(controller.model, 'mappedPartnerCriteria')
          .toArray()
          .filterBy('hasDirtyAttributes')
          .invoke('rollbackAttributes');
      }

      if (get(controller.model, 'hasDirtyAttributes')) {
        controller.model.rollbackAttributes();
      }

      if (isSmartMove(controller.model)) {
        const trigger = controller.model;
        trigger.moves?.forEach((move) => {
          move.rollbackChangesIfNeeded();
        });
      }
    }
  }
}
