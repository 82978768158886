import Model, { hasMany, attr, AsyncHasMany } from '@ember-data/model';
import LocationModel from 'teamtailor/models/location';

export default class RegionModel extends Model {
  @hasMany('location') declare locations: AsyncHasMany<LocationModel>;
  @attr('string') declare name: string;

  get withoutLocations() {
    return this.locations.length === 0;
  }

  get withLocations() {
    return !this.withoutLocations;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    region: RegionModel;
  }
}
