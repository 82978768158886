import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  ...attributes\n  id={{this.animationId}}\n  {{did-insert this.didInsertComponent}}\n>\n  {{yield}}\n</div>", {"contents":"<div\n  ...attributes\n  id={{this.animationId}}\n  {{did-insert this.didInsertComponent}}\n>\n  {{yield}}\n</div>","moduleName":"teamtailor/components/lottie-animation.hbs","parseOptions":{"srcName":"teamtailor/components/lottie-animation.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { v1 as uuid } from 'ember-uuid';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class LottieAnimation extends Component {
  @tracked animationId = '';

  @argDefault loop = true;

  constructor() {
    super(...arguments);

    this.animationId = `lottie-animation-${uuid()}`;
  }

  loadAnimations(lottie) {
    this.lottieAnimation = lottie.loadAnimation({
      container: document.getElementById(this.animationId),
      renderer: 'svg',
      path: `/assets/lottie-animations/${this.args.animationName}.json`,
      loop: this.loop,
      autoplay: false,
    });

    this.lottieAnimation.addEventListener('DOMLoaded', () => {
      this.lottieAnimation.play();
    });
  }

  @action
  async didInsertComponent() {
    const lottie = await import('lottie-web');
    this.loadAnimations(lottie);
  }
}
