import ApplicationAdapter from './application';
import PaymentError from 'teamtailor/utils/errors/payment-error';

export default class PromotionAdapter extends ApplicationAdapter {
  handleResponse(status, headers, payload) {
    if (status === 400) {
      return new PaymentError(payload.errors);
    }

    return super.handleResponse(...arguments);
  }
}
