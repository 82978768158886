import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { QueueItemClass } from 'teamtailor/components/bulk-candidate/queue-item';
import IntlService from 'ember-intl/services/intl';
import TtAlertService from 'teamtailor/services/tt-alert';

export default class CandidatesSegmentNew extends Controller {
  @service declare router: RouterService;
  @tracked queueItems: QueueItemClass[] = [];
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;

  get hasUnsavedCandidates(): boolean {
    if (this.queueItems.isEvery('isPersisted', true)) {
      return false;
    }

    return this.queueItems.rejectBy('isPersisted', true).isAny('isDirty', true);
  }

  @action
  handleClose() {
    if (this.hasUnsavedCandidates) {
      this.ttAlert.customConfirm({
        title: this.intl.t('common.leave_page_confirmation'),
        text: this.intl.t('common.unsaved_candidates'),
        confirmButtonText: this.intl.t('common.leave_this_page'),
        cancelButtonText: this.intl.t('common.stay_on_this_page'),
        confirmCallback: () =>
          this.router.transitionTo('candidates.segment.index', 'all'),

        cancelCallback: () => true,
      });
    } else {
      this.router.transitionTo('candidates.segment.index', 'all');
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'candidates.segment.new': CandidatesSegmentNew;
  }
}
