import { attr } from '@ember-data/model';
import Section from './section';
import { get } from 'teamtailor/utils/get';

export default class ContentBlockTextModel extends Section {
  @attr('string') declare title: string;

  get layoutHasImage() {
    const layout = get(this, 'layout');
    if (layout !== 'no-image' && layout !== 'title') {
      return true;
    }

    return undefined;
  }

  get description() {
    return get(this, 'header');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'content-block-text': ContentBlockTextModel;
  }
}
