import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';

import { task, timeout } from 'ember-concurrency';
import { CUSTOM_FORM_CHANNELS } from 'teamtailor/constants/custom-form-channels';

export default class ActivateController extends Controller {
  @service intl;
  @service current;
  @service router;
  @service store;
  @service flashMessages;

  get configFields() {
    return this.model.activationConfig?.config?.fields || [];
  }

  get showSettingsRoute() {
    return this.configFields.length;
  }

  get shouldLinkToCustomForm() {
    return (
      this.model.allowedPaymentTypes === 'partner_or_teamtailor' ||
      CUSTOM_FORM_CHANNELS.includes(this.model.internalName)
    );
  }

  @action
  handleClose() {
    return this.router.transitionTo('marketplace');
  }

  get settingsRoute() {
    if (this.shouldLinkToCustomForm) {
      return 'settings.integrations.channel-activation.custom_form_settings';
    }

    return this.model.activationConfig
      ? 'settings.integrations.channel-activation.activate'
      : 'settings.integrations.marketplace-activations';
  }

  createChannelActivation = task(async () => {
    await timeout(2000);
    const channel = this.store.peekRecord('channel', this.model.id);

    const channelActivation = get(
      this.current.company,
      'channelActivations'
    ).createRecord({
      activated: true,
      channel,
      adapterAuthorizationToken: this.getDefaultConfig(channel),
      existingAgreement:
        this.model.defaultActivationType === 'existing_agreement',
    });

    try {
      channelActivation
        .save()
        .then((savedChannelActivation) =>
          this.redirectAfterSave(channel, savedChannelActivation)
        );
    } catch (error) {
      const message =
        error.errors[0].status === '401'
          ? 'unauthorized'
          : 'something_went_wrong';
      this.flashMessages.error(
        this.intl.t(
          `settings.integrations.partner_activations.errors.${message}`
        )
      );
      throw new Error(error);
    }
  });

  getDefaultConfig(channel) {
    if (!channel.activationConfig) {
      return null;
    }

    const fields = channel.activationConfig.config.fields.map((field) => {
      if (field.value !== undefined) {
        return { [field.id]: field.value };
      }

      return {};
    });

    return Object.assign({}, ...fields);
  }

  redirectAfterSave(channel, channelActivation) {
    if (channel.activationConfig?.config?.fields.length && channelActivation) {
      return this.router.transitionTo(
        `settings.integrations.channel-activation`,
        channelActivation
      );
    }

    return null;
  }
}
