import Controller from '@ember/controller';
import { get } from 'teamtailor/utils/get';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import Current from 'teamtailor/services/current';
import FlipperService from 'teamtailor/services/flipper';

export default class SettingsSecurity extends Controller {
  @service declare router: RouterService;
  @service declare flipper: FlipperService;
  @service declare current: Current;
  @service declare intl: IntlService;

  get twoFaEnforced(): boolean {
    return this.current.company.twoFactorAuthenticationEnforced;
  }

  get twoFaBadge(): { text: string; color: string } {
    if (this.twoFaEnforced) {
      return {
        text: this.intl.t('settings.security.statuses.two_factor_enforced'),
        color: 'green',
      };
    }

    return {
      text: this.intl.t('settings.security.statuses.optional'),
      color: 'yellow',
    };
  }

  get parentHasActiveSingleSignOn(): boolean {
    return this.current.company.parentHasActiveSingleSignOn;
  }

  get hasSsoFeature(): boolean {
    return this.current.company.hasFeature('sso');
  }

  get hasSsoEnabled(): boolean {
    const sso = get(this.current.company, 'singleSignOn');

    return !!get(sso, 'active');
  }

  get hasSsoConfigured(): boolean {
    const sso = get(this.current.company, 'singleSignOn');

    return !!get(sso, 'idpSsoServiceUrl');
  }

  get ssoBadges(): { text: string; color: string }[] {
    if (this.hasSsoConfigured || this.parentHasActiveSingleSignOn) {
      return [
        {
          text: this.intl.t('settings.security.statuses.sso_configured'),
          color: 'green',
        },
        {
          text:
            this.hasSsoEnabled || this.parentHasActiveSingleSignOn
              ? this.intl.t('settings.security.statuses.sso_enforced')
              : this.intl.t('settings.security.statuses.sso_not_enforced'),

          color:
            this.hasSsoEnabled || this.parentHasActiveSingleSignOn
              ? 'green'
              : 'zinc',
        },
      ];
    }

    return [
      {
        text: this.intl.t('settings.security.statuses.sso_not_configured'),
        color: 'zinc',
      },
      {
        text: this.intl.t('settings.security.statuses.sso_not_enabled'),
        color: 'zinc',
      },
    ];
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.security': SettingsSecurity;
  }
}
