import { underscore } from '@ember/string';
import { get } from 'teamtailor/utils/get';

export default function computeFullErrorMessages(model, intl) {
  const modelName = underscore(model.constructor.modelName);

  return get(model, 'errors').map((error) => {
    const translationKey = `models.${modelName}.${underscore(error.attribute)}`;
    let { message, attribute } = error;
    message = `${intl.t(translationKey)} ${error.message}`;
    return { message, attribute };
  });
}
