import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import { Promise } from 'rsvp';
import window from 'ember-window-mock';

export default class LoginRoute extends Route {
  @service session;
  @service router;
  @service externalIntegrationOauth;

  routeIfAlreadyAuthenticated = 'companies';

  async beforeModel() {
    let { hash } = window.location;
    if (
      this.session.attemptedTransitionPath === null &&
      get(this, 'session.attemptedTransition.intent.url')
    ) {
      const attemptedTransitionPath = get(
        this,
        'session.attemptedTransition.intent.url'
      );

      this.session.attemptedTransitionPath = attemptedTransitionPath;
    } else if (!isEmpty(hash)) {
      const hasCustomLoginStrategy = hash.indexOf(':') > -1;
      const [strategy, token] = hasCustomLoginStrategy ? hash.split(':') : [];

      if (strategy === '#oauth') {
        this.externalIntegrationOauth.saveAuth(hash);
        return super.beforeModel(...arguments);
      }

      const impersonate = strategy;
      const code = strategy ? token : hash;

      const { routeIfAlreadyAuthenticated } = this;
      const { attemptedTransitionPath } = this.session;

      return this.session
        .authenticate('authenticator:token', { code, impersonate })
        .then(() => {
          let companyUuid = this.paramsFor('login').company_uuid;
          if (companyUuid) {
            window.location.replace(`/companies/${companyUuid}`);
            return new Promise();
          } else {
            if (!isEmpty(attemptedTransitionPath)) {
              this.session.attemptedTransitionPath = undefined;
              window.location.replace(attemptedTransitionPath);
              return new Promise();
            } else {
              this.router.transitionTo(routeIfAlreadyAuthenticated);
              return new Promise();
            }
          }
        })
        .finally(() => {
          // Remove invalid code from URL
          history.replaceState(null, null, ' ');
        });
    } else {
      this.session.prohibitAuthentication('companies');
      return super.beforeModel(...arguments);
    }

    return undefined;
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.email = this.extractEmail();
    controller.showTotpForm = this.extractTotpParam();
  }

  extractTotpParam() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // eslint-disable-next-line ember/use-ember-get-and-set
    return urlParams.get('totp_required') || false;
  }

  extractEmail() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // eslint-disable-next-line ember/use-ember-get-and-set
    return urlParams.get('email') || '';
  }
}
