import { assert } from '@ember/debug';
import { get } from 'teamtailor/utils/get';
import { KeysOfUnion } from './type-utils';
import { isArray } from '@ember/array';

export default function uniqBy<T, N extends KeysOfUnion<T>>(
  a: T[],
  key: N
): T[] {
  assert('uniqBys first argument must be array', isArray(a));
  assert('uniqBys second argument must be passed', key || key === 0);
  return a.filter(
    (item, index, array) =>
      array.findIndex((e) => get(e, key) === get(item, key)) === index
  );
}
