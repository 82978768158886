import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ToggleService extends Service {
  @tracked states: Record<string, boolean> = {};

  toggle(key: string) {
    if (this.states[key] === undefined) {
      this.states[key] = true;
    } else {
      this.states[key] = !this.states[key];
    }

    this.states = { ...this.states };
  }

  isVisible(key: string): boolean {
    return this.states[key] === true;
  }
}

declare module '@ember/service' {
  interface Registry {
    'toggle-service': ToggleService;
  }
}
