import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import JobOfferApprovalFlowModel from './job-offer-approval-flow';
import RequisitionFlowModel from './requisition-flow';
import UserModel from './user';

export type ApprovalPolicy = {
  name: 'All' | 'Any';
  value: 'all_users' | 'any_user';
};

export default class RequisitionFlowStepModel extends Model {
  @attr('number') declare stepIndex: number;
  @attr('string', { defaultValue: 'any_user' })
  declare approvalPolicy: ApprovalPolicy['value'];

  @attr('boolean') declare isEditable: boolean;
  @attr('boolean', { defaultValue: false }) declare _destroy: boolean;

  // Since we don't have pickedUsers in the backend, we need to fake it here
  // Clear this value when not needed anymore
  @tracked pickedUsers: UserModel[] = [];

  @hasMany('user', { async: true }) declare users: AsyncHasMany<UserModel>;
  @belongsTo('requisition-flow', { inverse: 'steps' })
  declare requisitionFlow: AsyncBelongsTo<RequisitionFlowModel>;

  @belongsTo('job-offer-approval-flow', { inverse: 'steps' })
  declare jobOfferApprovalFlow: AsyncBelongsTo<JobOfferApprovalFlowModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'requisition-flow-step': RequisitionFlowStepModel;
  }
}
