import Route from '@ember/routing/route';
import { get, set } from '@ember/object';

export default class ThumbnailSettingsRoute extends Route {
  afterModel(model) {
    return get(model, 'thumbnail');
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    set(controller, 'type', 'page');
  }
}
