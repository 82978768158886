import Route from '@ember/routing/route';
import PartnerResultsReport from 'teamtailor/classes/analytics/report-partner-results';
import { getOwner } from '@ember/application';

export default class PartnerResultsRoute extends Route {
  model() {
    return {
      partnerResults: new PartnerResultsReport({
        container: getOwner(this),
      }).fetchTask,
    };
  }
}
