import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import { CompanyModel } from 'teamtailor/models';

export default class DomainModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @attr('boolean', { defaultValue: false }) declare enabled: boolean;
  @attr('boolean', { defaultValue: false }) declare redirectToCname: boolean;
  @attr('string') declare cname: string;
  @attr('string', { defaultValue: 'setup_cname' }) declare status: string;
  @attr declare digest: string | null;

  get inProgress() {
    return !this.isNew && !this.hasFailed && !this.isComplete;
  }

  get hasFailed() {
    return (
      this.status.endsWith('_failed') || this.status.endsWith('_unverified')
    );
  }

  get isComplete() {
    return this.certificateVerified;
  }

  get cnameVerified() {
    // Any domain that is not just created or failed CNAME check
    return !['setup_cname', 'cname_failed'].includes(this.status);
  }

  get txtVerified() {
    // Any domain that is CNAME verified and has not failed a TXT record check
    return this.cnameVerified && 'txt_uniqueness_failed' !== this.status;
  }

  get domainInactive() {
    // Any domain that is not CNAME verified or not TXT verified
    return !this.txtVerified;
  }

  get domainPending() {
    // Any domain that is either waiting to be set up or being set up or failed setup
    return ['setup_domain', 'verifying_domain', 'domain_failed'].includes(
      this.status
    );
  }

  get domainVerified() {
    // Any domain that has verified CNAME and TXT records and is not pending
    return !this.domainInactive && !this.domainPending;
  }

  get certificateInactive() {
    // Any domain that is not verified
    return !this.domainVerified;
  }

  get certificateVerified() {
    // It doesn't make sense to include certificate_unverified in this check?
    return [
      'certificate_created',
      'certificate_unverified',
      'certificate_verified',
    ].includes(this.status);
  }

  get sslCheckInactive() {
    // Anything that is not certificate verified
    return !this.certificateVerified;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    domain: DomainModel;
  }
}
