import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import CustomReportModel from 'teamtailor/models/custom-report';
import CustomReportSchema from 'teamtailor/utils/custom-report-schema';
import CustomReportRequest, {
  fetchSchema,
} from 'teamtailor/utils/custom-report-request';
import AnalyticsService from 'teamtailor/services/analytics';
import Store from '@ember-data/store';
import { Transition } from 'teamtailor/utils/type-utils';
import { hasTrueDirtyAttributes } from 'teamtailor/controllers/analytics/custom-reports/snapshots/edit';
import IntlService from 'ember-intl/services/intl';
import RouterService from '@ember/routing/router-service';

interface QueryParams {
  custom_report_id: string;
  full?: boolean;
}

export default class extends Route {
  @service declare store: Store;
  @service declare analytics: AnalyticsService;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  queryParams = {
    template: {
      refreshModel: true,
    },

    full: {
      refreshModel: true,
    },
  };

  async model({ custom_report_id, full }: QueryParams): Promise<{
    schema: CustomReportSchema;
    request: CustomReportRequest;
    customReport: CustomReportModel;
  }> {
    const schema = await fetchSchema(this.store);
    const customReport = await this.store.findRecord(
      'custom-report',
      custom_report_id
    );

    const request = await customReport.buildRequest(
      !full,
      this.analytics.hasEnabledGroupAnalytics
    );

    return { schema, request, customReport };
  }

  afterModel() {
    this.store.findAll('department');
  }

  activate() {
    this.router.on('routeWillChange', this.onRouterWillChange);
  }

  deactivate() {
    this.router.off('routeWillChange', this.onRouterWillChange);
  }

  onRouterWillChange = (transition: Transition) => {
    if (!transition.to.find((route) => route.name === this.routeName)) {
      const { customReport } = this.controller.model;
      if (customReport.hasDirtyAttributes) {
        if (
          hasTrueDirtyAttributes(customReport.changedAttributes()) &&
          !confirm(this.intl.t('common.unsaved_changes_confirm'))
        ) {
          transition.abort();
          customReport.rollbackAttributes();
        }
      }
    }
  };
}
