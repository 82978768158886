import ApplicationInstance from '@ember/application/instance';
import { task } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';

import { inject as service } from '@ember/service';
import { gql } from '@apollo/client/core';
import ReportAnalyticsRequest from './report-analytics-request';
import DateRange from 'teamtailor/utils/date-range';
import moment from 'moment-timezone';
import AnalyticsService from 'teamtailor/services/analytics';

const query = gql`
  query PipelineConversionsQuery(
    $dateRange: DateRangeAttributes!
    $cohortDateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    all: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [SOURCED, APPLIED, REFERRAL, MOVED, HIRED, REJECTED]
      distinctBy: {
        fields: [JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
      filters: [
        { jobApplicationId: { exists: true } }
        { jobApplicationCreatedAt: { between: $cohortDateRange } }
      ]
      companyIds: $companyIds
    ) {
      aggregated(groupBy: [STAGE_TYPE]) {
        stageType
        total: count
        rejected: countOccurrences(filters: { eventType: { equals: REJECTED } })
      }
    }
  }
`;

class PipelineConversionReport {
  rows: any[];

  constructor({ rows }: { rows?: any[] }) {
    this.rows = rows ?? [];
  }
}

export default class PipelineConversionReportFetcher {
  @service declare analytics: AnalyticsService;

  container: ApplicationInstance;

  constructor({ container }: { container: ApplicationInstance }) {
    this.container = container;
  }

  fetch = task(async (): Promise<PipelineConversionReport> => {
    const { startDate, endDate } = this.analytics;

    const cohortDateRange = new DateRange(
      startDate,
      moment(endDate).add(1, 'day')
    ).asObject;

    const dateRange = new DateRange(
      startDate,
      moment().subtract(1, 'day').format('YYYY-MM-DD')
    );

    const results = await new ReportAnalyticsRequest({
      container: this.container,
      query,
      extraVariables: {
        cohortDateRange,
      },

      callback: (result: any) => result,
    }).fetch({ dateRange });

    return new PipelineConversionReport({
      rows: results?.all?.aggregated,
    });
  });

  fetchTask = trackedTask(this, this.fetch);
}
