import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  hasMany,
  belongsTo,
} from '@ember-data/model';
import Company from './company';
import ScorecardPick from './scorecard-pick';

export enum TOPIC {
  TRAIT = 'personality',
  SKILL = 'skills',
}

export default class ScorecardCriteriumModel extends Model {
  @belongsTo('company')
  declare company: AsyncBelongsTo<Company>;

  @hasMany('scorecard-pick')
  declare scorecardPicks: AsyncHasMany<ScorecardPick>;

  @attr('string') declare name: string;
  @attr('string') declare topic: TOPIC;
  @attr('boolean') declare addedByCopilot: boolean;
  @attr('date') declare createdAt: Date;
  @attr('number') declare usageCount: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'scorecard-criterium': ScorecardCriteriumModel;
  }
}
