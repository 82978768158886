import {
  validatePresence,
  validateFormat,
} from 'ember-changeset-validations/validators';

export default {
  name: validatePresence(true),
  inviteEmail: [validateFormat({ type: 'email', allowBlank: false })],
  recruitingFirm: validatePresence(true),
};
