import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showTooltip}}\n  <div class=\"h-16 leading-small\">\n    <Icon @icon=\"square-t\" @size=\"16\" class=\"text-neutral-weak\" />\n    <EmberTooltip\n      @text={{t \"job.job_ad_title\" externalTitle=@job.externalTitle}}\n      @popperOptions={{hash positionFixed=true}}\n      @side=\"top\"\n    />\n  </div>\n{{/if}}", {"contents":"{{#if this.showTooltip}}\n  <div class=\"h-16 leading-small\">\n    <Icon @icon=\"square-t\" @size=\"16\" class=\"text-neutral-weak\" />\n    <EmberTooltip\n      @text={{t \"job.job_ad_title\" externalTitle=@job.externalTitle}}\n      @popperOptions={{hash positionFixed=true}}\n      @side=\"top\"\n    />\n  </div>\n{{/if}}","moduleName":"teamtailor/components/job-title-tooltip.hbs","parseOptions":{"srcName":"teamtailor/components/job-title-tooltip.hbs"}});
import Component from '@glimmer/component';
import JobModel from 'teamtailor/models/job';
import { get } from 'teamtailor/utils/get';

interface JobTitleTooltipArgs {
  job: JobModel;
}

export default class JobTitleTooltip extends Component<JobTitleTooltipArgs> {
  get showTooltip(): boolean {
    return !!get(this.args.job, 'internalName');
  }
}
