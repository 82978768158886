import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default class ContentBlockTextSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    pickedImages: {
      serialize: 'records',
      deserialize: 'ids',
      key: 'picked_images_attributes',
    },

    company: { serialize: false },
  };

  payloadKeyFromModelName() {
    return 'content_block_text';
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'content-block-text': ContentBlockTextSerializer;
  }
}
