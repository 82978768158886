import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed, get, set, action } from '@ember/object';
import { alias, and, gt, sort, or, equal, not } from '@ember/object/computed';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';

export default Controller.extend({
  intl: service(),

  queryParams: {
    nurtureCampaignStepId: 'nurture_campaign_step_id',
    sortColumn: 'sort_column',
    sortDirection: 'sort_direction',
    status: 'status',
  },

  showFilterSidebar: computedLocalStorage(
    Boolean,
    'Recipients.showFilterSidebar',
    false
  ),

  totalAvailable: alias('nurtureCampaign.nurtureCampaignRecipientsCount'),
  hasRecipients: gt('total', 0),
  hasAvailableRecipients: gt('totalAvailable', 0),
  nurtureCampaignRecipients: alias('model.nurtureCampaignRecipients'),
  shouldShowFilters: and(
    'hasAvailableRecipients',
    'nurtureCampaignRecipients.isFulfilled'
  ),

  total: computed(
    'hasAvailableRecipients',
    'model.nurtureCampaignRecipients.content.meta.total_count',
    'nurtureCampaignRecipients.isFulfilled',
    'totalAvailable',
    function () {
      return get(this, 'hasAvailableRecipients')
        ? get(this, 'model.nurtureCampaignRecipients.content.meta.total_count')
        : undefined;
    }
  ),

  nurtureCampaignStepId: '',

  emptyNurtureCampaignStepId: equal('nurtureCampaignStepId', ''),
  emptyStatus: equal('status', ''),

  hasStatus: not('emptyStatus'),
  hasNurtureCampaignStepsId: not('emptyNurtureCampaignStepId'),

  hasFiltersSelected: or('hasStatus', 'hasNurtureCampaignStepsId'),

  sortColumn: 'created_at',
  sortDirection: 'desc',
  status: '',

  emailStepsOrder: Object.freeze(['rowOrder:asc']),
  sortedEmailSteps: sort('nurtureCampaign.emailSteps', 'emailStepsOrder'),

  clearFilters: action(function () {
    set(this, 'status', '');
    set(this, 'nurtureCampaignStepId', '');
  }),

  selectItem: action(function (attribute, selected) {
    set(this, attribute, selected ? get(selected, 'id') : '');
  }),

  reloadRecipients: action(function () {
    get(this, 'target').send('refreshAction');
  }),
});
