import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { set } from '@ember/object';

export default class LayoutRoute extends Route {
  @service filthyContent;

  queryParams = {
    isNewSection: { refreshModel: true },
  };

  isNewSection = false;

  setupController(controller, model) {
    super.setupController(controller, model);

    if (this.paramsFor(this.routeName).isNewSection) {
      this.filthyContent.setFilthy(model.id);
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      set(controller, 'isNewSection', null);
    }
  }
}
