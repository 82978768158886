import Component from '@ember/component';
import { get, setProperties } from '@ember/object';
import { bind, next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';

const possibleColors = [
  'DodgerBlue',
  'OliveDrab',
  'Gold',
  'Pink',
  'SlateBlue',
  'LightBlue',
  'Gold',
  'Violet',
  'PaleGreen',
  'SteelBlue',
  'SandyBrown',
  'Chocolate',
  'Crimson',
];

const randomFromTo = function (from, to) {
  return Math.floor(Math.random() * (to - from + 1) + from);
};

const ConfettiParticle = function (W, H, context, maxConfettis, emojis) {
  this.x = Math.random() * W; // x
  this.y = Math.random() * H - H; // y
  this.r = randomFromTo(11, 33); // radius
  this.d = Math.random() * maxConfettis + 11;
  this.emoji = emojis && emojis[Math.floor(Math.random() * emojis.length)];
  this.color =
    possibleColors[Math.floor(Math.random() * possibleColors.length)];
  this.tilt = Math.floor(Math.random() * 33) - 11;
  this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
  this.tiltAngle = 0;

  this.draw = function () {
    if (this.emoji) {
      context.font = '40px Segoe UI';
      return context.fillText(this.emoji, this.x, this.y);
    } else {
      context.beginPath();
      context.lineWidth = this.r / 2;
      context.strokeStyle = this.color;
      context.moveTo(this.x + this.tilt + this.r / 3, this.y);
      context.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5);
      return context.stroke();
    }
  };
};

export default Component.extend({
  confetti: service(),
  tagName: 'canvas',
  classNames: ['c-confetti-explosion'],

  classNameBindings: [
    'showFadeOut:c-confetti-explosion--fade-out',
    'withCover:c-confetti-explosion--with-cover',
  ],

  withCover: alias('confetti.withCover'),
  fadeOut: alias('confetti.fadeOut'),
  emojis: alias('confetti.emojis'),
  maxConfettis: 120,
  preventFadeOut: false,

  get showFadeOut() {
    return !this.preventFadeOut && this.fadeOut;
  },

  didInsertElement() {
    this._super(...arguments);

    let width = window.innerWidth;
    let height = window.innerHeight;
    let canvas = this.element;
    let context = canvas.getContext('2d');
    let particles = [];
    let maxConfettis = get(this, 'maxConfettis');

    setProperties(this, {
      width,
      height,
      canvas,
      context,
      particles,
    });

    next(this, () => {
      for (let i = 0; i < maxConfettis; i++) {
        get(this, 'particles').push(
          new ConfettiParticle(
            width,
            height,
            context,
            maxConfettis,
            get(this, 'emojis')
          )
        );
      }
    });

    canvas.width = width;
    canvas.height = height;
    requestAnimationFrame(bind(this, this.draw));
  },

  draw() {
    if (get(this, 'isDestroyed')) {
      return;
    }

    const context = get(this, 'context');
    const H = get(this, 'height');
    const W = get(this, 'width');

    context.clearRect(0, 0, W, window.innerHeight);

    get(this, 'particles').forEach((particle, i) => {
      particle.draw();
      particle.tiltAngle += particle.tiltAngleIncremental;
      particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2;
      particle.tilt = Math.sin(particle.tiltAngle - i / 3) * 15;

      // If a confetti has fluttered out of view,
      // bring it back to above the viewport and let if re-fall.
      if (particle.x > W + 30 || particle.x < -30 || particle.y > H) {
        particle.x = Math.random() * W;
        particle.y = -30;
        particle.tilt = Math.floor(Math.random() * 10) - 20;
      }
    });

    // Magical recursive functional love
    requestAnimationFrame(bind(this, this.draw));
  },
});
