import { set, get, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import TranslateResourcesHelper from 'teamtailor/helpers/translate-resources';

export default function customMessage(type) {
  let customSubject = `${type}Subject`;
  let customBody = `${type}Body`;
  let message = `${type}MessageWithFallback`;

  return computed(customSubject, customBody, {
    get() {
      return (
        !isEmpty(get(this, customSubject)) || !isEmpty(get(this, customBody))
      );
    },

    set(key, value) {
      if (value) {
        const helper = new TranslateResourcesHelper();
        const originalMessage = get(this, message);
        const languageCode = get(this, 'languageCode');
        let translatedMessage = helper.compute([
          originalMessage,
          languageCode,
          'canned-response',
        ]);
        set(this, customSubject, get(translatedMessage, 'subject'));
        set(this, customBody, get(translatedMessage, 'body'));
        return value;
      } else {
        set(this, customSubject, null);
        set(this, customBody, null);
        return value;
      }
    },
  });
}
