import classic from 'ember-classic-decorator';
import { or, not, equal, alias } from '@ember/object/computed';
import Controller from '@ember/controller';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-mixins
import SearchQuery from 'teamtailor/mixins/search-query';
import { set, action, computed } from '@ember/object';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import { dropTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

@classic
export default class IndexController extends Controller.extend(SearchQuery) {
  @service flipper;
  @service table;
  @service store;
  @service current;

  queryParams = [
    'page',
    'query',
    'role',
    'department_id',
    'location_id',
    'two_factor_authentication_available',
    'view',
  ];

  @computedLocalStorage(Boolean, 'Employees.index.showFilterSidebar', false)
  showFilterSidebar;

  query = '';
  page = 1;
  per_page = 20;
  role = '';
  department_id = '';
  location_id = '';
  view = 'list';
  two_factor_authentication_available = false;

  @tracked showBulk = false;
  @tracked selectAll = false;
  @tracked deselectedEmployeeIds = [];
  @tracked selectedEmployeeIds = [];

  @alias('fetchEmployees.last.value.meta.total_count')
  totalCount;

  @equal('role', '')
  defaultRoleFilter;

  @equal('department_id', '')
  emptyDepartment;

  @equal('location_id', '')
  emptyLocation;

  @not('defaultRoleFilter')
  hasRole;

  @not('emptyDepartment')
  hasDepartment;

  @not('emptyLocation')
  hasLocation;

  @or('hasRole', 'hasDepartment', 'hasLocation')
  hasFiltersSelected;

  get selectedCount() {
    if (this.selectAll) {
      return this.totalCount - this.deselectedEmployeeIds.length;
    } else {
      return this.selectedEmployeeIds.length;
    }
  }

  @computed(
    'department_id',
    'location_id',
    'query',
    'role',
    'single_sign_on_activated',
    'two_factor_authentication_activated'
  )
  get searchParams() {
    return {
      department_id: this.department_id,
      location_id: this.location_id,
      query: this.query,
      role: this.role,
      single_sign_on_activated: this.single_sign_on_activated,
      two_factor_authentication_activated:
        this.two_factor_authentication_activated,
    };
  }

  get showSingleSignOnFilter() {
    return get(this.current.company, 'singleSignOnActive');
  }

  fetchEmployees = dropTask(async (clearSelection) => {
    const params = {
      ...this.searchParams,
      page: this.page,
      per_page: this.per_page,
    };

    if (clearSelection) {
      this.clearSelection();
    }

    return this.store.query('user', params);
  });

  @action
  didInsert() {
    if (!this.fetchEmployees.last || this.table.refreshEmployeesTable) {
      this.fetchEmployees.perform(false);
      this.refreshEmployeesTable = false;
    }
  }

  @action
  clearFilters() {
    set(this, 'role', '');
    set(this, 'department_id', '');
    set(this, 'location_id', '');
  }

  @action
  selectItem(attribute, selected) {
    this.resetPage();
    set(this, attribute, selected ? get(selected, 'id') : '');
  }

  @action
  resetPage() {
    set(this, 'page', 1);
  }

  @action
  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    this.deselectedEmployeeIds.clear();
    this.selectedEmployeeIds.clear();
  }

  @action
  clearSelection() {
    this.selectAll = false;
    this.deselectedEmployeeIds.clear();
    this.selectedEmployeeIds.clear();
  }

  @action
  refetchEmployees() {
    if (this.page !== 1) {
      set(this, 'page', 1);
      this.clearSelection();
    } else {
      this.fetchEmployees.perform(true);
    }
  }

  @action
  toggleShowBulk() {
    if (this.showBulk) {
      this.clearSelection();
    }

    this.showBulk = !this.showBulk;
  }
}
