import Model, { attr } from '@ember-data/model';
import {
  CompositionStatus,
  TranscriptionStatus,
} from 'teamtailor/constants/video-room';

export default class VideoRoomModel extends Model {
  @attr('string')
  declare sid: string;

  @attr('string')
  declare name: string | null;

  @attr('boolean')
  declare isRecorded: boolean;

  @attr('string')
  declare recordingStatus: string;

  @attr('string')
  declare compositionSid: string | null;

  @attr('string')
  declare compositionStatus: CompositionStatus;

  @attr('number')
  declare compositionProgress: number | null;

  @attr('number')
  declare duration: number | null;

  @attr('string')
  declare mediaUrl: string | null;

  @attr('string')
  declare transcriptionJsonUrl: string | null;

  @attr('string')
  declare transcriptionStatus: TranscriptionStatus;

  @attr('date')
  declare createdAt: Date;

  @attr('date')
  declare updatedAt: Date;

  get isCompositionAvailable(): boolean {
    return this.compositionStatus === CompositionStatus.AVAILABLE;
  }

  get isCompositionNA(): boolean {
    return this.compositionStatus === CompositionStatus.NA;
  }

  get hasCompositionFailed(): boolean {
    return this.compositionStatus === CompositionStatus.FAILED;
  }

  get isTranscriptionAvailable(): boolean {
    return this.transcriptionStatus === TranscriptionStatus.AVAILABLE;
  }

  get hasTranscriptionFailed(): boolean {
    return this.transcriptionStatus === TranscriptionStatus.FAILED;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'video-room': VideoRoomModel;
  }
}
