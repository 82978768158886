import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  hasMany,
  belongsTo,
  attr,
} from '@ember-data/model';
import { get } from 'teamtailor/utils/get';
import { isEmpty } from '@ember/utils';
import ScorecardCriterium from './scorecard-criterium';
import ScorecardScore from './scorecard-score';
import JobDetail from './job-detail';

export default class ScorecardPickModel extends Model {
  @hasMany('scorecard-score')
  declare scorecardScores: AsyncHasMany<ScorecardScore>;

  @belongsTo('scorecard-criterium')
  declare scorecardCriterium: AsyncBelongsTo<ScorecardCriterium>;

  @belongsTo('job-detail')
  declare jobDetail: AsyncBelongsTo<JobDetail>;

  @attr('number') declare rowOrder: number;
  @attr('number') declare rowOrderPosition: number;
  @attr('number') declare weight: number;

  get topic() {
    return get(this.scorecardCriterium, 'topic');
  }

  get scorecardCriteriumId(): string {
    return get(this.scorecardCriterium, 'id');
  }

  get jobId(): string | undefined {
    const jobDetail = get(this, 'jobDetail') as JobDetail;
    return get(jobDetail, 'id');
  }

  get position(): number {
    const { rowOrderPosition, rowOrder } = this;
    return isEmpty(rowOrderPosition) ? rowOrder : rowOrderPosition;
  }

  get name(): string {
    return get(this.scorecardCriterium, 'name');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'scorecard-pick': ScorecardPickModel;
  }
}
