import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex\">\n  <Core::Input\n    class=\"rounded-r-0 border-r-0\"\n    @value={{@value}}\n    @size=\"large\"\n    @isReadonly={{true}}\n    @isFullWidth={{true}}\n  />\n  <Button\n    @text={{t \"components.copy_input.copy\"}}\n    class=\"h-auto min-w-[100px] rounded-l-0 pb-0 pt-0\"\n    @onClick={{this.copyToClipboard}}\n  />\n</div>", {"contents":"<div class=\"flex\">\n  <Core::Input\n    class=\"rounded-r-0 border-r-0\"\n    @value={{@value}}\n    @size=\"large\"\n    @isReadonly={{true}}\n    @isFullWidth={{true}}\n  />\n  <Button\n    @text={{t \"components.copy_input.copy\"}}\n    class=\"h-auto min-w-[100px] rounded-l-0 pb-0 pt-0\"\n    @onClick={{this.copyToClipboard}}\n  />\n</div>","moduleName":"teamtailor/components/copy-input.hbs","parseOptions":{"srcName":"teamtailor/components/copy-input.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class CopyInput extends Component {
  @service intl;
  @service flashMessages;

  @action
  copyToClipboard() {
    try {
      navigator.clipboard.writeText(this.args.value);
      this.flashMessages.success(this.intl.t('common.copied_to_clipboard'));
    } catch (error) {
      this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
    }
  }
}
