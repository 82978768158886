import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  local-class={{class-names\n    \"root\"\n    root--hasNoReactions=@hasNoReactions\n    root--isHovering=@isHovering\n  }}\n  {{outside-click this.onClickOutside leakyDelayAdd=true}}\n>\n  <div\n    local-class={{class-names\n      \"emojiWrapper\"\n      emojiWrapper--show=this.showEmojis\n      emojiWrapper--hasReactions=(not @hasNoReactions)\n    }}\n  >\n    {{#each @emojis as |emoji|}}\n      <button\n        type=\"button\"\n        local-class=\"emoji\"\n        {{on \"click\" (fn this.handleReaction emoji)}}\n      >\n        {{emoji}}\n      </button>\n    {{/each}}\n  </div>\n\n  <button\n    type=\"button\"\n    local-class=\"toggleBtn\"\n    {{on \"click\" this.toggleShowEmojis}}\n  >\n    <Icon @icon=\"comment-smile\" />\n  </button>\n</div>", {"contents":"<div\n  local-class={{class-names\n    \"root\"\n    root--hasNoReactions=@hasNoReactions\n    root--isHovering=@isHovering\n  }}\n  {{outside-click this.onClickOutside leakyDelayAdd=true}}\n>\n  <div\n    local-class={{class-names\n      \"emojiWrapper\"\n      emojiWrapper--show=this.showEmojis\n      emojiWrapper--hasReactions=(not @hasNoReactions)\n    }}\n  >\n    {{#each @emojis as |emoji|}}\n      <button\n        type=\"button\"\n        local-class=\"emoji\"\n        {{on \"click\" (fn this.handleReaction emoji)}}\n      >\n        {{emoji}}\n      </button>\n    {{/each}}\n  </div>\n\n  <button\n    type=\"button\"\n    local-class=\"toggleBtn\"\n    {{on \"click\" this.toggleShowEmojis}}\n  >\n    <Icon @icon=\"comment-smile\" />\n  </button>\n</div>","moduleName":"teamtailor/components/reactions/add-reaction.hbs","parseOptions":{"srcName":"teamtailor/components/reactions/add-reaction.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ReactionsAddReactionComponent extends Component {
  @tracked showEmojis = false;

  @action
  toggleShowEmojis() {
    this.showEmojis = !this.showEmojis;
  }

  @action
  handleReaction(emoji) {
    this.args.handleReaction(emoji);
    this.toggleShowEmojis();
  }

  @action
  onClickOutside() {
    this.showEmojis = false;
  }
}
