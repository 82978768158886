import Helper from '@ember/component/helper';

export function sectionName(name) {
  switch (name) {
    case 'cover':
      return 'Cover';
    case 'custom':
      return 'Custom HTML & CSS';
    case 'grid':
      return 'Grid';
    case 'welcome':
      return 'Welcome';
    case 'departments-list':
      return 'Departments List';
    case 'locations-list':
      return 'Locations List';
    case 'people':
      return 'People';
    case 'workplace':
      return 'Workplace & Culture';
    case 'perks':
      return 'Perks & Benefits';
    case 'jobs':
      return 'Jobs';
    case 'blog':
      return 'Blog';
    case 'social':
      return 'Social Widgets';
    case 'stories':
      return 'Team stories';
    case 'locations-map':
      return 'Locations Map';
    case 'companies':
      return 'Group Companies';
    case 'group_jobs':
      return 'Group Jobs';
    case 'text':
      return 'Text';
    case 'gallery':
      return 'Gallery';
    case 'cta':
      return 'Call to action';
    case 'hero':
      return 'Hero';
    case 'faq':
      return 'FAQ';
    case 'testimonial':
      return 'Testimonial';
    case 'infographic':
      return 'Infographic';
    case 'timeline':
      return 'Timeline';
    case 'value':
      return 'Company Values';
    case 'list':
      return 'List';
    case 'picture':
      return 'Image';
    case 'linked':
      return 'Global block';
    case 'connect':
      return 'Connect';
    default:
      return undefined;
  }
}

export default Helper.helper(function (params) {
  return sectionName(params[0]);
});
