import { isEmpty } from '@ember/utils';
import { get } from 'teamtailor/utils/get';

/*
 *
 * This file is the js version of app/services/placeholders.rb
 * Must change both if editing.
 *
 */

export default function placeholders(
  candidate,
  job,
  company = get(candidate, 'company'),
  internal = false
) {
  let placeholderData = {};
  if (candidate) {
    placeholderData = {
      'first-name': get(candidate, 'firstName'),
      'last-name': get(candidate, 'lastName'),
      'full-name': get(candidate, 'name'),
    };
  }

  if (job) {
    const jobDisplayedTitle = internal
      ? get(job, 'title')
      : get(job, 'externalTitle');

    placeholderData = { ...placeholderData, 'job-title': jobDisplayedTitle };
  }

  if (company) {
    placeholderData = {
      ...placeholderData,
      'connect-url': get(company, 'paths.connect_url'),
      'privacy-url': get(company, 'paths.privacy_url'),
      'policy-url': get(company, 'paths.policy_url'),
      'company-name': get(company, 'name'),
    };
  }

  let regexp = new RegExp(
    `{(${Object.keys(placeholderData).join('|')})(?:\\s(:?[^\\}]*))?}`,
    'ig'
  );

  return {
    parse(text, anonymous = false) {
      return text.replace(regexp, function (_, key, fallback) {
        if (
          anonymous &&
          ['first-name', 'last-name', 'full-name'].includes(key)
        ) {
          return '';
        } else {
          return placeholderData[key] || (isEmpty(fallback) ? '' : fallback);
        }
      });
    },
  };
}
