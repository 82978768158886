import Controller from '@ember/controller';
import { get, action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SettingsController extends Controller {
  @service router;

  get page() {
    return this.model;
  }

  @action
  savePage() {
    get(this, 'page')
      .save()
      .then(() => {
        this.router.transitionTo('content.index');
      });
  }
}
