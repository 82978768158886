import classic from 'ember-classic-decorator';
import { sort } from '@ember/object/computed';
import Controller from '@ember/controller';
import { get, computed } from '@ember/object';

@classic
export default class ChatChannelsController extends Controller {
  queryParams = ['archived'];
  archived = null;

  get channelsWithMessages() {
    return this.model.channels.filter((channel) => channel.messagesCount > 0);
  }

  get activeChannels() {
    return this.channelsWithMessages.filter((channel) => channel.notArchived);
  }

  get archivedChannels() {
    return this.channelsWithMessages.filter(
      (channel) => channel.attributes.archived
    );
  }

  @computed('archived', 'activeChannels', 'archivedChannels')
  get visibleChannels() {
    if (get(this, 'archived')) {
      return get(this, 'archivedChannels');
    }

    return get(this, 'activeChannels');
  }

  channelSorting = [
    'attributes.lastMessageFromCandidateReceivedAt:desc',
    'dateCreated:desc',
  ];

  @sort('visibleChannels', 'channelSorting')
  sortedVisibleChannels;
}
