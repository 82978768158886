import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex h-full flex-1 flex-col pb-16 pt-10\">\n  {{#if this.isLoading}}\n    <LoadingSpinner />\n  {{else}}\n    {{#if (is-empty this.unreadJobs)}}\n      <EmptyState\n        @header={{t \"global_comments.read_only.title\"}}\n        @emoji=\"🚜\"\n        @color=\"yellow\"\n      >\n        {{t \"global_comments.read_only.description\"}}\n      </EmptyState>\n    {{else}}\n      {{#each this.unreadJobs as |job|}}\n        <Toolbox::GlobalComments::Unread::UnreadJob @job={{job}} />\n      {{/each}}\n    {{/if}}\n  {{/if}}\n</div>", {"contents":"<div class=\"flex h-full flex-1 flex-col pb-16 pt-10\">\n  {{#if this.isLoading}}\n    <LoadingSpinner />\n  {{else}}\n    {{#if (is-empty this.unreadJobs)}}\n      <EmptyState\n        @header={{t \"global_comments.read_only.title\"}}\n        @emoji=\"🚜\"\n        @color=\"yellow\"\n      >\n        {{t \"global_comments.read_only.description\"}}\n      </EmptyState>\n    {{else}}\n      {{#each this.unreadJobs as |job|}}\n        <Toolbox::GlobalComments::Unread::UnreadJob @job={{job}} />\n      {{/each}}\n    {{/if}}\n  {{/if}}\n</div>","moduleName":"teamtailor/components/toolbox/global-comments/unread/unread-channel.hbs","parseOptions":{"srcName":"teamtailor/components/toolbox/global-comments/unread/unread-channel.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';
import { trackedFunction } from 'ember-resources/util/function';

type Args = {
  jobIds: string[];
  isLoading?: boolean;
};
export default class UnreadChannelComponent extends Component<Args> {
  @service declare store: Store;
  @service declare current: Current;

  resolveUnreadJobResponses = trackedFunction(this, async () => {
    if (this.args.jobIds.length === 0) return [];

    const unreadSortedJobs = await this.store.query('job', {
      ids: this.args.jobIds,
    });
    return unreadSortedJobs;
  });

  get isLoading() {
    return this.args.isLoading || this.resolveUnreadJobResponses.value === null;
  }

  get unreadJobs() {
    return this.resolveUnreadJobResponses.value ?? [];
  }
}
