import { inject as service } from '@ember/service';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { set, setProperties } from '@ember/object';
import IntlService from 'ember-intl/services/intl';
import AccessLevels from 'teamtailor/services/access-levels';
import { get } from 'teamtailor/utils/get';
import { Transition } from 'teamtailor/utils/type-utils';
import EmployeesBulkController from 'teamtailor/controllers/employees/bulk';
import RouterService from '@ember/routing/router-service';

export default class BulkRoute extends ScrollToTopRoute {
  @service declare intl: IntlService;
  @service declare accessLevels: AccessLevels;
  @service declare router: RouterService;

  async setupController(
    controller: EmployeesBulkController,
    model: unknown,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);
    await this.accessLevels.loadAll();

    set(
      controller,
      'bulkEmployeesAccessLevelIds',
      this.accessLevels.accessLevelIdsWithDefault()
    );
  }

  resetController(controller: EmployeesBulkController, isExiting: boolean) {
    if (isExiting) {
      const accessLevelIds = this.accessLevels.accessLevelIdsWithDefault();

      setProperties(controller, {
        bulkEmployeesEmails: null,
        bulkEmployeesVisible: null,
        bulkEmployeesHideEmail: null,
        bulkEmployeesHidePhone: null,
        bulkEmployeesRole: 'no_access',
        bulkEmployeesAccessLevelIds: accessLevelIds,
        errors: null,
        employeesCreated: false,
      });
    }
  }

  activate() {
    this.router.on('routeWillChange', this.onRouterWillChange);
  }

  deactivate() {
    this.router.off('routeWillChange', this.onRouterWillChange);
  }

  onRouterWillChange = (transition: Transition) => {
    if (!transition.to.find((route) => route.name === this.routeName)) {
      const controller = this.controller as EmployeesBulkController;

      if (
        get(controller, 'hasDirtyProperties') &&
        !get(controller, 'employeesCreated') &&
        !confirm(this.intl.t('common.unsaved_changes_confirm'))
      ) {
        transition.abort();
      }
    }
  };
}
