import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class={{class-names\n    \"relative self-center border-l-2 border-neutral\"\n    (if (eq this.size \"small\") \"h-24\")\n    (if (eq this.size \"medium\") \"h-32\")\n    (if (eq this.size \"large\") \"h-40\")\n  }}\n>\n  <div\n    class={{class-names\n      \"absolute rounded-full border-2 border-neutral bg-white\"\n      (if (eq this.size \"small\") \"-right-[3px] top-[8px] size-8\")\n      (if (eq this.size \"medium\") \"-right-[5px] top-10 size-12\")\n      (if (eq this.size \"large\") \"-right-[7px] top-[12px] size-16\")\n    }}\n  >\n  </div>\n</div>", {"contents":"<div\n  class={{class-names\n    \"relative self-center border-l-2 border-neutral\"\n    (if (eq this.size \"small\") \"h-24\")\n    (if (eq this.size \"medium\") \"h-32\")\n    (if (eq this.size \"large\") \"h-40\")\n  }}\n>\n  <div\n    class={{class-names\n      \"absolute rounded-full border-2 border-neutral bg-white\"\n      (if (eq this.size \"small\") \"-right-[3px] top-[8px] size-8\")\n      (if (eq this.size \"medium\") \"-right-[5px] top-10 size-12\")\n      (if (eq this.size \"large\") \"-right-[7px] top-[12px] size-16\")\n    }}\n  >\n  </div>\n</div>","moduleName":"teamtailor/components/knot.hbs","parseOptions":{"srcName":"teamtailor/components/knot.hbs"}});
import Component from '@glimmer/component';
import { verifyArg } from 'teamtailor/utils/verify-arg';

const DEFAULT_SIZE = 'medium';
const ALLOWED_SIZES = ['small', DEFAULT_SIZE, 'large'] as const;
type AllowedSizes = (typeof ALLOWED_SIZES)[number];

export type Args = {
  size?: AllowedSizes;
};

export default class KnotComponent extends Component<Args> {
  get size(): AllowedSizes {
    verifyArg(this.args.size, ALLOWED_SIZES, 'knot @size', DEFAULT_SIZE);

    return this.args.size ?? DEFAULT_SIZE;
  }
}
