import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<small>\n  {{t \"questions.filters.value\"}}\n</small>\n\n<FormInput\n  @type=\"date\"\n  @onFocusOut={{this.updateFilter}}\n  @value={{this.value}}\n/>", {"contents":"<small>\n  {{t \"questions.filters.value\"}}\n</small>\n\n<FormInput\n  @type=\"date\"\n  @onFocusOut={{this.updateFilter}}\n  @value={{this.value}}\n/>","moduleName":"teamtailor/components/question-filter-date.hbs","parseOptions":{"srcName":"teamtailor/components/question-filter-date.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

type QuestionFilters = {
  date?: string;
};

interface QuestionFilterNumberArgs {
  updateFilter({ date }: { date: string }): void;
  filter: QuestionFilters;
}

export default class QuestionFilterNumber extends Component<QuestionFilterNumberArgs> {
  get value(): string {
    return this.args.filter.date ?? '';
  }

  set value(val: string) {
    this.args.filter.date = val;
  }

  @action
  updateFilter(): void {
    this.args.updateFilter({ date: this.value.toString() });
  }
}
