import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span class={{this.classes}}>\n  <span class=\"step-indicator__step__dot\"></span>\n</span>", {"contents":"<span class={{this.classes}}>\n  <span class=\"step-indicator__step__dot\"></span>\n</span>","moduleName":"teamtailor/components/step-indicator-step.hbs","parseOptions":{"srcName":"teamtailor/components/step-indicator-step.hbs"}});
import Component from '@glimmer/component';
import classnames from 'teamtailor/utils/classnames';

export default class StepIndicatorStepComponent extends Component {
  get classes() {
    return classnames('step-indicator__step', {
      'step-indicator__step--active': this.args.step <= this.args.currentStep,
    });
  }
}
