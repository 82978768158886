import Component from '@glimmer/component';
import { action, get } from '@ember/object';

export default class WidgetClass extends Component {
  get isEditing() {
    return this.args.isEditing || false;
  }

  get dragging() {
    return this.args.dragging || false;
  }

  get draggable() {
    return this.args.draggable || false;
  }

  get widget() {
    return get(this.args.pickedDashboardWidget, 'dashboardWidget');
  }

  get widgetColor() {
    return get(this.widget, 'color');
  }

  @action
  handleSettingsClick() {
    this.args.onSettingsClick?.();
  }
}
