import {
  attr,
  AsyncBelongsTo,
  AsyncHasMany,
  belongsTo,
  hasMany,
  SyncHasMany,
} from '@ember-data/model';
import { getProperties } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import {
  CompanyModel,
  PickedQuestionModel,
  TagModel,
  TranslationCannedResponseModel,
  UploadModel,
} from 'teamtailor/models';
import OrganizableModel from 'teamtailor/models/organizable';

export default class CannedResponseModel extends OrganizableModel {
  @belongsTo('company', {
    inverse: 'dataPrivacyCannedResponses',
  })
  declare dataPrivacyCompany: AsyncBelongsTo<CompanyModel>;

  @hasMany('tag') declare tags: AsyncHasMany<TagModel>;
  @hasMany('translation/canned-response')
  declare translations: AsyncHasMany<TranslationCannedResponseModel>;

  @hasMany('picked-question', { async: false })
  declare pickedQuestions: SyncHasMany<PickedQuestionModel>;

  @hasMany('upload') declare uploads: AsyncHasMany<UploadModel>;

  @attr('string', { defaultValue: '' }) declare body: string;
  @attr('boolean') declare consentExpiredReminder: boolean;
  @attr('boolean') declare consentMissingReminder: boolean;
  @attr('boolean') declare consentExtendReminder: boolean;
  @attr('boolean') declare defaultReply: boolean;
  @attr('string') declare languageCode: string;
  @attr('string') declare name: string;
  @attr('boolean') declare reject: boolean;
  @attr('string') declare subject: string;
  @attr('boolean') declare welcomeToConnect: boolean;
  @attr('boolean') declare externalRecruiterInvite: boolean;

  get questions() {
    return get(this, 'sortedQuestions').mapBy('question');
  }

  get searchString() {
    return Object.values(getProperties(this, 'name', 'body', 'subject'))
      .join(' ')
      .toLowerCase();
  }

  get sortedQuestions() {
    return get(this, 'pickedQuestions').sortBy('position');
  }

  get nameWithTags() {
    return `${get(this, 'name')} (${get(this, 'tags').mapBy('name')})`;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'canned-response': CannedResponseModel;
  }
}
