import Store from '@ember-data/store';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import ReferenceTemplateEditController from 'teamtailor/controllers/settings/reference-templates/edit';
import ReferenceTemplateModel from 'teamtailor/models/reference-template';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { questionsToFormFields } from 'teamtailor/utils/form-builder';
import { get } from 'teamtailor/utils/get';
import { Transition } from 'teamtailor/utils/type-utils';

export default class EditRoute extends ScrollToTopRoute {
  @service declare store: Store;

  model(params: { reference_template_id: string }) {
    return this.store.findRecord(
      'reference-template',
      params.reference_template_id
    );
  }

  setupController(
    controller: ReferenceTemplateEditController,
    model: ReferenceTemplateModel,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);

    const fields = questionsToFormFields(
      get(model, 'sortedFormQuestions').filter((q) => !q.isNew)
    );

    set(controller, 'fields', fields);
  }

  resetController(
    controller: ReferenceTemplateEditController,
    isExiting: boolean
  ) {
    if (isExiting) {
      set(controller, 'fields', []);
      if (get(controller.model, 'hasDirtyAttributes')) {
        controller.model.rollbackAttributes();
      }
    }
  }
}
