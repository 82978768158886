import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ol\n  class={{class-names\n    \"c-chart-legend\"\n    (if @horizontal \"c-chart-legend--horizontal clearfix\")\n  }}\n>\n  {{#each this.titlesWithColor as |t|}}\n    <li>\n      <StageBullet class=\"c-chart-legend__bullet\" style={{t.styles}} />\n      {{#if (has-block)}}\n        {{yield t.title}}\n      {{else}}\n        {{t.title}}\n      {{/if}}\n    </li>\n  {{/each}}\n</ol>", {"contents":"<ol\n  class={{class-names\n    \"c-chart-legend\"\n    (if @horizontal \"c-chart-legend--horizontal clearfix\")\n  }}\n>\n  {{#each this.titlesWithColor as |t|}}\n    <li>\n      <StageBullet class=\"c-chart-legend__bullet\" style={{t.styles}} />\n      {{#if (has-block)}}\n        {{yield t.title}}\n      {{else}}\n        {{t.title}}\n      {{/if}}\n    </li>\n  {{/each}}\n</ol>","moduleName":"teamtailor/components/chart-legend.hbs","parseOptions":{"srcName":"teamtailor/components/chart-legend.hbs"}});
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';

export default class ChartLegend extends Component {
  get titlesWithColor() {
    const { colors, titles } = this.args;

    return titles.map((title, index) => {
      return {
        styles: this.buildStyles(colors[index]),
        title,
      };
    });
  }

  buildStyles(color) {
    return htmlSafe(`background-color: ${color}`);
  }
}
