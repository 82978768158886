// @ts-expect-error No types available, need to write our own
import Delimiter from '@editorjs/delimiter';

export default class Divider extends Delimiter {
  static get pasteConfig() {
    return {
      tags: ['HR'],
    };
  }

  onPaste() {}
}
