import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.displayHiddenInput}}\n  <HiddenInput\n    @inputClass=\"body-text-s\"\n    @buttonClass=\"max-w-full break-all\"\n    @inputType={{@field.type}}\n    @value={{@answer}}\n    @onChange={{@onUpdateAnswer}}\n    @placeholder={{t \"common.empty\"}}\n    @destinationElement={{@destinationElement}}\n  />\n{{else}}\n  <FormBuilder::DefaultField::Edit\n    @field={{@field}}\n    @hideLabel={{true}}\n    @includeOtherOption={{@includeOtherOption}}\n    @onAddOption={{@onAddOption}}\n    @onUpdateOption={{@onUpdateOption}}\n    @onRemoveOption={{@onRemoveOption}}\n    @allowAnswers={{@allowAnswers}}\n    @answer={{@answer}}\n    @onUpdateAnswer={{@onUpdateAnswer}}\n    @radioContainerClass=\"px-8\"\n  />\n{{/if}}", {"contents":"{{#if this.displayHiddenInput}}\n  <HiddenInput\n    @inputClass=\"body-text-s\"\n    @buttonClass=\"max-w-full break-all\"\n    @inputType={{@field.type}}\n    @value={{@answer}}\n    @onChange={{@onUpdateAnswer}}\n    @placeholder={{t \"common.empty\"}}\n    @destinationElement={{@destinationElement}}\n  />\n{{else}}\n  <FormBuilder::DefaultField::Edit\n    @field={{@field}}\n    @hideLabel={{true}}\n    @includeOtherOption={{@includeOtherOption}}\n    @onAddOption={{@onAddOption}}\n    @onUpdateOption={{@onUpdateOption}}\n    @onRemoveOption={{@onRemoveOption}}\n    @allowAnswers={{@allowAnswers}}\n    @answer={{@answer}}\n    @onUpdateAnswer={{@onUpdateAnswer}}\n    @radioContainerClass=\"px-8\"\n  />\n{{/if}}","moduleName":"teamtailor/components/form-builder/list-field/edit/index.hbs","parseOptions":{"srcName":"teamtailor/components/form-builder/list-field/edit/index.hbs"}});
import Component from '@glimmer/component';

export default class FormListFieldEditable extends Component {
  get displayHiddenInput() {
    const fieldType = this.args.field.type;
    return !(fieldType === 'radio' || fieldType === 'checkbox');
  }
}
