import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { AddonFeature } from 'types/features';

export default class HasFeature extends Helper {
  @service declare current: Current;

  compute([featureName]: [AddonFeature]) {
    return this.current.company.hasFeature(featureName);
  }
}
