import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';

export default class MarketplaceRoute extends Route {
  @service store;

  model() {
    return hash({
      partners: this.store.findAll('partner'),
    });
  }

  afterModel() {
    return this.store.findAll('partner-activation');
  }
}
