import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<StatusButton\n  {{did-insert this.setupPusher}}\n  {{will-destroy this.tearDownPusher}}\n  @appearance=\"secondary\"\n  @action={{this.pauseToggle}}\n  @size=\"small\"\n  @icon={{if this.isPaused \"play\" \"pause\"}}\n  @text={{t\n    (concat \"components.promotion.card.\" (if this.isPaused \"resume\" \"pause\"))\n  }}\n  class=\"self-start\"\n/>", {"contents":"<StatusButton\n  {{did-insert this.setupPusher}}\n  {{will-destroy this.tearDownPusher}}\n  @appearance=\"secondary\"\n  @action={{this.pauseToggle}}\n  @size=\"small\"\n  @icon={{if this.isPaused \"play\" \"pause\"}}\n  @text={{t\n    (concat \"components.promotion.card.\" (if this.isPaused \"resume\" \"pause\"))\n  }}\n  class=\"self-start\"\n/>","moduleName":"teamtailor/components/promotion/action/toggle-pause.hbs","parseOptions":{"srcName":"teamtailor/components/promotion/action/toggle-pause.hbs"}});
import Store from '@ember-data/store';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { get } from 'teamtailor/utils/get';
import { PromotionModel } from 'teamtailor/models';
import { tracked } from '@glimmer/tracking';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import PusherService from 'teamtailor/services/pusher';

export interface PromotionActionTogglePauseArgs {
  promotion: PromotionModel;
}

export default class PromotionActionTogglePause extends Component<PromotionActionTogglePauseArgs> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare current: Current;
  @service declare flashMessages: FlashMessageService;
  @service declare pusher: PusherService;
  @tracked isDisabled = false;

  get channel() {
    return this.args.promotion.channel;
  }

  get isPaused(): boolean {
    return this.args.promotion.paused || this.args.promotion.isPaused;
  }

  @action
  async pauseToggle() {
    if (get(this.channel, 'hasAdapter')) {
      this.isDisabled = true;
      await this.args.promotion.togglePause(!this.isPaused);
    }
  }

  @action
  async setupPusher() {
    const channel = await this.pusher.channel(
      get(this.current, 'user.notificationsChannel')
    );
    channel.bind('promotion-request-action', async ({ type }) => {
      if (type === 'fail') {
        this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
      } else {
        this.flashMessages.success(
          this.intl.t('components.promotion.card.promotion_status_changed')
        );
      }

      await this.args.promotion.reload();

      this.isDisabled = false;
    });
  }

  @action
  async teardownPusher() {
    const channel = await this.pusher.channel(
      get(this.current, 'user.notificationsChannel')
    );

    channel.unbind('promotion-request-action');
  }
}
