export default {
  "table": "PermissionsTable__table_4x50k9",
  "sticky": "PermissionsTable__sticky_4x50k9",
  "header": "PermissionsTable__header_4x50k9",
  "category": "PermissionsTable__category_4x50k9",
  "row": "PermissionsTable__row_4x50k9",
  "scopeIcon": "PermissionsTable__scopeIcon_4x50k9",
  "full": "PermissionsTable__full_4x50k9",
  "conditional": "PermissionsTable__conditional_4x50k9",
  "icon": "PermissionsTable__icon_4x50k9",
  "dot": "PermissionsTable__dot_4x50k9"
};
