import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import { set } from '@ember/object';
import { isPresent } from '@ember/utils';
import { getRobohashUrl } from 'teamtailor/utils/robohash';
import imageUrl from 'teamtailor/utils/image-url';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import { underscore } from '@ember/string';
import uniqBy from 'teamtailor/utils/uniq-by';

const numberOfUserColors = 6; // Defined as $user-colors in our scss variables
export default class UserModel extends Model {
  @service permissions;
  @service flipper;
  @service intl;
  @service server;
  @service intercom;
  @service userOnlineStatus;

  isUser = true;
  hasUpcomingCalendarEvents = false;
  requisitionApproverCount = 0;
  jobOfferApproverCount = 0;

  @belongsTo('company', { async: false, inverse: null }) company;
  @belongsTo('recruiting-firm') recruitingFirm;
  @belongsTo('data-privacy-setting') dataPrivacySetting;
  @belongsTo('location') location;
  @belongsTo('user-invitation', { async: false }) userInvitation;
  @belongsTo('login', { async: false }) login;
  @belongsTo('audit-log-export', { inverse: null }) incompleteExport;
  @belongsTo('department', { inverse: null }) department;
  @belongsTo('user-status', { async: false }) activeStatus;

  @hasMany('activity', { inverse: 'user' }) activities;
  @hasMany('todo') todos;
  @hasMany('todo', { inverse: 'assignee' }) assignedTodos;
  @hasMany('authorization') authorizations;
  @hasMany('department', { inverse: 'manager' }) departments;
  @hasMany('translation/user') translations;
  @hasMany('job', { inverse: 'user' }) jobs;
  @hasMany('job', { inverse: 'recruiter' }) recruiterJobs;
  @hasMany('referral') referrals;
  @hasMany('scorecard-comment') scorecardComments;
  @hasMany('team', { inverse: 'user' }) managerTeams;
  @hasMany('team') teams;
  @hasMany('department', {
    inverse: 'teamMembers',
  })
  teamMemberDepartments;

  @hasMany('location', {
    inverse: null,
  })
  teamMemberLocations;

  @hasMany('nurture-campaign-step', { polymorphic: true, inverse: 'sender' })
  nurtureCampaignSteps;

  @hasMany('job-detail', { inverse: 'teamMembers' }) teamMemberJobs;

  @hasMany('picked-dashboard-widget', {
    async: false,
  })
  pickedDashboardWidgets;

  @hasMany('requisition', { inverse: 'recruiter' }) requisitionRecruiter;
  @hasMany('requisition', { inverse: 'user' }) requisitionOwner;

  @hasMany('selected-candidate-display-column')
  selectedCandidateDisplayColumns;

  @hasMany('meeting-event') meetingEvents;
  @hasMany('gifts') gifts;
  @hasMany('access-level', { async: false }) accessLevels;
  @hasMany('notification-setting') notificationSettings;

  @attr('string') languageCode;
  @attr('raw') accessLevelIds;
  @attr('boolean') allowedToLogin;
  @attr('boolean') teamMemberForNoDepartment;
  @attr('boolean') autoJoin;
  @attr('string') companyId;
  @attr('date') createdAt;
  @attr('string') description;
  @attr('string') departmentId;
  @attr('string') email;
  @attr('string') firstName;
  @attr('raw') multipleCompanies;
  @attr('boolean') hideEmail;
  @attr('boolean') hidePhone;
  @attr('boolean') hideOnlineStatus;
  @attr('string') initials;
  @attr('string') intercomId;
  @attr('string') intercomUserHash;
  @attr('string') inviteEmail;
  @attr('string') lastName;
  @attr('string') locale;
  @attr('string') loginId;
  @attr('number') jobsCreated;
  @attr('string') name;
  @attr('string') notificationsChannel;
  @attr('string') phone;
  @attr('raw') paths;
  @attr('carrierwave') picture;
  @attr('carrierwave-cache') pictureCache;
  @attr('boolean', { defaultValue: false }) resendInvitation;
  @attr('string', { defaultValue: 'no_access' }) role;
  @attr('string') signature;
  @attr('string') title;
  @attr('boolean') superadmin;
  @attr('string') username;
  @attr('string') uuid;
  @attr('boolean') visible;
  @attr('string') slackUserId;
  @attr('string') startOfWeekDay;
  @attr('string') timeFormat;
  @attr('string') timeZone;
  @attr('string') tzid;
  @attr('string') twitterProfile;
  @attr('string') facebookProfile;
  @attr('string') linkedinProfile;
  @attr('string') instagramProfile;
  @attr('string') otherProfile;
  @attr('date') lastTtUpdateCheck;
  @attr('string') replacementUserId;
  @attr('raw') teamMemberDepartmentIds;
  @attr('boolean', { defaultValue: false }) meetingsHasDailyLimit;
  @attr('boolean', { defaultValue: false }) meetingsHasWeeklyLimit;
  @attr('number', { defaultValue: 4 }) meetingsDailyLimit;
  @attr('number', { defaultValue: 20 }) meetingsWeeklyLimit;

  get allTeams() {
    return uniqBy(
      [...get(this, 'teams').toArray(), ...get(this, 'managerTeams').toArray()],
      'id'
    );
  }

  get fullErrorMessages() {
    const modelName = underscore(this.constructor.modelName);

    return get(this, 'errors').map((error) => {
      const translationKey = `models.${modelName}.${underscore(
        error.attribute
      )}`;
      let { message, attribute } = error;
      message = `${this.intl.t(translationKey)} ${error.message}`;
      return { message, attribute };
    });
  }

  get memberDepartments() {
    return get(this, 'company.departmentsWithoutNoDepartment').filterBy(
      'memberOrManager'
    );
  }

  get addonsAccessLevels() {
    return get(this, 'accessLevels').filterBy('isAddon');
  }

  get cannotExportAuditLog() {
    return get(this, 'incompleteExport.id');
  }

  get sweater() {
    return get(this, 'gifts').findBy('product', 'sweater');
  }

  get sortedAvailableDepartments() {
    return get(this, 'availableDepartments').sortBy('name');
  }

  get tshirt() {
    return get(this, 'gifts').findBy('product', 'tshirt');
  }

  get showEmail() {
    return !get(this, 'hideEmail');
  }

  set showEmail(value) {
    set(this, 'hideEmail', !value);
  }

  get showPhone() {
    return !get(this, 'hidePhone');
  }

  set showPhone(value) {
    set(this, 'hidePhone', !value);
  }

  get persistedAuthorizations() {
    return get(this, 'authorizations').filterBy('isNew', false);
  }

  get cronofyAuthorizations() {
    return get(this, 'persistedAuthorizations').filterBy('isCronofy');
  }

  get cronofyAuthorization() {
    return get(this, 'cronofyAuthorizations')?.[0];
  }

  get hasMultipleCompanies() {
    return get(this, 'multipleCompanies.length') > 1;
  }

  get nameOrEmail() {
    return get(this, 'name') || get(this, 'email');
  }

  get publicEmail() {
    return get(this, 'inviteEmail') || get(this, 'email');
  }

  get admin() {
    return this.hasRole('admin');
  }

  get recruitmentAdmin() {
    return this.hasRole('recruitment_admin');
  }

  get designer() {
    return this.hasRole('designer');
  }

  get recruiter() {
    return this.hasRole('recruiter');
  }

  get user() {
    return this.hasRole('user');
  }

  get externalRecruiter() {
    return this.hasRole('external_recruiter');
  }

  get noAccess() {
    return this.hasRole('no_access');
  }

  get notExternalRecruiter() {
    return !this.externalRecruiter;
  }

  get adminOrRecruitmentAdmin() {
    return this.admin || this.recruitmentAdmin;
  }

  get displayedRole() {
    return get(this, 'accessLevels').findBy('isAddon', false).name;
  }

  get roleAddons() {
    return get(this, 'addonsAccessLevels').mapBy('name');
  }

  get manager() {
    return get(this, 'id') === get(this, 'company.manager.id');
  }

  get roboHash() {
    return getRobohashUrl(get(this, 'id'));
  }

  get ttUser() {
    return this.email.endsWith('@teamtailor.com');
  }

  get pictureUrl() {
    return imageUrl(get(this, 'picture'), 'employee_picture');
  }

  get avatarUrl() {
    if (get(this, 'picture')) {
      return get(this, 'pictureUrl');
    } else {
      return get(this, 'roboHash');
    }
  }

  get colorIndex() {
    return get(this, 'id') % numberOfUserColors;
  }

  get searchString() {
    return `${this.name} ${this.email} ${this.username}`
      .replace(/\s/g, '')
      .toLowerCase();
  }

  get displayName() {
    const name = get(this, 'name');
    if (name) {
      const parts = name.split(/\s+/);
      // If there are two names we guess first is the given name
      // Otherwise we default to full name instead of making wrong guesses
      return parts.length === 2 ? parts[0] : name;
    } else {
      return null;
    }
  }

  get hasMadeTshirtDecision() {
    const localStorageKey = `tshirtState-u-${get(this, 'id')}`;
    return localStorage.getItem(localStorageKey) === 'completed';
  }

  get hasMadeSweaterDecision() {
    const localStorageKey = `sweaterState-u-${get(this, 'id')}`;
    return localStorage.getItem(localStorageKey) === 'completed';
  }

  get hasAnyProfle() {
    return (
      get(this, 'hasTwitterProfile') ||
      get(this, 'hasFacebookProfile') ||
      get(this, 'hasLinkedinProfile') ||
      get(this, 'hasInstagramProfile') ||
      get(this, 'hasOtherProfile')
    );
  }

  get canCreateTags() {
    return (
      this.permissions.has('settings/update_recruitment') ||
      !get(this, 'company.restrictTagChanges')
    );
  }

  get teamDepartments() {
    return get(this, 'allTeams').reduce((departments, team) => {
      return [...departments, ...get(team, 'departments').toArray()];
    }, []);
  }

  get teamRoles() {
    return get(this, 'allTeams').reduce((roles, team) => {
      return [...roles, ...get(team, 'roles').toArray()];
    }, []);
  }

  get availableDepartments() {
    return uniqBy(
      get(this, 'teamDepartments')
        .addObjects(get(this, 'departments'))
        .addObjects(get(this, 'teamRoles').mapBy('department')),
      'id'
    );
  }

  get hasActiveStatus() {
    return !!this.activeStatus?.id;
  }

  get isOnline() {
    return this.userOnlineStatus.isUserOnline(this.id);
  }

  matchingSearchString = (query) => {
    return this.searchString.indexOf(query.toLowerCase()) >= 0;
  };

  recruiterForCandidate = (candidate) => {
    return get(candidate, 'jobs').some((job) => {
      if (job) {
        let job_user_id = job.belongsTo('user').id();
        return job_user_id && job_user_id === get(this, 'id');
      }

      return undefined;
    });
  };

  hasRole = (role) => {
    return isPresent(get(this, 'accessLevels').findBy('name', role));
  };

  async markConversationsAsRead(data) {
    return this.server.memberAction(this, {
      action: 'mark_conversations_as_read',
      method: 'post',
      options: { data },
    });
  }

  async checkForUpcomingEvents() {
    const response = await this.server.memberAction(this, {
      action: 'has_upcoming_calendar_events',
      method: 'get',
    });
    this.hasUpcomingCalendarEvents = response.has_upcoming_calendar_events;
    return this.hasUpcomingCalendarEvents;
  }

  async checkForRequisitionApprover() {
    const adapter = this.store.adapterFor('application');
    const url = adapter.buildURL('requisition-step-verdict');
    let response = await this.server.request(url, 'GET', {
      data: {
        user_id: this.id,
        status: 'pending',
        parent_type: 'requisition',
      },
    });
    this.requisitionApproverCount = response.meta?.total_count;
    return !!response.meta?.total_count;
  }

  async checkForJobOfferApprover() {
    const adapter = this.store.adapterFor('application');
    const url = adapter.buildURL('requisition-step-verdict');
    let response = await this.server.request(url, 'GET', {
      data: {
        user_id: this.id,
        status: 'pending',
        parent_type: 'job_offer',
      },
    });

    this.jobOfferApproverCount = response.meta?.total_count;
    return !!response.meta?.total_count;
  }

  async activateTwoFactorAuthentication(data) {
    const response = await this.server.memberAction(this, {
      action: 'activate_two_factor_authentication',
      method: 'post',
      options: { data },
    });
    this.store.pushPayload(response);
  }

  async disableTwoFactorAuthentication(data) {
    const response = await this.server.memberAction(this, {
      action: 'disable_two_factor_authentication',
      method: 'post',
      options: { data },
    });
    this.store.pushPayload(response);
  }

  async toggleHideOnlineStatus() {
    this.hideOnlineStatus = !this.hideOnlineStatus;

    this.userOnlineStatus.handleHideOnlineStatusChange();

    await this.server.memberAction(this, {
      action: 'toggle_hide_online_status',
      method: 'put',
      options: {
        data: {
          hide_online_status: this.hideOnlineStatus,
        },
      },
    });
  }
}
