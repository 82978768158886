import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import UserModel from 'teamtailor/models/user';

export default class UserInvitationModel extends Model {
  @belongsTo('user')
  declare user: AsyncBelongsTo<UserModel>;

  @attr('boolean')
  declare accepted: boolean;

  @attr('date')
  declare sentAt: Date;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-invitation': UserInvitationModel;
  }
}
