import {
  CustomReportFilter,
  CustomReportOperator,
  CustomReportValue,
} from './custom-report-schema';
import Model from '@ember-data/model';

import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';

export default class CustomReportRequestFilter {
  constructor(
    filter: CustomReportFilter,
    operator?: CustomReportOperator,
    value?: CustomReportValue
  ) {
    this.filter = filter;
    this.selectedOperator = operator;
    this.value = value;
    if (this.filterType === 'bool') {
      this.value = !!this.value;
    }

    if (!this.selectedOperator && this.filter.operators.length > 0) {
      this.selectedOperator = this.filter.operators[0];
    }
  }

  @tracked filter?: CustomReportFilter;
  @tracked selectedOperator?: CustomReportOperator;
  @tracked value?: CustomReportValue;
  @tracked isOpen = false;

  get name(): string | undefined {
    return this.filter?.name;
  }

  get isValid() {
    if (this.filterType === 'bool') {
      return this.filter && this.selectedOperator;
    }

    return this.filter && this.selectedOperator && this.value;
  }

  get options(): string[] {
    return this.filter?.options || [];
  }

  get operators(): CustomReportOperator[] | undefined {
    return this.filter?.operators;
  }

  get filterType(): string | undefined {
    return this.filter?.type;
  }

  get asJSON() {
    return {
      name: this.filter?.id,
      operator: this.selectedOperator,
      value: this.jsonValue,
    };
  }

  get jsonValue(): boolean | string | string[] | undefined {
    if (Array.isArray(this.value)) {
      return this.value.map((a: Model | string) =>
        typeof a === 'object' ? a.id : a
      );
    } else if (this.value instanceof Model) {
      return this.value.id;
    } else if (this.value instanceof Date) {
      return moment(this.value).format('YYYY-MM-DD');
    } else {
      return this.value;
    }
  }

  get serialize() {
    const values = [
      this.filter,
      this.selectedOperator,
      serializeValue(this.value),
    ];
    return JSON.stringify(values);
  }
}

export async function deserializeCustomReportRequestFilter(
  store: any,
  input: string,
  groupAnalytics: boolean
): Promise<CustomReportRequestFilter | undefined> {
  const [filter, operator, value] = JSON.parse(input) as any[];

  return new CustomReportRequestFilter(
    filter,
    operator,
    await deserializeValue(store, value, groupAnalytics)
  );
}

export interface SerializedModel {
  modelName: string;
  id: string;
}

export async function deserializeValue(
  store: any,
  input: boolean | string | string[] | SerializedModel | SerializedModel[],
  groupAnalytics: boolean
): Promise<CustomReportValue> {
  if (Array.isArray(input)) {
    const loadables = input
      .map((value: string | SerializedModel) =>
        typeof value === 'object' ? value : undefined
      )
      .filter(
        (loadable) =>
          loadable && !store.peekRecord(loadable.modelName, loadable.id)
      ) as SerializedModel[];

    if (loadables.length > 0 && loadables[0]) {
      const { modelName } = loadables[0];

      await store.query(modelName, {
        ids: loadables.map((loadable) => loadable.id),
        groupAnalytics,
      });
    }

    const output = input.map((value: string | SerializedModel) =>
      typeof value === 'object'
        ? store.peekRecord(value.modelName, value.id)
        : value
    );

    return output;
  } else if (typeof input === 'object' && input.modelName) {
    return store.findRecord(input.modelName, input.id, {
      backgroundReload: false,
      groupAnalytics,
    });
  } else if (input instanceof Date) {
    return input;
  } else if (input === 'false') {
    return false;
  } else if (input === 'true') {
    return true;
  }

  return input as string;
}

export function serializeValue(
  value?: CustomReportValue
): string | string[] | SerializedModel | SerializedModel[] {
  if (value === undefined) {
    return '';
  }

  if (Array.isArray(value)) {
    if (typeof value[0] === 'object') {
      return value.map((a: any) => ({
        modelName: a.constructor.modelName,
        id: a.id,
      }));
    } else {
      return value as string[];
    }
  } else if (value instanceof Model) {
    return { modelName: (value.constructor as any).modelName, id: value.id };
  } else if (value instanceof Date) {
    return moment(value).format('YYYY-MM-DD');
  } else if (value === true) {
    return 'true';
  } else if (value === false) {
    return 'false';
  } else {
    return value;
  }
}
