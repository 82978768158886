import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import CompanyModel from 'teamtailor/models/company';
import Current from 'teamtailor/services/current';

export default class SettingsSecurityEmailPasswordIndex extends ScrollToTopRoute {
  @service declare router: RouterService;
  @service declare current: Current;

  model(): CompanyModel {
    return this.current.company;
  }

  afterModel(model: CompanyModel) {
    if (model.twoFactorAuthenticationEnforcedAt) {
      this.router.transitionTo(
        'settings.security.email-password.2fa-deactivate'
      );
    } else {
      this.router.transitionTo('settings.security.email-password.2fa-setup');
    }
  }
}
