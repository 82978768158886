import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import CustomReportRequest, {
  buildFromSerializedFormat,
  fetchSchema,
} from 'teamtailor/utils/custom-report-request';
import ScheduledReport from './scheduled-report';
import { getOwner } from '@ember/application';
import {
  CustomReportRollups,
  CustomReportType,
} from 'teamtailor/utils/custom-report-schema';

export default class CustomReportModel extends Model {
  @attr('string') declare name: string;
  @attr('string') declare reportType: CustomReportType;
  @attr('string') declare emoji: string;
  @attr('date') declare updatedAt: Date;
  @attr('date') declare createdAt: string;

  @attr('array') declare properties: string[];
  @attr('raw') declare filters: any[];
  @attr('raw', { defaultValue: () => ({}) })
  declare rollups: CustomReportRollups;

  @belongsTo('scheduled-report')
  declare scheduledReport: AsyncBelongsTo<ScheduledReport>;

  async buildRequest(
    preview: boolean,
    groupAnalytics: boolean
  ): Promise<CustomReportRequest> {
    const schema = await fetchSchema(this.store);
    return buildFromSerializedFormat(
      getOwner(this),
      this.store,
      groupAnalytics,
      schema,
      preview,
      {
        reportType: this.reportType,
        properties: this.properties,
        filters: this.filters,
        rollups: this.rollups,
      }
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'custom-report': CustomReportModel;
  }
}
