import Service from '@ember/service';
import { timeout, keepLatestTask } from 'ember-concurrency';

export default class IframePreviewService extends Service {
  isIframeLoaded = false;
  scrollToSectionData = null;
  scrollToAnchorData = null;

  get pagePreviewIframe() {
    return document.querySelector('.careersite-preview--page');
  }

  get styleguidePreviewIframe() {
    return document.querySelector('.careersite-preview--styleguide');
  }

  postMessageToIframe(iframe, message) {
    return iframe.contentWindow.postMessage(JSON.stringify(message), '*');
  }

  postMessageToPagePreview(message) {
    if (!this.pagePreviewIframe) {
      return false;
    }

    return this.postMessageToIframe(this.pagePreviewIframe, message);
  }

  iframeLoaded() {
    this.isIframeLoaded = true;
    if (this.scrollToSectionData) {
      this.scrollToSection(this.scrollToSectionData);
    }

    if (this.scrollToAnchorData) {
      this.scrollToAnchor();
    }
  }

  reloadPageIframeTask = keepLatestTask(async () => {
    this.isIframeLoaded = false;
    await this.postMessageToPagePreview({ reload: true });
  });

  reloadIframe() {
    this.reloadPageIframeTask.perform();
  }

  reloadStyleguideIframeTask = keepLatestTask(async () => {
    if (this.styleguidePreviewIframe) {
      await this.postMessageToIframe(this.styleguidePreviewIframe, {
        reload: true,
      });
    }
  });

  reloadStyleguideIframe() {
    this.reloadStyleguideIframeTask.perform();
  }

  reloadSectionTask = keepLatestTask(async (sectionId) => {
    await this.postMessageToPagePreview({ reload: true, sectionId });
  });

  reloadSection(sectionId) {
    this.reloadSectionTask.perform(sectionId);
  }

  removeSectionTask = keepLatestTask(async (sectionId) => {
    await this.postMessageToPagePreview({ delete: true, sectionId });
  });

  removeSection(sectionId) {
    return this.removeSectionTask.perform(sectionId);
  }

  scrollToSectionTask = keepLatestTask(async ({ section, sectionId }) => {
    this.scrollToSectionData = { section, sectionId };

    if (this.isIframeLoaded) {
      await timeout(500);
      await this.postMessageToPagePreview(this.scrollToSectionData);
      this.scrollToSectionData = null;
    }
  });

  scrollToSection({ section, sectionId }) {
    return this.scrollToSectionTask.perform({ section, sectionId });
  }

  scrollToAnchorTask = keepLatestTask(async () => {
    if (this.isIframeLoaded && this.scrollToAnchorData) {
      await timeout(500);
      await this.postMessageToPagePreview(this.scrollToAnchorData);
      this.scrollToAnchorData = null;
    }
  });

  scrollToAnchor(anchor = null) {
    if (anchor) {
      this.scrollToAnchorData = { scrollAnchor: anchor };
    }

    return this.scrollToAnchorTask.perform();
  }

  scrollToTop() {
    this.scrollToAnchorData = { scrollAnchor: null };
    return this.scrollToAnchorTask.perform();
  }
}
