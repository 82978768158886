import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import { task, timeout } from 'ember-concurrency';

export default class BatchJobService extends Service {
  @service pusher;
  @service store;
  @service current;
  @service user;

  @tracked totalCount;
  @tracked hideBatchJobTriggered = false;

  showOnCompleteTimeout = 5000;

  constructor() {
    super(...arguments);
    this.pusher
      .subscribe(get(this, 'user.notificationsChannel'))
      .then((channel) => {
        channel.bind('batch-job', (batch) => {
          this.batchJobSetup(batch);
        });

        channel.bind('batch-job-completed', (batch) => {
          this.batchJobCompletedTask.perform(batch);
        });
      });
  }

  batchJobSetup(batch) {
    const batchJobAttrs = {
      id: batch.id,
      bid: batch.bid,
      batchType: batch.batch_type,
      batchAction: batch.batch_action,
      companyName: batch.company_name,
      totalCount: this.totalCount,
      data: batch.data,
    };

    if (batch.parent_batch_job_id) {
      batchJobAttrs.parentBatchJob = this.store.peekRecord(
        'batchJob',
        batch.parent_batch_job_id
      );
    }

    this.store.findRecord('user', batch.user_id).then((user) => {
      batchJobAttrs.user = user;
      get(this.current.company, 'batchJobs').createRecord(batchJobAttrs);
    });
  }

  batchJobStartedTask = task(async (promise, totalCount) => {
    this.totalCount = totalCount;
    await promise;
  });

  batchJobCompletedTask = task(async (batch) => {
    const batchJob = get(this.current.company, 'batchJobs').findBy(
      'bid',
      batch.bid
    );

    if (batchJob && !get(batchJob, 'isDeleted')) {
      batchJob.isOpened = true;
      batchJob.isComplete = true;
    }

    if (batchJob?.isBulkCandidateReject && batchJob?.data?.job_id) {
      const job = await this.store.peekRecord('job', batchJob.data.job_id);
      if (job) {
        job.refreshJobApplications();
      }
    }

    this.totalCount = null;

    await timeout(this.showOnCompleteTimeout);

    batchJob.isOpened = false;
  });
}
