import { inject as service } from '@ember/service';
import { set, get } from '@ember/object';
import CandidateModalRoute from 'teamtailor/routes/candidate-modal';

export default class CandidateRoute extends CandidateModalRoute {
  @service user;
  @service current;

  markAsRead(jobApplication) {
    if (
      get(jobApplication, 'unread') &&
      (!get(this, 'user.ttUser') ||
        get(this, 'user.company.id') === this.current.company.id)
    ) {
      jobApplication.markAsRead();
    }

    return undefined;
  }

  afterModel(model) {
    super.afterModel(...arguments);
    set(this, 'jobApplication', null);

    let job = this.modelFor('jobs.job');

    return get(model, 'jobApplications').then((jobApplications) => {
      let jobApplication = jobApplications.findBy('job.id', get(job, 'id'));
      set(this, 'jobApplication', jobApplication);
      return this.markAsRead(jobApplication);
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    set(controller, 'jobApplication', get(this, 'jobApplication'));
  }
}
