import BaseRoute from 'teamtailor/routes/base';

export default class ScrollToTopRoute extends BaseRoute {
  activate() {
    const scrollableContainer = document.getElementById('scrollable-container');
    if (scrollableContainer) {
      scrollableContainer.scrollTop = 0;
    } else {
      window.scrollTo(0, 0);
    }
  }
}
