import Model, {
  hasMany,
  belongsTo,
  attr,
  type AsyncBelongsTo,
  type SyncHasMany,
} from '@ember-data/model';
import Raw from 'teamtailor/transforms/raw';
import { CompanyModel, UploadModel } from 'teamtailor/models';

export default class SmsSettingsModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @hasMany('upload', { async: false })
  declare uploads: SyncHasMany<UploadModel>;

  @attr('boolean', { defaultValue: false }) declare hasAutoRenewal: boolean;
  @attr('boolean', { defaultValue: false })
  declare identityMessageShow: boolean;

  @attr('boolean', { defaultValue: false }) declare identityRequired: boolean;

  @attr('number') declare creditsRenewalThreshold: number;
  @attr('number') declare creditsToBuy: number;
  @attr('string') declare identityRejectReason: string;
  @attr('string') declare identityStatus: string;
  @attr('raw') declare creditsRenewalThresholdOptions: Raw;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'sms-setting': SmsSettingsModel;
  }
}
