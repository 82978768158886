import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Modal\n  @isOpen={{@isOpen}}\n  @onClose={{this.onClose}}\n  @size=\"medium\"\n  as |modal|\n>\n  <modal.Header @text={{t \"components.bulk_actions.employees.change_role\"}} />\n  <modal.Main as |main|>\n    <main.Section>\n      <Employees::MultiRoleSelector\n        @groupValue={{this.role}}\n        @onChange={{this.onChangeRole}}\n      />\n    </main.Section>\n  </modal.Main>\n  <modal.Footer>\n    <:right>\n      <Button\n        @text={{t \"common.cancel\"}}\n        @onClick={{this.onClose}}\n        @appearance=\"tertiary\"\n      />\n      <StatusButton\n        @isDisabled={{is-empty this.role}}\n        @action={{this.handleBulkChange}}\n        @text={{t \"common.save\"}}\n      />\n    </:right>\n  </modal.Footer>\n</Core::Modal>", {"contents":"<Core::Modal\n  @isOpen={{@isOpen}}\n  @onClose={{this.onClose}}\n  @size=\"medium\"\n  as |modal|\n>\n  <modal.Header @text={{t \"components.bulk_actions.employees.change_role\"}} />\n  <modal.Main as |main|>\n    <main.Section>\n      <Employees::MultiRoleSelector\n        @groupValue={{this.role}}\n        @onChange={{this.onChangeRole}}\n      />\n    </main.Section>\n  </modal.Main>\n  <modal.Footer>\n    <:right>\n      <Button\n        @text={{t \"common.cancel\"}}\n        @onClick={{this.onClose}}\n        @appearance=\"tertiary\"\n      />\n      <StatusButton\n        @isDisabled={{is-empty this.role}}\n        @action={{this.handleBulkChange}}\n        @text={{t \"common.save\"}}\n      />\n    </:right>\n  </modal.Footer>\n</Core::Modal>","moduleName":"teamtailor/components/bulk-actions/employees/role.hbs","parseOptions":{"srcName":"teamtailor/components/bulk-actions/employees/role.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { EmployeeBulkArgs } from 'teamtailor/constants/employee';
import AccessLevels from 'teamtailor/services/access-levels';
import { inject as service } from '@ember/service';
import FlipperService from 'teamtailor/services/flipper';

export default class BulkActionsEmployeesRoleComponent extends Component<EmployeeBulkArgs> {
  @service declare flipper: FlipperService;
  @tracked role = '';
  @tracked declare accessLevelIds: (string | undefined)[];
  @service declare accessLevels: AccessLevels;

  @action
  onClose(): void {
    this.args.onClose();
    this.role = '';
  }

  @action
  onChangeRole(value: string, addons: string[]): void {
    this.role = value;
    this.accessLevelIds = this.accessLevels.accessLevelIdsWithDefault(
      value,
      addons
    );
  }

  @action
  handleBulkChange(): void {
    const data = {
      role: this.role,
      accessLevelIds: this.accessLevelIds,
    };
    this.args.handleBulkChange(data, 'bulk_role');
    this.onClose();
  }
}
