import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task, timeout, restartableTask } from 'ember-concurrency';

export default class BaseComponent extends Component {
  @service current;
  @service store;
  @service bulkApi;
  @service batchJob;
  @service intl;
  @service flashMessages;

  get user() {
    return this.current.user;
  }

  get hasJob() {
    return !!this.args.job;
  }

  get company() {
    return this.current.company;
  }

  messageTask = restartableTask(async () => {
    await timeout(30000);
    if (this.postTask.isRunning) {
      this.flashMessages.notice(
        this.intl.t('mixins.bulk_actions_base.might_take_while'),
        { sticky: true }
      );
    }
  });

  postTask = task(async (action, data, scope = 'candidates') => {
    this.messageTask.perform();
    await this.bulkApi.post(action, data, scope);
    this.flashMessages.clearMessages();
  });

  get bulkOptionsQuery() {
    return this.args.searchParams?.query;
  }

  get bulkOptions() {
    if (this.args.selectAll) {
      return {
        query: this.args.searchParams,
        deselectedCandidateIds: this.args.deselectedIds,
      };
    } else {
      return {
        selectedCandidateIds: this.args.selectedIds,
      };
    }
  }

  postAction(action, data, scope = 'candidates') {
    return this.postTask.perform(action, data, scope);
  }

  postData(data) {
    return Object.assign(this.bulkOptions, data);
  }
}
