import Helper from '@ember/component/helper';
import { TRIGGER_COLORS } from 'teamtailor/constants/trigger-colors';

export function triggerColor([triggerType, alternativeStyle = false]) {
  const mappedColor = TRIGGER_COLORS[triggerType];

  if (!mappedColor) {
    return '';
  }

  let color = alternativeStyle
    ? [`text-decorative-${mappedColor}`]
    : [
        `bg-decorative-${mappedColor}-medium`,
        `text-decorative-${mappedColor}-medium`,
      ];

  return color.join(' ');
}

export default Helper.helper(triggerColor);
