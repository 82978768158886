import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.results}}\n  {{#each this.partnerActivations as |partnerActivation|}}\n    <CandidateModalNew::Main::Section::Partners::Results\n      @candidate={{@candidate}}\n      @partner={{partnerActivation.partner}}\n      @results={{this.results}}\n    />\n  {{/each}}\n{{/if}}", {"contents":"{{#if this.results}}\n  {{#each this.partnerActivations as |partnerActivation|}}\n    <CandidateModalNew::Main::Section::Partners::Results\n      @candidate={{@candidate}}\n      @partner={{partnerActivation.partner}}\n      @results={{this.results}}\n    />\n  {{/each}}\n{{/if}}","moduleName":"teamtailor/components/candidate-modal-new/main/section/partners/index.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-modal-new/main/section/partners/index.hbs"}});
import Component from '@glimmer/component';
import CandidateModel from 'teamtailor/models/candidate';
import JobApplicationModel from 'teamtailor/models/job-application';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';

interface Args {
  candidate: CandidateModel;
  jobApplication?: JobApplicationModel;
}

export default class CandidateModalNewMainTestsComponents extends Component<Args> {
  @service declare current: Current;

  get results() {
    let results = this.args.candidate.partnerResults
      .toArray()
      .sortBy('createdAt')
      .reverse();

    if (this.args.jobApplication) {
      const { jobId } = this.args.jobApplication;

      if (typeof jobId === 'number') {
        results = results.filter((r) => r.jobId === jobId.toString());
      }
    }

    return results;
  }

  get partnerActivations() {
    return this.current.company.partnerActivations.filter(
      (pa) => pa.capabilityCandidateResults
    );
  }
}
