import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import CustomFieldModel from '../custom-field';

export default class CustomFieldOptionModel extends Model {
  @belongsTo('custom-field', { polymorphic: true })
  declare customField: AsyncBelongsTo<CustomFieldModel>;

  @attr('string') declare value: string;
  @attr('number') declare rowOrder: number;
  @attr('number') declare rowOrderPosition: number;

  @attr('boolean', { defaultValue: false })
  declare _destroy: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'custom-field/option': CustomFieldOptionModel;
  }
}
