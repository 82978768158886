import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';

import UserModel from './user';
import PartnerModel from './partner';
import CustomFieldModel from './custom-field';
import QuestionModel from './question';

export default class SelectedCandidateDisplayColumnModel extends Model {
  @attr('string') declare candidateColumnName: string;

  @belongsTo('user', { async: true }) declare user: AsyncBelongsTo<UserModel>;
  @belongsTo('partner', { async: true })
  declare partner: AsyncBelongsTo<PartnerModel>;

  @belongsTo('custom-field', { async: true, polymorphic: true })
  declare customField: AsyncBelongsTo<CustomFieldModel>;

  @belongsTo('question', { async: true })
  declare question: AsyncBelongsTo<QuestionModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'selected-candidate-display-column': SelectedCandidateDisplayColumnModel;
  }
}
