import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';

export default class ChatWidgetController extends Controller {
  @service intl;
  @service store;

  get job() {
    return this.model;
  }

  get messageWidget() {
    return get(get(this.job, 'jobDetail'), 'messageWidget');
  }

  createMessageWidget(enabled = true) {
    this.store.createRecord('messageWidget', {
      greeting: this.intl.t('jobs.edit.chat_widget.chat_info_description'),
      user: get(this.job, 'user'),
      jobDetail: get(this.job, 'jobDetail'),
      popupGreeting: true,
      enabled,
    });
  }

  @action
  destroyMessageWidget() {
    return this.messageWidget.destroyRecord();
  }

  @action
  handleToggle() {
    if (!this.messageWidget) {
      this.createMessageWidget();
    }

    this.messageWidget.enabled = !this.messageWidget.enabled;
  }

  @action
  createAndDisableMessageWidget() {
    this.createMessageWidget(false);
  }

  @action
  createCustomMessageWidget() {
    this.createMessageWidget();
  }
}
