export const EXTERNAL_RECRUITER = 5;

export function getAvailableRoles(intl) {
  return [
    { id: 'all', label: intl.t('search.role.all_roles') },
    { id: 'no_access', label: intl.t('user.no_access') },
    { id: 'user', label: intl.t('user.user') },
    { id: 'designer', label: intl.t('user.designer') },
    { id: 'hiring_manager', label: intl.t('user.hiring_manager') },
    { id: 'recruiter', label: intl.t('user.recruiter') },
    { id: 'recruitment_admin', label: intl.t('user.recruitment_admin') },
    { id: 'admin', label: intl.t('user.admin') },
    {
      id: 'external_recruiter',
      label: intl.t('user.external_recruiter'),
    },
  ];
}
