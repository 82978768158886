import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';

export default class extends Controller {
  @service router;

  get cookieSettings() {
    return this.model;
  }

  @action
  close() {
    this.router.transitionTo('settings.cookies');
  }

  @action
  async updateCookieSettings(cookieSettings) {
    await cookieSettings.save();
    this.router.transitionTo('settings.cookies');
  }

  @action
  updatePolicy(changeset, body) {
    set(changeset, 'customCookiePolicy', body);
  }

  @action
  updateUser(changeset, user) {
    set(changeset, 'cookieContactUser', user);
  }
}
