export const AVAILABLE_CATEGORIES = [
  'Jobs',
  'Candidates',
  'Posts',
  'Pages',
  'Images',
  'CannedResponses',
  'Questions',
  'InterviewKits',
];
export const DEFAULT_BACKGROUND_COLOR = 'zinc';

export const BACKGROUND_COLORS = [
  { color: 'zinc', name: 'default' },
  { color: 'cerise', name: 'cerise' },
  { color: 'fuchsia', name: 'fuchsia' },
  { color: 'blue', name: 'blue' },
  { color: 'indigo', name: 'indigo' },
  { color: 'lime', name: 'lime' },
  { color: 'orange', name: 'orange' },
  { color: 'purple', name: 'purple' },
  { color: 'yellow', name: 'yellow' },
  { color: 'cyan', name: 'cyan' },
];

export const TABLE_COLUMNS = [
  {
    label: 'settings.tag_manager.columns.name',
    sortable: true,
    selectable: true,
    sortColumn: 'name',
    defaultSortOrder: 'desc',
  },
  {
    label: 'settings.tag_manager.columns.category',
  },
  {
    label: 'settings.tag_manager.columns.times_used',
  },
];

export const TABLE_LOADING_COLUMNS = [
  { width: 'm' },
  { width: 'm' },
  { width: 's' },
];
