import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { FormQuestionModel } from 'teamtailor/models';

export default class FormAnswerModel extends Model {
  @belongsTo('form-question')
  declare formQuestion: AsyncBelongsTo<FormQuestionModel>;

  @attr('array') declare arrayValue?: string[] | number[] | boolean[] | string;
  @attr('date') declare dateValue: Date;
  @attr('number') declare candidateId: number;
  @attr('number') declare numericValue: number;
  @attr('string') declare formattedValue: string;
  @attr('string') declare textValue: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'form-answer': FormAnswerModel;
  }
}
