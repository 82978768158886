import Model, {
  type AsyncBelongsTo,
  attr,
  belongsTo,
  hasMany,
  SyncHasMany,
} from '@ember-data/model';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { delayOptions, DelayUnit } from 'teamtailor/constants/triggers';
import { get } from 'teamtailor/utils/get';
import { relationsRecordRemover } from 'teamtailor/utils/record-remover';
import StageModel from 'teamtailor/models/stage';
import TriggerSmartMoveModel from 'teamtailor/models/trigger/smart-move';
import PickedQuestionModel from './picked-question';
import PickedLocationModel from './picked-location';

export default class MoveModel extends Model {
  @service declare intl: IntlService;

  @belongsTo('stage') declare proceedStage: AsyncBelongsTo<StageModel>;
  @belongsTo('trigger/smart-move', { inverse: 'moves' })
  declare trigger: AsyncBelongsTo<TriggerSmartMoveModel>;

  @hasMany('picked-question', { async: false })
  declare pickedQuestions: SyncHasMany<PickedQuestionModel>;

  @hasMany('picked-location', { async: false })
  declare pickedLocations: SyncHasMany<PickedLocationModel>;

  @attr('boolean', { defaultValue: false }) declare ignoreInternal: boolean;
  @attr('boolean', { defaultValue: false }) declare ignoreSourced: boolean;
  @attr('number') declare rowOrder: number;
  @attr('number') declare rowOrderPosition: number;
  @attr('number', { defaultValue: 0 }) declare delayJobFor: number;
  @attr('string', { allowNull: true }) declare criteriaStatus: string | null;
  @attr('string', { allowNull: true }) declare criteriaText: string | null;
  @attr('string', { defaultValue: 'all' }) declare operator: string;
  @attr('string', { defaultValue: DelayUnit.Minutes })
  declare delayJobForUnit: DelayUnit;

  @attr('string', { defaultValue: 'question' }) declare criteria: string;

  get keywords() {
    return this.criteriaText ? this.criteriaText.split(',') : [];
  }

  get lastPickedQuestionRowOrder() {
    const lastObject = this.pickedQuestions
      .toArray()
      .sort((a, b) => {
        return a.rowOrder - b.rowOrder;
      })
      .pop();
    return lastObject?.rowOrder || 0;
  }

  get stageNameOrFallback() {
    return get(this.proceedStage, 'name') || this.intl.t('common.na');
  }

  get hasProceedStage() {
    return this.proceedStage.isTruthy;
  }

  get delayJobForSelectedOption() {
    return delayOptions.find((o) => {
      return (
        o.value === this.delayJobFor &&
        this.delayJobForUnit === DelayUnit.Minutes
      );
    });
  }

  get delayJobForString() {
    const option = this.delayJobForSelectedOption;

    if (option) {
      return this.intl.t(option.title);
    } else {
      return `${this.delayJobFor} ${this.delayJobForUnit}`;
    }
  }

  get applyJobForSelectedOption() {
    if (get(this, 'ignoreSourced') && get(this, 'ignoreInternal')) {
      return 'ignoreSourcedAndInternal';
    }

    if (get(this, 'ignoreSourced')) {
      return 'ignoreSourced';
    }

    if (get(this, 'ignoreInternal')) {
      return 'ignoreInternal';
    }

    return 'all';
  }

  get hasCriteriaJobOffer() {
    return this.criteria === 'job_offer';
  }

  get hasCriteriaQuestion() {
    return this.criteria === 'question';
  }

  get hasCriteriaCvContent() {
    return this.criteria === 'cv_content';
  }

  get hasCriteriaLocation() {
    return this.criteria === 'location';
  }

  get valid() {
    const hasProceedStage = get(this, 'hasProceedStage');
    if (this.hasCriteriaQuestion) {
      const nonDeletedQuestions = get(this, 'pickedQuestions').filter(
        (p) => !p.isDeleted
      );
      return nonDeletedQuestions.length && hasProceedStage;
    } else if (this.hasCriteriaJobOffer) {
      return !!this.criteriaStatus && hasProceedStage;
    } else if (this.hasCriteriaCvContent) {
      return !!this.criteriaText && hasProceedStage;
    } else if (this.hasCriteriaLocation) {
      const pickedLocations = get(this, 'pickedLocations').filter(
        (p) => !p.isDeleted
      );
      return pickedLocations.length && hasProceedStage;
    }
  }

  rollbackChangesIfNeeded() {
    if (!get(this, 'isDestroyed') && !get(this, 'isDestroying')) {
      if (!get(this, 'isSaving')) {
        relationsRecordRemover(this, 'pickedLocation', 'pickedQuestion');
      }
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    move: MoveModel;
  }
}
