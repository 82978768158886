import { inject as service } from '@ember/service';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';

export default class GeneralRoute extends ScrollToTopRoute {
  @service router;
  @service permissions;

  beforeModel() {
    super.beforeModel(...arguments);

    if (!this.permissions.has('settings/update_company')) {
      this.router.transitionTo('settings.teams');
    }
  }
}
