import Model, { attr, belongsTo } from '@ember-data/model';
import CompanyModel from 'teamtailor/models/company';

export default class SubscriptionContractModel extends Model {
  @attr('string') declare periodStart: string;
  @attr('string') declare periodEnd: string;
  @attr('string') declare autoRenews: string;
  @attr('string') declare reference: string;
  @attr('string') declare invoiceRecipient: string;

  @belongsTo('company', { async: false })
  declare company: CompanyModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'subscription-contract': SubscriptionContractModel;
  }
}
