import ExistsFilter from '../filters/exists';
import AnyFilter from '../filters/any';
import BaseProperty, { BasePropertyOptions } from './base-property';
import { AllModels, ModelKey } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import IntlService from 'ember-intl/services/intl';
import preloadMissingModels from 'teamtailor/utils/insights/preload-missing-models';

export interface ModelPropertyOptions extends BasePropertyOptions {
  modelType: string;
  model: ModelKey;
  displayProperty: string;
  groupAnalytics: boolean;
}

export default class ModelProperty extends BaseProperty {
  model: ModelKey;
  displayProperty: string;
  translatedModelName?: string;
  groupAnalytics: boolean;

  constructor(options: ModelPropertyOptions) {
    super({
      ...options,
      type: 'model',
      columnType: options.modelType,
      groupByAddsExistsFilter: true,
    });
    this.groupAnalytics = options.groupAnalytics;
    this.model = options.model;
    this.displayProperty = options.displayProperty;
    this.operators = [new ExistsFilter(), new AnyFilter()];
  }

  translate(intl: IntlService) {
    super.translate(intl);
    this.translatedModelName = intl.t(
      `insights.reports.builder.models.${this.columnType}`
    );
  }

  serializeValue(value: (AllModels | undefined)[]) {
    if (!value.length) return [];
    return value.map((v) => v?.id).filter((v) => v !== undefined);
  }

  async deserializeValue(value: (number | string)[]) {
    if (!value.length) return [];

    return this.store
      .query(this.model, {
        ids: value.filter((v) => !!v),
      })
      .then((records: any[]) => records.toArray());
  }

  format(value: any) {
    if (!value) return value;

    const record = this.store.peekRecord(this.model, value) as any;

    if (record) {
      return get(record, this.displayProperty);
    }

    return value;
  }

  async preload(ids: string[]) {
    const existingModels = await this.store.peekAll(this.model);
    const missingModels = await preloadMissingModels(
      this.model,
      ids,
      this.store,
      {
        groupAnalytics: this.groupAnalytics || undefined,
      }
    );

    return [...existingModels, ...missingModels];
  }
}
