import { TableColumn } from 'teamtailor/components/table/container';

export const TABLE_COLUMNS: TableColumn[] = [
  {
    label: 'components.payments.subscription_invoices.description',
    sortOrder: 1,
  },
  {
    label: 'components.payments.subscription_invoices.status',
    sortOrder: 2,
  },
  {
    label: 'components.payments.subscription_invoices.date',
    sortOrder: 3,
  },
  {
    label: 'components.payments.subscription_invoices.amount',
    sortOrder: 4,
    alignment: 'right',
  },
  {
    label: '',
    sortOrder: 5,
  },
];

export const TABLE_LOADING_COLUMNS = [
  { width: 'xl' },
  { width: 's', contentType: 'core/badge' },
  { width: 'm' },
  { width: 'm' },
];

export const DEFAULT_CURRENCY = 'USD';
