import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Select\n  @isFullWidth={{this.isFullWidth}}\n  @size={{@size}}\n  @optionName=\"nameOrCity\"\n  @options={{this.options}}\n  @searchEnabled={{true}}\n  @isDisabled={{@isDisabled}}\n  @onSelect={{@onSelect}}\n  @isMultiple={{@isMultiple}}\n  @selected={{@selected}}\n  @optionCompareField=\"id\"\n  @placeholder={{if\n    @isMultiple\n    (t \"components.location_picker.select_locations\")\n    (t \"components.location_picker.select_location\")\n  }}\n  @closeOnSelect={{@closeOnSelect}}\n  @appearance={{@appearance}}\n  @searchPlaceholder={{t \"components.location_picker.search_location\"}}\n  @text={{if\n    (and (not @isMultiple) (not @selected))\n    (t \"components.location_picker.select_location\")\n  }}\n  ...attributes\n/>", {"contents":"<Core::Select\n  @isFullWidth={{this.isFullWidth}}\n  @size={{@size}}\n  @optionName=\"nameOrCity\"\n  @options={{this.options}}\n  @searchEnabled={{true}}\n  @isDisabled={{@isDisabled}}\n  @onSelect={{@onSelect}}\n  @isMultiple={{@isMultiple}}\n  @selected={{@selected}}\n  @optionCompareField=\"id\"\n  @placeholder={{if\n    @isMultiple\n    (t \"components.location_picker.select_locations\")\n    (t \"components.location_picker.select_location\")\n  }}\n  @closeOnSelect={{@closeOnSelect}}\n  @appearance={{@appearance}}\n  @searchPlaceholder={{t \"components.location_picker.search_location\"}}\n  @text={{if\n    (and (not @isMultiple) (not @selected))\n    (t \"components.location_picker.select_location\")\n  }}\n  ...attributes\n/>","moduleName":"teamtailor/components/location-picker.hbs","parseOptions":{"srcName":"teamtailor/components/location-picker.hbs"}});
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import LocationModel from 'teamtailor/models/location';
import { GroupedLocation } from 'teamtailor/constants/locations';

type LocationPickerArgs = {
  isMultiple?: boolean;
  selected?: LocationModel[] | LocationModel;
  isFullWidth?: boolean;
  options?: GroupedLocation[];
};

export default class LocationPickerComponent extends Component<LocationPickerArgs> {
  @service declare current: Current;

  get options() {
    if (this.args.options) {
      return this.args.options;
    }

    return get(this.current.company, 'sortedLocationsByRegions');
  }

  get isFullWidth(): boolean {
    return this.args.isFullWidth !== undefined ? this.args.isFullWidth : true;
  }
}
