/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { computed, get } from '@ember/object';

export default Mixin.create({
  queryParams: ['isNewSection'],

  backPath: computed('basePath', 'isNewSection', 'sectionPath', function () {
    if (get(this, 'isNewSection')) {
      return get(this, 'basePath');
    }

    return get(this, 'sectionPath');
  }),
});
