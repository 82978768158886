import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex h-full flex-1 flex-col\">\n  {{#if this.isLoading}}\n    <LoadingSpinner />\n  {{else}}\n    <div\n      class=\"flex-grow overflow-auto\"\n      {{this.registerScrollbar this.sortedActivities}}\n    >\n      <Jobs::Collaborate::List\n        @models={{this.sortedActivities}}\n        @job={{@job}}\n        @destroyActivity={{this.destroyActivity}}\n        @hideOnlineBeacon={{true}}\n        @unreadMessages={{@unreadMessages}}\n      />\n    </div>\n\n    {{#if @job}}\n      <footer class=\"flex-shrink-0 px-12 pb-12\">\n        <Note::Form\n          @saveDisabled={{this.isLoading}}\n          @finishedSaving={{this.addActivity}}\n          @job={{@job}}\n          @selectedJob={{@job}}\n          @placeholder={{t \"jobs.job.collaborate.write_to_hiring_team\"}}\n        />\n      </footer>\n    {{/if}}\n  {{/if}}\n</div>", {"contents":"<div class=\"flex h-full flex-1 flex-col\">\n  {{#if this.isLoading}}\n    <LoadingSpinner />\n  {{else}}\n    <div\n      class=\"flex-grow overflow-auto\"\n      {{this.registerScrollbar this.sortedActivities}}\n    >\n      <Jobs::Collaborate::List\n        @models={{this.sortedActivities}}\n        @job={{@job}}\n        @destroyActivity={{this.destroyActivity}}\n        @hideOnlineBeacon={{true}}\n        @unreadMessages={{@unreadMessages}}\n      />\n    </div>\n\n    {{#if @job}}\n      <footer class=\"flex-shrink-0 px-12 pb-12\">\n        <Note::Form\n          @saveDisabled={{this.isLoading}}\n          @finishedSaving={{this.addActivity}}\n          @job={{@job}}\n          @selectedJob={{@job}}\n          @placeholder={{t \"jobs.job.collaborate.write_to_hiring_team\"}}\n        />\n      </footer>\n    {{/if}}\n  {{/if}}\n</div>","moduleName":"teamtailor/components/toolbox/global-comments/jobs/collaborate/index.hbs","parseOptions":{"srcName":"teamtailor/components/toolbox/global-comments/jobs/collaborate/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import ActivityModel from 'teamtailor/models/activity';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';
import { registerDestructor } from '@ember/destroyable';
import { trackedFunction } from 'ember-resources/util/function';
import { JobModel } from 'teamtailor/models';
import { action } from '@ember/object';
import ScrollPosition from 'teamtailor/utils/scroll-position';
import { modifier } from 'ember-modifier';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';

interface GlobalCommentsJobsCollaborateArgs {
  job: JobModel;
  addActivity: (activity: ActivityModel) => void;
  isLoading?: boolean;
}

const NEW_JOB_NOTE_EVENT = 'new-job-note';
const NEW_REACTION_EVENT = 'new-reaction';
const DESTROYED_REACTION_EVENT = 'destroyed-reaction';

export default class GlobalCommentsJobsCollaborate extends Component<GlobalCommentsJobsCollaborateArgs> {
  @service declare store: Store;
  @service declare pusher: PusherService;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  @tracked activities: ActivityModel[] = [];

  declare pusherChannel: PusherChannel | null;
  declare scrollPosition: ScrollPosition;

  constructor(owner: unknown, args: GlobalCommentsJobsCollaborateArgs) {
    super(owner, args);

    this.setupPusher();

    registerDestructor(this, () => {
      this.teardownPusher();
    });
  }

  resolveActivityResponses = trackedFunction(this, async () => {
    /*
      `store.query` returns a PromiseArray which doesn't quite work like a real one,
      so we need to convert it
    */
    const activities: ActivityModel[] = await this.store.query('activity', {
      job_id: this.args.job.id,
      collaborate: true,
    });
    this.activities = activities.slice();
    return this.activities;
  });

  get isLoading() {
    return this.args.isLoading || this.resolveActivityResponses.value === null;
  }

  pusherChannelName() {
    return `presence-job-${this.args.job.id}`;
  }

  async setupPusher() {
    if (this.pusherChannel) {
      return;
    }

    this.pusher.subscribe(this.pusherChannelName()).then((channel) => {
      this.pusherChannel = channel;

      this.pusherChannel.bind(NEW_JOB_NOTE_EVENT, async (data) => {
        const record = await this.store.findRecord(
          'activity',
          data.activity_id
        );
        this.addActivity(record);
      });

      this.pusherChannel.bind(NEW_REACTION_EVENT, (reaction) => {
        this.store.pushPayload(reaction);
      });

      this.pusherChannel.bind(DESTROYED_REACTION_EVENT, (id) => {
        const destroyedReaction = this.store.peekRecord('reaction', id);
        if (destroyedReaction) {
          this.store.unloadRecord(destroyedReaction);
        }
      });
    });
  }

  teardownPusher() {
    if (!this.pusherChannel) {
      return;
    }

    this.pusherChannel.unsubscribe();
    this.pusherChannel = null;
  }

  get sortedActivities(): ActivityModel[] {
    const allActivitiesForJob = [...this.activities].sort(
      (a: ActivityModel, b: ActivityModel) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
    return allActivitiesForJob;
  }

  scrollToBottom() {
    this.scrollPosition.scrollToBottom();
  }

  @action
  addActivity(activity: ActivityModel) {
    if (!this.activities.includes(activity)) {
      this.activities = [...this.activities, activity];
    }

    this.scrollToBottom();
  }

  @action
  async destroyActivity(activityToRemove: ActivityModel) {
    activityToRemove.deleteRecord();
    try {
      await activityToRemove.destroyRecord();
      this.activities = this.activities.filter(
        (activity) => activity !== activityToRemove
      );
    } catch (e) {
      activityToRemove.rollbackAttributes();
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
    }
  }

  registerScrollbar = modifier((element: HTMLElement, [_sortedActivities]) => {
    this.scrollPosition = new ScrollPosition(element);
    this.scrollToBottom();
  });
}
