import Service, { inject as service } from '@ember/service';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, get, set } from '@ember/object';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { match } from '@ember/object/computed';
import { getOwner } from '@ember/application';

const VISITS_TO_DISPLAY = 3;
const STORAGE_KEY = 'recent-visits-cache';

export default class RecentVisitsService extends Service {
  @service store;
  @service router;
  @service user;

  @match('router.currentRouteName', /^jobs.job/) isJobRoute;

  _visits = [];

  @computed('user.id')
  get storageKey() {
    return `${STORAGE_KEY}-${get(this.user, 'id')}`;
  }

  @computed('_visits.[]', 'router.currentRouteName')
  get visits() {
    const currentVisit = this.getCurrentlyViewedVisit();
    const visits = this._visits.filter((visit) => {
      return visit !== currentVisit;
    });
    return visits.slice(0, VISITS_TO_DISPLAY);
  }

  constructor() {
    super(...arguments);

    this.copyLegacyLocalStorage();

    set(this, '_visits', this.getCachedVisits());
  }

  copyLegacyLocalStorage() {
    if (localStorage.getItem(this.storageKey)) {
      sessionStorage.setItem(
        this.storageKey,
        localStorage.getItem(this.storageKey)
      );
      localStorage.removeItem(this.storageKey);
    }
  }

  log(model) {
    let searchResult = this.modelToSearchResult(model);

    this.removePrevious(searchResult);
    this._visits.unshift(searchResult);
    this.unloadOldVisits();
    this.setCachedVisits();
  }

  unloadOldVisits() {
    this._visits.slice(VISITS_TO_DISPLAY + 1).forEach((visit) => {
      this._visits.removeObject(visit);
      visit.unloadRecord();
    });
  }

  removePrevious(searchResult) {
    let previous = this._visits.find(
      (i) =>
        i.type === searchResult.type &&
        parseInt(i.resultId, 10) === parseInt(searchResult.resultId, 10)
    );

    if (previous) {
      this._visits.removeObject(previous);
      previous.unloadRecord();
    }
  }

  modelToSearchResult(model) {
    const { modelName } = model.constructor;
    let attributes = {
      type: modelName,
      resultId: model.id,
    };

    if (modelName === 'candidate') {
      attributes.displayName = model.nameOrEmail;
      attributes.candidateAvatarId = model.picture;
      attributes.candidateInitials = model.initials;
      attributes.candidateColor = model.color;
      attributes.departmentName = get(model, 'department.name');
    }

    if (modelName === 'job') {
      attributes.displayName = model.title;
      attributes.status = model.humanStatus;
      attributes.careersiteUrl = model.url;
    }

    if (modelName === 'page') {
      attributes.type = 'page_publication';
      attributes.displayName = model.displayTitle;
      attributes.status = get(model, 'pagePublication.humanStatus');
      attributes.draftPageId = model.id;
      attributes.pageType = model.pageCategory;
      attributes.careersiteUrl = model.pageUrl;
    }

    return this.store.createRecord('search-result', attributes);
  }

  getCachedVisits() {
    const visits = JSON.parse(sessionStorage.getItem(this.storageKey)) || [];
    return visits.map((visitData) => {
      const {
        data: { attributes },
      } = this.store.normalize('search-result', visitData);
      return this.store.createRecord('search-result', attributes);
    });
  }

  setCachedVisits() {
    const visits = this._visits.map((model) => model.serialize());
    sessionStorage.setItem(this.storageKey, JSON.stringify(visits));
  }

  getCurrentlyViewedVisit() {
    const owner = getOwner(this);
    const model = this.getCurrentControllerModel(owner);

    if (model) {
      const { modelName } = model.constructor;
      switch (modelName) {
        case 'job':
        case 'candidate':
          return this.getCurrentModelMatch(modelName, model.id);
        case 'page':
          return this.getCurrentModelMatch('page_publication', model.id);
      }
    }

    if (this.isJobRoute) {
      const { id } = owner.lookup('controller:jobs.job').model;
      return this.getCurrentModelMatch('job', id);
    }

    return undefined;
  }

  getCurrentModelMatch(type, id) {
    return this._visits.find(
      (visit) => visit.resultId === id && visit.type === type
    );
  }

  getCurrentControllerModel(owner) {
    let name = this.router.currentRouteName;
    const route = owner.lookup(`route:${name}`);
    if (route && route.controllerName) {
      name = route.controllerName;
    }

    return owner.lookup(`controller:${name}`).model;
  }
}
