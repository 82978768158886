import Model, {
  hasMany,
  belongsTo,
  attr,
  type AsyncHasMany,
  type AsyncBelongsTo,
} from '@ember-data/model';

import { inject as service } from '@ember/service';
import RequisitionFlowStepModel from './requisition-flow-step';
import {
  CompanyModel,
  DepartmentModel,
  LocationModel,
  RoleModel,
} from 'teamtailor/models';
import IntlService from 'ember-intl/services/intl';
import computeFullErrorMessages from 'teamtailor/utils/full-error-messages';
import { type CriteriaGroup } from './requisition-flow';

export default class JobOfferApprovalFlowModel extends Model {
  @service declare intl: IntlService;

  @attr('string') declare title: string;
  @attr('boolean') declare isDefault: boolean;
  @attr('string') declare country: string;
  @attr('number') declare maxSalary: number;
  @attr('string') declare currency: string;
  @attr('string') declare salaryTimeUnit: string;
  @attr('raw') declare criteria: CriteriaGroup | null;

  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('location') declare location: AsyncBelongsTo<LocationModel>;
  @belongsTo('department') declare department: AsyncBelongsTo<DepartmentModel>;
  @belongsTo('role') declare role: AsyncBelongsTo<RoleModel>;

  // It's weird but, just roll with it
  @hasMany('requisition-flow-step', { inverse: 'jobOfferApprovalFlow' })
  declare steps: AsyncHasMany<RequisitionFlowStepModel>;

  get fullErrorMessages() {
    return computeFullErrorMessages(this, this.intl);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'job-offer-approval-flow': JobOfferApprovalFlowModel;
  }
}
