import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';

export default class JobOfferApprovalFlowsRoute extends ScrollToTopRoute {
  @service declare store: Store;

  model() {
    return this.store.findAll('job-offer-approval-flow');
  }
}
