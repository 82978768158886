import { decamelize } from '@ember/string';
import { A as array } from '@ember/array';
import { get } from 'teamtailor/utils/get';

export default function copyModel({
  model,
  deepClone = true,
  excludedRelationships = [],
}) {
  const class_type = model.constructor;
  const attrs = {};
  const root = decamelize(class_type.toString().split(':')[1]);
  class_type.eachAttribute((key) => {
    attrs[key] = model[key];
  });

  if (deepClone) {
    model.eachRelationship(function (key, relationship) {
      if (!excludedRelationships.includes(key)) {
        if (relationship.kind === 'belongsTo') {
          attrs[key] = get(model, key);
        } else if (relationship.kind === 'hasMany') {
          attrs[key] = array([]);
          get(model, key).forEach(function (obj) {
            attrs[key].addObject(copyModel({ model: obj, deepClone: true }));
          });
        }
      }
    });
  }

  return model.store.createRecord(root, attrs);
}
