import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { FocusTargetValue, FocusTrap } from 'focus-trap';
import { TrackedMap } from 'tracked-built-ins';

export default class FocusTrapStackService extends Service {
  @tracked stack: FocusTrap[] = [];
  focusTrapElements = new TrackedMap<FocusTrap, FocusTargetValue>();

  register(trap: FocusTrap, trapElement: FocusTargetValue) {
    // eslint-disable-next-line ember/use-ember-get-and-set
    this.focusTrapElements.set(trap, trapElement);
  }

  unregister(trap: FocusTrap) {
    this.focusTrapElements.delete(trap);
  }
}

declare module '@ember/service' {
  interface Registry {
    'focus-trap-stack': FocusTrapStackService;
  }
}
