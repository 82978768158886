import { FilterOption } from 'teamtailor/components/fancy-filters';
import CustomFieldModel from 'teamtailor/models/custom-field';
import BaseFilter from './base';

export default class CustomFieldFilter
  extends BaseFilter
  implements FilterOption
{
  customField: CustomFieldModel;

  constructor(customField: CustomFieldModel) {
    super();

    this.customField = customField;
    this.doNotTranslateName = true;
    this.translatedName = customField.name;

    if (this.customField.type === 'CustomField::Number') {
      this.customField
        .getRangeValues()
        .then((response) => (this.options = response.range));
    }
  }
}
