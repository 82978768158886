import { API } from '@editorjs/editorjs';
import { Config, SelectionState } from 'teamtailor/components/wysiwyg-editor';
import { createElement } from '../utils';

interface AiInlineToolConfig extends Config {
  registerRange(range: Range): void;
  setSelectionState(selectionState: SelectionState | null): void;
}

export default class AiInlineTool {
  static isInline = true;
  static title = 'Co-pilot';

  api: API;
  config: AiInlineToolConfig;
  mark?: HTMLElement;

  declare wrapper?: HTMLElement;

  _state = false;
  get state() {
    return this._state;
  }

  set state(state) {
    this._state = state;
  }

  constructor({ api, config }: { api: API; config: AiInlineToolConfig }) {
    this.api = api;
    this.config = config;
  }

  render() {
    if (!this.wrapper) {
      const wrapper = createElement('div');
      this.wrapper = wrapper;
      Object.assign(this.wrapper.style, { minWidth: '42px' }); // to make inline toolbar align properly :/
      this.config.initializeEmberBlock('ai-inline', {
        wrapper,
      });
    }

    return this.wrapper;
  }

  async surround(range: Range) {
    let mark!: HTMLElement;

    const blockIndex = this.api.blocks.getCurrentBlockIndex();
    const blockApiBlock = this.api.blocks.getBlockByIndex(blockIndex)!;

    const block = await this.api.blocks.getById(blockApiBlock.id)?.save();
    const currentBlockText = block?.data.text;

    if (this.state) {
      this.unwrap(range);
    } else {
      mark = this.wrap(range);
    }

    this.mark = mark;

    this.config.setSelectionState({
      currentBlockText,
      range,
      blockApiBlock,
      mark,
    });
  }

  tag = 'MARK';

  wrap(range: Range) {
    const selectedText = range.extractContents();
    const mark = document.createElement(this.tag);

    mark.classList.add('py-4', 'bg-brand-primary', 'text-white');
    mark.appendChild(selectedText);
    range.insertNode(mark);

    this.api.selection.expandToTag(mark);
    return mark;
  }

  unwrap(range: Range) {
    const mark = this.api.selection.findParentTag(this.tag);
    const text = range.extractContents();

    mark?.remove();

    range.insertNode(text);
  }

  checkState() {
    const mark = this.api.selection.findParentTag(this.tag);
    this.state = !!mark;
  }

  clear() {
    if (this.mark) {
      this.mark.replaceWith(...this.mark.childNodes);
    }

    this.config.setSelectionState(null);
  }
}
