import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { set, action } from '@ember/object';
import { runInDebug } from '@ember/debug';
import { UnauthorizedError } from 'teamtailor/adapters/application';
import ENV from 'teamtailor/config/environment';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';

polyfillCountryFlagEmojis();

export default class ApplicationRoute extends Route {
  @service appRefresher;
  @service flashMessages;
  @service router;
  @service intl;
  @service session;
  @service translations;

  constructor() {
    super(...arguments);

    if (navigator?.serviceWorker && ENV.CLEAR_SERVICE_WORKER) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }

    if (ENV.environment === 'development') {
      window.addEventListener('load', () => {
        LiveReload.addPlugin(
          class IgnoreServiceWorkerPlugin {
            static identifier = 'stop-service-worker-causing-full-reload';
            static version = '1.0';
            reload = (path) => {
              return ['sw.js', 'index.html'].includes(path);
            };
          }
        );
      });
    }
  }

  async beforeModel() {
    await this.translations.setLocale('en');
    await this.session.setup();
  }

  @action
  error(error) {
    runInDebug(() => {
      console.error(error); // eslint-disable-line no-console
    });
    set(this, 'appRefresher.forceRefresh', true);

    if (!(error instanceof UnauthorizedError)) {
      const message = window.navigator.onLine
        ? this.intl.t('errors.something_went_wrong')
        : this.intl.t('errors.page_currently_unavailable'); // Possible improvement: Check that its actually a offline cache miss

      this.flashMessages.error(message, {
        sticky: true,
      });
    }
  }
}
