// This is a copy of https://github.com/jmurphyau/ember-truth-helpers/blob/master/addon/helpers/or.js
// but only treats undefined and null as falsy.

import { helper } from '@ember/component/helper';
import { isNone } from '@ember/utils';

export function orNone(params) {
  for (let i = 0, len = params.length; i < len; i++) {
    if (!isNone(params[i]) === true) {
      return params[i];
    }
  }

  return params[params.length - 1];
}

export default helper(orNone);
