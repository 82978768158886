import { action } from '@ember/object';
import Controller from '@ember/controller';
import FlashMessageService from 'teamtailor/services/flash-message';
import { get } from 'teamtailor/utils/get';
import HireQualityResponseModel from 'teamtailor/models/hire-quality-response';
import IntlService from 'ember-intl/services/intl';
import RouteHelper from 'teamtailor/services/route-helper';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class HireQualitySurveyEditController extends Controller {
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare routeHelper: RouteHelper;
  @service declare router: RouterService;

  @tracked isCancelModalOpen = false;

  get hireQualityResponse(): HireQualityResponseModel {
    return this.model;
  }

  @action
  async cancelSurvey() {
    const candidateId = get(this.hireQualityResponse.candidate, 'id');
    await this.hireQualityResponse.destroyRecord();
    this.flashMessages.success(
      this.intl.t('candidates.hire_quality_survey.survey_cancelled')
    );
    this.onClose(candidateId);
  }

  @action
  onClose(candidateId: string | null = null) {
    if (get(this.hireQualityResponse, 'hasDirtyAttributes')) {
      this.hireQualityResponse.rollbackAttributes();
    }

    if (this.routeHelper.parentRoute !== undefined) {
      return this.router.transitionTo(
        this.routeHelper.parentRoute,
        get(this.hireQualityResponse.candidate, 'id') || candidateId!
      );
    }

    return window.history.back();
  }
}

declare module '@ember/controller' {
  interface Registry {
    'candidates.segment.candidate.hire-quality.edit': HireQualitySurveyEditController;
  }
}
