import { get, computed } from '@ember/object';
import round from './round';

export function compareCalculation(currentValue, oldValue) {
  if (currentValue !== undefined && oldValue !== undefined) {
    let diff = currentValue - oldValue;
    if (oldValue === 0) {
      return undefined;
    } else {
      return round((diff / oldValue) * 100, true);
    }
  }

  return undefined;
}

export default function comparePercentage(currentKey, oldKey) {
  return computed(currentKey, oldKey, function () {
    let currentValue = get(this, currentKey);
    let oldValue = get(this, oldKey);
    return compareCalculation(currentValue, oldValue);
  });
}
