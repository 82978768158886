export function extractYoutubeId(url: string): string | null {
  /* eslint-disable no-useless-escape */
  const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
  /* eslint-enable no-useless-escape */
  const match = url.match(regExp);
  return match?.[1]?.length === 11 ? match[1] : null;
}

export function extractVimeoId(url: string): string | null {
  /* eslint-disable no-useless-escape */
  const regExp =
    /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
  /* eslint-enable no-useless-escape */
  const match = url.match(regExp);
  return match?.[1] || null;
}

export function extractVideoInfo(url: string): {
  provider: string | null;
  id: string | null;
} {
  const youtubeId = extractYoutubeId(url);
  const id = youtubeId || extractVimeoId(url);
  const provider = youtubeId ? 'youtube' : id ? 'vimeo' : null;

  return {
    provider,
    id,
  };
}
