import Store from '@ember-data/store';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import AutoJoinDomainModel from 'teamtailor/models/auto-join-domain';
import SettingsAutoJoinRoute from 'teamtailor/routes/settings/auto-join';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import { get } from 'teamtailor/utils/get';
import { ModelFrom } from 'teamtailor/utils/type-utils';

export default class SettingsAutoJoinController extends Controller {
  declare model: ModelFrom<SettingsAutoJoinRoute>;

  @service declare current: Current;
  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  get autoJoinDomains() {
    return this.model.rejectBy('isDeleted');
  }

  get autoJoinErrors() {
    return get(this.current.company, 'errors').filterBy(
      'attribute',
      'auto_join_domains.domain'
    );
  }

  @action
  async handleSave() {
    try {
      await this.current.company.save();
      get(this.current.company, 'autoJoinDomains')
        .filterBy('isNew')
        .invoke('unloadRecord');

      this.flashMessages.success('Auto join domains saved');
    } catch {
      this.flashMessages.error('Failed to save auto join domains');
    }
  }

  @action
  handleRemoveAutoJoinDomain(autoJoinDomain: AutoJoinDomainModel) {
    autoJoinDomain.deleteRecord();
  }

  @action
  handleCreateAutoJoinDomain() {
    this.store.createRecord('auto-join-domain', {
      company: this.current.company,
    });
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.auto-join': SettingsAutoJoinController;
  }
}
