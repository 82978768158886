import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"flex h-56 w-full items-center justify-center gap-12 bg-warning px-12\"\n>\n  <div class=\"text-center\">\n    {{t\n      \"settings.security.banner.warning\"\n      date=(moment-format this.enforcementDate \"MMMM Do\")\n      htmlSafe=true\n    }}\n\n    <LinkTo\n      @route=\"employees.profile.security.index\"\n      @model={{this.current.user}}\n    >\n      {{t \"settings.security.banner.link_text\"}}\n    </LinkTo>\n  </div>\n</div>", {"contents":"<div\n  class=\"flex h-56 w-full items-center justify-center gap-12 bg-warning px-12\"\n>\n  <div class=\"text-center\">\n    {{t\n      \"settings.security.banner.warning\"\n      date=(moment-format this.enforcementDate \"MMMM Do\")\n      htmlSafe=true\n    }}\n\n    <LinkTo\n      @route=\"employees.profile.security.index\"\n      @model={{this.current.user}}\n    >\n      {{t \"settings.security.banner.link_text\"}}\n    </LinkTo>\n  </div>\n</div>","moduleName":"teamtailor/components/two-factor-auth-enforce-banner.hbs","parseOptions":{"srcName":"teamtailor/components/two-factor-auth-enforce-banner.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';

export default class TwoFactorAuthEnforceBanner extends Component {
  @service declare current: Current;

  get enforcementDate() {
    return this.current.company.twoFactorAuthenticationEnforcedAt;
  }
}
