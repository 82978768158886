import Service, { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class ReportsService extends Service {
  @service store;

  get adapter() {
    return this.store.adapterFor('application');
  }

  url(name, data) {
    let url = get(this, 'adapter').buildURL('reports');
    return `${url}/${name}?${new URLSearchParams(data)}`;
  }
}
