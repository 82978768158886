import BaseJobOfferRoute from './base-route';
import { inject as service } from '@ember/service';

export default class JobOfferShowRoute extends BaseJobOfferRoute {
  @service router;
  @service store;
  @service flashMessages;
  @service intl;

  model(params) {
    return this.store.findRecord('jobOffer', params.job_offer_id);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      let model = controller.jobOffer;
      if (model.isDeleted) {
        model.save().then(() => {
          this.flashMessages.success(
            this.intl.t('candidates.job_offer.offer_deleted')
          );
        });
      }
    }
  }
}
