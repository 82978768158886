import JobOfferShowRoute from 'teamtailor/routes/jobs/job/stages/index/candidate/job-offer/show';
import JobOfferShowController from 'teamtailor/controllers/jobs/job/stages/index/candidate/job-offer/show';
import JobOfferModel from 'teamtailor/models/job-offer';
import { Transition } from 'teamtailor/utils/type-utils';

export default class JobOfferShow extends JobOfferShowRoute {
  controllerName = 'jobs.job.stages.index.candidate.job-offer.show';
  templateName = 'jobs.job.stages.index.candidate.job-offer.show';

  setupController(
    controller: JobOfferShowController,
    model: JobOfferModel | null,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);
    controller.limitedView = true;
  }
}
