import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"mx-12 mt-8 flex h-32 flex-row items-center\">\n  <Icon @icon=\"sparkles\" class=\"mr-4 text-icon-copilot-weak\" />\n  <h2 class=\"body-text-s mr-8 truncate font-semibold text-copilot-weak\">\n    {{t \"job.stage_types.suggested\"}}\n  </h2>\n  <Core::Badge\n    @color=\"purple\"\n    @text={{@job.jobDetail.visibleCandidateSuggestions.length}}\n    @size=\"tiny\"\n    @colorVariant=\"strong\"\n  />\n  <div class=\"ml-auto flex items-center gap-8\">\n    {{#if this.anonymous}}\n      <div class=\"text-icon-copilot-weak\">\n        <Stage::IndicatorItem\n          @icon=\"mask\"\n          @iconStyle=\"solid\"\n          @tooltip={{t \"components.stage_list.anonymise\"}}\n        />\n      </div>\n    {{/if}}\n  </div>\n</div>", {"contents":"<div class=\"mx-12 mt-8 flex h-32 flex-row items-center\">\n  <Icon @icon=\"sparkles\" class=\"mr-4 text-icon-copilot-weak\" />\n  <h2 class=\"body-text-s mr-8 truncate font-semibold text-copilot-weak\">\n    {{t \"job.stage_types.suggested\"}}\n  </h2>\n  <Core::Badge\n    @color=\"purple\"\n    @text={{@job.jobDetail.visibleCandidateSuggestions.length}}\n    @size=\"tiny\"\n    @colorVariant=\"strong\"\n  />\n  <div class=\"ml-auto flex items-center gap-8\">\n    {{#if this.anonymous}}\n      <div class=\"text-icon-copilot-weak\">\n        <Stage::IndicatorItem\n          @icon=\"mask\"\n          @iconStyle=\"solid\"\n          @tooltip={{t \"components.stage_list.anonymise\"}}\n        />\n      </div>\n    {{/if}}\n  </div>\n</div>","moduleName":"teamtailor/components/stage/suggested/header.hbs","parseOptions":{"srcName":"teamtailor/components/stage/suggested/header.hbs"}});
import Component from '@glimmer/component';

import JobModel from 'teamtailor/models/job';

interface Args {
  job: JobModel;
}

export default class extends Component<Args> {
  get anonymous() {
    return this.args.job.stages.find(
      (stage) => stage.name === 'Inbox' && stage.anonymous
    );
  }
}
