import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.shouldShow}}\n  <div class=\"flex gap-2 border-b border-neutral last:mb-0 last:border-b-0\">\n    <dt class=\"min-w-[160px] max-w-[390px] p-12\">\n      {{@customField.label}}\n      {{#if @customField.description}}\n        <span class=\"align-middle\">\n          <InfoTooltip @text={{@customField.description}} />\n        </span>\n      {{/if}}\n    </dt>\n    <dd\n      class={{class-names\n        \"flex min-w-0 flex-wrap content-start gap-4 border-l border-neutral p-12\"\n      }}\n    >\n      <Requisition::CustomFormDataValue\n        @customField={{@customField}}\n        @customFields={{@customFields}}\n        @customFieldAnswers={{@customFieldAnswers}}\n      />\n    </dd>\n  </div>\n{{/if}}", {"contents":"{{#if this.shouldShow}}\n  <div class=\"flex gap-2 border-b border-neutral last:mb-0 last:border-b-0\">\n    <dt class=\"min-w-[160px] max-w-[390px] p-12\">\n      {{@customField.label}}\n      {{#if @customField.description}}\n        <span class=\"align-middle\">\n          <InfoTooltip @text={{@customField.description}} />\n        </span>\n      {{/if}}\n    </dt>\n    <dd\n      class={{class-names\n        \"flex min-w-0 flex-wrap content-start gap-4 border-l border-neutral p-12\"\n      }}\n    >\n      <Requisition::CustomFormDataValue\n        @customField={{@customField}}\n        @customFields={{@customFields}}\n        @customFieldAnswers={{@customFieldAnswers}}\n      />\n    </dd>\n  </div>\n{{/if}}","moduleName":"teamtailor/components/requisition/custom-form-data-row.hbs","parseOptions":{"srcName":"teamtailor/components/requisition/custom-form-data-row.hbs"}});
import Component from '@glimmer/component';

export default class CustomFormDataRow extends Component {
  get shouldShow() {
    const field = this.args.customField;
    if (field.show_if) {
      return this.extractAnswerValues.includes(field.show_if.value);
    } else {
      return true;
    }
  }

  get extractAnswerValues() {
    const answers = this.args.customFieldAnswers;

    return Object.values(answers).flatMap((value) => {
      if (Array.isArray(value)) {
        return value.map((item) => {
          if (item && typeof item === 'object' && 'id' in item) {
            return item.id;
          }

          return item;
        });
      }

      return [value];
    });
  }
}
