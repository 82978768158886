import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Current from 'teamtailor/services/current';
import { query } from 'ember-data-resources';
import { TableColumn } from 'teamtailor/components/table/container';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { RegionModel } from 'teamtailor/models';
import { restartableTask, timeout } from 'ember-concurrency';

export default class SettingsLocationsIndexController extends Controller {
  @service declare current: Current;

  @tracked name_or_city = '';
  @tracked page = 1;
  @tracked declare showFilterSidebar: boolean;
  @tracked region_id = '';
  @tracked sort_column = 'nameOrCity';
  @tracked sort_direction = 'asc';

  queryParams = ['name_or_city', 'region_id', 'sort_column', 'sort_direction'];

  get hasRegions() {
    return get(get(this.current.company, 'regions'), 'length') > 0;
  }

  get hasCareerSites() {
    return get(get(this.current.company, 'careerSites'), 'length') > 1;
  }

  get locations() {
    return this.locationsQuery.records;
  }

  get tableColumns() {
    const columns: TableColumn[] = [
      {
        label: 'settings.locations.name_or_city',
        sortable: true,
        sortColumn: 'nameOrCity',
        defaultSortOrder: 'asc',
        sortOrder: 1,
      },
      {
        label: 'common.address',
        sortable: false,
        sortOrder: 2,
      },
    ];

    if (this.hasRegions) {
      columns.push({
        label: 'common.region',
        sortable: true,
        sortColumn: 'region',
        sortOrder: 3,
      });
    }

    if (this.hasCareerSites) {
      columns.push({
        label: 'common.languages',
        sortable: false,
        sortOrder: 4,
      });
    }

    return columns;
  }

  locationsQuery = query(this, 'location', () => {
    return {
      per_page: 25,
      page: this.page || 1,
      name_or_city: this.name_or_city,
      region_id: this.region_id,
      sort_column: this.sort_column,
      sort_direction: this.sort_direction,
    };
  });

  handleSearchInput = restartableTask(async (value) => {
    await timeout(1000);
    this.name_or_city = value;
    this.page = 1;
  });

  @action
  selectRegion(selected?: RegionModel) {
    this.region_id = selected ? get(selected, 'id') : '';
    this.page = 1;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.locations.index': SettingsLocationsIndexController;
  }
}
