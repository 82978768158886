import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class={{class-names\n    \"h-6 w-full rounded-6\"\n    (or @backgroundClass \"bg-zinc-700\")\n  }}\n>\n  <div\n    class={{class-names\n      \"h-full rounded-8 bg-gradient-to-r from-pink-500 to-rose-500 transition-all duration-300 ease-in-out\"\n      @progressClass\n    }}\n    style={{this.progressStyle}}\n  ></div>\n</div>", {"contents":"<div\n  class={{class-names\n    \"h-6 w-full rounded-6\"\n    (or @backgroundClass \"bg-zinc-700\")\n  }}\n>\n  <div\n    class={{class-names\n      \"h-full rounded-8 bg-gradient-to-r from-pink-500 to-rose-500 transition-all duration-300 ease-in-out\"\n      @progressClass\n    }}\n    style={{this.progressStyle}}\n  ></div>\n</div>","moduleName":"teamtailor/components/progress-bar.hbs","parseOptions":{"srcName":"teamtailor/components/progress-bar.hbs"}});
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';

interface ProgressBarArgs {
  percentage: number;
  progressClass?: string;
}

export default class ProgressBar extends Component<ProgressBarArgs> {
  get progressStyle() {
    return htmlSafe(`width: ${this.args.percentage}%`);
  }
}
