import Route from '@ember/routing/route';
import FeatureModel, {
  FeatureCategoryType,
  FeatureStatus,
} from 'teamtailor/models/feature';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';

interface QueryParams {
  category: FeatureCategoryType;
}

export type ModelResponse = {
  features: FeatureModel[];
  category: FeatureCategoryType;
};

export default class FeaturesShowRoute extends Route {
  @service declare router: RouterService;
  @service declare store: Store;

  @tracked declare returnCategory: FeatureCategoryType;

  model(params: QueryParams): ModelResponse {
    const { category } = params;

    const response = this.store.peekAll('feature');

    return {
      features: this.sortedFeatures(
        this.filterByCategory(response.toArray(), category)
      ),

      category,
    };
  }

  sortedFeatures(features: FeatureModel[]): FeatureModel[] {
    const newFeatures = features.filterBy('status', FeatureStatus.RECENT);

    const upcoming = features.filterBy('status', FeatureStatus.UPCOMING);

    const rest = features
      .rejectBy('status', FeatureStatus.RECENT)
      .rejectBy('status', FeatureStatus.UPCOMING)
      .sortBy('rowOrder');

    return [...newFeatures, ...upcoming, ...rest];
  }

  filterByCategory(
    features: FeatureModel[],
    category: FeatureCategoryType
  ): FeatureModel[] {
    switch (category) {
      case 'available':
        return features
          .filterBy('isEnabledByCompany', false)
          .rejectBy('status', FeatureStatus.UPCOMING);
      case 'enabled':
        return features.filterBy('isEnabledByCompany', true);
      case 'new':
        return features.filterBy('status', FeatureStatus.RECENT);
      case 'upcoming':
        return features.filterBy('status', FeatureStatus.UPCOMING);
      case 'recruit':
      case 'relationships':
      case 'security':
      case 'enterprise':
        return features.filterBy('category', category);
      default:
        return features;
    }
  }
}
