import { helper } from '@ember/component/helper';
import { percentageOf as percentage } from 'teamtailor/utils/analytics';

export function percentageOf(args) {
  const [value, total, decimalPoints] = args;

  return percentage(value, total, decimalPoints);
}

export default helper(percentageOf);
