import Model, {
  hasMany,
  belongsTo,
  attr,
  AsyncHasMany,
  AsyncBelongsTo,
} from '@ember-data/model';
import { inject as service } from '@ember/service';
import RequisitionFlowStepModel from './requisition-flow-step';
import CompanyModel from './company';
import LocationModel from './location';
import DepartmentModel from './department';
import RoleModel from './role';
import computeFullErrorMessages from 'teamtailor/utils/full-error-messages';
import IntlService from 'ember-intl/services/intl';

export type CriteriaFieldSalary = {
  id: string;
  type: 'field';
  field: 'salary';
  operator: 'is_greater_than' | 'is_less_than' | 'is_between';
  maxSalary: number | null;
  currency: string | null;
  salaryTimeUnit: string | null;
};

export type CriteriaFieldLocation = {
  id: string;
  type: 'field';
  field: 'location';
  operator: 'is' | 'is_not';
  country: string | null;
  location_id: number | null;
};

export type CriteriaFieldDepartment = {
  id: string;
  type: 'field';
  field: 'department';
  operator: 'is' | 'is_not';
  role_id: number | null;
  department_id: number | null;
};

export type CriteriaField =
  | CriteriaFieldSalary
  | CriteriaFieldLocation
  | CriteriaFieldDepartment;

export type CriteriaGroup = {
  id: string;
  type: 'group';
  operator: 'or' | 'and';
  criteria: (CriteriaField | CriteriaGroup)[];
};

export default class RequisitionFlowModel extends Model {
  @service declare intl: IntlService;

  @attr('string') declare title: string;
  @attr('boolean') declare isDefault: boolean;
  @attr('string') declare country: string;
  @attr('number') declare maxSalary: number;
  @attr('string') declare currency: string | null;
  @attr('string') declare salaryTimeUnit: string | null;
  @attr('raw') declare criteria: CriteriaGroup | null;

  @hasMany('requisition-flow-step', { inverse: 'requisitionFlow' })
  declare steps: AsyncHasMany<RequisitionFlowStepModel>;

  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('location') declare location: AsyncBelongsTo<LocationModel>;
  @belongsTo('department') declare department: AsyncBelongsTo<DepartmentModel>;
  @belongsTo('role') declare role: AsyncBelongsTo<RoleModel>;

  get fullErrorMessages() {
    return computeFullErrorMessages(this, this.intl);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'requisition-flow': RequisitionFlowModel;
  }
}
