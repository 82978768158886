import {
  FilterValue,
  IOperator,
  ParseRawOptions,
} from 'teamtailor/components/fancy-filters';
import { JSONFilter } from 'teamtailor/components/fancy-filters/candidate-filters-json';
import BaseOperator from './base-operator';

export default class ExistsOperator extends BaseOperator implements IOperator {
  public get hideInput(): boolean {
    return true;
  }

  specialTypes = ['ReviewedBy', 'date', 'file'];

  public asJSON(): JSONFilter {
    if (!this.filter) {
      throw new Error('Filter is not set');
    }

    if (!this.filter.name) {
      return {};
    }

    return {
      [this.filter.name]: {
        [this.operatorName]: this.not && this.ignoreNotStructure ? 'no' : 'yes',
      },
    };
  }

  public isUsed(json: JSONFilter, options: ParseRawOptions = {}): boolean {
    if (this.ignoreNotStructure) {
      return !!Object.keys(json).find((key) => {
        if (
          key === 'or' ||
          key === 'and' ||
          key === 'not' ||
          key === this.filter?.name ||
          key === this.filter?.filter?.nested?.name ||
          key === this.filter?.filter?.groupedName
        ) {
          return this.isUsed(json[key] as JSONFilter, {
            ...options,
            [key]: true,
          });
        }

        if (key === this.operatorName) {
          if (
            (this.not && (json[key] === 'no' || json[key] === 'false')) ||
            (!this.not && (json[key] === 'yes' || json[key] === 'true'))
          ) {
            return true;
          }
        }

        return false;
      });
    } else {
      return super.isUsed(json, options);
    }
  }

  public parseValue(rawValue: string | string[]): FilterValue | undefined {
    return rawValue;
  }

  get stringifiedValue(): string {
    if (this.not) {
      return 'false';
    }

    return 'true';
  }

  public isValidValue = (): boolean => {
    return true;
  };
}

export function prepareExistsOperator(
  shouldExist = true,
  ignoreNotStructure = false
): () => ExistsOperator {
  return () => new ExistsOperator('exists', !shouldExist, ignoreNotStructure);
}
