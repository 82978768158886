import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import FromRoute from 'teamtailor/routes/employee/share-job/index';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import { get } from 'teamtailor/utils/get';
import { ModelFrom } from 'teamtailor/utils/type-utils';

export default class EmployeeShareJobIndex extends Controller {
  declare model: ModelFrom<FromRoute>;

  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare current: Current;

  get linkName() {
    return this.model.shareLinkName;
  }

  get mailHref() {
    const subject = this.intl.t('job.share.mail_subject', {
      companyName: this.current.company.name,
    });

    const body = this.intl.t('job.share.mail_body', { url: this.model.url });

    return `mailto:?subject=${subject}&body=${body}`;
  }

  get url() {
    return encodeURIComponent(this.model.url);
  }

  get title() {
    return encodeURIComponent(get(this.model.job, 'title'));
  }

  @action
  copyLink() {
    navigator.clipboard.writeText(this.model.url);

    this.flashMessages.success(
      this.intl.t('common.share_link_copied_to_clipboard')
    );
  }

  @action
  openLinkInWindow(e: MouseEvent) {
    e.preventDefault();

    const target = e.currentTarget as HTMLAnchorElement;
    window.open(target.href, 'newwindow', 'width=700, height=400');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'employee.share-job.index': EmployeeShareJobIndex;
  }
}
