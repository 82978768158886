import Controller from '@ember/controller';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Webhook from 'teamtailor/models/webhook-subscription';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import TtAlertService from 'teamtailor/services/tt-alert';

export default class SettingsWebhooksIndexController extends Controller {
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;

  @action
  delete(webhook: Webhook) {
    this.ttAlert.confirm(
      this.intl.t('settings.webhooks.are_you_sure_deleting'),
      () => {
        webhook.destroyRecord().then(() => {
          this.flashMessages.success(
            this.intl.t('settings.settings.webhook_removed')
          );
        });
      }
    );
  }

  @action
  copy(webhookSignature: string) {
    navigator.clipboard.writeText(webhookSignature);
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.webhooks.index': SettingsWebhooksIndexController;
  }
}
