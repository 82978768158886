import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"flex justify-end gap-6 text-[11px] leading-none text-neutral-medium\"\n>\n\n  <span\n    aria-label={{this.lastActivityLabel}}\n    class=\"last-activity-at body-text-xs m-0 flex items-center\"\n  >\n    <Icon @icon=\"bolt\" @size=\"10\" />\n    {{moment-from-now @lastActivityAt locale=(short-locale) interval=60000}}\n  </span>\n\n  <span\n    aria-label={{this.createdLabel}}\n    class=\"body-text-xs m-0 flex items-center gap-2\"\n  >\n    <Icon @icon=\"clock\" @size=\"10\" />\n    {{moment-from-now @createdAt locale=(short-locale) interval=60000}}\n  </span>\n</div>", {"contents":"<div\n  class=\"flex justify-end gap-6 text-[11px] leading-none text-neutral-medium\"\n>\n\n  <span\n    aria-label={{this.lastActivityLabel}}\n    class=\"last-activity-at body-text-xs m-0 flex items-center\"\n  >\n    <Icon @icon=\"bolt\" @size=\"10\" />\n    {{moment-from-now @lastActivityAt locale=(short-locale) interval=60000}}\n  </span>\n\n  <span\n    aria-label={{this.createdLabel}}\n    class=\"body-text-xs m-0 flex items-center gap-2\"\n  >\n    <Icon @icon=\"clock\" @size=\"10\" />\n    {{moment-from-now @createdAt locale=(short-locale) interval=60000}}\n  </span>\n</div>","moduleName":"teamtailor/components/stage/status.hbs","parseOptions":{"srcName":"teamtailor/components/stage/status.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import moment from 'moment-timezone';

interface Args {
  createReason: 'applied' | 'sourced' | 'suggested';
  createdAt: string;
  lastActivityAt: string;
}

export default class extends Component<Args> {
  @service declare intl: IntlService;

  get lastActivityLabel() {
    return this.intl.t('candidates.candidate.stage_card.last_activity_at', {
      date: moment(this.args.lastActivityAt).format('D MMM YYYY'),
    });
  }

  get createdLabel() {
    return this.intl.t(
      `candidates.candidate.stage_card.${this.args.createReason}_at`,
      {
        date: moment(this.args.createdAt).format('D MMM YYYY.'),
      }
    );
  }
}
