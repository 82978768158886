import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';

type PermissionRoleAccess = 'full' | 'conditional';
export type PermissionsMap = Record<string, PermissionRoleAccess>;

export default class PermissionsService extends Service {
  @service declare current: Current;
  @service declare store: Store;

  instantiate = true;
  singleton = true;

  @tracked
  permissions: PermissionsMap = {};

  has(type: string, scope?: PermissionRoleAccess): boolean {
    return scope ? this.permissions[type] === scope : !!this.permissions[type];
  }
}

declare module '@ember/service' {
  interface Registry {
    permissions: PermissionsService;
  }
}
