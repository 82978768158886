import { validatePresence } from 'ember-changeset-validations/validators';

import validateRegex from 'teamtailor/validators/regex';

const urlRegex =
  /(?:(http|https):\/\/)[a-zA-Z0-9][a-zA-Z-0-9]*(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-{}]*[\w@?^=%&amp;/~+#-{}])+/;

const urlValidator = validateRegex({
  pattern: urlRegex,
  messageKey: 'url',
});

export default {
  title: [validatePresence(true)],
  body: [validatePresence(true)],
  url: [urlValidator],
};
