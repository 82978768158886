import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import isIe11 from 'teamtailor/utils/is-ie11';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import { registerDestructor } from '@ember/destroyable';

export default class ApplicationController extends Controller {
  @service bowser;
  @service errorMessage;
  @service flashMessages;
  @service intercom;
  @service pwaInstallation;
  @service router;
  @service videoMeeting;

  @tracked
  pwaInstallIsSupported = false;

  get flashMessagesDestinationElement() {
    return document.getElementById('alert-box-wrapper');
  }

  @action onInsert() {
    const mutationObserver = new MutationObserver((mutationRecords) => {
      const addedIntercomNodes = mutationRecords
        .filter((r) => {
          return Array.from(r.addedNodes).filter((n) => {
            return !!n.className?.includes('intercom');
          }).length;
        })
        .reduce((acc, r) => [...acc, ...r.addedNodes], []);
      if (addedIntercomNodes.length > 0) {
        const intercomWrapperEl = addedIntercomNodes[0];

        const intercomWidgetEl =
          this.intercom.getIntercomWidgetEl(intercomWrapperEl);
        if (intercomWidgetEl) {
          intercomWidgetEl.style.viewTransitionName = 'intercomWidget';
        }
      }
    });

    mutationObserver.observe(document.body, {
      childList: true,
    });

    registerDestructor(this, () => {
      mutationObserver.disconnect();
    });
  }

  constructor() {
    super(...arguments);

    if (isIe11) {
      document.body.classList.add('ie-11');
    }

    window.addEventListener('appinstalled', () => {
      get(this, 'intercom').trackEvent('pwa-appinstalled');
      this.pwaInstallation.shouldShowInstalledMessage = true;

      location = get(this, 'router').urlFor('dashboard');
    });

    window.addEventListener('beforeinstallprompt', (event) => {
      if (this.bowser.platform.type === 'mobile') {
        event.preventDefault();
      } else {
        this.pwaInstallIsSupported = true;
      }

      this.deferredPrompt = event;
    });
  }
}
