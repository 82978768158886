import JobOfferBaseController from './base-controller';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class JobOfferShowController extends JobOfferBaseController {
  @service ttAlert;
  @service intl;
  @service flashMessages;
  @service router;
  @service user;
  @service routeHelper;
  @service store;

  @tracked showRejectModal = false;
  @tracked isIdle = false;
  @tracked limitedView = false;

  get jobOfferForm() {
    return this.jobOffer.jobOfferForm;
  }

  get displaySendButton() {
    return (
      !get(this, 'jobOffer.isSent') &&
      (!this.jobOfferApprovalsEnabled || get(this, 'jobOffer.isFlowApproved'))
    );
  }

  get displaySendForApprovalButton() {
    return (
      !get(this, 'jobOffer.isSent') && !get(this, 'jobOffer.approvalStatus')
    );
  }

  get displayEditButton() {
    // allow not to edit on "ready to send" and "sent" states
    return (
      (!this.limitedView &&
        this.jobOffer.approvalStatus === 'pending' &&
        this.jobOffer.status === 'pending') ||
      (this.jobOffer.approvalStatus === null &&
        this.jobOffer.status === 'pending' &&
        this.jobOffer.sentAt === null)
    );
  }

  get displayApprovalFlowAnswerButtons() {
    if (get(this, 'jobOffer.isDraft')) {
      return false;
    }

    const currentStep = get(this, 'jobOffer.currentStep');
    if (!currentStep) return undefined;

    return currentStep.pendingVerdicts
      .mapBy('userId')
      .includes(get(this.user, 'id'));
  }

  get editRoute() {
    return this.routeHelper.getNestedRoute('job-offer.edit');
  }

  get answerRoute() {
    return this.routeHelper.getNestedRoute('job-offer.answer');
  }

  sendOffer() {
    this.jobOffer.send().then(() => {
      this.flashMessages.success(
        this.intl.t('components.candidate_modal.job_offers.item.offer_sent')
      );
    });
  }

  deleteOffer() {
    this.jobOffer.deleteRecord();
    this.onClose();
  }

  @action
  previewOffer() {
    window.open(this.jobOffer.previewUrl, '_blank');
  }

  @action
  async handleCreateNewOffer() {
    this.isIdle = true;
    const jobOffer = this.store.createRecord('job-offer', {
      cloneJobOfferId: this.jobOffer.id,
    });

    if (this.jobOfferApprovalsEnabled) {
      await this.setApprovalFlow(
        get(this.jobOffer, 'jobApplication'),
        jobOffer
      );
    }

    await jobOffer.save();

    this.router.transitionTo(
      `${this.routeHelper.parentRoute}.job-offer.edit`,
      jobOffer
    );

    this.isIdle = false;
  }

  @action
  onDeleteOffer() {
    this.ttAlert.confirm(
      this.intl.t('candidates.job_offer.confirm_delete'),
      () => {
        this.deleteOffer();
      },
      () => {},
      {
        confirmButtonClass: 'btn-danger',
      }
    );
  }

  @action
  sendForApproval() {
    this.jobOffer.sendForApproval();
  }

  findUserVerdict() {
    const step = get(this.jobOffer, 'currentStep');

    return get(step, 'requisitionStepVerdicts').find((verdict) => {
      return get(verdict.user, 'id') === get(this.current.user, 'id');
    });
  }

  @action
  async onApprove() {
    const verdict = this.findUserVerdict();
    set(verdict, 'isApproved', true);
    return verdict.save();
  }

  @action
  async onReject() {
    const verdict = this.findUserVerdict();

    set(verdict, 'isApproved', false);

    this.store.createRecord('requisition-step-verdict-comment', {
      comment: this.comment,
      requisitionStepVerdict: verdict,
    });

    await verdict.save();
    set(this, 'showRejectModal', false);
  }
}
