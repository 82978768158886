export const maybeGroupSmallItems = (
  items: { name: string; value: number }[],
  percentageThreshold: number,
  groupLabel: string
) => {
  const valueSum = items.reduce((sum, item) => sum + item.value, 0);

  const firstTooSmallIndex = items.findIndex((i) => {
    return (i.value / valueSum) * 100 < percentageThreshold;
  });

  if (firstTooSmallIndex < 0) return items;

  const remainingValue = items
    .slice(firstTooSmallIndex)
    .reduce((sum, item) => sum + item.value, 0);

  const newItems = [
    ...items.slice(0, firstTooSmallIndex),
    {
      name: groupLabel,
      value: remainingValue,
      color: '#E5E6E9',
    },
  ];

  return newItems;
};
