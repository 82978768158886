import ApplicationSerializer from './application';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import { ModelSchema } from 'ember-data';
import { AnyObject } from 'active-model-adapter';

export default class BlockLayoutSerializer extends ApplicationSerializer {
  normalize(typeClass: ModelSchema, hash: AnyObject, prop: string) {
    hash._name = hash.name;
    delete hash.name;

    return super.normalize(typeClass, hash, prop);
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'block-layout': BlockLayoutSerializer;
  }
}
