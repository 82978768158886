import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { get } from 'teamtailor/utils/get';

export default class CandidateModalRoute extends Route {
  @service store;
  @service intl;
  @service recentVisits;
  @service router;
  @service flashMessages;

  async model({ candidate_id }) {
    try {
      return this.store.findRecord('candidate', candidate_id);
    } catch (e) {
      if (e.errors[0].status === '404') {
        let parentRoute = this.routeName.split('.').slice(0, -1).join('.');
        this.flashMessages.error(
          this.intl.t('mixins.candidate_modal_route.candidate_was_not_found')
        );
        this.router.transitionTo(parentRoute);
      }
    }
  }

  afterModel(model) {
    super.afterModel(...arguments);
    this.recentVisits.log(model);
  }

  resetController(controller, isExiting) {
    const candidate = get(controller, 'model');

    if (candidate) {
      if (isExiting) {
        set(controller, 'model', null);
      }
    }
  }
}
