import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"common.status\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"label\"\n    @options={{this.options}}\n    @isDisabled={{@isDisabled}}\n    @onSelect={{@onChange}}\n    @searchEnabled={{false}}\n    @selected={{find-by \"id\" @selected this.options}}\n    @placeholder={{t \"nurture_campaign.status_options.all_statuses\"}}\n    as |Item option|\n  >\n    <Item @status={{option.id}} />\n  </Core::Select>\n</label>", {"contents":"<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"common.status\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"label\"\n    @options={{this.options}}\n    @isDisabled={{@isDisabled}}\n    @onSelect={{@onChange}}\n    @searchEnabled={{false}}\n    @selected={{find-by \"id\" @selected this.options}}\n    @placeholder={{t \"nurture_campaign.status_options.all_statuses\"}}\n    as |Item option|\n  >\n    <Item @status={{option.id}} />\n  </Core::Select>\n</label>","moduleName":"teamtailor/components/sidebar/filters/nurture-statuses.hbs","parseOptions":{"srcName":"teamtailor/components/sidebar/filters/nurture-statuses.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

interface OptionsArgs {
  id: string | undefined;
  label: string | undefined;
}

export default class extends Component {
  @service declare intl: IntlService;

  get options(): OptionsArgs[] {
    return [
      { id: 'all', label: this.intl.t('nurture_campaign.status_options.all') },
      {
        id: 'available',
        label: this.intl.t('nurture_campaign.status_options.current'),
      },
      {
        id: 'active',
        label: this.intl.t('nurture_campaign.status_options.active'),
      },
      {
        id: 'draft',
        label: this.intl.t('nurture_campaign.status_options.draft'),
      },
      {
        id: 'paused',
        label: this.intl.t('nurture_campaign.status_options.paused'),
      },
      {
        id: 'archived',
        label: this.intl.t('common.archived'),
      },
    ];
  }
}
