import TriggerSerializer from 'teamtailor/serializers/trigger';

export default TriggerSerializer.extend({
  attrs: {
    stage: { serialize: 'ids' },
    form: {
      serialize: 'ids',
      key: 'survey',
      deserialize: 'records',
    },
  },

  serialize() {
    let [commonJson, extras] = this.extractCommonJson(
      this._super(...arguments)
    );

    let { ...data } = extras;

    return Object.assign(commonJson, {
      data,
    });
  },
});
