import Controller from '@ember/controller';
import { get, action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import saveFile from 'teamtailor/utils/save-file';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import moment from 'moment-timezone';

const formatDate = (date) => moment(date).format('YYYY-MM-DD');

export default class extends Controller {
  @service server;
  @service store;
  @service intl;
  @service router;
  @service current;
  @service permissions;
  @service flashMessages;

  @tracked selectedStageId = 'by_total';
  @tracked hasFiltersSelected = false;
  @tracked showFilterSidebar = false;

  @tracked jobId = null;
  @tracked location_id = null;
  @tracked department_id = null;
  @tracked year = null;
  @tracked showStageBreakdown = this.hasStageBreakdownAsDefault;
  @tracked isOpen = false;
  @tracked startDate = this.firstDayOfPreviousYear;
  @tracked endDate = this.lastDayOfPreviousYear;

  queryParams = ['jobId', 'location_id', 'department_id', 'year'];

  get lastDayOfPreviousYear() {
    return moment().startOf('year').subtract(1, 'days').endOf('day').toDate();
  }

  get firstDayOfPreviousYear() {
    return moment()
      .startOf('year')
      .subtract(1, 'years')
      .startOf('year')
      .toDate();
  }

  get formQuestions() {
    return get(this, 'survey.formQuestions');
  }

  get survey() {
    return this.model.survey;
  }

  get results() {
    return this.model.results;
  }

  get userCanDownloadEeoReport() {
    return (
      this.permissions.has('settings/update_company', 'full') &&
      this.survey.formType === 'eeo'
    );
  }

  get hasStageBreakdownAsDefault() {
    const savedStageBreakdownSurveys = JSON.parse(
      localStorage.getItem(this.stageBreakdownLocalStorageKey)
    );

    return (
      savedStageBreakdownSurveys &&
      savedStageBreakdownSurveys.includes(this.survey.id)
    );
  }

  get stageBreakdownLocalStorageKey() {
    return `stage-breakdown-${this.current.user.id}`;
  }

  get company() {
    return this.current.company;
  }

  get companyHasAnyLocations() {
    return this.store.query('location', { per_page: 1 });
  }

  get isJobFilterSelected() {
    return !!this.jobId;
  }

  get yearOptions() {
    const thisYear = new Date().getFullYear();
    const createdYear = this.survey.createdAt.getFullYear();
    let years = [];

    for (let i = thisYear; i >= createdYear; i--) {
      years.push(i);
    }

    return years.map((year) => {
      return {
        id: year,
        value: year.toString().trim(),
      };
    });
  }

  get questions() {
    const result = get(this, 'results.questions')
      .sort((qa, qb) => {
        const questionA = get(this, 'formQuestions').find(
          (item) => String(item.id) === String(qa.question_id)
        );

        const questionB = get(this, 'formQuestions').find(
          (item) => String(item.id) === String(qb.question_id)
        );

        return questionA.sortIndex - questionB.sortIndex;
      })
      .map((questionResult) => {
        const question = get(this, 'formQuestions').find(
          (item) => String(item.id) === String(questionResult.question_id)
        );

        if (get(question, 'isTextType') || get(question, 'isScaleType')) {
          return this.textOrScaleTypeQuestionData(question, questionResult);
        }

        if (!Array.isArray(questionResult.answers)) {
          return {
            title: get(question, 'text'),
            type: get(question, 'type'),
            answers: [],
          };
        }

        return this.choiceOrCheckboxTypeQuestionData(question, questionResult);
      });

    return result;
  }

  choiceOrCheckboxTypeQuestionData(question, questionResult) {
    const alternatives = [
      ...(get(question, 'schema')?.alternatives || []),
      ...(get(question, 'schema')?.other
        ? [
            {
              title: this.intl.t('settings.requisitions.custom_form.other'),
              id: 'other',
            },
          ]
        : []),
    ];

    const answers =
      alternatives.map((alt) => {
        const answer = questionResult.answers.find((a) => a.id === alt.id);

        const { number_of_picks, stage_breakdown } = answer;

        return {
          id: alt.id,
          label: alt.title,
          value: {
            by_total: number_of_picks?.by_total,
            by_rejected: number_of_picks?.by_rejected,
            by_hired: number_of_picks?.by_hired,
          },

          stageBreakdown: stage_breakdown,
        };
      }) || [];

    return {
      title: get(question, 'text'),
      type: get(question, 'type'),
      answers,
      others: questionResult.others,
    };
  }

  textOrScaleTypeQuestionData(question, questionResult) {
    const data = {
      id: get(question, 'id'),
      title: get(question, 'text'),
      type: get(question, 'type'),
      answers: questionResult.answers,
    };

    if (get(question, 'isScaleType')) {
      data.average_score = questionResult.average_score;
      data.maximum_value = questionResult.maximum_value;
    }

    return data;
  }

  get exportUrl() {
    const adapter = get(this, 'store').adapterFor('application');
    const url = adapter.buildURL('surveys', this.survey.id);

    const query = `?job_id=${this.jobId}&location_id=${this.location_id}&department_id=${this.department_id}&year=${this.year}`;

    return `${url}/results.csv${query}`;
  }

  get stats() {
    const stats = this.results.response_stats;
    return [
      {
        icon: 'inbox-out',
        title: this.intl.t('surveys.sent'),
        value: stats.sends,
      },
      {
        icon: 'inbox-in',
        title: this.intl.t('surveys.responses'),
        value: stats.responses,
      },
      {
        icon: 'chart-pie',
        title: this.intl.t('surveys.response_rate'),
        value: `${stats.responseRate}%`,
      },
    ];
  }

  get selectedYear() {
    return parseInt(this.year);
  }

  handleLocalStorage() {
    const savedStageBreakdownSurveys =
      JSON.parse(localStorage.getItem(this.stageBreakdownLocalStorageKey)) ||
      [];

    if (this.showStageBreakdown) {
      savedStageBreakdownSurveys.addObject(this.survey.id);
    } else {
      savedStageBreakdownSurveys.removeObject(this.survey.id);
    }

    if (savedStageBreakdownSurveys.length) {
      localStorage.setItem(
        this.stageBreakdownLocalStorageKey,
        JSON.stringify(savedStageBreakdownSurveys)
      );
    } else {
      localStorage.removeItem(this.stageBreakdownLocalStorageKey);
    }
  }

  fetchExportTask = task(async () => {
    const filename = `survey_${this.survey.id}.csv`;

    const content = await this.server
      .fetch(this.exportUrl)
      .then((response) => response.text());

    saveFile(filename, content, 'text/csv');
  });

  @action
  handlePickedJob(job) {
    this.selectItem('jobId', job);
  }

  @action
  handleShowStageBreakdown() {
    this.showStageBreakdown = !this.showStageBreakdown;
    this.handleLocalStorage();
  }

  @action
  clearFilters() {
    this.hasFiltersSelected = false;
  }

  @action
  selectItem(attribute, selected) {
    const currentValue = get(this, attribute);
    if (selected && currentValue === selected.id) {
      selected = false;
    }

    set(this, attribute, selected ? selected.id : null);

    const queryParams = {
      ...(this.jobId ? { job_id: this.jobId } : {}),
      ...(this.location_id ? { location_id: this.location_id } : {}),
      ...(this.department_id ? { department_id: this.department_id } : {}),
      ...(this.year ? { year: this.year } : {}),
    };

    this.router.transitionTo({
      queryParams,
    });
  }

  @action
  openModal() {
    this.isOpen = true;
  }

  @action
  onStartDateChange(date) {
    this.startDate = date;
  }

  @action
  onEndDateChange(date) {
    this.endDate = date;
  }

  @action
  async handleDownload() {
    return this.survey
      .downloadEeoReport(formatDate(this.startDate), formatDate(this.endDate))
      .then(() => {
        this.flashMessages.success(
          this.intl.t('surveys.export_eeo_successful')
        );
        this.onClose();
      })
      .catch(() => {
        this.flashMessages.error(this.intl.t('surveys.export_eeo_failed'));
      });
  }

  @action
  onClose() {
    this.isOpen = false;
  }
}
