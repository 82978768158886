import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import SettingsLocationEditController from 'teamtailor/controllers/settings/locations/edit';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get } from 'teamtailor/utils/get';

export default class LocationsEditRoute extends ScrollToTopRoute {
  @service declare store: Store;

  model(params: { location_id: string }) {
    return this.store.findRecord('location', params.location_id);
  }

  resetController(
    controller: SettingsLocationEditController,
    isExiting: boolean
  ) {
    if (isExiting && !get(controller.model, 'isDeleted')) {
      controller.model.rollbackAttributes();
      get(controller.model, 'translations').invoke('rollbackAttributes');
    }
  }
}
