import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"flex flex-col items-center bg-neutral md:flex-row\"\n  local-class={{class-names\n    \"actionBar\"\n    actionBar--wide=@isWide\n    actionBar--animate=@animate\n    actionBar--hidden=(not this.show)\n  }}\n  ...attributes\n>\n  <div local-class=\"content\" class=\"flex flex-grow justify-center\">\n    {{yield (hash content=(component \"yield-content\"))}}\n  </div>\n  <div local-class=\"action\" class={{@actionWrapperClasses}}>\n    {{yield (hash status-button=(component \"action-bar/status-button\"))}}\n  </div>\n</div>", {"contents":"<div\n  class=\"flex flex-col items-center bg-neutral md:flex-row\"\n  local-class={{class-names\n    \"actionBar\"\n    actionBar--wide=@isWide\n    actionBar--animate=@animate\n    actionBar--hidden=(not this.show)\n  }}\n  ...attributes\n>\n  <div local-class=\"content\" class=\"flex flex-grow justify-center\">\n    {{yield (hash content=(component \"yield-content\"))}}\n  </div>\n  <div local-class=\"action\" class={{@actionWrapperClasses}}>\n    {{yield (hash status-button=(component \"action-bar/status-button\"))}}\n  </div>\n</div>","moduleName":"teamtailor/components/action-bar.hbs","parseOptions":{"srcName":"teamtailor/components/action-bar.hbs"}});
import Component from '@glimmer/component';
import { argDefault } from 'teamtailor/utils/arg-default';

interface ActionBarComponentArgs {
  show?: boolean;
}

export default class ActionBarComponent extends Component<ActionBarComponentArgs> {
  @argDefault show = true;
}
