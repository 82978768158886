import { htmlSafe } from '@ember/template';
import Helper from '@ember/component/helper';
import { simpleFormat } from './simple-format';

const teamRegex = /@team(\/(\w+))?/gi;

export default Helper.helper(function (params, { mentionedUsernames = [] }) {
  let formattedString = simpleFormat(params).toString();
  let username = `@${params[1]}`;

  let maxWordLength = params[2] || null;

  if (maxWordLength !== null && maxWordLength > 0) {
    const stringParts = formattedString.split(' ');

    formattedString = stringParts.slice(0, maxWordLength).join(' ');
    if (stringParts.length > maxWordLength) {
      formattedString += '...';
    }
  }

  const mentionWrap = (matched) =>
    `<mark class="mention bg-action-weak-active px-[1px] rounded text-action ${
      matched === username ? 'mentions-me' : ''
    }">${matched}</mark>`;

  formattedString = formattedString.replace(teamRegex, mentionWrap);

  if (mentionedUsernames.length > 0) {
    formattedString = formattedString.replace(
      new RegExp(`@(${mentionedUsernames.join('|')})`, 'gi'),
      mentionWrap
    );
  }

  return htmlSafe(formattedString);
});
