import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import moment from 'moment-timezone';
import { dropTask } from 'ember-concurrency';
import CustomReportRequest, {
  fetchSchema,
  CustomReportRequestFilter,
} from 'teamtailor/utils/custom-report-request';
import Filter from 'teamtailor/components/fancy-filters/serializable-filter';
import { isEmpty, isPresent } from '@ember/utils';
import { copy } from 'ember-copy';
import { jsonToBase64 } from 'teamtailor/utils/base-64';
import { initializeProperties } from 'teamtailor/utils/insights/reports/properties';
import { initializeFilters } from 'teamtailor/utils/insights/reports/filters';
import { getOwner } from '@ember/application';
import uniq from 'teamtailor/utils/uniq';

const formatDate = (date) => moment(date).format('YYYY-MM-DD');

const parseDateWithFallback = (dateString, daysAgoFallback) => {
  let date = moment(dateString);

  if (!dateString || !date.isValid()) {
    date = moment().subtract(daysAgoFallback, 'days').startOf('day');
  }

  return date.toDate();
};

const daysAgo = (days) => moment().subtract(days, 'days').startOf('day');
export default class AnalyticsService extends Service {
  @service current;
  @service store;
  @service intl;

  @tracked jobId = '';
  @tracked locationId = '';
  @tracked departmentId = '';
  @tracked page = 1;
  @tracked filters = [];

  @tracked startDate = daysAgo(28).toDate();
  @tracked endDate = daysAgo(1).toDate();
  @tracked encodedFilters;

  get startDateParam() {
    return moment(this.startDate).format('YYYY-MM-DD');
  }

  get endDateParam() {
    return moment(this.endDate).format('YYYY-MM-DD');
  }

  get hasEnabledGroupAnalytics() {
    return get(this.current.company, 'hasEnabledGroupAnalytics');
  }

  _availableCompanyIds;
  get availableCompanyIds() {
    const companyIds = this._availableCompanyIds || [
      this.current.company.id,
      ...(this.hasEnabledGroupAnalytics
        ? this.current.company.groupAnalyticsChildCompanyIds
        : []),
    ];

    const companyFilter = this.filters.find(
      (filter) => filter.name === 'company' && Array.isArray(filter.value)
    );

    if (companyFilter) {
      const filteredCompanyIds = companyFilter.value.map((company) =>
        company.id.toString()
      );

      return companyIds.filter((id) =>
        filteredCompanyIds.includes(id.toString())
      );
    }

    return companyIds;
  }

  set availableCompanyIds(value) {
    this._availableCompanyIds = value;
  }

  _currentStore;
  get currentStore() {
    return this._currentStore || this.store;
  }

  set currentStore(value) {
    this._currentStore = value;
  }

  async initializeCustomReportItems(groupAnalytics, loadCustomFields) {
    this._allProperties = await initializeProperties(
      this.currentStore,
      groupAnalytics,
      this.intl,
      loadCustomFields
    );
    this._allFilters = await initializeFilters(
      this.currentStore,
      groupAnalytics,
      this.intl,
      loadCustomFields
    );
  }

  _allProperties;
  get allProperties() {
    return this._allProperties;
  }

  _allFilters;
  get allFilters() {
    return this._allFilters;
  }

  get minDate() {
    return (
      (this.current.company && get(this.current.company, 'went_live_at')) ||
      new Date('2001-01-01')
    );
  }

  get selectedJobIds() {
    return this.fetchMatchingJobs.lastSuccessful?.value
      ? uniq(this.fetchMatchingJobs.lastSuccessful?.value)
      : undefined;
  }

  fromJob = false;

  get serializedFilters() {
    if (isPresent(this.filters)) {
      return jsonToBase64(this.filters.mapBy('serialize'));
    } else {
      return '';
    }
  }

  get currentPeriod() {
    return [get(this, 'startDate'), get(this, 'endDate')];
  }

  get periodLength() {
    const startDate = get(this, 'startDate');
    const endDate = get(this, 'endDate');
    return moment(endDate).diff(moment(startDate).subtract(1, 'days'));
  }

  get previousPeriod() {
    const periodLength = get(this, 'periodLength');
    return get(this, 'currentPeriod').map((date) =>
      moment(date).subtract(periodLength)
    );
  }

  get currentPeriodSettings() {
    const [startDate, endDate] = get(this, 'currentPeriod').map(formatDate);

    return {
      startDate,
      endDate,
      jobId: get(this, 'jobId'),
      departmentId: get(this, 'departmentId'),
      locationId: get(this, 'locationId'),
      page: get(this, 'page'),
    };
  }

  get previousPeriodSettings() {
    const [startDate, endDate] = get(this, 'previousPeriod').map(formatDate);
    if (moment(startDate).isBefore(moment(this.minDate))) {
      return null;
    }

    return {
      startDate,
      endDate,
      jobId: get(this, 'jobId'),
      departmentId: get(this, 'departmentId'),
      locationId: get(this, 'locationId'),
      page: get(this, 'page'),
    };
  }

  fetchSchemaTask = dropTask(async (hideGroupCompanyAnalytics = false) => {
    const schema = await fetchSchema(this.store, hideGroupCompanyAnalytics);
    const { filters } = schema.jobs;

    const sortedFilters = ORDERED_FILTERS.map((name) =>
      filters.find((f) => f.name === name)
    );
    const otherFilters = filters.filter(
      (f) => !ORDERED_FILTERS.includes(f.name)
    );

    return [...sortedFilters, ...otherFilters];
  });

  addFilter(filterOption) {
    const nextFilter = new Filter(filterOption);
    nextFilter.isOpen = true;

    this.filters.pushObject(nextFilter);
  }

  removeFilter(filter) {
    this.filters.removeObject(filter);
  }

  _fetchedLatestJobsWithFilters = undefined;
  set fetchedLatestJobsWithFilters(value) {
    this._fetchedLatestJobsWithFilters = value.map((f) => copy(f, true));
  }

  get fetchedLatestJobsWithFilters() {
    return this._fetchedLatestJobsWithFilters;
  }

  fetchMatchingJobs = dropTask(async () => {
    const filters = this.filters.filterBy('isValid');

    if (isEmpty(filters)) {
      return undefined;
    }

    if (compareFilters(filters, this.fetchedLatestJobsWithFilters)) {
      return this.selectedJobIds;
    }

    const request = new CustomReportRequest(
      getOwner(this),
      false,
      'jobs',
      [{ id: 'id', name: 'id', type: 'integer' }],
      filters.map(
        (filter) =>
          new CustomReportRequestFilter(
            filter.filter,
            filter.selectedOperator,
            filter.value
          )
      )
    );

    const results = await request.fetchCustomReportRows.perform(3000);
    this.fetchedLatestJobsWithFilters = filters;

    return results.flat();
  });

  @action
  setStartDate(value) {
    let startDate = parseDateWithFallback(value, 28);
    this.startDate = startDate;
  }

  @action
  setEndDate(value) {
    let endDate = parseDateWithFallback(value, 1);
    this.endDate = endDate;
  }

  @action
  setCurrentPeriod(startDate, endDate) {
    this.setStartDate(startDate);
    this.setEndDate(endDate);
  }
}

function compareFilters(a, b) {
  if (a.length !== b?.length) {
    return false;
  }

  const serializedA = a.mapBy('serialize');
  const serializedB = b.mapBy('serialize');

  return serializedA.every((row) => serializedB.includes(row));
}

const ORDERED_FILTERS = [
  'job_ids',
  'department_ids',
  'location_ids',
  'role',
  'tags',
  'recruiter',
  'title',
  'status',
  'inbox_count',
  'in_process_count',
  'screening_count',
  'interview_count',
  'offer_count',
  'rejected_count',
  'hired_count',
  'remote_status',
  'internal',
  'job_started_at',
  'end_date',
  'start_date',
  'created_at',
];
