import { themeColors } from '@teamtailor/design-tokens';

export const DECORATIVE_COLORS = Object.keys(themeColors.background.decorative);
const ALERT_COLORS = ['success', 'warning', 'danger', 'info'];
export const STANDARD_COLORS = ['white', 'black', 'transparent'];

export const ALLOWED_COLORS = [
  ...DECORATIVE_COLORS,
  ...ALERT_COLORS,
  ...STANDARD_COLORS,
] as const;

export const DEFAULT_DECORATIVE_VARIANT = 'medium';
export const ALLOWED_VARIANTS = ['weak', DEFAULT_DECORATIVE_VARIANT, 'strong'];
