import { QueryType } from '../base-query';
import { capitalize } from '@ember/string';
import AggregatedProperty, {
  AggregatedPropertyOptions,
} from './aggregated-property';
import IntlService from 'ember-intl/services/intl';

interface CollectPropertyOptions extends AggregatedPropertyOptions {
  field: string;
  propertyTranslationKey?: string;
}

export default class CollectProperty extends AggregatedProperty {
  field: string;

  constructor(options: CollectPropertyOptions) {
    super({
      ...options,
      type: 'collect',
      columnType: 'array',
      translationKey: `insights.reports.builder.aggregated_properties.${
        options.propertyTranslationKey
          ? 'collect_with_property_name'
          : 'collect'
      }`,
    });
    this.field = options.field;
    this.propertyTranslationKey = options.propertyTranslationKey;
  }

  getField(_queryType: QueryType) {
    return `collect(field: ${this.field}, filters: ${this.filters})`;
  }

  get columnName() {
    const field = `${this.parentProperty?.columnName}`;

    return `${field}${capitalize(super.columnName)}Collects`;
  }

  translate(intl: IntlService) {
    if (this.parentProperty) {
      this.translatedName = capitalize(
        intl
          .t(this.translationKey, {
            eventName: intl.t(this.parentProperty.translationKey),
            propertyName:
              this.propertyTranslationKey &&
              intl.t(this.propertyTranslationKey),
          })
          .toLowerCase()
      );

      this.translatedNameForChartName = this.translatedName;
    } else {
      super.translate(intl);
    }
  }

  formatForCSV(value: any) {
    if (value === undefined) {
      return [];
    }

    return value.map((v: any) => this.parentProperty?.formatForCSV(v));
  }

  sorter(a: any, b: any) {
    return (
      (a[this.columnName] || []).length - (b[this.columnName] || []).length
    );
  }
}
