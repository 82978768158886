import TriggerSerializer from 'teamtailor/serializers/trigger';

export default class extends TriggerSerializer {
  attrs = {
    stage: { serialize: 'ids' },
  };

  serialize() {
    let [commonJson, extras] = this.extractCommonJson(
      super.serialize(...arguments)
    );

    let { ...data } = extras;

    return Object.assign(commonJson, {
      data,
    });
  }
}
