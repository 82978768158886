export const KeyCodes = {
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',
  ENTER: 'Enter',
  ESC: 'Escape',
  SPACE: 'Space',
  TAB: 'Tab',
  CMD_Z: 'cmd+z',
  CMD_SHIFT_Z: 'cmd+shift+z',
};
