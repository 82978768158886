import Helper from '@ember/component/helper';
import { RequisitionModel } from 'teamtailor/models';
import { BADGE_COLOR_MAP } from 'teamtailor/constants/badge-colors';

type ValidBadgeKeys = keyof typeof BADGE_COLOR_MAP;

export default class RequisitionStatusBadge extends Helper {
  compute([requisition]: [RequisitionModel]): ValidBadgeKeys {
    if (requisition.isDraft) {
      return 'draft';
    }

    if (requisition.isCompleted) {
      return 'completed';
    }

    if (['approved', 'rejected'].includes(requisition.status)) {
      return requisition.status as 'approved' | 'rejected';
    }

    return 'requested';
  }
}
