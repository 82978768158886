import Service from '@ember/service';
import { getOwner } from '@ember/application';
import Owner from '@ember/owner';
import ContentIndexController from 'teamtailor/controllers/content';

export default class ContentCareerSiteService extends Service {
  get current() {
    const owner = getOwner(this) as Owner;

    return (owner.lookup('controller:content.index') as ContentIndexController)
      .careerSite;
  }
}

declare module '@ember/service' {
  interface Registry {
    'content-career-site': ContentCareerSiteService;
  }
}
