import { registerDestructor } from '@ember/destroyable';
import Modifier from 'ember-modifier';

export default class LeafletMapModifier extends Modifier {
  async modify(element, [options]) {
    if (!options) {
      return;
    }

    if (!this.leaflet) {
      await this.initializeLeaflet();
    }

    this.drawMap({ options, element });

    registerDestructor(this, this.destroyMap.bind(this));
  }

  initializeLeaflet() {
    return Promise.all([
      import('leaflet').then((module) => module.default),
      import('leaflet/dist/leaflet.css'),
    ]).then(([Leaflet]) => {
      this.leaflet = Leaflet;
    });
  }

  drawMap({ options, element }) {
    if (!this.mapInstance) {
      this.mapInstance = this.leaflet.map(element, options.mapOptions);

      this.leaflet
        .tileLayer(
          'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
          {
            maxZoom: 13,
          }
        )
        .addTo(this.mapInstance);
    }

    const icon = this.leaflet.icon({
      iconUrl: '/assets/images/location-pin@2x.png',
      iconSize: [25, 40],
      iconAnchor: [12.5, 40],
    });

    if (this.marker) {
      this.marker.setLatLng(options.markerOptions);
    } else if (options.markerOptions) {
      this.marker = this.leaflet
        .marker(options.markerOptions, { icon })
        .addTo(this.mapInstance);
    }

    if (options.callback) {
      this.mapInstance.on('click', options.callback);
    }
  }

  destroyMap() {
    this.marker?.remove();
    this.mapInstance?.remove();
    this.marker = null;
    this.mapInstance = null;
  }
}
