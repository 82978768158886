import Model, { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import PermissionModel from 'teamtailor/models/permission';

export default class RolePermissionModel extends Model {
  @belongsTo('permission') declare permission: AsyncBelongsTo<PermissionModel>;

  @attr('number') declare role: number;
  @attr('boolean') declare conditional: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'role-permission': RolePermissionModel;
  }
}
