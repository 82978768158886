import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"job.requisitions_selects.assignee\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"label\"\n    @selected={{find-by \"id\" @selected this.options}}\n    @options={{this.options}}\n    @searchEnabled={{false}}\n    @onSelect={{@onChange}}\n    @nullOption={{t \"job.requisitions_selects.all\"}}\n  />\n</label>", {"contents":"<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"job.requisitions_selects.assignee\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"label\"\n    @selected={{find-by \"id\" @selected this.options}}\n    @options={{this.options}}\n    @searchEnabled={{false}}\n    @onSelect={{@onChange}}\n    @nullOption={{t \"job.requisitions_selects.all\"}}\n  />\n</label>","moduleName":"teamtailor/components/sidebar/filters/assignees.hbs","parseOptions":{"srcName":"teamtailor/components/sidebar/filters/assignees.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

interface StatusArgs {
  hideDrafts: boolean | undefined;
}

interface OptionsArgs {
  id: string | undefined;
  label: string | undefined;
}

export default class extends Component<StatusArgs> {
  @service declare intl: IntlService;

  get options(): OptionsArgs[] {
    const statuses = ['involving-me', 'awaiting-my-approval'];

    return statuses.map((status) => ({
      id: status,
      label: this.intl.t(`job.requisitions_selects.${status}`),
    }));
  }
}
