import { AsyncBelongsTo } from '@ember-data/model';
import Service from '@ember/service';
import { CandidateModel, JobModel } from 'teamtailor/models';

type FileData = {
  candidate: CandidateModel;
  job?: AsyncBelongsTo<JobModel>;
};

export default class FileUploadData extends Service {
  data: { [key: string]: FileData } = {};

  add(key: string, fileData: FileData) {
    this.data[key] = fileData;
  }

  remove(key: string) {
    delete this.data[key];
  }
}

declare module '@ember/service' {
  interface Registry {
    'file-upload-data': FileUploadData;
  }
}
