import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';

export default class NurtureCampaignController extends Controller {
  @service ttAlert;
  @service intl;
  @service router;
  @service flashMessages;

  get subTitle() {
    let count = this.model.activeRecipientsCount;
    return this.intl.t('nurture_campaign.no_active_recipients', {
      recipientsCount: count,
    });
  }

  @action
  activate() {
    this.model.activate();
  }

  @action
  pause() {
    this.model.pause();
  }

  @action
  resume() {
    this.model.resume();
  }

  @action
  archive() {
    get(this, 'ttAlert').confirm(
      this.intl.t('nurture_campaign.archive_normal_campaign'),
      () => {
        this.model.archive().then(() => {
          get(this, 'flashMessages').success('Campaign archived');
          this.router.transitionTo('nurture-campaigns.index');
        });
      }
    );
  }
}
