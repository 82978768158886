import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"min-h-full\" {{did-update (perform this.handleSearchInput) @query}}>\n  <img\n    class=\"mb-16 w-100\"\n    src=\"/PoweredBy_200px-White_HorizText.png\"\n    alt={{t \"components.redactor_giphy.manager.powered_by_giphy\"}}\n  />\n  <MediaLibrary::InfinityContent\n    @type=\"giphy\"\n    @fetchTask={{this.fetchImages}}\n    @images={{this.images}}\n    @canPickImage={{this.canPickImage}}\n    @onPickImage={{this.handlePickImage}}\n    @isSearching={{this.isSearching}}\n  />\n</div>", {"contents":"<div class=\"min-h-full\" {{did-update (perform this.handleSearchInput) @query}}>\n  <img\n    class=\"mb-16 w-100\"\n    src=\"/PoweredBy_200px-White_HorizText.png\"\n    alt={{t \"components.redactor_giphy.manager.powered_by_giphy\"}}\n  />\n  <MediaLibrary::InfinityContent\n    @type=\"giphy\"\n    @fetchTask={{this.fetchImages}}\n    @images={{this.images}}\n    @canPickImage={{this.canPickImage}}\n    @onPickImage={{this.handlePickImage}}\n    @isSearching={{this.isSearching}}\n  />\n</div>","moduleName":"teamtailor/components/redactor-giphy/manager.hbs","parseOptions":{"srcName":"teamtailor/components/redactor-giphy/manager.hbs"}});
import MediaLibraryManagerBaseComponent from 'teamtailor/components/media-library/manager-base';
import { restartableTask } from 'ember-concurrency';

import InfinityModel from 'ember-infinity/lib/infinity-model';

const GiphyInfinityModel = InfinityModel.extend({
  buildParams() {
    let params = this._super(...arguments);
    const page = parseInt(params.page, 10) || 0;
    params.offset = page * params.limit;
    return params;
  },
});

export default class RedactorGiphyManagerComponent extends MediaLibraryManagerBaseComponent {
  perPage = 25;

  willDestroy() {
    super.willDestroy(...arguments);
    this.store.unloadAll('giphy-image');
  }

  fetchImages = restartableTask(async () => {
    const params = {
      perPage: this.perPage,
      perPageParam: 'limit',
      startingPage: 0,
    };

    if (this.isSearching) {
      params.q = this.queryParams.query;
    }

    const infinityModel = await this.infinity.model(
      'giphy-image',
      params,
      GiphyInfinityModel
    );

    this.images = infinityModel;
  });
}
