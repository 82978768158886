import BaseRoute from 'teamtailor/routes/base';
import PageModel from 'teamtailor/models/page';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';

export default class SharingImageRoute extends BaseRoute {
  @service declare store: Store;

  model(): PageModel {
    return this.modelFor('content.index.page.settings');
  }

  afterModel(model: PageModel) {
    return get(model, 'sharingImage');
  }
}
