import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"body-text-semibold relative flex h-64 items-center justify-center\n    {{concat 'bg-decorative-' @cell.color '-weak'}}\n    {{concat 'text-decorative-' @cell.color '-weak'}}\n    \"\n  local-class=\"heatMapCellWidth\"\n>\n  {{#if (is-empty @cell.value)}}\n    {{t \"common.na\"}}\n  {{else}}\n    {{@cell.value}}\n  {{/if}}\n</div>", {"contents":"<div\n  class=\"body-text-semibold relative flex h-64 items-center justify-center\n    {{concat 'bg-decorative-' @cell.color '-weak'}}\n    {{concat 'text-decorative-' @cell.color '-weak'}}\n    \"\n  local-class=\"heatMapCellWidth\"\n>\n  {{#if (is-empty @cell.value)}}\n    {{t \"common.na\"}}\n  {{else}}\n    {{@cell.value}}\n  {{/if}}\n</div>","moduleName":"teamtailor/components/insights/atoms/heatmap-table-cell.hbs","parseOptions":{"srcName":"teamtailor/components/insights/atoms/heatmap-table-cell.hbs"}});
import Component from '@glimmer/component';
import { HeatmapTableCell } from 'teamtailor/components/insights/molecules/heatmap-table-types';

type Args = {
  cell: HeatmapTableCell;
};

export default class InsightsMoleculesHeatmapTableCell extends Component<Args> {}
