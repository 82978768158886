import Model, { belongsTo, attr, type AsyncBelongsTo } from '@ember-data/model';
import { CompanyModel, UserModel } from 'teamtailor/models';

export default class GlobalCollaborateMessageModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;

  @attr('string') declare message: string;
  @attr('string') declare channel: string;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'global-collaborate-message': GlobalCollaborateMessageModel;
  }
}
