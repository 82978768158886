import Model, { hasMany, attr, type AsyncHasMany } from '@ember-data/model';
import { OccupationGroupModel } from 'teamtailor/models';

export default class OccupationFieldModel extends Model {
  @hasMany('occupation-group')
  declare occupationGroups: AsyncHasMany<OccupationGroupModel>;

  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'occupation-field': OccupationFieldModel;
  }
}
