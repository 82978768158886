import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<p class=\"mb-0\">\n  {{t\n    \"components.trigger_nurture_form.header_text_with_suffix\"\n    headerText=this.headerText\n    htmlSafe=true\n  }}\n</p>\n\n<NurtureCampaign::Selector\n  @handleChange={{this.handleChange}}\n  @selected={{this.selectedNurtureCampaign}}\n/>\n\n<TriggerAdvancedOptions @model={{@model}} />", {"contents":"<p class=\"mb-0\">\n  {{t\n    \"components.trigger_nurture_form.header_text_with_suffix\"\n    headerText=this.headerText\n    htmlSafe=true\n  }}\n</p>\n\n<NurtureCampaign::Selector\n  @handleChange={{this.handleChange}}\n  @selected={{this.selectedNurtureCampaign}}\n/>\n\n<TriggerAdvancedOptions @model={{@model}} />","moduleName":"teamtailor/components/trigger-nurture-form.hbs","parseOptions":{"srcName":"teamtailor/components/trigger-nurture-form.hbs"}});
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';

export default class TriggerNurtureForm extends Component {
  @service intl;
  @service store;

  get selectedNurtureCampaign() {
    const nurtureCampaignId = get(this.args.model, 'nurtureCampaignId');
    return isPresent(nurtureCampaignId)
      ? this.store.findRecord('nurture-campaign', nurtureCampaignId)
      : null;
  }

  get headerText() {
    if (this.args.model.onReject) {
      return this.intl.t('mixins.trigger_form.header_text_on_reject', {
        stageName: get(this.args.model.stage, 'name'),
        htmlSafe: true,
      });
    } else {
      return this.intl.t('mixins.trigger_form.header_text_on_move', {
        stageName: get(this.args.model.stage, 'name'),
        htmlSafe: true,
      });
    }
  }

  @action
  handleChange(nurtureCampaign) {
    set(this.args.model, 'nurtureCampaignId', nurtureCampaign?.id);
  }
}
