import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import {
  CandidateModel,
  NurtureCampaignModel,
  NurtureCampaignStepEmailModel,
  PushPayloadArg,
} from 'teamtailor/models';
import Server from 'teamtailor/services/server';

export default class NurtureCampaignRecipientModel extends Model {
  @service declare server: Server;

  @belongsTo('candidate') declare candidate: AsyncBelongsTo<CandidateModel>;
  @belongsTo('nurture-campaign')
  declare nurtureCampaign: AsyncBelongsTo<NurtureCampaignModel>;

  @belongsTo('nurture-campaign-step/email')
  declare nextNurtureCampaignStep: AsyncBelongsTo<NurtureCampaignStepEmailModel>;

  @attr('date') declare createdAt: Date;
  @attr('date') declare nextStepAt: Date;
  @attr('string') declare captureType: string;
  @attr('string') declare exitReason: string;
  @attr('string') declare status: string;

  async triggerStatusChange({ event }: { event: string }) {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'trigger_status_change',
      method: 'PUT',
      options: {
        data: JSON.stringify({ event }),
      },
    });

    return this.store.pushPayload(response);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'nurture-campaign-recipient': NurtureCampaignRecipientModel;
  }
}
