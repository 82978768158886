import { modifier } from 'ember-modifier';

export default modifier((element, [action]) => {
  function setHoverState() {
    action(true);
  }

  function unsetHoverState() {
    action(false);
  }

  element.addEventListener('mouseenter', setHoverState);
  element.addEventListener('mouseleave', unsetHoverState);

  return () => {
    element.removeEventListener('mouseenter', setHoverState);
    element.removeEventListener('mouseleave', unsetHoverState);
  };
});
