import { helper } from '@ember/component/helper';
import fuzzysort from 'fuzzysort';

function sort([options, key, filter]: [
  ReadonlyArray<string | undefined>?,
  string?,
  string?
]) {
  if (!options || !key || !filter) {
    return options;
  }

  return fuzzysort
    .go(filter, options, {
      key,
      allowTypo: false,
      threshold: -10000,
    })
    .map((result) => result.obj);
}

export default helper(sort);
