import { AsyncBelongsTo } from '@ember-data/model';
import Controller from '@ember/controller';
import { action, set, setProperties } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import { query } from 'ember-data-resources';
import IntlService from 'ember-intl/services/intl';
import { CannedResponseModel, UserModel } from 'teamtailor/models';
import ExternalRecruiterRoute from 'teamtailor/routes/jobs/edit/external-recruiters';
import Current from 'teamtailor/services/current';
import FlipperService from 'teamtailor/services/flipper';
import { get } from 'teamtailor/utils/get';
import { ModelFrom } from 'teamtailor/utils/type-utils';

const NEW_RECRUITER_ROUTE = 'settings.external-recruitment.recruiters.new';
const NEW_RECRUITER_LINK_TEXT =
  'jobs.edit.external_recruiters.settings_link_text';

export default class JobsEditExternalRecruitersController extends Controller {
  declare model: ModelFrom<ExternalRecruiterRoute>;

  @service declare current: Current;
  @service declare flipper: FlipperService;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  @tracked cannedResponses: CannedResponseModel[] = [];
  @tracked jobUser?: UserModel | null;
  @tracked subjectInput?: HTMLInputElement | null = null;
  @tracked searchTerm = '';

  recruitingFirmsQuery = query(this, 'recruiting-firm', () => {
    return {
      query: this.searchTerm,
      per_page: 20,
    };
  });

  searchTask = restartableTask(async (searchTerm) => {
    searchTerm = typeof searchTerm === 'string' ? searchTerm : null;
    if (searchTerm !== this.searchTerm) {
      if (searchTerm) await timeout(500);
      this.searchTerm = searchTerm;
    }
  });

  get recruitingFirms() {
    const queried = this.recruitingFirmsQuery.records;
    return [
      ...new Set([...this.selectedRecruitingFirms, ...(queried || []).slice()]),
    ];
  }

  get signatureUser() {
    return this.jobUser ?? get(this.current, 'user');
  }

  get job() {
    return this.model.job;
  }

  get hasCustomInviteMessage() {
    return !!get(this.model, 'customExternalRecruiterInviteMessage');
  }

  set hasCustomInviteMessage(_value: boolean) {
    // noop. Core::Checkbox tries to modify this property when the property @checked is passed to arguments
  }

  get selectedRecruitingFirms() {
    const recruitingFirms = get(this.model, 'recruitingFirms');
    return recruitingFirms.filter((firm) =>
      get(firm, 'name').includes(this.searchTerm)
    );
  }

  get settingsLink() {
    const linkText = this.intl.t(NEW_RECRUITER_LINK_TEXT);
    const url = this.router.urlFor(NEW_RECRUITER_ROUTE);
    return `<a href="${url}">${linkText}</a>`;
  }

  get hasExternalRecruiterInvite() {
    return !!get(get(this.model, 'externalRecruiterInvite'), 'id');
  }

  get defaultInviteMessage() {
    return this.cannedResponses.findBy('externalRecruiterInvite');
  }

  get selectedInviteMessage() {
    if (this.hasExternalRecruiterInvite) {
      return get(this.model, 'externalRecruiterInvite');
    }

    return this.defaultInviteMessage;
  }

  get subject() {
    if (this.hasCustomInviteMessage) {
      return get(this.model, 'externalRecruiterInviteSubject') || '';
    }

    if (this.selectedInviteMessage) {
      return get(this.selectedInviteMessage, 'subject');
    }

    return '';
  }

  set subject(subject: string) {
    if (this.hasCustomInviteMessage) {
      set(this.model, 'externalRecruiterInviteSubject', subject);
    } else if (this.selectedInviteMessage) {
      set(this.selectedInviteMessage, 'subject', subject);
    }
  }

  get body() {
    const body = this.hasCustomInviteMessage
      ? get(this.model, 'externalRecruiterInviteBody')
      : get(this.selectedInviteMessage, 'body');

    return body || '';
  }

  @action
  setBody(body: string) {
    if (this.hasCustomInviteMessage) {
      set(this.model, 'externalRecruiterInviteBody', body);
    } else {
      set(this.model.externalRecruiterInvite, 'body', body);
    }
  }

  @action
  pickHiringTeamUser(user: UserModel) {
    get(this.job, 'teamMembers').pushObject(user);
  }

  @action
  dropHiringTeamUser(user: UserModel) {
    get(this.job, 'teamMembers').removeObject(user);
  }

  @action
  handleExternalRecruiterToggle(user: UserModel) {
    if (get(this.job, 'teamMembers').findBy('id', get(user, 'id'))) {
      get(this.job, 'teamMembers').removeObject(user);
    } else {
      get(this.job, 'teamMembers').pushObject(user);
    }
  }

  @action
  toggleCustomInviteMessage(value: boolean) {
    if (value) {
      setProperties(this.model, {
        externalRecruiterInviteSubject: get(
          this.selectedInviteMessage,
          'subject'
        ),

        externalRecruiterInviteBody: get(this.selectedInviteMessage, 'body'),
      });

      setTimeout(() => {
        this.subjectInput?.focus();
      }, 200);
    } else {
      setProperties(this.model, {
        externalRecruiterInviteSubject: null,
        externalRecruiterInviteBody: null,
      });
    }
  }

  @action
  changeTemplate(cannedResponse: AsyncBelongsTo<CannedResponseModel>) {
    this.model.externalRecruiterInvite = cannedResponse;
  }

  @action
  storeSubjectInput(element: HTMLInputElement) {
    this.subjectInput = element;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'jobs.edit.external-recruiters': JobsEditExternalRecruitersController;
  }
}
