import Service, { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import Server from 'teamtailor/services/server';

export default class TtZiggeo extends Service {
  @service declare server: Server;
  @service declare current: Current;

  async clientAuthToken(requestParams: string | undefined): Promise<string> {
    const requestUrl = `/app/companies/${this.current.company.uuid}/api/tt_ziggeos/client_auth_token?`;

    const response = await this.server.fetch(requestUrl + requestParams);
    return response.text();
  }

  async clientAuthTokenAnswer(answerId: string) {
    return this.clientAuthToken(`answer_ids=${answerId}`);
  }

  async clientAuthTokenDashboardWidget(dashboardWidgetId: string) {
    return this.clientAuthToken(`dashboard_widget_ids=${dashboardWidgetId}`);
  }
}

declare module '@ember/service' {
  interface Registry {
    'tt-ziggeo': TtZiggeo;
  }
}
