import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label\n  class={{class-names \"no-global-styles\" @wrapperClass}}\n  local-class=\"input\"\n  data-value={{@value}}\n>\n  <span class=\"sr-only\">{{@label}}</span>\n  <Textarea\n    ...attributes\n    class={{@inputClass}}\n    rows=\"1\"\n    maxlength={{@maxLength}}\n    @value={{@value}}\n    {{on \"keydown\" this.handleKeyDown}}\n  />\n</label>\n\n<ErrorMessages @errors={{@errors}} />", {"contents":"<label\n  class={{class-names \"no-global-styles\" @wrapperClass}}\n  local-class=\"input\"\n  data-value={{@value}}\n>\n  <span class=\"sr-only\">{{@label}}</span>\n  <Textarea\n    ...attributes\n    class={{@inputClass}}\n    rows=\"1\"\n    maxlength={{@maxLength}}\n    @value={{@value}}\n    {{on \"keydown\" this.handleKeyDown}}\n  />\n</label>\n\n<ErrorMessages @errors={{@errors}} />","moduleName":"teamtailor/components/multiline-input.hbs","parseOptions":{"srcName":"teamtailor/components/multiline-input.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { argDefault } from 'teamtailor/utils/arg-default';

type Args = {
  value?: string;
  allowEnter?: boolean;
  maxLength?: number;
};

export default class CoreInputComponent extends Component<Args> {
  @argDefault allowEnter = false;

  @action
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.allowEnter) {
      event.preventDefault();
    }
  }
}
