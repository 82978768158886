import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<CopilotButton\n  @loading={{this.isGeneratingCandidateResumeSummary}}\n  @size={{@size}}\n  @disabled={{or this.isGeneratingCandidateResumeSummary}}\n  @action={{perform this.generateCandidateResumeSummary}}\n  @text={{if\n    this.isGeneratingCandidateResumeSummary\n    (t\n      \"candidates.candidate.candidate_modal.recruiter_copilot.summarizing_resume\"\n    )\n    (t\n      \"candidates.candidate.candidate_modal.recruiter_copilot.summarize_resume\"\n    )\n  }}\n/>", {"contents":"<CopilotButton\n  @loading={{this.isGeneratingCandidateResumeSummary}}\n  @size={{@size}}\n  @disabled={{or this.isGeneratingCandidateResumeSummary}}\n  @action={{perform this.generateCandidateResumeSummary}}\n  @text={{if\n    this.isGeneratingCandidateResumeSummary\n    (t\n      \"candidates.candidate.candidate_modal.recruiter_copilot.summarizing_resume\"\n    )\n    (t\n      \"candidates.candidate.candidate_modal.recruiter_copilot.summarize_resume\"\n    )\n  }}\n/>","moduleName":"teamtailor/components/candidate-modal-new/main/section/resume/summarize-button.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-modal-new/main/section/resume/summarize-button.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';

import CandidateModel from 'teamtailor/models/candidate';
import CopilotService from 'teamtailor/services/copilot';

interface Args {
  candidate: CandidateModel;
}

export default class extends Component<Args> {
  @service declare copilot: CopilotService;

  generateCandidateResumeSummary = dropTask(async () => {
    this.args.candidate.resumeSummaryStatus = 'started'; // To show the spinner
    await this.copilot.generateCandidateResumeSummary(this.args.candidate.id);
  });

  get isGeneratingCandidateResumeSummary() {
    return this.args.candidate.resumeSummaryStatus === 'started';
  }
}
