import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import SessionService from 'ember-simple-auth/services/session';
import ENV from 'teamtailor/config/environment';
import IntlService from 'ember-intl/services/intl';
import Store from '@ember-data/store';
import { ServerError } from 'teamtailor/services/server';
import { STACKS, currentStack } from 'teamtailor/utils/stacks';
export default class Login extends Controller {
  @service declare session: SessionService;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare store: Store;

  @tracked email = '';
  @tracked password = '';
  @tracked declare totp?: string;
  @tracked declare token?: string;

  @tracked showTotpError = false;
  @tracked showRateLimitError = false;
  @tracked showVerificationTimeOverLimitError = false;
  @tracked showCodeChallengeLimitReachedError = false;

  enterpriseMode = ENV.TEAMTAILOR_ENTERPRISE_ENABLED;
  queryParams = ['company_uuid', 'password_updated', 'password_reset', 'token'];

  @tracked showTotpForm = false;
  @tracked untrustedDevice = false;

  get stacks() {
    const stacks = STACKS;
    if (!stacks.findBy('host', window.location.host)) {
      stacks.addObject({
        host: window.location.host,
        name: window.location.host,
      });
    }

    return stacks;
  }

  get currentStack() {
    return currentStack(this.stacks);
  }

  @action
  handlePickStack(stack: (typeof this.stacks)[number]) {
    document
      .querySelector('body')
      ?.classList.add('transition-opacity', 'duration-500', 'opacity-0');

    window.location.href = window.location.href.replace(
      window.location.host,
      stack.host
    );
  }

  findAndFocusInput = () => {
    // Add some slack for the form to render
    // Password managers like 1Password will autofill the form once it becomes focused.
    setTimeout(() => {
      const input =
        document.querySelector<HTMLInputElement>('input[name="totp"]');

      if (input) {
        input.focus();
      }
    }, 250);
  };

  codeChangeCallback = async (code: string, resetCallback: () => void) => {
    this.totp = code;
    this.showTotpError = false;

    if (code.length === 6) {
      try {
        await this.authenticate();
      } catch (error: any) {
        if (error instanceof ServerError) {
          const { code_challenge_limit_reached, verification_time_over_limit } =
            error.payload;

          if (code_challenge_limit_reached) {
            this.untrustedDevice = false;
            this.showCodeChallengeLimitReachedError = true;
          }

          if (verification_time_over_limit) {
            this.untrustedDevice = false;
            this.showVerificationTimeOverLimitError = true;
          }
        }

        this.showTotpError = true;
        resetCallback();
        this.findAndFocusInput();
      }
    }
  };

  authenticate = async (): Promise<void> => {
    const { email, password, totp, token, untrustedDevice } = this;
    this.resetLoginErrors();

    await this.session.authenticate('authenticator:token', {
      email,
      password,
      totp,
      token,
      untrustedDevice,
    });
  };

  @action
  async handleSubmit(event: SubmitEvent) {
    event.preventDefault();

    try {
      await this.authenticate();
    } catch (error: any) {
      if (error instanceof ServerError) {
        const { redirect_to_sso_url, totp_required, untrusted_device } =
          error.payload;

        if (redirect_to_sso_url) {
          window.location.replace(error.payload.redirect_to_sso_url);
        }

        if (totp_required) {
          this.showTotpForm = true;
        }

        if (untrusted_device) {
          this.untrustedDevice = true;
        }
      } else if (error.errors?.firstObject?.status === '429') {
        this.showRateLimitError = true;
      }
    }
  }

  resetLoginErrors = () => {
    this.showTotpError = false;
    this.showRateLimitError = false;
    this.showVerificationTimeOverLimitError = false;
    this.showCodeChallengeLimitReachedError = false;
  };

  @action
  totpDidInsert() {
    this.findAndFocusInput();
  }
}

declare module '@ember/controller' {
  interface Registry {
    login: Login;
  }
}
