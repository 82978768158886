import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import InterviewKitModel from 'teamtailor/models/interview-kit';
import PickedInterviewKitModel from 'teamtailor/models/picked-interview-kit';
import StageModel from 'teamtailor/models/stage';
import Job from 'teamtailor/models/job';
import ScorecardCriterium from 'teamtailor/models/scorecard-criterium';
import ScorecardPick from 'teamtailor/models/scorecard-pick';
import Current from 'teamtailor/services/current';
import PusherService from 'teamtailor/services/pusher';
import Server from 'teamtailor/services/server';
import Copilot from 'teamtailor/services/copilot';
import {
  COMPETENCE_WEIGHTS,
  CompetenceWeightsType,
} from 'teamtailor/constants/competence-weights';
import { next } from '@ember/runloop';

import IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import { dropTask, restartableTask, timeout } from 'ember-concurrency';
import { get } from 'teamtailor/utils/get';

export default class EvaluationController extends Controller {
  @service declare store: Store;
  @service declare current: Current;
  @service declare server: Server;
  @service declare intl: IntlService;
  @service declare pusher: PusherService;
  @service declare copilot: Copilot;

  @tracked declare searchProxy: string | undefined;

  @tracked query = '';
  @tracked page = 1;
  @tracked per_page = 10;

  get job(): Job {
    return this.model.job;
  }

  get jobDetail() {
    return this.job.jobDetail;
  }

  get jobStages(): StageModel[] {
    return this.model.job.stages || [];
  }

  get sortedPickedInterviewKits(): PickedInterviewKitModel[] {
    const stageIds = this.model.job.stages.map((s: StageModel) => s.id);

    return this.pickedInterviewKits.sort((a, b) => {
      const pickedInterviewKitStageA = get(get(a, 'stage'), 'id');
      const pickedInterviewKitStageB = get(get(b, 'stage'), 'id');
      return (
        stageIds.indexOf(pickedInterviewKitStageA) -
        stageIds.indexOf(pickedInterviewKitStageB)
      );
    });
  }

  get pickedInterviewKits(): PickedInterviewKitModel[] {
    return get(this.jobDetail, 'pickedInterviewKits').filter(
      (pickedInterviewKit: PickedInterviewKitModel) =>
        !get(pickedInterviewKit, 'isHidden')
    );
  }

  get jobScorecardPicksIds(): string[] {
    return this.jobScorecardPicks.mapBy('scorecardCriteriumId');
  }

  get allScorecardCriteriums(): ScorecardCriterium[] {
    return get(this.current.company, 'scorecardCriteria').toArray();
  }

  get pickedScorecardCriteriums(): ScorecardCriterium[] {
    return get(this.jobDetail, 'scorecardPicks').mapBy('scorecardCriterium');
  }

  get selectedSkillsAndTraits(): ScorecardCriterium[] {
    const scorecardCriteriumIds = this.jobScorecardPicks.mapBy(
      'scorecardCriterium.id'
    );

    return this.allScorecardCriteriums.filter(
      (scorecardCriterium: ScorecardCriterium) =>
        scorecardCriteriumIds.includes(scorecardCriterium.id)
    );
  }

  get jobScorecardPicks() {
    return get(this.jobDetail, 'scorecardPicks');
  }

  get showSearchBar(): boolean {
    return !!this.searchProxy && !!this.searchProxy.length;
  }

  getPickedInterviewKitById(id: string): PickedInterviewKitModel | undefined {
    const pickedInterviewKits = get(
      this.model.jobDetail,
      'pickedInterviewKits'
    );
    return pickedInterviewKits.findBy('interviewKit.id', id);
  }

  addInterviewKitToJob(interviewKit: InterviewKitModel): void {
    const pickedInterviewKit = this.store.createRecord('picked-interview-kit', {
      job: this.job,
      interviewKit,
      rowOrderPosition: 0,
    });

    get(this.jobDetail, 'pickedInterviewKits').pushObject(pickedInterviewKit);
  }

  removePickedInterviewKitFromJob(
    pickedInterviewKit: PickedInterviewKitModel,
    isArchivedInterviewKit: boolean
  ): void {
    if (isArchivedInterviewKit) {
      pickedInterviewKit.isHidden = true;
    } else {
      get(this.model.jobDetail, 'pickedInterviewKits').removeObject(
        pickedInterviewKit
      );
    }
  }

  handleSearchInput = restartableTask(async (value) => {
    await timeout(750);

    this.query = value?.length ? value : '';
  });

  copilotGenerateSkillsAndTraits = dropTask(async () => {
    const pickedSkillsTraits = this.pickedScorecardCriteriums.mapBy('name');
    const pickedSkillsTraitsIds = this.pickedScorecardCriteriums.mapBy('id');

    let skillTraitToolCalls = await this.copilot.generateSkillsAndTraits(
      this.job.id,
      get(this.jobDetail, 'languageCode'),
      [...pickedSkillsTraits]
    );

    // Even if we tell the AI which ones already picked/created, there's a chance it will return them again
    // So we filter out the ones that are already picked/created
    skillTraitToolCalls = skillTraitToolCalls.filter((call) => {
      if (
        (call.name === 'pick_skill' || call.name === 'pick_trait') &&
        call.arguments.id
      ) {
        return !pickedSkillsTraitsIds.includes(call.arguments.id.toString());
      } else if (call.arguments.name) {
        const createdName = call.arguments.name.replace(/-/g, ' ');

        return !pickedSkillsTraits.some((pickedSkillTrait) => {
          const existingName = pickedSkillTrait.replace(/-/g, ' ');
          return (
            existingName.localeCompare(createdName, undefined, {
              sensitivity: 'accent',
            }) === 0
          );
        });
      }
    });

    skillTraitToolCalls.forEach((call) => {
      let scorecardCriterium: ScorecardCriterium | undefined;

      if (call.arguments.id !== undefined) {
        scorecardCriterium = this.current.company.scorecardCriteria.findBy(
          'id',
          call.arguments.id.toString()
        );
      } else {
        scorecardCriterium = this.store.createRecord('scorecard-criterium', {
          name: call.arguments.name,
          topic: call.name === 'create_skill' ? 'skills' : 'personality',
          company: this.current.company,
          addedByCopilot: true,
        });
      }

      if (scorecardCriterium) {
        const scorecardPick = this.store.createRecord('scorecard-pick', {
          scorecardCriterium,
          jobDetail: this.jobDetail,
          weight: 0,
        });
        this.jobScorecardPicks.addObject(scorecardPick);

        next(() => {
          scorecardPick.weight =
            COMPETENCE_WEIGHTS[
              call.arguments.weight as keyof CompetenceWeightsType
            ] || COMPETENCE_WEIGHTS.medium;
        });
      }
    });
  });

  @action
  onInterviewKitAdded(interviewKitId: string): void {
    if (!this.isInterviewKitSelected(interviewKitId)) {
      const interviewKit = this.fetchInterviewKits.last?.value?.findBy(
        'id',
        interviewKitId
      );

      if (interviewKit) this.addInterviewKitToJob(interviewKit);
    }
  }

  @action
  onInterviewKitRemoved(pickedInterviewKitId: string): void {
    const pickedInterviewKit =
      this.getPickedInterviewKitById(pickedInterviewKitId);
    if (pickedInterviewKit) {
      const isArchivedInterviewKit = get(
        pickedInterviewKit.interviewKit,
        'isHidden'
      );
      this.removePickedInterviewKitFromJob(
        pickedInterviewKit,
        isArchivedInterviewKit
      );
    }
  }

  @action
  onStageSelectedForInterviewKit(
    pickedInterviewKit: PickedInterviewKitModel,
    stage: StageModel
  ): void {
    // @ts-expect-error It wants a PromiseObject here, but it's unnecessary
    pickedInterviewKit.stage = stage;
  }

  @action
  handleSelectSkillcardCriterium(scorecardCriterium: ScorecardCriterium) {
    const existingPick = this.jobScorecardPicks.find(
      (scorecardPick: ScorecardPick) => {
        const innerScorecardCriterium = get(
          scorecardPick,
          'scorecardCriterium'
        );
        return get(innerScorecardCriterium, 'id') === scorecardCriterium.id;
      }
    );

    if (existingPick) {
      this.jobScorecardPicks.removeObject(existingPick);
    } else {
      const scorecardPick = this.store.createRecord('scorecard-pick', {
        scorecardCriterium,
        jobDetail: this.jobDetail,
        weight: 0,
      });
      this.jobScorecardPicks.addObject(scorecardPick);

      next(() => {
        scorecardPick.weight = COMPETENCE_WEIGHTS.medium;
      });
    }
  }

  @action
  async handleDeleteScorecardPick(scorecardPick: ScorecardPick) {
    this.jobScorecardPicks.removeObject(scorecardPick);

    const scorecardCriterium = await get(scorecardPick, 'scorecardCriterium');

    if (scorecardCriterium && get(scorecardCriterium, 'isNew')) {
      scorecardCriterium.unloadRecord();
    }

    scorecardPick.unloadRecord();
  }

  fetchInterviewKits = dropTask(async (resetPage = false) => {
    // When a skill/trait is added its weight is initially set as 0, and then later set by the next() function
    // As such, we add this timeout to make sure the correct value is present at the time we access it
    await timeout(0);

    if (resetPage) this.page = 1;

    // Need to filter out Co-pilot ones since they have a null id (and also don't matter for the sorting criteria)
    const jobScorecardPicksWithoutCopilotOnes = this.jobScorecardPicks
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      .filter((jbp) => jbp.scorecardCriteriumId !== null);

    const scorecard_criterium_ids = jobScorecardPicksWithoutCopilotOnes.map(
      (jbp) => jbp.scorecardCriteriumId
    );
    const scorecard_criterium_weights = jobScorecardPicksWithoutCopilotOnes.map(
      (jbp) => (jbp.weight ? jbp.weight : COMPETENCE_WEIGHTS.medium)
    );

    const params = {
      scorecard_criterium_ids,
      scorecard_criterium_weights,
      query: this.query,
      page: this.page,
      per_page: this.per_page,
      dont_show_hidden: true,
    };

    const interviewKits = await this.store.query('interview-kit', params);

    return interviewKits;
  });

  get totalPages() {
    return this.fetchInterviewKits.last?.value?.meta?.total_pages;
  }

  get totalCountOfInterviewKits() {
    return this.fetchInterviewKits.last?.value?.meta?.total_count;
  }

  get companyHasInterviewKits() {
    return this.fetchInterviewKits.last?.value?.meta?.interview_kits_exist;
  }

  get jobScorecardPicksWeights() {
    return this.jobScorecardPicks.map((jbp) => jbp.weight);
  }

  isInterviewKitSelected = (id: string) => {
    return this.pickedInterviewKits.mapBy('interviewKit.id').includes(id);
  };

  @action
  setPage(page: number) {
    this.page = page;
  }

  @action
  didInsert() {
    this.fetchInterviewKits.perform();
  }
}

declare module '@ember/controller' {
  interface Registry {
    'jobs.edit.evaluation': EvaluationController;
  }
}
