import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import jsonToCsvFile from 'teamtailor/utils/json-to-csv-file';

export default class MobileController extends Controller {
  @service intl;
  @service analytics;

  @tracked sortDirection = 'desc';
  @tracked sortProperty = 'pageviews';

  get dataWithFallback() {
    return this.model.devices.value || [];
  }

  get sortedData() {
    return this.dataWithFallback.sort((a, b) => b.sessions - a.sessions);
  }

  get barChartData() {
    return this.sortedData.map((row) => ({
      ...row,
      label: row.name,
      value: row.sessions,
    }));
  }

  get total() {
    return this.barChartData?.reduce((acc, row) => acc + row.value, 0);
  }

  get sortedRows() {
    if (this.sortDirection === 'desc') {
      return this.dataWithFallback.sortBy(this.sortProperty).reverse();
    } else {
      return this.dataWithFallback.sortBy(this.sortProperty);
    }
  }

  @action
  handleExport() {
    jsonToCsvFile(
      'devices',
      this.analytics.startDate,
      this.analytics.endDate,
      this.dataWithFallback,
      (row) => ({
        deviceCategory: row.deviceCategory,
        applications: row.applications,
        pageviews: row.pageviews,
        sessions: row.sessions,
      })
    );
  }

  @action
  handleSort(sortProperty) {
    if (this.sortProperty === sortProperty) {
      const nextDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
      this.sortDirection = nextDirection;
    } else {
      this.sortDirection = 'desc';
      this.sortProperty = sortProperty;
    }
  }
}
