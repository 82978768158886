import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import CandidateModalRoute from 'teamtailor/routes/candidate-modal';
import { jsonToBase64 } from 'teamtailor/utils/base-64';

export default class IndexRoute extends CandidateModalRoute {
  @service user;

  async model(queryParams) {
    const user = get(this, 'user.content');
    const candidate = this.modelFor('candidates.candidate');

    let fromRoute = get(this, 'router.currentRouteName');
    if (fromRoute.startsWith('jobs.job.stages.')) {
      let jobId = await this.getJobContextId(user, candidate);
      if (jobId) {
        this.router.replaceWith(
          'jobs.job.stages.index.candidate',
          jobId,
          candidate.id,
          {
            queryParams,
          }
        );
        return {};
      }
    }

    return candidate;
  }

  afterModel(candidate, { queryParams }) {
    let fromRoute = get(this, 'router.currentRouteName');
    const q =
      queryParams && Object.keys(queryParams).length
        ? jsonToBase64(queryParams)
        : null;

    if (fromRoute.indexOf('candidates.segment.') === 0) {
      this.router.replaceWith(
        'candidates.segment.candidate',
        'all',
        candidate.id,
        {
          queryParams: {
            q,
          },
        }
      );
    }

    this.recentVisits.log(candidate);
  }

  async getJobContextId(user, candidate) {
    const jobApplications = await get(candidate, 'jobApplications');
    const candidateJobIds = jobApplications
      .filterBy('isNotRejected')
      .mapBy('jobId');

    if (get(user, 'admin')) {
      return candidateJobIds.length === 1 ? candidateJobIds[0] : null;
    }

    const jobId = await this.getPossibleUserJobMatch(user, candidateJobIds);
    return jobId;
  }

  async getPossibleUserJobMatch(user, candidateJobIds) {
    const userJobs = await get(user, 'teamMemberJobs');
    const userJobIds = userJobs.map((job) => parseInt(job.id, 10));
    const matches = candidateJobIds.filter((id) => userJobIds.includes(id));

    return matches.length === 1 ? matches[0] : null;
  }
}
