import {
  AsyncBelongsTo,
  attr,
  belongsTo,
  hasMany,
  SyncHasMany,
} from '@ember-data/model';
import {
  PartnerActivationModel,
  StageModel,
  TriggerModel,
} from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import { triggerLabel } from 'teamtailor/utils/trigger-text';
import MappedPartnerCriteriumModel from '../mapped-partner-criterium';

export type AssessmentCriteria = Record<'id' | 'label', string>;

export default class TriggerWebhookModel extends TriggerModel<'webhook'> {
  @belongsTo('partner-activation')
  declare partnerActivation: AsyncBelongsTo<PartnerActivationModel>;

  @hasMany('mapped-partner-criterium', { async: false })
  declare mappedPartnerCriteria: SyncHasMany<MappedPartnerCriteriumModel>;

  @belongsTo('stage', {
    inverse: 'proceedStageTriggerWebhooks',
  })
  declare proceedStage: AsyncBelongsTo<StageModel>;

  @attr('string', { defaultValue: 'above' })
  declare proceedConditionType: string;

  @attr('string') declare proceedValue: string;
  @attr('raw', {
    defaultValue() {
      return {};
    },
  })
  declare webhookData: unknown;

  @attr('raw', {
    defaultValue() {
      return [];
    },
  })
  declare webhookPartnerCriteria: AssessmentCriteria[];

  @attr('raw', {
    defaultValue() {
      return { fields: [] };
    },
  })
  declare webhookConfig: unknown;

  get valid() {
    return true;
  }

  get partner() {
    return get(get(this, 'partnerActivation'), 'partner');
  }

  get description() {
    return get(get(this, 'partnerActivation'), 'partnerName');
  }

  get label() {
    const webhookNoun = get(get(this, 'partner'), 'webhookNoun') || 'webhook';
    const webhookVerb = get(get(this, 'partner'), 'webhookVerb') || 'Send';

    return triggerLabel(
      get(this, 'intl').t.bind(get(this, 'intl')),
      get(this, 'type'),
      { webhookNoun, webhookVerb }
    );
  }

  get logotypeSquare() {
    return get(get(this, 'partner'), 'logotypeSquare');
  }

  get triggerContent() {
    return get(get(this, 'partner'), 'name');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'trigger/webhook': TriggerWebhookModel;
  }
}
