import Model, { attr, belongsTo } from '@ember-data/model';
import PickedVideoModel from './picked-video';
import ConnectSettingModel from './connect-setting';
import PickedImageModel from './picked-image';
export default class ConnectMediaItemModel extends Model {
  @attr('string') declare caption: string;
  @attr('string') declare mediaType: string;
  @attr('string') declare mediaUrl: string;
  @attr('string') declare videoType: string;
  @attr('number') declare rowOrder: number;
  @attr('number') declare rowOrderPosition: number;

  @belongsTo('connect-setting', { async: false })
  declare connectSetting?: ConnectSettingModel;

  @belongsTo('picked-video', { async: false })
  declare pickedVideo?: PickedVideoModel;

  @belongsTo('picked-image', { async: false })
  declare pickedImage?: PickedImageModel;

  get isGif() {
    return this.mediaType === 'gif';
  }

  get isImage() {
    return this.mediaType === 'image';
  }

  get isVideo() {
    return this.mediaType === 'video';
  }

  get externalVideoId() {
    if (this.isVideo && this.mediaUrl) {
      return this.mediaUrl;
    }
  }

  get itemType() {
    return this.videoType;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'connect-media-item': ConnectMediaItemModel;
  }
}
