import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { bool, alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { get, action } from '@ember/object';
import { task, timeout } from 'ember-concurrency';

@classic
export default class CompaniesController extends Controller {
  @service session;
  @service externalIntegrationOauth;
  @service router;

  secondsToForcedLogout = 30;

  @alias('model')
  companies;

  @bool('companies.length')
  hasCompanies;

  get route() {
    const data = this.externalIntegrationOauth.hasValidOauthData();
    return data ? 'marketplace.partners.oauth_partner' : 'dashboard';
  }

  @action
  handleCompanies(company) {
    this.router.transitionTo(this.route, company.uuid);
  }

  runTimerTask = task(
    {
      restartable: true,
    },
    async () => {
      while (get(this, 'secondsToForcedLogout')) {
        // eslint-disable-next-line no-await-in-loop
        await timeout(1000);
        this.decrementProperty('secondsToForcedLogout');
      }

      this.logout();
    }
  );

  @action
  logout() {
    get(this, 'session').invalidate();
  }
}
