import ApplicationAdapter from './application';

export default class SharedReportAdapter extends ApplicationAdapter {
  namespace = 'app/api';
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'shared-report': SharedReportAdapter;
  }
}
