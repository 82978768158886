import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import AssetMapService from 'teamtailor/services/asset-map';

export default class ConnectedConferenceModel extends Model {
  @service declare assetMap: AssetMapService;

  @attr('boolean') declare isConnected: boolean;
  @attr('boolean') declare isIncluded: boolean;
  @attr('string') declare appName: string;
  @attr('string') declare profileId: string;
  @attr('string') declare profileName: string;
  @attr('string') declare profileRelinkUrl: string;
  @attr('string') declare providerName: string;

  get image() {
    return this.assetMap.resolve(`assets/images/${this.providerName}.svg`);
  }

  get providerIcon() {
    switch (this.providerName) {
      case 'Google Meet':
        return 'google-meet';
      case 'ms_teams':
        return 'ms-teams';
      case 'zoom':
        return 'zoom';
      case 'go_to':
        return 'go-to';
      case 'teamtailor':
      default:
        return 'teamtailor';
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'connected-conference': ConnectedConferenceModel;
  }
}
