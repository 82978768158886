import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Button\n  {{on \"click\" (fn (mut this.isAdding) true)}}\n  @icon=\"tag\"\n  @appearance=\"secondary\"\n  @isActive={{this.isTagged}}\n  @tooltip={{if\n    this.isTagged\n    (t \"components.add_job_tag.edit_tags\")\n    (t \"components.add_job_tag.add_tags\")\n  }}\n/>\n\n<Core::Modal\n  @isOpen={{this.isAdding}}\n  @onClose={{fn (mut this.isAdding) false}}\n  @size=\"small\"\n  as |modal|\n>\n  <modal.Header @text={{t \"components.add_job_tag.job_tags\"}} />\n  <modal.Main as |main|>\n    <main.Section>\n      <Core::TagPicker\n        @onSelect={{this.changeTags}}\n        @selected={{this.model.tags}}\n        @tagLimit={{5}}\n        @taggableType=\"Job\"\n      />\n    </main.Section>\n  </modal.Main>\n</Core::Modal>", {"contents":"<Button\n  {{on \"click\" (fn (mut this.isAdding) true)}}\n  @icon=\"tag\"\n  @appearance=\"secondary\"\n  @isActive={{this.isTagged}}\n  @tooltip={{if\n    this.isTagged\n    (t \"components.add_job_tag.edit_tags\")\n    (t \"components.add_job_tag.add_tags\")\n  }}\n/>\n\n<Core::Modal\n  @isOpen={{this.isAdding}}\n  @onClose={{fn (mut this.isAdding) false}}\n  @size=\"small\"\n  as |modal|\n>\n  <modal.Header @text={{t \"components.add_job_tag.job_tags\"}} />\n  <modal.Main as |main|>\n    <main.Section>\n      <Core::TagPicker\n        @onSelect={{this.changeTags}}\n        @selected={{this.model.tags}}\n        @tagLimit={{5}}\n        @taggableType=\"Job\"\n      />\n    </main.Section>\n  </modal.Main>\n</Core::Modal>","moduleName":"teamtailor/components/add-job-tag.hbs","parseOptions":{"srcName":"teamtailor/components/add-job-tag.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import { isEmpty } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

export default class AddJobTagModalComponent extends Component {
  @tracked isAdding = false;

  get model() {
    return this.args.model;
  }

  get hasTags() {
    return !isEmpty(get(this.model, 'tags'));
  }

  get isTagged() {
    return get(this.model, 'jobDetail.isTagged') || this.hasTags;
  }

  @action
  changeTags(tag) {
    toggleInList(get(this.model, 'tags'), tag);
  }
}
