import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Table::Container\n  @columns={{this.columns}}\n  @isEmpty={{is-empty @jobs}}\n  @isLoading={{this.isLoading}}\n  ...attributes\n  as |table|\n>\n  {{#each @jobs as |job|}}\n    <table.row @id={{job.id}} as |row|>\n      <row.column>\n        {{job.id}}\n      </row.column>\n      <row.column>\n        {{job.title}}\n      </row.column>\n      <row.column>\n        {{job.location}}\n      </row.column>\n      <row.column>\n        Role\n      </row.column>\n    </table.row>\n  {{/each}}\n\n  <table.emptyState>\n    <EmptyState\n      @emoji=\"👩🏾‍💻\"\n      @header={{t \"jobs.index.empty_state.no_matching_jobs\"}}\n      @color=\"zinc\"\n    >\n      {{t \"jobs.index.empty_state.no_matching_jobs_description\"}}\n    </EmptyState>\n  </table.emptyState>\n  <table.loading @columns={{this.loadingColumns}} @repeat={{6}} />\n</Table::Container>", {"contents":"<Table::Container\n  @columns={{this.columns}}\n  @isEmpty={{is-empty @jobs}}\n  @isLoading={{this.isLoading}}\n  ...attributes\n  as |table|\n>\n  {{#each @jobs as |job|}}\n    <table.row @id={{job.id}} as |row|>\n      <row.column>\n        {{job.id}}\n      </row.column>\n      <row.column>\n        {{job.title}}\n      </row.column>\n      <row.column>\n        {{job.location}}\n      </row.column>\n      <row.column>\n        Role\n      </row.column>\n    </table.row>\n  {{/each}}\n\n  <table.emptyState>\n    <EmptyState\n      @emoji=\"👩🏾‍💻\"\n      @header={{t \"jobs.index.empty_state.no_matching_jobs\"}}\n      @color=\"zinc\"\n    >\n      {{t \"jobs.index.empty_state.no_matching_jobs_description\"}}\n    </EmptyState>\n  </table.emptyState>\n  <table.loading @columns={{this.loadingColumns}} @repeat={{6}} />\n</Table::Container>","moduleName":"teamtailor/components/unicorn/components/table.hbs","parseOptions":{"srcName":"teamtailor/components/unicorn/components/table.hbs"}});
import Component from '@glimmer/component';

const LOADING_COLUMNS = [
  {
    width: '2xl',
  },
  {
    width: 'xl',
  },
  {
    width: 'm',
    contentType: 'core/badge',
  },
  {
    width: 'm',
  },
];

export default class UnicornTable extends Component {
  loadingColumns = LOADING_COLUMNS;

  get columns() {
    return [
      {
        label: 'Id',
      },
      {
        label: 'Job title',
      },
      {
        label: 'Location',
      },
      {
        label: 'Role',
      },
    ];
  }
}
