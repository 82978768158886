import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class TriggerFormBase extends Component {
  @service store;
  @service intl;

  get model() {
    return this.args.model;
  }

  get headerText() {
    const translationKey = get(this, 'model.onReject')
      ? 'mixins.trigger_form.header_text_on_reject'
      : 'mixins.trigger_form.header_text_on_move';

    return this.intl.t(translationKey, {
      stageName: get(this, 'model.stage.name'),
      htmlSafe: true,
    });
  }

  get uploads() {
    return get(this, 'model.uploads');
  }

  @action
  removeUpload(upload) {
    this.store.deleteRecord(upload);
  }

  @action
  handleFileUploaded(temporaryFileUrl, fileFileName) {
    let upload = get(this, 'store').createRecord('upload', {
      temporaryFileUrl,
      fileFileName,
    });
    this.uploads.pushObject(upload);
  }
}
