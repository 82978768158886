import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span class=\"font-emoji\">\n  {{this.emojiWithFallback}}\n</span>", {"contents":"<span class=\"font-emoji\">\n  {{this.emojiWithFallback}}\n</span>","moduleName":"teamtailor/components/tt-emoji.hbs","parseOptions":{"srcName":"teamtailor/components/tt-emoji.hbs"}});
import Component from '@glimmer/component';
import { argDefault } from 'teamtailor/utils/arg-default';
import twemoji from 'twemoji';
import { emojiSupported } from 'teamtailor/constants/emojisupported';
import { htmlSafe } from '@ember/template';

interface TtEmojiComponentArgs {
  emoji?: string;
}

export default class TtEmojiComponent extends Component<TtEmojiComponentArgs> {
  @argDefault emoji = '🥝';

  get emojiWithFallback() {
    const { emoji } = this.args;
    if (emojiSupported) {
      return emoji;
    } else {
      return htmlSafe(
        twemoji.parse(this.emoji, {
          size: 72,
          className:
            'emoji inline-block w-[1em] h-[1em] align-[-0.1em] my-0 mr-[0.05em] ml-[0.1em]',
        })
      );
    }
  }
}
