import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import Job from 'teamtailor/models/job';
import Candidate from 'teamtailor/models/candidate';

export default class MeetingEventStatusModel extends Model {
  @belongsTo('job')
  declare job?: AsyncBelongsTo<Job>;

  @belongsTo('candidate')
  declare candidate: AsyncBelongsTo<Candidate>;

  @attr('string')
  declare status: string;

  @attr('date')
  declare bookedSlotStartsAt?: Date;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'meeting-event-status': MeetingEventStatusModel;
  }
}
