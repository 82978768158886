import { tracked } from '@glimmer/tracking';
import {
  Attendee,
  IAttendeeUser,
  IAttendeeCandidate,
  IAttendeeMeetingRoom,
} from 'teamtailor/components/meeting/types';
import { AttendeeStatusEnum, IAttendeeRecord } from './attendee';
import {
  CandidateAttendeeClass,
  IAttendeeCandidateRecord,
} from './candidate-attendee';
import {
  IAttendeeMeetingRoomRecord,
  MeetingRoomAttendeeClass,
} from './meeting-room-attendee';
import { IAttendeeUserRecord, UserAttendeeClass } from './user-attendee';

export type AttendeeAttributes =
  | IAttendeeUserRecord
  | IAttendeeCandidateRecord
  | IAttendeeMeetingRoomRecord
  | IAttendeeRecord;

export type AttendeeType = InstanceType<
  | typeof UserAttendeeClass
  | typeof CandidateAttendeeClass
  | typeof MeetingRoomAttendeeClass
>;

export class AttendeeListClass {
  @tracked private declare items: AttendeeType[];
  @tracked private readonly originalItems: AttendeeType[];

  constructor(items: AttendeeType[] | undefined) {
    this.items = items ?? [];
    this.originalItems = items ?? [];
  }

  static isAttendeeUser(attendee: Attendee): attendee is IAttendeeUser {
    return Boolean((attendee as IAttendeeUser).user);
  }

  static isAttendeeCandidate(
    attendee: Attendee
  ): attendee is IAttendeeCandidate {
    return Boolean((attendee as IAttendeeCandidate).candidate);
  }

  static isAttendeeMeetingRoom(
    attendee: Attendee
  ): attendee is IAttendeeMeetingRoom {
    return Boolean((attendee as IAttendeeMeetingRoom).meetingRoom);
  }

  private static createAttendee(fromObject: Attendee): AttendeeType {
    if (this.isAttendeeUser(fromObject)) {
      return new UserAttendeeClass(fromObject);
    }

    if (this.isAttendeeCandidate(fromObject)) {
      return new CandidateAttendeeClass(fromObject);
    }

    if (this.isAttendeeMeetingRoom(fromObject)) {
      return new MeetingRoomAttendeeClass(fromObject);
    }

    throw new Error();
  }

  static from(fromObject: Attendee[]): AttendeeListClass {
    const list = fromObject.map((attendee: Attendee) => {
      return this.createAttendee(attendee);
    });

    return new AttendeeListClass(list);
  }

  toggle(attendee: AttendeeType): void {
    if (this.hasAttendee(attendee)) {
      if (
        !AttendeeListClass.isAttendeeUser(attendee) ||
        this.userAttendees.length > 1
      ) {
        this.remove(attendee);
      }
    } else {
      this.add(attendee);
    }
  }

  toggleHasInterview(attendee: AttendeeType): void {
    (attendee as UserAttendeeClass).hasInterview = !(
      attendee as UserAttendeeClass
    ).hasInterview;
  }

  setAllHasInterview(hasInterview: boolean): void {
    this.userAttendees.forEach((userAttendee: UserAttendeeClass) => {
      userAttendee.hasInterview = hasInterview;
    });
  }

  hasAttendee(attendee: AttendeeType): boolean {
    return this.items.some((item) => item.isAttendee(attendee));
  }

  /**
   * Adds an attendee of type T to the evet attendee list
   */
  add(attendee: AttendeeType): void {
    if (!this.hasAttendee(attendee)) {
      if (AttendeeListClass.isAttendeeUser(attendee)) {
        attendee.isOrganizer = false;
      }

      const originalAttendee = this.originalItems.find((oa) =>
        oa.isAttendee(attendee)
      );

      // Can this be moved to a separate class perhaps?
      if (originalAttendee) {
        attendee.id = originalAttendee.id; // preserve id from old.. nothing more
      }

      this.items = [...this.items, attendee];
    }
  }

  remove(attendee: AttendeeType): void {
    const items = this.items.filter((item) => !item.isAttendee(attendee));
    this.items = [...items];
  }

  /**
   * Clears attendees by attendee type
   */
  clear(attendeeType: AttendeeType[]): void {
    attendeeType.forEach((type) => this.remove(type));
  }

  /**
   * # UserAttendeeClass
   * Returns a **tracked** and **unmutable** list of attendee users
   */
  get userAttendees(): UserAttendeeClass[] {
    return this.items.filter(
      (type: AttendeeType): type is UserAttendeeClass => {
        return type instanceof UserAttendeeClass;
      }
    );
  }

  get userAttendeesWithCalendar(): UserAttendeeClass[] {
    return this.userAttendees.filter(
      (attendee) => attendee.hasConnectedCalendar
    );
  }

  get userAttendeesWithoutCalendar(): UserAttendeeClass[] {
    return this.userAttendees.filter(
      (attendee) => !attendee.hasConnectedCalendar
    );
  }

  get candidateAttendees(): CandidateAttendeeClass[] {
    return this.items.filter(
      (type: AttendeeType): type is CandidateAttendeeClass => {
        return type instanceof CandidateAttendeeClass;
      }
    );
  }

  get meetingRoomAttendees(): MeetingRoomAttendeeClass[] | undefined {
    return this.items.filter(
      (type: AttendeeType): type is MeetingRoomAttendeeClass => {
        return type instanceof MeetingRoomAttendeeClass;
      }
    );
  }

  get candidates() {
    return this.candidateAttendees.map(
      (candidateAttendee) => candidateAttendee.candidate
    );
  }

  get users() {
    return this.userAttendees.map((userAttendee) => userAttendee.user);
  }

  get meetingRooms() {
    return this.meetingRoomAttendees?.map(
      (meetingRoomAttendee) => meetingRoomAttendee.meetingRoom
    );
  }

  get count(): number {
    return this.items.length;
  }

  get candidatesMissingEmailCount(): number {
    return this.candidateAttendees.filter((c) => c.isMissingEmail).length;
  }

  get candidateAttendeesWithEmail(): CandidateAttendeeClass[] {
    return this.candidateAttendees.filter((c) => !c.isMissingEmail);
  }

  get bookedMeetingRoom(): MeetingRoomAttendeeClass | undefined {
    return this.meetingRoomAttendees?.find(
      (m) => m.status === AttendeeStatusEnum.ACCEPTED
    );
  }

  hasChanges(): boolean {
    return this.originalItems !== this.items;
  }

  toAttributes(): AttendeeAttributes[] {
    const removedAttendees = this.originalItems.filter(
      (attendee) => attendee.id && !this.hasAttendee(attendee)
    );

    let items = [];
    if (this.candidates.length > 1) {
      items = [
        ...this.userAttendees,
        ...this.candidateAttendeesWithEmail,
        ...this.meetingRoomAttendees!,
      ];
    } else {
      items = [
        ...this.userAttendees,
        ...this.candidateAttendees,
        ...this.meetingRoomAttendees!,
      ];
    }

    return [
      ...items.map((attendee) => attendee.toAttributes()),
      ...removedAttendees.map(({ id }) => ({ id, _destroy: true })),
    ];
  }
}
