export enum CompositionStatus {
  NA = 'na',
  STARTED = 'started',
  AVAILABLE = 'available',
  FAILED = 'failed',
}

export enum TranscriptionStatus {
  NA = 'na',
  PROGRESS = 'progress',
  TRANSCRIBED = 'transcribed',
  AVAILABLE = 'available',
  FAILED = 'failed',
}
