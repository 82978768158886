import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"common.role\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"label\"\n    @options={{this.availableRoles}}\n    @searchEnabled={{true}}\n    @isDisabled={{@isDisabled}}\n    @onSelect={{@onChange}}\n    @selected={{if\n      @hasChangedValue\n      (find-by \"id\" @selected this.availableRoles)\n    }}\n    @nullOption={{t \"search.all\"}}\n    @placeholder={{if @placeholder @placeholder (t \"search.all\")}}\n    @searchPlaceholder={{t \"search.role.search_role\"}}\n  />\n</label>", {"contents":"<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"common.role\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"label\"\n    @options={{this.availableRoles}}\n    @searchEnabled={{true}}\n    @isDisabled={{@isDisabled}}\n    @onSelect={{@onChange}}\n    @selected={{if\n      @hasChangedValue\n      (find-by \"id\" @selected this.availableRoles)\n    }}\n    @nullOption={{t \"search.all\"}}\n    @placeholder={{if @placeholder @placeholder (t \"search.all\")}}\n    @searchPlaceholder={{t \"search.role.search_role\"}}\n  />\n</label>","moduleName":"teamtailor/components/sidebar/filters/role.hbs","parseOptions":{"srcName":"teamtailor/components/sidebar/filters/role.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { ROLES } from 'teamtailor/constants/roles';
import IntlService from 'ember-intl/services/intl';

type SidebarFiltersRoleArgs = {
  excludedRoles?: string[];
};

export default class extends Component<SidebarFiltersRoleArgs> {
  @service declare intl: IntlService;

  get excludedRoles() {
    return this.args.excludedRoles || [];
  }

  get availableRoles() {
    return ROLES.filter((role) => !this.excludedRoles.includes(role.id)).map(
      (roleObj) => {
        const { label, id } = roleObj;
        return {
          label: this.intl.t(label),
          id,
        };
      }
    );
  }
}
