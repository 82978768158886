import { set } from '@ember/object';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class ConnectRoute extends ScrollToTopRoute {
  @service current;
  @service flipper;
  @service router;

  beforeModel() {
    if (get(this.flipper, 'connect_content')) {
      this.router.transitionTo('settings.general');
    }
  }

  model() {
    return get(this.current.company, 'careerSites');
  }

  resetController(controller) {
    get(controller, 'careerSites').forEach((t) => {
      t.rollbackAttributes();
    });

    set(controller, 'selectedTabIndex', 0);
  }
}
