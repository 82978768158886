import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import AnalyticsService from 'teamtailor/services/analytics';

export default class extends Route {
  @service declare store: Store;
  @service declare analytics: AnalyticsService;

  async model() {
    const jobs = await this.store.query('job', {
      recruiter_or_team_member: true,
      groupAnalytics: this.analytics.hasEnabledGroupAnalytics,
    });

    return {
      myJobs: jobs
        .toArray()
        .filter((job) => job.isPublished)
        .slice(0, 3),
    };
  }
}
