import Model, { attr } from '@ember-data/model';
import Raw from 'teamtailor/transforms/raw';

export default class IndustryModel extends Model {
  @attr('string') declare description: string;

  @attr('raw') declare groups: Raw;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    industry: IndustryModel;
  }
}
