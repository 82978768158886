import ApplicationAdapter from 'teamtailor/adapters/application';

export default class JobteaserPaymentTypes extends ApplicationAdapter {
  pathForType() {
    return 'promotion_categories/jobteaser_payment_types';
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'promotion-categories/jobteaser-payment-type': JobteaserPaymentTypes;
  }
}
