import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import QRCodeStyling from 'qr-code-styling';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import EmployeesProfileSecurityActivate2faRoute from 'teamtailor/routes/employees/profile/security/activate-2fa';

export default class EmployeesProfileSecurityActivate2fa extends Controller {
  declare model: ModelFrom<EmployeesProfileSecurityActivate2faRoute>;
  @service declare router: RouterService;

  @tracked activationError = false;

  get twoFactorActivated() {
    return this.model.login?.twoFactorActivated;
  }

  @action
  applyQrCodeToCanvas(element: HTMLElement): void {
    new QRCodeStyling({
      width: 192,
      height: 192,
      type: 'svg',
      data: this.model.login?.twoFactorProvisioningUri,
      margin: 0,
    }).append(element);
  }

  @action
  async handleCodeChange(
    code: string,
    resetCallback: (refocus?: boolean) => void
  ): Promise<void> {
    this.activationError = false;

    if (code.length === 6) {
      try {
        await this.model
          .activateTwoFactorAuthentication({
            totp: code,
          })
          .then(() => {
            resetCallback();
          });
      } catch (err) {
        this.activationError = true;
        resetCallback(true);
      }
    }
  }

  @action
  findAndFocus2fa(): void {
    // Delay the visual cue
    setTimeout(() => {
      document.querySelector<HTMLInputElement>('input[name="totp"]')?.focus();
    }, 500);
  }

  @action
  handleClose() {
    this.router.transitionTo('employees.profile.security');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'employees.profile.security.activate-2fa': EmployeesProfileSecurityActivate2fa;
  }
}
