import Controller from '@ember/controller';
import { action } from '@ember/object';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';

export default class extends Controller {
  @alias('model') careerSite;

  @action
  save() {
    this.careerSite.save();
  }
}
