import TriggerSerializer from 'teamtailor/serializers/trigger';
import { camelize, decamelize } from '@ember/string';

const TODO_ATTRIBUTES_WHITE_LIST = [
  'value',
  'description',
  'daysFromNow',
  'assigneeId',
  'due',
];

const camelizeKeys = (attrs) => {
  const camelized = {};
  Object.keys(attrs).forEach((underscoreKey) => {
    camelized[camelize(underscoreKey)] = attrs[underscoreKey];
  });
  return camelized;
};

export default TriggerSerializer.extend({
  attrs: {
    stage: { serialize: 'ids' },
  },

  serialize() {
    let [commonJson, data] = this.extractCommonJson(this._super(...arguments));

    const todos = data.todos.map((todo) => {
      return TODO_ATTRIBUTES_WHITE_LIST.reduce((attrs, camelizedKey) => {
        attrs[decamelize(camelizedKey)] = todo[camelizedKey];
        return attrs;
      }, {});
    });

    return Object.assign(commonJson, {
      data: {
        ...data,
        todos,
      },
    });
  },

  normalize() {
    let hash = this._super(...arguments);

    if (hash.data.attributes.todos) {
      hash.data.attributes.todos = hash.data.attributes.todos.map((attrs) => {
        const todo = camelizeKeys(attrs);
        todo.relativeDueDays = todo.daysFromNow;
        return todo;
      });
    }

    return hash;
  },
});
