import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class SettingsTagsDeleteRoute extends Route {
  @service store;

  async model(params) {
    const tag = await this.store.findRecord('tag', params.id);
    await get(tag, 'taggingCount');
    return tag;
  }
}
