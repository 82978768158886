import Component from '@glimmer/component';
import { set, setProperties, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { v1 as uuid } from 'ember-uuid';
import { A as a } from '@ember/array';

export default class ItemComponent extends Component {
  @service router;
  @service iframePreview;
  @service store;
  @service filthyContent;

  get isNewItem() {
    return !this.args.model.id;
  }

  @action
  save() {
    const item = this.args.model;
    // eslint-disable-next-line prefer-destructuring
    const section = this.args.section;
    const sectionPath = 'content.editor.section.index';

    if (this.isNewItem) {
      set(item, 'id', uuid().split('-')[0]);

      if (section.items == null) {
        section.items = a();
      }

      section.items.pushObject(item);
    } else {
      section.items
        .removeObject(section.items.findBy('id', item.id))
        .pushObject(item);
    }

    return section.save().then(
      () => {
        this.router.transitionTo(sectionPath);
      },
      () => {
        this.isAddClicked = false;
      }
    );
  }

  @action
  handleRemoveImage() {
    let { section } = this.args;

    set(this, 'args.model.pickedImage', null);

    if (this.isNewItem) {
      setProperties(this.args.model, {
        destroyImageId: this.args.model.imageId,
        imageId: null,
      });
    } else {
      const item = section.items.findBy('id', this.args.model.id);
      const { imageId } = section.items.findBy('id', this.args.model.id);

      setProperties(this.args.model, {
        destroyImageId: imageId,
        imageId: null,
      });

      setProperties(item, {
        destroyImageId: imageId,
        imageId: null,
      });
    }

    this.filthyContent.setFilthy(section.id);
  }

  @action
  handlePickImage(image) {
    let { section } = this.args;
    let pickedImage = this.store.createRecord('picked-image', {
      section_id: section.id,
      image,
    });

    set(this, 'args.model.pickedImage', pickedImage);

    pickedImage.save().then((image) => {
      set(this, 'args.model.imageId', image.id);

      if (!this.isNewItem) {
        set(
          section.items.findBy('id', this.args.model.id),
          'imageId',
          image.id
        );
      }

      this.filthyContent.setFilthy(section.id);
    });
  }

  @action
  handlePickVideo(video) {
    let { section } = this.args;
    let pickedVideo = this.store.createRecord('picked-video', {
      section,
      video,
    });

    set(this, 'args.model.pickedVideo', pickedVideo);

    pickedVideo.save().then((video) => {
      set(this, 'args.model.videoId', video.id);

      if (!this.isNewItem) {
        set(
          section.items.findBy('id', this.args.model.id),
          'videoId',
          video.id
        );
      }

      this.filthyContent.setFilthy(section.id);
    });
  }

  @action
  handleRemoveVideo() {
    let { section } = this.args;

    set(this, 'args.model.pickedVideo', null);

    if (this.isNewItem) {
      setProperties(this.args.model, {
        destroyVideoId: this.args.model.videoId,
        videoId: null,
      });
    } else {
      const item = section.items.findBy('id', this.args.model.id);
      const { videoId } = section.items.findBy('id', this.args.model.id);

      setProperties(this.args.model.id, {
        destroyVideoId: videoId,
        videoId: null,
      });

      setProperties(item, {
        destroyVideoId: videoId,
        videoId: null,
      });
    }

    this.filthyContent.setFilthy(section.id);
  }

  @action
  makeDirty(section) {
    this.filthyContent.setFilthy(section.id);
  }
}
