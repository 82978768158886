import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<h3 class=\"heading-5 mb-8 mt-12\">\n  {{t \"components.search_filters.partner_result.status\"}}\n</h3>\n<Core::Select\n  @optionName=\"intl\"\n  @options={{this.statuses}}\n  @onSelect={{this.onStatusChange}}\n  @placeholder={{t \"components.search_filters.partner_result.search_status\"}}\n  @selected={{find-by \"id\" @partner_results_status this.statuses}}\n  @isFullWidth={{true}}\n  @nullOption={{t \"core.select.none\"}}\n/>\n\n{{#if this.showAssessmentScoresRange}}\n  <div>\n    <h3 class=\"heading-5 mb-8 mt-12\">\n      {{t \"components.search_filters.partner_result.assessment_score\"}}\n    </h3>\n    <RangeSlider\n      @value={{this.value}}\n      @min={{this.min}}\n      @max={{this.max}}\n      @isRange={{true}}\n      @onChange={{this.onRangeChange}}\n    />\n  </div>\n{{/if}}", {"contents":"<h3 class=\"heading-5 mb-8 mt-12\">\n  {{t \"components.search_filters.partner_result.status\"}}\n</h3>\n<Core::Select\n  @optionName=\"intl\"\n  @options={{this.statuses}}\n  @onSelect={{this.onStatusChange}}\n  @placeholder={{t \"components.search_filters.partner_result.search_status\"}}\n  @selected={{find-by \"id\" @partner_results_status this.statuses}}\n  @isFullWidth={{true}}\n  @nullOption={{t \"core.select.none\"}}\n/>\n\n{{#if this.showAssessmentScoresRange}}\n  <div>\n    <h3 class=\"heading-5 mb-8 mt-12\">\n      {{t \"components.search_filters.partner_result.assessment_score\"}}\n    </h3>\n    <RangeSlider\n      @value={{this.value}}\n      @min={{this.min}}\n      @max={{this.max}}\n      @isRange={{true}}\n      @onChange={{this.onRangeChange}}\n    />\n  </div>\n{{/if}}","moduleName":"teamtailor/components/candidates/advanced-partner-result-filters.hbs","parseOptions":{"srcName":"teamtailor/components/candidates/advanced-partner-result-filters.hbs"}});
import { action, get } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { STATUSES } from 'teamtailor/constants/partner-results';

export default class CandidatesAdvancedPartnerResultFiltersComponent extends Component {
  @service intl;
  @tracked partnerResultsMetadata;
  @tracked partnerResultMax;
  @tracked partnerResultMin;
  @tracked partnerResultStatus;

  min = 0;
  max = 100;

  get value() {
    return [this.min, this.max];
  }

  get params() {
    return {
      partner_results_min_score: this.partnerResultMin,
      partner_results_max_score: this.partnerResultMax,
      partner_results_status: this.partnerResultStatus,
    };
  }

  get statuses() {
    const translatedStatus = (status) => ({
      id: status,
      intl: this.intl.t(`components.search_filters.partner_result.${status}`),
    });

    return STATUSES.map(translatedStatus);
  }

  get useRange() {
    return this.min !== this.max;
  }

  get showAssessmentScoresRange() {
    return get(this, 'args.partner.capabilityAssessments');
  }

  @action onStatusChange(status) {
    this.partnerResultStatus = status?.id;
    this.args.onPartnerFilterChange(this.params);
  }

  @action
  onRangeChange(value) {
    const [min, max] = Array.isArray(value) ? value : [value, value];
    this.partnerResultMin = min;
    this.partnerResultMax = max;

    this.args.onPartnerFilterChange(this.params);
  }
}
