import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Atoms::Card\n  ...attributes\n  class=\"flex-row p-0\"\n  @loading={{@loading}}\n  @disableHover={{is-empty @stages}}\n  {{hover-state (fn (mut this.hovering))}}\n>\n  <div local-class=\"chart\">\n    <div class=\"mb-20 flex justify-between\">\n      <h4>{{t \"insights.molecules.activity_breakdown.title\"}}</h4>\n\n      {{#if (not (is-empty @stages))}}\n        <Insights::Atoms::ExpandModalButton\n          @onShowExportModal={{@onShowExportModal}}\n          @visible={{this.hovering}}\n        />\n      {{/if}}\n    </div>\n\n    <div class=\"mb-20 flex-1\">\n      <Insights::Charts::StackedBarChart\n        @categories={{this.chartCategories}}\n        @data={{@stages}}\n      />\n    </div>\n  </div>\n</Insights::Atoms::Card>", {"contents":"<Insights::Atoms::Card\n  ...attributes\n  class=\"flex-row p-0\"\n  @loading={{@loading}}\n  @disableHover={{is-empty @stages}}\n  {{hover-state (fn (mut this.hovering))}}\n>\n  <div local-class=\"chart\">\n    <div class=\"mb-20 flex justify-between\">\n      <h4>{{t \"insights.molecules.activity_breakdown.title\"}}</h4>\n\n      {{#if (not (is-empty @stages))}}\n        <Insights::Atoms::ExpandModalButton\n          @onShowExportModal={{@onShowExportModal}}\n          @visible={{this.hovering}}\n        />\n      {{/if}}\n    </div>\n\n    <div class=\"mb-20 flex-1\">\n      <Insights::Charts::StackedBarChart\n        @categories={{this.chartCategories}}\n        @data={{@stages}}\n      />\n    </div>\n  </div>\n</Insights::Atoms::Card>","moduleName":"teamtailor/components/insights/molecules/activity-breakdown.hbs","parseOptions":{"srcName":"teamtailor/components/insights/molecules/activity-breakdown.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class InsightsMoleculesActivityBreakdown extends Component {
  @service intl;

  get chartCategories() {
    return [this.intl.t('insights.molecules.activity_breakdown.times')];
  }
}
