import Model, { attr } from '@ember-data/model';

export default class CreditTransactionModel extends Model {
  @attr('number') declare credits: number;
  @attr('string') declare code: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'credit-transaction': CreditTransactionModel;
  }
}
