import Editor, { Action } from '../editor';

import * as icons from '../icons';

interface ToolData {
  level?: number;
  style?: string;
}

export default class TurnInto {
  editor: Editor;

  constructor(editor: Editor) {
    this.editor = editor;
  }

  async getActions(): Promise<Action[]> {
    return [
      {
        id: 'turn-into',
        icon: icons.arrowsRetweet,
        actions: await this.buildActions(),
      },
    ];
  }

  async buildActions(): Promise<Action[]> {
    const block = await this.editor.currentBlock?.save();
    const tool = block?.tool;
    const level = block?.data?.level;
    const style = block?.data?.style;

    const actions = [];

    if (tool !== 'paragraph') {
      actions.push({
        id: 'text',
        icon: icons.text,
        onClick: this.turnInto('paragraph'),
      });
    }

    if (tool !== 'quote') {
      actions.push({
        id: 'quote',
        icon: icons.quote,
        onClick: this.turnInto('quote'),
      });
    }

    if (style !== 'unordered') {
      actions.push({
        id: 'list-unordered',
        icon: icons.bulletedList,
        onClick: this.turnInto('list', { style: 'unordered' }),
      });
    }

    if (style !== 'ordered') {
      actions.push({
        id: 'list-ordered',
        icon: icons.numberedList,
        onClick: this.turnInto('list', { style: 'ordered' }),
      });
    }

    if (level !== 2) {
      actions.push({
        id: 'heading-2',
        icon: icons.heading2,
        onClick: this.turnInto('heading', { level: 2 }),
      });
    }

    if (level !== 3) {
      actions.push({
        id: 'heading-3',
        icon: icons.heading3,
        onClick: this.turnInto('heading', { level: 3 }),
      });
    }

    return actions;
  }

  turnInto(type: string, data: ToolData = {}) {
    return async () => {
      const block = await this.editor.currentBlock?.save();
      if (block) {
        const index = this.editor.blocks.getCurrentBlockIndex();
        this.editor.blocks.delete(index);

        const currentItems = block.data.items;
        const currentText = block.data.text;

        let text: string | undefined, items: string | undefined;

        if (type === 'list') {
          items = currentText ? currentText.split('<br>') : currentItems;
        } else {
          text = currentText || currentItems?.join('<br>');
        }

        this.editor.blocks.insert(
          type,
          { ...data, text, items },
          undefined,
          index
        );
      }

      this.editor.toolbar.close();
    };
  }
}
