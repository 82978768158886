import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class ContentEditorFooterController extends Controller {
  get careerSite() {
    return this.model;
  }

  @action
  save() {
    return this.careerSite.updateCompanyInfo();
  }
}
