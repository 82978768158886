import { getProperties } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default function filterList(filterAttributes, listItems, search) {
  if (isEmpty(search)) {
    return listItems;
  } else {
    search = search.toLowerCase();
    return listItems.filter((item) => {
      return (
        Object.values(getProperties(item, filterAttributes))
          .flat()
          .join(' ')
          .toLowerCase()
          .indexOf(search) > -1
      );
    });
  }
}
