import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.current.company.employeeDashboard.isFulfilled}}\n  <div local-class=\"root\">\n    <UserImage\n      class=\"mr-12\"\n      @user={{this.current.company.manager}}\n      @size=\"48\"\n    />\n    <div local-class=\"wrapper\">\n      <p local-class=\"text\">\n        {{~#if this.current.company.employeeDashboard.introMessage~}}\n          {{this.current.company.employeeDashboard.introMessage}}\n        {{~else~}}\n          {{t \"employee.lets_work_together\"}}\n          {{t \"employee.make_referral_description\"}}\n        {{~/if~}}\n      </p>\n\n      {{#if this.user.admin}}\n        <Button\n          local-class=\"settings\"\n          @onClick={{this.handleSettingsClick}}\n          @appearance=\"tertiary\"\n          @icon=\"gear\"\n          @tooltip={{t \"common.settings\"}}\n        />\n      {{/if}}\n    </div>\n  </div>\n{{/if}}", {"contents":"{{#if this.current.company.employeeDashboard.isFulfilled}}\n  <div local-class=\"root\">\n    <UserImage\n      class=\"mr-12\"\n      @user={{this.current.company.manager}}\n      @size=\"48\"\n    />\n    <div local-class=\"wrapper\">\n      <p local-class=\"text\">\n        {{~#if this.current.company.employeeDashboard.introMessage~}}\n          {{this.current.company.employeeDashboard.introMessage}}\n        {{~else~}}\n          {{t \"employee.lets_work_together\"}}\n          {{t \"employee.make_referral_description\"}}\n        {{~/if~}}\n      </p>\n\n      {{#if this.user.admin}}\n        <Button\n          local-class=\"settings\"\n          @onClick={{this.handleSettingsClick}}\n          @appearance=\"tertiary\"\n          @icon=\"gear\"\n          @tooltip={{t \"common.settings\"}}\n        />\n      {{/if}}\n    </div>\n  </div>\n{{/if}}","moduleName":"teamtailor/components/dashboard/intro-message.hbs","parseOptions":{"srcName":"teamtailor/components/dashboard/intro-message.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class DashboardIntroMessageComponent extends Component {
  @service current;
  @service router;

  get user() {
    return this.current.user;
  }

  @action
  handleSettingsClick() {
    this.router.transitionTo('employee.dashboard-widget.intro-message');
  }
}
