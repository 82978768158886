import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<p class=\"mb-0\">\n  {{t \"components.trigger_predicion_form.automatically_move_candidate\"}}\n</p>\n\n<Core::Form::Field\n  @label={{t\n    \"components.trigger_predicion_form.move_from_inbox_to\"\n    htmlSafe=true\n  }}\n  as |field|\n>\n  <field.Select\n    @size=\"large\"\n    @isFullWidth={{true}}\n    @placeholder={{t \"search.stage.select_stage\"}}\n    @selected={{if this.trigger.proceedStage.id this.trigger.proceedStage}}\n    @options={{this.stageOptions}}\n    @optionName=\"name\"\n    @onSelect={{this.handleSetProceedStage}}\n    @optionCompareField=\"id\"\n    as |Item option|\n  >\n    <Item\n      @dot={{stage-type-color option.stageType.category}}\n      @dotVariant=\"weak\"\n    />\n  </field.Select>\n</Core::Form::Field>", {"contents":"<p class=\"mb-0\">\n  {{t \"components.trigger_predicion_form.automatically_move_candidate\"}}\n</p>\n\n<Core::Form::Field\n  @label={{t\n    \"components.trigger_predicion_form.move_from_inbox_to\"\n    htmlSafe=true\n  }}\n  as |field|\n>\n  <field.Select\n    @size=\"large\"\n    @isFullWidth={{true}}\n    @placeholder={{t \"search.stage.select_stage\"}}\n    @selected={{if this.trigger.proceedStage.id this.trigger.proceedStage}}\n    @options={{this.stageOptions}}\n    @optionName=\"name\"\n    @onSelect={{this.handleSetProceedStage}}\n    @optionCompareField=\"id\"\n    as |Item option|\n  >\n    <Item\n      @dot={{stage-type-color option.stageType.category}}\n      @dotVariant=\"weak\"\n    />\n  </field.Select>\n</Core::Form::Field>","moduleName":"teamtailor/components/trigger-prediction-form.hbs","parseOptions":{"srcName":"teamtailor/components/trigger-prediction-form.hbs"}});
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import JobModel from 'teamtailor/models/job';
import StageModel from 'teamtailor/models/stage';
import TriggerPredictionModel from 'teamtailor/models/trigger/prediction';
import { get } from 'teamtailor/utils/get';

type TriggerPredictionFormArgs = {
  model: TriggerPredictionModel;
  job: JobModel;
};

export default class TriggerPredictionForm extends Component<TriggerPredictionFormArgs> {
  get trigger() {
    return this.args.model;
  }

  get job() {
    return this.args.job;
  }

  get stage() {
    return this.trigger.stage;
  }

  get stageOptions() {
    const jobStages = get(this.job, 'stages');

    return jobStages.filter((stage: StageModel) => {
      return get(stage, 'id') && get(stage, 'id') !== get(this.stage, 'id');
    });
  }

  @action
  handleSetProceedStage(stage: StageModel) {
    set(this.trigger, 'proceedStage', stage);
  }
}
