/**
 * based on https://codepen.io/familjenpersson/pen/bQeEBX
 * scroll smoothly to a element in the DOM.
 * @param elem element to scroll to
 * @param offset int offset pixels from element (default is 0)
 * @returns promise that gets resolved when scrolling is complete
 */

type TargetTop = number;

type Options = { signal?: AbortSignal } & ScrollOptions;

export function scrollToPromise(
  ...args: [TargetTop, Options?] | [HTMLElement, TargetTop, Options?]
): Promise<void> {
  let elemOrWindow: HTMLElement | Window = window;

  let optionsArg: Options | undefined;

  let targetTop: TargetTop = 0;
  if (typeof args[0] === 'number') {
    targetTop = args[0];
    optionsArg = args[1] as Options;
  } else {
    elemOrWindow = args[0];
    targetTop = args[1] as TargetTop;
    optionsArg = args[2];
  }

  const { signal, behavior } = Object.assign(
    {
      behavior: 'smooth',
    },
    optionsArg
  );

  targetTop = Math.min(Math.max(0, targetTop), getMaxScrollOffset());

  function getMaxScrollOffset() {
    const elem =
      elemOrWindow instanceof HTMLElement
        ? elemOrWindow
        : document.documentElement;

    return elem.scrollHeight - elem.offsetHeight;
  }

  function getScrollOffset() {
    return elemOrWindow instanceof HTMLElement
      ? elemOrWindow.scrollTop
      : elemOrWindow.scrollY;
  }

  return new Promise<void>((resolve) => {
    let failed: ReturnType<typeof setTimeout>;

    if (signal?.aborted) {
      return;
    }

    if (typeof targetTop === 'number' && getScrollOffset() !== targetTop) {
      elemOrWindow.scrollTo({
        top: targetTop,
        behavior,
      });
      failed = setTimeout(() => {
        elemOrWindow.removeEventListener('scroll', scrollHandler);
        resolve();
        // eslint-disable-next-line no-console
        console.error('scrollToPromise timed out');
      }, 2000);

      signal?.addEventListener('abort', () => {
        clearTimeout(failed);
      });

      const scrollHandler = () => {
        if (getScrollOffset() === targetTop) {
          elemOrWindow.removeEventListener('scroll', scrollHandler);
          clearTimeout(failed);
          resolve();
        }
      };

      if (getScrollOffset() === targetTop) {
        clearTimeout(failed);
        resolve();
      } else {
        elemOrWindow.addEventListener('scroll', scrollHandler);
      }
    } else {
      resolve();
    }
  });
}
