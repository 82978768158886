import Model, { attr } from '@ember-data/model';

export default class DataRequestSettingModel extends Model {
  @attr declare allowChanges: boolean;
  @attr declare availableTables: string[];
  @attr declare tables: string[];
  @attr declare messageSubject: string;
  @attr declare messageBody: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'data-request-setting': DataRequestSettingModel;
  }
}
