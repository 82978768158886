import Controller from '@ember/controller';
import jsonToCsvFile from 'teamtailor/utils/json-to-csv-file';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { MeetingEvent } from 'teamtailor/classes/analytics/report-meetings';
import AnalyticsService from 'teamtailor/services/analytics';
import { MeetingStatsTypeResponse } from 'teamtailor/utils/insights/graphql-response-types';

export default class AnalyticsOverviewMeetingsController extends Controller {
  @service declare analytics: AnalyticsService;

  // Gets set in model's setupController hook:
  calendarEvents: MeetingEvent[] = [];

  get meetingStats(): MeetingStatsTypeResponse | undefined {
    return this.model.meetingStats.value;
  }

  get durationPerInterval() {
    if (!this.meetingStats) {
      return [];
    }

    return this.meetingStats.durationPerInterval.map((interval) => {
      return {
        ...interval,
        count: interval.count / 3600, // seconds to hours
      };
    });
  }

  @action
  handleExport() {
    jsonToCsvFile(
      'team-activity',
      this.analytics.startDate,
      this.analytics.endDate,
      this.calendarEvents,
      (row: MeetingEvent) => ({
        starts_at: row.bookedSlot.startsAt,
        ends_at: row.bookedSlot.endsAt,
        summary: row.summary,
        candidate: row.candidateName,
        job: row.meeting.job?.title,
        location:
          row.meetingEventLocation?.location?.nameOrCity ||
          row.meetingEventLocation?.videoMeeting?.providerName,

        number_of_participants: row.numberOfUserParticipants,
        duration_in_mins: row.durationInMinutes,
      })
    );
  }
}

declare module '@ember/controller' {
  interface Registry {
    'analytics.overview.meetings': AnalyticsOverviewMeetingsController;
  }
}
