import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router';
import Store from '@ember-data/store';
import IntlService from 'ember-intl/services/intl';
import { dropTask } from 'ember-concurrency';

import JobModel from 'teamtailor/models/job';
import FlashMessageService from 'teamtailor/services/flash-message';
import { get } from 'teamtailor/utils/get';
import Copilot from 'teamtailor/services/copilot';
import OrderedListHelper from 'teamtailor/utils/competence/ordered-list-helpers';

export default class JobsEditEvaluationInterviewKitController extends Controller {
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare copilot: Copilot;

  queryParams = ['id'];

  id = null as string | null;

  get interviewKit() {
    return this.model.interviewKit;
  }

  get job(): JobModel {
    return this.model.job;
  }

  @action
  handleCancel(): void {
    this.copilot.destroyInterviewKitQuestions();
    this.router.transitionTo('jobs.edit.evaluation');
  }

  handleSave = dropTask(async () => {
    await this.copilot.saveQuestions();
    await this.copilot.saveScorecardCriteria();
    OrderedListHelper.updateOrderedCompetenceIds(
      this.interviewKit.competenceOrder
    );

    this.interviewKit.competenceOrder = [...this.interviewKit.competenceOrder];

    await this.interviewKit.save();

    if (!this.id) {
      const pickedInterviewKit = this.store.createRecord(
        'picked-interview-kit',
        {
          job: this.model.job,
          interviewKit: this.interviewKit,
          rowOrderPosition: 0,
        }
      );

      get(this.job.jobDetail, 'pickedInterviewKits').pushObject(
        pickedInterviewKit
      );
    }

    this.flashMessages.success(
      this.intl.t(
        this.id
          ? 'components.interview_kit_form.interview_kit_has_been_updated'
          : 'components.interview_kit_form.interview_kit_has_been_created'
      )
    );

    this.router.transitionTo('jobs.edit.evaluation');
    this.model.actions.refetchInterviewKits(true);
  });
}

declare module '@ember/controller' {
  interface Registry {
    'jobs.edit.evaluation.interview-kit': JobsEditEvaluationInterviewKitController;
  }
}
