import flatten from 'teamtailor/utils/flatten';
import { pluralize } from 'ember-inflector';
import { get } from 'teamtailor/utils/get';
import { camelize } from '@ember/string';

export default function recordRemover(store, ...records) {
  flatten(records).forEach((modelName) => {
    store
      .peekAll(modelName)
      .filter(Boolean)
      .filterBy('id', null)
      .forEach((model) => {
        model.deleteRecord();
        store.unloadRecord(model);
      });
  });
}

export function relationsRecordRemover(model, ...records) {
  flatten(records).forEach((relationModelName) => {
    const relationName = pluralize(camelize(relationModelName));
    const relation = get(model, relationName);

    if (relation && relation.length) {
      relation
        .slice()
        .filter((model) => {
          return model && !get(model, 'id');
        })
        .forEach((model) => {
          relation.removeObject(model);
          model.unloadRecord();
        });
    }
  });
}
