import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div data-test-employee-role-selector class=\"flex flex-col gap-8\">\n  {{#each-in this.roles as |role addons|}}\n    <div\n      class=\"overflow-hidden rounded-6 border border-action-medium shadow-button\"\n    >\n      <Employees::RoleSelectorInput\n        @label={{t (concat \"user.\" role)}}\n        @subTitle={{t (concat \"employees.\" role \"_description\")}}\n        @value={{role}}\n        @groupValue={{@groupValue}}\n        @onChange={{@onChange}}\n        @addons={{addons}}\n        @selectedAddons={{@selectedAddons}}\n        @disabled={{@isDisabled}}\n      />\n    </div>\n  {{/each-in}}\n</div>", {"contents":"<div data-test-employee-role-selector class=\"flex flex-col gap-8\">\n  {{#each-in this.roles as |role addons|}}\n    <div\n      class=\"overflow-hidden rounded-6 border border-action-medium shadow-button\"\n    >\n      <Employees::RoleSelectorInput\n        @label={{t (concat \"user.\" role)}}\n        @subTitle={{t (concat \"employees.\" role \"_description\")}}\n        @value={{role}}\n        @groupValue={{@groupValue}}\n        @onChange={{@onChange}}\n        @addons={{addons}}\n        @selectedAddons={{@selectedAddons}}\n        @disabled={{@isDisabled}}\n      />\n    </div>\n  {{/each-in}}\n</div>","moduleName":"teamtailor/components/employees/multi-role-selector.hbs","parseOptions":{"srcName":"teamtailor/components/employees/multi-role-selector.hbs"}});
import Component from '@glimmer/component';

export default class EmployeesMultiRoleSelector extends Component {
  get roles() {
    const addOnRoles = ['analytics', 'designer'];

    return {
      no_access: [],
      user: addOnRoles,
      hiring_manager: addOnRoles,
      recruiter: addOnRoles,
      recruitment_admin: addOnRoles,
      admin: [],
    };
  }
}
