import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"common.status\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"label\"\n    @options={{this.options}}\n    @onSelect={{@onChange}}\n    @searchEnabled={{false}}\n    @selected={{find-by \"id\" @selected this.options}}\n    @nullOption={{t \"search.all\"}}\n  />\n</label>", {"contents":"<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"common.status\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"label\"\n    @options={{this.options}}\n    @onSelect={{@onChange}}\n    @searchEnabled={{false}}\n    @selected={{find-by \"id\" @selected this.options}}\n    @nullOption={{t \"search.all\"}}\n  />\n</label>","moduleName":"teamtailor/components/sidebar/filters/requisition-types.hbs","parseOptions":{"srcName":"teamtailor/components/sidebar/filters/requisition-types.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { capitalize } from '@ember/string';
interface OptionsArgs {
  id: string | undefined;
  label: string | undefined;
}

export default class extends Component {
  @service declare intl: IntlService;

  get options(): OptionsArgs[] {
    const statuses = [
      'approved',
      'pending',
      'rejected',
      'completed',
      'draft',
      'archived',
    ];

    return statuses.map((status) => ({
      id: status,
      label: capitalize(this.intl.t(`job.requisitions_options.${status}`)),
    }));
  }
}
