import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{did-update this.onUpdateIframeSrc @iframeSrc}}\n\n<iframe\n  {{did-insert (set this \"el\")}}\n  src={{this.initialIframeSrc}}\n  class={{concat \"careersite-preview careersite-preview--\" @iframeType}}\n  style={{@iframeStyle}}\n  title={{t \"content.editor.preview\"}}\n></iframe>\n\n{{#if @isOnEditablePage}}\n  <IframePreview::SectionHoverMenu @allBlockLayouts={{@allBlockLayouts}} />\n{{/if}}", {"contents":"{{did-update this.onUpdateIframeSrc @iframeSrc}}\n\n<iframe\n  {{did-insert (set this \"el\")}}\n  src={{this.initialIframeSrc}}\n  class={{concat \"careersite-preview careersite-preview--\" @iframeType}}\n  style={{@iframeStyle}}\n  title={{t \"content.editor.preview\"}}\n></iframe>\n\n{{#if @isOnEditablePage}}\n  <IframePreview::SectionHoverMenu @allBlockLayouts={{@allBlockLayouts}} />\n{{/if}}","moduleName":"teamtailor/components/iframe-preview.hbs","parseOptions":{"srcName":"teamtailor/components/iframe-preview.hbs"}});
import Store from '@ember-data/store';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import IframePreviewService from 'teamtailor/services/iframe-preview';
import { inject as controller } from '@ember/controller';
import { CareerSiteModel } from 'teamtailor/models';
import { afterRender, aniMate, setStyles } from 'ember-ani-mate';
import ContentEditorController from 'teamtailor/controllers/content/editor';

interface IframePreviewComponentArgs {
  iframeSrc: string;
  iframeType: 'styleguide' | 'page';
  iframeStyle?: string;
  careerSite?: CareerSiteModel;
  editorActionsEl?: HTMLElement;
  listWrapperEl?: HTMLElement;
  emptyStateEl?: HTMLElement;
  editorHeaderEl?: HTMLElement;
  departmentId?: string;
  roleId?: string;
}

const EDITOR_PARAMS = [
  'editor',
  'role_id',
  'department_id',
  'job_id',
  'refresh',
];

const removeNonEditorQueryParams = (searchParams: URLSearchParams) => {
  const keysToDelete: string[] = [];
  searchParams.forEach((_value, key) => {
    if (!EDITOR_PARAMS.includes(key)) {
      keysToDelete.push(key);
    }
  });

  keysToDelete.forEach((key) => searchParams.delete(key));
};

export default class IframePreviewComponent extends Component<IframePreviewComponentArgs> {
  @service declare iframePreview: IframePreviewService;
  @service declare router: RouterService;
  @service declare store: Store;

  declare el: HTMLIFrameElement;

  constructor(owner: unknown, args: IframePreviewComponentArgs) {
    super(owner, args);
    this.initialIframeSrc = args.iframeSrc;
    this.currentIframeSrc = this.initialIframeSrc;

    window.addEventListener('message', this.handleIframeMessage);
  }

  willDestroy() {
    super.willDestroy();
    window.removeEventListener('message', this.handleIframeMessage);
  }

  declare currentIframeSrc: string;

  initialIframeSrc?: string;

  @controller('content.editor')
  declare contenEditorController: ContentEditorController;

  @action onUpdateIframeSrc() {
    const iframeSrcArgURL = new URL(this.args.iframeSrc);
    const currentIframeURL = new URL(this.currentIframeSrc);

    iframeSrcArgURL.searchParams.sort();
    currentIframeURL.searchParams.sort();

    removeNonEditorQueryParams(iframeSrcArgURL.searchParams);
    removeNonEditorQueryParams(currentIframeURL.searchParams);

    if (iframeSrcArgURL.toString() !== currentIframeURL.toString()) {
      this.el.contentWindow?.location.replace(this.args.iframeSrc);
      this.currentIframeSrc = this.args.iframeSrc;
    }
  }

  handleIframeMessage = async (
    event: MessageEvent<
      | {
          message: 'iframeLoaded';
        }
      | {
          message: 'navigateEvent';
          destinationUrl: string;
        }
      | {
          message: 'pageIdChanged';
          pageId: string;
          currentUrl: string;
        }
      | {
          message: 'transitionToRoute';
          routePath: string;
          sectionId: string;
        }
      | {
          message: 'click';
          clientX: number;
          clientY: number;
          pointerType?: string;
        }
    >
  ) => {
    switch (event.data.message) {
      case 'iframeLoaded':
        this.iframePreview.iframeLoaded();
        break;
      case 'transitionToRoute': {
        const { routePath, sectionId } = event.data;
        if (this.args.iframeType !== 'styleguide') {
          this.router.transitionTo(routePath, sectionId);
        }

        break;
      }

      case 'navigateEvent': {
        this.currentIframeSrc = event.data.destinationUrl;
        break;
      }

      case 'pageIdChanged': {
        if (this.args.iframeType === 'styleguide') {
          return;
        }

        const contentEditorRoute = this.router.currentRoute.find((item) => {
          return item.name === 'content.editor';
        });

        const page = event.data.pageId
          ? await this.store.findRecord('page', event.data.pageId)
          : null;

        const newIsEditable = !!page;
        const oldIsEditable =
          !this.router.currentRoute.queryParams.overrideIframeSrc;

        const fromEditable = !newIsEditable && oldIsEditable;
        const toEditable = newIsEditable && !oldIsEditable;

        if (
          event.data.pageId &&
          contentEditorRoute?.params.type === 'pages' &&
          contentEditorRoute.params.type_id === event.data.pageId &&
          !toEditable
        ) {
          // dont do anything if the pageId hasnt changed unless going from non-editable to editable
          return;
        }

        const { currentUrl } = event.data;

        const goToNewRoute = () => {
          if (page) {
            return this.router.replaceWith(
              'content.editor.index',
              'pages',
              page.id,
              {
                queryParams: {
                  ...this.router.currentRoute.queryParams,
                  overrideIframeSrc: null,
                },
              }
            );
          } else {
            return this.router.replaceWith(this.router.currentRouteName, {
              queryParams: {
                ...this.router.currentRoute.queryParams,
                overrideIframeSrc: currentUrl,
              },
            });
          }
        };

        if ((!fromEditable && !toEditable) || !document.startViewTransition) {
          goToNewRoute();
        } else {
          const unsetStyles: ReturnType<typeof setStyles>[] = [];

          const setViewTranstionNames = () => {
            unsetStyles.push(
              setStyles(document.documentElement, {
                '--view-transition-name-root': 'none',
              })
            );

            if (this.args.editorHeaderEl) {
              unsetStyles.push(
                setStyles(this.args.editorHeaderEl, {
                  viewTransitionName: 'editorHeader',
                }),
                setStyles(
                  '::view-transition-old(editorHeader),::view-transition-new(editorHeader)',
                  {
                    animationName: 'none',
                  }
                )
              );
            }

            if (this.args.editorActionsEl) {
              unsetStyles.push(
                setStyles(this.args.editorActionsEl, {
                  viewTransitionName: 'editorActions',
                }),
                setStyles(
                  '::view-transition-old(editorActions),::view-transition-new(editorActions)',
                  {
                    animationName: 'none',
                  }
                )
              );
            }

            if (this.args.listWrapperEl) {
              unsetStyles.push(
                setStyles(this.args.listWrapperEl, {
                  viewTransitionName: 'listWrapper',
                })
              );
            }

            if (this.args.emptyStateEl) {
              unsetStyles.push(
                setStyles(this.args.emptyStateEl, {
                  viewTransitionName: 'emptyState',
                })
              );
            }
          };

          setViewTranstionNames();

          const transition = document.startViewTransition(async () => {
            await goToNewRoute();
            await afterRender();

            setViewTranstionNames();
          });

          await transition.ready;

          let editorActions: ['to' | 'from', 'old' | 'new'] | undefined,
            emptyState: ['to' | 'from', 'old' | 'new'] | undefined,
            editorHeader: ['to' | 'from', 'old' | 'new'] | undefined;

          if (fromEditable) {
            editorActions = ['to', 'old'];
            emptyState = ['from', 'new'];
            editorHeader = ['to', 'old'];
          } else if (toEditable) {
            editorActions = ['from', 'new'];
            emptyState = ['to', 'old'];
            editorHeader = ['from', 'new'];
          }

          if (emptyState) {
            aniMate[emptyState[0]](
              document.documentElement,
              { scale: '0.95', opacity: 0 },
              {
                pseudoElement: `::view-transition-${emptyState[1]}(emptyState)`,
              }
            );
          }

          if (editorActions) {
            aniMate[editorActions[0]](
              document.documentElement,
              { translate: '0 100%' },
              {
                pseudoElement: `::view-transition-${editorActions[1]}(editorActions)`,
              }
            );
          }

          if (editorHeader) {
            aniMate[editorHeader[0]](
              document.documentElement,
              { translate: '0 -100%' },
              {
                pseudoElement: `::view-transition-${editorHeader[1]}(editorHeader)`,
              }
            );
          }

          unsetStyles.forEach((unsetFn) =>
            unsetFn({ await: transition.finished })
          );
        }

        break;
      }

      case 'click': {
        // Trigger click here so that open dropdowns are closed on click outside (on the iframe)
        const { clientX, clientY, pointerType } = event.data;
        const rect = this.el.getBoundingClientRect();

        document.body.dispatchEvent(
          new PointerEvent('click', {
            pointerType: pointerType || 'mouse',
            clientX: rect.left + clientX,
            clientY: rect.top + clientY,
            bubbles: true,
          })
        );
        break;
      }
    }
  };
}
