const parenthesesAddsUp = (str: string) => {
  const parentheses = str.match(/[()]/g);
  if (parentheses?.length) {
    if (parentheses[0] === ')') {
      return false;
    }

    return (
      parentheses.reduce((plusMinus, parenthesis) => {
        const add = parenthesis === '(' ? 1 : -1;
        return plusMinus + add;
      }, 0) === 0
    );
  }

  return true;
};

export const isUnfinishedAdvancedSearch = (
  str: string,
  checkParentheses = true
): boolean => {
  str = str.trim();

  /* Matches any string starting with AND/OR or contains empty parentheses */
  if (/(^(AND|OR)\b)|\(\)/.test(str)) {
    return true;
  }

  if (checkParentheses && !parenthesesAddsUp(str)) {
    return true;
  }

  // check any nested query parts
  const matches = str.match(/\([^()]*\)/g);
  if (matches) {
    return matches.some((match: string) => {
      return isUnfinishedAdvancedSearch(match.replace(/\(|\)/g, ''), false);
    });
  }

  // check all special words has something after
  return str.split(/\b(AND|OR|NOT)\b/g).some((word, i) => {
    return i > 0 && !word.trim();
  });
};
