import Controller from '@ember/controller';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';

export default class IndexController extends Controller {
  @action
  save() {
    return get(this.model, 'pagePublication').save();
  }
}

declare module '@ember/controller' {
  interface Registry {
    'content.index.page.settings.index': IndexController;
  }
}
