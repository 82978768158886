import Model, { attr } from '@ember-data/model';
import Raw from 'teamtailor/transforms/raw';

export default class PermissionModel extends Model {
  @attr('string') declare type: string;
  @attr('raw') declare roles: Raw;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    permission: PermissionModel;
  }
}
