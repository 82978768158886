import Model, { AsyncHasMany, attr, hasMany } from '@ember-data/model';
import computeFullErrorMessages from 'teamtailor/utils/full-error-messages';
import FormQuestionModel from './form-question';
import UploadModel from './upload';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

export default class FormModel extends Model {
  @service declare intl: IntlService;

  formQuestionsSorting = Object.freeze('sortIndex');

  @attr('string')
  declare name: string;

  @attr('string')
  declare introText: string;

  @attr('string')
  declare outroTitle: string;

  @attr('string')
  declare outroText: string;

  @attr('date')
  declare createdAt: Date;

  @attr('boolean')
  declare archived: boolean;

  @attr('number')
  declare responses: number;

  @attr('number') declare sends: number;

  @attr('raw') formQuestionsRaw: unknown;

  @hasMany('form-question')
  declare formQuestions: AsyncHasMany<FormQuestionModel>;

  @hasMany('upload')
  declare uploads: AsyncHasMany<UploadModel>;

  get fullErrorMessages() {
    return computeFullErrorMessages(this, this.intl);
  }

  get sortedFormQuestions() {
    return this.formQuestions.sortBy(this.formQuestionsSorting);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    form: FormModel;
  }
}
