import MeetingNewRoute from 'teamtailor/routes/meetings/new';

export default class JobsJobCompareCandidatesMeetingsNewRoute extends MeetingNewRoute {
  controllerName = 'meetings.new';
  templateName = 'meetings.new';

  meetingRouteRouteName = 'jobs.job.compare-candidates.candidate.meetings';
  candidateRouteRouteName = 'jobs.job.compare-candidates.candidate';
  jobRouteRouteName = 'jobs.job';
}
