import Model, { attr } from '@ember-data/model';
import Server from 'teamtailor/services/server';
import { inject as service } from '@ember/service';

export default class RejectReasonModel extends Model {
  @service declare server: Server;

  @attr('string') declare reason: string;
  @attr('boolean') declare hidden: boolean;
  @attr('boolean', { defaultValue: true }) declare rejectedByCompany: boolean;
  @attr('number') declare rowOrder: number;
  @attr('number') declare rowOrderPosition: number;

  async updateOrder(data: { row_order: number; id: string }) {
    return this.server.collectionAction({
      modelName: RejectReasonModel.modelName,
      action: 'update_order',
      options: {
        data: JSON.stringify(data),
      },
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'reject-reason': RejectReasonModel;
  }
}
