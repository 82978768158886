import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { get } from 'teamtailor/utils/get';

export default class PageModel extends Model {
  @service intl;
  @service server;

  @belongsTo('company') company;
  @belongsTo('image-with-setting') sharingImage;
  @belongsTo('job') job;
  @belongsTo('department') department;
  @belongsTo('role') role;
  @belongsTo('location') location;
  @belongsTo('page-publication', { async: false }) pagePublication;
  @belongsTo('message-widget') messageWidget;
  @belongsTo('image', { async: false }) thumbnail;
  @belongsTo('picked-image', { async: false }) pickedImage;

  @hasMany('user') users;
  @hasMany('section', { polymorphic: true }) sections;
  @hasMany('tag') tags;
  @hasMany('connect-setting') connectSettings;

  @attr('boolean', { defaultValue: false }) removeThumbnail;
  @attr('boolean', { defaultValue: false }) isDefaultCreatedAdTemplate;
  @attr('string') thumbnailCaption;
  @attr('string', { defaultValue: 'blank_page' }) pageType;
  @attr('string', { defaultValue: 'none' }) templateType;
  @attr('string', { defaultValue: 'all' }) visibility;
  @attr('number') pagePublicationId;
  @attr('number') careerSiteId;
  @attr('string') coverUrl;
  @attr('string') previewUrl;
  @attr('string') publicUrl;
  @attr('date') publishDate;
  @attr('raw') postContent;
  @attr('string') screenshot;
  @attr('string', { defaultValue: 'draft' }) status;
  @attr('string') title;
  @attr('string') titleWithFallback;
  @attr('string') description;
  @attr('boolean', { defaultValue: false }) isDefault;
  @attr('number') jobsUsageCount;
  @attr('boolean', { defaultValue: false }) unpublishedChanges;
  @attr('date', {
    defaultValue() {
      return new Date();
    },
  })
  createdAt;

  @attr('boolean', { defaultValue: false }) noIndex;
  @attr('string', { defaultValue: '' }) redirectUrl;
  @attr('date', {
    defaultValue() {
      return new Date();
    },
  })
  updatedAt;

  @attr('boolean', { defaultValue: null }) navigationLinkEnabled;

  get archivedAt() {
    return get(get(this, 'pagePublication'), 'archivedAt');
  }

  get careerSite() {
    return get(get(this, 'pagePublication'), 'careerSite');
  }

  get publishedAt() {
    return get(get(this, 'pagePublication'), 'publishedAt');
  }

  get isBlankPage() {
    return this.pageType === 'blank_page';
  }

  get isCampaignPage() {
    return this.pageType === 'campaign';
  }

  get isLeadPage() {
    return this.pageType === 'lead';
  }

  get isLocationPage() {
    return this.pageType === 'location';
  }

  get isDepartmentPage() {
    return this.pageType === 'department';
  }

  get isJobListPage() {
    return this.pageType === 'jobs_list';
  }

  get isDepartmentListPage() {
    return this.pageType === 'departments_list';
  }

  get isLocationListPage() {
    return this.pageType === 'locations_list';
  }

  get isHomePage() {
    return this.pageType === 'home';
  }

  get isTemplatePage() {
    return this.pageType === 'template';
  }

  get isPostPage() {
    return this.pageType === 'post';
  }

  get isPostListPage() {
    return this.pageType === 'posts_list';
  }

  get isEmployeesListPage() {
    return this.pageType === 'employees_list';
  }

  get isStoriesListPage() {
    return this.pageType === 'stories_list';
  }

  get isLocationsListPage() {
    return this.pageType === 'locations_list';
  }

  get isDepartmentsListPage() {
    return this.pageType === 'departments_list';
  }

  get isConnectPage() {
    return this.pageType === 'connect';
  }

  get isNotTemplatePage() {
    return !this.isTemplatePage;
  }

  get isCareerPage() {
    return (
      this.isHomePage ||
      this.isJobListPage ||
      this.isPostListPage ||
      this.isStoriesListPage ||
      this.isEmployeesListPage ||
      this.isDepartmentListPage ||
      this.isLocationListPage
    );
  }

  get isAnyCampaignPage() {
    return this.isCampaignPage || this.isLeadPage;
  }

  get isCampaignOrBlankPage() {
    return this.isAnyCampaignPage || this.isBlankPage;
  }

  get isDuplicatablePage() {
    return (
      this.isAnyCampaignPage ||
      this.isBlankPage ||
      this.isTemplatePage ||
      this.isPostPage
    );
  }

  get isCloning() {
    return this.status === 'clone';
  }

  get isCustom() {
    return this.isAnyCampaignPage || this.isBlankPage || this.isPostPage;
  }

  get hasNoJobsUsing() {
    return this.jobsUsageCount === 0;
  }

  get isUnusedTemplate() {
    return this.isTemplatePage && this.hasNoJobsUsing;
  }

  get isPublishing() {
    return get(get(this, 'pagePublication'), 'isPublishing');
  }

  get isPublished() {
    return get(get(this, 'pagePublication'), 'published');
  }

  get isActive() {
    return get(this, 'isPublished');
  }

  get isInactive() {
    return !get(this, 'isActive');
  }

  get canHaveHeroSection() {
    return this.isCampaignPage || this.isBlankPage;
  }

  get canChangeUrl() {
    return get(this, 'isCustom');
  }

  get canHaveChat() {
    return get(this, 'isCustom');
  }

  get canBeArchived() {
    return (
      this.isBlankPage ||
      this.isAnyCampaignPage ||
      this.isLeadPage ||
      this.isPostPage
    );
  }

  get canBeInactivated() {
    return (
      this.isCustom ||
      this.isDepartmentPage ||
      this.isLocationPage ||
      this.isDepartmentListPage ||
      this.isUnusedTemplate ||
      this.isLocationListPage ||
      this.isPostPage ||
      this.isPostListPage ||
      this.isEmployeesListPage
    );
  }

  get sortedSections() {
    return get(this, 'sections').sortBy('rowOrder');
  }

  get editorUrl() {
    let previewUrl = get(this, 'previewUrl');
    return `${previewUrl}&editor=true`;
  }

  get pageUrl() {
    if (get(this, 'isPublished')) {
      return get(this, 'publicUrl');
    } else {
      return get(this, 'previewUrl');
    }
  }

  get pageCategory() {
    if (this.isCareerPage) {
      return 'career';
    } else if (this.isDepartmentPage) {
      return 'departments';
    } else if (this.isLocationPage) {
      return 'locations';
    } else if (this.isAnyCampaignPage) {
      return 'campaigns';
    } else if (this.isTemplatePage) {
      return 'templates';
    } else if (this.isBlankPage) {
      return 'pages';
    } else if (this.isPostPage) {
      return 'post';
    } else if (this.isConnectPage) {
      return 'connect';
    }

    return null;
  }

  specialPageTitle = () => {
    let title;
    if (this.isHomePage) {
      title = this.intl.t('content.index.home');
    } else if (this.isDepartmentsListPage) {
      title = this.intl.t('content.index.departments');
    } else if (this.isJobListPage) {
      title = this.intl.t('content.index.jobs');
    } else if (this.isLocationsListPage) {
      title = this.intl.t('content.index.locations');
    } else if (this.isConnectPage) {
      title = 'Connect';
    }

    return title;
  };

  get displayTitle() {
    let name = get(this, 'pagePublication.name');
    let title = get(this, 'titleWithFallback');
    const id = get(this, 'id');
    name = isEmpty(name) ? undefined : name;

    title = this.specialPageTitle() || title;

    return name || title || this.intl.t('models.page.untitled', { id });
  }

  get isDeleteable() {
    return (
      this.isCampaignOrBlankPage ||
      (this.isTemplatePage &&
        this.jobsUsageCount === 0 &&
        !this.isDefault &&
        !this.isDefaultCreatedAdTemplate)
    );
  }

  async duplicate(data) {
    const response = await this.server.memberAction(this, {
      action: 'duplicate',
      options: {
        data,
      },
    });
    this.store.pushPayload(response);
  }
}
