import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    questions: { serialize: 'ids' },
    uploads: { serialize: 'records' },
    createdAt: { serialize: false },
    hasRawBody: { serialize: false },
  },
});
