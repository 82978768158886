import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { get } from 'teamtailor/utils/get';
import Candidate from './candidate';
import HireQualityResponse from './hire-quality-response';
import User from './user';
import Interview from './interview';
import ScorecardPick from './scorecard-pick';
import { SCORE_VALUE_ADJUSTMENT } from 'teamtailor/constants/scorecard-scores';

export default class ScorecardScoreModel extends Model {
  @belongsTo('user')
  declare user: AsyncBelongsTo<User>;

  @belongsTo('candidate')
  declare candidate: AsyncBelongsTo<Candidate>;

  @belongsTo('scorecard-pick')
  declare scorecardPick: AsyncBelongsTo<ScorecardPick>;

  @belongsTo('interview')
  declare interview: AsyncBelongsTo<Interview>;

  @belongsTo('hire-quality-response')
  declare hireQualityResponse: AsyncBelongsTo<HireQualityResponse>;

  @attr('number') declare interviewId: number | null;
  @attr('number') declare hireQualityResponseId: number | null;
  @attr('number') declare score: number | null;

  get topic(): string | undefined {
    const scorecardPick = get(this, 'scorecardPick') as ScorecardPick;
    return get(scorecardPick, 'topic');
  }

  get criteriumName() {
    return get(get(get(this, 'scorecardPick'), 'scorecardCriterium'), 'name');
  }

  get criteriumId() {
    return get(get(get(this, 'scorecardPick'), 'scorecardCriterium'), 'id');
  }

  get rowOrder() {
    const scorecardPick = get(this, 'scorecardPick') as ScorecardPick;
    return get(scorecardPick, 'rowOrder');
  }

  get humanScore() {
    return this.score !== null ? this.score + SCORE_VALUE_ADJUSTMENT : null;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'scorecard-score': ScorecardScoreModel;
  }
}
