import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';

export default class NewRoute extends ScrollToTopRoute {
  @service store;

  model({ type }) {
    return this.store.createRecord('cookie-detail', { type });
  }

  deactivate() {
    if (this.controller.model.isNew) {
      this.controller.model.deleteRecord();
    }
  }
}
