import TranslationModel from 'teamtailor/models/translation';
import InterviewKitModel from 'teamtailor/models/interview-kit';
import { attr, belongsTo, AsyncBelongsTo } from '@ember-data/model';

export default class TranslationInterviewKitModel extends TranslationModel {
  @belongsTo('interview-kit')
  declare interviewKit: AsyncBelongsTo<InterviewKitModel>;

  @attr('string') declare instructions: string;
  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'translation/interview-kit': TranslationInterviewKitModel;
  }
}
