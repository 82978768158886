import Helper from '@ember/component/helper';

export const TYPE_ICON_MAPPING = {
  'departments-list': { name: 'link-simple' },
  'job-ad': { name: 'text' },
  'locations-list': { name: 'link-simple' },
  'locations-map': { name: 'location-dot' },
  'related-jobs': { name: 'briefcase-blank' },
  'social-link': { name: 'chart-network' },
  blog: { name: 'pencil' },
  colleagues: { name: 'image-user' },
  companies: { name: 'link-simple' },
  connect: { name: 'circle-nodes' },
  cover: { name: 'book-sparkles' },
  cta: { name: 'bell-concierge' },
  custom: { name: 'code' },
  faq: { name: 'comments-question-check' },
  footer: { name: 'border-bottom' },
  gallery: { name: 'images' },
  grid: { name: 'link-simple' },
  group_jobs: { name: 'briefcase-blank' },
  header: { name: 'border-top' },
  hero: { name: 'rectangle-wide' },
  infographic: { name: 'chart-pie' },
  jobs: { name: 'briefcase-blank' },
  list: { name: 'list-ul' },
  numbers: { name: 'list-ol' },
  people: { name: 'image-user' },
  perks: { name: 'list-ul' },
  page_settings: { name: 'cog' },
  picture: { name: 'image' },
  posts: { name: 'feather-pointed' },
  recruiter: { name: 'address-card' },
  social: { name: 'hashtag' },
  stories: { name: 'rectangle-vertical-history', mirror: true },
  testimonial: { name: 'quote-right', style: 'solid' },
  text: { name: 'text' },
  timeline: { name: 'timeline-arrow' },
  value: { name: 'list-ul' },
  video: { name: 'video' },
  workplace: { name: 'icons' },
};

const DEFAULT_ICON = 'circle-dashed';
const DEFAULT_ICON_STYLE = 'regular';
const DEFAULT_MIRROR_STATE = false;

export const sectionIcon = (icon) => {
  return {
    name: TYPE_ICON_MAPPING[icon]?.name || DEFAULT_ICON,
    style: TYPE_ICON_MAPPING[icon]?.style || DEFAULT_ICON_STYLE,
    mirror: TYPE_ICON_MAPPING[icon]?.mirror || DEFAULT_MIRROR_STATE,
  };
};

export default Helper.helper(function (params) {
  return sectionIcon(params[0]);
});
