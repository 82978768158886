import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Molecules::Widget\n  ...attributes\n  @title={{if @title @title (t \"reports.pipeline_conversion.title\")}}\n  @onShowExportModal={{this.onShowExportModal}}\n  @empty={{eq this.data.length 0}}\n  @loading={{this.isLoading}}\n  @emptyEmoji=\"👀\"\n  @class=\"overflow-visible\"\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n>\n  <:content>\n    <Insights::Molecules::PipelineConversionChart\n      @data={{this.data}}\n      @shrink={{true}}\n    />\n    <p class=\"body-text-s mt-12 text-neutral-medium\">\n      {{t\n        \"reports.pipeline_conversion.widget_cohort_description\"\n        startDate=(moment-format @dateRange.startDate \"YYYY-MM-DD\")\n        endDate=(moment-format @dateRange.endDate \"YYYY-MM-DD\")\n      }}\n    </p>\n  </:content>\n</Insights::Molecules::Widget>", {"contents":"<Insights::Molecules::Widget\n  ...attributes\n  @title={{if @title @title (t \"reports.pipeline_conversion.title\")}}\n  @onShowExportModal={{this.onShowExportModal}}\n  @empty={{eq this.data.length 0}}\n  @loading={{this.isLoading}}\n  @emptyEmoji=\"👀\"\n  @class=\"overflow-visible\"\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n>\n  <:content>\n    <Insights::Molecules::PipelineConversionChart\n      @data={{this.data}}\n      @shrink={{true}}\n    />\n    <p class=\"body-text-s mt-12 text-neutral-medium\">\n      {{t\n        \"reports.pipeline_conversion.widget_cohort_description\"\n        startDate=(moment-format @dateRange.startDate \"YYYY-MM-DD\")\n        endDate=(moment-format @dateRange.endDate \"YYYY-MM-DD\")\n      }}\n    </p>\n  </:content>\n</Insights::Molecules::Widget>","moduleName":"teamtailor/components/insights/widgets/job-pipeline-conversion.hbs","parseOptions":{"srcName":"teamtailor/components/insights/widgets/job-pipeline-conversion.hbs"}});
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import Store from '@ember-data/store';
import { gql } from '@apollo/client/core';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import DateRange from 'teamtailor/utils/date-range';
import InsightsService from 'teamtailor/services/insights';
import { JobModel, StageModel } from 'teamtailor/models';
import {
  EventQueryResponse,
  EventTypeResponse,
} from 'teamtailor/utils/insights/graphql-response-types';
import moment from 'moment-timezone';
import {
  ProcessedStageRow,
  formatData,
} from 'teamtailor/utils/insights/pipeline-conversion';
import { action } from '@ember/object';
import InsightsExportService from 'teamtailor/services/insights-export';
import { get } from 'teamtailor/utils/get';

const QUERY = gql`
  query JobPipelineConversionsQuery(
    $dateRange: DateRangeAttributes!
    $cohortDateRange: DateRangeAttributes!
    $jobIds: [ID!]
  ) {
    eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [SOURCED, APPLIED, REFERRAL, MOVED, HIRED, REJECTED]
      distinctBy: {
        fields: [JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
      filters: [
        { jobApplicationId: { exists: true } }
        { jobApplicationCreatedAt: { between: $cohortDateRange } }
      ]
    ) {
      aggregated(groupBy: [STAGE_ID]) {
        stageId
        total: count
        rejected: countOccurrences(filters: { eventType: { equals: REJECTED } })
      }
    }
  }
`;

type Args = {
  dateRange: DateRange;
  job: JobModel;
};

const EXPORT_TABLE_COLUMNS = [
  {
    type: 'text',
    propertyName: 'displayName',
    headerKey: 'insights.widgets.job_pipeline_conversion.stage',
  },
  {
    type: 'text',
    propertyName: 'translatedStageType',
    headerKey: 'insights.widgets.job_pipeline_conversion.stage_type',
  },
  {
    type: 'number',
    propertyName: 'numberOfApplicantsPassed',
    headerKey: 'insights.widgets.job_pipeline_conversion.reached_stage',
  },
  {
    type: 'text',
    propertyName: 'conversionDown',
    headerKey: 'insights.widgets.job_pipeline_conversion.conversion_down',
  },
  {
    type: 'text',
    propertyName: 'conversionFromInbox',
    headerKey: 'insights.widgets.job_pipeline_conversion.conversion_inbox',
  },
  {
    type: 'text',
    propertyName: 'rejectedString',
    headerKey: 'insights.widgets.job_pipeline_conversion.rejected',
  },
  {
    type: 'text',
    propertyName: 'stayed',
    headerKey: 'insights.widgets.job_pipeline_conversion.not_moved',
  },
];

export default class JobPipelineConversionsWidget extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare insights: InsightsService;
  @service declare insightsExport: InsightsExportService;

  get jobDetail() {
    return get(this.args.job, 'jobDetail');
  }

  get stages() {
    const stages = get(this.jobDetail, 'stages');
    if (!this.jobDetail.isFulfilled || !stages.isLoaded) return [];
    return stages.toArray();
  }

  get data() {
    const lastValue = this.fetchData.lastSuccessful?.value;
    if (!lastValue?.length) return [];
    if (!this.stages.length) return [];

    const rows = this.inflateStageRows([...lastValue], this.stages);

    const data = formatData(
      rows,
      (row: ProcessedStageRow) => row.stageName || ''
    );

    return data;
  }

  get isLoading() {
    return this.fetchData.isRunning;
  }

  async queryForData(dateRange: DateRange) {
    const { job } = this.args;

    const untilNow = new DateRange(
      dateRange.startDate,
      moment().subtract(1, 'day').format('YYYY-MM-DD')
    );

    const result: {
      eventQuery: EventQueryResponse;
    } = await this.insights.query({
      query: QUERY,
      variables: {
        dateRange: untilNow.asObject,
        cohortDateRange: dateRange.asObject,
        jobIds: [job.id],
      },

      context: {
        jobId: job.id,
      },
    });

    const {
      eventQuery: { aggregated },
    } = result;

    return aggregated;
  }

  fetchData = dropTask(async () => {
    const rows = await this.queryForData(this.args.dateRange);
    return rows;
  });

  inflateStageRows = (
    rows: EventTypeResponse[],
    stages: StageModel[]
  ): ProcessedStageRow[] => {
    return stages
      .map((stage) => {
        const existingRow = rows.find((row) => row.stageId === stage.id);
        const stageTypeCategory = stage.stageType?.category || 'in_process';
        if (!existingRow) {
          return {
            stageId: stage.id,
            stageName: stage.name,
            stageRowOrder: stage.rowOrder,
            stageType: stageTypeCategory,
            translatedStageType: this.intl.t(
              `job.stage_types.${stageTypeCategory}`
            ),

            total: 0,
            rejected: 0,
          };
        } else {
          return {
            stageId: existingRow.stageId,
            stageName: stage.name,
            stageRowOrder: stage.rowOrder,
            stageType: stageTypeCategory,
            translatedStageType: this.intl.t(
              `job.stage_types.${stageTypeCategory}`
            ),

            total: existingRow.total,
            rejected: existingRow.rejected,
          };
        }
      })
      .sort((a, b) => {
        return a.stageRowOrder - b.stageRowOrder;
      });
  };

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      'insights.widgets.job_pipeline_conversion.title',
      `job-${this.args.job.id}`,
      EXPORT_TABLE_COLUMNS,
      this.retrieveExportData,
      this.args.dateRange,
      {
        sortKey: 'stageRowOrder',
        sortDirection: 'asc',
        createdAt: this.args.job.createdAt,
      }
    );
  }

  @action
  retrieveExportData() {
    return Promise.resolve(this.data);
  }
}
