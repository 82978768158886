import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"common.type\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"label\"\n    @options={{this.options}}\n    @onSelect={{@onChange}}\n    @searchEnabled={{false}}\n    @selected={{find-by \"id\" @selected this.options}}\n    @nullOption={{t \"search.all\"}}\n  />\n</label>", {"contents":"<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"common.type\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"label\"\n    @options={{this.options}}\n    @onSelect={{@onChange}}\n    @searchEnabled={{false}}\n    @selected={{find-by \"id\" @selected this.options}}\n    @nullOption={{t \"search.all\"}}\n  />\n</label>","moduleName":"teamtailor/components/sidebar/filters/tag-type.hbs","parseOptions":{"srcName":"teamtailor/components/sidebar/filters/tag-type.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { QuestionType, QUESTION_TYPES } from 'teamtailor/constants/question';

interface TagTypeArgs {
  hideDrafts: boolean | undefined;
}

export default class TagType extends Component<TagTypeArgs> {
  @service declare intl: IntlService;

  get options(): { id: string | null; label: string }[] {
    const options = [
      {
        id: null,
        label: this.intl.t('components.question_creator.default'),
      },
      ...Object.entries(QUESTION_TYPES).map((question) => {
        return {
          id: question[0] as QuestionType,
          label: this.intl.t(question[1].translationKey),
        };
      }),
    ];

    return options;
  }
}
