export const STRIPE_PAYMENT_ELEMENT_ID = 'payment-element';

export const PAYMENT_SETUP_PUSHER_TOPIC = 'setup-intent-succeeded';

export const STRIPE_APPEARANCE = {
  variables: {
    borderRadius: '6px',
    colorPrimary: '#f43f85',
    fontFamily: 'Inter, system-ui, sans-serif',
    fontSizeBase: '14px',
    fontSizeSm: '14px',
    spacingUnit: '4px',
    focusBoxShadow: '0 0 0 1px rgba(244, 63, 133, 0.5)',
    spacingGridRow: '16px',
  },

  rules: {
    '.Label': {
      fontWeight: '500',
    },

    '.Input': {
      border: '1px solid rgba(0,0,0,0.2)',
      marginBottom: '8px',
    },
  },
};

export const CARD_BRAND_THEME = {
  mastercard: 'bg-zinc-700 theme-dark',
  visa: 'bg-decorative-indigo-weak',
  amex: 'bg-decorative-blue-weak',
  diners: 'bg-decorative-purple-weak',
  discover: 'bg-decorative-orange-weak',
  elo: 'bg-decorative-cerise-weak',
  jcb: 'bg-decorative-emerald-weak',
  unionpay: 'bg-decorative-green-weak',
};
