import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<fieldset\n  class={{class-names\n    \"no-global-styles flex items-center gap-4 rounded-8 border border-solid border-neutral bg-neutral p-4 shadow-button\"\n    \"has-[:focus-visible]:border-transparent has-[:focus-visible]:outline has-[:focus-visible]:outline-2 has-[:focus-visible]:outline-focus\"\n    (if @isDisabled \"pointer-events-none opacity-40\")\n  }}\n  ...attributes\n>\n  {{yield\n    (hash\n      button=(component\n        \"core/toggle-switch/button\"\n        name=this.groupName\n        onChange=this.onChange\n        selectedValue=@value\n        color=@color\n        colorVariant=@colorVariant\n      )\n    )\n  }}\n</fieldset>", {"contents":"<fieldset\n  class={{class-names\n    \"no-global-styles flex items-center gap-4 rounded-8 border border-solid border-neutral bg-neutral p-4 shadow-button\"\n    \"has-[:focus-visible]:border-transparent has-[:focus-visible]:outline has-[:focus-visible]:outline-2 has-[:focus-visible]:outline-focus\"\n    (if @isDisabled \"pointer-events-none opacity-40\")\n  }}\n  ...attributes\n>\n  {{yield\n    (hash\n      button=(component\n        \"core/toggle-switch/button\"\n        name=this.groupName\n        onChange=this.onChange\n        selectedValue=@value\n        color=@color\n        colorVariant=@colorVariant\n      )\n    )\n  }}\n</fieldset>","moduleName":"teamtailor/components/core/toggle-switch/index.hbs","parseOptions":{"srcName":"teamtailor/components/core/toggle-switch/index.hbs"}});
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object';

interface CoreToggleSwitchComponentSignature {
  Args: {
    onChange?: (event: unknown) => void;
    value: unknown;
    isDisabled?: boolean;
  };
}

export default class CoreToggleSwitchComponent extends Component<CoreToggleSwitchComponentSignature> {
  get groupName() {
    return guidFor(this);
  }

  @action
  onChange(value: unknown) {
    this.args.onChange?.(value);
  }
}
