import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import { Transition } from 'teamtailor/utils/type-utils';
import FeatureModel from 'teamtailor/models/feature';
import ArrayProxy from '@ember/array/proxy';
import Store from '@ember-data/store';

export default class FeaturesRoute extends Route {
  @service declare router: RouterService;
  @service declare store: Store;

  model(): ArrayProxy<FeatureModel> {
    return this.store.findAll('feature', {
      reload: true,
    });
  }

  beforeModel(transition: Transition): void {
    if (transition.to.name === 'features.index') {
      this.router.transitionTo('features.show', 'all');
    }
  }
}
