import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import BowserService from 'teamtailor/services/bowser';
import {
  ANDROID_APP_STORE_URL,
  IOS_APP_STORE_URL,
} from 'teamtailor/constants/mobile-app';

export default class MobileAppController extends Controller {
  @service declare bowser: BowserService;

  initialViewportMetaContent = this.viewportMetaElement?.content;

  get viewportMetaElement(): HTMLMetaElement | null {
    return document.querySelector<HTMLMetaElement>('meta[name="viewport"]');
  }

  get androidAppStoreUrl(): string {
    return ANDROID_APP_STORE_URL;
  }

  get iosAppStoreUrl(): string {
    return IOS_APP_STORE_URL;
  }

  @action
  handleInsert(): void {
    this.viewportMetaElement?.setAttribute(
      'content',
      'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'
    );

    if (this.bowser.isAndroid) {
      window.location.replace(ANDROID_APP_STORE_URL);
    } else if (this.bowser.isIos) {
      window.location.replace(IOS_APP_STORE_URL);
    }
  }

  @action
  handleDestroy(): void {
    this.viewportMetaElement?.setAttribute(
      'content',
      this.initialViewportMetaContent || ''
    );
  }
}

declare module '@ember/controller' {
  interface Registry {
    'mobile-app': MobileAppController;
  }
}
