import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{@customField.name}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"value\"\n    @options={{@customField.options}}\n    @onSelect={{this.handleSelect}}\n    @searchEnabled={{false}}\n    @selected={{this.selectedOptions}}\n    @nullOption={{t \"search.all\"}}\n    @isMultiple={{this.isMultiSelect}}\n  />\n</label>", {"contents":"<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{@customField.name}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"value\"\n    @options={{@customField.options}}\n    @onSelect={{this.handleSelect}}\n    @searchEnabled={{false}}\n    @selected={{this.selectedOptions}}\n    @nullOption={{t \"search.all\"}}\n    @isMultiple={{this.isMultiSelect}}\n  />\n</label>","moduleName":"teamtailor/components/sidebar/filters/custom-select.hbs","parseOptions":{"srcName":"teamtailor/components/sidebar/filters/custom-select.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class SelectComponent extends Component {
  get isMultiSelect() {
    return this.args.customField.type === 'CustomField::MultiSelect';
  }

  get selectedOptions() {
    const { options } = this.args.customField;

    if (Array.isArray(this.args.selected) || this.isMultiSelect) {
      return options.filter((v) => (this.args.selected || []).includes(v.id));
    }

    let [selected] = options.filter((v) => this.args.selected === v.id);
    return selected;
  }

  changeMultiSelect(option) {
    if (this.selectedOptions.includes(option)) {
      this.args.onChange([
        ...this.selectedOptions.filter((o) => o.id !== option.id),
      ]);
    } else {
      this.args.onChange([...this.selectedOptions, option]);
    }
  }

  @action
  handleSelect(option) {
    if (this.isMultiSelect) {
      this.changeMultiSelect(option);
    } else {
      this.args.onChange(option);
    }
  }
}
