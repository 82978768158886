import { gql } from '@apollo/client/core';

export default gql`
  query ReferralLeaderboardSpots($userId: ID) {
    referralLeaderboardSpots(userScope: { userId: $userId }) {
      hiredCount
      totalCount
      user {
        id
        nameOrEmail
        picture {
          appUserAvatar {
            url
          }
        }
      }
    }
  }
`;
