import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import {
  CompanyModel,
  ImageModel,
  ImageWithSettingModel,
  PushPayloadArg,
} from 'teamtailor/models';
import { inject as service } from '@ember/service';
import Server from 'teamtailor/services/server';

export default class DesignModel extends Model {
  @service declare server: Server;

  @belongsTo('company', { inverse: null })
  declare company: AsyncBelongsTo<CompanyModel>;

  @belongsTo('image-with-setting')
  declare sharingImage: AsyncBelongsTo<ImageWithSettingModel>;

  @belongsTo('image') declare careersiteLogo: AsyncBelongsTo<ImageModel>;
  @belongsTo('image') declare logotype: AsyncBelongsTo<ImageModel>;

  @attr('array') declare availableFonts: string[];
  @attr('boolean') declare buttonsOutlined: boolean;
  @attr('boolean') declare hideLogotypeBackground: boolean;
  @attr('boolean') declare unpublishedChanges: boolean;
  @attr('boolean') declare useCustomFonts: boolean;
  @attr('boolean') declare confetti: boolean;
  @attr('carrierwave') declare favicon: string;
  @attr('carrierwave-cache') declare faviconCache: string;
  @attr('number') declare bodyFontWeight: number;
  @attr('number') declare buttonsRadius: number;
  @attr('number') declare headerFontWeight: number;
  @attr('string') declare bodyFont: string;
  @attr('string') declare buttonBackgroundColor: string;
  @attr('string') declare buttonTextColor: string;
  @attr('string') declare color: string;
  @attr('string') declare customCss: string;
  @attr('string') declare customFontLink?: string;
  @attr('string') declare customJavascript: string;
  @attr('string') declare employeesShape: string;
  @attr('string') declare headerFont: string;
  @attr('string') declare linkColor: string;
  @attr('string') declare linkStyle: string;
  @attr('string') declare primaryBackgroundColor: string;
  @attr('string') declare primaryTextColor: string;
  @attr('string') declare secondaryBackgroundColor: string;
  @attr('string') declare secondaryTextColor: string;

  get googleFontUrl() {
    if (this.useCustomFonts && this.customFontLink?.match(/\/\//)) {
      // Google fonts link was set by user
      return this.customFontLink;
    } else if (this.useCustomFonts && this.customFontLink) {
      // Not an url, probably typekit id
      return null;
    }

    const fontFamily = (name: string, weight: number) =>
      `${name.replace(/\s/g, '+')}:${weight}`;
    const families = [];

    if (this.bodyFont) {
      families.push(fontFamily(this.bodyFont, this.bodyFontWeight));
    }

    if (this.headerFont) {
      families.push(fontFamily(this.headerFont, this.headerFontWeight));
    }

    if (families.length > 0) {
      return `https://fonts.googleapis.com/css?family=${families.join('|')}`;
    }

    return undefined;
  }

  get typekitId() {
    if (
      this.useCustomFonts &&
      this.customFontLink &&
      this.customFontLink.match(/\/\//) === null
    ) {
      // Not an url, probably typekit id
      return this.customFontLink;
    }

    return undefined;
  }

  async publish() {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'publish',
    });
    this.store.pushPayload(response);
  }

  async getWebsiteFavicon() {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'get_website_favicon',
    });
    this.store.pushPayload(response);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    design: DesignModel;
  }
}
