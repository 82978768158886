import { htmlSafe } from '@ember/template';
import { helper } from '@ember/component/helper';

export function linkifyMarkdown([string], attributes) {
  if (typeof string !== 'string') {
    return '';
  }

  const linkifiedString = string.replace(
    /\[(.*?)\]\((.*?)\)/g,
    (_, text, href) => {
      const a = document.createElement('a');
      a.innerHTML = text;

      Object.entries({ ...attributes, href }).forEach(([attribute, value]) => {
        a.setAttribute(attribute, value);
      });

      return a.outerHTML;
    }
  );

  return htmlSafe(linkifiedString);
}

export default helper(linkifyMarkdown);
