import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class NewRoute extends Route {
  @service store;

  model(_, transition) {
    const contentController = this.controllerFor('content.index');
    return this.store.createRecord('page', {
      careerSiteId:
        get(contentController, 'site') || transition?.to?.queryParams?.site,
    });
  }
}
