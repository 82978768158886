import Modifier, { ArgsFor } from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';
import Owner from '@ember/owner';
import { later } from '@ember/runloop';

import throttle from 'teamtailor/utils/throttle';

export type RenderedSection = {
  id: string;
  title: string;
  open: boolean;
};

type SetRenderedSections = (renderedSections: RenderedSection[]) => void;
type SetSectionsInView = (sectionsInView: string[]) => void;
type SetFocusedSection = (focusedSection?: string) => void;

interface Signature {
  Element: HTMLElement;
  Args: {
    Positional: [SetRenderedSections, SetSectionsInView, SetFocusedSection];
  };
}

function cleanup(instance: FocusedSectionModifier) {
  instance.intersectionObserver?.disconnect();
  instance.mutationObserver?.disconnect();

  if (instance.scrollListener) {
    instance.rootElement?.removeEventListener(
      'scroll',
      instance.scrollListener
    );
  }
}

export default class FocusedSectionModifier extends Modifier<Signature> {
  intersectionObserver?: IntersectionObserver;
  mutationObserver?: MutationObserver;
  scrollListener?: () => void;
  rootElement?: HTMLElement;
  sectionsInView: string[] = [];
  renderedSections: RenderedSection[] = [];

  setRenderedSections?: SetRenderedSections;
  setSectionsInView?: SetSectionsInView;
  setFocusedSection?: SetFocusedSection;

  constructor(owner: Owner, args: ArgsFor<Signature>) {
    super(owner, args);
    registerDestructor(this, cleanup);
  }

  modify(
    element: HTMLElement,
    [setRenderedSections, setSectionsInView, setFocusedSection]: [
      SetRenderedSections,
      SetSectionsInView,
      SetFocusedSection
    ]
  ) {
    this.setRenderedSections = setRenderedSections;
    this.setSectionsInView = setSectionsInView;
    this.setFocusedSection = setFocusedSection;

    const reInitialize = () => {
      cleanup(this);
      this.initialize(element, reInitialize);
    };

    this.initialize(element, reInitialize);
  }

  updateRenderedSections(sections: RenderedSection[]) {
    this.renderedSections = sections;
    this.setRenderedSections?.(sections);
  }

  initialize(rootElement: HTMLElement, reInitialize: () => void) {
    this.rootElement = rootElement;
    const sections = rootElement.querySelectorAll('[data-type="section"]');

    later(() => {
      this.initializeRenderedSections(sections);
    }, 200);

    // Used to detect which sections are in scroll view
    this.intersectionObserver = setupIntersectionObserver(
      rootElement,
      sections,
      this.sectionViewVisibilityChanged
    );

    // Used to detect when sections:
    // - are collapsed/expanded
    // - are added/removed
    this.mutationObserver = setupMutationObserver(
      rootElement,
      reInitialize, // Restart the whole thing when sections are added/removed
      this.sectionStateChanged
    );

    // Used to detect which section is currently in the middle of scroll view
    this.scrollListener = setupScrollListener(
      rootElement,
      this.setFocusedSection
    );
  }

  initializeRenderedSections = (sections: NodeListOf<Element>) => {
    const renderedSections: RenderedSection[] = [];

    sections.forEach((section) => {
      const {
        dataset: { sectionId, sectionTitle },
        classList,
      } = section as HTMLElement;

      if (sectionId) {
        renderedSections.push({
          title: sectionTitle || '',
          id: sectionId,
          open: !classList.contains('hidden'),
        });
      }
    });

    this.updateRenderedSections(renderedSections);
  };

  sectionViewVisibilityChanged = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      const id = (entry.target as HTMLElement).dataset.sectionId;

      if (!id) {
        return;
      }

      if (entry.isIntersecting && !this.sectionsInView.includes(id)) {
        this.sectionsInView.push(id);
      } else if (!entry.isIntersecting) {
        this.sectionsInView.removeObject(id);
      }
    });

    this.setSectionsInView?.(this.sectionsInView);
  };

  sectionStateChanged = ({ id, open }: RenderedSection) => {
    const renderedSections = this.renderedSections.map((section) => {
      if (section.id === id) {
        return { ...section, open };
      }

      return section;
    });

    this.updateRenderedSections(renderedSections);
  };
}

function setupScrollListener(
  element: HTMLElement,
  setFocusedSection?: SetFocusedSection
) {
  if (!setFocusedSection) {
    return;
  }

  const sections = element.querySelectorAll('[data-type="section"]');
  const parentTop = element.getBoundingClientRect().top;

  const scrollListener = throttle(
    () => {
      const scrolledToBottom =
        element.scrollHeight - element.scrollTop === element.clientHeight;

      const sectionRects: { [key: string]: DOMRect } = {};

      Array.from(sections).forEach((section) => {
        const { sectionId } = (section as HTMLElement).dataset;
        if (sectionId) {
          sectionRects[sectionId] = section.getBoundingClientRect();
        }
      });

      // Special case when scrolled to bottom:
      // - always set the last opened section as focused
      if (scrolledToBottom) {
        const lastOpenedSection = Array.from(sections)
          .reverse()
          .find((section) => {
            const { sectionId } = (section as HTMLElement).dataset;

            if (sectionId) {
              const rect = sectionRects[sectionId];
              return rect?.height && rect.height > 0;
            }
          });

        if (lastOpenedSection) {
          const { sectionId } = (lastOpenedSection as HTMLElement).dataset;
          setFocusedSection(sectionId);
          return;
        }
      }

      // Math to figure out which section is in the middle of the scroll view

      const sectionCenters: { [key: string]: number } = {};

      Array.from(sections).forEach((section) => {
        const { sectionId } = (section as HTMLElement).dataset;

        if (!sectionId) {
          return;
        }

        const rect = sectionRects[sectionId];

        if (rect && rect.height > 0) {
          // Y center of section relative to scroll top of container
          const sectionCenter =
            element.scrollTop + rect.top - parentTop + rect.height / 2;

          if (sectionCenter !== 0 && sectionId) {
            sectionCenters[sectionId] = sectionCenter;
          }
        }
      });

      const scrollMiddle = element.scrollTop + element.clientHeight / 2;

      let closestSectionId = null as string | null;
      let closestDistance = Infinity;

      Object.entries(sectionCenters).forEach(([sectionId, sectionCenter]) => {
        const distance = Math.abs(sectionCenter - scrollMiddle);

        if (distance < closestDistance) {
          closestDistance = distance;
          closestSectionId = sectionId;
        }
      });

      if (closestSectionId && closestDistance < element.clientHeight / 2) {
        setFocusedSection(closestSectionId);
      } else {
        setFocusedSection(undefined);
      }
    },
    100,
    { trailing: true, useRequestAnimationFrame: true }
  );

  element.addEventListener('scroll', scrollListener);
  return scrollListener;
}

function setupMutationObserver(
  element: HTMLElement,
  sectionsChangedCallback: () => void,
  sectionChangedCallback: (section: RenderedSection) => void
) {
  const config = {
    attributes: true,
    childList: true,
    subtree: true,
    attributeFilter: ['class'],
  };

  const sectionChanged = (node: Node) => {
    return (
      node.nodeType === Node.ELEMENT_NODE &&
      (node as HTMLElement).dataset.type === 'section'
    );
  };

  const callback = function (mutationsList: MutationRecord[]) {
    let sectionsChanged = false;

    for (const mutation of mutationsList) {
      // Check if section is expanded or collapsed
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === 'class' &&
        mutation.target.nodeType === Node.ELEMENT_NODE
      ) {
        const target = mutation.target as HTMLElement;

        if (target.dataset.type === 'section') {
          const open = !target.classList.contains('hidden');
          const { sectionId, sectionTitle } = target.dataset;

          if (sectionId) {
            sectionChangedCallback({
              title: sectionTitle || '',
              id: sectionId,
              open,
            });
          }
        }
      }

      if (mutation.type === 'childList') {
        if (!sectionsChanged) {
          sectionsChanged = Array.from(mutation.addedNodes).some(
            sectionChanged
          );
        }

        if (!sectionsChanged) {
          sectionsChanged = Array.from(mutation.removedNodes).some(
            sectionChanged
          );
        }
      }
    }

    if (sectionsChanged) {
      // This means that sections were added or removed
      // - we reinitialize the whole thing and start from scratch
      sectionsChangedCallback();
    }
  };

  const mutationObserver = new MutationObserver(callback);

  mutationObserver.observe(element, config);

  return mutationObserver;
}

function setupIntersectionObserver(
  element: HTMLElement,
  sections: NodeListOf<Element>,
  callback: (entries: IntersectionObserverEntry[]) => void
) {
  // The margin and threshold can be adjusted if needed
  const options = {
    root: element,
    rootMargin: '50px',
    threshold: 0.7,
  };

  const intersectionObserver = new IntersectionObserver(callback, options);

  sections.forEach((section) => {
    intersectionObserver.observe(section);
  });

  return intersectionObserver;
}
