import Store from '@ember-data/store';
import { set, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import IntlService from 'ember-intl/services/intl';
import TemplateFormNewController from 'teamtailor/controllers/settings/reference-templates/new';
import ReferenceTemplateModel from 'teamtailor/models/reference-template';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import copyModel from 'teamtailor/utils/copy-model';
import { questionsToFormFields } from 'teamtailor/utils/form-builder';
import { get } from 'teamtailor/utils/get';
import { Transition } from 'teamtailor/utils/type-utils';

export default class ReferenceTemplatesNewRoute extends ScrollToTopRoute {
  @service declare store: Store;
  @service declare intl: IntlService;

  queryParams = {
    copiedReferenceTemplateId: {
      refreshModel: true,
    },
  };

  async model(params: {
    copiedReferenceTemplateId: string;
  }): Promise<ReferenceTemplateModel> {
    if (isPresent(params.copiedReferenceTemplateId)) {
      const referenceTemplate = await this.store.findRecord(
        'reference-template',
        params.copiedReferenceTemplateId
      );
      const copiedReferenceTemplate = copyModel({ model: referenceTemplate });
      set(
        copiedReferenceTemplate,
        'name',
        `${this.intl.t('settings.reference_templates.copy_of_template_name')} ${
          copiedReferenceTemplate.name
        }`
      );

      return copiedReferenceTemplate;
    }

    return this.store.createRecord('reference-template');
  }

  setupController(
    controller: TemplateFormNewController,
    model: ReferenceTemplateModel,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);

    const sortedFormQuestions = get(model, 'sortedFormQuestions');
    const fields =
      get(model, 'formQuestionsRaw') ||
      questionsToFormFields(sortedFormQuestions);

    set(controller, 'fields', fields);
  }

  resetController(controller: TemplateFormNewController, isExiting: boolean) {
    if (isExiting) {
      setProperties(controller, {
        fields: [],
        copiedReferenceTemplateId: null,
      });

      const model = get(controller, 'model');
      if (get(model, 'isNew')) {
        model.unloadRecord();
      }
    }
  }
}
