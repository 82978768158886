import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#let (file-queue name=this.name) as |fileQueue|}}\n  <FileDropzone class=\"absolute inset-0\" ...attributes @queue={{fileQueue}}>\n    {{#if fileQueue.files.length}}\n      <UploadProgress\n        @fileLength={{fileQueue.files.length}}\n        @progress={{fileQueue.progress}}\n      />\n    {{/if}}\n    <FileUpload\n      @name={{this.name}}\n      @accept=\"video/mp4,video/webm,video/flv,video/mov,video/quicktime,video/ogv,video/3gp,video/3g2,video/wmv,video/mpeg,video/avi\"\n      @multiple={{false}}\n      @onFileAdd={{this.upload}}\n      class=\"absolute inset-0\"\n    />\n    {{yield fileQueue}}\n  </FileDropzone>\n{{/let}}", {"contents":"{{#let (file-queue name=this.name) as |fileQueue|}}\n  <FileDropzone class=\"absolute inset-0\" ...attributes @queue={{fileQueue}}>\n    {{#if fileQueue.files.length}}\n      <UploadProgress\n        @fileLength={{fileQueue.files.length}}\n        @progress={{fileQueue.progress}}\n      />\n    {{/if}}\n    <FileUpload\n      @name={{this.name}}\n      @accept=\"video/mp4,video/webm,video/flv,video/mov,video/quicktime,video/ogv,video/3gp,video/3g2,video/wmv,video/mpeg,video/avi\"\n      @multiple={{false}}\n      @onFileAdd={{this.upload}}\n      class=\"absolute inset-0\"\n    />\n    {{yield fileQueue}}\n  </FileDropzone>\n{{/let}}","moduleName":"teamtailor/components/video-picker.hbs","parseOptions":{"srcName":"teamtailor/components/video-picker.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { argDefault } from 'teamtailor/utils/arg-default';
import DirectUploadService from 'teamtailor/services/direct-upload';
import Store from '@ember-data/store';
import VideoModel from 'teamtailor/models/video';

interface VideoPickerComponentArgs {
  name: string;
  theme: string;
  ratioNumerator: number;
  ratioDenominator: number;
  overlayOpacity: number;
  overlayColor: string;
  type: string;
  video?: VideoModel;
  onPickVideo: (video: VideoModel, type: string) => void;
}

export default class VideoPicker extends Component<VideoPickerComponentArgs> {
  @service declare directUpload: DirectUploadService;
  @service declare store: Store;

  @tracked declare file: File;

  @argDefault name = 'video-picker';
  @argDefault theme = 'default';

  uploadToS3 = task(async (file) => {
    const fileUrl = await this.directUpload.s3(file, { model: 'videos' });
    const video = await this.store
      .createRecord('video', { file: fileUrl, transcodeType: this.args.type })
      .save();
    this.args.onPickVideo(video, this.args.type);
  });

  @action
  upload(file: File) {
    this.uploadToS3.perform(file);
  }
}
