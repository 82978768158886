import ApplicationInstance from '@ember/application/instance';
import { task } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';
import ReportRequest from 'teamtailor/classes/analytics/report-request';
import round from 'teamtailor/utils/round';
import comparePercentage from 'teamtailor/utils/compare-percentage';
import colors, {
  bgColorClasses,
} from 'teamtailor/components/insights/charts/colors';
import fetchInBatches from 'teamtailor/utils/insights/fetch-in-batches';
import { gql } from '@apollo/client/core';
import uniq from 'teamtailor/utils/uniq';

type PartnerResultRow = {
  status: string;
  candidate: number;
  score: number;
  created_at: string;
  partner_id: string;
  partner_name: string;
};

type CandidateType = {
  id: string;
  nameOrEmail?: string;
  avatarUrl?: string;
  initials?: string;
  color?: string;
  deleted?: boolean;
  avatarImage?: {
    candidatePictureUrl: string;
  };
  company?: {
    id: string;
    name: string;
  };
};

type CandidatesResponseType = {
  candidates: CandidateType[];
};

type PartnerResultWithCandidate = {
  score: number;
  created_at: string;
  partner_id: string;
  partner_name: string;
  candidate: CandidateType;
  status: string;
};

const enrichPartnerResultsWithCandidates = async (
  container: any,
  rows: PartnerResultRow[]
): Promise<PartnerResultWithCandidate[]> => {
  const analyticsService = container.lookup('service:analytics');
  const apolloService = container.lookup('service:apollo');
  const currentService = container.lookup('service:current');

  const candidateIds = uniq(rows.map((row) => row.candidate.toString()));

  const candidates = await fetchInBatches<
    CandidateType,
    CandidatesResponseType
  >(
    candidateIds,
    (ids: string[]) =>
      apolloService.query({
        query: gql`
          query CandidatesQuery(
            $userId: ID!
            $candidateIds: [ID!]
            $companyIds: [ID!]
          ) {
            candidates(
              ids: $candidateIds
              userScope: { userId: $userId }
              groupCompanyIds: $companyIds
            ) {
              id
              nameOrEmail
              initials
              color
              avatarImage {
                candidatePictureUrl
              }
              company {
                id
                name
              }
            }
          }
        `,
        variables: {
          candidateIds: ids,
          userId: currentService.user.id,
          companyIds: analyticsService.availableCompanyIds,
        },
      }),
    (acc, response) => {
      return acc.concat(response.candidates);
    }
  );

  return rows.map((row) => {
    const candidate = candidates.find(
      (candidate: CandidateType) =>
        candidate.id.toString() === row.candidate.toString()
    );

    return {
      ...row,
      candidate: candidate || { id: row.candidate.toString(), deleted: true },
      companyName: candidate?.company?.name,
    };
  });
};

interface DataObjectArgs {
  container: ApplicationInstance;
  partnerResults: PartnerResultWithCandidate[];
}

class DataObject {
  container: ApplicationInstance;
  partnerResults: PartnerResultWithCandidate[] = [];

  constructor(args: DataObjectArgs) {
    this.container = args.container;
    this.partnerResults = args.partnerResults;
  }

  get hasData() {
    return this.allResults.length > 0;
  }

  get averageScore(): number | undefined {
    if (this.rowsWithScores.length) {
      const totalScore = this.rowsWithScores
        .map((row) => row.score)
        .reduce((sum, score) => sum + score);
      return totalScore / this.rowsWithScores.length;
    }

    return undefined;
  }

  @comparePercentage('averageScore', 'compareModel.averageScore')
  declare averageScoreCompare: number | undefined;

  get medianScore() {
    if (!this.hasData) {
      return 0;
    }

    const scores = this.rowsWithScores.map((row) => row.score);
    scores.sort(function (a, b) {
      return a - b;
    });

    const medianIndex = Math.floor(scores.length / 2);
    const middleLeft = scores[medianIndex - 1];
    const middleRight = scores[medianIndex];
    if (!middleLeft || !middleRight) {
      return 0;
    }

    return scores.length % 2 === 0
      ? (middleLeft + middleRight) / 2
      : scores[medianIndex];
  }

  @comparePercentage('medianScore', 'compareModel.medianScore')
  declare medianScoreCompare: number | undefined;

  get allResults() {
    return this.partnerResults;
  }

  get completedResults() {
    return this.allResults.filter((result) => result.status === 'completed');
  }

  get completedResultsPercentage(): number {
    return round(
      (this.completedResults.length / this.allResults.length) * 100,
      true
    );
  }

  @comparePercentage(
    'completedResultsPercentage',
    'compareModel.completedResultsPercentage'
  )
  declare completedResultsPercentageCompare: number | undefined;

  get completedResultsWithScores() {
    return this.completedResults.filter((result) => result.score);
  }

  @comparePercentage('rows.length', 'compareModel.rows.length')
  declare nrResultsCompare: number | undefined;

  get rows() {
    return this.completedResults;
  }

  get partnerNames(): string[] {
    const partnerNames = this.rows.map((row) => row.partner_name);

    return [...new Set(partnerNames)];
  }

  get legendColors() {
    const colorArray = colors.slice(1);

    return this.partnerNames.map((_name, index) => {
      return colorArray[index % colorArray.length];
    });
  }

  get legendColorClasses() {
    const classes = bgColorClasses.slice(1);

    return this.partnerNames.map((_name, index) => {
      return classes[index % classes.length];
    });
  }

  get rowsWithScores() {
    return this.rows.filter((row) => row.score);
  }
}

export default class PartnerResultsReport {
  container: ApplicationInstance;

  constructor({ container }: { container: ApplicationInstance }) {
    this.container = container;
  }

  fetch = task(async (): Promise<DataObject> => {
    const partnerReportResults = await new ReportRequest({
      container: this.container,
      path: 'candidates/partner_results',
    }).fetch();

    const partnerResults = await enrichPartnerResultsWithCandidates(
      this.container,
      partnerReportResults
    );

    return new DataObject({
      container: this.container,
      partnerResults,
    });
  });

  fetchTask = trackedTask(this, this.fetch);
}
