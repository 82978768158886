import Helper from '@ember/component/helper';

export default class ToIAttendeeUsers extends Helper {
  compute([users]) {
    return users?.length
      ? users.map((user) => {
          return { user };
        })
      : [];
  }
}
