import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { TagModel } from 'teamtailor/models';

export default class TaggingModel extends Model {
  @belongsTo('tag') declare tag: AsyncBelongsTo<TagModel>;

  @attr('string', { defaultValue: 'company_candidate_tags' })
  declare context: string;

  @attr('number') declare taggableId: number;
  @attr('string') declare taggableType: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    tagging: TaggingModel;
  }
}
