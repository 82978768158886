import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isChoiceOrCheckboxQuestion}}\n  {{#if @showStageBreakdown}}\n    <Surveys::AnswersTable\n      @questionIndex={{@questionIndex}}\n      @question={{@question}}\n      @isAnonymous={{@isAnonymous}}\n    />\n  {{else}}\n    <Surveys::QuestionAnswers\n      @questionIndex={{@questionIndex}}\n      @question={{@question}}\n      @stageType={{@stageType}}\n      @isAnonymous={{@isAnonymous}}\n    />\n  {{/if}}\n{{else if this.isTextQuestion}}\n  <Surveys::TextAnswers\n    @questionIndex={{@questionIndex}}\n    @question={{@question}}\n    @surveyId={{@surveyId}}\n    @displayStageTypeBadge={{@showStageBreakdown}}\n    @isAnonymous={{@isAnonymous}}\n  />\n{{else if this.isScaleQuestion}}\n  <Surveys::ScaleAnswers\n    @questionIndex={{@questionIndex}}\n    @question={{@question}}\n    @showStageBreakdown={{@showStageBreakdown}}\n    @isAnonymous={{@isAnonymous}}\n  />\n{{/if}}", {"contents":"{{#if this.isChoiceOrCheckboxQuestion}}\n  {{#if @showStageBreakdown}}\n    <Surveys::AnswersTable\n      @questionIndex={{@questionIndex}}\n      @question={{@question}}\n      @isAnonymous={{@isAnonymous}}\n    />\n  {{else}}\n    <Surveys::QuestionAnswers\n      @questionIndex={{@questionIndex}}\n      @question={{@question}}\n      @stageType={{@stageType}}\n      @isAnonymous={{@isAnonymous}}\n    />\n  {{/if}}\n{{else if this.isTextQuestion}}\n  <Surveys::TextAnswers\n    @questionIndex={{@questionIndex}}\n    @question={{@question}}\n    @surveyId={{@surveyId}}\n    @displayStageTypeBadge={{@showStageBreakdown}}\n    @isAnonymous={{@isAnonymous}}\n  />\n{{else if this.isScaleQuestion}}\n  <Surveys::ScaleAnswers\n    @questionIndex={{@questionIndex}}\n    @question={{@question}}\n    @showStageBreakdown={{@showStageBreakdown}}\n    @isAnonymous={{@isAnonymous}}\n  />\n{{/if}}","moduleName":"teamtailor/components/surveys/answers.hbs","parseOptions":{"srcName":"teamtailor/components/surveys/answers.hbs"}});
import Component from '@glimmer/component';

type Question = {
  type: string;
};

type SurveyAnswersArgs = {
  question: Question;
  isAnonymous: boolean;
};

export default class SurveyAnswersComponent extends Component<SurveyAnswersArgs> {
  get isChoiceOrCheckboxQuestion(): boolean {
    return (
      this.args.question.type === 'FormQuestion::Choice' ||
      this.args.question.type === 'FormQuestion::Checkbox'
    );
  }

  get isTextQuestion(): boolean {
    return (
      this.args.question.type === 'FormQuestion::Text' ||
      this.args.question.type === 'FormQuestion::TextArea'
    );
  }

  get isScaleQuestion(): boolean {
    return this.args.question.type === 'FormQuestion::Scale';
  }
}
