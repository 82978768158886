import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (and (has-feature \"job_offers\") this.jobOffers)}}\n  <CandidateModalNew::Main::Section\n    @title={{t \"components.candidate_modal.job_offers.title\"}}\n    @id=\"job-offers\"\n  >\n    <:content>\n      <ul class=\"flex flex-col gap-16\">\n        {{#each this.jobOffers as |jobOffer|}}\n          <CandidateModalNew::Main::Section::JobOffers::Item\n            @jobOffer={{jobOffer}}\n            @candidate={{@candidate}}\n          />\n        {{/each}}\n      </ul>\n    </:content>\n  </CandidateModalNew::Main::Section>\n{{/if}}", {"contents":"{{#if (and (has-feature \"job_offers\") this.jobOffers)}}\n  <CandidateModalNew::Main::Section\n    @title={{t \"components.candidate_modal.job_offers.title\"}}\n    @id=\"job-offers\"\n  >\n    <:content>\n      <ul class=\"flex flex-col gap-16\">\n        {{#each this.jobOffers as |jobOffer|}}\n          <CandidateModalNew::Main::Section::JobOffers::Item\n            @jobOffer={{jobOffer}}\n            @candidate={{@candidate}}\n          />\n        {{/each}}\n      </ul>\n    </:content>\n  </CandidateModalNew::Main::Section>\n{{/if}}","moduleName":"teamtailor/components/candidate-modal-new/main/section/job-offers/index.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-modal-new/main/section/job-offers/index.hbs"}});
import Component from '@glimmer/component';
import Candidate from 'teamtailor/models/candidate';
import JobApplication from 'teamtailor/models/job-application';

interface JobOffersSignature {
  Args: {
    candidate: Candidate;
    jobApplication?: JobApplication;
  };
}

export default class CandidateModalNewMainSectionJobOffers extends Component<JobOffersSignature> {
  get jobOffers() {
    if (!this.args.jobApplication) {
      return null;
    }

    const jobApplicationId = this.args.jobApplication.id;
    return this.args.candidate.jobOffers.toArray().filter((jobOffer) => {
      return (
        jobOffer.belongsTo('jobApplication').id() === jobApplicationId &&
        !jobOffer.isNew
      );
    });
  }
}
