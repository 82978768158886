import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Checkbox\n  {{did-insert this.didInsert}}\n  @label={{@method}}\n  @onChange={{this.onChange}}\n  @checked={{this.checked}}\n/>", {"contents":"<Core::Checkbox\n  {{did-insert this.didInsert}}\n  @label={{@method}}\n  @onChange={{this.onChange}}\n  @checked={{this.checked}}\n/>","moduleName":"teamtailor/components/subscription-checkbox.hbs","parseOptions":{"srcName":"teamtailor/components/subscription-checkbox.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export interface SubscriptionCheckboxArgs {
  onChange: (subscriptionCode: string) => void;
  subscriptionName: string;
  method: string;
  pickedSubscriptions: string[];
}

export default class SubsciptionCheckbox extends Component<SubscriptionCheckboxArgs> {
  get pickedSubscriptions() {
    return this.args.pickedSubscriptions;
  }

  @tracked checked = false;

  @action
  didInsert() {
    this.checked = this.pickedSubscriptions.includes(this.subscriptionCode);
  }

  get subscriptionCode() {
    return `${this.args.subscriptionName}.${this.args.method}`;
  }

  @action
  onChange() {
    this.args.onChange(this.subscriptionCode);
    this.checked = this.pickedSubscriptions.includes(this.subscriptionCode);
  }
}
