import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'ember-resources/util/function';
import Store from '@ember-data/store';
import { TodoModel } from 'teamtailor/models';

type CountResponseMeta = {
  due_today: number;
  total_today: number;
  due_tomorrow: number;
  due_later: number;
  total_count: number;
  total_due: number;
  completed?: number;
};

export default class TodoCountersService extends Service {
  @service declare store: Store;

  @tracked _totalTodos = 0;
  @tracked _dueTodayCount: number | null = null;
  @tracked declare _completedCount?: number;
  @tracked declare _dueLaterCount: number;
  @tracked declare _dueTomorrowCount: number;
  @tracked declare _totalTodayCount: number;
  @tracked _totalDue = 0;

  data = trackedFunction(this, async () => {
    if (this._dueTodayCount === null) {
      const todos = await this.store.query('todo', {
        per_page: 1,
      });
      this.setCountersFromMeta(todos.meta);
    }

    return {
      totalTodos: this._totalTodos,
      dueTodayCount: this._dueTodayCount,
      completedCount: this._completedCount,
      dueLaterCount: this._dueLaterCount,
      dueTomorrowCount: this._dueTomorrowCount,
      totalTodayCount: this._totalTodayCount,
      totalDue: this._totalDue,
    };
  });

  get totalTodos() {
    return this.data.value?.totalTodos;
  }

  get dueTodayCount() {
    return this.data.value?.dueTodayCount;
  }

  get completedCount() {
    return this.data.value?.completedCount;
  }

  get dueLaterCount() {
    return this.data.value?.dueLaterCount;
  }

  get dueTomorrowCount() {
    return this.data.value?.dueTomorrowCount;
  }

  get totalTodayCount() {
    return this.data.value?.totalTodayCount;
  }

  get totalDue() {
    return this.data.value?.totalDue;
  }

  setCountersFromMeta(meta: CountResponseMeta) {
    this._dueTodayCount = meta.due_today;
    this._totalTodayCount = meta.total_today;
    this._dueTomorrowCount = meta.due_tomorrow;
    this._dueLaterCount = meta.due_later;
    this._completedCount = meta.completed;
    this._totalTodos = meta.total_count;
    this._totalDue = meta.total_due;
  }

  handleToggledTodo(todo: TodoModel) {
    this._totalTodos += todo.completed ? -1 : 1;
    this.modifyDueGroupCount(todo.dueGroup, todo.completed ? -1 : 1);
  }

  modifyDueGroupCount(group: string, modifier: number) {
    switch (group) {
      case 'today':
        if (this._dueTodayCount !== null) {
          this._dueTodayCount += modifier;
        }

        this._totalTodayCount += modifier;
        break;
      case 'tomorrow':
        this._dueTomorrowCount += modifier;
        break;
      case 'upcoming':
        this._dueLaterCount += modifier;
        break;
      default:
        if (this._completedCount !== undefined) {
          this._completedCount += modifier;
        }
    }
  }

  refresh() {
    this._dueTodayCount = null;
  }
}

declare module '@ember/service' {
  interface Registry {
    'todo-counters': TodoCountersService;
  }
}
