import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import moment from 'moment-timezone';

export default class AnalyticsJobsController extends Controller {
  @service analytics;
  @service router;

  queryParams = ['page', 'filters'];

  get currentRoute() {
    return this.router.currentRouteName.replace('analytics.', '');
  }

  get startDate() {
    return this.analytics.startDateParam;
  }

  set startDate(value) {
    this.analytics.setStartDate(value);
  }

  get endDate() {
    return this.analytics.endDateParam;
  }

  set endDate(value) {
    this.analytics.setEndDate(value);
  }

  get page() {
    return this.analytics.page;
  }

  set page(value) {
    this.analytics.page = value;
  }

  get filters() {
    return this.analytics.encodedFilters;
  }

  set filters(value) {
    this.analytics.encodedFilters = value;
  }

  yesterday() {
    return moment().subtract(1, 'days').startOf('day');
  }

  @action
  navigationChanged(routeName) {
    this.router.transitionTo(routeName);
  }
}
