import { inject as service } from '@ember/service';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { set } from '@ember/object';
import { later } from '@ember/runloop';
import { get } from 'teamtailor/utils/get';

export default class RequisitionRoute extends ScrollToTopRoute {
  @service pusher;
  @service user;
  @service router;
  @service store;

  handleJobCreatedEvent = (data) => {
    this.router.transitionTo('jobs.edit', data.id, {
      queryParams: { template: true },
    });

    later(() => {
      set(this.controllerFor('jobs.requisition'), 'isCreatingJob', false);
    });
  };

  model(params) {
    return this.store.findRecord('requisition', params.requisition_id);
  }

  afterModel() {
    get(this, 'pusher')
      .channel(get(this, 'user.notificationsChannel'))
      .then((channel) => {
        channel.bind('job-created', this.handleJobCreatedEvent);
      });
  }

  deactivate() {
    get(this, 'pusher')
      .channel(get(this, 'user.notificationsChannel'))
      .then((channel) => {
        channel.unbind('job-created', this.handleJobCreatedEvent);
      });
  }
}
