import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SettingsTagsEditController extends Controller {
  @service router;

  get tag() {
    return this.model;
  }

  @action
  handleModalClose() {
    this.router.transitionTo('settings.tags.index');
  }
}
