import Model, {
  hasMany,
  belongsTo,
  attr,
  type AsyncBelongsTo,
  type AsyncHasMany,
} from '@ember-data/model';
import {
  BlogModel,
  DepartmentModel,
  RoleModel,
  TagModel,
  UserModel,
} from 'teamtailor/models';

export default class PostModel extends Model {
  @belongsTo('blog') declare blog: AsyncBelongsTo<BlogModel>;
  @belongsTo('department') declare department: AsyncBelongsTo<DepartmentModel>;
  @belongsTo('role') declare role: AsyncBelongsTo<RoleModel>;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;

  @hasMany('tag') declare tags: AsyncHasMany<TagModel>;

  @attr('boolean') declare draft: boolean;
  @attr('date') declare publishedAt: Date;
  @attr('string') declare body: string;
  @attr('string') declare header: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    post: PostModel;
  }
}
