import { helper } from '@ember/component/helper';

export function hasKey([object, key]: [
  Record<string, unknown> | null,
  string
]) {
  return object && typeof object === 'object' && key in object;
}

export default helper(hasKey);
