import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { and, alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { get, set, action, computed } from '@ember/object';
import {
  countries,
  identityAcceptExtension,
} from 'teamtailor/constants/twilio';
import { scheduleOnce } from '@ember/runloop';

@classic
export default class GetNumberController extends Controller {
  @service server;
  @service intercom;
  @service ttAlert;
  @service intl;
  @service flipper;
  @service current;
  @service router;
  @service store;
  @service flashMessages;

  requiredDocuments = null;
  fileNames = null;

  getTwilioFileObjectByName(keyName) {
    return `twilio.documents.${keyName}`;
  }

  @computed('missingFilesCount')
  get noMissingFiles() {
    return !get(this, 'missingFilesCount');
  }

  @computed(`twilio.documents`, 'requiredDocuments', 'selectedCountry')
  get validateRequiredDocuments() {
    if (!this.requiredDocuments) {
      return true;
    }

    // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
    scheduleOnce('afterRender', () => {
      this.isFilesMissing(this.requiredDocuments);
    });

    return true;
  }

  @and(
    'validateRequiredDocuments',
    'selectedNumberCountry',
    'noMissingFiles',
    'twilio.street',
    'twilio.city',
    'twilio.postalCode',
    'twilio.region'
  )
  formValidate;

  identityAcceptExtension = identityAcceptExtension;
  smsSetupStep = 1;

  @computed('countriesList.[]', 'store')
  get supportedCountriesList() {
    let countryList = countries;
    if (get(this.flipper, 'sms_extended')) {
      countryList.push({
        name: 'Czech Republic',
        code: 'CZ',
        visible: true,
        verifyDocuments: true,
      });
      countryList.push({
        name: 'Belgium',
        code: 'BE',
        visible: true,
        verifyDocuments: true,
      });
    }

    return countryList
      .filter((country) => {
        return country.visible === true;
      })
      .map((country) => {
        const countryModel = this.store.peekRecord('country', country.code);

        if (countryModel) {
          country.name = countryModel.name;
        }

        return country;
      });
  }

  @computed('supportedCountriesList')
  get sortedSupportedCountriesList() {
    return get(this, 'supportedCountriesList').sortBy('name');
  }

  isFilesMissing(requiredDocuments) {
    let missing = 0;

    requiredDocuments
      .filter((d) => d.type === 'document')
      .forEach((d) => {
        const acceptedTypes = d.accepted_documents.map((ad) => ad.type);

        const hasFile = acceptedTypes.some(
          (type) => !!get(this, 'twilio.documents')[type]
        );

        if (!hasFile) {
          missing++;
        }
      });

    set(this, 'missingFilesCount', missing);
  }

  selectedCountry = null;
  selectedNumberCountry = null;

  @alias('model')
  twilio;

  @action
  attachFile(key, url, fileName) {
    const docs = get(this, 'twilio.documents');

    const documentsObj = {
      ...(docs || {}),
      [key]: {
        ...(docs[key] || {}),
        url,
      },
    };

    const fileNames = {
      ...get(this, 'fileNames'),
      [key]: fileName,
    };

    set(this, 'twilio.documents', documentsObj);
    set(this, 'fileNames', fileNames);
  }

  @action
  modalClosedAction() {
    set(this, 'smsSetupStep', 1);
    set(this, 'selectedCountry', null);
    set(this, 'selectedNumberCountry', null);
    this.router.transitionTo('settings.sms');
  }

  @action
  setCountry(country) {
    set(this, 'selectedCountry', country);
    set(this, 'twilio.isoCountry', country.code);
  }

  @action
  setNumberCountry(country) {
    set(this, 'selectedCountry', country);
    set(this, 'selectedNumberCountry', country);
    set(this, 'twilio.isoCountry', country.code);
    set(this, 'twilio.numberCountry', country.code);
    set(this, 'twilio.verifyDocuments', country.verifyDocuments);
  }

  @action
  async goToStep2() {
    const json = await this.server.request(
      `/twilio/requirements?country_code=${this.twilio.isoCountry}`
    );
    set(this, 'requiredDocuments', [
      ...json.end_user,
      ...((json.supporting_document && json.supporting_document[0]) || []),
    ]);

    this.setStep(2);
  }

  @action
  setStep(step) {
    set(this, 'smsSetupStep', step);
  }

  @action
  save() {
    return get(this, 'twilio')
      .save()
      .then(() => {
        get(this, 'store').findRecord('company', this.current.company.id);
        get(this, 'intercom').trackEvent('activated-sms');
        get(this, 'flashMessages').success(
          this.intl.t('settings.sms.get_number.number_created')
        );
        set(this, 'smsSetupStep', 1);
        set(this, 'selectedCountry', null);
        get(this.current.company, 'smsSetting').reload();
        this.router.transitionTo('settings.sms');
      })
      .catch(() => {
        get(this, 'ttAlert').error(
          this.intl.t('settings.sms.get_number.couldnt_assing_number')
        );
      });
  }
}
