import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class SettingsTermsOfServiceSettingController extends Controller {
  @service current;
  @service intl;
  @service store;
  @service router;

  @tracked applyTextPlaceholder = null;
  @tracked connectFutureJobsTextPlaceholder = null;
  @tracked connectTextPlaceholder = null;
  @tracked futureJobsTextPlaceholder = null;
  @tracked sourcedReferredReminder = null;

  constructor() {
    super(...arguments);

    this.reminderOptions = [
      { days: -1, name: this.intl.t('settings.data_privacy.dont_remind_me') },
      { days: 0, name: this.intl.t('settings.data_privacy.immediately') },
      { days: 30, name: this.intl.t('settings.data_privacy.after_30_days') },
      { days: 90, name: this.intl.t('settings.data_privacy.after_90_days') },
    ];
  }

  get managePermissionsUrl() {
    return this.router.urlFor('settings.data_privacy_settings');
  }

  get termsOfServiceSetting() {
    return this.model.termsOfServiceSetting;
  }

  get dataPrivacySetting() {
    return this.model.dataPrivacySetting;
  }

  get neverRemindAboutMissingConsents() {
    if (get(this.dataPrivacySetting, 'sourcedReferredReminder') === -1) {
      return true;
    }

    return undefined;
  }

  @action
  setSourcedReferredReminder(option) {
    set(this, 'dataPrivacySetting.sourcedReferredReminder', option.days);
  }
}
