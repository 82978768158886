import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import TtAlertService from 'teamtailor/services/tt-alert';

export default class SettingsLocationsRegionsEditController extends Controller {
  @service declare intl: IntlService;
  @service declare ttAlert: TtAlertService;
  @service declare router: RouterService;

  @action
  save() {
    return this.model.save().then(() => {
      this.router.transitionTo('settings.locations.index');
    });
  }

  @action
  delete() {
    this.ttAlert.confirm(this.intl.t('settings.regions.confirm_delete'), () => {
      this.model.destroyRecord().then(() => {
        this.router.transitionTo('settings.locations.index');
      });
    });
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.locations.regions.edit': SettingsLocationsRegionsEditController;
  }
}
