import { validatePresence } from 'ember-changeset-validations/validators';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import { isEmpty } from '@ember/utils';

function validateJobOnLeadPage() {
  return (key, newValue, oldValue, changes, content) => {
    if (content.pageType !== 'lead') return true;

    const hasJob = !isEmpty(newValue);
    return hasJob || buildMessage(key, { type: 'leadRequiresJob' });
  };
}

export default {
  title: validatePresence(true),
  pageType: validatePresence(true),
  job: validateJobOnLeadPage(),
};
