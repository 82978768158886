import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import CurrentService from 'teamtailor/services/current';

export default class HasCopilotFeature extends Helper {
  @service declare current: CurrentService;

  compute([copilotFeatureName]: [string]): boolean {
    return this.current.company.hasCopilotFeature(copilotFeatureName);
  }
}
