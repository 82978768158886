import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import AnalyticsService from 'teamtailor/services/analytics';
import IntlService from 'ember-intl/services/intl';
import { get } from 'teamtailor/utils/get';
import InsightsExportService from 'teamtailor/services/insights-export';
import Current from 'teamtailor/services/current';
import FlipperService from 'teamtailor/services/flipper';

export default class AnalyticsOverviewNpsController extends Controller {
  @service declare analytics: AnalyticsService;
  @service declare intl: IntlService;
  @service declare insightsExport: InsightsExportService;
  @service declare current: Current;
  @service declare flipper: FlipperService;

  get isLoading() {
    const {
      perStage,
      perStageType,
      distribution,
      movingAverage,
      npsResponses,
      comparison,
    } = this.model;

    return (
      perStage.isRunning ||
      perStageType.isRunning ||
      distribution.isRunning ||
      movingAverage.isRunning ||
      npsResponses.isRunning ||
      comparison?.isRunning
    );
  }

  get hasSentNPSSurveys() {
    return get(this.model.npsSurveys, 'length') > 0;
  }

  get hasAnsweredNPSSurveys() {
    return this.model.npsResponses.value?.rows.length > 0;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'analytics.overview.nps': AnalyticsOverviewNpsController;
  }
}
