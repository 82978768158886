import { get } from 'teamtailor/utils/get';
import { userBackground } from 'teamtailor/helpers/user-background';
import TeamModel from 'teamtailor/models/team';
import UserModel from 'teamtailor/models/user';

const totalMaxLength = 65;
const maxLengthsWithStatus = [32, 15, 18]; // max lengths of nameOrEmail, username, status;
const maxLengthsWithoutStatus = [45, 20]; // max lengths of nameOrEmail, username;

function truncatedText(
  text: string,
  newLength: number = 0,
  maxLength: number = 0
) {
  if (text.length <= maxLength) {
    return text;
  }

  return `${text.substring(0, newLength - 3)}...`;
}

function truncatedTexts(
  nameOrEmail: string,
  username: string | undefined,
  status: string | undefined
) {
  const maxLengths = status ? maxLengthsWithStatus : maxLengthsWithoutStatus;
  const strings = [nameOrEmail, username, status].filter(
    (str) => typeof str === 'string'
  ) as string[];
  const lengths = strings.map((str) => str.length || 0);
  let totalLength = lengths.reduce((a, b) => a + b, 0);

  if (totalLength <= totalMaxLength) {
    return strings;
  }

  let excess = totalLength - totalMaxLength;
  let reductions = lengths.map((len, i) =>
    Math.min(
      excess * (len / totalLength),
      Math.max(0, len - (maxLengths[i] || 0))
    )
  );
  let newLengths = lengths.map((len, i) =>
    Math.floor(len - (reductions[i] || 0))
  );

  while (newLengths.reduce((a, b) => a + b, 0) > totalMaxLength) {
    newLengths = newLengths.map((len, i) =>
      len > (maxLengths[i] || 0) ? len - 1 : len
    );
  }

  return strings.map((str, i) =>
    truncatedText(str, newLengths[i], maxLengths[i])
  );
}

function nameElement(name: string): string {
  return `<span class='body-text-s-semibold'>${name}</span>`;
}

function usernameElement(username: string): string {
  return `<span class='body-text-xs-regular text-neutral-medium'>${username}</span>`;
}

function containerElement(text: string): string {
  return `<span class='flex body-text-s-regular text-neutral gap-4'>${text}</span>`;
}

function userText(user: UserModel): string {
  const { nameOrEmail, username } = user;
  let statusText: string | undefined;
  if (user.hasActiveStatus) {
    statusText = `${user.activeStatus?.emoji} ${user.activeStatus?.status}`;
  }

  const [truncatedName, truncatedUsername, truncatedStatus] = truncatedTexts(
    nameOrEmail,
    username,
    statusText
  );

  const nameEl = nameElement(truncatedName || '');

  let userText = nameEl;

  if (user.username) {
    const usernameText = `(${truncatedUsername})${
      user.hasActiveStatus ? ' - ' : ''
    }`;
    const usernameEl = usernameElement(usernameText);
    userText += usernameEl;
  }

  if (user.hasActiveStatus) {
    userText += truncatedStatus;
  }

  return containerElement(userText);
}

function teamText(team: TeamModel): string {
  const nameEl = nameElement(team.name);
  const usernameEl = usernameElement(`(${team.username})`);

  return containerElement(nameEl + usernameEl);
}

function onlineBeaconElement(): string {
  return `<span
      class="z-2 absolute rounded-full bg-success-strong outline outline-2 outline-white size-6"
      style="top:-1px;right:6px;"
    >
    </span>`;
}

function userImageElement(
  user: UserModel,
  includeOnlineBeacon: boolean
): string {
  let element = `<img
    src="${get(user, 'avatarUrl')}"
    class="size-20 mr-8 rounded ${userBackground([user.id])}"
  >`;

  if (includeOnlineBeacon && user.isOnline && !user.hideOnlineStatus) {
    element += onlineBeaconElement();
  }

  return `<span class="relative">${element}</span>`;
}

export default function mentionItem(
  item: TeamModel | UserModel,
  includeOnlineBeacon: boolean
): string {
  if (item instanceof UserModel) {
    return userImageElement(item, includeOnlineBeacon) + userText(item);
  } else {
    return `<span class="size-20 mr-8">${item.emoji}</span>${teamText(item)}`;
  }
}
