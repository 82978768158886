import Service, { inject as service } from '@ember/service';
import { timeout, restartableTask } from 'ember-concurrency';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, get } from '@ember/object';

const LOCAL_STORAGE_KEY = 'quick-navigation-history';
const HISTORY_COUNT = 3;

export default class QuickNavigationHistoryService extends Service {
  @service user;
  history = null;

  @computed('user.id')
  get localStorageKey() {
    return `${LOCAL_STORAGE_KEY}-${get(this.user, 'id')}`;
  }

  constructor() {
    super(...arguments);
    this.history = JSON.parse(localStorage.getItem(this.localStorageKey)) || [];
  }

  add(query) {
    query = query.trim();
    if (query === '') {
      return;
    }

    this.history.removeObject(query);
    this.history.unshiftObject(query);

    if (this.history.length > HISTORY_COUNT) {
      this.history.setObjects(this.history.slice(0, HISTORY_COUNT));
    }

    localStorage.setItem(this.localStorageKey, JSON.stringify(this.history));
  }

  clear() {
    this.history.clear();
    localStorage.removeItem(this.localStorageKey);
  }

  addTask = restartableTask(async (query, delay = 0) => {
    await timeout(delay);
    this.add(query);
  });
}
