import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import CompanyModel from 'teamtailor/models/company';

export default class AutoJoinDomainModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @attr('string') declare domain: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'auto-join-domain': AutoJoinDomainModel;
  }
}
