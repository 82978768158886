import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"[&_.ba-videoplayer-video-container]:h-full\"\n  local-class=\"{{if @isAnonymousStage 'ziggeoPlayer--blurred'}}\"\n>\n  <div\n    {{did-insert this.handleVideoElDidInsert}}\n    class=\"{{unless this.videoIsLoaded 'opacity-0'}}\"\n  ></div>\n</div>", {"contents":"<div\n  class=\"[&_.ba-videoplayer-video-container]:h-full\"\n  local-class=\"{{if @isAnonymousStage 'ziggeoPlayer--blurred'}}\"\n>\n  <div\n    {{did-insert this.handleVideoElDidInsert}}\n    class=\"{{unless this.videoIsLoaded 'opacity-0'}}\"\n  ></div>\n</div>","moduleName":"teamtailor/components/ziggeo-player.hbs","parseOptions":{"srcName":"teamtailor/components/ziggeo-player.hbs"}});
import { initZiggeoScriptTag } from './../utils/ziggeo';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import TimeFormatService from 'teamtailor/services/time-format';
import TtZiggeo from 'teamtailor/services/tt-ziggeo';

export default class ZiggeoPlayerComponent extends Component<{
  videoToken: string;
  answerId: string;
}> {
  @service declare timeFormat: TimeFormatService;
  @service declare ttZiggeo: TtZiggeo;

  @tracked
  videoIsLoaded = false;

  @action
  async handleVideoElDidInsert(el: HTMLElement) {
    const scriptTagPromise = initZiggeoScriptTag(this.timeFormat.locale);
    await scriptTagPromise;

    let clientAuthToken = await this.ttZiggeo.clientAuthTokenAnswer(
      this.args.answerId
    );

    const player = new ZiggeoApi.V2.Player({
      element: el,
      attrs: {
        video: this.args.videoToken,
        'client-auth': clientAuthToken,
      },
    });
    player.on('loaded', () => {
      this.videoIsLoaded = true;
    });
    player.activate();
  }
}
