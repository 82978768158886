import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { dropTask, task, timeout } from 'ember-concurrency';
import JobModel from 'teamtailor/models/job';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import CurrentService from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import IntlService from 'ember-intl/services/intl';
import ChannelActivationModel from 'teamtailor/models/channel-activation';
import { getOwner } from '@ember/application';
import DateRange from 'teamtailor/utils/date-range';
import PromotionModel from 'teamtailor/models/promotion';
import ReportAnalyticsRequest, {
  getClickhousePageviewsTransitionDate,
} from 'teamtailor/classes/analytics/report-analytics-request';
import { gql } from '@apollo/client/core';
import FlipperService from 'teamtailor/services/flipper';
import ChannelModel from 'teamtailor/models/channel';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';

const isFulfilled = <T>(
  p: PromiseSettledResult<T>
): p is PromiseFulfilledResult<T> => p.status === 'fulfilled';

export const INSIGHTS_GA_QUERY = gql`
  query GoogleAnalyticsPromotionsQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
  ) {
    googleAnalyticsQuery {
      promotions(dateRange: $dateRange, jobIds: $jobIds, groupByJob: true) {
        promotionId
        views: sessions
        jobId
      }
    }
  }
`;

export const INSIGHTS_PAGEVIEWS_QUERY = gql`
  query PromotionsQuery($dateRange: DateRangeAttributes!, $jobIds: [ID!]) {
    pageviewQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      filters: { promotionId: { exists: true } }
    ) {
      aggregated(groupBy: [JOB_ID, PROMOTION]) {
        promotionId
        jobId
        views: distinctCount(field: SESSION_ID)
      }
    }
  }
`;

export interface InsightsResponse {
  promotionId: string;
  jobId: string;
  views: number;
}

export interface IChannelSelectItem {
  id: string;
  name: string;
}

export default class PromotionsController extends Controller {
  @service declare store: Store;
  @service declare current: CurrentService;
  @service declare intl: IntlService;
  @service declare flipper: FlipperService;

  queryParams = [
    'page',
    'query',
    'sort',
    'current_user_jobs',
    'promotion_channel_id',
    'promoted',
    {
      excludeExpired: 'exclude_expired',
    },
    'status',
  ];

  @tracked query = '';
  @tracked page = 1;
  @tracked per_page = 10;
  @tracked status = 'published';
  @tracked current_user_jobs = false;
  @tracked defaultSort = 'pinned-desc';
  @tracked sort = this.defaultSort;
  @tracked excludeExpired = false;
  @tracked declare promotion_channel_id: string | null;
  @tracked promoted = false;
  @tracked declare totalPages: number;
  @tracked declare totalCount: number;
  @tracked channels: ChannelModel[] = [];

  @computedLocalStorage(Boolean, 'PromotionsIndex.showFilterSidebar', true)
  declare showFilterSidebar: boolean;

  get searchParams() {
    return {
      query: this.query,
      recruiter_or_team_member: this.current_user_jobs,
      promotion_channel_id: this.promotion_channel_id,
      sort: this.sort,
      promoted: this.promoted,
      status: this.status === 'published' ? 'published' : this.status,
      exclude_expired: this.excludeExpired,
    };
  }

  declare model: ChannelActivationModel[];

  @action async fetchAvailableChannels() {
    this.channels = await this.store.query('channel', {
      with_promotions: true,
    });
  }

  @tracked jobs: JobModel[] = [];
  @tracked promotions: PromotionModel[] = [];
  @tracked insights: InsightsResponse[] = [];

  get promotionsCount() {
    return this.totalCount;
  }

  get filterSelected() {
    return (
      this.current_user_jobs ||
      this.promotion_channel_id ||
      this.excludeExpired ||
      this.status
    );
  }

  get availableChannels() {
    const channelNames = this.channels
      .map((channel) => ({
        name: get(channel, 'name'),
        id: get(channel, 'id'),
      }))
      .filter((channel) => channel.name);

    return [{ name: this.intl.t('common.all'), id: 'all' }, ...channelNames];
  }

  @action onChannelChange(channel: IChannelSelectItem) {
    if (channel.id === 'all') {
      this.promotion_channel_id = null;
    } else {
      this.promotion_channel_id = channel.id;
    }

    this.resetPage();
  }

  @action onStatusChange(status: { id: string }) {
    this.status = status.id;
  }

  @action onSortChange(sort: { id: string; label: string }) {
    this.sort = sort.id;
    this.resetPage();
  }

  @action setPage(page: number) {
    this.page = page;
  }

  @action resetPage() {
    this.page = 1;
  }

  get showPagination() {
    return !this.isLoading && this.totalPages > 1;
  }

  fetchJobs = dropTask(async () => {
    const params = {
      ...this.searchParams,
      page: this.page,
      per_page: this.per_page,
      sort: this.sort,
    };

    const jobs = await this.store.query('job', params);
    const { total_pages, total_count } = jobs.meta as {
      total_pages: number;
      total_count: number;
    };
    const jobIds = jobs.mapBy('id');

    if (jobIds.length) {
      this.promotions = await this.store.query('promotion', {
        job_ids: jobIds,
      });
    }

    this.totalPages = total_pages;
    this.totalCount = total_count;
    this.jobs = jobs.toArray();
    this.insights = await this.insightsRequests();
  });

  @action async insightsRequests() {
    const transitionDate = getClickhousePageviewsTransitionDate(this.flipper);
    const container = getOwner(this);

    const queryData = [
      {
        query: INSIGHTS_PAGEVIEWS_QUERY,
        path: 'pageviewQuery.aggregated',
        timeStampType: 'after',
      },
      {
        query: INSIGHTS_GA_QUERY,
        path: 'googleAnalyticsQuery.promotions',
        timeStampType: 'before',
      },
    ];
    const jobIds = this.jobs.mapBy('id');
    const response = await Promise.allSettled<InsightsResponse[]>(
      queryData.map(({ query, path, timeStampType }) =>
        new ReportAnalyticsRequest({
          container,
          query,
          callback: (result) => get(result, path) || [],
        }).fetch({
          jobIds,
          [timeStampType]: transitionDate,
          dateRange: new DateRange(
            this.current.company.went_live_at,
            new Date().toISOString()
          ),

          context: {
            resourceType: 'promotion-dashboard',
          },
        })
      )
    );
    return this.handleInsightsResponse(response);
  }

  @action handleInsightsResponse(
    insightsResponse: PromiseSettledResult<InsightsResponse[]>[]
  ) {
    const responses = insightsResponse
      .filter(isFulfilled)
      .map(({ value }) => {
        // objects in response are frozen, hence the copy
        return value.map((e) => ({ ...e }));
      })
      .flat();

    return responses.reduce<InsightsResponse[]>((acc, curr) => {
      const entry = acc.find(
        ({ promotionId }) => curr.promotionId === promotionId
      );
      if (entry) {
        entry.views += curr.views;
      } else {
        acc.push(curr);
      }

      return acc;
    }, []);
  }

  handleSearchInput = task(
    { restartable: true },
    async (value: string | undefined) => {
      await timeout(100);

      if (value?.length) {
        this.query = value;
        this.resetPage();
      } else {
        this.query = '';
      }
    }
  );

  get isLoading() {
    return this.fetchJobs.isRunning;
  }

  isLoadingJobsArray = Array.from({ length: 2 });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    promotions: PromotionsController;
  }
}
