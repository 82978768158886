import { camelize, underscore } from '@ember/string';
import { QueryType } from '../base-query';
import BaseProperty, { BasePropertyOptions } from './base-property';
import moment, { Moment } from 'moment-timezone';
import EqualsFilter from '../filters/equals';
import IntlService from 'ember-intl/services/intl';

export default class DateProperty extends BaseProperty {
  declare intl?: IntlService;

  constructor(options: BasePropertyOptions) {
    super({
      ...options,
      type: 'date',
      columnType: options.id === 'date' ? 'date' : 'text',
    });
    this.operators = [new EqualsFilter()];
  }

  getKey(_queryType: QueryType) {
    let field: string;
    switch (this.id) {
      case 'week':
        field = 'iso_year_week';
        break;
      case 'month':
        field = 'iso_year_month';
        break;
      case 'year':
        field = 'year';
        break;
      case 'date':
      default:
        field = 'date';
        break;
    }

    return underscore(field).toUpperCase();
  }

  getField(queryType: QueryType) {
    return (
      this.getKey(queryType) && camelize(this.getKey(queryType).toLowerCase())
    );
  }

  translate(intl: IntlService) {
    this.intl = intl;
    return super.translate(intl);
  }

  format(value?: string) {
    if (!value) return value;

    let year: number, week: number;

    switch (this.id) {
      case 'week':
        year = parseInt(value.toString().substring(0, 4));
        week = parseInt(value.toString().substring(4));

        return moment()
          .year(year)
          .isoWeek(week)
          .format(this.intl?.t('insights.common.week_formatting'));
      case 'month':
        return moment(`${value}01`).format('MMMM YYYY');

      default:
        return value;
    }
  }

  isCompatibleWithBaseProperty(property: BaseProperty) {
    return property.type !== 'date';
  }

  isCompatibleWithAdditionalBaseProperty(property: BaseProperty) {
    return property.type !== 'date';
  }

  sorter(a: any, b: any) {
    let aMoment: Moment, bMoment: Moment;
    const weekFormatting = this.intl?.t('insights.common.week_formatting');
    switch (this.id) {
      case 'week':
        aMoment = moment(a[this.columnName], weekFormatting);
        bMoment = moment(b[this.columnName], weekFormatting);
        break;
      case 'month':
        aMoment = moment(a[this.columnName], 'MMMM YYYY');
        bMoment = moment(b[this.columnName], 'MMMM YYYY');
        break;
      default:
        aMoment = moment(a[this.columnName]);
        bMoment = moment(b[this.columnName]);
        break;
    }

    return aMoment.valueOf() - bMoment.valueOf();
  }
}
