import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<CompareCandidates::Cell\n  @classNames={{if this.isText \"align-top\"}}\n  @index={{@index}}\n  @pinnedCount={{@pinnedCount}}\n  @jobApplication={{@jobApplication}}\n  @offset={{@offset}}\n  @isHidden={{@isHidden}}\n  @isHovered={{@isHovered}}\n  @toggleContent={{fn (toggle \"showFullText\" this)}}\n  @isContentTruncated={{this.isContentTruncated}}\n  @showFullContent={{this.showFullText}}\n>\n  {{#if this.isText}}\n    {{this.textContent}}\n  {{else if this.isMultiSelect}}\n    {{#each this.optionValues as |value|}}\n      <Core::Badge class=\"max-w-[200px] overflow-hidden\">\n        {{value}}\n      </Core::Badge>\n    {{/each}}\n  {{else}}\n    {{this.formattedValue}}\n  {{/if}}\n</CompareCandidates::Cell>", {"contents":"<CompareCandidates::Cell\n  @classNames={{if this.isText \"align-top\"}}\n  @index={{@index}}\n  @pinnedCount={{@pinnedCount}}\n  @jobApplication={{@jobApplication}}\n  @offset={{@offset}}\n  @isHidden={{@isHidden}}\n  @isHovered={{@isHovered}}\n  @toggleContent={{fn (toggle \"showFullText\" this)}}\n  @isContentTruncated={{this.isContentTruncated}}\n  @showFullContent={{this.showFullText}}\n>\n  {{#if this.isText}}\n    {{this.textContent}}\n  {{else if this.isMultiSelect}}\n    {{#each this.optionValues as |value|}}\n      <Core::Badge class=\"max-w-[200px] overflow-hidden\">\n        {{value}}\n      </Core::Badge>\n    {{/each}}\n  {{else}}\n    {{this.formattedValue}}\n  {{/if}}\n</CompareCandidates::Cell>","moduleName":"teamtailor/components/compare-candidates/custom-field-cell.hbs","parseOptions":{"srcName":"teamtailor/components/compare-candidates/custom-field-cell.hbs"}});
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';
import IntlService from 'ember-intl/services/intl';
import JobApplicationModel from 'teamtailor/models/job-application';
import PickedCustomFieldModel from 'teamtailor/models/picked-custom-field';

type CompareCandidatesCustomFieldCellComponentArgs = {
  valueItem: ValueItem;
  isPinned: boolean;
  isHovered: boolean;
  jobApplicationId: string;
  offset: string;
};

type ValueItem = {
  jobApplication: JobApplicationModel;
  pickedCustomField: PickedCustomFieldModel | undefined;
};

const TEXT_VALUE_CHARACTER_LIMIT = 140;

export default class CompareCandidatesCellComponent extends Component<CompareCandidatesCustomFieldCellComponentArgs> {
  @service declare intl: IntlService;
  @tracked showFullText = false;

  get pickedCustomField(): PickedCustomFieldModel | undefined {
    return this.args.valueItem.pickedCustomField;
  }

  get isText() {
    return this.pickedCustomField?.type === 'CustomField::Text';
  }

  get isMultiSelect() {
    return this.pickedCustomField?.type === 'CustomField::MultiSelect';
  }

  get isContentTruncated(): boolean {
    const text = (this.pickedCustomField?.value || '') as string;
    return text.length > TEXT_VALUE_CHARACTER_LIMIT;
  }

  get textContent() {
    return this.showFullText || !this.isContentTruncated
      ? this.pickedCustomField?.value
      : `${(this.pickedCustomField?.value as string).substring(
          0,
          TEXT_VALUE_CHARACTER_LIMIT - 3
        )}...`;
  }

  get formattedValue() {
    const type = this.pickedCustomField?.type;
    if (type === 'CustomField::Checkbox') {
      return this.booleanValue;
    } else if (type === 'CustomField::Date') {
      return this.dateValue;
    } else if (type === 'CustomField::Select') {
      return this.optionValues[0];
    } else {
      return this.pickedCustomField?.value;
    }
  }

  get booleanValue(): string {
    return this.intl.t(`common.${this.pickedCustomField?.value === 'true'}`);
  }

  get dateValue(): string {
    return moment(this.pickedCustomField?.value).format('YYYY-MM-DD');
  }

  get optionValues(): string[] | string {
    const customFieldOptions = get(
      this.pickedCustomField?.customField,
      'options'
    );
    return (this.pickedCustomField?.value as string[]).map(
      (optionId: string) => {
        return customFieldOptions?.findBy('id', optionId)?.value || '';
      }
    );
  }
}
