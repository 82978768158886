import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import MeetingRoute from 'teamtailor/routes/meetings';
import { Transition } from 'teamtailor/utils/type-utils';
import { ErrorResponse } from '@apollo/client/link/error';
import { action } from '@ember/object';
import FlashMessageService from 'teamtailor/services/flash-message';

import MeetingLoaderNewGraphql from 'teamtailor/services/meeting-loader-new-graphql';
import RouterService from '@ember/routing/router-service';

export interface NewMeetingsQueryParams {
  candidateId?: string;
  jobId?: string;
  bulkOptions?: string;
}

export default class MeetingNewRoute extends Route {
  @service declare router: RouterService;
  @service declare meetingLoaderNewGraphql: MeetingLoaderNewGraphql;
  @service declare flashMessages: FlashMessageService;

  queryParams = {
    candidateId: {
      refreshModel: true,
    },

    jobId: {
      refreshModel: true,
    },

    bulkOptions: {
      refreshModel: true,
    },
  };

  meetingRouteRouteName = MeetingRoute.routeName; // is overriden when candidate modal route is in background
  candidateRouteRouteName = '';
  jobRouteRouteName = '';

  getParams(queryParams: NewMeetingsQueryParams): NewMeetingsQueryParams {
    let { candidateId, jobId } = queryParams;
    const { bulkOptions } = queryParams;

    if (!candidateId && this.candidateRouteRouteName) {
      const candidateModel = this.modelFor(this.candidateRouteRouteName);
      if (candidateModel) {
        candidateId = candidateModel.id;
      }
    }

    if (!jobId && this.jobRouteRouteName) {
      const jobModel = this.modelFor(this.jobRouteRouteName);
      if (jobModel) {
        jobId = jobModel.id;
      }
    }

    return {
      candidateId,
      jobId,
      bulkOptions,
    };
  }

  model(params: NewMeetingsQueryParams) {
    return this.getParams(params);
  }

  @action
  error(error: ErrorResponse, transition: Transition): void {
    if (error.graphQLErrors?.length) {
      this.flashMessages.error(
        error.graphQLErrors.map((m) => m.message).join('\n'),
        {
          sticky: true,
          preventDuplicates: false,
        }
      );
      if (transition.from) {
        transition.abort();
      } else {
        this.router.transitionTo('dashboard');
      }
    }
  }
}
