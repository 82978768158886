import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import { CompanyModel } from 'teamtailor/models';

export default class EmployeeDashboardModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @attr('string') declare introMessage: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'employee-dashboard': EmployeeDashboardModel;
  }
}
