import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import { Transition } from 'teamtailor/utils/type-utils';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import { ErrorResponse } from '@apollo/client/link/error';
import { action } from '@ember/object';
import MeetingEventController from 'teamtailor/controllers/meetings/events/show';
import MeetingEventModel from 'teamtailor/models/meeting-event';
import FlashMessageService from 'teamtailor/services/flash-message';
import Store from '@ember-data/store';

export interface ShowMeetingQueryParams {
  meeting_id: string;
  meeting_event_id: string;
}

export default class MeetingEventShowRoute extends Route {
  @service declare router: RouterService;
  @service declare apollo: TeamtailorApolloService;
  @service declare flashMessages: FlashMessageService;
  @service declare store: Store;

  model(queryParams: ShowMeetingQueryParams): ShowMeetingQueryParams {
    return queryParams;
  }

  setupController(
    controller: MeetingEventController,
    model: MeetingEventModel,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);
    controller.fromCreate = !!transition.from?.name.includes('meetings.new');
  }

  @action
  error(error: ErrorResponse, transition: Transition): void {
    if (error.graphQLErrors?.length) {
      this.flashMessages.error(
        error.graphQLErrors.map((m) => m.message).join('\n'),
        {
          sticky: true,
          preventDuplicates: false,
        }
      );
      if (transition.from) {
        transition.abort();
      } else {
        this.router.transitionTo('dashboard');
      }
    }
  }
}
