import { attr } from '@ember-data/model';
import TriggerModel from 'teamtailor/models/trigger';

export default class TriggerNurtureModel extends TriggerModel<'nurture'> {
  @attr('string') declare nurtureCampaignId: string;
  @attr('string') declare nurtureCampaignName: string;

  get valid() {
    return !!this.nurtureCampaignId;
  }

  get description() {
    return this.nurtureCampaignName;
  }

  get triggerContent() {
    return this.nurtureCampaignName;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'trigger/nurture': TriggerNurtureModel;
  }
}
