import Route from '@ember/routing/route';

export default class TemplatesRoute extends Route {
  setupController(controller, model) {
    super.setupController(controller, model);
    controller.loadTemplates.perform();
  }

  resetController(controller, isExiting) {
    if (isExiting && controller.instantlyCreateJob) {
      controller.instantlyCreateJob = false;
    }
  }
}
