import Service, { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';

export default class RouteHelper extends Service {
  @service declare router: RouterService;

  get parentRoute() {
    if (
      this.router.currentRouteName.includes('jobs.job.stages.index.candidate')
    ) {
      return 'jobs.job.stages.index.candidate';
    }

    if (
      this.router.currentRouteName.includes(
        'jobs.job.compare-candidates.candidate'
      )
    ) {
      return 'jobs.job.compare-candidates.candidate';
    }

    if (this.router.currentRouteName.includes('candidates.segment.candidate')) {
      return 'candidates.segment.candidate';
    }

    if (this.router.currentRouteName.includes('jobs.job.stages.index')) {
      return 'jobs.job.stages.index';
    }

    if (this.router.currentRouteName.includes('candidates.segment')) {
      return 'candidates.segment';
    }

    if (this.router.currentRouteName.includes('candidates.candidate')) {
      return 'candidates.candidate';
    }

    if (this.router.currentRouteName.includes('jobs.job.activity.candidate')) {
      return 'jobs.job.activity.candidate';
    }

    if (
      this.router.currentRouteName.includes(
        'nurture-campaigns.nurture-campaign.recipients.candidate'
      )
    ) {
      return 'nurture-campaigns.nurture-campaign.recipients.candidate';
    }

    if (
      this.router.currentRouteName.includes(
        'analytics.overview.team-activity.candidate'
      )
    ) {
      return 'analytics.overview.team-activity.candidate';
    }

    return undefined;
  }

  getNestedRoute(route: string) {
    if (this.parentRoute !== undefined) {
      return `${this.parentRoute}.${route}`;
    }

    return route;
  }
}

declare module '@ember/service' {
  interface Registry {
    'route-helper': RouteHelper;
  }
}
