import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Molecules::PipelineSpeed\n  ...attributes\n  @headingText={{t \"insights.widgets.job_pipeline_speed.title\"}}\n  @stagesAndTimes={{this.stagesAndTimes}}\n  @jobAverageDays={{this.jobAverageDays}}\n  @companyAverageDays={{this.companyAverageDays}}\n  @loading={{or (not this.fetchData.lastSuccessful) this.fetchData.isRunning}}\n  @onShowExportModal={{this.onShowExportModal}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>", {"contents":"<Insights::Molecules::PipelineSpeed\n  ...attributes\n  @headingText={{t \"insights.widgets.job_pipeline_speed.title\"}}\n  @stagesAndTimes={{this.stagesAndTimes}}\n  @jobAverageDays={{this.jobAverageDays}}\n  @companyAverageDays={{this.companyAverageDays}}\n  @loading={{or (not this.fetchData.lastSuccessful) this.fetchData.isRunning}}\n  @onShowExportModal={{this.onShowExportModal}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>","moduleName":"teamtailor/components/insights/widgets/job-pipeline-speed.hbs","parseOptions":{"srcName":"teamtailor/components/insights/widgets/job-pipeline-speed.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { gql } from '@apollo/client/core';
import moment from 'moment-timezone';
import round from 'teamtailor/utils/round';

const QUERY = gql`
  query JobPipelineSpeedQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
  ) {
    eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [REJECTED, MOVED]
    ) {
      jobAverage: average(field: TIME_IN_STAGE)

      stages: aggregated(groupBy: [FROM_STAGE_NAME, FROM_STAGE_INDEX]) {
        name: stageFromName
        index: stageFromIndex

        count

        average(field: TIME_IN_STAGE)
        min(field: TIME_IN_STAGE)
        max(field: TIME_IN_STAGE)
      }
    }
    companyAverage: eventQuery(
      dateRange: $dateRange
      eventTypes: [REJECTED, MOVED]
    ) {
      timeInStage: average(field: TIME_IN_STAGE)
    }
  }
`;

const EXPORT_TABLE_COLUMNS = [
  {
    type: 'text',
    propertyName: 'name',
    headerKey: 'insights.widgets.job_pipeline_speed.stage',
  },
  {
    type: 'number',
    propertyName: 'minDays',
    headerKey: 'insights.widgets.job_pipeline_speed.min_days',
    digits: 1,
  },
  {
    type: 'number',
    propertyName: 'maxDays',
    headerKey: 'insights.widgets.job_pipeline_speed.max_days',
    digits: 1,
  },
  {
    type: 'number',
    propertyName: 'avgDays',
    headerKey: 'insights.widgets.job_pipeline_speed.avg_days',
    digits: 1,
  },
];

export default class InsightsWidgetsJobPipelineSpeed extends Component {
  @service insights;
  @service insightsExport;

  get stagesAndTimes() {
    return this.fetchData.lastSuccessful.value.stagesAndTimes;
  }

  get companyAverageDays() {
    return this.fetchData.lastSuccessful.value.companyAverageDays;
  }

  get jobAverageDays() {
    return this.fetchData.lastSuccessful.value.jobAverageDays;
  }

  @action
  onShowExportModal() {
    this.insightsExport.sortDirection = 'asc';
    this.insightsExport.showModal(
      'insights.widgets.job_pipeline_speed.title',
      `job-${this.args.job.id}`,
      EXPORT_TABLE_COLUMNS,
      this.retrieveExportData,
      this.args.dateRange,
      { sortKey: 'index', createdAt: this.args.job.createdAt }
    );
  }

  @action
  retrieveExportData(dateRange) {
    return this.queryForData(dateRange).then((result) => result.stagesAndTimes);
  }

  async queryForData(dateRange) {
    const { job } = this.args;

    const result = await this.insights.query({
      query: QUERY,
      variables: {
        dateRange: dateRange.asObject,
        jobIds: [job.id],
      },

      context: {
        jobId: job.id,
      },
    });

    const {
      eventQuery: { stages, jobAverage },
      companyAverage: { timeInStage: companyAverage },
    } = result;

    return {
      jobAverageDays: this.convertToDays(jobAverage),

      companyAverageDays: this.convertToDays(companyAverage),

      stagesAndTimes: stages
        .map((stage) => {
          return {
            name: stage.name,
            index: stage.index,
            minDays: this.convertToDays(stage.min),
            maxDays: this.convertToDays(stage.max),
            avgDays: this.convertToDays(stage.average),
            totalCount: stage.count,
          };
        })
        .sort((a, b) => a.index - b.index)
        .reduce((acc, stage) => {
          let item = acc.find((item) => item.name === stage.name);
          if (item) {
            item.minDays = Math.min(item.minDays, stage.minDays);
            item.maxDays = Math.max(item.maxDays, stage.maxDays);
            item.avgDays =
              (item.avgDays * item.totalCount +
                stage.avgDays * stage.totalCount) /
              Math.max(item.totalCount + stage.totalCount, 1);
          } else {
            acc.push(stage);
          }

          return acc;
        }, []),
    };
  }

  fetchData = dropTask(async () => {
    const { stagesAndTimes, jobAverageDays, companyAverageDays } =
      await this.queryForData(this.args.dateRange);

    return {
      jobAverageDays,
      companyAverageDays,
      stagesAndTimes: stagesAndTimes
        .map((stage) => {
          return {
            ...stage,
            value: stage.avgDays,
          };
        })
        .sortBy('index'),
    };
  });

  convertToDays(seconds) {
    return round(moment.duration(seconds, 'seconds').asDays());
  }
}
