import Model, {
  belongsTo,
  hasMany,
  attr,
  AsyncBelongsTo,
  AsyncHasMany,
} from '@ember-data/model';
import { ISelfSchedule } from 'teamtailor/components/meeting/types';
import { get } from 'teamtailor/utils/get';
import {
  MeetingEventAttendeeModel,
  InterviewKitModel,
  MeetingEventLocationModel,
  MeetingModel,
  JobModel,
} from 'teamtailor/models';
import Current from 'teamtailor/services/current';
import { inject as service } from '@ember/service';

export default class MeetingEventModel extends Model {
  @service declare current: Current;

  @belongsTo('meeting')
  declare meeting: AsyncBelongsTo<MeetingModel>;

  @hasMany('meeting-event-attendee')
  declare meetingEventAttendees?: AsyncHasMany<MeetingEventAttendeeModel>;

  @belongsTo('meeting-event-location')
  declare meetingEventLocation: AsyncBelongsTo<MeetingEventLocationModel>;

  @belongsTo('interview-kit')
  declare interviewKit: AsyncBelongsTo<InterviewKitModel>;

  @belongsTo('job')
  declare job?: AsyncBelongsTo<JobModel>;

  @attr declare summary: string;
  @attr declare parsedSummary: string;
  @attr declare description: string;
  @attr declare meetingId: number;
  @attr declare uuid: string;
  @attr declare tzid: string;
  @attr declare bookedSlotStartsAt?: Date;
  @attr declare bookedSlotEndsAt?: Date;
  @attr declare interviewKitId?: number;
  @attr declare feedbackReminder?: boolean;
  @attr declare selfSchedule?: ISelfSchedule;

  get candidateAttendees() {
    return this.meetingEventAttendees?.filter(
      (a) => a.candidate && get(a.candidate, 'id')
    );
  }

  get userAttendees() {
    return this.meetingEventAttendees?.filter(
      (a) => a.user && get(a.user, 'id')
    );
  }

  get meetingRoomAttendees() {
    return this.meetingEventAttendees?.filter((a) => get(a.meetingRoom, 'id'));
  }

  get userIds() {
    return this.userAttendees?.map((a) => a.user && get(a.user, 'id'));
  }

  get candidateIds() {
    return this.candidateAttendees?.map(
      (a) => a.candidate && get(a.candidate, 'id')
    );
  }

  get meetingRoomIds() {
    return this.meetingRoomAttendees?.map((a) => get(a.meetingRoom, 'id'));
  }

  get organizer(): MeetingEventAttendeeModel | undefined {
    return this.userAttendees?.find((a) => a.isOrganizer);
  }

  get bookedMeetingRoom() {
    if (this.meetingRoomAttendees?.length === 1) {
      return this.meetingRoomAttendees.firstObject?.meetingRoom;
    }

    return undefined;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'meeting-event': MeetingEventModel;
  }
}
