import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { CandidateModel, CompanyModel } from 'teamtailor/models';

export default class SourceModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @hasMany('candidate') declare candidates: AsyncHasMany<CandidateModel>;

  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    source: SourceModel;
  }
}
