import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<p class=\"mb-0\">\n  {{t\n    \"components.trigger_restrict_form.header_text_with_suffix\"\n    headerText=this.headerText\n    htmlSafe=true\n  }}\n</p>\n\n<p class=\"mb-0\">\n  {{t\n    \"components.trigger_restrict_form.how_restricted_candidates_work\"\n    htmlSafe=true\n  }}\n  <ExternalLink\n    @href=\"https://support.teamtailor.com/en/articles/5772858-restricted-candidates\"\n    @title={{t \"common.learn_more\"}}\n  />\n</p>\n\n<TriggerAdvancedOptions @model={{@model}} />", {"contents":"<p class=\"mb-0\">\n  {{t\n    \"components.trigger_restrict_form.header_text_with_suffix\"\n    headerText=this.headerText\n    htmlSafe=true\n  }}\n</p>\n\n<p class=\"mb-0\">\n  {{t\n    \"components.trigger_restrict_form.how_restricted_candidates_work\"\n    htmlSafe=true\n  }}\n  <ExternalLink\n    @href=\"https://support.teamtailor.com/en/articles/5772858-restricted-candidates\"\n    @title={{t \"common.learn_more\"}}\n  />\n</p>\n\n<TriggerAdvancedOptions @model={{@model}} />","moduleName":"teamtailor/components/trigger-restrict-form.hbs","parseOptions":{"srcName":"teamtailor/components/trigger-restrict-form.hbs"}});
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class extends Component {
  @service intl;

  get headerText() {
    if (this.args.model.onReject) {
      return this.intl.t('mixins.trigger_form.header_text_on_reject', {
        stageName: get(this.args.model.stage, 'name'),
        htmlSafe: true,
      });
    } else {
      return this.intl.t('mixins.trigger_form.header_text_on_move', {
        stageName: get(this.args.model.stage, 'name'),
        htmlSafe: true,
      });
    }
  }
}
