import Route from '@ember/routing/route';
import RejectReasonsReport from 'teamtailor/classes/analytics/report-reject-reasons';
import { getOwner } from '@ember/application';

export default class IndexRoute extends Route {
  model() {
    return {
      rejectReasons: new RejectReasonsReport({
        container: getOwner(this),
      }).fetchTask,
    };
  }
}
