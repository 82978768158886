import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { get, action } from '@ember/object';

export default class IndexController extends Controller {
  @service store;
  @service intl;
  @service ttAlert;
  @service router;
  @service flashMessages;

  @action
  onOauthSuccess({ authorization: data }) {
    get(this, 'store')
      .createRecord('slack-app', {
        slackChannelId: data.slackChannelId,
        slackChannelName: decodeURIComponent(data.slackChannelName),
        slackConfigurationUrl: decodeURIComponent(data.slackConfigurationUrl),

        slackIncomingWebhook: decodeURIComponent(data.slackIncomingWebhook),

        slackTeamId: data.slackTeamId,
        slackTeamName: data.slackTeamName,
        slackUserId: data.slackUserId,
        token: data.token,
      })
      .save()
      .then((slackApp) => {
        this.router.transitionTo(
          'settings.integrations.slack_apps.edit',
          slackApp
        );
      });
  }

  @action
  onOauthFailure() {
    get(this, 'flashMessages').error(
      this.intl.t('errors.something_went_wrong')
    );
  }
}
