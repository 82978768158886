function idsInBatches(modelIds: string[], batchSize: number) {
  const numberOfBatches = Math.ceil(modelIds.length / batchSize);

  return Array.from({ length: numberOfBatches }).map((_, batchIndex) => {
    const start = batchIndex * batchSize;
    const end = start + batchSize;

    return modelIds.slice(start, end);
  });
}

async function fetchInBatches<T, ResponseT>(
  ids: string[],
  fetchCallback: (idsInBatch: string[]) => Promise<ResponseT>,
  combineCallback: (acc: T[], response: ResponseT) => T[],
  batchSize = 50
) {
  const batches = idsInBatches(ids, batchSize);

  const promises = batches.map((idsInBatch) => {
    return fetchCallback(idsInBatch);
  });

  const responses: ResponseT[] = await Promise.all(promises);

  return responses.reduce(combineCallback, []);
}

export default fetchInBatches;
