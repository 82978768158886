import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::DropdownMenu\n  @text={{this.statusButtonText}}\n  @appearance=\"secondary\"\n  @triggerFor=\"action\"\n  @isDisabled={{@isDisabled}}\n  as |menu|\n>\n  <menu.select\n    @text={{t \"meeting_events.show.footer.accept\"}}\n    @selected={{eq @attendee.status \"accepted\"}}\n    @dot={{\"lime\"}}\n    {{on \"click\" (queue (fn this.updateStatus \"ACCEPTED\") menu.api.close)}}\n  />\n  <menu.select\n    @text={{t \"meeting_events.show.footer.maybe\"}}\n    @selected={{eq @attendee.status \"tentative\"}}\n    @dot={{\"amber\"}}\n    {{on \"click\" (queue (fn this.updateStatus \"TENTATIVE\") menu.api.close)}}\n  />\n  <menu.select\n    @text={{t \"meeting_events.show.footer.decline\"}}\n    @selected={{eq @attendee.status \"declined\"}}\n    @dot={{\"red\"}}\n    {{on \"click\" (queue (fn this.updateStatus \"DECLINED\") menu.api.close)}}\n  />\n</Core::DropdownMenu>", {"contents":"<Core::DropdownMenu\n  @text={{this.statusButtonText}}\n  @appearance=\"secondary\"\n  @triggerFor=\"action\"\n  @isDisabled={{@isDisabled}}\n  as |menu|\n>\n  <menu.select\n    @text={{t \"meeting_events.show.footer.accept\"}}\n    @selected={{eq @attendee.status \"accepted\"}}\n    @dot={{\"lime\"}}\n    {{on \"click\" (queue (fn this.updateStatus \"ACCEPTED\") menu.api.close)}}\n  />\n  <menu.select\n    @text={{t \"meeting_events.show.footer.maybe\"}}\n    @selected={{eq @attendee.status \"tentative\"}}\n    @dot={{\"amber\"}}\n    {{on \"click\" (queue (fn this.updateStatus \"TENTATIVE\") menu.api.close)}}\n  />\n  <menu.select\n    @text={{t \"meeting_events.show.footer.decline\"}}\n    @selected={{eq @attendee.status \"declined\"}}\n    @dot={{\"red\"}}\n    {{on \"click\" (queue (fn this.updateStatus \"DECLINED\") menu.api.close)}}\n  />\n</Core::DropdownMenu>","moduleName":"teamtailor/components/meeting/attendee-status-actions.hbs","parseOptions":{"srcName":"teamtailor/components/meeting/attendee-status-actions.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import IntlService from 'ember-intl/services/intl';

import UPDATE_ATTENDEE_STATUS from 'teamtailor/gql/update-meeting-event-attendee.graphql';
import MeetingEventAttendeeModel from 'teamtailor/models/meeting-event-attendee';

interface Args {
  attendee: MeetingEventAttendeeModel;
}

export default class AttendeeStatusActions extends Component<Args> {
  @service declare intl: IntlService;
  @service declare apollo: TeamtailorApolloService;

  get statusButtonText(): string {
    const currentStatus = this.args.attendee.status.toLowerCase();
    if (currentStatus === 'needs_action') {
      return this.intl.t('components.meetings.status.your_status');
    }

    return this.intl.t(`components.meetings.status.${currentStatus}`);
  }

  @action
  async updateStatus(status: string) {
    const variables = {
      attendeeId: this.args.attendee.id,
      status,
    };

    await this.apollo.mutate({
      mutation: UPDATE_ATTENDEE_STATUS,
      variables,
    });

    this.args.attendee.reload();
  }
}
