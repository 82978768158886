import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Modal @isOpen={{@isOpen}} @onClose={{@onClose}} @size=\"small\" as |modal|>\n  <modal.Header\n    @text={{t \"candidates.candidate.bulk_actions.export_candidates\"}}\n  />\n  <modal.Main as |main|>\n    <main.Section>\n      <p class=\"mb-0\">\n        {{t \"candidates.candidate.bulk_actions.export_email_hint\"}}\n      </p>\n    </main.Section>\n  </modal.Main>\n  <modal.Footer>\n    <StatusButton\n      @action={{this.exportCandidates}}\n      @text={{t \"candidates.candidate.bulk_actions.export_and_send\"}}\n    />\n  </modal.Footer>\n</Core::Modal>", {"contents":"<Core::Modal @isOpen={{@isOpen}} @onClose={{@onClose}} @size=\"small\" as |modal|>\n  <modal.Header\n    @text={{t \"candidates.candidate.bulk_actions.export_candidates\"}}\n  />\n  <modal.Main as |main|>\n    <main.Section>\n      <p class=\"mb-0\">\n        {{t \"candidates.candidate.bulk_actions.export_email_hint\"}}\n      </p>\n    </main.Section>\n  </modal.Main>\n  <modal.Footer>\n    <StatusButton\n      @action={{this.exportCandidates}}\n      @text={{t \"candidates.candidate.bulk_actions.export_and_send\"}}\n    />\n  </modal.Footer>\n</Core::Modal>","moduleName":"teamtailor/components/bulk-actions/candidates/export.hbs","parseOptions":{"srcName":"teamtailor/components/bulk-actions/candidates/export.hbs"}});
import BaseComponent from './base';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import { findMatchingSelectedColumnRecord } from 'teamtailor/utils/candidate-table/column';

export default class ExportComponent extends BaseComponent {
  @service flashMessages;
  @service current;

  get user() {
    return this.current.user;
  }

  @action
  exportCandidates() {
    return this.postAction(
      'bulk_export',
      this.postData(
        this.args.sortedColumns
          ? {
              selectedColumnIds: this.args.sortedColumns
                .map((column) => {
                  let selectedColumnRecords = get(
                    this.user,
                    'selectedCandidateDisplayColumns'
                  ).toArray();

                  const selectedColumnRecordMatchingName =
                    findMatchingSelectedColumnRecord(
                      column,
                      selectedColumnRecords
                    );

                  return get(selectedColumnRecordMatchingName, 'id');
                })
                .filter(Boolean),
            }
          : {}
      )
    ).then(() => {
      this.args.onClose();
      this.flashMessages.success(
        this.intl.t(
          'components.bulk_actions.candidates.exported_candidates_count',
          { count: this.args.selectedCount }
        )
      );
    });
  }
}
