import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';

export default class NewRoute extends Route {
  @service current;
  @service iframePreview;

  model() {
    return get(this.current.company, 'locations').createRecord();
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    get(this, 'iframePreview').scrollToSection({
      sectionId: get(this.current.company, 'sectionsByName.locations.id'),
    });
  }

  resetController(controller) {
    let model = get(controller, 'model');
    if (get(model, 'isNew')) {
      model.destroyRecord();
    }
  }
}
