import Store from '@ember-data/store';
import {
  FilterType,
  FilterOption,
  FilterOperator,
  FilterValue,
  IFilter,
  SerializableFilterValue,
} from 'teamtailor/components/fancy-filters.d';
import {
  deserializeValue,
  SerializedModel,
  serializeValue,
} from 'teamtailor/utils/custom-report-request-filter';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';

export default class SerializableFilter implements IFilter {
  @tracked filter: FilterOption;
  @tracked selectedOperator?: FilterOperator;
  @tracked value: FilterValue;

  @tracked isOpen = false;

  constructor(filter: FilterOption) {
    this.filter = filter;
    this.value = '';

    if (this.filter.operators.length === 1) {
      this.selectedOperator = this.filter.operators.firstObject;
    }
  }

  get name(): string {
    return this.filter.name;
  }

  get isValid(): boolean {
    if (this.filterType === 'bool') {
      return isPresent(this.selectedOperator);
    }

    return isPresent(this.selectedOperator) && isPresent(this.value);
  }

  get options(): string[] {
    return this.filter.options || [];
  }

  get filterType(): FilterType {
    return this.filter.type;
  }

  get operators(): FilterOperator[] {
    return this.filter.operators;
  }

  get serialize(): string {
    return JSON.stringify({
      filter: this.filter,
      selectedOperator: this.selectedOperator,
      value: JSON.stringify(
        serializeValue(this.value as SerializableFilterValue)
      ),

      isOpen: this.isOpen,
    });
  }
}

export async function deserialize(
  store: Store,
  input: string,
  groupAnalytics: boolean
): Promise<SerializableFilter> {
  const result: any = JSON.parse(input);
  const filter = new SerializableFilter(result.filter);
  filter.selectedOperator = result.selectedOperator;
  filter.isOpen = result.isOpen;
  filter.value = (await deserializeValue(
    store,
    JSON.parse(result.value) as
      | boolean
      | string
      | string[]
      | SerializedModel
      | SerializedModel[],
    groupAnalytics
  )) as FilterValue;

  return filter;
}
