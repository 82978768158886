import Component from '@glimmer/component';
import { action } from '@ember/object';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import NurtureCampaignStepEmailValidations from 'teamtailor/validations/nurture-campaign-step/email';
import NurtureCampaignStepWaitValidations from 'teamtailor/validations/nurture-campaign-step/wait';
import { reject } from 'rsvp';
import { task, enqueueTask, timeout } from 'ember-concurrency';
import { get } from 'teamtailor/utils/get';

export default class NurtureCampaignStepBase extends Component {
  changeset = null;

  get isEmailType() {
    return this.args.step.stepType === 'email';
  }

  get isWaitType() {
    return this.args.step.stepType === 'wait';
  }

  get validations() {
    if (this.isEmailType) {
      return NurtureCampaignStepEmailValidations;
    }

    if (this.isWaitType) {
      return NurtureCampaignStepWaitValidations;
    }
  }

  constructor() {
    super(...arguments);
    this.changeset = new Changeset(
      this.args.step,
      lookupValidator(this.validations),
      this.validations
    );
  }

  delayedCloseTask = task(async () => {
    await timeout(800);
    this.onClose();
  });

  saveTask = enqueueTask(async () => {
    await this.changeset.validate();

    if (this.changeset.isValid) {
      await this.changeset.save();
      this.delayedCloseTask.perform();
      return true;
    } else {
      return reject();
    }
  });

  @action
  handleCancel() {
    this.changeset.rollback();
    if (get(this.step, 'isNew')) {
      this.args.onDelete(this.step);
    } else {
      this.args.onClose();
    }
  }

  @action
  handleDelete() {
    this.args.onDelete(this.step);
  }

  @action
  handleSave() {
    return this.saveTask.perform();
  }
}
