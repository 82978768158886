import Controller from '@ember/controller';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Router from 'teamtailor/router';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';

export default class SettingsWebhooksNewController extends Controller {
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare flashMessages: FlashMessageService;

  @action
  async save() {
    try {
      await this.model.save();
      this.flashMessages.success(
        this.intl.t('settings.webhooks.webhook_saved')
      );
      this.router.transitionTo('settings.webhooks');
    } catch (error) {
      this.flashMessages.error(
        this.intl.t('settings.webhooks.could_not_create_webhook')
      );
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.webhooks.new': SettingsWebhooksNewController;
  }
}
