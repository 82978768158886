import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { set, get } from '@ember/object';

export default class ContentRoute extends Route {
  @service current;
  @service pusher;
  @service iframePreview;
  @service store;

  setupController(controller, model) {
    super.setupController(controller, model);

    const channelName = get(this.current.company, 'contentChannel');
    get(this, 'pusher')
      .subscribe(channelName)
      .then((channel) => {
        set(this, 'channel', channel);

        channel.bind('page-updated', (event) => {
          this.store.pushPayload(event);
        });

        channel.bind('page-count-updated', (event) => {
          this.store.pushPayload(event);
        });

        channel.bind('department-updated', (event) => {
          this.store.pushPayload(event);
        });

        channel.bind('location-updated', (event) => {
          this.store.pushPayload(event);
        });

        channel.bind('video-transcoded', (video) => {
          this.store.pushPayload(video);
          get(this, 'iframePreview').reloadIframe();
        });
      });
  }

  resetController() {
    let channel = get(this, 'channel');
    if (channel) {
      channel.unbind('page-updated');
      channel.unbind('location-updated');
      channel.unbind('video-transcoded');
    }
  }
}
