import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Icon\n  @icon={{@icon}}\n  @style={{@style}}\n  @size={{if (eq this.size \"small\") \"16\" \"20\"}}\n  class={{class-names (concat \"text-icon-decorative-\" this.color \"-weak\")}}\n/>", {"contents":"<Icon\n  @icon={{@icon}}\n  @style={{@style}}\n  @size={{if (eq this.size \"small\") \"16\" \"20\"}}\n  class={{class-names (concat \"text-icon-decorative-\" this.color \"-weak\")}}\n/>","moduleName":"teamtailor/components/core/badge/icon.hbs","parseOptions":{"srcName":"teamtailor/components/core/badge/icon.hbs"}});
import Component from '@glimmer/component';
import { verifyArg } from 'teamtailor/utils/verify-arg';

const DEFAULT_SIZE = 'small';
const ALLOWED_SIZES = ['medium', DEFAULT_SIZE] as const;

type AllowedSizes = (typeof ALLOWED_SIZES)[number];

const DEFAULT_COLOR = 'zinc';

export type Args = {
  size?: AllowedSizes;
  icon: string;
  color?: string;
};

export default class IconComponent extends Component<Args> {
  get size(): AllowedSizes {
    verifyArg(this.args.size, ALLOWED_SIZES, 'Badge::Icon @size', DEFAULT_SIZE);

    return this.args.size ?? DEFAULT_SIZE;
  }

  get color(): string {
    return this.args.color ?? DEFAULT_COLOR;
  }
}
