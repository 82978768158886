import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import CareerSiteLanguageBaseRoute from 'teamtailor/routes/career-site-language-base';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import SettingsDepartmentsEditController from 'teamtailor/controllers/settings/departments/edit';

export default class NewRoute extends CareerSiteLanguageBaseRoute {
  @service declare store: Store;
  @service declare current: Current;

  model() {
    return this.store.createRecord('department', {
      startPageHeadline: get(this.current.company, 'departmentHeadlineDefault'),
      languageCode: this.defaultLanguageCode,
    });
  }

  afterModel() {
    this.controllerName = 'settings.departments.edit';
  }

  resetController(
    controller: SettingsDepartmentsEditController,
    isExiting: boolean
  ) {
    if (isExiting && get(controller.model, 'isNew')) {
      get(controller.model, 'pickedQuestions').invoke('unloadRecord');

      if (this.current.company.hasFeature('career_site_languages')) {
        get(controller.model, 'translations').invoke('unloadRecord');
      }

      controller.model.unloadRecord();
    }
  }
}
