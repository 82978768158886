import { inject as service } from '@ember/service';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get, set } from '@ember/object';
import ENV from 'teamtailor/config/environment';

export default class FacebookRoute extends ScrollToTopRoute {
  @service flashMessages;
  @service intl;
  @service router;

  facebookAppId = ENV.facebook.appId;

  beforeModel() {
    if (!this.facebookAppId) {
      this.router.replaceWith('settings');
    }
  }

  afterModel(model) {
    const queryParams = this._getQueryStringParams();
    const paramKeys = Object.keys(queryParams);

    if (queryParams.error_code) {
      get(this, 'flashMessages').error(
        this.intl.t('errors.something_went_wrong')
      );
    }

    let pageIdKey = paramKeys.find((key) => key.indexOf('tabs_added[') > -1);
    if (pageIdKey) {
      this._updateFacebookPageId(model, pageIdKey);
    }
  }

  deactivate() {
    let controller = get(this, 'controller');
    controller.current.company.rollbackAttributes();
  }

  _getQueryStringParams() {
    let params = {};
    let parser = document.createElement('a');
    parser.href = window.location.href;
    let query = parser.search.substring(1);
    let vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }

    return params;
  }

  _updateFacebookPageId(model, pageIdKey) {
    const regexp = /\[(.+)\]/;
    const [, pageId] = pageIdKey.match(regexp);

    set(model, 'facebookPageId', pageId);

    return model.save().then(() => {
      get(this, 'flashMessages').success(
        this.intl.t('settings.facebook_app.page_was_installed')
      );
    });
  }
}
