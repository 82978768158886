import Model, { attr } from '@ember-data/model';

export default class AuditLogExportModel extends Model {
  @attr('string')
  declare status: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'audit-log-export': AuditLogExportModel;
  }
}
