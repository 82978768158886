import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { allSettled, task } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';
import { LocationModel } from 'teamtailor/models';
import TranslationLocationModel from 'teamtailor/models/translation/location';
import SettingsLocationNewRoute from 'teamtailor/routes/settings/locations/new';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import { get } from 'teamtailor/utils/get';
import { ModelFrom } from 'teamtailor/utils/type-utils';

export default class SettingsLocationNewController extends Controller {
  declare model: ModelFrom<SettingsLocationNewRoute>;

  @service declare intl: IntlService;
  @service declare current: Current;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;

  @action
  onChange(location: LocationModel) {
    set(this, 'model', location);
  }

  saveTranslation = task(async (model: TranslationLocationModel) => {
    return model.save();
  });

  saveDepartment = task(async () => {
    try {
      return await this.model.save();
    } catch {
      this.flashMessages.error(
        this.intl.t('settings.locations.couldnt_add_new_location')
      );
      return Promise.reject();
    }
  });

  @action
  async save() {
    await this.model.save();

    const translationTasks = get(this.model, 'translations').map((t) => {
      return this.saveTranslation.perform(t);
    });
    const result = await allSettled(translationTasks);

    if (result.isAny('state', 'rejected')) {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
      return Promise.reject();
    } else {
      this.flashMessages.success(
        this.intl.t('settings.locations.new_location_added')
      );
      this.router.replaceWith('settings.locations.index');
      return Promise.resolve();
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.locations.new': SettingsLocationNewController;
  }
}
