import Route from '@ember/routing/route';
import FeatureModel, { FeatureCategoryType } from 'teamtailor/models/feature';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import FeatureShowRoute from 'teamtailor/routes/features/show';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { underscore } from '@ember/string';

interface QueryParams {
  feature_id: string;
}

type ModelResponse = {
  feature: FeatureModel | undefined;
  readonly idsInCategory: string[];
  category: FeatureCategoryType;
};

export default class FeaturesShowFeatureRoute extends Route {
  @service declare store: Store;

  model(params: QueryParams): ModelResponse {
    const { feature_id } = params;

    const parentModel: ModelFrom<FeatureShowRoute> =
      this.modelFor('features.show');

    const feature = this.store
      .peekAll('feature')
      .findBy('internalName', underscore(feature_id));

    return {
      feature,
      category: parentModel.category,
      idsInCategory: parentModel.features.toArray().mapBy('id'),
    };
  }
}
