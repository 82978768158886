export default function classnames() {
  let classes = [];

  for (let i = 0; i < arguments.length; i++) {
    const argument = arguments[i];

    if (typeof argument === 'object') {
      for (const key in argument) {
        if (argument[key]) {
          classes.push(key);
        }
      }
    } else if (argument) {
      classes.push(argument);
    }
  }

  return classes.join(' ');
}
