import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<h4\n  class={{class-names\n    \"heading-4 col-span-2 mt-24 text-white\"\n    opacity-20=this.isDisabled\n  }}\n>\n  {{@text}}\n</h4>", {"contents":"<h4\n  class={{class-names\n    \"heading-4 col-span-2 mt-24 text-white\"\n    opacity-20=this.isDisabled\n  }}\n>\n  {{@text}}\n</h4>","moduleName":"teamtailor/components/editor/adjustments/header.hbs","parseOptions":{"srcName":"teamtailor/components/editor/adjustments/header.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class EditorAdjustmentsHeader extends Component {
  @service blockAdjustments;

  get isDisabled() {
    return this.blockAdjustments.isEditing;
  }
}
