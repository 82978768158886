import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get, action } from '@ember/object';
import { task, timeout, restartableTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { argDefault } from 'teamtailor/utils/arg-default';

const noop = () => {};

export default class BulkActionsBase extends Component {
  @service bulkApi;
  @service batchJob;
  @service intl;
  @service flashMessages;

  classNameBindings = ['c-bulk-actions'];

  @argDefault clearSelection = noop;
  @argDefault showButtons = false;
  @argDefault showRejected = false;
  @argDefault selectAll = false;
  @argDefault selectedIds = [];
  @argDefault deselectedIds = [];
  @argDefault searchParams = null;
  @argDefault bulkCallbackAction = noop;
  @argDefault bulkDeleteCallbackAction = noop;
  @argDefault onBulkActionConfirm = noop;

  @tracked shareEmails = null;
  @tracked currentModal = null;

  get hasSelectedIds() {
    return this.selectedIds.length;
  }

  get hasSelectedModels() {
    return this.selectAll || this.hasSelectedIds;
  }

  get hasNoSelectedModels() {
    return !this.hasSelectedModels;
  }

  get visible() {
    return this.hasSelectedModels || this.showButtons;
  }

  get canCreateTags() {
    return get(this, 'user.canCreateTags');
  }

  get shouldRenderDOM() {
    return (
      this.shouldDisplay ||
      (this.args.selectedCount === undefined ? 0 : this.args.selectedCount) > 0
    );
  }

  get shouldDisplay() {
    return this.args.selectedCount > 0;
  }

  messageTask = restartableTask(async () => {
    await timeout(30000);
    if (this.postTask.isRunning) {
      this.flashMessages.notice(
        this.intl.t('mixins.bulk_actions_base.might_take_while'),
        { sticky: true }
      );
    }
  });

  postTask = task(async (action, data, scope = 'candidates') => {
    this.messageTask.perform();
    await this.bulkApi.post(action, data, scope);
    this.flashMessages.clearMessages();
  });

  get bulkOptionsQuery() {
    return this.searchParams?.query || this.args.query;
  }

  get bulkOptions() {
    if (this.selectAll) {
      return {
        query: this.bulkOptionsQuery,
        deselected_ids: this.deselectedIds,
      };
    } else {
      return {
        selected_ids: this.selectedIds,
      };
    }
  }

  postAction(action, data, scope = 'candidates') {
    return this.postTask.perform(action, data, scope);
  }

  postData(data = {}) {
    return Object.assign(this.bulkOptions, data);
  }

  @action
  openModal(modalName) {
    if (modalName === 'Export') {
      this.shareEmails = get(this, 'user.email');
    }

    this.currentModal = modalName;
  }
}
