import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Molecules::Widget\n  ...attributes\n  @title={{if @title @title (t \"reports.pipeline_conversion.title\")}}\n  @showMoreLink=\"analytics.overview.pipeline-conversions\"\n  @empty={{eq this.data.length 0}}\n  @emptyEmoji=\"🏝️\"\n  @loading={{@isLoading}}\n  @class=\"overflow-visible\"\n>\n  <:content>\n    <Insights::Molecules::PipelineConversionChart\n      @data={{this.data}}\n      @shrink={{true}}\n    />\n    <p class=\"body-text-s mt-12 text-neutral-medium\">\n      {{t\n        \"reports.pipeline_conversion.widget_cohort_description\"\n        startDate=(moment-format @startDate \"YYYY-MM-DD\")\n        endDate=(moment-format @endDate \"YYYY-MM-DD\")\n      }}\n    </p>\n  </:content>\n</Insights::Molecules::Widget>", {"contents":"<Insights::Molecules::Widget\n  ...attributes\n  @title={{if @title @title (t \"reports.pipeline_conversion.title\")}}\n  @showMoreLink=\"analytics.overview.pipeline-conversions\"\n  @empty={{eq this.data.length 0}}\n  @emptyEmoji=\"🏝️\"\n  @loading={{@isLoading}}\n  @class=\"overflow-visible\"\n>\n  <:content>\n    <Insights::Molecules::PipelineConversionChart\n      @data={{this.data}}\n      @shrink={{true}}\n    />\n    <p class=\"body-text-s mt-12 text-neutral-medium\">\n      {{t\n        \"reports.pipeline_conversion.widget_cohort_description\"\n        startDate=(moment-format @startDate \"YYYY-MM-DD\")\n        endDate=(moment-format @endDate \"YYYY-MM-DD\")\n      }}\n    </p>\n  </:content>\n</Insights::Molecules::Widget>","moduleName":"teamtailor/components/insights/widgets/pipeline-conversion.hbs","parseOptions":{"srcName":"teamtailor/components/insights/widgets/pipeline-conversion.hbs"}});
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import {
  DataRow,
  ProcessedStageTypeRow,
  formatData,
  inflateStageTypeRows,
  removeEmptyInProcess,
} from 'teamtailor/utils/insights/pipeline-conversion';

type Args = {
  data?: ProcessedStageTypeRow[];
  loading?: boolean;
};

export default class PipelineConversionsWidget extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;

  get data(): DataRow[] {
    if (!this.args.data) return [];

    let rows = [...this.args.data];
    const stageTypes = this.store.peekAll('stage-type').toArray();

    rows = inflateStageTypeRows(rows, stageTypes);
    return removeEmptyInProcess(
      formatData(rows, (row) => this.intl.t(`job.stage_types.${row.stageType}`))
    );
  }
}
