export default {
  "root": "EmptyState__root_14ak8w",
  "rootThin": "EmptyState__rootThin_14ak8w",
  "emoji": "EmptyState__emoji_14ak8w",
  "withCircle": "EmptyState__withCircle_14ak8w",
  "heading": "EmptyState__heading_14ak8w",
  "title": "EmptyState__title_14ak8w",
  "description": "EmptyState__description_14ak8w",
  "light": "EmptyState__light_14ak8w"
};
