import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class ApplicationFormRoute extends ScrollToTopRoute {
  @service current;

  afterModel() {
    super.afterModel();

    if (this.current.company.hasFeature('surveys')) {
      get(this.current.company, 'surveys').reload();
    }
  }
}
