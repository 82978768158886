import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { get } from 'teamtailor/utils/get';

export default class JobRoute extends Route {
  @service store;
  @service ttAlert;
  @service intl;
  @service recentVisits;
  @service user;
  @service router;
  @service flashMessages;
  @service pusher;

  activate() {
    const jobController = this.controllerFor('jobs.job');
    jobController.notifyPropertyChange('sidebarToShow');
  }

  async model(params) {
    const jobId = params.job_id.split('-')[0];
    try {
      return this.store.findRecord('job', jobId);
    } catch (e) {
      if (e.errors[0].status === '404') {
        this.flashMessages.error(this.intl.t('jobs.index.job_not_found'));
        this.router.transitionTo('jobs');
      }
    }
  }

  async afterModel(model, transition) {
    if (!get(model, 'userHasAccess')) {
      if (transition.from) {
        get(this, 'ttAlert').error(
          this.intl.t('jobs.index.dont_have_access_to_job')
        );
        transition.abort();
      } else {
        get(this, 'flashMessages').error(
          this.intl.t('jobs.index.dont_have_access_to_job')
        );
        this.router.replaceWith('dashboard');
      }
    }

    const isDraftOrPending =
      get(model, 'isDraft') ||
      get(model, 'isAwaitingApproval') ||
      get(model, 'isAwaitingPublish');

    const isApprovingOrRejecting = [
      'jobs.job.approvals.approve',
      'jobs.job.approvals.reject',
    ].includes(get(transition, 'targetName'));

    if (isDraftOrPending && !isApprovingOrRejecting) {
      this.router.replaceWith('jobs.edit', model);
    }

    let promises = [
      get(model, 'jobDetail'),
      get(this, 'user'),
      get(model, 'user'),
      get(model, 'teamMembers'),
    ];

    return RSVP.all(promises).then(() => this.recentVisits.log(model));
  }

  async setupController(controller, model, transition) {
    super.setupController(controller, model, transition);

    controller.pusherChannel = await this.pusher.subscribe(model.pusherChannel);
    controller.bindChannelEvents();
    controller.fetchActivities();
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.pusherChannel.unsubscribe();
    }
  }
}
