import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#animated-if this.showLabel use=this.transition initialInsertion=true}}\n  <Core::Badge\n    @text={{t \"components.new_label.new\"}}\n    @size=\"tiny\"\n    @color=\"green\"\n    ...attributes\n  />\n{{/animated-if}}", {"contents":"{{#animated-if this.showLabel use=this.transition initialInsertion=true}}\n  <Core::Badge\n    @text={{t \"components.new_label.new\"}}\n    @size=\"tiny\"\n    @color=\"green\"\n    ...attributes\n  />\n{{/animated-if}}","moduleName":"teamtailor/components/new-label.hbs","parseOptions":{"srcName":"teamtailor/components/new-label.hbs"}});
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import { parallel } from 'ember-animated';
import move from 'ember-animated/motions/move';
import { fadeIn } from 'ember-animated/motions/opacity';
import { boolArg } from 'teamtailor/utils/bool-arg';

export default class NewLabelComponent extends Component {
  // eslint-disable-next-line require-yield
  transition = function* ({ insertedSprites }) {
    if (boolArg(this.args.animateIn)) {
      for (let sprite of insertedSprites) {
        sprite.startTranslatedBy(0, 3);
        parallel(
          move(sprite, { duration: 200 }),
          fadeIn(sprite, { from: 0, duration: 200 })
        );
      }
    }
  }.bind(this);

  get showLabel() {
    return this.args.startDate
      ? moment(this.args.startDate).add(2, 'weeks').isAfter(new Date())
      : false;
  }
}
