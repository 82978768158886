import Store from '@ember-data/store';
import Controller, { inject as controller } from '@ember/controller';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import itemStyles from 'teamtailor/components/block-editor-item.module.scss';
import { restartableTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Current from 'teamtailor/services/current';
import TtAlertService from 'teamtailor/services/tt-alert';
import IframePreviewService from 'teamtailor/services/iframe-preview';
import IntlService from 'ember-intl/services/intl';
import ContentEditorController from 'teamtailor/controllers/content/editor';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import ContentEditorIndexRoute from 'teamtailor/routes/content/editor/index';
import {
  DepartmentModel,
  JobModel,
  LocationModel,
  PageModel,
  SectionModel,
} from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import RouterService from '@ember/routing/router-service';
import { justAssert } from 'teamtailor/utils/justAssert';
import { afterRender } from 'ember-ani-mate';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';
import FlashMessageService from 'teamtailor/services/flash-message';

const KEY_CODE_ESCAPE = 27;

export default class ContentEditorIndexController extends Controller {
  queryParams = ['returnedFromSectionId'];

  declare model: ModelFrom<ContentEditorIndexRoute>;
  @service declare router: RouterService;
  @service declare current: Current;
  @service declare ttAlert: TtAlertService;
  @service declare iframePreview: IframePreviewService;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;

  @tracked returnedFromSectionId?: string;

  @tracked editedSectionId?: string;

  @controller('content.editor')
  declare contentEditorController: ContentEditorController;

  el?: HTMLElement;
  returnToListData?: { scrollTop: number; sectionIdOrName?: string };

  get company() {
    return this.current.company;
  }

  get sortedSections() {
    return this.sections.sortBy('rowOrder');
  }

  get draggableSections() {
    return this.sortedSections.filterBy('isDragable');
  }

  get fixedSections() {
    return this.sortedSections.rejectBy('isDragable');
  }

  get modelType() {
    return this.model.editorModel.pageRecord?.constructor.modelName;
  }

  get isHomePage() {
    return this.page.isHomePage;
  }

  get isJobsPage() {
    return this.page.isJobListPage;
  }

  get isDepartmentPage() {
    return this.page.isDepartmentListPage;
  }

  get isTemplatePage() {
    return this.page.isTemplatePage;
  }

  get isPostListPage() {
    return this.page.isPostListPage;
  }

  get page() {
    return (this.modelType === 'page' &&
      this.model.editorModel.pageRecord) as PageModel;
  }

  fetchSectionsTask = restartableTask(async () => {
    await this.page.hasMany('sections').reload();
  });

  get sections() {
    let sections = get(this.page, 'sections')?.toArray();
    if (!sections) {
      return [];
    }

    sections = sections.filter((item) => {
      if (this.isHomePage) {
        return !item.department.content;
      }

      return true;
    });

    return sections.rejectBy('globalSection');
  }

  get pageTitle() {
    let { title } = this.page;

    if (this.isHomePage) {
      title = this.intl.t('content.index.home');
    } else if (this.isJobsPage) {
      title = this.intl.t('content.index.jobs');
    } else if (this.modelType === 'department') {
      title = (this.model.editorModel.pageRecord as DepartmentModel).name;
    } else if (this.modelType === 'location') {
      title = (this.model.editorModel.pageRecord as LocationModel).nameOrCity;
    }

    return title;
  }

  @action async getSectionEditRouteParams(
    sectionOrName?: SectionModel | DS.PromiseObject<SectionModel> | string
  ): Promise<undefined | [routeName: string, param?: SectionModel | string]> {
    if (
      typeof sectionOrName === 'string' &&
      ['header', 'footer'].includes(sectionOrName)
    ) {
      return [`content.editor.${sectionOrName}`];
    } else {
      return ['content.editor.section', await sectionOrName];
    }
  }

  @action async goToSectionEdit(
    sectionOrName?: SectionModel | DS.PromiseObject<SectionModel> | string
  ) {
    justAssert(sectionOrName);

    if (this.router.currentRouteName === 'content.editor.index' && this.el) {
      this.returnToListData = {
        scrollTop: this.el.scrollTop,
        sectionIdOrName:
          typeof sectionOrName === 'string'
            ? sectionOrName
            : (await sectionOrName).id,
      };
    }

    const [routeName, routeModel] =
      (await this.getSectionEditRouteParams(sectionOrName)) || [];

    if (routeModel) {
      this.router.transitionTo(routeName!, routeModel);
    } else {
      this.router.transitionTo(routeName!);
    }
  }

  @action
  async didInsert(el: HTMLElement) {
    this.el = el;

    if (this.isTemplatePage && this.page.job) {
      this.handlePickedJob(null);
    }

    if (this.fetchSectionsTask.performCount === 0) {
      await this.fetchSectionsTask.perform();
      await afterRender();
    }

    if (this.returnToListData) {
      this.el.scrollTop = this.returnToListData.scrollTop;
      this.contentEditorController.hoveredSectionData = {
        idOrName: this.returnToListData.sectionIdOrName,
        scroll: false,
      };
      this.returnToListData = undefined;
    } else if (this.returnedFromSectionId) {
      this.contentEditorController.hoveredSectionData = {
        idOrName: this.returnedFromSectionId,
        scroll: 'instant' as ScrollBehavior,
      };
    }

    if (this.returnedFromSectionId) {
      this.returnedFromSectionId = undefined;
    }
  }

  @action
  deleteSection(section: SectionModel) {
    this.ttAlert.confirm(
      this.intl.t('content.editor.confirm_delete_section'),
      () => {
        section.destroyRecord().then(() => {
          this.iframePreview.removeSection(section.id);
          this.flashMessages.success(
            this.intl.t('content.editor.section_deleted')
          );
        });
      },
      () => {},
      {
        confirmButtonText: this.intl.t('content.editor.delete_section'),
      }
    );
  }

  @action
  toggleGlobalSection(section: SectionModel) {
    if (section.isLinked) {
      this.ttAlert.confirm(
        this.intl.t('content.editor.no_global_block'),
        () => {
          section.unlinkSection();
        }
      );
    } else {
      section.markAsGlobal();
    }
  }

  @action
  renameSection(section: SectionModel) {
    set(this, 'editedSectionId', section.id);
  }

  @action
  handleEditRenameKeyUp(_value: unknown, event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE_ESCAPE) {
      set(this, 'editedSectionId', '');
    }
  }

  @action
  async saveCustomName(customName: string, section: SectionModel) {
    await section.setCustomName({ custom_name: customName });

    set(this, 'editedSectionId', '');
  }

  @action
  toggleEnabled(section: SectionModel) {
    section.toggleProperty('enabled');
    section.save().then(() => {
      this.iframePreview.reloadIframe();
      if (section.enabled) {
        this.iframePreview.scrollToSection({
          sectionId: section.id,
        });
      }
    });
  }

  @action
  handleDragStart({
    event,
    element,
  }: {
    event: DragEvent;
    element: HTMLElement;
  }) {
    const item = element.querySelector(`.${itemStyles.content}`)!;

    event.dataTransfer!.effectAllowed = 'link';
    event.dataTransfer?.setDragImage(item, 0, item.clientHeight / 2);
  }

  @action
  handleDragEnd({
    draggedItem,
    sourceList,
    sourceIndex,
    targetIndex,
  }: {
    draggedItem: SectionModel;
    sourceList: SectionModel[];
    sourceIndex: number;
    targetIndex: number;
  }) {
    if (sourceIndex === targetIndex) {
      return;
    }

    const indexChange = targetIndex > sourceIndex ? 1 : -1;
    draggedItem.rowOrder =
      sourceList.objectAt(targetIndex)!.rowOrder + indexChange;

    draggedItem.rowOrderPosition = targetIndex + this.fixedSections.length;

    draggedItem.save().then(() => {
      this.iframePreview.reloadIframe();
    });
  }

  @action
  routeToBlockLibrary(position: number) {
    const newPosition = position + this.fixedSections.length + 1;
    this.router.transitionTo('content.editor.block-library', {
      queryParams: { position: newPosition },
    });
  }

  @action
  handlePickedJob(job: JobModel | null) {
    const previewUrl = new URL(this.page.previewUrl);
    const jobId = get(job, 'id');
    if (jobId) {
      // eslint-disable-next-line ember/use-ember-get-and-set
      previewUrl.searchParams.set('job_id', jobId);
    } else if (previewUrl.searchParams.has('job_id')) {
      previewUrl.searchParams.delete('job_id');
    }

    this.page.previewUrl = previewUrl.toString();
  }
}

declare module '@ember/controller' {
  interface Registry {
    'content.editor.index': ContentEditorIndexController;
  }
}
