import EmberPageTitleListService from 'ember-page-title/services/page-title-list';
export default class PageTitleListService extends EmberPageTitleListService {
  unreadNotifications = 0;

  toString() {
    const title = super.toString();

    return this.unreadNotifications > 0
      ? `(${this.unreadNotifications}) ${title}`
      : title;
  }
}
