import Store from '@ember-data/store';
import { set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { allSettled, task } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';
import JobsRequisitionApprovalFlowEditController from 'teamtailor/controllers/jobs/requisition/approval-flow/edit';
import { RequisitionFlowStepModel, RequisitionModel } from 'teamtailor/models';
import FlashMessageService from 'teamtailor/services/flash-message';
import { cloneFlowFromSteps } from 'teamtailor/utils/approval-flows';
import { get } from 'teamtailor/utils/get';

export default class JobsRequisitionApprovalFlowEditRoute extends Route {
  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  model() {
    const requisition = this.modelFor('jobs.requisition') as RequisitionModel;

    const flow = cloneFlowFromSteps({
      store: this.store,
      steps: get(requisition, 'steps').toArray(),
      modelName: 'requisition-flow',
    });

    return {
      requisition,
      flow,
    };
  }

  async afterModel(model: ReturnType<this['model']>) {
    const clonedSteps = get(model.flow, 'steps');

    const tasks = clonedSteps.toArray().map((step) => {
      return this.resolveUserTask.perform(step);
    });

    const result = await allSettled(tasks);

    if (result.isAny('rejected')) {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
    }
  }

  resetController(
    controller: JobsRequisitionApprovalFlowEditController,
    isExiting: boolean
  ) {
    if (isExiting) {
      controller.model.flow.unloadRecord();
    }
  }

  private resolveUsers = async (step: RequisitionFlowStepModel) => {
    return get(step, 'users');
  };

  private resolveUserTask = task(async (step: RequisitionFlowStepModel) => {
    const users = await this.resolveUsers(step);
    set(step, 'pickedUsers', users.toArray());
  });
}
