export const ALLOWED_CURRENCIES = ['EUR', 'SEK', 'DKK', 'GBP', 'USD'];
export const DEFAULT_CURRENCY = 'EUR';

export default function currencyForCountry(country) {
  switch (country) {
    case 'Australia':
      return 'AUD';

    case 'Brazil':
      return 'BRL';

    case 'Canada':
      return 'CAD';

    case 'China':
      return 'CNY';

    case 'Colombia':
      return 'COP';

    case 'Denmark':
      return 'DKK';

    case 'Hungary':
      return 'HUF';

    case 'India':
      return 'INR';

    case 'Indonesia':
      return 'IDR';

    case 'Japan':
      return 'JPY';

    case 'Jordan':
      return 'JOD';

    case 'Malaysia':
      return 'MYR';

    case 'Mexico':
      return 'MXN';

    case 'New Zealand':
      return 'NZD';

    case 'Philippines':
      return 'PHP';

    case 'Poland':
      return 'PLN';

    case 'Romania':
      return 'RON';

    case 'Sweden':
      return 'SEK';

    case 'South Africa':
      return 'ZAR';

    case 'South Korea':
      return 'KRW';

    case 'Thailand':
      return 'THB';

    case 'Turkey':
      return 'TRY';

    case 'Tunisia':
      return 'TND';

    case 'United Kingdom':
      return 'GBP';

    case 'United States':
      return 'USD';

    default:
      return 'EUR';
  }
}
