import Modifier from 'ember-modifier';

interface ModifierSignature {
  Element: HTMLElement;
  Args: {
    Positional: [attrs: (string | [string, string])[]];
  };
}

const normalizeArg = (
  keyOrEntry: string | [string, string]
): [string, string] =>
  Array.isArray(keyOrEntry) ? keyOrEntry : [keyOrEntry, ''];

type Attrs = (string | [string, string])[];

function isObject(value: unknown): value is Record<string, any> {
  return typeof value === 'object' && value !== null;
}

export default class AttrsModifier extends Modifier<ModifierSignature> {
  prevAttrs: Attrs = [];

  modify(element: HTMLElement, [attrs]: [attrs?: Attrs]) {
    this.prevAttrs.forEach((keyOrEntry: string | [string, string]) => {
      const [key] = normalizeArg(keyOrEntry);
      if (attrs && !(key in attrs)) {
        element.removeAttribute(key);
      }
    });
    if (Array.isArray(attrs)) {
      attrs.forEach((keyOrEntry: any) => {
        const [key, val] = normalizeArg(keyOrEntry);
        element.setAttribute(key, String(val));
      });
    } else if (isObject(attrs)) {
      Object.entries(attrs).forEach((keyOrEntry: any) => {
        const [key, val] = normalizeArg(keyOrEntry);
        element.setAttribute(key, String(val));
      });
    }

    this.prevAttrs = attrs || [];
  }
}
