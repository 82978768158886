import IntlService from 'ember-intl/services/intl';
import BaseProperty, { BasePropertyOptions } from './base-property';

export interface BooleanPropertyOptions extends BasePropertyOptions {
  basePropertyValueTranslationKey: string;
}

export default class BooleanProperty extends BaseProperty {
  basePropertyValueTranslationKey: string;

  constructor(options: BooleanPropertyOptions) {
    super({
      ...options,
      type: 'boolean',
    });
    this.basePropertyValueTranslationKey =
      options.basePropertyValueTranslationKey;
  }

  translate(intl: IntlService) {
    super.translate(intl);

    this.translateBasePropertyValue = (value: boolean | string) =>
      intl.t(this.basePropertyValueTranslationKey, { value: value ? 1 : 0 });
  }

  formatBasePropertyValue(value: boolean) {
    return (
      this.translateBasePropertyValue?.(value) ||
      super.formatBasePropertyValue(value)
    );
  }
}
