import TemplateFormBaseController from 'teamtailor/controllers/settings/template-form-base';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class NewJobOfferTemplateController extends TemplateFormBaseController {
  @service flashMessages;
  @service router;
  @service ttAlert;
  @service intl;

  @action
  async save() {
    this.onSave().then(() => {
      this.model.rollbackAttributes();
      this.flashMessages.success(
        this.intl.t('settings.job_offer_templates.saved')
      );
      this.router.transitionTo('settings.job-offer-templates.index');
    });
  }

  @action
  async confirmDelete() {
    this.ttAlert.confirm(
      this.intl.t('settings.job_offer_templates.confirm_delete', {
        templateName: this.model.name,
      }),
      async () => {
        await this.model.destroyRecord();
        this.router.transitionTo('settings.job-offer-templates.index');
      }
    );
  }
}
