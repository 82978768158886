import Controller from '@ember/controller';
import { set, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class HiringTeamController extends Controller {
  @service current;
  @service permissions;

  setRecruiter = false;

  get isRecruiterForJob() {
    return get(this.model, 'recruiter.id') === this.current.user.id;
  }

  get canManageHiringTeam() {
    return this.permissions.has('job/update') || this.isRecruiterForJob;
  }

  get selectedAdditionalUsers() {
    return get(this.model, 'jobDetail.teamMembers').filter(
      (user) =>
        !user.externalRecruiter && user.id !== get(this.model.recruiter, 'id')
    );
  }

  @action
  handleOnChangeTeam(team) {
    if (team) {
      set(this, 'model.jobDetail.team', team);
      set(this, 'model.recruiter', team.user);
      if (!this.current.user.admin) {
        set(this, 'model.user', null);
      }
    } else {
      set(this, 'model.jobDetail.team', null);
      if (!this.current.user.admin) {
        this.setUserAsRecruiter(this.current.user);
      }
    }
  }

  @action
  setUserAsRecruiter(user) {
    this.model.user = user; // This is the actual recruiter being saved to the server
    this.model.recruiter = user; // This one is whats shown in the form
  }

  @action
  setTeamManagerAsRecruiter() {
    this.model.user = null;
    this.model.recruiter = get(this.model, 'jobDetail.team.user');
  }

  @action
  handleHireTeamUserChange(user) {
    if (get(this.model, 'teamMembers').findBy('id', get(user, 'id'))) {
      get(this.model, 'teamMembers').removeObject(user);
    } else {
      get(this.model, 'teamMembers').pushObject(user);
    }
  }
}
