import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { Calendar, EventInput, EventSourceInput } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import allLocales from '@fullcalendar/core/locales-all';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import moment from 'moment-timezone';
import Store from '@ember-data/store';

import FromRoute from 'teamtailor/routes/jobs/job/calendar';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import TimeFormatService from 'teamtailor/services/time-format';
import ArrayProxy from '@ember/array/proxy';
import MeetingEvent from 'teamtailor/models/meeting-event';
import Current from 'teamtailor/services/current';

export default class CalendarController extends Controller {
  declare model: ModelFrom<FromRoute>;

  @service declare timeFormat: TimeFormatService;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare current: Current;

  @tracked declare calendar: Calendar;
  @tracked declare startDate: string;
  @tracked declare headerTitle: string;

  queryParams = ['startDate'];

  renderCalendar = (element: HTMLDivElement): void => {
    this.calendar = new Calendar(element, {
      plugins: [timeGridPlugin, momentTimezonePlugin],
      timeZone:
        get(this.current.user, 'tzid') ??
        get(this.current.user, 'company').timeZoneIdentifier,

      locales: allLocales,
      locale: this.locale,

      height: 'auto',
      initialView: 'timeGridWeek',
      editable: false,
      selectable: false,
      weekNumberCalculation: 'ISO',
      navLinks: false,

      initialDate: this.startDate,

      headerToolbar: {
        left: undefined,
        center: undefined,
        right: undefined,
      },

      eventClick: (args) => {
        args.jsEvent.preventDefault();
        args.jsEvent.stopPropagation();
        this.router.transitionTo(args.event.url).data = {
          returnUrl: this.router.currentURL,
        };
      },

      eventTimeFormat: {
        hour: 'numeric',
        minute: '2-digit',
        meridiem: 'short',
        hour12: !this.timeFormat.militaryFormat,
      },

      datesSet: () => {
        this.headerTitle = this.calendar.getCurrentData().viewTitle;
        this.startDate = moment(this.calendar.getDate()).format('YYYY-MM-DD');
      },

      eventSources: this.eventSources,
    });

    this.calendar.render();
  };

  get locale(): string {
    return (
      get(this.current.user, 'locale') ||
      get(this.current.company, 'localeAts') ||
      'en'
    );
  }

  get eventSources(): EventSourceInput[] {
    return [
      {
        events: this.getEvents,
        editable: false,
        overlap: true,
      },
    ];
  }

  getEvents = async (
    info: {
      startStr: string;
      endStr: string;
    },
    successCallback: (events: EventInput[]) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    failureCallback: (error: any) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    try {
      const data: ArrayProxy<MeetingEvent> = await this.store.query(
        'meeting-event',
        {
          filter: {
            job_id: this.model.job.id,
            from_date: info.startStr,
            to_date: info.endStr,
          },
        }
      );

      const events = data.map((event) => ({
        id: event.id,
        title: event.parsedSummary,
        start: event.bookedSlotStartsAt,
        end: event.bookedSlotEndsAt,
        display: 'block',
        url: this.router.urlFor(
          'meetings.events/show',
          event.meetingId,
          event.id
        ),
      }));

      successCallback(events);
    } catch (err) {
      failureCallback(err);
    }
  };

  @action
  today(): void {
    this.calendar.today();
  }

  @action
  prevPeriod(): void {
    this.calendar.prev();
  }

  @action
  nextPeriod(): void {
    this.calendar.next();
  }
}

declare module '@ember/controller' {
  interface Registry {
    'jobs.job.calendar': CalendarController;
  }
}
