import Controller from '@ember/controller';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { TableColumn } from 'teamtailor/components/table/container';
// eslint-disable-next-line ember/no-mixins
import SearchQuery from 'teamtailor/mixins/search-query';
import JobModel from 'teamtailor/models/job';
import Current from 'teamtailor/services/current';
import TtAlertService from 'teamtailor/services/tt-alert';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import filterList from 'teamtailor/utils/filter-list';

export const TABLE_COLUMNS: TableColumn[] = [
  {
    label: 'settings.job_templates.template_name',
    sortable: true,
    sortColumn: 'templateName',
    defaultSortOrder: 'asc',
    sortOrder: 1,
  },
  {
    label: 'settings.job_templates.job_title',
    sortable: true,
    sortColumn: 'title',
    defaultSortOrder: 'asc',
    sortOrder: 10,
  },
  {
    label: 'common.created',
    sortable: true,
    sortColumn: 'createdAt',
    defaultSortOrder: 'desc',
    sortOrder: 20,
  },
  {
    label: 'common.tags',
    sortable: false,
    sortOrder: 30,
    colspan: 2,
  },
];

export default class SettingsTemplatesIndexController
  extends Controller.extend(SearchQuery)
  implements Omit<SearchQuery, '__ember_mixin__'>
{
  declare searchProxy?: SearchQuery['searchProxy'];
  declare handleSearchInput: SearchQuery['handleSearchInput'];

  declare model: JobModel[];

  @service declare ttAlert: TtAlertService;
  @service declare current: Current;

  @computedLocalStorage(Boolean, 'Settings.questions.showFilterSidebar', false)
  declare showFilterSidebar: boolean;

  queryParams = ['query', 'job_tag_id', 'sort_column', 'sort_direction'];
  searchProxyDelay = 50;
  filterAttributes = ['templateName', 'title'];

  @tracked declare job_tag_id?: string;
  @tracked declare query?: string;
  @tracked sort_column = 'templateName';
  @tracked sort_direction: 'asc' | 'desc' = 'desc';

  get templateColumns() {
    return TABLE_COLUMNS;
  }

  get list() {
    return get(this.current.company, 'availableJobTemplates');
  }

  private listWithTag() {
    if (this.job_tag_id) {
      return this.list.filter((job) => {
        return job.tags.isAny('id', this.job_tag_id);
      });
    }

    return this.list;
  }

  get filteredList(): JobModel[] {
    return filterList(
      this.filterAttributes,
      this.listWithTag(),
      this.searchProxy
    );
  }

  get sortedTemplates() {
    if (this.sort_direction === 'asc') {
      return this.filteredList
        .sortBy(this.sort_column)
        .toArray()
        .reverseObjects();
    }

    return this.filteredList.sortBy(this.sort_column);
  }

  get showEmptyState(): boolean {
    if (this.searchProxy?.length || this.list.length) {
      return false;
    }

    return true;
  }

  @action
  async createTemplate(): Promise<void> {
    return (await get(this.current.company, 'defaultJobTemplate'))?.copyJob({
      params: {
        is_template: true,
      },
    });
  }

  @action
  async copyTemplate(template: JobModel, e: Event): Promise<void> {
    e.stopPropagation();

    return template.copyJob({
      params: {
        is_template: true,
      },
    });
  }

  @action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectItem<K extends keyof this, T extends Record<string, any>>(
    attribute: K,
    selected: T | null
  ) {
    const id = selected?.id ?? undefined;
    set(this, attribute, id);
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.templates.index': SettingsTemplatesIndexController;
  }
}
