import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class extends Controller {
  @service router;

  @action onClose() {
    window.history.back();
  }
}
