import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import CompanyModel from './company';
import StageModel from './stage';

export const UNEDITABLE_STAGE_TYPES = ['inbox', 'hired'];

export enum Category {
  INBOX = 'inbox',
  SCREENING = 'screening',
  INTERVIEW = 'interview',
  OFFER = 'offer',
  HIRED = 'hired',
}

export default class StageTypeModel extends Model {
  @service declare intl: IntlService;

  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @hasMany('stage') declare stages: AsyncHasMany<StageModel>;

  @attr('string') declare category: Category | null;
  @attr('number') declare orderIndex: number;

  get translatedCategory() {
    return this.intl.t(`job.stage_types.${this.category}`);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'stage-type': StageTypeModel;
  }
}
