import Model, {
  AsyncBelongsTo,
  attr,
  belongsTo,
  hasMany,
  SyncHasMany,
} from '@ember-data/model';
import { FormModel, FormAnswerModel } from 'teamtailor/models';

export default class FormQuestionModel extends Model {
  @belongsTo('form', { inverse: 'formQuestions' })
  declare form: AsyncBelongsTo<FormModel>;

  @hasMany('form-answer', { async: false })
  declare formAnswers: SyncHasMany<FormAnswerModel>;

  @attr('boolean') declare hasAnswers: boolean;
  @attr('boolean') declare isRequired: boolean;
  @attr('number') declare sortIndex: number;
  @attr('raw') declare schema: { alternatives: { id: string }[] };
  @attr('string') declare text: string;
  @attr('string') declare type: string;
  @attr('string') declare uuid: string;

  get isTextType() {
    return (
      this.type === 'FormQuestion::Text' ||
      this.type === 'FormQuestion::TextArea'
    );
  }

  get isScaleType() {
    return this.type === 'FormQuestion::Scale';
  }

  get isChoiceType() {
    return this.type === 'FormQuestion::Choice';
  }

  get isCheckboxType() {
    return this.type === 'FormQuestion::Checkbox';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'form-question': FormQuestionModel;
  }
}
