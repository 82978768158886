import { AsyncHasMany, attr, hasMany } from '@ember-data/model';
import TriggerModel from 'teamtailor/models/trigger';
import UploadModel from 'teamtailor/models/upload';

export default class TriggerNoteModel extends TriggerModel<'note'> {
  @hasMany('upload') declare uploads: AsyncHasMany<UploadModel>;
  @attr('string') declare body: string;

  get description() {
    return this.body;
  }

  get valid() {
    return !!this.body;
  }

  get triggerContent() {
    return this.body;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'trigger/note': TriggerNoteModel;
  }
}
