import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  id={{@name}}\n  class=\"pointer-events-none fixed inset-0 isolate\"\n  local-class={{class-names\n    videoMeetingSidebarOpen=this.isVideoMeetingOpenToSidebar\n  }}\n  ...attributes\n></div>", {"contents":"<div\n  id={{@name}}\n  class=\"pointer-events-none fixed inset-0 isolate\"\n  local-class={{class-names\n    videoMeetingSidebarOpen=this.isVideoMeetingOpenToSidebar\n  }}\n  ...attributes\n></div>","moduleName":"teamtailor/components/outlet-target.hbs","parseOptions":{"srcName":"teamtailor/components/outlet-target.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import VideoMeetingService from 'teamtailor/services/video-meeting';

interface OutletTargetArgs {
  id: string;
}

export default class OutletTarget extends Component<OutletTargetArgs> {
  @service videoMeeting?: VideoMeetingService;

  get isVideoMeetingOpenToSidebar(): boolean {
    return this.videoMeeting?.isActiveSideBar || false;
  }
}
