import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';
import { fieldTypes } from 'teamtailor/constants/custom-fields';
import filterList from 'teamtailor/utils/filter-list';

export default class IndexController extends Controller {
  @service store;
  @service current;
  @service intl;
  @service ttAlert;

  @tracked modalOpen = false;
  @tracked customField = null;
  @tracked search;
  @tracked selectedTab = 'Candidate';

  filterAttributes = ['name'];

  get fields() {
    return fieldTypes.map((fieldType) => {
      return {
        ...fieldType,
        name: this.intl.t(`settings.custom_fields.types.${fieldType.tKey}`),
      };
    });
  }

  get allListItems() {
    return this.model.filter((customField) => !customField.isNew);
  }

  get candidateCustomFields() {
    return this.allListItems.filter(
      (listItem) => listItem.ownerType === 'Candidate'
    );
  }

  get jobCustomFields() {
    return this.allListItems.filter((listItem) => listItem.ownerType === 'Job');
  }

  get tabs() {
    return [
      {
        type: 'Candidate',
        translationKey: 'settings.custom_fields.tabs.candidates',
        totalCount: this.candidateCustomFields.length,
      },
      {
        type: 'Job',
        translationKey: 'settings.custom_fields.tabs.jobs',
        totalCount: this.jobCustomFields.length,
      },
    ];
  }

  get listItems() {
    return this.selectedTab === 'Candidate'
      ? this.candidateCustomFields
      : this.jobCustomFields;
  }

  get ownerType() {
    return this.customField?.ownerType || this.selectedTab;
  }

  get isSearchActive() {
    return isPresent(this.search);
  }

  get filteredList() {
    return filterList(this.filterAttributes, this.listItems, this.search);
  }

  @action
  async editField(field) {
    await field?.translations?.reload();
    this.customField = field;
    this.modalOpen = true;
  }

  @action
  updateStaticField(field) {
    this.current.company.toggleProperty(field);
    this.current.company.save();
  }

  @action
  close() {
    this.customField = null;
    this.modalOpen = false;
  }

  @action
  handleTabClick(tab) {
    this.selectedTab = tab;
  }

  @action
  delete() {
    this.ttAlert.confirm(
      this.intl.t('mixins.custom_fields.base_component.are_you_sure_deleting'),
      async () => {
        await this.customField.destroyRecord();
        this.close();
      },
      () => {}
    );
  }

  @action
  reordercustomFields({
    draggedItem,
    sourceList,
    sourceIndex,
    targetList,
    targetIndex,
  }) {
    if (sourceIndex !== targetIndex) {
      sourceList.splice(sourceIndex, 1);
      targetList.splice(targetIndex, 0, draggedItem);

      draggedItem.rowOrderPosition = targetIndex;
      draggedItem.rowOrder =
        targetIndex < targetList.length - 1
          ? targetList[targetIndex + 1].rowOrder - 1
          : Number.MAX_SAFE_INTEGER;

      draggedItem.save();
    }
  }
}
