import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import {
  CompanyModel,
  DepartmentModel,
  JobModel,
  RoleModel,
  UserModel,
} from 'teamtailor/models';

export default class SlackAppModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('department') declare department: AsyncBelongsTo<DepartmentModel>;
  @belongsTo('job') declare job: AsyncBelongsTo<JobModel>;
  @belongsTo('role') declare role: AsyncBelongsTo<RoleModel>;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;

  @attr('boolean') declare sendConnects: boolean;
  @attr('boolean') declare sendEmails: boolean;
  @attr('boolean') declare sendNewJobApplications: boolean;
  @attr('boolean') declare sendNotes: boolean;
  @attr('boolean') declare sendReviews: boolean;
  @attr('date') stoppedAt?: Date;
  @attr('string') declare lastError: string;
  @attr('string') declare slackChannelId: string;
  @attr('string') declare slackChannelName: string;
  @attr('string') declare slackConfigurationUrl: string;
  @attr('string') declare slackIncomingWebhook: string;
  @attr('string') declare slackTeamId: string;
  @attr('string') declare slackTeamName: string;
  @attr('string') declare slackUserId: string;
  @attr('string') declare token: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'slack-app': SlackAppModel;
  }
}
