import BaseProperty from './properties/base-property';
import { tracked } from '@glimmer/tracking';
import SelectedFilter from './filters/selected-filter';
import {
  CombinedTypeResponse,
  EventTypeResponse,
  PageviewTypeResponse,
} from '../graphql-response-types';
import IntlService from 'ember-intl/services/intl';
import { capitalize } from '@ember/string';

export function findProperty(
  properties: BaseProperty[],
  property: BaseProperty
) {
  return properties.find((p) => p.serialize() === property.serialize());
}

export function findSerializedProperty(
  properties: BaseProperty[],
  serializedProperty: string
) {
  return properties.find((p) => p.serialize() === serializedProperty);
}

export function formatRows(
  rows: (PageviewTypeResponse | EventTypeResponse | CombinedTypeResponse)[],
  properties: BaseProperty[],
  forCSV = false
) {
  return rows.map((row) => {
    const newRow = { ...row };
    properties.forEach((property) => {
      newRow[property.columnName] = forCSV
        ? property.formatForCSV(row[property.columnName])
        : property.format(row[property.columnName]);
    });

    return newRow;
  });
}

export function sortRows(
  rows: (PageviewTypeResponse | EventTypeResponse | CombinedTypeResponse)[],
  sortProperty?: BaseProperty,
  sortDirection?: 'asc' | 'desc'
) {
  if (!sortProperty) {
    return rows;
  }

  let sortedRows = [...rows];
  sortedRows.sort((a, b) => sortProperty.sorter(a, b));

  if (sortDirection === 'desc') {
    sortedRows = sortedRows.reverse();
  }

  return sortedRows;
}

export function limitRows(
  rows: (PageviewTypeResponse | EventTypeResponse | CombinedTypeResponse)[],
  limit?: number
) {
  if (!limit) {
    return rows;
  }

  return rows.slice(0, limit);
}

export type ChartType = 'bar' | 'line' | 'pie' | 'table' | 'column';

export type ChartTypeInfo = {
  translatedName?: string;
  name: ChartType;
  icon: string;
};

export default class Settings {
  properties: BaseProperty[];

  @tracked name?: string;
  @tracked currentBaseType = this.baseTypes[0];

  @tracked chartType?: ChartTypeInfo;
  @tracked baseProperty?: BaseProperty;
  @tracked additionalBaseProperty?: BaseProperty;
  @tracked selectedProperties: BaseProperty[] = [];
  @tracked sortProperty?: BaseProperty;
  @tracked sortDirection?: 'asc' | 'desc' = 'desc';
  @tracked limit?: number;
  @tracked limitType: 'groupSmallValues' | 'hideEmpty' | 'normal' | null = null;
  @tracked groupPercentage?: number;
  @tracked filters: SelectedFilter[] = [];
  @tracked direction: 'vertical' | 'horizontal' | undefined;

  @tracked editing = false;

  getDisplayName(intl: IntlService) {
    if (this.name) {
      return this.name;
    }

    if (!this.valid) {
      return;
    }

    let generatedName = '';

    this.selectedProperties.forEach(
      (property: BaseProperty, index: number, array: BaseProperty[]) => {
        if (index === 0) {
          generatedName = capitalize(property.displayNameForChartName!);
        } else if (index === array.length - 1) {
          generatedName = intl.t(
            'insights.reports.builder.chart_name_builder.and',
            {
              previousProperties: generatedName,
              property: property.displayNameForChartName?.toLowerCase(),
            }
          );
        } else {
          generatedName = intl.t(
            'insights.reports.builder.chart_name_builder.comma',
            {
              previousProperties: generatedName,
              property: property.displayNameForChartName?.toLowerCase(),
            }
          );
        }
      }
    );

    if (this.baseProperty?.type === 'date') {
      generatedName = intl.t(
        'insights.reports.builder.chart_name_builder.over_time',
        {
          previousProperties: generatedName,
        }
      );
    } else {
      generatedName = intl.t('insights.reports.builder.chart_name_builder.by', {
        previousProperties: generatedName,
        property: this.baseProperty?.displayNameForChartName?.toLowerCase(),
      });
    }

    if (this.additionalBaseProperty) {
      generatedName = intl.t(
        this.baseProperty?.type === 'date'
          ? 'insights.reports.builder.chart_name_builder.by'
          : 'insights.reports.builder.chart_name_builder.and',
        {
          previousProperties: generatedName,
          property:
            this.additionalBaseProperty.displayNameForChartName?.toLowerCase(),
        }
      );
    }

    return generatedName;
  }

  get currentGroupBys() {
    return [this.baseProperty, this.additionalBaseProperty].filter(
      (p) => !!p
    ) as BaseProperty[];
  }

  get allSelectedProperties() {
    return [...this.currentGroupBys, ...this.selectedProperties];
  }

  get firstSelectedMetricProperty() {
    return this.selectedProperties.firstObject;
  }

  get baseTypes() {
    return [{ type: 'regular' }, { type: 'cohort' }];
  }

  get basePropertyOptions() {
    return this.properties.filter(
      (property) =>
        property.groupable && property !== this.additionalBaseProperty
    );
  }

  get additionalBasePropertyOptions() {
    return this.basePropertyOptions.filter(
      (property) =>
        this.baseProperty &&
        property !== this.baseProperty &&
        property.isCompatibleWithBaseProperty(this.baseProperty)
    );
  }

  get isTable() {
    return this.chartType?.name === 'table';
  }

  get valid() {
    return (
      this.baseProperty && (this.isTable || this.selectedProperties.length > 0)
    );
  }

  get groupSmallValues() {
    return this.limitType === 'groupSmallValues';
  }

  get showTopValues() {
    return this.limitType === 'normal';
  }

  get hideEmpty() {
    return this.limitType === 'hideEmpty';
  }

  get availableProperties() {
    return [
      ...this.properties
        .map((property) => property.aggregatedProperties)
        .flat(),
    ]
      .filter((n) => !findProperty(this.allSelectedProperties, n))
      .filter(
        (property) =>
          this.baseProperty &&
          property.isCompatibleWithBaseProperty(this.baseProperty)
      )
      .filter(
        (property) =>
          !this.additionalBaseProperty ||
          property.isCompatibleWithAdditionalBaseProperty(
            this.additionalBaseProperty
          )
      )
      .filter((property) =>
        property.isCompatibleWithChartType(this.chartType?.name)
      )
      .filter(
        (property) =>
          this.isTable ||
          property.isCompatibleWithPreviouslySelectedProperties(
            this.selectedProperties
          )
      );
  }

  get groupedAvailableProperties() {
    return this.availableProperties.reduce(
      (acc: { groupName: string; options: BaseProperty[] }[], property) => {
        const category = property.category || 'Other';
        const existing = acc.find((o) => o.groupName === category);
        if (existing) {
          existing.options.push(property);
        } else {
          acc.push({ groupName: category, options: [property] });
        }

        return acc;
      },
      []
    );
  }

  constructor(properties: BaseProperty[], chartType?: ChartTypeInfo) {
    this.properties = properties;
    this.chartType = chartType;

    if (this.isTable) {
      this.baseProperty = properties.find(
        (property) => property.groupable && property.id === 'date'
      );
    }
  }

  deserialize(data: any) {
    try {
      this.name = data.name;
      this.chartType = data.chartType;
      this.baseProperty = findSerializedProperty(
        this.properties,
        data.baseProperty
      );
      this.additionalBaseProperty = findSerializedProperty(
        this.properties,
        data.additionalBaseProperty
      );

      this.selectedProperties = data.selectedProperties
        ?.map((serializedProperty: string) =>
          findSerializedProperty(this.availableProperties, serializedProperty)
        )
        ?.filter((property: BaseProperty) => property) as BaseProperty[];

      this.sortProperty = findSerializedProperty(
        this.selectedProperties,
        data.sortProperty
      );

      this.sortDirection = data.sortDirection;
      this.limit = data.limit;
      this.limitType = data.limitType || null;
      this.groupPercentage = data.groupPercentage;

      this.direction = data.direction;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  serialize() {
    return {
      name: this.name,
      chartType: this.chartType,
      baseProperty: this.baseProperty?.serialize(),
      additionalBaseProperty: this.additionalBaseProperty?.serialize(),

      selectedProperties: this.allSelectedProperties.map((property) =>
        property.serialize()
      ),

      sortProperty: this.sortProperty?.serialize(),
      sortDirection: this.sortDirection,
      limit: this.limit,
      limitType: this.limitType,
      groupPercentage: this.groupPercentage,

      filters: this.filters.map((filter) => filter.serialize()),

      direction: this.direction,
    };
  }
}
