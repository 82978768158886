import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class GetNumberRoute extends ScrollToTopRoute {
  @service current;
  @service store;

  model() {
    return this.store.createRecord('twilio', {
      company: this.current.company,
      identity: {},
      documents: {},
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    set(controller, 'fileNames', {});
    get(this, 'store')
      .findAll('country')
      .then((countries) => {
        set(controller, 'countriesList', countries);
      });
  }
}
