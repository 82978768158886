import { NamedArgs, PositionalArgs, modifier } from 'ember-modifier';

interface ModifierSignature {
  Element: HTMLElement;
  Args: {
    Positional: [name: string, valueArg?: string];
    Named: { pxify?: boolean; enabled?: boolean };
  };
}

export default modifier<ModifierSignature>(
  (
    element: HTMLElement,
    [name, valueArg]: PositionalArgs<ModifierSignature>,
    { pxify = true, enabled = true }: NamedArgs<ModifierSignature>
  ) => {
    if (enabled) {
      const value =
        typeof valueArg === 'number' && pxify ? `${valueArg}px` : valueArg;

      element.style.setProperty(name, value ?? '');
    } else {
      element.style.setProperty(name, '');
    }
  }
);
