import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';

export default class SettingsExternalRecruitmentIndexRoute extends ScrollToTopRoute {
  @service router;

  beforeModel() {
    this.router.replaceWith('settings.external-recruitment.recruiters');
  }
}
