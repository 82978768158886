import { action } from '@ember/object';
import Controller, { inject as controller } from '@ember/controller';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import uniqBy from 'teamtailor/utils/uniq-by';

export default class JobsJobController extends Controller {
  @service router;
  @service flipper;
  @service user;
  @service current;
  @service intercom;
  @service store;

  @controller('jobs.job.stages.index') stagesController;

  @tracked isAddingCandidate = false;
  @tracked activitiesClass;
  @tracked _activities = [];
  @tracked pusherChannel;
  @tracked headingRect;

  @computedLocalStorage(null, 'sidebarToShowLocalStorageKey')
  sidebarToShow;

  get activeSidebar() {
    return this.sidebarToShow || this.stagesController.sidebarToShow;
  }

  get activities() {
    return uniqBy([...this._activities].rejectBy('isDeleted'), 'id');
  }

  get sidebarToShowLocalStorageKey() {
    return `Job(${this.model.id}).sidebarToShow`;
  }

  get isStageActive() {
    const stagesRouteName = 'jobs.job.stages';
    return this.router.currentRouteName.indexOf(stagesRouteName) === 0;
  }

  get isCustomOpen() {
    return this.activeSidebar === 'custom' && this.isStageActive;
  }

  get hasTriggerButton() {
    return (
      this.current.company.hasFeature('triggers') &&
      !get(this, 'user.externalRecruiter')
    );
  }

  get isOnTriggersPage() {
    return (
      this.router.currentRouteName === 'jobs.job.stages.stage-triggers.index'
    );
  }

  get isOnCompareCandidates() {
    return this.router.currentRouteName === 'jobs.job.compare-candidates.index';
  }

  get headingRectHeight() {
    const height = this.headingRect?.height ?? 0;
    return Math.round(height * 2) / 2;
  }

  bindChannelEvents() {
    this.pusherChannel.bind('new-job-note', async (data) => {
      const record = await this.store.findRecord('activity', data.activity_id);

      if (record) {
        const existingActivity = this._activities.findBy('id', record.id);
        if (existingActivity) {
          this._activities.splice(
            this._activities.indexOf(existingActivity),
            1,
            record
          );
        } else {
          this.addActivity(record);
        }
      }
    });

    this.pusherChannel.bind('new-reaction', (data) => {
      this.store.pushPayload(data);
    });

    this.pusherChannel.bind('destroyed-reaction', (id) => {
      const reaction = this.store.peekRecord('reaction', id);
      if (reaction) {
        this.store.unloadRecord(reaction);
      }
    });

    this.pusherChannel.bind('requisition_updated', (data) => {
      if (data.requisition_id) {
        const requisition = this.store.peekRecord(
          'requisition',
          data.requisition_id
        );

        if (requisition) {
          requisition.reload();
        }
      }
    });
  }

  @action
  async fetchActivities() {
    this.activitiesClass = this.store.query('activity', {
      job_id: this.model.id,
      collaborate: true,
    });

    // Calling .then() on the query above breaks ArrayProxy behavior for some reason
    this.activitiesClass.promise.then((models) => {
      this._activities = [...models.toArray()];
    });
  }

  @action
  addActivity(activity) {
    this._activities.pushObject(activity);
  }

  @action
  async destroyActivity(model) {
    model.deleteRecord();

    try {
      await model.save();
      this._activities.removeObject(model);
    } catch (e) {
      model.rollbackAttributes();
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
    }
  }

  @action
  toggleFiltersSidebar(sidebar) {
    this.stagesController.sidebarToShow = null;
    this.sidebarToShow = sidebar;
  }

  @action
  toggleCustomSidebar() {
    this.stagesController.sidebarToShow = null;

    if (this.activeSidebar === 'custom') {
      this.sidebarToShow = null;
      this.intercom.showWidget();
    } else {
      this.sidebarToShow = 'custom';
      this.intercom.hideWidget();
    }
  }

  @action
  onOpenArchiveModal() {
    this.stagesController.clearFilters();
  }
}
