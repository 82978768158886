import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { bind } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import { trackedFunction } from 'ember-resources/util/function';

export default class LinkedinRscController extends Controller {
  @service pusher;
  @service current;
  @service intl;
  @service timeFormat;
  @service cookieConsent;

  @tracked isLoading = false;
  @tracked firstConfig = false;
  @tracked showAdditionalOptions = false;
  @tracked manualSyncing = false;
  @tracked pusherHistoricalSyncFinish = false;
  @tracked isValidateSeatholderButtonDisabled = false;
  @tracked clientId = this.current.company.linkedinClientId;

  _linkedinSetting = trackedFunction(this, async () => {
    const setting = await get(this.current.company, 'linkedinsetting');
    return setting;
  });

  get linkedinSetting() {
    return this._linkedinSetting.value;
  }

  get rawErrors() {
    return this.linkedinSetting.syncErrors;
  }

  get errors() {
    return this.rawErrors.filter((resource) => resource.count);
  }

  get showIndustryAlert() {
    return this.current.company.industryId === null;
  }

  get state() {
    if (
      (this.linkedinSetting.historicalSyncStatus === 'pending' ||
        this.manualSyncing) &&
      !this.pusherHistoricalSyncFinish
    ) {
      return 'loading';
    }

    return null;
  }

  setupController() {
    window.addEventListener(
      'message',
      bind(this, this.handleJavascriptEvents),
      false
    );

    this.pusher
      .channel(this.current.user.notificationsChannel)
      .then((channel) => {
        this.channel = channel;

        channel.bind('linkedinPartnerCreator', (data) => {
          if (data.created) {
            this.firstConfig = true;
            this.clientId = data.linkedin_client_id;
          } else {
            this.isLoading = false;
            this.error = this.intl.t('settings.apply_with_linkedin.error');
          }
        });

        channel.bind('linkedinPartnerIntegration', (data) => {
          if (!data.integrated) {
            this.error = this.intl.t('settings.apply_with_linkedin.error');
          }
        });

        channel.bind('linkedinHistoricalSyncCompleted', (data) => {
          if (data.historical_sync_status) {
            this.pusherHistoricalSyncFinish =
              data.historical_sync_status === 'completed';
          }
        });
      });
  }

  handleJavascriptEvents(e) {
    if (e.data.for === 'applyWithLinkedinController') {
      switch (e.data.action) {
        case 'IFRAME_LOADED':
          this.isLoading = false;
          break;
        case 'INTEGRATIONS':
          this.handleCallbackIntegration(e.data.integrations);
          break;
      }
    }
  }

  resetController() {
    let { channel } = this;
    if (channel) {
      channel.unbind('linkedinPartnerCreator');
      channel.unbind('linkedinPartnerIntegration');
      channel.unbind('linkedinHistoricalSyncCompleted');
    }

    window.removeEventListener(
      'message',
      bind(this, this.handleJavascriptEvents),
      false
    );
  }

  handleCallbackIntegration(integration) {
    this.isLoading = true;
    this.error = undefined;
    this.current.company.integrateLinkedinPartner({ integration });
  }

  @action
  async validateSeatholderCall() {
    this.isValidateSeatholderButtonDisabled = true;
    const result = await this.current.company.validateSeatholderCall();
    this.isValidateSeatholderButtonDisabled = false;
    if (result.status === 200) {
      return window.location.reload();
    }
  }

  @action
  createLinkedinPartner() {
    this.isLoading = true;
    this.error = undefined;
    this.current.company.createLinkedinPartner();
  }

  @action
  toggleAdditionalOptions() {
    set(this, 'showAdditionalOptions', !this.showAdditionalOptions);
    this.isLoading = true;
  }

  @action
  historicalDataSync() {
    this.manualSyncing = true;
    this.pusherHistoricalSyncFinish = false;
    set(this, 'rawErrors', null);
    this.linkedinSetting.historicalSync();
  }

  @action
  changeSyncMode() {
    set(
      this,
      'linkedinSetting.syncAsPrivate',
      !get(this, 'linkedinSetting.syncAsPrivate')
    );
    this.linkedinSetting.save();
  }

  @action
  toggleSyncInmail() {
    set(
      this,
      'linkedinSetting.disableInmailSync',
      !get(this, 'linkedinSetting.disableInmailSync')
    );
    this.linkedinSetting.save();
  }

  @action
  toggleApplyWithLinkedin() {
    if (this.clientId) {
      this.isLoading = true;
    }

    this.error = undefined;
    this.current.company.save();
  }

  @action
  handleManageCookieClick(event) {
    event.preventDefault();

    this.cookieConsent.resetAll();
  }
}
