import { CompanyModel } from 'teamtailor/models';

export type TabName =
  | 'activity-feed'
  | 'interview-feed'
  | 'score-feed'
  | 'todo-feed'
  | 'message-feed'
  | 'note-feed';

export type Tab = {
  name: string;
  icon: string;
  translationKey: string;
  verbTranslationKey?: string;
};

export const tabs: Tab[] = [
  {
    name: 'activity-feed',
    icon: 'bolt',
    translationKey: 'activity',
  },
  {
    name: 'note-feed',
    icon: 'messages',
    translationKey: 'comments',
    verbTranslationKey: 'comment',
  },
  {
    name: 'score-feed',
    icon: 'clipboard-list-check',
    translationKey: 'scorecards',
  },
  {
    name: 'todo-feed',
    icon: 'circle-check',
    translationKey: 'todos',
    verbTranslationKey: 'add_todo',
  },
  {
    name: 'interview-feed',
    icon: 'clipboard-list-check',
    translationKey: 'interviews',
  },
  {
    name: 'message-feed',
    icon: 'paper-plane',
    translationKey: 'messages',
    verbTranslationKey: 'message',
  },
];

export const filteredTabs = (company: CompanyModel) => {
  const interviewKitsEnabled = company.hasFeature('interview_kit');

  return tabs.filter((tab) => {
    if (tab.name === 'interview-feed') {
      return interviewKitsEnabled;
    } else if (tab.name === 'score-feed') {
      return !interviewKitsEnabled;
    }

    return true;
  });
};

export const actionBarTabs = (): Tab[] => {
  return tabs.filter((tab) => {
    if (tab.verbTranslationKey) {
      return true;
    } else {
      return false;
    }
  });
};
