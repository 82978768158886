import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class ContentIndexPageRoute extends Route {
  @service declare store: Store;

  model(params: { page_id: string }) {
    return this.store.findRecord('page', params.page_id);
  }
}
