import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';
import { Promise } from 'rsvp';
import { guidFor } from '@ember/object/internals';

export default class PrintRoute extends Route {
  @service server;
  @service pusher;
  @service user;
  @service current;
  @service flashMessages;

  queryParams = {
    category: {
      printOptions: true,
    },
  };

  async model({ printOptions }) {
    let data = JSON.parse(printOptions);
    let url = `/app/companies/${this.current.company.uuid}/api/print`;

    return new Promise((resolve, reject) => {
      let eventName = `print-job-${guidFor(resolve)}`;

      get(this, 'pusher')
        .subscribe(get(this, 'user.notificationsChannel'))
        .then((channel) => {
          channel.bind(eventName, ({ url }) => {
            channel.unbind(eventName);
            resolve(url);
          });
          data.event_name = eventName;
          this.server
            .request(url, 'POST', {
              data,
            })
            .then(({ url }) => {
              if (url) {
                channel.unbind(eventName);
                resolve(url);
              }
            })
            .catch((error) => {
              if (
                this.current.user.superadmin &&
                this.current.company.id !== this.current.user.companyId
              ) {
                this.flashMessages.error(
                  "Teamtailor admins can't print documents on behalf of users",
                  { sticky: true }
                );
              } else {
                reject(error);
              }
            });
        });
    }).then((url) => {
      window.location.href = url;
    });
  }
}
