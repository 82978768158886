import { attr } from '@ember-data/model';
import Section from './section';

export default class ContentBlockGridModel extends Section {
  @attr('string', { defaultValue: 'cards' }) declare layout: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'content-block-grid': ContentBlockGridModel;
  }
}
