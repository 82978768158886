import Store from '@ember-data/store';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import jsonToCsvFile from 'teamtailor/utils/json-to-csv-file';
import AnalyticsService from 'teamtailor/services/analytics';

import { stageTypeColors } from 'teamtailor/components/insights/charts/colors';
import { Category } from 'teamtailor/models/stage-type';

export type AccumulatedStageTypeData = {
  stageType: string;
  applications: number[];
  rejected: number[];
};

export type StageTypeData = {
  stageType: string;
  applications: number;
  rejected: number;
};

export type StageTypeRow = {
  date: string;
  stageTypes: StageTypeData[];
};

export type StageData = {
  dataId: string;
  name: string;
  stageType: Category;
  applications: number;
  rejected: number;
};

export type StageRow = {
  date: string;
  stages: StageData[];
  [key: string]: any;
};

export type ExportableStageRow = {
  date: string;
  stages: StageData[];
};

export type Row = StageTypeRow | StageRow;

export default class AnalyticsOverviewPipelineOverviewController extends Controller {
  @service declare analytics: AnalyticsService;
  @service declare store: Store;

  @tracked showingStageBreakdown = false;
  @tracked sortDirection = this.defaultSortDirection;
  @tracked sortProperty = this.defaultSortProperty;
  @tracked visibleStageTypes = this.data.stageTypes;

  colors = stageTypeColors;

  get data() {
    return this.model.pipelineOverview.value;
  }

  get defaultSortProperty() {
    return 'date';
  }

  get defaultSortDirection() {
    return 'desc';
  }

  get allStageTypes(): string[] {
    return this.data?.stageTypes ?? [];
  }

  get allStages(): any[] {
    if (!this.data) {
      return [];
    }

    return this.sortByStageType(
      this.data.stages.reduce((acc: any[], stageRow: StageRow) => {
        stageRow.stages.forEach((stage: StageData) => {
          const data = acc.find((it) => it.dataId === stage.dataId);
          if (!data) {
            acc.push({
              dataId: stage.dataId,
              name: stage.name,
              stageType: stage.stageType,
            });
          }
        });

        return acc;
      }, [])
    );
  }

  get rows(): Row[] {
    if (!this.data) {
      return [];
    }

    let count = 0;
    let rows = [];

    if (this.showingStageBreakdown) {
      rows = this.data.stages.map((row: StageRow) => {
        return {
          date: row.date,
          ...this.allStages.reduce((acc, stage) => {
            const stageData = row.stages.find(
              (st) => st.dataId === stage.dataId
            );

            count += stageData?.applications || 0;
            return {
              ...acc,
              [stage.dataId]: stageData?.applications || 0,
            };
          }, {}),
        };
      });
    } else {
      rows = [...this.data.rows].map((row: StageTypeRow) => {
        return {
          date: row.date,
          ...this.allStageTypes.reduce((acc, stageType) => {
            const stageTypeData = row.stageTypes.find(
              (st) => st.stageType === stageType
            );

            count += stageTypeData?.applications || 0;
            return {
              ...acc,
              [stageType]: stageTypeData?.applications || 0,
            };
          }, {}),
        };
      });
    }

    return count === 0 ? [] : rows;
  }

  get sortedRows(): Row[] {
    return this.sortRows(this.rows);
  }

  sortRows(
    rows: Row[],
    sortProperty: string = this.sortProperty,
    sortDirection: string = this.sortDirection
  ): Row[] {
    if (sortDirection === 'asc') {
      return rows.sortBy(sortProperty);
    } else {
      return rows.sortBy(sortProperty).reverse();
    }
  }

  sortByStageType(rows: StageData[]): StageData[] {
    const stageTypes = this.store.peekAll('stage-type');
    return rows.sort((a, b) => {
      const stageTypeA = stageTypes.find(
        (stageType) => stageType.category === a.stageType
      );
      const stageTypeB = stageTypes.find(
        (stageType) => stageType.category === b.stageType
      );

      return (
        (stageTypeA ? stageTypeA.orderIndex : 0.5) -
        (stageTypeB ? stageTypeB.orderIndex : 0.5)
      );
    });
  }

  @action
  handleExport(): void {
    let rows: Row[] = [];

    let formatRows:
      | ((row: StageRow) => StageRow)
      | ((row: StageTypeRow) => StageTypeRow) = (row: StageTypeRow) => row;

    rows = this.sortRows(
      this.rows,
      this.defaultSortProperty,
      this.defaultSortDirection
    );

    if (this.showingStageBreakdown) {
      formatRows = (row: StageRow) => ({
        date: row.date,
        ...this.allStages.reduce((acc, stage) => {
          return {
            ...acc,
            [stage.name]: row[stage.dataId],
          };
        }, {}),
      });
    }

    jsonToCsvFile(
      'conversions',
      this.analytics.startDate,
      this.analytics.endDate,
      rows,
      formatRows
    );
  }

  @action
  handleSort(sortBy: string): void {
    if (this.sortProperty === sortBy) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortDirection = 'desc';
      this.sortProperty = sortBy;
    }
  }

  @action
  handleCheck(stageType: string): void {
    const visibleStageTypes = [...this.visibleStageTypes];
    if (visibleStageTypes.includes(stageType)) {
      this.visibleStageTypes = visibleStageTypes.filter(
        (type: string) => type !== stageType
      );
    } else {
      visibleStageTypes.push(stageType);
      this.visibleStageTypes = visibleStageTypes;
    }

    this.visibleStageTypes = this.allStageTypes.reduce(
      (acc: string[], type: string) => {
        if (this.visibleStageTypes.includes(type)) {
          acc.push(type);
        }

        return acc;
      },
      []
    );
  }
}

declare module '@ember/controller' {
  interface Registry {
    'analytics.overview.pipeline-overview': AnalyticsOverviewPipelineOverviewController;
  }
}
