import Controller from '@ember/controller';
import { action } from '@ember/object';
import { timeout } from 'ember-concurrency';

export default class UnicornComponentsButtonSplitButtonController extends Controller {
  @action
  async someAction() {
    await timeout(800);
  }
}

declare module '@ember/controller' {
  interface Registry {
    'unicorn.components.buttons.split-button': UnicornComponentsButtonSplitButtonController;
  }
}
