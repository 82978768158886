import Model, {
  type AsyncBelongsTo,
  type AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { CompanyModel, RequisitionModel } from 'teamtailor/models';
import Current from 'teamtailor/services/current';
import { inject as service } from '@ember/service';

export default class RequisitionArchiveReasonModel extends Model {
  @service declare current: Current;

  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @hasMany('requisition') declare requisitions: AsyncHasMany<RequisitionModel>;

  @attr('string') declare reason: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'requisition-archive-reason': RequisitionArchiveReasonModel;
  }
}
