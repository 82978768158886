import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
// eslint-disable-next-line ember/no-mixins
import SearchQuery from 'teamtailor/mixins/search-query';
import Router from '@ember/routing/router';
import InterviewKit from 'teamtailor/models/interview-kit';
import {
  TABLE_COLUMNS,
  TABLE_LOADING_COLUMNS,
} from 'teamtailor/constants/interview-kits';
export default class IndexController extends Controller.extend(SearchQuery) {
  queryParams = ['query', 'sort_column', 'sort_direction'];

  searchProxyDelay = 250;

  @service declare router: Router;

  @tracked sort_column = 'name';
  @tracked sort_direction = 'asc';

  columns = TABLE_COLUMNS;
  loadingInterviewKitsColumns = TABLE_LOADING_COLUMNS;

  get availableInterviewKits() {
    return this.model;
  }

  get totalCount() {
    return this.model?.meta?.total_count;
  }

  get interviewKitsExist() {
    return this.model?.meta?.interview_kits_exist;
  }

  @action
  copyInterviewKit(interviewKit: InterviewKit) {
    this.router.transitionTo('settings.interview-kits.new', {
      queryParams: { copiedInterviewKitId: interviewKit.id },
    });
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.interview-kits.index': IndexController;
  }
}
