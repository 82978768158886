import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"c-calendar-availability-rules\">\n  <div\n    id=\"cronofy-availability-rules\"\n    {{did-insert this.createAvailabilityComponent}}\n  ></div>\n  {{#if @elementToken}}\n    <div class=\"c-calendar-availability-rules__week-days\">\n      {{#each this.sortedLocaleWeekDays as |weekDay|}}\n        <div class=\"c-calendar-availability-rules__day\">\n          {{weekDay}}\n        </div>\n      {{/each}}\n    </div>\n  {{/if}}\n</div>", {"contents":"<div class=\"c-calendar-availability-rules\">\n  <div\n    id=\"cronofy-availability-rules\"\n    {{did-insert this.createAvailabilityComponent}}\n  ></div>\n  {{#if @elementToken}}\n    <div class=\"c-calendar-availability-rules__week-days\">\n      {{#each this.sortedLocaleWeekDays as |weekDay|}}\n        <div class=\"c-calendar-availability-rules__day\">\n          {{weekDay}}\n        </div>\n      {{/each}}\n    </div>\n  {{/if}}\n</div>","moduleName":"teamtailor/components/calendar/availability-rules.hbs","parseOptions":{"srcName":"teamtailor/components/calendar/availability-rules.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import ENV from 'teamtailor/config/environment';

export default class AvailabilityRules extends Component {
  @service timeFormat;

  date = new Date();

  @tracked tzid = this.args.timeZone || moment.tz.guess();

  get sortedLocaleWeekDays() {
    const localeData = moment.localeData(this.timeFormat.locale);
    return localeData
      .weekdays()
      .slice(this.timeFormat.firstDayOfWeek.id)
      .concat(
        localeData.weekdays().slice(0, this.timeFormat.firstDayOfWeek.id)
      );
  }

  get calendarLocale() {
    const cronofySupportedLocales = [
      'de',
      'en',
      'es',
      'fr',
      'fr-CA',
      'it',
      'ja',
      'nl',
      'ru',
      'sv',
    ];

    if (cronofySupportedLocales.indexOf(this.timeFormat.locale) !== -1) {
      return this.timeFormat.locale;
    }

    return 'en';
  }

  get calendarOptions() {
    return {
      element_token: this.args.elementToken,
      data_center: ENV.CRONOFY_DATA_CENTRE,
      target_id: 'cronofy-availability-rules',
      availability_rule_id: this.args.availabilityRuleId || 'default',
      config: {
        start_time: this.args.startTime || '08:00',
        end_time: this.args.endTime || '18:00',
        duration: 60,
        week_start_day: this.timeFormat.firstDayOfWeek.value,
      },

      styles: {
        colors: {
          available: '#f43f85',
          unavailable: '#f4f7fa',
          hairline: 'transparent',
          availableHover: '#fdecf3',
          availableActive: '#fdecf3',
          unavailableHover: '#bdc0c4',
          unavailableActive: '#bdc0c4',
        },

        prefix: 'cronofy-availability-rules',
      },

      locale: this.calendarLocale,

      tzid: this.tzid,
      callback: this.callback,
    };
  }

  callback = (response) => {
    if (response?.notification?.type !== 'availability_rule_saved') {
      return;
    }

    this.args.cronofyRulesSavedCallback();

    if (this.args.onSave) {
      this.args.onSave(response);
    }
  };

  @action
  async createAvailabilityComponent() {
    const CronofyElements = await import('cronofy-elements');
    this._cronofyElement = CronofyElements.AvailabilityRules(
      this.calendarOptions
    );
  }
}
