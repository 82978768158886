import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<HighCharts\n  @chartOptions={{this.chartOptions}}\n  @content={{this.chartData}}\n  @hideAnimations={{@hideAnimations}}\n/>", {"contents":"<HighCharts\n  @chartOptions={{this.chartOptions}}\n  @content={{this.chartData}}\n  @hideAnimations={{@hideAnimations}}\n/>","moduleName":"teamtailor/components/insights/charts/donut-chart.hbs","parseOptions":{"srcName":"teamtailor/components/insights/charts/donut-chart.hbs"}});
import Component from '@glimmer/component';
import colors, { nps, grey } from './colors';
import defaultOptions, {
  npsJobReportOptions,
  rejectJobReportOptions,
  sourcesOptions,
  devicesOptions,
  reportOptions,
} from './donut-chart-options';

type DataType = {
  name: string;
  value: number;
  color?: string;
};

type Args = {
  data: DataType[];
  colorscheme: 'nps' | 'rejects' | undefined;
  variant:
    | 'sources'
    | 'devices'
    | 'nps-job-report'
    | 'reject-job-report'
    | 'report'
    | undefined;
};

export default class InsightsChartsDonutChart extends Component<Args> {
  get colors() {
    switch (this.args.colorscheme) {
      case 'nps':
        return nps;

      case 'rejects':
        return grey.concat(colors);

      default:
        return colors;
    }
  }

  get chartData() {
    return [
      {
        data: this.args.data.map((row, index) => ({
          name: row.name,
          y: row.value,
          color: row.color || this.colors[index % this.colors.length],
        })),
      },
    ];
  }

  get chartOptions() {
    switch (this.args.variant) {
      case 'sources':
        return sourcesOptions;
      case 'devices':
        return devicesOptions;
      case 'nps-job-report':
        return npsJobReportOptions;
      case 'reject-job-report':
        return rejectJobReportOptions;
      case 'report':
        return reportOptions;
      default:
        return defaultOptions;
    }
  }
}
