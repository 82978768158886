import RouterService from '@ember/routing/router-service';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import Transition from '@ember/routing/-private/transition';
import SettingsQuestionsController from 'teamtailor/controllers/settings/questions';

export default class SettingsQuestionsRoute extends ScrollToTopRoute {
  @service declare router: RouterService;

  queryParams = {
    query: { refreshModel: true },
    question_tag_id: { refreshModel: true },
    type: { refreshModel: true },
    sort_column: { refreshModel: true },
    sort_direction: { refreshModel: true },
  };

  activate() {
    this.router.on('routeDidChange', this.onRouterDidChange);
  }

  deactivate() {
    this.router.off('routeDidChange', this.onRouterDidChange);
  }

  onRouterDidChange = (transition: Transition) => {
    if (transition.from?.name === 'settings.questions.new') {
      (this.controller as SettingsQuestionsController).page = 1;
    }
  };
}
