import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes class=\"c-view-filter__filter-bar\">\n  <div class=\"c-view-filter__filter-bar__container\">\n    <div\n      class=\"c-view-filter__filter-bar__container__filters\n        {{if @scrollable 'scrollable' ''}}\"\n      {{did-insert this.onVisible}}\n    >\n      {{yield}}\n    </div>\n  </div>\n  {{#if @hasFiltersSelected}}\n    <div class=\"c-view-filter__filter-bar--divider\"></div>\n    <button type=\"button\" {{on \"click\" @clearFilters}}>\n      <Icon @icon=\"circle-xmark\" />\n    </button>\n  {{/if}}\n</div>", {"contents":"<div ...attributes class=\"c-view-filter__filter-bar\">\n  <div class=\"c-view-filter__filter-bar__container\">\n    <div\n      class=\"c-view-filter__filter-bar__container__filters\n        {{if @scrollable 'scrollable' ''}}\"\n      {{did-insert this.onVisible}}\n    >\n      {{yield}}\n    </div>\n  </div>\n  {{#if @hasFiltersSelected}}\n    <div class=\"c-view-filter__filter-bar--divider\"></div>\n    <button type=\"button\" {{on \"click\" @clearFilters}}>\n      <Icon @icon=\"circle-xmark\" />\n    </button>\n  {{/if}}\n</div>","moduleName":"teamtailor/components/filter-bar.hbs","parseOptions":{"srcName":"teamtailor/components/filter-bar.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class FilterBar extends Component {
  @tracked hasFiltersSelected;

  @action
  onVisible(element) {
    element.addEventListener('wheel', function (e) {
      if (e.deltaX === 0) {
        e.preventDefault();

        element.scrollLeft += e.deltaY;
      }
    });
  }
}
