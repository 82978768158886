import { helper } from '@ember/component/helper';
import ENV from 'teamtailor/config/environment';

export function externalUrl([host, path]) {
  if (ENV[host] === undefined) {
    throw new Error(`Trying to create URL for unknown host "${host}"`);
  }

  return `${ENV.httpOrHttps}://${ENV[host]}/${path}`;
}

export default helper(externalUrl);
