import Model, {
  belongsTo,
  hasMany,
  AsyncBelongsTo,
  AsyncHasMany,
} from '@ember-data/model';

import CompanyModel from 'teamtailor/models/company';
import MeetingEventModel from 'teamtailor/models/meeting-event';
import JobModel from 'teamtailor/models/job';
import PreparedMessageModel from 'teamtailor/models/prepared-message';

export default class MeetingModel extends Model {
  @belongsTo('company', { async: false })
  declare company: CompanyModel;

  @hasMany('meeting-event')
  declare meetingEvents: AsyncHasMany<MeetingEventModel>;

  @belongsTo('prepared-message')
  declare preparedMessage?: AsyncBelongsTo<PreparedMessageModel>;

  @belongsTo('job')
  declare job?: AsyncBelongsTo<JobModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    meeting: MeetingModel;
  }
}
