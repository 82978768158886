import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import Service from '@ember/service';
import { set, get } from '@ember/object';
import { task, timeout, waitForProperty } from 'ember-concurrency';

@classic
export default class ConfettiService extends Service {
  @alias('_showConfetti.isRunning')
  showConfetti;

  withCover = false;
  emojis = undefined;
  _infinite = false;

  show({ duration = 2000, withCover = false, emojis = null } = {}) {
    return get(this, '_showConfetti').perform({ duration, withCover, emojis });
  }

  hide() {
    set(this, '_infinite', false);
  }

  _showConfetti = task(
    {
      restartable: true,
    },
    async ({ duration, withCover, emojis }) => {
      set(this, 'fadeOut', false);
      set(this, 'withCover', withCover);
      set(this, 'emojis', emojis);

      if (duration === Infinity) {
        set(this, '_infinite', true);
        await waitForProperty(this, '_infinite', false);
      } else {
        await timeout(duration);
      }

      set(this, 'fadeOut', true);
      await timeout(4000);
      set(this, 'withCover', false);
    }
  );
}
