import { QueryType } from '../base-query';
import AggregatedProperty, {
  AggregatedPropertyOptions,
} from './aggregated-property';

export default class CountProperty extends AggregatedProperty {
  constructor(options: AggregatedPropertyOptions) {
    super({
      ...options,
      type: 'count',
      columnType: 'number',
      translationKey: 'insights.reports.builder.aggregated_properties.count',
    });
  }

  getField(_queryType: QueryType) {
    return `count`;
  }
}
