import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

export default class ActivityController extends Controller {
  @service router;

  @action
  onShowMessages(activity) {
    this.router.transitionTo(
      'jobs.job.activity.candidate',
      activity.candidate,
      { queryParams: { tab: 'message-feed' } }
    );
  }

  @action
  onShowNotes(activity) {
    this.router.transitionTo(
      'jobs.job.activity.candidate',
      activity.candidate,
      { queryParams: { tab: 'note-feed' } }
    );
  }
}
