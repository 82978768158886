import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import ActiveFilter from 'teamtailor/components/fancy-filters/active-filter';
import { fancyFilterDisplayValue } from './fancy-filter-display-value';
import IntlService from 'ember-intl/services/intl';
import FancyFilterOption from 'teamtailor/utils/fancy-filter-option';
import { assert } from '@ember/debug';
import { SafeString } from '@ember/template/-private/handlebars';

export default class FancyCandidateFilterButtonText extends Helper {
  @service declare intl: IntlService;

  compute([activeFilter]: ActiveFilter[]): SafeString | string | undefined {
    assert('activeFilter must exist', activeFilter);

    (activeFilter.filter as FancyFilterOption).ignoreParent = false;
    let filterName: string | SafeString | undefined =
      activeFilter.translatedName;
    (activeFilter.filter as FancyFilterOption).ignoreParent = true;

    if (!activeFilter.selectedOperator) {
      return filterName;
    }

    let value = fancyFilterDisplayValue(
      [activeFilter.value!, true],
      activeFilter.translateValue ? this.intl : undefined
    );

    if (
      activeFilter.filterType === 'range' &&
      Array.isArray(activeFilter.value)
    ) {
      value = `${activeFilter.value[0]} - ${activeFilter.value[1]}`;
    }

    if (activeFilter.filter?.category === 'candidate_status') {
      value = `${filterName} - ${value}`;
      filterName = this.intl.t(
        `components.fancy_filters.categories.${activeFilter.filter.category}`
      );
    }

    if (activeFilter.filter?.name === 'rejected') {
      value = `${filterName}`;
      filterName = this.intl.t(
        `components.fancy_filters.categories.${activeFilter.filter.category}`
      );
    }

    return this.intl.t(activeFilter.selectedOperator.translationKeyForTrigger, {
      value,
      htmlSafe: true,
      filterName,
    });
  }
}
