import Route from '@ember/routing/route';
import UserService from 'teamtailor/services/user';
import ApolloService from 'ember-apollo-client/services/apollo';
import AnalyticsService from 'teamtailor/services/analytics';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import MeetingStatsReport from 'teamtailor/classes/analytics/report-meeting-stats';
import ReportMeetings from 'teamtailor/classes/analytics/report-meetings';
import { set } from '@ember/object';

export default class MeetingsRoute extends Route {
  @service declare apollo: ApolloService;
  @service declare analytics: AnalyticsService;
  @service declare user: UserService;

  model() {
    return {
      meetingStats: new MeetingStatsReport({ container: getOwner(this) })
        .fetchTask,
    };
  }

  async setupController(controller: any, model: any, transition: any) {
    super.setupController(controller, model, transition);

    const reportMeetings = new ReportMeetings({
      container: getOwner(this),
    });

    set(controller, 'reportMeetings', reportMeetings);
    const calendarEvents = await reportMeetings.fetch.perform();

    set(controller, 'calendarEvents', calendarEvents);
  }
}
