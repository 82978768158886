export default [
  'alligator',
  'ant',
  'badger',
  'bat',
  'bear',
  'beaver',
  'bee',
  'bull',
  'butterfly',
  'camel',
  'cat',
  'caterpillar',
  'chicken',
  'chinchilla',
  'clownfish',
  'cow',
  'crab',
  'crane',
  'crow',
  'deer',
  'dinosaur',
  'dog',
  'dolphin',
  'donkey',
  'dove',
  'dragon',
  'dragonfly',
  'duck',
  'elephant',
  'falcon',
  'fish',
  'flamingo',
  'fox',
  'frog',
  'giraffe',
  'goat',
  'gorilla',
  'grasshopper',
  'hamster',
  'hedgehog',
  'hippopotamus',
  'hornet',
  'horse',
  'hummingbird',
  'jaguar',
  'jellyfish',
  'kangaroo',
  'kiwi',
  'ladybird',
  'lemur',
  'leopard',
  'lion',
  'llama',
  'manatee',
  'monkey',
  'moose',
  'mouse',
  'nautilus',
  'octopus',
  'owl',
  'ox',
  'parakeet',
  'parrot',
  'peacock',
  'pelican',
  'penguin',
  'phoenix',
  'pig',
  'platypus',
  'prawn',
  'puffin',
  'quail',
  'rabbit',
  'reindeer',
  'rhinoceros',
  'rooster',
  'seagull',
  'seahorse',
  'seal',
  'sheep',
  'shellfish',
  'sloth',
  'slug',
  'snail',
  'snake',
  'spider',
  'squirrel',
  'starfish',
  'stingray',
  'stork',
  'swan',
  'tiger',
  'toucan',
  'turtle',
  'unicorn',
  'walrus',
  'whale',
  'wolf',
  'woodpecker',
  'zebra',
];
