import ApplicationInstance from '@ember/application/instance';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import ApolloService from 'ember-apollo-client/services/apollo';
import Store from '@ember-data/store';
import { task } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';

import round from 'teamtailor/utils/round';
import AverageRating from 'teamtailor/utils/average-rating';
import { gql } from '@apollo/client/core';
import ReportAnalyticsRequest, {
  getClickhousePageviewsTransitionDate,
} from './report-analytics-request';
import {
  EventQueryResponse,
  EventTypeResponse,
  GoogleAnalyticsPromotionsTypeResponse,
  GoogleAnalyticsQueryResponse,
  PageviewQueryResponse,
  PageviewTypeResponse,
} from 'teamtailor/utils/insights/graphql-response-types';
import { get } from 'teamtailor/utils/get';
import fetchInBatches from 'teamtailor/utils/insights/fetch-in-batches';
import FlipperService from 'teamtailor/services/flipper';
import AnalyticsService from 'teamtailor/services/analytics';
import CurrentService from 'teamtailor/services/current';
import uniq from 'teamtailor/utils/uniq';

const INSIGHTS_GA_QUERY = gql`
  query GoogleAnalyticsPromotionsQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    googleAnalyticsQuery {
      promotions(
        dateRange: $dateRange
        jobIds: $jobIds
        groupByJob: true
        companyIds: $companyIds
      ) {
        promotionId
        channelId
        jobId
        applicationIds
        candidateIds
        pageviews
        sessions
      }
    }
  }
`;

const INSIGHTS_PAGEVIEWS_QUERY = gql`
  query PromotionsQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    applications: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [APPLIED]
      filters: { jobApplicationPromotionId: { exists: true } }
      companyIds: $companyIds
    ) {
      aggregated(groupBy: [PROMOTION_ID, CHANNEL_ID, JOB_ID]) {
        promotionId: jobApplicationPromotionId
        channelId: jobApplicationPromotionChannelId
        jobId
        count
        candidateIds: collect(field: CANDIDATE_ID)
        applicationIds: collect(field: JOB_APPLICATION_ID)
      }
    }
    pageviewQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      filters: { promotionId: { exists: true } }
      companyIds: $companyIds
    ) {
      aggregated(groupBy: [PROMOTION, JOB_ID]) {
        promotionId
        jobId
        count
        distinctCount(field: SESSION_ID)
      }
    }
  }
`;

type InsightsPageviewsResult = PageviewTypeResponse;

interface InsightsEventResult extends EventTypeResponse {
  promotionId: string;
  channelId?: string;
  candidateIds: string[];
  applicationIds: string[];
}

type Row = {
  name: string;
  jobId?: string;
  applications: string[];
  nrApplications: number;
  sessions: number;
  conversionRate: number;
  averageRating: AverageRating;
  candidateIds: string[];
  companyName?: string;
};

const formatInsightsData = (
  pageviews: InsightsPageviewsResult[] = [],
  eventsData: InsightsEventResult[] = []
): GoogleAnalyticsPromotionsTypeResponse[] => {
  if (!pageviews.length) return [];

  const eventsByPromotionId = eventsData.map(
    ({ promotionId, channelId, jobId, candidateIds, applicationIds }) => {
      return {
        promotionId,
        channelId,
        jobId,
        sessions: 0,
        pageviews: 0,
        candidateIds: [...candidateIds],
        applicationIds: [...applicationIds],
      };
    }
  );

  const rows = pageviews.reduce((acc, row) => {
    const { count, distinctCount, promotionId, jobId } = row;

    const item = acc.find(
      (r) => r.promotionId === promotionId && r.jobId === jobId
    );

    if (item) {
      item.pageviews = count;
      item.sessions = distinctCount;
    } else {
      acc.push({
        promotionId,
        jobId,
        channelId: undefined,
        pageviews: count,
        sessions: distinctCount,
        applicationIds: [],
        candidateIds: [],
      });
    }

    return acc;
  }, eventsByPromotionId);

  return rows;
};

class ReportAudiencePromotions {
  @service declare intl: IntlService;
  @service declare store: Store;

  container: ApplicationInstance;
  rows: Row[];

  constructor({
    container,
    rows,
  }: {
    container: ApplicationInstance;
    rows: Row[];
  }) {
    this.container = container;
    this.rows = rows;
  }

  get totalVisits(): number {
    return this.rows.reduce((acc, row) => acc + row.sessions, 0);
  }

  get totalApplications(): number {
    return this.rows.reduce((acc, row) => acc + row.nrApplications, 0);
  }

  get topPromotions() {
    return this.groupedRows.sort(
      (promo1: Row, promo2: Row) =>
        promo2.nrApplications - promo1.nrApplications
    );
  }

  get conversionRate(): number {
    if (this.totalVisits === 0) {
      return 0;
    }

    return round((this.totalApplications / this.totalVisits) * 100, true);
  }

  get groupedRows() {
    return this.rows.reduce<Row[]>((acc, row) => {
      const name = row.name || this.intl.t('reports.unknown');
      const item = acc.find((item) => item.name === name);

      if (item) {
        item.applications.push(...row.applications);
        item.nrApplications += row.nrApplications;
        item.sessions += row.sessions;
        item.conversionRate = item.nrApplications / item.sessions;
        item.candidateIds.push(...row.candidateIds);
        item.averageRating = new AverageRating(this.store, item.candidateIds);
      } else {
        acc.push({
          name,
          jobId: row.jobId,
          applications: [...row.applications],
          nrApplications: row.nrApplications,
          sessions: row.sessions,
          conversionRate: row.nrApplications / row.sessions,
          averageRating: row.averageRating,
          candidateIds: [...row.candidateIds],
        });
      }

      return acc;
    }, []);
  }
}

type JobType = {
  id: string;
  title: string;
  company: {
    id: string;
    name: string;
  };
};

type JobsResponseType = {
  jobs: JobType[];
};

export default class AudiencePromotionsReportFetcher {
  @service declare analytics: AnalyticsService;
  @service declare apollo: ApolloService;
  @service declare current: CurrentService;
  @service declare intl: IntlService;
  @service declare flipper: FlipperService;
  @service declare store: Store;

  container: ApplicationInstance;

  constructor({ container }: { container: ApplicationInstance }) {
    this.container = container;
  }

  fetch = task(async () => {
    const transitionDate = getClickhousePageviewsTransitionDate(this.flipper);

    const googleAnalyticsRows: GoogleAnalyticsPromotionsTypeResponse[] =
      await new ReportAnalyticsRequest({
        container: this.container,
        query: INSIGHTS_GA_QUERY,
        callback: (result?: {
          googleAnalyticsQuery: GoogleAnalyticsQueryResponse;
        }) => result?.googleAnalyticsQuery.promotions || [],
      }).fetch({
        before: transitionDate,
      });

    const insightsData = await new ReportAnalyticsRequest({
      container: this.container,
      query: INSIGHTS_PAGEVIEWS_QUERY,
      callback: (result?: {
        pageviewQuery: PageviewQueryResponse;
        eventQuery: EventQueryResponse;
      }) => result,
    }).fetch({ after: transitionDate });

    const clickhouseRows = formatInsightsData(
      insightsData?.pageviewQuery?.aggregated,
      insightsData?.applications?.aggregated
    );

    const _rows = googleAnalyticsRows.reduce((acc, row) => {
      const item = acc.find(
        (r) => r.promotionId === row.promotionId && r.jobId === row.jobId
      );

      if (item) {
        item.channelId = item.channelId || row.channelId;
        item.applicationIds.push(...row.applicationIds);
        item.candidateIds.push(...row.candidateIds);
        item.pageviews += row.pageviews || 0;
        item.sessions += row.sessions || 0;
      } else {
        acc = [...acc, row];
      }

      return acc;
    }, clickhouseRows);

    type IdChannelNamePair = {
      id: string;
      shareLinkNameOrChannelName?: string;
    };

    const promotionResponse = await this.apollo.query({
      query: gql`
        query PromotionQuery(
          $promotionIds: [ID!]
          $userId: ID!
          $companyIds: [ID!]
        ) {
          promotions(
            ids: $promotionIds
            userScope: { userId: $userId }
            groupCompanyIds: $companyIds
          ) {
            id
            shareLinkNameOrChannelName
          }
        }
      `,
      variables: {
        promotionIds: uniq(_rows.map((row) => row.promotionId)),

        userId: get(this.current.user, 'id'),
        companyIds: this.analytics.availableCompanyIds,
      },
    });

    let promotions: IdChannelNamePair[] =
      promotionResponse.promotions as IdChannelNamePair[];

    if (_rows.length > 0 && promotions.length !== _rows.length) {
      let channelIds = _rows.map((row) => row.channelId).filter((id) => !!id);

      channelIds = uniq(channelIds);

      const channelResponse = await this.apollo.query({
        query: gql`
          query ChannelQuery(
            $channelIds: [ID!]
            $userId: ID!
            $companyIds: [ID!]
          ) {
            channels(
              ids: $channelIds
              userScope: { userId: $userId }
              groupCompanyIds: $companyIds
            ) {
              id
              shareLinkNameOrChannelName: name
            }
          }
        `,
        variables: {
          channelIds,

          userId: get(this.current.user, 'id'),
          companyIds: this.analytics.availableCompanyIds,
        },
      });

      const channels: IdChannelNamePair[] =
        channelResponse.channels as IdChannelNamePair[];

      promotions = _rows.reduce(
        (acc, row) => {
          const exists = acc.find(
            (promotion) => promotion.id === String(row.promotionId)
          );
          if (!exists) {
            const channel = channels.find(
              (channel) => channel.id === String(row.channelId)
            );
            acc.push({
              id: String(row.promotionId),
              shareLinkNameOrChannelName: channel?.shareLinkNameOrChannelName,
            });
          }

          return acc;
        },
        [...promotions]
      );
    }

    const jobIds = uniq(
      _rows.map((row) => row.jobId).filter((id) => !!id)
    ) as string[];

    const jobs = await fetchInBatches<JobType, JobsResponseType>(
      jobIds,
      (ids: string[]) =>
        this.apollo.query({
          query: gql`
            query UsersQuery(
              $filter: JobsFilterInput
              $userId: ID!
              $companyIds: [ID!]
            ) {
              jobs(
                filter: $filter
                userScope: { userId: $userId }
                groupCompanyIds: $companyIds
              ) {
                id
                title
                company {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            filter: { ids },
            userId: this.current.user.id,
            companyIds: this.analytics.availableCompanyIds,
          },
        }),
      (acc, response) => {
        return acc.concat(response.jobs);
      }
    );

    const notAvailableString = this.intl.t(
      'components.data_table.not_available'
    );
    const deletedJobString = this.intl.t('common.deleted_job');

    return new ReportAudiencePromotions({
      container: this.container,
      rows: _rows.map((row) => {
        const promotion = promotions.find(
          (promotion) => promotion.id === String(row.promotionId)
        );
        const job = jobs.find(
          (job) => job.id.toString() === row.jobId?.toString()
        );

        let conversionRate: number | undefined;

        if (row.applicationIds.length && row.sessions) {
          conversionRate = row.applicationIds.length / row.sessions;
        }

        return {
          name:
            promotion?.shareLinkNameOrChannelName ||
            this.intl.t('reports.unknown'),

          job: job || { title: deletedJobString },
          jobId: row.jobId,
          applications: row.applicationIds,
          nrApplications: row.applicationIds.length,
          sessions: row.sessions,
          conversionRate: conversionRate || 0,
          averageRating: new AverageRating(this.store, row.candidateIds),
          candidateIds: row.candidateIds,
          companyName: job?.company.name || notAvailableString,
        };
      }),
    });
  });

  fetchTask = trackedTask(this, this.fetch);
}
