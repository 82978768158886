import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';

export default class SettingsAutomaticDeletionController extends Controller {
  @service intl;
  @service ttAlert;
  @service flashMessages;
  @service current;
  @service store;

  @tracked isResetDefaultModalOpen = false;

  get automaticDeletionSetting() {
    return get(this.model, 'automaticDeletionSetting');
  }

  get willBeDeletedCount() {
    return this.model.willBeDeletedCount.meta.total;
  }

  get formIsSaveable() {
    return this.daysSelectedForAllSettings;
  }

  get daysSelectedForAllSettings() {
    if (
      this.automaticDeletionSetting.inactiveCandidatesActivated &&
      !this.automaticDeletionSetting.inactiveCandidatesDays
    ) {
      return false;
    }

    if (
      this.automaticDeletionSetting.missingPurposeCandidatesActivated &&
      !this.automaticDeletionSetting.missingPurposeCandidatesDays
    ) {
      return false;
    }

    if (
      this.automaticDeletionSetting.removalRequestsActivated &&
      !this.automaticDeletionSetting.removalRequestsDays
    ) {
      return false;
    }

    if (
      this.automaticDeletionSetting.gdprConsentActivated &&
      !this.automaticDeletionSetting.gdprConsentDays
    ) {
      return false;
    }

    if (
      this.automaticDeletionSetting.rejectedJobApplicationsActivated &&
      !this.automaticDeletionSetting.rejectedJobApplicationsDays
    ) {
      return false;
    }

    return true;
  }

  @action
  async resetToDefault() {
    await this.automaticDeletionSetting.destroyRecord();
    set(
      this.model,
      'automaticDeletionSetting',
      this.store.createRecord('automatic-deletion-setting')
    );

    this.isResetDefaultModalOpen = false;
  }

  @action
  saveForm() {
    return new Promise((resolve, reject) => {
      this.automaticDeletionSetting
        .save()
        .then(() => {
          this.current.company.reload();
          resolve();
        })
        .catch((e) => {
          if (e.errors.length) {
            this.flashMessages.error(e.errors.firstObject.detail);
          }

          reject();
        });
    });
  }
}
