import Service from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class ImageAverageColorService extends Service {
  fac = null;

  @tracked urlToColorMap = {};

  getAverageColor = task(async (imageUrl) => {
    if (!this.fac) {
      const FastAverageColor = (await import('fast-average-color')).default;
      this.fac = new FastAverageColor();
    }

    if (!this.urlToColorMap[imageUrl]) {
      const img = new Image();
      await new Promise((resolve) => {
        img.crossOrigin = 'Anonymous';
        img.addEventListener('load', resolve, false);
        img.src = imageUrl;
      });
      const result = await this.fac.getColorAsync(img);

      this.urlToColorMap = { ...this.urlToColorMap, [imageUrl]: result };
    }
  });
}
