const doc = {
  "kind": "Document",
  "definitions": [
    {
      "kind": "OperationDefinition",
      "operation": "query",
      "name": {
        "kind": "Name",
        "value": "PromotionsQuery"
      },
      "variableDefinitions": [
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "dateRange"
            }
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "DateRangeAttributes"
              }
            }
          },
          "directives": []
        },
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "promotionIds"
            }
          },
          "type": {
            "kind": "ListType",
            "type": {
              "kind": "NonNullType",
              "type": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "ID"
                }
              }
            }
          },
          "directives": []
        }
      ],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [
          {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "applications"
            },
            "name": {
              "kind": "Name",
              "value": "eventQuery"
            },
            "arguments": [
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "dateRange"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "dateRange"
                  }
                }
              },
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "eventTypes"
                },
                "value": {
                  "kind": "ListValue",
                  "values": [
                    {
                      "kind": "EnumValue",
                      "value": "APPLIED"
                    },
                    {
                      "kind": "EnumValue",
                      "value": "HIRED"
                    }
                  ]
                }
              },
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "filters"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "jobApplicationPromotionId"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [
                          {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "any"
                            },
                            "value": {
                              "kind": "Variable",
                              "name": {
                                "kind": "Name",
                                "value": "promotionIds"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "aggregated"
                  },
                  "arguments": [
                    {
                      "kind": "Argument",
                      "name": {
                        "kind": "Name",
                        "value": "groupBy"
                      },
                      "value": {
                        "kind": "ListValue",
                        "values": [
                          {
                            "kind": "EnumValue",
                            "value": "PROMOTION_ID"
                          },
                          {
                            "kind": "EnumValue",
                            "value": "CHANNEL_ID"
                          }
                        ]
                      }
                    }
                  ],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "alias": {
                          "kind": "Name",
                          "value": "hiredCount"
                        },
                        "name": {
                          "kind": "Name",
                          "value": "countOccurrences"
                        },
                        "arguments": [
                          {
                            "kind": "Argument",
                            "name": {
                              "kind": "Name",
                              "value": "filters"
                            },
                            "value": {
                              "kind": "ObjectValue",
                              "fields": [
                                {
                                  "kind": "ObjectField",
                                  "name": {
                                    "kind": "Name",
                                    "value": "eventType"
                                  },
                                  "value": {
                                    "kind": "ObjectValue",
                                    "fields": [
                                      {
                                        "kind": "ObjectField",
                                        "name": {
                                          "kind": "Name",
                                          "value": "equals"
                                        },
                                        "value": {
                                          "kind": "EnumValue",
                                          "value": "HIRED"
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "alias": {
                          "kind": "Name",
                          "value": "appliedCount"
                        },
                        "name": {
                          "kind": "Name",
                          "value": "countOccurrences"
                        },
                        "arguments": [
                          {
                            "kind": "Argument",
                            "name": {
                              "kind": "Name",
                              "value": "filters"
                            },
                            "value": {
                              "kind": "ObjectValue",
                              "fields": [
                                {
                                  "kind": "ObjectField",
                                  "name": {
                                    "kind": "Name",
                                    "value": "eventType"
                                  },
                                  "value": {
                                    "kind": "ObjectValue",
                                    "fields": [
                                      {
                                        "kind": "ObjectField",
                                        "name": {
                                          "kind": "Name",
                                          "value": "equals"
                                        },
                                        "value": {
                                          "kind": "EnumValue",
                                          "value": "APPLIED"
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "alias": {
                          "kind": "Name",
                          "value": "promotionId"
                        },
                        "name": {
                          "kind": "Name",
                          "value": "jobApplicationPromotionId"
                        },
                        "arguments": [],
                        "directives": []
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "pageViews"
            },
            "name": {
              "kind": "Name",
              "value": "pageviewQuery"
            },
            "arguments": [
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "dateRange"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "dateRange"
                  }
                }
              },
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "filters"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "promotionId"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [
                          {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "any"
                            },
                            "value": {
                              "kind": "Variable",
                              "name": {
                                "kind": "Name",
                                "value": "promotionIds"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "aggregated"
                  },
                  "arguments": [
                    {
                      "kind": "Argument",
                      "name": {
                        "kind": "Name",
                        "value": "groupBy"
                      },
                      "value": {
                        "kind": "EnumValue",
                        "value": "PROMOTION"
                      }
                    }
                  ],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "promotionId"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "count"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "distinctCount"
                        },
                        "arguments": [
                          {
                            "kind": "Argument",
                            "name": {
                              "kind": "Name",
                              "value": "field"
                            },
                            "value": {
                              "kind": "EnumValue",
                              "value": "SESSION_ID"
                            }
                          }
                        ],
                        "directives": []
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ],
  "loc": {
    "start": 0,
    "end": 757
  }
};
export default doc;
