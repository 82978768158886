import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class SettingsRoute extends Route {
  @service current;
  @service flashMessages;
  @service intl;
  @service permissions;
  @service router;
  @service store;

  get hasNoAccess() {
    return (
      !this.permissions.has('settings/update_company') &&
      !this.permissions.has('settings/update_recruitment')
    );
  }

  beforeModel() {
    if (this.hasNoAccess) {
      this.flashMessages.error(this.intl.t('errors.dont_have_access_to_page'));
      this.router.replaceWith('dashboard');
    }

    // trigger a reload of the company, but don't wait for it to finish
    this.store.getReference('company', this.current.company.id).reload();
  }
}
