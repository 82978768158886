export default {
  "wrapper": "Index__wrapper_12n33q",
  "header": "Index__header_12n33q",
  "draggableArea": "Index__draggableArea_12n33q",
  "dragHandle": "Index__dragHandle_12n33q",
  "footer": "Index__footer_12n33q",
  "area": "Index__area_12n33q",
  "trash": "Index__trash_12n33q",
  "options": "Index__options_12n33q",
  "conditional": "Index__conditional_12n33q",
  "select": "Index__select_12n33q",
  "additional": "Index__additional_12n33q",
  "title": "Index__title_12n33q",
  "subtitle": "Index__subtitle_12n33q",
  "text": "Index__text_12n33q",
  "read": "Index__read_12n33q",
  "editIcon": "Index__editIcon_12n33q",
  "inner": "Index__inner_12n33q"
};
