import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    approved: { serialize: false },
    colleagues: { serialize: 'ids' },
    partners: { serialize: 'records' },
    team: { serialize: 'ids' },
    imageWithSetting: {
      serialize: 'records',
      deserialize: 'ids',
      key: 'image_with_setting_attributes',
    },

    pickedQuestions: { serialize: 'records' },
    stages: { serialize: 'records', deserialize: 'ids' },
    scorecardPicks: { serialize: 'records', deserialize: 'ids' },
    recruitingFirms: { serialize: 'ids' },
    messageWidget: { serialize: 'records', key: 'message_widget' },
    unreadApplications: { serialize: false },
    responseTimeOptions: { serialize: false },
    pickedInterviewKits: {
      key: 'picked_interview_kits_attributes',
      serialize: 'records',
    },

    pickedCustomFields: {
      key: 'picked_custom_fields_attributes',
      serialize: 'records',
    },

    teamMembers: { serialize: 'ids' },
  },
});
