function hexToRgb(hex: string): number[] {
  hex = hex.replace(/^#/, '');
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return [r, g, b];
}

export function hexIsCloseToWhite(hex: string, tolerance = 15) {
  const [r, g, b] = hexToRgb(hex);
  if (r && g && b) {
    return 255 - r <= tolerance && 255 - g <= tolerance && 255 - b <= tolerance;
  } else {
    return false;
  }
}
