import { tracked } from '@glimmer/tracking';
import { IAttendeeUser, IUser } from 'teamtailor/components/meeting/types';
import { AttendeeClass, IAttendeeRecord } from './attendee';
import { AttendeeType } from './attendee-list';

export interface IAttendeeUserRecord extends IAttendeeRecord {
  userId?: string;
  hasInterview: boolean;
  isOrganizer?: boolean;
  excludeAvailability?: boolean;
}

export class UserAttendeeClass extends AttendeeClass {
  @tracked declare hasInterview: boolean;
  @tracked declare excludeAvailability: boolean;
  @tracked declare user?: IUser;
  @tracked declare isOrganizer: boolean;
  @tracked isDisabled = false;

  constructor(attendee: IAttendeeUser) {
    super(attendee);
    this.hasInterview = attendee.hasInterview ?? false;
    this.user = attendee.user;
    this.isOrganizer = attendee.isOrganizer;
    this.excludeAvailability = attendee.excludeAvailability;
  }

  get disabled(): boolean {
    return this.isDisabled;
  }

  static from(user: IUser): UserAttendeeClass {
    const attendee = { user } as IAttendeeUser;
    return new UserAttendeeClass(attendee);
  }

  isAttendee(attendee: AttendeeType): boolean {
    const userAttendee = attendee as UserAttendeeClass | undefined;
    return userAttendee?.user?.id === this.user?.id;
  }

  get attendeeType(): string {
    return 'user';
  }

  toAttributes(): IAttendeeUserRecord {
    return {
      userId: this.user?.id,
      id: this.id,
      hasInterview: this.hasInterview,
      isOrganizer: this.isOrganizer,
      excludeAvailability: this.excludeAvailability,
    };
  }

  get cronofyAuthorization() {
    return this.user?.authorizations?.filter(
      (authorization) => authorization.isCronofy
    )?.[0];
  }

  get hasConnectedCalendar(): boolean {
    return !!this.cronofyAuthorization;
  }
}
