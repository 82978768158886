import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { get } from 'teamtailor/utils/get';
import { AddonFeature } from 'types/features';

export default class InterviewsRoute extends ScrollToTopRoute {
  @service
  declare store: Store;

  requiredFeature: AddonFeature = 'interview_kit';

  async model(): Promise<unknown> {
    const job = this.modelFor('jobs.edit');
    const jobDetail = await get(job, 'jobDetail');
    const pickedInterviewKits = get(jobDetail, 'pickedInterviewKits');
    const interviewKits = await this.store.query('interview-kit', {
      ids: pickedInterviewKits.mapBy('interviewKitId'),
    });

    const evaluationController = this.controllerFor('jobs.edit.evaluation');

    return {
      job,
      jobDetail,
      interviewKits,
      pickedInterviewKits,
      actions: {
        refetchInterviewKits:
          evaluationController.fetchInterviewKits.perform.bind(
            evaluationController
          ),
      },
    };
  }
}
