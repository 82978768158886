import {
  FilterType,
  FilterOption,
  FilterValue,
  IFilter,
  IOperator,
  SerializableFilterValue,
  FilterBaseValueType,
} from 'teamtailor/components/fancy-filters.d';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';
import BaseOperator from 'teamtailor/utils/operators/base-operator';
import { JSONFilter } from './candidate-filters-json';
import { SafeString } from '@ember/template/-private/handlebars';

export default class ActiveFilter implements IFilter {
  @tracked dropdownApi?: { updatePosition: () => void };
  @tracked filter?: FilterOption;
  @tracked selectedOperator?: IOperator;
  @tracked value?: FilterValue;
  @tracked operators: IOperator[] = [];
  @tracked isOpen = false;
  @tracked translateValue = false;
  @tracked rawValue?: string | string[] | null;

  constructor(filter: FilterOption) {
    this.filter = filter;
    this.value = filter.defaultValue ?? '';
    this.translateValue = filter.translateValue || false;

    for (const operator of this.filter.operators) {
      const operatorFunction = operator as () => BaseOperator;
      const op = operatorFunction();
      op.filter = this;
      this.operators.push(op);
    }

    if (this.operators.length > 0) {
      this.selectedOperator = this.operators.firstObject;
    }
  }

  equals(filter: ActiveFilter): boolean {
    return JSON.stringify(filter.asJSON()) === JSON.stringify(this.asJSON());
  }

  isFulfilled(value: FilterValue): boolean {
    if (Array.isArray(value)) {
      return (value as FilterValue[]).every((v: FilterValue) =>
        this.isFulfilled(v)
      );
    }

    if (
      typeof value === 'object' &&
      Object.keys(value).includes('isFulfilled')
    ) {
      return (value as any).isFulfilled;
    }

    if (value instanceof Promise) {
      return false;
    }

    return true;
  }

  get isContainer(): boolean {
    return this.filter?.isContainer || false;
  }

  get serializedValue(): SerializableFilterValue {
    if (this.value && !this.isFulfilled(this.value)) {
      return this.rawValue as SerializableFilterValue;
    }

    return (
      this.filter?.serialize?.(this.value) ||
      (this.value as SerializableFilterValue)
    );
  }

  get name(): string {
    return this.filter?.name || '';
  }

  get translatedName(): SafeString | string | undefined {
    return this.filter?.translatedName || this.filter?.name;
  }

  get isValid(): boolean {
    return !!(
      isPresent(this.selectedOperator) &&
      this.selectedOperator?.isValidValue(this.value)
    );
  }

  get options(): string[] {
    return this.filter?.options?.toArray
      ? this.filter.options.toArray()
      : this.filter?.options || [];
  }

  get modelQueryParams() {
    return this.filter?.modelQueryParams;
  }

  get filterType(): FilterType | undefined {
    return this.filter?.type;
  }

  parse(json: JSONFilter): void {
    this.selectedOperator = this.operators.find((operator) => {
      if (operator.isUsed(json)) {
        return true;
      }

      return false;
    });

    if (!this.selectedOperator && this.filter?.nested?.id) {
      this.selectedOperator = this.operators.find((operator) => {
        if ((operator as BaseOperator).operatorName === 'exists') {
          return Object.keys(json).includes('not') === operator.not;
        }

        return false;
      });
    }

    this.rawValue = this.selectedOperator?.parseRawValue(json);
    this.value = this.selectedOperator?.parseValue(this.rawValue!);
    if (!Array.isArray(this.value) && typeof this.value === 'object') {
      this.value = [this.value] as [any];
    }

    if (Array.isArray(this.value)) {
      this.value.forEach((v: any, i) => {
        if (Object.keys(v).includes('isFulfilled')) {
          if (v.isFulfilled) {
            (this.value as [FilterValue])[i] = v.content as FilterValue;
          } else {
            (this.value as [any])[i].then((value: FilterValue) => {
              if (
                Array.isArray(value) &&
                (this.value as FilterBaseValueType[]).length === 1
              ) {
                this.value = value;
              } else {
                (this.value as [FilterValue])[i] = value;
              }
            });
          }
        } else if (v instanceof Promise) {
          v.then((value: FilterValue) => {
            (this.value as [FilterValue])[i] = value;
          });
        }
      });
    }
  }

  asJSON(): JSONFilter {
    if (!this.selectedOperator) {
      throw new Error('Operator is not set');
    }

    let query: JSONFilter = this.selectedOperator.asJSON();

    if (this.filter?.nested) {
      query = {
        [this.filter.nested.name]: query,
      };
      if (this.filter.nested.id) {
        const idKey = `${this.filter.nested.name}_id`;
        (query[this.filter.nested.name] as JSONFilter)[idKey] =
          this.filter.nested.id;
      }
    }

    if (
      this.selectedOperator.not &&
      !this.selectedOperator.ignoreNotStructure
    ) {
      query = {
        not: query,
      };
    }

    return query;
  }

  serialize() {
    if (!this.selectedOperator) {
      throw new Error('Operator is not set');
    }

    return {
      filter: this.filter?.name,
      operator: this.selectedOperator.name,
      value: this.value,
    };
  }

  deserialize(value: any): FilterValue {
    return this.filter?.deserialize ? this.filter.deserialize(value) : value;
  }
}
