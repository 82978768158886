import Model, { attr, belongsTo } from '@ember-data/model';
import CompanyModel from 'teamtailor/models/company';

export default class SubscriptionInvoiceModel extends Model {
  @attr('string') declare date: string;
  @attr('string') declare payedAt: string;
  @attr('number') declare total: number;
  @attr('string') declare currency: string;
  @attr('string') declare description: string;

  @belongsTo('company', { async: false })
  declare company: CompanyModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'subscription-invoice': SubscriptionInvoiceModel;
  }
}
