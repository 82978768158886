import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div {{this.showWidget @candidate}}>\n  {{#if\n    (and this.cookieConsent.functional this.widgetId this.showLinkedinWidget)\n  }}\n    <CandidateModalNew::Main::Section\n      @title={{t \"candidates.candidate.candidate_modal.linkedin_profile\"}}\n      @id=\"linkedin-profile\"\n    >\n      <:content>\n        <Linkedin::CandidateWidget\n          @candidate={{@candidate}}\n          @widgetId={{this.widgetId}}\n        />\n\n      </:content>\n    </CandidateModalNew::Main::Section>\n  {{/if}}\n</div>", {"contents":"<div {{this.showWidget @candidate}}>\n  {{#if\n    (and this.cookieConsent.functional this.widgetId this.showLinkedinWidget)\n  }}\n    <CandidateModalNew::Main::Section\n      @title={{t \"candidates.candidate.candidate_modal.linkedin_profile\"}}\n      @id=\"linkedin-profile\"\n    >\n      <:content>\n        <Linkedin::CandidateWidget\n          @candidate={{@candidate}}\n          @widgetId={{this.widgetId}}\n        />\n\n      </:content>\n    </CandidateModalNew::Main::Section>\n  {{/if}}\n</div>","moduleName":"teamtailor/components/candidate-modal-new/main/section/linkedin-widget/index.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-modal-new/main/section/linkedin-widget/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import { CandidateModel } from 'teamtailor/models';
import CookieConsentService from 'teamtailor/services/cookie-consent';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';
import { modifier } from 'ember-modifier';

interface Args {
  candidate: CandidateModel;
  anonymous: boolean;
}

export default class extends Component<Args> {
  @service declare current: Current;
  @service declare cookieConsent: CookieConsentService;

  @tracked showLinkedinWidget = false;

  get widgetId() {
    const integration = get(this.linkedinSetting, 'content.integrationSetup');
    const anonymousStage = this.args.anonymous;
    const integrationEnabled = get(this.linkedinSetting, 'rscEnabled');

    if (integration && !anonymousStage && integrationEnabled) {
      const findContractWithPlugin = Object.keys(integration).filter(
        (id) => integration[id].PROFILE_PLUGIN
      );
      const [contractWithPluginId] = findContractWithPlugin;

      if (
        contractWithPluginId !== undefined &&
        integration[contractWithPluginId]
      ) {
        return integration[contractWithPluginId].PROFILE_PLUGIN.context;
      }
    }

    return null;
  }

  get linkedinSetting() {
    return get(this.current, 'company.linkedinsetting');
  }

  showWidget = modifier((_element, [_candidate]) => {
    this.showLinkedinWidget = false;
    later(() => {
      this.showLinkedinWidget = true;
    }, 100);
  });
}
