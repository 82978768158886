type GrowToSize<T, N extends number, A extends T[]> = A['length'] extends N
  ? A
  : GrowToSize<T, N, [...A, T]>;

type FixedArray<T, N extends number> = GrowToSize<T, N, []>;

export type ColorsObject = {
  [key: string]: string;
};

export const colors: FixedArray<string, 20> = [
  '#f43f85',
  '#9013fe',
  '#1f8ceb',
  '#f56023',
  '#ffd700',
  '#3bb7bb',
  '#7ed321',
  '#fbd5af',
  '#799351',
  '#a20a0a',
  '#e7a117',
  '#002bdc',
  '#ff9a76',
  '#562349',
  '#ffec6f',
  '#e43a19',
  '#0e5f76',
  '#b5421e',
  '#beceb0',
  '#a8026f',
];

export const colorClasses: string[] = [
  'text-charts-color-0',
  'text-charts-color-1',
  'text-charts-color-2',
  'text-charts-color-3',
  'text-charts-color-4',
  'text-charts-color-5',
  'text-charts-color-6',
  'text-charts-color-7',
  'text-charts-color-8',
  'text-charts-color-9',
  'text-charts-color-10',
  'text-charts-color-11',
  'text-charts-color-12',
  'text-charts-color-13',
  'text-charts-color-14',
  'text-charts-color-15',
  'text-charts-color-16',
  'text-charts-color-17',
  'text-charts-color-18',
  'text-charts-color-19',
];

export const bgColorClasses: string[] = [
  'bg-charts-color-0',
  'bg-charts-color-1',
  'bg-charts-color-2',
  'bg-charts-color-3',
  'bg-charts-color-4',
  'bg-charts-color-5',
  'bg-charts-color-6',
  'bg-charts-color-7',
  'bg-charts-color-8',
  'bg-charts-color-9',
  'bg-charts-color-10',
  'bg-charts-color-11',
  'bg-charts-color-12',
  'bg-charts-color-13',
  'bg-charts-color-14',
  'bg-charts-color-15',
  'bg-charts-color-16',
  'bg-charts-color-17',
  'bg-charts-color-18',
  'bg-charts-color-19',
];

export const bgOtherColorClass = 'bg-charts-other-color';

export const stageTypeColors: ColorsObject = {
  inbox: '#3b82f6',
  screening: '#f97316',
  interview: '#a855f7',
  offer: '#eab308',
  hired: '#26c12b',
  rejected: '#ef4444',
  in_process: '#71717A',
};

export const stageTypeColorClasses: ColorsObject = {
  inbox: 'text-charts-stage-type-inbox',
  screening: 'text-charts-stage-type-screening',
  interview: 'text-charts-stage-type-interview',
  offer: 'text-charts-stage-type-offer',
  hired: 'text-charts-stage-type-hired',
  rejected: 'text-charts-stage-type-rejected',
  in_process: 'text-charts-stage-type-in_process',
};

export const bgStageTypeColorClasses: ColorsObject = {
  inbox: 'bg-charts-stage-type-inbox',
  screening: 'bg-charts-stage-type-screening',
  interview: 'bg-charts-stage-type-interview',
  offer: 'bg-charts-stage-type-offer',
  hired: 'bg-charts-stage-type-hired',
  rejected: 'bg-charts-stage-type-rejected',
  in_process: 'bg-charts-stage-type-in_process',
};

export const activityTypeColors: ColorsObject = {
  moves: '#F55291',
  notes: '#9013FE',
  sources: '#FF8736',
  reviews: '#1F8CEB',
  messages: '#FFD700',
  scores: '#BF821B',
  rejections: '#D0021B',
  hires: '#7ED321',
  interviews: '#4C162B',
};

export const activityTypeColorClasses: ColorsObject = {
  moves: 'text-charts-activity-type-moves',
  notes: 'text-charts-activity-type-notes',
  sources: 'text-charts-activity-type-sources',
  reviews: 'text-charts-activity-type-reviews',
  messages: 'text-charts-activity-type-messages',
  scores: 'text-charts-activity-type-scores',
  rejections: 'text-charts-activity-type-rejections',
  hires: 'text-charts-activity-type-hires',
  interviews: 'text-charts-activity-type-interviews',
};

export const bgActivityTypeColorClasses: ColorsObject = {
  moves: 'bg-charts-activity-type-moves',
  notes: 'bg-charts-activity-type-notes',
  sources: 'bg-charts-activity-type-sources',
  reviews: 'bg-charts-activity-type-reviews',
  messages: 'bg-charts-activity-type-messages',
  scores: 'bg-charts-activity-type-scores',
  rejections: 'bg-charts-activity-type-rejections',
  hires: 'bg-charts-activity-type-hires',
  interviews: 'bg-charts-activity-type-interviews',
};

export const nps: string[] = ['#7ed321', '#d0021b', '#dddee1'];
export const npsClasses: string[] = [
  'text-charts-nps-0',
  'text-charts-nps-1',
  'text-charts-nps-2',
];
export const bgNpsClasses: string[] = [
  'bg-charts-nps-0',
  'bg-charts-nps-1',
  'bg-charts-nps-2',
];
export const movingAverageColors: ColorsObject = {
  positiveBorder: '#16A31B',
  negativeBorder: '#DC2626',
  positiveBackground: '#DEFADE',
  negativeBackground: '#FEE1E2',
};
export const primaryColor = '#F43F85';
export const secondaryColor = '#71717A';
export const funnelColors: string[] = ['#f43f85', '#9013FE', '#1F8CEB'];
export const grey: string[] = ['#C4C4C4'];
export const greyClasses: string[] = ['text-charts-grey-0'];
export const bgGreyClasses: string[] = ['bg-charts-grey-0'];

export default colors;
