import Model, { hasMany, attr, type SyncHasMany } from '@ember-data/model';
import SearchResultModel from 'teamtailor/models//search-result';

export default class SearchModel extends Model {
  @hasMany('search-result', { async: false })
  declare searchResults: SyncHasMany<SearchResultModel>;

  @attr('string') declare query: string;
  @attr('string') declare filterByType: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    search: SearchModel;
  }
}
