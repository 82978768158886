import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class PromoteRoute extends ScrollToTopRoute {
  @service current;
  @service intl;
  @service user;
  @service store;

  async model() {
    const channels = await this.store.findAll('channel');
    return {
      channels,
      job: this.modelFor('jobs.job'),
    };
  }

  afterModel({ job }) {
    super.afterModel(...arguments);
    get(this.current.company, 'channelActivations');
    get(job, 'promotions');
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.showPurchaseHistory = false;
    controller.showShareLinksHistory = false;
  }
}
