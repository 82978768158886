import Controller from '@ember/controller';
import { get, set, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import filterList from 'teamtailor/utils/filter-list';

export default class InterviewKitsController extends Controller {
  @service user;

  @tracked search = '';

  filterAttributes = ['name', 'tagNames'];

  get job() {
    return this.model.job;
  }

  get interviewKits() {
    return this.model.interviewKits.rejectBy('job.id');
  }

  get sortedAvailablePickedInterviewKits() {
    return get(this, 'job.jobDetail.sortedPickedInterviewKits').filter(
      (kit) => kit.interviewKit.content.isHidden === false
    );
  }

  get availableInterviewKits() {
    return this.interviewKits.filter((kit) => kit.isHidden === false);
  }

  get availableKits() {
    const sortedPickedInterviewKitIds = get(
      this,
      'job.jobDetail.sortedPickedInterviewKits'
    ).mapBy('interviewKitId');
    return this.availableInterviewKits.filter((interviewKit) => {
      return !sortedPickedInterviewKitIds.includes(interviewKit.id);
    });
  }

  get filteredAvailableKits() {
    return filterList(this.filterAttributes, this.availableKits, this.search);
  }

  updateRowOrderPosition() {
    get(this, 'job.jobDetail.sortedPickedInterviewKits')
      .rejectBy('isDeleted')
      .sortBy('position')
      .forEach((pickedInterviewKit, index) =>
        set(pickedInterviewKit, 'rowOrderPosition', index)
      );
  }

  @action
  addInterviewKit(interviewKit) {
    let rowOrderPosition = 0;

    if (get(this, 'job.jobDetail.sortedPickedInterviewKits')) {
      this.updateRowOrderPosition();
      rowOrderPosition =
        get(
          this,
          'job.jobDetail.sortedPickedInterviewKits.lastObject.rowOrderPosition'
        ) + 1;
    }

    this.job.jobDetail.content.pickedInterviewKits.createRecord({
      interviewKit,
      rowOrderPosition,
      interviewKitId: interviewKit.id,
    });

    this.search = '';
  }

  @action
  removeInterviewKit(pickedInterviewKit) {
    pickedInterviewKit.deleteRecord();
    this.updateRowOrderPosition();
  }

  @action
  dragEnd({ draggedItem: pickedInterviewKit, sourceIndex, targetIndex }) {
    get(this, 'job.jobDetail.sortedPickedInterviewKits')
      .rejectBy('isDeleted')
      .forEach((kit, index) => set(kit, 'rowOrderPosition', index * 10 + 1));

    const offset = targetIndex < sourceIndex ? 0 : 2;
    set(pickedInterviewKit, 'rowOrderPosition', targetIndex * 10 + offset);
    this.updateRowOrderPosition();
  }
}
