import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<strong>\n  {{t \"questions.filters.value\"}}:\n  {{this.rangeDisplayValue}}\n  {{this.question.unit}}\n</strong>\n\n<Core::Range\n  @value={{this.range}}\n  @min={{@question.questionData.start_with}}\n  @max={{@question.questionData.end_with}}\n  @onChange={{this.handleChange}}\n  @onInput={{this.handleInput}}\n/>", {"contents":"<strong>\n  {{t \"questions.filters.value\"}}:\n  {{this.rangeDisplayValue}}\n  {{this.question.unit}}\n</strong>\n\n<Core::Range\n  @value={{this.range}}\n  @min={{@question.questionData.start_with}}\n  @max={{@question.questionData.end_with}}\n  @onChange={{this.handleChange}}\n  @onInput={{this.handleInput}}\n/>","moduleName":"teamtailor/components/question-filter-range.hbs","parseOptions":{"srcName":"teamtailor/components/question-filter-range.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';

type Question = {
  questionData?: {
    start_with?: number;
    end_with?: number;
  };
};

interface Signature {
  Args: {
    filter: { range?: number };
    question: Question;
    updateFilter: (arg: Record<string, number>) => void;
  };
}

export default class QuestionFilterRange extends Component<Signature> {
  @tracked declare range;
  @tracked declare rangeDisplayValue;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.range =
      args.filter.range === undefined
        ? get(args.question, 'questionData')?.start_with ?? 0
        : args.filter.range;

    this.rangeDisplayValue = this.range;
  }

  @action
  handleChange(value: number) {
    this.range = value;
    this.args.updateFilter({ range: this.range });
  }

  @action
  handleInput(value: number) {
    this.rangeDisplayValue = value;
  }
}
