import Service, { inject as service } from '@ember/service';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import { action } from '@ember/object';

const COOKIE_GROUPS = ['functional'];

export default class CookieConsentService extends Service {
  @service cookies;
  @service intercom;
  @computedLocalStorage(Boolean, 'functionalKey')
  _functional;

  get functional() {
    return this._functional;
  }

  set functional(newValue) {
    if (newValue !== this._functional) {
      this.applyCookieGroupSideEffect('functional', newValue);
    }

    this._functional = newValue;
  }

  functionalKey = 'CookieConsent--functional';

  @computedLocalStorage(Boolean, 'hideCookieConsentKey')
  hideCookieConsent;

  hideCookieConsentKey = 'CookieConsent--hideCookieConsent';

  cookieGroups = COOKIE_GROUPS;

  applyCookieGroupSideEffect(cookieGroup, userHasConsented) {
    switch (cookieGroup) {
      case 'functional': {
        if (userHasConsented) {
          if (this.intercom.user.id) {
            this.intercom.start();
          }
        } else {
          this.intercom.shutdown();
        }

        break;
      }
    }
  }

  @action
  acceptAll() {
    this.hideCookieConsent = true;

    this.cookieGroups.forEach((cookieGroup) => {
      this[cookieGroup] = true;
    });
  }

  @action
  declineAll() {
    this.hideCookieConsent = true;

    this.cookieGroups.forEach((cookieGroup) => {
      this[cookieGroup] = false;
    });
  }

  @action
  resetAll() {
    this.hideCookieConsent = false;

    this.cookieGroups.forEach((cookieGroup) => {
      this[cookieGroup] = false;
    });
  }
}
