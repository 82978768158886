import { tracked } from '@glimmer/tracking';
import { ILocation, ILocationBase } from 'teamtailor/components/meeting/types';

export type MeetingEventLocationLocationAttributes = {
  id?: string;
  locationId?: string;
  _destroy?: boolean;
};

export class MeetingEventLocationLocationClass {
  @tracked declare id?: string;
  @tracked declare location?: ILocation;

  constructor(location: ILocationBase) {
    this.id = location.id;
    this.location = location.location;
  }

  toAttributes(): MeetingEventLocationLocationAttributes {
    return {
      id: this.id,
      locationId: this.location?.id,
    };
  }
}
