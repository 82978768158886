export default {
  "modalContent": "AddForm__modalContent_z3cnld",
  "formCol": "AddForm__formCol_z3cnld",
  "header": "AddForm__header_z3cnld",
  "footer": "AddForm__footer_z3cnld",
  "pager": "AddForm__pager_z3cnld",
  "username": "AddForm__username_z3cnld",
  "usernameInner": "AddForm__usernameInner_z3cnld",
  "queueRow": "AddForm__queueRow_z3cnld",
  "queueRow--isReadyForEdit": "AddForm__queueRow--isReadyForEdit_z3cnld",
  "queueRow--active": "AddForm__queueRow--active_z3cnld",
  "queueRow--disabled": "AddForm__queueRow--disabled_z3cnld",
  "queueRow--missingData": "AddForm__queueRow--missingData_z3cnld",
  "dropZone": "AddForm__dropZone_z3cnld",
  "fileUpload--beforeDrop": "AddForm__fileUpload--beforeDrop_z3cnld",
  "circle": "AddForm__circle_z3cnld",
  "circleBackground": "AddForm__circleBackground_z3cnld",
  "circleBar": "AddForm__circleBar_z3cnld",
  "fill": "AddForm__fill_z3cnld"
};
