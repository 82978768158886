import Model, { attr } from '@ember-data/model';

export default class VideoModel extends Model {
  @attr('string') declare file: File;
  @attr('string') declare fileFileName: string;
  @attr('string') declare fileName: string;
  @attr('string') declare url: string;
  @attr('string', { defaultValue: 'cover' }) declare transcodeType: string;
  @attr('string', { defaultValue: 'pending' }) declare transcodeStatus: string;
  @attr('date') declare createdAt: Date;

  get isTranscodePending() {
    return this.transcodeStatus === 'pending';
  }

  get isTranscodeCompleted() {
    return this.transcodeStatus === 'completed';
  }

  get isTranscodeError() {
    return this.transcodeStatus === 'error';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    video: VideoModel;
  }
}
