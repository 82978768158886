import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { currentStack } from 'teamtailor/utils/stacks';
import { get } from 'teamtailor/utils/get';
import toggleInList from 'teamtailor/utils/toggle-in-list';
export default class GeneralController extends Controller {
  @service translations;
  @service store;
  @service current;
  @service intl;

  get fetchIndustries() {
    return this.store.findAll('industry');
  }

  get industry() {
    const { industryId } = this.current.company;
    return industryId ? this.store.peekRecord('industry', industryId) : null;
  }

  get currentStack() {
    return currentStack() ? currentStack().name : null;
  }

  get dataPrivacySetting() {
    return get(this.current.company, 'dataPrivacySetting');
  }

  get meetingVisibilities() {
    return [
      {
        id: 'default',
        label: this.intl.t(
          'components.meetings.event_scheduler.visibility_options.default'
        ),
      },
      {
        id: 'no_candidate_names_in_invite',
        label: this.intl.t(
          'components.meetings.event_scheduler.visibility_options.no_candidate_names_in_invite'
        ),
      },
      {
        id: 'private',
        label: this.intl.t(
          'components.meetings.event_scheduler.visibility_options.private'
        ),
      },
    ];
  }

  @action
  updateMeetingVisibility(meetingVisibility) {
    this.current.company.meetingVisibility = meetingVisibility.id;
  }

  @action
  updateIndustry(industry) {
    this.current.company.industryId = industry.id;
  }

  @action
  handleNameChange(event) {
    const name = event.target.value;
    this.current.company.name = name;
  }

  @action
  handlePickLocaleAts(locale) {
    this.current.company.localeAts = locale;
  }

  @action
  async save() {
    const changedAttributes = this.current.company.changedAttributes();

    const dataPrivacySetting = await this.dataPrivacySetting;
    if (dataPrivacySetting) {
      dataPrivacySetting.save();
    }

    await this.current.company.save();
    const userLocale = this.current.user.locale;
    if (!userLocale && changedAttributes.localeAts) {
      await this.translations.setLocale(this.current.company.localeAts);
    }
  }

  @action
  async toggleUser(user) {
    const dataPrivacySetting = await this.dataPrivacySetting;

    if (!dataPrivacySetting) {
      this.current.company.dataPrivacySetting = await this.store.createRecord(
        'data-privacy-setting',
        {
          users: [user],
        }
      );
    } else {
      toggleInList(get(this.dataPrivacySetting, 'users'), user);
    }
  }
}
