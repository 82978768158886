import Model, {
  hasMany,
  belongsTo,
  attr,
  type AsyncBelongsTo,
  type AsyncHasMany,
} from '@ember-data/model';
import { OccupationFieldModel, OccupationNameModel } from 'teamtailor/models';
export default class OccupationGroupModel extends Model {
  @belongsTo('occupation-field')
  declare occupationField: AsyncBelongsTo<OccupationFieldModel>;

  @hasMany('occupation-name')
  declare occupationNames: AsyncHasMany<OccupationNameModel>;

  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'occupation-group': OccupationGroupModel;
  }
}
