import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::DropdownMenu\n  @fallbackPlacements=\"vertical\"\n  @useAnimationFrame={{true}}\n  @onIsOpenChange={{@onIsOpenChange}}\n  @isDisabled={{@disabled}}\n  @icon=\"plus\"\n  @text={{@label}}\n  class=\"print:invisible\"\n  as |menu|\n>\n  <FancyFilters::FilterList\n    class=\"-my-8\"\n    @appearance={{@appearance}}\n    @closeDropDown={{menu.api.close}}\n    @onSelect={{@onSelect}}\n    @options={{@options}}\n  >\n    <:category as |category|>\n      {{#if (has-block \"category\")}}\n        {{yield category to=\"category\"}}\n      {{/if}}\n    </:category>\n    <:option as |option|>\n      {{yield option to=\"option\"}}\n    </:option>\n  </FancyFilters::FilterList>\n</Core::DropdownMenu>", {"contents":"<Core::DropdownMenu\n  @fallbackPlacements=\"vertical\"\n  @useAnimationFrame={{true}}\n  @onIsOpenChange={{@onIsOpenChange}}\n  @isDisabled={{@disabled}}\n  @icon=\"plus\"\n  @text={{@label}}\n  class=\"print:invisible\"\n  as |menu|\n>\n  <FancyFilters::FilterList\n    class=\"-my-8\"\n    @appearance={{@appearance}}\n    @closeDropDown={{menu.api.close}}\n    @onSelect={{@onSelect}}\n    @options={{@options}}\n  >\n    <:category as |category|>\n      {{#if (has-block \"category\")}}\n        {{yield category to=\"category\"}}\n      {{/if}}\n    </:category>\n    <:option as |option|>\n      {{yield option to=\"option\"}}\n    </:option>\n  </FancyFilters::FilterList>\n</Core::DropdownMenu>","moduleName":"teamtailor/components/fancy-filters/add-filter-button.hbs","parseOptions":{"srcName":"teamtailor/components/fancy-filters/add-filter-button.hbs"}});
import Component from '@glimmer/component';
import { FilterOption } from 'teamtailor/components/fancy-filters.d';

interface AddFilterButtonArgs {
  options: FilterOption[];
  onSelect: (value: FilterOption) => void;
  appearance?: string;
}

export default class AddFilterButton extends Component<AddFilterButtonArgs> {}
