import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { PagePublicationModel, CareerSiteHeaderModel } from 'teamtailor/models';

export default class NavigationItemModel extends Model {
  @belongsTo('career-site-header')
  declare careerSiteHeader: AsyncBelongsTo<CareerSiteHeaderModel>;

  @belongsTo('page-publication')
  declare pagePublication: AsyncBelongsTo<PagePublicationModel>;

  @attr('boolean') declare visible: boolean;
  @attr('number') declare rowOrder: number;
  @attr('number') declare rowOrderPosition: number;
  @attr('string') declare customTitle: string;
  @attr('string') declare customUrl: string;
  @attr('string') declare staticType: string;
  @attr('string') declare title: string;
  @attr('string') declare url: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'navigation-item': NavigationItemModel;
  }
}
