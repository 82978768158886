import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{@customField.name}}\n  </span>\n  <Core::DateTimePicker\n    @enableTime={{false}}\n    @isFullWidth={{true}}\n    @value={{this.selectedRange}}\n    @onChange={{this.handleChange}}\n    @triggerText={{this.label}}\n    @dateFormat={{\"dddd, MMM D\"}}\n    @mode=\"range\"\n  />\n</label>", {"contents":"<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{@customField.name}}\n  </span>\n  <Core::DateTimePicker\n    @enableTime={{false}}\n    @isFullWidth={{true}}\n    @value={{this.selectedRange}}\n    @onChange={{this.handleChange}}\n    @triggerText={{this.label}}\n    @dateFormat={{\"dddd, MMM D\"}}\n    @mode=\"range\"\n  />\n</label>","moduleName":"teamtailor/components/sidebar/filters/custom-date.hbs","parseOptions":{"srcName":"teamtailor/components/sidebar/filters/custom-date.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';

const userTimezone = moment.tz.guess();
const formatDate = (date) => moment.tz(date, userTimezone).format('YYYY-MM-DD');
export default class DateComponent extends Component {
  @service intl;

  get label() {
    let values = this.selectedRange;

    if (values?.length > 0) {
      return values.map((v) => formatDate(v)).join(' - ');
    }

    return this.intl.t('common.all');
  }

  get selectedRange() {
    let values = this.args.selected;

    if (values) {
      let returnValues = [];
      if (values.date_query_start) {
        returnValues.push(values.date_query_start);
      }

      if (values.date_query_end) {
        returnValues.push(values.date_query_end);
      }

      return returnValues.map((val) => moment.tz(val, userTimezone).toDate());
    }

    return [];
  }

  @action
  handleChange(dates) {
    if (dates.length < 2) {
      return;
    }

    const { 0: startDate, 1: endDate } = dates;

    this.args.onChange(formatDate(startDate), formatDate(endDate));
  }
}
