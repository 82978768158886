import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';

export default class JobsJobPromotePromotionEditExternalRoute extends Route {
  @service promotionRequests;
  @service store;

  async model() {
    const { promotion_id } = this.paramsFor('jobs.job.promote.promotion');
    const promotion = await this.store.findRecord('promotion', promotion_id);

    return hash({
      promotion,
      channel: promotion.channel,
      job: promotion.job,
    });
  }
}
