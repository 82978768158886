import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"header\" {{did-insert this.setDefaultDates}}>\n  <h4 class=\"flex-1\">\n    {{@title}}\n  </h4>\n\n  <Insights::DatePickerButton\n    @startDate={{this.startDate}}\n    @endDate={{this.endDate}}\n    @onUpdate={{this.handleUpdateDates}}\n  />\n</div>\n\n<div local-class=\"content\">\n  {{yield this.startDate this.endDate}}\n</div>", {"contents":"<div local-class=\"header\" {{did-insert this.setDefaultDates}}>\n  <h4 class=\"flex-1\">\n    {{@title}}\n  </h4>\n\n  <Insights::DatePickerButton\n    @startDate={{this.startDate}}\n    @endDate={{this.endDate}}\n    @onUpdate={{this.handleUpdateDates}}\n  />\n</div>\n\n<div local-class=\"content\">\n  {{yield this.startDate this.endDate}}\n</div>","moduleName":"teamtailor/components/insights/section-heading.hbs","parseOptions":{"srcName":"teamtailor/components/insights/section-heading.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import moment from 'moment-timezone';

export default class InsightsJobSectionHeading extends Component {
  @tracked startDate = null;
  @tracked endDate = null;

  @action
  setDefaultDates() {
    this.startDate = moment().subtract(29, 'day');
    this.endDate = moment().subtract(1, 'day');
  }

  @action
  handleUpdateDates(startDate, endDate) {
    this.startDate = moment(startDate);
    this.endDate = moment(endDate);
  }
}
