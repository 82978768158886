import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Table::Container\n  @columns={{this.columns}}\n  @sortDirection={{this.sortDirection}}\n  @sortColumn={{@sortColumn}}\n  @isEmpty={{is-empty @recipients}}\n  @isLoading={{@isLoading}}\n  as |table|\n>\n  {{#each @recipients as |recipient|}}\n    <table.row\n      @route={{this.candidateRoute}}\n      @id={{recipient.candidate.id}}\n      @query={{hash tab=\"activity-feed\"}}\n    >\n      <NurtureCampaignRecipients::Row\n        @recipient={{recipient}}\n        @candidateRoute={{this.candidateRoute}}\n      />\n    </table.row>\n  {{/each}}\n  <table.loading @columns={{this.loadingColumns}} @repeat={{8}} />\n  <table.infinityLoader\n    @model={{@recipients}}\n    @repeat={{8}}\n    @columns={{this.loadingColumns}}\n  />\n</Table::Container>", {"contents":"<Table::Container\n  @columns={{this.columns}}\n  @sortDirection={{this.sortDirection}}\n  @sortColumn={{@sortColumn}}\n  @isEmpty={{is-empty @recipients}}\n  @isLoading={{@isLoading}}\n  as |table|\n>\n  {{#each @recipients as |recipient|}}\n    <table.row\n      @route={{this.candidateRoute}}\n      @id={{recipient.candidate.id}}\n      @query={{hash tab=\"activity-feed\"}}\n    >\n      <NurtureCampaignRecipients::Row\n        @recipient={{recipient}}\n        @candidateRoute={{this.candidateRoute}}\n      />\n    </table.row>\n  {{/each}}\n  <table.loading @columns={{this.loadingColumns}} @repeat={{8}} />\n  <table.infinityLoader\n    @model={{@recipients}}\n    @repeat={{8}}\n    @columns={{this.loadingColumns}}\n  />\n</Table::Container>","moduleName":"teamtailor/components/nurture-campaign-recipients/table.hbs","parseOptions":{"srcName":"teamtailor/components/nurture-campaign-recipients/table.hbs"}});
import Component from '@glimmer/component';
import {
  TABLE_COLUMNS,
  LOADING_COLUMNS,
} from 'teamtailor/constants/nurture-campaign-recipients';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class NurtureCampaignReccipientsTableComponent extends Component {
  @argDefault sortDirection = null;
  columns = TABLE_COLUMNS;
  loadingColumns = LOADING_COLUMNS;
  candidateRoute = 'nurture-campaigns.nurture-campaign.recipients.candidate';

  get oppositeSortDirection() {
    return this.sortDirection === 'desc' ? 'asc' : 'desc';
  }
}
