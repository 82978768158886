export default {
  "candidateInfo": "CandidateInfo__candidateInfo_l0pyfg",
  "application": "CandidateInfo__application_l0pyfg",
  "candidateAvatar": "CandidateInfo__candidateAvatar_l0pyfg",
  "stageName": "CandidateInfo__stageName_l0pyfg",
  "status": "CandidateInfo__status_l0pyfg",
  "dot": "CandidateInfo__dot_l0pyfg",
  "inbox": "CandidateInfo__inbox_l0pyfg",
  "inprocess": "CandidateInfo__inprocess_l0pyfg",
  "hired": "CandidateInfo__hired_l0pyfg",
  "rejected": "CandidateInfo__rejected_l0pyfg"
};
