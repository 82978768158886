import JobModel from 'teamtailor/models/job';

export const LOADING_COLUMNS = [
  {
    width: '2xl',
  },
  {
    width: 'xl',
    hasCircles: true,
    amountOfCircles: 5,
    circleClasses: 'mr-4 size-24',
  },
  {
    width: 's',
  },
  {
    width: 'm',
  },
  {
    width: 'm',
  },
];

type SearchParams = {
  [key: string]: unknown;
};

export type JobBulkArgs = {
  onClose: () => void;
  handleBulkChange: (data: unknown, action: string) => void;
  selectedJobs: JobModel[];
  selectedCount: number;
  searchParams: SearchParams;
  deselectedIds: string[];
  selectAll: boolean;
};
