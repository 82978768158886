import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import UserModel from './user';

export default class StoryModel extends Model {
  @attr('string') declare caption: string;
  @attr('date') declare createdAt: string;
  @attr('string') declare imageUrl: string;
  @attr('string') declare videoUrl: string;

  @belongsTo('user', { async: true })
  declare user: AsyncBelongsTo<UserModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    story: StoryModel;
  }
}
