import Model, { attr } from '@ember-data/model';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Server from 'teamtailor/services/server';
import Raw from 'teamtailor/transforms/raw';

export default class SegmentModel extends Model {
  @service declare server: Server;

  @attr('boolean', { defaultValue: false }) declare companyWide: boolean;
  @attr('string') declare emoji: string;
  @attr('raw') declare filters: Raw;
  @attr('string') declare name: string;

  @tracked count: unknown = null;

  async fetchCount() {
    const response = await this.server.memberAction(this, {
      action: 'count',
      method: 'GET',
    });
    this.count = response;
  }

  @action
  getSegmentCount() {
    this.fetchCount();
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    segment: SegmentModel;
  }
}
