import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import moment from 'moment-timezone';
import {
  NurtureCampaignModel,
  NurtureCampaignStepWaitModel,
} from 'teamtailor/models';
import NurtureCampaignStepModel from 'teamtailor/models/nurture-campaign-step';
import Current from 'teamtailor/services/current';
import TimeFormatService from 'teamtailor/services/time-format';
import { get } from 'teamtailor/utils/get';

export default class NurtureCampaignStepEmailModel extends NurtureCampaignStepModel {
  @service declare current: Current;
  @service declare timeFormat: TimeFormatService;

  @belongsTo('nurture-campaign', { async: false, inverse: 'emailSteps' })
  declare nurtureCampaign: NurtureCampaignModel;

  @belongsTo('nurture-campaign-step/wait')
  declare waitStep: AsyncBelongsTo<NurtureCampaignStepWaitModel>;

  @attr('string') declare sendAt: string;
  @attr('string') declare body: string;
  @attr('string') declare subject: string;
  @attr('string', { defaultValue: 'plain_text' }) declare layout: string;

  get icon() {
    return 'envelope';
  }

  get prettyName() {
    return isEmpty(this.subject) ? 'Email' : this.subject;
  }

  // NOTE: This thrrows a warning in the console
  get prettySendAt() {
    return moment(get(this, 'sendAt'))
      .tz(this.current.company.timeZoneIdentifier)
      .format(get(this.timeFormat, 'format'));
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'nurture-campaign-step/email': NurtureCampaignStepEmailModel;
  }
}
