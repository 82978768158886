import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { get, set, setProperties } from '@ember/object';

const typesWithRoutes = [
  'blog',
  'faq',
  'grid',
  'infographic',
  'lead',
  'list',
  'numbers',
  'perks',
  'picture',
  'social-links',
  'testimonial',
  'text',
  'timeline',
  'value',
  'video',
];

const careersiteBlocks = [
  'blog',
  'companies',
  'cover',
  'cta',
  'custom',
  'departments-list',
  'faq',
  'gallery',
  'grid',
  'group_jobs',
  'group',
  'index',
  'infographic',
  'jobs-list',
  'jobs',
  'layout',
  'lead',
  'list',
  'locations-list',
  'locations-map',
  'numbers',
  'people',
  'perks',
  'picture',
  'social-links',
  'social',
  'stories',
  'testimonial',
  'text',
  'timeline',
  'value',
  'video',
  'welcome',
  'workplace',
];

export default class IndexRoute extends Route {
  @service current;
  @service iframePreview;
  @service router;
  @service flipper;
  @service store;

  afterModel(model) {
    let modelName = get(model, 'name');
    if (get(model, 'name') === 'jobs' && get(model, 'page.isJobPage')) {
      modelName = 'jobs-list';
    }

    if (
      modelName === 'workplace' &&
      get(model, 'page.isTemplatePage') &&
      get(model, 'layout') !== 'template'
    ) {
      const images = [];
      get(model, 'pickedImages').forEach((pickedImage) => {
        images.push(
          this.store.createRecord('picked-image', {
            section_id: pickedImage.section_id,
            image: pickedImage.image,
            imageUrl: pickedImage.imageUrl,
            rowOrder: pickedImage.rowOrder,
            rowOrderPosition: pickedImage.rowOrderPosition,
          })
        );
        pickedImage.deleteRecord();
      });
      setProperties(model, {
        pickedImages: images,
        layout: 'template',
      });
    }

    let lookupName = `content.editor.section.${modelName}`;

    if (typesWithRoutes.indexOf(modelName) > -1) {
      lookupName = `content.editor.section.${modelName}.index`;
    }

    if (careersiteBlocks.indexOf(modelName) > -1) {
      setProperties(this, {
        controllerName: lookupName,
        templateName: lookupName,
      });
    }

    switch (get(model, 'name')) {
      case 'blog':
        return undefined;
      case 'departments':
        return get(this.current.company, 'departments');
      case 'locations':
        return get(this.current.company, 'locations');
      default:
        return undefined;
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      set(controller, 'showAdjustments', false);
    }
  }

  deactivate() {
    super.deactivate(...arguments);
    setProperties(this, { controllerName: null, templateName: null });
  }
}
