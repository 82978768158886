export default {
  "card": "NpsComments__card_yjfyji",
  "root": "NpsComments__root_yjfyji",
  "scoreIcon--promoter": "NpsComments__scoreIcon--promoter_yjfyji",
  "scoreIcon--passive": "NpsComments__scoreIcon--passive_yjfyji",
  "scoreIcon--detractor": "NpsComments__scoreIcon--detractor_yjfyji",
  "responseRow": "NpsComments__responseRow_yjfyji",
  "candidateAvatar": "NpsComments__candidateAvatar_yjfyji",
  "candidateName": "NpsComments__candidateName_yjfyji",
  "timestamp": "NpsComments__timestamp_yjfyji",
  "jobDetails": "NpsComments__jobDetails_yjfyji",
  "comment": "NpsComments__comment_yjfyji",
  "scoreRow": "NpsComments__scoreRow_yjfyji"
};
