import Route from '@ember/routing/route';
import { set } from '@ember/object';
import Purchase from 'teamtailor/lib/purchase';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class PickRoute extends Route {
  @service store;

  model({ channel_id }) {
    return this.store.findRecord('channel', channel_id);
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    const channelActivation = get(model, 'channelActivation');
    if (channelActivation?.isNew) {
      channelActivation.save();
    }

    const job = get(this.controllerFor('jobs.job'), 'model');

    const promotion = get(job, 'promotions').createRecord(
      Object.assign(get(model, 'defaultPromotionValues'), { channel: model })
    );

    set(promotion, 'invoiceInfo', this.store.createRecord('invoice-info'));
    set(controller, 'promotion', promotion);

    get(job, 'location').then((location) =>
      set(controller, 'location', location)
    );

    set(
      controller,
      'purchase',
      new Purchase({
        channel: model,
        promotion,
        currency: get(controller, 'currency'),
        selectedBudget: get(controller, 'selectedBudget'),
      })
    );
  }

  resetController(controller) {
    let promotion = get(controller, 'promotion');
    if (get(promotion, 'isNew')) {
      promotion.destroyRecord();
    }
  }
}
