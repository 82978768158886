import Model, { attr, belongsTo } from '@ember-data/model';
import { isPresent, isEmpty } from '@ember/utils';
import { set } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import OrganizableModel from 'teamtailor/models/organizable';
import DepartmentModel from 'teamtailor/models/department';
import RoleModel from 'teamtailor/models/role';
import RegionModel from 'teamtailor/models/region';
import LocationModel from 'teamtailor/models/location';

export default class AssetStructureModel extends Model {
  @belongsTo('organizable', { async: false, polymorphic: true })
  declare organizable: OrganizableModel;

  @attr('raw') options: any;

  matches(type: string, value: string | string[]) {
    let result = false;
    const valueAsArray = Array.isArray(value) ? value : [value];
    if (type === 'department') {
      result = this.matchesDepartment(valueAsArray);
    } else if (type === 'role') {
      result = this.matchesRole(valueAsArray);
    } else if (type === 'region') {
      result = this.matchesRegion(valueAsArray);
    } else if (type === 'location') {
      result = this.matchesLocation(valueAsArray);
    } else {
      result = false;
    }

    return result;
  }

  matchesDepartment(departmentIds: string[]) {
    const department = get(this.options, 'department');
    return (
      isEmpty(department) ||
      departmentIds.some((departmentId) => {
        return department.includes(departmentId);
      })
    );
  }

  matchesRole(roleIds: string[]) {
    const role = get(this.options, 'role');
    return (
      isEmpty(role) ||
      roleIds.some((roleId) => {
        return role.includes(roleId);
      })
    );
  }

  matchesLocation(locationIds: string[]) {
    const location = get(this.options, 'location');
    return (
      isEmpty(location) ||
      locationIds.some((locationId) => {
        return location.includes(locationId);
      })
    );
  }

  matchesRegion(regionIds: string[]) {
    const region = get(this.options, 'region');
    return (
      isEmpty(region) ||
      regionIds.some((regionId) => {
        return region.includes(regionId);
      })
    );
  }

  setDepartment(department: DepartmentModel | null) {
    this.setOption('department', department);
  }

  setRole(role: RoleModel | null) {
    this.setOption('role', role);
  }

  setRegion(region: RegionModel | null) {
    this.setOption('region', region);
  }

  setLocation(location: LocationModel | null) {
    this.setOption('location', location);
  }

  get departmentId() {
    const department = get(this.options, 'department');
    return Array.isArray(department) && department.length > 0
      ? department[0]
      : department;
  }

  get regionId() {
    const region = get(this.options, 'region');
    return Array.isArray(region) && region.length > 0 ? region[0] : region;
  }

  get locationId() {
    const location = get(this.options, 'location');
    return Array.isArray(location) && location.length > 0
      ? location[0]
      : location;
  }

  get roleId() {
    const role = get(this.options, 'role');
    return Array.isArray(role) && role.length > 0 ? role[0] : role;
  }

  setOption(key: string, value: any) {
    const optionValue = isPresent(value) ? [value.id] : [];
    set(this.options, key, optionValue);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'asset-structure': AssetStructureModel;
  }
}
