import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"c-online-beacon__icon\">\n  {{#if this.showText}}\n    <EmberTooltip @text=\"Online\" />\n  {{/if}}\n</div>", {"contents":"<div class=\"c-online-beacon__icon\">\n  {{#if this.showText}}\n    <EmberTooltip @text=\"Online\" />\n  {{/if}}\n</div>","moduleName":"teamtailor/components/candidate-online-beacon.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-online-beacon.hbs"}});
import Component from '@ember/component';
import { equal } from '@ember/object/computed';

export default Component.extend({
  classNameBindings: [
    ':c-online-beacon',
    'visible:c-online-beacon--visible',
    'isSmall:c-online-beacon--small',
  ],

  isSmall: equal('beaconSize', 'smaller-medium'),
});
