import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import { AuditLogModel } from 'teamtailor/models';

export default class AuditEventModel extends Model {
  @belongsTo('audit-log') declare auditLog: AsyncBelongsTo<AuditLogModel>;

  @attr('date') declare timestamp: Date;
  @attr('number') declare actorId: number;
  @attr('number') declare sourceId: number;
  @attr('string') declare action: string;
  @attr('string') declare actorLabel: string;
  @attr('string') declare description: string;
  @attr('string') declare ipAddress: string;
  @attr('string') declare sourceLabel: string;
  @attr('string') declare sourceType: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'audit-event': AuditEventModel;
  }
}
