import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<DirectFileUpload\n  @onFileUploaded={{this.handleUpload}}\n  @label={{if this.uploadFilename this.uploadFilename @label}}\n  @changeLabelToLoading={{true}}\n  @rootPath=\"partner-uploads\"\n  @class=\"text-left\"\n  @accept={{@accept}}\n  class=\"no-global-styles\"\n/>\n{{#if this.uploadFilename}}\n  {{t \"components.partner.config_field.file\"}}\n  {{this.uploadFilename}}\n{{/if}}", {"contents":"<DirectFileUpload\n  @onFileUploaded={{this.handleUpload}}\n  @label={{if this.uploadFilename this.uploadFilename @label}}\n  @changeLabelToLoading={{true}}\n  @rootPath=\"partner-uploads\"\n  @class=\"text-left\"\n  @accept={{@accept}}\n  class=\"no-global-styles\"\n/>\n{{#if this.uploadFilename}}\n  {{t \"components.partner.config_field.file\"}}\n  {{this.uploadFilename}}\n{{/if}}","moduleName":"teamtailor/components/partner/config-fields/file.hbs","parseOptions":{"srcName":"teamtailor/components/partner/config-fields/file.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @tracked uploadFilename;

  @action
  handleUpload(url, fileName) {
    this.uploadFilename = fileName;
    this.args.onChange(url);
  }
}
