import MeetingNewRoute from 'teamtailor/routes/meetings/new';

export default class CandidatesSegmentMeetingNewRoute extends MeetingNewRoute {
  controllerName = 'meetings.new';
  templateName = 'meetings.new';

  meetingRouteRouteName = 'candidates.segment.meetings';
  candidateRouteRouteName = '';
  jobRouteRouteName = '';
}
