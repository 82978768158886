import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import CurrentService from 'teamtailor/services/current';
import IntlService from 'ember-intl/services/intl';

export default class UnicornController extends Controller {
  @service declare current: CurrentService;
  @service declare intl: IntlService;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    unicorn: UnicornController;
  }
}
