import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class CookiePolicyRoute extends ScrollToTopRoute {
  @service current;
  @service store;

  async model() {
    let cookieSettings = await get(this.current.company, 'cookieSetting');
    if (!cookieSettings) {
      cookieSettings = this.store.createRecord('cookie-setting');
    }

    return cookieSettings;
  }
}
