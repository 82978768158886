import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class ItemController extends Controller {
  @service current;

  get item() {
    if (get(this.model, 'itemsArray.length')) {
      return get(this.model, 'itemsArray').findBy('id', get(this, 'itemId'));
    }

    return undefined;
  }
}
