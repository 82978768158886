import Route from '@ember/routing/route';
import { set, setProperties } from '@ember/object';

export default class ItemRoute extends Route {
  model() {
    return this.modelFor('content.editor.section.lead');
  }

  afterModel() {
    setProperties(this, {
      controllerName: 'content.editor.section.lead.item',
      templateName: 'content.editor.section.lead.item',
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    set(controller, 'itemId', this.paramsFor(this.routeName).item_id);
  }
}
