import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { isPresent } from '@ember/utils';

export default class ApiKey extends Model {
  @service intl;

  @attr('date') createdAt;
  @attr('boolean') groupKey;
  @attr('string') key;
  @attr('string') name;
  @attr('boolean', { defaultValue: false }) read;
  @attr('string') scope;
  @attr('boolean', { defaultValue: false }) write;

  get humanScope() {
    switch (this.scope) {
      case 'open':
        return this.intl.t('common.public');
      default:
        return capitalize(this.scope);
    }
  }

  get parameterName() {
    const scope = this.scope === 'open' ? 'public' : this.scope;
    const read = this.read ? 'read' : null;
    const write = this.write ? 'write' : null;

    return [scope, read, write].filter(isPresent).join('_');
  }

  get readWrite() {
    return [
      this.read ? this.intl.t('models.api_key.read') : null,
      this.write ? this.intl.t('models.api_key.write') : null,
    ]
      .filter(isPresent)
      .join('/');
  }
}
