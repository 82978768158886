import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class DesktopNotificationsService extends Service {
  @tracked notificationPermission?: 'default' | 'granted' | 'denied';

  constructor(owner?: object) {
    super(owner);

    try {
      this.notificationPermission = Notification.permission;
    } catch (_err) {
      // Do nothing
    }
  }

  showNotification(silent: boolean, body: string, link: string) {
    if (silent) {
      return;
    }

    const notification = new Notification('Teamtailor', {
      icon: '/lettermark/512x512.png',
      body,
    });

    notification.onclick = () => {
      window.focus();
      window.location.href = link;
    };
  }

  async enableDesktopNotifications() {
    this.notificationPermission = await Notification.requestPermission();
  }

  get isNotificationsGranted() {
    return this.notificationPermission === 'granted';
  }
}

declare module '@ember/service' {
  interface Registry {
    'desktop-notifications': DesktopNotificationsService;
  }
}
