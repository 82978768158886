import Transform from '@ember-data/serializer/transform';

// Taken from https://github.com/lodr/ember-cli-form-data

export default class FileTransform extends Transform {
  deserialize(serialized: unknown) {
    return serialized;
  }

  serialize(deserialized: unknown) {
    return deserialized;
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    file: FileTransform;
  }
}
