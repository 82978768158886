import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { get } from 'teamtailor/utils/get';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { createCache, getValue } from '@glimmer/tracking/primitives/cache';

export default class ConversationModel extends Model {
  @service anonymals;
  @service server;

  @belongsTo candidate;
  @belongsTo jobApplication;
  @belongsTo message;
  @belongsTo('user') assignedUser;
  @hasMany('user', { async: true }) users;

  @attr('string') bodyTruncated;
  @attr('string') color;
  @attr('string') initials;
  @attr('string') firstName;
  @attr('string') lastName;
  @attr('string') email;
  @attr('string') picture;
  @attr('string') socialImageUrl;
  @attr('date') messageSentAt;
  @attr('number') jobApplicationId;
  @attr('number') candidateId;
  @attr('boolean') isAnonymous;
  @attr('string') status;

  get isUnread() {
    return this.status === 'unread';
  }

  get isRead() {
    return !this.unread;
  }

  get isArchived() {
    return this.status === 'archived';
  }

  get nameOrEmail() {
    return this.name || this.email;
  }

  get name() {
    if (this.isAnonymous) {
      return get(this.candidate, 'anonymousName');
    } else if (isEmpty(this.firstName) && isEmpty(this.lastName)) {
      return null;
    }

    const name = `${this.firstName} ${this.lastName}`.trim();
    return isEmpty(name) ? null : name;
  }

  #anonymalCache = createCache(() => {
    return this.anonymals.fromSeed(this.candidateId);
  });

  get anonymal() {
    return getValue(this.#anonymalCache);
  }

  async archive() {
    const response = await this.server.memberAction(this, {
      action: 'archive',
    });
    this.store.pushPayload(response);
  }

  async unarchive() {
    const response = await this.server.memberAction(this, {
      action: 'unarchive',
    });
    this.store.pushPayload(response);
  }

  async markAsUnread() {
    const response = await this.server.memberAction(this, {
      action: 'mark_as_unread',
    });
    this.store.pushPayload(response);
  }

  async leaveConversation() {
    const response = await this.server.memberAction(this, {
      action: 'opt_out',
    });
    this.store.pushPayload(response);
  }

  async addUsers(users) {
    const data = { user_ids: users.mapBy('id') };
    return this.server.memberAction(this, {
      action: 'users/add',
      method: 'POST',
      options: { data },
    });
  }
}
