import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    meetingEvents: { key: 'meeting_events_attributes', serialize: 'records' },
    preparedMessage: {
      key: 'prepared_message_attributes',
      serialize: 'records',
    },
  },
});
