import Service, { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';
import Server from 'teamtailor/services/server';
import { get } from 'teamtailor/utils/get';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { later } from '@ember/runloop';

export type SkillTraitToolCall = {
  name: string;
  arguments: {
    id?: number;
    name?: string;
    weight: string;
  };
};

export type InterviewKitQuestionToolCall = {
  name: string;
  arguments: {
    title?: string;
    question_id?: number;
    type?:
      | 'text'
      | 'multiple_choice'
      | 'single_choice'
      | 'yes_or_no'
      | 'date'
      | 'number'
      | 'range';
    alternatives?: string[];
    range?: {
      start_with: number;
      end_with: number;
      unit?: string;
    };
  };
};

type ChatCompletionEvent = {
  content: string;
  timestamp: number; // given in seconds
  stop: boolean;
  error?: string;
};

const SKILLS_TRAIT_EVENT = 'copilot-skills-traits';
const INTERVIEW_KIT_QUESTIONS_EVENT = 'copilot-interview-kit-questions';
const JOB_APPLICATION_QUESTIONS_EVENT = 'copilot-job-application-questions';
const DRAFT_JOB_DESCRIPTION_EVENT = 'draft_job_description_new_message';
const DRAFT_REJECTION_EMAIL_EVENT = 'rejection_email_draft_new_message';
const TRANSLATE_TEXT_EVENT = 'translate_text';

export default class CopilotService extends Service {
  @service declare current: Current;
  @service declare server: Server;
  @service declare pusher: PusherService;
  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  @tracked isThinking = false;
  thinkingText: string | null = null;
  @tracked copilotThinkingStateIndex = 0;

  channelEvents: string[] = [];
  dots = ['', '.', '..', '...'];

  get channel(): Promise<PusherChannel> {
    return this.pusher.channel(this.current.user.notificationsChannel);
  }

  get thinkingDots() {
    return `${
      this.thinkingText || this.intl.t('recruiter_copilot.is_thinking')
    } ${this.dots[this.copilotThinkingStateIndex % 4]}`;
  }

  @action
  updateThinkingDots() {
    if (!this.isThinking) return;

    later(() => {
      this.copilotThinkingStateIndex = (this.copilotThinkingStateIndex + 1) % 4;
      this.updateThinkingDots();
    }, 400);
  }

  generateCandidateResumeSummary(candidateId: string) {
    this.copilotRequest('candidate_resume_summary', {
      candidate_id: candidateId,
    });
  }

  async generateSkillsAndTraits(
    jobId: string,
    languageCode: string | null,
    added_skills_traits?: string[]
  ): Promise<SkillTraitToolCall[]> {
    const channel = await this.channel;

    return new Promise((resolve, reject) => {
      channel.bind(SKILLS_TRAIT_EVENT, ({ tool_calls, error }) => {
        channel.unbind(SKILLS_TRAIT_EVENT);
        if (tool_calls) {
          resolve(tool_calls);
        } else {
          this.flashMessages.error(error);
          reject(error);
        }
      });

      this.copilotRequest('skills_traits', {
        job_id: jobId,
        language_code: languageCode,
        added_skills_traits,
      });
    });
  }

  async saveScorecardCriteria() {
    await Promise.all(
      this.store
        .peekAll('scorecard-criterium')
        .filterBy('isNew')
        .map((criterium) => criterium.save())
    );
  }

  async generateInterviewKitQuestions(
    jobId?: string,
    scorecardCriteriumId?: string,
    skill?: string,
    trait?: string,
    addedQuestionTitles?: string[]
  ): Promise<{
    toolCalls: InterviewKitQuestionToolCall[];
    languageCode: string;
  }> {
    const channel = await this.channel;

    return new Promise((resolve, reject) => {
      channel.bind(INTERVIEW_KIT_QUESTIONS_EVENT, (response) => {
        channel.unbind(INTERVIEW_KIT_QUESTIONS_EVENT);
        if (response.error) {
          this.flashMessages.error(response.error);
          reject(response.error);
        } else {
          resolve({
            toolCalls: response.tool_calls,
            languageCode: response.language_code,
          });
        }
      });

      this.copilotRequest('interview_kit_questions', {
        job_id: jobId,
        scorecard_criterium_id: scorecardCriteriumId,
        skill,
        trait,
        added_question_titles: addedQuestionTitles,
      });
    });
  }

  async saveQuestions() {
    await Promise.all(
      this.store
        .peekAll('question')
        .filterBy('isNew')
        .filter((question) => !!question.title)
        .map((question) => question.save())
    );
  }

  destroyInterviewKitQuestions(scorecardCriteriumNameToDestroy = 'all') {
    this.store
      .peekAll('question')
      .filterBy('isNew')
      .filter((question) => {
        if (scorecardCriteriumNameToDestroy === 'all') return true;

        const scorecardCriterium = get(question, 'scorecardCriterium');
        const scorecardCriteriumName = get(scorecardCriterium, 'name');
        return scorecardCriteriumName === scorecardCriteriumNameToDestroy;
      })
      .map((question) => question.deleteRecord());
  }

  async generateJobApplicationQuestions(
    jobTitle?: string,
    jobBody?: string,
    languageCode?: string,
    addedQuestionTitles?: string[]
  ) {
    const channel = await this.channel;

    return new Promise((resolve, reject) => {
      channel.bind(JOB_APPLICATION_QUESTIONS_EVENT, (response) => {
        channel.unbind(JOB_APPLICATION_QUESTIONS_EVENT);
        if (response.error) {
          this.flashMessages.error(response.error);
          reject(response.error);
        } else {
          resolve({
            toolCalls: response.tool_calls,
            languageCode: response.language_code,
          });
        }
      });

      this.copilotRequest('job_application_questions', {
        job_title: jobTitle,
        job_body: jobBody,
        language_code: languageCode,
        added_question_titles: addedQuestionTitles,
      });
    });
  }

  async translateText(text: any, languageCode: string): Promise<{ text: any }> {
    const channel = await this.channel;
    this.thinkingText = this.intl.t('recruiter_copilot.is_translating');
    this.startThinkingDots();

    return new Promise((resolve, reject) => {
      channel.bind(TRANSLATE_TEXT_EVENT, (response) => {
        this.stopThinkingDots();
        this.thinkingText = null;
        channel.unbind(TRANSLATE_TEXT_EVENT);
        if (response.error) {
          this.flashMessages.error(response.error);
          reject(response.error);
        } else {
          resolve({
            text: JSON.parse(response.text),
          });
        }
      });

      this.copilotRequest('translate_text', {
        text,
        language_code: languageCode,
      });
    });
  }

  startThinkingDots() {
    this.isThinking = true;
    this.updateThinkingDots();
  }

  stopThinkingDots() {
    this.isThinking = false;
  }

  async draftJobDescription(
    onChangeCallback: (value: string | null) => void,
    jobTitle: string,
    skills: string[],
    traits: string[],
    language_code: string
  ) {
    this.startThinkingDots();
    onChangeCallback(null);
    let draftedJobDescription = '';

    return new Promise((resolve, reject) => {
      const eventId = this.bindEvent(
        DRAFT_JOB_DESCRIPTION_EVENT,
        (cce: ChatCompletionEvent) => {
          if (cce.error) {
            this.flashMessages.error(cce.error);
            return reject(cce.error);
          }

          draftedJobDescription += cce.content;
          onChangeCallback(draftedJobDescription);

          // Reached end of drafted message
          if (cce.stop) {
            this.stopThinkingDots();
            this.unbindEvent(DRAFT_JOB_DESCRIPTION_EVENT, eventId);
            resolve(draftedJobDescription);
          }
        }
      );

      this.copilotRequest('draft_job_description', {
        job_title: jobTitle,
        skills,
        traits,
        language_code,
        event_id: eventId,
      });
    });
  }

  async draftRejectEmail(
    onChangeCustomSubject: (subject: string | null) => void,
    onChangeCustomBody: (body: string | null) => void,
    data: {
      job_application_id: number;
      reject_reason?: string;
      rejected_by_company?: boolean;
    }
  ): Promise<string> {
    this.startThinkingDots();
    onChangeCustomSubject(null);
    onChangeCustomBody('');
    let draftedRejectionMessage = '';

    return new Promise((resolve, reject) => {
      const eventId = this.bindEvent(
        DRAFT_REJECTION_EMAIL_EVENT,
        (cce: ChatCompletionEvent) => {
          if (cce.error) {
            this.flashMessages.error(cce.error);
            return reject(cce.error);
          }

          draftedRejectionMessage += cce.content;
          draftedRejectionMessage = this.fillRejectionEmail(
            draftedRejectionMessage,
            onChangeCustomSubject,
            onChangeCustomBody
          );

          // Reached end of drafted message
          if (cce.stop) {
            this.stopThinkingDots();
            this.unbindEvent(DRAFT_REJECTION_EMAIL_EVENT, eventId);
            resolve(draftedRejectionMessage);
          }
        }
      );

      this.copilotRequest('rejection_email_draft', {
        ...data,
        event_id: eventId,
      });
    });
  }

  fillRejectionEmail(
    draftedRejectionMessage: string,
    onChangeCustomSubject: (subject: string | null) => void,
    onChangeCustomBody: (body: string | null) => void
  ) {
    // Get the subject from the message, could be "Subject: The subject" or "The subject"
    const subject = draftedRejectionMessage.match(/^[\w-]+:\s(.+)/)?.[1];

    if (subject) {
      onChangeCustomSubject(subject);
      if (draftedRejectionMessage.includes('\n')) {
        // Remove the first line of the message, since it's the subject
        draftedRejectionMessage = draftedRejectionMessage
          .split('\n')
          .slice(1)
          .join('\n');
      }
    } else {
      onChangeCustomBody(draftedRejectionMessage);
    }

    return draftedRejectionMessage;
  }

  // Unbind all events that we saved in channelEvents and clear it
  stop() {
    this.stopThinkingDots();
    this.channelEvents.forEach((event) => this.unbindEvent(event));
  }

  copilotRequest(method: string, data: object) {
    return this.server.request(
      `/app/companies/${this.current.company.uuid}/api/copilot/${method}`,
      'POST',
      {
        data,
      }
    ) as Promise<void>;
  }

  eventName(name: string, id?: string) {
    return id ? `${name}:${id}` : name;
  }

  bindEvent(name: string, callback: (cce: ChatCompletionEvent) => void) {
    // To be able to unbind this specific event later we need to keep track of it with an id. E.g., with the stop button.
    const id = this.generateId();
    this.channel.then((channel) =>
      channel.bind(this.eventName(name, id), callback)
    );
    this.channelEvents.push(this.eventName(name, id));
    return id;
  }

  // Unbinds event from channel and removes it from channelEvents
  unbindEvent(name: string, id?: string) {
    const eventName = this.eventName(name, id);
    this.channel.then((channel) => channel.unbind(eventName));
    this.channelEvents = this.channelEvents.filter(
      (event) => event !== eventName
    );
  }

  generateId() {
    // The fallback is when crypto.randomUUID() isn't available, e.g., in the review apps as it's not on https...
    if (typeof crypto.randomUUID === 'function') {
      return crypto.randomUUID();
    } else {
      return Math.random()
        .toString(36)
        .substring(2, 6 + 2);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    copilot: CopilotService;
  }
}
