import Service, { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import moment from 'moment-timezone';
import MEETING_EVENTS_DATES_QUERY from 'teamtailor/gql/meetings/meeting-events-dates.graphql';

export default class MeetingEventQueryService extends Service {
  @service store;
  @service user;
  @service apollo;

  eventDatesCallback = () => {};
  perPage = 25;
  scrollContainerElement = null;

  @tracked meetingEvents = [];
  @tracked allMeetingEventDates = [];
  @tracked page = 1;
  @tracked totalPages = 1;
  @tracked selectedDate = null;
  @tracked monthToShow = null;
  @tracked filterOnFeedback = null;

  fetchMeetingEventsTask = dropTask(async () => {
    if (this.page !== 1 && this.page > this.totalPages) {
      return;
    }

    if (this.page === 1) {
      this.meetingEvents.clear();
    }

    const filter = {
      user_ids: get(this.user, 'id'),
      ...(this.monthToShow
        ? {
            from_date: moment(this.monthToShow)
              .startOf('month')
              .format('YYYY-MM-DD'),

            to_date: moment(this.monthToShow)
              .endOf('month')
              .format('YYYY-MM-DD'),
          }
        : { upcoming_and_ongoing: true }),

      ...(this.selectedDate
        ? {
            from_date: moment(this.selectedDate).format('YYYY-MM-DD'),
            to_date: moment(this.selectedDate)
              .add(1, 'day')
              .format('YYYY-MM-DD'),
          }
        : {}),

      ...(this.filterOnFeedback
        ? {
            feedback_user_id: get(this.user, 'id'),
          }
        : {}),
    };

    const params = {
      page: this.page,
      per_page: this.perPage,
      with_order: 'starts_at',
      sort_direction: 'asc',
      filter,
    };

    const placeholders = new Array(5).fill().map(() => {
      return { id: 'placeholder' };
    });
    this.meetingEvents.pushObjects(placeholders);

    const meetingEvents = await this.store.query('meeting-event', params);

    this.page = this.page + 1;
    this.totalPages = meetingEvents.meta.total_pages;

    await this.meetingEvents.removeObjects(placeholders);
    await this.meetingEvents.pushObjects(meetingEvents.toArray());
  });

  async fetchMeetingEventsDates() {
    const { meetingEventsFull } = await this.apollo.query({
      query: MEETING_EVENTS_DATES_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          userIds: get(this.user, 'id'),
          upcomingAndOngoing: true,
        },
      },
    });

    this.allMeetingEventDates = meetingEventsFull.map((me) =>
      moment(me.bookedSlot.startsAt).format('YYYY-MM-DD')
    );
  }

  refetchTask = dropTask(async () => {
    if (this.scrollContainerElement) {
      this.scrollContainerElement.scrollTop = 0;
    }

    this.page = 1;
    this.fetchMeetingEventsDates().then(this.eventDatesCallback);
    await this.fetchMeetingEventsTask.perform();
  });
}
