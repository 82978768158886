import IntlService from 'ember-intl/services/intl';
import QuestionModel from 'teamtailor/models/question';
import PermissionsService from 'teamtailor/services/permissions';
import { get } from 'teamtailor/utils/get';

export function editQuestionProperties(
  question: QuestionModel,
  intl: IntlService,
  permissions: PermissionsService
) {
  if (get(question, 'createdByCopilot') && get(question, 'isNew')) {
    return {
      disabled: false,
      description: null,
    };
  } else if (permissions.has('settings/update_recruitment')) {
    return {
      disabled: true,
      description: intl.t('jobs.edit.question.edit_question_admin'),
    };
  } else if (get(question, 'createdByCopilot')) {
    return {
      disabled: true,
      description: intl.t(
        'jobs.edit.question.edit_copilot_created_question_no_access'
      ),
    };
  } else {
    return {
      disabled: true,
      description: intl.t('jobs.edit.question.edit_question_no_access'),
    };
  }
}
