import { capitalize, underscore } from '@ember/string';
import { QueryType } from '../base-query';
import AggregatedProperty, {
  AggregatedPropertyOptions,
} from './aggregated-property';

export interface DistinctCountPropertyOptions
  extends AggregatedPropertyOptions {
  customDistinctKey?: string;
}

export default class DistinctCountProperty extends AggregatedProperty {
  customDistinctKey?: string;

  constructor(options: DistinctCountPropertyOptions) {
    super({
      ...options,
      type: 'distinct-count',
      columnType: 'number',
      translationKey: 'insights.reports.builder.aggregated_properties.count',
    });

    this.customDistinctKey = options.customDistinctKey;
  }

  getField(queryType: QueryType) {
    let key = this.parentProperty?.getKey(queryType);
    if (this.customDistinctKey) {
      key = underscore(this.customDistinctKey).toUpperCase();
    }

    return `distinctCount(field: ${key}${
      this.filters ? `, filters: ${this.filters}` : ''
    })`;
  }

  get columnName() {
    const field = `${this.parentProperty?.columnName}`;

    return `${field}${capitalize(super.columnName)}DistinctCount`;
  }
}
