import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
import moment from 'moment-timezone';
import { action } from '@ember/object';
import jsonToCsvFile from 'teamtailor/utils/json-to-csv-file';
import {
  formatTooltip,
  HIRE_QUALITY_STATUS,
} from 'teamtailor/utils/hires-report';
import { tokenColors } from '@teamtailor/design-tokens';

export default class TimeToHireController extends Controller {
  @service reports;
  @service analytics;
  @service user;
  @service intl;
  @service apollo;
  @service server;
  @service store;

  @tracked sortDirection = 'desc';
  @tracked sortProperty = 'timestamp';

  get data() {
    return this.model.timeToHire.value;
  }

  get timeToHires() {
    return this.data?.rows;
  }

  get totalHires() {
    return this.timeToHires.length;
  }

  get chartOptions() {
    const { intl, showHireQualityInfo } = this;

    return {
      legend: {
        enabled: false,
      },

      xAxis: {
        type: 'datetime',
        min: moment(this.analytics.startDate).valueOf(),
        max: moment(this.analytics.endDate).endOf('day').valueOf(),
      },

      plotOptions: {
        line: {
          enableMouseTracking: false,
        },
      },

      tooltip: {
        useHTML: true,
        padding: 12,
        backgroundColor: tokenColors.zinc['800'],
        formatter() {
          return formatTooltip(this, intl, showHireQualityInfo);
        },
      },

      yAxis: {
        min: 0,
        title: {
          text: this.intl.t('reports.days'),
        },
      },
    };
  }

  get chartData() {
    const data = this.timeToHires.map((row) => {
      const rowData = {
        x: moment(row.timestamp).valueOf(),
        y: row.timeToHireInDays,
        candidate: row.candidate,
      };

      if (this.showHireQualityInfo) {
        Object.assign(rowData, {
          color: this.hireQualityColor(row.hireQuality),
          hireQualityResponseCounts: row.hireQualityResponseCounts,
        });
      }

      return rowData;
    });

    return [
      {
        type: 'scatter',
        name: this.intl.t('reports.hired_candidate'),
        data,
        marker: {
          radius: 4,
        },

        regression: data.length > 1,
        regressionSettings: {
          type: 'linear',
          color: '#000',
        },
      },
    ];
  }

  get sortedRows() {
    const { sortDirection, sortProperty, timeToHires } = this;
    const sortedSources = timeToHires.sortBy(sortProperty);
    return sortDirection === 'desc' ? sortedSources.reverse() : sortedSources;
  }

  hireQualityColor(hireQuality) {
    switch (hireQuality) {
      case HIRE_QUALITY_STATUS.good:
        return tokenColors.green['500'];
      case HIRE_QUALITY_STATUS.poor:
        return tokenColors.red['500'];
      case HIRE_QUALITY_STATUS.pending:
        return tokenColors.amber['500'];
      case HIRE_QUALITY_STATUS.not_sent:
        return tokenColors.zinc['500'];
      case HIRE_QUALITY_STATUS.uncertain:
        return {
          linearGradient: { x1: 0, x2: 1, y1: 1, y2: 0 },
          stops: [
            [0, '#EF4444'],
            [0.4999, '#EF4444'],
            [0.5, '#26C12B'],
            [1, '#26C12B'],
          ],
        };
    }
  }

  get showHireQualityInfo() {
    return this.data?.showHireQualityInfo;
  }

  get hireQualityPercentage() {
    const includedStatuses = [
      HIRE_QUALITY_STATUS.good,
      HIRE_QUALITY_STATUS.uncertain,
      HIRE_QUALITY_STATUS.poor,
    ];

    const { goodCount, totalCount } = this.timeToHires.reduce(
      (counts, hire) => {
        if (hire.hireQuality === HIRE_QUALITY_STATUS.good) {
          counts.goodCount += 1;
        }

        if (includedStatuses.includes(hire.hireQuality)) {
          counts.totalCount += 1;
        }

        return counts;
      },
      { goodCount: 0, totalCount: 0 }
    );

    return totalCount > 0 ? (goodCount / totalCount) * 100 : 0;
  }

  hireQualityText(row) {
    const texts = [];
    const { goodCount, poorCount, pendingCount } =
      row.hireQualityResponseCounts;

    if (goodCount) {
      texts.push(`${this.intl.t('reports.hire_quality.good')}: ${goodCount}`);
    }

    if (poorCount) {
      texts.push(`${this.intl.t('reports.hire_quality.poor')}: ${poorCount}`);
    }

    if (pendingCount) {
      texts.push(
        `${this.intl.t('reports.hire_quality.pending')}: ${pendingCount}`
      );
    }

    return texts.join(', ');
  }

  @action
  async handleExport() {
    jsonToCsvFile(
      'hires',
      this.analytics.startDate,
      this.analytics.endDate,
      this.data?.rows ?? [],
      (row) => {
        const rowData = {
          deleted: row.deleted,
          candidate_id: row.candidateId,
          candidate_name:
            row.candidate?.nameOrEmail ||
            this.intl.t('insights.common.deleted_candidate'),

          job: row.job?.title || this.intl.t('insights.common.deleted_job'),
          timestamp: row.timestamp,
          'time to hire (days)': row.timeToHireInDays,
          'referring site': row.source,
        };

        if (this.showHireQualityInfo) {
          Object.assign(rowData, {
            'hire quality': this.hireQualityText(row),
          });
        }

        return rowData;
      }
    );
  }

  @action
  handleSort(sortProperty) {
    if (this.sortProperty === sortProperty) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortDirection = 'desc';
      this.sortProperty = sortProperty;
    }
  }
}
