import { tracked } from '@glimmer/tracking';
import {
  IMeetingRoom,
  IAttendeeMeetingRoom,
} from 'teamtailor/components/meeting/types';
import { AttendeeClass, IAttendeeRecord } from './attendee';
import { AttendeeType } from './attendee-list';

export interface IAttendeeMeetingRoomRecord extends IAttendeeRecord {
  meetingRoomId?: string;
}

export class MeetingRoomAttendeeClass extends AttendeeClass {
  @tracked declare meetingRoom?: IMeetingRoom;

  constructor(attende: IAttendeeMeetingRoom) {
    super(attende);
    this.meetingRoom = attende.meetingRoom;
  }

  static from(meetingRoom: IMeetingRoom): MeetingRoomAttendeeClass {
    const attende = { meetingRoom } as IAttendeeMeetingRoom;
    return new MeetingRoomAttendeeClass(attende);
  }

  get attendeeType(): string {
    return 'meetingRoom';
  }

  isAttendee(attende: AttendeeType): boolean {
    const meetingRoomAttendee = attende as MeetingRoomAttendeeClass | undefined;
    return meetingRoomAttendee?.meetingRoom?.id === this.meetingRoom?.id;
  }

  toAttributes(): IAttendeeMeetingRoomRecord {
    return {
      meetingRoomId: this.meetingRoom?.id,
      id: this.id,
    };
  }
}
