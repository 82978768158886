import { action } from '@ember/object';
import EditorBlockLibraryController from 'teamtailor/controllers/content/editor/block-library';

export default class EditorBlockLibraryBlockLayoutController extends EditorBlockLibraryController {
  queryParams = ['position'];

  get blockLayout() {
    return this.model?.blockLayout;
  }

  get mockSection() {
    return this.model?.mockSection;
  }

  get careerSite() {
    return this.model?.careerSite;
  }

  @action
  routeBack() {
    this.router.transitionTo('content.editor');
  }
}
