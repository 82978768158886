import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { set, action } from '@ember/object';
import { get } from 'teamtailor/utils/get';

export default class RenewCreditsController extends Controller {
  @service intl;
  @service current;
  @service router;
  @service flashMessages;

  get smsSetting() {
    return this.model;
  }

  get paymentProcessorSetting() {
    return get(this.current.company, 'paymentProcessorSetting');
  }

  get vatPercentage() {
    return get(this.paymentProcessorSetting, 'vatPercentage');
  }

  @action
  handleTakeoverCloseAction() {
    this.router.transitionTo('settings.sms');
  }

  @action
  selectPackage(selectedPackage) {
    set(this, 'currentPackage', selectedPackage);
    set(this, 'smsSetting.creditsToBuy', selectedPackage.credits);
  }

  @action
  setCreditsRenewalThreshold(value) {
    set(this, 'smsSetting.creditsRenewalThreshold', value);
  }

  @action
  save() {
    set(this, 'smsSetting.hasAutoRenewal', true);
    return get(this, 'smsSetting')
      .save()
      .then(() => {
        get(this, 'flashMessages').success(
          this.intl.t('settings.sms.renew_credits.auto_renewal_set')
        );
        this.router.transitionTo('settings.sms', this.current.company.uuid);
      })
      .catch((errorResponse) => {
        return errorResponse;
      });
  }
}
