import NewJobOfferRoute from 'teamtailor/routes/jobs/job/stages/index/candidate/job-offer/new';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { JobApplicationModel, JobOfferModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';

export default class CandidatesSegmentCandidateJobOfferNewRoute extends NewJobOfferRoute {
  @service declare store: Store;

  modelFromParent = 'candidates.segment.candidate';
  controllerName = 'jobs.job.stages.index.candidate.job-offer.new';
  templateName = 'jobs.job.stages.index.candidate.job-offer.new';

  model() {
    const { user } = this.current;

    const candidate = this.modelFor(this.modelFromParent);

    const { jobApplicationId } = this.paramsFor(this.modelFromParent) as {
      jobApplicationId: string;
    };

    let jobApplication: JobOfferModel | null = null;

    if (jobApplicationId) {
      jobApplication = get(candidate, 'jobApplications').find(
        (jobApplication: JobApplicationModel) =>
          jobApplication.id === jobApplicationId
      );
    }

    return this.store.createRecord('job-offer', {
      jobApplication,
      jobOfferForm: this.store.createRecord('job-offer-form', { uploads: [] }),
      users: [user],
      candidate,
      user,
    });
  }
}
