import ApplicationSerializer from './application';

export default class ContentBlockInfographic extends ApplicationSerializer {
  payloadKeyFromModelName() {
    return 'content_block_infographic';
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'content-block-infographic': ContentBlockInfographic;
  }
}
