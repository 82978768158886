import { inject as service } from '@ember/service';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { set } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import Changeset from 'ember-changeset';
import UserValidations from 'teamtailor/validations/user';
import lookupValidator from 'ember-changeset-validations';

export default class EditRoute extends ScrollToTopRoute {
  @service ttAlert;
  @service intl;
  @service current;
  @service user;
  @service store;
  @service accessLevels;
  @service router;

  afterModel(model, transition) {
    if (!get(this, 'user.admin') && get(this, 'user.id') !== model.id) {
      get(this, 'ttAlert').error(
        this.intl.t('errors.dont_have_access_to_page')
      );
      transition.abort();
    }

    return this.store.findAll('department');
  }

  async setupController(controller, model) {
    super.setupController(controller, model);
    set(
      controller,
      'changeset',
      new Changeset(model, lookupValidator(UserValidations), UserValidations)
    );

    get(this.current.company, 'dataPrivacySetting').then(
      (dataPrivacySetting) => {
        if (dataPrivacySetting) {
          let isGdprManager = dataPrivacySetting
            .hasMany('users')
            .ids()
            .includes(get(model, 'id'));

          set(controller, 'isGdprManager', isGdprManager);
        }
      }
    );

    await this.accessLevels.loadAll();
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      set(controller, 'errors', {});
    }
  }

  activate() {
    this.router.on('routeWillChange', this.onRouterWillChange);
  }

  deactivate() {
    this.router.off('routeWillChange', this.onRouterWillChange);
  }

  onRouterWillChange = (transition) => {
    if (!transition.to.find((route) => route.name === this.routeName)) {
      if (get(this, 'controller.changeset.isDirty')) {
        transition.abort();

        const title = this.intl.t('employees.profile.save_unsaved_changes');
        const text = this.intl.t(
          'employees.profile.save_unsaved_changes_confirm'
        );
        const confirmButtonText = this.intl.t('common.save_changes');
        const cancelButtonText = this.intl.t('common.discard_changes');

        get(this, 'ttAlert').customConfirm({
          title,
          text,
          confirmButtonText,
          cancelButtonText,
          confirmCallback: () => {
            get(this, 'controller')
              .saveUser()
              .then(() => transition.retry())
              .catch(() => {});
          },

          cancelCallback: () => {
            get(this, 'controller.changeset').rollback();
            set(this, 'controller.fullErrorMessages', null);
            transition.retry();
          },
        });
      }
    }
  };
}
