import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"h-screen w-full\" ...attributes>\n  <VideoMeeting::RemoteParticipant\n    @participant={{this.participant}}\n    {{! Chime }}\n    @isNewProvider={{@isNewProvider}}\n    @audioVideoFacade={{@audioVideoFacade}}\n    @tilesMap={{@tilesMap}}\n    @showRecordingSign={{@showRecordingSign}}\n    @style=\"full\"\n  >\n    {{! Recording Button/Sign }}\n    {{yield}}\n  </VideoMeeting::RemoteParticipant>\n</div>", {"contents":"<div class=\"h-screen w-full\" ...attributes>\n  <VideoMeeting::RemoteParticipant\n    @participant={{this.participant}}\n    {{! Chime }}\n    @isNewProvider={{@isNewProvider}}\n    @audioVideoFacade={{@audioVideoFacade}}\n    @tilesMap={{@tilesMap}}\n    @showRecordingSign={{@showRecordingSign}}\n    @style=\"full\"\n  >\n    {{! Recording Button/Sign }}\n    {{yield}}\n  </VideoMeeting::RemoteParticipant>\n</div>","moduleName":"teamtailor/components/video-meeting/views/single.hbs","parseOptions":{"srcName":"teamtailor/components/video-meeting/views/single.hbs"}});
import VideoMeetingViewsBase from 'teamtailor/components/video-meeting/views/base';
import { MeetingAttendee } from 'teamtailor/utils/video-meetings/utils';
import { RemoteParticipant } from 'twilio-video';
export default class VideoMeetingViewsSpeaker extends VideoMeetingViewsBase {
  get participant(): RemoteParticipant | MeetingAttendee | undefined {
    return this.args.remoteParticipants[0];
  }
}
