import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/no-mixins
import SearchQuery from 'teamtailor/mixins/search-query';
import {
  TABLE_COLUMNS,
  TABLE_LOADING_COLUMNS,
} from 'teamtailor/constants/skills-traits';
import Current from 'teamtailor/services/current';
import ScorecardCriteriumModel from 'teamtailor/models/scorecard-criterium';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import Server from 'teamtailor/services/server';
import Store from '@ember-data/store';

export default class SettingsQuestionsController extends Controller.extend(
  SearchQuery
) {
  queryParams = ['query', 'sort_column', 'sort_direction'];

  searchProxyDelay = 250;

  @service declare current: Current;
  @service declare server: Server;
  @service declare store: Store;

  @tracked sort_column = 'name';
  @tracked sort_direction = 'asc';
  @tracked showBulk = false;
  @tracked query = '';

  @tracked selectAll = false;
  @tracked deselectedIds: string[] = [];
  @tracked selectedIds: string[] = [];
  @tracked totalCount: number = this.model.meta.total_count;

  @tracked declare type: string | null;

  @tracked declare selectedScorecardCriterium: ScorecardCriteriumModel | null;

  loadingSkillsTraitsColumns = TABLE_LOADING_COLUMNS;

  columns = TABLE_COLUMNS;

  get selectedCount() {
    if (this.selectAll) {
      return this.totalCount - this.deselectedIds.length;
    } else {
      return this.selectedIds.length;
    }
  }

  fetchCount = task(async () => {
    const adapter = this.store.adapterFor('application');
    const url = `${adapter.buildURL('scorecard-criterium')}/count_all`;

    this.totalCount = (await this.server.request(url, 'GET', {
      data: {
        query: this.query,
      },
    })) as number;
  });

  @action
  updateTotalCount() {
    this.totalCount = this.model.meta.total_count;
  }

  @action
  onDeleteClick(scorecardCriterium: ScorecardCriteriumModel) {
    this.selectedScorecardCriterium = scorecardCriterium;
  }

  @action
  onModalClose() {
    this.selectedScorecardCriterium = null;
  }

  @action
  async onDeleteConfirm() {
    if (this.selectedScorecardCriterium) {
      await this.selectedScorecardCriterium.destroyRecord();
      this.model.removeObject(this.selectedScorecardCriterium);
      this.fetchCount.perform();
      this.onModalClose();
    }
  }

  @action
  handleCriteriumCreated(scorcardCriterium: ScorecardCriteriumModel) {
    this.model.unshiftObject(scorcardCriterium);
    this.fetchCount.perform();
  }

  @action selectAllEnabledUpdateSelected(isSelected: boolean, id: string) {
    if (isSelected) {
      this.deselectedIds.pushObject(id);
    } else {
      this.deselectedIds.removeObject(id);
    }
  }

  @action selectAllDisabledUpdateSelected(isSelected: boolean, id: string) {
    if (isSelected) {
      this.selectedIds.removeObject(id);
    } else {
      this.selectedIds.pushObject(id);
    }
  }

  @action
  handleSelectClick(scorecardCriterium: ScorecardCriteriumModel) {
    const isSelected = this.isSelected(scorecardCriterium);
    const { id } = scorecardCriterium;
    if (this.selectAll) {
      this.selectAllEnabledUpdateSelected(isSelected, id);
    } else {
      this.selectAllDisabledUpdateSelected(isSelected, id);
    }
  }

  @action
  isSelected(scorecardCriterium: ScorecardCriteriumModel) {
    const { id } = scorecardCriterium;
    if (this.selectAll) {
      return !this.deselectedIds.includes(id);
    }

    return this.selectedIds.includes(id);
  }

  @action
  toggleSelectAll() {
    this.selectAll = !this.selectAll;

    this.deselectedIds = [];
    this.selectedIds = [];
  }

  @action
  clearSelection() {
    this.selectAll = false;
    this.deselectedIds = [];
    this.selectedIds = [];
  }

  @action
  onBulkActionDelete() {
    if (this.selectedIds.length) {
      this.selectedIds.forEach((id: string) => {
        const scorecard = this.model.find(
          (sc: ScorecardCriteriumModel) => sc.id === id
        );
        this.model.removeObject(scorecard);
      });
    }

    if (this.selectAll && !this.deselectedIds.length) {
      this.model.clear();
    }

    if (this.selectAll && this.deselectedIds.length) {
      const deletedScorecardCriteria = this.model.filter(
        (sc: ScorecardCriteriumModel) => !this.deselectedIds.includes(sc.id)
      );

      deletedScorecardCriteria.forEach((sc: ScorecardCriteriumModel) => {
        this.model.removeObject(sc);
      });
    }

    this.clearSelection();
    this.fetchCount.perform();
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.skills-traits': SettingsQuestionsController;
  }
}
