import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span>{{humanize-string this.preparedValue}}</span>", {"contents":"<span>{{humanize-string this.preparedValue}}</span>","moduleName":"teamtailor/components/custom-fields/show/field-visibility-options.hbs","parseOptions":{"srcName":"teamtailor/components/custom-fields/show/field-visibility-options.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class FieldVisibilityOptions extends Component {
  @service intl;

  get preparedValue() {
    const { field } = this.args;
    const properties = [];
    const types = {
      isHidden: 'is_hidden',
      isFeatured: 'is_featured',
      isExternal: 'is_external',
      isPrivate: 'is_private',
      isRequired: 'is_required',
    };

    Object.keys(types).forEach((key) => {
      if (field[key]) {
        properties.push(
          this.intl.t(
            `components.custom_fields.show.field_visibility_options.${types[key]}`
          )
        );
      }
    });

    if (!properties.length) {
      properties.push(
        this.intl.t(
          'components.custom_fields.show.field_visibility_options.normal'
        )
      );
    }

    return properties.join(', ').toLowerCase();
  }
}
