import RSVP from 'rsvp';
import { get } from 'teamtailor/utils/get';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';

export default class CookiesRoute extends ScrollToTopRoute {
  @service current;
  @service store;

  async model() {
    let cookieSettings = await get(this.current.company, 'cookieSetting');
    if (!cookieSettings) {
      cookieSettings = this.store.createRecord('cookie-setting');
    }

    return RSVP.hash({
      cookieDetails: this.store.findAll('cookie-detail'),
      cookieSettings,
    });
  }
}
