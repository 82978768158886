import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { fetchMissingAverageRatings } from 'teamtailor/utils/average-rating';
import sortOnAverageRating from 'teamtailor/utils/sort-on-average-rating';
import CsvFileExporter from 'teamtailor/utils/csv-file-exporter';
import preloadMissingModels from 'teamtailor/utils/insights/preload-missing-models';

const COLUMN_CONFIGURATION_FOR_EXPORT = [
  {
    type: 'text',
    propertyName: 'name',
    headerKey: 'common.name',
  },
  {
    type: 'number',
    propertyName: 'sessions',
    headerKey: 'reports.visits',
  },
  {
    type: 'number',
    propertyName: 'nrApplications',
    headerKey: 'reports.applications',
  },
  {
    type: 'number',
    propertyName: 'conversionRate',
    headerKey: 'reports.conversion_rate',
  },
  {
    type: 'rating',
    propertyName: 'averageRating',
    headerKey: 'reports.avg_rating',
  },
  {
    type: 'text',
    propertyName: 'candidates',
    headerKey: 'reports.applicants',
  },
];

const COLUMN_CONFIGURATION_FOR_JOB_BREAKDOWN_EXPORT = [
  {
    type: 'text',
    propertyName: 'jobTitle',
    headerKey: 'insights.common.job',
  },
  {
    type: 'text',
    propertyName: 'name',
    headerKey: 'common.name',
  },
  {
    type: 'number',
    propertyName: 'sessions',
    headerKey: 'reports.visits',
  },
  {
    type: 'number',
    propertyName: 'nrApplications',
    headerKey: 'reports.applications',
  },
  {
    type: 'number',
    propertyName: 'conversionRate',
    headerKey: 'reports.conversion_rate',
  },
  {
    type: 'rating',
    propertyName: 'averageRating',
    headerKey: 'reports.avg_rating',
  },
  {
    type: 'text',
    propertyName: 'candidates',
    headerKey: 'reports.applicants',
  },
];

export default class PromotionsController extends Controller {
  @service analytics;
  @service store;
  @service intl;

  @tracked sortDirection = 'desc';
  @tracked sortProperty = 'nrApplications';
  @tracked loadingAverageRatings = false;
  @tracked showingJobBreakdown = false;

  get promotions() {
    let promotions = this.model.promotions.value;
    if (!promotions) {
      return;
    }

    let { rows, groupedRows, totalVisits, totalApplications, conversionRate } =
      promotions;

    return {
      rows: this.showingJobBreakdown ? rows : groupedRows,
      totalVisits,
      totalApplications,
      conversionRate,
    };
  }

  get sortedPromotions() {
    if (this.sortProperty === 'averageRating') {
      return sortOnAverageRating(this.promotions.rows, this.sortDirection);
    }

    if (this.sortDirection === 'asc') {
      return this.promotions.rows.sortBy(this.sortProperty);
    } else {
      return this.promotions.rows.sortBy(this.sortProperty).reverse();
    }
  }

  async loadMissingCandidates(rows) {
    const candidateIds = rows.map((promotion) => promotion.candidateIds).flat();

    await preloadMissingModels('candidate', candidateIds, this.store, {
      batchSize: 50,
      groupAnalytics: this.analytics.hasEnabledGroupAnalytics,
    });
  }

  async loadMissingJobs(rows) {
    const jobIds = rows.map((promotion) => promotion.jobId).flat();

    await preloadMissingModels('job', jobIds, this.store, {
      batchSize: 50,
      groupAnalytics: this.analytics.hasEnabledGroupAnalytics,
    });
  }

  @action
  async handleExport() {
    const { sortedPromotions } = this;

    await fetchMissingAverageRatings(sortedPromotions);
    await this.loadMissingCandidates(sortedPromotions);
    await this.loadMissingJobs(sortedPromotions);

    const rowsToExport = sortedPromotions.map((row) => ({
      ...row,
      conversionRate: (100 * row.conversionRate).toFixed(2),
      jobTitle:
        this.store.peekRecord('job', row.jobId)?.title ||
        this.intl.t('insights.common.deleted_job'),

      candidates: row.candidateIds
        .map(
          (id) =>
            this.store.peekRecord('candidate', id)?.name ||
            this.intl.t('insights.common.deleted_candidate')
        )
        .join(', '),
    }));

    new CsvFileExporter(
      rowsToExport,
      this.showingJobBreakdown
        ? COLUMN_CONFIGURATION_FOR_JOB_BREAKDOWN_EXPORT
        : COLUMN_CONFIGURATION_FOR_EXPORT,
      this.intl
    ).downloadFile({
      baseFileName: 'promotions',
      startDate: this.analytics.startDate,
      endDate: this.analytics.endDate,
    });
  }

  @action
  async handleSort(sortProperty) {
    if (this.sortProperty === sortProperty) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
    } else {
      if (sortProperty === 'averageRating') {
        this.loadingAverageRatings = true;
        await fetchMissingAverageRatings(this.model.promotions.value.rows);
        this.loadingAverageRatings = false;
      }

      this.sortDirection = 'desc';
      this.sortProperty = sortProperty;
    }
  }
}
