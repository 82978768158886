import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Button\n  @icon=\"facebook-square\"\n  @iconStyle=\"brands\"\n  @onClick={{this.handleClick}}\n  @text={{@buttonText}}\n/>", {"contents":"<Button\n  @icon=\"facebook-square\"\n  @iconStyle=\"brands\"\n  @onClick={{this.handleClick}}\n  @text={{@buttonText}}\n/>","moduleName":"teamtailor/components/share-button/facebook.hbs","parseOptions":{"srcName":"teamtailor/components/share-button/facebook.hbs"}});
import ShareButton from 'teamtailor/components/share-button/share-button';

export default class extends ShareButton {
  shareUrl = 'https://facebook.com/sharer.php';

  buildUrl() {
    const url = encodeURIComponent(this.args.url);
    return `${this.shareUrl}?display=popup&u=${url}&quote=${this.args.quote}`;
  }
}
