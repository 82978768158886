import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Button\n  @icon=\"square-x-twitter\"\n  @iconStyle=\"brands\"\n  @onClick={{this.handleClick}}\n  @text={{@buttonText}}\n/>", {"contents":"<Button\n  @icon=\"square-x-twitter\"\n  @iconStyle=\"brands\"\n  @onClick={{this.handleClick}}\n  @text={{@buttonText}}\n/>","moduleName":"teamtailor/components/share-button/twitter.hbs","parseOptions":{"srcName":"teamtailor/components/share-button/twitter.hbs"}});
import ShareButton from 'teamtailor/components/share-button/share-button';

export default class extends ShareButton {
  shareURL = 'https://twitter.com/intent/tweet';

  buildUrl() {
    let url = this.shareURL;
    url += `?text=${this.args.title}`;
    url += `&url=${encodeURIComponent(this.args.url)}`;
    url += `&hashtags=${this.args.hashtags}`;
    url += `&via=${this.args.via}`;
    return url;
  }
}
