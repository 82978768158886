import Model, {
  belongsTo,
  hasMany,
  attr,
  type SyncHasMany,
} from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import {
  ConnectedCalendarModel,
  ConnectedConferenceModel,
} from 'teamtailor/models';

export default class CalendarOptionModel extends Model {
  @belongsTo('connected-calendar', { async: false })
  declare connectedCalendar: ConnectedCalendarModel;

  @hasMany('connected-conference', { async: false })
  declare connectedConferences: SyncHasMany<ConnectedConferenceModel>;

  @attr('boolean', { defaultValue: false })
  declare calendarIntegratedConferencingAvailable: boolean;

  @attr('boolean') declare calendarPrimary: boolean;
  @attr('boolean') declare calendarReadonly: boolean;
  @attr('string') declare name: string;
  @attr('string') declare profileId: string;
  @attr('string') declare profileName: string;
  @attr('string') declare providerName: string;

  get isPrimary() {
    return get(this.connectedCalendar, 'useForInserts');
  }

  get isEditable() {
    return !this.calendarReadonly;
  }

  get isSelected() {
    return !isEmpty(this.connectedCalendar);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'calendar-option': CalendarOptionModel;
  }
}
