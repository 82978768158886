import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { setProperties } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import Current from 'teamtailor/services/current';
import InfinityService from 'ember-infinity/addon/services/infinity';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/-private/transition';
import ContentIndexController from 'teamtailor/controllers/content';

export default class ContentIndexRoute extends Route {
  @service declare current: Current;
  @service declare infinity: InfinityService;
  @service declare router: RouterService;
  @service declare store: Store;

  queryParams = {
    view: { refreshModel: true },
    site: { refreshModel: true },
    query: { refreshModel: true },
  };

  async beforeModel({
    to: {
      queryParams: { site },
    },
  }: Transition) {
    if (this.current.company.hasFeature('career_site_languages') && !site) {
      await this.current.company.careerSites;
      // @ts-expect-error ember docs says that the first arg has to be a string, does this work?
      this.router.replaceWith({
        queryParams: {
          site: get(get(this.current.company, 'defaultCareerSite'), 'id'),
        },
      });
    }
  }

  model({ site, view, query }: { site: string; view: string; query: string }) {
    const careerSite = this.peekOrFindCareerSite(site);

    const pages = this.infinity.model('page', {
      startingPage: 1,
      perPage: 25,
      career_site_id: site,
      view,
      query,
      no_connect: true,
    });

    return {
      careerSite,
      pages,
    };
  }

  resetController(controller: ContentIndexController, isExiting: boolean) {
    if (isExiting) {
      setProperties(controller, {
        view: null,
        query: null,
        site: null,
        showPublishChangesModal: false,
      });
    }
  }

  peekOrFindCareerSite(careerSiteId: string) {
    return careerSiteId
      ? this.store.peekRecord('career-site', careerSiteId) ||
          this.store.findRecord('career-site', careerSiteId)
      : this.current.company.defaultCareerSite;
  }
}
