import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (and this.flipper.hire_quality_survey this.hireQualityResponses)}}\n  <CandidateModalNew::Main::Section\n    @title={{t \"components.candidate_modal.hire_quality_surveys.title\"}}\n    @id=\"hire-quality-surveys\"\n  >\n    <:content>\n      <p>\n        {{t\n          \"components.candidate_modal.hire_quality_surveys.question\"\n          candidateName=@candidate.nameOrEmail\n        }}\n      </p>\n      <ul class=\"flex flex-col gap-16\">\n        {{#each this.hireQualityResponses as |hireQualityResponse|}}\n          <CandidateModal::HireQualitySurveys::Item\n            @hireQualityResponse={{hireQualityResponse}}\n          />\n        {{/each}}\n      </ul>\n    </:content>\n  </CandidateModalNew::Main::Section>\n{{/if}}", {"contents":"{{#if (and this.flipper.hire_quality_survey this.hireQualityResponses)}}\n  <CandidateModalNew::Main::Section\n    @title={{t \"components.candidate_modal.hire_quality_surveys.title\"}}\n    @id=\"hire-quality-surveys\"\n  >\n    <:content>\n      <p>\n        {{t\n          \"components.candidate_modal.hire_quality_surveys.question\"\n          candidateName=@candidate.nameOrEmail\n        }}\n      </p>\n      <ul class=\"flex flex-col gap-16\">\n        {{#each this.hireQualityResponses as |hireQualityResponse|}}\n          <CandidateModal::HireQualitySurveys::Item\n            @hireQualityResponse={{hireQualityResponse}}\n          />\n        {{/each}}\n      </ul>\n    </:content>\n  </CandidateModalNew::Main::Section>\n{{/if}}","moduleName":"teamtailor/components/candidate-modal-new/main/section/hire-quality-surveys/index.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-modal-new/main/section/hire-quality-surveys/index.hbs"}});
import CandidateModel from 'teamtailor/models/candidate';
import Component from '@glimmer/component';
import FlipperService from 'teamtailor/services/flipper';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import JobApplicationModel from 'teamtailor/models/job-application';

interface Args {
  candidate: CandidateModel;
  jobApplication: JobApplicationModel;
}

export default class CandidateModalNewMainHireQualitySurveysComponents extends Component<Args> {
  @service declare flipper: FlipperService;

  get hireQualityResponses() {
    const jobApplicationId = get(this.args.jobApplication, 'id');
    return this.args.candidate.hireQualityResponses
      .toArray()
      .filter((hireQualityResponse) => {
        return (
          hireQualityResponse.jobApplicationId.toString() === jobApplicationId
        );
      });
  }
}
