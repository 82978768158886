import Model, {
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import ChargesModel from './charge';
import CompanyModel from './company';
import { inject as service } from '@ember/service';
import Server from 'teamtailor/services/server';

export interface PaymentMethodInterface {
  exp_month: string;
  exp_year: string;
  last_four: string;
  brand: string;
  name: string;
  theme?: string;
}

export const DEFAULT_VAT_TYPE = 'EUREVERSEDVAT';

export default class PaymentProcessorSettingModel extends Model {
  @service declare server: Server;

  @belongsTo('company', { async: false })
  declare company: CompanyModel;

  @hasMany('charge', { async: true })
  declare charges: AsyncHasMany<ChargesModel>;

  @attr('string') declare address: string;
  @attr('string') declare city: string;
  @attr('string') declare country: string;
  @attr('string') declare email: string;
  @attr('string') declare name: string;
  @attr('string') declare phone: string;
  @attr('string') declare postcode: string;
  @attr('string') declare state: string;
  @attr('string') declare status: string;
  @attr('string') declare display: string;
  @attr('string') declare optionalTaxNumber: string;
  @attr('string') declare stripeClientSecret: string;
  @attr('string') declare stripeCustomerId: string;
  @attr('string') declare stripePaymentIntentId: string;
  @attr('string') declare stripeSetupIntentId: string;
  @attr('string') declare taxNumber: string;
  @attr('string') declare vatType: string;
  @attr('number') declare vatPercentage: number;

  @attr('raw') declare stripePaymentMethods:
    | PaymentMethodInterface[]
    | undefined;

  async removePaymentMethod(paymentMethodId: string) {
    const data = {
      to_be_removed_payment_method_id: paymentMethodId,
    };

    return this.server.memberAction(this, {
      action: 'remove_payment_method',
      method: 'POST',
      options: {
        data,
      },
    });
  }

  get isInitial() {
    return this.status === 'initial';
  }

  get isPending() {
    return this.status === 'pending';
  }

  get isSaved() {
    return this.status === 'saved';
  }

  get canNotEditTaxNumber() {
    return this.vatType === DEFAULT_VAT_TYPE;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'payment-processor-setting': PaymentProcessorSettingModel;
  }
}
