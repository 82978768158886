import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    rowOrder: { serialize: false },
  },

  serialize() {
    let json = this._super(...arguments);

    if (json.user_id) {
      json.owner_id = json.user_id;
      json.owner_type = 'User';
    } else if (json.company_id) {
      json.owner_id = json.company_id;
      json.owner_type = 'Company';
    }

    return json;
  },
});
