import { service } from '@ember/service';
import Controller from '@ember/controller';
import { set, setProperties, action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { Promise } from 'rsvp';
import { tracked } from '@glimmer/tracking';

export default class IndexController extends Controller {
  @service flashMessages;
  @service intl;
  @service router;
  @service store;

  @tracked _gdprAutomaticallyAskForPermission = null;

  expirationOptions = [
    { days: -1, intlKey: 'settings.data_privacy.dont_remind_me' },
    { days: 90, intlKey: 'settings.data_privacy.after_3_months' },
    { days: 180, intlKey: 'settings.data_privacy.after_6_months' },
    { days: 360, intlKey: 'settings.data_privacy.after_12_months' },
    { days: 540, intlKey: 'settings.data_privacy.after_18_months' },
    { days: 720, intlKey: 'settings.data_privacy.after_24_months' },
    { days: 1080, intlKey: 'settings.data_privacy.after_36_months' },
  ];

  get dataPrivacySetting() {
    return this.model;
  }

  get gdprAutomaticallyAskForPermission() {
    if (this._gdprAutomaticallyAskForPermission) {
      return this._gdprAutomaticallyAskForPermission;
    } else if (get(this, 'dataPrivacySetting.autoSendExtend')) {
      return 'opt-in';
    } else if (get(this, 'dataPrivacySetting.autoSendRenewal')) {
      return 'opt-out';
    } else {
      return 'none';
    }
  }

  get neverRemindAboutExpiredConsents() {
    if (get(this, 'dataPrivacySetting.consentExpiration') === -1) {
      // eslint-disable-next-line ember/no-side-effects
      set(this, 'dataPrivacySetting.autoSendRenewal', false);
      return true;
    }

    return undefined;
  }

  @action
  gdprAutoSendChanged(value) {
    this._gdprAutomaticallyAskForPermission = value;
    if (value === 'opt-out') {
      setProperties(get(this, 'dataPrivacySetting'), {
        autoSendRenewal: true,
        autoSendExtend: false,
      });
    } else if (value === 'opt-in') {
      setProperties(get(this, 'dataPrivacySetting'), {
        autoSendRenewal: false,
        autoSendExtend: true,
      });
    } else {
      setProperties(get(this, 'dataPrivacySetting'), {
        autoSendRenewal: false,
        autoSendExtend: false,
      });
    }
  }

  @action
  save() {
    return new Promise((resolve, reject) => {
      get(this, 'dataPrivacySetting')
        .save()
        .then(resolve, () => {
          get(this, 'flashMessages').error(
            this.intl.t('settings.data_privacy.could_not_save_settings')
          );
          return reject();
        });
    });
  }

  @action
  setConsentExpiration(option) {
    set(this, 'dataPrivacySetting.consentExpiration', option.days);
  }
}
