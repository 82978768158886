import moment, { Moment } from 'moment-timezone';
import { tracked } from '@glimmer/tracking';

export default class DateRange {
  get asObject() {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }

  get previousDateRange() {
    return new DateRange(
      this._startDate.clone().subtract(this.rangeInDays + 1, 'days'),
      this._startDate.clone().subtract(1, 'days')
    );
  }

  constructor(
    startDate?: string | Date | Moment | null,
    endDate?: string | Date | Moment | null
  ) {
    this._startDate = moment(startDate);
    this._endDate = moment(endDate);
  }

  @tracked _startDate: Moment;
  @tracked _endDate: Moment;

  get startDate() {
    return this._startDate.format('YYYY-MM-DD');
  }

  set startDate(value: string | Date | Moment) {
    this._startDate = moment(value);
  }

  get endDate() {
    return this._endDate.format('YYYY-MM-DD');
  }

  set endDate(value: string | Date | Moment) {
    this._endDate = moment(value);
  }

  get rangeInDays() {
    return Math.abs(this._startDate.diff(this._endDate, 'days'));
  }

  get dateSpan(): Moment[] {
    const startDate = moment(this._startDate);
    const dates = [];

    for (; startDate.isBefore(this._endDate); startDate.add(1, 'day')) {
      dates.push(startDate.clone());
    }

    dates.push(startDate);

    return dates;
  }

  compare(date: string | Date) {
    const mDate = moment(date);
    if (mDate.isBetween(this._startDate, this._endDate, 'day', '[]')) {
      return 0;
    }

    return mDate.isAfter(this._endDate) ? 1 : -1;
  }
}
