import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"w-full\">\n  <Toolbox::GlobalComments::ReadOnly::ChannelNameDivider\n    @jobId={{@job.id}}\n    @name={{@job.title}}\n  />\n\n  {{#if this.isLoading}}\n    <LoadingSpinner />\n  {{else}}\n    <Toolbox::GlobalComments::Unread::UnreadActivities\n      @jobId={{@job.id}}\n      @latestJobCommentReadDateAt={{this.latestReadDate}}\n    />\n  {{/if}}\n</div>", {"contents":"<div class=\"w-full\">\n  <Toolbox::GlobalComments::ReadOnly::ChannelNameDivider\n    @jobId={{@job.id}}\n    @name={{@job.title}}\n  />\n\n  {{#if this.isLoading}}\n    <LoadingSpinner />\n  {{else}}\n    <Toolbox::GlobalComments::Unread::UnreadActivities\n      @jobId={{@job.id}}\n      @latestJobCommentReadDateAt={{this.latestReadDate}}\n    />\n  {{/if}}\n</div>","moduleName":"teamtailor/components/toolbox/global-comments/unread/unread-job.hbs","parseOptions":{"srcName":"teamtailor/components/toolbox/global-comments/unread/unread-job.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { JobModel, LatestReadJobCommentModel } from 'teamtailor/models';
import Store from '@ember-data/store';
import { trackedFunction } from 'ember-resources/util/function';

type Args = {
  job: JobModel;
  isLoading?: boolean;
};

export default class UnreadJobComponent extends Component<Args> {
  @service declare store: Store;

  get job() {
    return this.args.job;
  }

  resolveLatestReadJobCommentResponses = trackedFunction(this, async () => {
    const latestJobComment: LatestReadJobCommentModel[] =
      await this.store.query('latest-read-job-comment', {
        job_id: this.job.id,
      });
    return latestJobComment;
  });

  get isLoading() {
    return (
      this.args.isLoading ||
      this.resolveLatestReadJobCommentResponses.value === null
    );
  }

  get latestReadJobComment() {
    return this.resolveLatestReadJobCommentResponses.value?.[0] || null;
  }

  get latestReadDate() {
    const latestRead = this.latestReadJobComment?.latestReadCommentAt;
    return latestRead;
  }
}
