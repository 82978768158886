import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FormLabel @theme={{this.theme}} @label={{this.label}}>\n  <GMap as |g|>\n    <g.autocomplete @onSearch={{this.onChange}} as |autocomplete|>\n      <Input\n        class={{this.inputClasses}}\n        @value={{readonly this.defaultInputValue}}\n        disabled={{this.isDisabled}}\n        {{did-insert autocomplete.setup}}\n      />\n    </g.autocomplete>\n  </GMap>\n</FormLabel>", {"contents":"<FormLabel @theme={{this.theme}} @label={{this.label}}>\n  <GMap as |g|>\n    <g.autocomplete @onSearch={{this.onChange}} as |autocomplete|>\n      <Input\n        class={{this.inputClasses}}\n        @value={{readonly this.defaultInputValue}}\n        disabled={{this.isDisabled}}\n        {{did-insert autocomplete.setup}}\n      />\n    </g.autocomplete>\n  </GMap>\n</FormLabel>","moduleName":"teamtailor/components/form-location/autocomplete.hbs","parseOptions":{"srcName":"teamtailor/components/form-location/autocomplete.hbs"}});
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import classnames from 'teamtailor/utils/classnames';
import { equal } from '@ember/object/computed';

export default Component.extend({
  showMap: null,
  location: null,
  onChange: null,
  theme: 'default',
  isDisabled: false,

  isDarkTheme: equal('theme', 'dark'),

  defaultInputValue: computed('location.{address,city}', function () {
    return [get(this, 'location.address'), get(this, 'location.city')]
      .filter((a) => a)
      .join(', ');
  }),

  inputClasses: computed('inputClass', 'isDarkTheme', 'theme', function () {
    return classnames('tt-form-control', get(this, 'inputClass'), {
      'tt-form-dark': get(this, 'isDarkTheme'),
    });
  }),
});
