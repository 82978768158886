import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class NewRoute extends Route {
  @service store;

  model(params) {
    const { byCompany } = params;

    return this.store.createRecord('reject-reason', {
      rejectedByCompany: byCompany,
    });
  }

  resetController(controller, isExiting) {
    if (isExiting && get(controller.model, 'isNew')) {
      controller.model.unloadRecord();
    }
  }
}
