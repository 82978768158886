import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import {
  DepartmentModel,
  LocationModel,
  PickedImageModel,
  PickedVideoModel,
  SectionModel,
} from 'teamtailor/models';
import Raw from 'teamtailor/transforms/raw';
import { get } from 'teamtailor/utils/get';
import { IconPrefix } from '@fortawesome/fontawesome-svg-core';

export default class SectionItemModel extends Model {
  @belongsTo('section', { polymorphic: true })
  declare section: AsyncBelongsTo<SectionModel>;

  @belongsTo('location', { async: false })
  declare location: LocationModel;

  @belongsTo('department', { async: false })
  declare department: DepartmentModel;

  @belongsTo('picked-image', { async: false })
  declare pickedImage?: PickedImageModel;

  @belongsTo('picked-video', { async: false })
  declare pickedVideo?: PickedVideoModel;

  @cached
  get pagePublication() {
    return get(this, 'modelType') === 'PagePublication' && get(this, 'modelId')
      ? this.store.findRecord('page-publication', this.modelId)
      : null;
  }

  @cached
  get job() {
    return get(this, 'modelType') === 'Job' && get(this, 'modelId')
      ? this.store.findRecord('job', this.modelId)
      : null;
  }

  @attr('boolean') declare isVideo: boolean;
  @attr('boolean', { allowNull: true }) declare abbreviated: boolean;
  @attr('boolean', { allowNull: true }) declare openInNewTab: boolean;
  @attr('string') declare modelId: string;
  @attr('string') declare icon: string;
  @attr('string') declare iconPrefix: IconPrefix;
  @attr('number') declare number: number;
  @attr('number') declare rowOrder: number;
  @attr('number') rowOrderPosition?: number;
  @attr('string') declare body: string;
  @attr('string') declare color: string;
  @attr('string') declare externalVideoId: string;
  @attr('string') declare itemType: string;
  @attr('string') declare jobTitle: string;
  @attr('string') declare label: string;
  @attr('string') declare modelType?: string;
  @attr('string') declare timestamp: string;
  @attr('string', { defaultValue: '' }) declare title: string;
  @attr('string') declare url: string;
  @attr('raw') declare data: Raw;
  @attr('raw') declare dataset: Raw;

  get position() {
    return this.rowOrderPosition === undefined
      ? this.rowOrder
      : this.rowOrderPosition;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'section-item': SectionItemModel;
  }
}
