import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{@name}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"label\"\n    @options={{this.options}}\n    @onSelect={{this.handleSelect}}\n    @searchEnabled={{false}}\n    @selected={{this.selectedOption}}\n    @nullOption={{t \"search.all\"}}\n  />\n</label>", {"contents":"<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{@name}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"label\"\n    @options={{this.options}}\n    @onSelect={{this.handleSelect}}\n    @searchEnabled={{false}}\n    @selected={{this.selectedOption}}\n    @nullOption={{t \"search.all\"}}\n  />\n</label>","moduleName":"teamtailor/components/sidebar/filters/custom-checkbox.hbs","parseOptions":{"srcName":"teamtailor/components/sidebar/filters/custom-checkbox.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class SelectComponent extends Component {
  @service intl;

  get options() {
    return [
      { id: 'yes', label: this.intl.t('common.yes') },
      { id: 'no', label: this.intl.t('common.no') },
    ];
  }

  get selectedOption() {
    return this.options.findBy('id', this.args.selected);
  }

  @action
  handleSelect(value) {
    this.args.onChange(value?.id);
  }
}
