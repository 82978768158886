import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';

export default class CareerSiteLanguageBaseRoute extends ScrollToTopRoute {
  @service current;

  hasCareerSiteLanguages = false;
  defaultLanguageCode = null;

  async beforeModel() {
    const company = await this.current.company;
    this.hasCareerSiteLanguages = company.hasFeature('career_site_languages');
    if (this.hasCareerSiteLanguages) {
      await get(company, 'careerSites');
      this.defaultLanguageCode = company.defaultCareerSite.languageCode;
    }
  }

  resetController({ model }, isExiting) {
    if (isExiting && model.isNew) {
      model.destroyRecord();
    }
  }
}
