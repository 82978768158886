import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isLoading}}\n  <LoadingSpinner />\n{{else}}\n  <Jobs::Collaborate::List\n    @models={{this.sortedMentionedActivities}}\n    @readOnlyChannel={{true}}\n    @destroyActivity={{this.destroyActivity}}\n    @hideOnlineBeacon={{true}}\n  />\n{{/if}}", {"contents":"{{#if this.isLoading}}\n  <LoadingSpinner />\n{{else}}\n  <Jobs::Collaborate::List\n    @models={{this.sortedMentionedActivities}}\n    @readOnlyChannel={{true}}\n    @destroyActivity={{this.destroyActivity}}\n    @hideOnlineBeacon={{true}}\n  />\n{{/if}}","moduleName":"teamtailor/components/toolbox/global-comments/mentioned/mentioned-activities.hbs","parseOptions":{"srcName":"teamtailor/components/toolbox/global-comments/mentioned/mentioned-activities.hbs"}});
import Component from '@glimmer/component';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { trackedFunction } from 'ember-resources/util/function';
import ActivityModel from 'teamtailor/models/activity';

type Args = {
  jobId: string;
  actionIds: string[];
  isLoading?: boolean;
};

export default class MentionedActivitiesComponent extends Component<Args> {
  @service declare store: Store;

  resolveMentionedActivityResponses = trackedFunction(this, async () => {
    const mentionedActivities: ActivityModel[] = await this.store.query(
      'activity',
      {
        job_id: this.args.jobId,
        action_ids: this.args.actionIds,
        collaborate: true,
      }
    );
    return mentionedActivities;
  });

  get isLoading() {
    return (
      this.args.isLoading ||
      this.resolveMentionedActivityResponses.value === null
    );
  }

  get mentionedActivities(): ActivityModel[] {
    return this.resolveMentionedActivityResponses.value || [];
  }

  get sortedMentionedActivities(): ActivityModel[] {
    return [...this.mentionedActivities].sort(
      (a: ActivityModel, b: ActivityModel) =>
        new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
    );
  }
}
