import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    screenshot: { serialize: false },
    publicUrl: { serialize: false },
    sharingImage: {
      key: 'sharing_image_attributes',
      serialize: 'records',
      deserialize: false,
    },

    pagePublication: {
      deserialize: {
        embed: 'always',
      },
    },

    users: { serialize: 'ids' },

    tags: { serialize: 'records', key: 'tag_list' },
  },

  serialize() {
    let json = { ...this._super(...arguments) };
    if (json.tag_list.length) {
      json.tag_list = json.tag_list.map((tag) => tag.name);
    }

    return json;
  },
});
