import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Modal\n  @isOpen={{@modalOpen}}\n  @onClose={{@onClose}}\n  @size=\"small\"\n  as |modal|\n>\n  <modal.Header @text={{t \"components.message_item.raw_message\"}} />\n  <modal.Main as |main|>\n    <main.Section>\n      {{#if this.fetchRawBody.isRunning}}\n        <LoadingSpinner @size=\"large\" />\n      {{else}}\n        {{simple-format this.rawBody}}\n      {{/if}}\n    </main.Section>\n  </modal.Main>\n</Core::Modal>", {"contents":"<Core::Modal\n  @isOpen={{@modalOpen}}\n  @onClose={{@onClose}}\n  @size=\"small\"\n  as |modal|\n>\n  <modal.Header @text={{t \"components.message_item.raw_message\"}} />\n  <modal.Main as |main|>\n    <main.Section>\n      {{#if this.fetchRawBody.isRunning}}\n        <LoadingSpinner @size=\"large\" />\n      {{else}}\n        {{simple-format this.rawBody}}\n      {{/if}}\n    </main.Section>\n  </modal.Main>\n</Core::Modal>","moduleName":"teamtailor/components/messages/raw-body-modal.hbs","parseOptions":{"srcName":"teamtailor/components/messages/raw-body-modal.hbs"}});
import Component from '@glimmer/component';
import { dropTask, lastValue } from 'ember-concurrency';

export default class extends Component {
  constructor() {
    super(...arguments);
    this.fetchRawBody.perform();
  }

  @lastValue('fetchRawBody')
  rawBody;

  fetchRawBody = dropTask(async () => {
    return this.args.message.getRawBody().then((data) => {
      return data.raw_body;
    });
  });
}
