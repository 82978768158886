import { inject as service } from '@ember/service';
import EmberRouter from '@ember/routing/router';
import config from 'teamtailor/config/environment';
import { unicornRoutes } from 'teamtailor/routes/unicorn';

class Router extends EmberRouter {
  @service intercom;
  @service current;

  constructor() {
    super(...arguments);
    this.on('routeDidChange', this.testTwoFactorAuthenticationEnforced);
  }

  location = config.locationType;
  rootURL = config.rootURL;

  testTwoFactorAuthenticationEnforced() {
    if (!this.current.company) return;
    if (this.current.user.superadmin) return;
    if (this.currentRouteName === 'employees.profile.security.activate-2fa')
      return;

    const { twoFactorAuthenticationEnforced } = this.current.company;
    const { twoFactorActivated } = this.current.user.login || {};

    if (twoFactorAuthenticationEnforced && !twoFactorActivated) {
      this.replaceWith(
        'employees.profile.security.index',
        this.current.user.id
      );
    } else {
      this.off('routeDidChange', this.testTwoFactorAuthenticationEnforced);
    }
  }
}

export default Router;

const blockRoutesWithLayout = function () {
  this.route('index');
  this.route('new', { path: 'item/new' });
  this.route('item', { path: 'item/:item_id' });
  this.route('layout');
};

const meetingRoutes = function () {
  this.route('new');
  this.route('edit', { path: '/:meeting_id/edit' });
  this.route('events/show', {
    path: '/:meeting_id/events/:meeting_event_id',
  });
};

/* eslint-disable ember/no-shadow-route-definition */
const jobOfferRoutes = function () {
  this.route('show', { path: '/:job_offer_id' });
  this.route('edit', { path: '/:job_offer_id/edit' });
  this.route('answer', { path: '/:job_offer_id/answer' });
  this.route('new');
};

const hireQualityRoutes = function () {
  this.route('edit', { path: '/:hire_quality_response_id' });
};
/* eslint-enable ember/no-shadow-route-definition */

const candidateRoutes = function () {
  this.route('meetings', meetingRoutes);
  this.route('job-offer', jobOfferRoutes);
  this.route('hire-quality', { path: 'quality-of-hire' }, hireQualityRoutes);
};

Router.map(function () {
  this.route('login');
  this.route('invitations', { path: 'invitations/:invitation_id' });
  this.route('companies');
  this.route('cookie-policy');
  this.route('video-meetings', { path: 'video-meetings/:video_meeting_id' });
  this.route('mobile-app');

  this.route('shared-reports', { path: 'shared-report/:token' });

  this.route('company', { path: 'companies/:company_id' }, function () {
    this.route(
      'video-meeting',
      { path: 'video-meeting/:video_meeting_id', resetNamespace: true },
      function () {
        this.route(
          'candidate',
          { path: 'candidate/:candidate_id' },
          candidateRoutes
        );
      }
    );
    this.route('marketplace', { resetNamespace: true }, function () {
      this.route('partners', function () {
        this.route('oauth_partner');
        this.route('index', { path: '/' }, function () {
          this.route('partner', { path: '/:partner_id' }, function () {
            this.route('index', { path: '/' });
            this.route('internal');
            this.route('activate');
          });
        });
      });
      this.route('channels', function () {
        this.route('index', { path: '/' }, function () {
          this.route('channel', { path: '/:channel_id' }, function () {
            this.route('activate');
            this.route('custom_form');
            this.route('indeed');
            this.route('indeed_employer_setup');
            this.route('always_included');
          });
        });
      });
    });
    this.route('dashboard', { resetNamespace: true }, function () {
      this.route('tranquility');
      this.route('dashboard-widget', function () {
        this.route('edit', { path: 'edit/:dashboard_widget_id' });
      });
    });

    this.route('features', { resetNamespace: true }, function () {
      this.route('show', { path: '/:category' }, function () {
        this.route('feature', { path: '/:feature_id' }, function () {
          this.route('index', { path: '/' }, function () {
            this.route('deactivate');
            this.route('success');
          });
        });
      });
    });

    this.route('tags', function () {
      this.route('new');
      this.route('delete', { path: 'delete/:tag_id' });
    });
    this.route('candidates', { resetNamespace: true }, function () {
      this.route('segment', { path: '/segment/:segment_id' }, function () {
        this.route('new');
        this.route(
          'candidate',
          { path: 'candidate/:candidate_id' },
          candidateRoutes
        );
        this.route('meetings', meetingRoutes);
      });

      this.route('segment-redirect', { path: '/segment' });
      this.route('candidate-redirect', {
        path: 'departments/candidate/:candidate_id',
      });
      this.route('departments-redirect', { path: 'departments' });

      this.route('new');
      this.route('candidate', { path: ':candidate_id' }, function () {
        this.route('meetings', meetingRoutes);
        this.route('job-offer', jobOfferRoutes);
        this.route(
          'hire-quality',
          { path: 'quality-of-hire' },
          hireQualityRoutes
        );
        this.route('edit', function () {
          this.route('answer', {
            path: 'answer/:answer_id',
          });
        });
      });
    });

    this.route('print', { resetNamespace: true });
    this.route('employees', { resetNamespace: true }, function () {
      this.route('index', { path: '/' }, function () {
        // eslint-disable-next-line ember/no-shadow-route-definition
        this.route('employees-list', { path: '' });
      });
      this.route('new', function () {
        this.route('permissions');
      });
      this.route('bulk');
      this.route('profile', { path: ':user_id' }, function () {
        this.route('edit');
        this.route('settings', function () {
          this.route('permissions');
        });
        this.route('security', function () {
          this.route('activate-2fa');
          this.route('disable-2fa');
        });
        this.route('notifications');
        this.route('meetings');
      });
    });

    this.route('approvals-redirect', {
      path: 'approvals/:approval_id/:type',
      resetNamespace: true,
    });

    this.route('promotions', { resetNamespace: true }, function () {
      this.route('index', { path: '/' });
    });

    this.route('jobs', { resetNamespace: true }, function () {
      this.route('index', { path: '/' }, function () {
        // eslint-disable-next-line ember/no-shadow-route-definition
        this.route('jobs-list', { path: '' }, function () {
          this.route('templates');
        });
      });
      this.route('requisitions', function () {
        this.route('export');
      });
      this.route(
        'requisition',
        { path: 'requisition/:requisition_id' },
        function () {
          this.route('approval-flow', function () {
            this.route('edit');
          });

          this.route('jobs', function () {
            this.route('job-templates');
          });
          this.route('hires');
          this.route('activity');
        }
      );
      this.route('job', { path: ':job_id' }, function () {
        this.route('analytics');
        this.route('approvals', function () {
          this.route('approve', { path: ':approval_id/approve' });
          this.route('reject', { path: ':approval_id/reject' });
        });
        this.route('compare-candidates', function () {
          this.route(
            'candidate',
            { path: 'candidate/:candidate_id' },
            candidateRoutes
          );
        });
        this.route('stages', function () {
          this.route('index', { path: '' }, function () {
            this.route(
              'candidate',
              { path: 'candidate/:candidate_id' },
              candidateRoutes
            );
            this.route(
              'quick-reject-settings',
              { path: ':stage_id/quick-reject-settings' },
              function () {
                this.route('new');
                this.route('edit', {
                  path: ':quick_reject_setting_id/edit',
                });
              }
            );
            this.route('meetings', meetingRoutes);
          });

          this.route('stage-triggers', { path: 'triggers' }, function () {
            this.route('new');
            this.route('edit', { path: ':type/:id/edit' });
          });
        });

        this.route('activity', function () {
          this.route(
            'candidate',
            { path: 'candidate/:candidate_id' },
            candidateRoutes
          );
        });

        this.route('promote', function () {
          this.route('channel', { path: 'channel/:channel_id' }, function () {
            this.route('activate');
            this.route('external');
          });
          this.route(
            'promotion',
            { path: 'promotion/:promotion_id' },
            function () {
              this.route('edit-external');
              this.route('activate');
            }
          );
          this.route('pick', { path: 'pick/:channel_id' });
          this.route('edit', { path: 'edit/:promotion_id' });
        });
        this.route('calendar');
      });
      this.route('edit', { path: ':job_id/edit' }, function () {
        this.route('requisition');
        this.route('template');
        this.route('posting');
        this.route('application-form');
        this.route('stages', function () {
          this.route('triggers', function () {});
          this.route(
            'quick-reject-settings',
            { path: ':stage_id/quick-reject-settings' },
            function () {
              this.route('new');
              this.route('edit', {
                path: ':quick_reject_setting_id/edit',
              });
            }
          );
        });
        this.route('hiring-team');
        this.route('external-recruiters');
        this.route('scorecard');
        this.route('chat-widget');
        this.route('interview-kits');
        this.route('evaluation', function () {
          this.route('interview-kit');
        });
      });
    });

    this.route('content', { resetNamespace: true }, function () {
      this.route('index', { path: '/' }, function () {
        this.route('new', function () {
          this.route('page');
        });

        this.route('page', { path: ':page_id' }, function () {
          this.route('settings', function () {
            this.route('candidate-chat');
            this.route('sharing-image');
            this.route('seo-settings');
            this.route('department-settings');
            this.route('thumbnail-settings');
          });
          this.route('promote', function () {
            this.route('facebook');
            this.route('adwords');
            this.route('snapchat');
          });
        });
      });

      this.route('posts', function () {
        this.route('edit', { path: '/:page_id' });
      });

      this.route('editor', { path: ':type/:type_id' }, function () {
        this.route('index', { path: '/' });
        this.route('global-design');
        this.route('block-library', function () {
          this.route('block-layout', { path: ':layout_name' });
        });

        this.route('header', { path: 'sections/header' });

        this.route('footer', { path: 'sections/footer' }, function () {
          this.route('index', { path: '/' });
        });

        this.route('section', { path: 'sections/:section_id' }, function () {
          this.route('index', { path: '/' });
          this.route('layout');

          this.route('faq');
          this.route('testimonial');
          this.route('text');
          this.route('timeline');
          this.route('list');
          this.route('grid');
          this.route('picture');
          this.route('lead', blockRoutesWithLayout);
          this.route('infographic');
          this.route('video');
          this.route('social-links');
          this.route('numbers');
          this.route('custom');
          this.route('perks');
          this.route('blog', function () {
            this.route('settings');
          });
        });

        this.route('promote', function () {
          this.route('facebook');
          this.route('adwords');
          this.route('snapchat');
        });

        this.route('jobs', function () {
          this.route('layout');
        });

        this.route('jobs-list', function () {
          this.route('layout');
        });
      });
    });

    this.route('analytics', { resetNamespace: true }, function () {
      this.route('custom-reports', function () {
        this.route('snapshots', function () {
          this.route('new');
          this.route('edit', { path: '/:custom_report_id/edit' });
        });

        this.route('historic', function () {
          this.route('new');
          this.route('edit', { path: ':insights_report_id/edit' });
        });
      });

      this.route('jobs', function () {
        this.route('job', { path: ':job_id' });
      });

      this.route('employees', function () {
        this.route('employee', { path: ':employee_id' });
      });

      this.route('overview', { path: '' }, function () {
        this.route('visitors', function () {
          this.route('candidate', { path: 'candidate/:candidate_id' });
        });

        this.route('mobile');

        this.route('sources', function () {
          this.route('candidate', { path: 'candidate/:candidate_id' });
        });

        this.route('promotions', function () {
          this.route('candidate', { path: 'candidate/:candidate_id' });
        });

        this.route('time-to-hire', function () {
          this.route('candidate', { path: 'candidate/:candidate_id' });
        });

        this.route('team-activity', function () {
          this.route(
            'candidate',
            { path: 'candidate/:candidate_id' },
            candidateRoutes
          );
        });

        this.route('pipeline-speed');
        this.route('pipeline-overview');
        this.route('pipeline-conversions');

        // Old routes, only used as redirects for old links
        this.route('speed');
        this.route('conversions');

        this.route('referrals', function () {
          this.route('candidate', { path: 'candidate/:candidate_id' });
        });

        this.route('meetings');

        this.route('partner-results', function () {
          this.route('candidate', { path: 'candidate/:candidate_id' });
        });

        this.route('nps', function () {
          this.route('candidate', { path: 'candidate/:candidate_id' });
        });

        this.route('nps-overview', function () {
          this.route('candidate', { path: 'candidate/:candidate_id' });
        });

        this.route('nps-per-stage');

        this.route('reject-reasons', function () {
          this.route('index', { path: '/' }, function () {
            this.route('candidate', { path: 'candidate/:candidate_id' });
          });
        });

        this.route('job-overview', function () {
          this.route('candidate', { path: 'candidate/:candidate_id' });
        });
      });
    });

    this.route('requisitions', { resetNamespace: true }, function () {
      this.route('new');
    });

    this.route('chats', { resetNamespace: true });
    this.route(
      'chat-channels',
      { resetNamespace: true, path: 'chats/:chat_id' },
      function () {
        this.route('chat-channel', { path: 'channels/:sid' }, function () {
          this.route('candidate', { path: 'candidate/:candidate_id' });
        });
      }
    );

    this.route('employee', { resetNamespace: true }, function () {
      this.route('referral');
      this.route('share-job', function () {
        this.route('index', { path: '/' });
      });
      this.route('dashboard-widget', function () {
        this.route('edit', { path: 'edit/:dashboard_widget_id' });
        this.route('intro-message');
      });
      this.route('highlight-job', { path: 'highlight-job/:job_id' });
    });

    this.route('settings', { resetNamespace: true }, function () {
      this.route(
        'data_privacy_settings',
        { path: '/data-retention' },
        function () {
          this.route('permission_expired_template');
          this.route('permission_extended_template');
        }
      );
      this.route('automatic-deletion');
      this.route('data-requests');
      this.route('consent', { path: 'permissions' }, function () {
        this.route('permission_missing_template');
      });
      this.route('teams', function () {
        this.route('new');
        this.route('edit', {
          path: '/:team_id/edit',
        });
      });
      this.route('connect');
      this.route('apply_with');
      this.route('linkedin_rsc');
      this.route('webhooks', function () {
        this.route('new');
        this.route('edit', { path: ':webhook_id/edit' }, function () {});
        this.route('show', { path: '/:webhook_id' }, function () {
          this.route('keys');
        });
      });
      this.route('partner_hub', function () {
        this.route('new');
        this.route(
          'edit',
          { path: 'edit/:sandbox_activation_id' },
          function () {}
        );
        this.route('show', { path: '/:sandbox_activation_id' }, function () {
          this.route('keys');
        });
      });
      this.route('policy', function () {
        this.route('edit');
      });
      this.route('cookies', function () {
        this.route('new');
        this.route('edit', { path: '/:cookie_detail_id' });
        this.route('cookie-policy');
      });
      this.route('cookie-policy');
      this.route('cookie-consent');
      this.route('general');
      this.route('auto-join');
      this.route('tags', function () {
        this.route('index', { path: '/' }, function () {
          this.route('new');
          this.route('delete', { path: 'delete/:id' });
          this.route('edit', { path: 'edit/:id' });
        });
        this.route('settings');
      });
      this.route('custom-fields', function () {});
      this.route('payments', function () {
        this.route('in-app');
      });
      this.route('ratings');
      this.route('chat');
      this.route('recruiter-copilot');
      this.route('requisitions', function () {
        this.route('form');

        this.route('index', { path: '/' }, function () {
          this.route('new');
          this.route('edit', { path: 'edit/:requisition_id' });
        });
      });

      this.route('requisition_archive_reasons');

      this.route('share-links');
      this.route('sms', function () {
        this.route('get_number');
        this.route('get_credits');
        this.route('renew_credits');
        this.route('update_documents');
      });
      this.route('locations', function () {
        this.route('new');
        this.route('edit', { path: 'edit/:location_id' });
        this.route('regions', function () {
          this.route('edit', { path: 'region/:region_id' });
        });
      });
      this.route('departments', function () {
        this.route('new');
        this.route('edit', { path: 'edit/:department_id' }, function () {});

        this.route('show', { path: '/:department_id' }, function () {
          this.route('roles', function () {
            this.route('new');
            this.route('edit', { path: 'edit/:role_id' });
          });
        });
      });
      this.route('templates', function () {
        this.route('index', { path: '/' });
      });
      this.route('questions', function () {
        this.route('new');
        this.route('edit', { path: 'edit/:question_id' });
      });
      this.route('reject_reasons', function () {
        this.route('new');
        this.route('edit', { path: 'edit/:reject_reason_id' });
      });
      this.route('canned_responses', function () {
        this.route('new');
        this.route('edit', { path: 'edit/:canned_response_id' });
      });
      this.route('todo-templates', function () {
        this.route('new');
        this.route('edit', { path: 'edit/:todo_template_id' });
      });
      this.route('reference-templates', function () {
        this.route('new');
        this.route('edit', { path: 'edit/:reference_template_id' });
      });
      this.route('approval_settings');
      this.route('integrations', function () {
        this.route('slack_apps', function () {
          this.route('edit', { path: 'edit/:slack_app_id' });
        });
        this.route(
          'channel-activation',
          { path: '/channel-activation/:channel_activation_id' },
          function () {
            this.route('activate', { path: '/' });
            this.route('oauth');
            this.route('indeed_always_included');
            this.route('indeed');
            this.route('linkedin_limited_listings');
            this.route('facebook_jobs');
            this.route('custom_form_settings');
          }
        );
        this.route(
          'partner-activation',
          {
            path: 'partner-activation/:partner_activation_id',
          },
          function () {
            this.route('activate', { path: '/' });
            this.route('oauth');
          }
        );
        this.route('marketplace-activations');
        this.route('widgets');
        this.route('api_keys');
        this.route('browser-extension');
      });
      this.route('media-library');
      this.route('group-site');

      this.route('domain');
      this.route('language');

      this.route('facebook');
      this.route('facebook-pixel');
      this.route('google');
      this.route('eeo-reporting');

      this.route('interview-kits', function () {
        this.route('new');
        this.route('edit', { path: '/:interview_kit_id/edit' });
      });

      this.route('skills-traits');

      this.route('nurture');
      this.route('meeting-rooms');
      this.route('enterprise-calendar');
      this.route('external-recruitment', function () {
        this.route('recruiters', function () {
          this.route('new');
          this.route('delete', { path: 'delete/:external_recruiter_id' });
          this.route('edit', { path: 'edit/:external_recruiter_id' });
        });
        this.route(
          'recruiting-firms',
          { path: 'recruiting-agencies' },
          function () {
            this.route('delete', { path: 'delete/:recruiting_firm_id' });
            this.route('edit', { path: 'edit/:recruiting_firm_id' });
          }
        );
      });
      this.route('audit-log', function () {
        this.route('events');
      });
      this.route('user-template');
      this.route('job-offer-templates', function () {
        this.route('new');
        this.route('edit', { path: 'edit/:job_offer_template_id' });
      });

      this.route('job-offer-approval-flows', function () {
        this.route('new');
        this.route('edit', { path: 'edit/:job_offer_approval_flow_id' });
      });

      this.route('security', function () {
        this.route('sso', function () {
          this.route('index', { path: '/' });
          this.route('no-config');
          this.route('new');
          this.route('edit', { path: '/:sso_id/edit' });
          this.route('show', { path: '/:sso_id' }, function () {
            this.route('mappings', function () {
              this.route('new');
              this.route('edit', { path: '/:mapping_id/edit' });
            });
          });
        });
        this.route('email-password', function () {
          this.route('2fa-setup');
          this.route('2fa-deactivate');
        });
      });
    });

    this.route(
      'nurture-campaigns',
      {
        resetNamespace: true,
      },
      function () {
        this.route('new', function () {
          this.route('schedule');
        });

        this.route('edit', { path: '/:nurture_campaign_id/edit' }, function () {
          this.route('schedule');
        });

        this.route(
          'nurture-campaign',
          { path: '/:nurture_campaign_id' },
          function () {
            this.route('recipients', function () {
              this.route(
                'candidate',
                { path: 'candidate/:candidate_id' },
                candidateRoutes
              );
            });
            this.route('schedule');
            this.route('settings');
            this.route('analytics');
          }
        );
      }
    );

    this.route('surveys', { resetNamespace: true }, function () {
      this.route('new');
      this.route('edit', { path: '/:survey_id/edit' });
      this.route('results', { path: '/:survey_id/results' });
    });

    this.route('meetings', { resetNamespace: true }, meetingRoutes);
    this.route('wrap-up', { resetNamespace: true });
    this.route('job-offer', { resetNamespace: true }, function () {
      this.route('show', { path: '/:job_offer_id' });
    });
  });

  this.route('unicorn', { resetNamespace: true }, unicornRoutes);

  this.route('four-oh-four', { path: '*path' });
});
