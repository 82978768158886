import { CandidateModel, ModelKey, UserModel } from 'teamtailor/models';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';
import Store from '@ember-data/store';
import { get } from 'teamtailor/utils/get';
import { CopilotAnswersStatus } from 'teamtailor/models/interview';
import CandidateModalFeedService from 'teamtailor/services/candidate-modal-feed';

export interface Member {
  info?: { candidate?: boolean };
}

export function teardownCandidatePusherChannels(
  connectedChannels: PusherChannel[]
) {
  connectedChannels.forEach((channel) => {
    channel.unsubscribe();
    channel.unbind();
  });

  connectedChannels.clear();
}

// @TODO: Remove this after the old modal is gone.
const shouldPushPayload = (
  code: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  currentUser: UserModel,
  hasFeed: boolean
) => {
  if (hasFeed || !['message', 'sms'].includes(code)) {
    return true;
  }

  return data.messages[0]?.user_id !== parseInt(currentUser.id);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deconstructArgs = (activity: any) => {
  const {
    code,
    candidate_id: candidateId,
    id: activityId,
    job_application_id: jobApplicationId,
  } = activity;

  return {
    code,
    candidateId,
    activityId,
    jobApplicationId,
  };
};

const handleActivities = (
  candidate: CandidateModel,
  store: Store,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  currentUser: UserModel,
  candidateModalFeed?: CandidateModalFeedService
) => {
  if (data.id_to_destroy) {
    const review = store.peekRecord('activity', data.id_to_destroy);

    if (review && candidateModalFeed) {
      review.unloadRecord();
      candidateModalFeed.removeRecord(review, {
        modelKey: 'activity',
        candidate,
      });
    }

    return;
  }

  const { code, candidateId, activityId, jobApplicationId } = deconstructArgs(
    data.activity
  );

  if (code === 'note' && candidateId) {
    Object.assign(data, { note: data.activity });
  }

  if (shouldPushPayload(code, data, currentUser, !!candidateModalFeed)) {
    store.pushPayload(data);
  }

  if (candidateModalFeed) {
    const jobApplication = jobApplicationId
      ? store.peekRecord('job-application', jobApplicationId)
      : null;

    const pushToFeed = (modelKey: ModelKey, modelId: string) => {
      const record = store.peekRecord(modelKey, modelId);

      if (record) {
        candidateModalFeed.addRecord(record, {
          candidate,
          modelKey,
          jobApplication,
        });
      }
    };

    pushToFeed('activity', activityId);

    if (code === 'message') {
      if (data.messages) {
        data.messages.forEach(({ id }: { id: string }) => {
          pushToFeed('message', id);
        });
      }
    }
  }
};

export function subscribeToCandidatePusherChannels(
  connectedChannels: PusherChannel[],
  candidate: CandidateModel,
  pusher: PusherService,
  store: Store,
  toggleOnline: (member: Member, online: boolean) => void,
  currentUser: UserModel,
  jobId: number | null | undefined,
  candidateModalFeed?: CandidateModalFeedService
) {
  candidate.scopedPusherChannels(jobId).forEach((channelName) => {
    pusher.subscribe(channelName).then((channel) => {
      connectedChannels.push(channel);

      channel.bind('new-activity', (data) => {
        handleActivities(
          candidate,
          store,
          data,
          currentUser,
          candidateModalFeed
        );
      });

      channel.bind('update-partner-result', (payload) => {
        store.pushPayload(payload);
      });

      channel.bind('updated-candidate', (data) => {
        if (data.candidate.reload) {
          store.findRecord('candidate', data.candidate.id);
        } else {
          candidate.socketUpdated = data.candidate.socket_updated;
          store.pushPayload(data);
        }
      });

      channel.bind('updated-interview', (data) => {
        store.pushPayload(data);
        const interview = store.peekRecord('interview', data.interview.id);
        if (
          interview &&
          interview.copilotAnswersStatus === CopilotAnswersStatus.AVAILABLE
        ) {
          interview.hasMany('answers').reload();
        }
      });

      channel.bind('updated-activity', (data) => {
        handleActivities(
          candidate,
          store,
          data,
          currentUser,
          candidateModalFeed
        );
      });

      channel.bind('destroyed-activity', (data) => {
        handleActivities(
          candidate,
          store,
          data,
          currentUser,
          candidateModalFeed
        );
      });

      channel.bind('updated-messages', (data) => {
        store.pushPayload(data);
      });

      channel.bind('new-reaction', (data) => {
        store.pushPayload(data);
      });
      channel.bind('meeting-invite-message', async ({ message_id }) => {
        const modelKey = 'message';
        const record = await store.findRecord(modelKey, message_id);

        if (candidateModalFeed) {
          candidateModalFeed.addRecord(record, {
            candidate,
            modelKey,
          });
        }
      });

      channel.bind('created-job-application-trigger', (data) => {
        store.findRecord(
          data.job_application_trigger.trigger_type,
          data.job_application_trigger.trigger_id
        );
        store
          .findRecord(
            'job-application-trigger',
            data.job_application_trigger.id
          )
          .then((jobApplicationTrigger) => {
            get(candidate, 'jobApplicationTriggers').pushObject(
              jobApplicationTrigger
            );
          });
      });

      channel.bind('updated-job-application-trigger', (data) => {
        store.pushPayload(data);
      });

      channel.bind('destroyed-reaction', (id) => {
        const reaction = store.peekRecord('reaction', id);
        if (reaction) {
          store.unloadRecord(reaction);
        }
      });

      if (/^presence-candidate-messages/.test(channelName)) {
        channel.bind('pusher:member_added', (member) =>
          toggleOnline(member, true)
        );

        channel.bind('pusher:member_removed', (member) =>
          toggleOnline(member, false)
        );

        channel.bind('pusher:subscription_succeeded', (members) =>
          members.each((member: Member) => toggleOnline(member, true))
        );
      }
    });
  });
}
