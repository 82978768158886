import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Tag\n  class=\"overflow-hidden\"\n  @color={{scorecard-criterium-color @scorecardPick}}\n  @size={{this.size}}\n  @icon={{if @scorecardPick.scorecardCriterium.addedByCopilot \"sparkles\" null}}\n  ...attributes\n>\n  <span class=\"truncate\" {{did-insert this.handleEllipsis}}>\n    {{@criteriumName}}\n  </span>\n  {{#if this.showTooltip}}\n    <EmberTooltip @text={{@criteriumName}} />\n  {{/if}}\n</Core::Tag>", {"contents":"<Core::Tag\n  class=\"overflow-hidden\"\n  @color={{scorecard-criterium-color @scorecardPick}}\n  @size={{this.size}}\n  @icon={{if @scorecardPick.scorecardCriterium.addedByCopilot \"sparkles\" null}}\n  ...attributes\n>\n  <span class=\"truncate\" {{did-insert this.handleEllipsis}}>\n    {{@criteriumName}}\n  </span>\n  {{#if this.showTooltip}}\n    <EmberTooltip @text={{@criteriumName}} />\n  {{/if}}\n</Core::Tag>","moduleName":"teamtailor/components/scorecard-results/score-tag.hbs","parseOptions":{"srcName":"teamtailor/components/scorecard-results/score-tag.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import ScorecardPickModel from 'teamtailor/models/scorecard-pick';

const DEFAULT_SIZE = 'small';
const ALLOWED_SIZES = [DEFAULT_SIZE, 'medium'] as const;

type ScorecardResultsScoreTagArgs = {
  size?: string;
  scorecardPick?: ScorecardPickModel;
};

export default class ScorecardResultsScoreTag extends Component<ScorecardResultsScoreTagArgs> {
  @tracked showTooltip = false;

  get size() {
    verifyArg(
      this.args.size,
      ALLOWED_SIZES,
      'ScorecardResultsScoreTag @size',
      DEFAULT_SIZE
    );

    return this.args.size;
  }

  @action
  handleEllipsis(e: HTMLSpanElement) {
    this.showTooltip = e.offsetWidth < e.scrollWidth;
  }
}
