import Model, { AsyncHasMany, attr, hasMany } from '@ember-data/model';
import { MonsterJobOccupationModel } from 'teamtailor/models';

export default class MonsterJobCategoryModel extends Model {
  @hasMany('monster-job-occupation')
  declare monsterJobOccupations: AsyncHasMany<MonsterJobOccupationModel>;

  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'monster-job-category': MonsterJobCategoryModel;
  }
}
