import classic from 'ember-classic-decorator';
import Service from '@ember/service';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

@classic
export default class FlipperService extends Service {
  @tracked features = null;

  unknownProperty(key) {
    if (this.features) {
      let feature = get(this.features, key);
      return !!feature;
    } else {
      return false;
    }
  }
}
