import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import AnalyticsService from 'teamtailor/services/analytics';
import FlipperService from 'teamtailor/services/flipper';
import { get } from 'teamtailor/utils/get';

interface QueryParams {
  insights_report_id: string;
}

export default class extends Route {
  @service declare store: Store;
  @service declare analytics: AnalyticsService;
  @service declare flipper: FlipperService;

  async model({ insights_report_id }: QueryParams) {
    const loadCustomFields = get(
      this.flipper,
      'custom_fields_in_historic_reports'
    );

    await this.analytics.initializeCustomReportItems(
      this.analytics.hasEnabledGroupAnalytics,
      loadCustomFields
    );

    const report = await this.store.findRecord(
      'insights-report',
      insights_report_id
    );

    return report;
  }
}
