import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { DepartmentModel } from 'teamtailor/models';

export default class CandidateCounterDepartmentModel extends Model {
  @belongsTo('department') declare department: AsyncBelongsTo<DepartmentModel>;

  @attr('number') declare count: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'candidate-counter/department': CandidateCounterDepartmentModel;
  }
}
