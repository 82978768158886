/* eslint-disable ember/use-ember-get-and-set */
import Service, { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import {
  CandidateModel,
  JobApplicationModel,
  AllModels,
  ModelKey,
} from 'teamtailor/models';
import { schedule } from '@ember/runloop';
import ApplicationInstance from '@ember/application/instance';
import { TrackedMap } from 'tracked-built-ins';

interface KeyIdentifiers {
  modelKey: ModelKey;
  candidate: CandidateModel;
  jobApplication?: JobApplicationModel | null;
}

interface QueryParams extends KeyIdentifiers {
  page: number;
  perPage?: number;
}

type Cache = {
  records: AllModels[];
  timestamp: number;
  hasMore: boolean;
  page: number;
};

const CACHE_EXPIRATION = 5 * 60 * 1000; // 5 minutes
const MAX_RECORDS_IN_CACHE = 750;

const expired = (timestamp: number, now?: number) => {
  now = now || new Date().getTime();
  return timestamp < now - CACHE_EXPIRATION;
};

export const isCacheKey = (key: string) => {
  return /^c[0-9]+\|{1}[a-z]+(\|ja-[0-9]*){1}$/g.test(key);
};

export default class CandidateModalFeedService extends Service {
  @service declare store: Store;

  cache = new TrackedMap<string, Cache>();

  private declare currentKey?: string;
  private declare lastCleanup: number;
  private cacheCount = 0;

  constructor(application: ApplicationInstance) {
    super(application);
    this.lastCleanup = new Date().getTime();
  }

  async fetch(params: QueryParams) {
    const key = this.getCacheKey(params);
    const { page } = params;
    const isLoadingMore = page > 1;
    const cacheHit = this.getResultFromCache(key);

    if (!isLoadingMore && cacheHit) {
      return Object.assign({ isCached: true }, cacheHit);
    }

    const result = await this.store.query(
      params.modelKey,
      this.buildQuery(params)
    );

    const records = result.toArray();
    const hasMore = result.meta.total_pages > result.meta.page;

    const cache: Cache = {
      records,
      page,
      hasMore,
      timestamp: new Date().getTime(),
    };

    if (cacheHit) {
      cacheHit.records.addObjects(records);
      Object.assign(cacheHit, { page, hasMore });
    } else {
      this.cache.set(key, cache);
    }

    this.cacheCount = this.cacheCount + records.length;

    this.maybeScheduleCleanup();

    return Object.assign({ isCached: false }, cache);
  }

  addRecord(record: AllModels, identifiers: KeyIdentifiers) {
    const key = this.getCacheKey(identifiers);
    const cache = this.cache.get(key);

    if (cache) {
      this.addToRecordsIfNotExisting(cache, record);
    }

    if (!identifiers.jobApplication) {
      this.cache.forEach((cache, _key) => {
        if (_key.startsWith(key)) {
          this.addToRecordsIfNotExisting(cache, record);
        }
      });
    }
  }

  addToRecordsIfNotExisting(cache: Cache, record: AllModels) {
    if (cache.records.find((r) => r.id === record.id)) {
      return;
    }

    cache.records.unshiftObject(record);
  }

  removeRecord(record: AllModels, identifiers: KeyIdentifiers) {
    const key = this.getCacheKey(identifiers);
    const cache = this.cache.get(key);

    if (cache) {
      cache.records.removeObject(record);
    }

    if (!identifiers.jobApplication) {
      this.cache.forEach((cache, _key) => {
        if (_key.startsWith(key)) {
          cache.records.removeObject(record);
        }
      });
    }
  }

  unknownProperty(key: string) {
    if (isCacheKey(key)) {
      const cache = this.cache.get(key);
      this.currentKey = key;
      if (cache) {
        return cache.records;
      }
    }
  }

  getCacheKey(identifiers: KeyIdentifiers) {
    const { candidate, jobApplication, modelKey } = identifiers;
    return (
      `c${candidate.id}|` +
      `${modelKey}|` +
      `ja-${jobApplication ? jobApplication.id : ''}`
    );
  }

  private getResultFromCache(key: string) {
    const cache = this.cache.get(key);

    if (cache) {
      if (expired(cache.timestamp)) {
        this.deleteCache(key);
      } else {
        return cache;
      }
    }
  }

  private buildQuery(params: QueryParams) {
    const { candidate, page, jobApplication, perPage } = params;

    const query: Record<string, unknown> = {
      page,
      candidate_id: candidate.id,
    };

    if (perPage) {
      query.per_page = perPage;
    }

    if (jobApplication) {
      query.job_application_id = jobApplication.id;
      query.job_id = jobApplication.jobId;
    }

    return query;
  }

  private maybeScheduleCleanup() {
    if (expired(this.lastCleanup) || this.cacheCount > MAX_RECORDS_IN_CACHE) {
      schedule('afterRender', this, this.deleteCaches);
    }
  }

  private deleteCaches() {
    const now = new Date().getTime();

    this.cache.forEach((cache, key) => {
      if (
        this.cacheCount > MAX_RECORDS_IN_CACHE ||
        expired(cache.timestamp, now)
      ) {
        this.deleteCache(key);
      }
    });

    this.lastCleanup = now;
  }

  private deleteCache(key: string) {
    if (this.currentKey === key) {
      return;
    }

    const recordsCount = this.cache.get(key)!.records.length;
    this.cache.delete(key);
    this.cacheCount = this.cacheCount - recordsCount;
  }
}

declare module '@ember/service' {
  interface Registry {
    'candidate-modal-feed': CandidateModalFeedService;
  }
}
