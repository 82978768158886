export const periodOptions = [
  {
    id: 'dont_clear',
    isDefault: true,
  },
  {
    id: '30_minutes',
    add: 30,
    unit: 'minutes',
  },
  {
    id: '1_hour',
    add: 1,
    unit: 'hours',
  },
  {
    id: '4_hours',
    add: 4,
    unit: 'hours',
  },
  {
    id: 'today',
    endOf: 'day',
  },
  {
    id: 'end_of_this_week',
    day: 5,
    endOf: 'day',
  },
  {
    id: 'end_of_next_week',
    add: 1,
    unit: 'weeks',
    day: 5,
    endOf: 'day',
  },
  {
    id: 'custom',
  },
];
