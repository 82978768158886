import Controller from '@ember/controller';
import {
  FeatureCategory,
  FeatureCategoryType,
  FeatureStatus,
} from 'teamtailor/models/feature';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import FromRoute from 'teamtailor/routes/features';

type MenuOption = {
  category: FeatureCategoryType;
  label: string;
  hidden?: boolean;
};

export default class FeaturesController extends Controller {
  declare model: ModelFrom<FromRoute>;

  private readonly menuOptions: MenuOption[][] = [
    [
      {
        category: 'all',
        label: 'common.all',
      },
      {
        category: FeatureCategory.RECRUITING,
        label: `features.categories.${FeatureCategory.RECRUITING}`,
      },
      {
        category: FeatureCategory.RELATIONS,
        label: `features.categories.${FeatureCategory.RELATIONS}`,
      },
      {
        category: FeatureCategory.SECURITY,
        label: `features.categories.${FeatureCategory.SECURITY}`,
      },
      {
        category: FeatureCategory.ENTERPRISE,
        label: `features.categories.${FeatureCategory.ENTERPRISE}`,
      },
    ],
    [
      {
        category: 'new',
        label: 'features.index.new',
      },
      {
        category: 'upcoming',
        label: 'features.index.coming_soon',
      },
    ],
    [
      {
        category: 'available',
        label: 'common.discover',
      },
      {
        category: 'enabled',
        label: 'common.activated',
      },
    ],
  ];

  get menu(): MenuOption[][] {
    return this.menuOptions.map((group) => {
      return group.map((item) => {
        if (item.category === 'upcoming') {
          item.hidden =
            this.model.filterBy('status', FeatureStatus.UPCOMING).length === 0;
        }

        if (item.category === 'new') {
          item.hidden =
            this.model.filterBy('status', FeatureStatus.RECENT).length === 0;
        }

        return item;
      });
    });
  }

  get category(): typeof FeatureCategory {
    return FeatureCategory;
  }
}

declare module '@ember/controller' {
  interface Registry {
    features: FeaturesController;
  }
}
