import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { get, set, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class RequisitionController extends Controller {
  @service store;
  @service user;
  @service router;
  @service current;
  @service requisitionUpdateService;

  @tracked isModalOpen = false;
  @tracked modalType;

  get approvedSteps() {
    return this.requisition?.steps?.filter((item) => get(item, 'isApproved'));
  }

  get requisition() {
    return this.model;
  }

  get requisitionStatus() {
    const { status, isDraft } = this.requisition;

    switch (status) {
      case 'approved':
        return 'approved';
      case 'rejected':
        return 'rejected';
      case 'pending':
        if (!isDraft) {
          return 'awaiting_approval';
        }

        break;
      default:
        return null;
    }
  }

  get needsYourApproval() {
    if (
      this.requisition.isDraft ||
      this.requisition.archivedAt ||
      this.requisition.isRejected ||
      this.requisitionUpdateService.isEditing
    ) {
      return false;
    }

    const currentStep = get(this.requisition, 'currentStep');

    if (!currentStep) {
      return undefined;
    }

    return currentStep.pendingVerdicts
      .map((item) => get(item, 'userId'))
      .includes(get(this.user, 'id'));
  }

  findUserVerdict() {
    const step = get(this.requisition, 'currentStep');

    return get(step, 'requisitionStepVerdicts').find((verdict) => {
      return get(verdict.user, 'id') === get(this.current.user, 'id');
    });
  }

  @action
  showModal(type) {
    set(this, 'isModalOpen', !!type);
    set(this, 'modalType', type);
  }

  @action
  async onProcessApproval(comment, isApproved) {
    const verdict = this.findUserVerdict();
    set(verdict, 'isApproved', isApproved);

    this.store.createRecord('requisition-step-verdict-comment', {
      comment,
      requisitionStepVerdict: verdict,
    });

    await verdict.save();
    await this.requisition.reload();
    this.showModal(false);
  }
}
