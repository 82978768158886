import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"max-w-[446px]\" {{did-insert this.delayRender}}>\n  {{#if this.shouldInsert}}\n    <div class=\"overflow-hidden rounded bg-base-grey-9 drop-shadow\">\n      <iframe\n        title={{t \"features.feature.more_about_feature\"}}\n        class=\"aspect-video w-full\"\n        allowfullscreen=\"allowfullscreen\"\n        mozallowfullscreen=\"mozallowfullscreen\"\n        msallowfullscreen=\"msallowfullscreen\"\n        oallowfullscreen=\"oallowfullscreen\"\n        webkitallowfullscreen=\"webkitallowfullscreen\"\n        src={{@feature.moreLink}}\n        controls=\"0\"\n        rel=\"0\"\n        loading=\"lazy\"\n        local-class=\"iframe\"\n      ></iframe>\n    </div>\n  {{/if}}\n</div>", {"contents":"<div class=\"max-w-[446px]\" {{did-insert this.delayRender}}>\n  {{#if this.shouldInsert}}\n    <div class=\"overflow-hidden rounded bg-base-grey-9 drop-shadow\">\n      <iframe\n        title={{t \"features.feature.more_about_feature\"}}\n        class=\"aspect-video w-full\"\n        allowfullscreen=\"allowfullscreen\"\n        mozallowfullscreen=\"mozallowfullscreen\"\n        msallowfullscreen=\"msallowfullscreen\"\n        oallowfullscreen=\"oallowfullscreen\"\n        webkitallowfullscreen=\"webkitallowfullscreen\"\n        src={{@feature.moreLink}}\n        controls=\"0\"\n        rel=\"0\"\n        loading=\"lazy\"\n        local-class=\"iframe\"\n      ></iframe>\n    </div>\n  {{/if}}\n</div>","moduleName":"teamtailor/components/features/youtube-iframe.hbs","parseOptions":{"srcName":"teamtailor/components/features/youtube-iframe.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class FeatureYoutubeIframe extends Component {
  @tracked shouldInsert = false;

  @action
  delayRender(): void {
    setTimeout(() => {
      this.shouldInsert = true;
    }, 250);
  }
}
