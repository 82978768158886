import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { get, set, setProperties } from '@ember/object';
import { isPresent } from '@ember/utils';
import { base64ToJson } from 'teamtailor/utils/base-64';
import { deserialize } from 'teamtailor/components/fancy-filters/serializable-filter';

export default class AnalyticsJobsRoute extends Route {
  @service current;
  @service intercom;
  @service ttAlert;
  @service analytics;
  @service intl;
  @service flipper;
  @service permissions;
  @service user;
  @service router;
  @service store;

  queryParams = {
    departmentId: { refreshModel: true },
    locationId: { refreshModel: true },
    page: { refreshModel: true },
    filters: { refreshModel: true, as: 'q' },
  };

  async model({
    jobId,
    departmentId,
    locationId,
    page,
    filters: filtersParam,
  }) {
    const analytics = get(this, 'analytics');

    if (isPresent(filtersParam)) {
      const filters = await RSVP.all(
        base64ToJson(filtersParam).map((row) => deserialize(this.store, row))
      );

      set(analytics, 'filters', filters);
    }

    setProperties(analytics, {
      jobId,
      departmentId,
      locationId,
      page,
    });

    if (jobId) {
      return this.store.findRecord('job', jobId);
    } else {
      return null;
    }
  }

  async afterModel(model, transition) {
    let user = get(this, 'user');
    let promises = [user, get(this.current.company, 'partnerActivations')];
    if (model) {
      promises.pushObjects([
        get(model, 'jobDetail'),
        user,
        get(model, 'user'),
        get(model, 'teamMembers'),
      ]);
    }

    await this.analytics.fetchMatchingJobs.perform();

    return RSVP.all(promises).then(() => {
      const isJobCreator = model && get(model, 'user.id') === get(user, 'id');
      const isTeamMember =
        model &&
        get(model, 'teamMembers').mapBy('id').includes(get(user, 'id'));
      const hasAccess = get(this, 'permissions').has('analytics/access');

      if (!isJobCreator && !isTeamMember && !hasAccess) {
        get(this, 'ttAlert').error(
          this.intl.t('reports.dont_have_access_to_analytics')
        );
        transition.abort();
      }
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    get(this, 'intercom').trackEvent('viewed-analytics');
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      set(controller, 'page', 1);

      setProperties(this.analytics, {
        jobId: null,
        departmentId: null,
        locationId: null,
        page: 1,
      });
    }
  }
}
