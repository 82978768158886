import Controller from '@ember/controller';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SettingsExternalRecruitmentRecruitingFirmEditController extends Controller {
  @service flashMessages;
  @service intl;
  @service router;

  @action
  handleModalClose() {
    this.router.transitionTo('settings.external-recruitment.recruiting-firms');
  }

  @action
  async handleSave(model) {
    await model.validate();
    if (get(model, 'isValid')) {
      await model.save();
      this.router.transitionTo(
        'settings.external-recruitment.recruiting-firms'
      );
    } else {
      throw new Error('Validation failed');
    }
  }
}
