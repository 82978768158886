import Model, {
  attr,
  hasMany,
  type AsyncHasMany,
  type SyncHasMany,
} from '@ember-data/model';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import IntlService from 'ember-intl/services/intl';
import {
  NurtureCampaignRecipientModel,
  NurtureCampaignStepEmailModel,
  NurtureCampaignStepWaitModel,
  PushPayloadArg,
  SegmentModel,
} from 'teamtailor/models';
import Server from 'teamtailor/services/server';
import { get } from 'teamtailor/utils/get';

const AN_HOUR_IN_MILLISECONDS = 3600 * 1000;

export default class NurtureCampaignModel extends Model {
  @service declare intl: IntlService;
  @service declare server: Server;

  @hasMany('segment') declare segments: AsyncHasMany<SegmentModel>;
  @hasMany('nurture-campaign-step/wait', {
    async: false,
    inverse: 'nurtureCampaign',
  })
  declare waitSteps: SyncHasMany<NurtureCampaignStepWaitModel>;

  @hasMany('nurture-campaign-step/email', {
    async: false,
    inverse: 'nurtureCampaign',
  })
  declare emailSteps: SyncHasMany<NurtureCampaignStepEmailModel>;

  @hasMany('nurture-campaign-recipient', {
    async: false,
  })
  declare nurtureCampaignRecipients: SyncHasMany<NurtureCampaignRecipientModel>;

  @attr('boolean', { defaultValue: false }) declare exitOnApply: boolean;
  @attr('boolean', { defaultValue: false }) declare exitOnConnect: boolean;
  @attr('boolean', { defaultValue: false }) declare exitOnReply: boolean;
  @attr('date') activatedAt?: Date | null;
  @attr('date') declare createdAt: Date;
  @attr('number', { defaultValue: 0 })
  declare nurtureCampaignRecipientsCount: number;

  @attr('string') declare name: string;
  @attr('string', { defaultValue: 'manual' }) declare campaignType: string;
  @attr('string', { defaultValue: 'temp' }) declare status: string;
  @attr('number', { defaultValue: 0 }) declare activeRecipientsCount: number;
  @attr('number', { defaultValue: 0 }) declare completedRecipientsCount: number;
  @attr('number', { defaultValue: 0 }) declare exitedRecipientsCount: number;
  @attr('number', { defaultValue: 0 }) declare hiresCount: number;
  @attr('number', { defaultValue: 0 }) declare openRate: number;

  get neverActivated() {
    return isEmpty(this.activatedAt);
  }

  get isActive() {
    return this.status === 'active';
  }

  get isDraft() {
    return this.status === 'draft';
  }

  get isPaused() {
    return this.status === 'paused';
  }

  get isTemp() {
    return this.status === 'temp';
  }

  get isArchived() {
    return this.status === 'archived';
  }

  get isPausedOrActive() {
    return this.isPaused || this.isActive;
  }

  get isEditable() {
    return this.isDraft || this.isTemp;
  }

  get notEditable() {
    return !this.isEditable;
  }

  get nurtureCampaignSteps() {
    return [
      ...get(this, 'waitSteps').toArray(),
      ...get(this, 'emailSteps').toArray(),
    ];
  }

  get sortedNurtureCampaignSteps() {
    return get(this, 'nurtureCampaignSteps').sortBy('rowOrder');
  }

  get waitStepDays() {
    return get(this, 'waitSteps').mapBy('waitDays');
  }

  get durationDays() {
    return get(this, 'waitStepDays').reduce((a, b) => a + b, 0);
  }

  get immediate() {
    return get(this, 'durationDays') <= 0;
  }

  get displayName() {
    return this.name || this.intl.t('models.nurture_campaign.draft');
  }

  get missingLoadedRecipients() {
    return (
      get(this, 'activeRecipientsCount') >
      get(this, 'nurtureCampaignRecipientsCount')
    );
  }

  get statusOptions() {
    const options = [];

    if (this.status === 'archived') {
      options.push({
        action: 'delete',
        theme: 'danger',
        title: this.intl.t('common.delete'),
      });
      options.push({
        action: 'activate',
        title: this.intl.t('models.nurture_campaign.unarchive'),
      });
    } else {
      if (this.status !== 'draft') {
        options.push({
          action: 'archive',
          theme: 'danger',
          title: this.intl.t('models.nurture_campaign.archive'),
        });
      }

      if (this.status === 'active') {
        options.push({
          action: 'pause',
          theme: 'pause',
          title: this.intl.t('models.nurture_campaign.pause'),
        });
      } else {
        options.push({
          action: 'activate',
          theme: 'activate',
          title: this.intl.t('models.nurture_campaign.activate'),
        });
      }
    }

    return options;
  }

  get hasUnorderedNurtureCampaignStepTimes() {
    return this.groupedEmailSteps.some((stepTimes) => {
      return this.groupHasUnorderedTimes(stepTimes);
    });
  }

  get hasSimilarNurtureCampaignStepTimes() {
    return this.groupedEmailSteps.some((stepTimes) => {
      return this.groupHasSimilarTimes(stepTimes);
    });
  }

  get groupedEmailSteps(): number[][] {
    let currentGroup: number[] = [];
    const groups: number[][] = [];

    const possiblePushToGroups = (group?: number[]) => {
      if (group) {
        groups.push(group);
      }
    };

    const sortedSteps = get(this, 'sortedNurtureCampaignSteps');

    sortedSteps.forEach((step) => {
      if (step.stepType === 'wait') {
        possiblePushToGroups(currentGroup);
        currentGroup = [];
      } else if (
        !get(step, 'isNew') &&
        step instanceof NurtureCampaignStepEmailModel
      ) {
        currentGroup.push(new Date(step.sendAt).getTime());
      }
    });

    possiblePushToGroups(currentGroup);

    return groups;
  }

  groupHasSimilarTimes(stepTimes: number[]) {
    const times = stepTimes.toArray().sort(function (a, b) {
      return a - b;
    });

    for (let i = 1; i < times.length; i++) {
      const time = times[i] ?? 0;
      const previousTime = times[i - 1] ?? 0;

      if (Math.abs(time - previousTime) < AN_HOUR_IN_MILLISECONDS) {
        return true;
      }
    }

    return false;
  }

  groupHasUnorderedTimes(stepTimes: unknown[]) {
    for (let i = 1; i < stepTimes.length; i++) {
      const time = stepTimes[i] ?? 0;
      const previousTime = stepTimes[i - 1] ?? 0;

      if (time <= previousTime) {
        return true;
      }
    }

    return false;
  }

  async saveAsDraft() {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'save_as_draft',
    });
    return this.store.pushPayload(response);
  }

  async activate() {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'activate',
    });
    return this.store.pushPayload(response);
  }

  async resume() {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'resume',
    });
    return this.store.pushPayload(response);
  }

  async pause() {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'pause',
    });
    return this.store.pushPayload(response);
  }

  async sendTest(emails: string[]) {
    return this.server.memberAction(this, {
      action: 'send_test',
      method: 'POST',
      options: {
        data: JSON.stringify({ emails }),
      },
    });
  }

  async archive() {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'archive',
    });
    return this.store.pushPayload(response);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'nurture-campaign': NurtureCampaignModel;
  }
}
