import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import { BookedSlotClass } from '.';
import {
  IMeetingEventDateRange,
  ISelfSchedule,
  SlotRules,
} from 'teamtailor/components/meeting/types';

export class SelfScheduleClass {
  @tracked declare duration: number;
  @tracked declare bufferBefore: number;
  @tracked declare bufferAfter: number;
  @tracked declare slots?: BookedSlotClass[];
  @tracked declare slotRules?: SlotRules;
  @tracked declare startInterval: number;
  @tracked declare required?: number | 'all';
  @tracked private declare _event: Partial<ISelfSchedule> | undefined;

  constructor(event?: Partial<ISelfSchedule>) {
    this.duration = event?.duration ?? 60;
    this.bufferBefore = event?.bufferBefore ?? 0;
    this.bufferAfter = event?.bufferAfter ?? 0;
    this.startInterval = event?.startInterval ?? 30;
    this.slots =
      event?.slots?.map((s: IMeetingEventDateRange) =>
        BookedSlotClass.from(s)
      ) ?? [];
    this.slotRules = event?.slotRules;
    this.required = event?.required;
    this._event = event;
  }

  get validSlots(): BookedSlotClass[] {
    return this.slots?.filter((slot) => slot.isValid() && !slot.isPast()) ?? [];
  }

  static from(event: ISelfSchedule): SelfScheduleClass {
    return new SelfScheduleClass(event);
  }

  static createWithDefaultSlotRules() {
    return new SelfScheduleClass({
      slotRules: {
        dailyTimes: [{ from: '08:00', to: '17:00' }],
        weekdays: [0, 1, 2, 3, 4, 5, 6],
        dateRanges: [
          {
            from: moment().add(1, 'days').startOf('day').format('YYYY-MM-DD'),
            to: moment().add(15, 'days').startOf('day').format('YYYY-MM-DD'),
          },
        ],
      },
    });
  }

  hasChanges(): boolean {
    return (
      this.duration !== (this._event?.duration ?? 60) ||
      this.bufferBefore !== (this._event?.bufferBefore ?? 0) ||
      this.bufferAfter !== (this._event?.bufferAfter ?? 0) ||
      this.startInterval !== (this._event?.startInterval ?? 30) ||
      this.slots?.length !== (this._event?.slots?.length ?? 0) ||
      this.slotRules !== this._event?.slotRules ||
      this.required !== this._event?.required
    );
  }

  toAttributes(): ISelfSchedule {
    return {
      duration: this.duration,
      bufferBefore: this.bufferBefore,
      bufferAfter: this.bufferAfter,
      startInterval: this.startInterval,
      required: this.required,
      slots: this.slots?.map((s) =>
        s.toAttributes()
      ) as IMeetingEventDateRange[],

      slotRules: this.slotRules
        ? {
            dailyTimes: this.slotRules.dailyTimes?.map((dailyTime) => ({
              from: dailyTime.from,
              to: dailyTime.to,
            })),

            dateRanges:
              this.slotRules.dateRanges?.map((dateRange) => ({
                from: dateRange.from,
                to: dateRange.to,
              })) || [],

            weekdays: this.slotRules.weekdays,
          }
        : undefined,
    };
  }

  slotRulesToPeriods = (tzid = 'UTC') => {
    if (!this.slotRules) {
      return [];
    }

    const { dailyTimes, dateRanges, weekdays } = this.slotRules;

    const periods: { start: Date; end: Date }[] = [];

    moment.locale('en');

    dateRanges?.forEach((dateRange) => {
      let mCurrentDate = moment(dateRange.from);
      const toDate = moment(dateRange.to);

      while (mCurrentDate.isSameOrBefore(toDate)) {
        if (weekdays?.includes(mCurrentDate.weekday())) {
          if (dailyTimes) {
            dailyTimes.forEach((dailyTime) => {
              periods.push({
                start: moment
                  .tz(
                    `${mCurrentDate.format('YYYY-MM-DD')}T${dailyTime.from}`,
                    tzid
                  )
                  .toDate(),

                end: moment
                  .tz(
                    `${mCurrentDate.format('YYYY-MM-DD')}T${dailyTime.to}`,
                    tzid
                  )
                  .toDate(),
              });
            });
          } else {
            periods.push({
              start: moment(`${mCurrentDate.format('YYYY-MM-DD')}T00:00`)
                .tz(tzid)
                .toDate(),

              end: moment(`${mCurrentDate.format('YYYY-MM-DD')}T24:00`)
                .tz(tzid)
                .toDate(),
            });
          }
        }

        mCurrentDate = mCurrentDate.add(1, 'day');
      }
    });

    return periods;
  };
}
