import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Atoms::Card\n  ...attributes\n  local-class=\"root\"\n  @loading={{@loading}}\n  @onShowExportModal={{@onShowExportModal}}\n  {{hover-state (fn (mut this.hovering))}}\n>\n  <div class=\"mb-20 flex justify-between\">\n    <div>\n      <Insights::Charts::Heading @variant=\"regular\" @text={{@headingText}}>\n        <InfoTooltip\n          @text={{t \"insights.molecules.pipeline_speed.title_tooltip\"}}\n        />\n      </Insights::Charts::Heading>\n    </div>\n\n    <Insights::Atoms::ExpandModalButton\n      @onShowExportModal={{@onShowExportModal}}\n      @visible={{this.hovering}}\n    />\n  </div>\n\n  <div class=\"mb-20\" local-class=\"scrollable\">\n    <Insights::Charts::StackedBarChart\n      @categories={{this.chartCategories}}\n      @data={{this.chartData}}\n      @plotLines={{this.plotLines}}\n    />\n  </div>\n</Insights::Atoms::Card>", {"contents":"<Insights::Atoms::Card\n  ...attributes\n  local-class=\"root\"\n  @loading={{@loading}}\n  @onShowExportModal={{@onShowExportModal}}\n  {{hover-state (fn (mut this.hovering))}}\n>\n  <div class=\"mb-20 flex justify-between\">\n    <div>\n      <Insights::Charts::Heading @variant=\"regular\" @text={{@headingText}}>\n        <InfoTooltip\n          @text={{t \"insights.molecules.pipeline_speed.title_tooltip\"}}\n        />\n      </Insights::Charts::Heading>\n    </div>\n\n    <Insights::Atoms::ExpandModalButton\n      @onShowExportModal={{@onShowExportModal}}\n      @visible={{this.hovering}}\n    />\n  </div>\n\n  <div class=\"mb-20\" local-class=\"scrollable\">\n    <Insights::Charts::StackedBarChart\n      @categories={{this.chartCategories}}\n      @data={{this.chartData}}\n      @plotLines={{this.plotLines}}\n    />\n  </div>\n</Insights::Atoms::Card>","moduleName":"teamtailor/components/insights/molecules/pipeline-speed.hbs","parseOptions":{"srcName":"teamtailor/components/insights/molecules/pipeline-speed.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { plotLineFontOptions } from 'teamtailor/components/insights/charts/font-options';

export default class InsightsMoleculesPipelineSpeed extends Component {
  @service intl;

  get chartCategories() {
    return [this.intl.t('insights.molecules.pipeline_speed.speed')];
  }

  get plotLines() {
    const { companyAverageDays, jobAverageDays } = this.args;

    const tooClose = Math.abs(jobAverageDays - companyAverageDays) < 4;

    return [
      {
        tooClose,
        text: this.intl.t(
          'insights.molecules.pipeline_speed.average_for_current_job'
        ),

        textColor: plotLineFontOptions.color,
        color: plotLineFontOptions.color,
        fontOptions: plotLineFontOptions,
        value: jobAverageDays,
      },

      {
        tooClose,
        text: this.intl.t(
          'insights.molecules.pipeline_speed.average_for_company'
        ),

        textColor: plotLineFontOptions.color,
        color: '#9013FE',
        fontOptions: plotLineFontOptions,
        value: companyAverageDays,
      },
    ];
  }

  get chartData() {
    return this.args.stagesAndTimes.sortBy('index').map((stageAndTime) => {
      return { name: stageAndTime.name, value: [Number(stageAndTime.value)] };
    });
  }
}
