import Service from '@ember/service';
import Bowser from 'bowser';

export default class BowserService extends Service.extend(
  Bowser.getParser(navigator.userAgent).getResult()
) {
  // normal class body definition here

  get isSafari(): boolean {
    return this.browser.name === 'Safari';
  }

  get isFirefox(): boolean {
    return this.browser.name === 'Firefox';
  }

  get isIos(): boolean {
    return this.os.name === 'iOS';
  }

  get isAndroid(): boolean {
    return this.os.name === 'Android';
  }
}

declare module '@ember/service' {
  interface Registry {
    bowser: BowserService;
  }
}
