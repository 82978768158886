import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

const INSTRUCTIONS_LINK = 'https://support.google.com/analytics/answer/9539598';

export default class GoogleController extends Controller {
  @service current;

  get company() {
    return this.current.company;
  }

  get whereToFindTrackingIdLink() {
    return INSTRUCTIONS_LINK;
  }

  @action
  handleSave() {
    return this.company.save();
  }
}
