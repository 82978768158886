import Model, {
  belongsTo,
  hasMany,
  attr,
  type AsyncBelongsTo,
  type AsyncHasMany,
  type SyncHasMany,
} from '@ember-data/model';
import {
  CandidateModel,
  JobModel,
  ReactionModel,
  UploadModel,
  UserModel,
} from 'teamtailor/models';
import Raw from 'teamtailor/transforms/raw';
import { get } from 'teamtailor/utils/get';

export default class NoteModel extends Model {
  @belongsTo('candidate') declare candidate: AsyncBelongsTo<CandidateModel>;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;
  @belongsTo('upload') declare upload: AsyncBelongsTo<UploadModel>;
  @belongsTo('job') declare job: AsyncBelongsTo<JobModel>;

  @hasMany('reaction', { async: false })
  declare reactions: SyncHasMany<ReactionModel>;

  @hasMany('user') declare privateNoteUsers: AsyncHasMany<UserModel>;

  @attr('array') declare mentionedUsernames: string[];
  @attr('boolean') declare createdByTrigger: boolean;
  @attr('boolean') declare privateNote: boolean;
  @attr('boolean', { defaultValue: false }) declare pinned: boolean;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('raw') declare actionData: Raw<{ note: string }>;
  @attr('raw') declare mentionedUserIds: Raw;
  @attr('string') declare code: string;
  @attr('string') declare commentAuthor: string;

  get note() {
    return get(this.actionData, 'note');
  }

  get isNote() {
    return true;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    note: NoteModel;
  }
}
