import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class PartnerControllerIndex extends Controller {
  @service router;

  @action
  handleClose() {
    this.router.transitionTo('marketplace');
  }
}
