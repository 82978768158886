import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class StageTriggersRoute extends Route {
  @service store;
  @service current;

  model() {
    let job = this.modelFor('jobs.job');

    return RSVP.hash({
      stages: get(job, 'stages'),
      triggers: get(job, 'triggers'),
      partnerActivations: get(this.current.company, 'partnerActivations'),
      job,
    });
  }
}
