import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';

export default class ShortLocale extends Helper {
  @service timeFormat;

  compute() {
    return this.timeFormat.shortLocale;
  }
}
