import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import SettingsRequisitionsIndexEditRoute from 'teamtailor/routes/settings/requisitions/index/edit';
import Current from 'teamtailor/services/current';
import TtAlertService from 'teamtailor/services/tt-alert';
import { get } from 'teamtailor/utils/get';
import { ModelFrom } from 'teamtailor/utils/type-utils';

export default class SettingsRequisitionsIndexEditController extends Controller {
  declare model: ModelFrom<SettingsRequisitionsIndexEditRoute>;

  @service declare router: RouterService;
  @service declare current: Current;
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;

  get company() {
    return this.current.company;
  }

  @action
  handleDestroy() {
    this.model.deleteRecord();

    this.ttAlert.confirm(
      this.intl.t('settings.requisitions.confirmation_message', {
        title: this.model.title,
      }),
      async () => {
        try {
          await this.model.save();
          this.router.replaceWith('settings.requisitions');
        } catch {
          this.ttAlert.error(this.intl.t('common.something_went_wrong'));
          this.model.rollbackAttributes();
        }
      },
      () => {
        this.model.rollbackAttributes();
      },
      {
        title: this.intl.t('settings.requisitions.are_you_sure'),
        confirmButtonText: this.intl.t('settings.requisitions.delete_flow'),
      }
    );
  }

  @action
  handleExiting() {
    this.router.transitionTo('settings.requisitions.index');
  }

  @action
  async handleSave() {
    const steps = await get(this.model, 'steps');

    steps.forEach((step) => {
      const tempUsers = get(step, 'users');
      set(step, 'users', step.pickedUsers);
      set(step, 'pickedUsers', tempUsers.toArray()); // Keep for potential rollback. Will be resetted in the route
    });

    try {
      await this.model.save();
      this.handleExiting();
    } catch {
      steps.forEach((step) => {
        set(step, 'users', get(step, 'pickedUsers'));
        set(step, 'pickedUsers', get(step, 'users').toArray());
      });
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.requisitions.index.edit': SettingsRequisitionsIndexEditController;
  }
}
