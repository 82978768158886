/**
 * Helper to allow for boolean arguments that match html boolean attributes behaviour,
 * see: https://medium.com/@noslouch/react-boolean-attributes-in-ember-glimmer-components-d8993d1c8ee9
 *
 * Examples where @foo resolves to
 *   true:
 *     <Comp @foo />
 *     <Comp @foo={{true}} />
 *     <Comp @foo="true" />
 *     <Comp @foo="" /> <-- The least intuitive one! But is required since valueless args resolve to "" 🤷‍♂️
 *
 *   false:
 *     <Comp />
 *     <Comp @foo={{false}} />
 *     <Comp @foo="false" />
 **/

export const boolArg = (arg) => {
  return (arg && arg !== 'false') || arg === '';
};
