import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';

export default class BaseComponent extends Component {
  get activity() {
    return get(this.args, 'activity');
  }

  get anonymous() {
    return get(this.args, 'anonymous');
  }

  get job() {
    return get(this.args, 'job');
  }

  get isProfile() {
    return get(this.args, 'isProfile');
  }

  get candidateDisplayName() {
    const nameOrEmail = get(this, 'activity.candidate.nameOrEmail');

    let isAnonymous = get(this, 'anonymous');

    if (get(this, 'job.id')) {
      const jobApplication = get(
        get(get(this, 'activity'), 'candidate'),
        'jobApplications'
      )?.findBy('jobId', get(this, 'job.id'));

      if (jobApplication) {
        isAnonymous = get(jobApplication, 'stage.anonymous');
      }
    }

    return isAnonymous
      ? get(this, 'activity.candidate.anonymousName')
      : nameOrEmail;
  }
}
