import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"group flex flex-row flex-wrap gap-8\">\n  {{#each-in (group-by \"emoji\" this.sortedReactions) as |emoji reactions|}}\n    <ReactionsListItem\n      @emoji={{emoji}}\n      @reactions={{reactions}}\n      @onToggleReaction={{this.handleToggleReaction}}\n    />\n  {{/each-in}}\n\n  {{#if @pickerModal}}\n    <button\n      {{emoji-picker onSelect=this.handleToggleReaction modal=@pickerModal}}\n      type=\"button\"\n      class=\"inline-flex min-h-[24px] items-center self-center rounded-[30px] px-6 opacity-0 group-hover:opacity-100\"\n    >\n      <Icon\n        @icon=\"face-smile-plus\"\n        @tooltip={{t \"components.reactions_list.add_reaction\"}}\n        class=\"text-icon-neutral-weak hover:text-icon-neutral\"\n      />\n    </button>\n  {{/if}}\n</div>", {"contents":"<div class=\"group flex flex-row flex-wrap gap-8\">\n  {{#each-in (group-by \"emoji\" this.sortedReactions) as |emoji reactions|}}\n    <ReactionsListItem\n      @emoji={{emoji}}\n      @reactions={{reactions}}\n      @onToggleReaction={{this.handleToggleReaction}}\n    />\n  {{/each-in}}\n\n  {{#if @pickerModal}}\n    <button\n      {{emoji-picker onSelect=this.handleToggleReaction modal=@pickerModal}}\n      type=\"button\"\n      class=\"inline-flex min-h-[24px] items-center self-center rounded-[30px] px-6 opacity-0 group-hover:opacity-100\"\n    >\n      <Icon\n        @icon=\"face-smile-plus\"\n        @tooltip={{t \"components.reactions_list.add_reaction\"}}\n        class=\"text-icon-neutral-weak hover:text-icon-neutral\"\n      />\n    </button>\n  {{/if}}\n</div>","moduleName":"teamtailor/components/reactions-list.hbs","parseOptions":{"srcName":"teamtailor/components/reactions-list.hbs"}});
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import ActivityModel from 'teamtailor/models/activity';
import NoteModel from 'teamtailor/models/note';
import { action } from '@ember/object';
import toggleReaction from 'teamtailor/utils/toggle-reaction';
import Current from 'teamtailor/services/current';
import { inject as service } from '@ember/service';

interface ReactionsListComponentArgs {
  model: NoteModel | ActivityModel;
}

export default class ReactionsListComponent extends Component<ReactionsListComponentArgs> {
  @service declare current: Current;

  get reactions() {
    return get(this.args.model, 'reactions');
  }

  get sortedReactions() {
    return this.reactions.sortBy('createdAt');
  }

  get user() {
    return this.current.user;
  }

  @action
  handleToggleReaction(emoji: string) {
    toggleReaction(this.args.model, this.user, emoji);
  }
}
