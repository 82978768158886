import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Modal @isOpen={{@isOpen}} @onClose={{@onClose}} @size=\"small\" as |modal|>\n  <modal.Header @text={{t this.title}} />\n  <modal.Main as |main|>\n    <main.Section class=\"gap-8\">\n      <span\n        class={{cn \"body-text-s-medium flex items-center gap-4 self-start\"}}\n      >{{t this.commentLabel}}</span>\n      <Core::Textarea\n        @value={{this.comment}}\n        placeholder={{t \"requisitions.optional\"}}\n        aria-label={{t this.commentLabel}}\n      />\n    </main.Section>\n  </modal.Main>\n  <modal.Footer>\n    <StatusButton\n      @action={{this.onConfirm}}\n      @text={{t this.confirmButtonText}}\n    />\n  </modal.Footer>\n</Core::Modal>", {"contents":"<Core::Modal @isOpen={{@isOpen}} @onClose={{@onClose}} @size=\"small\" as |modal|>\n  <modal.Header @text={{t this.title}} />\n  <modal.Main as |main|>\n    <main.Section class=\"gap-8\">\n      <span\n        class={{cn \"body-text-s-medium flex items-center gap-4 self-start\"}}\n      >{{t this.commentLabel}}</span>\n      <Core::Textarea\n        @value={{this.comment}}\n        placeholder={{t \"requisitions.optional\"}}\n        aria-label={{t this.commentLabel}}\n      />\n    </main.Section>\n  </modal.Main>\n  <modal.Footer>\n    <StatusButton\n      @action={{this.onConfirm}}\n      @text={{t this.confirmButtonText}}\n    />\n  </modal.Footer>\n</Core::Modal>","moduleName":"teamtailor/components/requisition/approval-modal.hbs","parseOptions":{"srcName":"teamtailor/components/requisition/approval-modal.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

type ApprovalModalArgs = {
  isOpen: boolean;
  onClose: () => void;
  onProcessApproval: (
    comment: string | undefined,
    isApproved: boolean
  ) => Promise<void>;
  type: 'APPROVE' | 'DENY';
};

export default class ApprovalModal extends Component<ApprovalModalArgs> {
  @tracked
  comment: string = '';

  get title() {
    return this.args.type === 'APPROVE'
      ? 'requisitions.approval_modal_title'
      : 'requisitions.denial_modal_title';
  }

  get commentLabel() {
    return this.args.type === 'APPROVE'
      ? 'requisitions.add_comment_for_approval'
      : 'requisitions.why_you_deny';
  }

  get confirmButtonText() {
    if (!this.comment.length) {
      return this.args.type === 'APPROVE'
        ? 'requisitions.approve_without_comment'
        : 'requisitions.deny_without_feedback';
    }

    return this.args.type === 'APPROVE'
      ? 'requisitions.approve_with_comment'
      : 'requisitions.deny_with_feedback';
  }

  resetComment() {
    this.comment = '';
  }

  @action
  async onConfirm() {
    if (this.args.type === 'APPROVE') {
      await this.args.onProcessApproval(this.comment, true);
    } else {
      await this.args.onProcessApproval(this.comment, false);
    }

    this.resetComment();
  }
}
