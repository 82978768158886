import { capitalize, underscore } from '@ember/string';
import BaseProperty, { BasePropertyOptions } from './base-property';
import IntlService from 'ember-intl/services/intl';
import EqualsFilter from '../filters/equals';
import GreaterThanFilter from '../filters/greater-than';
import LessThanFilter from '../filters/less-than';

export interface AggregatedPropertyOptions extends BasePropertyOptions {
  filters?: string;
  customFormat?: (value: any) => any;
  propertyTranslationKey?: string;
  category: string;
}

export default class AggregatedProperty extends BaseProperty {
  filters?: string;
  customFormat?: (value: any) => any;
  _propertyTranslationKey?: string;
  category: string;
  translatedNameForChartName?: string;

  constructor(options: AggregatedPropertyOptions) {
    super({
      ...options,
      id: `${underscore(options.type!)}-${options.id}`,
    } as BasePropertyOptions);
    this.filters = options.filters;
    this.customFormat = options.customFormat;
    this.propertyTranslationKey = options.propertyTranslationKey;
    this.category = options.category;
    this.operators = options.operators || [
      new EqualsFilter(),
      new GreaterThanFilter(),
      new LessThanFilter(),
    ];
  }

  get displayNameForChartName() {
    return this.translatedNameForChartName || this.translatedName;
  }

  get propertyTranslationKey(): string | undefined {
    return this._propertyTranslationKey || this.parentProperty?.translationKey;
  }

  set propertyTranslationKey(value: string | undefined) {
    this._propertyTranslationKey = value;
  }

  translate(intl: IntlService) {
    if (this.parentProperty && this.propertyTranslationKey) {
      this.translatedName = capitalize(
        intl
          .t(this.translationKey, {
            propertyName: intl.t(this.propertyTranslationKey),
          })
          .toLowerCase()
      );
    } else {
      super.translate(intl);
    }

    this.translatedNameForChartName = intl
      .t(`${this.translationKey}_for_chart_name`, {
        propertyName: intl.t(
          this.propertyTranslationKey || this.translationKey
        ),
      })
      .toLowerCase();
  }

  format(value: any) {
    if (this.customFormat) {
      return this.customFormat(value);
    }

    return super.format(value);
  }
}
