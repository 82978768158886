import Helper from '@ember/component/helper';

const units = ['', 'K', 'M', 'B'];
const threshold = 1000;

export function formatCount(number) {
  if (typeof number === 'object' && !!number) {
    number = number[0];
  }

  if (Number.isNaN(Number.parseInt(number, 10))) {
    return '-';
  }

  if (typeof number === 'string') {
    return number;
  }

  let unitIndex = 0;

  if (number > threshold) {
    do {
      number /= threshold;
      ++unitIndex;
    } while (number >= threshold);
  }

  let digits = unitIndex > 0 ? 1 : 0;

  return number && number.toFixed(digits) + units[unitIndex];
}

export default Helper.helper(formatCount);
