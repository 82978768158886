import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';

export default class IndexRoute extends ScrollToTopRoute {
  @service infinity;

  queryParams = {
    query: { refreshModel: true },
    sortColumn: { refreshModel: true },
    sortDirection: { refreshModel: true },
    cannedResponseTagId: { refreshModel: true },
  };

  model({ query, sortColumn, sortDirection, cannedResponseTagId }) {
    return this.infinity.model('canned-response', {
      startingPage: 1,
      perPage: 15,
      query,
      sort_column: sortColumn,
      sort_direction: sortDirection,
      canned_response_tag_id: cannedResponseTagId,
      infinityCache: 36000,
    });
  }
}
