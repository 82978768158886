import { tracked } from '@glimmer/tracking';
import {
  ICustomLocation,
  ICustomLocationBase,
} from 'teamtailor/components/meeting/types';

export type MeetingEventLocationCustomLocationAttributes = {
  id?: string;
  customLocation?: {
    id?: string;
    description: string;
    long?: number;
    lat?: number;
  };
  _destroy?: boolean;
};

export class CustomLocationClass {
  @tracked declare id?: string;
  @tracked declare customLocation: ICustomLocation;

  constructor(location: ICustomLocationBase) {
    this.id = location.id;
    this.customLocation = location.customLocation;
  }

  toAttributes(): MeetingEventLocationCustomLocationAttributes {
    return {
      id: this.id,
      customLocation: {
        id: this.customLocation.id,
        description: this.customLocation.description,
        long: this.customLocation.long,
        lat: this.customLocation.lat,
      },
    };
  }
}
