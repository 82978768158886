import Store from '@ember-data/store';
import DirectUploadService from './../../services/direct-upload.d';
import BaseComponent, {
  BaseComponentArgs,
} from 'teamtailor/components/content-block/base-component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { v1 as uuid } from 'ember-uuid';
import IframePreviewService from 'teamtailor/services/iframe-preview';
import IntlService from 'ember-intl/services/intl';
import TtAlertService from 'teamtailor/services/tt-alert';
import { UploadFile } from 'ember-file-upload';
import { ImageModel, SectionItemModel, VideoModel } from 'teamtailor/models';
import { AsyncBelongsTo } from '@ember-data/model';

export type SectionItemComponentArgs = BaseComponentArgs & {
  sectionItem?: SectionItemModel;
  focused: SectionItemModel;
  preventInstantSave: boolean;
  onSave?: () => void;
  onUploadVideo?: (video: VideoModel) => void;
  onDelete?: () => void;
};

export default class SectionItemComponent extends BaseComponent<SectionItemComponentArgs> {
  @service declare directUpload: DirectUploadService;
  @service declare iframePreview: IframePreviewService;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare ttAlert: TtAlertService;

  @tracked showImageLibrary = false;
  @tracked isOpen = false;

  get sectionItem() {
    return this.args.sectionItem;
  }

  get hasFocus() {
    return this.args.focused === this.sectionItem;
  }

  get itemName() {
    return null;
  }

  get idWithFallback() {
    return this.sectionItem?.id || uuid();
  }

  async reloadSection() {
    const sectionId =
      (await this.args.model)?.id ||
      this.sectionItem?.belongsTo('section').id();
    this.iframePreview.reloadSection(sectionId!);
  }

  uploadToS3 = task(async (file) => {
    const fileUrl = await this.directUpload.s3(file, { model: 'videos' });
    const video = await this.store
      .createRecord('video', { file: fileUrl, transcodeType: 'video_block' })
      .save();

    if (this.args.onUploadVideo) {
      this.args.onUploadVideo(video);
    } else {
      await this.sectionItem?.save();

      const { pickedVideo } = this.sectionItem!;
      if (pickedVideo) {
        await this.store
          .findRecord('picked-video', pickedVideo.id)
          .then(function (pickedVideo) {
            pickedVideo.video = video;
            pickedVideo.save();
          });
      } else {
        await this.store
          .createRecord('picked-video', {
            video,
            sectionItem: this.sectionItem,
          })
          .save();
      }

      this.reloadSection();
    }
  });

  @action
  onResizeComplete() {
    this.isOpen = !this.isOpen;
  }

  @action
  async handleSaveItem() {
    if (!this.args.preventInstantSave) {
      if (this.args.onSave) {
        this.args.onSave();
      } else {
        await this.sectionItem?.save();
        this.reloadSection();
      }
    }
  }

  @action
  handleUpload(file: UploadFile | File) {
    this.uploadToS3.perform(file);
  }

  @tracked uploadLabel?: HTMLElement;

  @action
  handleInsertFileUpload(uploadLabel: HTMLElement) {
    this.uploadLabel = uploadLabel;
    if (this.sectionItem?.isNew) {
      this.uploadLabel.click();
    }
  }

  @action
  handleOpenFileUpload() {
    if (this.uploadLabel) {
      this.uploadLabel.click();
    }
  }

  @action
  handleColorChange(color: string) {
    this.sectionItem!.color = color;
    this.handleSaveItem();
  }

  @action
  handleDelete() {
    this.ttAlert.confirm(
      this.intl.t('content.editor.section.items.delete_confirm'),
      () => {
        this.sectionItem?.destroyRecord().then(() => {
          if (this.args.onDelete) {
            this.args.onDelete();
          } else {
            this.reloadSection();
          }
        });
      },
      () => {},
      {
        confirmButtonText: this.intl.t('common.delete'),
      }
    );
  }

  @action
  async handlePickImage(image: AsyncBelongsTo<ImageModel>) {
    if (this.sectionItem?.isNew) {
      await this.sectionItem.save();
    }

    const pickedImage =
      this.sectionItem?.pickedImage ||
      (await this.store.createRecord('picked-image', {
        sectionItem: this.sectionItem,
      }));

    pickedImage.image = image;
    await pickedImage.save();
    this.reloadSection();
  }
}
