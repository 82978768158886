import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class ShowRoute extends ScrollToTopRoute {
  @service store;

  model(params) {
    return get(this, 'store').findRecord('department', params.department_id);
  }
}
