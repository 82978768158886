import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import AnalyticsService from 'teamtailor/services/analytics';
import IntlService from 'ember-intl/services/intl';
import Settings, {
  ChartTypeInfo,
} from 'teamtailor/utils/insights/reports/settings';
import FilterSettings from 'teamtailor/utils/insights/reports/filter-settings';
import TtAlertService from 'teamtailor/services/tt-alert';
import FlipperService from 'teamtailor/services/flipper';
import CookieConsentService from 'teamtailor/services/cookie-consent';
import SharedReportStoreService from 'teamtailor/services/shared-report-store';

export default class SharedReportsController extends Controller {
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare analytics: AnalyticsService;
  @service declare ttAlert: TtAlertService;
  @service declare flipper: FlipperService;
  @service declare cookieConsent: CookieConsentService;
  @service declare sharedReportStore: SharedReportStoreService;

  queryParams = ['startDate', 'endDate', 'hasEnabledGroupAnalytics'];

  @tracked allCurrentSettings: Settings[] = [];

  @tracked loaded = 0;
  @tracked totalAmount = 0;

  @tracked settings?: string;

  @tracked hasEnabledGroupAnalytics = false;

  @tracked currentFilterSettings?: FilterSettings;

  getCurrentFilterSettings() {
    if (!this.currentFilterSettings) {
      this.currentFilterSettings = new FilterSettings(
        this.analytics.allProperties,
        this.analytics.allFilters
      );
    }

    return this.currentFilterSettings;
  }

  constructor(...args: any[]) {
    super(...args);
    this.cookieConsent.hideCookieConsent = true;
  }

  get startDate() {
    return this.analytics.startDateParam;
  }

  set startDate(date: string | null) {
    this.analytics.setStartDate(date);
  }

  get endDate() {
    return this.analytics.endDateParam;
  }

  set endDate(date: string | null) {
    this.analytics.setEndDate(date);
  }

  get reportName() {
    return (
      this.report.name ||
      this.intl.t('insights.reports.builder.untitled_report')
    );
  }

  get availableChartTypes(): ChartTypeInfo[] {
    return [
      { name: 'bar', icon: 'chart-simple' },
      { name: 'line', icon: 'chart-line' },
      { name: 'pie', icon: 'chart-pie' },
      { name: 'table', icon: 'table' },
    ];
  }

  get report() {
    return this.model;
  }

  @action
  handleUpdatedSettings() {
    const combinedSettings = this.report.deserializeSettings(
      this.analytics.allFilters,
      this.analytics.allProperties,
      this.report.settings
    );

    this.allCurrentSettings = combinedSettings.chartSettings;
    this.currentFilterSettings = combinedSettings.filterSettings;
  }

  @action
  handleUpdatedReportSettings() {
    this.settings = this.report.serializeSettings(
      this.allCurrentSettings,
      this.getCurrentFilterSettings()
    );
  }

  @action
  handleLoaded() {
    this.loaded += 1;
  }

  @action
  insertedVisualization() {
    this.totalAmount += 1;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'shared-reports': SharedReportsController;
  }
}
