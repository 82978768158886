import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class ActivityRoute extends Route {
  @service store;

  model() {
    const requisition = this.modelFor('jobs.requisition');

    return this.store.query('activity', {
      requisition_id: requisition.id,
    });
  }
}
