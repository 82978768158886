export const DEFAULT_SECTION_ORDER = [
  {
    position: 0,
    section: 'candidate-modal-new/main/section/job-match',
    id: 'job-match',
  },
  {
    position: 1,
    section: 'candidate-modal-new/main/section/scorecards',
    id: 'scorecard',
  },
  {
    position: 2,
    section: 'candidate-modal-new/main/section/hire-quality-surveys',
    id: 'hire-quality-surveys',
  },
  {
    position: 3,
    section: 'candidate-modal-new/main/section/nps',
    id: 'nps',
  },
  {
    position: 4,
    section: 'candidate-modal-new/main/section/job-offers',
    id: 'job-offers',
  },
  {
    position: 5,
    section: 'candidate-modal-new/main/section/meetings',
    id: 'meetings',
  },
  {
    position: 6,
    section: 'candidate-modal-new/main/section/interviews',
    id: 'interviews',
  },
  {
    position: 7,
    section: 'candidate-modal-new/main/section/partners',
    id: 'partners',
  },
  {
    position: 8,
    section: 'candidate-modal-new/main/section/referrals',
    id: 'referrals',
  },
  {
    position: 9,
    section: 'candidate-modal-new/main/section/questions',
    id: 'questions',
  },
  {
    position: 10,
    section: 'candidate-modal-new/main/section/resume',
    id: 'resume',
  },
  {
    position: 11,
    section: 'candidate-modal-new/main/section/documents',
    id: 'document',
  },
  {
    position: 12,
    section: 'candidate-modal-new/main/section/recommendations',
    id: 'recommendation',
  },
  {
    position: 13,
    section: 'candidate-modal-new/main/section/cover-letter',
    id: 'cover-letter',
  },
  {
    position: 14,
    section: 'candidate-modal-new/main/section/pitch',
    id: 'pitch',
  },
  {
    position: 15,
    section: 'candidate-modal-new/main/section/surveys',
    id: 'survey',
  },
  {
    position: 16,
    section: 'candidate-modal-new/main/section/linkedin-widget',
    id: 'linkedin-widget',
  },
  {
    position: 17,
    section: 'candidate-modal-new/main/section/linkedin',
    id: 'linkedin',
  },
  {
    position: 18,
    section: 'candidate-modal-new/main/section/facebook',
    id: 'facebook',
  },
];
