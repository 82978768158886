import ApplicationSerializer from './application';
import ConnectSettingModel from 'teamtailor/models/connect-setting';
import { classify } from '@ember/string';

type Snapshot = {
  id: string;
  modelName: string;
  record: ConnectSettingModel;
};

type PickedQuestionAttributes = {
  id?: string;
  owner_id: string;
  owner_type: string;
  _destroy?: boolean;
};
export default class ConnectSettingSerializer extends ApplicationSerializer.extend(
  {
    attrs: {
      pickedQuestions: {
        key: 'picked_questions_attributes',
        serialize: 'records',
      },
    },

    serialize(snapshot: Snapshot, options: object) {
      const json = this._super(snapshot, options);
      if (json.picked_questions_attributes.length) {
        json.picked_questions_attributes = json.picked_questions_attributes.map(
          (pickedQuestionAttributes: PickedQuestionAttributes) => {
            const modelName = classify(snapshot.modelName);
            // This is to prevent sending id when using the default picked questions are used since we need to create new picked questions on connect settings
            if (
              !pickedQuestionAttributes._destroy &&
              pickedQuestionAttributes.owner_type !== modelName
            ) {
              delete pickedQuestionAttributes.id;
            }

            pickedQuestionAttributes.owner_id = snapshot.id;
            pickedQuestionAttributes.owner_type = classify(modelName);
            return pickedQuestionAttributes;
          }
        );
      }

      return json;
    },
  }
) {}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'connect-setting': ConnectSettingSerializer;
  }
}
