import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import PaymentProcessorSettingModel from './payment-processor-setting';
import UserModel from './user';

export default class ChargeModel extends Model {
  @attr('string') declare description: string;
  @attr('string') declare price: string;
  @attr('string') declare currency: string;
  @attr('string') declare download_link: string;
  @attr('date') declare createdAt: string;

  @belongsTo('payment-processor-setting', { async: true })
  declare paymentProcessorSetting: AsyncBelongsTo<PaymentProcessorSettingModel>;

  @belongsTo('user', { async: true })
  declare purchasedBy: AsyncBelongsTo<UserModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    charge: ChargeModel;
  }
}
