import Controller from '@ember/controller';
import ENV from 'teamtailor/config/environment';
import { inject as service } from '@ember/service';

export default class FacebookController extends Controller {
  @service current;

  facebookAppId = ENV.facebook.appId;

  get facebookRedirectUrl() {
    return `https://${ENV.APP_HOST}/companies/${this.current.company.uuid}/settings/facebook`;
  }

  get facebookPageUrl() {
    return `https://www.facebook.com/${this.current.company.facebookPageId}?v=app_${this.facebookAppId}`;
  }
}
