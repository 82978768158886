import Transform from '@ember-data/serializer/transform';

export const REMOVE_ATTRIBUTE = '::remove::carrierwave::';

export default class CarrierWaveTransform extends Transform {
  deserialize(serialized: unknown[] | undefined) {
    return serialized;
  }

  serialize(deserialized: unknown[] | undefined) {
    return !deserialized ? REMOVE_ATTRIBUTE : deserialized;
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    carrierwave: CarrierWaveTransform;
  }
}
