import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { get, set, setProperties } from '@ember/object';
import { DEFAULT_SEARCH_PARAMS } from 'teamtailor/controllers/candidates/segment';
import * as deepmerge from 'deepmerge';
import { hash } from 'rsvp';
import { bind } from '@ember/runloop';
import { jsonToBase64, base64ToJson } from 'teamtailor/utils/base-64';
import {
  loadAvailableFilters,
  initializeExternalRecruiterFilters,
  preloadMissingQuestions,
} from 'teamtailor/constants/candidate-filters';
import { loadAvailableColumns } from 'teamtailor/constants/candidate-columns';

const SEARCH_PARAMS_KEYS = Object.keys(DEFAULT_SEARCH_PARAMS);

export default class SegmentRoute extends Route {
  @service current;
  @service flashMessages;
  @service flipper;
  @service intl;
  @service permissions;
  @service pusher;
  @service router;
  @service store;

  queryParams = {
    query: {
      refreshModel: true,
    },

    sortDirection: {
      refreshModel: true,
    },

    sortColumn: {
      refreshModel: true,
    },
  };

  get company() {
    return this.current.company;
  }

  get user() {
    return this.current.user;
  }

  beforeModel(transition) {
    if (!this.permissions.has('candidate/list')) {
      this.flashMessages.error(this.intl.t('errors.dont_have_access_to_page'));
      this.router.replaceWith('dashboard');
    }

    const { to: toRouteInfo } = transition;
    const { queryParams } = toRouteInfo;
    const companyRouteData = toRouteInfo.find(
      (route) => route.name === 'company'
    );
    const candidatesSegmentRouteData = toRouteInfo.find(
      (route) => route.name === 'candidates.segment'
    );

    const keys = Object.keys(queryParams).filter(
      (x) => SEARCH_PARAMS_KEYS.indexOf(x) >= 0
    );

    if (keys.length && !queryParams.q) {
      const query = {};
      keys.forEach((key) => {
        query[key] = queryParams[key];
        delete queryParams[key];
      });

      const newQueryParams = deepmerge(queryParams, {
        q: jsonToBase64(query),
      });

      transition.abort();
      return this.router.replaceWith(
        'candidates.segment',
        companyRouteData.params.company_id,
        candidatesSegmentRouteData.params.segment_id,
        { queryParams: newQueryParams }
      );
    }

    return undefined;
  }

  model(params) {
    const promises = {};
    if (params.query) {
      promises.query = base64ToJson(params.query);
    }

    promises.model = {};

    if (params.segment_id !== 'all') {
      promises.model.segment = this.store
        .findRecord('segment', params.segment_id, {
          backgroundReload: false,
        })
        .catch(() => {
          this.router.replaceWith('candidates.segment', 'all');
        });
    }

    promises.model.availableColumns =
      // eslint-disable-next-line ember/no-controller-access-in-routes
      this.controller?.model?.availableColumns ?? loadAvailableColumns(this);

    return hash({ ...promises, model: hash(promises.model) });
  }

  async setupController(controller, { model, query }) {
    super.setupController(controller, model);

    setProperties(controller, {
      _forceCloseDepartments: false,
      _forceCloseSegments: false,
      totalExtra: 0,
    });

    let params = query || model.segment?.filters;

    let filters = [];
    if (controller.availableFilters === null) {
      filters = await loadAvailableFilters(this.store, params);
      if (
        this.company.hasFeature('external_recruitment') &&
        !get(this, 'user.externalRecruiter')
      ) {
        filters.pushObjects(initializeExternalRecruiterFilters(this.store));
      }

      if (!get(this.flipper, 'manual_data_requests')) {
        filters.removeObject(filters.findBy('name', 'data_requested_at'));
      }

      set(controller, 'availableFilters', filters);
    } else {
      await preloadMissingQuestions(
        this.store,
        controller.availableFilters,
        params
      );
      filters = controller.availableFilters;
    }

    if (params) {
      set(controller, 'searchParams', deepmerge(DEFAULT_SEARCH_PARAMS, params));
    } else {
      controller.resetSearchParams();
    }

    controller.fetchCandidates.perform();

    if (!get(this, 'channel')) {
      get(this, 'pusher')
        .subscribe(get(this, 'company.candidatesChannel'))
        .then((channel) => {
          set(this, 'channel', channel);
          channel.bind('candidate-added', bind(this, 'handleNewCandidate'));
        });
    }
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    let channel = get(this, 'channel');
    if (isExiting) {
      set(controller, 'showBulk', false);

      if (channel) {
        channel.unbind('candidate-added');
        set(this, 'channel', null);
      }
    }
  }

  handleNewCandidate({ id }) {
    if (this.candidateAlreadyLoaded(id)) {
      return;
    }

    this.store.findRecord('candidate', id).then(() => {
      this.controller.checkForNewCandidate.perform(id);
      this.company.incrementProperty('_candidateCount');
    });
  }

  candidateAlreadyLoaded(id) {
    return (
      !!this.store.peekRecord('candidate', id) &&
      this.controller.candidates.findBy('id', id.toString())
    );
  }
}
