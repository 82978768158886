import Controller from '@ember/controller';
import { set, action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class PostingController extends Controller {
  @service current;

  get applyButtonText() {
    const text = get(this, 'model.jobDetail.applyButtonText');
    if (isEmpty(text)) {
      return get(this.current.company, 'applyButtonTextDefault');
    } else {
      return text;
    }
  }

  @action
  onChange(value) {
    set(this, 'model.jobDetail.body', value);
  }
}
