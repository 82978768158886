import Route from '@ember/routing/route';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import PageModel from 'teamtailor/models/page';

interface QueryParams {
  jobId?: number;
  pageType?: string;
}

export default class PageRoute extends Route {
  @service declare store: Store;

  async model({ jobId, pageType }: QueryParams): Promise<PageModel> {
    const model = this.modelFor('content.index.new');

    if (jobId) {
      await this.store.findRecord('job', jobId).then((job) => {
        set(model, 'job', job);
        model.title = job.title;
      });
    }

    if (pageType) {
      model.pageType = pageType;
    }

    return model;
  }
}
