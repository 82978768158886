export const DEFAULT_SIZE = 'medium';
export const ALLOWED_SIZES = ['inGroup', 'small', 'medium', 'large'] as const;

export const DEFAULT_APPEARANCES = 'primary';
export const ALLOWED_APPEARANCES = [
  'primary',
  'secondary',
  'inGroup',
  'tertiary',
] as const;

export const ICON_SIZE_MAP = {
  medium: '20',
  inGroup: '20',
  small: '16',
  large: '24',
} as const;

export const AVATAR_SIZE_MAP = {
  medium: 'x-smaller',
  large: 'x-small',
  small: 'x-smaller',
  inGroup: 'x-smaller',
} as const;
