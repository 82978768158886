import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isOpen}}\n  {{set-body-class \"overflow-hidden\"}}\n  {{#in-element this.modalElement insertBefore=null}}\n    <div\n      class=\"pointer-events-auto absolute inset-0 isolate grid h-screen place-items-center p-12 lg:p-0\"\n      {{did-insert (optional @onOpen)}}\n      {{focus-trap focusTrapOptions=(hash clickOutsideDeactivates=true)}}\n    >\n      <div\n        role=\"button\"\n        {{on \"click\" this.handleBackdropClick}}\n        class={{class-names\n          \"absolute inset-0 bg-zinc-800/90\"\n          (if this.closeOnBackdropClick \"cursor-pointer\" \"cursor-default\")\n        }}\n      ></div>\n      <div\n        class=\"relative flex max-h-[88vh] w-full max-w-[550px] flex-col overflow-hidden rounded-10 bg-zinc-750 px-44 py-32 text-white shadow-base-hover lg:px-[72px]\"\n      >\n        {{yield}}\n      </div>\n    </div>\n  {{/in-element}}\n{{/if}}", {"contents":"{{#if this.isOpen}}\n  {{set-body-class \"overflow-hidden\"}}\n  {{#in-element this.modalElement insertBefore=null}}\n    <div\n      class=\"pointer-events-auto absolute inset-0 isolate grid h-screen place-items-center p-12 lg:p-0\"\n      {{did-insert (optional @onOpen)}}\n      {{focus-trap focusTrapOptions=(hash clickOutsideDeactivates=true)}}\n    >\n      <div\n        role=\"button\"\n        {{on \"click\" this.handleBackdropClick}}\n        class={{class-names\n          \"absolute inset-0 bg-zinc-800/90\"\n          (if this.closeOnBackdropClick \"cursor-pointer\" \"cursor-default\")\n        }}\n      ></div>\n      <div\n        class=\"relative flex max-h-[88vh] w-full max-w-[550px] flex-col overflow-hidden rounded-10 bg-zinc-750 px-44 py-32 text-white shadow-base-hover lg:px-[72px]\"\n      >\n        {{yield}}\n      </div>\n    </div>\n  {{/in-element}}\n{{/if}}","moduleName":"teamtailor/components/video-meeting/modal.hbs","parseOptions":{"srcName":"teamtailor/components/video-meeting/modal.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { keyResponder, onKey } from 'ember-keyboard';
import { KeyCodes } from 'teamtailor/constants/key-codes';
import { argDefault } from 'teamtailor/utils/arg-default';

export type CoreModalComponentSize = 'small' | 'medium' | 'large';

export interface CoreModalComponentArgs {
  size?: CoreModalComponentSize;
  showCloseButton?: boolean;
  closeOnBackdropClick?: boolean;
  closeOnEscape?: boolean;
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

@keyResponder
export default class CoreModalComponent extends Component<CoreModalComponentArgs> {
  @argDefault closeOnBackdropClick = true;
  @argDefault closeOnEscape = true;
  @argDefault isOpen = true;

  get modalElement() {
    return document.getElementById('ember-teamtailor-modal');
  }

  @action
  handleBackdropClick() {
    if (this.closeOnBackdropClick) {
      this.args.onClose?.();
    }
  }

  @onKey(KeyCodes.ESC)
  handleEscape() {
    if (this.closeOnEscape) {
      this.args.onClose?.();
    }
  }
}
