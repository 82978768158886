import Controller from '@ember/controller';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, computed } from '@ember/object';
import Changeset from 'ember-changeset';
import { inject as service } from '@ember/service';

export default class EmployeeDashboardWidgetIntroMessageController extends Controller {
  @service router;

  @computed('model')
  get changeset() {
    return new Changeset(this.model);
  }

  @action
  handleModalClose() {
    this.router.transitionTo('employee.index');
  }

  @action
  save() {
    this.changeset.save().then(() => {
      this.router.transitionTo('employee.index');
    });
  }
}
