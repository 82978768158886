import ContentEditorFooterController from 'teamtailor/controllers/content/editor/footer';
import { action, setProperties } from '@ember/object';
import CareerSiteFooterValidations from 'teamtailor/validations/career-site-footer';

export default class ContentEditorFooterIndexController extends ContentEditorFooterController {
  modelValidations = CareerSiteFooterValidations;

  setChangesOnCareerSite(changeset) {
    setProperties(
      this.careerSite,
      changeset.changes.reduce((current, { key, value }) => {
        current[key] = value;
        return current;
      }, {})
    );
  }

  @action
  async save(changeset) {
    await changeset.validate();

    if (changeset.isValid) {
      this.setChangesOnCareerSite(changeset);
      return this.careerSite.updateCompanyInfo();
    } else {
      return false;
    }
  }
}
