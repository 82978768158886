import Current from 'teamtailor/services/current';
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { isObjectWithKey } from 'teamtailor/utils/type-utils';
import IntlService from 'ember-intl/services/intl';
import SectionModel, { ColorPresets } from 'teamtailor/models/section';
import { get } from 'teamtailor/utils/get';
import IframePreviewService from 'teamtailor/services/iframe-preview';

type BlocksBaseSignature = {
  Args: {
    model: SectionModel;
    onChange: () => void;
  };
};

type ColorPresetVariantName = 'background' | 'text';

export default class BlocksBaseComponent extends Component<BlocksBaseSignature> {
  @service declare current: Current;
  @service declare intl: IntlService;
  @service declare iframePreview: IframePreviewService;

  get model() {
    return this.args.model;
  }

  get design() {
    return this.current.company.draftDesign;
  }

  get colorPresets() {
    return [
      {
        id: 'primary',
        label: this.intl.t('content.editor.section.color_presets.primary'),
      },
      {
        id: 'secondary',
        label: this.intl.t('content.editor.section.color_presets.secondary'),
      },
      {
        id: 'custom',
        label: this.intl.t('content.editor.section.color_presets.custom'),
      },
    ];
  }

  get titleOptions() {
    return [
      {
        id: 'title',
        label: this.intl.t('content.editor.section.title_options.title'),
      },
      {
        id: 'title-subtitle',
        label: this.intl.t(
          'content.editor.section.title_options.title_subtitle'
        ),
      },
      {
        id: 'none',
        label: this.intl.t('content.editor.section.title_options.none'),
      },
    ];
  }

  get textAlignOptions() {
    return [
      {
        id: 'left',
        label: this.intl.t('content.editor.section.text_align_options.left'),
      },
      ...(this.model.layout !== 'LI04'
        ? [
            {
              id: 'right',
              label: this.intl.t(
                'content.editor.section.text_align_options.right'
              ),
            },
          ]
        : []),
      {
        id: 'center',
        label: this.intl.t('content.editor.section.text_align_options.center'),
      },
    ];
  }

  get shadowOptions() {
    return [
      {
        id: 'strong',
        label: this.intl.t('content.editor.section.shadow_options.strong'),
      },
      {
        id: 'light',
        label: this.intl.t('content.editor.section.shadow_options.light'),
      },
      {
        id: 'none',
        label: this.intl.t('content.editor.section.shadow_options.none'),
      },
    ];
  }

  get imageShapeOptions() {
    return [
      {
        id: 'rectangle',
        label: this.intl.t(
          'content.editor.section.image_shape_options.rectangle'
        ),
      },
      {
        id: 'circle',
        label: this.intl.t('content.editor.section.image_shape_options.circle'),
      },
    ];
  }

  get backgroundColor() {
    return this.colorSetting(this.model.customBackgroundColor!, 'background');
  }

  get titleColor() {
    return this.colorSetting(this.model.customTitleColor!, 'text');
  }

  get textColor() {
    return this.colorSetting(this.model.customTextColor!, 'text');
  }

  get iconColor() {
    return this.model.customIconColor ?? '#FFFFFF';
  }

  get imageBackground() {
    return this.model.customImageBackground || this.model.imageBackground;
  }

  get linkColor() {
    if (this.model.colorPreset === 'custom' && this.model.customLinkColor) {
      return this.model.customLinkColor;
    } else {
      return this.linkColorFromPreset(this.model.colorPreset);
    }
  }

  get highlightColor() {
    if (
      this.model.colorPreset === 'custom' &&
      this.model.customHighlightColor
    ) {
      return this.model.customHighlightColor;
    } else {
      return this.highlightColorFromPreset(this.model.colorPreset);
    }
  }

  linkColorFromPreset(preset: string) {
    if (preset === 'secondary') {
      return this.colorFromPreset('secondary', 'text');
    } else {
      return get(this.design, 'linkColor');
    }
  }

  highlightColorFromPreset(preset: string) {
    if (preset === 'secondary') {
      return this.colorFromPreset('secondary', 'text');
    } else {
      return get(this.design, 'color');
    }
  }

  colorSetting(customColor: string, defaultName: ColorPresetVariantName) {
    if (this.model.colorPreset === 'custom' && customColor) {
      return customColor;
    } else if (this.model.colorPreset === 'secondary') {
      return this.colorFromPreset('secondary', defaultName);
    } else {
      return this.colorFromPreset('primary', defaultName);
    }
  }

  colorFromPreset(preset: ColorPresets, name: ColorPresetVariantName) {
    const capitalizedName = capitalize(
      name
    ) as Capitalize<ColorPresetVariantName>;
    return get(
      this.design,
      `${preset as Exclude<ColorPresets, 'custom'>}${capitalizedName}Color`
    );
  }

  setDefaultCustomColors() {
    // Only attempt to set preset colors if at least one custom color is set
    if (
      this.model.customBackgroundColor ||
      this.model.customTitleColor ||
      this.model.customTextColor ||
      this.model.customLinkColor ||
      this.model.customHighlightColor
    ) {
      this.model.customBackgroundColor =
        this.model.customBackgroundColor ??
        this.colorFromPreset(this.model.colorPreset, 'background');
      this.model.customTitleColor =
        this.model.customTitleColor ??
        this.colorFromPreset(this.model.colorPreset, 'text');
      this.model.customTextColor =
        this.model.customTextColor ??
        this.colorFromPreset(this.model.colorPreset, 'text');
      this.model.customLinkColor =
        this.model.customLinkColor ??
        this.linkColorFromPreset(this.model.colorPreset);
      this.model.customHighlightColor =
        this.model.customHighlightColor ??
        this.highlightColorFromPreset(this.model.colorPreset);
    }
  }

  resetUnchangedCustomColors(colorPreset: ColorPresets) {
    // Set all unchanged custom colors to the given preset color
    const changedAttributes = Object.keys(this.model.changedAttributes());

    if (!changedAttributes.includes('customBackgroundColor')) {
      this.model.customBackgroundColor = this.colorFromPreset(
        colorPreset,
        'background'
      );
    }

    if (!changedAttributes.includes('customTitleColor')) {
      this.model.customTitleColor = this.colorFromPreset(colorPreset, 'text');
    }

    if (!changedAttributes.includes('customTextColor')) {
      this.model.customTextColor = this.colorFromPreset(colorPreset, 'text');
    }

    if (!changedAttributes.includes('customLinkColor')) {
      this.model.customLinkColor = this.linkColorFromPreset(
        this.model.colorPreset
      );
    }

    if (!changedAttributes.includes('customHighlightColor')) {
      this.model.customHighlightColor = this.highlightColorFromPreset(
        this.model.colorPreset
      );
    }
  }

  @action
  async handleColorPresetChange(colorPreset: ColorPresets) {
    if (colorPreset === 'custom') {
      this.setDefaultCustomColors();
    }

    this.model.colorPreset = colorPreset;
    await this.args.onChange();
  }

  @action
  async handleTitleTypeChange(titleType: string) {
    this.model.customTitleType = titleType;
    this.model.titleType = titleType;
    await this.args.onChange();
  }

  @action
  async handleTextAlignChange(textAlign: string) {
    // @ts-expect-error this field doesnt exist?
    this.model.customTextAlign = textAlign;
    await this.args.onChange();
  }

  @action
  async handleShadowChange(shadow: string) {
    this.model.customShadowType = shadow;
    await this.args.onChange();
  }

  @action
  async handleImageShapeChange(imageShape: unknown) {
    // @ts-expect-error this field doesnt exist?
    this.model.customImageShape = imageShape;
    await this.args.onChange();
  }

  @action
  async handleChangeColor() {
    if (this.model.colorPreset !== 'custom') {
      this.resetUnchangedCustomColors(this.model.colorPreset);
    }

    this.setDefaultCustomColors();
    this.model.colorPreset = 'custom';
    await this.args.onChange();
  }

  @action
  async handleHasClassNameChange() {
    if (!this.model.hasCustomClassName) {
      this.model.wrapperClass = null;
    }

    await this.args.onChange();
  }

  @action
  async handleAttributeChange(
    value: unknown,
    {
      attribute,
      reloadIframeAfterChange,
    }: { attribute?: string; reloadIframeAfterChange?: boolean } = {}
  ) {
    if (attribute && isObjectWithKey(this.model, attribute)) {
      set(this.model, attribute, value);
    }

    await this.args.onChange();

    if (reloadIframeAfterChange) {
      this.iframePreview.reloadIframe();
    }
  }
}
