export default {
  "loadingSpinner": "Referrals__loadingSpinner_s37199",
  "stats": "Referrals__stats_s37199",
  "statsBoxes": "Referrals__statsBoxes_s37199",
  "count": "Referrals__count_s37199",
  "icon": "Referrals__icon_s37199",
  "label": "Referrals__label_s37199",
  "text": "Referrals__text_s37199",
  "referralList": "Referrals__referralList_s37199",
  "item": "Referrals__item_s37199",
  "name": "Referrals__name_s37199",
  "statusDate": "Referrals__statusDate_s37199",
  "statusSection": "Referrals__statusSection_s37199",
  "statusColorArea": "Referrals__statusColorArea_s37199",
  "statusColorArea--inbox": "Referrals__statusColorArea--inbox_s37199",
  "statusColorArea--inProcess": "Referrals__statusColorArea--inProcess_s37199",
  "statusColorArea--hired": "Referrals__statusColorArea--hired_s37199",
  "statusColorArea--rejected": "Referrals__statusColorArea--rejected_s37199",
  "emptyState": "Referrals__emptyState_s37199",
  "buttonWrapper": "Referrals__buttonWrapper_s37199",
  "button": "Referrals__button_s37199",
  "pagination": "Referrals__pagination_s37199"
};
