import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';

export default class SettingsPartnerHubShowKeysRoute extends ScrollToTopRoute {
  @service declare store: Store;

  model(params: { sandbox_activation_id: string }) {
    return this.store.findRecord(
      'sandbox-activation',
      params.sandbox_activation_id
    );
  }
}
