import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import { buildReport as buildNpsPerStageReport } from 'teamtailor/classes/analytics/report-nps-per-stage-new';
import { buildReport as buildNpsPerStageTypeReport } from 'teamtailor/classes/analytics/report-nps-per-stage-type';
import { buildReport as buildNpsDistributionReport } from 'teamtailor/classes/analytics/report-nps-distribution';
import { buildReport as buildNpsMovingAverageReport } from 'teamtailor/classes/analytics/report-nps-moving-average';
import { buildReport as buildNpsResponsesReport } from 'teamtailor/classes/analytics/report-nps-responses';
import { buildReport as buildNpsComparisonReport } from 'teamtailor/classes/analytics/report-nps-comparison';

import AnalyticsService from 'teamtailor/services/analytics';
import { get } from 'teamtailor/utils/get';
import DateRange from 'teamtailor/utils/date-range';
import FlipperService from 'teamtailor/services/flipper';
import Store from '@ember-data/store';

export default class NpsRoute extends Route {
  @service declare analytics: AnalyticsService;
  @service declare flipper: FlipperService;
  @service declare store: Store;

  model() {
    const container = getOwner(this);

    const compareSettings = get(this.analytics, 'previousPeriodSettings');

    const dateCompareSettings = compareSettings
      ? {
          dateRange: new DateRange(
            compareSettings.startDate,
            compareSettings.endDate
          ),
        }
      : undefined;

    const npsPerStageReportBuilder = buildNpsPerStageReport({ container });
    const npsPerStageTypeReportBuilder = buildNpsPerStageTypeReport({
      container,
    });
    const npsDistributionReportBuilder = buildNpsDistributionReport({
      container,
      options: {},
      compareOptions: dateCompareSettings,
    });
    const npsMovingAverageReportBuilder = buildNpsMovingAverageReport({
      container,
    });
    const npsResponsesReportBuilder = buildNpsResponsesReport({ container });
    const npsComparisonReportBuilder = buildNpsComparisonReport({
      container,
      options: {},
      compareOptions: dateCompareSettings,
    });

    return {
      perStage: npsPerStageReportBuilder.buildTask,
      perStageType: npsPerStageTypeReportBuilder.buildTask,
      distribution: npsDistributionReportBuilder.buildTask,
      movingAverage: npsMovingAverageReportBuilder.buildTask,
      npsResponses: npsResponsesReportBuilder.buildTask,
      npsSurveys: this.store.query('nps-response', {
        groupAnalytics: this.analytics.hasEnabledGroupAnalytics,
      }),

      ...(get(this.flipper, 'show_nps_comparison_widget')
        ? {
            comparison: npsComparisonReportBuilder.buildTask,
          }
        : {}),
    };
  }
}
