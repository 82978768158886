type FieldType = {
  tKey: string;
  type: string;
  inputType: string;
  previewType: string;
  icon: string;
};

export const fieldTypes: FieldType[] = [
  {
    tKey: 'text',
    type: 'CustomField::Text',
    inputType: 'text',
    previewType: 'text',
    icon: 'font',
  },
  {
    tKey: 'number',
    type: 'CustomField::Number',
    inputType: 'number',
    previewType: 'number',
    icon: 'hashtag',
  },
  {
    tKey: 'select',
    type: 'CustomField::Select',
    inputType: 'select',
    previewType: 'select',
    icon: 'circle-caret-down',
  },
  {
    tKey: 'multi_select',
    type: 'CustomField::MultiSelect',
    inputType: 'select',
    previewType: 'select',
    icon: 'list',
  },
  {
    tKey: 'true_false',
    type: 'CustomField::Checkbox',
    inputType: 'checkbox',
    previewType: 'checkbox',
    icon: 'square-check',
  },
  {
    tKey: 'date',
    type: 'CustomField::Date',
    inputType: 'date',
    previewType: 'date',
    icon: 'calendar',
  },
  {
    tKey: 'email',
    type: 'CustomField::Email',
    inputType: 'text',
    previewType: 'email',
    icon: 'at',
  },
  {
    tKey: 'phone',
    type: 'CustomField::Phone',
    inputType: 'phone',
    previewType: 'phone',
    icon: 'phone-flip',
  },
  {
    tKey: 'url',
    type: 'CustomField::Url',
    inputType: 'url',
    previewType: 'url',
    icon: 'link',
  },
];

export const getFieldByType = (type: string): FieldType | undefined => {
  return fieldTypes.find((f: FieldType) => f.type === type);
};
