import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { SafeString } from '@ember/template/-private/handlebars';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import FromRoute from 'teamtailor/routes/features/show/feature';
import FeatureModel from 'teamtailor/models/feature';
import IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import FlashMessageService from 'teamtailor/services/flash-message';
import { get } from 'teamtailor/utils/get';
import { stripHtml } from 'teamtailor/utils/string-sanitizer';
import Current from 'teamtailor/services/current';

export default class FeatureShowFeatureIndexDeactivateController extends Controller {
  declare model: ModelFrom<FromRoute>;

  @service declare current: Current;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  @tracked declare cp: string;

  get boldDefaultCareerSiteName(): string | SafeString {
    const defaultCareerSite = get(this.current.company, 'defaultCareerSite');
    if (defaultCareerSite) {
      return htmlSafe(
        `<strong>${stripHtml(get(defaultCareerSite, 'emoji'))} ${get(
          defaultCareerSite,
          'translatedLanguage'
        )}</strong>`
      );
    }

    return '';
  }

  get feature(): FeatureModel {
    return this.model.feature!;
  }

  @action
  handleOnDeactivate(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.feature
        .disable()
        .then(() => {
          get(this.current.company, 'features').removeObject(this.feature);
          this.flashMessages.success(
            this.intl.t('features.feature.feature_deactivated')
          );
          this.cancel();
          resolve();
        })
        .catch(({ errors }) => {
          reject();
          if (errors.length > 0 && errors[0].status === 409) {
            this.flashMessages.error(errors[0].detail);
          } else {
            this.flashMessages.error(
              this.intl.t('common.something_went_wrong')
            );
          }
        });
    });
  }

  @action
  cancel(): void {
    window.history.back();
  }
}

declare module '@ember/controller' {
  interface Registry {
    'features.show.feature.index.deactivate': FeatureShowFeatureIndexDeactivateController;
  }
}
