import Store from '@ember-data/store';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import FromRoute from 'teamtailor/routes/settings/security/sso/show/mappings/new';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import { get } from 'teamtailor/utils/get';
import { ModelFrom } from 'teamtailor/utils/type-utils';

export default class SettingsSecuritySsoShowMappingsNew extends Controller {
  declare model: ModelFrom<FromRoute>;

  @service declare current: Current;
  @service declare flashMessages: FlashMessageService;
  @service declare router: RouterService;
  @service declare store: Store;

  @action
  handleSubmit() {
    return this.handleSave();
  }

  @action
  handleClose() {
    this.router.transitionTo('settings.security.sso.show');
  }

  @action
  async handleSave() {
    try {
      await this.model.save();

      const singleSignOn = get(this.current.company, 'singleSignOn');

      get(singleSignOn, 'ssoUserAttributeMappings').pushObject(this.model);

      this.flashMessages.success('Successfully created SSO mapping');
      this.router.refresh('settings.security.sso.show.mappings.new');

      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.security.sso.show.mappings.new': SettingsSecuritySsoShowMappingsNew;
  }
}
