import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import TranslationModel from 'teamtailor/models/translation';
import CustomFieldModel from 'teamtailor/models/custom-field';

export default class TranslationCustomFieldModel extends TranslationModel {
  @belongsTo('custom-field', { polymorphic: true })
  declare customField: AsyncBelongsTo<CustomFieldModel>;

  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'translation/custom-field': TranslationCustomFieldModel;
  }
}
