export function getClosest(el, tag) {
  do {
    if (el.nodeName === tag.toUpperCase()) {
      return el;
    }
  } while ((el = el.parentNode));

  return null;
}

export default {};
