import Model, { SyncHasMany, attr, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import UploadModel from './upload';
import FormQuestionModel from './form-question';
import computeFullErrorMessages from 'teamtailor/utils/full-error-messages';
import { v4 as uuid } from 'ember-uuid';

type Options = {
  currency?: string;
  salaryTimeUnit?: string;
  salary?: number;
};

export default class JobOfferFormModel extends Model {
  @service declare intl: IntlService;

  formQuestionsSorting = Object.freeze('sortIndex');

  @attr('string')
  declare name: string;

  @attr('string')
  declare introText: string;

  @attr('string')
  declare outroTitle: string;

  @attr('string')
  declare outroText: string;

  @attr('date')
  declare createdAt: Date;

  @attr('boolean')
  declare archived: boolean;

  @attr('number')
  declare responses: number;

  @attr('number') declare sends: number;

  @attr('raw') formQuestionsRaw: unknown;

  @attr('raw')
  declare options: Options;

  @hasMany('form-question', { async: false })
  declare formQuestions: SyncHasMany<FormQuestionModel>;

  @hasMany('upload', { async: false })
  declare uploads: SyncHasMany<UploadModel>;

  get fullErrorMessages() {
    return computeFullErrorMessages(this, this.intl);
  }

  get sortedFormQuestions() {
    return this.formQuestions.sortBy(this.formQuestionsSorting);
  }

  get unmodifiableFields() {
    return ['acceptance_message', 'rejection_message', 'salary'];
  }

  get messageFields() {
    return ['acceptance_message', 'rejection_message'];
  }

  get defaultAcceptanceMessage() {
    return this.intl.t('models.job_offer_form.default_acceptance_message');
  }

  get defaultRejectionMessage() {
    return this.intl.t('models.job_offer_form.default_rejection_message');
  }

  get numberOfOfferItems() {
    return (
      this.formQuestions.filterBy('isNew', false).length -
      this.messageFields.length
    );
  }

  get defaultFormFields() {
    return [
      {
        uuid: uuid(),
        label: 'acceptance_message',
        type: 'textarea',
        required: true,
        answers: [{ textValue: this.defaultAcceptanceMessage }],
      },
      {
        uuid: uuid(),
        label: 'rejection_message',
        type: 'textarea',
        required: true,
        answers: [{ textValue: this.defaultRejectionMessage }],
      },
      {
        uuid: uuid(),
        label: 'start_date',
        type: 'date',
        required: false,
        answers: [{ dateValue: '' }],
        icon: 'calendar',
      },
    ];
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'job-offer-form': JobOfferFormModel;
  }
}
