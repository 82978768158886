import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<h3 ...attributes local-class={{join \" \" this.localClassNames}}>\n  {{#each this.text as |info|}}\n    {{unless info \"\" (concat info \" · \")}}\n  {{/each}}\n  {{#if @isPublished}}\n    {{t\n      \"insights.job.published_at\"\n      date=(moment-format @publishedAt \"DD MMM YYYY\")\n    }}\n  {{else}}\n    {{t \"insights.job.unpublished\"}}\n  {{/if}}\n</h3>", {"contents":"<h3 ...attributes local-class={{join \" \" this.localClassNames}}>\n  {{#each this.text as |info|}}\n    {{unless info \"\" (concat info \" · \")}}\n  {{/each}}\n  {{#if @isPublished}}\n    {{t\n      \"insights.job.published_at\"\n      date=(moment-format @publishedAt \"DD MMM YYYY\")\n    }}\n  {{else}}\n    {{t \"insights.job.unpublished\"}}\n  {{/if}}\n</h3>","moduleName":"teamtailor/components/insights/job-info-heading.hbs","parseOptions":{"srcName":"teamtailor/components/insights/job-info-heading.hbs"}});
import Component from '@glimmer/component';
export default class InsightsJobInfoHeading extends Component {
  get text() {
    const { departmentName, locations } = this.args;

    const locNames = locations
      .map((location) => location.name || location.city)
      .join(', ');

    return [departmentName || '', locNames];
  }

  get localClassNames() {
    const classes = ['root', `root--${this.args.variant}`];

    if (this.args.light) {
      classes.push('root--light');
    }

    return classes;
  }
}
