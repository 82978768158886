import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.hasMeetingEvents}}\n  <CandidateModalNew::Main::Section\n    @title={{t \"components.meetings.meetings\"}}\n    @id=\"meetings\"\n  >\n    <:content>\n      <ul class=\"flex flex-col gap-16\">\n        {{#each this.sortedMeetingEvents as |event|}}\n          <li>\n            <Meeting::CandidateEventDetail\n              @event={{event}}\n              @leaveFeedback={{this.leaveFeedback}}\n              @user={{@user}}\n              @candidate={{@candidate}}\n              @job={{event.job}}\n              @route={{this.meetingEventRoute}}\n            />\n          </li>\n        {{/each}}\n      </ul>\n    </:content>\n  </CandidateModalNew::Main::Section>\n{{/if}}", {"contents":"{{#if this.hasMeetingEvents}}\n  <CandidateModalNew::Main::Section\n    @title={{t \"components.meetings.meetings\"}}\n    @id=\"meetings\"\n  >\n    <:content>\n      <ul class=\"flex flex-col gap-16\">\n        {{#each this.sortedMeetingEvents as |event|}}\n          <li>\n            <Meeting::CandidateEventDetail\n              @event={{event}}\n              @leaveFeedback={{this.leaveFeedback}}\n              @user={{@user}}\n              @candidate={{@candidate}}\n              @job={{event.job}}\n              @route={{this.meetingEventRoute}}\n            />\n          </li>\n        {{/each}}\n      </ul>\n    </:content>\n  </CandidateModalNew::Main::Section>\n{{/if}}","moduleName":"teamtailor/components/candidate-modal-new/main/section/meetings/index.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-modal-new/main/section/meetings/index.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import RouterService from '@ember/routing/router-service';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import RouteHelper from 'teamtailor/services/route-helper';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import {
  CandidateModel,
  MeetingEventModel,
  UserModel,
} from 'teamtailor/models';
import moment from 'moment-timezone';

interface Signature {
  Args: {
    user: UserModel;
    leaveFeedback(interviewKitId: string, jobId?: string): void;
    jobId?: string;
    candidate: CandidateModel;
  };
}

export default class CandidateModalNewMainSectionMeetings extends Component<Signature> {
  @service declare router: RouterService;
  @service declare apollo: TeamtailorApolloService;
  @service declare routeHelper: RouteHelper;
  @service declare store: Store;

  get meetingEvents() {
    if (get(this.args.candidate.meetingEvents, 'isFulfilled')) {
      return this.args.candidate.meetingEvents
        .slice()
        .filter((meetingEvent) => {
          const jobId = meetingEvent.belongsTo('job').id();

          return jobId === null || jobId === this.args.jobId;
        });
    }

    return [];
  }

  get hasMeetingEvents() {
    return this.meetingEvents.length > 0;
  }

  get eventsWithSelfSchedule(): MeetingEventModel[] {
    return this.meetingEvents.filter((event) => {
      return !event.bookedSlotStartsAt;
    });
  }

  get eventsWithBookedSlot(): MeetingEventModel[] {
    return this.meetingEvents.filter((event) => {
      return event.bookedSlotStartsAt;
    });
  }

  get pastEvents(): MeetingEventModel[] {
    const now = moment();

    return this.eventsWithBookedSlot
      .filter((m) => {
        return (
          moment(m.bookedSlotStartsAt) < now && !this.ongoingEvents.includes(m)
        );
      })
      .sortBy('bookedSlotStartsAt')
      .reverse();
  }

  get upcomingEvents(): MeetingEventModel[] {
    const now = moment();

    return this.eventsWithBookedSlot
      .filter((m) => moment(m.bookedSlotStartsAt) > now)
      .sortBy('bookedSlotStartsAt');
  }

  get ongoingEvents(): MeetingEventModel[] {
    const now = moment();

    return this.eventsWithBookedSlot
      .filter((m) => {
        if (m.bookedSlotStartsAt && m.bookedSlotEndsAt) {
          return (
            moment(m.bookedSlotStartsAt) < now &&
            moment(m.bookedSlotEndsAt) > now
          );
        }
      })
      .sortBy('bookedSlotStartsAt');
  }

  get sortedMeetingEvents(): MeetingEventModel[] {
    return [
      ...this.ongoingEvents,
      ...this.upcomingEvents,
      ...this.eventsWithSelfSchedule,
      ...this.pastEvents,
    ];
  }

  get meetingEventRoute() {
    const nestedRoute = this.routeHelper.getNestedRoute('meetings.events/show');
    return nestedRoute || 'meetings.events/show';
  }

  @action
  leaveFeedback(interviewKitId: string, jobId?: string) {
    const feedBackArgs = {
      interviewKitId,
      jobId,
    };

    this.router.transitionTo({
      queryParams: {
        tab: 'interview-feed',
        interviewFeedQuery: JSON.stringify(feedBackArgs),
      },
    });
  }
}
