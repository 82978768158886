import RESTSerializer from '@ember-data/serializer/rest';

export default class GiphyImage extends RESTSerializer {
  normalizeQueryResponse(store, primaryModelClass, payload) {
    payload.giphy_images.forEach(this._buildImageObject);
    return super.normalizeQueryResponse(...arguments);
  }

  normalizeQueryRecordResponse(store, primaryModelClass, payload) {
    payload.giphy_image = this._buildImageObject(payload.giphy_image);
    return super.normalizeQueryRecordResponse(...arguments);
  }

  normalizeFindAllResponse(store, primaryModelClass, payload) {
    payload.giphy_images.forEach(this._buildImageObject);
    return super.normalizeFindAllResponse(...arguments);
  }

  _buildImageObject(image) {
    image.type = 'giphy-image';
    image.url = image.images.original.url;
    image.previewUrl = image.images.downsized_medium.url;
    image.width = image.images.downsized_medium.width;
    image.height = image.images.downsized_medium.height;
    return image;
  }
}
