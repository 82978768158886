import {
  AsyncBelongsTo,
  attr,
  belongsTo,
  hasMany,
  SyncHasMany,
} from '@ember-data/model';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { wait } from 'ember-ani-mate';
import IntlService from 'ember-intl/services/intl';
import { PickedQuestionModel, StageModel } from 'teamtailor/models';
import MoveModel from 'teamtailor/models/move';
import Trigger, { AllTriggerModels } from 'teamtailor/models/trigger';
import FlipperService from 'teamtailor/services/flipper';
import { get } from 'teamtailor/utils/get';

export function isSmartMove(
  trigger: AllTriggerModels
): trigger is TriggerSmartMoveModel {
  return trigger.type === 'smart-move';
}

export default class TriggerSmartMoveModel extends Trigger<'smart-move'> {
  @service declare intl: IntlService;
  @service declare flipper: FlipperService;

  @hasMany('picked-question', { async: false })
  declare pickedQuestions: SyncHasMany<PickedQuestionModel>;

  @hasMany('move', { async: false }) declare moves?: SyncHasMany<MoveModel>;
  @attr('string') declare proceedValue: string;
  @belongsTo('stage', {
    inverse: 'proceedStageTriggerSmartMoves',
  })
  declare proceedStage: AsyncBelongsTo<StageModel>;

  get subtype() {
    const moves = get(this, 'moves');
    return moves && moves.length ? moves[0].criteria : '';
  }

  get typeWithSubtype(): string {
    return this.subtype.length > 0 ? `${this.type}-${this.subtype}` : this.type;
  }

  get questions() {
    return get(this, 'pickedQuestions').map((p) => p.question);
  }

  get hasProceedStage() {
    return this.proceedStage.isTruthy;
  }

  get valid() {
    const nonDeletedQuestions = this.pickedQuestions.rejectBy('isDeleted');
    return !!nonDeletedQuestions.length && this.hasProceedStage;
  }

  get stageNameOrFallback() {
    return (
      (this.hasProceedStage && get(this.proceedStage, 'name')) ||
      this.intl.t('common.na')
    );
  }

  @action loadAsyncRelationships(timeout = 10000) {
    const rels = Promise.all(
      get(this, 'moves')
        ?.toArray()
        .flatMap((m) =>
          m.pickedQuestions.map((pq) => pq.loadAsyncRelationships(timeout))
        ) ?? []
    );
    return Promise.race([rels, wait(timeout)]);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'trigger/smart-move': TriggerSmartMoveModel;
  }
}
