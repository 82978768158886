import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';

export default class ApprovalSettingsRoute extends ScrollToTopRoute {
  @service current;
  @service store;

  model() {
    const approvalSettingId = this.current.company
      .belongsTo('approvalSetting')
      .id();

    if (approvalSettingId) {
      return this.store.findRecord('approval-setting', approvalSettingId);
    } else {
      return this.store.createRecord('approval-setting');
    }
  }
}
