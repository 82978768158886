import Model, { attr, belongsTo } from '@ember-data/model';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { SafeString } from '@ember/template/-private/handlebars';
import IntlService from 'ember-intl/services/intl';
import Current from 'teamtailor/services/current';
import FlipperService from 'teamtailor/services/flipper';
import Server from 'teamtailor/services/server';
import { get } from 'teamtailor/utils/get';

export enum FeatureCategory {
  RECRUITING = 'recruit',
  RELATIONS = 'relationships',
  ENTERPRISE = 'enterprise',
  SECURITY = 'security',
}

export enum FeatureStatus {
  NOT_AVAILABLE = 'not_available',
  NOT_TOGGLABLE = 'not_togglable',
  UPCOMING = 'upcoming',
  RECENT = 'recent',
  AVAILABLE = 'available',
  CONTACT_SUPPORT = 'contact_support',
}

export type FeatureCategoryType =
  | FeatureCategory
  | 'all'
  | 'available'
  | 'enabled'
  | 'new'
  | 'upcoming';

export const featuresWithMandatoryConfig: FeatureModel['internalName'][] = [
  'audit_log',
  'candidate_chat',
  'career_site_languages',
  'external_recruitment',
  'interview_kit',
  'linkedin_rsc',
  'nurture',
  'requisitions',
  'sms',
  'sso',
  'surveys',
  'two_factor_authentication',
];

export const featuresWithOptionalConfig: FeatureModel['internalName'][] = [
  'recruiter_copilot',
];

export default class FeatureModel extends Model {
  @service private declare intl: IntlService;
  @service private declare current: Current;
  @service private declare flipper: FlipperService;
  @service private declare server: Server;

  @belongsTo('feature', { async: false, inverse: null })
  declare parentFeature: FeatureModel;

  @attr('boolean') declare beta: boolean;
  @attr('number') declare rowOrder: number;
  @attr('number') declare tourId: number;
  @attr('string') declare category: FeatureCategory;
  @attr('string') declare icon: string;
  @attr('string') declare internalName: string;
  @attr('string') declare moreLink: string;
  @attr('string') declare settingsLink: string | null;
  @attr('string') declare status: FeatureStatus;
  @attr('string') declare supportArticle: string;

  get isEnabledByCompany(): boolean {
    return get(this.current.company, 'hasFeature')(this);
  }

  get isRecent(): boolean {
    return this.status === FeatureStatus.RECENT;
  }

  get isUpcoming(): boolean {
    return this.status === FeatureStatus.UPCOMING;
  }

  get isCompatibleWithCompany(): boolean {
    if (this.isGroupAnalytics) {
      return (
        get(this.flipper, 'group_analytics') &&
        !get(this.current, 'company.hasParentCompany')
      );
    }

    return true;
  }

  get categoryName(): string {
    return this.intl.t(`features.categories.${this.category}`);
  }

  get description(): string {
    return this.intl.t(`features.descriptions.${this.internalName}`);
  }

  get longDescription(): SafeString {
    return htmlSafe(
      this.intl.t(`features.long_descriptions.${this.internalName}`)
    );
  }

  get betaDisclaimer(): SafeString {
    return htmlSafe(
      this.intl.t(`features.beta_disclaimers.${this.internalName}`)
    );
  }

  get hasParentFeature(): boolean {
    return !!this.parentFeature;
  }

  get parentFeatureEnabled(): boolean {
    if (!this.hasParentFeature) {
      return true;
    }

    return this.parentFeature.isEnabledByCompany;
  }

  get hasMandatoryConfiguration(): boolean {
    return featuresWithMandatoryConfig.includes(this.internalName);
  }

  get hasOptionalConfiguration(): boolean {
    return featuresWithOptionalConfig.includes(this.internalName);
  }

  get isRecruitCategory(): boolean {
    return this.category === FeatureCategory.RECRUITING;
  }

  get isRelationshipCategory(): boolean {
    return this.category === FeatureCategory.RELATIONS;
  }

  get isEnterpriseCategory(): boolean {
    return this.category === FeatureCategory.ENTERPRISE;
  }

  get notToggable(): boolean {
    return this.status === FeatureStatus.NOT_TOGGLABLE;
  }

  get canBeToggled(): boolean {
    return this.status !== FeatureStatus.NOT_TOGGLABLE;
  }

  get needContactSupport(): boolean {
    return this.status === FeatureStatus.CONTACT_SUPPORT;
  }

  get isSourcingExtension(): boolean {
    return this.internalName === 'sourcing_extensions';
  }

  get isInternalJobs(): boolean {
    return this.internalName === 'internal_jobs';
  }

  get isSmsFeature(): boolean {
    return this.internalName === 'sms';
  }

  get isFacebook(): boolean {
    return this.internalName === 'facebook';
  }

  get isCustomReject(): boolean {
    return this.internalName === 'custom_reject';
  }

  get isGroupAnalytics(): boolean {
    return this.internalName === 'group_analytics';
  }

  get enableInSettings(): boolean {
    return this.isFacebook || this.isSmsFeature || this.isCustomReject;
  }

  get isAvailable(): boolean {
    return Object.values<string>(FeatureStatus).includes(this.status);
  }

  get name(): string {
    return this.intl.t(`features.names.${this.internalName}`);
  }

  get hasSettings(): boolean {
    return this.settingsLink !== null;
  }

  get iconPrefix(): 'regular' | 'brands' | 'kit' {
    switch (this.internalName) {
      case 'linkedin_rsc':
      case 'facebook':
        return 'brands';
      case 'nurture':
      case 'enterprise_calendar':
        return 'kit';
      default:
        return 'regular';
    }
  }

  async enable() {
    await this.server.memberAction(this, { action: 'enable' });
    this.afterEnable();
  }

  async disable() {
    await this.server.memberAction(this, { action: 'disable' });
    this.afterDisable();
  }

  async require() {
    return this.server.memberAction(this, {
      action: 'require',
      method: 'POST',
    });
  }

  private afterEnable() {
    if (this.isInternalJobs) {
      set(this.current.company, 'hasInternalJobs', true);
    }
  }

  private afterDisable() {
    if (this.isInternalJobs) {
      set(this.current.company, 'hasInternalJobs', false);
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    feature: FeatureModel;
  }
}
