import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';

export default class LanguageIndexRoute extends ScrollToTopRoute {
  @service current;
  @service store;

  model() {
    return this.store.findAll('locale');
  }

  deactivate() {
    this.current.company.rollbackAttributes();
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      if (controller.isChangingDefaultCareerSite) {
        controller.isChangingDefaultCareerSite = false;
      }

      if (controller.userChannel) {
        controller.userChannel.unbind('default-language-change-complete');
      }
    }
  }
}
