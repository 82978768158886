import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import JobApplicationModel from './job-application';

export default class PredictionModel extends Model {
  @belongsTo('job-application')
  declare jobApplication: AsyncBelongsTo<JobApplicationModel>;

  @attr('number') declare prediction: number;
  @attr('number') declare result: number;
  @attr('string') declare name: string;

  get recommended() {
    return !!this.result;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    prediction: PredictionModel;
  }
}
