import Route from '@ember/routing/route';
import { getOwner } from '@ember/application';
import TimeToHireReport from 'teamtailor/classes/analytics/report-time-to-hire';

export default class TimeToHireRoute extends Route {
  model() {
    return {
      timeToHire: new TimeToHireReport({ container: getOwner(this) }).fetchTask,
    };
  }
}
