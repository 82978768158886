import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import Server from 'teamtailor/services/server';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';

export default class UpdateDocuments extends ScrollToTopRoute {
  @service declare store: Store;
  @service declare server: Server;
  @service declare current: Current;

  async fetchRequiredDocuments(isoCountry: string) {
    const json: any = await this.server.request(
      `/twilio/requirements?country_code=${isoCountry}`
    );
    return [...json.end_user, ...(json.supporting_document?.[0] || [])];
  }

  async model() {
    const twilio = await this.store.findRecord(
      'twilio',
      this.current.company.id
    );
    const setup = await get(this.current.company, 'phoneNumberSetup');
    if (!setup) {
      throw new Error('Phone number setup not found');
    }

    const requiredDocuments = await this.fetchRequiredDocuments(
      setup.numberCountry
    );
    return { requiredDocuments, twilio };
  }
}
