import { tracked } from '@glimmer/tracking';
import { IMeetingEventDateRange } from 'teamtailor/components/meeting/types';
import moment from 'moment-timezone';

export class BookedSlotClass {
  @tracked declare startsAt?: Date;
  @tracked declare endsAt?: Date;
  @tracked private declare _originalSlot:
    | Partial<IMeetingEventDateRange>
    | undefined;

  constructor(slot?: Partial<IMeetingEventDateRange>) {
    this.startsAt = slot?.startsAt;
    this.endsAt = slot?.endsAt;
    this._originalSlot = slot;
  }

  isPast(): boolean {
    return moment().isAfter(this.startsAt);
  }

  isValid(): boolean {
    return moment(this.endsAt).isAfter(this.startsAt);
  }

  static from(eventDate: IMeetingEventDateRange): BookedSlotClass {
    return new BookedSlotClass(eventDate);
  }

  hasChanges(): boolean {
    return (
      this._originalSlot?.startsAt !== this.startsAt ||
      this._originalSlot?.endsAt !== this.endsAt
    );
  }

  toAttributes(): IMeetingEventDateRange | undefined {
    if (this.endsAt && this.startsAt) {
      return {
        startsAt: this.startsAt,
        endsAt: this.endsAt,
      } as IMeetingEventDateRange;
    }

    return undefined;
  }
}
