import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import DateRange from 'teamtailor/utils/date-range';
export default class AnalyticsEmployeesEmployeeController extends Controller {
  @service analytics;

  get selectedUser() {
    return this.model;
  }

  get dateRange() {
    return new DateRange(this.analytics.startDate, this.analytics.endDate);
  }
}
