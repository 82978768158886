import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { Transition } from 'teamtailor/utils/type-utils';
import ErrorMessage from 'teamtailor/services/error-message';
import Current from 'teamtailor/services/current';
import Store from '@ember-data/store';
import { AddonFeature } from 'types/features';

export default class BaseRoute extends Route {
  @service declare store: Store;
  @service declare errorMessage: ErrorMessage;
  @service declare current: Current;

  requiredFeature: AddonFeature | null = null;

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    if (
      this.requiredFeature &&
      !this.current.company.hasFeature(this.requiredFeature)
    ) {
      this.errorMessage.notFound();
      transition.abort();
    }
  }
}
