import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div {{this.renderMentionUnreadActionIds}} class=\"w-full\">\n  <Toolbox::GlobalComments::ReadOnly::ChannelNameDivider\n    @jobId={{@job.id}}\n    @name={{@job.title}}\n    @showButton={{true}}\n  />\n\n  <Toolbox::GlobalComments::Mentioned::MentionedActivities\n    @jobId={{@job.id}}\n    @actionIds={{this.mentionUnreadActionIds}}\n  />\n</div>", {"contents":"<div {{this.renderMentionUnreadActionIds}} class=\"w-full\">\n  <Toolbox::GlobalComments::ReadOnly::ChannelNameDivider\n    @jobId={{@job.id}}\n    @name={{@job.title}}\n    @showButton={{true}}\n  />\n\n  <Toolbox::GlobalComments::Mentioned::MentionedActivities\n    @jobId={{@job.id}}\n    @actionIds={{this.mentionUnreadActionIds}}\n  />\n</div>","moduleName":"teamtailor/components/toolbox/global-comments/mentioned/mentioned-job.hbs","parseOptions":{"srcName":"teamtailor/components/toolbox/global-comments/mentioned/mentioned-job.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import NotificationsService from 'teamtailor/services/notifications';
import { modifier } from 'ember-modifier';
import { tracked } from '@glimmer/tracking';
import { JobModel } from 'teamtailor/models';

type Args = {
  job: JobModel;
};

export default class MentionedJobComponent extends Component<Args> {
  @service declare notifications: NotificationsService;

  @tracked mentionUnreadActionIds: string[] = [];

  get job() {
    return this.args.job;
  }

  renderMentionUnreadActionIds = modifier(() => {
    if (this.notifications.unreadMentions) {
      this.mentionUnreadActionIds =
        this.notifications.unreadMentions[this.job.id] || [];
    }
  });
}
