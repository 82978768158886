import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class SettingsPartnerHubEditRoute extends ScrollToTopRoute {
  @service store;

  model(params) {
    return this.store.findRecord(
      'sandbox-activation',
      params.sandbox_activation_id
    );
  }

  resetController(controller) {
    get(controller, 'model')?.rollbackAttributes();
  }
}
