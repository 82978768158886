import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  hasMany,
  belongsTo,
  attr,
} from '@ember-data/model';
import { get } from 'teamtailor/utils/get';
import {
  RequisitionStepModel,
  RequisitionStepVerdictCommentModel,
  UserModel,
} from 'teamtailor/models';

export default class RequisitionStepVerdictModel extends Model {
  @belongsTo('requisition-step', { async: false })
  declare requisitionStep: RequisitionStepModel;

  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;

  @hasMany('requisition-step-verdict-comment')
  declare requisitionStepVerdictComments: AsyncHasMany<RequisitionStepVerdictCommentModel>;

  @attr('string') declare approverName: string; // Backup for deleted users
  @attr('boolean', { allowNull: true }) declare isApproved: boolean | null;
  @attr('date') declare updatedAt: Date;

  get userId() {
    const user = get(this, 'user');
    return get(user, 'id');
  }

  get isRejected() {
    return this.isApproved === false;
  }

  get isPending() {
    return this.isApproved === null;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'requisition-step-verdict': RequisitionStepVerdictModel;
  }
}
