import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"root {{if this.isFocused 'root--active'}}\">\n  <label local-class=\"label\" for=\"search-input\">\n    <Icon @icon=\"magnifying-glass\" />\n  </label>\n\n  <input\n    type=\"search\"\n    local-class=\"input\"\n    placeholder={{@placeholder}}\n    value={{@value}}\n    id=\"insights-table-instant-search-input\"\n    {{on \"input\" (pipe (pick \"target.value\") @onchange)}}\n    {{on \"focusin\" (fn (mut this.isFocused) true)}}\n    {{on \"focusout\" (fn (mut this.isFocused) false)}}\n  />\n\n  {{#if @value}}\n    <button type=\"button\" class=\"bg-transparent\" {{on \"click\" this.clearInput}}>\n      <Icon @icon=\"circle-xmark\" />\n    </button>\n  {{/if}}\n</div>", {"contents":"<div local-class=\"root {{if this.isFocused 'root--active'}}\">\n  <label local-class=\"label\" for=\"search-input\">\n    <Icon @icon=\"magnifying-glass\" />\n  </label>\n\n  <input\n    type=\"search\"\n    local-class=\"input\"\n    placeholder={{@placeholder}}\n    value={{@value}}\n    id=\"insights-table-instant-search-input\"\n    {{on \"input\" (pipe (pick \"target.value\") @onchange)}}\n    {{on \"focusin\" (fn (mut this.isFocused) true)}}\n    {{on \"focusout\" (fn (mut this.isFocused) false)}}\n  />\n\n  {{#if @value}}\n    <button type=\"button\" class=\"bg-transparent\" {{on \"click\" this.clearInput}}>\n      <Icon @icon=\"circle-xmark\" />\n    </button>\n  {{/if}}\n</div>","moduleName":"teamtailor/components/insights/table-instant-search.hbs","parseOptions":{"srcName":"teamtailor/components/insights/table-instant-search.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class InsightsTableInstantSearchComponent extends Component {
  @tracked isFocused = false;

  @action
  clearInput() {
    this.args.onchange('');
    document.getElementById('insights-table-instant-search-input').focus();
  }
}
