import { DocumentNode } from 'graphql';
import FilterSettings from './filter-settings';
import Settings from './settings';
import PageviewQuery from './pageview-query';
import EventQuery from './event-query';
import { gql } from '@apollo/client/core';
import CombinedQuery from './combined-query';

function generateQuery(
  chartSettings: Settings,
  filterSettings: FilterSettings,
  page?: number,
  pageSize?: number
): DocumentNode | undefined {
  const shouldFetchPageviewQuery =
    chartSettings.selectedProperties.some((property) =>
      property.queryTypes.some((qt) => qt === 'pageview')
    ) &&
    chartSettings.currentGroupBys.some((property) =>
      property.queryTypes.some((qt) => qt === 'pageview')
    );
  const shouldFetchEventQuery =
    chartSettings.selectedProperties.some((property) =>
      property.queryTypes.some((qt) => qt === 'event')
    ) &&
    chartSettings.currentGroupBys.some((property) =>
      property.queryTypes.some((qt) => qt === 'event')
    );

  let queries: (string | undefined)[] = [];
  if (shouldFetchPageviewQuery && shouldFetchEventQuery) {
    const combinedQuery = new CombinedQuery(
      chartSettings.currentGroupBys,
      chartSettings.selectedProperties,
      filterSettings.filters.filter((filter) => filter.isValid),
      chartSettings.sortProperty,
      chartSettings.sortDirection,
      (chartSettings.sortProperty && chartSettings.limit) || pageSize,
      page
    );

    queries = [combinedQuery.query].filter((arg) => arg !== undefined);
  } else {
    const shouldLimitPageviews =
      chartSettings.sortProperty?.queryTypes.includes('pageview');
    const shouldLimitEvents =
      chartSettings.sortProperty?.queryTypes.includes('event');

    const pageviewQuery: PageviewQuery = new PageviewQuery(
      chartSettings.currentGroupBys,
      chartSettings.selectedProperties,
      filterSettings.filters.filter((filter) => filter.isValid),
      chartSettings.sortProperty,
      chartSettings.sortDirection,
      (shouldLimitPageviews && chartSettings.limit) || pageSize,
      page
    );
    const eventQuery: EventQuery = new EventQuery(
      chartSettings.currentGroupBys,
      chartSettings.selectedProperties,
      filterSettings.filters.filter((filter) => filter.isValid),
      chartSettings.sortProperty,
      chartSettings.sortDirection,
      (shouldLimitEvents && chartSettings.limit) || pageSize,
      page
    );

    queries = [pageviewQuery.query, eventQuery.query].filter(
      (arg) => arg !== undefined
    );
  }

  if (queries.length === 0) {
    return undefined;
  }

  return gql`
    query CustomBuiltReportQuery($dateRange: DateRangeAttributes!, $companyIds: [ID!]) {
      ${queries.join('\n\n')}
    }
  `;
}

export { generateQuery };
export default generateQuery;
