import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import Store from '@ember-data/store';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import SettingsRequisitionsFormRoute from 'teamtailor/routes/settings/requisitions/form';
import FlashMessageService from 'teamtailor/services/flash-message';
import { FormBuilderField } from 'teamtailor/utils/form-builder';
import IntlService from 'ember-intl/services/intl';

export default class SettingsRequisitionsFormController extends Controller {
  declare model: ModelFrom<SettingsRequisitionsFormRoute>;

  @service declare current: Current;
  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  @tracked showDefaultFields = false;

  get fields() {
    return this.model?.customForm || [];
  }

  set fields(fields) {
    if (this.model) {
      set(this.model, 'customForm', fields);
    }
  }

  @action
  toggleDefaultFields() {
    this.showDefaultFields = !this.showDefaultFields;
  }

  @action
  onUpdateField(fieldUuid: string, data: FormBuilderField) {
    this.fields.forEach((field, i) => {
      if (field.uuid === fieldUuid) {
        this.fields[i] = data;
      }
    });
  }

  @action
  onUpdateFields(fields: FormBuilderField[]) {
    this.fields = fields;
  }

  @action
  save() {
    try {
      this.model?.save();
    } catch {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.requisitions.form': SettingsRequisitionsFormController;
  }
}
