import { GraphQLError, GraphQLErrorExtensions } from 'graphql';

export interface IGraphQLErrorResponse {
  errors?: IGraphQLError[];
}

export interface IGraphQLError extends GraphQLError {
  message: string;
  path: (string | number)[];
  extensions: IGraphQLErrorExtension;
}

export interface IGraphQLErrorExtension extends GraphQLErrorExtensions {
  problems: IGraphQLErrorProblem[];
}

export interface IGraphQLErrorProblem {
  path: (string | number)[];
  explanation: string;
}

export const getStringErrorPath = (
  path: (string | number)[],
  wildCardArray = false
): string => {
  const stringPath = path.reduce((acc, c) => {
    const add = () => {
      if (typeof c === 'number') {
        if (wildCardArray) {
          return '[]';
        }

        return `[${c}]`;
      }

      return acc ? `.${c}` : c;
    };

    return `${acc}${add()}`;
  }, '');

  return `${stringPath}`;
};

export const findProblemByPath = (
  problems: IGraphQLErrorProblem[] | undefined,
  path: string
): IGraphQLErrorProblem | undefined => {
  return problems
    ? problems.find((problem) => getStringErrorPath(problem.path) === path)
    : undefined;
};

export const removeProblemByPath = (
  problems: IGraphQLErrorProblem[],
  path: string
): IGraphQLErrorProblem[] => {
  return problems.filter(
    (problem) => getStringErrorPath(problem.path) !== path
  );
};
