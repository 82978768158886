import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import MeetingRoute from 'teamtailor/routes/meetings';
import { Transition } from 'teamtailor/utils/type-utils';
import RouterService from '@ember/routing/router-service';
import { ErrorResponse } from '@apollo/client/link/error';
import { action } from '@ember/object';
import FlashMessageService from 'teamtailor/services/flash-message';

export interface EditMeetingQueryParams {
  meeting_id?: string;
  meeting_event_id: string;
}

export default class MeetingEditRoute extends Route {
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;

  meetingRouteRouteName = MeetingRoute.routeName; // is overriden when candidate modal route is in background

  model(params: EditMeetingQueryParams): EditMeetingQueryParams {
    return params;
  }

  @action
  error(error: ErrorResponse, transition: Transition): void {
    if (error.graphQLErrors?.length) {
      this.flashMessages.error(
        error.graphQLErrors.map((m) => m.message).join('\n'),
        {
          sticky: true,
          preventDuplicates: false,
        }
      );
      if (transition.from) {
        transition.abort();
      } else {
        this.router.transitionTo('dashboard');
      }
    }
  }
}
