import Route from '@ember/routing/route';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class NewRoute extends Route {
  @service store;

  model() {
    const section = this.modelFor('content.editor.section.perks');

    return this.store.createRecord('perk', {
      id: Date.now(),
      section,
      index: section.perks.length,
    });
  }

  setupController(controller) {
    super.setupController(...arguments);

    const section = this.modelFor('content.editor.section.perks');
    set(controller, 'section', section);
  }

  deactivate() {
    const model = this.modelFor('content.editor.section.perks.new');

    if (model.isNew) {
      model.deleteRecord();
    }
  }
}
