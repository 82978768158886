import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    pickedQuestions: {
      key: 'picked_questions_attributes',
      serialize: 'records',
      deserialize: 'ids',
    },
  },
});
