import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import Current from 'teamtailor/services/current';
import UserService from 'teamtailor/services/user';
import FlipperService from 'teamtailor/services/flipper';

export default class SettingsPayments extends Controller {
  @service declare current: Current;
  @service declare flipper: FlipperService;
  @service declare user: UserService;
}

declare module '@ember/controller' {
  interface Registry {
    'settings.payments': SettingsPayments;
  }
}
