import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Current from 'teamtailor/services/current';
import { query } from 'ember-data-resources';
import { TableColumn } from 'teamtailor/components/table/container';
import { restartableTask, timeout } from 'ember-concurrency';

export default class SettingsLocationsRegionsIndexController extends Controller {
  @service declare current: Current;

  @tracked page = 1;
  @tracked query = '';
  @tracked sort_direction = 'asc';
  @tracked sort_column = 'name';

  queryParams = ['query', 'sort_direction'];

  get regions() {
    return this.regionsQuery.records;
  }

  get tableColumns() {
    const columns: TableColumn[] = [
      {
        label: 'common.name',
        sortable: true,
        sortColumn: 'name',
        defaultSortOrder: 'asc',
        sortOrder: 1,
      },
      {
        label: 'common.locations',
        sortable: false,
        sortOrder: 2,
      },
    ];

    return columns;
  }

  regionsQuery = query(this, 'region', () => {
    return {
      per_page: 25,
      page: this.page || 1,
      query: this.query,
      sort_direction: this.sort_direction,
    };
  });

  handleSearchInput = restartableTask(async (value) => {
    await timeout(1000);
    this.query = value;
    this.page = 1;
  });
}

declare module '@ember/controller' {
  interface Registry {
    'settings.locations.regions.index': SettingsLocationsRegionsIndexController;
  }
}
