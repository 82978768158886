import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    jobDetail: 'job_id',
    rowOrder: { serialize: false },
    jobApplicationCount: { serialize: false },
    quickRejectSetting: {
      key: 'quick_reject_setting_attributes',
      deserialize: false,
      serialize: 'records',
    },

    triggerMessages: {
      key: 'trigger_messages_attributes',
      serialize: 'records',
      deserialize: false,
    },

    triggerNotes: {
      key: 'trigger_notes_attributes',
      serialize: 'records',
      deserialize: false,
    },

    triggerNps: {
      key: 'trigger_nps_attributes',
      serialize: 'records',
      deserialize: false,
    },

    triggerNurtures: {
      key: 'trigger_nurtures_attributes',
      serialize: 'records',
      deserialize: false,
    },

    triggerShares: {
      key: 'trigger_shares_attributes',
      serialize: 'records',
      deserialize: false,
    },

    triggerSmartMoves: {
      key: 'trigger_smart_moves_attributes',
      serialize: 'records',
      deserialize: false,
    },

    triggerSmartSchedules: {
      key: 'trigger_smart_schedules_attributes',
      serialize: 'records',
      deserialize: false,
    },

    triggerTags: {
      key: 'trigger_tags_attributes',
      serialize: 'records',
      deserialize: false,
    },

    triggerTodos: {
      key: 'trigger_todos_attributes',
      serialize: 'records',
      deserialize: false,
    },

    triggerWebhooks: {
      key: 'trigger_webhooks_attributes',
      serialize: 'records',
      deserialize: false,
    },

    triggerPredictions: {
      key: 'trigger_predictions_attributes',
      serialize: 'records',
      deserialize: false,
    },

    triggerSurveys: {
      key: 'trigger_surveys_attributes',
      serialize: 'records',
      deserialize: false,
    },

    triggerRestricts: {
      key: 'trigger_restricts_attributes',
      serialize: 'records',
      deserialize: false,
    },

    triggerAskForFeedback: {
      key: 'trigger_ask_for_feedbacks_attributes',
      serialize: 'records',
      deserialize: false,
    },

    triggerHireQualitySurveys: {
      key: 'trigger_hire_quality_surveys_attributes',
      serialize: 'records',
      deserialize: false,
    },
  },

  normalize(modelName, resourceHash) {
    let hash = this._super(...arguments);
    hash.data.attributes.jobId = resourceHash.job_detail;
    return hash;
  },
});
