import { get } from 'teamtailor/utils/get';
import EmberDragSortItemComponent from 'ember-drag-sort/components/drag-sort-item';

export default EmberDragSortItemComponent.extend({
  dragOver(event) {
    if (
      !get(this, 'dragSort.isDragging') ||
      get(this, 'determineForeignPositionAction')
    )
      return;

    const group = get(this, 'group');
    const activeGroup = get(this, 'dragSort.group');

    if (group !== activeGroup) return;

    this.draggingOver(event);
  },
});
