import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class AnalyticsOverviewRejectReasonsController extends Controller {
  @service declare analytics: any;
}

declare module '@ember/controller' {
  interface Registry {
    'analytics.overview.reject-reasons': AnalyticsOverviewRejectReasonsController;
  }
}
