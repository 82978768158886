import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"size-full\" {{did-insert this.onInsert}} ...attributes>\n  {{#if @isNewProvider}}\n    <VideoMeeting::VideoElement\n      @audioVideoFacade={{@audioVideoFacade}}\n      @videoId={{concat \"video-\" @remoteParticipantScreenShare.attendeeId}}\n      @videoClasses=\"'size-full mx-auto object-contain shadow-video-meeting overflow-hidden rounded-10\"\n      @shouldUnbindVideoElement={{true}}\n      @remoteParticipantScreenShare={{@remoteParticipantScreenShare}}\n      @attendeeId={{@remoteParticipantScreenShare.attendeeId}}\n      {{on \"resize\" this.handleResize}}\n    />\n  {{/if}}\n</div>", {"contents":"<div class=\"size-full\" {{did-insert this.onInsert}} ...attributes>\n  {{#if @isNewProvider}}\n    <VideoMeeting::VideoElement\n      @audioVideoFacade={{@audioVideoFacade}}\n      @videoId={{concat \"video-\" @remoteParticipantScreenShare.attendeeId}}\n      @videoClasses=\"'size-full mx-auto object-contain shadow-video-meeting overflow-hidden rounded-10\"\n      @shouldUnbindVideoElement={{true}}\n      @remoteParticipantScreenShare={{@remoteParticipantScreenShare}}\n      @attendeeId={{@remoteParticipantScreenShare.attendeeId}}\n      {{on \"resize\" this.handleResize}}\n    />\n  {{/if}}\n</div>","moduleName":"teamtailor/components/video-meeting/views/speaker-share-screen.hbs","parseOptions":{"srcName":"teamtailor/components/video-meeting/views/speaker-share-screen.hbs"}});
import Component from '@glimmer/component';
import { RemoteVideoTrack } from 'twilio-video';
import { action } from '@ember/object';
import {
  RemoteParticipantScreenShare,
  VideoDimensions,
  bindChimeTileToVideoElement,
} from 'teamtailor/utils/video-meetings/utils';
import { AudioVideoFacade } from 'amazon-chime-sdk-js';

interface VideoMeetingViewsSpeakerShareScreenArgs {
  track: RemoteVideoTrack;
  onDimensionsChanged: (
    track?: RemoteVideoTrack | null,
    dimensions?: VideoDimensions | null
  ) => void;
  // Chime
  remoteParticipantScreenShare?: RemoteParticipantScreenShare;
  isNewProvider: boolean;
  audioVideoFacade: AudioVideoFacade;
}

export default class VideoMeetingViewsSpeakerShareScreen extends Component<VideoMeetingViewsSpeakerShareScreenArgs> {
  @action
  onInsert(element: HTMLElement) {
    if (this.args.isNewProvider) {
      if (this.args.remoteParticipantScreenShare) {
        bindChimeTileToVideoElement(
          element,
          this.args.audioVideoFacade,
          this.args.remoteParticipantScreenShare.attendeeId,
          this.args.remoteParticipantScreenShare.tileId
        );
      }
    } else {
      const { track } = this.args;

      const trackElement = track.attach();
      trackElement.classList.add(
        'w-full',
        'h-full',
        'mx-auto',
        'object-contain',
        'shadow-video-meeting',
        'overflow-hidden',
        'rounded-10'
      );
      element.appendChild(trackElement);

      track.on('dimensionsChanged', (track: RemoteVideoTrack) =>
        this.args.onDimensionsChanged(track)
      );

      this.args.onDimensionsChanged(track);
    }
  }

  @action
  handleResize(event: Event) {
    if (this.args.isNewProvider) {
      const videoElement = <HTMLVideoElement>event.target;
      if (videoElement.videoHeight && videoElement.videoWidth) {
        const dimensions = {
          height: videoElement.videoHeight,
          width: videoElement.videoWidth,
        };
        this.args.onDimensionsChanged(null, dimensions);
      }
    }
  }
}
