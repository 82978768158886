import { inject as service } from '@ember/service';
import NewController from './new';
import { action } from '@ember/object';

export default class SettingsTeamsEditController extends NewController {
  @service ttAlert;

  successMessage = 'settings.teams.saved';

  @action
  deleteTeam() {
    this.ttAlert.confirm(
      this.intl.t('settings.teams.delete_team_are_you_sure'),
      () => {
        this.team.destroyRecord().then(() => {
          this.flashMessages.success(
            this.intl.t('settings.teams.team_deleted')
          );
          this.router.transitionTo('settings.teams');
        });
      }
    );
  }
}
