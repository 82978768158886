import Helper from '@ember/component/helper';

export function toSnakeCase([value]: [string]): string {
  return value
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_');
}

export default Helper.helper(toSnakeCase);
