export type ChartSetting = {
  nameKey: string;
  chartType: {
    name: string;
    icon: string;
  };
  baseProperty: string;
  additionalBaseProperty?: string;
  selectedProperties: string[];
  sortProperty?: string;
  sortDirection: 'asc' | 'desc';
  limit?: number;
  groupPercentage?: number;
  limitType: 'groupSmallValues' | 'normal' | 'hideEmpty' | null;
  filters?: string[];
  direction?: 'vertical' | 'horizontal';
};

export type ReportTemplate = {
  key: string;
  emoji: string;
  chartSettings: ChartSetting[];
  filterSettings: {
    filters: { [key: string]: any }[];
  };
};

export const REPORT_TEMPLATES: ReportTemplate[] = [
  {
    key: 'hires',
    emoji: '🎊',
    chartSettings: [
      {
        nameKey: 'hires_over_time',
        chartType: { name: 'line', icon: 'chart-line' },
        baseProperty: '{"id":"week","type":"date"}',
        selectedProperties: [
          '{"id":"week","type":"date"}',
          '{"id":"count_occurrences-hired","type":"count-occurrences"}',
        ],

        sortDirection: 'desc',
        limitType: null,
        filters: [],
      },
      {
        nameKey: 'hired_by_department',
        chartType: { name: 'pie', icon: 'chart-pie' },
        baseProperty: '{"id":"department_id","type":"model"}',
        selectedProperties: [
          '{"id":"department_id","type":"model"}',
          '{"id":"count_occurrences-hired","type":"count-occurrences"}',
        ],

        sortDirection: 'desc',
        limitType: 'groupSmallValues',
        groupPercentage: 1,
        filters: [],
      },
      {
        nameKey: 'hired_by_job',
        chartType: { name: 'bar', icon: 'chart-simple' },
        baseProperty: '{"id":"job_id","type":"model"}',
        selectedProperties: [
          '{"id":"job_id","type":"model"}',
          '{"id":"count_occurrences-hired","type":"count-occurrences"}',
        ],

        sortProperty:
          '{"id":"count_occurrences-hired","type":"count-occurrences"}',

        sortDirection: 'desc',
        limit: 10,
        limitType: null,
        filters: [],
        direction: 'horizontal',
      },
      {
        nameKey: 'hired_by_user',
        chartType: { name: 'bar', icon: 'chart-simple' },
        baseProperty: '{"id":"user_id","type":"model"}',
        selectedProperties: [
          '{"id":"user_id","type":"model"}',
          '{"id":"count_occurrences-hired","type":"count-occurrences"}',
        ],

        sortProperty:
          '{"id":"count_occurrences-hired","type":"count-occurrences"}',

        sortDirection: 'desc',
        limit: 10,
        limitType: null,
        filters: [],
        direction: 'horizontal',
      },
    ],

    filterSettings: { filters: [] },
  },
  {
    key: 'job_offers',
    emoji: '🤝',
    chartSettings: [
      {
        nameKey: 'by_user',
        chartType: { name: 'pie', icon: 'chart-pie' },
        baseProperty: '{"id":"user_id","type":"model"}',
        selectedProperties: [
          '{"id":"user_id","type":"model"}',
          '{"id":"count_occurrences-job_offer_sent","type":"count-occurrences"}',
        ],

        sortDirection: 'desc',
        limitType: 'groupSmallValues',
        groupPercentage: 1,
        filters: [],
      },
      {
        nameKey: 'conversion',
        chartType: { name: 'bar', icon: 'chart-simple' },
        baseProperty: '{"id":"job_id","type":"model"}',
        selectedProperties: [
          '{"id":"job_id","type":"model"}',
          '{"id":"count_occurrences-job_offer_sent","type":"count-occurrences"}',
          '{"id":"count_occurrences-job_offer_accepted","type":"count-occurrences"}',
        ],

        sortProperty:
          '{"id":"count_occurrences-job_offer_sent","type":"count-occurrences"}',

        sortDirection: 'desc',
        limit: 10,
        limitType: null,
        filters: [],
        direction: 'horizontal',
      },
      {
        nameKey: 'breakdown',
        chartType: { name: 'pie', icon: 'chart-pie' },
        baseProperty: '{"id":"job_offer_status","type":"base"}',
        selectedProperties: [
          '{"id":"job_offer_status","type":"base"}',
          '{"id":"count-job_offer_id","type":"count"}',
        ],

        sortDirection: 'desc',
        limitType: null,
        filters: [],
      },
    ],

    filterSettings: { filters: [] },
  },
  {
    key: 'promotions',
    emoji: '🔗',
    chartSettings: [
      {
        nameKey: 'visits',
        chartType: { name: 'line', icon: 'chart-line' },
        baseProperty: '{"id":"week","type":"date"}',
        additionalBaseProperty: '{"id":"promotion_id","type":"model"}',
        selectedProperties: [
          '{"id":"week","type":"date"}',
          '{"id":"promotion_id","type":"model"}',
          '{"id":"distinct_count-visits","type":"distinct-count"}',
        ],

        sortDirection: 'desc',
        limitType: null,
        filters: [],
      },
      {
        nameKey: 'hired',
        chartType: { name: 'pie', icon: 'chart-pie' },
        baseProperty: '{"id":"promotion_id","type":"model"}',
        selectedProperties: [
          '{"id":"promotion_id","type":"model"}',
          '{"id":"count_occurrences-hired","type":"count-occurrences"}',
        ],

        sortDirection: 'desc',
        limitType: null,
        filters: [],
      },
      {
        nameKey: 'most_visited',
        chartType: { name: 'pie', icon: 'chart-pie' },
        baseProperty: '{"id":"promotion_id","type":"model"}',
        selectedProperties: [
          '{"id":"promotion_id","type":"model"}',
          '{"id":"distinct_count-visits","type":"distinct-count"}',
        ],

        sortDirection: 'desc',
        limitType: 'groupSmallValues',
        groupPercentage: 1,
        filters: [],
      },
      {
        nameKey: 'conversion',
        chartType: { name: 'bar', icon: 'chart-simple' },
        baseProperty: '{"id":"promotion_id","type":"model"}',
        selectedProperties: [
          '{"id":"promotion_id","type":"model"}',
          '{"id":"distinct_count-visits","type":"distinct-count"}',
          '{"id":"count_occurrences-applied","type":"count-occurrences"}',
        ],

        sortProperty:
          '{"id":"count_occurrences-applied","type":"count-occurrences"}',

        sortDirection: 'desc',
        limit: 10,
        limitType: null,
        filters: [],
      },
    ],

    filterSettings: { filters: [] },
  },
  {
    key: 'career_site',
    emoji: '💻️',
    chartSettings: [
      {
        nameKey: 'conversion',
        chartType: { name: 'bar', icon: 'chart-simple' },
        baseProperty: '{"id":"job_id","type":"model"}',
        selectedProperties: [
          '{"id":"job_id","type":"model"}',
          '{"id":"distinct_count-visits","type":"distinct-count"}',
          '{"id":"count_occurrences-applied","type":"count-occurrences"}',
        ],

        sortProperty:
          '{"id":"count_occurrences-applied","type":"count-occurrences"}',

        sortDirection: 'desc',
        limit: 20,
        limitType: null,
        filters: [],
      },
      {
        nameKey: 'country_visits',
        chartType: { name: 'bar', icon: 'chart-simple' },
        baseProperty: '{"id":"geolocation_country","type":"customFormat"}',
        selectedProperties: [
          '{"id":"geolocation_country","type":"customFormat"}',
          '{"id":"distinct_count-visits","type":"distinct-count"}',
        ],

        sortProperty: '{"id":"distinct_count-visits","type":"distinct-count"}',
        sortDirection: 'desc',
        limit: 15,
        limitType: null,
        filters: [],
      },
      {
        nameKey: 'page_visits',
        chartType: { name: 'bar', icon: 'chart-simple' },
        baseProperty: '{"id":"page_id","type":"model"}',
        selectedProperties: [
          '{"id":"page_id","type":"model"}',
          '{"id":"distinct_count-visits","type":"distinct-count"}',
        ],

        sortProperty: '{"id":"distinct_count-visits","type":"distinct-count"}',
        sortDirection: 'desc',
        limit: 10,
        limitType: null,
        filters: [],
      },
      {
        nameKey: 'url_visits',
        chartType: { name: 'table', icon: 'table' },
        baseProperty: '{"id":"path","type":"base"}',
        selectedProperties: [
          '{"id":"path","type":"base"}',
          '{"id":"distinct_count-visits","type":"distinct-count"}',
        ],

        sortProperty: '{"id":"distinct_count-visits","type":"distinct-count"}',
        sortDirection: 'desc',
        limitType: null,
        filters: [],
      },
    ],

    filterSettings: { filters: [] },
  },
  {
    key: 'department',
    emoji: '🏢',
    chartSettings: [
      {
        nameKey: 'user_activities',
        chartType: { name: 'bar', icon: 'chart-simple' },
        baseProperty: '{"id":"user_id","type":"model"}',
        selectedProperties: [
          '{"id":"user_id","type":"model"}',
          '{"id":"count_occurrences-messages","type":"count-occurrences"}',
          '{"id":"count_occurrences-note","type":"count-occurrences"}',
          '{"id":"count_occurrences-score","type":"count-occurrences"}',
          '{"id":"count_occurrences-moved","type":"count-occurrences"}',
          '{"id":"count_occurrences-referral","type":"count-occurrences"}',
          '{"id":"count_occurrences-rejected_by_company","type":"count-occurrences"}',
        ],

        sortProperty:
          '{"id":"count_occurrences-messages","type":"count-occurrences"}',

        sortDirection: 'desc',
        limitType: null,
        filters: [],
        direction: 'horizontal',
      },
      {
        nameKey: 'hired_by_location',
        chartType: { name: 'pie', icon: 'chart-pie' },
        baseProperty: '{"id":"location_id","type":"model"}',
        selectedProperties: [
          '{"id":"location_id","type":"model"}',
          '{"id":"count_occurrences-hired","type":"count-occurrences"}',
        ],

        sortDirection: 'desc',
        limitType: 'groupSmallValues',
        groupPercentage: 1,
        filters: [],
      },
      {
        nameKey: 'recruitment_duration',
        chartType: { name: 'bar', icon: 'chart-simple' },
        baseProperty: '{"id":"month","type":"date"}',
        selectedProperties: [
          '{"id":"month","type":"date"}',
          '{"id":"average-time_to_hire","type":"average"}',
          '{"id":"average-time_to_reject","type":"average"}',
          '{"id":"average-time_in_stage","type":"average"}',
        ],

        sortDirection: 'desc',
        limitType: null,
        filters: [],
      },
      {
        nameKey: 'visits_from_sources',
        chartType: { name: 'pie', icon: 'chart-pie' },
        baseProperty: '{"id":"source","type":"base"}',
        selectedProperties: [
          '{"id":"source","type":"base"}',
          '{"id":"distinct_count-visits","type":"distinct-count"}',
        ],

        sortDirection: 'desc',
        limitType: 'groupSmallValues',
        groupPercentage: 1,
        filters: [],
      },
      {
        nameKey: 'visits_by_path',
        chartType: { name: 'table', icon: 'table' },
        baseProperty: '{"id":"path","type":"base"}',
        selectedProperties: [
          '{"id":"path","type":"base"}',
          '{"id":"distinct_count-visits","type":"distinct-count"}',
        ],

        sortProperty: '{"id":"distinct_count-visits","type":"distinct-count"}',
        sortDirection: 'desc',
        limitType: null,
        filters: [],
      },
    ],

    filterSettings: {
      filters: [{ department_id: { any: [] } }],
    },
  },
  {
    key: 'locations_comparison',
    emoji: '🗺️',
    chartSettings: [
      {
        nameKey: 'hires',
        chartType: { name: 'pie', icon: 'chart-pie' },
        baseProperty: '{"id":"location_id","type":"model"}',
        selectedProperties: [
          '{"id":"location_id","type":"model"}',
          '{"id":"count_occurrences-hired","type":"count-occurrences"}',
        ],

        sortDirection: 'desc',
        limitType: 'groupSmallValues',
        groupPercentage: 1,
        filters: [],
      },
      {
        nameKey: 'visits_over_time',
        chartType: { name: 'line', icon: 'chart-line' },
        baseProperty: '{"id":"week","type":"date"}',
        additionalBaseProperty: '{"id":"location_id","type":"model"}',
        selectedProperties: [
          '{"id":"week","type":"date"}',
          '{"id":"location_id","type":"model"}',
          '{"id":"distinct_count-visits","type":"distinct-count"}',
        ],

        sortDirection: 'desc',
        limitType: null,
        filters: [],
      },
      {
        nameKey: 'candidates',
        chartType: { name: 'table', icon: 'table' },
        baseProperty: '{"id":"location_id","type":"model"}',
        selectedProperties: [
          '{"id":"location_id","type":"model"}',
          '{"id":"collect-hired","type":"collect"}',
          '{"id":"collect-rejected_by_company","type":"collect"}',
          '{"id":"collect-sourced_candidates","type":"collect"}',
          '{"id":"collect-referral","type":"collect"}',
          '{"id":"collect-connected","type":"collect"}',
        ],

        sortProperty: '{"id":"collect-sourced_candidates","type":"collect"}',
        sortDirection: 'desc',
        limitType: null,
        filters: [],
      },
    ],

    filterSettings: { filters: [] },
  },
  {
    key: 'location',
    emoji: '📍',
    chartSettings: [
      {
        nameKey: 'hires_by_department',
        chartType: { name: 'pie', icon: 'chart-pie' },
        baseProperty: '{"id":"department_id","type":"model"}',
        selectedProperties: [
          '{"id":"department_id","type":"model"}',
          '{"id":"count_occurrences-hired","type":"count-occurrences"}',
        ],

        sortDirection: 'desc',
        limitType: 'groupSmallValues',
        groupPercentage: 1,
        filters: [],
      },
      {
        nameKey: 'visits_by_source',
        chartType: { name: 'pie', icon: 'chart-pie' },
        baseProperty: '{"id":"source","type":"base"}',
        selectedProperties: [
          '{"id":"source","type":"base"}',
          '{"id":"distinct_count-visits","type":"distinct-count"}',
        ],

        sortDirection: 'desc',
        limitType: 'groupSmallValues',
        groupPercentage: 1,
        filters: [],
      },
      {
        nameKey: 'job_offers',
        chartType: { name: 'bar', icon: 'chart-simple' },
        baseProperty: '{"id":"job_id","type":"model"}',
        additionalBaseProperty: '{"id":"job_offer_status","type":"base"}',
        selectedProperties: [
          '{"id":"job_id","type":"model"}',
          '{"id":"job_offer_status","type":"base"}',
          '{"id":"count-job_offer_id","type":"count"}',
        ],

        sortProperty: '{"id":"count-job_offer_id","type":"count"}',
        sortDirection: 'desc',
        limit: 10,
        limitType: null,
        filters: [],
        direction: 'horizontal',
      },
      {
        nameKey: 'applications_by_job',
        chartType: { name: 'bar', icon: 'chart-simple' },
        baseProperty: '{"id":"job_id","type":"model"}',
        selectedProperties: [
          '{"id":"job_id","type":"model"}',
          '{"id":"count_occurrences-applied","type":"count-occurrences"}',
        ],

        sortProperty:
          '{"id":"count_occurrences-applied","type":"count-occurrences"}',

        sortDirection: 'desc',
        limit: 10,
        limitType: null,
        filters: [],
      },
      {
        nameKey: 'top_10_recruiters',
        chartType: { name: 'bar', icon: 'chart-simple' },
        baseProperty: '{"id":"user_id","type":"model"}',
        selectedProperties: [
          '{"id":"user_id","type":"model"}',
          '{"id":"count_occurrences-referral","type":"count-occurrences"}',
          '{"id":"count_occurrences-hired","type":"count-occurrences"}',
          '{"id":"count_occurrences-rejected_by_company","type":"count-occurrences"}',
          '{"id":"count_occurrences-messages","type":"count-occurrences"}',
          '{"id":"count_occurrences-note","type":"count-occurrences"}',
          '{"id":"count_occurrences-moved","type":"count-occurrences"}',
        ],

        sortProperty:
          '{"id":"count_occurrences-hired","type":"count-occurrences"}',

        sortDirection: 'desc',
        limit: 10,
        limitType: null,
        filters: [],
        direction: 'horizontal',
      },
    ],

    filterSettings: {
      filters: [{ location_id: { any: [] } }],
    },
  },

  {
    key: 'top10',
    emoji: '🔟',
    chartSettings: [
      {
        nameKey: 'visited_pages',
        chartType: { name: 'bar', icon: 'chart-simple' },
        baseProperty: '{"id":"page_id","type":"model"}',
        selectedProperties: [
          '{"id":"page_id","type":"model"}',
          '{"id":"distinct_count-visits","type":"distinct-count"}',
        ],

        sortProperty: '{"id":"distinct_count-visits","type":"distinct-count"}',
        sortDirection: 'desc',
        limit: 10,
        limitType: null,
        filters: [],
      },
      {
        nameKey: 'popular_departments',
        chartType: { name: 'bar', icon: 'chart-simple' },
        baseProperty: '{"id":"department_id","type":"model"}',
        selectedProperties: [
          '{"id":"department_id","type":"model"}',
          '{"id":"count_occurrences-applied","type":"count-occurrences"}',
        ],

        sortProperty:
          '{"id":"count_occurrences-applied","type":"count-occurrences"}',

        sortDirection: 'desc',
        limit: 10,
        limitType: null,
        filters: [],
        direction: 'horizontal',
      },
      {
        nameKey: 'reviewers',
        chartType: { name: 'bar', icon: 'chart-simple' },
        baseProperty: '{"id":"user_id","type":"model"}',
        selectedProperties: [
          '{"id":"user_id","type":"model"}',
          '{"id":"count-review_rating","type":"count"}',
        ],

        sortProperty: '{"id":"count-review_rating","type":"count"}',
        sortDirection: 'desc',
        limit: 10,
        limitType: null,
        filters: [],
        direction: 'horizontal',
      },
      {
        nameKey: 'recruiters',
        chartType: { name: 'bar', icon: 'chart-simple' },
        baseProperty: '{"id":"user_id","type":"model"}',
        selectedProperties: [
          '{"id":"user_id","type":"model"}',
          '{"id":"count_occurrences-hired","type":"count-occurrences"}',
        ],

        sortProperty:
          '{"id":"count_occurrences-hired","type":"count-occurrences"}',

        sortDirection: 'desc',
        limit: 10,
        limitType: null,
        filters: [],
        direction: 'horizontal',
      },
    ],

    filterSettings: { filters: [] },
  },
  {
    key: 'mobile',
    emoji: '📱',
    chartSettings: [
      {
        nameKey: 'sources',
        chartType: { name: 'pie', icon: 'chart-pie' },
        baseProperty: '{"id":"source","type":"base"}',
        selectedProperties: [
          '{"id":"source","type":"base"}',
          '{"id":"distinct_count-visits","type":"distinct-count"}',
        ],

        sortDirection: 'desc',
        limitType: 'groupSmallValues',
        groupPercentage: 1,
        filters: [],
      },
      {
        nameKey: 'conversion',
        chartType: { name: 'line', icon: 'chart-line' },
        baseProperty: '{"id":"month","type":"date"}',
        selectedProperties: [
          '{"id":"month","type":"date"}',
          '{"id":"distinct_count-visits","type":"distinct-count"}',
          '{"id":"count_occurrences-applied","type":"count-occurrences"}',
        ],

        sortDirection: 'desc',
        limitType: null,
        filters: [],
      },
      {
        nameKey: 'visited_pages',
        chartType: { name: 'bar', icon: 'chart-simple' },
        baseProperty: '{"id":"page_id","type":"model"}',
        selectedProperties: [
          '{"id":"page_id","type":"model"}',
          '{"id":"distinct_count-visits","type":"distinct-count"}',
        ],

        sortProperty: '{"id":"distinct_count-visits","type":"distinct-count"}',
        sortDirection: 'desc',
        limit: 10,
        limitType: null,
        filters: [],
      },
    ],

    filterSettings: { filters: [{ device_type: { any: ['mobile'] } }] },
  },
];
