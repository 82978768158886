import { action, set } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import filterList from 'teamtailor/utils/filter-list';
import {
  TABLE_COLUMNS,
  TABLE_LOADING_COLUMNS,
} from 'teamtailor/constants/canned-response';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
// eslint-disable-next-line ember/no-mixins
import SearchQuery from 'teamtailor/mixins/search-query';
import { get } from 'teamtailor/utils/get';

export default class IndexController extends Controller.extend(SearchQuery) {
  queryParams = [
    'query',
    {
      cannedResponseTagId: 'canned_response_tag_id',
      sortColumn: 'sort_column',
      sortDirection: 'sort_direction',
    },
  ];

  sorting = ['name'];

  @service current;

  @computedLocalStorage(
    Boolean,
    'SettingsCannedResponsesIndex.showFilterSidebar',
    false
  )
  showFilterSidebar;

  filterAttributes = ['name', 'subject'];

  get loadingColumns() {
    return [
      ...TABLE_LOADING_COLUMNS,
      ...(this.displayLanguageColumn ? [{ width: 's' }] : []),
    ];
  }

  get columns() {
    return [
      ...TABLE_COLUMNS,
      ...(this.displayLanguageColumn
        ? [
            {
              label: 'common.languages',
              sortable: false,
              sortOrder: 4,
            },
          ]
        : []),
    ];
  }

  get listItems() {
    return this.model.filter((template) => {
      return (
        !template.consentMissingReminder &&
        !template.consentExpiredReminder &&
        !template.consentExtendReminder
      );
    });
  }

  get sortedCannedResponses() {
    return this.filteredList.sort(this.sorting);
  }

  get displayLanguageColumn() {
    const { company } = this.current;
    return (
      company.hasFeature('career_site_languages') &&
      company.careerSites.length > 1
    );
  }

  get totalCount() {
    return this.model?.meta?.total_count;
  }

  get filteredList() {
    return filterList(this.filterAttributes, this.listItems, this.search);
  }

  @action
  selectItem(attribute, selected) {
    set(this, attribute, selected ? get(selected, 'id') : null);
  }
}
