import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<dl\n  class=\"mx-auto mt-24 flex w-full max-w-[300px] flex-col gap-10 self-stretch rounded-6 border border-neutral px-12 py-10\"\n>\n  {{#each @modifiers as |modifier|}}\n    <div class=\"flex items-center justify-between\">\n      <dt class=\"body-text-xs-semibold flex items-center\">\n        <span class=\"text-start\">\n          {{t (this.intlPathFor modifier.key)}}\n        </span>\n        <span class=\"pl-4\">\n          <Icon @icon=\"circle-info\" @size=\"16\" class=\"text-neutral-weak\" />\n          <EmberTooltip\n            @text={{t (this.intlDescriptionPathFor modifier.key)}}\n            @tooltipClass=\"text-start max-w-[300px]\"\n          />\n        </span>\n      </dt>\n      <dd class=\"pl-4\">\n        <Stage::OddsOfHiring::ModifierGrade @modifierValue={{modifier.value}} />\n      </dd>\n    </div>\n  {{/each}}\n</dl>", {"contents":"<dl\n  class=\"mx-auto mt-24 flex w-full max-w-[300px] flex-col gap-10 self-stretch rounded-6 border border-neutral px-12 py-10\"\n>\n  {{#each @modifiers as |modifier|}}\n    <div class=\"flex items-center justify-between\">\n      <dt class=\"body-text-xs-semibold flex items-center\">\n        <span class=\"text-start\">\n          {{t (this.intlPathFor modifier.key)}}\n        </span>\n        <span class=\"pl-4\">\n          <Icon @icon=\"circle-info\" @size=\"16\" class=\"text-neutral-weak\" />\n          <EmberTooltip\n            @text={{t (this.intlDescriptionPathFor modifier.key)}}\n            @tooltipClass=\"text-start max-w-[300px]\"\n          />\n        </span>\n      </dt>\n      <dd class=\"pl-4\">\n        <Stage::OddsOfHiring::ModifierGrade @modifierValue={{modifier.value}} />\n      </dd>\n    </div>\n  {{/each}}\n</dl>","moduleName":"teamtailor/components/stage/odds-of-hiring/modifier-explanation.hbs","parseOptions":{"srcName":"teamtailor/components/stage/odds-of-hiring/modifier-explanation.hbs"}});
import Component from '@glimmer/component';
import {
  Modifier,
  ModifierType,
} from 'teamtailor/components/stage/odds-of-hiring/index';

interface ModifierExplanationArgs {
  modifiers: Modifier[];
}

export default class ModifierExplanationComponent extends Component<ModifierExplanationArgs> {
  intlPathFor = (modifierType: ModifierType) =>
    `job.odds_of_hiring.hiring_forecast.modifier.modifier_type_${modifierType.toLowerCase()}`;

  intlDescriptionPathFor = (modifierType: ModifierType) =>
    `job.odds_of_hiring.hiring_forecast.modifier.modifier_description_${modifierType.toLowerCase()}`;
}
