import { helper } from '@ember/component/helper';
import moment from 'moment-timezone';

export function formatSecondsAsDecimalDays(args) {
  const [seconds, decimalPoints] = args;

  if (seconds !== null) {
    return parseFloat(
      moment
        .duration(seconds, 'seconds')
        .as('days')
        .toFixed(decimalPoints || 0)
    );
  } else {
    return 0;
  }
}

export default helper(formatSecondsAsDecimalDays);
