import Route from '@ember/routing/route';
import { hash } from 'rsvp';

export default class JobsJobPromoteChannelExternalRoute extends Route {
  queryParams = {
    currency: { refreshModel: false },
  };

  model(params) {
    const channel = this.modelFor('jobs.job.promote.channel');
    const job = this.modelFor('jobs.job');

    return hash({
      promotion: job.promotions.createRecord({
        channel,
        currency: params.currency,
      }),

      channel,
      job,
    });
  }

  deactivate() {
    if (this.controller.model.promotion.isNew) {
      this.controller.model.promotion.deleteRecord();
    }
  }
}
