import Model, { belongsTo, attr, type AsyncBelongsTo } from '@ember-data/model';
import { CandidateModel, JobModel, UserModel } from 'teamtailor/models';

export default class ScorecardCommentModel extends Model {
  @belongsTo('candidate') declare candidate: AsyncBelongsTo<CandidateModel>;
  @belongsTo('job') declare job: AsyncBelongsTo<JobModel>;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;

  @attr('string') declare comment: string | null;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'scorecard-comment': ScorecardCommentModel;
  }
}
