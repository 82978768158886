import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    cannedResponse: { serialize: false },
    rowOrder: { serialize: false },
    interviewKit: { serialize: false },
  },
});
