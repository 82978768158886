import ArrayProxy from '@ember/array/proxy';
import Service from '@ember/service';
import { query } from 'ember-data-resources';

export default class TagQueryService extends Service {
  declare taggableType?: string;

  query = query(this, 'tag', () => ({ taggable_type: this.taggableType }));

  get tags(): ArrayProxy<unknown> | undefined {
    return this.query.records;
  }

  get isLoading(): boolean {
    return this.query.isLoading;
  }

  get hasRun(): boolean {
    return this.query.hasRan;
  }

  refresh(): void {
    this.query.retry();
  }
}

declare module '@ember/service' {
  interface Registry {
    'tag-query': TagQueryService;
  }
}
