import { tracked } from '@glimmer/tracking';
import JobApplicationModel from 'teamtailor/models/job-application';
import CandidateModalController from 'teamtailor/controllers/candidate-modal';

export default class JobsJobCompareCandidatesCandidate extends CandidateModalController {
  @tracked jobApplication: JobApplicationModel | null = null;

  backRoute = 'jobs.job.compare-candidates';
}

declare module '@ember/controller' {
  interface Registry {
    'jobs.job.compare-candidates.candidate': JobsJobCompareCandidatesCandidate;
  }
}
