import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{did-insert this.loadData @queryParamArg}}\n{{did-update this.loadData @queryParamArg}}\n{{yield (hash data=this.data loading=this.loading)}}", {"contents":"{{did-insert this.loadData @queryParamArg}}\n{{did-update this.loadData @queryParamArg}}\n{{yield (hash data=this.data loading=this.loading)}}","moduleName":"teamtailor/components/meeting/edit-loader.hbs","parseOptions":{"srcName":"teamtailor/components/meeting/edit-loader.hbs"}});
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Store from '@ember-data/store';

import Component from '@glimmer/component';
import { EditMeetingQueryParams } from 'teamtailor/routes/meetings/edit';
import MeetingLoaderEditGraphql, {
  Response,
} from 'teamtailor/services/meeting-loader-edit-graphql';

interface Args {
  queryParamArg: EditMeetingQueryParams;
}

export default class EditLoader extends Component<Args> {
  @service declare meetingLoaderEditGraphql: MeetingLoaderEditGraphql;
  @service declare store: Store;
  @tracked declare data?: Response;
  @tracked loading = true;

  @action
  async loadData() {
    this.loading = true;

    let meetingId = this.args.queryParamArg.meeting_id;
    const meetingEventId = this.args.queryParamArg.meeting_event_id;

    if (!meetingId) {
      const meetingEvent = await this.store.findRecord(
        'meeting-event',
        meetingEventId
      );

      meetingId = meetingEvent.meetingId.toString();
    }

    this.data = await this.meetingLoaderEditGraphql.loadData(meetingId);
    this.loading = false;
  }
}
