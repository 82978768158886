import moment from 'moment-timezone';
import jsonToCsvConverter from 'teamtailor/utils/json-to-csv-converter';
import saveFile from 'teamtailor/utils/save-file';

export default function jsonToCsvFile(
  basename,
  startDate,
  endDate,
  rows,
  callback
) {
  const content = callback ? rows.map((row) => callback(row)) : rows;

  const filename = buildFilename(basename, startDate, endDate);
  saveFile(filename, jsonToCsvConverter(content, false), 'text/csv');
}

function buildFilename(basename, startDate, endDate) {
  const filename = [
    basename,
    moment(startDate).format('YYYYMMDD'),
    moment(endDate).format('YYYYMMDD'),
  ].join('-');

  return `${filename}.csv`;
}
