import Controller from '@ember/controller';
import { get, action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ChannelActivationBaseController extends Controller {
  @service router;
  @service ttAlert;
  @service intl;
  @service flashMessages;

  @action
  async confirmDeactivate(activation) {
    this.ttAlert.customConfirm({
      title: this.intl.t(
        'settings.integrations.marketplace_activations.confirm_deactivation'
      ),

      text: this.intl.t(
        'settings.integrations.marketplace_activations.deactivation_description',
        {
          activationName:
            get(activation, 'channel.name') || get(activation, 'partner.name'),
        }
      ),

      confirmButtonText: this.intl.t(
        'settings.integrations.marketplace_activations.deactivation_confirmation'
      ),

      cancelButtonText: this.intl.t(
        'settings.integrations.marketplace_activations.deactivation_cancel'
      ),

      confirmCallback: async () => {
        await activation.destroyRecord();
        this.flashMessages.success(
          this.intl.t(
            'settings.integrations.marketplace_activations.channel_deleted',
            {
              activationName:
                get(activation, 'channel.name') ||
                get(activation, 'partner.name'),
            }
          )
        );
        this.router.transitionTo(
          'settings.integrations.marketplace-activations'
        );
      },

      cancelCallback: () => {},
    });
  }

  @action
  async save() {
    try {
      await this.model.save();
      this.flashMessages.success(
        this.intl.t('settings.integrations.channel_activations.updated')
      );
      this.router.transitionTo('settings.integrations.marketplace-activations');
    } catch (e) {
      return false;
    }

    return true;
  }

  @action
  closeModal() {
    this.router.transitionTo('settings.integrations.marketplace-activations');
  }
}
