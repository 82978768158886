import Controller from '@ember/controller';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class SettingsIntegrationsPartnerActivationOauthController extends Controller {
  @service ttAlert;
  @service intl;
  @service router;
  @service store;
  @service flashMessages;

  get partner() {
    return this.model.partner;
  }

  handleOauthActivation = task(async (callback) => {
    await callback();
  });

  get authComponent() {
    return ['deel'].includes(get(this.partner, 'name')?.toLowerCase())
      ? 'pusher-auth'
      : 'auth-popup';
  }

  @action async onOauthSuccess({ authorization: data }) {
    if (data?.pusherAuth) {
      const { type, id } = data;
      await this.store.findRecord(type, id);
      return this.flashMessages.success(
        this.intl.t('marketplace.partner_reconnected', {
          subject: get(this, 'partner.name'),
        })
      );
    }

    try {
      const authorization = await this.store.createRecord(
        'authorization',
        Object.assign(data)
      );

      await authorization.save();

      await this.model.save();

      this.flashMessages.success(
        this.intl.t('marketplace.partner_reconnected', {
          subject: this.partner.name,
        })
      );
      return this.router.transitionTo(
        'settings.integrations.marketplace-activations'
      );
    } catch (err) {
      return this.flashMessages.error(
        this.intl.t('errors.something_went_wrong')
      );
    }
  }

  @action async onOauthFailure() {
    this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
  }

  @action
  delete() {
    this.ttAlert.customConfirm({
      title: this.intl.t('common.are_you_sure'),
      text: this.intl.t(
        'settings.integrations.partner_activations.deactivate_alert.text'
      ),

      confirmButtonText: this.intl.t(
        'settings.integrations.partner_activations.deactivate_alert.confirm'
      ),

      cancelButtonText: this.intl.t('common.cancel'),
      confirmCallback: async () => {
        await this.model.destroyRecord();
        this.flashMessages.success(
          this.intl.t(
            'settings.integrations.partner_activations.deactivate_alert.partner_deleted'
          )
        );
        this.router.transitionTo(
          'settings.integrations.marketplace-activations'
        );
      },

      cancelCallback: () => {},
    });
  }

  @action
  closeModal() {
    this.router.transitionTo('settings.integrations.marketplace-activations');
  }
}
