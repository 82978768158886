import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex flex-row items-center\">\n  <PieChart\n    @title={{@serie.title}}\n    @data={{@serie.data}}\n    @colors={{this.colors}}\n  />\n  <ChartLegend @titles={{this.titles}} @colors={{this.colors}} />\n</div>", {"contents":"<div class=\"flex flex-row items-center\">\n  <PieChart\n    @title={{@serie.title}}\n    @data={{@serie.data}}\n    @colors={{this.colors}}\n  />\n  <ChartLegend @titles={{this.titles}} @colors={{this.colors}} />\n</div>","moduleName":"teamtailor/components/single-pie-chart.hbs","parseOptions":{"srcName":"teamtailor/components/single-pie-chart.hbs"}});
import Component from '@glimmer/component';
import colors from 'teamtailor/components/insights/charts/colors';

export default class SinglePieChart extends Component {
  get colors() {
    return colors.slice(this.args.colorOffset ?? 0);
  }

  get titles() {
    return this.args.serie.data.map((data) => data.title);
  }
}
