import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"root\">\n  <button\n    local-class=\"btn {{unless @checked 'btn--unchecked'}}\"\n    type=\"button\"\n    style={{this.colorStyles}}\n    {{on \"click\" (stop-propagation @onToggle)}}\n  >\n    {{#if @checked}}\n      <Icon local-class=\"checkmark\" @icon=\"check\" @size=\"16\" />\n    {{/if}}\n  </button>\n\n  <button\n    type=\"button\"\n    local-class=\"label\"\n    {{on \"click\" (stop-propagation @onToggle)}}\n  >\n    {{yield}}\n  </button>\n</div>", {"contents":"<div local-class=\"root\">\n  <button\n    local-class=\"btn {{unless @checked 'btn--unchecked'}}\"\n    type=\"button\"\n    style={{this.colorStyles}}\n    {{on \"click\" (stop-propagation @onToggle)}}\n  >\n    {{#if @checked}}\n      <Icon local-class=\"checkmark\" @icon=\"check\" @size=\"16\" />\n    {{/if}}\n  </button>\n\n  <button\n    type=\"button\"\n    local-class=\"label\"\n    {{on \"click\" (stop-propagation @onToggle)}}\n  >\n    {{yield}}\n  </button>\n</div>","moduleName":"teamtailor/components/insights/atoms/checkbox.hbs","parseOptions":{"srcName":"teamtailor/components/insights/atoms/checkbox.hbs"}});
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import colors from 'teamtailor/components/insights/charts/colors';

export default class extends Component {
  get colorStyles() {
    const color = this.args.color || colors[this.args.index % colors.length];
    if (this.args.checked) {
      return htmlSafe(`background-color: ${color}`);
    } else {
      return htmlSafe('background-color: white;');
    }
  }
}
