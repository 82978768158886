import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Molecules::Sources\n  ...attributes\n  @data={{this.sources}}\n  @onShowExportModal={{this.onShowExportModal}}\n  @loading={{or (not this.fetchData.lastSuccessful) this.fetchData.isRunning}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>", {"contents":"<Insights::Molecules::Sources\n  ...attributes\n  @data={{this.sources}}\n  @onShowExportModal={{this.onShowExportModal}}\n  @loading={{or (not this.fetchData.lastSuccessful) this.fetchData.isRunning}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>","moduleName":"teamtailor/components/insights/widgets/job-sources.hbs","parseOptions":{"srcName":"teamtailor/components/insights/widgets/job-sources.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isEqual } from '@ember/utils';
import { gql } from '@apollo/client/core';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import AverageRating, {
  fetchMissingAverageRatings,
} from 'teamtailor/utils/average-rating';
import uniq from 'teamtailor/utils/uniq';

const SOURCE_QUERY = gql`
  query JobSourcesQuery($dateRange: DateRangeAttributes!, $jobIds: [ID!]) {
    eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [APPLIED, HIRED]
    ) {
      sources: aggregated(groupBy: [JOB_APPLICATION_SOURCE]) {
        name: jobApplicationSource
        hiredCandidates: collect(
          field: CANDIDATE_ID
          filters: { eventType: { equals: HIRED } }
        )
        candidates: collect(
          field: CANDIDATE_ID
          filters: { eventType: { equals: APPLIED } }
        )
      }
    }

    pageviewQuery(dateRange: $dateRange, jobIds: $jobIds) {
      pageviews: aggregated(groupBy: SOURCE) {
        name: source
        count
      }
    }
  }
`;

const EXPORT_TABLE_COLUMNS = [
  { type: 'text', propertyName: 'name', headerKey: 'candidate.source' },
  {
    type: 'number',
    propertyName: 'pageviews',
    headerKey: 'insights.common.pageviews',
  },
  {
    type: 'number',
    propertyName: 'applications',
    headerKey: 'insights.common.applications',
  },
  {
    type: 'number',
    propertyName: 'hires',
    headerKey: 'insights.users.index.hires',
  },
  {
    type: 'average-rating',
    propertyName: 'averageRating',
    headerKey: 'candidate.rating',
  },
];

export default class InsightsWidgetsJobSources extends Component {
  @service store;
  @service insights;
  @service insightsExport;
  @service intl;

  get sources() {
    return this.fetchData.lastSuccessful?.value?.sources || [];
  }

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      'insights.common.sources',
      `job-${this.args.job.id}`,
      EXPORT_TABLE_COLUMNS,
      this.retrieveExportData,
      this.args.dateRange,
      { sortKey: 'pageviews', createdAt: this.args.job.createdAt }
    );
  }

  @action
  async retrieveExportData(dateRange) {
    const {
      eventQuery: { sources },
      pageviewQuery: { pageviews },
    } = await this.queryForData(dateRange);

    const combinedSources = sources.map((source) => ({
      ...source,
      name: source.name?.toLowerCase() || '(direct)',
    }));
    pageviews.forEach((source) => {
      const name = source.name?.toLowerCase() || '(direct)';
      const existingSource = combinedSources.find((item) => item.name === name);
      if (existingSource) {
        existingSource.pageviews = source.count;
      } else {
        combinedSources.push({
          name,
          pageviews: source.count,
          candidates: [],
          hiredCandidates: [],
        });
      }
    });

    const rows = combinedSources.map((row) => {
      const candidateIds = uniq(
        [...row.candidates, ...row.hiredCandidates].map(Number)
      );

      const averageRating = new AverageRating(this.store, candidateIds);

      return {
        name: isEqual(row.name, '(direct)')
          ? this.intl.t('insights.molecules.sources.direct.title')
          : row.name,

        pageviews: row.pageviews,
        applications: row.candidates.length,
        hires: row.hiredCandidates.length,
        averageRating,
        candidateIds,
      };
    });

    await fetchMissingAverageRatings(rows);
    return rows;
  }

  @action
  queryForData(dateRange) {
    const { job } = this.args;

    return this.insights.query({
      query: SOURCE_QUERY,
      variables: {
        dateRange: dateRange.asObject,
        jobIds: [job.id],
      },

      context: { jobId: job.id },
    });
  }

  fetchData = dropTask(async () => {
    const {
      pageviewQuery: { pageviews },
    } = await this.queryForData(this.args.dateRange);

    const groupedSources = pageviews.reduce((acc, item) => {
      const name = item.name?.toLowerCase() || '(direct)';
      acc[name] = acc[name] + item.count || item.count;
      return acc;
    }, {});

    const [a, b, c, d, ...rest] = Object.keys(groupedSources)
      .map((name) => {
        return {
          name: isEqual(name, '(direct)')
            ? this.intl.t('insights.molecules.sources.direct.title')
            : name,

          value: groupedSources[name],
        };
      })
      .sortBy('value')
      .reverse();

    const other = {
      name: 'Other',
      value: rest.mapBy('value').reduce((acc, value) => acc + value, 0),
    };

    return {
      sources: [a, b, c, d, other.value === 0 ? null : other].compact(),
    };
  });
}
