import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Molecules::ActivityBreakdown\n  ...attributes\n  @onShowExportModal={{this.onShowExportModal}}\n  @stages={{this.stages}}\n  @data={{this.data}}\n  @loading={{or (not this.fetchData.lastSuccessful) this.fetchData.isRunning}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>", {"contents":"<Insights::Molecules::ActivityBreakdown\n  ...attributes\n  @onShowExportModal={{this.onShowExportModal}}\n  @stages={{this.stages}}\n  @data={{this.data}}\n  @loading={{or (not this.fetchData.lastSuccessful) this.fetchData.isRunning}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>","moduleName":"teamtailor/components/insights/widgets/user-activity.hbs","parseOptions":{"srcName":"teamtailor/components/insights/widgets/user-activity.hbs"}});
import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { gql } from '@apollo/client/core';
import activityKeys from 'teamtailor/components/insights/charts/activity-keys';
import { activityTypeColors } from 'teamtailor/components/insights/charts/colors';

const DATA_QUERY = gql`
  query EmployeeUserActivityQuery(
    $dateRange: DateRangeAttributes!
    $userId: ID!
  ) {
    eventQuery(
      dateRange: $dateRange
      filters: { userId: { equals: $userId } }
    ) {
      aggregated(groupBy: DATE) {
        date
        notes: countOccurrences(filters: { eventType: { equals: NOTE } })
        sources: countOccurrences(filters: { eventType: { equals: SOURCED } })
        reviews: countOccurrences(filters: { eventType: { equals: REVIEW } })
        messages: countOccurrences(filters: { eventType: { equals: MESSAGE } })
        moves: countOccurrences(filters: { eventType: { equals: MOVED } })
        rejections: countOccurrences(
          filters: { eventType: { equals: REJECTED } }
        )
        hires: countOccurrences(filters: { eventType: { equals: HIRED } })
        interviews: countOccurrences(
          filters: { eventType: { equals: INTERVIEW_CREATED } }
        )
        totalActivities: count
      }
    }
  }
`;

const EXPORT_TABLE_COLUMNS = [
  {
    type: 'date',
    propertyName: 'date',
    headerKey: 'common.date',
  },
  {
    type: 'number',
    propertyName: 'totalActivities',
    headerKey: 'insights.users.common.total_activities',
  },
  {
    type: 'number',
    propertyName: 'moves',
    headerKey: 'insights.users.common.moves',
  },
  {
    type: 'number',
    propertyName: 'hires',
    headerKey: 'insights.users.common.hires',
  },
  {
    type: 'number',
    propertyName: 'rejections',
    headerKey: 'insights.users.common.rejections',
  },
  {
    type: 'number',
    propertyName: 'sources',
    headerKey: 'insights.users.common.sources',
  },
  {
    type: 'number',
    propertyName: 'notes',
    headerKey: 'insights.users.common.notes',
  },
  {
    type: 'number',
    propertyName: 'messages',
    headerKey: 'insights.users.common.messages',
  },
  {
    type: 'number',
    propertyName: 'reviews',
    headerKey: 'insights.users.common.reviews',
  },
  {
    type: 'number',
    propertyName: 'interviews',
    headerKey: 'insights.users.common.interviews',
  },
];

export default class InsightsWidgetsUserActivity extends Component {
  @service insights;
  @service insightsExport;
  @service intl;

  get stages() {
    return this.fetchData.lastSuccessful?.value?.stages;
  }

  get data() {
    return this.fetchData.lastSuccessful?.value?.data;
  }

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      'insights.molecules.activity_breakdown.title',
      `employee-${this.args.user.id}`,
      EXPORT_TABLE_COLUMNS,
      this.retrieveExportData,
      this.args.dateRange
    );
  }

  @action
  retrieveExportData(dateRange) {
    return this.queryForData(dateRange);
  }

  @action
  async queryForData(dateRange) {
    const { user } = this.args;
    const userId = get(user, 'id');

    const { eventQuery: userResponse } = await this.insights.query({
      query: DATA_QUERY,
      variables: {
        userId,
        dateRange: dateRange.asObject,
      },
    });

    return userResponse.aggregated.map((activitiesByDate) => ({
      ...activitiesByDate,
    }));
  }

  fetchData = dropTask(async () => {
    const activityOverview = await this.queryForData(this.args.dateRange);
    const activities = activityKeys
      .map(({ name }) => ({
        name: this.intl.t(`insights.users.common.${name}`),
        value: [
          activityOverview.reduce((acc, activities) => {
            acc += activities[name];
            return acc;
          }, 0),
        ],

        color: activityTypeColors[name],
      }))
      .filter((activity) => !isNaN(activity.value[0]) && activity.value[0] > 0);

    return {
      stages: activities,
    };
  });
}
