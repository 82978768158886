import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield this.isFirstRender}}", {"contents":"{{yield this.isFirstRender}}","moduleName":"teamtailor/components/first-render.hbs","parseOptions":{"srcName":"teamtailor/components/first-render.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { scheduleOnce } from '@ember/runloop';

export default class FirstRenderComponent extends Component {
  @tracked isFirstRender = true;

  constructor() {
    super(...arguments);
    scheduleOnce('afterRender', this, this.setIsFirstRender);
  }

  setIsFirstRender() {
    this.isFirstRender = false;
  }
}
