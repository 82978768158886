import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Dropdown::SelectScrollWrapper\n  @fetchPageTask={{this.fetchCandidatesTask}}\n  as |sw|\n>\n  <Core::Select\n    @appearance=\"tertiary\"\n    @isMultiple={{false}}\n    @optionType=\"candidate\"\n    @options={{sw.options}}\n    @searchEnabled={{true}}\n    @emptyText={{if\n      (is-empty sw.searchTerm)\n      (t \"components.meetings.event_scheduler.candidate.type_to_get_results\")\n      null\n    }}\n    @onSearch={{perform sw.searchTask}}\n    @onSelect={{@changeCandidate}}\n    @selected={{@selected}}\n    @hideOptionCheck={{true}}\n    @isFullWidth={{true}}\n    @placeholder={{t\n      \"components.meetings.event_scheduler.candidate.choose_candidate\"\n    }}\n    @searchPlaceholder={{t \"search.candidate.search_candidate\"}}\n    @size=\"large\"\n    as |Item|\n  >\n    <Item />\n  </Core::Select>\n</Core::Dropdown::SelectScrollWrapper>", {"contents":"<Core::Dropdown::SelectScrollWrapper\n  @fetchPageTask={{this.fetchCandidatesTask}}\n  as |sw|\n>\n  <Core::Select\n    @appearance=\"tertiary\"\n    @isMultiple={{false}}\n    @optionType=\"candidate\"\n    @options={{sw.options}}\n    @searchEnabled={{true}}\n    @emptyText={{if\n      (is-empty sw.searchTerm)\n      (t \"components.meetings.event_scheduler.candidate.type_to_get_results\")\n      null\n    }}\n    @onSearch={{perform sw.searchTask}}\n    @onSelect={{@changeCandidate}}\n    @selected={{@selected}}\n    @hideOptionCheck={{true}}\n    @isFullWidth={{true}}\n    @placeholder={{t\n      \"components.meetings.event_scheduler.candidate.choose_candidate\"\n    }}\n    @searchPlaceholder={{t \"search.candidate.search_candidate\"}}\n    @size=\"large\"\n    as |Item|\n  >\n    <Item />\n  </Core::Select>\n</Core::Dropdown::SelectScrollWrapper>","moduleName":"teamtailor/components/meeting/candidate-dropdown.hbs","parseOptions":{"srcName":"teamtailor/components/meeting/candidate-dropdown.hbs"}});
import { enqueueTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import Component from '@glimmer/component';

export default class CandidateDropdown extends Component {
  @service store;
  @service intl;

  fetchCandidatesTask = enqueueTask(async ({ searchTerm: query, page }) => {
    let store = get(this, 'store');
    const params = Object.assign(get(this, 'searchParams') || {}, {
      page: page || get(this, 'page'),
      query,
    });

    let candidates = await store.query('candidate', params);

    if (page === 1 && this.args.defaultOptionPlaceholder) {
      let { meta } = candidates;
      candidates = [
        {
          id: '',
          nameOrEmail: this.args.defaultOptionPlaceholder,
        },
        ...candidates.toArray(),
      ];
      set(candidates, 'meta', meta);
    }

    return candidates;
  });
}
