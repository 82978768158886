import { set } from '@ember/object';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import { Changeset as changeset } from 'ember-changeset';
import DeactivateController from 'teamtailor/controllers/settings/security/email-password/2fa-deactivate';
import CompanyModel from 'teamtailor/models/company';
import Current from 'teamtailor/services/current';
import { Transition } from 'teamtailor/utils/type-utils';

export default class SettingsSecurityEmailPassword2faDeactivate extends ScrollToTopRoute {
  @service declare current: Current;

  model(): CompanyModel {
    return this.current.company;
  }

  setupController(
    controller: DeactivateController,
    model: CompanyModel,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);
    set(controller, 'changeset', changeset(model));
  }

  resetController(controller: DeactivateController, isExiting: boolean): void {
    if (isExiting) {
      controller.changeset.rollback();
    }
  }
}
