import Controller from '@ember/controller';
import { timeout, task } from 'ember-concurrency';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class MarketplacePartnersIndexPartnerActivateController extends Controller {
  @service intl;
  @service store;
  @service router;
  @service flashMessages;
  @service externalIntegrationOauth;

  createPartnerActivation = task(async () => {
    await timeout(2000);
    const data = this.externalIntegrationOauth.getAuthorizationData();

    try {
      const authorization = await this.store.createRecord(
        'authorization',
        Object.assign(data)
      );

      await authorization.save();
      const partnerActivation = this.store.createRecord('partner-activation', {
        partner: this.model,
        authorization,
      });
      await partnerActivation.save();
      this.externalIntegrationOauth.clear();

      this.flashMessages.success(
        this.intl.t('marketplace.partner_reconnected', {
          subject: this.model.name,
        })
      );
      window.location.replace(this.model.website);
    } catch (err) {
      this.externalIntegrationOauth.clear();
      this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
    }
  });

  @action
  maybeClearData() {
    if (this.model.isActivated) {
      this.externalIntegrationOauth.clear();
    }
  }

  @action
  goBack() {
    this.router.transitionTo('marketplace.partners.index');
  }
}
