import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import Route from '@ember/routing/route';
import { getOwner } from '@ember/application';
import DateRange from 'teamtailor/utils/date-range';

import AudienceDevicesReport from 'teamtailor/classes/analytics/report-audience-devices';
import { buildReport as buildAudienceVisitorsReport } from 'teamtailor/classes/analytics/report-audience-visitors';
import AudiencePromotionsReportFetcher from 'teamtailor/classes/analytics/report-audience-promotions';
import AudienceSourcesReportFetcher from 'teamtailor/classes/analytics/report-audience-sources';
import PipelineSpeedReportFetcher from 'teamtailor/classes/analytics/report-pipeline-speed';
import PipelineOverviewReportFetcher from 'teamtailor/classes/analytics/report-pipeline-overview';
import PipelineConversionReportFetcher from 'teamtailor/classes/analytics/report-pipeline-conversions';
import RejectReasonsReport from 'teamtailor/classes/analytics/report-reject-reasons';
import TimeToHireReport from 'teamtailor/classes/analytics/report-time-to-hire';
import MeetingStatsReport from 'teamtailor/classes/analytics/report-meeting-stats';
import PartnerResultsReport from 'teamtailor/classes/analytics/report-partner-results';
import { buildReport as buildNpsOverviewReport } from 'teamtailor/classes/analytics/report-nps-overview';
import { buildReport as buildNpsMovingAverageReport } from 'teamtailor/classes/analytics/report-nps-moving-average';
import { buildReport as buildTeamActivityReport } from 'teamtailor/classes/analytics/report-team-activity';
import { buildReport as buildEmployeeReferralsReport } from 'teamtailor/classes/analytics/report-employee-referrals';

export default class IndexRoute extends Route {
  @service analytics;
  @service flipper;

  model() {
    const compareSettings = get(this, 'analytics.previousPeriodSettings');

    const container = getOwner(this);

    const dateCompareSettings = compareSettings
      ? {
          dateRange: new DateRange(
            compareSettings.startDate,
            compareSettings.endDate
          ),
        }
      : null;

    const audienceDeviceReport = new AudienceDevicesReport({
      container,
    });

    const audienceVisitorsReport = buildAudienceVisitorsReport({
      container,
      options: {
        onlyVisits: true,
        ignoreJobsFilter: true,
      },

      compareOptions: dateCompareSettings && {
        onlyVisits: true,
        ignoreJobsFilter: true,
        ...dateCompareSettings,
      },
    });

    const audienceJobAdVisitorsReport = buildAudienceVisitorsReport({
      container,
      options: {
        onlyJobAds: true,
      },

      compareOptions: dateCompareSettings && {
        onlyJobAds: true,
        ...dateCompareSettings,
      },
    });

    const npsOverviewReport = buildNpsOverviewReport({
      container,
      options: { limit: 10 },
      dateCompareSettings,
    });

    const npsMovingAverageReport = buildNpsMovingAverageReport({ container });
    const teamActivityReport = buildTeamActivityReport({
      container,
      options: {},
      compareOptions: dateCompareSettings,
    });
    const employeeReferralsReport = buildEmployeeReferralsReport({ container });

    const audiencePromotionsReport = new AudiencePromotionsReportFetcher({
      container,
    });

    const audienceSourcesReport = new AudienceSourcesReportFetcher({
      container,
    });

    const pipelineSpeedReport = new PipelineSpeedReportFetcher({
      container,
      compareOptions: dateCompareSettings,
    });

    const pipelineOverviewReport = new PipelineOverviewReportFetcher({
      container,
      options: { fetchByStageName: false },
    });

    const pipelineConversionReport = new PipelineConversionReportFetcher({
      container,
    });

    const rejectReasonsReport = new RejectReasonsReport({ container });
    const timeToHireReport = new TimeToHireReport({ container });
    const meetingsReport = new MeetingStatsReport({ container });
    const partnerResultsReport = new PartnerResultsReport({ container });

    return {
      devices: audienceDeviceReport.fetchTask,
      visitors: audienceVisitorsReport.buildTask,
      jobAdVisitors: audienceJobAdVisitorsReport.buildTask,
      promotions: audiencePromotionsReport.fetchTask,
      sources: audienceSourcesReport.fetchTask,
      speed: pipelineSpeedReport.fetchTask,
      pipelineOverview: pipelineOverviewReport.fetchTask,
      pipelineConversions: pipelineConversionReport.fetchTask,
      rejectReasons: rejectReasonsReport.fetchTask,
      timeToHire: timeToHireReport.fetchTask,
      meetings: meetingsReport.fetchTask,
      partnerResults: partnerResultsReport.fetchTask,
      npsOverview: npsOverviewReport.buildTask,
      npsMovingAverage: npsMovingAverageReport.buildTask,
      teamActivity: teamActivityReport.buildTask,
      referrals: employeeReferralsReport.buildTask,
    };
  }
}
