import Route from '@ember/routing/route';
import { questionsToFormFields } from 'teamtailor/utils/form-builder';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class EditRoute extends Route {
  @service store;

  model({ survey_id }) {
    return this.store.findRecord('survey', survey_id);
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    const fields = questionsToFormFields(model.sortedFormQuestions);

    set(controller, 'fields', fields);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      set(controller, 'fields', []);
    }
  }
}
