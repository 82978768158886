import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import ArrayProxy from '@ember/array/proxy';
import WebhookSubscription from 'teamtailor/models/webhook-subscription';

export default class SettingsWebhookRoute extends ScrollToTopRoute {
  @service declare store: Store;

  model(): ArrayProxy<WebhookSubscription> {
    return this.store.findAll('webhook-subscription', { reload: true });
  }
}
