import buildMessage from 'ember-changeset-validations/utils/validation-errors';

const validateRegex = ({
  pattern,
  messageKey = 'regex',
  allowBlank = true,
} = {}) => {
  return (key, newValue) => {
    if ((newValue === '' || newValue === null) && allowBlank) {
      return true;
    }

    return pattern.test(newValue)
      ? true
      : buildMessage(key, { type: messageKey });
  };
};

export default validateRegex;
