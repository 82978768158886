import ApplicationInstance from '@ember/application/instance';

import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';

import round from 'teamtailor/utils/round';
import { gql } from '@apollo/client/core';
import { stageTypeColors } from 'teamtailor/components/insights/charts/colors';
import ReportAnalyticsRequest, {
  FetchOptions,
} from './report-analytics-request';
import Store from '@ember-data/store';
import AnalyticsService from 'teamtailor/services/analytics';
import IntlService from 'ember-intl/services/intl';
import Current from 'teamtailor/services/current';
import StageType from 'teamtailor/models/stage-type';
import DateRange from 'teamtailor/utils/date-range';
import { get } from 'teamtailor/utils/get';

const insightsQuery = gql`
  query FetchPipelineSpeed(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    general: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      companyIds: $companyIds
      distinctBy: {
        fields: [EVENT_TYPE, JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
    ) {
      timeInInbox: average(field: TIME_IN_INBOX)
      timeToReject: average(field: TIME_TO_REJECT)
      timeToHired: average(field: TIME_TO_HIRE)
    }
    companyRejectTime: eventQuery(
      dateRange: $dateRange
      eventTypes: [REJECTED]
      companyIds: $companyIds
    ) {
      average: average(field: TIME_TO_REJECT)
    }
    companyMoveTime: eventQuery(
      dateRange: $dateRange
      eventTypes: [MOVED]
      companyIds: $companyIds
    ) {
      average: average(field: TIME_IN_STAGE)
    }
    grouped: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [REJECTED, MOVED]
      companyIds: $companyIds
    ) {
      byStageType: aggregated(groupBy: [FROM_STAGE_TYPE]) {
        stageFromType
        count
        average(field: TIME_IN_STAGE)
        min(field: TIME_IN_STAGE)
        max(field: TIME_IN_STAGE)
      }
      byStageName: aggregated(groupBy: [FROM_STAGE_TYPE, FROM_STAGE_NAME]) {
        stageFromType
        stageFromName
        count
        average(field: TIME_IN_STAGE)
        min(field: TIME_IN_STAGE)
        max(field: TIME_IN_STAGE)
      }
    }
  }
`;

type StageTypeRow = {
  stageFromType: string;
  count: number;
  average: number;
  min: number;
  max: number;
};

type StageNameRow = StageTypeRow & {
  stageFromName: string;
};

interface PipelineSpeedReportArgs {
  container: ApplicationInstance;
  _rowsByStageType?: StageTypeRow[];
  _rowsByName?: StageNameRow[];
  timeInInbox?: number;
  timeToReject?: number;
  timeToHired?: number;
  companyAverage?: number;
  compareModel?: PipelineSpeedReport;
}

class PipelineSpeedReport {
  @service declare store: Store;
  @service declare analytics: AnalyticsService;
  @service declare intl: IntlService;
  @service declare current: Current;

  container: ApplicationInstance;
  _rowsByStageType: StageTypeRow[] = [];
  _rowsByName: StageNameRow[] = [];
  timeInInbox: number = 0;
  timeToReject: number = 0;
  timeToHired: number = 0;
  companyAverage: number = 0;
  compareModel?: PipelineSpeedReport;

  sortBy = 'orderIndex';
  sortDirection = 'asc';

  constructor(args: PipelineSpeedReportArgs) {
    this.container = args.container;
    Object.assign(this, args);
  }

  get orderedStageTypeCategories(): string[] {
    return get(this.current, 'company.stageTypes')
      .toArray()
      .sort((a: StageType, b: StageType) => a.orderIndex - b.orderIndex)
      .map((stageType: StageType) => get(stageType, 'category'));
  }

  get averageTimeInInbox(): number {
    return round(moment.duration(this.timeInInbox, 'seconds').asDays());
  }

  get averageTimeToReject(): number {
    return round(moment.duration(this.timeToReject, 'seconds').asDays());
  }

  get averageTimeToHired(): number {
    return round(moment.duration(this.timeToHired, 'seconds').asDays());
  }

  get chartOptions(): any {
    return {
      chart: {
        type: 'bar',
        height: 270,
      },

      xAxis: {
        categories: get(this, 'rowsByStageType').mapBy('humanStageType'),
      },

      yAxis: {
        title: {
          enabled: false,
        },
      },

      legend: {
        enabled: false,
      },

      tooltip: {
        pointFormat: '<b>{point.y} days</b>',
      },

      plotOptions: {
        bar: {
          pointWidth: 16,
        },
      },
    };
  }

  get chartData() {
    return [
      {
        name: '',
        data: this.rowsByStageType.map((row) => {
          return {
            name: this.intl.t(`job.stage_types.${row.stageType}`),
            y: round(moment.duration(row.averageDuration, 'seconds').asDays()),

            color: row.color,
          };
        }),
      },
    ];
  }

  get rowsByStageType() {
    let rows = [];
    rows = this._rowsByStageType.map((row) => {
      const { count, average, min, max, stageFromType: stageType } = row;
      const { orderIndex, color } = this.getStageTypeInfo(stageType);

      return {
        orderIndex,
        color,
        stageType,
        humanStageType: this.intl.t(`job.stage_types.${stageType}`),

        averageDuration: average,
        totalCount: count,
        minDuration: min,
        maxDuration: max,
      };
    });

    return rows
      .filter((row) => row.stageType !== 'hired')
      .sort((a, b) => a.orderIndex - b.orderIndex)
      .map((row, index) => ({
        ...row,
        orderIndex: index + 1, // a more human-readable index
      }));
  }

  get rowsByName() {
    return this._rowsByName.map((row) => {
      const {
        count,
        average,
        min,
        max,
        stageFromType: stageType,
        stageFromName: name,
      } = row;
      const humanStageType = this.intl.t(`job.stage_types.${stageType}`);

      const newRow = {
        stage: {
          name,
          stageType,
        },

        name: `${name} (${humanStageType})`,

        averageDuration: average,
        totalCount: count,
        minDuration: min,
        maxDuration: max,
      };

      return newRow;
    });
  }

  getStageTypeInfo(stageType: string) {
    let orderIndex = this.orderedStageTypeCategories.findIndex(
      (category) => category === stageType
    );

    if (orderIndex === -1) {
      // Special case for "in_process", which is not in the list of stage types
      orderIndex = 0.5;
    }

    const color = stageTypeColors[stageType || 'in_process'];

    return { orderIndex, color };
  }
}

interface PipelineSpeedReportFetcherArgs {
  container: ApplicationInstance;
  options?: FetchOptions;
  compareOptions?: FetchOptions;
}

interface PipelineSpeedReportResult {
  general: Record<string, any>;
  companyRejectTime: Record<string, any>;
  companyMoveTime: Record<string, any>;
  grouped: Record<string, any>;
}

export default class PipelineSpeedReportFetcher {
  public container: PipelineSpeedReportFetcherArgs['container'];
  public options: PipelineSpeedReportFetcherArgs['options'];
  public compareOptions: PipelineSpeedReportFetcherArgs['compareOptions'];

  constructor({
    container,
    options,
    compareOptions,
  }: PipelineSpeedReportFetcherArgs) {
    this.container = container;
    this.options = options;
    this.compareOptions = compareOptions;
  }

  async reportRequest(options: PipelineSpeedReportFetcherArgs['options']) {
    let dateRange: DateRange | undefined;

    if (options?.dateRange) {
      dateRange = options.dateRange;
    }

    return new ReportAnalyticsRequest({
      container: this.container,
      query: insightsQuery,
      callback: (result) => result,
    }).fetch({
      dateRange,
    });
  }

  createReport({
    results,
    compareModel,
  }: {
    results: PipelineSpeedReportResult;
    compareModel?: PipelineSpeedReport;
  }) {
    const {
      general,
      companyRejectTime,
      companyMoveTime,
      grouped: result,
    } = results;

    const { average: companyAverageRejected } = companyRejectTime;
    const { average: companyAverageMoved } = companyMoveTime;
    const { timeInInbox, timeToReject, timeToHired } = general;

    const companyAverageTime =
      (companyAverageRejected?.count || 0 + companyAverageMoved?.count || 0) > 0
        ? (companyAverageRejected.count *
            companyAverageRejected.averageDuration +
            companyAverageMoved.count * companyAverageMoved.averageDuration) /
          (companyAverageRejected.count + companyAverageMoved.count)
        : 0;

    return new PipelineSpeedReport({
      container: this.container,
      _rowsByStageType: result.byStageType,
      _rowsByName: result.byStageName,
      timeInInbox,
      timeToReject,
      timeToHired,
      companyAverage: round(
        moment.duration(companyAverageTime, 'seconds').asDays()
      ),

      compareModel,
    });
  }

  fetch = task(async (): Promise<PipelineSpeedReport> => {
    const results = await this.reportRequest(this.options);

    if (!results) {
      return new PipelineSpeedReport({
        container: this.container,
      });
    }

    let compareModel: PipelineSpeedReportArgs['compareModel'];
    if (this.compareOptions) {
      const compareResults = await this.reportRequest(this.compareOptions);
      if (compareResults) {
        compareModel = this.createReport({ results: compareResults });
      }
    }

    return this.createReport({ results, compareModel });
  });

  fetchTask = trackedTask(this, this.fetch);
}
