import Route from '@ember/routing/route';
import { set } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { getTimeSlot } from 'teamtailor/utils/time-slot';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class DashboardRoute extends Route {
  @service current;
  @service flipper;
  @service permissions;
  @service pwaInstallation;
  @service router;
  @service store;
  @service user;

  beforeModel() {
    get(this, 'user').then((user) => {
      if (get(user, 'externalRecruiter')) {
        this.router.transitionTo('candidates.segment', 'all');
        return;
      }

      if (!this.permissions.has('job/list')) {
        this.router.transitionTo('employee');
      }
    });
  }

  async model() {
    const dashboardSettings = await get(
      this.current.company,
      'dashboardSetting'
    );
    if (dashboardSettings) {
      return dashboardSettings;
    }

    return this.store.createRecord('dashboard-setting');
  }

  afterModel() {
    if (!get(this.flipper, 'tshirt')) {
      return;
    }

    get(this, 'user').then((user) => {
      if (
        !get(user, 'hasMadeTshirtDecision') ||
        !get(user, 'hasMadeSweaterDecision')
      ) {
        get(user, 'gifts').then(() => {
          const tshirt = get(user, 'tshirt');
          if (!isEmpty(tshirt) && tshirt.status === 'pending') {
            set(tshirt, 'isVisible', true);
          }

          const sweater = get(user, 'sweater');
          if (!isEmpty(sweater) && sweater.status === 'pending') {
            set(sweater, 'isVisible', true);
          }
        });
      }
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.timeSlot = getTimeSlot();
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      this.pwaInstallation.shouldShowInstalledMessage = false;
    }
  }
}
