import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"ml-auto flex w-auto flex-row items-center gap-6\">\n  <Icon @icon=\"input-volume\" @style=\"kit\" class=\"text-neutral\" />\n  <div\n    class=\"relative h-[30px] w-76\"\n    ...attributes\n    {{did-insert this.onUpdateTrack}}\n    {{did-update this.onUpdateTrack @track}}\n  >\n    <div class=\"flex w-full items-center space-x-4\">\n      {{#each (repeat this.maxBars)}}\n        <span class=\"h-[30px] w-4 rounded-full bg-zinc-250\"></span>\n      {{/each}}\n    </div>\n    <div class=\"absolute top-0 flex w-full items-center space-x-4\">\n      {{#each (repeat this.barCount)}}\n        <span class=\"h-[30px] w-4 rounded-full bg-green-500\"></span>\n      {{/each}}\n    </div>\n  </div>\n</div>", {"contents":"<div class=\"ml-auto flex w-auto flex-row items-center gap-6\">\n  <Icon @icon=\"input-volume\" @style=\"kit\" class=\"text-neutral\" />\n  <div\n    class=\"relative h-[30px] w-76\"\n    ...attributes\n    {{did-insert this.onUpdateTrack}}\n    {{did-update this.onUpdateTrack @track}}\n  >\n    <div class=\"flex w-full items-center space-x-4\">\n      {{#each (repeat this.maxBars)}}\n        <span class=\"h-[30px] w-4 rounded-full bg-zinc-250\"></span>\n      {{/each}}\n    </div>\n    <div class=\"absolute top-0 flex w-full items-center space-x-4\">\n      {{#each (repeat this.barCount)}}\n        <span class=\"h-[30px] w-4 rounded-full bg-green-500\"></span>\n      {{/each}}\n    </div>\n  </div>\n</div>","moduleName":"teamtailor/components/video-meeting/audio-meter-bar.hbs","parseOptions":{"srcName":"teamtailor/components/video-meeting/audio-meter-bar.hbs"}});
import { tracked } from '@glimmer/tracking';
import VideoMeetingAudioMeter from 'teamtailor/components/video-meeting/audio-meter';

export default class VideoMeetingAudioMeterBar extends VideoMeetingAudioMeter {
  @tracked barCount = 0;
  @tracked maxBars = 10;
  barWidth = 7;

  updateView(element: HTMLElement, inputData: Float32Array) {
    const { clientWidth } = element;

    this.maxBars = Math.floor(clientWidth / this.barWidth);

    const percent = this.percentFromInputData(inputData);

    this.barCount = Math.floor(this.maxBars * percent);
  }
}
