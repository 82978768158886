export type EventTypeEnum =
  | 'REVIEW'
  | 'REJECTED'
  | 'MOVED'
  | 'APPLIED'
  | 'CONNECTED'
  | 'SOURCED'
  | 'CANDIDATE_SOURCE_UPDATED'
  | 'HIRED'
  | 'NOTE'
  | 'MESSAGE'
  | 'REFERRAL'
  | 'SCORE'
  | 'INTERVIEW_CREATED'
  | 'NPS_RESPONSE'
  | 'HIRE_QUALITY_RESPONSE'
  | 'NURTURE_CAMPAIGN_RECIPIENT_CREATED'
  | 'NURTURE_CAMPAIGN_EXITED'
  | 'NURTURE_CAMPAIGN_STEP_SENT'
  | 'JOB_OFFER_CREATED'
  | 'JOB_OFFER_SENT'
  | 'JOB_OFFER_ACCEPTED'
  | 'JOB_OFFER_DECLINED'
  | 'JOB_PUBLISHED'
  | 'JOB_ARCHIVED'
  | 'JOB_UNLISTED'
  | 'JOB_AWAITING';

export default class EventType {
  static REVIEW = new EventType('REVIEW');
  static REJECTED = new EventType('REJECTED');
  static MOVED = new EventType('MOVED');
  static APPLIED = new EventType('APPLIED');
  static CONNECTED = new EventType('CONNECTED');
  static SOURCED = new EventType('SOURCED');
  static CANDIDATE_SOURCE_UPDATED = new EventType('CANDIDATE_SOURCE_UPDATED');
  static HIRED = new EventType('HIRED');
  static NOTE = new EventType('NOTE');
  static MESSAGE = new EventType('MESSAGE');
  static REFERRAL = new EventType('REFERRAL');
  static SCORE = new EventType('SCORE');
  static INTERVIEW_CREATED = new EventType('INTERVIEW_CREATED');
  static NPS_RESPONSE = new EventType('NPS_RESPONSE');
  static HIRE_QUALITY_RESPONSE = new EventType('HIRE_QUALITY_RESPONSE');

  static NURTURE_CAMPAIGN_RECIPIENT_CREATED = new EventType(
    'NURTURE_CAMPAIGN_RECIPIENT_CREATED'
  );

  static NURTURE_CAMPAIGN_EXITED = new EventType('NURTURE_CAMPAIGN_EXITED');
  static NURTURE_CAMPAIGN_STEP_SENT = new EventType(
    'NURTURE_CAMPAIGN_STEP_SENT'
  );

  static JOB_OFFER_CREATED = new EventType('JOB_OFFER_CREATED');
  static JOB_OFFER_SENT = new EventType('JOB_OFFER_SENT');
  static JOB_OFFER_ACCEPTED = new EventType('JOB_OFFER_ACCEPTED');
  static JOB_OFFER_DECLINED = new EventType('JOB_OFFER_DECLINED');

  static JOB_PUBLISHED = new EventType('JOB_PUBLISHED');
  static JOB_ARCHIVED = new EventType('JOB_ARCHIVED');
  static JOB_UNLISTED = new EventType('JOB_UNLISTED');
  static JOB_AWAITING = new EventType('JOB_AWAITING');

  type: string;
  constructor(type: EventTypeEnum) {
    this.type = type;
  }

  toString() {
    return this.type;
  }
}

export const EVENT_TYPES = [
  EventType.REVIEW,
  EventType.REJECTED,
  EventType.MOVED,
  EventType.APPLIED,
  EventType.CONNECTED,
  EventType.SOURCED,
  EventType.CANDIDATE_SOURCE_UPDATED,
  EventType.HIRED,
  EventType.NOTE,
  EventType.MESSAGE,
  EventType.REFERRAL,
  EventType.SCORE,
  EventType.INTERVIEW_CREATED,
  EventType.NPS_RESPONSE,
  EventType.HIRE_QUALITY_RESPONSE,
  EventType.NURTURE_CAMPAIGN_RECIPIENT_CREATED,
  EventType.NURTURE_CAMPAIGN_EXITED,
  EventType.NURTURE_CAMPAIGN_STEP_SENT,
  EventType.JOB_OFFER_CREATED,
  EventType.JOB_OFFER_SENT,
  EventType.JOB_OFFER_ACCEPTED,
  EventType.JOB_OFFER_DECLINED,
  EventType.JOB_PUBLISHED,
  EventType.JOB_ARCHIVED,
  EventType.JOB_UNLISTED,
  EventType.JOB_AWAITING,
];
