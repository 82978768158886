import Model, {
  AsyncBelongsTo,
  hasMany,
  belongsTo,
  attr,
  SyncHasMany,
  AsyncHasMany,
} from '@ember-data/model';
import { get } from 'teamtailor/utils/get';
import {
  JobOfferModel,
  RequisitionFlowModel,
  RequisitionModel,
  RequisitionStepVerdictModel,
  UserModel,
} from 'teamtailor/models';
import { tracked } from '@glimmer/tracking';
import Server from 'teamtailor/services/server';
import { inject as service } from '@ember/service';

export default class RequisitionStepModel extends Model {
  @service declare server: Server;

  @belongsTo('job-offer') declare jobOffer: AsyncBelongsTo<JobOfferModel>;
  @belongsTo('requisition', { inverse: 'steps', async: false })
  declare requisition: RequisitionModel;

  // Since we don't have pickedUsers in the backend, we need to fake it here
  // Clear this value when not needed anymore
  @tracked pickedUsers: UserModel[] = [];
  @hasMany('user', { async: true }) declare users: AsyncHasMany<UserModel>;

  @belongsTo('requisition-flow')
  declare requisitionFlow: AsyncBelongsTo<RequisitionFlowModel>;

  @hasMany('requisition-step-verdict', { async: false })
  declare requisitionStepVerdicts: SyncHasMany<RequisitionStepVerdictModel>;

  @attr('string', { defaultValue: 'any_user' }) declare approvalPolicy: string;
  @attr('boolean') declare isEditable: boolean;
  @attr('boolean') declare isOverdue: boolean;
  @attr('number') declare stepIndex: number;

  get pendingVerdicts() {
    return get(this, 'requisitionStepVerdicts').filterBy('isPending');
  }

  get isApproved() {
    const verdicts = get(this, 'requisitionStepVerdicts');

    return this.approvalPolicy === 'all_users'
      ? verdicts.every((verdict) => !!get(verdict, 'isApproved'))
      : verdicts.some(
          (verdict: RequisitionStepVerdictModel) => !!get(verdict, 'isApproved')
        );
  }

  get isRejected() {
    const verdicts = get(this, 'requisitionStepVerdicts');

    return verdicts.some((verdict: RequisitionStepVerdictModel) => {
      // Has to be explicitly false and not just null:
      return get(verdict, 'isApproved') === false;
    });
  }

  get isPending() {
    return !this.isApproved && !this.isRejected;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'requisition-step': RequisitionStepModel;
  }
}
