import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"jobs.job.audience.title\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"label\"\n    @selected={{if @hasChangedValue (find-by \"id\" @selected this.options)}}\n    @options={{this.options}}\n    @searchEnabled={{false}}\n    @isDisabled={{@isDisabled}}\n    @onSelect={{@onChange}}\n    @nullOption={{t \"search.all\"}}\n  />\n</label>", {"contents":"<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"jobs.job.audience.title\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"label\"\n    @selected={{if @hasChangedValue (find-by \"id\" @selected this.options)}}\n    @options={{this.options}}\n    @searchEnabled={{false}}\n    @isDisabled={{@isDisabled}}\n    @onSelect={{@onChange}}\n    @nullOption={{t \"search.all\"}}\n  />\n</label>","moduleName":"teamtailor/components/sidebar/filters/internal.hbs","parseOptions":{"srcName":"teamtailor/components/sidebar/filters/internal.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

export default class extends Component {
  @service declare intl: IntlService;

  get options() {
    return [
      {
        id: 'true',
        label: this.intl.t('job.internal'),
      },
      {
        id: 'false',
        label: this.intl.t('job.public'),
      },
    ];
  }
}
