import Controller from '@ember/controller';
import { action } from '@ember/object';

import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ENV from 'teamtailor/config/environment';
import { get } from 'teamtailor/utils/get';

export default class MarketplaceChannelsIndexChannelIndeedOauthController extends Controller {
  queryParams = ['result'];

  indeedAtsKey = ENV.indeed.atsKey;

  @service current;
  @service user;
  @service router;
  @service intl;
  @service store;
  @service flashMessages;

  @tracked businessEmail;
  @tracked isDisabled = true;
  @tracked result;
  @tracked isOpen = true;

  @action
  redirectToIndeed() {
    const companyId = this.current.company.id;
    window.location.replace(
      `https://ats-management.indeed.com/accountsetup?__email=${this.businessEmail}&ak=${this.indeedAtsKey}&state=${companyId}`
    );
  }

  get showActivationModal() {
    return this.result === 'OK';
  }

  @action
  validate(value) {
    const regex = /\S+@\S+\.\S+/;
    this.isDisabled = !regex.test(value);
  }

  @action
  onClose() {
    this.result = undefined;
    this.router.transitionTo('marketplace.channels.index.channel.indeed');
  }

  @action async onOauthSuccess({ authorization: data }) {
    try {
      const authorization = await this.store.createRecord(
        'authorization',
        Object.assign(data)
      );

      await authorization.save();

      const activation = this.store.createRecord('channel-activation', {
        channel: this.model,
        authorization,
        activated: true,
      });

      await activation.save();

      this.flashMessages.success(
        this.intl.t('marketplace.partner_connected', {
          subject: get(this, 'partner.name'),
        })
      );
      this.router.transitionTo('marketplace.channels.index.channel.indeed');
    } catch (err) {
      get(this, 'flashMessages').error(
        this.intl.t('errors.something_went_wrong')
      );
    }
  }

  @action async onOauthFailure() {
    this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
  }
}
