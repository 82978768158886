import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label class=\"tt-form-label\">\n  {{#if @label}}\n    <span class={{this.labelTextClasses}}>\n      {{@label}}\n      {{#if @infoText}}\n        <InfoPopover>\n          {{@infoText}}\n        </InfoPopover>\n      {{/if}}\n    </span>\n  {{/if}}\n\n  {{yield}}\n</label>", {"contents":"<label class=\"tt-form-label\">\n  {{#if @label}}\n    <span class={{this.labelTextClasses}}>\n      {{@label}}\n      {{#if @infoText}}\n        <InfoPopover>\n          {{@infoText}}\n        </InfoPopover>\n      {{/if}}\n    </span>\n  {{/if}}\n\n  {{yield}}\n</label>","moduleName":"teamtailor/components/form-label.hbs","parseOptions":{"srcName":"teamtailor/components/form-label.hbs"}});
import Component from '@glimmer/component';
import classnames from 'teamtailor/utils/classnames';

interface FormLabelArgs {
  theme: 'default' | 'dark' | undefined;
  labelClass: string;
}

export default class FormLabelComponent extends Component<FormLabelArgs> {
  theme = this.args.theme ?? 'default';

  get isDarkTheme(): boolean {
    return this.theme === 'dark';
  }

  get labelTextClasses(): string {
    return classnames('tt-form-label-text', this.args.labelClass, {
      'tt-dark-theme': this.isDarkTheme,
    });
  }
}
