import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.npsResponses}}\n  <CandidateModalNew::Main::Section\n    @title={{t \"candidates.candidate.candidate_modal.net_promoter_score\"}}\n    @id=\"nps\"\n  >\n    <:content>\n      <div class=\"mt-8 flex flex-col gap-20\">\n        {{#each this.npsResponses as |npsResponse|}}\n          <CandidateModalNew::Main::Section::Nps::Item\n            @npsResponse={{npsResponse}}\n            @reply={{this.reply}}\n          />\n        {{/each}}\n      </div>\n    </:content>\n  </CandidateModalNew::Main::Section>\n{{/if}}", {"contents":"{{#if this.npsResponses}}\n  <CandidateModalNew::Main::Section\n    @title={{t \"candidates.candidate.candidate_modal.net_promoter_score\"}}\n    @id=\"nps\"\n  >\n    <:content>\n      <div class=\"mt-8 flex flex-col gap-20\">\n        {{#each this.npsResponses as |npsResponse|}}\n          <CandidateModalNew::Main::Section::Nps::Item\n            @npsResponse={{npsResponse}}\n            @reply={{this.reply}}\n          />\n        {{/each}}\n      </div>\n    </:content>\n  </CandidateModalNew::Main::Section>\n{{/if}}","moduleName":"teamtailor/components/candidate-modal-new/main/section/nps/index.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-modal-new/main/section/nps/index.hbs"}});
import Component from '@glimmer/component';
import {
  CandidateModel,
  JobApplicationModel,
  NpsResponseModel,
} from 'teamtailor/models';
import { trackedFunction } from 'ember-resources/util/function';
import flatten from 'teamtailor/utils/flatten';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import MessageService from 'teamtailor/services/message-service';
import IntlService from 'ember-intl/services/intl';

interface NpsSignature {
  Args: {
    candidate: CandidateModel;
    jobApplication?: JobApplicationModel;
    onTabChange: (tab: string) => void;
  };
}

export default class CandidateModalNewMainSectionNps extends Component<NpsSignature> {
  @service declare messageService: MessageService;
  @service declare intl: IntlService;

  get npsResponses() {
    return this.resolveNpsResponses.value;
  }

  resolveNpsResponses = trackedFunction(this, async () => {
    const jobApplications = this.args.jobApplication
      ? [this.args.jobApplication]
      : await this.args.candidate.jobApplications;

    return flatten(
      jobApplications.map((jobApplication) =>
        jobApplication.npsResponses.toArray().sort(this.sort)
      )
    );
  });

  sort(a: NpsResponseModel, b: NpsResponseModel) {
    if (!a.scoredAt && !b.scoredAt) {
      return a.sentAt < b.sentAt ? 1 : -1;
    }

    return (a.scoredAt as unknown as Date) < (b.scoredAt as unknown as Date)
      ? 1
      : -1;
  }

  get npsReplyBody() {
    return this.intl.t('insights.feedback_modal.thanks');
  }

  get npsSubject() {
    return this.intl.t(
      'candidates.candidate.candidate_modal.nps_reply_subject'
    );
  }

  @action
  reply() {
    this.messageService.subject = this.npsSubject;
    this.messageService.body = this.npsReplyBody;
    this.args.onTabChange('message-feed');
  }
}
