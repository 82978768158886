import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AudiencePromotionsReportFetcher from 'teamtailor/classes/analytics/report-audience-promotions';
import { getOwner } from '@ember/application';

export default class PromotionsRoute extends Route {
  @service analytics;

  model() {
    return {
      promotions: new AudiencePromotionsReportFetcher({
        container: getOwner(this),
      }).fetchTask,
    };
  }
}
