import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<DataTable::Cell\n  @data={{@data}}\n  @onMouseEnterColumn={{@onMouseEnterColumn}}\n  @onMouseExitColumn={{@onMouseExitColumn}}\n  @hovering={{@hovering}}\n  @lastRow={{@lastRow}}\n  {{in-viewport onEnter=this.onEnter}}\n>\n  {{#if\n    (or\n      (eq this.fetchUserRecordTask.performCount 0)\n      this.fetchUserRecordTask.isRunning\n    )\n  }}\n    <LoadingSpinner @size=\"small\" />\n  {{else if (is-empty this.userId)}}\n    {{t \"components.data_table.not_available\"}}\n  {{else if (not this.userRecord)}}\n    {{t \"common.deleted_user\"}}\n  {{else}}\n    {{this.userRecord.nameOrEmail}}\n  {{/if}}\n</DataTable::Cell>", {"contents":"<DataTable::Cell\n  @data={{@data}}\n  @onMouseEnterColumn={{@onMouseEnterColumn}}\n  @onMouseExitColumn={{@onMouseExitColumn}}\n  @hovering={{@hovering}}\n  @lastRow={{@lastRow}}\n  {{in-viewport onEnter=this.onEnter}}\n>\n  {{#if\n    (or\n      (eq this.fetchUserRecordTask.performCount 0)\n      this.fetchUserRecordTask.isRunning\n    )\n  }}\n    <LoadingSpinner @size=\"small\" />\n  {{else if (is-empty this.userId)}}\n    {{t \"components.data_table.not_available\"}}\n  {{else if (not this.userRecord)}}\n    {{t \"common.deleted_user\"}}\n  {{else}}\n    {{this.userRecord.nameOrEmail}}\n  {{/if}}\n</DataTable::Cell>","moduleName":"teamtailor/components/data-table/user.hbs","parseOptions":{"srcName":"teamtailor/components/data-table/user.hbs"}});
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Store from '@ember-data/store';

type Args = {
  data: {
    value: string;
  };
};

export default class DataTableUserComponent extends Component<Args> {
  @service declare store: Store;

  get userId() {
    return this.args.data.value;
  }

  get userRecord() {
    return this.fetchUserRecordTask.lastSuccessful?.value;
  }

  fetchUserRecordTask = dropTask(async () => {
    try {
      return await this.store.findRecord('user', this.userId);
    } catch {
      return undefined;
    }
  });

  @action
  onEnter() {
    if (this.fetchUserRecordTask.performCount === 0) {
      this.fetchUserRecordTask.perform();
    }
  }
}
