export interface MenuItem {
  text: string;
  route?: string;
  subMenu?: boolean;
  children?: MenuItem[];
  highlightedPath?: string;
}

export const Routes: MenuItem[] = [
  {
    text: 'Design tokens',
    subMenu: true,
    route: 'unicorn.design-tokens',
    children: [
      {
        text: 'Colors',
        subMenu: true,
        route: 'unicorn.design-tokens.colors',
        children: [
          {
            text: 'Decorative',
            route: 'unicorn.design-tokens.colors.decorative',
          },
          {
            text: 'Functional',
            route: 'unicorn.design-tokens.colors.functional',
          },
          {
            text: 'Spectrum',
            route: 'unicorn.design-tokens.colors.spectrum',
          },
        ],
      },
      {
        text: 'Border radius',
        route: 'unicorn.design-tokens.radius',
      },
      { text: 'Effects', route: 'unicorn.design-tokens.effects' },
      { text: 'Spacing', route: 'unicorn.design-tokens.spacing' },
      {
        text: 'Typography',
        route: 'unicorn.design-tokens.typography',
      },
    ],
  },
  {
    text: 'Components',
    subMenu: true,
    route: 'unicorn.components',
    children: [
      { text: 'Alert message', route: 'unicorn.components.alert-message' },
      { text: 'Badge', route: 'unicorn.components.badge' },
      {
        text: 'Buttons',
        subMenu: true,
        route: 'unicorn.components.buttons',
        children: [
          {
            text: 'Button',
            route: 'unicorn.components.buttons.button',
          },
          {
            text: 'Status button',
            route: 'unicorn.components.buttons.status-button',
          },
          {
            text: 'Split button',
            route: 'unicorn.components.buttons.split-button',
          },
          {
            text: 'Back button',
            route: 'unicorn.components.buttons.back-button',
          },
        ],
      },
      { text: 'Checkbox', route: 'unicorn.components.checkbox' },
      { text: 'Choice Card', route: 'unicorn.components.choice-card' },
      { text: 'Color Dot', route: 'unicorn.components.color-dot' },
      { text: 'Dropdown', route: 'unicorn.components.dropdown' },
      { text: 'Empty State', route: 'unicorn.components.empty-state' },
      { text: 'Forms', route: 'unicorn.components.forms' },
      { text: 'Icon', route: 'unicorn.components.icon' },
      { text: 'Input', route: 'unicorn.components.input' },
      { text: 'Modal', route: 'unicorn.components.modal' },
      { text: 'Radio Group', route: 'unicorn.components.radio-group' },
      { text: 'Select', route: 'unicorn.components.select' },
      { text: 'SelectableItem', route: 'unicorn.components.selectable-item' },
      { text: 'Stat bar', route: 'unicorn.components.stat-bar' },
      { text: 'Status dot', route: 'unicorn.components.status-dot' },
      { text: 'Table', route: 'unicorn.components.table' },
      { text: 'Tabs', route: 'unicorn.components.tabs' },
      { text: 'Tag', route: 'unicorn.components.tag' },
      { text: 'Textarea', route: 'unicorn.components.textarea' },
      { text: 'Tooltip', route: 'unicorn.components.tooltip' },
      { text: 'Toggle Switch', route: 'unicorn.components.toggle-switch' },
      { text: 'User Image', route: 'unicorn.components.user-image' },
      {
        text: 'User Image Group',
        route: 'unicorn.components.user-image-group',
      },
      { text: 'Selected Count', route: 'unicorn.components.selected-count' },
    ],
  },
  {
    text: 'Guidelines',
    subMenu: true,
    route: 'unicorn.guidelines',
    children: [
      { text: 'CSS', route: 'unicorn.guidelines.css' },
      {
        text: 'Ember',
        route: 'unicorn.guidelines.ember',
        subMenu: true,
        children: [
          { text: 'Ember', route: 'unicorn.guidelines.ember.index' },
          { text: 'Glimmer', route: 'unicorn.guidelines.ember.glimmer' },
          {
            text: 'Relations',
            route: 'unicorn.guidelines.ember.relations',
          },
          { text: 'Tests', route: 'unicorn.guidelines.ember.tests' },
          { text: 'Routing', route: 'unicorn.guidelines.ember.routing' },
          {
            text: 'Render modifiers',
            route: 'unicorn.guidelines.ember.render-modifiers',
          },
          {
            text: 'Controller injections',
            route: 'unicorn.guidelines.ember.controller-injections',
          },
          {
            text: 'Migration guides',
            route: 'unicorn.guidelines.ember.migrate',
          },
        ],
      },
      { text: 'Ruby on rails', route: 'unicorn.guidelines.rails' },
      { text: 'Figma', route: 'unicorn.guidelines.figma' },
      { text: 'Delete', route: 'unicorn.guidelines.delete' },
      {
        text: 'Content',
        route: 'unicorn.guidelines.content',
        subMenu: true,
        children: [
          {
            text: 'The Teamtailor voice',
            route: 'unicorn.guidelines.content.voice',
          },
          {
            text: 'Actionable language',
            route: 'unicorn.guidelines.content.actionable-language',
          },
          {
            text: 'Inclusive language',
            route: 'unicorn.guidelines.content.inclusive-language',
          },
          {
            text: 'Web elements',
            route: 'unicorn.guidelines.content.web-elements',
          },
        ],
      },
    ],
  },
  {
    text: 'Contributing',
    route: 'unicorn.contributing',
  },
];
