import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Molecules::ActivityCard\n  ...attributes\n  @onShowExportModal={{this.onShowExportModal}}\n  @activities={{this.activities}}\n  @loading={{or (not this.fetchData.lastSuccessful) this.fetchData.isRunning}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>", {"contents":"<Insights::Molecules::ActivityCard\n  ...attributes\n  @onShowExportModal={{this.onShowExportModal}}\n  @activities={{this.activities}}\n  @loading={{or (not this.fetchData.lastSuccessful) this.fetchData.isRunning}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>","moduleName":"teamtailor/components/insights/widgets/user-latest-activity.hbs","parseOptions":{"srcName":"teamtailor/components/insights/widgets/user-latest-activity.hbs"}});
import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { gql } from '@apollo/client/core';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';

const DATA_QUERY = gql`
  query EmployeeUserInfoQuery($dateRange: DateRangeAttributes!, $userId: ID!) {
    user: eventQuery(
      dateRange: $dateRange
      filters: { userId: { equals: $userId }, jobId: { exists: true } }
      eventTypes: [
        HIRED
        INTERVIEW_CREATED
        MOVED
        REJECTED
        SCORE
        MESSAGE
        NOTE
        REFERRAL
        REVIEW
        SOURCED
      ]
      orderBy: { field: DATE, desc: true }
    ) {
      activities: rows {
        date
        jobId
        eventType
      }
    }
  }
`;

const EXPORT_TABLE_COLUMNS = [
  { type: 'text', propertyName: 'eventType', headerKey: 'common.event_type' },
  {
    type: 'number',
    propertyName: 'jobId',
    headerKey: 'insights.common.job_id',
  },
  {
    type: 'text',
    propertyName: 'jobTitle',
    headerKey: 'insights.common.job_title',
  },
  {
    type: 'date',
    propertyName: 'date',
    headerKey: 'common.date',
  },
];

export default class InsightsWidgetsUserLatestActivity extends Component {
  @service insights;
  @service intl;
  @service store;
  @service insightsExport;
  @service analytics;

  get lastActivity() {
    return this.fetchData.lastSuccessful?.value?.lastActivity;
  }

  get activities() {
    return this.fetchData.lastSuccessful?.value?.activities;
  }

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      'insights.molecules.activity_card.heading',
      `employee-${this.args.user.id}`,
      EXPORT_TABLE_COLUMNS,
      this.retrieveExportData,
      this.args.dateRange
    );
  }

  @action
  retrieveExportData(dateRange) {
    return this.queryForData(dateRange).then(
      (userActivities) => userActivities.user.activities
    );
  }

  @action
  queryForData(dateRange) {
    const { user } = this.args;
    const userId = get(user, 'id');

    return this.insights.query({
      query: DATA_QUERY,
      variables: {
        dateRange: dateRange.asObject,
        userId,
      },
    });
  }

  fetchData = dropTask(async () => {
    const { user: userResponse } = await this.queryForData(this.args.dateRange);
    const { activities } = userResponse;

    const jobIds = activities.map((activity) => activity.jobId);
    const jobs = await this.store.query('job', {
      ids: jobIds,
      groupAnalytics: this.analytics.hasEnabledGroupAnalytics,
    });

    return {
      activities: activities.map((activity) => ({
        ...activity,
        jobTitle:
          jobs.find((job) => job.id === activity.jobId)?.title ||
          this.intl.t('insights.common.deleted_job'),
      })),
    };
  });
}
