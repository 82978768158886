import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button\n  class=\"group flex w-full items-center gap-8 rounded-8 bg-neutral-medium p-4 hover:bg-neutral-hover focus-visible:border-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus\"\n  type=\"button\"\n  {{on \"click\" this.onClick}}\n  disabled={{this.isDisabled}}\n>\n  <div\n    class=\"relative flex size-32 shrink-0 items-center justify-center rounded-8 bg-zinc-600\"\n  >\n    <Icon @icon={{this.icon}} class=\"text-zinc-800 group-hover:text-white\" />\n    {{#if @hasChanges}}\n      <StatusDot\n        class=\"absolute -right-2 -top-2 cursor-pointer\"\n        @size=\"medium\"\n        @status=\"changed\"\n      />\n    {{/if}}\n  </div>\n  <div class=\"line-clamp-1 pr-4 text-left\">{{@title}}</div>\n</button>", {"contents":"<button\n  class=\"group flex w-full items-center gap-8 rounded-8 bg-neutral-medium p-4 hover:bg-neutral-hover focus-visible:border-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus\"\n  type=\"button\"\n  {{on \"click\" this.onClick}}\n  disabled={{this.isDisabled}}\n>\n  <div\n    class=\"relative flex size-32 shrink-0 items-center justify-center rounded-8 bg-zinc-600\"\n  >\n    <Icon @icon={{this.icon}} class=\"text-zinc-800 group-hover:text-white\" />\n    {{#if @hasChanges}}\n      <StatusDot\n        class=\"absolute -right-2 -top-2 cursor-pointer\"\n        @size=\"medium\"\n        @status=\"changed\"\n      />\n    {{/if}}\n  </div>\n  <div class=\"line-clamp-1 pr-4 text-left\">{{@title}}</div>\n</button>","moduleName":"teamtailor/components/connect-settings/block-editor-item.hbs","parseOptions":{"srcName":"teamtailor/components/connect-settings/block-editor-item.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { argDefault } from 'teamtailor/utils/arg-default';
interface ConnectSettingsBlockEditorItem {
  Args: {
    blockType: string;
    isDisabled?: boolean;
    anchorValue?: string;
    onClick?: (blockType: string, anchorValue?: string) => void;
  };
}

export default class ConnectSettingsBlockEditorItemComponent extends Component<ConnectSettingsBlockEditorItem> {
  @argDefault isDisabled = false;

  get icon() {
    switch (this.args.blockType) {
      case 'cover':
        return 'book-sparkles';

      case 'welcome-message':
        return 'envelope';

      case 'posts':
        return 'feather-pointed';

      case 'noteworthy':
        return 'sparkle';

      default:
        return 'question-circle';
    }
  }

  @action
  onClick() {
    this.args.onClick?.(this.args.blockType, this.args.anchorValue);
  }
}
