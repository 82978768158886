export default function simulateLinkClick(
  url: string,
  event: MouseEvent
): void {
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.dispatchEvent(
    new MouseEvent('click', {
      ctrlKey: event.ctrlKey,
      metaKey: event.metaKey,
    })
  );
}
