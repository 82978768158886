import EditRoute from 'teamtailor/routes/employees/profile/edit';
import { hash } from 'rsvp';

export default class NotificationsRoute extends EditRoute {
  afterModel(model, transition) {
    let departments = super.afterModel(model, transition);
    return hash({
      settings: model.hasMany('notificationSettings').reload(),
      departments,
    });
  }
}
