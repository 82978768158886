import Helper from '@ember/component/helper';
import { getStageTypeColor } from 'teamtailor/helpers/stage-type-color';

export function getStageTypeColorClass(category, colorTypesArg = 'bg') {
  const colorTypes = Array.isArray(colorTypesArg)
    ? colorTypesArg
    : [colorTypesArg];

  const color = getStageTypeColor(category);

  return colorTypes.map((type) => `${type}-decorative-${color}-weak`).join(' ');
}

export default Helper.helper(function (params) {
  return getStageTypeColorClass(...params);
});
