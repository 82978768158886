type RoleObject = {
  id: string;
  label: string;
};

export const ROLES: RoleObject[] = [
  { id: 'no_access', label: 'user.no_access' },
  { id: 'user', label: 'user.user' },
  { id: 'designer', label: 'user.designer' },
  { id: 'hiring_manager', label: 'user.hiring_manager' },
  { id: 'recruiter', label: 'user.recruiter' },
  { id: 'recruitment_admin', label: 'user.recruitment_admin' },
  { id: 'admin', label: 'user.admin' },
  {
    id: 'external_recruiter',
    label: 'user.external_recruiter',
  },
];
