import ApplicationInstance from '@ember/application/instance';
import { gql } from '@apollo/client/core';
import ReportAnalyticsRequest, {
  FetchOptions,
} from './report-analytics-request';
import {
  AnalyticsReportBuilder,
  BuildReportArgs,
  ComparableReport,
} from './analytics-report-builder';
import { compareCalculation } from 'teamtailor/utils/compare-percentage';

const QUERY = gql`
  query NPSDistributionQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [NPS_RESPONSE]
      distinctBy: {
        fields: [JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
      companyIds: $companyIds
    ) {
      aggregated {
        averageScore: average(field: NPS_SCORE)

        promotersCount: countOccurrences(
          filters: { npsResponseScore: { greaterThan: 8 } }
        )
        detractorsCount: countOccurrences(
          filters: { npsResponseScore: { lessThan: 7 } }
        )
        passivesCount: countOccurrences(
          filters: { npsResponseScore: { greaterThan: 6, lessThan: 9 } }
        )
      }
    }
  }
`;

interface Result {
  eventQuery:
    | {
        aggregated: NpsDistributionRow[];
      }
    | undefined;
}

export interface NpsDistributionRow {
  averageScore?: number;
  promotersCount: number;
  detractorsCount: number;
  passivesCount: number;
}

interface NpsDistributionReportArgs {
  averageScore?: number;
  promotersCount: number;
  detractorsCount: number;
  passivesCount: number;
  compareModel?: NpsDistributionReport;
}

class NpsDistributionReport implements ComparableReport {
  averageScore?: number;
  promotersCount!: number;
  detractorsCount!: number;
  passivesCount!: number;
  compareModel?: NpsDistributionReport;

  constructor(args: NpsDistributionReportArgs) {
    Object.assign(this, args);
  }

  get previousAverageScore() {
    return this.compareModel?.averageScore;
  }

  get promotersPercentage() {
    return (
      Math.round(100 * ((100 * this.promotersCount) / this.totalCount)) / 100.0
    );
  }

  get detractorsPercentage() {
    return (
      Math.round(100 * ((100 * this.detractorsCount) / this.totalCount)) / 100.0
    );
  }

  get passivesPercentage() {
    return (
      Math.round(100 * ((100 * this.passivesCount) / this.totalCount)) / 100.0
    );
  }

  get promotersCompare() {
    return compareCalculation(
      this.promotersCount,
      this.compareModel?.promotersCount
    );
  }

  get passivesCompare() {
    return compareCalculation(
      this.passivesCount,
      this.compareModel?.passivesCount
    );
  }

  get detractorsCompare() {
    return compareCalculation(
      this.detractorsCount,
      this.compareModel?.detractorsCount
    );
  }

  get totalCount() {
    return this.promotersCount + this.detractorsCount + this.passivesCount;
  }
}

export function buildReport(args: BuildReportArgs) {
  const { container, compareOptions } = args;
  return new AnalyticsReportBuilder<
    NpsDistributionReport,
    NpsDistributionReportArgs
  >(container, {
    query: async (options: FetchOptions = {}) => {
      return fetch(container, options);
    },

    compareOptions,

    createReport: (queryResult: NpsDistributionReportArgs) => {
      return new NpsDistributionReport(queryResult);
    },
  });
}

export async function fetch(
  container: ApplicationInstance,
  options: FetchOptions = {}
): Promise<NpsDistributionReportArgs> {
  let compareModel: NpsDistributionReport | undefined;

  const rows = await new ReportAnalyticsRequest({
    container,
    query: QUERY,
    callback: (result?: Result) => result?.eventQuery?.aggregated,
  }).fetch(options);

  if (!rows?.length) {
    return {
      promotersCount: 0,
      detractorsCount: 0,
      passivesCount: 0,
    };
  }

  const { averageScore, promotersCount, detractorsCount, passivesCount } =
    rows[0];

  return {
    compareModel,
    averageScore,
    promotersCount,
    detractorsCount,
    passivesCount,
  };
}

export default NpsDistributionReport;
