import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import RouterService from '@ember/routing/router-service';
import Current from 'teamtailor/services/current';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class SettingsSecuritySsoIndex extends ScrollToTopRoute {
  @service declare router: RouterService;
  @service declare current: Current;

  async redirect() {
    if (this.current.company.hasParentCompany) {
      this.router.transitionTo('settings.security.sso.no-config');
    }

    const model = await get(this.current.company, 'singleSignOn');

    if (model && get(model, 'idpSsoServiceUrl')) {
      this.router.replaceWith('settings.security.sso.show', get(model, 'id'));
    } else {
      this.router.replaceWith('settings.security.sso.new');
    }
  }
}
