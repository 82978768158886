import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"common.category\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"label\"\n    @options={{this.options}}\n    @onSelect={{@onChange}}\n    @searchEnabled={{false}}\n    @selected={{find-by \"id\" @selected this.options}}\n    @nullOption={{t \"search.all\"}}\n  />\n</label>", {"contents":"<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"common.category\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @optionName=\"label\"\n    @options={{this.options}}\n    @onSelect={{@onChange}}\n    @searchEnabled={{false}}\n    @selected={{find-by \"id\" @selected this.options}}\n    @nullOption={{t \"search.all\"}}\n  />\n</label>","moduleName":"teamtailor/components/sidebar/filters/tag-category.hbs","parseOptions":{"srcName":"teamtailor/components/sidebar/filters/tag-category.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import CurrentService from 'teamtailor/services/current';
import IntlService from 'ember-intl/services/intl';

interface TagCategoryArgs {
  hideDrafts: boolean | undefined;
}

export default class TagCategory extends Component<TagCategoryArgs> {
  @service declare intl: IntlService;
  @service declare current: CurrentService;

  get options(): { id: string | null; label: string }[] {
    const options = [
      {
        id: 'candidate',
        label: this.intl.t('settings.tag_manager.categories.candidates'),
      },
      {
        id: 'job',
        label: this.intl.t('settings.tag_manager.categories.jobs'),
      },
      {
        id: 'image',
        label: this.intl.t('settings.tag_manager.categories.images'),
      },
      {
        id: 'post',
        label: this.intl.t('settings.tag_manager.categories.posts'),
      },
      {
        id: 'page',
        label: this.intl.t('settings.tag_manager.categories.pages'),
      },
      {
        id: 'question',
        label: this.intl.t('settings.tag_manager.categories.questions'),
      },
      {
        id: 'cannedresponse',
        label: this.intl.t('settings.tag_manager.categories.cannedresponses'),
      },
    ];

    if (this.current.company.hasFeature('interview_kit')) {
      options.pushObject({
        id: 'interviewkit',
        label: this.intl.t('settings.tag_manager.categories.interviewkits'),
      });
    }

    return options;
  }
}
