import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AudienceDevicesReport from 'teamtailor/classes/analytics/report-audience-devices';
import { getOwner } from '@ember/application';

export default class MobileRoute extends Route {
  @service analytics;

  model() {
    const container = getOwner(this);
    // NOTE: If the task call wasn't wrapped in an object, Ember's model hook
    // resolution would return the data after resolving the promise, so our handling
    // of the analytics data would be different from the overview page.
    //
    // Also, this way, we have the option of showing the loading state by
    // accessing @model.devices.isRunning
    return {
      devices: new AudienceDevicesReport({ container }).fetchTask,
    };
  }
}
