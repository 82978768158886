/* eslint-disable ember/use-ember-get-and-set */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { BufferedChangeset } from 'ember-changeset/types';
import IntlService from 'ember-intl/services/intl';
import FromRoute from 'teamtailor/routes/settings/security/sso/edit';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import TtAlertService from 'teamtailor/services/tt-alert';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import { tracked } from 'tracked-built-ins';

export default class SettingsSecuritySsoEdit extends Controller {
  declare model: ModelFrom<FromRoute>;

  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;
  @service declare current: Current;

  @tracked declare changeset: BufferedChangeset;

  get availableUserRoles() {
    return this.model.availableUserRoles.map((role) => ({
      value: role,
      label: this.intl.t(`user.${role}`),
    }));
  }

  @action
  toggleActive() {
    this.changeset.set('active', !this.changeset.active);
  }

  @action
  handleUserRoleChange(option: { value: string; label: string }) {
    this.changeset.set('defaultUserRole', option.value);
  }

  @action
  handleMultiCertChange(index: number, key: string, event: Event) {
    const target = event.target as HTMLTextAreaElement;
    const data = this.changeset.get(key).toArray();
    data[index] = target.value;
    this.changeset.set(key, data);
  }

  @action
  async saveChanges() {
    try {
      await this.changeset.save();
      this.router.transitionTo(
        'settings.security.sso.show',
        this.changeset.get('id')
      );
      return Promise.resolve();
    } catch (error) {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
      return Promise.reject(error);
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.security.sso.edit': SettingsSecuritySsoEdit;
  }
}
