import Store from '@ember-data/store';
import { FilterOption } from 'teamtailor/components/fancy-filters';
import { CustomFieldModel } from 'teamtailor/models';
import SelectedFilter from '../filters/selected-filter';
import { prepareExistsOperator } from 'teamtailor/utils/operators/exists-operator';
import ModelProperty, { ModelPropertyOptions } from './model-property';
import BaseProperty, { BasePropertyOptions } from './base-property';
import BooleanProperty, { BooleanPropertyOptions } from './boolean-property';
import { QueryType } from '../base-query';
import SharedReportStoreService from 'teamtailor/services/shared-report-store';
import { ChartType } from '../settings';

interface CustomFieldModelPropertyOptions extends ModelPropertyOptions {
  customFieldId: string;
}

class CustomFieldModelProperty extends ModelProperty {
  customFieldId: string;

  constructor(options: CustomFieldModelPropertyOptions) {
    super(options);
    this.customFieldId = options.customFieldId;
  }

  getStatement(queryType: QueryType) {
    if (this.columnName === this.getField(queryType)) {
      return `${this.columnName}(customFieldId: ${this.customFieldId})`;
    }

    return `${this.columnName}: ${this.getField(queryType)}(customFieldId: ${
      this.customFieldId
    })`;
  }
}

interface CustomFieldBooleanPropertyOptions extends BooleanPropertyOptions {
  customFieldId: string;
}

class CustomFieldBooleanProperty extends BooleanProperty {
  customFieldId: string;

  constructor(options: CustomFieldBooleanPropertyOptions) {
    super(options);
    this.customFieldId = options.customFieldId;
  }

  getStatement(queryType: QueryType) {
    if (this.columnName === this.getField(queryType)) {
      return `${this.columnName}(customFieldId: ${this.customFieldId})`;
    }

    return `${this.columnName}: ${this.getField(queryType)}(customFieldId: ${
      this.customFieldId
    })`;
  }
}

interface CustomFieldBasePropertyOptions extends BasePropertyOptions {
  customFieldId: string;
}

class CustomFieldBaseProperty extends BaseProperty {
  customFieldId: string;

  constructor(options: CustomFieldBasePropertyOptions) {
    super(options);
    this.customFieldId = options.customFieldId;
  }

  getStatement(queryType: QueryType) {
    if (this.columnName === this.getField(queryType)) {
      return `${this.columnName}(customFieldId: ${this.customFieldId})`;
    }

    return `${this.columnName}: ${this.getField(queryType)}(customFieldId: ${
      this.customFieldId
    })`;
  }
}

export default function createCustomFieldProperty(
  store: Store | SharedReportStoreService,
  groupAnalytics: boolean,
  customField: CustomFieldModel
) {
  const queryTypes: QueryType[] = ['event'];
  let namePrefix: string,
    enumPrefix: string,
    property: BaseProperty,
    icon: string;

  const groupByFilters: SelectedFilter[] = [];

  if (customField.ownerType === 'Job') {
    queryTypes.push('pageview');
    namePrefix = 'jobCustomField';
    enumPrefix = 'JOB_CUSTOM_FIELD';
  } else {
    namePrefix = 'candidateCustomField';
    enumPrefix = 'CANDIDATE_CUSTOM_FIELD';
  }

  const nameValue = `${namePrefix}Value`;
  const enumValue = `${enumPrefix}_VALUE_${customField.id}`;
  const nameOptionId = `${namePrefix}OptionId`;
  const enumOptionId = `${enumPrefix}_OPTION_ID_${customField.id}`;

  const options: { [key: string]: unknown } = {};
  const allowAsBaseForChartTypes: ChartType[] = ['bar', 'pie', 'table'];

  switch (customField.type) {
    case 'CustomField::Checkbox':
      property = new CustomFieldBooleanProperty({
        id: `custom_field_${customField.id}`,
        customFieldId: customField.id,
        store,
        icon: 'square-check',
        translationKey: customField.name,
        basePropertyValueTranslationKey: 'common',
        queryTypes,
        queryName: nameValue,
        queryEnum: enumValue,
        groupable: true,
        allowAsBaseForChartTypes,
        ...options,
      });

      break;
    case 'CustomField::Select':
    case 'CustomField::MultiSelect':
      property = new CustomFieldModelProperty({
        id: `custom_field_${customField.id}`,
        customFieldId: customField.id,
        store,
        groupAnalytics,
        icon: 'list',
        translationKey: customField.name,
        model: 'custom-field/option',
        modelType: 'custom_field_option',
        displayProperty: 'value',
        queryTypes,
        queryName: nameOptionId,
        queryEnum: enumOptionId,
        groupable: true,
        allowAsBaseForChartTypes,
        ...options,
      });
      break;
    default:
      switch (customField.type) {
        case 'CustomField::Number':
          icon = 'hashtag';
          break;
        case 'CustomField::Date':
          icon = 'calendar';
          break;
        default:
          icon = 'font';
          break;
      }

      property = new CustomFieldBaseProperty({
        id: `custom_field_${customField.id}`,
        customFieldId: customField.id,
        store,
        icon,
        columnType: 'string',
        translationKey: customField.name,
        queryTypes,
        queryName: nameValue,
        queryEnum: enumValue,
        groupable: true,
        allowAsBaseForChartTypes,
        ...options,
      });
      break;
  }

  if (customField.ownerType === 'Job') {
    const selectedFilter = new SelectedFilter({
      id: 'id',
      name: property.id,
      type: '[Job]',
      eventFieldName: 'jobCustomField',
      pageviewFieldName: 'jobCustomField',
      combinedFieldName: 'jobCustomField',
      filterOptions: { customFieldId: customField.id },
      queryTypes: ['pageview', 'event', 'combined'],
      modelName: property instanceof ModelProperty ? property.model : undefined,

      operators: [prepareExistsOperator()],
    } as FilterOption);
    groupByFilters.push(selectedFilter);
  } else {
    const selectedFilter = new SelectedFilter({
      id: 'id',
      name: property.id,
      type: '[Job]',
      eventFieldName: 'candidateCustomField',
      combinedFieldName: 'candidateCustomField',
      filterOptions: { customFieldId: customField.id },
      queryTypes: ['event', 'combined'],
      modelName: property instanceof ModelProperty ? property.model : undefined,

      operators: [prepareExistsOperator()],
    } as FilterOption);
    groupByFilters.push(selectedFilter);
  }

  property.groupByFilters = groupByFilters;

  return property;
}
