import Route from '@ember/routing/route';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class IndexRoute extends Route {
  @service store;

  queryParams = {
    archived: { refreshModel: true },
  };

  model({ archived }) {
    return this.store.query('survey', { archived });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      set(controller, 'modalIsOpen', false);
    }
  }
}
