import Service from '@ember/service';
import Evented from '@ember/object/evented';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';

interface QueueItem {
  type: string;
  data: Record<string, unknown>;
}

export default class StageListHelperService extends Service.extend(Evented) {
  // eslint-disable-next-line new-cap
  animationQueue: Array<QueueItem> = A([]);

  @tracked lastMovedJobApplicationId?: string;
  @tracked lastSuggestedCandidateId?: string;

  triggerStageLastVisibleChanged(stageId: string, args: unknown): void {
    this.trigger(`stageLastVisibleChanged-${stageId}`, args);
  }

  onNextStageLastVisibleChanged(
    stageId: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    listener: (...args: any[]) => void
  ): void {
    this.one(`stageLastVisibleChanged-${stageId}`, listener);
  }

  getMatchingAnimationQueueItem(itemToMatch: QueueItem): QueueItem | undefined {
    return this.animationQueue.find((queueItem: QueueItem) => {
      return (
        queueItem.type === itemToMatch.type &&
        Object.entries(itemToMatch.data).every(([key, value]) => {
          return value === queueItem.data[key];
        })
      );
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'stage-list-helper': StageListHelperService;
  }
}
