import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
  SyncHasMany,
} from '@ember-data/model';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import {
  ActivityModel,
  CandidateModel,
  CompanyModel,
  DepartmentModel,
  JobModel,
  LocationModel,
  PushPayloadArg,
  RequisitionFlowModel,
  RequisitionStepModel,
  RequisitionArchiveReasonModel,
  RoleModel,
  UserModel,
} from 'teamtailor/models';
import NotificationsService from 'teamtailor/services/notifications';
import Server from 'teamtailor/services/server';
import { get } from 'teamtailor/utils/get';
import recordRemover from 'teamtailor/utils/record-remover';

export default class RequisitionModel extends Model {
  @service declare notifications: NotificationsService;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare server: Server;

  @hasMany('candidate') declare hires: AsyncHasMany<CandidateModel>;
  @hasMany('job') declare jobs: AsyncHasMany<JobModel>;
  @hasMany('requisition-step', { async: false })
  declare steps: SyncHasMany<RequisitionStepModel>;

  @hasMany('activity') declare userActivities: AsyncHasMany<ActivityModel>;

  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('department') declare department: AsyncBelongsTo<DepartmentModel>;
  @belongsTo('location') declare location: AsyncBelongsTo<LocationModel>;
  @belongsTo('requisition-archive-reason', { async: false })
  declare requisitionArchiveReason: RequisitionArchiveReasonModel;

  @belongsTo('user', { inverse: 'requisitionRecruiter' })
  declare recruiter: AsyncBelongsTo<UserModel>;

  @belongsTo('requisition-flow')
  declare requisitionFlow: AsyncBelongsTo<RequisitionFlowModel>;

  @belongsTo('role') declare role: AsyncBelongsTo<RoleModel>;
  @belongsTo('user', { inverse: 'requisitionOwner' })
  declare user: AsyncBelongsTo<UserModel>;

  @attr('date') archivedAt?: Date | null;
  @attr('string') declare attachment: string;
  @attr('string') declare attachmentFileName: string;
  @attr('string') declare country: string;
  @attr('date') declare createdAt: Date;
  @attr('string') declare currency: number;
  @attr('raw') declare customForm: unknown;
  @attr('string') declare downloadAttachmentUrl: string;
  @attr('number') declare hiredCount: number;
  @attr('boolean', { defaultValue: false }) declare isDraft: boolean;
  @attr('string') declare jobDescription: string;
  @attr('string') declare jobTitle: string;
  @attr('number') declare maxSalary: number;
  @attr('number') declare minSalary: number;
  @attr('number', { defaultValue: 1 }) declare numberOfOpenings: number;
  @attr('date') completedAt?: Date;
  @attr('boolean', { defaultValue: false }) declare removeAttachment: boolean;
  @attr('string', { defaultValue: 'monthly' }) declare salaryTimeUnit: string;
  @attr('string') declare status: string;

  get reqId() {
    return `reqID${this.id}`;
  }

  get savedJobs() {
    return get(this, 'jobs').filterBy('status').filterBy('isTemp', false);
  }

  get publishedJobs() {
    return this.savedJobs.filterBy('isPublished');
  }

  get isCompleted() {
    const { numberOfOpenings, hires, completedAt } = this;

    if (completedAt) {
      return true;
    }

    return numberOfOpenings
      ? hires.length >= numberOfOpenings
      : hires.length > 0;
  }

  get isHiringComplete() {
    const { numberOfOpenings, hires } = this;

    return numberOfOpenings
      ? hires.length >= numberOfOpenings
      : hires.length > 0;
  }

  get hiresLeft() {
    const { numberOfOpenings, hires } = this;

    return numberOfOpenings - hires.length;
  }

  get numberOfJobs() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this as any).hasMany('jobs').ids().length;
  }

  // The requisition is considered approved
  // if each of its steps is approved
  get isApproved() {
    if (get(this, 'status') === 'approved') {
      return true;
    }

    const steps = get(this, 'steps');

    return (
      get(steps, 'length') && steps.every((step) => get(step, 'isApproved'))
    );
  }

  // The requisition is considered rejected
  // if any of its steps is rejected
  get isRejected() {
    const steps = get(this, 'steps');

    return get(steps, 'length') && steps.isAny('isRejected');
  }

  // The requisition is considered pending
  // it it's neither approved nor rejected
  get isPending() {
    const isApproved = get(this, 'isApproved');
    const isRejected = get(this, 'isRejected');

    return !isApproved && !isRejected;
  }

  get currentStep() {
    return this.steps.sortBy('stepIndex').findBy('isPending');
  }

  get approvedSteps() {
    return this.steps.filter((item) => get(item, 'isApproved'));
  }

  async archive(requisitionArchiveReasonId: string | null) {
    const data = { requisition_archive_reason_id: requisitionArchiveReasonId };
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'archive',
      options: { data },
    });
    this.store.pushPayload(response);
  }

  async updateFlow() {
    const data = { requisition: this.serialize() };
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'update_flow',
      options: { data },
      urlType: 'updateRecord',
    });
    this.store.pushPayload(response);
    recordRemover(this.store, 'requisition-step');
  }

  async copy() {
    return this.store.createRecord('requisition', {
      jobTitle: this.jobTitle,
      jobDescription: this.jobDescription,
      minSalary: this.minSalary,
      maxSalary: this.maxSalary,
      attachment: this.attachment,
      attachmentFileName: this.attachmentFileName,
      downloadAttachmentUrl: this.downloadAttachmentUrl,
      salaryTimeUnit: this.salaryTimeUnit,
      currency: this.currency,
      numberOfOpenings: this.numberOfOpenings,
      recruiter: await get(this, 'recruiter'),
      country: this.country,
      location: await get(this, 'location'),
      department: await get(this, 'department'),
      role: await get(this, 'role'),
      requisitionFlow: await get(this, 'requisitionFlow'),
      customForm: this.customForm,
    });
  }

  needsApprovalFromUser(user: UserModel) {
    if (this.isDraft || this.archivedAt || this.isRejected) {
      return false;
    }

    const currentStep = get(this, 'currentStep');

    if (!currentStep) {
      return undefined;
    }

    return currentStep.pendingVerdicts
      .mapBy('userId')
      .includes(get(user, 'id'));
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    requisition: RequisitionModel;
  }
}
