import UserModel from 'teamtailor/models/user';
export const TABLE_COLUMNS = [
  {
    label: 'common.name',
    width: 380,
    selectable: true,
  },
  {
    label: 'common.location',
    width: 170,
  },
  {
    label: 'common.department',
    width: 170,
  },
  {
    label: 'user.role',
    width: 120,
  },
  {
    label: 'employees.joined',
    width: 80,
  },
  {
    label: 'common.public',
    width: 80,
  },
  {
    label: 'components.employee_row.two_factor_activation_header',
    width: 80,
  },
];

export const TABLE_COLUMNS_SSO = [
  {
    label: 'components.employee_row.single_sign_on_header',
    width: 80,
  },
];

export const TABLE_LOADING_COLUMNS = [
  {
    width: '2xl',
    withAvatar: true,
    avatarClasses: 'rounded-full',
  },
  { width: 'm' },
  { width: 'm' },
  {
    width: 'm',
    hasCircles: true,
    amountOfCircles: 1,
    circleClasses: 'w-56 h-16',
  },
  {
    width: 'm',
    hasCircles: true,
    amountOfCircles: 1,
    circleClasses: 'w-56 h-16',
  },
  {
    width: 'm',
    hasCircles: true,
    amountOfCircles: 1,
    circleClasses: 'w-56 h-16',
  },
  {
    width: 'm',
    hasCircles: true,
    amountOfCircles: 1,
    circleClasses: 'w-56 h-16',
  },
];

export const TABLE_LOADING_COLUMNS_SSO = [
  {
    width: 'm',
    hasCircles: true,
    amountOfCircles: 1,
    circleClasses: 'w-56 h-16',
  },
];

export type EmployeeBulkArgs = {
  onClose: () => void;
  handleBulkChange: (data: unknown, action: string) => void;
  selectedCount: number;
  selectedIds: string[];
  deselectedIds: string[];
  selectAll: boolean;
  selectedEmployees: UserModel[];
};
