import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default class TodoTemplateSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    todos: {
      serialize: 'records',
      deserialize: 'ids',
      key: 'todos_attributes',
    },
  };
}
