import { setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import JobsEditExternalRecruitersController from 'teamtailor/controllers/jobs/edit/external-recruiters';
import { JobDetailModel } from 'teamtailor/models';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import { Transition } from 'teamtailor/utils/type-utils';

export default class JobsEditExternalRecruitmentRoute extends ScrollToTopRoute {
  @service declare current: Current;

  model(): Promise<JobDetailModel> {
    return get(this.modelFor('jobs.edit'), 'jobDetail');
  }

  async afterModel() {
    const relation = this.current.company.hasMany('cannedResponses');

    if (relation.ids().length === 0) {
      await get(this.current.company, 'cannedResponses');
    }
  }

  async setupController(
    controller: JobsEditExternalRecruitersController,
    model: JobDetailModel,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);

    setProperties(controller, {
      cannedResponses: this.current.company.cannedResponses.toArray(),
      jobUser: await get(model.job, 'user'),
    });
  }
}
