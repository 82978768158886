import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { TagModel } from 'teamtailor/models';

export default class TaggingCountModel extends Model {
  @belongsTo('tag') declare tag: AsyncBelongsTo<TagModel>;

  @attr('number') declare count: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tagging-count': TaggingCountModel;
  }
}
