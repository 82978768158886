import { tracked } from '@glimmer/tracking';
import {
  IInterviewKit,
  IMeetingEvent,
  IMeetingEventDateRange,
  IMeetingEventVisibility,
  ISelfSchedule,
} from 'teamtailor/components/meeting/types';
import { MeetingPreparedMessageAttributes } from '../prepared-message';
import { AttendeeAttributes, AttendeeListClass } from './attendee-list';
import { BookedSlotClass } from './booked-slot';
import {
  LocationType,
  MeetingEventLocationAttributes,
  MeetingEventLocationClass,
} from './meeting-event-location';
import { SelfScheduleClass } from './self-schedule';
import { UserAttendeeClass } from './user-attendee';
import moment from 'moment-timezone';
import { MeetingRoomAttendeeClass } from './meeting-room-attendee';
import { MeetingEventListClass } from 'teamtailor/classes/meetings';

interface IMeetingEventAttributes {
  id?: string;
  summary?: string;
  description?: string;
  feedbackReminder?: boolean;
  candidateReminder?: boolean;
  bookedSlot?: IMeetingEventDateRange | null;
  selfSchedule?: ISelfSchedule | null;
  interviewKitId?: string;
  tzid?: string;
  visibility?: IMeetingEventVisibility;
  meetingEventAttendeesAttributes?: AttendeeAttributes[];
  meetingEventLocationAttributes?: MeetingEventLocationAttributes;
  _destroy?: boolean;
}

export type MeetingEventAttributes = IMeetingEventAttributes;

export interface IMeetingRecord {
  id?: string;
  meetingEventsAttributes: IMeetingEventAttributes[];
  preparedMessageAttributes?: MeetingPreparedMessageAttributes;
  jobId?: string;
}
export type MeetingEventConstructorType = Partial<IMeetingEvent> & {
  organizer: UserAttendeeClass;
};

export class MeetingEventClass {
  @tracked declare id?: string;
  @tracked declare summary: string;
  @tracked declare description: string;
  @tracked declare tzid: string;
  @tracked declare meetingEventAttendees: AttendeeListClass;
  @tracked declare meetingEventLocation?: LocationType;
  @tracked declare originalLocation?: LocationType;
  @tracked declare bookedSlot?: BookedSlotClass;
  @tracked declare selfSchedule?: SelfScheduleClass;
  @tracked declare interviewKit?: IInterviewKit;
  @tracked declare feedbackReminder: true | false;
  @tracked declare candidateReminder: true | false;
  @tracked declare visibility: IMeetingEventVisibility;
  @tracked declare readonly originalBookedSlot?: BookedSlotClass;
  @tracked declare meetingEventList: MeetingEventListClass;

  @tracked private declare _organizer: UserAttendeeClass;
  @tracked private declare _event: MeetingEventConstructorType;

  constructor(event: MeetingEventConstructorType) {
    this.id = event.id;
    this.summary = event.summary ?? '';
    this.description = event.description ?? '';
    this.meetingEventAttendees = AttendeeListClass.from(
      event.meetingEventAttendees ?? []
    );
    this.meetingEventLocation = MeetingEventLocationClass.from(
      event.meetingEventLocation
    );
    this.originalLocation = MeetingEventLocationClass.from(
      event.meetingEventLocation
    );
    this.originalBookedSlot = event.bookedSlot
      ? new BookedSlotClass(event.bookedSlot)
      : undefined;
    this.bookedSlot = event.bookedSlot
      ? new BookedSlotClass(event.bookedSlot)
      : undefined;
    this.selfSchedule = event.selfSchedule
      ? new SelfScheduleClass(event.selfSchedule)
      : undefined;
    this.interviewKit = event.interviewKit;
    this.feedbackReminder = event.feedbackReminder ?? true;
    this.candidateReminder = event.candidateReminder ?? true;
    this.tzid = event.tzid ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.visibility = event.visibility ?? 'default';
    this._organizer = new UserAttendeeClass(event.organizer);
    this._event = event;
  }

  get organizer(): UserAttendeeClass | undefined {
    return this._organizer;
  }

  reassignOrganizer(attendee: UserAttendeeClass): void {
    const excludeOrganizer = this._organizer.excludeAvailability;
    this._organizer.excludeAvailability = false;
    this.meetingEventAttendees.userAttendees.forEach(
      (a) => (a.isOrganizer = false)
    );
    attendee.isOrganizer = true;
    attendee.excludeAvailability = excludeOrganizer;
    this._organizer = attendee;
  }

  get bookedMeetingRoom(): MeetingRoomAttendeeClass | undefined {
    return this.meetingEventAttendees.bookedMeetingRoom;
  }

  get isReadOnly(): boolean {
    if (this.meetingEventAttendees.candidateAttendees.length === 0) {
      return true;
    }

    const originalStartDate = this.originalBookedSlot?.startsAt;
    return !!this.id && moment().isAfter(originalStartDate);
  }

  clearInterviewKit(): void {
    this.meetingEventAttendees.setAllHasInterview(false);
    this.interviewKit = undefined;
  }

  selectInterviewKit(interviewKit: IInterviewKit | undefined): void {
    if (!this.interviewKit && interviewKit) {
      this.meetingEventAttendees.setAllHasInterview(true);
    }

    this.interviewKit = interviewKit;
  }

  static from(event: MeetingEventConstructorType): MeetingEventClass {
    return new MeetingEventClass(event);
  }

  static toAttributes(events: MeetingEventClass[]): MeetingEventAttributes[] {
    return events.map((event) => MeetingEventClass.createAttributes(event));
  }

  toAttributes(): MeetingEventAttributes {
    return MeetingEventClass.createAttributes(this);
  }

  hasChanges(): boolean {
    return (
      this.summary !== (this._event.summary ?? '') ||
      this.description !== (this._event.description ?? '') ||
      this.meetingEventAttendees.hasChanges() ||
      (this._event.bookedSlot
        ? this.bookedSlot?.hasChanges()
        : this.bookedSlot !== undefined) ||
      (this._event.selfSchedule
        ? this.selfSchedule?.hasChanges()
        : this.selfSchedule !== undefined) ||
      this.interviewKit !== this._event.interviewKit ||
      this.feedbackReminder !== (this._event.feedbackReminder ?? true) ||
      this.tzid !==
        (this._event.tzid ??
          Intl.DateTimeFormat().resolvedOptions().timeZone) ||
      this._organizer.user?.id !== this._event.organizer.user?.id
    );
  }

  private static createAttributes(
    event: MeetingEventClass
  ): MeetingEventAttributes {
    const {
      summary,
      description,
      bookedSlot,
      selfSchedule,
      interviewKit,
      feedbackReminder,
      candidateReminder,
      meetingEventAttendees,
      meetingEventLocation,
      originalLocation,
      id,
      tzid,
      visibility,
    } = event;

    let locationAttributes: MeetingEventLocationAttributes | undefined;
    if (!meetingEventLocation && originalLocation) {
      locationAttributes = {
        id: originalLocation.id,
        _destroy: true,
      };
    } else {
      locationAttributes = meetingEventLocation?.toAttributes();
    }

    let _candidateReminder = candidateReminder;
    if (candidateReminder && bookedSlot?.startsAt) {
      _candidateReminder = moment(bookedSlot.startsAt).isAfter(
        moment().add(1, 'days')
      );
    }

    return {
      id,
      summary: summary || event.meetingEventList.preparedMessage?.subject,
      description,
      bookedSlot: bookedSlot?.toAttributes() ?? null,
      selfSchedule: selfSchedule?.toAttributes() ?? null,
      interviewKitId: interviewKit?.id,
      feedbackReminder,
      candidateReminder: _candidateReminder,
      meetingEventAttendeesAttributes: meetingEventAttendees.toAttributes(),
      meetingEventLocationAttributes: locationAttributes,
      visibility,
      tzid,
    };
  }
}
