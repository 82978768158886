import Service, { inject as service } from '@ember/service';
import { task, waitForEvent } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import ENV from 'teamtailor/config/environment';

const EVENT_ORIGIN = `${ENV.httpOrHttps}://${ENV.RAILS_HOST}`;
const GOOGLE_AUTH_URL = `https://${ENV.RAILS_HOST}/auth/google_oauth2`;

export default class TtAdminService extends Service {
  @service server;

  @tracked showIframe = false;

  authenticate = task(async () => {
    this.showIframe = true;
    let event = await waitForEvent(window, 'message');

    if (event.origin === EVENT_ORIGIN && event.data.authorized) {
      await this.server.request('/auth/tt-admin', 'PUT', {
        credentials: 'include',
        data: {
          token: event.data.encrypted_token,
        },
      });
      window.location.reload();
    } else {
      window.location = `${GOOGLE_AUTH_URL}?origin=${encodeURIComponent(
        window.location
      )}`;
    }
  });
}
