import TranslationModel from 'teamtailor/models/translation';
import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import DepartmentModel from 'teamtailor/models/department';

export default class TranslationDepartmentModel extends TranslationModel {
  @belongsTo('department') declare department: AsyncBelongsTo<DepartmentModel>;
  @attr('string') declare connectWelcomeMessage: string;
  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'translation/department': TranslationDepartmentModel;
  }
}
