import { attr } from '@ember-data/model';
import TriggerModel from 'teamtailor/models/trigger';
import { isEmpty } from '@ember/utils';
import { get } from 'teamtailor/utils/get';

export default class TriggerTodoModel extends TriggerModel<'todo'> {
  @attr('raw', {
    defaultValue() {
      return [];
    },
  })
  declare todos: { value: string }[];

  get numberOfTodos() {
    return this.todos.length;
  }

  get valid() {
    if (isEmpty(this.todos)) {
      return false;
    }

    return (
      this.todos.mapBy('value').filter((value) => !isEmpty(value)).length > 0
    );
  }

  get description() {
    return this.intl.t('models.trigger.todo.add_todos', {
      count: get(this, 'numberOfTodos'),
    });
  }

  get triggerContent() {
    if (this.todos.length === 1 && this.todos[0]) {
      return this.todos[0].value;
    } else {
      return `${this.todos.length}`;
    }
  }

  get triggerTranslationName() {
    return this.todos.length === 1 ? 'one_todo' : 'many_todos';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'trigger/todo': TriggerTodoModel;
  }
}
