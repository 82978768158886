import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import {
  ActivityModel,
  AnswerModel,
  CandidateModel,
  CannedResponseModel,
  FormModel,
  JobModel,
  MessageModel,
  NoteModel,
  UserModel,
} from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';

export default class UploadModel extends Model {
  @belongsTo('activity') declare activity: AsyncBelongsTo<ActivityModel>;
  @belongsTo('answer') declare answer: AsyncBelongsTo<AnswerModel>;
  @belongsTo('candidate') declare candidate: AsyncBelongsTo<CandidateModel>;
  @belongsTo('canned-response')
  declare cannedResponse: AsyncBelongsTo<CannedResponseModel>;

  @belongsTo('form') declare form: AsyncBelongsTo<FormModel>;
  @belongsTo('job') declare job: AsyncBelongsTo<JobModel>;
  @belongsTo('message') declare message: AsyncBelongsTo<MessageModel>;
  @belongsTo('note') declare note: AsyncBelongsTo<NoteModel>;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;

  @attr('boolean') declare internal: boolean;
  @attr('boolean') declare isFileInfected: boolean;
  @attr('date') declare createdAt: Date;
  @attr('file') file?: File;
  @attr('string') declare fileContentType: string;
  @attr('string') declare fileFileName: string;
  @attr('string') declare fileUrl: string;
  @attr('string') declare languageCode: string;
  @attr('string') declare temporaryFileUrl: string;

  get fileNameWithFallback() {
    return this.fileFileName || this.file?.name;
  }

  get notFromAnswer() {
    return isEmpty(get(this.answer, 'id'));
  }

  get hasActivity() {
    return !isEmpty(get(this.activity, 'id'));
  }

  get hasMessage() {
    return !isEmpty(get(this.message, 'id'));
  }

  get fileName() {
    return this.fileFileName;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    upload: UploadModel;
  }
}
