import { attr } from '@ember-data/model';
import Section from './section';

export default class ContentBlockLeadModel extends Section {
  @attr('string') declare buttonText: string;
  @attr('string') declare respondTimeText: string;
  @attr('boolean') declare isBlank: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'content-block-lead': ContentBlockLeadModel;
  }
}
