import { inject as service } from '@ember/service';
import { setProperties } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import CandidateModalRoute from 'teamtailor/routes/candidate-modal';

export default class CandidateRoute extends CandidateModalRoute {
  @service current;
  @service store;

  jobApplication = null;

  get company() {
    return this.current.company;
  }

  get user() {
    return this.current.user;
  }

  markAsRead(jobApplication) {
    if (
      get(jobApplication, 'unread') &&
      (!get(this, 'user.ttUser') ||
        get(this, 'user.company.id') === get(this, 'company.id'))
    ) {
      jobApplication.markAsRead();
    }
  }

  async afterModel(candidate) {
    super.afterModel(candidate);

    const job = this.modelFor('jobs.job');
    const jobId = parseInt(get(job, 'id'));
    const jobApplications = await get(candidate, 'jobApplications');

    this.jobApplication = jobApplications.findBy('jobId', jobId);
    this.stage = await get(this.jobApplication, 'stage');

    this.markAsRead(this.jobApplication);
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    const { job, candidateIds, params } = this.modelFor(
      'jobs.job.stages.index'
    );

    const properties = {
      job,
      candidateIds,
      jobApplication: this.jobApplication,
      params,
    };

    if (!this.controller.stage) {
      properties.stage = this.stage;
      properties.showRejected = get(this.jobApplication, 'isRejected');
    }

    properties.openCustomSidebar = get(
      this.controllerFor('jobs.job'),
      'isCustomOpen'
    );

    setProperties(controller, properties);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      setProperties(controller, {
        _browseModelsCache: [],
        stage: null,
        jobApplication: null,
        showRejected: false,
      });
    }
  }
}
