import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { gt, filterBy, alias } from '@ember/object/computed';
import Controller from '@ember/controller';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-mixins
import SearchQuery from 'teamtailor/mixins/search-query';
import { get, set, setProperties, action, computed } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import {
  TABLE_COLUMNS,
  LOADING_COLUMNS,
} from 'teamtailor/constants/nurture-campaign';
import { tracked } from '@glimmer/tracking';

@classic
export default class IndexController extends Controller.extend(SearchQuery) {
  queryParams = [
    'query',
    'status',
    {
      sortColumn: 'sort_column',
      sortDirection: 'sort_direction',
    },
  ];

  @service intl;

  columns = TABLE_COLUMNS;
  loadingColumns = LOADING_COLUMNS;

  @tracked sortColumn = 'campaign';
  @tracked sortDirection = 'desc';
  @tracked query = '';
  @tracked status = '';

  @computedLocalStorage(
    Boolean,
    'NurtureCampaignsIndex.showFilterSidebar',
    false
  )
  showFilterSidebar;

  get hasStatus() {
    return this.status !== '';
  }

  @alias('model.meta.total_available_count')
  totalAvailableCount;

  @gt('totalAvailableCount', 0)
  availableExists;

  @filterBy('model', 'isNew', false)
  savedNurtureCampaigns;

  @filterBy('savedNurtureCampaigns', 'isTemp', false)
  visibleNurtureCampaigns;

  @alias('visibleNurtureCampaigns')
  nurtureCampaigns;

  @computed(
    'model.meta.{total_available_count,total_count}',
    'totalAvailableCount'
  )
  get totalCount() {
    return get(this, 'totalAvailableCount') > 0
      ? get(this, 'model.meta.total_count')
      : undefined;
  }

  @computed('nurtureCampaigns.[]')
  get completedMessageTitle() {
    return this.intl.t(
      'nurture_campaign.great_work_your_campaign_was_created',
      { campaignCount: get(this, 'nurtureCampaigns').length }
    );
  }

  handleSearchInput = task(
    {
      restartable: true,
    },
    async (value) => {
      await timeout(get(this, 'searchProxyDelay'));

      if (value && value.length) {
        setProperties(this, {
          page: 1,
          query: value,
        });
      } else {
        set(this, 'query', '');
      }
    }
  );

  @action
  clearFilters() {
    set(this, 'status', '');
  }

  @action
  selectItem(attribute, selected) {
    this.resetPage();
    set(this, attribute, selected ? get(selected, 'id') : '');
  }

  @action
  resetPage() {
    set(this, 'page', 1);
  }

  @action
  setPage(page) {
    set(this, 'page', page);
    window.scrollTo(0, 0);
  }
}
