import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @route}}\n  <Button\n    ...attributes\n    @appearance=\"secondary\"\n    @icon=\"chevron-left\"\n    @linkToRoute={{@route}}\n    @model={{@model}}\n    @query={{hash query=@buttonQueryParams}}\n    @text={{@text}}\n  />\n{{else}}\n  <Button\n    ...attributes\n    @appearance=\"secondary\"\n    @icon=\"chevron-left\"\n    @onClick={{this.goBack}}\n    @text={{@text}}\n  />\n{{/if}}", {"contents":"{{#if @route}}\n  <Button\n    ...attributes\n    @appearance=\"secondary\"\n    @icon=\"chevron-left\"\n    @linkToRoute={{@route}}\n    @model={{@model}}\n    @query={{hash query=@buttonQueryParams}}\n    @text={{@text}}\n  />\n{{else}}\n  <Button\n    ...attributes\n    @appearance=\"secondary\"\n    @icon=\"chevron-left\"\n    @onClick={{this.goBack}}\n    @text={{@text}}\n  />\n{{/if}}","moduleName":"teamtailor/components/back-button.hbs","parseOptions":{"srcName":"teamtailor/components/back-button.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class BackButton extends Component {
  @action
  goBack() {
    window.history.back();
  }
}
