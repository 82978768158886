import BaseProperty from '../properties/base-property';
import BaseFilter from './base-filter';

export default class ExistsFilter extends BaseFilter {
  constructor() {
    super('exists');
  }

  serializeValue(_property: BaseProperty, value: any): string {
    return value;
  }

  deserializeValue(_property: BaseProperty, value: string): any {
    return value;
  }
}
