import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Molecules::Widget\n  ...attributes\n  @title={{t \"insights.widgets.nps_moving_average.title\"}}\n  @subTitle={{@subTitle}}\n  @loading={{@loading}}\n  @empty={{@isEmpty}}\n  @emptyEmoji=\"⛱️\"\n  @disableHover={{eq this.numberOfResponses 0}}\n  @showMoreLink={{@showMoreLink}}\n  @onShowExportModal={{unless @showMoreLink this.onShowExportModal}}\n>\n  <:content>\n    <div class=\"overflow-hidden px-8 pt-8\">\n      <Insights::Charts::NpsMovingAverageChart\n        @data={{this.chartData}}\n        @series={{this.seriesNames}}\n        @columns={{this.dates}}\n        @startDate={{this.analytics.startDateParam}}\n        @endDate={{this.analytics.endDateParam}}\n        @colors={{this.colors}}\n        @height=\"350px\"\n      />\n    </div>\n  </:content>\n</Insights::Molecules::Widget>", {"contents":"<Insights::Molecules::Widget\n  ...attributes\n  @title={{t \"insights.widgets.nps_moving_average.title\"}}\n  @subTitle={{@subTitle}}\n  @loading={{@loading}}\n  @empty={{@isEmpty}}\n  @emptyEmoji=\"⛱️\"\n  @disableHover={{eq this.numberOfResponses 0}}\n  @showMoreLink={{@showMoreLink}}\n  @onShowExportModal={{unless @showMoreLink this.onShowExportModal}}\n>\n  <:content>\n    <div class=\"overflow-hidden px-8 pt-8\">\n      <Insights::Charts::NpsMovingAverageChart\n        @data={{this.chartData}}\n        @series={{this.seriesNames}}\n        @columns={{this.dates}}\n        @startDate={{this.analytics.startDateParam}}\n        @endDate={{this.analytics.endDateParam}}\n        @colors={{this.colors}}\n        @height=\"350px\"\n      />\n    </div>\n  </:content>\n</Insights::Molecules::Widget>","moduleName":"teamtailor/components/insights/widgets/nps-moving-average.hbs","parseOptions":{"srcName":"teamtailor/components/insights/widgets/nps-moving-average.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import { movingAverageColors } from 'teamtailor/components/insights/charts/colors';
import IntlService from 'ember-intl/services/intl';
import AnalyticsService from 'teamtailor/services/analytics';
import {
  NpsMovingAverageRow,
  fetch,
} from 'teamtailor/classes/analytics/report-nps-moving-average';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import Current from 'teamtailor/services/current';
import InsightsExportService from 'teamtailor/services/insights-export';
import DateRange from 'teamtailor/utils/date-range';

const EXPORT_TABLE_COLUMNS = [
  {
    type: 'date',
    propertyName: 'date',
    headerKey: 'common.date',
  },
  {
    type: 'number',
    propertyName: 'npsScore',
    headerKey: 'insights.widgets.nps_moving_average.daily_average',
  },
  {
    type: 'number',
    propertyName: 'movingAverageNpsScore',
    headerKey: 'insights.widgets.nps_moving_average.moving_average',
  },
];

type Args = {
  data?: NpsMovingAverageRow[];
  loading?: boolean;
  emptyText?: string;
  isEmpty?: boolean;
  showMoreLink?: string;
};

export default class InsightsWidgetsNpsMovingAverage extends Component<Args> {
  @service declare intl: IntlService;
  @service declare analytics: AnalyticsService;
  @service declare insightsExport: InsightsExportService;
  @service declare current: Current;

  @tracked hovering = false;

  get colors() {
    return movingAverageColors;
  }

  get inflatedData() {
    if (!this.args.data?.length) return [];

    const data = [...this.args.data];
    let lastRow = data[data.length - 1];
    if (!lastRow) return [];

    const endDate = this.analytics.endDateParam;
    if (lastRow.date !== endDate) {
      lastRow = { ...lastRow, date: endDate };
      data.push(lastRow);
    }

    return data.map((row) => {
      return {
        ...row,
        x: moment(row.date).diff(this.analytics.startDate, 'days'),
      };
    });
  }

  get dates() {
    return this.inflatedData.map((row) => row.date);
  }

  get series() {
    const { inflatedData } = this;

    return [
      {
        name: this.intl.t('insights.widgets.nps_moving_average.moving_average'),
        color: this.colors.positive,
        data: inflatedData.map((row) => {
          return [row.x, Math.round(100.0 * row.movingAverageNpsScore) / 100.0];
        }),
      },
      {
        name: this.intl.t('insights.widgets.nps_moving_average.daily_average'),
        color: this.colors.negative,
        data: inflatedData.map((row) => {
          return row.npsScore;
        }),
      },
    ];
  }

  get seriesNames() {
    return this.series.map((series) => {
      return series.name;
    });
  }

  get chartData() {
    return this.series.map((series) => {
      return series.data;
    });
  }

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      'insights.widgets.nps_moving_average.title',
      `nps`,
      EXPORT_TABLE_COLUMNS,
      this.fetchExportData,
      new DateRange(this.analytics.startDate, this.analytics.endDate),
      {
        sortKey: 'date',
        sortDirection: 'desc',
        createdAt: get(this.current.company, 'went_live_at'),
      }
    );
  }

  @action
  async fetchExportData(dateRange: DateRange) {
    const container = getOwner(this);
    const rows = await fetch(container, { dateRange });
    return rows.map((row: NpsMovingAverageRow) => ({
      ...row,
      npsScore: Math.round(100.0 * row.npsScore) / 100.0,
      movingAverageNpsScore:
        Math.round(100.0 * row.movingAverageNpsScore) / 100.0,
    }));
  }
}
