import { camelize, capitalize, underscore } from '@ember/string';
import { QueryType } from '../base-query';
import Store from '@ember-data/store';
import IntlService from 'ember-intl/services/intl';
import EventType from '../event-types';
import BaseFilter from '../filters/base-filter';
import { ChartType } from '../settings';
import SharedReportStoreService from 'teamtailor/services/shared-report-store';
import SelectedFilter from '../filters/selected-filter';

type PropertyType =
  | 'base'
  | 'date'
  | 'boolean'
  | 'collect'
  | 'enum'
  | 'model'
  | 'count'
  | 'distinct-count'
  | 'count-occurrences'
  | 'max'
  | 'min'
  | 'average'
  | 'customFormat';

const isCountType = (property: BaseProperty) => {
  return ['distinct-count', 'count-occurrences', 'count'].includes(
    property.type
  );
};

const isDurationType = (property: BaseProperty) => {
  return (
    ['max', 'min', 'average'].includes(property.type) && !isRatingType(property)
  );
};

const isRatingType = (property: BaseProperty) => {
  return property.parentProperty?.id === 'review_rating';
};

export interface BasePropertyOptions {
  id: string;
  type?: PropertyType;
  translationKey?: string;
  translationOptions?: (intl: IntlService) => {
    [key: string]: string;
  };
  queryTypes: QueryType[];
  groupable?: boolean;
  allowAsBaseForChartTypes?: ChartType[];
  groupByFilters?: SelectedFilter[];
  groupByAddsExistsFilter?: boolean;
  queryName?: string;
  queryEnum?: string;
  pageviewQueryName?: string;
  pageviewQueryEnum?: string;
  eventQueryEnum?: string;
  eventQueryName?: string;
  combinedQueryName?: string;
  combinedQueryEnum?: string;
  aggregatedProperties?: BaseProperty[];
  parentProperty?: BaseProperty;

  basePropertyValueTranslationKey?: string;

  operators?: BaseFilter[];

  icon?: string;
  iconStyle?: string;

  columnType?: string;

  eventTypes?: EventType[];

  category?: string;

  store: Store | SharedReportStoreService;

  incompatibleBaseProperties?: string[];

  onlyCompatibleWithParent?: boolean;
}

export default class BaseProperty {
  store: Store | SharedReportStoreService;
  id: string;
  type: PropertyType;
  translatedName?: string;
  translationKey: string;
  translationOptions?: (intl: IntlService) => {
    [key: string]: string;
  };

  basePropertyValueTranslationKey?: string;
  translateBasePropertyValue?: (value: boolean | string) => string;

  queryTypes: QueryType[];

  icon?: string;
  iconStyle?: string;

  groupable?: boolean;
  allowAsBaseForChartTypes: ChartType[];
  groupByFilters?: SelectedFilter[];
  groupByAddsExistsFilter: boolean;
  queryName?: string;
  queryEnum?: string;
  pageviewQueryName?: string;
  pageviewQueryEnum?: string;
  eventQueryEnum?: string;
  eventQueryName?: string;
  combinedQueryName?: string;
  combinedQueryEnum?: string;
  aggregatedProperties: BaseProperty[] = [];
  parentProperty?: BaseProperty;
  eventTypes: EventType[];

  operators: BaseFilter[];

  columnType?: string;

  category?: string;

  incompatibleBaseProperties: string[];

  onlyCompatibleWithParent?: boolean;

  get columnName() {
    return camelize(this.id);
  }

  get isSortable() {
    return (
      isCountType(this) ||
      isDurationType(this) ||
      isRatingType(this) ||
      this.type === 'date'
    );
  }

  get displayNameForChartName() {
    return this.translatedName?.toLowerCase();
  }

  constructor(options: BasePropertyOptions) {
    this.store = options.store;
    this.id = options.id;
    this.type = options.type || 'base';
    this.translationKey = options.translationKey!;
    this.translationOptions = options.translationOptions;
    this.queryTypes = options.queryTypes;
    this.groupable = options.groupable;
    this.allowAsBaseForChartTypes = options.allowAsBaseForChartTypes || [
      'bar',
      'pie',
      'table',
    ];
    this.groupByFilters = options.groupByFilters;
    this.groupByAddsExistsFilter = options.groupByAddsExistsFilter || false;
    this.queryName = options.queryName;
    this.queryEnum = options.queryEnum;
    this.pageviewQueryName = options.pageviewQueryName;
    this.pageviewQueryEnum = options.pageviewQueryEnum;
    this.eventQueryEnum = options.eventQueryEnum;
    this.eventQueryName = options.eventQueryName;
    this.combinedQueryName = options.combinedQueryName;
    this.combinedQueryEnum = options.combinedQueryEnum;
    this.aggregatedProperties = options.aggregatedProperties || [];
    this.parentProperty = options.parentProperty;
    this.eventTypes = options.eventTypes || [];

    this.incompatibleBaseProperties = options.incompatibleBaseProperties || [];
    this.onlyCompatibleWithParent = options.onlyCompatibleWithParent;

    this.basePropertyValueTranslationKey =
      options.basePropertyValueTranslationKey;

    this.operators = options.operators || [];

    this.columnType = options.columnType;
    this.icon = options.icon;
    this.iconStyle = options.iconStyle;
    this.aggregatedProperties.forEach((property) => {
      property.parentProperty = this;
    });
  }

  translate(intl: IntlService) {
    if (this.translationOptions) {
      this.translatedName = capitalize(
        intl
          .t(this.translationKey, {
            count: 10,
            ...this.translationOptions(intl),
          })
          .toLowerCase()
      );
    } else {
      this.translatedName = capitalize(
        intl.t(this.translationKey, { count: 1 }).toLowerCase()
      );
    }

    this.aggregatedProperties.forEach((property) => {
      property.translate(intl);
    });
    if (this.basePropertyValueTranslationKey) {
      this.translateBasePropertyValue = (value: boolean | string) =>
        intl.t(
          `${this.basePropertyValueTranslationKey!}.${
            typeof value === 'string' ? value.toLowerCase() : value
          }`
        );
    }
  }

  getKey(queryType: QueryType) {
    let field = this.queryEnum || this.queryName || this.id;
    switch (queryType) {
      case 'pageview':
        field = this.pageviewQueryEnum || field;
        break;
      case 'event':
        field = this.eventQueryEnum || field;
        break;
      case 'combined':
        field = this.combinedQueryEnum || field;
        break;
    }

    return underscore(field).toUpperCase();
  }

  getField(queryType: QueryType) {
    let field = this.queryName || camelize(this.id);
    switch (queryType) {
      case 'pageview':
        field = this.pageviewQueryName || field;
        break;
      case 'event':
        field = this.eventQueryName || field;
        break;
      case 'combined':
        field = this.combinedQueryName || field;
        break;
    }

    return field;
  }

  getStatement(queryType: QueryType) {
    if (this.columnName === this.getField(queryType)) {
      return this.columnName;
    }

    return `${this.columnName}: ${this.getField(queryType)}`;
  }

  serialize() {
    return JSON.stringify({ id: this.id, type: this.type });
  }

  format(value: any) {
    return (
      this.translateBasePropertyValue?.(value) ||
      (value === undefined && this.columnType === 'number' ? 0 : value)
    );
  }

  formatForCSV(value: any) {
    return this.format(value);
  }

  formatBasePropertyValue(value: any) {
    return this.format(value);
  }

  sorter(a: any, b: any) {
    const valueA = a[this.columnName];
    const valueB = b[this.columnName];

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return valueA.localeCompare(valueB);
    } else {
      return (valueA || 0) - (valueB || 0);
    }
  }

  serializeValue(value: any) {
    return value;
  }

  isCompatibleWithChartType(chartType?: ChartType) {
    if (chartType === 'pie') {
      // Only allow counts in pie charts, so that the sum of the values makes sense:
      return ['distinct-count', 'count', 'count-occurrences'].includes(
        this.type
      );
    }

    if (chartType !== 'table') {
      return this.type !== 'collect';
    }

    return true;
  }

  // Make sure the lines/bars within a graph measure the same thing:
  isCompatibleWithPreviouslySelectedProperties(
    selectedProperties: BaseProperty[]
  ) {
    const prevSelectedProperty = selectedProperties[0];

    if (!prevSelectedProperty) return true;

    // If one of the properties is a rating, the other one must be a rating too:
    // (it wouldn't make sense to compare numbers of 1-5 to other huge ones)
    if (isRatingType(prevSelectedProperty)) {
      return isRatingType(this);
    }

    if (isCountType(prevSelectedProperty)) {
      return isCountType(this);
    }

    if (isDurationType(prevSelectedProperty)) {
      return isDurationType(this);
    }

    // Make sure secondary property is of same type as primary:
    return this.type === prevSelectedProperty.type;
  }

  isCompatibleWithBaseProperty(property: BaseProperty) {
    return (
      this.isCompatibleWithAdditionalBaseProperty(property) &&
      !(this.onlyCompatibleWithParent && this.parentProperty !== property)
    );
  }

  isCompatibleWithAdditionalBaseProperty(property: BaseProperty) {
    return (
      property.queryTypes.some((queryType) => {
        if (this.queryTypes.includes(queryType)) {
          if (queryType === 'event') {
            if (
              property.eventTypes.length === 0 ||
              this.eventTypes.length === 0
            ) {
              return true;
            }

            return property.eventTypes.some((eventType) =>
              this.eventTypes.includes(eventType)
            );
          }

          return true;
        }

        return false;
      }) && !this.incompatibleBaseProperties.includes(property.columnType || '')
    );
  }

  async deserializeValue(value: any) {
    return new Promise((resolve) => resolve(value));
  }
}
