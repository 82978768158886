import Modifier from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';

function cleanup(instance: HashChangeModifier) {
  const { listener } = instance;

  if (listener !== undefined) {
    window.removeEventListener('hashchange', listener);
  }
}

type OnChange = (hash: string) => void;

interface ModifierSignature {
  Element: Element;
  Args: {
    Positional: [OnChange];
  };
}

export default class HashChangeModifier extends Modifier<ModifierSignature> {
  declare listener: void | undefined;

  modify(_: Element, [onChange]: [OnChange]) {
    if (this.listener !== undefined) {
      window.removeEventListener('hashchange', this.listener);
      this.listener = undefined;
    }

    this.listener = window.addEventListener('hashchange', () => {
      onChange(window.location.hash.slice(1));
    });

    registerDestructor(this, cleanup);
  }
}
