import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import Store from '@ember-data/store';
import SettingsLocationNewController from 'teamtailor/controllers/settings/locations/new';

export default class SettingsLocationNewRoute extends ScrollToTopRoute {
  @service declare current: Current;
  @service declare store: Store;

  async beforeModel() {
    return get(this.current.company, 'careerSites');
  }

  model() {
    const { company } = this.current;
    const hasCareerSiteLanguages = company.hasFeature('career_site_languages');

    return this.store.createRecord('location', {
      languageCode: hasCareerSiteLanguages
        ? get(company.defaultCareerSite, 'languageCode')
        : null,
    });
  }

  resetController(
    controller: SettingsLocationNewController,
    isExiting: boolean
  ) {
    if (isExiting && get(controller.model, 'isNew')) {
      if (this.current.company.hasFeature('career_site_languages')) {
        get(controller.model, 'translations').invoke('unloadRecord');
      }

      controller.model.unloadRecord();
    }
  }
}
