import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import { get } from 'teamtailor/utils/get';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    averageRating: { serialize: false },
    color: { serialize: false },
    consentFutureJobsAt: { serialize: false },
    consentMissingAt: { serialize: false },
    consentRequestedAt: { serialize: false },
    downloadOriginalResumeUrl: { serialize: false },
    downloadResumeUrl: { serialize: false },
    externalRecruitmentId: { serialize: false },
    hiredCount: { serialize: false },
    inboxCount: { serialize: false },
    initials: { serialize: false },
    inProcessCount: { serialize: false },
    isFileInfected: { serialize: false },
    isNumberInShortcodeCountry: { serialize: false },
    jobApplicationWillBeDeletedAt: { serialize: false },
    lastActivityAt: { serialize: false },
    linkedinProfile: { serialize: false },
    reviewCount: { serialize: false },
    metaText: { serialize: false },
    originalResumeFileName: { serialize: false },
    pdfOrOriginalResumeFileName: { serialize: false },
    pickedLocations: {
      serialize: 'records',
      key: 'picked_locations_attributes',
    },

    picture: { serialize: false },
    pusherChannels: { serialize: false },
    pusherChatPresence: { serialize: false },
    referralsCount: { serialize: false },
    referringUrl: { serialize: false },
    rejectedCount: { serialize: false },
    socialImageUrl: { serialize: false },
    socketUpdated: { serialize: false },
    tags: { serialize: false },
    willBeDeletedAt: { serialize: false },
  },

  serialize(snapshot) {
    const json = { ...this._super(...arguments) };

    if (get(snapshot.record, 'isNew')) {
      return Object.assign(json, {
        tag_list: get(snapshot.record, 'tags').mapBy('name'),
      });
    }

    return json;
  },

  normalize(modelClass, resourceHash) {
    resourceHash.resume = '';
    return this._super(modelClass, resourceHash);
  },
});
