import Model, {
  belongsTo,
  attr,
  hasMany,
  SyncHasMany,
} from '@ember-data/model';
import JobModel from 'teamtailor/models/job';
import QuestionModel from 'teamtailor/models/question';

export default class QuestionDetailModel extends Model {
  @belongsTo('question', { async: false }) declare question: QuestionModel;
  @attr('number', { defaultValue: 0 }) declare answersCount: number;
  @hasMany('job', { async: false })
  declare triggerJobs: SyncHasMany<JobModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'question-detail': QuestionDetailModel;
  }
}
