import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"bg-neutral px-8 py-16\">\n  <FormLabel @label=\"Additional feedback\" />\n  <p class=\"m-0 whitespace-normal\">\n    {{this.row.npsResponseFeedback}}\n  </p>\n  {{#unless this.row.deleted}}\n    <p class=\"m-0 mt-10\">\n      <LinkTo\n        @route=\"analytics.overview.nps-overview.candidate\"\n        @model={{this.row.candidate.id}}\n        @query={{hash tab=\"message-feed\"}}\n      >{{t \"common.reply\"}}</LinkTo>\n    </p>\n  {{/unless}}\n</div>", {"contents":"<div class=\"bg-neutral px-8 py-16\">\n  <FormLabel @label=\"Additional feedback\" />\n  <p class=\"m-0 whitespace-normal\">\n    {{this.row.npsResponseFeedback}}\n  </p>\n  {{#unless this.row.deleted}}\n    <p class=\"m-0 mt-10\">\n      <LinkTo\n        @route=\"analytics.overview.nps-overview.candidate\"\n        @model={{this.row.candidate.id}}\n        @query={{hash tab=\"message-feed\"}}\n      >{{t \"common.reply\"}}</LinkTo>\n    </p>\n  {{/unless}}\n</div>","moduleName":"teamtailor/components/report/nps-description.hbs","parseOptions":{"srcName":"teamtailor/components/report/nps-description.hbs"}});
import { alias } from '@ember/object/computed';
import Component from '@ember/component';

export default Component.extend({
  tagName: '',
  classNames: ['py-16 px-8'],

  row: null,
  feedback: alias('row.feedback'),
});
