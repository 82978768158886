import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"root\" class=\"flex flex-col items-center justify-center px-64\">\n  <div local-class=\"emoji\">\n    <TtEmoji @emoji={{this.emoji}} />\n  </div>\n  <p class=\"mb-4 text-center font-semibold\">\n    {{t\n      (concat this.copyKey \".title\")\n      search=(html-safe (concat \"<strong>\" @searchString \"</strong>\"))\n      htmlSafe=true\n    }}\n  </p>\n  <p class=\"body-text-s text-center text-zinc-600\">\n    {{t (concat this.copyKey \".subtitle\")}}\n  </p>\n  {{#if (and @hasSearched @onClearSearch)}}\n    <Button @text={{t \"common.clear\"}} @onClick={{@onClearSearch}} />\n  {{/if}}\n</div>", {"contents":"<div local-class=\"root\" class=\"flex flex-col items-center justify-center px-64\">\n  <div local-class=\"emoji\">\n    <TtEmoji @emoji={{this.emoji}} />\n  </div>\n  <p class=\"mb-4 text-center font-semibold\">\n    {{t\n      (concat this.copyKey \".title\")\n      search=(html-safe (concat \"<strong>\" @searchString \"</strong>\"))\n      htmlSafe=true\n    }}\n  </p>\n  <p class=\"body-text-s text-center text-zinc-600\">\n    {{t (concat this.copyKey \".subtitle\")}}\n  </p>\n  {{#if (and @hasSearched @onClearSearch)}}\n    <Button @text={{t \"common.clear\"}} @onClick={{@onClearSearch}} />\n  {{/if}}\n</div>","moduleName":"teamtailor/components/toolbox/empty-result.hbs","parseOptions":{"srcName":"teamtailor/components/toolbox/empty-result.hbs"}});
import Component from '@glimmer/component';

const emojis = {
  all: '🐳',
  archived: '🚀',
  search: '👀',
  unanswered: '👏',
  unread: '🎉',
  todos: '🧘',
  meetings: '🥝',
};
export default class extends Component {
  get copyKey() {
    const translationPath = `components.toolbox.${this.args.toolbox}.empty_state.`;
    const key = this.args.hasSearched ? 'search' : this.args.filter;
    return translationPath + key;
  }

  get emoji() {
    if (this.args.hasSearched) {
      return emojis.search;
    }

    return emojis[this.args.filter];
  }
}
