import Route from '@ember/routing/route';
import { buildReport as buildEmployeeReferralsReport } from 'teamtailor/classes/analytics/report-employee-referrals';
import { getOwner } from '@ember/application';

export default class ReferralsRoute extends Route {
  model() {
    return {
      referrals: buildEmployeeReferralsReport({
        container: getOwner(this),
      }).buildTask,
    };
  }
}
