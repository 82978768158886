import Service, { inject as service } from '@ember/service';

import {
  IMeeting,
  IUser,
  IJobApplication,
  IGraphqlConnectionRecord,
} from 'teamtailor/components/meeting/types';

import MEETING_QUERY from 'teamtailor/gql/meeting-query.graphql';
import RouterService from '@ember/routing/router-service';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import { JobClass } from 'teamtailor/classes/job';
import { MeetingEventListClass } from 'teamtailor/classes/meetings';
import { MeetingRoomAttendeeClass } from 'teamtailor/classes/meetings/meeting-room-attendee';
import GET_JOB_APPLICATIONS from 'teamtailor/gql/job-applications-query.graphql';
import ErrorClass from 'teamtailor/classes/error-class';
import PreparedMessageClass from 'teamtailor/classes/prepared-message';
import GET_USER from 'teamtailor/gql/get-user.graphql';

export type Response = {
  user: IUser;
  preparedMessage?: PreparedMessageClass;
  meetingEvents: MeetingEventListClass;
  jobClass: JobClass;
  meetingId: string;
  errors: ErrorClass;
  meetingRooms: MeetingRoomAttendeeClass[];
  jobApplications: IJobApplication[];
};

export default class MeetingLoaderEditGraphql extends Service {
  @service declare apollo: TeamtailorApolloService;
  @service declare router: RouterService;

  async loadData(meetingId: string): Promise<Response> {
    const meeting = await this.fetchMeeting(meetingId);

    const preparedMessage =
      meeting.preparedMessage &&
      PreparedMessageClass.from(meeting.preparedMessage);

    const meetingEvents = MeetingEventListClass.from(
      meeting.meetingEvents,
      preparedMessage
    );

    let jobApplications: IJobApplication[] = [];
    const firstCandidate = this.firstCandidate(meetingEvents);
    if (firstCandidate) {
      jobApplications = await this.fetchJobApplication(firstCandidate.id);
    }

    const user = await this.fetchUser();

    return {
      user,
      meetingEvents,
      preparedMessage,
      jobClass: JobClass.from(meeting.job!),
      meetingId,
      errors: new ErrorClass(),
      meetingRooms: user.company.meetingRooms.map((m) =>
        MeetingRoomAttendeeClass.from(m)
      ),

      jobApplications,
    };
  }

  async fetchMeeting(meetingId: string): Promise<IMeeting> {
    const { meeting }: { meeting: IMeeting } = await this.apollo.query({
      query: MEETING_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        meetingId,
      },
    });

    return meeting;
  }

  async fetchJobApplication(candidateId: string): Promise<IJobApplication[]> {
    const {
      jobApplicationsConnection,
    }: {
      jobApplicationsConnection: IGraphqlConnectionRecord<IJobApplication>;
    } = await this.apollo.query({
      query: GET_JOB_APPLICATIONS,
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          candidateId,
        },
      },
    });

    return jobApplicationsConnection.edges.map((edge) => edge.node);
  }

  async fetchUser(): Promise<IUser> {
    const { user }: { user: IUser } = await this.apollo.query({
      query: GET_USER,
      fetchPolicy: 'cache-first',
    });

    return user;
  }

  firstCandidate(meetingEvents: MeetingEventListClass) {
    return meetingEvents.meetingEvents.firstObject?.meetingEventAttendees
      .candidates.firstObject;
  }
}

declare module '@ember/service' {
  interface Registry {
    'meeting-loader-edit-graphql': MeetingLoaderEditGraphql;
  }
}
