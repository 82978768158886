import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Icon\n  @icon={{this.properties.icon}}\n  @style={{this.properties.style}}\n  @size={{or @size this.properties.size}}\n/>", {"contents":"<Icon\n  @icon={{this.properties.icon}}\n  @style={{this.properties.style}}\n  @size={{or @size this.properties.size}}\n/>","moduleName":"teamtailor/components/content-page-type-icon.hbs","parseOptions":{"srcName":"teamtailor/components/content-page-type-icon.hbs"}});
import Component from '@glimmer/component';
import {
  ALLOWED_TYPES,
  PAGE_TYPE_MAP,
  DEFAULT_PAGE_TYPE,
  PageIconType,
} from 'teamtailor/constants/pages';

interface ContentPageTypeIconArgs {
  type: string;
}

type AllowedTypes = (typeof ALLOWED_TYPES)[number];

export default class ContentPageTypeIcon extends Component<ContentPageTypeIconArgs> {
  get type(): AllowedTypes {
    return this.args.type;
  }

  get properties(): PageIconType {
    return PAGE_TYPE_MAP[this.type] || DEFAULT_PAGE_TYPE;
  }
}
