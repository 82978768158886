import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { EmptyObj } from 'teamtailor/utils/type-utils';

type PostEventMessage =
  | 'video-has-joined'
  | 'video-has-maximized'
  | 'video-has-minimized'
  | 'video-has-ended';

export default class VideoMeetingService extends Service {
  @tracked isChatActive = false;
  @tracked isFullScreen = false;
  @tracked isJoined = false;
  @tracked isJoining = false;
  @tracked meetingUrl: string | null = null;
  @tracked newChatMessagesAmount = 0;

  constructor(owner?: object) {
    super(owner);
    window.addEventListener('message', this.handleMessage, false);
  }

  willDestroy() {
    window.removeEventListener('message', this.handleMessage);
  }

  get isActiveFullScreen() {
    return (this.isJoining || this.isJoined) && this.isFullScreen;
  }

  get isActiveSideBar() {
    return (this.isJoining || this.isJoined) && !this.isFullScreen;
  }

  @action
  closeJoinMeetingPage() {
    this.isJoining = false;
    this.meetingUrl = null;
  }

  joinMeeting() {
    this.isJoining = false;
    this.isJoined = true;
    this.isFullScreen = true;
  }

  closeMeeting() {
    this.isJoined = false;
    this.meetingUrl = null;
    this.toggleIntercomPosition(true);
    this.toggleHtmlStyles(true);
  }

  maximize() {
    this.isFullScreen = true;
    this.toggleIntercomPosition(this.isFullScreen);
    this.toggleHtmlStyles(this.isFullScreen);
  }

  @action
  minimize() {
    this.isFullScreen = false;
    this.postMessage('app-video-has-minimized');
    this.toggleIntercomPosition(this.isFullScreen);
    this.toggleHtmlStyles(this.isFullScreen);
  }

  postMessage(message: unknown) {
    const iframeWindow = document.getElementById(
      'video-meeting-iframe'
    ) as HTMLIFrameElement | null;

    if (!iframeWindow) return;

    iframeWindow.contentWindow?.postMessage(message, '*');
  }

  toggleIntercomPosition(isFullScreen: boolean) {
    const intercomElement = document.querySelector('.intercom-lightweight-app');
    const sidebarClass = 'intercom-lightweight-app--with-video-meeting-sidebar';
    if (!intercomElement) return;

    if (isFullScreen) {
      intercomElement.classList.remove(sidebarClass);
    } else {
      intercomElement.classList.add(sidebarClass);
    }
  }

  toggleHtmlStyles(isFullScreen: boolean) {
    const html = document.querySelector('html');
    if (!html) return;
    html.style.overflow = isFullScreen ? '' : 'hidden';
  }

  handleMessage = ({
    type,
    data,
  }: {
    type: 'message' | (string & EmptyObj);
    data: PostEventMessage;
  }) => {
    if (type !== 'message') return;

    switch (data) {
      case 'video-has-joined':
        this.joinMeeting();
        break;
      case 'video-has-maximized':
        this.maximize();
        break;
      case 'video-has-minimized':
        this.minimize();
        break;
      case 'video-has-ended':
        this.closeMeeting();
        break;
    }
  };

  @action
  toggleChat(): void {
    this.isChatActive = !this.isChatActive;

    if (this.isChatActive) this.newChatMessagesAmount = 0;
  }

  @action
  updateNewChatMessagesAmount(): void {
    if (!this.isChatActive) {
      this.newChatMessagesAmount++;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'video-meeting': VideoMeetingService;
  }
}
