import Editor from './editor';

export interface Translations {
  [key: string]: string;
}

export interface Action {
  id: string;
  icon?: string;
  actions?: Action[];
  onClick?: () => void;
}

export default class Menu {
  editor: Editor;
  translations: Translations;

  constructor(editor: Editor, translations: Translations) {
    this.editor = editor;
    this.translations = translations;
  }
}
