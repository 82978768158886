type ScorecardScoreType = {
  label: number;
  value: number;
  color: string;
};

export const SCORE_VALUE_ADJUSTMENT = 3;

export const SCORECARD_SCORES: ScorecardScoreType[] = [
  { label: 1, value: -2, color: 'red-600' },
  { label: 2, value: -1, color: 'red-400' },
  { label: 3, value: 0, color: 'amber-400' },
  { label: 4, value: 1, color: 'lime-400' },
  { label: 5, value: 2, color: 'lime-600' },
];
