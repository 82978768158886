import EmberTooltipComponent from 'ember-tooltips/components/ember-tooltip';

let popperOptions = {
  modifiers: {
    preventOverflow: {
      escapeWithReference: false,
    },
  },
};

export default EmberTooltipComponent.extend({
  delay: 200,
  popperOptions,

  get popperContainer() {
    return document.querySelector('dialog[open]#notifications-modal') || 'body';
  },
});
