import MeetingShowRoute from 'teamtailor/routes/meetings/events/show';

export default class JobsJobActivityCandidateMeetingShowRoute extends MeetingShowRoute {
  controllerName = 'meetings.events.show';
  templateName = 'meetings.events.show';

  meetingRouteRouteName = 'jobs.job.activity.candidate.meetings';
  candidateRouteRouteName = 'jobs.job.activity.candidate.index';
  jobRouteRouteName = 'jobs.job';
}
