import Controller from '@ember/controller';
import { action, get, setProperties } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class SettingsUserTemplateController extends Controller {
  @tracked userTemplate = this.model.firstObject;
  @tracked defaultNotificationConfigs =
    this.userTemplate.defaultNotificationConfigs;

  @tracked activeTab = 'profileSettings';

  get disableSave() {
    return (
      !this.userTemplate.hasDirtyAttributes &&
      this.defaultNotificationConfigs.filterBy('hasDirtyAttributes').length ===
        0
    );
  }

  @action
  handleTabClick(tab) {
    this.activeTab = tab;
  }

  @action
  restoreDefaults() {
    this.defaultNotificationConfigs.forEach((defaultNotificationConfig) => {
      const { notificationConfig } = defaultNotificationConfig;
      setProperties(defaultNotificationConfig, {
        email: get(notificationConfig, 'defaultEnabledEmail'),
        browser: get(notificationConfig, 'defaultEnabledBrowser'),
        mobile: get(notificationConfig, 'defaultEnabledMobile'),
      });
    });
  }

  @action
  save() {
    const promises = [];

    if (this.userTemplate.hasDirtyAttributes) {
      promises.push(this.userTemplate.save());
    }

    this.defaultNotificationConfigs.forEach((defaultNotificationConfig) => {
      if (defaultNotificationConfig.hasDirtyAttributes) {
        promises.push(defaultNotificationConfig.save());
      }
    });

    return Promise.all(promises);
  }
}
