import BaseProperty from '../properties/base-property';

export default class BaseFilter {
  name: string;

  constructor(name: string) {
    this.name = name;
  }

  serializeValue(property: BaseProperty, value: any): string {
    return property.serializeValue(value);
  }

  deserializeValue(property: BaseProperty, value: string): any {
    return property.deserializeValue(value);
  }
}
