import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { NotificationConfigModel, UserTemplateModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';

export default class DefaultNotificationConfigModel extends Model {
  @belongsTo('user-template')
  declare userTemplate: AsyncBelongsTo<UserTemplateModel>;

  @belongsTo('notification-config')
  declare notificationConfig: AsyncBelongsTo<NotificationConfigModel>;

  @attr('boolean') declare defaultEnabledBrowser: boolean;
  @attr('boolean') declare defaultEnabledEmail: boolean;
  @attr('boolean') declare defaultEnabledMobile: boolean;

  get notificationConfigCategory() {
    return get(this.notificationConfig, 'category');
  }

  get sortColumn() {
    return get(this.notificationConfig, 'key');
  }

  get browser() {
    return this.defaultEnabledBrowser;
  }

  set browser(value) {
    this.defaultEnabledBrowser = value;
  }

  get email() {
    return this.defaultEnabledEmail;
  }

  set email(value) {
    this.defaultEnabledEmail = value;
  }

  get mobile() {
    return this.defaultEnabledMobile;
  }

  set mobile(value) {
    this.defaultEnabledMobile = value;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'default-notification-config': DefaultNotificationConfigModel;
  }
}
