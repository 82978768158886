import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Widgets::RejectReasons\n  @rows={{this.rejectReasons}}\n  @onShowExportModal={{this.onShowExportModal}}\n  @loading={{or (not this.fetchData.lastSuccessful) this.fetchData.isRunning}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>", {"contents":"<Insights::Widgets::RejectReasons\n  @rows={{this.rejectReasons}}\n  @onShowExportModal={{this.onShowExportModal}}\n  @loading={{or (not this.fetchData.lastSuccessful) this.fetchData.isRunning}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>","moduleName":"teamtailor/components/insights/widgets/job-reject-reasons.hbs","parseOptions":{"srcName":"teamtailor/components/insights/widgets/job-reject-reasons.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { gql } from '@apollo/client/core';

const REJECT_REASONS_QUERY = gql`
  query JobRejectReasonsQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
  ) {
    job: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [REJECTED]
    ) {
      rejectReasons: aggregated(
        groupBy: [REJECT_REASON_REASON, REJECT_REASON_REJECTED_BY_COMPANY]
      ) {
        name: rejectReasonReason
        rejectedByCompany: rejectReasonRejectedByCompany
        numberOfRejects: count
      }
    }
  }
`;

const EXPORT_TABLE_COLUMNS = [
  { type: 'text', propertyName: 'name', headerKey: 'common.name' },
  {
    type: 'number',
    propertyName: 'numberOfRejects',
    headerKey: 'insights.common.count',
  },
  {
    type: 'text',
    propertyName: 'rejectedByCompany',
    headerKey: 'insights.molecules.reject_reasons.rejected_by_company',
  },
];

export default class InsightsWidgetsJobRejectReasons extends Component {
  @service insights;
  @service insightsExport;
  @service intl;
  @service store;

  get rejectReasons() {
    return this.fetchData.lastSuccessful?.value?.rejectReasons || [];
  }

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      'insights.common.reject_reasons',
      `job-${this.args.job.id}`,
      EXPORT_TABLE_COLUMNS,
      this.retrieveExportData,
      this.args.dateRange,
      { sortKey: 'numberOfRejects', createdAt: this.args.job.createdAt }
    );
  }

  @action
  retrieveExportData(dateRange) {
    return this.queryForData(dateRange).then((data) =>
      data.job.rejectReasons.map((reason) => {
        return {
          ...reason,
          rejectedByCompany: reason.rejectedByCompany
            ? this.intl.t('common.yes')
            : this.intl.t('common.no'),
        };
      })
    );
  }

  @action
  queryForData(dateRange) {
    const { job } = this.args;

    return this.insights.query({
      query: REJECT_REASONS_QUERY,
      variables: {
        jobIds: [job.id],
        dateRange: dateRange.asObject,
      },

      context: { jobId: job.id },
    });
  }

  fetchData = dropTask(async () => {
    const {
      job: { rejectReasons },
    } = await this.queryForData(this.args.dateRange);

    return { rejectReasons };
  });
}
