import { computed, get } from '@ember/object';

export const booleanTransform = (value) => value.toString() === 'true';

export const computedLocalStorage = function (
  transformFunction,
  globalKey = null,
  defaultValue = null
) {
  if (!transformFunction) {
    transformFunction = (value) => value;
  }

  function getKey(globalKey, valueKey) {
    return globalKey
      ? this && globalKey in this
        ? get(this, globalKey)
        : globalKey
      : valueKey;
  }

  return computed({
    get(valueKey) {
      const key = getKey.call(this, globalKey, valueKey);

      try {
        return (
          transformFunction(window.localStorage.getItem(key)) || defaultValue
        );
      } catch (exception) {
        return defaultValue;
      }
    },

    set(valueKey, value) {
      const key = getKey.call(this, globalKey, valueKey);

      try {
        if (value) {
          window.localStorage.setItem(key, value);
        } else {
          window.localStorage.removeItem(key);
        }

        return transformFunction(value) ?? defaultValue;
      } catch (exception) {
        return defaultValue;
      }
    },
  });
};
