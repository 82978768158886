import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import { CloseReason } from 'teamtailor/components/trigger-modal';

export default class NewController extends Controller {
  @service declare router: RouterService;

  queryParams = ['onReject', 'type', 'stageId', 'partnerActivationId'];
  onReject = false;
  type = null;
  partnerActivationId = null;
  stageId?: string;

  @action
  handleClose(reason: CloseReason) {
    if (reason === 'saved') {
      this.router.transitionTo('jobs.job.stages.stage-triggers');
    } else {
      this.router.transitionTo('jobs.job.stages');
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'jobs.job.stages.index.new-trigger': NewController;
  }
}
