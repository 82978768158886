import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class JobsEditStagesQuickRejectSettingsEdit extends Route {
  @service declare store: Store;

  controllerName = 'jobs.edit.stages.quick-reject-settings.edit';
  templateName = 'jobs.edit.stages.quick-reject-settings.edit';

  model(params: { quick_reject_setting_id: string }) {
    return this.store.findRecord(
      'quick-reject-setting',
      params.quick_reject_setting_id
    );
  }
}
