import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get } from 'teamtailor/utils/get';
import { AddonFeature } from 'types/features';

export default class AuditLogRoute extends ScrollToTopRoute {
  requiredFeature: AddonFeature = 'audit_log';

  model() {
    return get(this.current.company, 'auditLog');
  }
}
