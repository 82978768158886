import Service, { inject as service } from '@ember/service';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

export default class TtAlertService extends Service {
  @service intl;

  get target() {
    return document.getElementById('ember-teamtailor-modal-on-modal') || 'body';
  }

  error(text) {
    Swal.fire(this.intl.t('tt_alert.title.error'), text, 'error');
  }

  success(text) {
    Swal.fire(this.intl.t('tt_alert.title.success'), text, 'success');
  }

  warning(text) {
    Swal.fire(this.intl.t('tt_alert.title.warning'), text, 'warning');
  }

  info(text) {
    Swal.fire(this.intl.t('tt_alert.title.info'), text, 'info');
  }

  question(text) {
    Swal.fire(this.intl.t('tt_alert.title.question'), text, 'question');
  }

  confirm(text, confirmCallback, cancelCallback, options) {
    options = options ? options : {};
    const { title, confirmButtonText, cancelButtonText, confirmButtonClass } =
      options;
    Swal.fire({
      title: title ? title : this.intl.t('common.are_you_sure'),
      text,
      type: 'warning',
      confirmButtonClass: confirmButtonClass
        ? confirmButtonClass
        : 'btn-danger',

      confirmButtonText: confirmButtonText
        ? confirmButtonText
        : this.intl.t('common.yes'),

      cancelButtonText: cancelButtonText
        ? cancelButtonText
        : this.intl.t('common.cancel'),

      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,
      target: this.target,
      customClass: {
        container: 'pointer-events-auto',
      },
    }).then((result) => {
      if (result.value) {
        confirmCallback();
      } else {
        if (cancelCallback) {
          cancelCallback();
        }
      }
    });
  }

  customConfirm({
    title,
    html,
    text,
    confirmButtonText,
    cancelButtonText,
    confirmCallback,
    cancelCallback,
    confirmButtonClass,
    showCancelButton = true,
    showCloseButton = true,
  }) {
    Swal.fire({
      title,
      html,
      text,
      confirmButtonText,
      cancelButtonText,
      confirmButtonClass,
      type: 'warning',
      showCancelButton,
      showCloseButton,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmCallback();
      } else {
        cancelCallback();
      }
    });
  }
}
