import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Modals::AddCandidateToNurtureCampaign\n  @isOpen={{true}}\n  @candidate={{@candidate}}\n  @onSelect={{this.addToNurtureCampaign}}\n  @onClose={{@onClose}}\n/>", {"contents":"<Modals::AddCandidateToNurtureCampaign\n  @isOpen={{true}}\n  @candidate={{@candidate}}\n  @onSelect={{this.addToNurtureCampaign}}\n  @onClose={{@onClose}}\n/>","moduleName":"teamtailor/components/candidate-modal-new/action-modals/nurture.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-modal-new/action-modals/nurture.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import {
  CandidateModel,
  JobApplicationModel,
  NurtureCampaignModel,
} from 'teamtailor/models';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';

interface Args {
  candidate: CandidateModel;
  jobApplication: JobApplicationModel | undefined;
  onClose: () => void;
}

export default class extends Component<Args> {
  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  get candidate() {
    return this.args.candidate;
  }

  @action
  addToNurtureCampaign(nurtureCampaign: NurtureCampaignModel) {
    const nurtureCampaignRecipient = this.store.createRecord(
      'nurture-campaign-recipient',
      {
        nurtureCampaign,
        candidate: this.candidate,
      }
    );

    return nurtureCampaignRecipient
      .save()
      .then(() => {
        this.args.onClose();

        this.flashMessages.success(
          this.intl.t(
            'candidates.candidate.candidate_modal.candidate_added_to_nurture_campaign'
          )
        );
      })
      .catch(() => {
        this.flashMessages.error(
          this.intl.t(
            'candidates.candidate.candidate_modal.could_not_add_candidate_to_nurture_campaign'
          )
        );
      });
  }
}
