export const STATUS_COLOR = {
  lead: 'lead',
  externally_sourced: 'sourced_external',
  sourced: 'sourced',
  referred: 'referred',
  internal: 'internal',
  data_requested: 'danger',
  removal_requested: 'danger',
  consent_expired: 'expired',
  consent_pending: 'inbox',
  consent_missing: 'missing',
  will_be_deleted: 'will_be_deleted',
  application_will_be_deleted: 'will_be_deleted',
  connected: 'connected',
  shared: 'shared',
  unsubscribed: 'unsubscribed',
};

export const STATUS_TEXT = {
  lead: 'candidates.candidate.candidates_table.row.lead',
  externally_sourced: 'candidate.sourced_external',
  sourced: 'candidate.sourced',
  referred: 'candidates.candidate.candidates_table.row.referred',
  internal: 'candidates.candidate.candidate_merger.candidate.internal',
  data_requested: 'candidates.candidate.candidates_table.row.data_requested',

  removal_requested:
    'candidates.candidate.candidates_table.row.removal_requested',

  consent_expired: 'candidates.candidate.candidates_table.row.expired',
  consent_pending:
    'candidates.candidate.candidates_table.row.permission_pending',

  consent_missing:
    'candidates.candidate.candidates_table.row.permission_missing',

  will_be_deleted: 'candidates.candidate.candidates_table.row.deleting_in',
  application_will_be_deleted:
    'candidates.candidate.candidates_table.row.deleting_application_in',

  connected: 'candidates.candidate.candidates_table.row.connected',
  shared: 'candidates.candidate.candidate_modal.status.shared',
  unsubscribed: 'candidates.candidate.candidate_modal.status.unsubscribed',
};
