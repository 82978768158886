import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span\n  class={{class-names\n    \"inline-flex items-center justify-center\"\n    this.sizeClasses\n  }}\n  ...attributes\n>\n  {{#if (or (eq @icon true))}}\n    {{! This is if you need an element the same size as if there was an icon here }}\n  {{else if (eq this.style \"kit\")}}\n    {{inline-svg (concat \"icons/\" @icon \".svg\")}}\n  {{else if (has-key @icon \"html\")}}\n    {{@icon.html}}\n  {{else if (this.iconIsLoaded @icon)}}\n    {{! template-lint-disable no-fa-icon }}\n    <FaIcon\n      @icon={{@icon}}\n      @prefix={{this.prefix}}\n      @spin={{@spin}}\n      @transform={{@transform}}\n      @flip={{@flip}}\n      @fixedWidth={{true}}\n    />\n  {{/if}}\n\n  {{#if @tooltip}}\n    <EmberTooltip @text={{@tooltip}} @side={{or @tooltipSide \"top\"}} />\n  {{/if}}\n</span>", {"contents":"<span\n  class={{class-names\n    \"inline-flex items-center justify-center\"\n    this.sizeClasses\n  }}\n  ...attributes\n>\n  {{#if (or (eq @icon true))}}\n    {{! This is if you need an element the same size as if there was an icon here }}\n  {{else if (eq this.style \"kit\")}}\n    {{inline-svg (concat \"icons/\" @icon \".svg\")}}\n  {{else if (has-key @icon \"html\")}}\n    {{@icon.html}}\n  {{else if (this.iconIsLoaded @icon)}}\n    {{! template-lint-disable no-fa-icon }}\n    <FaIcon\n      @icon={{@icon}}\n      @prefix={{this.prefix}}\n      @spin={{@spin}}\n      @transform={{@transform}}\n      @flip={{@flip}}\n      @fixedWidth={{true}}\n    />\n  {{/if}}\n\n  {{#if @tooltip}}\n    <EmberTooltip @text={{@tooltip}} @side={{or @tooltipSide \"top\"}} />\n  {{/if}}\n</span>","moduleName":"teamtailor/components/icon.hbs","parseOptions":{"srcName":"teamtailor/components/icon.hbs"}});
import { helper } from '@ember/component/helper';
import {
  IconName,
  IconPrefix,
  findIconDefinition,
} from '@fortawesome/fontawesome-svg-core';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
  AllowedSizes,
  ALLOWED_SIZES,
  ALLOWED_STYLES,
  DEFAULT_SIZE,
  DEFAULT_STYLE,
  SIZE_CLASSES,
} from 'teamtailor/constants/icon';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import { inject as service } from '@ember/service';
import EsmFaIconsService from 'teamtailor/services/esm-fa-icons';

export const STYLE_PREFIX_MAP = {
  solid: 'fas',
  regular: 'far',
  brands: 'fab',
  kit: 'fak',
  light: 'fal',
  duotone: 'fad',
} as const;

type AllowedStyles = (typeof ALLOWED_STYLES)[number];

export type Args = {
  icon?: IconName;
  size?: AllowedSizes;
  spin?: boolean;
  style?: AllowedStyles;
  prefix?: IconPrefix;
  transform?: string;
};

export default class IconComponent extends Component<Args> {
  @service declare esmFaIcons: EsmFaIconsService;

  @tracked inLibrary = true;

  // will try to load icon if its not loaded
  iconIsLoaded = helper(() => {
    if (
      this.prefix !== 'fas' || // only load solid icons for now
      (this.args.icon && typeof this.args.icon !== 'string')
    ) {
      return true;
    }

    const iconDefinition = findIconDefinition({
      prefix: this.prefix,
      iconName: this.args.icon!,
    });

    if (!iconDefinition) {
      this.inLibrary = false;

      this.esmFaIcons.loadIcon(this.prefix, this.args.icon!).then(() => {
        this.inLibrary = true;
      });
    }

    return this.inLibrary;
  });

  get sizeClasses() {
    return SIZE_CLASSES[this.size];
  }

  get prefix() {
    return this.args.prefix ?? STYLE_PREFIX_MAP[this.style];
  }

  get size(): AllowedSizes {
    verifyArg(this.args.size, ALLOWED_SIZES, 'Icon @size', DEFAULT_SIZE);

    return this.args.size ?? DEFAULT_SIZE;
  }

  get style(): AllowedStyles {
    verifyArg(this.args.style, ALLOWED_STYLES, 'Icon @style', DEFAULT_STYLE);

    return this.args.style ?? DEFAULT_STYLE;
  }
}
