import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<a href={{this.mailHref}} {{on \"click\" this.click}}>\n  {{yield}}\n</a>", {"contents":"<a href={{this.mailHref}} {{on \"click\" this.click}}>\n  {{yield}}\n</a>","moduleName":"teamtailor/components/support-link.hbs","parseOptions":{"srcName":"teamtailor/components/support-link.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntercomService from 'teamtailor/services/intercom';
import { action } from '@ember/object';

export interface SupportLinkComponentSignature {
  Args: {
    subject?: string;
    message?: string;
  };
}

export default class SupportLinkComponent extends Component<SupportLinkComponentSignature> {
  @service
  declare intercom: IntercomService;

  get mailHref() {
    return `mailto:support@teamtailor.com?subject=${this.args.subject}`;
  }

  @action
  click(event: MouseEvent) {
    const message = this.args.message ? `${this.args.message}\n\n` : null;
    this.intercom.showNewMessage(message);
    event.preventDefault();
  }
}
