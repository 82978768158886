import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';

export default class SettingsPartnerHubRoute extends ScrollToTopRoute {
  @service store;

  model() {
    return this.store.findAll('sandbox-activation', { reload: true });
  }
}
