import BaseProperty from './properties/base-property';
import { tracked } from '@glimmer/tracking';
import SelectedFilter from './filters/selected-filter';
import { JSONFilter } from 'teamtailor/components/fancy-filters/candidate-filters-json';
import { ReportFilterOption } from './filters';

export default class FilterSettings {
  properties: BaseProperty[];
  translatedFilters: ReportFilterOption[];
  @tracked filters: SelectedFilter[] = [];

  constructor(
    properties: BaseProperty[],
    translatedFilters: ReportFilterOption[]
  ) {
    this.properties = properties;
    this.translatedFilters = translatedFilters;
  }

  deserialize(data: any) {
    this.filters = data.filters?.map((filterObject: JSONFilter) => {
      const filter = this.translatedFilters.find(
        (filter) => filter.name === Object.keys(filterObject)[0]
      );
      const activeFilter = new SelectedFilter(filter!);
      activeFilter.parse(filterObject);
      return activeFilter;
    });
  }

  serialize() {
    return {
      filters: this.filters.map((filter) => filter.asJSON()),
    };
  }
}
