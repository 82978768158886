import ApplicationInstance from '@ember/application/instance';
import ApolloService from 'ember-apollo-client/services/apollo';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';

import CurrentService from 'teamtailor/services/current';
import AnalyticsService from 'teamtailor/services/analytics';
import { gql } from '@apollo/client/core';
import moment from 'moment-timezone';
import { MeetingStatsTypeResponse } from 'teamtailor/utils/insights/graphql-response-types';

const MEETING_STATS_QUERY = gql`
  query (
    $userId: ID!
    $jobIds: [ID!]
    $startDate: String
    $endDate: String
    $companyIds: [ID!]
  ) {
    meetingStats(
      jobIds: $jobIds
      userScope: { userId: $userId }
      from: $startDate
      to: $endDate
      groupCompanyIds: $companyIds
    ) {
      numberOfScheduledMeetings
      numberOfCandidatesInvited
      totalMeetingTime
      averageMeetingTime
      averageNumberOfParticipants
      meetingsPerInterval {
        year
        month
        day
        count
      }
      durationPerInterval {
        year
        month
        day
        count
      }
      intervalType
    }
  }
`;

export default class MeetingStatsReport {
  @service declare apollo: ApolloService;
  @service declare analytics: AnalyticsService;
  @service declare current: CurrentService;

  container: ApplicationInstance;

  constructor({ container }: { container: ApplicationInstance }) {
    this.container = container;
  }

  fetch = task(async (): Promise<MeetingStatsTypeResponse> => {
    const { meetingStats }: { meetingStats: MeetingStatsTypeResponse } =
      await this.apollo.query({
        query: MEETING_STATS_QUERY,
        variables: {
          jobIds: this.analytics.selectedJobIds,
          userId: this.current.user.id,
          startDate: moment(this.analytics.startDate).format('YYYY-MM-DD'),
          endDate: moment(this.analytics.endDate).format('YYYY-MM-DD'),
          companyIds: this.analytics.availableCompanyIds,
        },
      });

    return meetingStats;
  });

  fetchTask = trackedTask(this, this.fetch);
}
