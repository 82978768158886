import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import { CompanyModel } from 'teamtailor/models';

export default class MeetingRoomModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @attr('boolean') declare failed: boolean;
  @attr('boolean', { defaultValue: false })
  declare hasUpcomingCalendarEvents: boolean;

  @attr('boolean', { defaultValue: true }) declare bookable: boolean;

  @attr('string') declare name: string;
  @attr('string') declare resourceEmail: string;
  @attr('string') declare resourceId: string;
  @attr('string') declare resourceName: string;

  @tracked waitingForResourceId = false;

  get displayName() {
    return this.name || this.resourceName || this.resourceEmail;
  }

  waitForResourceIdTask = task(async () => {
    this.waitingForResourceId = true;
    await timeout(5000);
    if (this.waitForResourceIdTask.performCount < 4 && !this.resourceId) {
      this.reload();
      this.waitForResourceIdTask.perform();
    } else {
      this.waitingForResourceId = false;
    }
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'meeting-room': MeetingRoomModel;
  }
}
