import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { delayOptions } from 'teamtailor/constants/triggers';
import JobModel from 'teamtailor/models/job';
import StageModel from 'teamtailor/models/stage';
import { get } from 'teamtailor/utils/get';
import { triggerLabel } from 'teamtailor/utils/trigger-text';
import TriggerAskForFeedbackModel from './trigger/ask-for-feedback';
import TriggerHireQualitySurveyModel from './trigger/hire-quality-survey';
import TriggerMessageModel from './trigger/message';
import TriggerSurveyModel from './trigger/survey';
import TriggerNoteModel from './trigger/note';
import TriggerNpsModel from './trigger/nps';
import TriggerNurtureModel from './trigger/nurture';
import TriggerPredictionModel from './trigger/prediction';
import TriggerRestrictModel from './trigger/restrict';
import TriggerShareModel from './trigger/share';
import TriggerSmartMoveModel from './trigger/smart-move';
import TriggerSmartScheduleModel from './trigger/smart-schedule';
import TriggerTagModel from './trigger/tag';
import TriggerTodoModel from './trigger/todo';
import TriggerWebhookModel from './trigger/webhook';
import { set } from '@ember/object';

const WEEKEND_SKIPPING_TYPES = ['todo', 'message', 'note', 'nps'];

export enum DelayUnit {
  minutes = 'minutes',
  hours = 'hours',
  days = 'days',
}

export type AllTriggerModels =
  | TriggerAskForFeedbackModel
  | TriggerHireQualitySurveyModel
  | TriggerNoteModel
  | TriggerNpsModel
  | TriggerNurtureModel
  | TriggerMessageModel
  | TriggerPredictionModel
  | TriggerRestrictModel
  | TriggerShareModel
  | TriggerSmartMoveModel
  | TriggerSmartScheduleModel
  | TriggerSurveyModel
  | TriggerTagModel
  | TriggerTodoModel
  | TriggerWebhookModel;

export default class TriggerModel<
  TriggerType extends AllTriggerModels['type']
> extends Model {
  @service declare intl: IntlService;

  @belongsTo('job') declare job: AsyncBelongsTo<JobModel>;
  @belongsTo('stage') declare stage: AsyncBelongsTo<StageModel>;

  @attr('date') declare created_at: Date;

  @attr('string', { defaultValue: DelayUnit.minutes })
  declare delayJobForUnit: DelayUnit;

  @attr('number', { defaultValue: 1 }) declare delayJobFor: number;
  @attr('string', { defaultValue: 'above' })
  declare proceedConditionType: string;

  @attr('boolean', { defaultValue: false }) declare ignoreInternal: boolean;
  @attr('boolean', { defaultValue: false }) declare ignoreSourced: boolean;
  @attr('boolean', { defaultValue: false }) declare delayUntilWeekday: boolean;
  @attr('boolean', { defaultValue: false }) declare onReject: boolean;
  @attr('string') declare type: TriggerType;
  @attr('boolean', { defaultValue: false }) declare hideOnStage: boolean;
  @attr('boolean', { defaultValue: false }) declare cancelOnMove: boolean;
  @attr('boolean', { defaultValue: false }) declare cancelOnReject: boolean;

  get triggerTranslationName(): string {
    return this.type;
  }

  get valid() {
    return false;
  }

  get delayJobForCustomValue() {
    const unit = get(this, 'delayJobForUnit');
    const delayMinutes = get(this, 'delayJobFor');

    if (unit === DelayUnit.days) {
      return Math.floor(delayMinutes / 60 / 24);
    } else if (unit === DelayUnit.hours) {
      return Math.floor(delayMinutes / 60);
    } else {
      return delayMinutes;
    }
  }

  set delayJobForCustomValue(value) {
    const unit = get(this, 'delayJobForUnit');

    if (unit === DelayUnit.days) {
      set(this, 'delayJobFor', Math.floor(value * 60 * 24));
    } else if (unit === DelayUnit.hours) {
      set(this, 'delayJobFor', Math.floor(value * 60));
    } else {
      set(this, 'delayJobFor', Math.floor(value));
    }
  }

  get delayJobForSelectedOption() {
    const option = delayOptions.find(
      (o) => o.value === get(this, 'delayJobFor')
    );

    if (option) {
      return option.value;
    } else {
      return 'custom';
    }
  }

  get delayJobForString() {
    const delayJobFor = get(this, 'delayJobFor');
    const option = delayOptions.find((o) => {
      return o.value === delayJobFor;
    });

    if (option) {
      return this.intl.t(option.title);
    } else {
      return `${get(this, 'delayJobForCustomValue')} ${get(
        this,
        'delayJobForUnit'
      )}`;
    }
  }

  get typeWithSubtype(): string {
    return get(this, 'type');
  }

  get label() {
    return triggerLabel(
      get(this, 'intl').t.bind(get(this, 'intl')),
      get(this, 'typeWithSubtype'),
      { webhookNoun: '', webhookVerb: '' }
    );
  }

  get description() {
    return this.intl.t('models.trigger.no_description');
  }

  get canSkipWeekends() {
    return WEEKEND_SKIPPING_TYPES.includes(get(this, 'type'));
  }

  get triggerContent() {
    return '';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    trigger: AllTriggerModels;
  }
}
