import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#each this.orderedStageTypeNamesWithInProcess as |stageTypeName|}}\n  <Insights::Molecules::StageTypes::StatsItem\n    @isEmpty={{@isEmpty}}\n    @stageTypeName={{stageTypeName}}\n    @stageTypesStats={{@stageTypesStats}}\n  />\n{{/each}}", {"contents":"{{#each this.orderedStageTypeNamesWithInProcess as |stageTypeName|}}\n  <Insights::Molecules::StageTypes::StatsItem\n    @isEmpty={{@isEmpty}}\n    @stageTypeName={{stageTypeName}}\n    @stageTypesStats={{@stageTypesStats}}\n  />\n{{/each}}","moduleName":"teamtailor/components/insights/molecules/stage-types/stats.hbs","parseOptions":{"srcName":"teamtailor/components/insights/molecules/stage-types/stats.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class Stats extends Component {
  @service current;

  get orderedStageTypeNames() {
    if (!this.current.company) return [];

    return get(this.current.company, 'orderedStageTypes')?.map(
      (stageType) => stageType.category
    );
  }

  get orderedStageTypeNamesWithInProcess() {
    return [
      this.orderedStageTypeNames[0],
      'in_process',
      ...this.orderedStageTypeNames.slice(
        1,
        this.orderedStageTypeNames.length - 1
      ),
    ];
  }
}
