import ApplicationInstance from '@ember/application/instance';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';
import AnalyticsService from 'teamtailor/services/analytics';
import { FetchOptions, CompareFetchOptions } from './report-analytics-request';
import DateRange from 'teamtailor/utils/date-range';

export interface ComparableReport {
  compareModel?: ComparableReport;
}

interface BuilderArgs<Report, QueryReturnType> {
  query: (options?: FetchOptions) => Promise<QueryReturnType>;
  queryOptions?: FetchOptions;
  compareOptions?: CompareFetchOptions;
  createReport: (
    queryResult: QueryReturnType,
    extraProps?: Record<string, unknown>
  ) => Report;
}

export interface BuildReportArgs {
  container: ApplicationInstance;
  options?: FetchOptions;
  compareOptions?: CompareFetchOptions;
}

export class AnalyticsReportBuilder<Report, QueryReturnType> {
  container: ApplicationInstance;
  query: BuilderArgs<Report, QueryReturnType>['query'];
  queryOptions?: BuilderArgs<Report, QueryReturnType>['queryOptions'];
  createReport: BuilderArgs<Report, QueryReturnType>['createReport'];

  compareOptions?: CompareFetchOptions;

  constructor(
    container: ApplicationInstance,
    args: BuilderArgs<Report, QueryReturnType>
  ) {
    this.container = container;
    this.query = args.query;
    this.queryOptions = args.queryOptions;
    this.compareOptions = args.compareOptions;
    this.createReport = args.createReport;
  }

  @service declare analytics: AnalyticsService;

  build = task(async () => {
    const queryResult = await this.query(this.queryOptions);
    const report = this.createReport(queryResult, {
      dateRange: new DateRange(
        this.analytics.startDateParam,
        this.analytics.endDateParam
      ),
    });

    if (this.compareOptions) {
      const rawDataCompare = await this.query(this.compareOptions);
      // @ts-expect-error: Property 'compareModel' does not exist on type 'Report'
      report.compareModel = this.createReport(rawDataCompare, {
        dateRange: this.compareOptions.dateRange,
      });
    }

    return report;
  });

  buildTask = trackedTask(this, this.build);
}
