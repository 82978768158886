import Store from '@ember-data/store';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { JobModel } from 'teamtailor/models';

export default class JobsEditStagesQuickRejectSettings extends Route {
  @service declare router: RouterService;
  @service declare store: Store;

  model(params: { stage_id: string }) {
    return this.store.findRecord('stage', params.stage_id);
  }

  @action
  onClose(job: JobModel) {
    this.router.transitionTo('jobs.job.stages.index', job.id);
  }
}
