import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { base64ToJson } from 'teamtailor/utils/base-64';
import Settings from 'teamtailor/utils/insights/reports/settings';
import UserModel from 'teamtailor/models/user';
import BaseProperty from 'teamtailor/utils/insights/reports/properties/base-property';
import FilterSettings from 'teamtailor/utils/insights/reports/filter-settings';
import { ReportFilterOption } from 'teamtailor/utils/insights/reports/filters';
import ScheduledReport from './scheduled-report';

type CombinedSettings = {
  chartSettings: Settings[];
  filterSettings: FilterSettings;
};

export default class SharedReportModel extends Model {
  @attr('string') declare name: string;
  @attr('string', { defaultValue: '📊' }) declare emoji: string;
  @attr('string') declare settings: string;

  @attr('number') declare userId: number;
  @attr('boolean') declare isShared: boolean;
  @attr('date') declare updatedAt: Date;
  @attr('date') declare createdAt: Date;

  @attr('array') declare companyIds: string[];

  @belongsTo('scheduled-report')
  declare scheduledReport: AsyncBelongsTo<ScheduledReport>;

  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;

  deserializeSettings(
    translatedFilters: ReportFilterOption[],
    translatedProperties: BaseProperty[],
    base64Settings: string
  ): CombinedSettings {
    const combinedSerializedSettings = base64ToJson(
      base64Settings
    ) as CombinedSettings;
    const chartSettings = combinedSerializedSettings.chartSettings.map(
      (o: any) => {
        const settings = new Settings(translatedProperties);
        settings.deserialize(o);
        return settings;
      }
    );
    const filterSettings = new FilterSettings(
      translatedProperties,
      translatedFilters
    );
    filterSettings.deserialize(combinedSerializedSettings.filterSettings);

    return {
      chartSettings,
      filterSettings,
    };
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'shared-report': SharedReportModel;
  }
}
