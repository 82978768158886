import Controller from '@ember/controller';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { restartableTask } from 'ember-concurrency';

const exampleTags = [
  ['awesome', 'go getter', 'bread and butter'],
  ['AWESOME', 'GO GETTER', 'BREAD AND BUTTER'],
  ['Awesome', 'Go-getter', 'Bread and Butter'],
  ['awesome', 'go-getter', 'bread-and-butter'],
  ['Awesome', 'Go getter', 'Bread and butter'],
  ['Awesome', 'Go Getter', 'Bread And Butter'],
];

export default class SettingsTagsSettingsController extends Controller {
  @service intl;
  @service current;
  @service store;

  tagFormats = [
    { value: 0, name: 'downcase' },
    { value: 1, name: 'uppercase' },
    { value: 2, name: 'none' },
    { value: 3, name: 'kebab' },
    { value: 4, name: 'capitalize' },
    { value: 5, name: 'titlecase' },
  ];

  getFormattedTagExamples(tagFormat) {
    return exampleTags[tagFormat.value].join(', ');
  }

  get hasTags() {
    return get(this.current.company, 'tags.length');
  }

  saveTask = restartableTask(async () => {
    const { company } = this.current;

    if (company.changedAttributes().tagFormat) {
      this.store.unloadAll('tag');
    }

    return company.saveTagSettings();
  });

  @action
  getTagFormatSubtitle(tagFormat) {
    const helpText = this.intl.t(
      `settings.tag_manager.settings.${tagFormat.name}_helptext`
    );

    const examples = this.intl.t('settings.tag_manager.settings.examples', {
      tags: `<strong>${this.getFormattedTagExamples(tagFormat)}</strong>`,
    });

    return htmlSafe(`${helpText} <br>${examples}`);
  }
}
