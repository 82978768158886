import { tracked } from '@glimmer/tracking';
import { IMeetingEvent } from 'teamtailor/components/meeting/types';
import PreparedMessageClass from '../../classes/prepared-message';
import {
  MeetingEventAttributes,
  MeetingEventClass,
  MeetingEventConstructorType,
} from './meeting-event';

export class MeetingEventListClass {
  @tracked declare meetingEvents: MeetingEventClass[];
  @tracked private readonly originalItems: MeetingEventClass[];
  @tracked readonly preparedMessage?: PreparedMessageClass;

  constructor(
    meetingEvents: MeetingEventClass[],
    preparedMessage?: PreparedMessageClass
  ) {
    meetingEvents.forEach((event) => (event.meetingEventList = this));
    this.meetingEvents = [...meetingEvents];
    this.originalItems = [...meetingEvents];
    this.preparedMessage = preparedMessage;
  }

  static from(
    meetingEvents: IMeetingEvent[],
    preparedMessage?: PreparedMessageClass
  ): MeetingEventListClass {
    const events = meetingEvents.map((meetingEvent) =>
      MeetingEventClass.from(meetingEvent as MeetingEventConstructorType)
    );
    return new MeetingEventListClass(events, preparedMessage);
  }

  add(meetingEvent: MeetingEventClass): void {
    meetingEvent.meetingEventList = this;
    this.meetingEvents = [...this.meetingEvents, meetingEvent];
  }

  remove(meetingEvent: MeetingEventClass): void {
    const meetingEvents = this.meetingEvents.filter(
      (m: MeetingEventClass) => m !== meetingEvent
    );

    this.meetingEvents = [...meetingEvents];
  }

  hasEvent(meetingEvent: MeetingEventClass): boolean {
    return this.meetingEvents.some((item) => item === meetingEvent);
  }

  hasSelfSchedule(): boolean {
    return this.meetingEvents.some((event) => event.selfSchedule);
  }

  hasChanges(): boolean {
    return this.meetingEvents.some((event) => event.hasChanges());
  }

  toAttributes(): MeetingEventAttributes[] {
    const removedEvents = this.originalItems.filter(
      (meetingEvent) => meetingEvent.id && !this.hasEvent(meetingEvent)
    );

    const editableEvents = this.meetingEvents.filter(
      (event) => !event.isReadOnly
    );

    return [
      ...editableEvents.map((meetingEvent) => meetingEvent.toAttributes()),
      ...removedEvents.map(({ id }) => ({ id, _destroy: true })),
    ];
  }
}
