import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Icon @icon={{this.icon}} @size={{@size}} @style={{this.style}} ...attributes />", {"contents":"<Icon @icon={{this.icon}} @size={{@size}} @style={{this.style}} ...attributes />","moduleName":"teamtailor/components/content-block/social-links/icon.hbs","parseOptions":{"srcName":"teamtailor/components/content-block/social-links/icon.hbs"}});
import Component from '@glimmer/component';

const ICON_MAP: { [key: string]: string } = {
  link: 'link-simple',
  twitter: 'x-twitter',
};

interface SocialLinksIconArgs {
  type: string;
}

export default class SocialLinksIcon extends Component<SocialLinksIconArgs> {
  get icon() {
    return ICON_MAP[this.args.type] || this.args.type;
  }

  get style() {
    return this.args.type === 'link' ? 'regular' : 'brands';
  }
}
