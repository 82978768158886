import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import jsonToCsvFile from 'teamtailor/utils/json-to-csv-file';
import { fetchMissingAverageRatings } from 'teamtailor/utils/average-rating';
import sortOnAverageRating from 'teamtailor/utils/sort-on-average-rating';

export default class SourcesController extends Controller {
  @service reports;
  @service analytics;
  @service intl;

  get data() {
    return this.model.sources.value;
  }

  get sources() {
    if (!this.data) {
      return;
    }

    return this.data.filteredSources;
  }

  @tracked sortDirection = 'desc';
  @tracked sortProperty = 'sessions';
  @tracked loadingAverageRatings = false;

  get sortedRows() {
    const { sortDirection, sortProperty, sources } = this;
    if (sortProperty === 'averageRating') {
      return sortOnAverageRating(sources, sortDirection);
    }

    const sortedSources = sources.sortBy(sortProperty);
    return sortDirection === 'desc' ? sortedSources.reverse() : sortedSources;
  }

  get chartData() {
    let { sortProperty } = this;
    if (sortProperty !== 'sessions' && sortProperty !== 'applications') {
      sortProperty = 'sessions';
    }

    const sortedRows = this.sources.sortBy(sortProperty).reverse();

    const top = sortedRows
      .slice(0, 10)
      .map((row) => ({ title: row.name, value: row[sortProperty] }));

    let others;
    if (sortedRows.length > 10) {
      others = {
        title: this.intl.t('insights.reports.other'),
        value: sortedRows
          .slice(10)
          .reduce((sum, row) => sum + row[sortProperty], 0),
      };
    }

    return {
      title: this.intl.t(`reports.${sortProperty}`),
      data: top.concat(others).filter((row) => !!row),
    };
  }

  @action
  async handleExport() {
    await fetchMissingAverageRatings(this.data.rows);

    jsonToCsvFile(
      'sources',
      this.analytics.startDate,
      this.analytics.endDate,
      this.data.rows,
      (row) => ({
        source: row.name,
        applications: row.applications,
        pageviews: row.pageviews,
        sessions: row.sessions,
        averageRating: row.averageRating.valueForCsv,
        candidatesCount: row.nrCandidates,
        candidateIds: row.candidateIds,
      })
    );
  }

  @action
  async handleSort(sortProperty) {
    if (this.sortProperty === sortProperty) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
    } else {
      if (sortProperty === 'averageRating') {
        this.loadingAverageRatings = true;
        await fetchMissingAverageRatings(this.data.rows);
        this.loadingAverageRatings = false;
      }

      this.sortDirection = 'desc';
      this.sortProperty = sortProperty;
    }
  }
}
