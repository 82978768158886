import Service, { inject as service } from '@ember/service';

import {
  IUser,
  IJobApplication,
  IJob,
  IStage,
  ICandidate,
} from 'teamtailor/components/meeting/types';

import TeamtailorApolloService from 'teamtailor/services/apollo';
import { JobClass } from 'teamtailor/classes/job';
import {
  CandidateAttendeeClass,
  MeetingEventClass,
  MeetingEventListClass,
  UserAttendeeClass,
} from 'teamtailor/classes/meetings';
import { MeetingRoomAttendeeClass } from 'teamtailor/classes/meetings/meeting-room-attendee';
import GET_JOB_APPLICATIONS from 'teamtailor/gql/job-applications-query.graphql';
import ErrorClass from 'teamtailor/classes/error-class';
import PreparedMessageClass from 'teamtailor/classes/prepared-message';
import GET_USER from 'teamtailor/gql/get-user.graphql';
import GET_CANDIDATES from 'teamtailor/gql/candidates-queries.graphql';

export type Response = {
  user: IUser;
  preparedMessage: PreparedMessageClass;
  meetingEvents: MeetingEventListClass;
  jobClass: JobClass;
  errors: ErrorClass;
  meetingRooms: MeetingRoomAttendeeClass[];
  jobApplications: IJobApplication[];
};

type JobApplicationsConnectionEdge = {
  node: IJobApplication;
};

type JobApplicationsConnectionResponse = {
  jobApplicationsConnection: {
    edges: JobApplicationsConnectionEdge[];
  };
};

type CandidatesResult = {
  candidates: ICandidate[];
};

interface QueryParams {
  candidateId?: string;
  jobId?: string;
  bulkOptions?: string;
}

export default class MeetingLoaderNewGraphql extends Service {
  @service declare apollo: TeamtailorApolloService;

  async loadData({
    candidateId,
    jobId,
    bulkOptions,
  }: QueryParams): Promise<Response> {
    const user = await this.fetchUser();

    const organizer = UserAttendeeClass.from(user);
    organizer.isOrganizer = true;
    const candidateAttendees: CandidateAttendeeClass[] = [];

    await this.getCandidatesData(bulkOptions, candidateId, candidateAttendees);

    const event = new MeetingEventClass({
      tzid: user.tzid || user.company.tzid,
      visibility: user.company.meetingVisibility,
      meetingEventAttendees: [organizer, ...candidateAttendees],
      organizer,
    });

    let jobApplications: IJobApplication[] = [];
    // DO NOT CHANGE TO candidateAttendees[0]?.candidate, cause it does not work
    if (
      candidateAttendees.length > 0 &&
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      candidateAttendees[0] &&
      candidateAttendees[0].candidate
    ) {
      jobApplications = await this.fetchJobApplication(
        candidateAttendees[0].candidate.id
      );
    }

    const job = jobId
      ? jobApplications
          .map((ja: IJobApplication) => ja.job)
          .find((j: IJob | undefined) => j?.id === jobId)
      : undefined;

    const stage = jobApplications.firstObject?.stage;
    const interviewKitId = stage?.interviewKit?.id;

    if (
      job &&
      interviewKitId &&
      this.isAllCandidatesOnSameStage(candidateAttendees, stage)
    ) {
      const interviewKit = job.interviewKits?.findBy('id', interviewKitId);
      if (interviewKit) {
        event.selectInterviewKit(interviewKit);
      }
    }

    const preparedMessage = new PreparedMessageClass();

    return {
      user,
      meetingEvents: new MeetingEventListClass([event], preparedMessage),
      meetingRooms: user.company.meetingRooms.map((m) =>
        MeetingRoomAttendeeClass.from(m)
      ),

      preparedMessage,
      jobClass: new JobClass(job),
      errors: new ErrorClass(),
      jobApplications,
    };
  }

  isAllCandidatesOnSameStage(
    candidateAttendees: CandidateAttendeeClass[],
    stage: IStage
  ): boolean {
    if (candidateAttendees.length === 1) {
      return true;
    }

    return candidateAttendees.every(({ candidate }) => {
      if (!candidate) {
        return false;
      }

      return candidate.jobApplications
        .slice()
        .some((ja) => ja.stage?.id === stage.id);
    });
  }

  async getCandidatesData(
    bulkOptions: string | undefined,
    candidateId: string | undefined,
    candidateAttendees: CandidateAttendeeClass[]
  ): Promise<void> {
    if (!bulkOptions && !candidateId) {
      return;
    }

    const data: CandidatesResult = await this.apollo.watchQuery({
      query: GET_CANDIDATES,
      fetchPolicy: 'network-only',
      variables: {
        filterBase64: bulkOptions,
        candidateIds: [candidateId],
      },
    });
    data.candidates.forEach((c: ICandidate) =>
      candidateAttendees.push(CandidateAttendeeClass.from(c))
    );
  }

  async fetchJobApplication(candidateId: string): Promise<IJobApplication[]> {
    const { jobApplicationsConnection }: JobApplicationsConnectionResponse =
      await this.apollo.query({
        query: GET_JOB_APPLICATIONS,
        fetchPolicy: 'network-only',
        variables: {
          filter: {
            candidateId,
          },
        },
      });

    return jobApplicationsConnection.edges.map(
      (edge: JobApplicationsConnectionEdge) => edge.node
    );
  }

  async fetchUser(): Promise<IUser> {
    const { user }: { user: IUser } = await this.apollo.query({
      query: GET_USER,
      fetchPolicy: 'cache-first',
    });

    return user;
  }
}

declare module '@ember/service' {
  interface Registry {
    'meeting-loader-new-graphql': MeetingLoaderNewGraphql;
  }
}
