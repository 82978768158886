import Store from '@ember-data/store';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { allSettled, task } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';
import { PickedQuestionModel, QuestionModel } from 'teamtailor/models';
import TranslationDepartmentModel from 'teamtailor/models/translation/department';
import SettingsDepartmentsEditRoute from 'teamtailor/routes/settings/departments/edit';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import FlipperService from 'teamtailor/services/flipper';
import IframePreviewService from 'teamtailor/services/iframe-preview';
import TtAlertService from 'teamtailor/services/tt-alert';
import applyDefaultPickedQuestionValues from 'teamtailor/utils/apply-default-picked-question-values';
import { get } from 'teamtailor/utils/get';
import { ModelFrom } from 'teamtailor/utils/type-utils';

export default class SettingsDepartmentsEditController extends Controller {
  declare model: ModelFrom<SettingsDepartmentsEditRoute>;

  @service declare iframePreview: IframePreviewService;
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare flipper: FlipperService;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;
  @service declare current: Current;

  get defaultLanguageCode() {
    const careerSite = get(this.current.company, 'defaultCareerSite');
    return get(careerSite, 'languageCode');
  }

  saveTranslation = task(async (model: TranslationDepartmentModel) => {
    return model.save();
  });

  saveDepartment = task(async () => {
    try {
      const model = await this.model.save();
      get(model, 'pickedQuestions').filterBy('isNew').invoke('unloadRecord');
      return model;
    } catch {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
      return Promise.reject();
    }
  });

  @action
  async handleSave() {
    const model = await this.saveDepartment.perform();

    // Note: We must exclude default language from translations or else the previously updated page publication will be overwrittena
    const translationTasks = get(this.model, 'translations')
      .filter((t) => t.languageCode !== this.defaultLanguageCode)
      .map((t) => {
        return this.saveTranslation.perform(t);
      });

    const result = await allSettled(translationTasks);

    if (result.isAny('state', 'rejected')) {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
      return Promise.reject();
    } else {
      this.flashMessages.success(
        this.intl.t('settings.departments.department_was_saved')
      );
      this.router.replaceWith('settings.departments.edit', model.id);
      return Promise.resolve();
    }
  }

  @action
  handleDestroy() {
    get(this, 'ttAlert').confirm(
      this.intl.t('settings.departments.confirm_deleting_department'),
      () => {
        this.model
          .destroyRecord()
          .then(() => {
            get(this, 'flashMessages').success(
              this.intl.t('settings.departments.department_deleted')
            );
            this.router.transitionTo('settings.departments');
          })
          .catch(() => {
            this.flashMessages.error(
              this.intl.t('common.something_went_wrong')
            );
          });
      }
    );
  }

  @action
  removeQuestion(question: PickedQuestionModel) {
    question.deleteRecord();
  }

  @action
  pickQuestion(question: QuestionModel) {
    const pickedQuestions = get(this.model, 'pickedQuestions');

    get(this.model, 'pickedQuestions').createRecord(
      applyDefaultPickedQuestionValues({
        question,
        rowOrderPosition: pickedQuestions.length,
      })
    );
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.departments.edit': SettingsDepartmentsEditController;
  }
}
