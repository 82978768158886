import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::DropdownMenu\n  @icon=\"thumbtack\"\n  @tooltip={{t \"jobs.job.stages.pinned_messages\"}}\n  @ariaLabel={{t \"jobs.job.stages.pinned_messages\"}}\n  @text={{this.messageCount}}\n  class={{if (eq this.pinnedMessages.length 0) \"hidden\"}}\n>\n  <ol class=\"flex w-[342px] flex-col gap-y-12 p-8\">\n    {{#each this.pinnedMessages as |model|}}\n      <li>\n        <Jobs::Collaborate::Item @model={{model}} />\n      </li>\n    {{/each}}\n  </ol>\n</Core::DropdownMenu>", {"contents":"<Core::DropdownMenu\n  @icon=\"thumbtack\"\n  @tooltip={{t \"jobs.job.stages.pinned_messages\"}}\n  @ariaLabel={{t \"jobs.job.stages.pinned_messages\"}}\n  @text={{this.messageCount}}\n  class={{if (eq this.pinnedMessages.length 0) \"hidden\"}}\n>\n  <ol class=\"flex w-[342px] flex-col gap-y-12 p-8\">\n    {{#each this.pinnedMessages as |model|}}\n      <li>\n        <Jobs::Collaborate::Item @model={{model}} />\n      </li>\n    {{/each}}\n  </ol>\n</Core::DropdownMenu>","moduleName":"teamtailor/components/jobs/pinned-messages.hbs","parseOptions":{"srcName":"teamtailor/components/jobs/pinned-messages.hbs"}});
import Component from '@glimmer/component';
import ActivityModel from 'teamtailor/models/activity';
import { get } from 'teamtailor/utils/get';
import ArrayProxy from '@ember/array/proxy';

interface JobsPinnedMessagesArgs {
  activities: ArrayProxy<ActivityModel>;
}

export default class JobsPinnedMessages extends Component<JobsPinnedMessagesArgs> {
  get pinnedMessages(): ActivityModel[] {
    return get(this.args, 'activities')
      .filter((activity) => activity.pinned)
      .sortBy('createdAt')
      .reverse();
  }

  get messageCount(): number | undefined {
    return this.pinnedMessages.length;
  }
}
