import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#component @content}}\n  <FormRange\n    @min={{1}}\n    @max={{100}}\n    @value={{this.waitDays}}\n    @label={{t \"nurture_campaigns.step.wait_for\" days=this.waitDays}}\n    @unit={{t \"nurture_campaigns.step.wait_unit\"}}\n    @onInput={{set this \"waitDays\"}}\n  />\n{{/component}}\n\n<FormExpandableBox::Footer\n  @onSave={{this.onSave}}\n  @onCancel={{this.handleCancel}}\n  @onDelete={{this.handleDelete}}\n  @showDeleteButton={{@showDeleteButton}}\n  @showSaveButton={{or\n    (not (eq this.waitDays this.initValue))\n    this.changeset.isNew\n    this.saveTask.isRunning\n    this.delayedCloseTask.isRunning\n  }}\n/>", {"contents":"{{#component @content}}\n  <FormRange\n    @min={{1}}\n    @max={{100}}\n    @value={{this.waitDays}}\n    @label={{t \"nurture_campaigns.step.wait_for\" days=this.waitDays}}\n    @unit={{t \"nurture_campaigns.step.wait_unit\"}}\n    @onInput={{set this \"waitDays\"}}\n  />\n{{/component}}\n\n<FormExpandableBox::Footer\n  @onSave={{this.onSave}}\n  @onCancel={{this.handleCancel}}\n  @onDelete={{this.handleDelete}}\n  @showDeleteButton={{@showDeleteButton}}\n  @showSaveButton={{or\n    (not (eq this.waitDays this.initValue))\n    this.changeset.isNew\n    this.saveTask.isRunning\n    this.delayedCloseTask.isRunning\n  }}\n/>","moduleName":"teamtailor/components/nurture-campaign-step/wait.hbs","parseOptions":{"srcName":"teamtailor/components/nurture-campaign-step/wait.hbs"}});
import NurtureCampaignStepBase from './base';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import NurtureCampaignStepWaitValidations from 'teamtailor/validations/nurture-campaign-step/wait';

export default class NurtureCampaignStepWaitComponent extends NurtureCampaignStepBase {
  validator = NurtureCampaignStepWaitValidations;

  initValue;
  @tracked waitDays;

  constructor(owner, args) {
    super(owner, args);
    const value = this.changeset.waitMinutes / 60 / 24;
    this.initValue = value;
    this.waitDays = value;
  }

  @action
  onSave() {
    this.changeset.waitMinutes = this.waitDays * 60 * 24;
    return this.saveTask.perform();
  }
}
