import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import { isEmpty } from '@ember/utils';
import { ActiveModelSerializer } from 'active-model-adapter';
import { REMOVE_ATTRIBUTE } from 'teamtailor/transforms/carrierwave';
import { get } from 'teamtailor/utils/get';

export default class Application extends ActiveModelSerializer.extend(
  EmbeddedRecordsMixin
) {
  isNewSerializerAPI = true;

  serialize() {
    let json = super.serialize(...arguments);
    Object.keys(json).forEach(function (key) {
      if (json[key] === REMOVE_ATTRIBUTE) {
        delete json[key];
        if (isEmpty(json[`${key}_cache`])) {
          json[`remove_${key}`] = true;
        }
      }
    });

    return json;
  }

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    payload = this.typifyTriggers(payload || {});
    return super.normalizeResponse(
      store,
      primaryModelClass,
      payload,
      id,
      requestType
    );
  }

  modelNameFromPayloadKey() {
    let modelName = super.modelNameFromPayloadKey(...arguments);

    if (modelName.startsWith('trigger-')) {
      modelName = modelName.replace('-', '/');
    }

    return modelName;
  }

  typifyTriggers(payload) {
    if (payload && payload.triggers) {
      let { triggers } = payload;
      delete payload.triggers;
      triggers.forEach((data) => {
        let key = data.relation_name;
        payload[key] = payload[key] || [];
        payload[key].push(data);
      });
    }

    return payload;
  }

  _serializeEmbeddedHasMany(snapshot, json, relationship) {
    super._serializeEmbeddedHasMany(snapshot, json, relationship);

    const serializedKey = `${this.keyForRelationship(
      relationship.key
    )}_attributes`;

    if (!(serializedKey in json)) {
      return json;
    }

    const deletedRecords = get(snapshot.record, relationship.key)
      .toArray()
      .filterBy('isDeleted')
      .mapBy('id')
      .map((id) => {
        return { id, _destroy: true };
      });

    return Object.assign(json, {
      [serializedKey]: [...json[serializedKey], ...deletedRecords],
    });
  }
}
