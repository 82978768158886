import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"relative\"\n  {{did-insert this.initDropdown}}\n  {{will-destroy this.destroyEvents}}\n>\n  <Editor::Adjustments::Controls::Button\n    @attribute={{@attribute}}\n    @label={{@label}}\n    @emptyLabel={{@emptyLabel}}\n    @value={{@displayValue}}\n    @icon={{@icon}}\n    @onClick={{this.toggleOpen}}\n    @tooltip={{@tooltip}}\n  />\n  {{#if this.dropdownOpen}}\n    <div\n      class={{class-names\n        \"absolute z-content-editor-dropdown mt-12 w-[120%] rounded-8 bg-base-grey-78 p-16\"\n        @dropdownClass\n        left-0=this.leftAlignedDropdown\n        right-0=(not this.leftAlignedDropdown)\n      }}\n      {{outside-click this.handleOutsideClick leakyDelayAdd=true}}\n    >\n      {{yield this}}\n    </div>\n  {{/if}}\n</div>", {"contents":"<div\n  class=\"relative\"\n  {{did-insert this.initDropdown}}\n  {{will-destroy this.destroyEvents}}\n>\n  <Editor::Adjustments::Controls::Button\n    @attribute={{@attribute}}\n    @label={{@label}}\n    @emptyLabel={{@emptyLabel}}\n    @value={{@displayValue}}\n    @icon={{@icon}}\n    @onClick={{this.toggleOpen}}\n    @tooltip={{@tooltip}}\n  />\n  {{#if this.dropdownOpen}}\n    <div\n      class={{class-names\n        \"absolute z-content-editor-dropdown mt-12 w-[120%] rounded-8 bg-base-grey-78 p-16\"\n        @dropdownClass\n        left-0=this.leftAlignedDropdown\n        right-0=(not this.leftAlignedDropdown)\n      }}\n      {{outside-click this.handleOutsideClick leakyDelayAdd=true}}\n    >\n      {{yield this}}\n    </div>\n  {{/if}}\n</div>","moduleName":"teamtailor/components/editor/adjustments/controls/dropdown.hbs","parseOptions":{"srcName":"teamtailor/components/editor/adjustments/controls/dropdown.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';

export default class extends Component {
  @service blockAdjustments;

  @tracked
  dropdownOpen = false;

  @tracked
  leftAlignedDropdown = true;

  @tracked
  selectedColor = this.args.displayValue;

  get isDisabled() {
    return (
      this.blockAdjustments.editingAttribute &&
      this.blockAdjustments.editingAttribute !== this.args.attribute
    );
  }

  @action
  toggleOpen() {
    if (!this.isDisabled && this.dropdownOpen) {
      this.close();
    } else if (!this.isDisabled && !this.dropdownOpen) {
      this.open();
    }
  }

  @action
  close() {
    this.dropdownOpen = false;
    this.blockAdjustments.editingAttribute = null;
  }

  @action
  open() {
    this.dropdownOpen = true;
    this.blockAdjustments.editingAttribute = this.args.attribute;
  }

  @action
  initDropdown(element) {
    const elementRect = element.getBoundingClientRect();
    const parentRect = element.parentElement.getBoundingClientRect();

    this.leftAlignedDropdown =
      parentRect.right - elementRect.right > elementRect.width / 2;

    document.addEventListener(this.blockAdjustments.closeAllEvent, this.close);
  }

  @action
  destroyEvents() {
    document.removeEventListener(
      this.blockAdjustments.closeAllEvent,
      this.close
    );
  }

  @action
  handleOutsideClick() {
    if (!this.args.disableOutsideClick) {
      next(() => {
        this.close();
      });
    }
  }
}
