import { inject as service } from '@ember/service';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get, set } from '@ember/object';

export default class RenewCreditsRoute extends ScrollToTopRoute {
  @service current;
  @service store;

  setupController(controller, model) {
    super.setupController(controller, model);
    const packages = get(this.current.company, 'wallet.packages');
    if (get(this.current.company, 'smsSetting.id')) {
      const [currentPackage] = packages.filter((creditsPackage) => {
        return (
          get(this.current.company, 'smsSetting.creditsToBuy') ===
          creditsPackage.credits
        );
      });
      set(controller, 'currentPackage', currentPackage);
    } else {
      set(controller, 'currentPackage', packages[1]);
    }

    set(controller, 'packageOptions', packages);
    const creditsRenewalThresholdOptions = get(
      this.current.company,
      'wallet.creditsRenewalThresholdOptions'
    );
    set(
      controller,
      'creditsRenewalThresholdOptions',
      creditsRenewalThresholdOptions
    );
  }

  beforeModel() {
    return get(this.current.company, 'wallet');
  }

  model() {
    const creditsRenewalThresholdOptions = get(
      this.current.company,
      'wallet.creditsRenewalThresholdOptions'
    );
    if (get(this.current.company, 'smsSetting.id')) {
      return get(this.current.company, 'smsSetting');
    } else {
      return this.store.createRecord('sms-setting', {
        creditsRenewalThreshold: creditsRenewalThresholdOptions[0],
      });
    }
  }
}
