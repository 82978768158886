import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Select\n  ...attributes\n  @isFullWidth={{this.isFullWidth}}\n  @size={{this.size}}\n  @options={{@options}}\n  @optionName=\"name\"\n  @placeholder={{t \"surveys.select_survey\"}}\n  @searchTerm=\"name\"\n  @searchPlaceholder={{t \"search.survey.search_survey\"}}\n  @onSelect={{@onSelect}}\n  @selected={{@selected}}\n  @nullOption={{if @allowNull (t \"core.select.none\") null}}\n  as |Item survey|\n>\n  <Item\n    @text={{survey.name}}\n    @badgeText={{if survey.open (t \"surveys.open\") (t \"surveys.anonymous\")}}\n  />\n</Core::Select>", {"contents":"<Core::Select\n  ...attributes\n  @isFullWidth={{this.isFullWidth}}\n  @size={{this.size}}\n  @options={{@options}}\n  @optionName=\"name\"\n  @placeholder={{t \"surveys.select_survey\"}}\n  @searchTerm=\"name\"\n  @searchPlaceholder={{t \"search.survey.search_survey\"}}\n  @onSelect={{@onSelect}}\n  @selected={{@selected}}\n  @nullOption={{if @allowNull (t \"core.select.none\") null}}\n  as |Item survey|\n>\n  <Item\n    @text={{survey.name}}\n    @badgeText={{if survey.open (t \"surveys.open\") (t \"surveys.anonymous\")}}\n  />\n</Core::Select>","moduleName":"teamtailor/components/core/survey-picker.hbs","parseOptions":{"srcName":"teamtailor/components/core/survey-picker.hbs"}});
import Component from '@glimmer/component';
import SurveyModel from 'teamtailor/models/survey';
import { argDefault } from 'teamtailor/utils/arg-default';

type SurveyPickerArgs = {
  isFullWidth?: boolean;
  allowNull?: boolean;
  size?: string;
  selected?: SurveyModel;
  options: SurveyModel[];
  onSelect: (survey?: SurveyModel) => void;
};

export default class CoreSurveyPicker extends Component<SurveyPickerArgs> {
  @argDefault isFullWidth = true;
  @argDefault allowNull = false;
  @argDefault size = 'large';
}
