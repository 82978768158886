import ApplicationInstance from '@ember/application/instance';
import { gql } from '@apollo/client/core';
import ReportAnalyticsRequest, {
  FetchOptions,
} from './report-analytics-request';
import {
  AnalyticsReportBuilder,
  BuildReportArgs,
} from './analytics-report-builder';

const QUERY = gql`
  query NewNPSPerStageQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [NPS_RESPONSE]
      distinctBy: {
        fields: [JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
      companyIds: $companyIds
    ) {
      aggregated(groupBy: [STAGE_NORMALIZED_NAME, STAGE_INDEX]) {
        stageName: stageNormalizedName
        stageIndex

        totalNpsScore: average(
          field: NPS_SCORE
          filters: { npsScore: { exists: true } }
        )
        totalResponses: countOccurrences(
          filters: { npsScore: { exists: true } }
        )

        rejectedNpsScore: average(
          field: NPS_SCORE
          filters: {
            npsScore: { exists: true }
            stageIsReject: { equals: true }
          }
        )
        rejectedResponses: countOccurrences(
          filters: {
            npsScore: { exists: true }
            stageIsReject: { equals: true }
          }
        )

        nonRejectedNpsScore: average(
          field: NPS_SCORE
          filters: {
            npsScore: { exists: true }
            stageIsReject: { equals: false }
          }
        )
        nonRejectedResponses: countOccurrences(
          filters: {
            npsScore: { exists: true }
            stageIsReject: { equals: false }
          }
        )
      }
    }
  }
`;

interface Result {
  eventQuery:
    | {
        aggregated: NpsPerStageRow[];
      }
    | undefined;
}

export interface NpsPerStageRow {
  stageName: string;
  stageIndex: number;
  totalNpsScore: number;
  totalResponses: number;
  rejectedNpsScore: number;
  rejectedResponses: number;
  nonRejectedNpsScore: number;
  nonRejectedResponses: number;
}

class NpsPerStageReport {
  rows: NpsPerStageRow[] = [];

  constructor(rows: NpsPerStageRow[]) {
    this.rows = rows;
  }
}

export function buildReport(args: BuildReportArgs) {
  const { container } = args;
  return new AnalyticsReportBuilder<NpsPerStageReport, NpsPerStageRow[]>(
    container,
    {
      query: async (options: FetchOptions = {}) => {
        return fetch(container, options);
      },

      createReport: (queryResult: NpsPerStageRow[] | null) => {
        return new NpsPerStageReport(queryResult ?? []);
      },
    }
  );
}

export async function fetch(
  container: ApplicationInstance,
  options: FetchOptions
): Promise<NpsPerStageRow[]> {
  return new ReportAnalyticsRequest({
    container,
    query: QUERY,
    callback: (result?: Result) => result?.eventQuery?.aggregated,
  }).fetch(options);
}
