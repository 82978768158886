import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class MarketplacePartnersIndexPartnerInternalController extends Controller {
  @service intl;
  @service store;
  @service router;
  @service current;
  @service flashMessages;
  @service user;

  get settingsRoute() {
    if (this.model.webhookNoun === 'apply_with_linkedin') {
      return 'apply_with';
    } else {
      return this.model.webhookNoun;
    }
  }

  @action
  handleClose() {
    this.router.transitionTo('marketplace');
  }

  @action
  async activateInternal() {
    try {
      const partner = await this.store.findRecord('partner', this.model.id);
      const { company } = this.current;
      const partnerActivation = this.store.createRecord('partnerActivation', {
        partner,
        company,
      });

      await partnerActivation.save();

      this.router.transitionTo(`settings.${this.model.webhookNoun}`);
    } catch (e) {
      this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
    }
  }
}
