import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class ProfileRoute extends Route {
  @service notifications;
  @service intl;
  @service store;
  @service router;
  @service flashMessages;

  async model({ user_id }) {
    try {
      return this.store.findRecord('user', user_id);
    } catch (e) {
      if (e.errors[0].status === '404') {
        this.flashMessages.error(this.intl.t('employee.employee_not_found'));
        this.router.transitionTo('employees');
      }
    }
  }

  afterModel(user) {
    super.afterModel(...arguments);

    this.notifications.markAsRead({
      userId: user.id,
    });
  }
}
