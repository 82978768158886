import {
  API,
  BlockAPI,
  HTMLPasteEventDetail,
  PasteEvent,
} from '@editorjs/editorjs/types';
import { LiteYTEmbed } from '@justinribeiro/lite-youtube';
import { LiteVimeoEmbed } from '@slightlyoff/lite-vimeo';

interface BlockData {
  youtubeId?: string | null;
  vimeoId?: string | null;
}

interface Args {
  api: API;
  data: BlockData;
  block: BlockAPI;
  config: Config;
}

interface Config {
  placeholder: string;
}

interface VideoNodes {
  input?: HTMLInputElement;
  container?: HTMLDivElement;
}

const youtubeRegExp =
  /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;
const vimeoRegExp =
  /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i;

function parseYoutubeId(url: string) {
  const match = url.match(youtubeRegExp);
  return match && match[1]?.length === 11 ? match[1] : null;
}

function parseVimeoId(url: string) {
  const match = url.match(vimeoRegExp);
  return match?.[1] ? match[1] : null;
}

export default class Video {
  api: API;
  data: BlockData;
  block: BlockAPI;
  config: Config;
  nodes: VideoNodes;

  constructor({ api, data, block, config }: Args) {
    this.api = api;
    this.data = data;
    this.block = block;
    this.config = config;
    this.nodes = {};
  }

  static get pasteConfig() {
    return {
      tags: ['LITE-YOUTUBE', 'LITE-VIMEO'],
      patterns: {
        youtube: youtubeRegExp,
        vimeo: vimeoRegExp,
      },
    };
  }

  onPaste(event: PasteEvent) {
    const { data } = event.detail as HTMLPasteEventDetail;
    if (event.type === 'tag') {
      if (data.nodeName === 'LITE-YOUTUBE') {
        this.data = {
          ...this.data,
          youtubeId: (data as LiteYTEmbed).getAttribute('videoid'),
        };
        this.block.dispatchChange();
      }

      if (data.nodeName === 'LITE-VIMEO') {
        this.data = {
          ...this.data,
          vimeoId: (data as LiteVimeoEmbed).getAttribute('videoid'),
        };
        this.block.dispatchChange();
      }

      this.renderVideo();
    }

    if (
      event.type === 'pattern' &&
      this.nodes.input &&
      typeof data === 'string'
    ) {
      const youtubeId = parseYoutubeId(data);
      const vimeoId = parseVimeoId(data);

      if (youtubeId || vimeoId) {
        this.data = {
          youtubeId,
          vimeoId,
        };
        this.block.dispatchChange();
      }

      this.renderVideo();
    }
  }

  static get toolbox() {
    return {
      title: 'Video',
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }

  renderVideo = () => {
    const video = this.addVideo();
    if (video && this.nodes.input) {
      this.nodes.input.style.display = 'none';
    }
  };

  render() {
    const container = document.createElement('div');
    container.classList.add('cdx-block');
    const input = document.createElement('input');
    input.placeholder = this.config.placeholder;

    input.addEventListener('input', this.renderVideo);

    container.appendChild(input);

    this.nodes.container = container;
    this.nodes.input = input;
    this.renderVideo();
    return container;
  }

  save() {
    return {
      youtubeId: this.data.youtubeId,
      vimeoId: this.data.vimeoId,
    };
  }

  addVideo(): HTMLElement | undefined {
    const url = this.nodes.input?.value;

    const youtubeId = url ? parseYoutubeId(url) : this.data.youtubeId;
    const vimeoId = url ? parseVimeoId(url) : this.data.vimeoId;

    this.data.youtubeId = youtubeId;
    this.data.vimeoId = vimeoId;

    const existingVimeo = this.nodes.container?.querySelector('lite-vimeo');
    const existingYoutube = this.nodes.container?.querySelector('lite-youtube');

    if (youtubeId && existingYoutube) {
      existingYoutube.setAttribute('videoid', youtubeId);
      return existingYoutube;
    } else if (vimeoId && existingVimeo) {
      existingVimeo.setAttribute('videoid', vimeoId);
      return existingVimeo;
    } else if (youtubeId) {
      import('@justinribeiro/lite-youtube');
      const video = document.createElement('lite-youtube');
      video.dataset.mutationFree = 'true';
      video.setAttribute('videoid', youtubeId);
      this.nodes.container?.prepend(video);
      return video;
    } else if (vimeoId) {
      import('@slightlyoff/lite-vimeo');
      const video = document.createElement('lite-vimeo');
      video.dataset.mutationFree = 'true';
      video.setAttribute('videoid', vimeoId);
      this.nodes.container?.prepend(video);

      return video;
    }

    return undefined;
  }
}
