import { tracked } from '@glimmer/tracking';

export class ScrollHelper {
  @tracked scrollDistance: number;
  @tracked scrolledToEnd: boolean;

  private static instance?: ScrollHelper;

  private constructor() {
    this.scrollDistance = 0;
    this.scrolledToEnd = false;
  }

  public static getInstance(): ScrollHelper {
    if (!ScrollHelper.instance) {
      ScrollHelper.instance = new ScrollHelper();
    }

    return ScrollHelper.instance;
  }
}
