import { SyncHasMany, hasMany } from '@ember-data/model';
import TriggerModel from 'teamtailor/models/trigger';
import TagModel from 'teamtailor/models/tag';

export default class TriggerTagModel extends TriggerModel<'tag'> {
  @hasMany('tag', { async: false }) declare tags: SyncHasMany<TagModel>;

  get valid() {
    return !!this.tags;
  }

  get description() {
    return this.tags.mapBy('name').join(', ');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'trigger/tag': TriggerTagModel;
  }
}
