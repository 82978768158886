import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Modal @isOpen={{@isOpen}} @onClose={{@onClose}} @size=\"small\" as |modal|>\n  <modal.Header\n    @text={{t \"candidates.candidate.bulk_actions.send_nps_survey\"}}\n  />\n  <modal.Main as |main|>\n    <main.Section>\n      <p class=\"mb-0\">\n        {{t\n          \"candidates.candidate.bulk_actions.send_nps_hint\"\n          href=\"https://support.teamtailor.com/applicant-tracking/get-started-with-nps\"\n          htmlSafe=true\n        }}\n      </p>\n    </main.Section>\n  </modal.Main>\n  <modal.Footer>\n    <StatusButton\n      @action={{this.sendNps}}\n      @text={{t \"candidates.candidate.bulk_actions.send_survey\"}}\n    />\n  </modal.Footer>\n</Core::Modal>", {"contents":"<Core::Modal @isOpen={{@isOpen}} @onClose={{@onClose}} @size=\"small\" as |modal|>\n  <modal.Header\n    @text={{t \"candidates.candidate.bulk_actions.send_nps_survey\"}}\n  />\n  <modal.Main as |main|>\n    <main.Section>\n      <p class=\"mb-0\">\n        {{t\n          \"candidates.candidate.bulk_actions.send_nps_hint\"\n          href=\"https://support.teamtailor.com/applicant-tracking/get-started-with-nps\"\n          htmlSafe=true\n        }}\n      </p>\n    </main.Section>\n  </modal.Main>\n  <modal.Footer>\n    <StatusButton\n      @action={{this.sendNps}}\n      @text={{t \"candidates.candidate.bulk_actions.send_survey\"}}\n    />\n  </modal.Footer>\n</Core::Modal>","moduleName":"teamtailor/components/bulk-actions/candidates/send-nps.hbs","parseOptions":{"srcName":"teamtailor/components/bulk-actions/candidates/send-nps.hbs"}});
import BaseComponent from './base';
import { action, get } from '@ember/object';

export default class SendNpsComponent extends BaseComponent {
  @action
  sendNps() {
    return this.postAction(
      'bulk_nps',
      this.postData({
        job_id: get(this.args, 'job.id'),
      })
    ).then(() => {
      this.args.onClose();
    });
  }
}
