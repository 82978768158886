import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"h-[200px] w-full\" {{leaflet-map this.options}}></div>", {"contents":"<div class=\"h-[200px] w-full\" {{leaflet-map this.options}}></div>","moduleName":"teamtailor/components/form-location/map.hbs","parseOptions":{"srcName":"teamtailor/components/form-location/map.hbs"}});
import Component from '@glimmer/component';
import { getProperties } from '@ember/object';

export default class FormLocationMap extends Component {
  get options() {
    const { lat, long: lng } = getProperties(this.args.location, 'lat', 'long');

    return {
      mapOptions: {
        center: {
          lat,
          lng,
        },

        zoom: 13,
        zoomControl: this.args.zoomControl,
        doubleClickZoom: this.args.doubleClickZoom,
        scrollWheelZoom: this.args.scrollWheelZoom,
      },

      markerOptions: [lat, lng],
    };
  }
}
