import Component from '@glimmer/component';

export function argDefault<
  Target extends Component<Target['args']>,
  Value extends Property,
  Property extends keyof Target['args']
>(
  _: Target,
  property: Property,
  desc?: TypedPropertyDescriptor<Value> & { initializer: () => Value }
): any {
  const defaultInitializer =
    desc?.initializer || desc?.get || (() => undefined);

  return {
    get() {
      const argValue = this.args[property];
      return argValue !== undefined ? argValue : defaultInitializer();
    },
  };
}
