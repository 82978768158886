import Store from '@ember-data/store';
import { get } from 'teamtailor/utils/get';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import NewController from 'teamtailor/controllers/settings/security/sso/new';
import SingleSignOnModel from 'teamtailor/models/single-sign-on';
import Current from 'teamtailor/services/current';
import TtAlertService from 'teamtailor/services/tt-alert';

export default class SettingsSecuritySsoNew extends ScrollToTopRoute {
  @service declare store: Store;
  @service declare current: Current;
  @service declare router: RouterService;
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;

  async model(): Promise<SingleSignOnModel> {
    const model = (await get(
      this.current.company,
      'singleSignOn'
    )) as unknown as SingleSignOnModel;

    return model;
  }

  resetController(controller: NewController, isExiting: boolean): void {
    if (isExiting) {
      if (get(controller.model, 'hasDirtyAttributes')) {
        controller.model.rollbackAttributes();
      }

      controller.model.isParsing = false;
    }
  }
}
