import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import copyModel from 'teamtailor/utils/copy-model';
import InterviewKitModel from 'teamtailor/models/interview-kit';
import NewController from 'teamtailor/controllers/settings/interview-kits/new';
import { allSettled } from 'ember-concurrency';
import { AddonFeature } from 'types/features';

type Params = {
  copiedInterviewKitId: string | undefined | null;
};

export default class NewRoute extends ScrollToTopRoute {
  requiredFeature: AddonFeature = 'interview_kit';

  queryParams = {
    copiedInterviewKitId: {
      refreshModel: true,
    },
  };

  async model(params: Params): Promise<InterviewKitModel> {
    if (params.copiedInterviewKitId) {
      const interviewKit = await this.store.findRecord(
        'interview-kit',
        params.copiedInterviewKitId
      );
      await allSettled(interviewKit.questions);
      const copiedInterviewKit = copyModel({ model: interviewKit });
      copiedInterviewKit.name = `Copy of ${copiedInterviewKit.name}`;
      copiedInterviewKit.pickedQuestions = [
        ...copiedInterviewKit.pickedQuestions.sortBy('position'),
      ];
      copiedInterviewKit.scorecardPicks = [
        ...copiedInterviewKit.scorecardPicks.sortBy('position'),
      ];
      return copiedInterviewKit;
    }

    return this.store.createRecord('interview-kit');
  }

  resetController(controller: NewController, isExiting: boolean) {
    if (isExiting && controller.model.isNew) {
      controller.model.deleteRecord();
    }
  }
}
