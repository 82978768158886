import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { set, get, action } from '@ember/object';
import { later } from '@ember/runloop';
import { dasherize } from '@ember/string';
import RSVP from 'rsvp';
import { tracked } from '@glimmer/tracking';

export default class PickController extends Controller {
  queryParams = ['currency'];

  @service intl;
  @service current;
  @service router;
  @service flashMessages;
  @service store;

  get errorsToDisplay() {
    let errors = get(this, 'promotion.errors');
    return errors.filter((error) => error.attribute === 'base');
  }

  get paymentProcessorSetting() {
    return get(this.current.company, 'paymentProcessorSetting');
  }

  get vatPercentage() {
    return get(this.paymentProcessorSetting, 'vatPercentage');
  }

  get price() {
    if (this.hasBudgetPicker && this.purchase.availableBudgets) {
      return this.purchase.pickedBudget.amount;
    } else if (this.model.channelActivation?.existingAgreement) {
      return null;
    } else {
      return (
        get(this.model, 'discountedPromotionPrice') ||
        get(this.model, 'originalPromotionPrice')
      );
    }
  }

  cleanUpPickedLocations() {
    get(this, 'promotion.pickedLocations')
      .filterBy('hasDirtyAttributes')
      .forEach((pl) => pl.rollbackAttributes());
  }

  onSuccess() {
    later(
      this,
      () => {
        set(this, 'selectedBudget', null);
        set(this, 'step', 'step-1');

        if (get(this, 'promotion.isNotShareLinkOrQrCode')) {
          get(this, 'flashMessages').success(
            this.intl.t('components.promotion.created_message')
          );
          this.router.transitionTo('jobs.job.promote');
        }
      },
      500
    );
  }

  get promotionComponent() {
    const templateName = get(this.model, 'templateName');
    let componentName = dasherize(templateName);

    return `jobs/promotions/${componentName}`;
  }

  get hasBudgetPicker() {
    return get(this.model, 'templateName') === 'manual';
  }

  get processInfoRows() {
    return [
      { emoji: '👥', type: 'specialists' },
      { emoji: '😊', type: 'satisfaction' },
      { emoji: '⏰', type: 'lead_time' },
      { emoji: '🔎', type: 'platform' },
    ];
  }

  @action
  pick() {
    return this.purchase
      .pick()
      .then(() => this.onSuccess())
      .finally(() => this.cleanUpPickedLocations());
  }

  @action
  goBack() {
    set(this, 'selectedBudget', null);
    return this.router.transitionTo('jobs.job.promote');
  }

  @action loadDataBasedOnModel() {
    const { model } = this;
    switch (get(model, 'internalName')) {
      case 'ams':
        return RSVP.all([
          this.store.findAll('occupation-field'),
          this.store.findAll('municipality-code'),
          this.store.findAll('country'),
        ]);
      case 'blocket':
      case 'stepstone_sverige':
        return RSVP.all([
          this.store.findAll('blocket-category'),
          this.store.findAll('blocket-subcategory'),
          this.store.findAll('blocket-region'),
          this.store.findAll('blocket-municipality'),
        ]);
      case 'metro':
        return RSVP.all([
          this.store.findAll('metro-category'),
          this.store.findAll('metro-region'),
        ]);
      case 'monster':
      case 'monster_fi':
      case 'monster_de':
      case 'monster_fr':
      case 'monster_us':
      case 'monster_ca':
      case 'monster_ie':
      case 'monster_uk':
      case 'monster_lu':
      case 'monster_nl':
        return RSVP.all([
          this.store.query('monster-job-category', {
            channel_internal_name: get(model, 'internalName'),
          }),
          this.store.query('monster-job-industry', {
            channel_internal_name: get(model, 'internalName'),
          }),
          this.store.query('monster-job-occupation', {
            channel_internal_name: get(model, 'internalName'),
          }),
        ]);
      case 'finn':
        return RSVP.all([
          this.store.findAll('finn-occupation'),
          this.store.findAll('finn-occupation-specialization'),
          this.store.findAll('finn-industry'),
        ]);
      case 'uptrail':
        return RSVP.all([
          this.store.findAll('uptrail-category'),
          this.store.findAll('uptrail-region'),
        ]);
      case 'oikotie':
        return RSVP.all([this.store.findAll('oikotie-line-of-business')]);
      case 'reed':
        return RSVP.all([this.store.findAll('reed/parent-sector')]);
      case 'apec':
        return RSVP.all([
          this.store.findAll('promotion-categories/apec-industry'),
          this.store.findAll('promotion-categories/apec-department'),
        ]);
      case 'jobborse':
        return RSVP.all([
          this.store.findAll('promotion-categories/jobborse-region'),
          this.store.findAll('promotion-categories/jobborse-country'),
          this.store.findAll('promotion-categories/jobborse-occupation'),
        ]);
      case 'jobnet':
        return RSVP.all([
          this.store.findAll('promotion-categories/jobnet-main-profession'),
          this.store.findAll('promotion-categories/jobnet-municipality'),
        ]);
      case 'jobteaser':
        return RSVP.all([
          this.store.findAll('promotion-categories/jobteaser-education-level'),
          this.store.findAll('promotion-categories/jobteaser-work-experience'),
          this.store.findAll('promotion-categories/jobteaser-contract-type'),
          this.store.findAll('promotion-categories/jobteaser-remote-type'),
          this.store.findAll(
            'promotion-categories/jobteaser-contract-duration'
          ),
          this.store.findAll(
            'promotion-categories/jobteaser-position-category'
          ),
          this.store.findAll('promotion-categories/jobteaser-payment-type'),
        ]);
      case 'ejobs':
        return RSVP.all([
          this.store.findAll('promotion-categories/ejobs-city'),
          this.store.findAll('promotion-categories/ejobs-department'),
        ]);
      case 'cv_library':
        return RSVP.all([
          this.store.findAll('promotion-categories/cv-library-industry'),
          this.store.findAll('promotion-categories/cv-library-job-type'),
          this.store.findAll('promotion-categories/cv-library-salary-period'),
        ]);
      case 'cv_online_ee':
      case 'cv_online_lt':
      case 'cv_online_lv':
        return RSVP.all([
          this.store.findAll('promotion-categories/cv-online-work-time'),
          this.store.findAll('promotion-categories/cv-online-search-category'),
          this.store.query('promotion-categories/cv-online-town', {
            channel_internal_name: get(model, 'internalName'),
          }),
        ]);
      case 'caterer_global':
        return RSVP.all([
          this.store.findAll('promotion-categories/caterer-global-region'),
          this.store.findAll('promotion-categories/totaljobs-minmax-salary'),
        ]);
      case 'totaljobs':
      case 'caterer':
      case 'jobsite':
      case 'cwjobs':
        return RSVP.all([
          this.store.findAll('promotion-categories/totaljobs-region'),
          this.store.findAll('promotion-categories/totaljobs-minmax-salary'),
        ]);
      default:
        return undefined;
    }
  }

  @tracked isLoading = true;

  @action async onInsert() {
    this.isLoading = true;
    await this.loadDataBasedOnModel();
    this.isLoading = false;
  }
}
