import ApplicationInstance from '@ember/application/instance';
import { gql } from '@apollo/client/core';
import ReportAnalyticsRequest, {
  FetchOptions,
} from './report-analytics-request';
import {
  AnalyticsReportBuilder,
  BuildReportArgs,
} from './analytics-report-builder';

const QUERY = gql`
  query NPSPerStageTypeQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [NPS_RESPONSE]
      distinctBy: {
        fields: [JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
      companyIds: $companyIds
    ) {
      aggregated(groupBy: STAGE_TYPE) {
        stageType
        totalNpsScore: average(
          field: NPS_SCORE
          filters: { npsScore: { exists: true } }
        )
        rejectedNpsScore: average(
          field: NPS_SCORE
          filters: {
            npsScore: { exists: true }
            stageIsReject: { equals: true }
          }
        )
        nonRejectedNpsScore: average(
          field: NPS_SCORE
          filters: {
            npsScore: { exists: true }
            stageIsReject: { equals: false }
          }
        )
      }
    }
  }
`;

interface Result {
  eventQuery:
    | {
        aggregated: NpsPerStageTypeRow[];
      }
    | undefined;
}

export interface NpsPerStageTypeRow {
  stageType: string;
  totalNpsScore: number;
  rejectedNpsScore: number;
  nonRejectedNpsScore: number;
}

class NpsPerStageTypeReport {
  rows: NpsPerStageTypeRow[] | null = [];

  constructor(rows: NpsPerStageTypeRow[]) {
    this.rows = rows;
  }
}

export function buildReport(args: BuildReportArgs) {
  const { container } = args;
  return new AnalyticsReportBuilder<
    NpsPerStageTypeReport,
    NpsPerStageTypeRow[]
  >(container, {
    query: async (options: FetchOptions = {}) => {
      return fetch(container, options);
    },

    createReport: (queryResult: NpsPerStageTypeRow[]) => {
      return new NpsPerStageTypeReport(queryResult);
    },
  });
}

export async function fetch(
  container: ApplicationInstance,
  options: FetchOptions = {}
): Promise<NpsPerStageTypeRow[]> {
  return new ReportAnalyticsRequest({
    container,
    query: QUERY,
    callback: (result?: Result) => result?.eventQuery?.aggregated,
  }).fetch(options);
}
