import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  local-class=\"formPhoneInput\"\n  {{did-insert this.onDidInsertOrUpdate @number @isDisabled}}\n  {{did-update this.onDidInsertOrUpdate @number @isDisabled}}\n>\n  <label class=\"tt-form-label\">\n    <span class=\"phoneLabel\">\n      {{@label}}\n    </span>\n  </label>\n  {{#if @companyHeadquarter}}\n    <PhoneInput\n      @number={{this.phoneNumber}}\n      @separateDialCode=\"true\"\n      @initialCountry={{@companyHeadquarter}}\n      @disabled={{@isDisabled}}\n      @update={{this.handleUpdate}}\n    />\n  {{else}}\n    <PhoneInput\n      @number={{this.phoneNumber}}\n      @separateDialCode=\"true\"\n      @initialCountry={{\"se\"}}\n      @disabled={{@isDisabled}}\n      @update={{this.handleUpdate}}\n    />\n  {{/if}}\n</div>", {"contents":"<div\n  local-class=\"formPhoneInput\"\n  {{did-insert this.onDidInsertOrUpdate @number @isDisabled}}\n  {{did-update this.onDidInsertOrUpdate @number @isDisabled}}\n>\n  <label class=\"tt-form-label\">\n    <span class=\"phoneLabel\">\n      {{@label}}\n    </span>\n  </label>\n  {{#if @companyHeadquarter}}\n    <PhoneInput\n      @number={{this.phoneNumber}}\n      @separateDialCode=\"true\"\n      @initialCountry={{@companyHeadquarter}}\n      @disabled={{@isDisabled}}\n      @update={{this.handleUpdate}}\n    />\n  {{else}}\n    <PhoneInput\n      @number={{this.phoneNumber}}\n      @separateDialCode=\"true\"\n      @initialCountry={{\"se\"}}\n      @disabled={{@isDisabled}}\n      @update={{this.handleUpdate}}\n    />\n  {{/if}}\n</div>","moduleName":"teamtailor/components/form-phone-input.hbs","parseOptions":{"srcName":"teamtailor/components/form-phone-input.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

interface FormPhoneInputArgs {
  number: string;
  onUpdate: (number: string) => void;
}

export default class FormPhoneInput extends Component<FormPhoneInputArgs> {
  @tracked
  phoneNumber = '';

  @action
  onDidInsertOrUpdate(
    element: HTMLElement,
    [number, isDisabled]: [string, boolean]
  ): void {
    const input: HTMLInputElement | null = element.querySelector('.iti input');
    if (input && isEmpty(number)) {
      input.value = '';
    }

    this.phoneNumber = number;

    const flagDropdown = element.querySelector('.iti__selected-flag');

    if (flagDropdown) {
      if (isDisabled) {
        flagDropdown.classList.add('disabled-flag-dropdown');
      } else {
        flagDropdown.classList.remove('disabled-flag-dropdown');
      }
    }
  }

  @action
  handleUpdate(value: string) {
    if (this.phoneNumber !== value) {
      this.args.onUpdate(value);
    }
  }
}
