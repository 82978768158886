import { API, BlockAPI } from '@editorjs/editorjs/types';
import { createElement } from 'teamtailor/utils/wysiwyg-editor/utils';

export interface AiBlockData {
  type: 'aiBlock' | (string & Record<never, never>);
  blockIndex: number;
}

export interface Config {
  initializeEmberBlock: (
    blockId: string,
    args: { wrapper: HTMLElement; data: AiBlockData }
  ) => void;
}

interface Args {
  api: API;
  block: BlockAPI;
  data: AiBlockData;
  config: Config;
}

export default class AiBlockTool {
  api: API;
  block: BlockAPI;
  data: AiBlockData;
  config: Config;
  declare wrapper?: HTMLElement;

  constructor({ data, block, config, api }: Args) {
    this.api = api;
    this.block = block;
    this.config = config;
    this.data = {
      type: data.type,
      blockIndex: api.blocks.getCurrentBlockIndex(),
    };
  }

  render() {
    if (!this.wrapper) {
      const wrapper = createElement('div');
      this.wrapper = wrapper;
      this.config.initializeEmberBlock(this.block.id, {
        wrapper,
        data: this.data,
      });
    }

    return this.wrapper;
  }

  save() {
    return this.data;
  }
}
