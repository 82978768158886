import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex-col justify-between\">\n  <div>\n    <Core::Form::Field\n      @searchEnabled={{false}}\n      @as=\"core/select\"\n      @label={{t \"components.partner.webhooks_modal.pick_job_application\"}}\n      @optionName=\"jobTitle\"\n      @options={{@jobApplications}}\n      @selected={{find-by \"id\" this.pickedJobApplication.id @jobApplications}}\n      @onSelect={{this.handlePickJobApplication}}\n      @size=\"large\"\n    />\n  </div>\n</div>", {"contents":"<div class=\"flex-col justify-between\">\n  <div>\n    <Core::Form::Field\n      @searchEnabled={{false}}\n      @as=\"core/select\"\n      @label={{t \"components.partner.webhooks_modal.pick_job_application\"}}\n      @optionName=\"jobTitle\"\n      @options={{@jobApplications}}\n      @selected={{find-by \"id\" this.pickedJobApplication.id @jobApplications}}\n      @onSelect={{this.handlePickJobApplication}}\n      @size=\"large\"\n    />\n  </div>\n</div>","moduleName":"teamtailor/components/partner/webhook-job-context-provider.hbs","parseOptions":{"srcName":"teamtailor/components/partner/webhook-job-context-provider.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class PartnerWebhookJobContextProviderComponent extends Component {
  @tracked pickedJobApplication;

  @action handlePickJobApplication(jobApplication) {
    this.pickedJobApplication = jobApplication;
    this.args.setJobApplicationContext(this.pickedJobApplication);
  }
}
