import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class extends Controller {
  @service router;
  @service flashMessages;
  @service intl;
  @service ttAlert;

  @tracked fields = [];

  routePrefix = 'surveys.edit.';

  @action
  onUpdateField(fieldUuid, data, refreshFields) {
    let field = this.fields.findBy('uuid', fieldUuid);

    const resetField = Object.fromEntries(
      Object.keys(field).map((key) => [key, undefined])
    );

    Object.assign(field, resetField, data);

    if (refreshFields) {
      this.onUpdateFields(this.fields);
    }
  }

  @action
  onUpdateFields(fields) {
    this.fields = fields.map((field, index) => ({
      ...field,
      sort_index: index,
    }));
  }

  @action
  async archive() {
    this.ttAlert.confirm(this.intl.t('surveys.confirm_archive'), () => {
      this.toggleArchive(true);
    });
  }

  @action
  async toggleArchive(value) {
    try {
      await this.model.toggleArchive(value);
      this.model.unloadRecord();
      this.flashMessages.success(
        value
          ? this.intl.t('surveys.survey_archived')
          : this.intl.t('surveys.survey_unarchived')
      );
      this.router.transitionTo('surveys');
    } catch (err) {
      this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
      throw err;
    }
  }

  @action
  async save(successMessage) {
    this.model.formQuestionsRaw = this.fields;
    await this.model.save();
    this.flashMessages.success(successMessage);
    this.router.transitionTo('surveys.results', this.model.id);
  }

  @action
  async delete() {
    this.ttAlert.confirm(
      this.intl.t('surveys.confirm_delete', { count: this.model.responses }),
      async () => {
        try {
          await this.model.destroyRecord();
          this.flashMessages.success(this.intl.t('surveys.survey_deleted'));
          this.router.transitionTo('surveys');
        } catch (err) {
          this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
          this.model.rollbackAttributes();
          throw err;
        }
      }
    );
  }
}
