import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { set, get, action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class EditRoute extends ScrollToTopRoute {
  @service router;
  @service store;

  model(params) {
    return this.store.findRecord('canned_response', params.canned_response_id);
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    set(controller, 'isOpen', true);

    if (!model.assetStructure) {
      const options = {
        department: [],
        role: [],
        region: [],
        location: [],
      };

      this.store.createRecord('asset-structure', {
        options,
        organizable: model,
      });
    }
  }

  resetController(controller, isExiting) {
    let model = get(controller, 'model');
    if (isExiting) {
      if (!get(model, 'isDeleted')) {
        model.rollbackAttributes();
      }

      return this.store.findAll('canned-response');
    }

    return undefined;
  }

  @action
  modalClosed() {
    this.router.transitionTo('settings.canned_responses');
  }
}
