import TranslationModel from 'teamtailor/models/translation';
import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import LocationModel from 'teamtailor/models/location';

export default class TranslationLocationModel extends TranslationModel {
  @belongsTo('location') declare location: AsyncBelongsTo<LocationModel>;
  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'translation/location': TranslationLocationModel;
  }
}
