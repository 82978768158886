import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { JobModel, UserModel } from 'teamtailor/models';

export default class JobHighlightModel extends Model {
  @belongsTo('job') declare job: AsyncBelongsTo<JobModel>;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;

  @attr('string') declare message: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'job-highlight': JobHighlightModel;
  }
}
