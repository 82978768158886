import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

const partnersToRedirect = ['apply-with-linkedin', 'linkedin-rsc'];

export default class MarketplacePartnersIndexPartnerIndexRoute extends Route {
  @service router;

  afterModel(model) {
    if (partnersToRedirect.includes(model.slug)) {
      this.router.replaceWith(`marketplace.partners.index.partner.internal`);
    }

    if (model.activationType === 'customer') {
      this.router.replaceWith('marketplace.partners.index.partner.activate');
    }

    return model;
  }
}
