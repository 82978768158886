import Model, { attr } from '@ember-data/model';

export default class PageCountModel extends Model {
  @attr('number') declare total: number;
  @attr('number') declare inactive: number;
  @attr('number') declare unpublished: number;
  @attr('number') declare career: number;
  @attr('number') declare department: number;
  @attr('number') declare location: number;
  @attr('number') declare campaign: number;
  @attr('number') declare blank: number;
  @attr('number') declare template: number;
  @attr('number') declare post: number;
  @attr('number') declare connect: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'page-count': PageCountModel;
  }
}
