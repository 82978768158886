import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { isEmpty } from '@ember/utils';

export default class MetroCategory extends Model {
  @belongsTo('metro-category', { inverse: 'metroCategories' }) metroCategory;
  @hasMany('metro-category', { async: true, inverse: 'metroCategory' })
  metroCategories;

  @attr('string') name;

  get isParent() {
    return !isEmpty('metroCategories');
  }
}
