import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { inject as service } from '@ember/service';
import { underscore } from '@ember/string';
import IntlService from 'ember-intl/services/intl';
import CompanyModel from './company';
import UserModel from './user';
import SegmentModel from './segment';
import PickedDashboardWidgetModel from './picked-dashboard-widget';
import { get } from 'teamtailor/utils/get';

const USE_TITLE_TYPES = [
  'custom',
  'employee-custom',
  'segment',
  'image',
  'video',
  'employee-video',
  'employee-image',
];

type Options = {
  displayTitle?: boolean;
  displayContent?: boolean;
  displayUserInfo?: boolean;
  externalVideoId?: string | null;
  videoProvider?: string | null;
  imageUrl?: string;
};

export default class DashboardWidgetModel extends Model {
  @service declare intl: IntlService;

  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;
  @belongsTo('segment') declare segment: AsyncBelongsTo<SegmentModel>;

  @hasMany('picked-dashboard-widget', { async: true })
  declare pickedDashboardWidgets: AsyncHasMany<PickedDashboardWidgetModel>;

  @attr('string') declare color: string;
  @attr('string') declare body: string;
  @attr('number') declare defaultColumn: number;
  @attr('number') declare defaultRowOrder: number;
  @attr('string') declare emoji: string;
  @attr('string', { defaultValue: 'custom' }) declare name: string;
  @attr('boolean', { defaultValue: true }) declare showEmoji: boolean;
  @attr('string') declare target: string;
  @attr('string') declare title: string;
  @attr('raw') declare options: Options;

  get column() {
    return this.defaultColumn;
  }

  get headerClass() {
    return this;
  }

  get rowOrder() {
    return this.defaultRowOrder;
  }

  get hasPickedDashboardWidgets() {
    return (get(get(this, 'pickedDashboardWidgets'), 'length') || 0) > 0;
  }

  get hasCompanyColor() {
    return this.color === 'company';
  }

  get hasBorder() {
    return this.name === 'employee-custom';
  }

  get titleTranslated() {
    return USE_TITLE_TYPES.includes(this.name)
      ? this.title
      : this.intl.t(`dashboard.widgets.${underscore(this.name)}.title`);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'dashboard-widget': DashboardWidgetModel;
  }
}
