import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { lt, alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { get, set, action, computed } from '@ember/object';

@classic
export default class NewController extends Controller {
  @service iframePreview;
  @service router;

  @alias('model') location;

  @computed('location.pickedImages.@each.isDeleted')
  get numberOfSelectedImages() {
    return get(this, 'location.pickedImages').rejectBy('isDeleted', true)
      .length;
  }

  @lt('numberOfSelectedImages', 6)
  canAddMoreImages;

  @action
  handleDropImage(image, position) {
    set(image, 'rowOrderPosition', position);
  }

  @action
  handleRemoveImage(image) {
    image.deleteRecord();
  }

  @action
  handlePickImage(image) {
    get(this, 'location.pickedImages').createRecord({ image });
  }

  @action
  deleteUnsavedPickedImages() {
    get(this, 'location.pickedImages')
      .filterBy('hasDirtyAttributes', true)
      .forEach((pickedImage) => pickedImage.unloadRecord());
  }

  @action
  redirectToEdit() {
    this.router.transitionTo(
      'content.editor.locations.edit',
      get(this, 'location')
    );
  }
}
