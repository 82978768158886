import ApplicationInstance from '@ember/application/instance';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { gql } from '@apollo/client/core';
import { compareCalculation } from 'teamtailor/utils/compare-percentage';
import ReportAnalyticsRequest, {
  FetchOptions,
} from './report-analytics-request';
import moment from 'moment-timezone';
import Store from '@ember-data/store';
import AnalyticsService from 'teamtailor/services/analytics';
import IntlService from 'ember-intl/services/intl';
import ApolloService from 'ember-apollo-client/services/apollo';
import UserService from 'teamtailor/services/user';
import { get } from 'teamtailor/utils/get';
import JobModel from 'teamtailor/models/job';
import {
  AnalyticsReportBuilder,
  BuildReportArgs,
} from './analytics-report-builder';
import uniq from 'teamtailor/utils/uniq';

const QUERY = gql`
  query NPSOverviewQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $limit: Int
    $companyIds: [ID!]
  ) {
    eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [NPS_RESPONSE]
      distinctBy: {
        fields: [JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
      companyIds: $companyIds
    ) {
      aggregated {
        promotersCount: countOccurrences(
          filters: { npsResponseScore: { greaterThan: 8 } }
        )
        detractorsCount: countOccurrences(
          filters: { npsResponseScore: { lessThan: 7 } }
        )
        passivesCount: countOccurrences(
          filters: { npsResponseScore: { greaterThan: 6, lessThan: 9 } }
        )
      }

      responses: rows(
        limit: $limit
        orderBy: { field: TIMESTAMP, desc: true }
      ) {
        candidateId
        jobId
        timestamp: keenTimestamp
        npsResponseScore
        npsResponseFeedback
        stageIsReject
        stageName
      }
    }
  }
`;

interface Result {
  eventQuery:
    | {
        aggregated: {
          promoterCount: number;
          detractorCount: number;
          passiveCount: number;
        };
        responses: ResponseRow[];
      }
    | undefined;
}

interface ResponseRow {
  candidateId: string;
  jobId: string;
  timestamp: string;
  npsResponseScore: string;
  npsResponseFeedback: string;
  stageIsReject: boolean;
  stageName: string;
}

interface RowWithJob {
  candidateId: number;
  jobId: number;
  candidate: { id: number };
  jobTitle: string;
  timestamp: string;
  npsResponseScore: number;
  npsResponseFeedback: string;
  stageIsReject: boolean;
  stageName: string;
  deleted?: boolean;
}

export interface NpsRow extends RowWithJob {
  date: string;
  id: number;
  hasFeedback: boolean;
  displayStageName: string;
}

const fetchJobs = async (
  container: any,
  rows: ResponseRow[] | undefined
): Promise<RowWithJob[]> => {
  if (!rows) {
    return [];
  }

  const analyticsService = container.lookup('service:analytics');
  const store = container.lookup('service:store');
  const intl = container.lookup('service:intl');

  const jobIds = uniq(rows.map((row) => row.jobId));

  const jobs =
    jobIds.length > 0
      ? await store.query('job', {
          ids: jobIds,
          groupAnalytics: analyticsService.hasEnabledGroupAnalytics,
        })
      : [];

  return rows.map((row) => {
    const job = jobs.find((job: JobModel) => job.id === row.jobId);

    return {
      ...row,
      npsResponseScore: parseInt(row.npsResponseScore),
      candidateId: parseInt(row.candidateId),
      jobId: parseInt(row.jobId),
      jobTitle: job?.title || intl.t('insights.common.deleted_job'),
      candidate: {
        id: parseInt(row.candidateId),
      },
    };
  });
};

interface NpsOverviewReportArgs {
  container: ApplicationInstance;
  _rows?: RowWithJob[];
  compareModel?: NpsOverviewReport;
  npsResponseRate?: number;
  promotersCount?: number;
  detractorsCount?: number;
  passivesCount?: number;
}

class NpsOverviewReport {
  @service declare analytics: AnalyticsService;
  @service declare user: UserService;
  @service declare apollo: ApolloService;
  @service declare store: Store;
  @service declare intl: IntlService;

  _rows: RowWithJob[] = [];
  npsResponseRate = 0.0;
  promotersCount = 0;
  passivesCount = 0;
  detractorsCount = 0;
  compareModel?: NpsOverviewReport;

  constructor(args: NpsOverviewReportArgs) {
    Object.assign(this, args);
  }

  get npsResponseRatePercentage() {
    return (this.npsResponseRate * 100).toFixed(0);
  }

  get totalCount() {
    return this.promotersCount + this.passivesCount + this.detractorsCount;
  }

  get promotersCompare() {
    return compareCalculation(
      this.promotersCount,
      this.compareModel?.promotersCount
    );
  }

  get passivesCompare() {
    return compareCalculation(
      this.passivesCount,
      this.compareModel?.passivesCount
    );
  }

  get detractorsCompare() {
    return compareCalculation(
      this.detractorsCount,
      this.compareModel?.detractorsCount
    );
  }

  get score(): number | undefined {
    const total =
      this.promotersCount + this.passivesCount + this.detractorsCount;
    if (total > 0) {
      const promotersPercentage = (this.promotersCount / total) * 100;
      const detractorsPercentage = (this.detractorsCount / total) * 100;
      return promotersPercentage - detractorsPercentage;
    }

    return undefined;
  }

  get previousScore(): number | undefined {
    return this.compareModel?.score;
  }

  get scoreForCompare(): number {
    if (!this.score) {
      return 100;
    }

    return this.score + 100;
  }

  get breakdown() {
    const { intl } = this;

    const legendNameAndCount = (key: string, count: number) => {
      return {
        name: intl.t(`insights.widgets.job_nps_score.${key}`),
        value: count,
        label: intl.t(`insights.widgets.job_nps_score.${key}_with_count`, {
          count,
        }),
      };
    };

    return [
      legendNameAndCount('promoters', this.promotersCount),
      legendNameAndCount('detractors', this.detractorsCount),
      legendNameAndCount('passives', this.passivesCount),
    ];
  }

  get rows(): NpsRow[] {
    return this._rows.map((row: RowWithJob) => ({
      ...row,
      date: moment(row.timestamp).format('YYYY-MM-DD'),
      id: row.candidateId,
      hasFeedback: isPresent(row.npsResponseFeedback),
      displayStageName: row.stageIsReject
        ? this.intl.t(`insights.common.rejected_stage`, {
            stageName: row.stageName,
          })
        : row.stageName,
    }));
  }
}

interface NpsOverviewFetchOptions extends FetchOptions {
  limit?: number;
}

export function buildReport(args: BuildReportArgs) {
  const { container, options, compareOptions } = args;
  return new AnalyticsReportBuilder<NpsOverviewReport, NpsOverviewReportArgs>(
    container,
    {
      query: async (options: NpsOverviewFetchOptions = {}) => {
        let compareModel: NpsOverviewReport | undefined,
          npsResponseRate: number | undefined;

        if (!options.dateRange) {
          const apolloService = container.lookup('service:apollo');
          const userService = container.lookup('service:user');

          const apolloResponse = await apolloService.query({
            query: gql`
              query NPSResponseRateQuery($userId: ID!, $jobIds: [ID!]) {
                npsResponseRate(jobIds: $jobIds, userScope: { userId: $userId })
              }
            `,
            variables: {
              jobIds: options.jobIds,
              userId: get(userService, 'id'),
            },
          });

          npsResponseRate = apolloResponse?.npsResponseRate;
        }

        const results = await new ReportAnalyticsRequest({
          container,
          query: QUERY,
          extraVariables: {
            limit: options.limit,
          },

          callback: (result?: Result) => result?.eventQuery,
        }).fetch(options);

        if (!results) {
          return { container };
        }

        const { responses, aggregated } = results;

        const _rows = await fetchJobs(container, responses);

        const { promotersCount, detractorsCount, passivesCount } =
          aggregated.firstObject || {};

        return {
          container,
          _rows,
          compareModel,
          npsResponseRate,
          promotersCount,
          detractorsCount,
          passivesCount,
        };
      },

      queryOptions: options,
      compareOptions,

      createReport: (
        queryResult: NpsOverviewReportArgs,
        extraProps: Record<string, any> = {}
      ) => {
        let props: NpsOverviewReportArgs = {
          ...queryResult,
          ...extraProps,
        };

        return new NpsOverviewReport(props);
      },
    }
  );
}
