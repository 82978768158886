import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Icon @style=\"kit\" @icon={{this.providerIcon}} @size={{@size}} ...attributes />", {"contents":"<Icon @style=\"kit\" @icon={{this.providerIcon}} @size={{@size}} ...attributes />","moduleName":"teamtailor/components/meeting/video-meeting-icon.hbs","parseOptions":{"srcName":"teamtailor/components/meeting/video-meeting-icon.hbs"}});
import Component from '@glimmer/component';

interface Args {
  providerName: string;
}

export default class VideoMeetingIcon extends Component<Args> {
  get providerIcon(): string {
    switch (this.args.providerName) {
      case 'Google Meet':
        return 'google-meet';
      case 'ms_teams':
        return 'ms-teams';
      case 'zoom':
        return 'zoom';
      case 'go_to':
        return 'go-to';
      case 'teamtailor':
      default:
        return 'teamtailor';
    }
  }
}
