export default {
  "bottomDots": "HiredApplications__bottomDots_xwncaw",
  "dot": "HiredApplications__dot_xwncaw",
  "dot--active": "HiredApplications__dot--active_xwncaw",
  "summary": "HiredApplications__summary_xwncaw",
  "summaryAvatars": "HiredApplications__summaryAvatars_xwncaw",
  "summaryExtras": "HiredApplications__summaryExtras_xwncaw",
  "summaryDetailTitle": "HiredApplications__summaryDetailTitle_xwncaw",
  "summaryDetailValue": "HiredApplications__summaryDetailValue_xwncaw"
};
