import Route from '@ember/routing/route';
import { set, get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';

export default class CustomFormRoute extends Route {
  @service current;

  afterModel() {
    return get(this.current.company, 'channelActivations');
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    let channelActivation = get(model, 'channelActivation');
    if (!channelActivation) {
      if (isEmpty(channelActivation)) {
        channelActivation = get(
          this.current.company,
          'channelActivations'
        ).createRecord({ channel: model });
      }
    }

    set(controller, 'channelActivation', channelActivation);

    set(controller, 'parentModel', this.modelFor('parent'));
  }

  deactivate() {
    const controller = this.controllerFor(this.routeName);
    if (controller.channelActivation.isNew) {
      controller.channelActivation.unloadRecord();
    }
  }
}
