import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import downloadCustomReport from 'teamtailor/utils/download-custom-report';
import IntlService from 'ember-intl/services/intl';
import { jsonToBase64 } from 'teamtailor/utils/base-64';
import AnalyticsService from 'teamtailor/services/analytics';
import { modifier } from 'ember-modifier';

export default class AnalyticsCustomReportsSnapshotsController extends Controller {
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare analytics: AnalyticsService;

  get request() {
    return this.model.request;
  }

  get schema() {
    return this.model.schema;
  }

  get customReport() {
    return this.model.customReport;
  }

  queryParams = ['full', 'template'];

  @tracked full = false;
  @tracked fullReportPending = false;
  @tracked template: string | null = null;
  @tracked rows: any[] = [];

  get editing() {
    return !this.full;
  }

  set editing(value: boolean) {
    this.full = !value;
  }

  get hasSelectedProperties(): boolean {
    return this.request.properties.length > 0;
  }

  get serializedTemplate() {
    return jsonToBase64({
      ...this.request.serialize,
      rollups: this.customReport.rollups,
    });
  }

  @action
  download() {
    downloadCustomReport(this.rows, this.request.properties, this.intl);
  }

  @action
  onGenerateFull() {
    this.fullReportPending = true;
    this.full = true;
  }

  @action
  onUpdatePropertyRollup(property: string, value: number) {
    this.customReport.rollups = {
      ...this.customReport.rollups,
      [property]: value,
    };
  }

  @action
  async onSave() {
    await this.customReport.save();

    this.router.replaceWith(
      'analytics.custom-reports.snapshots.edit',
      this.customReport.id,
      {
        queryParams: {
          full: true,
        },
      }
    );
  }

  @action
  toggleEdit() {
    this.editing = !this.editing;
  }

  handleRequestChange = modifier((_element, [serializedTemplate]: [string]) => {
    this.template = serializedTemplate;
  });
}

declare module '@ember/controller' {
  interface Registry {
    'analytics.custom-reports.snapshots.new': AnalyticsCustomReportsSnapshotsController;
  }
}
