import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';
import { formFieldsToQuestions } from 'teamtailor/utils/form-builder';

const DENY_ATTRIBUTES = [
  'form_questions_raw',
  'responses',
  'sends',
  'results_id',
  'created_at',
  'uploads',
];

export default class Form extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin,
  {
    attrs: {
      uploads: { serialize: 'records', key: 'uploads_attributes' },
    },
  }
) {
  serialize() {
    let json = { ...super.serialize(...arguments) };
    const { form_questions_raw: fields } = json;

    const form_questions_attributes = formFieldsToQuestions(fields);

    return {
      ...Object.fromEntries(
        Object.entries(json).filter(([key]) => !DENY_ATTRIBUTES.includes(key))
      ),

      form_questions_attributes,
    };
  }
}
