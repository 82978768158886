import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get } from 'teamtailor/utils/get';
import { questionsToFormFields } from 'teamtailor/utils/form-builder';
import { inject as service } from '@ember/service';

export default class EditJobOfferTemplateRoute extends ScrollToTopRoute {
  @service store;

  model(params) {
    return this.store.findRecord(
      'job-offer-template',
      params.job_offer_template_id
    );
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    const fields = questionsToFormFields(
      model.sortedFormQuestions.filter((q) => !q.isNew)
    );

    controller.fields = fields;
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.fields = [];
      const model = get(this, 'controller.model');

      if (!model.isDestroying && !model.isDestroyed && !model.isDeleted) {
        model.rollbackAttributes();
      }
    }
  }
}
