import { get } from 'teamtailor/utils/get';
import EmberDragSortItemComponent from 'ember-drag-sort/components/drag-sort-item';

export default EmberDragSortItemComponent.extend({
  dragStart(event) {
    // Ignore irrelevant drags
    if (!get(this, 'draggingEnabled')) return;

    if (!this.isHandleUsed(event.target)) {
      event.preventDefault();
      return;
    }

    event.stopPropagation();

    // Required for Firefox. http://stackoverflow.com/a/32592759/901944
    if (event.dataTransfer) {
      if (event.dataTransfer.setData) {
        event.dataTransfer.setData('text', 'anything');
      }

      if (event.dataTransfer.setDragImage) {
        let { x, y } = this.element.getBoundingClientRect();
        event.dataTransfer.setDragImage(
          this.element,
          event.clientX - x,
          event.clientY - y
        );
      }
    }

    this.startDragging(event);
  },
});
