import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{concat \" · \" this.formattedDate}}", {"contents":"{{concat \" · \" this.formattedDate}}","moduleName":"teamtailor/components/candidate-modal-new/right-sidebar/activity/meeting-events/meeting-date/index.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-modal-new/right-sidebar/activity/meeting-events/meeting-date/index.hbs"}});
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import { ActivityModel } from 'teamtailor/models';
import TimeFormatService from 'teamtailor/services/time-format';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

interface Args {
  activity: ActivityModel;
}

export default class extends Component<Args> {
  @service declare timeFormat: TimeFormatService;

  get tzid() {
    return get(this.activity.actionData, 'meeting_event_tzid') as string;
  }

  get formattedDate() {
    const time = get(
      this.activity.actionData,
      'meeting_event_starts_at'
    ) as string;

    if (!this.tzid) {
      // for older actions without tzid

      return moment
        .parseZone(time)
        .format(`DD MMM YYYY ${this.timeFormat.format}`);
    }

    return moment(time)
      .tz(this.tzid)
      .format(`DD MMM YYYY · ${this.timeFormat.format} (z)`);
  }

  get activity() {
    return this.args.activity;
  }
}
