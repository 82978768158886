import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.activatedSms}}\n  <ButtonGroup>\n    <ButtonGroup::Button\n      @icon=\"envelope\"\n      @ariaLabel={{t \"components.candidate_messages.email\"}}\n      @tooltip={{t \"components.candidate_messages.email\"}}\n      @isActive={{eq @messageMode \"email\"}}\n      @onClick={{fn @onChangeMessageMode \"email\"}}\n    />\n    <ButtonGroup::Button\n      @isDisabled={{not this.candidateHasValidPhoneNumber}}\n      @tooltipOutsideButton={{true}}\n      @icon=\"mobile\"\n      @tooltip={{t this.smsTooltip}}\n      @ariaLabel={{t \"components.candidate_messages.sms\"}}\n      @isActive={{eq @messageMode \"sms\"}}\n      @onClick={{fn @onChangeMessageMode \"sms\"}}\n      data-test-toggle-sms\n    />\n  </ButtonGroup>\n{{/if}}", {"contents":"{{#if this.activatedSms}}\n  <ButtonGroup>\n    <ButtonGroup::Button\n      @icon=\"envelope\"\n      @ariaLabel={{t \"components.candidate_messages.email\"}}\n      @tooltip={{t \"components.candidate_messages.email\"}}\n      @isActive={{eq @messageMode \"email\"}}\n      @onClick={{fn @onChangeMessageMode \"email\"}}\n    />\n    <ButtonGroup::Button\n      @isDisabled={{not this.candidateHasValidPhoneNumber}}\n      @tooltipOutsideButton={{true}}\n      @icon=\"mobile\"\n      @tooltip={{t this.smsTooltip}}\n      @ariaLabel={{t \"components.candidate_messages.sms\"}}\n      @isActive={{eq @messageMode \"sms\"}}\n      @onClick={{fn @onChangeMessageMode \"sms\"}}\n      data-test-toggle-sms\n    />\n  </ButtonGroup>\n{{/if}}","moduleName":"teamtailor/components/candidate-messages/composer/message-mode-switch.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-messages/composer/message-mode-switch.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { MessageMode } from 'teamtailor/components/candidate-messages/composer';
import { CandidateModel } from 'teamtailor/models';

interface ComposerMessageModeSwitchSignature {
  Args: {
    candidate: CandidateModel;
    messageMode: MessageMode;
    onChangeMessageMode: (messageMode: MessageMode) => void;
  };
}

export default class ComposerMessageModeSwitch extends Component<ComposerMessageModeSwitchSignature> {
  @service declare current: Current;

  get activatedSms() {
    return !!this.current.company.belongsTo('phoneNumber').id();
  }

  get candidateHasValidPhoneNumber() {
    return this.args.candidate.isTwilioNumberValid;
  }

  get smsTooltip() {
    if (!this.args.candidate.phone) {
      return 'components.candidate_messages.no_number';
    } else if (!this.candidateHasValidPhoneNumber) {
      return 'components.candidate_messages.number_not_valid';
    }

    return 'components.candidate_messages.sms';
  }
}
