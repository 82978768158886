import Model, {
  attr,
  belongsTo,
  hasMany,
  type AsyncBelongsTo,
  type AsyncHasMany,
} from '@ember-data/model';
import { CompanyModel, CreditTransactionModel } from 'teamtailor/models';
import Raw from 'teamtailor/transforms/raw';

export default class WalletModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @hasMany('credit-transaction')
  declare creditTransactions: AsyncHasMany<CreditTransactionModel>;

  @attr('number') declare balance: number;
  @attr('raw') declare packages: Raw;
  @attr('raw') declare creditsRenewalThresholdOptions: Raw;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    wallet: WalletModel;
  }
}
