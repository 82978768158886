import Controller from '@ember/controller';
import { action, get } from '@ember/object';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default class extends Controller {
  @service router;

  @alias('model') cookieDetail;

  @action
  async save(cookie) {
    await cookie.save();

    if (get(cookie, 'isValid')) {
      this.router.transitionTo('settings.cookies');
    }
  }

  @action
  close() {
    this.router.transitionTo('settings.cookies');
  }
}
