import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<p>\n  {{t\n    \"components.trigger_hire_quality_survey_form.header_text_with_suffix\"\n    htmlSafe=true\n  }}\n</p>\n\n<div class=\"flex flex-row gap-16\">\n  <Core::Input\n    class=\"w-56\"\n    @type=\"number\"\n    @value={{this.evaluationPeriod}}\n    @onChange={{this.changeEvaluationPeriod}}\n  />\n  <Core::ToggleSwitch\n    @onChange={{this.changeEvaluationPeriodUnit}}\n    @value={{this.evaluationPeriodUnit}}\n    as |switch|\n  >\n    <switch.button\n      @text={{t \"components.trigger_hire_quality_survey_form.weeks\"}}\n      @value={{\"weeks\"}}\n    />\n    <switch.button\n      @text={{t \"components.trigger_hire_quality_survey_form.months\"}}\n      @value={{\"months\"}}\n    />\n  </Core::ToggleSwitch>\n</div>\n<TriggerAdvancedOptions @model={{@model}} />", {"contents":"<p>\n  {{t\n    \"components.trigger_hire_quality_survey_form.header_text_with_suffix\"\n    htmlSafe=true\n  }}\n</p>\n\n<div class=\"flex flex-row gap-16\">\n  <Core::Input\n    class=\"w-56\"\n    @type=\"number\"\n    @value={{this.evaluationPeriod}}\n    @onChange={{this.changeEvaluationPeriod}}\n  />\n  <Core::ToggleSwitch\n    @onChange={{this.changeEvaluationPeriodUnit}}\n    @value={{this.evaluationPeriodUnit}}\n    as |switch|\n  >\n    <switch.button\n      @text={{t \"components.trigger_hire_quality_survey_form.weeks\"}}\n      @value={{\"weeks\"}}\n    />\n    <switch.button\n      @text={{t \"components.trigger_hire_quality_survey_form.months\"}}\n      @value={{\"months\"}}\n    />\n  </Core::ToggleSwitch>\n</div>\n<TriggerAdvancedOptions @model={{@model}} />","moduleName":"teamtailor/components/trigger-hire-quality-survey-form.hbs","parseOptions":{"srcName":"teamtailor/components/trigger-hire-quality-survey-form.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import TriggerHireQualitySurveyModel from 'teamtailor/models/trigger/hire-quality-survey';

interface Args {
  model: TriggerHireQualitySurveyModel;
}

export default class TriggerHireQualitySurveyForm extends Component<Args> {
  @service declare intl: IntlService;

  @tracked evaluationPeriod = this.trigger.convertedEvaluationPeriod;
  @tracked evaluationPeriodUnit = this.trigger.convertedEvaluationPeriodUnit;

  get trigger(): TriggerHireQualitySurveyModel {
    return this.args.model;
  }

  @action
  changeEvaluationPeriod(): void {
    const value = this.evaluationPeriod;
    this.trigger.evaluationPeriod =
      this.evaluationPeriodUnit === 'weeks' ? value * 7 : value;
  }

  @action
  changeEvaluationPeriodUnit(option: string): void {
    this.evaluationPeriodUnit = option;

    if (this.trigger.evaluationPeriodUnit === option) {
      return;
    }

    const value = this.trigger.evaluationPeriod;
    this.trigger.evaluationPeriod = option === 'weeks' ? value * 7 : value / 7;
    this.trigger.evaluationPeriodUnit = option === 'weeks' ? 'days' : option;
  }
}
