import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<p class=\"mb-0\">\n  {{t\n    \"components.trigger_tag_form.header_text_with_suffix\"\n    headerText=this.headerText\n    htmlSafe=true\n  }}\n</p>\n\n<Core::TagPicker\n  @taggableType=\"Candidate\"\n  @selected={{@model.tags}}\n  @onSelect={{this.changeTags}}\n  @size=\"large\"\n  @tagLimit={{3}}\n/>\n\n<TriggerAdvancedOptions @model={{@model}} />", {"contents":"<p class=\"mb-0\">\n  {{t\n    \"components.trigger_tag_form.header_text_with_suffix\"\n    headerText=this.headerText\n    htmlSafe=true\n  }}\n</p>\n\n<Core::TagPicker\n  @taggableType=\"Candidate\"\n  @selected={{@model.tags}}\n  @onSelect={{this.changeTags}}\n  @size=\"large\"\n  @tagLimit={{3}}\n/>\n\n<TriggerAdvancedOptions @model={{@model}} />","moduleName":"teamtailor/components/trigger-tag-form.hbs","parseOptions":{"srcName":"teamtailor/components/trigger-tag-form.hbs"}});
import TriggerFormBaseComponent from 'teamtailor/components/trigger-form-base';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import { get } from 'teamtailor/utils/get';
import { action } from '@ember/object';

export default class TriggerTagFormComponent extends TriggerFormBaseComponent {
  @action
  changeTags(tag) {
    toggleInList(get(this.model, 'tags'), tag);
  }
}
