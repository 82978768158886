import Store from '@ember-data/store';
import { ModelKey } from 'teamtailor/models';
import SharedReportStoreService from 'teamtailor/services/shared-report-store';

function idsInBatches(modelIds: string[], batchSize: number) {
  const numberOfBatches = Math.ceil(modelIds.length / batchSize);

  return Array.from({ length: numberOfBatches }).map((_, batchIndex) => {
    const start = batchIndex * batchSize;
    const end = start + batchSize;

    return modelIds.slice(start, end);
  });
}

type PreloadMissingModelsOptions = {
  groupAnalytics?: boolean;
  batchSize?: number;
};

const preloadMissingModels = async (
  model: ModelKey,
  ids: string[],
  store: Store | SharedReportStoreService,
  options: PreloadMissingModelsOptions = {}
) => {
  if (!ids.length) {
    return [];
  }

  const { groupAnalytics = false, batchSize = 100 } = options;

  const loadedModels = store
    .peekAll(model)
    .filter((loadedModel) => ids.includes(loadedModel.id))
    .map((loadedModel) => loadedModel.id)
    .flat();

  const missingIds = ids.filter((id) => !loadedModels.includes(id));

  const batchLoadPromises = idsInBatches(missingIds, batchSize).map(
    (idsInBatch) => {
      return store.query(model, {
        ids: idsInBatch,
        groupAnalytics: groupAnalytics || undefined,
      });
    }
  );

  const missingModelsBatches = await Promise.all(batchLoadPromises);

  return missingModelsBatches.flat();
};

export default preloadMissingModels;
