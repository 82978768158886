import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import Current from 'teamtailor/services/current';
import WrapUpController from 'teamtailor/controllers/wrap-up';
import Store from '@ember-data/store';
import {
  buildReport as buildAudienceVisitorsReport,
  AudienceVisitorsReport,
} from 'teamtailor/classes/analytics/report-audience-visitors';
import { getOwner } from '@ember/application';
import {
  HiredCandidate,
  HIRED_CANDIDATES_QUERY,
  ReturnModel,
  WrapUpRecord,
  WRAP_UP_QUERY,
} from 'teamtailor/constants/wrap-up';
import FlipperService from 'teamtailor/services/flipper';
import { get } from 'teamtailor/utils/get';
import Sentry from '@sentry/ember';

export default class WrapUp extends Route {
  @service declare apollo: TeamtailorApolloService;
  @service declare current: Current;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare flipper: FlipperService;

  container = getOwner(this);

  beforeModel() {
    const hasAccess =
      this.current.user.admin && !!this.current.company.churnedAt;
    if (!get(this.flipper, 'wrap_up') || !hasAccess) {
      this.router.transitionTo('dashboard');
    }
  }

  async model(): Promise<ReturnModel> {
    const wrapUpResult: Record<'wrapUp', WrapUpRecord> =
      await this.apollo.query({
        query: WRAP_UP_QUERY,
      });

    let audienceVisitors: AudienceVisitorsReport | null = null;

    try {
      audienceVisitors = await buildAudienceVisitorsReport(
        this.container
      ).build.perform();
    } catch (error) {
      Sentry.captureException(error);
    }

    const hiredCandidatesResult: Record<'candidates', HiredCandidate[]> =
      await this.apollo.query({
        query: HIRED_CANDIDATES_QUERY,
        variables: {
          filter: {
            hired: 'yes',
            perPage: 100,
          },
        },
      });

    const features = await this.store.findAll('feature');

    return {
      ...wrapUpResult.wrapUp,
      pageVisitsCount: audienceVisitors?.totalVisits || 0,
      hiredCandidates: hiredCandidatesResult.candidates,
      features: features
        .filter((f) => f.isEnabledByCompany)
        .map((f) => {
          return {
            id: f.id,
            icon: f.icon,
            name: f.name,
            category: f.category,
            iconPrefix: f.iconPrefix,
          };
        }),
    };
  }

  resetController(controller: WrapUpController) {
    controller.fadeOut = false;
  }
}
