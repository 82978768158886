import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';

export default class NurtureController extends Controller {
  @service intercom;
  @service ttAlert;
  @service intl;
  @service current;

  get nurtureCampaigns() {
    return this.model;
  }

  get activeNurtureCampaigns() {
    return this.nurtureCampaigns.filterBy('isActive', true);
  }

  get hasActiveNurtureCampaigns() {
    return this.activeNurtureCampaigns.length > 0;
  }

  get visibleNurtureCampaigns() {
    return this.nurtureCampaigns.filterBy('isPausedOrActive', true);
  }

  save() {
    return this.current.company.save();
  }

  @action
  toggleNurture() {
    if (
      get(this, 'hasActiveNurtureCampaigns') &&
      this.current.company.nurtureEnabled
    ) {
      get(this, 'ttAlert').customConfirm({
        title: this.intl.t('settings.nurture.disable_nurture'),
        text: this.intl.t('settings.nurture.disable_nurture_confirmation'),

        confirmButtonText: this.intl.t(
          'settings.nurture.disable_nurture_confirm'
        ),

        cancelButtonText: this.intl.t('common.cancel'),
        confirmCallback: () => {
          this.current.company.nurtureEnabled = false;
          this.save().then(() => {
            get(this, 'activeNurtureCampaigns').map((nurtureCampaign) =>
              nurtureCampaign.pause()
            );
          });
        },

        cancelCallback: () => {
          this.current.company.nurtureEnabled = true;
        },
      });
      return undefined;
    } else {
      this.current.company.nurtureEnabled =
        !this.current.company.nurtureEnabled;
      return this.save().then(() => {
        if (this.current.company.nurtureEnabled) {
          get(this, 'intercom').trackEvent('enabled-nurture');
        }
      });
    }
  }
}
