import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import RejectSettings from 'teamtailor/services/reject-settings';
import IntlService from 'ember-intl/services/intl';
import {
  CannedResponseModel,
  RejectReasonModel,
  StageModel,
} from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';

const transformKey = function (key: string) {
  return key.toLowerCase().replace(' ', '_');
};

const DAY_IN_SECONDS = 86400;

export default class QuickRejectSettingModel extends Model {
  @service declare rejectSettings: RejectSettings;
  @service declare intl: IntlService;

  @belongsTo('stage') declare stage: AsyncBelongsTo<StageModel>;
  @belongsTo('reject-reason')
  declare rejectReason: AsyncBelongsTo<RejectReasonModel>;

  @belongsTo('canned-response')
  declare cannedResponse: AsyncBelongsTo<CannedResponseModel>;

  @attr('boolean', { defaultValue: true }) declare sendMessage: boolean;
  @attr('number') declare messageDelay: number;
  @attr('string') declare body: string;
  @attr('string') declare subject: string;

  get delayText() {
    return this.intl.t(this.delayTranslationKey, { days: this.delayInDays });
  }

  get delayTranslationKey() {
    const found = get(this.rejectSettings, 'defaultDelayOptions').find(
      (option) => {
        return option.value === this.messageDelay;
      }
    );

    const key = found ? transformKey(found.text) : 'custom_days';
    return `components.reject_settings_form.${key}`;
  }

  get delayInDays() {
    return typeof this.messageDelay === 'string' ||
      Number.isNaN(this.messageDelay)
      ? 1
      : Math.max(Math.floor(this.messageDelay / DAY_IN_SECONDS), 1);
  }

  get defaultDelayOptions() {
    return this.rejectSettings.defaultDelayOptions;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'quick-reject-setting': QuickRejectSettingModel;
  }
}
