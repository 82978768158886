/* eslint-disable ember/no-new-mixins */
import AdapterError from '@ember-data/adapter/error';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { next } from '@ember/runloop';
import { get, set } from '@ember/object';
import ENV from 'teamtailor/config/environment';
import { ServerError } from 'teamtailor/services/server';

export const UnauthorizedError = AdapterError.extend({
  message: 'UnauthorizedError',
});

export const NoAccessError = AdapterError.extend({
  message: 'NoAccessError',
});

export default Mixin.create({
  appRefresher: service(),
  flashMessages: service(),
  session: service(),

  handleResponse(status, headers, body) {
    if (status === 401) {
      if (body && body.message && body.message !== 'Not authorized') {
        const { loginUrl } = body;
        get(this, 'session')
          .invalidate()
          .then(() => {
            if (loginUrl) {
              window.location = `${loginUrl}?return_to=${window.location}`;
            }
          });
        return new UnauthorizedError();
      }

      return new ServerError(body, status);
    }

    if (status === 403) {
      if (get(this, 'session.data.authenticated.companies.length') > 1) {
        window.location = `https://app.${ENV.HTTP_HOST}/companies`;
      }

      return new NoAccessError();
    }

    let emberVersion = headers['x-ember-version'];
    let flashMessages = headers['x-flash-messages'];

    if (!isEmpty(emberVersion)) {
      set(this, 'appRefresher.deployedAppVersion', emberVersion);
    }

    if (!isEmpty(flashMessages)) {
      let messages = JSON.parse(flashMessages);
      Object.keys(messages).forEach((key) => {
        next(() => {
          get(this, 'flashMessages')[key](messages[key]);
        });
      });
    }

    return this._super(...arguments);
  },
});
