import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<footer\n  ...attributes\n  class=\"flex flex-[0_0_64px] items-center justify-end gap-16 border-t border-t-neutral bg-canvas px-24\n    {{if (and @isLoading this.showOnLoad) 'h-0 basis-0 overflow-hidden'}}\"\n>\n  {{#if (or (has-block \"left\") (has-block \"right\"))}}\n    {{#if (has-block \"left\")}}\n      <div class=\"mr-auto flex items-center gap-16\">\n        {{yield to=\"left\"}}\n      </div>\n    {{/if}}\n    {{#if (has-block \"right\")}}\n      <div class=\"ml-auto flex items-center gap-16\">\n        {{yield to=\"right\"}}\n      </div>\n    {{/if}}\n  {{else}}\n    {{yield}}\n  {{/if}}\n</footer>", {"contents":"<footer\n  ...attributes\n  class=\"flex flex-[0_0_64px] items-center justify-end gap-16 border-t border-t-neutral bg-canvas px-24\n    {{if (and @isLoading this.showOnLoad) 'h-0 basis-0 overflow-hidden'}}\"\n>\n  {{#if (or (has-block \"left\") (has-block \"right\"))}}\n    {{#if (has-block \"left\")}}\n      <div class=\"mr-auto flex items-center gap-16\">\n        {{yield to=\"left\"}}\n      </div>\n    {{/if}}\n    {{#if (has-block \"right\")}}\n      <div class=\"ml-auto flex items-center gap-16\">\n        {{yield to=\"right\"}}\n      </div>\n    {{/if}}\n  {{else}}\n    {{yield}}\n  {{/if}}\n</footer>","moduleName":"teamtailor/components/core/modal/footer.hbs","parseOptions":{"srcName":"teamtailor/components/core/modal/footer.hbs"}});
import Component from '@glimmer/component';
import { argDefault } from 'teamtailor/utils/arg-default';

interface CoreModalFooterArgs {
  isLoading?: boolean;
  showOnLoad?: boolean;
}

export default class CoreModalFooter extends Component<CoreModalFooterArgs> {
  @argDefault showOnLoad = true;
}
