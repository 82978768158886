import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"common.remote_status\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @options={{this.options}}\n    @isDisabled={{@isDisabled}}\n    @onSelect={{@onChange}}\n    @searchEnabled={{false}}\n    @selected={{@selected}}\n    @text={{if\n      @selected\n      (t (concat \"jobs.job.remote_status.options.\" @selected))\n      (t \"search.all\")\n    }}\n    @nullOption={{t \"search.all\"}}\n    as |Item option|\n  >\n    <Item @text={{t (concat \"jobs.job.remote_status.options.\" option)}} />\n  </Core::Select>\n</label>", {"contents":"<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"common.remote_status\"}}\n  </span>\n  <Core::Select\n    @isFullWidth={{true}}\n    @options={{this.options}}\n    @isDisabled={{@isDisabled}}\n    @onSelect={{@onChange}}\n    @searchEnabled={{false}}\n    @selected={{@selected}}\n    @text={{if\n      @selected\n      (t (concat \"jobs.job.remote_status.options.\" @selected))\n      (t \"search.all\")\n    }}\n    @nullOption={{t \"search.all\"}}\n    as |Item option|\n  >\n    <Item @text={{t (concat \"jobs.job.remote_status.options.\" option)}} />\n  </Core::Select>\n</label>","moduleName":"teamtailor/components/sidebar/filters/remote.hbs","parseOptions":{"srcName":"teamtailor/components/sidebar/filters/remote.hbs"}});
import Component from '@glimmer/component';

interface SidebarFiltersRemoteComponentArgs {
  extraStatuses?: string[];
}

export default class SidebarFiltersRemoteComponent extends Component<SidebarFiltersRemoteComponentArgs> {
  get options() {
    return [...(this.args.extraStatuses || [])];
  }
}
