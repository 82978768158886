import { attr } from '@ember-data/model';
import TriggerModel from 'teamtailor/models/trigger';

export default class TriggerNpsModel extends TriggerModel<'nps'> {
  @attr('number', { defaultValue: 120 })
  declare delayJobFor: number;

  get valid() {
    return true;
  }

  get description() {
    return this.intl.t('models.trigger.nps.to_candidate');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'trigger/nps': TriggerNpsModel;
  }
}
