import { tracked } from '@glimmer/tracking';
import { IJob } from 'teamtailor/components/meeting/types';

export class JobClass {
  @tracked declare job?: IJob;

  constructor(job?: IJob) {
    this.job = job;
  }

  static from(job: IJob): JobClass {
    return new JobClass(job);
  }
}
