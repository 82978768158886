import ChannelModel from 'teamtailor/models/channel';
import uniq from './uniq';

export const DEFAULT_COUNTRY = 'All countries';
export const DEFAULT_CATEGORY = 'All categories';

export const getAvailableCountries = (
  channels: ChannelModel[],
  jobCountry?: string
) => {
  const countries = uniq(
    channels.mapBy('countries').flat().addObject(jobCountry).filter(Boolean)
  )
    .compact()
    .sort()
    .removeObject('Global') as string[];

  countries.unshift(DEFAULT_COUNTRY);
  return countries.map((country) => ({ id: country, label: country }));
};
