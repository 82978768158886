import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import { get } from 'teamtailor/utils/get';
import { NotificationConfigModel, UserModel } from 'teamtailor/models';

export default class NotificationSettingModel extends Model {
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;
  @belongsTo('notification-config')
  declare notificationConfig: AsyncBelongsTo<NotificationConfigModel>;

  @attr('boolean') declare browser: boolean;
  @attr('boolean') declare email: boolean;
  @attr('boolean') declare mobile: boolean;
  @attr('boolean') declare slack: boolean;

  get notificationConfigCategory() {
    return get(this.notificationConfig, 'category');
  }

  get sortColumn() {
    return get(this.notificationConfig, 'key');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'notification-setting': NotificationSettingModel;
  }
}
