import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Molecules::Widget\n  ...attributes\n  @title={{t \"insights.widgets.nps_gauge.title\"}}\n  @loading={{@loading}}\n  @empty={{@isEmpty}}\n  @emptyEmoji=\"🏝️\"\n  @disableHover={{true}}\n  @showMoreLink={{@showMoreLink}}\n>\n  <:content>\n    <div class=\"mb-0 flex flex-1 flex-col items-center justify-center\">\n      <Insights::Charts::NpsGauge @data={{this.roundedScore}} />\n      {{#unless (eq @previousScore undefined)}}\n        <Insights::Atoms::Compare\n          @currentValue={{this.roundedScore}}\n          @previousValue={{this.roundedPreviousScore}}\n          class=\"body-text-xs relative bottom-48 flex items-center\"\n        />\n      {{/unless}}\n    </div>\n  </:content>\n</Insights::Molecules::Widget>", {"contents":"<Insights::Molecules::Widget\n  ...attributes\n  @title={{t \"insights.widgets.nps_gauge.title\"}}\n  @loading={{@loading}}\n  @empty={{@isEmpty}}\n  @emptyEmoji=\"🏝️\"\n  @disableHover={{true}}\n  @showMoreLink={{@showMoreLink}}\n>\n  <:content>\n    <div class=\"mb-0 flex flex-1 flex-col items-center justify-center\">\n      <Insights::Charts::NpsGauge @data={{this.roundedScore}} />\n      {{#unless (eq @previousScore undefined)}}\n        <Insights::Atoms::Compare\n          @currentValue={{this.roundedScore}}\n          @previousValue={{this.roundedPreviousScore}}\n          class=\"body-text-xs relative bottom-48 flex items-center\"\n        />\n      {{/unless}}\n    </div>\n  </:content>\n</Insights::Molecules::Widget>","moduleName":"teamtailor/components/insights/widgets/nps-gauge.hbs","parseOptions":{"srcName":"teamtailor/components/insights/widgets/nps-gauge.hbs"}});
import Component from '@glimmer/component';

type Args = {
  score: number;
  previousScore?: number;
};

export default class InsightsWidgetsNpsGauge extends Component<Args> {
  get roundedScore() {
    return Math.round(this.args.score);
  }

  get roundedPreviousScore() {
    if (!this.args.previousScore) return 0;

    return Math.round(this.args.previousScore);
  }
}
