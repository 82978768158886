import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class={{class-names\n    \"flex h-56 w-full items-center justify-center gap-16 px-12\"\n    (if this.isUrgent \"bg-danger\" \"bg-success\")\n  }}\n>\n  <span>\n    {{t\n      \"components.trial_period_banner.info_text\"\n      daysToExpire=@daysToExpire\n      htmlSafe=true\n    }}\n  </span>\n  <ExternalLink\n    @href={{this.iframeUrl}}\n    @title={{t \"components.trial_period_banner.iframe\"}}\n  />\n</div>", {"contents":"<div\n  class={{class-names\n    \"flex h-56 w-full items-center justify-center gap-16 px-12\"\n    (if this.isUrgent \"bg-danger\" \"bg-success\")\n  }}\n>\n  <span>\n    {{t\n      \"components.trial_period_banner.info_text\"\n      daysToExpire=@daysToExpire\n      htmlSafe=true\n    }}\n  </span>\n  <ExternalLink\n    @href={{this.iframeUrl}}\n    @title={{t \"components.trial_period_banner.iframe\"}}\n  />\n</div>","moduleName":"teamtailor/components/trial-period-banner.hbs","parseOptions":{"srcName":"teamtailor/components/trial-period-banner.hbs"}});
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';

export interface TrialPeriodBannerArgs {
  daysToExpire?: number;
}

const HUBSPOT_FORM_SUBDOMAIN = 'https://resources.teamtailor.com';
const HUBSPOT_SUBDIRECTORY = 'product-experience';

const HUBSPOT_AVAILABLE_LANGUAGE_CODES = [
  'da',
  'de',
  'en',
  'es',
  'et',
  'fi',
  'fr',
  'it',
  'lt',
  'lv',
  'nl',
  'no',
  'pl',
  'pt',
  'sv',
];

export default class TrialPeriodBannerComponent extends Component<TrialPeriodBannerArgs> {
  @service declare current: Current;

  hubspotAvailableLanguageCodes = HUBSPOT_AVAILABLE_LANGUAGE_CODES;

  get isUrgent(): boolean {
    return this.args.daysToExpire !== undefined && this.args.daysToExpire <= 7;
  }

  get languageCode() {
    const languageCode = get(this.current.company, 'localeAts');

    return this.hubspotAvailableLanguageCodes.includes(languageCode)
      ? languageCode
      : 'en';
  }

  get iframeUrl() {
    return `${HUBSPOT_FORM_SUBDOMAIN}/${this.languageCode}/${HUBSPOT_SUBDIRECTORY}`;
  }
}
