import Model, { hasMany, attr, AsyncHasMany } from '@ember-data/model';
import { UserModel } from 'teamtailor/models';

export default class ApprovalSettingModel extends Model {
  @hasMany('user') declare users: AsyncHasMany<UserModel>;

  @attr('string') declare message: string;
  @attr('boolean', { defaultValue: false }) declare multiple: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'approval-setting': ApprovalSettingModel;
  }
}
