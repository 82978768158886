import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, set, setProperties } from '@ember/object';
import { timeout, restartableTask, dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { gql } from '@apollo/client/core';
import ActiveFilter from 'teamtailor/components/fancy-filters/active-filter';
import FancyFilterOption from 'teamtailor/utils/fancy-filter-option';
import { prepareAnyOperator } from 'teamtailor/utils/operators/any-operator';
import { getAvailableRoles } from 'teamtailor/constants/user-role';
import DateRange from 'teamtailor/utils/date-range';

const USERS_ACTIVITIES_QUERY = gql`
  query CollectUserActivitiesQuery($dateRange: DateRangeAttributes!) {
    eventQuery(
      dateRange: $dateRange
      eventTypes: [
        HIRED
        INTERVIEW_CREATED
        MOVED
        REJECTED
        SCORE
        MESSAGE
        NOTE
        REFERRAL
        REVIEW
        SOURCED
      ]
      filters: { userId: { exists: true } }
    ) {
      usersActivities: aggregated(groupBy: [USER_ID]) {
        userId
        totalActivities: count
        moves: countOccurrences(filters: { eventType: { equals: MOVED } })
        hires: countOccurrences(filters: { eventType: { equals: HIRED } })
        rejections: countOccurrences(
          filters: { eventType: { equals: REJECTED } }
        )
        referrals: countOccurrences(
          filters: { eventType: { equals: REFERRAL } }
        )
        sources: countOccurrences(filters: { eventType: { equals: SOURCED } })
        notes: countOccurrences(filters: { eventType: { equals: NOTE } })
        messages: countOccurrences(filters: { eventType: { equals: MESSAGE } })
        reviews: countOccurrences(filters: { eventType: { equals: REVIEW } })
        scores: countOccurrences(filters: { eventType: { equals: SCORE } })
        interviews: countOccurrences(
          filters: { eventType: { equals: INTERVIEW_CREATED } }
        )
      }
    }
  }
`;

const BLANK_ACTIVITIES = {
  totalActivities: 0,
  moves: 0,
  hires: 0,
  rejections: 0,
  sources: 0,
  notes: 0,
  messages: 0,
  reviews: 0,
};

export default class AnalyticsEmployeesIndexController extends Controller {
  @service store;
  @service insights;
  @service intl;
  @service analytics;

  queryParams = [
    'query',
    'roles',
    'department_ids',
    'location_ids',
    'topUserCriteria',
    'page',
  ];

  _roles = [];
  _department_ids = [];
  _location_ids = [];

  @tracked query = '';
  @tracked topUserCriteria = 'totalActivities';
  @tracked page = 1;
  @tracked activeFilters = [];

  get startDate() {
    return this.analytics.startDateParam;
  }

  get endDate() {
    return this.analytics.endDateParam;
  }

  get isLoading() {
    return this.usersActivities.isRunning;
  }

  get users() {
    return this.usersActivities.lastSuccessful?.value?.users;
  }

  get meta() {
    return this.usersActivities.lastSuccessful?.value?.meta;
  }

  get topUsersIsLoading() {
    return this.topUsers.isRunning;
  }

  get topUsersLastValue() {
    return this.topUsers.lastSuccessful?.value;
  }

  get employeeFilters() {
    return [
      {
        name: 'roles',
        type: '[Alternative]',
        options: this.availableRoles,
        operators: [prepareAnyOperator()],
      },
      {
        name: 'department_ids',
        type: '[Department]',
        operators: [prepareAnyOperator()],
      },
      {
        name: 'location_ids',
        type: '[Location]',
        operators: [prepareAnyOperator()],
      },
    ];
  }

  get userCriteria() {
    return [
      {
        id: 'totalActivities',
        label: this.intl.t('insights.users.index.activity'),
      },
      {
        id: 'hires',
        label: this.intl.t('insights.users.index.hires'),
      },
      {
        id: 'messages',
        label: this.intl.t('insights.users.index.messages'),
      },
      { id: 'moves', label: this.intl.t('insights.users.index.moves') },
      { id: 'notes', label: this.intl.t('insights.users.index.notes') },
      {
        id: 'rejections',
        label: this.intl.t('insights.users.index.rejections'),
      },
      { id: 'reviews', label: this.intl.t('insights.users.index.reviews') },
      { id: 'sources', label: this.intl.t('insights.users.index.sources') },
    ];
  }

  get availableFilters() {
    return this.employeeFilters.filter(
      (filter) =>
        this.activeFilters.find(
          (activeFilter) => activeFilter.name === filter.name
        ) === undefined
    );
  }

  availableRoles = [];

  get roles() {
    return this._roles || [];
  }

  set roles(value) {
    this.updateValue('roles', value, '', this.availableRoles);
  }

  get department_ids() {
    return this._department_ids || [];
  }

  set department_ids(value) {
    this.updateValue('department', value);
  }

  get location_ids() {
    return this._location_ids || [];
  }

  set location_ids(value) {
    this.updateValue('location', value);
  }

  get dateRange() {
    return new DateRange(this.analytics.startDate, this.analytics.endDate);
  }

  get validFilters() {
    return this.activeFilters.toArray().filter((filter) => filter.isValid);
  }

  constructor() {
    super(...arguments);

    this.store.peekAll('department');
    this.availableRoles = getAvailableRoles(this.intl)
      .filter((r) => r.id !== 'all')
      .map((r) => ({
        ...r,
        title: r.label,
      }));
  }

  updateValue(type, value, suffix = '_ids', options = undefined) {
    const propertyName = `${type}${suffix}`;

    if (value !== this[`_${propertyName}`]) {
      this[`_${propertyName}`] = value;
    }

    let activeFilter = this.activeFilters.findBy('name', `${propertyName}`);

    if (value.length > 0) {
      if (!activeFilter) {
        if (options) {
          activeFilter = this.createFilter(
            propertyName,
            options.filter((item) => value.includes(item.id))
          );
          if (activeFilter) {
            this.activeFilters.pushObject(activeFilter);
          }
        } else {
          this.store.findAll(type).then((items) => {
            activeFilter = this.createFilter(
              propertyName,
              items.filter((item) => value.includes(item.id))
            );
            if (activeFilter) {
              this.activeFilters.pushObject(activeFilter);
            }
          });
        }
      }
    } else if (activeFilter) {
      this.activeFilters = this.activeFilters.filter(
        (filter) => filter.name !== propertyName
      );
    }
  }

  createFilter(name, value) {
    const fancyFilter = new FancyFilterOption();
    const filter = this.availableFilters.find((f) => f.name === name);
    if (!filter) {
      return undefined;
    }

    Object.assign(fancyFilter, {
      intl: this.intl,
      translationPrefix: 'components.fancy_filters.filters',
      ...filter,
    });
    const activeFilter = new ActiveFilter(fancyFilter);
    if (value !== undefined) {
      activeFilter.value = value;
    }

    return activeFilter;
  }

  @action
  handleUpdateTimeRange(startDate, endDate) {
    this.analytics.setCurrentPeriod(startDate, endDate);
    this.query = '';
    this.usersActivities.perform();
    this.topUsers.perform();
  }

  queryForData(dateRange) {
    return this.insights
      .query({
        query: USERS_ACTIVITIES_QUERY,
        variables: {
          dateRange: dateRange.asObject,
        },
      })
      .then((result) => result.eventQuery);
  }

  async onlyFiltered(users) {
    const { usersActivities: activitiesResult } = await this.queryForData(
      this.dateRange
    );

    return users
      .map((user) => ({
        ...activitiesResult.findBy('userId', user.id),
        user,
      }))
      .map((userActivities) =>
        Object.assign({ ...BLANK_ACTIVITIES }, userActivities || {})
      );
  }

  topUsers = dropTask(async () => {
    const { usersActivities } = await this.queryForData(this.dateRange);

    const topActivities = usersActivities
      .sortBy(this.topUserCriteria)
      .reverse()
      .slice(0, 5);

    const users = await this.store.query('user', {
      ids: topActivities.mapBy('userId'),
    });

    return topActivities.map((userActivity) => ({
      ...userActivity,
      user: users.findBy('id', userActivity.userId),
    }));
  });

  usersActivities = restartableTask(async () => {
    await timeout(500);

    const users = await this.store.query('user', {
      query: this.query,
      roles: this.roles,
      department_ids: this.department_ids,
      location_ids: this.location_ids,
      page: this.page,
    });

    return {
      meta: users.meta,
      users: await this.onlyFiltered(users),
    };
  });

  @action
  handleSelectFilter(filter) {
    const fancyFilter = new FancyFilterOption();
    Object.assign(fancyFilter, {
      intl: this.intl,
      translationPrefix: 'components.fancy_filters.filters',
      ...filter,
    });
    this.activeFilters.forEach((filter) => {
      filter.isOpen = false;
    });
    const activeFilter = new ActiveFilter(fancyFilter);
    this.activeFilters = [...this.activeFilters, activeFilter];

    activeFilter.isOpen = true;
  }

  @action
  handleRemoveFilter(filter) {
    set(this, filter.name, []);
  }

  @action
  handleFilterUpdate() {
    let properties = this.validFilters.reduce((all, filter) => {
      if (filter.value?.map) {
        all[filter.filter.name] = filter.value.map((v) =>
          v.id !== undefined ? v.id : v
        );
      } else {
        all[filter.filter.name] = filter.value;
      }

      return all;
    }, {});

    setProperties(this, properties);
  }
}
