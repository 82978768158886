import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import {
  FilterBaseValueType,
  FilterValue,
} from 'teamtailor/components/fancy-filters';
import LocationModel from 'teamtailor/models/location';
import Department from 'teamtailor/models/department';
import Role from 'teamtailor/models/role';
import User from 'teamtailor/models/user';
import Tag from 'teamtailor/models/tag';
import StageType from 'teamtailor/models/stage-type';
import CustomFieldOption from 'teamtailor/models/custom-field/option';
import moment from 'moment-timezone';
import { underscore } from '@ember/string';
import { isEmpty } from '@ember/utils';
import PromotionModel from 'teamtailor/models/promotion';
import PageModel from 'teamtailor/models/page';
import { RequisitionModel } from 'teamtailor/models';

export function fancyFilterDisplayValue(
  [value]: FilterValue[],
  intl?: IntlService
): string {
  if (Array.isArray(value)) {
    return value
      .map((v) => displayValue(v as FilterBaseValueType, intl))
      .join(', ');
  } else {
    return displayValue(value as FilterBaseValueType, intl);
  }
}

function displayValue(
  value: FilterBaseValueType | undefined,
  intl?: IntlService
): string {
  if (value instanceof LocationModel) {
    return value.nameOrCity;
  } else if (value instanceof Department) {
    return value.name;
  } else if (value instanceof PromotionModel) {
    return value.humanName;
  } else if (value instanceof PageModel) {
    return value.displayTitle;
  } else if (value instanceof Role) {
    return value.name;
  } else if (value instanceof User) {
    return value.nameOrEmail;
  } else if (value instanceof Tag) {
    return value.name;
  } else if (value instanceof RequisitionModel) {
    return value.jobTitle;
  } else if (value instanceof StageType) {
    return value.translatedCategory;
  } else if (value instanceof Date) {
    return moment(value).format('YYYY-MM-DD');
  } else if (value instanceof CustomFieldOption) {
    return value.value;
  } else if ((value as any)?.value) {
    return (value as any)?.value;
  } else if (value === undefined) {
    return '';
  } else if ((value as any)?.name) {
    return (value as any).name;
  } else if ((value as any)?.title) {
    return (value as any).title;
  } else if (!isEmpty(value)) {
    return intl
      ? intl.t(
          `components.fancy_filters.values.${underscore(value.toString())}`
        )
      : value.toString();
  }

  return '';
}

export default class FancyFilterDisplayValue extends Helper {
  @service declare intl: IntlService;

  compute([value]: FilterValue[]): string {
    return value ? fancyFilterDisplayValue([value]) : '';
  }
}
