import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Editor::Adjustments::Controls::Dropdown\n  @model={{@model}}\n  @attribute={{@attribute}}\n  @displayValue={{@displayValue}}\n  @icon={{@icon}}\n  @label={{@label}}\n  @emptyLabel={{@emptyLabel}}\n  @tooltip={{@tooltip}}\n  @dropdownClass={{\"w-[calc(200%+12px)]\"}}\n  @disableOutsideClick={{this.mediaPickerOpen}}\n>\n  <MediaLibrary::Picker\n    @theme=\"dark\"\n    @imageUrl={{this.imageUrl}}\n    @onPickImage={{queue this.handlePickImage @onChange}}\n    @onRemoveImage={{queue this.handleRemoveImage @onChange}}\n    @previewBackgroundColor={{@previewBackgroundColor}}\n    @backgroundSize={{@backgroundSize}}\n    @onOpenPicker={{this.handleOnOpenPicker}}\n    @onModalClose={{this.handleModalClose}}\n  />\n\n  <p class=\"body-text-xs mb-0 mt-2 italic\">{{@helpText}}</p>\n</Editor::Adjustments::Controls::Dropdown>", {"contents":"<Editor::Adjustments::Controls::Dropdown\n  @model={{@model}}\n  @attribute={{@attribute}}\n  @displayValue={{@displayValue}}\n  @icon={{@icon}}\n  @label={{@label}}\n  @emptyLabel={{@emptyLabel}}\n  @tooltip={{@tooltip}}\n  @dropdownClass={{\"w-[calc(200%+12px)]\"}}\n  @disableOutsideClick={{this.mediaPickerOpen}}\n>\n  <MediaLibrary::Picker\n    @theme=\"dark\"\n    @imageUrl={{this.imageUrl}}\n    @onPickImage={{queue this.handlePickImage @onChange}}\n    @onRemoveImage={{queue this.handleRemoveImage @onChange}}\n    @previewBackgroundColor={{@previewBackgroundColor}}\n    @backgroundSize={{@backgroundSize}}\n    @onOpenPicker={{this.handleOnOpenPicker}}\n    @onModalClose={{this.handleModalClose}}\n  />\n\n  <p class=\"body-text-xs mb-0 mt-2 italic\">{{@helpText}}</p>\n</Editor::Adjustments::Controls::Dropdown>","moduleName":"teamtailor/components/editor/adjustments/inputs/media.hbs","parseOptions":{"srcName":"teamtailor/components/editor/adjustments/inputs/media.hbs"}});
import { inject as service } from '@ember/service';
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Base from './base';

export default class EditorAdjustmentsInputsMedia extends Base {
  @service filthyContent;

  @tracked mediaPickerOpen = false;

  get imageUrl() {
    return get(this, `args.model.${this.args.attribute}.s3Image`);
  }

  @action
  handlePickImage(image) {
    this.value = image;
    this.filthyContent.setFilthy(this.args.model.id);
  }

  @action
  handleRemoveImage() {
    this.value = null;
    this.filthyContent.setFilthy(this.args.model.id);
  }

  @action
  handleOnOpenPicker() {
    this.mediaPickerOpen = true;
  }

  @action
  handleModalClose() {
    this.mediaPickerOpen = false;
  }
}
