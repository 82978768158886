import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import CompanyModel from 'teamtailor/models/company';
import TodoModel from 'teamtailor/models/todo';

export default class TodoTemplateModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @hasMany('todo') declare todos: AsyncHasMany<TodoModel>;
  @attr('string') declare name: string;
  @attr('raw', { defaultValue: () => [] }) declare roles: string[];
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'todo-template': TodoTemplateModel;
  }
}
