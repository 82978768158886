import Controller from '@ember/controller';
import { timeout, restartableTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import saveFile from 'teamtailor/utils/save-file';
import { get } from 'teamtailor/utils/get';

export default class extends Controller {
  @service intl;
  @service current;
  @service intercom;
  @service store;
  @service ttAlert;
  @service server;
  @service flipper;

  @tracked page = 1;
  @tracked query = '';
  @tracked sortDirection = 'desc';
  @tracked sortColumn = null;
  @tracked _responsibleUser = null;
  @tracked errorKeysForUsers = {};

  channel = null;

  queryParams = ['page', 'query', 'sortColumn', 'sortDirection'];

  get responsibleUser() {
    return this._responsibleUser || this.current.company.manager;
  }

  get totalPages() {
    return this.model.users.meta.total_pages;
  }

  get totalCount() {
    return this.model.users.meta.total_count;
  }

  get showPagination() {
    return this.totalPages > 1;
  }

  get serviceAccounts() {
    return this.model.authorizations.filterBy(
      'provider',
      'cronofy_service_account'
    );
  }

  get serviceAccount() {
    return this.serviceAccounts.firstObject;
  }

  get serviceAccountsAuthorizations() {
    return this.model.authorizations.filterBy(
      'provider',
      'cronofy_service_account_authorization'
    );
  }

  get hasServiceAccount() {
    return (
      this.current.company.parentHasEnterpriseCalendar || this.serviceAccount
    );
  }

  get noServiceAccountsAuthorizations() {
    return this.serviceAccountsAuthorizations.length === 0;
  }

  get oppositeSortDirection() {
    return this.sortDirection === 'desc' ? 'asc' : 'desc';
  }

  get tableData() {
    return this.model.users.map((user) => {
      return {
        user,
        status: this.getStatusForUser(user),
        errorKey: this.errorKeysForUsers[user.id],
      };
    });
  }

  getStatusForUser(user) {
    const errorKey = this.errorKeysForUsers[user.id];

    if (errorKey) {
      return 'error';
    }

    if (user.cronofyAuthorization?.cronofyReconnectedRequired) {
      return 'expired';
    }

    return user.cronofyAuthorization ? 'active' : 'unlisted';
  }

  enterpriseConnectAuthorizationError({
    user: {
      user: { id },
    },
    error_key: errorKey,
  }) {
    this.errorKeysForUsers = { ...this.errorKeysForUsers, [id]: errorKey };
  }

  setQueryTask = restartableTask(async (value) => {
    await timeout(300);
    this.query = value;
    this.page = 1;
  });

  retryCalendarConnectionsTask = restartableTask(async () => {
    await get(this, 'current.company').retryEnterpriseCalendarConnections();
    await timeout(10000);
  });

  responsibleUserTask = restartableTask(async () => {
    if (this.current.company.enterpriseCalendarContact) {
      const user = await this.store.findRecord(
        'user',
        this.current.company.enterpriseCalendarContact
      );

      if (user) {
        this._responsibleUser = user;
      }
    }
  });

  @action
  deleteAuthorization(authorization) {
    this.ttAlert.customConfirm({
      title: this.intl.t(
        'settings.enterprise_calendar.disconnect_confirm.title'
      ),

      text: this.intl.t('settings.enterprise_calendar.disconnect_confirm.text'),

      cancelButtonText: this.intl.t('common.no'),
      confirmButtonClass: 'btn-danger',
      confirmButtonText: this.intl.t(
        'settings.enterprise_calendar.disconnect_confirm.title'
      ),

      confirmCallback: () => {
        authorization.destroyRecord().then(() => {});
      },
    });
  }

  @action
  onCronofyConnectSuccess(data) {
    let authorization = this.store.createRecord(
      'authorization',
      Object.assign(data.authorization, {
        company: get(this, 'current.company'),
      })
    );

    return authorization.save().then(() => {
      this.intercom.trackEvent('connected-service-account');
    });
  }

  @action
  setPage(page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  get exportUrl() {
    const adapter = get(this, 'store').adapterFor('application');
    const url = adapter.buildURL('companies', get(this, 'current.company.id'));
    return `${url}/enterprise_calendars.csv`;
  }

  @action
  async handleExport() {
    return this.server
      .fetch(this.exportUrl)
      .then((response) => response.text())
      .then((content) => {
        saveFile(
          `enterprise_calendar_${new Date().toISOString()}.csv`,
          content,
          'text/csv'
        );
      });
  }

  @action
  setResponsibleUser(user) {
    this.current.company.enterpriseCalendarContact = user.id;
    this.current.company.save();
    this._responsibleUser = user;
  }
}
