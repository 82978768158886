import { isPresent, isEmpty } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import OrganizableModel from 'teamtailor/models/organizable';
import IntlService from 'ember-intl/services/intl';

export type AssetStructureContext = {
  [key: string]: string | null;
};

export type GroupedOrganizable = {
  groupName: string;
  options: OrganizableModel[];
};

export default function filterAssets(
  assets: OrganizableModel[],
  context?: AssetStructureContext
) {
  let filtered = assets;
  if (context) {
    Object.keys(context).forEach((key) => {
      if (isPresent(context[key])) {
        filtered = filtered.filter((asset) => {
          const structure = get(asset, 'assetStructure');
          return isPresent(structure) && structure.matches(key, context[key]!);
        });
      }
    });
  }

  return filtered;
}

export function getAssetsWithoutStructure(assets: OrganizableModel[]) {
  return assets.filter((asset) => {
    return isEmpty(get(asset, 'assetStructure'));
  });
}

export function fetchGroupedAssetsByContext(
  assets: OrganizableModel[],
  context: AssetStructureContext,
  intl: IntlService
) {
  const bestMatched = filterAssets(assets, context);
  const nonStructured = getAssetsWithoutStructure(assets);
  // When the context content is null, same assets can appear in both sections.
  // That's why we filter them below.
  const others = nonStructured.filter((item) => !bestMatched.includes(item));
  if (bestMatched.length && others.length) {
    return [
      {
        groupName: intl.t('settings.canned_responses.grouping_best_match'),

        options: bestMatched,
      },
      {
        groupName: intl.t('settings.canned_responses.grouping_others'),
        options: others,
      },
    ];
  } else if (bestMatched.length) {
    return bestMatched;
  } else if (others.length) {
    return others;
  } else {
    return [];
  }
}
