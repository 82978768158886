import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { isPresent } from '@ember/utils';

export default class ReportRequest {
  @service store;
  @service analytics;

  path;

  constructor({ container, path }) {
    this.container = container;
    this.path = path;
  }

  async fetch(overrideParams = {}) {
    if (
      Array.isArray(this.queryParams.jobs) &&
      this.queryParams.jobs.length === 0
    ) {
      return [];
    }

    const params = new URLSearchParams({
      start_date: moment(this.analytics.startDate).format('YYYY-MM-DD'),
      end_date: moment(this.analytics.endDate).format('YYYY-MM-DD'),
      groupAnalytics: this.analytics.hasEnabledGroupAnalytics,
      ...this.queryParams,
      ...overrideParams,
    });

    let rootURL = this.adapter.buildURL('reports');
    return this.adapter.ajax(`${rootURL}/${this.path}?${params}`, 'GET');
  }

  get queryParams() {
    const reportFilter = {};

    if (isPresent(this.analytics.jobId)) {
      reportFilter.jobs = [Number(this.analytics.jobId)];
    } else if (this.analytics.selectedJobIds) {
      reportFilter.jobs = this.analytics.selectedJobIds;
    }

    return reportFilter;
  }

  get adapter() {
    return this.store.adapterFor('application');
  }
}
