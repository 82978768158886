import Service, { inject as service } from '@ember/service';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import CRONOFY_AVAILABILITY_QUERY from 'teamtailor/gql/cronofy-availability-query.graphql';
import {
  MeetingEventClass,
  SelfScheduleClass,
} from 'teamtailor/classes/meetings';

type CronofyAvailability = {
  periods: string[];
};

export default class AvailabilityService extends Service.extend({}) {
  @service declare apollo: TeamtailorApolloService;

  async requestAvailableSlotsDataForEvent(
    event?: MeetingEventClass
  ): Promise<CronofyAvailability | undefined> {
    return this.requestAvailableSlotsData(
      event?.tzid,
      event?.meetingEventAttendees.userAttendees.map((a) => a.user?.id),
      event?.meetingEventAttendees.meetingRooms?.map((a) => a?.id),
      event?.selfSchedule,
      event?.meetingEventAttendees.userAttendees
        .filter((a) => a.excludeAvailability)
        .map((a) => a.user?.id)
    );
  }

  async requestAvailableSlotsData(
    tzid = 'UTC',
    userIds: (string | undefined)[] = [],
    meetingRoomIds: (string | undefined)[] = [],
    selfSchedule: SelfScheduleClass | undefined,
    excludedUserIds: (string | undefined)[] = []
  ): Promise<CronofyAvailability> {
    const data: Record<'cronofyAvailability', CronofyAvailability> =
      await this.apollo.query({
        query: CRONOFY_AVAILABILITY_QUERY,
        variables: {
          filter: {
            userIds,
            meetingRoomIds,
            timeZone: tzid,
            responseFormat: 'slots',
            formatResponse: false,
            selfSchedule: selfSchedule?.toAttributes(),
            required: selfSchedule?.required,
            managed: true,
            maxResults: 512,
            excludedUserIds,
          },
        },
      });

    return data.cronofyAvailability;
  }
}

declare module '@ember/service' {
  interface Registry {
    availability: AvailabilityService;
  }
}
