import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<HighCharts\n  @chartOptions={{this.chartOptions}}\n  @content={{this.chartData}}\n  class=\"grow\"\n/>", {"contents":"<HighCharts\n  @chartOptions={{this.chartOptions}}\n  @content={{this.chartData}}\n  class=\"grow\"\n/>","moduleName":"teamtailor/components/insights/molecules/partner-results-chart.hbs","parseOptions":{"srcName":"teamtailor/components/insights/molecules/partner-results-chart.hbs"}});
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import Component from '@glimmer/component';
import { colors as hc } from 'teamtailor/components/insights/charts/colors';

import { get } from 'teamtailor/utils/get';

interface RowWithCandidate {
  partner_name: string;
  score: number;
}

interface Args {
  rows?: RowWithCandidate[];
  partnerNames?: string[];
}

export default class PartnerResultsChart extends Component<Args> {
  @service declare intl: IntlService;

  get chartData() {
    const { rows } = this.args;

    if (!rows) return null;

    const step = 20;

    const colors = hc.slice(1);

    const allPartnerNames = [...new Set(rows.map((row) => row.partner_name))];

    const partnerNames = this.args.partnerNames || allPartnerNames;

    return partnerNames.map((name) => {
      const data = [0, 0, 0, 0, 0];

      rows
        .filter((row) => row.partner_name === name)
        .forEach(function (item) {
          const idx = Math.floor(get(item, 'score') / step);
          data[idx]++;
        });

      const colorIndex = allPartnerNames.indexOf(name);
      const color = colors[colorIndex % colors.length];

      return {
        name,
        data,
        color,
      };
    });
  }

  get chartOptions() {
    return {
      chart: {
        type: 'column',
        height: 280,
      },

      xAxis: {
        categories: ['0-20', '21-40', '41-60', '61-80', '81-100'],
      },

      yAxis: {
        allowDecimals: false,
        title: {
          text: null,
        },
      },

      legend: {
        enabled: false,
      },

      tooltip: {
        pointFormat: `<b>{point.y} ${this.intl.t('common.candidates')}</b>`,
      },

      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
    };
  }
}
