import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import store from '@ember-data/store';
import Controller from '@ember/controller';

export default class SettingsWebhookNewRoute extends ScrollToTopRoute {
  @service declare store: store;

  model() {
    return this.store.createRecord('webhook-subscription');
  }

  resetController(controller: Controller) {
    controller.model.rollbackAttributes();
  }
}
