import ApplicationInstance from '@ember/application/instance';
import { gql } from '@apollo/client/core';
import ReportAnalyticsRequest from './report-analytics-request';

const QUERY = gql`
  query JobOverviewsQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    pageviews: pageviewQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      filters: [{ jobId: { exists: true } }]
      companyIds: $companyIds
    ) {
      aggregated(groupBy: JOB_ID) {
        jobId
        pageviews: count
      }
    }
    applications: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [APPLIED, SOURCED]
      filters: [{ jobId: { exists: true } }]
      companyIds: $companyIds
    ) {
      aggregated(groupBy: [JOB_ID]) {
        jobId
        candidateIds: collect(field: CANDIDATE_ID)
        jobApplicationIds: collect(field: JOB_APPLICATION_ID)

        numberOfApplications: countOccurrences(
          filters: { eventType: { equals: APPLIED } }
        )
        numberOfSourcedApplications: countOccurrences(
          filters: { eventType: { equals: SOURCED } }
        )
      }
    }
    reviews: cohortEventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      cohortEventField: CANDIDATE_ID
      cohortEventTypes: [SOURCED, APPLIED]
      cohortJoinEventFields: [JOB_ID]
      eventTypes: [REVIEW]
      distinctBy: {
        fields: [CANDIDATE_ID, USER_ID]
        order: { field: TIMESTAMP, desc: true }
      }
      filters: [{ jobId: { exists: true } }]
      companyIds: $companyIds
    ) {
      aggregated(groupByCohortField: [JOB_ID]) {
        jobId
        average(field: REVIEW_RATING)
        count
      }
    }
  }
`;

type JobPageviews = {
  jobId: string;
  pageviews: number;
};

type JobApplications = {
  jobId: string;
  candidateIds: string[];
  jobApplicationIds: string[];
  numberOfApplications: number;
  numberOfSourcedApplications: number;
};

type JobReviews = {
  jobId: string;
  average: number;
  count: number;
};

type Result = {
  pageviews: {
    aggregated: JobPageviews[];
  };
  applications: {
    aggregated: JobApplications[];
  };
  reviews: {
    aggregated: JobReviews[];
  };
};

type JobOverviewCombined = {
  jobId: string;
  candidates?: string[];
  applications?: string[];
  numberOfApplications?: number;
  numberOfSourcedApplications?: number;
  averageRating?: number;
  pageviews?: number;
};

function processInsightsResults(result: Result | undefined) {
  if (!result) return null;

  const { pageviews, applications, reviews } = result;

  const jobs = pageviews.aggregated.map((job) => ({
    ...job,
  })) as JobOverviewCombined[];

  applications.aggregated.forEach((job) => {
    const existingJob = jobs.find((j) => j.jobId === job.jobId);

    if (existingJob) {
      existingJob.numberOfApplications = job.numberOfApplications;
      existingJob.numberOfSourcedApplications = job.numberOfSourcedApplications;
      existingJob.applications = job.jobApplicationIds;
      existingJob.candidates = job.candidateIds;
    } else {
      jobs.push({
        jobId: job.jobId,
        numberOfApplications: job.numberOfApplications,
        numberOfSourcedApplications: job.numberOfSourcedApplications,
        applications: job.jobApplicationIds,
        candidates: job.candidateIds,
      });
    }
  });

  reviews.aggregated.forEach((job) => {
    const existingJob = jobs.find((j) => j.jobId === job.jobId);

    if (existingJob) {
      existingJob.averageRating = job.average;
    } else {
      jobs.push({
        jobId: job.jobId,
        averageRating: job.average,
      });
    }
  });

  return jobs.map((job) => ({
    ...job,
    applications: job.applications || [],
    candidates: job.candidates || [],
    pageviews: job.pageviews || 0,
    numberOfApplications: job.numberOfApplications || 0,
    numberOfSourcedApplications: job.numberOfSourcedApplications || 0,
    numberOfCandidates: job.candidates?.length || 0,
    averageRating: job.averageRating || 0,
  }));
}

class JobOverviewReport {
  container: ApplicationInstance;
  rows: JobOverviewCombined[] = [];

  constructor({
    container,
    rows,
  }: {
    container: ApplicationInstance;
    rows: JobOverviewCombined[];
  }) {
    this.container = container;
    this.rows = rows;
  }

  get totalJobs() {
    return this.rows.length;
  }

  get totalPageviews() {
    return this.rows
      .map((result) => result.pageviews)
      .reduce(
        (previousValue, pageviews) => (previousValue ?? 0) + (pageviews ?? 0),
        0
      );
  }

  get totalApplications() {
    return this.rows.map((row) => row.applications).flat().length;
  }

  get averagePageviewsPerJob() {
    if (this.totalJobs && this.totalPageviews) {
      return Math.round(this.totalPageviews / this.totalJobs);
    }

    return undefined;
  }

  get averageApplicationPerPageview() {
    if (this.totalApplications && this.totalPageviews) {
      return (this.totalApplications / this.totalPageviews) * 100;
    }

    return undefined;
  }

  get averageApplicationsPerJob() {
    if (this.totalJobs && this.totalApplications) {
      return (this.totalApplications / this.totalJobs).toFixed(1);
    }

    return undefined;
  }
}

type SimpleJobType = {
  id: string;
  title: string;
  status: string;
  company: {
    name: string;
  };
};

export async function fetch(container: ApplicationInstance) {
  const _rows = await new ReportAnalyticsRequest({
    container,
    query: QUERY,
    callback: (result?: Result) => processInsightsResults(result),
  }).fetch();

  if (!_rows || _rows.length === 0) {
    return new JobOverviewReport({ container, rows: [] });
  }

  const analyticsService = container.lookup('service:analytics');
  const apolloService = container.lookup('service:apollo');
  const intlService = container.lookup('service:intl');
  const currentService = container.lookup('service:current');
  const jobIdsFromRows = _rows.map((row: any) => row.jobId);
  const { jobs }: { jobs: SimpleJobType[] } = await apolloService.query({
    query: gql`
      query JobsOverviewJobsQuery(
        $userId: ID!
        $filter: JobsFilterInput
        $companyIds: [ID!]
      ) {
        jobs(
          filter: $filter
          userScope: { userId: $userId }
          groupCompanyIds: $companyIds
        ) {
          id
          status
          title
          company {
            name
          }
        }
      }
    `,
    variables: {
      userId: currentService.user.id,
      companyIds: analyticsService.availableCompanyIds,
      filter: {
        ids: jobIdsFromRows,
        status: ['open', 'draft', 'archived', 'unlisted', 'awaiting', 'temp'],
      },
    },
  });

  const rows = _rows.map((row: JobOverviewCombined) => {
    const job = jobs.find((job) => job.id === row.jobId);
    return {
      ...row,
      jobTitle: job?.title || intlService.t('jobs.edit.job_deleted'),
      jobStatus: job?.status,
      companyName:
        job?.company.name ||
        intlService.t('components.data_table.not_available'),

      conversionRate:
        row.pageviews! > 0
          ? row.numberOfApplications! / row.pageviews!
          : undefined,
    };
  });

  return new JobOverviewReport({ container, rows });
}
