/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { get, computed } from '@ember/object';

export default Mixin.create({
  canPickImage: computed(
    'currentAvailableImages',
    'pickedImages.length',
    function () {
      if (get(this, 'currentAvailableImages') !== undefined) {
        return (
          get(this, 'currentAvailableImages') -
            get(this, 'pickedImages.length') >
          0
        );
      } else {
        return true;
      }
    }
  ),
});
