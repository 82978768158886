import ApplicationInstance from '@ember/application/instance';
import { task } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';
import Current from 'teamtailor/services/current';
import { candidateHireQuality } from 'teamtailor/utils/hires-report';
import DateRange from 'teamtailor/utils/date-range';
import moment from 'moment-timezone';
import round from 'teamtailor/utils/round';
import ReportAnalyticsRequest from './report-analytics-request';
import { gql } from '@apollo/client/core';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import AccessLevelModel from 'teamtailor/models/access-level';

const QUERY = gql`
  query TimeToHireQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [HIRED]
      orderBy: { field: TIMESTAMP, desc: true }
      companyIds: $companyIds
    ) {
      hires: rows {
        candidateId
        jobId
        jobApplicationId
        source: jobApplicationSource
        timeToHire: jobApplicationTimeToHired
        timestamp: keenTimestamp
      }
    }
  }
`;

const HIRE_QUALITY_RESPONSE_QUERY = gql`
  query HireQualityResponseQuery(
    $dateRange: DateRangeAttributes!
    $jobApplicationIds: [ID!]
    $companyIds: [ID!]
  ) {
    eventQuery(
      dateRange: $dateRange
      eventTypes: [HIRE_QUALITY_RESPONSE]
      filters: [{ jobApplicationId: { any: $jobApplicationIds } }]
      distinctBy: {
        fields: [JOB_APPLICATION_ID, HIRE_QUALITY_RESPONSE_ID]
        order: { field: TIMESTAMP, desc: true }
      }
      companyIds: $companyIds
    ) {
      hireQualityResponses: rows {
        jobApplicationId
        hireQualityResponseId
        hireQualityResponseRecommended
        userId
      }
    }
  }
`;

type ResultRow = {
  candidateId: string;
  jobId: string;
  jobApplicationId: string;
  source?: string;
  timeToHire: string;
  timestamp: string;
};

type Result = {
  eventQuery:
    | {
        hires: ResultRow[] | undefined;
        hireQualityResponses: HireQualityResponseRow[] | undefined;
      }
    | undefined;
};

type Row = {
  candidate?: CandidateResultRow;
  job?: JobResultRow;
  candidateId: number;
  jobId: number;
  jobApplicationId: number;
  timeToHire: number;
  timeToHireInDays: number;
  timestamp: string;
  source?: string;
  deleted: boolean;

  nameOrEmail?: string;
  initials?: string;
  color?: string;
  picture?: string;
  jobStartedAt?: Date;
  timeToFillInDays?: number;
  hireQualityResponseCounts?: HireQualityResponseCounts;
  hireQuality?: number;
};

type CandidateResultRow = {
  id: string;
  nameOrEmail?: string;
  initials?: string;
  color?: string;
  avatarImage?: {
    candidatePictureUrl: string;
  };
  company?: {
    id: string;
    name: string;
  };
};

type JobResultRow = {
  id: string;
  title: string;
  jobStartedAt: Date;
};

type CandidateAndJobsResult = {
  candidates: CandidateResultRow[];
  jobs: JobResultRow[];
};

type HireQualityResponseRow = {
  jobApplicationId: string;
  hireQualityResponseId: string;
  hireQualityResponseRecommended: boolean | null;
};

type HireQualityResponseCounts = {
  poorCount: number;
  goodCount: number;
  pendingCount: number;
};

const queryCandidatesAndJobs = async (
  container: ApplicationInstance,
  candidateIds: string[],
  jobIds: string[]
): Promise<CandidateAndJobsResult> => {
  const apolloService = container.lookup('service:apollo');
  const currentService = container.lookup('service:current');
  const analyticsService = container.lookup('service:analytics');
  const userAccessLevels = await get(currentService, 'user.accessLevels');

  const userHasAnalyticsRole = userAccessLevels
    .toArray()
    .some((l: AccessLevelModel) => l.name === 'analytics');

  const { candidates, jobs }: CandidateAndJobsResult =
    await apolloService.query({
      query: gql`
        query CandidatesAndJobsQuery(
          $userId: ID!
          $candidateIds: [ID!]
          $jobFilter: JobsFilterInput
          $allPublished: Boolean
          $companyIds: [ID!]
        ) {
          candidates(
            ids: $candidateIds
            userScope: { userId: $userId }
            groupCompanyIds: $companyIds
          ) {
            id
            nameOrEmail
            initials
            color
            avatarImage {
              candidatePictureUrl
            }
            company {
              id
              name
            }
          }
          jobs(
            filter: $jobFilter
            userScope: { userId: $userId }
            allPublished: $allPublished
            groupCompanyIds: $companyIds
          ) {
            id
            title
            jobStartedAt
          }
        }
      `,
      variables: {
        userId: get(currentService, 'user.id'),
        allPublished: userHasAnalyticsRole,
        candidateIds,
        companyIds: analyticsService.availableCompanyIds,
        jobFilter: {
          ids: jobIds,
          status: ['open', 'draft', 'archived', 'unlisted', 'awaiting', 'temp'],
        },
      },
    });

  return { candidates, jobs };
};

const fetchJobsAndCandidates = async (
  rows: ResultRow[] | undefined,
  container: ApplicationInstance
): Promise<Row[]> => {
  if (!rows) {
    return [];
  }

  const intl = container.lookup('service:intl');
  const jobIds = rows.map((row) => row.jobId);
  const candidateIds = rows.map((row) => row.candidateId);

  const { jobs, candidates } = await queryCandidatesAndJobs(
    container,
    candidateIds,
    jobIds
  );

  return rows.map((row) => {
    const job = jobs.find((job) => job.id.toString() === row.jobId.toString());
    const candidate = candidates.find(
      (candidate) => candidate.id.toString() === row.candidateId.toString()
    );

    const date = moment(row.timestamp).format('YYYY-MM-DD');
    const jobStartedAt = job?.jobStartedAt;

    return {
      candidateId: parseInt(row.candidateId),
      jobId: parseInt(row.jobId),
      jobApplicationId: parseInt(row.jobApplicationId),
      source: row.source === '(direct)' ? undefined : row.source,
      job,
      candidate: candidate || { id: row.candidateId, deleted: true },
      companyName:
        candidate?.company?.name ||
        intl.t('components.data_table.not_available'),

      timestamp: date,
      timeToHire: parseInt(row.timeToHire),
      timeToHireInDays: round(
        moment.duration(row.timeToHire, 'seconds').asDays()
      ),

      deleted: !candidate,
      nameOrEmail:
        candidate?.nameOrEmail || intl.t('insights.common.deleted_candidate'),

      initials: candidate?.initials || 'DC',
      color: candidate?.color || '#333333',
      picture: candidate?.avatarImage?.candidatePictureUrl,
      jobStartedAt,
      timeToFillInDays: jobStartedAt
        ? moment.duration(moment(date).diff(moment(jobStartedAt))).as('days')
        : undefined,
    };
  });
};

const countHireQualityResponses = (responses: HireQualityResponseRow[]) => {
  return responses.reduce(
    (
      hireQualityResponses: { [key: string]: HireQualityResponseCounts },
      { jobApplicationId, hireQualityResponseRecommended }
    ) => {
      let responseCounts = hireQualityResponses[jobApplicationId];
      if (!responseCounts) {
        responseCounts = { goodCount: 0, poorCount: 0, pendingCount: 0 };
      }

      if (hireQualityResponseRecommended) {
        responseCounts.goodCount += 1;
      } else if (hireQualityResponseRecommended === false) {
        responseCounts.poorCount += 1;
      } else {
        responseCounts.pendingCount += 1;
      }

      hireQualityResponses[jobApplicationId] = responseCounts;

      return hireQualityResponses;
    },
    {}
  );
};

const queryHireQualityResponses = async (
  container: ApplicationInstance,
  jobApplicationIds: number[]
): Promise<HireQualityResponseRow[]> => {
  const analyticsService = container.lookup('service:analytics');
  const dateRange = new DateRange(analyticsService.startDate, new Date());

  return new ReportAnalyticsRequest({
    container,
    query: HIRE_QUALITY_RESPONSE_QUERY,
    extraVariables: { jobApplicationIds },
    callback: (result?: Result) =>
      result?.eventQuery?.hireQualityResponses || [],
  }).fetch({ dateRange });
};

const addHireQualityResponseCounts = (
  rows: Row[],
  responses: HireQualityResponseRow[]
): Row[] => {
  if (rows.length) {
    const responseCountsByJobApplication = countHireQualityResponses(responses);
    rows.forEach((row) => {
      const defaultCounts = { goodCount: 0, poorCount: 0, pendingCount: 0 };
      const responseCounts =
        responseCountsByJobApplication[row.jobApplicationId] || defaultCounts;

      Object.assign(row, {
        hireQuality: candidateHireQuality(responseCounts),
        hireQualityResponseCounts: responseCounts,
      });
    });
  }

  return rows;
};

interface DataObjectArgs {
  rows: Row[];
  showHireQualityInfo: boolean;
}

class DataObject {
  rows: Row[] = [];
  showHireQualityInfo = false;

  sortBy = 'timestamp';

  constructor(args: DataObjectArgs) {
    Object.assign(this, args);
  }

  get timesToHire() {
    return this.rows.map((row) => row.timeToHire);
  }

  get totalHireTime() {
    return this.timesToHire.reduce((acc, val) => acc + val, 0);
  }

  get average() {
    if (this.rows.length === 0) {
      return undefined;
    }

    return round(this.totalHireTime / this.rows.length);
  }

  get averageInDays() {
    if (this.average === undefined) {
      return undefined;
    }

    return round(moment.duration(this.average, 'seconds').asDays());
  }

  get latestHires() {
    return this.rows.slice(0, 4);
  }
}

export default class TimeToHireReport {
  container: ApplicationInstance;

  @service declare current: Current;

  constructor({ container }: { container: ApplicationInstance }) {
    this.container = container;
  }

  fetch = task(async (): Promise<DataObject> => {
    const result = await new ReportAnalyticsRequest({
      container: this.container,
      query: QUERY,
      callback: (result?: Result) => result?.eventQuery?.hires || [],
    }).fetch();

    let rows = await fetchJobsAndCandidates(result, this.container);

    let showHireQualityInfo = false;
    if (this.current.user.admin || this.current.user.recruitmentAdmin) {
      const jobApplicationIds = rows.map((row) => row.jobApplicationId);
      const hireQualityResponses = await queryHireQualityResponses(
        this.container,
        jobApplicationIds
      );
      if (hireQualityResponses.length) {
        showHireQualityInfo = true;
        rows = addHireQualityResponseCounts(rows, hireQualityResponses);
      }
    }

    return new DataObject({
      rows,
      showHireQualityInfo,
    });
  });

  fetchTask = trackedTask(this, this.fetch);
}
