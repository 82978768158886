import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { get } from 'teamtailor/utils/get';
import PromotionModel from './promotion';
import ChannelActivationModel from './channel-activation';
import JobModel from './job';
import Server from 'teamtailor/services/server';
import { inject as service } from '@ember/service';

type WebhookConfig = {
  page: number;
  hasNextPage: boolean;
  requireLocation?: boolean;
  requireLocations?: boolean;
  hasRedirectLink: boolean;
};

export default class PromotionRequestModel extends Model {
  @service declare server: Server;

  @belongsTo('promotion') declare promotion: AsyncBelongsTo<PromotionModel>;
  @belongsTo('job') declare job: AsyncBelongsTo<JobModel>;
  @belongsTo('channel-activation')
  declare channelActivation: AsyncBelongsTo<ChannelActivationModel>;

  @attr('string') declare status: string;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('raw') declare webhookConfig?: WebhookConfig;
  @attr('raw') declare webhookData: Record<string, unknown>;
  @attr('raw') declare responseErrors: Record<string, unknown>;
  @attr('boolean') declare isSuccessfull: boolean;
  @attr('boolean') declare skipRequestJob: boolean;

  get hasPreviousPage() {
    return (this.webhookConfig?.page || 1) > 1;
  }

  get hasNextPage() {
    return this.webhookConfig?.hasNextPage;
  }

  get requireLocation() {
    if (this.webhookConfig?.requireLocation !== undefined) {
      return this.webhookConfig.requireLocation;
    }

    return false;
  }

  get renderRedirectSummary() {
    return this.hasRedirectLink && get(get(this, 'promotion'), 'externalUrl');
  }

  get hasRedirectLink() {
    return this.webhookConfig?.hasRedirectLink;
  }

  get requireLocations() {
    if (this.webhookConfig?.requireLocations !== undefined) {
      return this.webhookConfig.requireLocations;
    }

    return false;
  }

  async previousPageConfig() {
    return this.server.memberAction(this, {
      action: 'previous_page_config',
      options: {
        data: this.serialize(),
      },
    });
  }

  async nextPageConfig() {
    return this.server.memberAction(this, {
      action: 'next_page_config',
      options: {
        data: this.serialize(),
      },
    });
  }

  async fetchDynamicField(arg: Record<string, unknown>) {
    const { webhook_data, channel_activation_id } = this.serialize() as {
      webhook_data: string;
      channel_activation_id: string;
    };
    const data = {
      webhook_data,
      channel_activation_id,
      ...arg,
    };

    return this.server.memberAction(this, {
      action: 'dynamic_config_field',
      method: 'GET',
      options: {
        data,
      },
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'promotion-request': PromotionRequestModel;
  }
}
