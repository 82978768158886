import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class MessageService extends Service {
  @tracked body: string | null = null;
  @tracked subject: string | null = null;
  @tracked newHire: boolean | null = null;

  reset() {
    this.body = null;
    this.subject = null;
    this.newHire = null;
  }
}

declare module '@ember/service' {
  interface Registry {
    'message-service': MessageService;
  }
}
