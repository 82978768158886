import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';

export default class IndexRoute extends ScrollToTopRoute {
  @service store;

  model() {
    return this.store.findAll('reject-reason');
  }
}
