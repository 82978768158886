import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SettingsQuestionsEditController extends Controller {
  @service router;

  @action
  handleModalClose() {
    this.router.transitionTo('settings.questions');
  }

  @action
  onModelSaved() {
    this.handleModalClose();
  }
}
