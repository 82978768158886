/* eslint-disable @typescript-eslint/no-explicit-any */
import { computePosition } from '@floating-ui/dom';

import Editor from '../editor';
import Menu, { Action, Translations } from '../menu';
import { buildSVGElement } from '../utils';
import * as icons from '../icons';
import Media from './media';
import TurnInto from './turn-into';

interface ItemElementOptions {
  leftIcon?: string;
  rightIcon?: string;
}

const popoverClasses = [
  'flex-col',
  'px-16',
  'py-8',
  'text-14',
  'overflow-visible',
];

export default class Settings extends Menu {
  constructor(editor: Editor, translations: Translations) {
    super(editor, translations);

    const actionsElement = this.editor.holder?.querySelector<HTMLElement>(
      '.ce-settings__default-zone'
    );

    if (actionsElement) {
      const observer = new MutationObserver(this.handleMutationEvent);

      observer.observe(actionsElement, {
        childList: true,
      });
    }
  }

  handleMutationEvent = (mutationList: MutationRecord[]) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'childList') {
        this.renderPopover();
      }
    }
  };

  async renderPopover() {
    const popover =
      this.editor.holder?.querySelector<HTMLElement>('.ce-settings');

    if (popover) {
      popover.classList.add(...popoverClasses);

      while (popover.firstElementChild) {
        popover.firstElementChild.remove();
      }

      const blockActions = [];
      if (
        this.editor.currentBlock?.name === 'image' &&
        this.editor.currentBlock.config?.type === 'image'
      ) {
        const media = new Media(this.editor);
        blockActions.push({
          id: 'size',
          icon: icons.arrowUpSmallBig,
          actions: await media.buildImageSizeActions(),
        });
      }

      if (
        ['heading', 'paragraph', 'list', 'quote'].includes(
          this.editor.currentBlock?.name || ''
        )
      ) {
        const turnInto = new TurnInto(this.editor);
        blockActions.push(...(await turnInto.getActions()));
      }

      const buttons = <Action[]>[
        ...blockActions,
        {
          id: 'up',
          icon: icons.up,
          onClick: async () => {
            const currentIndex =
              await this.editor.blocks.getCurrentBlockIndex();
            this.editor.blocks.move(currentIndex - 1);
            this.editor.toolbar.close();
          },
        },
        {
          id: 'down',
          icon: icons.down,
          onClick: async () => {
            const currentIndex =
              await this.editor.blocks.getCurrentBlockIndex();
            this.editor.blocks.move(currentIndex - 1);
            this.editor.toolbar.close();
          },
        },
        {
          id: 'delete',
          icon: icons.trash,
          onClick: async () => {
            const currentIndex =
              await this.editor.blocks.getCurrentBlockIndex();
            this.editor.blocks.delete(currentIndex);
            this.editor.toolbar.close();
          },
        },
      ];

      buttons.forEach((button) => {
        if (button.actions) {
          const { trigger, dropdown } = this.buildDropdownElement(
            (this.translations as any)[button.id] || button.id,
            button.actions,
            { leftIcon: button.icon, rightIcon: icons.chevronRight }
          );
          popover.appendChild(trigger);
          popover.appendChild(dropdown);
        } else if (button.onClick) {
          const item = this.buildItemElement(
            (this.translations as any)[button.id] || button.id,
            { leftIcon: button.icon }
          );
          item.addEventListener('click', button.onClick);
          popover.appendChild(item);
        }
      });
    }
  }

  buildDropdownElement(
    label: string,
    actions: Action[],
    options?: ItemElementOptions
  ): { trigger: HTMLElement; dropdown: HTMLElement } {
    const trigger = this.buildItemElement(label, options);
    const dropdown = document.createElement('div');
    dropdown.classList.add(
      'ce-popover',
      'ce-popover--opened',
      ...popoverClasses,
      'flex',
      'fixed',
      'w-max',
      'top-0',
      'left-0',
      '-mt-2',
      'hidden'
    );

    const showDropdown = () => {
      dropdown.classList.remove('hidden');
    };

    const positionAndShowDropdown = () => {
      computePosition(trigger, dropdown, {
        placement: 'right-start',
        strategy: 'fixed',
      }).then(({ x, y }) => {
        Object.assign(dropdown.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      });
      showDropdown();
    };

    const hideDropdown = () => {
      dropdown.classList.add('hidden');
    };

    trigger.addEventListener('mouseenter', positionAndShowDropdown);
    trigger.addEventListener('mouseleave', hideDropdown);
    dropdown.addEventListener('mouseenter', showDropdown);
    dropdown.addEventListener('mouseleave', hideDropdown);

    const hasIcon = actions.some((action) => action.icon !== undefined);

    actions.forEach((action) => {
      const item = this.buildItemElement(
        (this.translations as any)[action.id] || action.id,
        { leftIcon: action.icon }
      );

      if (hasIcon && !action.icon) {
        item.classList.add('!pl-24');
      }

      if (action.onClick) {
        item.addEventListener('click', action.onClick);
      }

      dropdown.appendChild(item);
    });

    return { trigger, dropdown };
  }

  buildItemElement(
    label: string,
    options?: ItemElementOptions
  ): HTMLButtonElement {
    const itemElement = document.createElement('button');
    itemElement.classList.add(
      'flex',
      'items-center',
      'px-8',
      'py-4',
      '-mx-8',
      'rounded-6',
      'hover:bg-neutral-weak'
    );

    const leftSvgElement = options?.leftIcon
      ? buildSVGElement(options.leftIcon)
      : undefined;

    if (leftSvgElement) {
      leftSvgElement.classList.add('w-16', 'h-16');
      itemElement.appendChild(leftSvgElement);
    }

    const labelElement = document.createElement('span');
    labelElement.classList.add('ml-8', 'mr-auto');
    labelElement.innerText = label;
    itemElement.appendChild(labelElement);

    const rightSvgElement = options?.rightIcon
      ? buildSVGElement(options.rightIcon)
      : undefined;

    if (rightSvgElement) {
      rightSvgElement.classList.add('w-16', 'h-16');
      itemElement.appendChild(rightSvgElement);
    }

    return itemElement;
  }
}
