import CandidateModalRoute from 'teamtailor/routes/candidate-modal';
import { inject as service } from '@ember/service';
import CompanyPageService from 'teamtailor/services/company-page';
import IntercomService from 'teamtailor/services/intercom';
import VideoMeetingCandidateController from 'teamtailor/controllers/video-meeting/candidate';
import { CandidateModel } from 'teamtailor/models';
import Transition from '@ember/routing/-private/transition';

export default class VideoMeetingCandidate extends CandidateModalRoute {
  @service declare companyPage: CompanyPageService;
  @service declare intercom: IntercomService;

  beforeModel() {
    this.companyPage.hideHeader = true;
  }

  afterModel() {
    this.intercom.hideWidget();
  }

  setupController(
    controller: VideoMeetingCandidateController,
    model: CandidateModel,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);

    const videoMeetingToken = this.modelFor('video-meeting');

    controller.videoMeetingToken = videoMeetingToken;
  }
}
