import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import { get } from 'teamtailor/utils/get';

export default class TValidationChangeset extends Helper {
  @service intl;

  compute([errorMessage]) {
    const error = JSON.parse(errorMessage);
    return get(this, 'intl').t(error.key, error.args);
  }
}
