import Component from '@glimmer/component';
import {
  CandidateModel,
  JobApplicationModel,
  AllModels,
  ModelKey,
} from 'teamtailor/models';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import CandidateModalFeedService from 'teamtailor/services/candidate-modal-feed';
import { getOwner } from '@ember/application';
import { get } from 'teamtailor/utils/get';
import { modifier } from 'ember-modifier';

export interface CandidateFeedSignature {
  Args: {
    candidate: CandidateModel;
    jobApplication?: JobApplicationModel | null | undefined;
    modelKey: ModelKey;
    globalFilter?: boolean;
  };
}

class Feed {
  declare candidateModalFeed: CandidateModalFeedService;
  declare modelKey: ModelKey;
  declare perPage?: number;

  @tracked declare candidate: CandidateModel;
  @tracked declare jobApplication?: JobApplicationModel | null | undefined;
  @tracked hasMore = false;

  page = 1;
  isCached = false;

  constructor(
    component: CachedFeed<CandidateFeedSignature['Args']>,
    modelKey: ModelKey,
    candidate: CandidateModel,
    jobApplication?: JobApplicationModel | null | undefined,
    perPage?: number
  ) {
    this.modelKey = modelKey;
    this.candidate = candidate;
    this.jobApplication = jobApplication;
    this.perPage = perPage;

    const owner = getOwner(component);
    this.candidateModalFeed = owner.lookup('service:candidate-modal-feed');
  }

  get params() {
    return {
      modelKey: this.modelKey,
      page: this.page,
      candidate: this.candidate,
      jobApplication: this.jobApplication,
      perPage: this.perPage,
    };
  }

  get records(): AllModels[] | undefined {
    const key = this.candidateModalFeed.getCacheKey(this.params);
    return get(this.candidateModalFeed, key as unknown as never);
  }

  async fetchRecords() {
    const { hasMore, page, isCached } = await this.candidateModalFeed.fetch(
      this.params
    );
    this.hasMore = hasMore;
    this.page = page;
    this.isCached = isCached;
  }

  async loadMore() {
    this.page = this.page + 1;
    await this.fetchRecords();
  }

  addRecord(record: AllModels) {
    this.candidateModalFeed.addRecord(record, this.params);
  }

  removeRecord(record: AllModels) {
    this.candidateModalFeed.removeRecord(record, this.params);
  }

  reset(
    candidate: CandidateModel,
    jobApplication?: JobApplicationModel | null | undefined
  ) {
    this.candidate = candidate;
    this.jobApplication = jobApplication;
    this.page = 1;
    this.hasMore = false;
  }
}

export default class CachedFeed<Args> extends Component<
  CandidateFeedSignature & Args
> {
  declare modelKey: ModelKey;
  declare feed: Feed;

  cache: { jobApplicationId: string | null; candidateId: string | null } = {
    jobApplicationId: null,
    candidateId: null,
  };

  constructor(
    owner: unknown,
    args: CandidateFeedSignature['Args'],
    modelKey: ModelKey,
    perPage?: number
  ) {
    super(owner, args);

    this.modelKey = modelKey;

    this.feed = new Feed(
      this,
      this.modelKey,
      this.args.candidate,
      this.args.jobApplication,
      perPage
    );
  }

  get isFirstLoad() {
    return this.feed.records === undefined;
  }

  async onJobApplicationChange() {
    this.feed.reset(this.args.candidate, this.args.jobApplication);
    await this.feed.fetchRecords();
  }

  onChangeJobApplication = modifier(
    (
      _: HTMLElement,
      [jobApplication]: [JobApplicationModel | null, boolean]
    ) => {
      const jobApplicationId = jobApplication?.id || null;
      const candidateId = this.args.candidate.id;

      if (
        this.cache.jobApplicationId !== jobApplicationId ||
        this.cache.candidateId !== candidateId
      ) {
        this.cache.jobApplicationId = jobApplicationId;
        this.cache.candidateId = candidateId;

        this.onJobApplicationChange();
      }
    }
  );

  @action
  async fetchRecords() {
    await this.feed.fetchRecords();
  }

  @action
  async loadMore() {
    await this.feed.loadMore();
  }

  @action
  addToFeed(record: AllModels) {
    this.feed.addRecord(record);
  }
}
