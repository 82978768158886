import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { cached, tracked } from '@glimmer/tracking';
import AnalyticsService from 'teamtailor/services/analytics';
import IntlService from 'ember-intl/services/intl';
import Settings, {
  ChartTypeInfo,
} from 'teamtailor/utils/insights/reports/settings';
import FilterSettings from 'teamtailor/utils/insights/reports/filter-settings';
import generateQuery from 'teamtailor/utils/insights/reports/generate-query';
import TtAlertService from 'teamtailor/services/tt-alert';
import FlipperService from 'teamtailor/services/flipper';

export default class EditReportController extends Controller {
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare analytics: AnalyticsService;
  @service declare ttAlert: TtAlertService;
  @service declare flipper: FlipperService;

  @tracked allCurrentSettings: Settings[] = [];

  @tracked showSaveModal = false;
  @tracked showScheduleReportModal = false;

  @tracked settings: string = this.model.settings;

  @tracked currentFilterSettings?: FilterSettings;

  getCurrentFilterSettings() {
    if (!this.currentFilterSettings) {
      this.currentFilterSettings = new FilterSettings(
        this.analytics.allProperties,
        this.analytics.allFilters
      );
    }

    return this.currentFilterSettings;
  }

  @cached
  get newestQueries() {
    return this.allCurrentSettings.map((settings) =>
      generateQuery(settings, this.getCurrentFilterSettings())
    );
  }

  get reportName() {
    return (
      this.report.name ||
      this.intl.t('insights.reports.builder.untitled_report')
    );
  }

  get availableChartTypes(): ChartTypeInfo[] {
    return [
      { name: 'bar', icon: 'chart-simple' },
      { name: 'line', icon: 'chart-line' },
      { name: 'pie', icon: 'chart-pie' },
      { name: 'table', icon: 'table' },
    ];
  }

  get report() {
    return this.model;
  }

  get hasChanges() {
    const settingsChanges = this.settings !== this.report.settings;
    return this.report.hasDirtyAttributes || settingsChanges;
  }

  @action
  addVisualization(chartType: ChartTypeInfo) {
    const settings = new Settings(this.analytics.allProperties, chartType);
    settings.editing = true;
    this.allCurrentSettings.unshiftObject(settings);
  }

  @action
  deleteVisualization(settings: Settings) {
    this.allCurrentSettings.removeObject(settings);
    this.handleUpdatedReportSettings();
  }

  @action
  onSave() {
    if (!this.report.name) {
      this.showSaveModal = true;
    } else {
      this.saveReport();
    }
  }

  @action
  async saveReport() {
    this.report.settings = this.settings;

    await this.report.save();

    this.showSaveModal = false;
  }

  @action
  handleUpdatedSettings() {
    if (!this.report.settings) {
      this.allCurrentSettings = [];
      this.currentFilterSettings = new FilterSettings(
        this.analytics.allProperties,
        this.analytics.allFilters
      );
    } else if (
      this.report.settings !==
      this.report.serializeSettings(
        this.allCurrentSettings,
        this.getCurrentFilterSettings()
      )
    ) {
      const combinedSettings = this.report.deserializeSettings(
        this.analytics.allFilters,
        this.analytics.allProperties,
        this.report.settings
      );

      this.allCurrentSettings = combinedSettings.chartSettings;
      this.currentFilterSettings = combinedSettings.filterSettings;
    }
  }

  @action
  handleUpdatedReportSettings() {
    this.settings = this.report.serializeSettings(
      this.allCurrentSettings,
      this.getCurrentFilterSettings()
    );
  }

  @action
  handleMove(settings: Settings, direction: 'up' | 'down') {
    const index = this.allCurrentSettings.indexOf(settings);
    const newIndex = direction === 'up' ? index - 1 : index + 1;
    this.allCurrentSettings.removeObject(settings);
    this.allCurrentSettings.insertAt(newIndex, settings);
    this.handleUpdatedReportSettings();
  }

  @action
  toggleSaveModal() {
    this.showSaveModal = !this.showSaveModal;
  }

  @action
  showDeleteConfirmation() {
    this.ttAlert.customConfirm({
      html: this.intl.t(
        'insights.reports.builder.action_bar.delete_confirmation_modal.description'
      ),

      title: this.intl.t(
        'insights.reports.builder.action_bar.delete_confirmation_modal.title'
      ),

      cancelButtonText: this.intl.t('common.go_back'),
      confirmButtonText: this.intl.t(
        'insights.reports.builder.action_bar.delete_confirmation_modal.delete_button'
      ),

      cancelCallback: () => undefined,
      confirmCallback: () => {
        this.report.destroyRecord().then(() => {
          this.router.transitionTo('analytics.custom-reports');
        });
      },

      text: '',
    });
  }

  @action
  toggleScheduleReportModal() {
    this.showScheduleReportModal = !this.showScheduleReportModal;
  }

  @action
  openSharePage() {
    const url = new URL(this.report.shareLink);
    url.searchParams.append('startDate', this.analytics.startDateParam || '');
    url.searchParams.append('endDate', this.analytics.endDateParam || '');
    window.open(url, '_blank');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'analytics.custom-reports.historic.edit': EditReportController;
  }
}
