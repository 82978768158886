import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { restartableTask } from 'ember-concurrency';
import { set, setProperties, action } from '@ember/object';
import { capitalize } from '@ember/string';
import jsonToCsvFile from 'teamtailor/utils/json-to-csv-file';
import colors, {
  activityTypeColors,
} from 'teamtailor/components/insights/charts/colors';
import activityKeys from 'teamtailor/components/insights/charts/activity-keys';
import { tracked } from '@glimmer/tracking';

export default class TeamActivityController extends Controller {
  @service analytics;
  @service reports;
  @service intl;
  @service store;

  @tracked sortDirection = 'desc';
  @tracked sortProperty = 'total';

  get activities() {
    return this.model.teamActivity.value?.rows ?? [];
  }

  chartOptionsTask = restartableTask(async () => {
    const userIds = this.chartUsers.mapBy('userId');
    const userRecords = await this.store.query('user', {
      ids: userIds,
      groupAnalytics: this.analytics.hasEnabledGroupAnalytics,
    });

    return {
      chart: { type: 'bar' },
      xAxis: {
        categories: this.chartUsers.map((row) => {
          const userRecord = userRecords.findBy('id', row.userId);
          return userRecord?.nameOrEmail || 'Deleted user';
        }),
      },

      yAxis: { min: 0, title: { enabled: false } },
      legend: { reversed: true },
      plotOptions: {
        series: {
          stacking: 'normal',
          borderColor: 'transparent',
          maxPointWidth: 50,
        },
      },
    };
  });

  get chartData() {
    return activityKeys.map(({ name: activityType, colorIndex }) => {
      const color = activityTypeColors[activityType] || colors[colorIndex];
      const intlActivityType = this.intl.t(
        `reports.team_activities.${activityType}`
      );

      return {
        name: capitalize(intlActivityType),
        data: this.chartUsers.map((user) => {
          return user[activityType];
        }),

        color,
      };
    });
  }

  get chartUsers() {
    return this.sortedActivities.sortBy('total').reverse().slice(0, 10);
  }

  get sortedActivities() {
    const activities = this.activities.sortBy(this.sortProperty);

    if (this.sortDirection === 'asc') {
      return activities;
    } else {
      return activities.reverse();
    }
  }

  @action
  async handleExport() {
    await this.store.query('user', {
      ids: this.activities.map((activity) => activity.userId),
      groupAnalytics: this.analytics.hasEnabledGroupAnalytics,
    });

    jsonToCsvFile(
      'team-activity',
      this.analytics.startDate,
      this.analytics.endDate,
      this.activities,
      (row) => ({
        name: row.nameOrEmail,
        comments: row.notes,
        sourced: row.sources,
        reviewed: row.reviews,
        messages: row.messages,
        scored: row.scores,
        moves: row.moves,
        rejected: row.rejections,
        hired: row.hires,
        interviews: row.interviews,
        user_id: row.userId,
      })
    );
  }

  @action
  handleSort(attribute) {
    if (this.sortProperty === attribute) {
      set(
        this,
        'sortDirection',
        this.sortDirection === 'desc' ? 'asc' : 'desc'
      );
    } else {
      setProperties(this, {
        sortProperty: attribute,
        sortDirection: 'desc',
      });
    }
  }
}
