import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { camelize } from '@ember/string';
import CurrentService from 'teamtailor/services/current';
import { AllModels } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';

type ModelsWithTranslations = Extract<AllModels, { translations: unknown }>;

interface TranslatableFormBaseComponentArgs {
  translatableModel: ModelsWithTranslations;
}

export default class TranslatableFormBaseComponent<
  Models extends ModelsWithTranslations = ModelsWithTranslations
> extends Component<TranslatableFormBaseComponentArgs> {
  @service declare current: CurrentService;

  @tracked tabLanguage: string | null = null;

  @tracked
  selectedTabIndex = 0;

  get translatableModel() {
    return this.args.translatableModel;
  }

  get translations(): Models['translations'] | undefined {
    return this.translatableModel.translations;
  }

  get defaultLanguageCode() {
    return this.translatableModel.languageCode;
  }

  get translatableData() {
    return this.tabLanguage
      ? this.translations?.findBy('languageCode', this.tabLanguage)
      : this.translatableModel;
  }

  get company() {
    return this.current.company;
  }

  get isTranslationFeatureEnabled() {
    return this.company.hasFeature('career_site_languages');
  }

  get shouldSaveTranslations() {
    return (
      this.isTranslationFeatureEnabled &&
      (this.translations?.toArray().length || 0) > 0
    );
  }

  get currentLanguageTab() {
    return this.tabLanguage || this.defaultLanguageCode;
  }

  get isTranslating() {
    return this.currentLanguageTab !== this.defaultLanguageCode;
  }

  handleTranslation(languageCode: string) {
    if (!this.translations) {
      return;
    }

    const translation = this.translations.findBy('languageCode', languageCode);
    if (!translation) {
      this.translations.createRecord({
        languageCode,
      });
    }
  }

  @action
  async saveTranslations() {
    if (this.shouldSaveTranslations) {
      const translatableModelName = camelize(
        this.translatableModel.constructor.modelName
      );

      const translations = await get(this.translatableModel, 'translations');

      translations.forEach((translation) => {
        if (translation.languageCode !== this.defaultLanguageCode) {
          set(
            translation,
            translatableModelName as keyof typeof translation,
            this.translatableModel
          );
          translation.save();
        }
      });
    }
  }

  @action
  handleTab(tab: { index: number; languageCode: string }) {
    const { index, languageCode } = tab;

    this.selectedTabIndex = index;
    const isDefaultLanguage = this.defaultLanguageCode === languageCode;
    this.tabLanguage = isDefaultLanguage ? null : languageCode;

    if (!isDefaultLanguage) {
      this.handleTranslation(languageCode);
    }
  }

  @action
  async handleSave() {
    return this.translatableModel.save().then(async () => {
      await this.saveTranslations();
    });
  }
}
