import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"col-span-6 grid grid-cols-6 gap-14\">\n  {{#each this.topUsers as |row|}}\n    <Insights::Molecules::UserCard\n      @activities={{row}}\n      @criteria=\"totalActivities\"\n      @user={{row.user}}\n      @disableHover={{true}}\n    />\n  {{/each}}\n</div>", {"contents":"<div class=\"col-span-6 grid grid-cols-6 gap-14\">\n  {{#each this.topUsers as |row|}}\n    <Insights::Molecules::UserCard\n      @activities={{row}}\n      @criteria=\"totalActivities\"\n      @user={{row.user}}\n      @disableHover={{true}}\n    />\n  {{/each}}\n</div>","moduleName":"teamtailor/components/insights/widgets/top-users.hbs","parseOptions":{"srcName":"teamtailor/components/insights/widgets/top-users.hbs"}});
import Component from '@glimmer/component';

interface UserActivity {
  hires: number;
  interviews: number;
  messages: number;
  moves: number;
  notes: number;
  rejections: number;
  reviews: number;
  scores: number;
  sources: number;
  userId: string;
  name: string;
  total: number;
}

type Args = {
  rows?: UserActivity[];
};

export default class InsightsWidgetsTopUsers extends Component<Args> {
  get topUsers() {
    return this.args.rows
      ?.sort((a, b) => {
        return b.total - a.total;
      })
      .slice(0, 6);
  }
}
