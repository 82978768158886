import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SettingsExternalRecruitmentRecruitingFirmEditRoute extends Route {
  @service store;

  async model(params) {
    let recruitingFirm = await this.store.findRecord(
      'recruiting-firm',
      params.recruiting_firm_id
    );

    return recruitingFirm;
  }
}
