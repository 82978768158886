import Service, { inject as service } from '@ember/service';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, get } from '@ember/object';

const STORAGE_KEY = 'quick-navigation-cache';
const CACHE_TIME = 300; // 5 minutes

export default class QuickNavigationCacheService extends Service {
  @service user;
  @service store;

  cache = null;

  @computed('user.id')
  get storageKey() {
    return `${STORAGE_KEY}-${get(this.user, 'id')}`;
  }

  constructor(owner) {
    super(owner);

    this.copyLegacyLocalStorage();

    this.cache = JSON.parse(sessionStorage.getItem(this.storageKey)) || [];
  }

  copyLegacyLocalStorage() {
    if (localStorage.getItem(this.storageKey)) {
      sessionStorage.setItem(
        this.storageKey,
        localStorage.getItem(this.storageKey)
      );
      localStorage.removeItem(this.storageKey);
    }
  }

  add(params, result) {
    this.clearExisting(params);
    const timestamp = Date.now();

    this.cache.unshiftObject({
      params,
      timestamp,
      result: this.serialize(result),
    });

    sessionStorage.setItem(this.storageKey, JSON.stringify(this.cache));
  }

  clear() {
    this.cache.clear();
    sessionStorage.removeItem(this.storageKey);
  }

  clearExisting(params) {
    const cachedObject = this.findCachedObject(params);
    if (cachedObject) {
      this.cache.removeObject(cachedObject);
    }
  }

  clearInvalid() {
    const now = Date.now();
    this.cache = this.cache.filter((cachedObject) => {
      return cachedObject.timestamp > now - CACHE_TIME * 1000;
    });
  }

  getResult(params) {
    this.clearInvalid();
    const cachedObject = this.findCachedObject(params);
    if (cachedObject) {
      return cachedObject.result.map((data) => {
        return this.normalize(data);
      });
    }

    return null;
  }

  findCachedObject(params) {
    const { filterByType, query } = params;
    return this.cache.find((cachedObject) => {
      const p = cachedObject.params;
      return p.filterByType === filterByType && p.query === query;
    });
  }

  serialize(result) {
    return result.map((model) => model.serialize());
  }

  normalize(rawData) {
    const {
      data: { attributes },
    } = this.store.normalize('search-result', rawData);

    return this.store.createRecord('search-result', attributes);
  }
}
