import ApplicationSerializer from './application';

export default class ContentBlockTestimonial extends ApplicationSerializer {
  payloadKeyFromModelName() {
    return 'content_block_testimonial';
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'content-block-testimonial': ContentBlockTestimonial;
  }
}
