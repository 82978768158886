import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import AccessLevelModel from 'teamtailor/models/access-level';
import Store from '@ember-data/store';
import ArrayProxy from '@ember/array/proxy';
import { isPresent } from '@ember/utils';

export default class AccessLevels extends Service.extend({}) {
  @service declare store: Store;
  @tracked all?: ArrayProxy<AccessLevelModel>;

  async loadAll(): Promise<void> {
    if (!this.all) {
      this.all = await this.store.findAll('access-level', {
        reload: true,
      });
    }
  }

  get roles() {
    return this.all?.filterBy('isAddon', false);
  }

  get addons() {
    return this.all?.filterBy('isAddon', true);
  }

  accessLevelWithDefault(role = 'no_access'): AccessLevelModel | undefined {
    return this.all?.findBy('name', role);
  }

  accessLevelIdsWithDefault(
    role = 'no_access',
    addons: string[] = []
  ): (string | undefined)[] {
    const accessLevelIds = [this.accessLevelWithDefault(role)?.id];
    if (role === 'designer') {
      accessLevelIds.push(this.accessLevelWithDefault('user')?.id);
    }

    if (isPresent(addons)) {
      addons.forEach((addon) => {
        const accessLevel = this.accessLevelWithDefault(addon);
        accessLevelIds.push(accessLevel?.id);
      });
    }

    return accessLevelIds;
  }
}

declare module '@ember/service' {
  interface Registry {
    'access-levels': AccessLevels;
  }
}
