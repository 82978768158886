export const STACKS = [
  { host: 'app.teamtailor.com', name: 'EU (Ireland)' },
  { host: 'app.na.teamtailor.com', name: 'US West (Oregon)' },
];

export interface Stack {
  host: string;
  name: string;
}

export function currentStack(stacks: Stack[] = STACKS) {
  const stack = stacks.find((stack) => stack.host === window.location.host);
  return stack ? stack : null;
}
