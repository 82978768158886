import TriggerSerializer from 'teamtailor/serializers/trigger';

export default TriggerSerializer.extend({
  attrs: {
    stage: { serialize: 'ids' },
    mappedPartnerCriteria: {
      key: 'mapped_partner_criteria_attributes',
      serialize: 'records',
      deserialize: 'ids',
    },
  },

  serialize() {
    const [commonJson, extras] = this.extractCommonJson(
      this._super(...arguments)
    );

    const {
      partner_activation_id,
      mapped_partner_criteria_attributes,
      ...data
    } = extras;

    return Object.assign(commonJson, {
      data,
      partner_activation_id,
      mapped_partner_criteria_attributes,
    });
  },
});
