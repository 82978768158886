import moment from 'moment-timezone';
import saveFile from 'teamtailor/utils/save-file';
import AverageRating from './average-rating';
import { Parser } from 'json2csv';
import { ColumnDefinition } from 'teamtailor/services/insights-export';
import IntlService from 'ember-intl/services/intl';

type ExportableRow = {
  [key: string]: string | number | boolean | AverageRating | null | undefined;
};

type FileNameOption = {
  baseFileName: string;
  startDate: Date;
  endDate?: Date;
};

type CsvRow = {
  [key: string]: string | number | boolean | null | undefined;
};

interface Exporter {
  downloadFile(exportOptions: FileNameOption): void;
}

export default class CsvFileExporter implements Exporter {
  rows: ExportableRow[];
  columnDefinitions: ColumnDefinition[];
  intl: IntlService;

  constructor(
    rows: ExportableRow[],
    columnDefinitions: ColumnDefinition[],
    intl: IntlService
  ) {
    this.rows = rows;
    this.columnDefinitions = columnDefinitions;
    this.intl = intl;
  }

  get translatedColumnKeys() {
    return this.columnDefinitions.map((columnDefinition) => {
      return this.intl.t(columnDefinition.headerKey);
    });
  }

  downloadFile(fileNameOptions: FileNameOption) {
    const csvFileContent = this.convertRowsToCsvFormat(
      this.convertToCsvUsingAllowedColumns()
    );

    saveFile(this.buildFilename(fileNameOptions), csvFileContent, 'text/csv');
  }

  convertToCsvUsingAllowedColumns() {
    const orderedAndFiltered = this.rows.map((row) => {
      const convertedRow: CsvRow = {};

      this.columnDefinitions.forEach((key) => {
        const value = row[key.propertyName];
        const translatedKey = this.intl.t(key.headerKey);

        if (value instanceof AverageRating) {
          convertedRow[translatedKey] = value.valueForCsv;
        } else {
          convertedRow[translatedKey] = value;
        }
      });

      return convertedRow;
    });

    return orderedAndFiltered;
  }

  convertRowsToCsvFormat(rows: CsvRow[]) {
    return new Parser(this.translatedColumnKeys).parse(rows);
  }

  buildFilename(options: FileNameOption) {
    const filename = [
      options.baseFileName,
      moment(options.startDate).format('YYYYMMDD'),
      moment(options.endDate).format('YYYYMMDD'),
    ].join('-');

    return `${filename}.csv`;
  }
}
