import { isEqual } from '@ember/utils';

export function percentageOf(set, total, decimals) {
  if (isEqual(total, 0)) {
    return 0;
  }

  return ((set / total) * 100).toFixed(decimals || 0);
}

export function dropOffCount(firstPoint, secondPoint) {
  return firstPoint - secondPoint;
}

export function dropOffPercentage(firstPoint, secondPoint, decimals) {
  if (isEqual(firstPoint, 0)) {
    return 0;
  }

  return ((dropOffCount(firstPoint, secondPoint) / firstPoint) * 100).toFixed(
    decimals || 0
  );
}
