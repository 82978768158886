import Model, {
  type AsyncBelongsTo,
  type AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import Server from 'teamtailor/services/server';
import CareerSiteModel from './career-site';
import NavigationItemModel from './navigation-item';
import PageModel from './page';
import { PushPayloadArg } from '.';

export default class PagePublicationModel extends Model {
  @service declare server: Server;
  @service declare store: Store;

  @belongsTo('career-site') declare careerSite: AsyncBelongsTo<CareerSiteModel>;
  @belongsTo('page', { async: false }) declare page: PageModel;
  @hasMany('navigation-item')
  declare navigationItems: AsyncHasMany<NavigationItemModel>;

  @attr('date') declare archivedAt?: Date;
  @attr('date') declare publishedAt?: Date;
  @attr('boolean', { defaultValue: false }) declare isPublishing: boolean;
  @attr('number') declare ownerId: number;
  @attr('string') declare name: string;
  @attr('boolean', { defaultValue: false })
  declare navigationLinkEnabled: boolean;

  @attr('string') declare navigationLinkTitle: string;
  @attr('string') declare navigationTitle: string;
  @attr('boolean') declare inNavigation: boolean;
  @attr('string') declare slug: string;
  @attr('string') declare humanStatus: string;
  @attr('string') declare url: string;

  get published() {
    return !!this.publishedAt;
  }

  async publish() {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'publish',
    });
    return this.store.pushPayload(response);
  }

  async unpublish() {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'unpublish',
    });
    return this.store.pushPayload(response);
  }

  async promoteChanges() {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'promote_changes',
    });
    return this.store.pushPayload(response);
  }

  async discardChanges() {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'discard_changes',
    });
    return this.store.pushPayload(response);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'page-publication': PagePublicationModel;
  }
}
