import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<DataTable::Cell\n  @data={{@data}}\n  @onMouseEnterColumn={{@onMouseEnterColumn}}\n  @onMouseExitColumn={{@onMouseExitColumn}}\n  @hovering={{@hovering}}\n  @lastRow={{@lastRow}}\n  class={{if this.zero \"zero\"}}\n>\n  {{this.formattedValue}}\n</DataTable::Cell>", {"contents":"<DataTable::Cell\n  @data={{@data}}\n  @onMouseEnterColumn={{@onMouseEnterColumn}}\n  @onMouseExitColumn={{@onMouseExitColumn}}\n  @hovering={{@hovering}}\n  @lastRow={{@lastRow}}\n  class={{if this.zero \"zero\"}}\n>\n  {{this.formattedValue}}\n</DataTable::Cell>","moduleName":"teamtailor/components/data-table/number.hbs","parseOptions":{"srcName":"teamtailor/components/data-table/number.hbs"}});
import Component from '@glimmer/component';

type NumberCellData = {
  value?: number;
  digits?: number;
};

type NumberCellArgs = {
  data: NumberCellData;
};

export default class DataTableNumberComponent extends Component<NumberCellArgs> {
  get value() {
    return this.args.data.value;
  }

  get zero() {
    return parseFloat(this.value?.toString() || '0') === 0;
  }

  get formattedValue() {
    const { digits } = this.args.data;

    if (digits !== undefined) {
      return this.value?.toFixed(digits);
    }

    return this.value;
  }
}
