import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

import ModalService from 'teamtailor/services/modal';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import FromRoute from 'teamtailor/routes/meetings/events/show';

export default class MeetingEventController extends Controller {
  declare model: ModelFrom<FromRoute>;
  @service declare modal: ModalService;

  @tracked fromCreate = false;

  @action
  handleClose(): void {
    this.modal.closeMeetingModal();
  }
}

declare module '@ember/controller' {
  interface Registry {
    'meetings.events.show': MeetingEventController;
  }
}
