import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import CurrentService from 'teamtailor/services/current';

export default class SettingsPaymentsIndexController extends Controller {
  @service declare current: CurrentService;

  get showSubscriptionSection() {
    return (
      this.current.company.customer && !this.current.company.hasParentCompany
    );
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.payments.index': SettingsPaymentsIndexController;
  }
}
