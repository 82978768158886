import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    trigger: { serialize: 'ids' },
    pickedQuestions: {
      serialize: 'records',
      deserialize: 'ids',
    },

    pickedLocations: {
      serialize: 'records',
      deserialize: 'ids',
    },

    rowOrder: { serialize: false },
  },
});
