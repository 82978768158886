import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex h-full flex-col items-center justify-center gap-24 p-32\">\n  <div\n    class=\"flex size-84 items-center justify-center rounded-full bg-copilot-strong\"\n  >\n    <Icon @icon=\"sparkles\" @size=\"48\" class=\"text-white\" />\n  </div>\n  <p class=\"heading-5 m-0 text-center text-copilot-weak\">\n    {{t \"job.suggested_candidates.enable.description\"}}\n  </p>\n  <StatusButton\n    @text={{t \"job.suggested_candidates.enable.button\"}}\n    @appearance=\"secondary\"\n    @action={{perform this.enableSuggestedCandidates}}\n  />\n</div>", {"contents":"<div class=\"flex h-full flex-col items-center justify-center gap-24 p-32\">\n  <div\n    class=\"flex size-84 items-center justify-center rounded-full bg-copilot-strong\"\n  >\n    <Icon @icon=\"sparkles\" @size=\"48\" class=\"text-white\" />\n  </div>\n  <p class=\"heading-5 m-0 text-center text-copilot-weak\">\n    {{t \"job.suggested_candidates.enable.description\"}}\n  </p>\n  <StatusButton\n    @text={{t \"job.suggested_candidates.enable.button\"}}\n    @appearance=\"secondary\"\n    @action={{perform this.enableSuggestedCandidates}}\n  />\n</div>","moduleName":"teamtailor/components/stage/suggested/enable.hbs","parseOptions":{"srcName":"teamtailor/components/stage/suggested/enable.hbs"}});
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';

import JobModel from 'teamtailor/models/job';

interface Args {
  job: JobModel;
}

export default class extends Component<Args> {
  enableSuggestedCandidates = dropTask(async () => {
    this.args.job.suggestedStageEnabled = true;
    await this.args.job.save();
  });
}
