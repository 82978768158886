import { prepareExistsOperator } from 'teamtailor/utils/operators/exists-operator';
import SelectedFilter from './filters/selected-filter';
import BaseProperty from './properties/base-property';
import { FilterOption } from 'teamtailor/components/fancy-filters';
import ModelProperty from './properties/model-property';
import 'ember-cached-decorator-polyfill';
import { cached } from '@glimmer/tracking';

export type QueryType = 'event' | 'pageview' | 'snapshot' | 'combined';

export default class BaseQuery {
  groupBys: BaseProperty[];
  properties: BaseProperty[];
  filters: SelectedFilter[];
  queryType: QueryType;
  sortProperty?: BaseProperty;
  sortDirection?: 'asc' | 'desc';
  limit?: number;
  page?: number;

  constructor(
    queryType: QueryType,
    groupBys: BaseProperty[] = [],
    properties: BaseProperty[] = [],
    filters: SelectedFilter[] = [],
    sortProperty?: BaseProperty,
    sortDirection?: 'asc' | 'desc',
    limit?: number,
    page?: number
  ) {
    this.groupBys = groupBys;
    this.properties = [...groupBys, ...properties];
    this.filters = filters;
    this.queryType = queryType;
    this.sortProperty = sortProperty;
    this.sortDirection = sortDirection;
    this.limit = limit;
    this.page = page;
  }

  @cached
  get combinedFilters() {
    const combinedFilters = [...this.filters];

    this.groupBys.forEach((property) => {
      const filter = this.filters.find(
        (filter) => filter.filter?.name === property.id
      );

      if (!filter && property.groupByAddsExistsFilter) {
        const existFilter = new SelectedFilter({
          id: 'id',
          name: property.id,
          type: '[Job]',
          eventFieldName: property.getField('event'),
          pageviewFieldName: property.getField('pageview'),
          combinedFieldName: property.getField('combined'),
          queryTypes: ['pageview', 'event', 'combined'],
          modelName:
            property instanceof ModelProperty ? property.model : undefined,

          operators: [prepareExistsOperator()],
        } as FilterOption);
        combinedFilters.push(existFilter);
      }

      if (property.groupByFilters) {
        property.groupByFilters.forEach((filter) => {
          combinedFilters.push(filter);
        });
      }
    });

    return combinedFilters;
  }

  get groupByStatement() {
    return this.groupBys.length > 0
      ? `groupBy: [${this.groupBys
          .map((property) => property.getKey(this.queryType))
          .join(', ')}]`
      : undefined;
  }

  get orderStatement() {
    return this.sortProperty
      ? `orderBy: { field: "${this.sortProperty.columnName}", desc: ${
          this.sortDirection === 'desc'
        } }`
      : undefined;
  }

  get limitStatement() {
    return this.limit !== undefined ? `limit: ${this.limit}` : undefined;
  }

  get pageStatement() {
    return this.page !== undefined ? `page: ${this.page}` : undefined;
  }

  get filterStatement() {
    let filterStrings = this.combinedFilters.map(
      (filter) => `{${filter.getString(this.queryType)}}`
    );
    this.filters.forEach((filter) => {
      if (this.queryType === 'pageview') {
        filter.filter?.pageviewAdditionalFilters?.forEach((filterString) => {
          filterStrings.push(filterString);
        });
      }
    });
    filterStrings = filterStrings.flat();
    return this.combinedFilters.length > 0
      ? `filters: [${filterStrings.join(', ')}]`
      : undefined;
  }

  get propertyStatements() {
    return this.properties
      .map((property) => property.getStatement(this.queryType))
      .join('\n');
  }
}
