export const exitReasons = {
  bounced: 'nurture_campaigns.exit_reasons.bounced',
  canceled: 'nurture_campaigns.exit_reasons.canceled',
};

export const TABLE_COLUMNS = [
  {
    label: 'common.campaign',
    sortable: true,
    sortColumn: 'campaign',
    defaultSortOrder: 'desc',
    width: 400,
  },
  {
    label: 'nurture_campaigns.nurture_campaign.entries',
    sortable: true,
    sortColumn: 'entries',
    defaultSortOrder: 'desc',
    width: 130,
  },
  {
    label: 'nurture_campaigns.nurture_campaign.exits',
    sortable: true,
    sortColumn: 'exits',
    defaultSortOrder: 'desc',
    width: 130,
  },
  {
    label: 'nurture_campaigns.nurture_campaign.active',
    sortable: true,
    sortColumn: 'active',
    defaultSortOrder: 'desc',
    width: 130,
  },
  {
    label: 'nurture_campaigns.touchpoints',
    sortable: true,
    sortColumn: 'touchpoints',
    defaultSortOrder: 'desc',
    width: 130,
  },
  {
    label: 'nurture_campaigns.open_rate',
    sortable: true,
    sortColumn: 'open_rate',
    defaultSortOrder: 'desc',
    width: 130,
  },
  {
    label: 'nurture_campaigns.hired',
    sortable: true,
    sortColumn: 'hired',
    defaultSortOrder: 'desc',
    width: 130,
  },
  {
    label: 'common.status',
    sortable: true,
    sortColumn: 'status',
    defaultSortOrder: 'desc',
    width: 130,
  },
];

export const LOADING_COLUMNS = [
  {
    width: '2xl',
  },
  {
    width: 's',
  },
  {
    width: 's',
  },
  {
    width: 's',
  },
  {
    width: 's',
  },
  {
    width: 's',
  },
  {
    width: 's',
  },
];
