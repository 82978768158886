import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { Transition } from 'teamtailor/utils/type-utils';

export default class SettingsSecuritySsoShowMappings extends ScrollToTopRoute {
  @service declare router: RouterService;

  redirect(_model: unknown, transition: Transition) {
    if (transition.to.localName !== 'edit') {
      this.router.transitionTo('settings.security.sso.show.mappings.new');
    }
  }
}
