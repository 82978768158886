import classic from 'ember-classic-decorator';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import TodoModel from 'teamtailor/models/todo';
import ActivityModel from 'teamtailor/models/activity';
import MeetingEventModel from 'teamtailor/models/meeting-event';

type StageCard = {
  id: string;
  stageId: string;
  meetings: MeetingEventModel[];
  activites: ActivityModel[];
  todos: TodoModel[];
};

@classic
export default class CandidatePreviewService extends Service {
  @tracked popoverDisplayable = true;
  @tracked hasSlowerDelay = true;
  @tracked stageCards: StageCard[] = [];
}

declare module '@ember/service' {
  interface Registry {
    'candidate-preview': CandidatePreviewService;
  }
}
