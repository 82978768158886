import ApplicationInstance from '@ember/application/instance';
import { gql } from '@apollo/client/core';
import ReportAnalyticsRequest, {
  FetchOptions,
} from './report-analytics-request';
import {
  AnalyticsReportBuilder,
  BuildReportArgs,
} from './analytics-report-builder';

const QUERY = gql`
  query NPSMovingAverageQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [NPS_RESPONSE]
      distinctBy: {
        fields: [JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
      companyIds: $companyIds
    ) {
      aggregated(groupBy: DATE) {
        date
        npsScore: average(
          field: NPS_SCORE
          filters: { npsScore: { exists: true } }
        )
        npsScoreResponses: countOccurrences(
          filters: { eventType: { equals: NPS_RESPONSE } }
        )
        movingAverageNpsScore
      }
    }
  }
`;

interface Result {
  eventQuery:
    | {
        aggregated: NpsMovingAverageRow[];
      }
    | undefined;
}

export interface NpsMovingAverageRow {
  date: string;
  npsScore: number;
  npsScoreResponses: number;
  movingAverageNpsScore: number;
}

class NpsMovingAverageReport {
  rows: NpsMovingAverageRow[];

  constructor(rows: NpsMovingAverageRow[]) {
    this.rows = rows;
  }
}

export function buildReport(args: BuildReportArgs) {
  const { container, compareOptions } = args;
  return new AnalyticsReportBuilder<
    NpsMovingAverageReport,
    NpsMovingAverageRow[]
  >(container, {
    query: async (options: FetchOptions = {}) => {
      return fetch(container, options);
    },

    compareOptions,

    createReport: (queryResult: NpsMovingAverageRow[]) => {
      return new NpsMovingAverageReport(queryResult);
    },
  });
}

export async function fetch(
  container: ApplicationInstance,
  options: FetchOptions = {}
): Promise<NpsMovingAverageRow[]> {
  const rows = await new ReportAnalyticsRequest({
    container,
    query: QUERY,
    callback: (result?: Result) => result?.eventQuery?.aggregated,
  }).fetch(options);

  return rows ?? [];
}
