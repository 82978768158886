export function createElement(
  tagName: string,
  classNames?: string[],
  text?: string
): HTMLElement {
  const element = document.createElement(tagName);
  if (classNames) {
    element.classList.add(...classNames);
  }

  if (text) {
    element.innerText = text;
  }

  return element;
}

export function buildSVGElement(icon: string): SVGElement | undefined {
  const div = document.createElement('div');
  div.innerHTML = icon;
  return div.querySelector('svg') || undefined;
}
