import IntlService from 'ember-intl/services/intl';
import jsonToCsvConverter from './json-to-csv-converter';
import saveFile from './save-file';

interface Property {
  name: string;
  type: string;
  default: boolean;
  doNotTranslateName?: boolean;
}

export default function downloadCustomReport(
  rows: any[],
  properties: Property[],
  intl: IntlService
): void {
  const objectsWithTranslatedKeys = rows.map((row) =>
    Object.keys(row).reduce((acc, _val, index) => {
      const property = properties[index];
      const propertyName = property?.name || '';
      const translatedPropertyName = property?.doNotTranslateName
        ? propertyName
        : intl.t(`custom_report.properties.${propertyName}`);

      return {
        ...acc,
        [translatedPropertyName]: row[index],
      };
    }, {})
  );

  const fileName = `custom-report-${new Date()}.csv`;

  saveFile(fileName, jsonToCsvConverter(objectsWithTranslatedKeys), 'text/csv');
}
