import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n  (hash\n    currentIndex=this.currentIndex\n    numberOfDots=this.numberOfDots\n    updateCurrentIndex=this.updateCurrentIndex\n  )\n}}", {"contents":"{{yield\n  (hash\n    currentIndex=this.currentIndex\n    numberOfDots=this.numberOfDots\n    updateCurrentIndex=this.updateCurrentIndex\n  )\n}}","moduleName":"teamtailor/components/insights/molecules/carousel.hbs","parseOptions":{"srcName":"teamtailor/components/insights/molecules/carousel.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';

export default class InsightsWidgetsJobAudience extends Component {
  @tracked currentIndex = 0;
  @alias('args.numberOfWidgets') numberOfDots;
}
