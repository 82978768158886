import { tracked } from '@glimmer/tracking';
export type TransitionDirection = 'feature-to-left' | 'feature-to-right';

export class TransitionHelper {
  @tracked direction: TransitionDirection;

  private static instance?: TransitionHelper;

  private constructor() {
    this.direction = 'feature-to-left';
  }

  public static getInstance(): TransitionHelper {
    if (!TransitionHelper.instance) {
      TransitionHelper.instance = new TransitionHelper();
    }

    return TransitionHelper.instance;
  }
}
