import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    upload: { serialize: 'records' },
    createdByTrigger: { serialize: false },
    privateNoteUsers: {
      serialize: 'ids',
    },

    user: { serialize: false },
    mentionedUsernames: { serialize: false },
    commentAuthor: { serialize: false },
    createdAt: { serialize: false },
    jobId: { serialize: false },
    mentionedUserIds: { serialize: false },
  },
});
