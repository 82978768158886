import { attr } from '@ember-data/model';
import Section from './section';

export default class ContentBlockCustomModel extends Section {
  @attr('string') declare html: string;
  @attr('string') declare css: string;
  @attr('boolean') declare useDefaultPadding: boolean;
  @attr('string') declare wrapperClass: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'content-block-custom': ContentBlockCustomModel;
  }
}
