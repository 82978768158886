import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"body-text-s mr-8 flex items-center rounded-8 px-8 py-6 font-medium\n    {{this.color}}\"\n>\n  <div class=\"flex items-center\">\n    {{@property.translatedName}}\n  </div>\n\n  {{#if this.showRemoveButton}}\n    <button\n      type=\"button\"\n      class=\"inline-flex items-center bg-transparent\"\n      {{on \"click\" this.remove}}\n    >\n      <Icon @icon=\"xmark\" @size=\"16\" />\n    </button>\n  {{/if}}\n</div>", {"contents":"<div\n  class=\"body-text-s mr-8 flex items-center rounded-8 px-8 py-6 font-medium\n    {{this.color}}\"\n>\n  <div class=\"flex items-center\">\n    {{@property.translatedName}}\n  </div>\n\n  {{#if this.showRemoveButton}}\n    <button\n      type=\"button\"\n      class=\"inline-flex items-center bg-transparent\"\n      {{on \"click\" this.remove}}\n    >\n      <Icon @icon=\"xmark\" @size=\"16\" />\n    </button>\n  {{/if}}\n</div>","moduleName":"teamtailor/components/insights/builder/selected-property.hbs","parseOptions":{"srcName":"teamtailor/components/insights/builder/selected-property.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import BaseProperty from 'teamtailor/utils/insights/reports/properties/base-property';

type Args = {
  property: BaseProperty;
  onRemove?: (p: BaseProperty) => void;
};

export default class SelectedPropertyComponent extends Component<Args> {
  get color(): string {
    const hasEvent = this.args.property.queryTypes.includes('event');
    const hasPageview = this.args.property.queryTypes.includes('pageview');

    if (hasEvent && hasPageview) {
      return 'bg-green-300';
    } else if (hasEvent) {
      return 'bg-blue-300';
    }

    return 'bg-yellow-300';
  }

  get showRemoveButton(): boolean {
    return !!this.args.onRemove;
  }

  @action
  remove() {
    this.args.onRemove?.(this.args.property);
  }
}
