import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import MeetingModel from 'teamtailor/models/meeting';
import QuestionModel from 'teamtailor/models/question';
import UploadModel from 'teamtailor/models/upload';

export default class PreparedMessageModel extends Model {
  @belongsTo('meeting')
  declare meeting: AsyncBelongsTo<MeetingModel>;

  @hasMany('question')
  declare questions: AsyncHasMany<QuestionModel>;

  @hasMany('upload')
  declare uploads: AsyncHasMany<UploadModel>;

  @attr('string')
  declare subject: string;

  @attr('string')
  declare body: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'prepared-message': PreparedMessageModel;
  }
}
