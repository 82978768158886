import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import AnalyticsService from 'teamtailor/services/analytics';

export default class AnalyticsReportsController extends Controller {
  @service declare analytics: AnalyticsService;
  @service declare router: RouterService;

  get startDate() {
    return this.analytics.startDateParam;
  }

  get endDate() {
    return this.analytics.endDateParam;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'analytics.custom-reports': AnalyticsReportsController;
  }
}
