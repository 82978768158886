import Controller from '@ember/controller';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import { TABLE_COLUMNS, TABLE_LOADING_COLUMNS } from 'teamtailor/constants/tag';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import { query } from 'ember-data-resources';

export default class SettingsTagsController extends Controller {
  queryParams = [
    'query',
    'tagged_type',
    'color',
    {
      sortColumn: 'sort_column',
      sortDirection: 'sort_direction',
    },
  ];

  @computedLocalStorage(Boolean, 'SettingsTagsIndex.showFilterSidebar', false)
  showFilterSidebar;

  @service router;
  @service server;
  @service flipper;
  @service flashMessages;
  @service current;
  @service store;

  @tracked sortColumn = 'name';
  @tracked sortDirection = 'asc';
  @tracked imageTagCount = null;
  @tracked candidateTagCount = null;
  @tracked jobTagCount = null;
  @tracked postTagCount = null;
  @tracked questionTagCount = null;
  @tracked deselectedTagIds = [];
  @tracked selectedTagIds = [];
  @tracked selectAll = false;
  @tracked isInitialFetch = true;
  @tracked query = '';
  @tracked tagged_type = null;
  @tracked color = null;
  @tracked page = 1;
  @tracked showBulk = false;

  tagColumns = TABLE_COLUMNS;

  tagsQuery = query(this, 'tag', () => {
    return {
      per_page: 25,
      query: this.query,
      color: this.color,
      tagged_type: this.tagged_type,
      sort_column: this.sortColumn,
      sort_direction: this.sortDirection,
      page: this.page,
    };
  });

  handleSearchInput = restartableTask(async (value) => {
    await timeout(1000);
    this.query = value;
    this.page = 1;
  });

  get tags() {
    return get(this.tagsQuery, 'records');
  }

  get totalCount() {
    return this.tags?.meta?.total_count;
  }

  get totalPages() {
    return this.tags?.meta?.total_pages;
  }

  get isDefaultCategory() {
    return this.tagged_type === null;
  }

  get isDefaultColor() {
    return this.color === null;
  }

  get isSettingsPage() {
    return this.router.currentRouteName === 'settings.tags.settings';
  }

  get isIndexPage() {
    return this.router.currentRouteName === 'settings.tags.index';
  }

  get loadingColumns() {
    return this.showBulk
      ? [{ width: 'xs' }, ...TABLE_LOADING_COLUMNS]
      : TABLE_LOADING_COLUMNS;
  }

  get selectedCount() {
    if (this.selectAll) {
      return this.totalCount - this.deselectedTagIds.length;
    } else {
      return this.selectedTagIds.length;
    }
  }

  @action isSelected(tag) {
    const id = get(tag, 'id');
    if (this.selectAll) {
      return !this.deselectedTagIds.includes(id);
    }

    return this.selectedTagIds.includes(id);
  }

  saveCompany = restartableTask(async () => {
    await this.current.company.save();
  });

  @action
  selectItem(attribute, selected) {
    set(this, attribute, selected ? get(selected, 'id') : null);
  }

  @action
  toggleSelectAll() {
    set(this, 'selectAll', !this.selectAll);

    this.deselectedTagIds.clear();
    this.selectedTagIds.clear();
  }

  @action
  clearSelection() {
    this.selectAll = false;
    this.deselectedTagIds.clear();
    this.selectedTagIds.clear();
  }

  @action
  toggleSettingsView() {
    if (this.isSettingsPage) {
      this.router.transitionTo('settings.tags.index');
    } else {
      this.router.transitionTo('settings.tags.settings');
    }
  }

  @action
  removeTagsFromList(ids) {
    ids.forEach((id) => {
      const tag = this.tags.find((tag) => get(tag, 'id') === id);
      tag.deleteRecord();
    });
  }

  @action
  selectAllEnabledUpdateSelected(isSelected, id) {
    if (isSelected) {
      this.deselectedTagIds.pushObject(id);
    } else {
      this.deselectedTagIds.removeObject(id);
    }
  }

  @action
  selectAllDisabledUpdateSelected(isSelected, id) {
    if (isSelected) {
      this.selectedTagIds.removeObject(id);
    } else {
      this.selectedTagIds.pushObject(id);
    }
  }

  @action
  handleSelectClick(tag) {
    const isSelected = this.isSelected(tag);
    const id = get(tag, 'id');
    if (this.selectAll) {
      this.selectAllEnabledUpdateSelected(isSelected, id);
    } else {
      this.selectAllDisabledUpdateSelected(isSelected, id);
    }
  }

  @action
  goToPage(page) {
    this.page = page;
  }
}
