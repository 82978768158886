import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import DateRange from 'teamtailor/utils/date-range';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';

export default class AnalyticsJobsIndexController extends Controller {
  @service store;
  @service analytics;
  @service current;

  get dateRange() {
    return new DateRange(
      moment(this.analytics.startDate).toDate(),
      moment(this.analytics.endDate).toDate()
    );
  }

  get selectedUser() {
    if (isPresent(this.userId)) {
      return this.store.findRecord('user', this.userId);
    } else {
      return undefined;
    }
  }

  @action
  setSortOrder(_key, { id }) {
    this.sortOrder = id;
  }
}
