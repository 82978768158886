import MeetingEditRoute from 'teamtailor/routes/meetings/edit';

export default class JobsJobActivityCandidateMeetingEditRoute extends MeetingEditRoute {
  controllerName = 'meetings.edit';
  templateName = 'meetings.edit';

  meetingRouteRouteName = 'jobs.job.activity.candidate.meetings';
  candidateRouteRouteName = 'jobs.job.activity.candidate.index';
  jobRouteRouteName = 'jobs.job';
}
