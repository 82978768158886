import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<img\n  class=\"team-member-avatar img-responsive\"\n  src={{this.src}}\n  title={{@text}}\n  alt={{@text}}\n/>", {"contents":"<img\n  class=\"team-member-avatar img-responsive\"\n  src={{this.src}}\n  title={{@text}}\n  alt={{@text}}\n/>","moduleName":"teamtailor/components/robo-hash.hbs","parseOptions":{"srcName":"teamtailor/components/robo-hash.hbs"}});
import Component from '@glimmer/component';
import { getRobohashUrl } from 'teamtailor/utils/robohash';

export default class RoboHashComponent extends Component {
  get src() {
    return getRobohashUrl(this.args.text);
  }
}
