import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Modal @onClose={{this.goBack}} as |modal|>\n  <modal.Header @channel={{@channel}} />\n  {{yield modal}}\n</Core::Modal>", {"contents":"<Core::Modal @onClose={{this.goBack}} as |modal|>\n  <modal.Header @channel={{@channel}} />\n  {{yield modal}}\n</Core::Modal>","moduleName":"teamtailor/components/promotion/modals/external.hbs","parseOptions":{"srcName":"teamtailor/components/promotion/modals/external.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class PromotionModalsExternal extends Component {
  @service router;

  @action
  goBack() {
    return this.router.transitionTo('jobs.job.promote');
  }
}
