/* eslint-disable ember/use-ember-get-and-set */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { BufferedChangeset } from 'ember-changeset/types';
import moment from 'moment-timezone';
import FromRoute from 'teamtailor/routes/settings/security/email-password/2fa-setup';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import TimeFormatService from 'teamtailor/services/time-format';
import { ModelFrom } from 'teamtailor/utils/type-utils';

type Option = {
  id: number;
  label: string;
  value?: Date | string;
};

export default class SettingsSecurityEmailPassword2faSetup extends Controller {
  declare model: ModelFrom<FromRoute>;

  @service declare timeFormat: TimeFormatService;
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;
  @service declare router: RouterService;
  @service declare current: Current;

  @tracked pickedOption?: Option;
  @tracked declare changeset: BufferedChangeset;

  get options(): Option[] {
    return [
      {
        id: 1,
        label: this.intl.t('common.immediately'),
        value: moment().toDate(),
      },
      {
        id: 2,
        label: this.intl.t('common.tomorrow'),
        value: moment().add(1, 'day').startOf('day').toDate(),
      },
      {
        id: 3,
        label: `1 ${this.intl.t('common.week', { count: 1 })}`,
        value: moment().add(1, 'week').startOf('day').toDate(),
      },
      {
        id: 4,
        label: `2 ${this.intl.t('common.week', { count: 2 })}`,
        value: moment().add(2, 'weeks').startOf('day').toDate(),
      },
      {
        id: 5,
        label: `1 ${this.intl.t('common.month', { count: 1 })}`,
        value: moment().add(1, 'month').startOf('day').toDate(),
      },
      {
        id: 6,
        label: this.intl.t(
          'settings.security.form.select_option_custom_date_time'
        ),

        value: moment().toDate(),
      },
    ];
  }

  get minDate() {
    return moment().startOf('day').toDate();
  }

  get locale() {
    return this.timeFormat.locale;
  }

  get enforceAt(): Date | null {
    return this.changeset.get('twoFactorAuthenticationEnforcedAt');
  }

  get customDateTime() {
    return this.enforceAt ?? this.minDate;
  }

  get showForm(): boolean {
    return !!this.enforceAt;
  }

  @action
  isSelected(option: Option): boolean {
    return this.pickedOption?.id === option.id;
  }

  @action
  toggleTwoFactorAuthentication(value: boolean): void {
    this.changeset.set(
      'twoFactorAuthenticationEnforcedAt',
      value ? this.minDate : null
    );
  }

  @action
  handleDateChange(date: Date): void {
    this.changeset.set('twoFactorAuthenticationEnforcedAt', date);

    this.selectOption({
      id: 6,
      label: this.intl.t(
        'settings.security.form.select_option_custom_date_time'
      ),

      value: date,
    });
  }

  @action
  selectOption(option: Option): void {
    this.changeset.set('twoFactorAuthenticationEnforcedAt', option.value);

    this.pickedOption = {
      ...option,
      ...(option.id !== 6 && {
        label: moment(option.value).format(`LL`),
      }),
    };
  }

  @action
  async handleSave(): Promise<void> {
    try {
      await this.changeset.save();
      this.flashMessages.success(
        this.intl.t('settings.security.alerts.enabled')
      );
      this.router.transitionTo(
        'settings.security.email-password.2fa-deactivate'
      );

      return Promise.resolve();
    } catch (error) {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
      return Promise.reject(error);
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.security.email-password.2fa-setup': SettingsSecurityEmailPassword2faSetup;
  }
}
