import { inject as service } from '@ember/service';
import { set, action } from '@ember/object';
import Controller from '@ember/controller';
import { get } from 'teamtailor/utils/get';

export default class AnswerController extends Controller {
  @service store;
  @service router;

  get answer() {
    return this.model;
  }

  get question() {
    return get(this.answer, 'question');
  }

  _goBackWithoutHistory() {
    const answer = get(this, 'answer');
    set(answer, 'newUploadUrl', undefined);
    set(this, 'newUploadFilename', undefined);

    this.router.replaceWith('candidates.candidate.edit');
  }

  @action
  modalClosedAction() {
    get(this, 'answer').rollbackAttributes();
    this._goBackWithoutHistory();
  }

  @action
  save() {
    const answer = get(this, 'answer');
    const upload = get(answer, 'upload.content');
    if (upload) {
      upload.unloadRecord();
    }

    return answer.save().then(this._goBackWithoutHistory.bind(this));
  }
}
