import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { ParseRequestBody } from 'teamtailor/models/single-sign-on';
import FromRoute from 'teamtailor/routes/settings/security/sso/new';
import FlashMessageService from 'teamtailor/services/flash-message';
import { ModelFrom } from 'teamtailor/utils/type-utils';

export default class SettingsSecuritySsoNew extends Controller {
  declare model: ModelFrom<FromRoute>;

  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  get availableUserRoles() {
    return this.model.availableUserRoles.map((role) => ({
      value: role,
      label: this.intl.t(`user.${role}`),
    }));
  }

  private fileToBase64 = (file: File): Promise<FileReader['result']> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        resolve(window.btoa(reader.result?.toString() ?? ''));
      };

      reader.onerror = () => {
        reject(reader.error);
      };

      reader.readAsBinaryString(file);
    });
  };

  private buildPostData = async (
    formData: FormData
  ): Promise<ParseRequestBody> => {
    const { meta_data_file, meta_data_file_url } = Object.fromEntries(
      formData.entries()
    ) as {
      meta_data_file?: File;
      meta_data_file_url?: string;
    };

    if (meta_data_file?.size && meta_data_file.size > 0) {
      const b64 = await this.fileToBase64(meta_data_file);
      return { meta_data_file: b64 };
    }

    if (meta_data_file_url?.length) {
      return { meta_data_file_url };
    }

    return Promise.reject();
  };

  @action
  async handleParseSubmit(event: SubmitEvent): Promise<void> {
    event.preventDefault();

    try {
      const data = await this.buildPostData(
        new FormData(event.target as HTMLFormElement)
      );

      await this.model.parseMetaDataFile(data);
      await this.model.save();
    } catch (error) {
      this.model.isParsing = false;
      this.flashMessages.error(
        this.intl.t('settings.security.sso.new.feedback_parse_failed')
      );
    }
  }

  @action
  async saveSettings(): Promise<void> {
    try {
      const model = await this.model.save();
      this.router.transitionTo('settings.security.sso.show', model);

      this.flashMessages.success(
        'Your Single Sign-On settings have been saved.'
      );
      return Promise.resolve();
    } catch (error) {
      this.flashMessages.error(
        this.intl.t('settings.security.sso.new.feedback_save_failed')
      );
      return Promise.reject(error);
    }
  }

  @action
  async handleDownloadMetadata(metadataFileUrl: string): Promise<Blob | void> {
    return fetch(metadataFileUrl, {
      headers: {
        Accept: 'application/xml',
        ContentType: 'application/xml',
      },
    })
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then((url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'metadata.xml';
        link.click();
        URL.revokeObjectURL(url);
      });
  }

  @action
  handleUserRoleChange(option: { value: string; label: string }) {
    set(this.model, 'defaultUserRole', option.value);
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.security.sso.new': SettingsSecuritySsoNew;
  }
}
