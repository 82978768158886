import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class EmployeeShareJob extends Controller {
  @service declare router: RouterService;

  @action
  onClose() {
    this.router.transitionTo('employee');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'employee.share-job': EmployeeShareJob;
  }
}
