import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    unpublishedChanges: { serialize: false },
    sharingImage: {
      key: 'sharing_image_attributes',
      serialize: 'records',
      deserialize: false,
    },
  },
});
