import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { CandidateModel, JobModel, UserModel } from 'teamtailor/models';

export default class ReferralModel extends Model {
  @service declare intl: IntlService;

  @belongsTo('job') declare job: AsyncBelongsTo<JobModel>;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;
  @belongsTo('candidate') declare candidate: AsyncBelongsTo<CandidateModel>;

  @attr('string') declare email: string;
  @attr('string') declare name: string;
  @attr('string') declare phone: string;
  @attr('string') declare comment: string;
  @attr('date') declare createdAt: Date;
  @attr('string') declare jobApplicationStatus: string;
  @attr('string') declare jobTitle: string;

  @attr('string') declare picture: string;
  @attr('string') declare initials: string;
  @attr('string') declare color: string;
  @attr('string') declare resume: string;

  @attr('date') declare lastActivityAt: Date;

  get nameOrEmail() {
    return this.name || this.email;
  }

  get translatedStatus() {
    return this.intl.t(`candidate.${this.jobApplicationStatus}`);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    referral: ReferralModel;
  }
}
