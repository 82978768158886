import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import ModalService from 'teamtailor/services/modal';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import FromRoute from 'teamtailor/routes/meetings/new';

export default class MeetingNewController extends Controller {
  declare model: ModelFrom<FromRoute>;
  @service declare modal: ModalService;

  @action handleClose(meetingId?: string, meetingEventId?: string) {
    this.modal.closeMeetingModal(meetingId, meetingEventId);
  }
}

declare module '@ember/controller' {
  interface Registry {
    'meetings.new': MeetingNewController;
  }
}
