import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#animated-if @visible use=this.transition duration=200}}\n  <div\n    class={{class-names\n      \"z-content-editor-settings w-full bg-canvas\"\n      (if\n        this.absolute\n        \"absolute h-[calc(100%-120px)] rounded-t-8 shadow-top-dark\"\n      )\n      (unless this.absolute \"scrollbar-dark-theme overflow-y-auto\")\n    }}\n  >\n    <div class=\"grid grid-cols-2 gap-[12px] p-12\">\n      {{yield}}\n    </div>\n  </div>\n{{/animated-if}}", {"contents":"{{#animated-if @visible use=this.transition duration=200}}\n  <div\n    class={{class-names\n      \"z-content-editor-settings w-full bg-canvas\"\n      (if\n        this.absolute\n        \"absolute h-[calc(100%-120px)] rounded-t-8 shadow-top-dark\"\n      )\n      (unless this.absolute \"scrollbar-dark-theme overflow-y-auto\")\n    }}\n  >\n    <div class=\"grid grid-cols-2 gap-[12px] p-12\">\n      {{yield}}\n    </div>\n  </div>\n{{/animated-if}}","moduleName":"teamtailor/components/editor/adjustments/forms/wrapper.hbs","parseOptions":{"srcName":"teamtailor/components/editor/adjustments/forms/wrapper.hbs"}});
import Component from '@glimmer/component';
import move from 'ember-animated/motions/move';
import { easeOut } from 'ember-animated/easings/cosine';
import { fadeOut, fadeIn } from 'ember-animated/motions/opacity';
import bezier from 'bezier-easing';
import { argDefault } from 'teamtailor/utils/arg-default';

export default class FormWrapperComponent extends Component {
  @argDefault absolute = true;

  *transition({ insertedSprites, removedSprites }) {
    for (let sprite of insertedSprites) {
      sprite.startAtPixel({ y: window.innerHeight });
      move(sprite, {
        easing: bezier(0.1, 0.95, 0.45, 1.05),
      });
      yield fadeIn(sprite);
    }

    for (let sprite of removedSprites) {
      sprite.endAtPixel({ y: window.innerHeight });
      move(sprite, { easing: easeOut });
      yield fadeOut(sprite);
    }
  }
}
