import { FilterType } from 'teamtailor/components/fancy-filters';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import EmberObject from '@ember/object';
import TimeFormatService from 'teamtailor/services/time-format';
import { tracked } from '@glimmer/tracking';
import { getOwner, setOwner } from '@ember/application';
import { Entries } from '../type-utils';
import { NestedOptions } from 'teamtailor/constants/candidate-columns';
import Store from '@ember-data/store';
import SelectedCandidateDisplayColumnModel from 'teamtailor/models/selected-candidate-display-column';

type CustomizeTableColumnPOJO = CustomizeTableColumn;

export default class CustomizeTableColumn {
  @service declare intl: IntlService;
  @service declare timeFormat: TimeFormatService;

  constructor(
    container: EmberObject | { store: Store },
    column: Partial<CustomizeTableColumnPOJO>
  ) {
    setOwner(this, getOwner(container));

    (Object.entries(column) as Entries<CustomizeTableColumnPOJO>).forEach(
      ([key, value]) => {
        // @ts-expect-error ts doesnt like looping over objects, remake this to set every field separately?
        this[key] = value;
      }
    );

    this.newInstance = () => {
      const newInstance = new CustomizeTableColumn(container, column);
      newInstance.category = this.category;
      newInstance.rect = this.rect;
      newInstance.type = this.type;
      newInstance.displayWithBadges = this.displayWithBadges;
      return newInstance;
    };
  }

  declare newInstance: () => CustomizeTableColumn;

  @tracked name = '';
  @tracked declare rowIcon?: string;
  @tracked type: FilterType = 'string';
  @tracked declare sortable?: boolean;
  @tracked declare sortColumnName?: string;
  @tracked defaultSortOrder?: string = 'desc';
  @tracked declare tableClassSuffix?: string;
  @tracked declare tableRowIcon?: string;
  @tracked declare value?: string | number;
  @tracked declare onlyHeaderCell?: boolean;
  @tracked declare selectable?: boolean;
  @tracked declare values?: any[];
  @tracked declare isCustomField?: boolean;
  @tracked declare id?: string;
  @tracked declare displayWithBadges?: boolean;
  @tracked declare category?: string;
  @tracked declare nested?: NestedOptions;
  @tracked declare doNotTranslateName?: boolean;
  @tracked declare _translatedName?: string;
  @tracked declare width?: number;
  @tracked declare parent?: CustomizeTableColumn;
  @tracked childColumns?: CustomizeTableColumn[] = [];
  @tracked ignoreParent = true;
  @tracked translationPrefix?: string;
  @tracked declare partnerName?: string;
  @tracked declare isDefault?: boolean;
  @tracked declare isDisabled?: boolean;
  @tracked declare rect?: DOMRectReadOnly;
  @tracked declare hideIcon?: boolean;

  get label() {
    return this.translatedName;
  }

  get translatedName() {
    if (this.doNotTranslateName) {
      return this._translatedName || this.name;
    }

    const intlArgs: { parentName?: string | number } = {};
    let intlString = this.defaultIntl;
    if (this.category === 'partner_results') {
      intlString += `.with_parent`;
      intlArgs.parentName = this.nested?.partnerName;
    }

    return this.intl.t(intlString, intlArgs);
  }

  set translatedName(value: string | undefined) {
    this._translatedName = value;
  }

  get translatedNameShort() {
    if (this.doNotTranslateName) {
      return this._translatedName || this.name;
    }

    let intlString = this.defaultIntl;
    if (this.category === 'partner_results') {
      intlString += `.without_parent`;
    }

    return this.intl.t(intlString);
  }

  get defaultIntl() {
    let keyName = this.name;
    if (this.nested?.name) {
      keyName = `${this.nested.name}.${keyName}`;
    }

    return `candidates.candidate.candidate_table.${keyName}`;
  }

  get sortColumn() {
    return this.sortColumnName || this.name;
  }

  get isRecruitmentColumn(): boolean {
    return this.category === 'external_recruiter';
  }

  get isQuestionColumn(): boolean {
    return this.category === 'answers_questions';
  }

  get isPartnerColumn(): boolean {
    return this.category === 'partner_results';
  }

  get isDateColumn(): boolean {
    return this.type === 'date';
  }

  get isStringColumn(): boolean {
    return this.type === 'string';
  }

  get isIntegerColumn(): boolean {
    return this.type === 'integer';
  }

  isMatchingColumn(column: SelectedCandidateDisplayColumnModel) {
    if (column.belongsTo('question').id()) {
      return (
        this.category === 'answers_questions' &&
        this.nested?.id === column.belongsTo('question').id()
      );
    } else if (column.belongsTo('partner').id()) {
      return (
        this.name === column.candidateColumnName &&
        this.category === 'partner_results' &&
        this.nested?.id === column.belongsTo('partner').id()
      );
    } else if (column.belongsTo('customField').id()) {
      return (
        this.isCustomField && this.id === column.belongsTo('customField').id()
      );
    } else {
      return this.name === column.candidateColumnName;
    }
  }
}

export const findMatchingSelectedColumnRecord = (
  column: CustomizeTableColumn,
  selectedColumnRecords: SelectedCandidateDisplayColumnModel[]
) => {
  return selectedColumnRecords.find(
    (selectedColumnRecord: SelectedCandidateDisplayColumnModel) =>
      column.isMatchingColumn(selectedColumnRecord)
  );
};
