import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<DataTable::Cell\n  @data={{@data}}\n  @onMouseEnterColumn={{@onMouseEnterColumn}}\n  @onMouseExitColumn={{@onMouseExitColumn}}\n  @hovering={{@hovering}}\n  @lastRow={{@lastRow}}\n  {{in-viewport onEnter=this.onEnter}}\n>\n  {{#if this.isLoading}}\n    <LoadingSpinner @size=\"small\" />\n  {{else if this.isSuccessful}}\n    <div local-class=\"root\" class=\"m-0 flex items-center text-left\">\n      <StarRatings @value={{this.averageRating}} />\n    </div>\n  {{/if}}\n</DataTable::Cell>", {"contents":"<DataTable::Cell\n  @data={{@data}}\n  @onMouseEnterColumn={{@onMouseEnterColumn}}\n  @onMouseExitColumn={{@onMouseExitColumn}}\n  @hovering={{@hovering}}\n  @lastRow={{@lastRow}}\n  {{in-viewport onEnter=this.onEnter}}\n>\n  {{#if this.isLoading}}\n    <LoadingSpinner @size=\"small\" />\n  {{else if this.isSuccessful}}\n    <div local-class=\"root\" class=\"m-0 flex items-center text-left\">\n      <StarRatings @value={{this.averageRating}} />\n    </div>\n  {{/if}}\n</DataTable::Cell>","moduleName":"teamtailor/components/data-table/average-rating.hbs","parseOptions":{"srcName":"teamtailor/components/data-table/average-rating.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import AverageRating from 'teamtailor/utils/average-rating';

type AverageRatingCellData = {
  value?: AverageRating | number;
};

type Args = {
  data: AverageRatingCellData;
};

export default class DataTableAverageRatingComponent extends Component<Args> {
  get value() {
    return this.args.data.value;
  }

  get averageRating() {
    return this.value instanceof AverageRating ? this.value.value : this.value;
  }

  get isLoading() {
    return this.value instanceof AverageRating
      ? this.value.fetchAverageRating.isRunning
      : false;
  }

  get isSuccessful() {
    return this.value instanceof AverageRating
      ? this.value.fetchAverageRating.lastSuccessful
      : true;
  }

  @action
  onEnter() {
    if (
      this.value instanceof AverageRating &&
      !this.value.fetchAverageRating.isRunning &&
      this.value.fetchAverageRating.performCount === 0
    ) {
      this.value.fetchAverageRating.perform();
    }
  }
}
