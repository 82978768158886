import Model, { belongsTo, attr } from '@ember-data/model';
import { fieldTypes } from 'teamtailor/constants/custom-fields';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';

export default class PickedCustomField extends Model {
  @tracked firstReload = true;

  @belongsTo('custom-field', { polymorphic: true }) customField;

  @attr('raw') value;
  @attr('string') name;
  @attr('string') ownerId;
  @attr('string') ownerType;
  @attr('string') fieldType;
  @attr('string') type;
  @attr('boolean') isHidden;
  @attr('boolean') isFeatured;
  @attr('boolean') isPrivate;
  @attr('boolean') isExternal;
  @attr('boolean') isRequired;
  @attr('string') customFieldId;
  @attr('number') rowOrder;

  get inputType() {
    return fieldTypes.find((field) => field.type === get(this, 'type'))
      .inputType;
  }

  get hasValue() {
    return get(this, 'value')?.toString().length > 0;
  }

  get lastValue() {
    return get(this, 'value');
  }
}
