import TriggerSerializer from 'teamtailor/serializers/trigger';

export default TriggerSerializer.extend({
  attrs: {
    stage: { serialize: 'ids' },
  },

  serialize() {
    let [commonJson, data] = this.extractCommonJson(this._super(...arguments));

    return Object.assign(commonJson, {
      data,
    });
  },
});
