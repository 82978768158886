export default {
  zuccini: '#17462e',
  nordic: '#00688B',
  sherpablue: '#00494e',
  deepteal: '#254855',
  daintree: '#567E3A',
  cyprus: '#0f4645',
  everglade: '#264334',
  capri: '#30436a',
  cobalt: '#273c5a',
  chambray: '#475877',
  astral: '#376f89',
  jellybean: '#44798e',
  calypso: '#3d7188',
  lochmara: '#316ea0',
  azure: '#4e699a',
  paradiso: '#488084',
  elm: '#297b76',
  eden: '#328573',
  aquamarine: '#205948',
  amazon: '#387b54',
  deepsea: '#167e65',
  fir: '#15633d',
  vikingblue: '#4db1c8',
  danube: '#5b89c0',
  bostonblue: '#438eac',
  glacier: '#78b1bf',
  riptide: '#89d9c8',
  aqua: '#92d3ca',
  avocado: '#98A148',
  reefgold: '#a98d36',
  strawberry: '#BE2625',
  coral: '#F08080',
  sienna: '#CD6839',
  apricot: '#FBA16C',
  tangerine: '#FF7216',
  pecan: '#E18E2E',
  khaki: '#8B864E',
  teal: '#388E8E',
  turquoise: '#00C5CD',
  skyblue: '#0099CC',
  cornflower: '#6495ED',
  ultramarine: '#120A8F',
  deeppurple: '#5D478B',
  violet: '#8A2BE2',
  plum: '#694489',
  orchid: '#6B238E',
  lavender: '#A74CAB',
  magenta: '#CD00CD',
  maroon: '#8E236B',
  violetred: '#CC3299',
  rose: '#FF6EC7',
  amethyst: '#9D6B84',
  raspberry: '#872657',
  broadwaypink: '#FF0066',
  crimson: '#DC143C',
  cranberry: '#F54D70',
  peach: '#FFADB9',
  bordeaux: '#99182C',
  burntsiena: '#B03517',
  burgundy: '#9E0508',
  kiwi: '#7F9A65',
  gablegreen: '#659D32',
  sapgreen: '#308014',
  limegreen: '#32CD32',
  emerald: '#5B9C64',
  bottlegreen: '#1DA237',
  malachite: '#108070',
  pacific: '#36DBCA',
  atlantic: '#2A8E82',
  seagreen: '#068481',
  carrot: '#fd9325',
  red: '#f14343',
  blue: '#6084ea',
  green: '#70b368',
  brown: '#9c7122',
  cyan: '#5fd0d0',
  mauve: '#da9eac',
  yellow: '#eace43',
  beige: '#eacc87',
  purple: '#c348c3',
  hazel: '#da8447',
  golden: '#e0b828',
  silver: '#bdbcb7',
  bronze: '#e29a2e',
  cherry: '#d64d80',
  ruby: '#e84b4b',
  pink: '#f574b5',
  navy: '#53529c',
  indigo: '#6953bf',
  olive: '#69736d',
  mint: '#9cc7ae',
  licorice: '#676767',
  mustard: '#FFCC11',
  blackberry: '#3A3A38',
  pineapple: '#FBDB0C',
  chartreuse: '#AADD00',
  iceberg: '#8FD8D8',
  brickred: '#F2473F',
  titanium: '#B6AFA9',
};
