import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { set, action } from '@ember/object';
import { get } from 'teamtailor/utils/get';

export default class NewController extends Controller {
  @service intl;
  @service user;
  @service router;
  @service table;
  @service flashMessages;
  @service accessLevels;
  @service flipper;
  @service current;

  get employee() {
    return this.model;
  }

  get roleAccessLevelName() {
    return this.accessLevels.roles.find((accessLevel) => {
      return this.employee.accessLevelIds.includes(accessLevel.id);
    }).name;
  }

  get fullErrorMessages() {
    return get(this, 'employee.fullErrorMessages').filter(
      (error) => !error.attribute.includes('company')
    );
  }

  @action
  handleSelectLocation(location) {
    const employee = get(this, 'employee');
    if (get(employee, 'location.id') === location.id) {
      set(employee, 'location', null);
    } else {
      set(employee, 'location', location);
    }
  }

  @action
  saveUser() {
    let employee = get(this, 'employee');
    return employee.save().then(() => {
      this.table.refreshEmployeesTable = true;
      this.router.transitionTo('employees.index');
      get(this, 'flashMessages').success(
        this.intl.t('employee.new.employee_added')
      );
    });
  }

  @action
  selectDepartment(department) {
    set(this, 'employee.department', department);
  }

  @action
  handleToggleHideEmail(value) {
    set(this, 'employee.hideEmail', !value);
  }

  @action
  handleToggleHidePhone(value) {
    set(this, 'employee.hidePhone', !value);
  }

  @action
  onChangeRole(role, addons) {
    set(this, 'employee.role', role);
    const accessLevelIds = this.accessLevels.accessLevelIdsWithDefault(
      role,
      addons
    );
    set(this, 'employee.accessLevelIds', accessLevelIds);
  }
}
