import { inject as service } from '@ember/service';
import ApplicationInstance from '@ember/application/instance';
import { gql } from '@apollo/client/core';
import { task } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';
import ReportAnalyticsRequest, {
  CLICKHOUSE_PAGEVIEWS_TRANSITION_DATE,
} from './report-analytics-request';
import {
  EventQueryResponse,
  EventTypeResponse,
  GoogleAnalyticsDeviceTypeResponse,
  GoogleAnalyticsQueryResponse,
  PageviewQueryResponse,
  PageviewTypeResponse,
} from 'teamtailor/utils/insights/graphql-response-types';
import IntlService from 'ember-intl/services/intl';

const INSIGHTS_GA_QUERY = gql`
  query GoogleAnalyticsDevicesQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    googleAnalyticsQuery {
      devices(dateRange: $dateRange, jobIds: $jobIds, companyIds: $companyIds) {
        numberOfApplications
        deviceType
        pageviews
        sessions
      }
    }
  }
`;

const INSIGHTS_PAGEVIEWS_QUERY = gql`
  query DeviceTypePageviewsQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    pageviewQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      companyIds: $companyIds
    ) {
      aggregated(groupBy: DEVICE_TYPE) {
        deviceType
        distinctCount(field: SESSION_ID)
        count
      }
    }
    eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [APPLIED]
      companyIds: $companyIds
    ) {
      aggregated(groupBy: [DEVICE_TYPE]) {
        deviceType: jobApplicationDeviceType
        count
      }
    }
  }
`;

type InsightsPageviewsResult = {
  pageviewQuery: PageviewQueryResponse;

  eventQuery: EventQueryResponse;
};

interface ModifiedEventTypeResponse extends EventTypeResponse {
  deviceType: string;
}

const formatInsightsData = (
  pageviews: PageviewTypeResponse[],
  applicationsData: ModifiedEventTypeResponse[]
): GoogleAnalyticsDeviceTypeResponse[] => {
  if (!pageviews.length) return [];

  const applicationsByDeviceType = applicationsData.reduce(
    (result: { [key: string]: number }, { deviceType, count }) => {
      result[deviceType] = count;
      return result;
    },
    {}
  );

  const rows = pageviews.map((row) => {
    const { deviceType, count, distinctCount } = row;
    const numberOfApplications = applicationsByDeviceType[deviceType] || 0;

    return {
      deviceType,
      pageviews: count,
      sessions: distinctCount,
      numberOfApplications,
    };
  });

  return rows;
};

class AudienceDevicesReport {
  @service declare intl: IntlService;

  container: ApplicationInstance;
  _rows: GoogleAnalyticsDeviceTypeResponse[] = [];

  constructor({ container }: { container: ApplicationInstance }) {
    this.container = container;
  }

  get rows() {
    return this._rows.map((device) => {
      return {
        deviceCategory: device.deviceType,
        name: this.intl.t(`reports.${device.deviceType}`),
        sessions: device.sessions,
        pageviews: device.pageviews,
        applications: device.numberOfApplications,
        conversionRate: device.numberOfApplications / device.sessions,
      };
    });
  }

  fetch = task(async () => {
    const request = new ReportAnalyticsRequest({
      container: this.container,
      query: INSIGHTS_GA_QUERY,
      callback: (result?: {
        googleAnalyticsQuery: GoogleAnalyticsQueryResponse;
      }) => result?.googleAnalyticsQuery.devices || [],
    });

    const googleAnalyticsRows = await request.fetch({
      before: CLICKHOUSE_PAGEVIEWS_TRANSITION_DATE,
    });

    const insightsData: InsightsPageviewsResult | undefined =
      await new ReportAnalyticsRequest({
        container: this.container,
        query: INSIGHTS_PAGEVIEWS_QUERY,
        callback: (result) => result,
      }).fetch({ after: CLICKHOUSE_PAGEVIEWS_TRANSITION_DATE });

    const clickhouseRows = formatInsightsData(
      insightsData?.pageviewQuery.aggregated || [],
      (insightsData?.eventQuery.aggregated || []) as ModifiedEventTypeResponse[]
    );

    this._rows = googleAnalyticsRows.reduce(
      (
        acc: GoogleAnalyticsDeviceTypeResponse[],
        row: GoogleAnalyticsDeviceTypeResponse
      ) => {
        const item = acc.findBy('deviceType', row.deviceType);
        if (item) {
          item.numberOfApplications += row.numberOfApplications;
          item.pageviews += row.pageviews;
          item.sessions += row.sessions;
        } else {
          acc = [...acc, row];
        }

        return acc;
      },
      clickhouseRows
    );

    return this._rows.map((device) => {
      return {
        deviceCategory: device.deviceType,
        name: this.intl.t(`reports.${device.deviceType}`),
        sessions: device.sessions,
        pageviews: device.pageviews,
        applications: device.numberOfApplications,
        conversionRate: device.numberOfApplications / device.sessions,
      };
    });
  });

  fetchTask = trackedTask(this, this.fetch, () => []);
}

export default AudienceDevicesReport;
