import SettingsLocationsIndexController from 'teamtailor/controllers/settings/locations';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';

export default class SettingsLocationsRoute extends ScrollToTopRoute {
  queryParams = {
    name_or_city: { refreshModel: true },
    region_id: { refreshModel: true },
    sort_column: { refreshModel: true },
    sort_direction: { refreshModel: true },
  };

  resetController(
    controller: SettingsLocationsIndexController,
    isExiting: boolean
  ) {
    if (isExiting) {
      controller.page = 1;
    }
  }
}
