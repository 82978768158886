import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import { triggerLabel as label } from 'teamtailor/utils/trigger-text';

export default class TriggerLabel extends Helper {
  @service intl;

  compute([type], labelArguments) {
    return label(this.intl.t.bind(this.intl), type, labelArguments);
  }
}
