import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { get } from 'teamtailor/utils/get';

export default class SettingsIntegrationsChannelActivationActivateRoute extends ScrollToTopRoute {
  @service ttAlert;
  @service intl;
  @service user;
  @service router;

  async model() {
    const channelActivation = this.modelFor(
      'settings.integrations.channel-activation'
    );

    const activationConfig = await get(
      channelActivation,
      'channelActivationFields'
    )();
    return hash({
      channelActivation,
      activationConfig,
    });
  }

  redirect(model) {
    if (get(model, 'channelActivation.channel.activationType') === 'oauth') {
      this.router.transitionTo(
        'settings.integrations.channel-activation.oauth'
      );
    }
  }
}
