import { later, scheduleOnce } from '@ember/runloop';

export default class ScrollPosition {
  declare scrollContainer?: HTMLElement;
  declare previousScrollHeight?: number;
  declare previousScrollTop?: number;

  ASYNC_CHILDREN_TIMEOUT = 500;

  constructor(element: HTMLElement) {
    this.scrollContainer = element;
  }

  setPosition(): void {
    this.previousScrollHeight = this.scrollContainer?.scrollHeight;
    this.previousScrollTop = this.scrollContainer?.scrollTop;
  }

  restorePosition(): void {
    const restorePosition = () => {
      if (
        !this.scrollContainer ||
        this.previousScrollHeight === undefined ||
        this.previousScrollTop === undefined
      ) {
        return;
      }

      const newContentHeight =
        this.scrollContainer.scrollHeight - this.previousScrollHeight;
      const scrollTopDifference =
        this.scrollContainer.scrollTop - this.previousScrollTop;

      const newScrollTop = newContentHeight + scrollTopDifference;

      this.scrollContainer.scrollTo(0, newScrollTop);

      this.previousScrollHeight = undefined;
      this.previousScrollTop = undefined;
    };

    scheduleOnce('afterRender', this, restorePosition);
  }

  scrollToBottom(): void {
    const scrollToButton = () => {
      if (this.scrollContainer) {
        this.scrollContainer.scrollTo(0, this.scrollContainer.scrollHeight);
      }
    };

    scheduleOnce('afterRender', this, scrollToButton);
    // Wait for async children to be added to the DOM, i.e images and metadata
    this.observeChildElements(scrollToButton);
  }

  observeChildElements(callback: () => void): void {
    if (!this.scrollContainer) {
      return;
    }

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'childList' &&
          mutation.addedNodes.length > 0 &&
          this.scrollContainer
        ) {
          const notAtBottom =
            this.scrollContainer.scrollTop + this.scrollContainer.clientHeight <
            this.scrollContainer.scrollHeight;

          if (notAtBottom) {
            callback();
          }
        }
      }
    });
    observer.observe(this.scrollContainer, { childList: true, subtree: true });

    later(this, () => observer.disconnect(), this.ASYNC_CHILDREN_TIMEOUT);
  }
}
