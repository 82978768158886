import Controller from '@ember/controller';

export default class SettingsIntegrationsWidgetsController extends Controller {
  queryParams = ['tab'];
  tab = 'job-list';
  tabs = [
    {
      name: 'job-list',
      i18nKey: 'settings.integrations.widget.job_list',
    },
    {
      name: 'career-button',
      i18nKey: 'settings.integrations.widget.career_button',
    },
    {
      name: 'hiring',
      i18nKey: 'settings.integrations.widget.hiring.title',
    },
  ];
}

declare module '@ember/controller' {
  interface Registry {
    'settings.integrations.widgets': SettingsIntegrationsWidgetsController;
  }
}
