import Route from '@ember/routing/route';
import { setProperties } from '@ember/object';
import moment from 'moment-timezone';

export default class extends Route {
  queryParams = {
    startDate: { refreshModel: true },
    endDate: { refreshModel: true },
  };

  model(params) {
    const job = this.modelFor('jobs.job');
    const startDate = params.startDate || moment().subtract(28, 'days');
    const endDate = params.endDate || moment().subtract(1, 'day');

    return { job, startDate, endDate };
  }

  setupController(controller, { startDate, endDate }) {
    super.setupController(...arguments);

    setProperties(controller, {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      setProperties(controller, { startDate: null, endDate: null });
    }
  }
}
