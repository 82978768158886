import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  SyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import {
  PageModel,
  DepartmentModel,
  RoleModel,
  PickedQuestionModel,
  CompanyModel,
  PickedImageModel,
  SectionChangeModel,
  ConnectMediaItemModel,
} from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';

export default class ConnectSettingModel extends Model {
  @attr('string') declare welcomeMessage: string;
  @attr('array') declare orderedPosts: string[];

  @hasMany('connect-media-item', { async: false })
  declare connectMediaItems: SyncHasMany<ConnectMediaItemModel>;

  @hasMany('picked-question', { async: false })
  declare pickedQuestions: SyncHasMany<PickedQuestionModel>;

  @hasMany('page', { inverse: null })
  declare blogPosts: AsyncHasMany<PageModel>;

  @belongsTo('page') declare page: AsyncBelongsTo<PageModel>;
  @belongsTo('department') declare department: AsyncBelongsTo<DepartmentModel>;
  @belongsTo('role') declare role: AsyncBelongsTo<RoleModel>;
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('picked-image', { async: true })
  declare cover: AsyncBelongsTo<PickedImageModel>;

  @belongsTo('section-change')
  sectionChange?: AsyncBelongsTo<SectionChangeModel>;

  @attr('array') declare changedFields?: string[];

  get sortedBlogPosts() {
    let blogPosts = this.blogPosts.toArray();

    blogPosts.sort((postA, postB) => {
      let postAIndex = this.orderedPosts.indexOf(postA.id);
      let postBIndex = this.orderedPosts.indexOf(postB.id);

      return postAIndex - postBIndex;
    });

    return blogPosts;
  }

  get sortedConnectMediaItems() {
    let mediaItems = this.connectMediaItems.toArray();

    mediaItems.sort((itemA, itemB) => {
      return itemA.rowOrder - itemB.rowOrder;
    });

    return mediaItems;
  }

  get questions() {
    return this.pickedQuestions.mapBy('question');
  }

  get hasQuestions() {
    return !!this.pickedQuestions.length;
  }

  get hasWelcomeMessage() {
    return !!this.welcomeMessage;
  }

  get hasCover() {
    return !!get(this.cover, 'id');
  }

  get hasChanges() {
    return !!this.changedFields?.length;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'connect-setting': ConnectSettingModel;
  }
}
