import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';
import { bind } from '@ember/runloop';

import User from 'teamtailor/models/user';
import Pusher, { PusherChannel } from 'teamtailor/services/pusher';
import Store from '@ember-data/store';
import Router from '@ember/routing/router-service';
import Current from 'teamtailor/services/current';

type QueryParams = {
  user_id: string;
};

type JobCreatedEventData = {
  id: string;
};

export default class IndexRoute extends Route {
  @service declare pusher: Pusher;
  @service declare current: Current;
  @service declare store: Store;
  @service declare router: Router;

  get user(): User {
    return this.current.user;
  }

  afterModel() {
    get(this, 'pusher')
      .channel(get(this.user, 'notificationsChannel'))
      .then((channel: PusherChannel) => {
        channel.bind('job-created', bind(this, this.handleJobCreatedEvent));
      });

    const params: QueryParams = this.paramsFor(
      get(this, 'routeName')
    ) as QueryParams;
    if (params.user_id) {
      return this.store.findRecord('user', params.user_id);
    }

    return undefined;
  }

  deactivate() {
    get(this, 'pusher')
      .channel(get(this.user, 'notificationsChannel'))
      .then((channel) => {
        channel.unbind('job-created', bind(this, this.handleJobCreatedEvent));
      });
  }

  handleJobCreatedEvent(data: JobCreatedEventData) {
    this.router.transitionTo('jobs.edit', data.id, {
      queryParams: { template: true },
    });
  }
}
