import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import CurrentService from 'teamtailor/services/current';
import RouterService from '@ember/routing/router';
import PickedQuestion from 'teamtailor/models/picked-question';
import FlipperService from 'teamtailor/services/flipper';
import ScorecardPickModel from 'teamtailor/models/scorecard-pick';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import SettingsInterviewKitsEditRoute from 'teamtailor/routes/settings/interview-kits/edit';
import TranslationInterviewKitModel from 'teamtailor/models/translation/interview-kit';
import { get } from 'teamtailor/utils/get';

export default class SettingsInterviewKitsEditController extends Controller {
  declare model: ModelFrom<SettingsInterviewKitsEditRoute>;

  @service declare current: CurrentService;
  @service declare router: RouterService;
  @service declare flipper: FlipperService;

  get interviewKit() {
    return this.model;
  }

  get currentLanguageCode() {
    return get(this.current.company.defaultCareerSite, 'languageCode');
  }

  rollbackChanges(): void {
    if (this.interviewKit.isDestroyed) {
      return;
    }

    this.interviewKit.rollbackAttributes();
    this.interviewKit.deleteNewAssociations();
    this.interviewKit.scorecardPicks.forEach((sp: ScorecardPickModel) =>
      sp.rollbackAttributes()
    );
    this.interviewKit.pickedQuestions.forEach((pq: PickedQuestion) =>
      pq.rollbackAttributes()
    );
  }

  async saveTranslations() {
    let promises: Promise<TranslationInterviewKitModel>[] = [];

    promises = this.interviewKit.translations
      .filter((translation: TranslationInterviewKitModel) => {
        return (
          get(translation, 'hasDirtyAttributes') &&
          translation.languageCode !== this.currentLanguageCode
        );
      })
      .map((translation: TranslationInterviewKitModel) => {
        return translation.save();
      });

    return Promise.all(promises);
  }

  @action
  handleCancel(): void {
    this.rollbackChanges();
    this.router.transitionTo('settings.interview-kits.index');
  }

  @action
  async handleSave() {
    this.interviewKit.competenceOrder = [...this.interviewKit.competenceOrder];
    await this.saveTranslations();
    await this.interviewKit.save();
    this.rollbackChanges();
    this.router.transitionTo('settings.interview-kits.index');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.interview-kits.edit': SettingsInterviewKitsEditController;
  }
}
