import { tracked } from '@glimmer/tracking';
import {
  IVideoMeeting,
  IVideoMeetingBase,
} from 'teamtailor/components/meeting/types';

export type MeetingEventLocationVideoMeetingAttributes = {
  id?: string;
  videoMeetingAttributes?: {
    id?: string;
    providerName?: string;
  };
  _destroy?: boolean;
};

export class VideoMeetingLocationClass {
  @tracked declare id?: string;
  @tracked declare videoMeeting?: IVideoMeeting;

  constructor(location: IVideoMeetingBase) {
    this.id = location.id;
    this.videoMeeting = location.videoMeeting;
  }

  static from(videoMeeting: IVideoMeeting): VideoMeetingLocationClass {
    return new VideoMeetingLocationClass({ videoMeeting });
  }

  get iconName() {
    switch (this.videoMeeting?.providerName) {
      case 'Google Meet':
        return 'google-meet';
      case 'ms_teams':
        return 'ms-teams';
      case 'zoom':
        return 'zoom';
      case 'go_to':
        return 'go-to';
      case 'teamtailor':
      default:
        return 'teamtailor';
    }
  }

  toAttributes(): MeetingEventLocationVideoMeetingAttributes {
    return {
      id: this.id,
      videoMeetingAttributes: {
        id: this.videoMeeting?.id,
        providerName: this.videoMeeting?.providerName,
      },
    };
  }
}
