import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{or @label (t \"common.recruiter\")}}\n  </span>\n  <Core::UserPicker\n    @onSelect={{@onChange}}\n    @selected={{@selected}}\n    @size=\"medium\"\n    @currentUser={{this.user}}\n    @nullOption={{t \"search.all\"}}\n    @placeholder={{t \"search.all\"}}\n    @searchPlaceholder={{t \"jobs.index.search_user\"}}\n  />\n</label>", {"contents":"<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{or @label (t \"common.recruiter\")}}\n  </span>\n  <Core::UserPicker\n    @onSelect={{@onChange}}\n    @selected={{@selected}}\n    @size=\"medium\"\n    @currentUser={{this.user}}\n    @nullOption={{t \"search.all\"}}\n    @placeholder={{t \"search.all\"}}\n    @searchPlaceholder={{t \"jobs.index.search_user\"}}\n  />\n</label>","moduleName":"teamtailor/components/sidebar/filters/user.hbs","parseOptions":{"srcName":"teamtailor/components/sidebar/filters/user.hbs"}});
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class extends Component {
  @service('user') declare user: any;
}
