import { computed, get } from '@ember/object';
import { isPresent } from '@ember/utils';

export default function groupBy(collection, property) {
  let dependentKey = `${collection}.@each.${property}`;

  return computed(dependentKey, function () {
    const groups = [];
    const items = get(this, collection);

    if (items) {
      items.forEach(function (item) {
        const value = get(item, property);
        let group = groups.findBy('value', value);

        if (isPresent(group)) {
          get(group, 'items').push(item);
        } else {
          group = { property, value, items: [item] };
          groups.push(group);
        }
      });
    }

    return groups;
  }).readOnly();
}
