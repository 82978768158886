import Model, { attr } from '@ember-data/model';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class FinnOccupationSpecialization extends Model {
  @service store;

  @attr('string') name;
  @attr('string') parentId;

  get parent() {
    return this.store.peekAll('finn-occupation').find((occupation) => {
      return get(occupation, 'id') === this.parentId;
    });
  }
}
