import ApplicationSerializer from './application';
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

export default class ImageSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    tags: { serialize: 'records', key: 'tag_list' },
  };

  serialize() {
    let json = super.serialize(...arguments);
    if (json.tag_list.length) {
      json.tag_list = json.tag_list.map((tag) => tag.name);
    }

    return json;
  }
}
