import Route from '@ember/routing/route';
import { StageModel } from 'teamtailor/models';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import JobsEditStagesQuickRejectSettingController from 'teamtailor/controllers/jobs/edit/stages/quick-reject-settings/edit';

export default class JobsEditStagesQuickRejectSettingsNew extends Route {
  @service declare store: Store;

  controllerName = 'jobs.edit.stages.quick-reject-settings.edit';
  templateName = 'jobs.edit.stages.quick-reject-settings.edit';

  model() {
    const stage = this.modelFor(
      'jobs.edit.stages.quick-reject-settings'
    ) as StageModel;

    return this.store.createRecord('quick-reject-setting', {
      stage,
    });
  }

  resetController(
    controller: JobsEditStagesQuickRejectSettingController,
    isExiting: boolean
  ) {
    if (isExiting) {
      controller.model.rollbackAttributes();
    }
  }
}
