import TranslationModel from 'teamtailor/models/translation';
import { attr, belongsTo, AsyncBelongsTo } from '@ember-data/model';
import RoleModel from '../role';

export default class TranslationRoleModel extends TranslationModel {
  @belongsTo('role') declare role: AsyncBelongsTo<RoleModel>;
  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'translation/role': TranslationRoleModel;
  }
}
