export const STAGE_TYPE_ICONS = {
  inbox: 'inbox',
  screening: 'file-magnifying-glass',
  interview: 'messages',
  offer: 'file-signature',
  hired: 'party-horn',
  rejected: 'ban',
};

export const DEFAULT_STAGE_ICON = 'arrow-right';
