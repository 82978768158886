import { helper } from '@ember/component/helper';

import { SCORECARD_SCORES } from 'teamtailor/constants/scorecard-scores';

export function scorecardScoreColorClass([score]: (number | null)[]): string {
  const color = SCORECARD_SCORES.findBy('value', score)?.color;
  return color ? `bg-${color}` : '';
}

export default helper(scorecardScoreColorClass);
