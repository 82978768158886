import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"mb-16\" {{did-insert this.setScores}}>\n  <h3 class=\"heading-5\">\n    {{t \"components.scorecard.personality\"}}\n  </h3>\n  <ScorecardScoreButtons\n    @scores={{this.personalityScores}}\n    @giveScore={{this.score}}\n  />\n</div>\n<div class=\"mb-16\">\n  <h3 class=\"heading-5\">\n    {{t \"components.scorecard.skills\"}}\n  </h3>\n  <ScorecardScoreButtons\n    @scores={{this.skillsScores}}\n    @giveScore={{this.score}}\n  />\n</div>\n<Textarea\n  class=\"mb-16\"\n  aria-label={{t \"components.scorecard.comment_your_scores\"}}\n  placeholder={{t \"components.scorecard.comment_your_scores\"}}\n  @value={{this.scorecardComment.comment}}\n  {{autoresize this.scorecardComment.comment}}\n/>\n<StatusButton\n  @action={{this.save}}\n  @text={{t \"components.scorecard.save_comment\"}}\n/>", {"contents":"<div class=\"mb-16\" {{did-insert this.setScores}}>\n  <h3 class=\"heading-5\">\n    {{t \"components.scorecard.personality\"}}\n  </h3>\n  <ScorecardScoreButtons\n    @scores={{this.personalityScores}}\n    @giveScore={{this.score}}\n  />\n</div>\n<div class=\"mb-16\">\n  <h3 class=\"heading-5\">\n    {{t \"components.scorecard.skills\"}}\n  </h3>\n  <ScorecardScoreButtons\n    @scores={{this.skillsScores}}\n    @giveScore={{this.score}}\n  />\n</div>\n<Textarea\n  class=\"mb-16\"\n  aria-label={{t \"components.scorecard.comment_your_scores\"}}\n  placeholder={{t \"components.scorecard.comment_your_scores\"}}\n  @value={{this.scorecardComment.comment}}\n  {{autoresize this.scorecardComment.comment}}\n/>\n<StatusButton\n  @action={{this.save}}\n  @text={{t \"components.scorecard.save_comment\"}}\n/>","moduleName":"teamtailor/components/scorecard-scorer.hbs","parseOptions":{"srcName":"teamtailor/components/scorecard-scorer.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { setProperties, action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

export default class ScorecardScorerComponent extends Component {
  @service current;
  @service store;

  @tracked personalityScores;
  @tracked skillsScores;
  @tracked scorecardComment;

  get scorecardPicks() {
    return get(this, 'args.job.jobDetail.scorecardPicks');
  }

  get _scorecardScores() {
    return get(this, 'args.job.jobDetail.scorecardScores');
  }

  get scorecardScores() {
    return this._scorecardScores.filterBy('isNew', false);
  }

  getScorecardComment() {
    const existingComment = get(this, 'args.job.scorecardComments').find(
      (comment) => {
        return (
          get(comment, 'user.id') === this.current.user.id &&
          get(comment, 'candidate.id') === this.args.candidate.id
        );
      }
    );

    return (
      existingComment ||
      this.store.createRecord('scorecard-comment', {
        candidate: this.args.candidate,
        user: this.current.user,
        job: this.args.job,
      })
    );
  }

  getScores(picks) {
    return picks.map((pick) => {
      const score = pick.scorecardScores
        .filterBy('candidate.id', this.args.candidate.id)
        .findBy('user.id', this.current.user.id);
      return score || pick.scorecardScores.createRecord();
    });
  }

  @action
  setScores() {
    this.skillsScores = this.getScores(
      get(this, 'args.job.jobDetail.skillsPicks')
    );

    this.personalityScores = this.getScores(
      get(this, 'args.job.jobDetail.personalityPicks')
    );

    this.scorecardComment = this.getScorecardComment();
  }

  @action
  score(scorecardScore, score) {
    setProperties(scorecardScore, {
      score,
      candidate: this.args.candidate,
    });
    scorecardScore.save();
  }

  @action
  save() {
    const promise = this.scorecardComment.save();
    promise.then(() => {
      this.args.onScoreSaved();
    });
    return promise;
  }
}
