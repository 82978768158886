import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.user.hasActiveStatus}}\n  <@menu.section @horizontalAlignment={{true}}>\n    <@menu.button\n      @emoji={{this.activeStatus.emoji}}\n      @text={{this.statusText}}\n      @onClick={{@onOpenUserStatusModal}}\n      @hideFocusIndicatorOnBlur={{true}}\n      @afterContentClass=\"!pl-0\"\n    />\n    <@menu.button\n      @liClass=\"!w-44\"\n      class=\"!w-32 !px-0\"\n      @appearance=\"tertiary\"\n      @icon=\"xmark\"\n      @onClick={{this.removeStatus}}\n      @afterContentClass=\"hidden\"\n      @tooltip={{t \"navbar.account_menu.clear_status\"}}\n    />\n  </@menu.section>\n{{else}}\n  <@menu.button\n    @text={{t \"navbar.account_menu.set_status\"}}\n    @icon=\"tree-palm\"\n    @onClick={{@onOpenUserStatusModal}}\n  />\n  <@menu.divider />\n{{/if}}", {"contents":"{{#if this.user.hasActiveStatus}}\n  <@menu.section @horizontalAlignment={{true}}>\n    <@menu.button\n      @emoji={{this.activeStatus.emoji}}\n      @text={{this.statusText}}\n      @onClick={{@onOpenUserStatusModal}}\n      @hideFocusIndicatorOnBlur={{true}}\n      @afterContentClass=\"!pl-0\"\n    />\n    <@menu.button\n      @liClass=\"!w-44\"\n      class=\"!w-32 !px-0\"\n      @appearance=\"tertiary\"\n      @icon=\"xmark\"\n      @onClick={{this.removeStatus}}\n      @afterContentClass=\"hidden\"\n      @tooltip={{t \"navbar.account_menu.clear_status\"}}\n    />\n  </@menu.section>\n{{else}}\n  <@menu.button\n    @text={{t \"navbar.account_menu.set_status\"}}\n    @icon=\"tree-palm\"\n    @onClick={{@onOpenUserStatusModal}}\n  />\n  <@menu.divider />\n{{/if}}","moduleName":"teamtailor/components/user-status.hbs","parseOptions":{"srcName":"teamtailor/components/user-status.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import Current from 'teamtailor/services/current';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import TimeFormatService from 'teamtailor/services/time-format';
import UserModel from 'teamtailor/models/user';
import UserStatusModel from 'teamtailor/models/user-status';

interface ComponentArgs {
  status: UserStatusModel;
}

export default class UserStatusComponent extends Component<ComponentArgs> {
  @service declare current: Current;
  @service declare intl: IntlService;
  @service declare timeFormat: TimeFormatService;

  get user(): UserModel {
    return this.current.user;
  }

  get activeStatus(): UserStatusModel | null {
    return this.user.activeStatus;
  }

  get statusText(): string {
    let statusText = `${this.activeStatus?.status}`;

    if (this.activeStatus?.endDate) {
      statusText += ` (${this.intl.t('components.user_status.until')} ${
        this.activeStatus.formattedEndDate
      })`;
    }

    return statusText.length > 25
      ? `${statusText.substring(0, 25)}...`
      : statusText;
  }

  @action
  removeStatus() {
    if (this.activeStatus) {
      this.activeStatus.isLastActive = false;
      this.activeStatus.save();
      this.user.activeStatus = null;
    }
  }
}
