import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { get, set, setProperties, action } from '@ember/object';

export default class GetCreditsController extends Controller {
  @service intl;
  @service current;
  @service router;
  @service flashMessages;

  get wallet() {
    return this.current.company.wallet;
  }

  get transaction() {
    return this.model;
  }

  get paymentProcessorSetting() {
    return get(this.current.company, 'paymentProcessorSetting');
  }

  get vatPercentage() {
    return get(this.paymentProcessorSetting, 'vatPercentage');
  }

  @action
  handleTakeoverCloseAction() {
    this.router.transitionTo('settings.sms');
  }

  @action
  selectPackage(selectedPackage) {
    setProperties(get(this, 'transaction'), {
      credits: selectedPackage.credits,
      code: selectedPackage.code,
    });
    set(this, 'currentPackage', selectedPackage);
  }

  @action
  purchase() {
    return get(this, 'transaction')
      .save()
      .then(() => {
        get(this, 'flashMessages').success(
          this.intl.t('settings.sms.get_credits.credits_added')
        );
        set(
          this,
          'wallet.balance',
          get(this, 'wallet.balance') + get(this, 'transaction.credits')
        );
        this.router.transitionTo('settings.sms', this.current.company.uuid);
      });
  }
}
