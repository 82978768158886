import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class StagesRoute extends Route {
  @service pusher;
  @service store;

  setupController(controller, model) {
    super.setupController(...arguments);
    this.pusher.subscribe(model.pusherChannel).then((channel) => {
      this.channel = channel;
      channel.bind('new-activity', (data) => {
        if (data.model_name && data.model_id) {
          this.store.findRecord(data.model_name, data.model_id);
        }

        if (data.candidate) {
          this.store.findRecord('candidate', data.candidate.id).then(() => {
            this.store.pushPayload(data);
          });
        }
      });
    });
  }

  resetController() {
    if (this.channel) {
      this.channel.unsubscribe();
      this.channel = null;
    }
  }
}
