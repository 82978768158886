import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class extends Route {
  @service router;

  beforeModel(/* transition */) {
    this.router.replaceWith('analytics.overview.pipeline-overview');
  }
}
