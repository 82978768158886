import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @date}}\n  <span ...attributes class=\"flex items-center\">\n    {{#if @icon}}\n      <Icon @icon={{@icon}} />\n    {{/if}}\n    <span class=\"ml-6 text-14\">\n      {{#if this.createdThisYear}}\n        {{moment-format @date \"DD MMM\"}}\n      {{else}}\n        {{moment-format @date \"DD MMM ’YY\"}}\n      {{/if}}\n      <EmberTooltip @text={{moment-from-now @date}} />\n    </span>\n  </span>\n{{/if}}", {"contents":"{{#if @date}}\n  <span ...attributes class=\"flex items-center\">\n    {{#if @icon}}\n      <Icon @icon={{@icon}} />\n    {{/if}}\n    <span class=\"ml-6 text-14\">\n      {{#if this.createdThisYear}}\n        {{moment-format @date \"DD MMM\"}}\n      {{else}}\n        {{moment-format @date \"DD MMM ’YY\"}}\n      {{/if}}\n      <EmberTooltip @text={{moment-from-now @date}} />\n    </span>\n  </span>\n{{/if}}","moduleName":"teamtailor/components/candidates-table/date-column.hbs","parseOptions":{"srcName":"teamtailor/components/candidates-table/date-column.hbs"}});
import Component from '@glimmer/component';

type CandidatesDateColumnArgs = {
  date: Date | string;
  icon?: string;
};

export default class CandidatesDateColumnComponent extends Component<CandidatesDateColumnArgs> {
  get createdThisYear(): boolean {
    return this.args.date instanceof Date
      ? this.args.date.getFullYear() === new Date().getFullYear()
      : false;
  }
}
