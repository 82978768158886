import Model, { AsyncHasMany, attr, hasMany } from '@ember-data/model';
import FormQuestionModel from './form-question';
import UploadModel from './upload';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import computeFullErrorMessages from 'teamtailor/utils/full-error-messages';
import Server from 'teamtailor/services/server';
import CandidateModel from './candidate';

type Options = {
  displayAnswersOnCandidatePage: boolean;
  subject?: string;
  title?: string;
  body?: string;
};

export default class SurveyModel extends Model {
  @service declare intl: IntlService;
  @service declare server: Server;

  formQuestionsSorting = Object.freeze('sortIndex');

  @attr('string')
  declare name: string;

  @attr('string')
  declare introText: string;

  @attr('string')
  declare outroTitle: string;

  @attr('string')
  declare outroText: string;

  @attr('string')
  declare formType: string;

  @attr('date')
  declare createdAt: Date;

  @attr('boolean')
  declare archived: boolean;

  @attr('number')
  declare responses: number;

  @attr('number') declare sends: number;

  @attr('raw') formQuestionsRaw: unknown;

  @hasMany('form-question')
  declare formQuestions: AsyncHasMany<FormQuestionModel>;

  @hasMany('upload')
  declare uploads: AsyncHasMany<UploadModel>;

  get fullErrorMessages() {
    return computeFullErrorMessages(this, this.intl);
  }

  get sortedFormQuestions() {
    return this.formQuestions.sortBy(this.formQuestionsSorting);
  }

  @attr('boolean', { defaultValue: true })
  declare allowSkip: boolean;

  @attr('boolean', { defaultValue: false })
  declare open: boolean;

  @attr('raw')
  declare options: Options;

  get responseRate() {
    if (this.sends > 0) {
      return Math.round((100 * this.responses) / this.sends);
    }

    return 0;
  }

  async toggleArchive(value: boolean) {
    return this.server.memberAction(this, {
      action: 'toggle_archive',
      queryParams: {
        archived: value.toString(),
      },
    });
  }

  async getResults(data: Record<string, unknown>) {
    const response = await this.server.memberAction<{
      candidates?: CandidateModel[];
    }>(this, {
      action: 'results',
      method: 'GET',
      options: {
        data,
      },
    });

    if (response.candidates) {
      this.store.pushPayload({ candidates: response.candidates });
    }

    return response;
  }

  async downloadEeoReport(startDate: string, endDate: string) {
    const queryParams = {
      date_start: startDate,
      date_end: endDate,
    };

    const response = await this.server.memberAction(this, {
      action: 'download_eeo_report',
      method: 'GET',
      queryParams,
    });

    return response;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    survey: SurveyModel;
  }
}
