import {
  BlockAPI,
  HTMLPasteEventDetail,
  PasteEvent,
} from '@editorjs/editorjs/types';
import {
  createElement,
  buildSVGElement,
} from 'teamtailor/utils/wysiwyg-editor/utils';
import {
  image as imageIconSvg,
  gif as gifIconSvg,
} from 'teamtailor/utils/wysiwyg-editor/icons';
import imageUrl from 'teamtailor/utils/image-url';

interface BlockData {
  imageId?: number;
  url?: string;
  caption?: string;
  imageSize?: 'default' | 'large' | 'full';
  type: 'image' | 'gif';
}
interface Args {
  data: BlockData;
  block: BlockAPI;
  config: Config;
}

interface Config {
  default?: string;
  large?: string;
  full?: string;
  gifPlaceholder?: string;
  imagePlaceholder?: string;
  captionPlaceholder?: string;
  openMediaLibrary?: (type: 'image' | 'gif', blockId: string) => void;
}

interface ImageNodes {
  wrapper?: HTMLElement;
  image?: HTMLImageElement;
  caption?: HTMLInputElement;
  placeholder?: HTMLElement;
}

export default class Image {
  block: BlockAPI;
  data: BlockData;
  config: Config;
  nodes: ImageNodes;

  constructor({ data, block, config }: Args) {
    this.block = block;
    this.config = config;
    this.data = {
      imageId: data.imageId,
      caption: data.caption,
      url: data.url,
      imageSize: data.imageSize,
      type: data.type,
    };

    this.nodes = {};
  }

  static get pasteConfig() {
    return {
      tags: ['IMG', 'FIGURE'],
    };
  }

  onPaste(event: PasteEvent) {
    const { data } = event.detail as HTMLPasteEventDetail;

    switch (event.type) {
      case 'tag': {
        const image = data as HTMLImageElement;
        this.data = {
          ...this.data,
          url: image.src,
          type: image.src.endsWith('.gif') ? 'gif' : 'image',
        };
        if (this.nodes.image) {
          this.nodes.image.src = image.src || '';

          if (this.data.type === 'image') {
            this.nodes.image.classList.add('w-full', 'object-fit');
          }
        }

        break;
      }
    }
  }

  render() {
    const wrapper = createElement('div', [
      'relative',
      'overflow-hidden',
      'flex',
      'flex-col',
    ]);

    this.nodes.wrapper = wrapper;

    if (this.data.url) {
      this.renderImage();
    } else {
      this.renderPlaceholder();
    }

    return wrapper;
  }

  renderPlaceholder(): void {
    if (this.nodes.wrapper) {
      const placeholderButton = createElement('button', [
        'bg-neutral-weak',
        'flex-col',
        'flex',
        'gap-16',
        'h-[390px]',
        'items-center',
        'justify-center',
        'rounded-10',
        'text-neutral-medium',
        'w-full',
        'transition-colors',
        'hover:bg-neutral-medium',
        'hover:text-neutral-medium',
      ]);

      placeholderButton.addEventListener('click', () => {
        if (this.config.openMediaLibrary) {
          this.config.openMediaLibrary(this.data.type, this.block.id);
        }
      });

      const icon = buildSVGElement(
        this.data.type === 'image' ? imageIconSvg : gifIconSvg
      )!;
      icon.classList.add('w-48', 'h-48');

      const textElement = createElement(
        'span',
        [],
        this.data.type === 'image'
          ? this.config.imagePlaceholder
          : this.config.gifPlaceholder
      );

      placeholderButton.appendChild(icon);
      placeholderButton.appendChild(textElement);
      this.nodes.wrapper.appendChild(placeholderButton);
      this.nodes.placeholder = placeholderButton;
    }
  }

  renderImage(): void {
    if (this.nodes.wrapper) {
      const image = document.createElement('img');
      this.block.config.type = this.data.type;
      this.nodes.wrapper.appendChild(image);

      if (this.data.type === 'image') {
        image.classList.add(
          'h-full',
          'm-auto',
          'max-h-[calc(2/3*750px)]',
          'max-w-full',
          'rounded-10'
        );
      }

      if (this.data.imageSize) {
        const size = document.createElement('div');
        size.innerText =
          this.config[this.data.imageSize] || this.data.imageSize;
        size.classList.add(
          'absolute',
          'rounded-tr-10',
          'rounded-bl-10',
          'text-14',
          'bg-black',
          'bg-opacity-20',
          'text-white',
          'px-12',
          'py-6'
        );

        imageLoaded(image).then((image) => {
          const imageStyle = window.getComputedStyle(image);
          let captionHeight = 0;

          if (this.nodes.caption) {
            const captionStyle = window.getComputedStyle(this.nodes.caption);
            captionHeight =
              parseInt(captionStyle.height, 10) +
              parseInt(captionStyle.marginTop, 10) +
              parseInt(captionStyle.marginBottom, 10);
          }

          size.style.left = imageStyle.marginLeft;
          size.style.bottom = `${
            captionHeight + parseInt(imageStyle.marginBottom, 10)
          }px`;
          this.nodes.wrapper?.appendChild(size);
        });
      }

      if (this.data.url) {
        image.dataset.imageId = this.data.imageId?.toString();
        image.dataset.originalUrl = this.data.url;
        image.src = imageUrl(this.data.url, 'gallery_picture') || this.data.url;
      }

      this.nodes.image = image;

      if (this.data.type === 'image') {
        const caption = document.createElement('input');
        caption.classList.add(
          'bg-transparent',
          'border-none',
          'mt-4',
          'py-2',
          'px-6',
          'text-16',
          'text-neutral-medium',
          'w-full',
          'placeholder:text-neutral-weak',
          'focus:outline-none',
          'no-global-styles'
        );
        caption.placeholder = this.config.captionPlaceholder || '';
        caption.value = this.data.caption || '';
        caption.addEventListener('input', (event) => {
          this.data.caption = (event.target as HTMLInputElement).value;
          this.block.dispatchChange();
        });
        this.nodes.wrapper.appendChild(caption);
        this.nodes.caption = caption;
      }
    }
  }

  save(wrapper: HTMLElement) {
    const image = wrapper.querySelector('img');
    return Object.assign(this.data, {
      imageId: image?.dataset.imageId,
      url: image?.dataset.originalUrl,
    });
  }
}

export function imageLoaded(
  image: HTMLImageElement
): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    if (image.complete && image.naturalWidth) {
      resolve(image);
    }

    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
  });
}
