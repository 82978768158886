import CandidateModel from 'teamtailor/models/candidate';
import IntlService from 'ember-intl/services/intl';
import imageUrl from 'teamtailor/utils/image-url';
import moment from 'moment-timezone';
import { HIRE_QUALITY_COLORS } from 'teamtailor/constants/hire-quality';

interface HireQualityResponseCounts {
  goodCount: number;
  poorCount: number;
  pendingCount: number;
}

interface Point {
  x: string;
  y: string;
  color: string;
  candidate: CandidateModel | undefined;
  hireQualityResponseCounts: HireQualityResponseCounts;
}

interface TooltipFormatterContextObject {
  point: Point;
  series: {
    data: Point[];
  };
}

export const HIRE_QUALITY_STATUS = {
  not_sent: 1,
  pending: 2,
  poor: 3,
  uncertain: 4,
  good: 5,
};

export const candidateHireQuality = (counts: HireQualityResponseCounts) => {
  const hireQualityStatus = HIRE_QUALITY_STATUS;
  const { goodCount, poorCount, pendingCount } = counts;

  if (goodCount > poorCount) {
    return hireQualityStatus.good;
  } else if (poorCount > goodCount) {
    return hireQualityStatus.poor;
  } else if (goodCount === poorCount && goodCount > 0) {
    return hireQualityStatus.uncertain;
  } else if (pendingCount > 0) {
    return hireQualityStatus.pending;
  } else {
    return hireQualityStatus.not_sent;
  }
};

export const badgeText = (
  counts: HireQualityResponseCounts,
  intl: IntlService
) => {
  const hireQuality = candidateHireQuality(counts);

  switch (hireQuality) {
    case HIRE_QUALITY_STATUS.good:
      return intl.t('reports.hire_quality.good');
    case HIRE_QUALITY_STATUS.poor:
      return intl.t('reports.hire_quality.poor');
    case HIRE_QUALITY_STATUS.pending:
      return intl.t('reports.hire_quality.pending');
    case HIRE_QUALITY_STATUS.not_sent:
      return intl.t('reports.hire_quality.not_sent');
    default:
      return '';
  }
};

export const badgeColor = (counts: HireQualityResponseCounts) => {
  const hireQuality = candidateHireQuality(counts);

  switch (hireQuality) {
    case HIRE_QUALITY_STATUS.good:
      return HIRE_QUALITY_COLORS.good;
    case HIRE_QUALITY_STATUS.poor:
      return HIRE_QUALITY_COLORS.poor;
    case HIRE_QUALITY_STATUS.pending:
      return HIRE_QUALITY_COLORS.pending;
    case HIRE_QUALITY_STATUS.not_sent:
      return HIRE_QUALITY_COLORS.not_sent;
    default:
      return '';
  }
};

const avatar = (candidate: CandidateModel) => {
  if (candidate.picture) {
    const imgUrl = imageUrl(candidate.picture, 'employee_picture');
    return `<img class='c-avatar-image__image c-avatar-image--x-small' src='${imgUrl}' />`;
  } else {
    return `<span class='c-avatar-image--initials size-24 rounded-full' style="background: ${candidate.color}">${candidate.initials}</span>`;
  }
};

const tooltipHeader = (point: Point, intl: IntlService) => {
  const { candidate } = point;
  const name = candidate
    ? candidate.nameOrEmail
    : intl.t('insights.common.deleted_candidate');

  return `
    <span class='flex flex-row gap-8'>
      ${candidate ? avatar(candidate) : ''}
      <span class='body-text-semibold'>${name}</span>
    </span>
  `;
};

const tooltipInfoItem = (label: string, info: string) => {
  return `
    <span class='flex justify-between'>
      <span class='body-text-s'>${label}:</span>
      <span class='body-text-s-semibold'>${info}</span>
    </span>
  `;
};

const timeToHire = (point: Point, intl: IntlService) => {
  const label = intl.t('reports.time_to_hire');
  const days = `${point.y} ${intl.t('common.days')}`;

  return tooltipInfoItem(label, days);
};

const hireQualityResponseBadgeItem = (text: string, color: string) => {
  return `
    <span class='
      bg-decorative-${color}-weak text-decorative-${color}-weak rounded-full py-2 px-6 body-text-xxs-medium
    '>
      ${text}
    </span>
  `;
};

const hireQualityResponseBadge = (
  counts: HireQualityResponseCounts,
  intl: IntlService
) => {
  const text = badgeText(counts, intl);
  const color = badgeColor(counts);
  return hireQualityResponseBadgeItem(text, color);
};

const hireQualityResponseBadges = (
  counts: HireQualityResponseCounts,
  intl: IntlService
) => {
  const { goodCount, poorCount, pendingCount } = counts;

  const goodText = `${intl.t('reports.hire_quality.good')}: ${goodCount}`;
  const poorText = `${intl.t('reports.hire_quality.poor')}: ${poorCount}`;
  const pendingText = `${intl.t(
    'reports.hire_quality.pending'
  )}: ${pendingCount}`;

  return `
    <span class="flex flex-row gap-8 ml-8">
      ${
        goodCount
          ? hireQualityResponseBadgeItem(goodText, HIRE_QUALITY_COLORS.good)
          : ''
      }
      ${
        poorCount
          ? hireQualityResponseBadgeItem(poorText, HIRE_QUALITY_COLORS.poor)
          : ''
      }
      ${
        pendingCount
          ? hireQualityResponseBadgeItem(
              pendingText,
              HIRE_QUALITY_COLORS.pending
            )
          : ''
      }
    </span>
  `;
};

export const showMultipleBadges = (counts: HireQualityResponseCounts) => {
  const { goodCount, poorCount, pendingCount } = counts;
  return (
    (goodCount > 0 && poorCount > 0) ||
    (goodCount > 0 && pendingCount > 0) ||
    (poorCount > 0 && pendingCount > 0)
  );
};

const hireQualityResponseCounts = (
  counts: HireQualityResponseCounts,
  intl: IntlService
) => {
  if (showMultipleBadges(counts)) {
    return hireQualityResponseBadges(counts, intl);
  } else {
    return hireQualityResponseBadge(counts, intl);
  }
};

const hireQualityResponses = (point: Point, intl: IntlService) => {
  const label = intl.t('reports.hire_quality.quality_of_hire');
  const answers = hireQualityResponseCounts(
    point.hireQualityResponseCounts,
    intl
  );

  return tooltipInfoItem(label, answers);
};

const dateHired = (point: Point, intl: IntlService) => {
  const label = intl.t('reports.date_hired');
  const date = moment(point.x).format('YYYY-MM-DD');

  return tooltipInfoItem(label, date);
};

const tooltipBody = (
  point: Point,
  intl: IntlService,
  showHireQualityInfo: boolean
) => {
  return `
    <span class='flex flex-col gap-4'>
      ${timeToHire(point, intl)}
      ${showHireQualityInfo ? hireQualityResponses(point, intl) : ''}
      ${dateHired(point, intl)}
    </span>
  `;
};

const overlappingPoints = (ctx: TooltipFormatterContextObject) => {
  const hoveredPoint = ctx.point;
  const allPoints = ctx.series.data;

  return allPoints.filter(
    (point) => point.x === hoveredPoint.x && point.y === hoveredPoint.y
  );
};

const tooltip = (
  point: Point,
  intl: IntlService,
  showHireQualityInfo: boolean
) => {
  return `
    <span class='flex flex-col min-w-[228px] gap-12'>
      ${tooltipHeader(point, intl)}
      ${tooltipBody(point, intl, showHireQualityInfo)}
    </span>
  `;
};

export const formatTooltip = (
  ctx: TooltipFormatterContextObject,
  intl: IntlService,
  showHireQualityInfo: boolean
) => {
  const pointsOverlap = overlappingPoints(ctx);
  if (pointsOverlap.length > 1 && pointsOverlap.length <= 100) {
    return `
      <span class='flex flex-col gap-16 max-h-[360px] overflow-y-auto pointer-events-auto'>
        ${pointsOverlap
          .map((point) => {
            return tooltip(point, intl, showHireQualityInfo);
          })
          .join('')}
      </span>
    `;
  } else {
    return tooltip(ctx.point, intl, showHireQualityInfo);
  }
};
