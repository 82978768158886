import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class extends Controller {
  @service intl;
  @service ttAlert;

  get hasDefaultPolicy() {
    return !this.model.custom;
  }

  get policyPreview() {
    return this.model.body;
  }

  @action
  restoreDefaultPrivacyPolicy() {
    const message = this.intl.t('components.trigger_modal.cannot_be_undone');
    this.ttAlert.confirm(message, () => {
      return this.model.destroyRecord();
    });
  }
}
