import RSVP from 'rsvp';
import { get, set } from '@ember/object';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';

import { inject as service } from '@ember/service';

export default class NewRoute extends ScrollToTopRoute {
  @service current;
  @service permissions;
  @service router;
  @service store;

  beforeModel() {
    if (!this.permissions.has('requisition/create')) {
      this.router.transitionTo('dashboard');
    }
  }

  async model() {
    const locations = this.store.findAll('location');
    const departments = this.store.findAll('department');

    const defaultFlow = await this.store
      .findAll('requisition-flow')
      .then((flows) => flows.find((flow) => get(flow, 'isDefault')));

    const requisitionSetting = get(this.current.company, 'requisitionSetting');

    const [copiedRequisition] = this.store
      .peekAll('requisition')
      .filterBy('id', null);

    const requisition =
      copiedRequisition ||
      this.store.createRecord('requisition', {
        requisitionFlow: defaultFlow,
      });

    return RSVP.hash({
      requisition,
      locations,
      departments,
      requisitionSetting,
      defaultFlow,
    });
  }

  deactivate() {
    // Remove newly created requisition record from store:
    const controller = get(this, 'controller');
    const models = get(controller, 'model');
    models.requisition.rollbackAttributes();
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    set(controller, 'customFieldAnswers', {});
  }
}
