import { tracked } from '@glimmer/tracking';

export class JsonValue {
  @tracked jsonString?: string | null;

  get parsed() {
    return this.jsonString
      ? (JSON.parse(this.jsonString) as Record<string, unknown>)
      : null;
  }

  set parsed(val: Record<string, unknown> | null) {
    this.jsonString = val ? JSON.stringify(val) : null;
  }
}
