import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';

export default class extends Controller {
  controllerName = 'surveys.edit';
  @controller 'surveys.edit';

  get fields() {
    const controller = this[this.controllerName];
    return controller.fields;
  }

  @action
  onUpdateFields(...args) {
    const controller = this[this.controllerName];
    controller.send('onUpdateFields', ...args);
  }

  @action
  onUpdateField(...args) {
    const controller = this[this.controllerName];
    controller.send('onUpdateField', ...args);
  }
}
