import Route from '@ember/routing/route';
import { jsonToBase64 } from 'teamtailor/utils/base-64';
import { inject as service } from '@ember/service';

export default class CandidateRedirectRoute extends Route {
  @service router;

  model({ candidate_id }, { queryParams }) {
    this.router.replaceWith(
      'candidates.segment.candidate',
      'all',
      candidate_id,
      {
        queryParams: { q: jsonToBase64(queryParams) },
      }
    );
  }
}
