import Route from '@ember/routing/route';
import SettingsDataRequestController from 'teamtailor/controllers/settings/data-requests';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import CurrentService from 'teamtailor/services/current';

export default class DataRequestsRoute extends Route {
  @service declare current: CurrentService;
  model() {
    return this.current.company.dataRequestSetting;
  }

  resetController(
    controller: SettingsDataRequestController,
    isExiting: boolean
  ) {
    if (isExiting) {
      get(controller, 'model').rollbackAttributes();
    }
  }
}
