import Model, {
  belongsTo,
  hasMany,
  attr,
  AsyncBelongsTo,
  AsyncHasMany,
  SyncHasMany,
} from '@ember-data/model';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import {
  CandidateCounterDepartmentModel,
  CompanyModel,
  JobModel,
  PagePublicationModel,
  PickedQuestionModel,
  RoleModel,
  UserModel,
} from 'teamtailor/models';
import TranslationDepartmentModel from './translation/department';

export const NO_DEPARTMENT_ID = '1000000000';

export default class DepartmentModel extends Model {
  @service declare current: Current;

  @belongsTo('candidate-counter/department')
  declare candidateCounter: AsyncBelongsTo<CandidateCounterDepartmentModel>;

  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('page-publication')
  declare pagePublication: AsyncBelongsTo<PagePublicationModel>;

  @belongsTo('user')
  declare manager: AsyncBelongsTo<UserModel>;

  @hasMany('job') declare jobs: AsyncHasMany<JobModel>;
  @hasMany('picked-question', { async: false })
  declare pickedQuestions: SyncHasMany<PickedQuestionModel>;

  @hasMany('role', { async: false }) declare roles: SyncHasMany<RoleModel>;
  @hasMany('user', { inverse: 'teamMemberDepartments' })
  declare teamMembers: AsyncHasMany<UserModel>;

  @hasMany('translation/department')
  declare translations: AsyncHasMany<TranslationDepartmentModel>;

  @attr('string') declare connectWelcomeMessage: string;
  @attr('string') declare languageCode: string;
  @attr('string') declare name: string;

  get candidateCount() {
    return get(this.candidateCounter, 'count');
  }

  get isManager() {
    return get(this.manager, 'id') === this.current.user.id;
  }

  get isMember() {
    return this.teamMembers.mapBy('id').includes(this.current.user.id);
  }

  get memberOrManager() {
    return this.isManager || this.isMember;
  }

  get questions() {
    return this.pickedQuestions.mapBy('question');
  }

  get roleNames() {
    return this.roles.mapBy('name');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    department: DepartmentModel;
  }
}
