import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import IntlService from 'ember-intl/services/intl';
import moment from 'moment-timezone';
import Server from 'teamtailor/services/server';
import CompanyModel from './company';
import SsoUserAttributeMappingsModel from './sso-user-attribute-mapping';

type SingleSignOnRecord = Record<
  'single_sign_on',
  {
    idp_metadata_url: string;
    idp_sso_service_url: string;
    idp_cert: string;
    signing: string[];
    encryption: string[];
  }
>;

// TODO: This should probably be handled in the serializer?
type NormalizedSingleSignOnRecord = Record<
  'data',
  {
    attributes: {
      idpMetadataUrl: string;
      idpSsoServiceUrl: string;
      idpCert: string;
      signing: string[];
      encryption: string[];
    };
  }
>;

export type ParseRequestBody = {
  meta_data_file?: string | ArrayBuffer | null;
  meta_data_file_url?: string;
};

export interface AvailableUserAttributeValue {
  translation_path: string;
  value: string | number;
  translation: string;
}

export interface AvailableUserAttribute {
  key: string;
  translation_path: string;
  values?: AvailableUserAttributeValue[];
  translation: string;
}

export default class SingleSignOnModel extends Model {
  @service declare intl: IntlService;
  @service declare server: Server;

  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @attr('boolean', { defaultValue: false }) declare active: boolean;
  @attr('string') declare idpCert: string;
  @attr('string') declare customIdpCert: string;

  @attr('raw') declare signing: string[];
  @attr('raw') declare encryption: string[];
  @attr('string') declare defaultUserRole: string | undefined;
  @attr('raw') declare availableUserRoles: string[];
  @attr('string') declare autoJoinDomains: string | undefined;

  @hasMany('sso-user-attribute-mapping')
  declare ssoUserAttributeMappings: AsyncHasMany<SsoUserAttributeMappingsModel>;

  @attr('string') declare idpMetadataUrl: string;
  @attr('string') declare idpSsoServiceUrl: string;
  @attr('string') declare serviceProviderCallbackUrl: string;
  @attr('string') declare serviceProviderMetadataUrl: string;
  @attr('date') declare createdAt: string;
  @attr('date') declare updatedAt: string;
  @attr('date') declare samlResponseValidAt: string;
  @attr('date') declare samlValidationErrorStartedAt: string;
  @attr('raw') declare samlValidationErrors: string[];

  @attr('sso-user-attribute')
  declare availableUserAttributes: AvailableUserAttribute[];

  @tracked isParsing = false;

  async parseMetaDataFile(args: ParseRequestBody) {
    this.isParsing = true;
    try {
      const response = (await this.server.collectionAction({
        modelName: 'single-sign-on',
        action: 'parse_meta_data_file',
        options: {
          data: JSON.stringify(args),
        },
      })) as SingleSignOnRecord;

      const record = this.store.normalize(
        'single-sign-on',
        response.single_sign_on
      ) as NormalizedSingleSignOnRecord;
      setProperties(this, record.data.attributes);
    } finally {
      this.isParsing = false;
    }
  }

  get currentIdpCertificate(): string | undefined {
    return this.customIdpCert || this.idpCert || this.signing.join(' ');
  }

  get createdAtFormatted(): string | undefined {
    if (this.createdAt) {
      return moment(this.createdAt).format('LLLL Z');
    }

    return undefined;
  }

  get updatedAtFormatted(): string | undefined {
    if (this.updatedAt) {
      return moment(this.updatedAt).format('LLLL Z');
    }

    return undefined;
  }

  get samlResponseValidAtFormatted(): string | undefined {
    if (this.samlResponseValidAt) {
      return moment(this.samlResponseValidAt).format('LLLL Z');
    }

    return undefined;
  }

  get samlValidationErrorStartedAtFormatted(): string | undefined {
    if (this.samlValidationErrorStartedAt) {
      return moment(this.samlValidationErrorStartedAt).format('LLLL Z');
    }

    return undefined;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'single-sign-on': SingleSignOnModel;
  }
}
