import Controller from '@ember/controller';
import { set, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { capitalize } from '@ember/string';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';
import { get } from 'teamtailor/utils/get';
import { isPresent } from '@ember/utils';
import applyDefaultPickedQuestionValues from 'teamtailor/utils/apply-default-picked-question-values';
import { MappedTypes } from 'teamtailor/constants/question';
import { dropTask } from 'ember-concurrency';

export default class JobsEditApplicationFormController extends Controller {
  @service user;
  @service store;
  @service current;
  @service flipper;
  @service copilot;

  @tracked missingTranslations = null;
  @tracked showAdvanced = false;

  get pickedQuestionCount() {
    return get(this, 'model.jobDetail.pickedQuestions.length');
  }

  get qualifyingPickedQuestions() {
    return get(this, 'model.jobDetail.qualifyingPickedQuestions').rejectBy(
      'isDeleted'
    );
  }

  get nonQualifyingPickedQuestions() {
    return get(this, 'model.jobDetail.nonQualifyingPickedQuestions').rejectBy(
      'isDeleted'
    );
  }

  get sortedQualifyingPickedQuestions() {
    return this.qualifyingPickedQuestions.sortBy('rowOrderPosition');
  }

  get sortedNonQualifyingPickedQuestions() {
    return this.nonQualifyingPickedQuestions.sortBy('rowOrderPosition');
  }

  get signatureUser() {
    return (
      get(this, 'model.user') ||
      get(this, 'model.team.user') ||
      get(this, 'user')
    );
  }

  get selectedSurvey() {
    return get(this, 'current.company.surveys').findBy(
      'id',
      get(this, 'model.jobDetail.form.id')
    );
  }

  get currentCompany() {
    return this.current.company;
  }

  get cannedResponses() {
    const context = get(this.model, 'assetStructureContext');
    return this.currentCompany.fetchGroupedCannedResponsesByContext(context);
  }

  get selectedReplyMessage() {
    return (
      get(this, 'model.jobDetail.replyMessage.content') ||
      get(this, 'current.company.defaultReplyMessage')
    );
  }

  get selectedRejectMessage() {
    return (
      get(this, 'model.jobDetail.rejectMessage.content') ||
      get(this, 'current.company.defaultRejectMessage')
    );
  }

  findPickedQuestionByQuestion(question) {
    const jobId = get(this, 'model.id');
    return this.store.peekAll('picked-question').find((pq) => {
      return (
        pq.ownerType === 'Job' &&
        pq.ownerId === jobId &&
        pq.questionId === question.id
      );
    });
  }

  handleTranslations(questionId, missingTranslation) {
    next(() => {
      if (this.missingTranslations?.includes(questionId)) {
        if (!missingTranslation) {
          this.missingTranslations = this.missingTranslations.filter(
            (qId) => qId !== questionId
          );
        }
      } else if (missingTranslation) {
        if (this.missingTranslations === null) {
          this.missingTranslations = [questionId];
        } else {
          this.missingTranslations.pushObject(questionId);
        }
      }
    });
  }

  @action
  toggleAdvanced() {
    this.showAdvanced = !this.showAdvanced;
  }

  @action
  handleQuestionWillChange(pickedQuestion) {
    const items = pickedQuestion.isQualifying
      ? this.nonQualifyingPickedQuestions
      : this.qualifyingPickedQuestions;
    const lastItemIndex =
      items && items.length > 0
        ? get(items[items.length - 1], 'rowOrderPosition')
        : 0;
    const newItemIndex = isPresent(lastItemIndex) ? items.length : 0;
    set(pickedQuestion, 'rowOrderPosition', newItemIndex);
  }

  @action
  changeTemplate(type, cannedResponse) {
    set(this, `model.jobDetail.${type}Message`, cannedResponse);
    const cannedResponseWithFallback = get(
      this,
      `model.jobDetail.${type}MessageWithFallback`
    );
    if (get(this, `model.jobDetail.custom${capitalize(type)}Message`)) {
      set(
        this,
        `model.jobDetail.${type}Subject`,
        get(cannedResponseWithFallback, 'subject')
      );
      set(
        this,
        `model.jobDetail.${type}Body`,
        get(cannedResponseWithFallback, 'body')
      );
    }
  }

  @action
  pickQuestion(isQualifying, question) {
    const existingPickedQuestion = this.findPickedQuestionByQuestion(question);

    if (existingPickedQuestion) {
      get(this, 'model.jobDetail.pickedQuestions').addObject(
        existingPickedQuestion
      );
    } else {
      get(this, 'model.jobDetail.pickedQuestions').createRecord(
        applyDefaultPickedQuestionValues({
          question,
          ownerType: 'Job',
          ownerId: get(this, 'model.id'),
          rowOrder: 'last',
          rowOrderPosition: 'last',
          isQualifying,
        })
      );
    }
  }

  @action
  async removeQuestion(pickedQuestion, questionId) {
    get(this, 'model.jobDetail.pickedQuestions').removeObject(pickedQuestion);
    if (get(pickedQuestion, 'isQualifying')) {
      set(pickedQuestion, 'isQualifying', false);
    }

    if (get(pickedQuestion, 'isNew')) {
      const question = await get(pickedQuestion, 'question');
      pickedQuestion.unloadRecord();
      if (question.isNew) {
        question.unloadRecord();
      }
    }

    this.handleTranslations(questionId, false);
  }

  @action
  handleMissingTranslations(questionId, missingTranslation) {
    this.handleTranslations(questionId, missingTranslation);
  }

  @action
  changeSurvey(survey) {
    set(this, 'model.jobDetail.form', survey);
  }

  suggestQuestionsForApplication = dropTask(async () => {
    const addedQuestionTitles = get(this, 'model.jobDetail.questions').mapBy(
      'title'
    );
    const { toolCalls, languageCode } =
      await this.copilot.generateJobApplicationQuestions(
        get(this, 'model.title'),
        get(this, 'model.jobDetail.body'),
        get(this, 'model.languageCode'),
        addedQuestionTitles
      );

    await Promise.all(
      toolCalls.map(async (toolCall) => {
        if (toolCall.name === 'create_new_question') {
          const question = await this.createQuestion(
            toolCall.arguments,
            languageCode
          );
          this.createPickedQuestion(question);
        } else if (
          toolCall.name === 'pick_existing_question' &&
          toolCall.arguments.question_id
        ) {
          const question = await this.store.findRecord(
            'question',
            toolCall.arguments.question_id
          );
          question.addedByCopilot = true;
          this.createPickedQuestion(question);
        }
      })
    );

    return true;
  });

  createPickedQuestion(question) {
    return get(this, 'model.jobDetail.pickedQuestions').createRecord(
      applyDefaultPickedQuestionValues({
        question,
        ownerType: 'Job',
        ownerId: get(this, 'model.id'),
        questionId: question.id,
        rowOrder: 'last',
        rowOrderPosition: 'last',
      })
    );
  }

  async createQuestion(questionArguments, languageCode) {
    const questionType = (type) => {
      switch (type) {
        case 'text':
          return MappedTypes.text;
        case 'yes_or_no':
          return MappedTypes.boolean;
        case 'date':
          return MappedTypes.date;
        case 'number':
          return MappedTypes.number;
        case 'range':
          return MappedTypes.range;
        case 'multiple_choice':
        case 'single_choice':
          return MappedTypes.choice;
        default:
          return MappedTypes.text;
      }
    };

    const question = this.store.createRecord('question', {
      type: questionType(questionArguments.type),
      languageCode,
      alternatives: questionArguments.alternatives?.join('\n') || [],
      startWith: questionArguments.range?.start_with,
      endWith: questionArguments.range?.end_with,
      multiple: questionArguments.type === 'multiple_choice',
      singleLine: false,
      questionData: {
        alternatives:
          questionArguments.alternatives?.map((alternative, index) => ({
            id: index + 1,
            title: alternative,
          })) || [],

        end_with: questionArguments.range?.end_with,
        start_with: questionArguments.range?.start_with,
        unit: questionArguments.range?.unit,
      },

      title: questionArguments.title,
      createdByCopilot: true,
    });

    return question;
  }
}
