import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#let (file-queue name=@name onFileAdded=this.handleFileSelected) as |queue|}}\n  <label class=\"mb-0\" ...attributes>\n    {{#if (has-block)}}\n      {{yield}}\n    {{else}}\n      <Button\n        @appearance={{or @appearance \"secondary\"}}\n        @icon={{or @icon \"paperclip\"}}\n        @text={{this.label}}\n        @asSpan={{true}}\n        @size={{or @size \"medium\"}}\n        class=\"cursor-pointer\"\n      />\n    {{/if}}\n    <input\n      name={{@name}}\n      type=\"file\"\n      class=\"hidden\"\n      accept={{@accept}}\n      multiple={{@multiple}}\n      {{queue.selectFile}}\n    />\n  </label>\n{{/let}}", {"contents":"{{#let (file-queue name=@name onFileAdded=this.handleFileSelected) as |queue|}}\n  <label class=\"mb-0\" ...attributes>\n    {{#if (has-block)}}\n      {{yield}}\n    {{else}}\n      <Button\n        @appearance={{or @appearance \"secondary\"}}\n        @icon={{or @icon \"paperclip\"}}\n        @text={{this.label}}\n        @asSpan={{true}}\n        @size={{or @size \"medium\"}}\n        class=\"cursor-pointer\"\n      />\n    {{/if}}\n    <input\n      name={{@name}}\n      type=\"file\"\n      class=\"hidden\"\n      accept={{@accept}}\n      multiple={{@multiple}}\n      {{queue.selectFile}}\n    />\n  </label>\n{{/let}}","moduleName":"teamtailor/components/direct-file-upload.hbs","parseOptions":{"srcName":"teamtailor/components/direct-file-upload.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class DirectFileUpload extends Component {
  @service directUpload;
  @service intl;

  @tracked pendingUploads = [];
  @tracked lastUploadedFileName = null;

  get label() {
    if (this.args.changeLabelToLoading && this.isUploading) {
      return this.intl.t('components.direct_file_upload.uploading_dots');
    }

    if (this.lastUploadedFileName) {
      return this.lastUploadedFileName;
    }

    if (this.args.label !== undefined) {
      return this.args.label;
    }

    return this.args.multiple
      ? this.intl.t('common.attach_files')
      : this.intl.t('common.attach_file');
  }

  get isUploading() {
    return !!this.pendingUploads.length;
  }

  @action
  handleFileSelected(file) {
    file.name = file.name
      .replace(/[^a-z0-9.]/gi, '-')
      .replace(/[-]+/g, '-')
      .toLowerCase();

    this.pendingUploads.pushObject(file.name);

    this.args.onFileUploadStart?.(file.name, file);

    this.directUpload
      .s3(file, {
        rootPath: this.args.rootPath,
        returnKey: this.args.returnKey ?? false,
      })
      .then((temporaryFileUrlOrKey) => {
        if (this.args.filenameAsLabel) {
          this.lastUploadedFileName = file.name;
        }

        this.args.onFileUploaded?.(temporaryFileUrlOrKey, file.name, file);
        this.pendingUploads.removeObject(file.name);

        if (!this.pendingUploads.length) {
          this.args.onFileUploadComplete?.();
        }
      });
  }
}
