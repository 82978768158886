import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield this.onAction}}\n{{#if this.showErrors}}\n  <div class=\"flex items-center gap-8\" ...attributes>\n    <Icon\n      @icon=\"triangle-exclamation\"\n      @style=\"solid\"\n      @size=\"16\"\n      class=\"text-icon-danger\"\n    />\n    <div>\n      {{#each @errors as |error|}}\n        <span class=\"body-text-xs text-danger\">{{error.message}}</span>\n      {{/each}}\n    </div>\n  </div>\n{{/if}}", {"contents":"{{yield this.onAction}}\n{{#if this.showErrors}}\n  <div class=\"flex items-center gap-8\" ...attributes>\n    <Icon\n      @icon=\"triangle-exclamation\"\n      @style=\"solid\"\n      @size=\"16\"\n      class=\"text-icon-danger\"\n    />\n    <div>\n      {{#each @errors as |error|}}\n        <span class=\"body-text-xs text-danger\">{{error.message}}</span>\n      {{/each}}\n    </div>\n  </div>\n{{/if}}","moduleName":"teamtailor/components/only-show-errors-after-first.hbs","parseOptions":{"srcName":"teamtailor/components/only-show-errors-after-first.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

type Args = {
  errors?: string[];
};

export default class OnlyShowErrorsAfterFirstComponent extends Component<Args> {
  @tracked hasDoneAction = false;

  get showErrors() {
    return (
      this.hasDoneAction && this.args.errors && this.args.errors.length > 0
    );
  }

  @action
  onAction() {
    this.hasDoneAction = true;
  }
}
