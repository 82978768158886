import Model, {
  hasMany,
  belongsTo,
  attr,
  AsyncBelongsTo,
  AsyncHasMany,
  SyncHasMany,
} from '@ember-data/model';
import { getRobohashUrl } from 'teamtailor/utils/robohash';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import IntlService from 'ember-intl/services/intl';
import InterviewKitModel from './interview-kit';
import CandidateModel from './candidate';
import UserModel from './user';
import JobModel from './job';
import PartnerModel from './partner';
import AnswerModel from './answer';
import ScorecardScoreModel from './scorecard-score';

export enum CopilotAnswersStatus {
  NOT_AVAILABLE = 'not_available',
  PENDING = 'pending',
  AVAILABLE = 'available',
  FAILED = 'failed',
}

export default class InterviewModel extends Model {
  @service declare intl: IntlService;

  @hasMany('answer', { async: true })
  declare answers: AsyncHasMany<AnswerModel>;

  @hasMany('scorecard-score', { async: false })
  declare scores: SyncHasMany<ScorecardScoreModel>;

  @belongsTo('interview-kit', { async: true })
  declare interviewKit: AsyncBelongsTo<InterviewKitModel>;

  @belongsTo('candidate', { async: true })
  declare candidate: AsyncBelongsTo<CandidateModel>;

  @belongsTo('user', { async: true }) declare user: AsyncBelongsTo<UserModel>;
  @belongsTo('job', { async: true }) declare job: AsyncBelongsTo<JobModel>;
  @belongsTo('partner', { async: true })
  declare partner: AsyncBelongsTo<PartnerModel>;

  @attr('string') declare note: string;
  @attr('string') declare author: string;
  @attr('date') declare createdAt: Date;
  @attr('date') declare deletedUserAt?: Date;
  @attr('string') declare copilotAnswersStatus: CopilotAnswersStatus;
  @attr('string') declare status: string;
  @attr('string') declare jobId: string;

  get interviewKitId() {
    return get(this.interviewKit, 'id');
  }

  get questions() {
    return get(this.interviewKit, 'questions');
  }

  get userId() {
    return get(this.user, 'id');
  }

  get candidateId() {
    return get(this.candidate, 'id');
  }

  get completedAnswers() {
    return get(this, 'answers').filter((answer) => !answer.emptyAnswer);
  }

  get completedScores() {
    return get(this, 'scores').filter(
      (scorecardScore) => typeof scorecardScore.score === 'number'
    );
  }

  get isDraft() {
    return get(this, 'status') === 'draft';
  }

  get isPublished() {
    return get(this, 'status') === 'published';
  }

  get userOrAuthorName() {
    const deletedUserAt = get(this, 'deletedUserAt');
    return this.userId
      ? {
          type: 'user',
          id: this.userId,
          user: get(this, 'user'),
        }
      : {
          type: 'authorName',
          id: get(this, 'author'),
          author: deletedUserAt
            ? this.intl.t('components.activity_item.somebody')
            : get(this, 'author'),

          robohashUrl: getRobohashUrl(deletedUserAt || get(this, 'author')),
        };
  }

  get copilotGenerated() {
    return get(this, 'copilotAnswersStatus') === 'available';
  }

  get hasChanges() {
    return (
      get(this, 'hasDirtyAttributes') ||
      get(this, 'answers')
        .slice()
        .some((answer) => get(answer, 'hasDirtyAttributes')) ||
      get(this, 'scores')
        .slice()
        .some((score) => get(score, 'hasDirtyAttributes'))
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    interview: InterviewModel;
  }
}
