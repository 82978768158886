import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class BlockSettingsService extends Service {
  @tracked
  editingAttribute = null;

  get isEditing() {
    return this.editingAttribute !== null;
  }

  @action
  isDisabled(attribute) {
    return this.isEditing && this.editingAttribute !== attribute;
  }
}
