import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isOpen}}\n  {{#in-element this.wrapperElement insertBefore=null}}\n    <div\n      class=\"pointer-events-auto absolute inset-0 flex size-full cursor-pointer bg-zinc-800/50\"\n    >\n      <div\n        class=\"mx-76 my-48 flex w-full cursor-auto overflow-hidden rounded-8\"\n        {{outside-click this.close leakyDelayAdd=true}}\n        {{focus-trap focusTrapOptions=(hash clickOutsideDeactivates=true)}}\n      >\n        <aside\n          class=\"w-[255px] shrink-0 overflow-auto rounded-l-8 bg-canvas px-20 py-40\"\n        >\n          {{yield to=\"sidebar\"}}\n        </aside>\n        <div\n          class=\"grow overflow-auto rounded-r-8 bg-neutral-medium px-32 py-40\"\n        >\n          {{yield to=\"content\"}}\n        </div>\n      </div>\n    </div>\n  {{/in-element}}\n{{/if}}", {"contents":"{{#if this.isOpen}}\n  {{#in-element this.wrapperElement insertBefore=null}}\n    <div\n      class=\"pointer-events-auto absolute inset-0 flex size-full cursor-pointer bg-zinc-800/50\"\n    >\n      <div\n        class=\"mx-76 my-48 flex w-full cursor-auto overflow-hidden rounded-8\"\n        {{outside-click this.close leakyDelayAdd=true}}\n        {{focus-trap focusTrapOptions=(hash clickOutsideDeactivates=true)}}\n      >\n        <aside\n          class=\"w-[255px] shrink-0 overflow-auto rounded-l-8 bg-canvas px-20 py-40\"\n        >\n          {{yield to=\"sidebar\"}}\n        </aside>\n        <div\n          class=\"grow overflow-auto rounded-r-8 bg-neutral-medium px-32 py-40\"\n        >\n          {{yield to=\"content\"}}\n        </div>\n      </div>\n    </div>\n  {{/in-element}}\n{{/if}}","moduleName":"teamtailor/components/modals/takeover.hbs","parseOptions":{"srcName":"teamtailor/components/modals/takeover.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { keyResponder, onKey } from 'ember-keyboard';
import { argDefault } from 'teamtailor/utils/arg-default';

@keyResponder
export default class ModalsTakeoverComponent extends Component {
  @argDefault isOpen = true;
  wrapperElement = null;
  keyboardPriority = 100;

  constructor() {
    super(...arguments);
    this.wrapperElement = document.getElementById('ember-teamtailor-modal');
  }

  @onKey('Escape')
  closeOnEscape(event, ekEvent) {
    if (this.isOpen) {
      ekEvent.stopImmediatePropagation();
      ekEvent.stopPropagation();
      this.close();
    }
  }

  @action
  close() {
    if (this.args.onClose) {
      this.args.onClose();
    }
  }
}
