import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import {
  CompanyModel,
  DashboardWidgetModel,
  UserModel,
} from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';

export default class PickedDashboardWidgetModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('dashboard-widget')
  declare dashboardWidget: AsyncBelongsTo<DashboardWidgetModel>;

  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;

  @attr('number') declare column: number;
  @attr('number') declare dashboardWidgetId: number;
  @attr('number') declare rowOrder: number;
  @attr('string') declare rowOrderPosition: string;

  get target() {
    return get(this.dashboardWidget, 'target');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'picked-dashboard-widget': PickedDashboardWidgetModel;
  }
}
