import { inject as service } from '@ember/service';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get } from 'teamtailor/utils/get';

export default class EeoReportingRoute extends ScrollToTopRoute {
  @service current;
  @service store;

  model() {
    if (get(this.current.company, 'eeoReportSetting.id')) {
      return get(this.current.company, 'eeoReportSetting');
    } else {
      return this.store.createRecord('eeo-report-setting', {
        race: true,
        gender: true,
        veteranStatus: true,
      });
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.bindChannels();
  }

  resetController(controller) {
    super.resetController(controller);
    controller.unbindChannels();
  }
}
