export const DEFAULT_STYLE = {
  name: 'file',
  style: 'duotone',
  color: 'text-decorative-blue',
};

const imageStyle = {
  name: 'file-image',
  style: 'duotone',
  color: 'text-decorative-yellow',
};

const documentStyle = {
  name: 'file-lines',
  style: 'duotone',
  color: 'text-decorative-blue',
};

const sheetsStyle = {
  name: 'file-spreadsheet',
  style: 'duotone',
  color: 'text-decorative-green',
};

const videoStyle = {
  name: 'file-video',
  style: 'duotone',
  color: 'text-decorative-teal',
};

export const ICON_FILE_TYPE = {
  'application/pdf': {
    name: 'file-pdf',
    style: 'duotone',
    color: 'text-decorative-red',
  },

  'application/msword': documentStyle,
  'application/x-iwork-pages-sffpages': documentStyle,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    documentStyle,

  'application/vnd.ms-excel': sheetsStyle,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    sheetsStyle,

  'image/jpeg': imageStyle,
  'image/png': imageStyle,
  'image/gif': imageStyle,
  'image/bmp': imageStyle,
  'image/tiff': imageStyle,
  'image/svg+xml': imageStyle,
  'image/webp': imageStyle,

  'video/mp4': videoStyle,
  'video/mpeg': videoStyle,
  'video/ogg': videoStyle,
  'video/webm': videoStyle,
  'video/quicktime': videoStyle,
};
