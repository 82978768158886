import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class IndexRoute extends ScrollToTopRoute {
  @service current;
  @service store;

  model() {
    const { company } = this.current;

    return get(company, 'dataPrivacySetting').then((dataPrivacySetting) => {
      if (dataPrivacySetting) {
        return dataPrivacySetting;
      } else {
        return this.store.createRecord('data-privacy-setting', {
          company,
          users: [get(company, 'manager.content')],
          sourcedReferredReminder: -1,
          consentExpiration: -1,
        });
      }
    });
  }
}
