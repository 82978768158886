import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class extends Controller {
  @service current;

  @action
  save() {
    return this.model.save();
  }
}
