import Model, { belongsTo, attr, type AsyncBelongsTo } from '@ember-data/model';
import {
  CandidateModel,
  JobApplicationModel,
  UserModel,
} from 'teamtailor/models';

export default class ReviewModel extends Model {
  @belongsTo('candidate') declare candidate: AsyncBelongsTo<CandidateModel>;
  @belongsTo('job-application')
  declare jobApplication: AsyncBelongsTo<JobApplicationModel>;

  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;

  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('number') declare rating: number;
  @attr('string') declare comment: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    review: ReviewModel;
  }
}
