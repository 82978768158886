import Model, { attr } from '@ember-data/model';
import { FormBuilderField } from 'teamtailor/utils/form-builder';

export default class RequisitionSettingModel extends Model {
  @attr('boolean', { defaultValue: false }) declare enabledByCompany: boolean;
  @attr('raw') declare customForm: FormBuilderField[] | null;
  @attr('string') declare instructions: string | null;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'requisition-setting': RequisitionSettingModel;
  }
}
