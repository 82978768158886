import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import { CompanyModel, PartnerActivationModel, UserModel } from '.';
import { inject as service } from '@ember/service';
import Server from 'teamtailor/services/server';

export default class AuthorizationModel extends Model {
  @service declare server: Server;

  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;
  @belongsTo('partner-activation')
  declare partnerActivation: AsyncBelongsTo<PartnerActivationModel>;

  @attr('string') declare cronofyAvailabilityRuleId: string;
  @attr('string') declare expiresAt: string;
  @attr('string') declare provider: string;
  @attr('string') declare refreshToken: string;
  @attr('string') declare token: string;
  @attr('string') declare uid: string;
  @attr('string') declare username: string;
  @attr('date') declare cronofyReconnectRequiredAt: Date;

  get cronofyReconnectedRequired() {
    return !isEmpty(this.cronofyReconnectRequiredAt);
  }

  get isCronofy() {
    return (
      this.isCronofyIndividualConnect || this.isCronofyEnterpriseConnectForUser
    );
  }

  get isCronofyBusinessConnect() {
    return this.provider === 'cronofy_business_connect';
  }

  get isCronofyEnterpriseConnect() {
    return this.provider === 'cronofy_service_account';
  }

  get isCronofyEnterpriseConnectForUser() {
    return this.provider === 'cronofy_service_account_authorization';
  }

  get isCronofyIndividualConnect() {
    return this.provider === 'cronofy';
  }

  get isCronofyServiceAccount() {
    return this.isCronofyBusinessConnect || this.isCronofyEnterpriseConnect;
  }

  async fetchAvailabilitySettings() {
    return this.server.memberAction(this, {
      action: 'availability_settings',
      method: 'GET',
    });
  }

  async fetchReconnectProfiles() {
    return this.server.memberAction(this, {
      action: 'reconnect_profiles',
      method: 'GET',
    });
  }

  async getConnectedConferenceRedirectUrl(data: {
    userId: string;
    eventId: string;
  }) {
    return this.server.memberAction(this, {
      action: 'get_connected_conference_redirect_url',
      method: 'GET',
      options: { data },
    });
  }

  async upsertDefaultAvailabilityRule(data: { time_zone: string }) {
    return this.server.memberAction(this, {
      action: 'upsert_default_availability_rule',
      method: 'POST',
      options: { data },
    });
  }

  cronofyReconnectProfiles = async () => {
    const result = await this.fetchReconnectProfiles();
    return result;
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    authorization: AuthorizationModel;
  }
}
