import JobOfferBaseController from './base-controller';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default class JobOfferAnswerController extends JobOfferBaseController {
  get saveDisabled() {
    if (!(this.jobOffer.isDeclined || this.jobOffer.isAccepted)) {
      return true;
    }

    return this.jobOffer.response
      ? isEmpty(this.jobOffer.response.trim())
      : true;
  }

  @action
  answerOffer() {
    return this.jobOffer.answer().then(() => {
      this.onClose();
    });
  }
}
