import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { get } from 'teamtailor/utils/get';

export default class ScheduleController extends Controller {
  @service ttAlert;
  @service intl;
  @service router;
  @service flashMessages;

  get nurtureCampaign() {
    return this.model;
  }

  @action
  handleStatusChange(status) {
    if (status === 'archive') {
      get(this, 'ttAlert').confirm(
        this.intl.t('nurture_campaign.archive_campaign_confirm'),
        () => {
          return this.nurtureCampaign.archive().then(() => {
            get(this, 'flashMessages').success('Nurture campaign archived');
            this.router.transitionTo('nurture-campaigns.index');
          });
        }
      );
    }

    if (status === 'pause' && this.nurtureCampaign.isActive) {
      return this.nurtureCampaign.pause();
    }

    if (status === 'activate') {
      return this.nurtureCampaign.isPaused
        ? this.nurtureCampaign.resume()
        : this.nurtureCampaign.activate();
    }

    if (status === 'delete') {
      get(this, 'ttAlert').confirm(
        this.intl.t('nurture_campaign.delete_campaign_confirm'),
        () => {
          return this.nurtureCampaign.destroyRecord().then(() => {
            later(() => {
              get(this, 'flashMessages').success('Nurture campaign deleted');
              this.router.transitionTo('nurture-campaigns.index');
            }, 500);
          });
        }
      );
    }

    return true;
  }

  @action
  handleAfterSave() {
    this.router.transitionTo('nurture-campaigns');
  }
}
