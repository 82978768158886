import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"relative flex flex-row items-center gap-8 rounded-6 border border-neutral-strong bg-neutral py-4 pl-4 pr-8 text-14 focus:outline focus:outline-2 focus:outline-focus\"\n>\n  <Editor::ColorPicker\n    @isOpen={{this.isOpen}}\n    @onClose={{this.handleClose}}\n    @displayValue={{@color}}\n    @onChange={{@onChange}}\n    @class=\"shadow-base\"\n    @roundedClass=\"rounded\"\n    @theme=\"light\"\n  />\n  <Editor::ColorPreview\n    @size=\"large\"\n    @onClick={{toggle-action \"isOpen\" this}}\n    @color={{@color}}\n    @roundedClass=\"rounded\"\n  />\n  <div class=\"body-text-s text-neutral-medium\">\n    {{@color}}\n  </div>\n</div>", {"contents":"<div\n  class=\"relative flex flex-row items-center gap-8 rounded-6 border border-neutral-strong bg-neutral py-4 pl-4 pr-8 text-14 focus:outline focus:outline-2 focus:outline-focus\"\n>\n  <Editor::ColorPicker\n    @isOpen={{this.isOpen}}\n    @onClose={{this.handleClose}}\n    @displayValue={{@color}}\n    @onChange={{@onChange}}\n    @class=\"shadow-base\"\n    @roundedClass=\"rounded\"\n    @theme=\"light\"\n  />\n  <Editor::ColorPreview\n    @size=\"large\"\n    @onClick={{toggle-action \"isOpen\" this}}\n    @color={{@color}}\n    @roundedClass=\"rounded\"\n  />\n  <div class=\"body-text-s text-neutral-medium\">\n    {{@color}}\n  </div>\n</div>","moduleName":"teamtailor/components/tt-color-picker.hbs","parseOptions":{"srcName":"teamtailor/components/tt-color-picker.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class TtColorPickerComponent extends Component {
  @tracked isOpen = false;

  pickerComponents = {
    interaction: {
      hex: true,
      rgba: false,
      hsva: false,
      clear: false,
      save: false,
    },
  };

  @action
  handleClose(e) {
    e.preventDefault();

    this.isOpen = false;
  }
}
