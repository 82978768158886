import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Button\n  @appearance=\"secondary\"\n  @size=\"small\"\n  @text={{t \"components.promotion.card.edit\"}}\n  @onClick={{this.handleEdit}}\n/>", {"contents":"<Button\n  @appearance=\"secondary\"\n  @size=\"small\"\n  @text={{t \"components.promotion.card.edit\"}}\n  @onClick={{this.handleEdit}}\n/>","moduleName":"teamtailor/components/promotion/action/edit.hbs","parseOptions":{"srcName":"teamtailor/components/promotion/action/edit.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import PromotionModel from 'teamtailor/models/promotion';
import JobModel from 'teamtailor/models/job';

export interface PromotionActionEditArgs {
  promotion: PromotionModel | undefined;
  job: JobModel;
  hasAdapter: boolean;
}

export default class PromotionActionEdit extends Component<PromotionActionEditArgs> {
  @service declare router: RouterService;

  @action
  handleEdit() {
    if (!this.args.promotion) return;

    if (this.args.hasAdapter) {
      this.router.transitionTo(
        'jobs.job.promote.promotion.edit-external',
        this.args.job,
        this.args.promotion
      );
    } else {
      this.router.transitionTo(
        'jobs.job.promote.edit',
        this.args.job,
        this.args.promotion
      );
    }
  }
}
