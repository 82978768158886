function random(array) {
  return array[Math.floor(Math.random() * array.length)];
}

export default class JobCreatedMessage {
  intl = null;
  jobsCount = null;

  constructor(intl, jobsCount) {
    this.intl = intl;
    this.jobsCount = jobsCount;
  }

  getMessage() {
    if (this.jobsCount === 1) {
      return this.firstJobCreated;
    } else if (this.jobsCount === 3) {
      return this.thirdJobCreated;
    } else if (this.jobsCount === 10) {
      return this.tenthJobCreated;
    } else if (this.jobsCount === 20) {
      return this.twentiethJobCreated;
    } else {
      return this.random();
    }
  }

  random() {
    let emoji = random(['👏', '✌️', '🎊']);
    let title = random([
      this.intl.t(
        'constants.job_status_messages.randomize_published_title_one'
      ),
      this.intl.t(
        'constants.job_status_messages.randomize_published_title_two'
      ),
      this.intl.t(
        'constants.job_status_messages.randomize_published_title_three'
      ),
    ]);
    let content = random([
      this.intl.t(
        'constants.job_status_messages.randomize_published_content_one'
      ),
      this.intl.t(
        'constants.job_status_messages.randomize_published_content_two'
      ),
      this.intl.t(
        'constants.job_status_messages.randomize_published_content_three'
      ),
    ]);
    return {
      emoji,
      title,
      content,
    };
  }

  get firstJobCreated() {
    return {
      emoji: '🎉',
      title: this.intl.t(
        'constants.job_status_messages.first_job_published_title'
      ),

      content: this.intl.t(
        'constants.job_status_messages.first_job_published_description',
        {
          firstUrl:
            'https://support.teamtailor.com/en/articles/6254633-always-included-free-job-boards',

          secondUrl:
            'https://support.teamtailor.com/en/articles/6025203-promote',

          htmlSafe: true,
        }
      ),
    };
  }

  get thirdJobCreated() {
    return {
      emoji: '😁',
      title: this.intl.t(
        'constants.job_status_messages.third_job_published_title'
      ),

      content: this.intl.t(
        'constants.job_status_messages.third_job_published_description'
      ),
    };
  }

  get tenthJobCreated() {
    return {
      emoji: '🙌',
      title: this.intl.t(
        'constants.job_status_messages.tenth_job_published_title'
      ),

      content: this.intl.t(
        'constants.job_status_messages.tenth_job_published_description'
      ),
    };
  }

  get twentiethJobCreated() {
    return {
      emoji: '👑',
      title: this.intl.t(
        'constants.job_status_messages.twentieth_job_published_title'
      ),

      content: this.intl.t(
        'constants.job_status_messages.twentieth_job_published_description'
      ),
    };
  }
}
