import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import FancyFilterOption from 'teamtailor/utils/fancy-filter-option';
import BaseFilter from 'teamtailor/utils/fancy-filters/base';

export default class TranslatedFilters extends Helper {
  @service intl;

  compute([filters, translationPrefix]) {
    if (!filters || !translationPrefix) {
      return filters;
    }

    return filters.map((filter) => {
      let fancyFilter;
      if (filter instanceof BaseFilter) {
        fancyFilter = Object.assign(filter, {
          intl: this.intl,
          translationPrefix,
        });
      } else {
        fancyFilter = FancyFilterOption.create(this, {
          translationPrefix,
          ...filter,
        });
      }

      if (filter.parent instanceof BaseFilter) {
        fancyFilter.parent = Object.assign(filter.parent, {
          intl: this.intl,
          translationPrefix,
        });
      } else if (filter.parent) {
        fancyFilter.parent = FancyFilterOption.create(this, {
          translationPrefix,
          ...filter.parent,
        });
      }

      return fancyFilter;
    });
  }
}
