import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span class=\"flex flex-col\">\n  <span class=\"body-text-semibold\">{{this.truncatedName}}</span>\n  <span class=\"flex flex-row items-center gap-4\">\n    <Core::ColorDot\n      class={{unless this.showOnline \"border border-base-grey-64\"}}\n      @size=\"medium\"\n      @color={{if this.showOnline \"success\" \"transparent\"}}\n      @variant=\"strong\"\n    />\n    <span class=\"body-text-xs-medium text-neutral-medium\">\n      {{t\n        (concat\n          \"components.user_nav_bar_profile.\"\n          (if this.showOnline \"active\" \"away\")\n        )\n      }}\n    </span>\n  </span>\n</span>", {"contents":"<span class=\"flex flex-col\">\n  <span class=\"body-text-semibold\">{{this.truncatedName}}</span>\n  <span class=\"flex flex-row items-center gap-4\">\n    <Core::ColorDot\n      class={{unless this.showOnline \"border border-base-grey-64\"}}\n      @size=\"medium\"\n      @color={{if this.showOnline \"success\" \"transparent\"}}\n      @variant=\"strong\"\n    />\n    <span class=\"body-text-xs-medium text-neutral-medium\">\n      {{t\n        (concat\n          \"components.user_nav_bar_profile.\"\n          (if this.showOnline \"active\" \"away\")\n        )\n      }}\n    </span>\n  </span>\n</span>","moduleName":"teamtailor/components/user-nav-bar-profile.hbs","parseOptions":{"srcName":"teamtailor/components/user-nav-bar-profile.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import UserModel from 'teamtailor/models/user';
import UserOnlineStatus from 'teamtailor/services/user-online-status';

interface ComponentArgs {
  user: UserModel;
}

export default class UserNavBarProfileComponent extends Component<ComponentArgs> {
  @service declare userOnlineStatus: UserOnlineStatus;

  get user(): UserModel {
    return this.args.user;
  }

  get truncatedName(): string {
    const name = this.args.user.nameOrEmail;

    if (name.length > 24) {
      return `${name.substring(0, 21)}...`;
    }

    return name;
  }

  get showOnline(): boolean {
    return !this.user.hideOnlineStatus && !this.userOnlineStatus.impersonating;
  }
}
