import { get, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default function rejectSettingsFormValid(
  reason,
  sendEmail,
  template,
  subject,
  body
) {
  return computed(reason, sendEmail, template, subject, body, function () {
    const rejectReason = get(this, reason);
    const rejectSendEmail = get(this, sendEmail);
    const rejectSubject = get(this, subject);
    const rejectBody = get(this, body);
    const rejectTemplateId = get(this, `${template}.id`);

    if (!rejectReason) return false;
    if (!rejectSendEmail) return true;

    return rejectTemplateId
      ? true
      : !isEmpty(rejectSubject) && !isEmpty(rejectBody);
  });
}
