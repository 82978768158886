import { getOwner } from '@ember/application';

export default function buildReportModel(
  context,
  Model,
  settings,
  compareSettings
) {
  return Model.create(
    getOwner(context).ownerInjection(),
    Object.assign(settings, {
      compareModel: Model.create(
        getOwner(context).ownerInjection(),
        compareSettings
      ),
    })
  );
}
