import Model, { attr, AsyncBelongsTo, belongsTo } from '@ember-data/model';
import { JobModel, ShareLinkModel } from 'teamtailor/models';

export default class ShareLinksJobModel extends Model {
  @belongsTo('job') declare job: AsyncBelongsTo<JobModel>;
  @belongsTo('share-link') declare shareLink: AsyncBelongsTo<ShareLinkModel>;

  @attr('array') declare answers: string[];
  @attr('boolean', { defaultValue: true }) declare coverLetter: boolean;
  @attr('array') declare internalDocuments: string[];
  @attr('array') declare candidateDocuments: string[];
  @attr('array') declare interviewKits: string[];
  @attr('array') declare locations: string[];
  @attr('boolean', { defaultValue: true }) declare notes: boolean;
  @attr('boolean', { defaultValue: true }) declare recommendations: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'share-links-job': ShareLinksJobModel;
  }
}
