import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default class IndexRoute extends Route {
  @service router;
  @service store;

  async model() {
    return hash({
      channels: this.store.findAll('channel'),
      partners: this.store.findAll('partner'),
      locations: this.store.findAll('location'),
      channelActivations: this.store.findAll('channel-activation'),
    });
  }
}
