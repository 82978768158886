import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action, setProperties } from '@ember/object';

export default class RejectReasonController extends Controller {
  @service flashMessages;
  @service intl;

  get rejectReasons() {
    return this.model;
  }

  get rejectReasonsByCandidate() {
    return this.rejectReasons.filter(
      (rejectReason) => !rejectReason.rejectedByCompany
    );
  }

  get rejectReasonsByCompany() {
    return this.rejectReasons.filter(
      (rejectReason) => rejectReason.rejectedByCompany
    );
  }

  _orderRejectReasons() {
    let companyRejectReasons = this.rejectReasonsByCompany.sortBy(
      'rowOrder',
      'rowOrderPosition'
    );
    let candidateRejectReasons = this.rejectReasonsByCandidate.sortBy(
      'rowOrder',
      'rowOrderPosition'
    );
    companyRejectReasons.forEach((item, index) => {
      setProperties(item, {
        rowOrderPosition: index,
        rowOrder: index,
      });
    });

    let candidateRejectReasonsStartIndex = companyRejectReasons.length;
    candidateRejectReasons.forEach((item, index) => {
      setProperties(item, {
        rowOrderPosition: candidateRejectReasonsStartIndex + index,
        rowOrder: candidateRejectReasonsStartIndex + index,
      });
    });
  }

  @action
  handleDragEnd({
    draggedItem,
    sourceList,
    sourceIndex,
    targetList,
    targetIndex,
  }) {
    if (sourceIndex === targetIndex) {
      return;
    }

    sourceList.removeAt(sourceIndex);
    targetList.insertAt(targetIndex, draggedItem);

    targetList.forEach((item, index) => {
      setProperties(item, {
        rowOrderPosition: index,
        rowOrder: index,
      });
    });
    this._orderRejectReasons();

    const orderedRejectReasons = targetList.map((rejectReason) => {
      return { row_order: rejectReason.rowOrder, id: rejectReason.id };
    });

    draggedItem
      .updateOrder(orderedRejectReasons)
      .then(() => {
        this.flashMessages.success(
          this.intl.t('settings.reject_reasons.success')
        );
      })
      .catch(() => {
        this.flashMessages.error(this.intl.t('settings.reject_reasons.error'));
      });
  }
}
