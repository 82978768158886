import Controller from '@ember/controller';

export default class FeaturesFeatureShowController extends Controller {
  get modalTarget(): Element | undefined {
    return document.getElementById('ember-teamtailor-modal') ?? undefined;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'features.show': FeaturesFeatureShowController;
  }
}
