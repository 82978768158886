import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"min-h-0 flex-grow overflow-auto\">\n  <div class=\"stage-drop-list\">\n    {{#if (has-permission \"trigger/create\")}}\n      <div class=\"mb-16\">\n        <Core::DropdownMenu\n          @text={{t \"components.stage.add_trigger\"}}\n          @icon=\"plus\"\n          as |menu|\n        >\n          <Triggers::DropdownList\n            @onClickTrigger={{this.createTrigger}}\n            @menu={{menu}}\n            @job={{@job}}\n            @stage={{@stage}}\n            @showRejected={{@showRejected}}\n          />\n        </Core::DropdownMenu>\n      </div>\n    {{/if}}\n    <StageTriggersList\n      @onClick={{this.editTrigger}}\n      @onMouseEnter={{this.handleTriggerMouseEnter}}\n      @job={{@job}}\n      @stage={{@stage}}\n      @onReject={{@showRejected}}\n    />\n  </div>\n</div>", {"contents":"<div class=\"min-h-0 flex-grow overflow-auto\">\n  <div class=\"stage-drop-list\">\n    {{#if (has-permission \"trigger/create\")}}\n      <div class=\"mb-16\">\n        <Core::DropdownMenu\n          @text={{t \"components.stage.add_trigger\"}}\n          @icon=\"plus\"\n          as |menu|\n        >\n          <Triggers::DropdownList\n            @onClickTrigger={{this.createTrigger}}\n            @menu={{menu}}\n            @job={{@job}}\n            @stage={{@stage}}\n            @showRejected={{@showRejected}}\n          />\n        </Core::DropdownMenu>\n      </div>\n    {{/if}}\n    <StageTriggersList\n      @onClick={{this.editTrigger}}\n      @onMouseEnter={{this.handleTriggerMouseEnter}}\n      @job={{@job}}\n      @stage={{@stage}}\n      @onReject={{@showRejected}}\n    />\n  </div>\n</div>","moduleName":"teamtailor/components/stage/triggers.hbs","parseOptions":{"srcName":"teamtailor/components/stage/triggers.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { aniMate } from 'ember-ani-mate';
import { get } from '../../utils/get';
import { isSmartMove } from 'teamtailor/models/trigger/smart-move';

export default class StageTriggers extends Component {
  @service router;

  @action handleTriggerMouseEnter(trigger) {
    trigger.loadAsyncRelationships?.();
  }

  @action
  async editTrigger(trigger, _, triggerCardEl) {
    if (!document.startViewTransition) {
      this.goToEditRoute(trigger);
      return;
    }

    const triggerCardRect = triggerCardEl.getBoundingClientRect();

    if (isSmartMove(trigger)) {
      await trigger.reload();
    }

    await trigger.loadAsyncRelationships?.(300);

    triggerCardEl.style.viewTransitionName = 'stage-trigger-card';

    const transition = document.startViewTransition(() =>
      this.goToEditRoute(trigger)?.followRedirects?.()
    );

    await Promise.all([transition.ready, transition.updateCallbackDone]);

    const modalBoxWrapper = document.querySelector(
      '.stage-triggers-modal-box-wrapper'
    );
    const modalBoxWrapperRect = modalBoxWrapper?.getBoundingClientRect();
    if (!modalBoxWrapperRect) {
      return;
    }

    const duration = 250;

    aniMate.to(
      document.documentElement,
      {
        opacity: 0,
      },
      {
        duration: 150,
        easing: 'ease-in-out-cubic',
        pseudoElement: '::view-transition-old(stage-trigger-card)',
      }
    );

    aniMate(
      document.documentElement,
      [
        {
          width: triggerCardRect.width,
          height: triggerCardRect.height,
          left: 0,
          top: 0,
        },
        {
          height: modalBoxWrapperRect.height,
          top: modalBoxWrapperRect.top - triggerCardRect.top,
          left: modalBoxWrapperRect.left - triggerCardRect.left,
          width: modalBoxWrapperRect.width,
        },
      ],
      {
        duration,
        easing: 'ease-in-out-cubic',
        pseudoElement: `::view-transition-old(stage-trigger-card)`,
      }
    );

    aniMate(
      document.documentElement,
      [
        {
          width: triggerCardRect.width,
          height: triggerCardRect.height,
          top: triggerCardRect.top - modalBoxWrapperRect.top,
          left: triggerCardRect.left - modalBoxWrapperRect.left,
          opacity: 0.3,
        },
        {
          opacity: 1,
          height: modalBoxWrapperRect.height,
          left: 0,
          top: 0,
          width: modalBoxWrapperRect.width,
        },
      ],
      {
        duration,
        easing: 'ease-in-out-cubic',
        pseudoElement:
          '::view-transition-new(stage-triggers-modal-box-wrapper)',
      }
    );
  }

  @action goToEditRoute(trigger) {
    return this.router.transitionTo(
      'jobs.job.stages.stage-triggers.edit',
      get(this.args, 'job'),
      trigger,
      {
        queryParams: {
          type: get(trigger, 'type'),
        },
      }
    );
  }

  @action
  createTrigger(type, partnerActivation = null, subtype = null) {
    this.router.transitionTo(
      'jobs.job.stages.stage-triggers.new',
      get(this.args, 'job'),
      {
        queryParams: {
          stageId: get(this.args.stage, 'id'),
          type,
          subtype,
          partnerActivationId: partnerActivation
            ? get(partnerActivation, 'id')
            : null,

          onReject: this.args.showRejected,
        },
      }
    );
  }
}
