/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Mixin.create({
  router: service(),
  ttAlert: service(),
  iframePreview: service(),
  intl: service(),

  classNames: ['careersite-form', 'scrollbar-dark-theme'],

  isAddClicked: false,

  reload() {
    const sectionId = get(this, 'model.id');
    get(this, 'iframePreview').reloadSection(sectionId);
  },

  actions: {
    addNewItem(type) {
      set(this, 'isAddClicked', true);
      get(this, 'router').transitionTo(`content.editor.section.${type}.new`);
    },

    deleteSection(item) {
      set(this, 'hideSaveButton', true);
      get(this, 'ttAlert').confirm(
        this.intl.t('content.editor.confirm_delete_section_item'),
        () => {
          let newArray = get(this, 'model.itemsArray').rejectBy('id', item.id);
          newArray = newArray.map((item, index) => {
            return { ...item, index, rowOrderPosition: index };
          });

          set(this, 'model.items', newArray);

          get(this, 'model')
            .save()
            .then(() => {
              this.reload();
              set(this, 'hideSaveButton', false);
            });
        },
        () => {}
      );
    },

    async saveCustomName(customName, section) {
      await section.setCustomName({ custom_name: customName });
    },
  },
});
