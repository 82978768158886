import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ReferenceTemplateModel from 'teamtailor/models/reference-template';
import { TABLE_COLUMNS } from 'teamtailor/constants/reference-template';
import TimeFormatService from 'teamtailor/services/time-format';
import filterList from 'teamtailor/utils/filter-list';

export default class IndexController extends Controller {
  @service declare timeFormat: TimeFormatService;
  @service declare router: RouterService;

  @tracked search = '';
  sortField = 'name';

  filterAttributes = ['name'];
  columns = TABLE_COLUMNS;

  get filteredList() {
    return filterList(this.filterAttributes, this.model, this.search);
  }

  get sortedTemplates() {
    const listCopy = [...this.filteredList];
    return listCopy.sort((a, b) =>
      a[this.sortField].localeCompare(b[this.sortField], undefined, {
        sensitivity: 'base',
      })
    );
  }

  get totalCount() {
    return this.filteredList.length;
  }

  @action
  copyReferenceTemplate(referenceTemplate: ReferenceTemplateModel) {
    this.router.transitionTo('settings.reference-templates.new', {
      queryParams: { copiedReferenceTemplateId: referenceTemplate.id },
    });
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.reference-templates.index': IndexController;
  }
}
