import Model, { belongsTo, attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default class Approval extends Model {
  @service intl;
  @service server;

  @belongsTo('job') job;
  @belongsTo('user') requester;
  @belongsTo('user') approvedByUser;

  @attr('date') createdAt;
  @attr('string') message;
  @attr('string') status;

  get approver() {
    return this.approvedByUser;
  }

  get approved() {
    return this.status === 'approved';
  }

  get pending() {
    return this.status === 'pending';
  }

  get rejected() {
    return this.status === 'rejected';
  }

  get statusText() {
    if (isEmpty(this.status)) {
      return undefined;
    }

    let statusText = '';

    if (this.status === 'rejected') {
      statusText = this.intl.t('models.approval.not_approved');
    } else {
      statusText = this.status.charAt(0).toUpperCase() + this.status.slice(1);
    }

    return statusText;
  }

  async approve() {
    return this.server.memberAction(this, { action: 'approve' });
  }

  async reject(data) {
    return this.server.memberAction(this, {
      action: 'reject',
      options: { data },
    });
  }
}
