import { inject as service } from '@ember/service';
import ApplicationInstance from '@ember/application/instance';
import IntlService from 'ember-intl/services/intl';
import Store from '@ember-data/store';
import { isEmpty } from '@ember/utils';
import { task } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';
import { gql } from '@apollo/client/core';

import AverageRating from 'teamtailor/utils/average-rating';
import ReportAnalyticsRequest, {
  getClickhousePageviewsTransitionDate,
} from './report-analytics-request';
import {
  EventTypeResponse,
  GoogleAnalyticsSourceTypeResponse,
  PageviewTypeResponse,
} from 'teamtailor/utils/insights/graphql-response-types';
import CurrentService from 'teamtailor/services/current';
import FlipperService from 'teamtailor/services/flipper';
import { get } from 'teamtailor/utils/get';

const INSIGHTS_GA_QUERY = gql`
  query GoogleAnalyticsSourcesQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    googleAnalyticsQuery {
      sources(dateRange: $dateRange, jobIds: $jobIds, companyIds: $companyIds) {
        applications: numberOfApplications
        candidateIds
        pageviews
        sessions
        source
      }
    }
  }
`;

const INSIGHTS_QUERY = gql`
  query SourcesQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    pageviewQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      companyIds: $companyIds
    ) {
      aggregated(groupBy: SOURCE) {
        source
        count
        distinctCount(field: SESSION_ID)
      }
    }
    applications: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [APPLIED]
      companyIds: $companyIds
    ) {
      aggregated(groupBy: [JOB_APPLICATION_SOURCE]) {
        source: jobApplicationSource
        count
        candidateIds: collect(field: CANDIDATE_ID)
      }
    }
  }
`;

interface InsightsEventResult extends EventTypeResponse {
  source: string;
  candidateIds: string[];
}

type Row = {
  name: string;
  sessions: number;
  pageviews: number;
  applications: number;
  candidateIds: string[];
  nrCandidates: number;
  conversionRate: number;
  averageRating: number;
};

function sourceNameAsKey(source: string | undefined) {
  return source?.toLowerCase() || '(direct)';
}

const formatInsightsData = (
  pageviewsData: PageviewTypeResponse[] = [],
  eventsData: InsightsEventResult[] = []
) => {
  if (!pageviewsData.length && !eventsData.length) return [];

  const events = eventsData.map(({ source, count, candidateIds }) => {
    return {
      source,
      pageviews: 0,
      sessions: 0,
      applications: count,
      candidateIds: [...candidateIds],
    };
  });

  const pageviews = pageviewsData.map((row) => {
    const { source, count, distinctCount } = row;

    return {
      source,
      pageviews: count,
      sessions: distinctCount,
      candidateIds: [],
      applications: 0,
    };
  });

  const rows = [...events, ...pageviews];

  return rows.reduce(
    (
      result: GoogleAnalyticsSourceTypeResponse[],
      row: GoogleAnalyticsSourceTypeResponse
    ) => {
      const existingRow = result.find(
        (item) => sourceNameAsKey(item.source) === sourceNameAsKey(row.source)
      );
      if (existingRow) {
        existingRow.pageviews =
          (existingRow.pageviews || 0) + (row.pageviews || 0);
        existingRow.sessions =
          (existingRow.sessions || 0) + (row.sessions || 0);
        existingRow.applications =
          (existingRow.applications || 0) + (row.applications || 0);
        existingRow.candidateIds = [
          ...existingRow.candidateIds,
          ...row.candidateIds,
        ];
      } else {
        result.push(row);
      }

      return result;
    },
    []
  );
};

type ChartDataRow = {
  name: string;
  value: number;
};

class AudienceSourcesReport {
  @service declare current: CurrentService;
  @service declare intl: IntlService;

  rows: Row[] = [];
  container: ApplicationInstance;

  constructor({
    container,
    rows,
  }: {
    container: ApplicationInstance;
    rows: Row[];
  }) {
    this.container = container;
    this.rows = rows;
  }

  get sortedRows() {
    return [...this.rows].sort((row1, row2) => row2.sessions - row1.sessions);
  }

  get cname() {
    return get(this.current, 'company.domain.cname');
  }

  get filteredSources() {
    const { cname } = this;
    return this.sortedRows.filter((row) => {
      if (cname && !isEmpty(cname) && row.name.includes(cname)) {
        return false;
      }

      return !this.current.company.subdomainUrl.includes(row.name);
    });
  }

  get topSources() {
    return this.filteredSources.slice(0, 4);
  }

  get chartData(): ChartDataRow[] {
    const sources = this.filteredSources.filter(
      (source) => source.sessions > 0
    );

    const MAX_SOURCES = 5;
    const LAST_SOURCE_INDEX = 4;

    let data = [];
    if (sources.length > MAX_SOURCES) {
      data = sources.reduce((acc: ChartDataRow[], row, index) => {
        if (index >= LAST_SOURCE_INDEX) {
          if (acc.length < MAX_SOURCES) {
            acc.push({
              name: this.intl.t('insights.reports.other'),
              value: row.sessions,
            });
          } else if (acc[LAST_SOURCE_INDEX]) {
            acc[LAST_SOURCE_INDEX].value += row.sessions;
          }
        } else {
          acc.push({ name: row.name, value: row.sessions });
        }

        return acc;
      }, []);
    } else {
      data = sources.map((source) => {
        return {
          name: source.name,
          value: source.sessions,
        };
      });
    }

    return data;
  }
}

export default class AudienceSourcesReportFetcher {
  container: ApplicationInstance;

  @service declare intl: IntlService;
  @service declare flipper: FlipperService;
  @service declare store: Store;

  constructor({ container }: { container: ApplicationInstance }) {
    this.container = container;
  }

  fetch = task(async () => {
    const transitionDate = getClickhousePageviewsTransitionDate(this.flipper);

    const googleAnalyticsRows = await new ReportAnalyticsRequest({
      container: this.container,
      query: INSIGHTS_GA_QUERY,
      callback: (result?: {
        googleAnalyticsQuery: { sources: GoogleAnalyticsSourceTypeResponse[] };
      }) => result?.googleAnalyticsQuery.sources || [],
    }).fetch({
      before: transitionDate,
    });

    const insightsData = await new ReportAnalyticsRequest({
      container: this.container,
      query: INSIGHTS_QUERY,
      callback: (result) => result,
    }).fetch({ after: transitionDate });

    const clickhouseRows = formatInsightsData(
      insightsData?.pageviewQuery?.aggregated,
      insightsData?.applications?.aggregated
    );

    const _rows = googleAnalyticsRows.reduce(
      (
        acc: GoogleAnalyticsSourceTypeResponse[],
        row: GoogleAnalyticsSourceTypeResponse
      ) => {
        const item = acc.find(
          (clickRow) =>
            sourceNameAsKey(clickRow.source) === sourceNameAsKey(row.source)
        );
        if (item) {
          item.applications += row.applications;
          item.pageviews += row.pageviews;
          item.sessions += row.sessions;
          item.candidateIds = [...item.candidateIds, ...row.candidateIds];
        } else {
          acc = [...acc, { ...row }];
        }

        return acc;
      },
      clickhouseRows.map((row) => ({ ...row }))
    );

    return new AudienceSourcesReport({
      container: this.container,
      rows: _rows.map(
        (sourceTypeResponse: GoogleAnalyticsSourceTypeResponse) => {
          const applications = Math.max(
            sourceTypeResponse.applications,
            sourceTypeResponse.candidateIds.length
          );

          let normalizedSource =
            sourceTypeResponse.source?.toLowerCase() || '(direct)';
          let sourceNameFromPredefined = {
            qr_code: this.intl.t('models.report_audience_sources.qr_code'),
            new_jobs_digest: this.intl.t(
              'models.report_audience_sources.new_jobs_digest'
            ),

            new_job: this.intl.t(
              'models.report_audience_sources.connect_emails'
            ),

            teamtailor_page: this.intl.t(
              'models.report_audience_sources.content_pages'
            ),

            '(direct)': this.intl.t('insights.molecules.sources.direct.title'),
          }[normalizedSource];

          let name = sourceNameFromPredefined ?? sourceTypeResponse.source;

          return {
            name,
            sessions: sourceTypeResponse.sessions,
            pageviews: sourceTypeResponse.pageviews,
            applications,
            candidateIds: sourceTypeResponse.candidateIds,
            nrCandidates: sourceTypeResponse.candidateIds.length,
            conversionRate: applications / sourceTypeResponse.sessions,
            averageRating: new AverageRating(
              this.store,
              sourceTypeResponse.candidateIds
            ),
          };
        }
      ),
    });
  });

  fetchTask = trackedTask(this, this.fetch);
}
