import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class SettingsController extends Controller {
  @service iframePreview;
  @service current;
  @service router;

  @action
  saved() {
    this.iframePreview.reloadIframe();
    this.router.transitionTo('content.editor.section.blog.index');
  }
}
