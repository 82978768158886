import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Molecules::HiredApplications\n  ...attributes\n  class=\"flex-1\"\n  @applications={{this.applications}}\n  @job={{@job}}\n  @loading={{or (not this.fetchData.lastSuccessful) this.fetchData.isRunning}}\n  @onShowExportModal={{this.onShowExportModal}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>", {"contents":"<Insights::Molecules::HiredApplications\n  ...attributes\n  class=\"flex-1\"\n  @applications={{this.applications}}\n  @job={{@job}}\n  @loading={{or (not this.fetchData.lastSuccessful) this.fetchData.isRunning}}\n  @onShowExportModal={{this.onShowExportModal}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>","moduleName":"teamtailor/components/insights/widgets/job-hired-applications.hbs","parseOptions":{"srcName":"teamtailor/components/insights/widgets/job-hired-applications.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { gql } from '@apollo/client/core';

import AverageRating, {
  fetchMissingAverageRatings,
} from 'teamtailor/utils/average-rating';

const HIRES_QUERY = gql`
  query JobHiredApplicationsQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
  ) {
    job: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: HIRED
      distinctBy: {
        fields: [EVENT_TYPE, JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
    ) {
      hires: rows {
        jobId
        jobApplicationId
        timeToHire: jobApplicationTimeToHired
        timestamp: keenTimestamp
        source: jobApplicationSource
      }
    }
  }
`;

const EXPORT_TABLE_COLUMNS = [
  {
    type: 'text',
    propertyName: 'nameOrEmail',
    headerKey: 'common.name',
  },
  {
    type: 'number',
    propertyName: 'timeToHireInDays',
    headerKey: 'insights.molecules.hired_applications.tth_days',
  },
  {
    type: 'average-rating',
    propertyName: 'averageRating',
    headerKey: 'insights.common.rating',
  },
  { type: 'text', propertyName: 'source', headerKey: 'candidate.source' },
  { type: 'text', propertyName: 'timestamp', headerKey: 'common.date' },
];

export default class extends Component {
  @service insights;
  @service insightsExport;
  @service store;

  get applications() {
    return this.fetchData?.lastSuccessful?.value?.applications;
  }

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      'job.hires',
      `job-${this.args.job.id}`,
      EXPORT_TABLE_COLUMNS,
      this.exportData,
      this.args.dateRange,
      { createdAt: this.args.job.createdAt }
    );
  }

  @action
  async exportData(dateRange) {
    const data = await this.queryForData(dateRange);

    await fetchMissingAverageRatings(data);
    return data.map((row) => ({
      ...row,
      timeToHireInDays: Math.round(row.timeToHireInDays),
    }));
  }

  @action
  async queryForData(dateRange) {
    const { job } = this.args;
    const jobDetail = await job.jobDetail;
    const jobStartedAt = moment(jobDetail.jobStartedAt);

    const hires = await this.insights
      .query({
        query: HIRES_QUERY,
        variables: {
          jobIds: [job.id],
          dateRange: dateRange.asObject,
        },

        context: { jobId: job.id },
      })
      .then((result) =>
        result.job.hires.map((row) => ({
          ...row,
          timestamp: moment(row.timestamp).format('YYYY-MM-DD'),
        }))
      );

    const jobApplications = await this.store.query('job-application', {
      ids: hires.mapBy('jobApplicationId'),
    });

    const candidates = await this.store.query('candidate', {
      ids: jobApplications.mapBy('candidateId'),
    });

    return hires.map((row) => {
      const jobApplication = jobApplications.find(
        (jobApplication) => jobApplication.id === row.jobApplicationId
      );

      const candidate = candidates.find(
        (candidate) => Number(candidate.id) === jobApplication?.candidateId
      );

      const timeToHire = moment.duration(row.timeToHire, 'seconds');
      const timeToFill = jobStartedAt
        ? moment.duration(moment(row.timestamp).diff(jobStartedAt))
        : null;

      return {
        ...row,
        source: row.source === '(direct)' ? undefined : row.source,
        initials: (candidate && candidate.initials) || 'dc',
        color: candidate && candidate.color,
        picture: candidate && candidate.picture,
        timeToHireInDays: timeToHire.as('days'),
        timeToFillInDays: timeToFill && timeToFill.as('days'),
        averageRating: new AverageRating(
          this.store,
          (candidate && [candidate.id]) || []
        ),

        nameOrEmail:
          (candidate && candidate.nameOrEmail) || 'Deleted candidate',
      };
    });
  }

  fetchData = dropTask(async () => {
    const applications = await this.queryForData(this.args.dateRange).then(
      (applications) => applications.reverse()
    );

    return {
      applications,
    };
  });
}
