import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{t \"components.activity_item.requested_remind_me\"}}\n{{#if (has-permission \"job/list\")}}\n  <LinkTo\n    @route=\"jobs.job\"\n    @model={{this.activity.actionData.job_id}}\n  >{{this.activity.actionData.job_title}}</LinkTo>\n{{else}}\n  {{this.activity.actionData.job_title}}\n{{/if}}", {"contents":"{{t \"components.activity_item.requested_remind_me\"}}\n{{#if (has-permission \"job/list\")}}\n  <LinkTo\n    @route=\"jobs.job\"\n    @model={{this.activity.actionData.job_id}}\n  >{{this.activity.actionData.job_title}}</LinkTo>\n{{else}}\n  {{this.activity.actionData.job_title}}\n{{/if}}","moduleName":"teamtailor/components/activity-item/reminder-sent.hbs","parseOptions":{"srcName":"teamtailor/components/activity-item/reminder-sent.hbs"}});
import { inject as service } from '@ember/service';
import Base from './base';

export default class ReminderSentComponent extends Base {
  @service current;

  get user() {
    return this.current.user;
  }
}
