import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import {
  DepartmentModel,
  DesignModel,
  JobModel,
  PageModel,
  PickedImageModel,
  SectionModel,
} from 'teamtailor/models';

export default class ImageWithSettingModel extends Model {
  @belongsTo('design') declare design: AsyncBelongsTo<DesignModel>;
  @belongsTo('department') declare department: AsyncBelongsTo<DepartmentModel>;
  @belongsTo('job') declare job: AsyncBelongsTo<JobModel>;
  @belongsTo('section', { polymorphic: true })
  declare section: AsyncBelongsTo<SectionModel>;

  @belongsTo('page') declare page: AsyncBelongsTo<PageModel>;
  @belongsTo('picked-image', { async: false })
  declare pickedImage: PickedImageModel | null;

  @attr('boolean', { defaultValue: true }) declare allowSharingImage: boolean;
  @attr('number', { defaultValue: 50 }) declare coverLayoutOpacity: number;
  @attr('string') declare buttonText: string;
  @attr('string') declare title: string;
  @attr('string', { defaultValue: '#000000' }) declare coverLayoutColor: string;
  @attr('string', { defaultValue: '#FFFFFF' })
  declare coverLayoutTextColor: string;

  @attr('string', { defaultValue: 'default' }) declare coverLayoutType: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'image-with-setting': ImageWithSettingModel;
  }
}
