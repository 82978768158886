import { get } from 'teamtailor/utils/get';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import JobModel from 'teamtailor/models/job';
import Current from 'teamtailor/services/current';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';

export default class SettingsTemplatesRoute extends ScrollToTopRoute {
  @service declare current: Current;
  @service declare pusher: PusherService;
  @service declare router: RouterService;

  private declare pusherChannel?: PusherChannel;

  queryParams = {
    query: { refreshModel: false },
    job_tag_id: { refreshModel: false },
    sort_column: { refreshModel: false },
    sort_direction: { refreshModel: false },
  };

  model() {
    return get(this.current.company, 'templates');
  }

  async afterModel(): Promise<void> {
    return this.current.company.hasMany('templates').reload();
  }

  activate() {
    super.activate();
    this.pusher
      .subscribe(this.current.user.notificationsChannel)
      .then((channel) => {
        this.pusherChannel = channel;
        channel.bind('job-created', (job: JobModel) => {
          this.router.transitionTo('jobs.edit', job.id, {
            queryParams: { template: true },
          });
        });
      });
  }

  deactivate() {
    if (this.pusherChannel) {
      this.pusherChannel.unbind('job-created');
    }
  }
}
