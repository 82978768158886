import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button\n  class=\"w-full max-w-full truncate text-left text-action\"\n  type=\"button\"\n  {{on \"click\" this.download}}\n>\n  <Icon @icon=\"paperclip\" @size=\"16\" class=\"!inline-block\" />\n  {{@activity.upload.fileFileName}}\n</button>\n\n<CandidateModalNew::Main::Section::Documents::Document::VirusModal\n  @isOpen={{this.virusModalOpen}}\n  @onClose={{this.handleVirusModalClose}}\n  @download={{this.download}}\n/>", {"contents":"<button\n  class=\"w-full max-w-full truncate text-left text-action\"\n  type=\"button\"\n  {{on \"click\" this.download}}\n>\n  <Icon @icon=\"paperclip\" @size=\"16\" class=\"!inline-block\" />\n  {{@activity.upload.fileFileName}}\n</button>\n\n<CandidateModalNew::Main::Section::Documents::Document::VirusModal\n  @isOpen={{this.virusModalOpen}}\n  @onClose={{this.handleVirusModalClose}}\n  @download={{this.download}}\n/>","moduleName":"teamtailor/components/candidate-modal-new/right-sidebar/upload-link/index.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-modal-new/right-sidebar/upload-link/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import ActivityModel from 'teamtailor/models/activity';
import Server from 'teamtailor/services/server';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface Args {
  activity: ActivityModel;
}

export default class extends Component<Args> {
  @service declare server: Server;
  @tracked virusModalOpen = false;

  get document() {
    return get(this.args.activity, 'upload');
  }

  @action
  download() {
    const newWindow = window.open();
    const url = get(this.document, 'fileUrl');
    (this.server.request(url) as Promise<{ url: string }>).then(({ url }) => {
      if (!newWindow) {
        return;
      }

      newWindow.location.href = url;
    });
  }

  get isVirusModalOpen() {
    return this.virusModalOpen;
  }

  @action
  handleVirusModalClose() {
    this.virusModalOpen = false;
  }

  @action
  click(event: Event) {
    event.preventDefault();

    const isFileInfected = get(this.document, 'isFileInfected');
    if (isFileInfected) {
      this.virusModalOpen = true;
    } else {
      this.download();
    }
  }
}
