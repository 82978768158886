import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import FlashMessageService from 'teamtailor/services/flash-message';
import RouterService from '@ember/routing/router-service';
import IntlService from 'ember-intl/services/intl';

const customCredentialsKeys = [
  'amsSenderId',
  'apecAccountNumber',
  'catererUsername',
  'catererPassword',
  'catererGlobalUsername',
  'catererGlobalPassword',
  'companyReference',
  'cvLibraryPassword',
  'cvLibraryUsername',
  'cvOnlineEeApiKey',
  'cvOnlineLtApiKey',
  'cvOnlineLvApiKey',
  'cwjobsUsername',
  'cwjobsPassword',
  'ejobsToken',
  'facebookPageUrl',
  'finnClientId',
  'indeedEmail',
  'indeedPhone',
  'jobteaserCompanyId',
  'jobsiteUsername',
  'jobsitePassword',
  'jobborseSupplierId',
  'jobborseHiringOrgId',
  'monsterUsername',
  'monsterPassword',
  'oikotieIdentification',
  'reedPostingKey',
  'reedUsername',
  'stepstoneDeOrganisationId',
  'stepstoneDeSenderId',
  'hainesattractCompanyIdentifier',
  'hainesattractPassword',
  'totaljobsUsername',
  'totaljobsPassword',
  'helloworkContactEmail',
  'linkedinLimitedListingsCompanyId',
  'facebookJobsPageUrl',
  'diceBatchUsername',
  'diceBatchPassword',
  'diceGroupId',
  'uptrailAuthorizationToken',
];

class CustomFormSettingsController extends Controller {
  @service declare current: Current;
  @service declare flashMessages: FlashMessageService;
  @service declare router: RouterService;
  @service declare intl: IntlService;

  @tracked selectedActivationType = this.model.channelActivation
    .existingAgreement
    ? 'existing_agreement'
    : 'teamtailor';

  @tracked providerKeyData = {};
  get isExistingAgreement() {
    return this.selectedActivationType === 'existing_agreement';
  }

  get providerKey() {
    if (!this.isExistingAgreement) return {};
    return this.providerKeyData;
  }

  @action
  cleanupChannelActivationData() {
    if (this.isExistingAgreement) return;
    customCredentialsKeys.forEach(
      (key) => (this.model.channelActivation[key] = null)
    );
  }

  @action
  async save() {
    try {
      this.model.channelActivation.existingAgreement = this.isExistingAgreement;
      await this.current.company.save();

      // when existing agreement is changed to single ads then clean up channel activation data
      this.model.channelActivation.adapterAuthorizationToken = this.providerKey;
      this.cleanupChannelActivationData();
      await this.model.channelActivation.save();
      this.flashMessages.success(
        this.intl.t('settings.integrations.channel_activations.channel_updated')
      );
      this.router.transitionTo('settings.integrations.marketplace-activations');
    } catch (errors) {
      const typedError = errors as Record<string, unknown>[];
      typedError.forEach((error) => {
        this.flashMessages.error(
          (error.detail as string) || this.intl.t('common.something_went_wrong')
        );
      });
    }
  }

  @action
  goBack() {
    return this.router.transitionTo(
      'settings.integrations.marketplace-activations'
    );
  }
}

export default CustomFormSettingsController;

declare module '@ember/controller' {
  interface Registry {
    'settings.integrations.channel-activation.custom-form-settings': CustomFormSettingsController;
  }
}
