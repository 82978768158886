import TriggerSerializer from 'teamtailor/serializers/trigger';

export default TriggerSerializer.extend({
  attrs: {
    stage: { serialize: 'ids' },
    users: { serialize: 'ids' },
    uploads: { serialize: 'records', key: 'uploads_attributes' },
  },

  serialize() {
    let [commonJson, extras] = this.extractCommonJson(
      this._super(...arguments)
    );
    let {
      duration,
      event_description,
      from_time,
      location,
      meeting_room_id,
      organizer_id,
      summary,
      time_zone,
      to_time,
      user_ids,
      has_video,
      provider_name,
      start_interval,
      buffer,
      candidate_reminder,
      timeframe,
      proceed_stage_id,
      uploads_attributes,
      interview_kit_id,
      required_attendees,
    } = extras;

    return Object.assign(commonJson, {
      duration,
      event_description,
      from_time,
      location,
      meeting_room_id,
      organizer_id,
      summary,
      time_zone,
      to_time,
      user_ids,
      has_video,
      provider_name,
      start_interval,
      buffer,
      candidate_reminder,
      timeframe,
      proceed_stage_id,
      uploads_attributes,
      interview_kit_id,
      required_attendees,
    });
  },
});
