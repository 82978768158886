import TemplateFormBaseController from 'teamtailor/controllers/settings/template-form-base';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import FlashMessageService from 'teamtailor/services/flash-message';
import RouterService from '@ember/routing/router-service';
import IntlService from 'ember-intl/services/intl';
import TtAlertService from 'teamtailor/services/tt-alert';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import RouteModel from 'teamtailor/routes/settings/reference-templates/new';

export default class SettingsReferenceTemplatesNewController extends TemplateFormBaseController {
  declare model: ModelFrom<RouteModel>;

  @service declare flashMessages: FlashMessageService;
  @service declare router: RouterService;
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;

  @tracked copiedReferenceTemplateId = null;

  @action
  onUpdateField(fieldUuid: string, data: unknown) {
    const field = this.fields.findBy('uuid', fieldUuid);
    Object.assign(field, data);
  }

  @action
  async save() {
    return this.onSave().then(() => {
      this.flashMessages.success(
        this.intl.t('settings.reference_templates.saved')
      );
      this.router.transitionTo('settings.reference-templates.index');
    });
  }

  @action
  confirmDelete() {
    return this.ttAlert.confirm(
      this.intl.t('settings.reference_templates.confirm_delete', {
        templateName: this.model.name,
      }),
      async () => {
        await this.model.destroyRecord();
        this.router.transitionTo('settings.reference-templates.index');
      }
    );
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.reference-templates.new': SettingsReferenceTemplatesNewController;
  }
}
