import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<main\n  ...attributes\n  class={{class-names\n    \"relative rounded-b-10  bg-canvas max-[640px]:px-24\"\n    (if this.isScrollable \"overflow-auto\" \"overflow-hidden\")\n    (if this.padding \"px-44 py-32\")\n    \"flex flex-1 flex-col gap-32 overscroll-contain\"\n  }}\n  {{on \"scroll\" (optional @onScroll)}}\n  {{did-insert this.handleIsLoadingChange}}\n  {{did-update this.handleIsLoadingChange @isLoading}}\n>\n  {{#if this._isLoading}}\n    <LoadingSpinner @size=\"large\" />\n  {{else}}\n    {{yield (hash Section=(component \"core/modal/main/section\"))}}\n  {{/if}}\n</main>", {"contents":"<main\n  ...attributes\n  class={{class-names\n    \"relative rounded-b-10  bg-canvas max-[640px]:px-24\"\n    (if this.isScrollable \"overflow-auto\" \"overflow-hidden\")\n    (if this.padding \"px-44 py-32\")\n    \"flex flex-1 flex-col gap-32 overscroll-contain\"\n  }}\n  {{on \"scroll\" (optional @onScroll)}}\n  {{did-insert this.handleIsLoadingChange}}\n  {{did-update this.handleIsLoadingChange @isLoading}}\n>\n  {{#if this._isLoading}}\n    <LoadingSpinner @size=\"large\" />\n  {{else}}\n    {{yield (hash Section=(component \"core/modal/main/section\"))}}\n  {{/if}}\n</main>","moduleName":"teamtailor/components/core/modal/main.hbs","parseOptions":{"srcName":"teamtailor/components/core/modal/main.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { aniMate, beforeAfter } from 'ember-ani-mate';
import { argDefault } from 'teamtailor/utils/arg-default';

interface CoreModalMainArgs {
  isScrollable?: boolean;
  isLoading?: boolean;
  padding?: boolean;
}

export default class CoreModalMain extends Component<CoreModalMainArgs> {
  @argDefault isScrollable = true;
  @argDefault padding = true;

  @tracked _isLoading? = this.args.isLoading;

  @action async handleIsLoadingChange(el: HTMLElement) {
    if (!!this.args.isLoading !== !!this._isLoading) {
      const { before, after } = await beforeAfter(
        el,
        'height',
        () => (this._isLoading = !!this.args.isLoading)
      );

      await aniMate(el, [{ flexBasis: before }, { flexBasis: after }], {
        setStylesAfter: false,
      });
    }
  }
}
