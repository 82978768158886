import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class ApprovalsRedirectRoute extends Route {
  @service router;

  model({ approval_id, type }) {
    super.model({ approval_id }).then((approval) => {
      this.router.transitionTo(
        `jobs.job.approvals.${type}`,
        get(approval, 'job'),
        approval
      );
    });
  }
}
