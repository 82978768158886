import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import ENV from 'teamtailor/config/environment';

const PAGES_WITH_SIDEBAR = [
  'tags',
  'questions',
  'templates',
  'locations',
  'canned_responses',
  'todo-templates',
];

export default class SettingsController extends Controller {
  @service router;
  @service current;
  @service flipper;

  facebookAppId = ENV.facebook.appId;

  get hasSidebar() {
    return PAGES_WITH_SIDEBAR.includes(this.settingsPageName);
  }

  get company() {
    return this.current.company;
  }

  get settingsPageName() {
    return this.router.currentRouteName.split('.')[1];
  }
}
