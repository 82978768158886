import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FormRadioButton\n  class={{this.radioButtonClasses}}\n  @value={{this.value}}\n  @groupValue={{this.groupValue}}\n  @changed={{@onChange}}\n>\n  <div class=\"c-section-layout-option__preview\">\n    <img src={{this.image}} alt={{this.description}} />\n    <span class=\"c-section-layout-option__description\">\n      {{this.description}}\n    </span>\n    {{#if this.border}}\n      <div class=\"c-section-layout-option__border\"></div>\n    {{/if}}\n    <div class=\"c-section-layout-option__overlay\">\n      <span class=\"c-section-layout-option__overlay-content\">\n        {{t \"content.editor.section.use\"}}\n      </span>\n    </div>\n    <div class=\"c-section-layout-option__current\">\n      <Icon @icon=\"check\" />\n    </div>\n  </div>\n</FormRadioButton>", {"contents":"<FormRadioButton\n  class={{this.radioButtonClasses}}\n  @value={{this.value}}\n  @groupValue={{this.groupValue}}\n  @changed={{@onChange}}\n>\n  <div class=\"c-section-layout-option__preview\">\n    <img src={{this.image}} alt={{this.description}} />\n    <span class=\"c-section-layout-option__description\">\n      {{this.description}}\n    </span>\n    {{#if this.border}}\n      <div class=\"c-section-layout-option__border\"></div>\n    {{/if}}\n    <div class=\"c-section-layout-option__overlay\">\n      <span class=\"c-section-layout-option__overlay-content\">\n        {{t \"content.editor.section.use\"}}\n      </span>\n    </div>\n    <div class=\"c-section-layout-option__current\">\n      <Icon @icon=\"check\" />\n    </div>\n  </div>\n</FormRadioButton>","moduleName":"teamtailor/components/section-layout-option.hbs","parseOptions":{"srcName":"teamtailor/components/section-layout-option.hbs"}});
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import classnames from 'teamtailor/utils/classnames';

export default Component.extend({
  border: false,
  description: null,
  dynamicSize: false,
  groupValue: null,
  image: null,
  tagName: '',
  value: null,
  extraClasses: null,

  radioButtonClasses: computed(
    'border',
    'dynamicSize',
    'extraClasses',
    function () {
      return classnames(
        'c-section-layout-option',
        'tt-dark-theme',
        get(this, 'extraClasses'),
        {
          'c-section-layout-option--with-border': get(this, 'border'),
          'c-section-layout-option--dynamic-size': get(this, 'dynamicSize'),
        }
      );
    }
  ),
});
