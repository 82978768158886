import Model, { belongsTo, attr, type AsyncBelongsTo } from '@ember-data/model';
import CompanyModel from './company';

export default class PhoneNumberSetupModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @attr('string') declare status: string;
  @attr('string') declare numberCountry: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'phone-number-setup': PhoneNumberSetupModel;
  }
}
