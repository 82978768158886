import SettingsLocationsRegionsIndexController from 'teamtailor/controllers/settings/locations/regions';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';

export default class SettingsLocationsRegionsRoute extends ScrollToTopRoute {
  queryParams = {
    query: { refreshModel: true },
    sort_direction: { refreshModel: true },
  };

  resetController(
    controller: SettingsLocationsRegionsIndexController,
    isExiting: boolean
  ) {
    if (isExiting) {
      controller.page = 1;
    }
  }
}
