import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import UserModel from 'teamtailor/models/user';
import FlipperService from 'teamtailor/services/flipper';

export default class EmployeesProfileSecurity extends Route {
  @service declare flipper: FlipperService;
  @service declare router: RouterService;

  model(): UserModel {
    return this.modelFor('employees.profile');
  }
}
