export const DEFAULT_BADGE_COLOR = 'zinc';

export const BADGE_COLOR_MAP = {
  ai_suggested: 'violet',
  approved: 'green',
  archived: 'red',
  completed: 'blue',
  connected: 'purple',
  copilot: 'copilot',
  danger: 'red',
  default: DEFAULT_BADGE_COLOR,
  draft: 'yellow',
  expired: 'amber',
  hired: 'green',
  inbox: 'blue',
  internal: 'indigo',
  lead: 'cyan',
  missing: 'yellow',
  pending: 'amber',
  pinned: 'amber',
  overdue: 'rose',
  referred: 'cerise',
  rejected: 'red',
  reminded: 'orange',
  requested: 'orange',
  restricted: 'orange',
  reviewing: 'orange',
  shared: 'zinc',
  scheduled: 'cyan',
  sourced: 'teal',
  sourced_external: 'zinc',
  unread: 'blue',
  unsubscribed: 'zinc',
  will_be_deleted: 'orange',
  new: 'amber',
};
