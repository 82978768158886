import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import recordRemover from 'teamtailor/utils/record-remover';
import applyDefaultPickedQuestionValues from 'teamtailor/utils/apply-default-picked-question-values';

export default class BaseController extends Controller {
  @service store;
  @service current;
  @service router;
  @service intl;
  @service flashMessages;

  get company() {
    return this.current.company;
  }

  get cannedResponse() {
    return this.model;
  }

  get sortedPickedQuestions() {
    return this.model?.sortedQuestions;
  }

  get lastPickedQuestion() {
    return this.sortedPickedQuestions?.lastObject;
  }

  get hasCareerSiteLanguages() {
    return this.company.hasFeature('career_site_languages');
  }

  @action
  save() {
    let { model } = this;
    const { options } = get(model, 'assetStructure');

    return model.save().then(() => {
      recordRemover(this.store, 'upload', 'pickedQuestion');

      if (this.hasCareerSiteLanguages && model.translations.length > 0) {
        model.translations.forEach((translation) => {
          if (translation.hasDirtyAttributes) {
            translation.cannedResponse = model;
            translation.save();
          }
        });
      }

      let { assetStructure } = model;
      assetStructure.options = options;
      assetStructure.save();

      model.pickedQuestions
        .filterBy('isDeleted', true)
        .forEach((upload) => upload.unloadRecord());

      this.flashMessages.success(
        this.intl.t('settings.canned_responses.template_saved')
      );
      this.router.transitionTo('settings.canned_responses');
    });
  }

  @action
  handleFileUploaded(languageCode, temporaryFileUrl, filename) {
    this.model.uploads.createRecord({
      temporaryFileUrl,
      fileFileName: filename,
      languageCode: this.hasCareerSiteLanguages ? languageCode : null,
    });
  }

  @action
  removeUpload(upload) {
    if (upload.isNew) {
      upload.destroyRecord();
    } else {
      upload.deleteRecord();
    }
  }

  @action
  addQuestion(question) {
    const lastRowOrder = this.lastPickedQuestion?.rowOrder;
    let rowOrder = lastRowOrder !== undefined ? lastRowOrder + 1 : null;

    this.model.pickedQuestions.createRecord(
      applyDefaultPickedQuestionValues({
        question,
        rowOrder,
      })
    );
  }

  @action
  removeQuestion(question) {
    question.deleteRecord();
  }
}
