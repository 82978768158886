import Model, { AsyncHasMany, attr, hasMany } from '@ember-data/model';
import moment from 'moment-timezone';
import { UserModel } from 'teamtailor/models';

export default class DataPrivacySettingModel extends Model {
  @hasMany('user') declare users: AsyncHasMany<UserModel>;

  @attr('boolean', { defaultValue: false }) declare autoSendExtend: boolean;
  @attr('boolean', { defaultValue: false }) declare autoSendInitial: boolean;
  @attr('boolean', { defaultValue: false }) declare autoSendRenewal: boolean;
  @attr('date') setupRunAt?: Date | null;
  @attr('number') declare consentExpiration: number;
  @attr('number') declare sourcedReferredReminder: number;

  get activated() {
    return Boolean(this.id);
  }

  get consentExpirationDate() {
    return moment().add(this.consentExpiration, 'days');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'data-privacy-setting': DataPrivacySettingModel;
  }
}
