import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showBanner}}\n  <AlertMessage\n    @onClose={{this.onClose}}\n    class=\"!mx-8 !my-8 items-center rounded-8\"\n    @type=\"info\"\n    @noMargin={{true}}\n  >\n    <p>\n      <Button\n        @appearance=\"tertiary\"\n        @onClick={{this.enableDesktopNotifications}}\n        @text={{t\n          \"components.tt_notifications.list.enable_desktop_notifications\"\n        }}\n      />\n    </p>\n  </AlertMessage>\n{{/if}}", {"contents":"{{#if this.showBanner}}\n  <AlertMessage\n    @onClose={{this.onClose}}\n    class=\"!mx-8 !my-8 items-center rounded-8\"\n    @type=\"info\"\n    @noMargin={{true}}\n  >\n    <p>\n      <Button\n        @appearance=\"tertiary\"\n        @onClick={{this.enableDesktopNotifications}}\n        @text={{t\n          \"components.tt_notifications.list.enable_desktop_notifications\"\n        }}\n      />\n    </p>\n  </AlertMessage>\n{{/if}}","moduleName":"teamtailor/components/notifications/enable-desktop-notifications-banner.hbs","parseOptions":{"srcName":"teamtailor/components/notifications/enable-desktop-notifications-banner.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import DesktopNotificationsService from 'teamtailor/services/desktop-notifications';
import { inject as service } from '@ember/service';

export default class EnableDesktopNotificationsBanner extends Component {
  @service declare desktopNotifications: DesktopNotificationsService;

  get isNotificationsGranted() {
    return this.desktopNotifications.isNotificationsGranted;
  }

  get showBanner(): boolean {
    return this.desktopNotifications.notificationPermission === 'default';
  }

  @action
  enableDesktopNotifications() {
    this.desktopNotifications.enableDesktopNotifications();
  }
}
