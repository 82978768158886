import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { set, action } from '@ember/object';
import { get } from 'teamtailor/utils/get';

import config from 'teamtailor/config/environment';
import { tracked } from '@glimmer/tracking';

const MILLISECONDS_PER_SECOND = 1000;
const SECONDS_PER_HOUR = 3600;
const HOURS_PER_DAY = 24;

export default class CompanyController extends Controller {
  @service appRefresher;
  @service batchJob;
  @service companyPage;
  @service current;
  @service confetti;
  @service notifications;
  @service quickNavigation;
  @service router;
  @service session;
  @service flipper;
  @service intl;
  @service permissions;

  pwaInstallIsSupported = false;

  @tracked impersonating = false;
  @tracked toolbox = null;
  @tracked _modal = null;
  queryParams = ['toolbox', { _modal: 'modal' }];

  get company() {
    return this.current.company;
  }

  get user() {
    return this.current.user;
  }

  get modal() {
    return JSON.parse(this._modal);
  }

  set modal(val) {
    this._modal = val ? JSON.stringify(val) : null;
  }

  navbarVisibleHeight = 56;

  adminUrl = `https://tt.${config.HTTP_HOST}/admin/`;

  get shouldDisplayTrialBanner() {
    return this.daysToTrialEnd !== undefined && this.daysToTrialEnd > 0;
  }

  get shouldDisplayTwoFactorBanner() {
    if (
      this.user.login?.twoFactorActivated ||
      this.company.singleSignOnActive
    ) {
      return false;
    }

    const {
      twoFactorAuthenticationEnforcedAt,
      twoFactorAuthenticationEnforced,
    } = this.company;

    return (
      twoFactorAuthenticationEnforcedAt && !twoFactorAuthenticationEnforced
    );
  }

  get shouldDisplaySmsProviderChangedBanner() {
    return (
      get(this.company, 'customer') &&
      !get(this.company, 'paymentByInvoice') &&
      get(this.company, 'missingPaymentReason') === 'missing_for_sms_renewal'
    );
  }

  get daysToTrialEnd() {
    const trialEndsAt = get(this.company, 'trialEndsAt');

    if (!trialEndsAt) {
      return undefined;
    }

    const now = new Date();

    const differenceInMs = trialEndsAt.getTime() - now.getTime();

    const daysToTrialEnd = Math.ceil(
      differenceInMs /
        (MILLISECONDS_PER_SECOND * SECONDS_PER_HOUR * HOURS_PER_DAY)
    );

    return daysToTrialEnd;
  }

  get trialEnded() {
    return this.daysToTrialEnd <= 0;
  }

  get isDisplayingAnyBanner() {
    return (
      this.shouldDisplayTrialBanner ||
      this.shouldDisplayTwoFactorBanner ||
      this.shouldDisplaySmsProviderChangedBanner
    );
  }

  environment = config.environment;
  translationLocale = config.translationLocale;

  @action
  onOpenUserStatusModal() {
    this.modal = {
      component: 'user-status-modal',
    };
  }

  @action
  refreshRoute() {
    this.router.refresh('company');
  }

  @action
  closeToolbox(page) {
    set(this, 'toolbox', page);
  }

  @action handleCloseModal() {
    this.modal = null;
  }
}
