import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import InfinityService from 'ember-infinity/addon/services/infinity';

type Params = {
  query: string;
  sort_column: string;
  sort_direction: string;
};

export default class SkillsTraitsRoute extends ScrollToTopRoute {
  @service declare store: Store;
  @service declare infinity: InfinityService;

  queryParams = {
    query: { refreshModel: true },
    question_tag_id: { refreshModel: true },
    type: { refreshModel: true },
    sort_column: { refreshModel: true },
    sort_direction: { refreshModel: true },
  };

  model(params: Params) {
    return this.infinity.model('scorecard-criterium', {
      perPage: 25,
      infinityCache: 36000,
      name: params.query,
      query: params.query,
      sort_column: params.sort_column,
      sort_direction: params.sort_direction,
      startingPage: 1,
    });
  }
}
