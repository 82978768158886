import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<LabelSwitch\n  @checked={{@settings.hideEmpty}}\n  @handleChange={{this.toggleHideEmpty}}\n  @alignment=\"right\"\n  class=\"pt-16\"\n>\n  <span class=\"body-text-s\">\n    {{t\n      (concat \"insights.reports.builder.chart_settings.hide_empty_\" @dateType)\n    }}\n  </span>\n</LabelSwitch>", {"contents":"<LabelSwitch\n  @checked={{@settings.hideEmpty}}\n  @handleChange={{this.toggleHideEmpty}}\n  @alignment=\"right\"\n  class=\"pt-16\"\n>\n  <span class=\"body-text-s\">\n    {{t\n      (concat \"insights.reports.builder.chart_settings.hide_empty_\" @dateType)\n    }}\n  </span>\n</LabelSwitch>","moduleName":"teamtailor/components/insights/molecules/reports/line-chart-settings.hbs","parseOptions":{"srcName":"teamtailor/components/insights/molecules/reports/line-chart-settings.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import Settings from 'teamtailor/utils/insights/reports/settings';

type Args = {
  settings: Settings;
  onUpdate: (settings: Settings) => void;
  dateType: 'date' | 'week' | 'month' | 'year';
};

export default class ReportPieChartSettings extends Component<Args> {
  get currentSettings() {
    return this.args.settings;
  }

  @action
  toggleHideEmpty() {
    this.currentSettings.limitType = this.currentSettings.hideEmpty
      ? null
      : 'hideEmpty';
    this.args.onUpdate(this.currentSettings);
  }
}
