import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Icon ...attributes @icon={{this.activityIconName}} @style=\"solid\" />", {"contents":"<Icon ...attributes @icon={{this.activityIconName}} @style=\"solid\" />","moduleName":"teamtailor/components/insights/activity-icon.hbs","parseOptions":{"srcName":"teamtailor/components/insights/activity-icon.hbs"}});
import Component from '@glimmer/component';

export default class InsightsActivityIcon extends Component {
  get activityIconName() {
    switch (this.args.activity.toLowerCase()) {
      case 'hires':
      case 'hired':
        return 'thumbs-up';

      case 'move':
      case 'moves':
      case 'moved':
        return 'circle-check';

      case 'message':
      case 'messages':
        return 'comments';

      case 'note':
      case 'notes':
        return 'note-sticky';

      case 'reject':
      case 'rejected':
      case 'rejections':
        return 'ban';

      case 'review':
      case 'reviews':
        return 'star';

      case 'source':
      case 'sourced':
      case 'sources':
      case 'referral':
      case 'referrals':
        return 'arrow-right';

      case 'interview':
      case 'interview_created':
      case 'interviews':
        return 'microphone';

      default:
        return 'circle';
    }
  }
}
