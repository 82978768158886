import Controller from '@ember/controller';

export default class ContentEditorHeaderController extends Controller {
  get careerSiteHeader() {
    return this.model.careerSiteHeader;
  }

  get page() {
    return this.model.page;
  }
}
