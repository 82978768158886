import Store from '@ember-data/store';
import { set } from '@ember/object';
import Transition from '@ember/routing/-private/transition';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import { Changeset as changeset } from 'ember-changeset';
import EditController from 'teamtailor/controllers/settings/security/sso/edit';
import SingleSignOnModel from 'teamtailor/models/single-sign-on';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';

export default class SettingsSecuritySsoEdit extends ScrollToTopRoute {
  @service declare current: Current;
  @service declare store: Store;

  async model(): Promise<SingleSignOnModel> {
    const model = (await get(
      this.current.company,
      'singleSignOn'
    )) as unknown as SingleSignOnModel;

    return model;
  }

  setupController(
    controller: EditController,
    model: SingleSignOnModel,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);
    set(controller, 'changeset', changeset(model));
  }

  resetController(controller: EditController, isExiting: boolean): void {
    if (isExiting) {
      controller.changeset.rollback();
    }
  }
}
