import Model, { attr } from '@ember-data/model';

export default class InvoiceInfoModel extends Model {
  @attr('string') declare companyName: string;
  @attr('string') declare corporateNumber: string;
  @attr('string') declare costcenter: string;
  @attr('string') declare invoicingAddress: string;
  @attr('string') declare invoicingEmail: string;
  @attr('string') declare otherInformation: string;
  @attr('string') declare vatNumber: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'invoice-info': InvoiceInfoModel;
  }
}
