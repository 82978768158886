import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class TagRoute extends Route {
  @service store;

  model() {
    let stage = this.modelFor('jobs.edit.stages.triggers.new');
    let job = get(stage, 'job');

    return this.store.createRecord('trigger', {
      job,
      stage,
      type: 'tag',
    });
  }
}
