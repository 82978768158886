import Service, { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

export default class RejectSettings extends Service {
  @service declare intl: IntlService;

  get defaultDelayOptions() {
    return [
      {
        value: 0,
        text: this.intl.t('components.reject_settings_form.no_delay'),
      },
      {
        value: 10800,
        text: this.intl.t('components.reject_settings_form.3_hours'),
      },
      {
        value: 86400,
        text: this.intl.t('components.reject_settings_form.1_day'),
      },
      {
        value: 604800,
        text: this.intl.t('components.reject_settings_form.1_week'),
      },
    ];
  }
}

declare module '@ember/service' {
  interface Registry {
    'reject-settings': RejectSettings;
  }
}
