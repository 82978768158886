export const STAGE_TYPE_COLORS = {
  inbox: 'blue',
  screening: 'orange',
  interview: 'purple',
  offer: 'yellow',
  hired: 'green',
  rejected: 'red',
};

export const DEFAULT_STAGE_COLOR = 'zinc';
