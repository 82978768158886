import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"max-w-[100px]\" ...attributes>\n  <Core::Dropdown::SelectScrollWrapper\n    @fetchPageTask={{this.fetchCandidatesTask}}\n    as |sw|\n  >\n    <Core::Select\n      @size=\"small\"\n      @optionType=\"candidate\"\n      @searchOnOpen={{true}}\n      @options={{sw.options}}\n      @onSearch={{perform sw.searchTask}}\n      @onSelect={{@onCandidateSelected}}\n      @selected={{@candidate}}\n      @nullOption={{t \"core.select.none\"}}\n      @closeOnSelect={{true}}\n    />\n  </Core::Dropdown::SelectScrollWrapper>\n</div>", {"contents":"<div class=\"max-w-[100px]\" ...attributes>\n  <Core::Dropdown::SelectScrollWrapper\n    @fetchPageTask={{this.fetchCandidatesTask}}\n    as |sw|\n  >\n    <Core::Select\n      @size=\"small\"\n      @optionType=\"candidate\"\n      @searchOnOpen={{true}}\n      @options={{sw.options}}\n      @onSearch={{perform sw.searchTask}}\n      @onSelect={{@onCandidateSelected}}\n      @selected={{@candidate}}\n      @nullOption={{t \"core.select.none\"}}\n      @closeOnSelect={{true}}\n    />\n  </Core::Dropdown::SelectScrollWrapper>\n</div>","moduleName":"teamtailor/components/todo/edit/candidate-picker.hbs","parseOptions":{"srcName":"teamtailor/components/todo/edit/candidate-picker.hbs"}});
import { set } from '@ember/object';
import Component from '@glimmer/component';
import { enqueueTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default class TodoEditCandidatePicker extends Component {
  @service store;

  fetchCandidatesTask = enqueueTask(async ({ searchTerm: query, page }) => {
    const params = {
      page: page || 1,
      query,
    };

    let candidates = await this.store.query('candidate', params);

    if (page === 1 && this.args.defaultOptionPlaceholder) {
      let { meta } = candidates;
      candidates = [
        {
          id: '',
          nameOrEmail: this.args.defaultOptionPlaceholder,
        },
        ...candidates.toArray(),
      ];
      set(candidates, 'meta', meta);
    }

    return candidates;
  });
}
