import Model, { attr, AsyncBelongsTo, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import IntlService from 'ember-intl/services/intl';
import {
  UploadModel,
  ShareLinkModel,
  JobApplicationModel,
} from 'teamtailor/models';

export default class SharedItemModel extends Model {
  @service declare intl: IntlService;

  @belongsTo('job-application')
  declare jobApplication: AsyncBelongsTo<JobApplicationModel>;

  @belongsTo('share-link') declare shareLink: AsyncBelongsTo<ShareLinkModel>;

  @belongsTo('upload') declare upload: AsyncBelongsTo<UploadModel>;

  @attr('boolean', { defaultValue: true }) declare visible: boolean;

  get forCoverLetter() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return !!(this as any).belongsTo('jobApplication').id();
  }

  get hasUploadActivity() {
    const upload = get(this, 'upload');
    return get(upload, 'hasActivity');
  }

  get label() {
    if (get(this.jobApplication, 'id')) {
      return this.intl.t('models.shared_item.cover_letter_label', {
        jobTitle: get(this.jobApplication, 'jobTitle'),
      });
    } else if (get(this.upload, 'id')) {
      return get(this.upload, 'fileFileName');
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'shared-item': SharedItemModel;
  }
}
