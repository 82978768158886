export default {
  "root": "Header__root_1cidco",
  "extraPadding": "Header__extraPadding_1cidco",
  "container": "Header__container_1cidco",
  "button": "Header__button_1cidco",
  "isEditing": "Header__isEditing_1cidco",
  "floatingEditContainer": "Header__floatingEditContainer_1cidco",
  "floatingEdit": "Header__floatingEdit_1cidco",
  "editContainer": "Header__editContainer_1cidco",
  "nps-score": "Header__nps-score_1cidco",
  "date": "Header__date_1cidco",
  "stars": "Header__stars_1cidco"
};
