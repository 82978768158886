import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { StageModel } from 'teamtailor/models';

export default class StageJobApplicationCountModel extends Model {
  @belongsTo('stage') declare stage: AsyncBelongsTo<StageModel>;

  @attr('number') declare activeCount: number;
  @attr('number') declare rejectedCount: number;
  @attr('number') declare overdueGuideTimeCount: number;
  @attr('number') declare activeSourcedCount: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'stage-job-application-count': StageJobApplicationCountModel;
  }
}
