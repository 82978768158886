import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label class=\"tt-form-label no-global-styles\">\n  {{#if @label}}\n    <span class=\"tt-form-label-text\">\n      {{@label}}\n    </span>\n  {{/if}}\n  <DatetimePicker\n    @displayCalendarIcon={{true}}\n    @enableTime={{false}}\n    @value={{this.value}}\n    @onChange={{this.pickDate}}\n    @useUTC={{true}}\n    @minDate={{this.minDate}}\n    @maxDate={{this.maxDate}}\n    @verticalPosition=\"auto\"\n  />\n</label>", {"contents":"<label class=\"tt-form-label no-global-styles\">\n  {{#if @label}}\n    <span class=\"tt-form-label-text\">\n      {{@label}}\n    </span>\n  {{/if}}\n  <DatetimePicker\n    @displayCalendarIcon={{true}}\n    @enableTime={{false}}\n    @value={{this.value}}\n    @onChange={{this.pickDate}}\n    @useUTC={{true}}\n    @minDate={{this.minDate}}\n    @maxDate={{this.maxDate}}\n    @verticalPosition=\"auto\"\n  />\n</label>","moduleName":"teamtailor/components/partner/config-fields/date.hbs","parseOptions":{"srcName":"teamtailor/components/partner/config-fields/date.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

import moment from 'moment-timezone';

export default class extends Component {
  @action
  pickDate(value) {
    const date = moment.utc(value).format('YYYY-MM-DD');
    this.args.onChange(date);
  }

  get minDate() {
    return new Date(this.args.field?.min);
  }

  get maxDate() {
    return new Date(this.args.field?.max);
  }

  get value() {
    return new Date(this.args.value);
  }
}
