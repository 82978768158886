import Route from '@ember/routing/route';
import { fetch } from 'teamtailor/classes/analytics/report-job-overviews';
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';

export default class IndexRoute extends Route {
  @service analytics;

  model() {
    const jobIds = this.analytics.selectedJobIds;
    const container = getOwner(this);
    return fetch(container, jobIds);
  }
}
