import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import JobsEditStagesQuickRejectSettingsEditRoute from 'teamtailor/routes/jobs/edit/stages/quick-reject-settings/edit';
import FlashMessageService from 'teamtailor/services/flash-message';
import { get } from 'teamtailor/utils/get';
import rejectSettingsFormValid from 'teamtailor/utils/reject-settings-form-valid';
import { ModelFrom } from 'teamtailor/utils/type-utils';

export default class JobsEditStagesQuickRejectSettingsEditController extends Controller {
  declare model: ModelFrom<JobsEditStagesQuickRejectSettingsEditRoute>;

  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;

  get formIsValid() {
    return rejectSettingsFormValid(
      'settings.rejectReason',
      'settings.sendMessage',
      'settings.cannedResponse',
      'settings.subject',
      'settings.body'
    );
  }

  @action
  async handleClose() {
    const job = await get(this.model.stage, 'job');
    if (
      this.router.currentRouteName.startsWith(
        'jobs.edit.stages.quick-reject-settings'
      )
    ) {
      this.router.transitionTo('jobs.edit.stages', job.id);
    } else {
      this.router.transitionTo('jobs.job.stages.index', job.id);
    }
  }

  @action
  async deleteSettings() {
    this.model.deleteRecord();

    try {
      await this.model.save();
      later(this.handleClose, 500);
    } catch {
      this.model.rollbackAttributes();
      this.flashMessages.error('common.something_went_wrong');
    }
  }

  @action
  async saveSettings() {
    try {
      await this.model.save();
      later(this.handleClose, 500);
    } catch {
      this.flashMessages.error('common.something_went_wrong');
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'jobs.edit.stages.quick-reject-settings.edit': JobsEditStagesQuickRejectSettingsEditController;
  }
}
