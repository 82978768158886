import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { get, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { gql } from '@apollo/client/core';
import jsonToCsvFile from 'teamtailor/utils/json-to-csv-file';

export default class extends Controller {
  @service analytics;
  @service user;
  @service apollo;

  @tracked sortProperty = 'created_at';
  @tracked sortOrder = 'desc';

  @tracked visiblePartnerNames = this.model.partnerResults.value.partnerNames;

  get data() {
    return this.model.partnerResults.value;
  }

  get rows() {
    return this.data?.rows || [];
  }

  get sortedRows() {
    const { sortOrder, sortProperty } = this;
    const sortedSources = this.rows.sortBy(sortProperty);
    return sortOrder === 'desc' ? sortedSources.reverse() : sortedSources;
  }

  @action
  handleCheck(clickedPartnerName) {
    let visiblePartnerNames = [...this.visiblePartnerNames];

    if (visiblePartnerNames.includes(clickedPartnerName)) {
      visiblePartnerNames = visiblePartnerNames.filter(
        (partnerName) => partnerName !== clickedPartnerName
      );
    } else {
      visiblePartnerNames.push(clickedPartnerName);
    }

    this.visiblePartnerNames = visiblePartnerNames;
  }

  @action
  handleSort(sortProperty) {
    if (this.sortProperty === sortProperty) {
      const nextOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
      this.sortOrder = nextOrder;
    } else {
      this.sortOrder = 'desc';
      this.sortProperty = sortProperty;
    }
  }

  @action
  async handleExport() {
    const { candidates } = await this.apollo.query({
      query: gql`
        query ($candidateIds: [ID!]!, $userId: ID!) {
          candidates(ids: $candidateIds, userScope: { userId: $userId }) {
            nameOrEmail
            id
          }
        }
      `,
      variables: {
        candidateIds: this.rows.map((row) => row.candidate.id),
        userId: get(this, 'user.id'),
      },
    });

    jsonToCsvFile(
      'partner-results',
      this.analytics.startDate,
      this.analytics.endDate,
      this.rows,
      (row) => ({
        candidateId: get(row, 'candidate.id'),
        candidateName: candidates.findBy('id', get(row, 'candidate.id'))
          ?.nameOrEmail,

        createdAt: row.created_at,
        score: row.score,
        status: row.status,
        url: row.url,
      })
    );
  }
}
