import { htmlSafe } from '@ember/template';
import { helper } from '@ember/component/helper';
import linkifyText from 'teamtailor/utils/linkify';
import { SafeString } from '@ember/template/-private/handlebars';

export function linkify([string]: string): SafeString {
  const text = typeof string !== 'string' ? '' : linkifyText(string);
  return htmlSafe(text);
}

export default helper(linkify);
