import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import { get } from 'teamtailor/utils/get';

export default class MultipleUsers extends Helper {
  @service intl;

  compute([users]) {
    if (users.isDestroyed || users.isDestroying) {
      return '';
    }

    const nonAdmins = users.filter((user) => !get(user, 'admin'));

    if (!nonAdmins.length) {
      return `${this.intl.t(
        'components.note_feed_item.visibility_selected'
      )} ${this.intl.t('components.note_feed_item.all_admins')}`;
    }

    const nonAdminNames = nonAdmins.map((user) => get(user, 'nameOrEmail'));

    return `${this.intl.t(
      'components.note_feed_item.visibility_selected'
    )} ${nonAdminNames.join(', ')} ${this.intl.t('common.and')} ${this.intl.t(
      'components.note_feed_item.all_admins'
    )}`;
  }
}
