export default {
  "gridSpan--two": "Cell__gridSpan--two_h7ptwv",
  "gridSpan--three": "Cell__gridSpan--three_h7ptwv",
  "gridSpan--four": "Cell__gridSpan--four_h7ptwv",
  "gridSpan--six": "Cell__gridSpan--six_h7ptwv",
  "setHeight": "Cell__setHeight_h7ptwv",
  "setHeight--text": "Cell__setHeight--text_h7ptwv",
  "setHeight--xsmall": "Cell__setHeight--xsmall_h7ptwv",
  "setHeight--small": "Cell__setHeight--small_h7ptwv",
  "setHeight--large": "Cell__setHeight--large_h7ptwv",
  "setHeight--activities": "Cell__setHeight--activities_h7ptwv",
  "setHeight--nps": "Cell__setHeight--nps_h7ptwv"
};
