import Controller from '@ember/controller';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { action, setProperties } from '@ember/object';
import moment from 'moment-timezone';

export default class extends Controller {
  queryParams = ['startDate', 'endDate'];

  @alias('model.job') job;

  @tracked startDate = null;
  @tracked endDate = null;

  @action
  handleUpdateTimeRange(startDate, endDate) {
    setProperties(this, {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    });
  }
}
