import ScorecardPick from 'teamtailor/models/scorecard-pick';
import BaseRoute from 'teamtailor/routes/base';
import { get } from 'teamtailor/utils/get';
import ScorecardCriteriumModel from 'teamtailor/models/scorecard-criterium';
import JobsEditEvaluationInterviewKitController from 'teamtailor/controllers/jobs/edit/evaluation/interview-kit';
import recordRemover from 'teamtailor/utils/record-remover';
import InterviewKitModel from 'teamtailor/models/interview-kit';
import { AddonFeature } from 'types/features';

type QueryParams = {
  id: string | null;
};

export default class JobsEditEvaluationInterviewKitRoute extends BaseRoute {
  requiredFeature: AddonFeature = 'interview_kit';

  async model(params: QueryParams) {
    const { job, jobDetail, actions } = this.modelFor('jobs.edit.evaluation');
    const { id } = params;
    let interviewKit: InterviewKitModel;
    if (id) {
      interviewKit = await this.store.findRecord('interview-kit', id);
    } else {
      interviewKit = this.store.createRecord('interview-kit');
      get(jobDetail, 'scorecardPicks')
        .mapBy('scorecardCriterium')
        .forEach((scorecardCriterium: ScorecardCriteriumModel) => {
          interviewKit.addScorecardPick(scorecardCriterium);
        });
    }

    return {
      interviewKit,
      job,
      actions: {
        refetchInterviewKits: actions.refetchInterviewKits,
      },
    };
  }

  resetController(
    controller: JobsEditEvaluationInterviewKitController,
    isExiting: boolean
  ) {
    if (isExiting) {
      const { interviewKit } = get(controller, 'model');

      interviewKit.scorecardPicks.forEach((scorecardPick?: ScorecardPick) =>
        scorecardPick?.rollbackAttributes()
      );

      recordRemover(this.store, 'interview-kit', 'picked-question');
    }
  }
}
