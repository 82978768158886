import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { set, action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { isPresent } from '@ember/utils';
import IntercomService from 'teamtailor/services/intercom';

export default class ChatController extends Controller {
  @service declare intercom: IntercomService;

  get messageWidget() {
    return this.model.messageWidget;
  }

  get persistedUserId() {
    return this.model.persistedUserId;
  }

  set persistedUserId(userId) {
    set(this.model, 'persistedUserId', userId);
  }

  get hasUpdatedUserId() {
    return this.persistedUserId !== get(this.messageWidgetUser, 'id');
  }

  get messageWidgetUser() {
    return get(this.messageWidget, 'user');
  }

  get saveButtonEnabled() {
    if (!this.messageWidget.enabled) {
      return false;
    }

    return (
      get(this.messageWidget, 'hasDirtyAttributes') || this.hasUpdatedUserId
    );
  }

  @action
  async handleSave() {
    const isChangingState = isPresent(
      this.messageWidget.changedAttributes().enabled
    );

    await this.messageWidget.save();

    if (isChangingState) {
      this.intercom.trackEvent(
        this.messageWidget.enabled ? 'enabled-chat' : 'disabled-chat'
      );
    }

    this.persistedUserId = get(this.messageWidgetUser, 'id');
  }

  @action
  handleToggle() {
    this.messageWidget.enabled = !this.messageWidget.enabled;
    this.handleSave();
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.chat': ChatController;
  }
}
