import classic from 'ember-classic-decorator';
import Intercom from 'ember-intercom-io/services/intercom';
import { get } from 'teamtailor/utils/get';
import { next } from '@ember/runloop';
import config from 'teamtailor/config/environment';
const intercomConfig = config.intercom || {};

@classic
export default class IntercomService extends Intercom {
  config = intercomConfig;

  showNewMessage() {
    next(() => {
      get(this, 'api')('showNewMessage', arguments[0]);
    });
  }

  trackEvent() {
    next(() => {
      get(this, 'api')('trackEvent', arguments[0]);
    });
  }

  update() {
    next(() => {
      get(this, 'api')('update', arguments[0]);
    });
  }

  showWidget() {
    this.update({ hide_default_launcher: false });
  }

  hideWidget() {
    this.update({ hide_default_launcher: true });
  }

  getIntercomWidgetEl(
    wrapperEl = document.querySelector(
      // .intercom-namespace if has been opened and .intercom-lightweight-app when it hasnt
      '.intercom-lightweight-app,.intercom-namespace'
    )
  ) {
    let intercomWidgetEl;
    if (wrapperEl?.classList.contains('intercom-namespace')) {
      intercomWidgetEl = wrapperEl.querySelector(
        '.intercom-launcher-frame'
      )?.parentElement;
    } else {
      intercomWidgetEl = wrapperEl?.querySelector('.intercom-launcher');
    }

    return intercomWidgetEl;
  }
}
