import { helper } from '@ember/component/helper';
import { jsonToBase64 } from 'teamtailor/utils/base-64';

export function base64([input], namedArgs) {
  let json = input || namedArgs;

  return jsonToBase64(json);
}

export default helper(base64);
