import { get } from 'teamtailor/utils/get';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';

export default class EventsRoute extends ScrollToTopRoute {
  @service current;
  @service router;
  @service store;

  queryParams = {
    page: { refreshModel: true, type: 'number' },
  };

  beforeModel() {
    if (get(this.current.company, 'auditLog.id') === undefined) {
      this.router.transitionTo('settings.audit-log.index');
    }
  }

  model({ page }) {
    return this.store.query('audit-event', { page });
  }
}
