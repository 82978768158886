export default function round(number, roundToOneDecimal = false) {
  if (!number) {
    return 0;
  }

  if (number < 1 || roundToOneDecimal) {
    return Math.round(number * 10) / 10;
  } else {
    return Math.round(number);
  }
}
