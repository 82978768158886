import { attr } from '@ember-data/model';
import SectionModel, { ColorPresets } from 'teamtailor/models/section';

export default class ContentBlockPictureModel extends SectionModel {
  @attr('string') declare altText: string;
  @attr('string') declare url: string;
  @attr('string') declare customBackgroundColor: string;
  @attr('string') declare backgroundColor: string;
  @attr('string') declare colorPreset: ColorPresets;
  @attr('number') declare borderRadius: number;
  @attr('number', { allowNull: true }) declare customBorderRadius: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'content-block-picture': ContentBlockPictureModel;
  }
}
