import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import UserModel from './user';
import JobDetail from './job-detail';
import PageModel from './page';
import PickedVideoModel from './picked-video';

export default class MessageWidgetModel extends Model {
  @belongsTo('user')
  declare user: AsyncBelongsTo<UserModel | null>;

  @belongsTo('job-detail')
  declare jobDetail: AsyncBelongsTo<JobDetail>;

  @belongsTo('page')
  declare page: AsyncBelongsTo<PageModel>;

  @belongsTo('picked-video', { async: false })
  declare pickedVideo: PickedVideoModel | null;

  @attr('boolean')
  declare enabled: boolean;

  @attr('string')
  declare greeting: string;

  @attr('boolean')
  declare popupGreeting: string;

  @attr('number')
  declare pageId?: number;

  @attr('number')
  declare jobDetailId?: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'message-widget': MessageWidgetModel;
  }
}
