import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import IntlService from 'ember-intl/services/intl';
import FlashMessageService from 'teamtailor/services/flash-message';
import SettingsRequisitionArchiveReasonsRoute from 'teamtailor/routes/settings/requisition-archive-reasons';

export default class SettingsRequisitionArchiveReasonsController extends Controller {
  declare model: ModelFrom<SettingsRequisitionArchiveReasonsRoute>;

  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  get requisitionArchiveReasons() {
    return this.model;
  }

  @action
  addArchiveReason() {
    this.store.createRecord('requisition-archive-reason');
  }

  @action
  async saveArchiveReason() {
    try {
      await this.model.save();
    } catch {
      this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.requisition_archive_reasons': SettingsRequisitionArchiveReasonsController;
  }
}
