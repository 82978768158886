import moment, { Moment } from 'moment-timezone';

export const getDays = (start: Moment, end: Moment) => {
  const diff = Math.ceil(end.diff(start, 'day', true)) || 1;

  return Array.from({ length: diff }).map((_, index) =>
    moment(start).add(index, 'day')
  );
};

export const getMonths = (start: Moment, end: Moment) => {
  const diff =
    Math.ceil(end.endOf('month').diff(start.startOf('month'), 'month', true)) ||
    1;

  return Array.from({ length: diff }).map((_, index) =>
    moment(start).add(index, 'month')
  );
};

export const getYears = (start: Moment, end: Moment) => {
  const diff =
    Math.ceil(end.endOf('year').diff(start.startOf('year'), 'year', true)) || 1;

  const dates = Array.from({ length: diff }).map((_, index) =>
    moment(start).add(index, 'y')
  );

  return dates.map((date) => date.format('YYYY'));
};
