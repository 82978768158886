import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';

export default class IndexRoute extends ScrollToTopRoute {
  @service current;

  model() {
    return this.current.company;
  }
}
