import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CandidatesRoute extends Route {
  @service store;

  model() {
    return hash({
      segments: this.store.findAll('segment'),
    });
  }

  setupController(controller, { segments }) {
    super.setupController(...arguments);
    set(controller, 'segments', segments);
  }
}
