import Controller from '@ember/controller';
import { shuffle } from 'ember-composable-helpers/helpers/shuffle';
import { inject as service } from '@ember/service';
import WrapUpRoute from 'teamtailor/routes/wrap-up';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import ConfettiService from 'teamtailor/services/confetti';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import RouterService from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import Current from 'teamtailor/services/current';
import SessionService from 'ember-simple-auth/services/session';
import Server from 'teamtailor/services/server';
import IntlService from 'ember-intl/services/intl';
import Store from '@ember-data/store';
import FlashMessageService from 'teamtailor/services/flash-message';

export default class WrapUp extends Controller {
  declare model: ModelFrom<WrapUpRoute>;
  @service declare confetti: ConfettiService;
  @service declare router: RouterService;
  @service declare current: Current;
  @service declare session: SessionService;
  @service declare server: Server;
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;
  @service declare store: Store;

  @tracked fadeOut = false;

  get modalTarget(): Element | undefined {
    return document.getElementById('ember-teamtailor-modal') ?? undefined;
  }

  private shuffleCandidates = (): ModelFrom<WrapUpRoute>['hiredCandidates'] => {
    return shuffle(this.model.hiredCandidates);
  };

  get shuffledCandidates(): ModelFrom<WrapUpRoute>['hiredCandidates'] {
    const shuffled = this.shuffleCandidates();
    return [...shuffled, ...shuffled];
  }

  get shuffleCandidatesAlt(): ModelFrom<WrapUpRoute>['hiredCandidates'] {
    const shuffled = this.shuffleCandidates();
    return [...shuffled, ...shuffled];
  }

  get animationSpeed(): number {
    return this.shuffledCandidates.length * 4;
  }

  @action
  handleConfettiClick(): void {
    if (this.confetti.showConfetti) {
      return;
    }

    this.confetti.show();

    if (!this.current.company.isLocked) {
      this.fadeOut = true;
      setTimeout(() => {
        this.router.transitionTo('dashboard');
      }, 7000);
    }
  }

  @action logout(): void {
    this.session.invalidate();
  }

  @action exportData(): void {
    const adapter = this.store.adapterFor('application');
    const url = `${adapter.buildURL(
      'company',
      get(this.current.company, 'uuid')
    )}/export_data`;
    this.server
      .request(url, 'POST')
      .then(() => {
        this.flashMessages.success(this.intl.t('offboarding.success_message'));
      })
      .catch(() => {
        this.flashMessages.error(this.intl.t('offboarding.error_message'));
      });
  }
}

declare module '@ember/controller' {
  interface Registry {
    'wrap-up': WrapUp;
  }
}
