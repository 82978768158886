import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CandidateEditRoute extends ScrollToTopRoute {
  @service store;

  resetController(controller, isExiting) {
    if (!isExiting) {
      return;
    }

    const candidate = get(controller, 'candidate');

    if (candidate && !get(candidate, 'isDeleted')) {
      set(candidate, 'removePicture', false);
    }
  }
}
