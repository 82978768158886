import Helper from '@ember/component/helper';
import {
  STAGE_TYPE_ICONS,
  DEFAULT_STAGE_ICON,
} from 'teamtailor/constants/stage-type-icons';

const VALID_STAGE_TYPES = Object.keys(STAGE_TYPE_ICONS);

export function getStageTypeIcon(category) {
  if (VALID_STAGE_TYPES.includes(category)) {
    return STAGE_TYPE_ICONS[category];
  } else {
    return DEFAULT_STAGE_ICON;
  }
}

export default Helper.helper(function (params) {
  return getStageTypeIcon(...params);
});
