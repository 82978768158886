import Service from '@ember/service';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';
import config from 'teamtailor/config/environment';
import { dropTask } from 'ember-concurrency';

export type PuffItem = {
  text: string;
  link?: string;
};

export default class Puff extends Service.extend({}) {
  @tracked items: PuffItem[] = [];

  declare lastFetchAt?: moment.Moment;

  get isFetching() {
    return this.fetchItems.isRunning;
  }

  get tips(): PuffItem[] {
    return this.items.filterBy('fields.type', 'tip');
  }

  get randomTip() {
    return this.tips[Math.floor(Math.random() * this.tips.length)];
  }

  get updates(): PuffItem[] {
    return this.items.filterBy('fields.type', 'update');
  }

  get latestUpdate() {
    return this.updates[0];
  }

  get shouldRefresh() {
    return (
      this.lastFetchAt === undefined ||
      this.lastFetchAt.isBefore(moment().subtract(1, 'hour'))
    );
  }

  get hasCredentials() {
    const { space, accessToken } = config.contentful;
    return space && accessToken;
  }

  fetchItems = dropTask(async () => {
    const { space, accessToken } = config.contentful;

    if (!this.shouldRefresh || !this.hasCredentials) return;

    const items: PuffItem[] | undefined = await fetch(
      `https://cdn.contentful.com/spaces/${space}/entries/?content_type=puff`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response) => response.json() as Promise<{ items: PuffItem[] }>)
      .then((json) => json.items);

    this.items = items ?? [];
    this.lastFetchAt = moment();
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    puff: Puff;
  }
}
