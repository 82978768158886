import {
  ALLOWED_CURRENCIES,
  DEFAULT_CURRENCY,
} from 'teamtailor/utils/currency-for-country';
import { get, set, action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

export default class Purchase {
  constructor({ channel, promotion, currency, selectedBudget }) {
    this.channel = channel;
    this.promotion = promotion;
    this.currency = currency;
    this.selectedBudget = selectedBudget;
  }

  @tracked channel = this.channel;
  @tracked promotion = this.promotion;
  @tracked currency = this.currency;
  @tracked selectedBudget = this.selectedBudget;
  @tracked customPrice;

  get currencyWithFallback() {
    if (ALLOWED_CURRENCIES.includes(this.currency)) {
      return this.currency;
    }

    return DEFAULT_CURRENCY;
  }

  get availableBudgets() {
    if (!isEmpty(get(this.channel, 'plans'))) {
      return get(this.channel, 'plans')[this.currencyWithFallback];
    }

    return undefined;
  }

  get pickedBudget() {
    return (
      this.selectedBudget ||
      this.availableBudgets?.[1] ||
      this.availableBudgets?.[0]
    );
  }

  setPriceAndCurrency() {
    set(this, 'promotion.currency', this.currencyWithFallback);

    if (this.pickedBudget === 'custom') {
      set(this, 'promotion.price', this.customPrice);
      set(this, 'promotion.plan', 'custom');
    } else if (this.pickedBudget) {
      set(this, 'promotion.price', get(this.pickedBudget, 'amount'));
      set(this, 'promotion.plan', get(this.pickedBudget, 'name'));
    }
  }

  @action
  pick() {
    this.setPriceAndCurrency();
    return this.promotion.save();
  }

  @action
  handleBudgetChange(budget) {
    set(this, 'selectedBudget', budget);
  }
}
