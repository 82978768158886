import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import moment from 'moment-timezone';
import { exitReasons } from 'teamtailor/constants/nurture-campaign';
import { get } from 'teamtailor/utils/get';

export default class ActivityModel extends Model {
  @service intl;

  @hasMany('user') approvers;
  @hasMany('user') privateNoteUsers;
  @hasMany('user', { async: false }) messageUsers;
  @hasMany('reaction', { async: false }) reactions;
  @belongsTo('candidate') candidate;
  @belongsTo('user') user;
  @belongsTo('job') job;
  @belongsTo('department') department;
  @belongsTo('role') role;
  @belongsTo('job-application') jobApplication;
  @belongsTo('job-offer') jobOffer;
  @belongsTo('message', { async: false }) message;
  @belongsTo('nurture-campaign') nurtureCampaign;
  @belongsTo('nps-response') npsResponse;
  @belongsTo('upload') upload;
  @belongsTo('questionnaire', { async: false }) questionnaire;
  @belongsTo('partner', { async: false }) partner;
  @belongsTo('partner-result', { async: false }) partnerResult;
  @belongsTo('nurture-campaign-step', { async: false }) nurtureCampaignStep;
  @belongsTo('reject-reason') rejectReason;
  @belongsTo('requisition') requisition;
  @belongsTo('feature') feature;
  @belongsTo('user') newRecruiter;
  @belongsTo('reference') reference;
  @belongsTo('trigger', { async: true, polymorphic: true }) trigger;
  @belongsTo('hire-quality-response') hireQualityResponse;

  @attr('raw') actionData;
  @attr('boolean') automaticEmail;
  @attr('string') code;
  @attr('string') commentAuthor;
  @attr('date') createdAt;
  @attr('boolean') createdByTrigger;
  @attr('date') date;
  @attr('number') jobId;
  @attr('raw') mentionedUserIds;
  @attr('array') mentionedUsernames;
  @attr('string') nurtureCampaignExitReason;
  @attr('string') nurtureCampaignName;
  @attr('boolean', { defaultValue: false }) pinned;
  @attr('boolean') privateNote;
  @attr('date') updatedAt;
  @attr('string') interviewKitName;
  @attr('string') todoAssigner;
  @attr('string') todoValue;

  get todoAssignedBy() {
    const id = this.actionData.assigner_id;
    if (id) {
      return this.store.findRecord('user', id);
    }

    return null;
  }

  get note() {
    return this.actionData.note;
  }

  get approvalStatus() {
    return this.actionData.status;
  }

  get approvalRequested() {
    return this.approvalStatus === 'requested';
  }

  get notAutomaticEmail() {
    return !this.automaticEmail;
  }

  get survey() {
    return this.actionData.survey;
  }

  get sendByBulk() {
    return this.actionData.by_bulk;
  }

  get fromJobSubscription() {
    return this.actionData.from_job_subscription;
  }

  get endDate() {
    return moment(this.date).add(1, 'hour').toDate();
  }

  get fromStage() {
    if (this.actionData.from && !isEmpty(get(this.job, 'stages'))) {
      return get(this.job, 'stages').findBy(
        'id',
        this.actionData.from.toString()
      );
    }
  }

  get toStage() {
    if (this.actionData.to && !isEmpty(get(this.job, 'stages'))) {
      return get(this.job, 'stages').findBy(
        'id',
        this.actionData.to.toString()
      );
    }
  }

  get stage() {
    if (this.actionData.stage_id && !isEmpty(get(this.job, 'stages'))) {
      return get(this.job, 'stages').findBy(
        'id',
        this.actionData.stage_id.toString()
      );
    }
  }

  get newApproverName() {
    return this.actionData.new_approver_name;
  }

  get oldApproverName() {
    return this.actionData.old_approver_name;
  }

  get hasDate() {
    return !isEmpty(this.date);
  }

  get isApproval() {
    return this.code === 'approval';
  }

  get isAssessment() {
    return this.code === 'assessment';
  }

  get isConnected() {
    return this.code === 'connected';
  }

  get isConsentMissing() {
    return this.code === 'consent_missing';
  }

  get isConsentExtended() {
    return this.code === 'consent_extended';
  }

  get isConsentExtendedByCandidate() {
    return this.isConsentExtended && this.actionData.by_candidate;
  }

  get isConsentFutureJobsExtended() {
    return this.code === 'consent_extended_future_jobs';
  }

  get isConsentExtendRequest() {
    return this.code === 'consent_extend_request';
  }

  get isConsentRequested() {
    return this.code === 'consent_requested';
  }

  get isConsentGiven() {
    return this.code === 'consent_given';
  }

  get isCreated() {
    return this.code === 'created';
  }

  get isEmail() {
    return this.code === 'email';
  }

  get isSms() {
    return this.code === 'sms';
  }

  get isSmsOptOut() {
    return this.code === 'sms_opt_out';
  }

  get isCandidateMerged() {
    return this.code === 'candidate_merged';
  }

  get isTodoAssigned() {
    return this.code === 'todo_assigned';
  }

  get isLead() {
    return this.code === 'lead';
  }

  get isMessage() {
    return this.code === 'message';
  }

  get isMoved() {
    return this.code === 'moved';
  }

  get isNote() {
    return this.code === 'note';
  }

  get isNpsResponse() {
    return this.code === 'nps_response';
  }

  get isQuestionnaire() {
    return this.code === 'questionnaire';
  }

  get isReminderSent() {
    return this.code === 'reminder_sent';
  }

  get isReferred() {
    return this.code === 'referred';
  }

  get isRejected() {
    return this.code === 'rejected';
  }

  get isRemovalRequest() {
    return this.code === 'removal_request';
  }

  get isDataRequest() {
    return this.code === 'data_request';
  }

  get isDataRequestDismissed() {
    return this.code === 'data_request_dismissed';
  }

  get isDataRequestHandled() {
    return this.code === 'data_request_handled';
  }

  get isDataRequestHandledBySystem() {
    return !get(this.user, 'id') && this.code === 'data_request_handled';
  }

  get isReview() {
    return this.code === 'review';
  }

  get isScorecard() {
    return this.code === 'scorecard';
  }

  get isShare() {
    return this.code === 'share';
  }

  get isSourced() {
    return this.code === 'sourced';
  }

  get isSourcedRemoved() {
    return this.code === 'sourced_removed';
  }

  get isInternal() {
    return this.code === 'internal';
  }

  get isInternalRemoved() {
    return this.code === 'internal_removed';
  }

  get isStage() {
    return this.code === 'stage';
  }

  get isStatus() {
    return this.code === 'status';
  }

  get isTags() {
    return this.code === 'tags';
  }

  get isUnsubscribe() {
    return this.code === 'unsubscribed';
  }

  get isUpdatedProfile() {
    return this.code === 'updated_profile';
  }

  get isUpload() {
    return this.code === 'upload';
  }

  get isSurvey() {
    return this.code === 'survey_sent';
  }

  get isWebhook() {
    return this.code === 'webhook_sent';
  }

  get isSmartMoved() {
    return this.code === 'smart_moved';
  }

  get isPredictionMoved() {
    return this.code === 'prediction_moved';
  }

  get isPartnerResult() {
    return this.code === 'partner_result';
  }

  get isConnectedOrMoved() {
    return this.isConnected || this.isMoved;
  }

  get isFeatureEnabled() {
    return this.code === 'feature_enabled';
  }

  get isFeatureDisabled() {
    return this.code === 'feature_disabled';
  }

  get isNurtureCampaignAdded() {
    return this.code === 'nurture_campaign_added';
  }

  get nurtureCampaignExited() {
    return this.code === 'nurture_campaign_exited';
  }

  get isTriggerCancelled() {
    return this.code === 'trigger_cancelled';
  }

  get isRestricted() {
    return this.code === 'restricted';
  }

  get isUnrestricted() {
    return this.code === 'unrestricted';
  }

  get isResumeUploaded() {
    return this.code === 'resume_uploaded';
  }

  get isNurtureCampaignExited() {
    return this.nurtureCampaignExited && this.notNurtureCampaignUnsubscribed;
  }

  get isNurtureCampaignUnsubscribed() {
    return this.nurtureCampaignExitReason === 'unsubscribed';
  }

  get notNurtureCampaignUnsubscribed() {
    return !this.isNurtureCampaignUnsubscribed;
  }

  get isNurtureCampaignRecipientPaused() {
    return this.code === 'nurture_campaign_recipient_paused';
  }

  get isNurtureCampaignRecipientActivated() {
    return this.code === 'nurture_campaign_recipient_activated';
  }

  get isNurtureCampaign() {
    return this.isNurtureCampaignAdded || this.isNurtureCampaignExited;
  }

  get isJobPublished() {
    return this.code === 'job_open';
  }

  get isJobUnlisted() {
    return this.code === 'job_unlisted';
  }

  get isJobArchived() {
    return this.code === 'job_archived';
  }

  get isJobStatusChange() {
    return this.isJobPublished || this.isJobUnlisted || this.isJobArchived;
  }

  get isJobInternal() {
    return this.code === 'job_internal';
  }

  get isJobPublic() {
    return this.code === 'job_public';
  }

  get isJobAudienceChanged() {
    return this.isJobInternal || this.isJobPublic;
  }

  get isReferenceGiven() {
    return this.code === 'reference_given';
  }

  get isRequisitionRejected() {
    return this.code === 'requisition_rejected';
  }

  get isRequisitionApproved() {
    return this.code === 'requisition_approved';
  }

  get isRequisitionVerdictApproved() {
    return this.code === 'requisition_verdict_approved';
  }

  get isRequisitionVerdictChanged() {
    return this.code === 'requisition_verdict_user_changed';
  }

  get isRequisitionVerdictRequested() {
    return this.code === 'requisition_verdict_requested';
  }

  get isRequisitionVerdictReminder() {
    return this.code === 'requisition_verdict_reminder';
  }

  get isRequisitionCompleted() {
    return this.code === 'requisition_completed';
  }

  get isRequisitionArchived() {
    return this.code === 'requisition_archived';
  }

  get isRequisitionUnarchived() {
    return this.code === 'requisition_unarchived';
  }

  get isRequisitionCreated() {
    return this.code === 'requisition_created';
  }

  get isRequisitionUpdated() {
    return this.code === 'requisition_updated';
  }

  get isLinkedinRscError() {
    return this.code === 'linkedin_rsc_error';
  }

  get isLinkedinAction() {
    return this.code === 'linkedin';
  }

  get isLinkedin() {
    return this.isLinkedinRscError || this.isLinkedinAction;
  }

  get isJobOfferAccepted() {
    return this.code === 'job_offer_accepted';
  }

  get isJobOfferDeclined() {
    return this.code === 'job_offer_declined';
  }

  get isJobOfferSent() {
    return this.code === 'job_offer_sent';
  }

  get isJobOfferOpened() {
    return this.code === 'job_offer_opened';
  }

  get isJobOfferFlowRejected() {
    return this.code === 'job_offer_flow_rejected';
  }

  get isJobOfferFlowApproved() {
    return this.code === 'job_offer_flow_approved';
  }

  get isJobOfferFlowVerdictApproved() {
    return this.code === 'job_offer_flow_verdict_approved';
  }

  get isJobOfferFlowVerdictRejected() {
    return this.code === 'job_offer_flow_verdict_rejected';
  }

  get isJobOfferFlowVerdictRequested() {
    return this.code === 'job_offer_flow_verdict_requested';
  }

  get isJobOfferFlowVerdictReminder() {
    return this.code === 'job_offer_flow_verdict_reminder';
  }

  get isCopilotMeetingSummary() {
    return this.code === 'copilot_meeting_summary';
  }

  get isCopilotResumeSummary() {
    return this.code === 'copilot_resume_summary';
  }

  get isCopilotInterviewKitQuestionsFeedback() {
    return this.code === 'copilot_interview_kit_questions_feedback';
  }

  get isInterviewAdded() {
    return this.code === 'interview_added';
  }

  get isMeetingEventCandidateInvited() {
    return this.code === 'meeting_event_candidate_invited';
  }

  get isMeetingEventCancelled() {
    return this.code === 'meeting_event_cancelled';
  }

  get isMeetingEventSentSelfSchedule() {
    return this.code === 'meeting_event_sent_self_schedule';
  }

  get isMeetingEventSelfScheduleNoTime() {
    return this.code === 'meeting_event_self_schedule_no_time';
  }

  get isMeetingEventCandidateStatusChanged() {
    return this.code === 'meeting_event_candidate_status_changed';
  }

  get isMeetingEventSelfSchedulePickedTime() {
    return this.code === 'meeting_event_self_schedule_picked_time';
  }

  get isHireQualityResponse() {
    return this.code === 'hire_quality_response';
  }

  get isCopilot() {
    return (
      this.isCopilotMeetingSummary ||
      this.isCopilotResumeSummary ||
      this.isCopilotInterviewKitQuestionsFeedback
    );
  }

  get activityUser() {
    if (this.automaticEmail) {
      return null;
    }

    if (this.message) {
      return get(this.message, 'user');
    }

    if (this.todoAssignedBy) {
      return this.todoAssignedBy;
    }

    if (this.user) {
      return this.user;
    }
  }

  get triggerOrUserName() {
    return this.createdByTrigger
      ? this.intl.t('components.activity_item.trigger_title')
      : this.userName;
  }

  get userName() {
    const userId = this.message
      ? this.message.belongsTo('user').id()
      : this.belongsTo('user').id();

    if (userId) {
      const user = this.message ? get(this.message, 'user') : this.user;
      return get(user, 'nameOrEmail');
    } else if (this.commentAuthor) {
      return this.commentAuthor;
    } else {
      return this.intl.t('common.deleted_user');
    }
  }

  get jobStatusChangeText() {
    const action = this.intl
      .t(`models.activity.actions_past_tense.${this.code.replace('job_', '')}`)
      .toLowerCase();
    return get(this, 'user.id')
      ? this.intl.t('models.activity.action_on_job', {
          actionNamePastTense: action,
        })
      : this.intl.t('models.activity.action_via_api', {
          actionNamePastTense: action,
        });
  }

  get nurtureCampaignExitReasonString() {
    return exitReasons[get(this, 'nurtureCampaignExitReason')];
  }

  get tagsRemoved() {
    if (this.isTags) {
      return (
        (this.actionData.to.length === undefined
          ? 0
          : this.actionData.to.length) <
        (this.actionData.from.length === undefined
          ? 0
          : this.actionData.from.length)
      );
    }

    return undefined;
  }

  get linkedinMessage() {
    if (this.isLinkedin) {
      return this.actionData.message === undefined
        ? ''
        : this.actionData.message;
    }
  }

  get linkedinSubject() {
    if (this.isLinkedin) {
      return this.actionData.subject === undefined
        ? ''
        : this.actionData.subject;
    }
  }

  get tagsChange() {
    if (this.isTags) {
      const normalize = (tagOrName) => {
        return typeof tagOrName === 'string'
          ? { name: tagOrName, color: null }
          : tagOrName;
      };

      let { from, to } = this.actionData;
      from = from ? this.actionData.from.map(normalize) : [];
      to = to ? this.actionData.to.map(normalize) : [];

      const fromNames = from.map((tag) => tag.name);
      const toNames = to.map((tag) => tag.name);

      return this.tagsRemoved
        ? from.filter((x) => !toNames.includes(x.name))
        : to.filter((x) => !fromNames.includes(x.name));
    }
  }

  get actionDataKeys() {
    return Object.keys(this.actionData);
  }

  get createdWhen() {
    const updatedAtWithGuessedTimezone = moment(this.createdAt)
      .tz(moment.tz.guess())
      .startOf('day');
    const diff = moment()
      .startOf('day')
      .diff(updatedAtWithGuessedTimezone, 'days');

    if (diff === 0) {
      return 'today';
    } else if (diff === 1) {
      return 'yesterday';
    } else if (diff > 1 && diff <= 7) {
      return 'last-week';
    } else if (diff > 7 && diff <= 30) {
      return 'last-month';
    } else if (diff > 30 && diff < 366) {
      return 'last-year';
    } else {
      return 'previous-years';
    }
  }
}
