import BlockLayout from 'teamtailor/models/block-layout';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { set, action } from '@ember/object';
import CompanyPageService from 'teamtailor/services/company-page';
import TtAlertService from 'teamtailor/services/tt-alert';
import IntlService from 'ember-intl/services/intl';
import ContentEditorController from 'teamtailor/controllers/content/editor';
import { DepartmentModel, LocationModel, PageModel } from 'teamtailor/models';
import Transition from '@ember/routing/-private/transition';
import { hash } from 'rsvp';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';
import Current from 'teamtailor/services/current';
import IntercomService from 'teamtailor/services/intercom';

type EditorRouteModel = {
  pageRecord?: PageModel | DepartmentModel | LocationModel;
};

export default class EditorRoute extends Route<EditorRouteModel> {
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;
  @service declare companyPage: CompanyPageService;
  @service declare store: Store;
  @service declare current: Current;
  @service declare intercom: IntercomService;

  beforeModel() {
    this.companyPage.hideHeader = true;
  }

  async model(params: {
    type: 'pages' | 'departments' | 'locations';
    type_id: string;
  }) {
    const { type, type_id: typeId } = params;

    const promises = {} as {
      pageRecord?: PageModel | Promise<DepartmentModel | LocationModel>;
      allBlockLayouts: DS.PromiseArray<BlockLayout>;
    };

    if (type === 'pages') {
      const page = await this.store.findRecord('page', typeId);
      await page.belongsTo('pagePublication').reload();
      promises.pageRecord = page;
    } else if (type === 'departments') {
      promises.pageRecord = this.store.findRecord('department', typeId);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (type === 'locations') {
      promises.pageRecord = this.store.findRecord('location', typeId);
    }

    promises.allBlockLayouts = this.store.findAll('block-layout');

    return hash(promises);
  }

  async setupController(
    controller: ContentEditorController,
    model: EditorRouteModel,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);

    this.companyPage.hideHeader = true;

    // @ts-expect-error I think this can be removed but not sure
    set(
      controller,
      'modelType',
      (await model.pageRecord)?.constructor.modelName
    );

    if ((<PageModel>model.pageRecord).isConnectPage) {
      this.intercom.hideWidget();
      const connectSettings = await this.store.query('connect-setting', {
        page_id: model.pageRecord?.id,
      });
      if (connectSettings.length) {
        controller.connectSettings = connectSettings[0];
      } else {
        const page = <PageModel>model.pageRecord;
        const careerSite = await page.careerSite;
        controller.connectSettings = this.store.createRecord(
          'connect-setting',
          {
            page,
            company: this.current.company,
            welcomeMessage: careerSite?.connectWelcomeMessage,
            pickedQuestions: this.current.company.pickedQuestions,
          }
        );

        controller.connectSettings.save();
      }

      controller.generalConnectSettings = controller.connectSettings;
    }
  }

  resetController(controller: ContentEditorController, isExiting: boolean) {
    this.companyPage.hideHeader = false;
    controller.selectedDepartment = undefined;
    controller.selectedRole = undefined;
    controller.selectedConnectBlock = undefined;
    controller.cleanupConnectMediaItems();

    if (isExiting) {
      controller.overrideIframeSrc = undefined;
    }
  }

  @action
  error(error: { errors?: { status: string }[] }) {
    const { errors } = error;

    if (errors?.findBy('status', '401')) {
      this.ttAlert.error(this.intl.t('errors.dont_have_access_to_page'));
    }
  }
}
