export default {
  "overlay": "Container__overlay_1n1x0i",
  "overlay--withVideoMeetingSidebar": "Container__overlay--withVideoMeetingSidebar_1n1x0i",
  "backdrop": "Container__backdrop_1n1x0i",
  "sidebar": "Container__sidebar_1n1x0i",
  "show": "Container__show_1n1x0i",
  "slide-sidebar-in": "Container__slide-sidebar-in_1n1x0i",
  "hide": "Container__hide_1n1x0i",
  "slide-sidebar-out": "Container__slide-sidebar-out_1n1x0i",
  "border": "Container__border_1n1x0i",
  "shadow": "Container__shadow_1n1x0i",
  "contentContainer": "Container__contentContainer_1n1x0i",
  "slide-content-container-in": "Container__slide-content-container-in_1n1x0i",
  "slide-content-container-out": "Container__slide-content-container-out_1n1x0i",
  "content": "Container__content_1n1x0i",
  "content-fade-in": "Container__content-fade-in_1n1x0i",
  "content-fade-out": "Container__content-fade-out_1n1x0i",
  "close": "Container__close_1n1x0i",
  "closeButton": "Container__closeButton_1n1x0i",
  "fade-in": "Container__fade-in_1n1x0i",
  "fade-out": "Container__fade-out_1n1x0i",
  "slide-in": "Container__slide-in_1n1x0i",
  "slide-out": "Container__slide-out_1n1x0i"
};
