import { helper } from '@ember/component/helper';
import moment from 'moment-timezone';

export function formatDatetime([date], { languageCode, todayAtTranslation }) {
  if (languageCode) {
    moment.locale(languageCode);
  }

  if (!moment(date).isValid()) {
    return '';
  } else {
    const date_moment = moment(date);
    if (isToday(date_moment)) {
      const todayAt = todayAtTranslation || 'Today at';
      return date_moment.format(`[${todayAt}] H:mm`);
    } else if (isThisYear(date_moment)) {
      return date_moment.format('MMM D [at] H:mm');
    } else {
      return date_moment.format('MMM D, YYYY [at] H:mm');
    }
  }
}

export default helper(formatDatetime);

function isToday(date_moment) {
  return date_moment.isSame(moment(), 'day');
}

function isThisYear(date_moment) {
  return date_moment.isSame(moment(), 'year');
}
