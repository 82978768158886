import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AnimatedContainer @motion={{this.motion}}>\n  {{#animated-if @hasFocus}}\n    {{yield}}\n  {{/animated-if}}\n</AnimatedContainer>", {"contents":"<AnimatedContainer @motion={{this.motion}}>\n  {{#animated-if @hasFocus}}\n    {{yield}}\n  {{/animated-if}}\n</AnimatedContainer>","moduleName":"teamtailor/components/content-block/section-item-content.hbs","parseOptions":{"srcName":"teamtailor/components/content-block/section-item-content.hbs"}});
import Component from '@glimmer/component';

import resize from 'teamtailor/utils/motions/resize';

export default class SectionItemComponent extends Component {
  constructor() {
    super(...arguments);

    this.motion = resize(() => {
      if (this.args.onResizeComplete) {
        this.args.onResizeComplete();
      }
    });
  }
}
