import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import DateRange from 'teamtailor/utils/date-range';

export type SortDirection = 'asc' | 'desc';

export type ColumnDefinition = {
  type: string;
  propertyName: string;
  headerKey: string;
  digits?: number;
};

export type ModalOptions = {
  titleKey: string;
  filePrefix: string;
  columns: ColumnDefinition[];
  retrieveExportData: (dateRange: DateRange) => Promise<any>;
  createdAt?: Date | null;
  size?: string;
  lastUpdated?: Date;
};

export default class InsightsExportService extends Service {
  @tracked modalOpen = false;
  @tracked modalOptions: ModalOptions | null = null;
  @tracked sortDirection: SortDirection | null = null;
  @tracked sortProperty: string | null = null;
  @tracked dateRange = new DateRange();

  showModal(
    titleKey: string,
    filePrefix: string,
    columns: ColumnDefinition[],
    retrieveExportData: (dateRange: DateRange) => Promise<any>,
    dateRange: DateRange,
    options: {
      createdAt?: Date | null;
      size?: string;
      sortDirection?: SortDirection;
      sortKey?: string;
    } = {}
  ) {
    this.modalOptions = {
      titleKey,
      filePrefix,
      columns,
      retrieveExportData,
      createdAt: options.createdAt,
      size: options.size,
    };
    this.dateRange = dateRange;
    this.modalOpen = true;
    this.fetchData.perform();
    this.sortDirection = options.sortDirection || 'desc';
    this.sortProperty =
      options.sortKey ||
      (columns.length > 0 && columns[0]?.propertyName) ||
      null;
  }

  closeModal() {
    if (this.modalOpen) {
      this.modalOptions = null;
      this.modalOpen = false;
      this.sortDirection = null;
      this.sortProperty = null;
    }
  }

  handleUpdateDateRange(dateRange: DateRange) {
    this.dateRange = dateRange;
    if (this.modalOptions) {
      this.modalOptions.lastUpdated = new Date();
    }

    this.fetchData.perform();
  }

  fetchData = dropTask(async () => {
    return this.modalOptions?.retrieveExportData(this.dateRange);
  });
}

declare module '@ember/service' {
  interface Registry {
    'insights-export': InsightsExportService;
  }
}
