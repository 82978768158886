import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showLocationPicker}}\n  <FormField>\n    <FormLabel @label={{t \"search.location.select_location\"}}>\n      <Core::Select\n        @optionName=\"nameOrCity\"\n        @options={{this.locations}}\n        @onSelect={{queue\n          (fn (mut @promotion.location))\n          (optional @onChangeCallback)\n        }}\n        @selected={{@promotion.location}}\n        @placeholder={{t \"common.location\"}}\n        @text={{unless @promotion.location (t \"common.location\")}}\n        @isFullWidth={{true}}\n        @size=\"large\"\n      />\n    </FormLabel>\n  </FormField>\n{{/if}}", {"contents":"{{#if this.showLocationPicker}}\n  <FormField>\n    <FormLabel @label={{t \"search.location.select_location\"}}>\n      <Core::Select\n        @optionName=\"nameOrCity\"\n        @options={{this.locations}}\n        @onSelect={{queue\n          (fn (mut @promotion.location))\n          (optional @onChangeCallback)\n        }}\n        @selected={{@promotion.location}}\n        @placeholder={{t \"common.location\"}}\n        @text={{unless @promotion.location (t \"common.location\")}}\n        @isFullWidth={{true}}\n        @size=\"large\"\n      />\n    </FormLabel>\n  </FormField>\n{{/if}}","moduleName":"teamtailor/components/promotion/select-location.hbs","parseOptions":{"srcName":"teamtailor/components/promotion/select-location.hbs"}});
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @tracked promotion = this.args.promotion;

  get locations() {
    if (get(this, 'args.locations') && get(this, 'args.locations.length') > 0) {
      return get(this, 'args.locations');
    } else {
      return get(this, 'promotion.job.locations');
    }
  }

  get showLocationPicker() {
    if (get(this, 'args.isLocationPickerAlwaysVisible')) {
      return true;
    }

    return get(this, 'promotion.job.locations.length') > 1;
  }
}
