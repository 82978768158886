import Modifier from 'ember-modifier';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { registerDestructor } from '@ember/destroyable';
import ResizeObserverService from 'ember-resize-observer-service';

function cleanup(instance: GetRectModifier) {
  const { elem, resizeObserver, handleResize } = instance;

  resizeObserver.unobserve(elem, handleResize);
}

type OnResize = ((rect: DOMRectReadOnly) => void) | undefined;

type NamedArgs = { enabled?: boolean; rect?: 'contentRect' };

interface ModifierSignature {
  Element: Element;
  Args: {
    Positional: [OnResize];
    Named: NamedArgs;
  };
}

export default class GetRectModifier extends Modifier<ModifierSignature> {
  @service declare resizeObserver: ResizeObserverService;

  declare elem: Element;
  declare onResize: OnResize;
  declare rectToUse?: 'contentRect';

  modify(
    elem: Element,
    [onResize]: [OnResize],
    { enabled = true, rect }: NamedArgs = {}
  ) {
    this.onResize = onResize;
    this.rectToUse = rect;
    this.elem = elem;
    if (enabled && onResize) {
      this.resizeObserver.observe(elem, this.handleResize);
    }

    registerDestructor(this, cleanup);
  }

  @action
  handleResize(resizeEntry: ResizeObserverEntry) {
    const rect =
      this.rectToUse === 'contentRect'
        ? resizeEntry.contentRect
        : resizeEntry.target.getBoundingClientRect();

    this.onResize?.(rect.toJSON());
  }
}
