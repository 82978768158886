import { tracked } from '@glimmer/tracking';
import {
  ICandidate,
  IAttendeeCandidate,
} from 'teamtailor/components/meeting/types';
import { AttendeeClass, IAttendeeRecord } from './attendee';
import { AttendeeType } from './attendee-list';

export interface IAttendeeCandidateRecord extends IAttendeeRecord {
  candidateId?: string;
}

export class CandidateAttendeeClass extends AttendeeClass {
  @tracked declare candidate?: ICandidate;

  constructor(attendee: IAttendeeCandidate) {
    super(attendee);
    this.candidate = attendee.candidate;
  }

  static from(candidate: ICandidate): CandidateAttendeeClass {
    const attendee = { candidate } as IAttendeeCandidate;
    return new CandidateAttendeeClass(attendee);
  }

  get attendeeType(): string {
    return 'candidate';
  }

  isAttendee(attendee: AttendeeType): boolean {
    const candidateAttendee = attendee as CandidateAttendeeClass | undefined;
    return candidateAttendee?.candidate?.id === this.candidate?.id;
  }

  get isMissingEmail(): boolean {
    return !this.candidate?.email;
  }

  anonymousOnJob(jobId?: string): boolean {
    return !!jobId && !!this.candidate?.anonymousInJobIds.includes(jobId);
  }

  toAttributes(): IAttendeeCandidateRecord {
    return {
      candidateId: this.candidate?.id,
      id: this.id,
    };
  }
}
