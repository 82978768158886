import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';
import { underscore } from '@ember/string';

const CAMELCASE_QR_CODE_SETTINGS_ATTRIBUTES = [
  'accentColor',
  'isRound',
  'useLogo',
];

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    applicationsCount: { serialize: false },
    url: { serialize: false },
    finnOccupationGenerals: { serialize: 'ids' },
    finnOccupationSpecializations: { serialize: 'ids' },
    finnIndustries: { serialize: 'ids' },
    uptrailCategories: { serialize: 'ids' },
    uptrailRegion: { serialize: 'id' },
    cvOnlineWorkTimes: { serialize: 'ids' },
    cvOnlineSearchCategories: { serialize: 'ids' },
    successfullyPublished: { serialize: false },
    lastResponseCode: { serialize: false },
    molGeneralProfession: { serialize: false },
    molAdditionalLocations: { serialize: 'ids' },
    indeedActive: { serialize: false },
    indeedTimeToEnd: { serialize: false },
    jobborseCountry: { serialize: false },
    reedParentSector: { serialize: false },
    totaljobsMinmaxSalaries: { serialize: false },
    publishedAt: { serialize: false },
    endDate: { serialize: false },
    isExpired: { serialize: false },
    invoiceInfo: { serialize: 'records', deserialize: false },
    companyName: { serialize: false },
    corporateNumber: { serialize: false },
    vatNumber: { serialize: false },
    invoicingAddress: { serialize: false },
    invoicingEmail: { serialize: false },
    costcenter: { serialize: false },
    otherInformation: { serialize: false },
    qrCodePosterUrl: { serialize: false },
    oikotieLineOfBusiness: { serialize: 'ids' },
    pickedLocations: {
      serialize: 'records',
      deserialize: false,
      key: 'picked_locations_attributes',
    },
  },

  serialize() {
    const json = this._super(...arguments);
    this.switchQrCodeSettingsCase(json, true);
    return json;
  },

  normalize(typeClass, hash) {
    this.switchQrCodeSettingsCase(hash);
    return this._super(...arguments);
  },

  switchQrCodeSettingsCase(hash, toUnderscore = false) {
    const qrCodeSettings = hash.qr_code_settings;
    if (!qrCodeSettings) {
      return;
    }

    CAMELCASE_QR_CODE_SETTINGS_ATTRIBUTES.forEach((attr) => {
      const { toAttr, fromAttr } = this.getQrCodeAttrs(attr, toUnderscore);
      qrCodeSettings[toAttr] = qrCodeSettings[fromAttr];
      delete qrCodeSettings[fromAttr];
    });
  },

  getQrCodeAttrs(attr, toUnderscore) {
    return {
      toAttr: toUnderscore ? underscore(attr) : attr,
      fromAttr: toUnderscore ? attr : underscore(attr),
    };
  },
});
