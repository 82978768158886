import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { BlocketRegionModel } from 'teamtailor/models';

export default class BlocketMunicipalityModel extends Model {
  @belongsTo('blocket-region')
  declare blocketRegion: AsyncBelongsTo<BlocketRegionModel>;

  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'blocket-municipality': BlocketMunicipalityModel;
  }
}
