import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class IndexRoute extends Route {
  @service current;

  model() {
    return get(this.current.company, 'blog');
  }
}
