import BaseJobOfferRoute from './base-route';
import { inject as service } from '@ember/service';

export default class AnswerJobOfferRoute extends BaseJobOfferRoute {
  @service store;

  model(params) {
    return this.store.findRecord('jobOffer', params.job_offer_id);
  }

  resetController(controller, isExiting) {
    if (isExiting && controller.jobOffer.hasDirtyAttributes) {
      controller.jobOffer.rollbackAttributes();
    }
  }
}
