import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class NewController extends Controller {
  @service current;

  get item() {
    const index = this.model.items.length || 0;

    return {
      id: undefined,
      date: null,
      header: null,
      text: null,
      rowOrderPosition: index,
      index,
    };
  }
}
