import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AnalyticsJobsJobRoute extends Route {
  @service store;

  async model(params) {
    const job = await this.store.findRecord('job', params.job_id);

    return { job };
  }
}
