import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { set, action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { get } from 'teamtailor/utils/get';

export default class ProfileController extends Controller {
  @service intl;
  @service current;
  @service router;
  @service flashMessages;

  get employee() {
    return this.model;
  }

  get user() {
    return this.current.user;
  }

  get isIndexRoute() {
    return this.router.currentRouteName === 'employees.profile.index';
  }

  get canEdit() {
    return this.employee.id === this.user.id || this.user.admin;
  }

  get showSidebar() {
    return this.canEdit && !this.isIndexRoute;
  }

  get subtitle() {
    const titleParts = [
      get(this.employee, 'title'),
      get(this.employee, 'department.name'),
    ];
    return titleParts.filter((part) => !isEmpty(part)).join(' - ');
  }

  @action
  handleImageUploaded(pictureUrl) {
    set(this, 'employee.pictureCache', pictureUrl);
    return get(this, 'employee').save();
  }

  @action
  handleImageRemoved() {
    set(this, 'employee.picture', null);
    get(this, 'employee')
      .save()
      .then(() => {
        get(this, 'flashMessages').success(
          this.intl.t('employees.profile.image_removed')
        );
      });
  }
}
