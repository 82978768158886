import Helper from '@ember/component/helper';
import { themeColors } from '@teamtailor/design-tokens';

const DECORATIVE_COLORS = Object.keys(themeColors.background.decorative).filter(
  (color) => color !== 'zinc'
);

const DEFAULT_COLOR = 'blue';

type AllowedColors = (typeof DECORATIVE_COLORS)[number];

function colorIndex(userId: number): number {
  return userId % DECORATIVE_COLORS.length;
}

function colorName(index: number): AllowedColors {
  return DECORATIVE_COLORS[index] ?? DEFAULT_COLOR;
}

export function userBackground([_id]: string[]): string {
  let id = parseInt(_id || 0);
  if (isNaN(id) && _id) {
    id = _id.length;
  }

  const index = colorIndex(id);
  return `bg-decorative-${colorName(index)}-weak`;
}

export default Helper.helper(userBackground);
