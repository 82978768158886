import BaseProperty from './properties/base-property';
import BaseQuery from './base-query';
import SelectedFilter from './filters/selected-filter';

export default class EventQuery extends BaseQuery {
  constructor(
    groupBys: BaseProperty[],
    properties: BaseProperty[],
    filters: SelectedFilter[],
    sortProperty?: BaseProperty,
    sortDirection?: 'asc' | 'desc',
    limit?: number,
    page?: number
  ) {
    const shouldFetchEventQuery =
      properties.some((property) =>
        property.queryTypes.some((qt) => qt === 'event')
      ) &&
      groupBys.some((property) =>
        property.queryTypes.some((qt) => qt === 'event')
      );

    if (shouldFetchEventQuery) {
      super(
        'event',
        groupBys.filter((property) => property.queryTypes.includes('event')),
        properties.filter((property) => property.queryTypes.includes('event')),
        filters.filter((filter) => filter.filter?.queryTypes.includes('event')),
        sortProperty?.queryTypes.includes('event') ? sortProperty : undefined,
        sortProperty?.queryTypes.includes('event') ? sortDirection : undefined,
        limit,
        page
      );
    } else {
      super('event');
    }
  }

  get query() {
    if (this.properties.length === 0) return undefined;

    const eventTypes = this.groupBys
      .map((property) => property.eventTypes)
      .flat();
    const args = [
      this.groupByStatement,
      this.orderStatement,
      this.limitStatement,
      this.pageStatement,
    ].filter((arg) => arg !== undefined);
    const eventTypesArg =
      eventTypes.length > 0 ? `, eventTypes: [${eventTypes.join(', ')}]` : '';
    const filterArg = this.filterStatement ? `, ${this.filterStatement}` : '';

    return `
    eventQuery(dateRange: $dateRange, companyIds: $companyIds${eventTypesArg}${filterArg}) {
      ${
        this.limitStatement
          ? `totalAggregatedCount(${this.groupByStatement})`
          : ''
      }
      aggregated${args.length > 0 ? `(${args.join(',')})` : ''} {
        ${this.propertyStatements}
      }
    }
  `;
  }
}
