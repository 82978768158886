import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';

export default class NurtureCampaignsEditScheduleController extends Controller {
  @service router;
  @service ttAlert;
  @service intl;
  @service flashMessages;

  @action
  handleAfterSave() {
    this.router.transitionTo('nurture-campaigns');
  }

  @action
  handleStatusChange(status) {
    if (status === 'archive') {
      this.ttAlert.confirm(
        this.intl.t('nurture_campaign.archive_campaign_confirm'),
        () => {
          return this.model.archive().then(() => {
            this.flashMessages.success('Nurture campaign archived');
            this.router.transitionTo('nurture-campaigns.index');
          });
        }
      );
    }

    if (status === 'pause' && this.model.isActive) {
      return this.model.pause();
    }

    if (status === 'activate') {
      return this.model.isPaused ? this.model.resume() : this.model.activate();
    }

    if (status === 'delete') {
      this.ttAlert.confirm(
        this.intl.t('nurture_campaign.delete_campaign_confirm'),
        () => {
          return this.model.destroyRecord().then(() => {
            later(() => {
              this.flashMessages.success('Nurture campaign deleted');
              this.router.transitionTo('nurture-campaigns.index');
            }, 500);
          });
        }
      );
    }

    return true;
  }
}
