import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class JobsListSettingsController extends Controller {
  @service router;

  get page() {
    return this.model;
  }

  @action
  handleCloseTakeover() {
    this.router.transitionTo('content.index');
  }
}
