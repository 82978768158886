import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';
import hexToRgb from 'teamtailor/utils/hex-to-rgb';

export function colorStyle(params) {
  const [attribute, hexColor, opacity] = params;

  if (attribute && hexColor) {
    const color = hexToRgb(hexColor, opacity || 1);

    return htmlSafe(`${attribute}: ${color};`);
  }

  return null;
}

export default helper(colorStyle);
