import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { set, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';

export default class extends Controller {
  @service current;
  @service flipper;

  @tracked _showSeekModal = false;
  @tracked _showLinkedinModal = false;

  @action
  toggleApplyWithLinkedin(value) {
    set(this.current.company, 'applyWithLinkedin', value);
    this.current.company.save();
  }

  @action
  toggleApplyWithFacebook(value) {
    set(this.current.company, 'applyWithFacebook', value);
    this.current.company.save();
  }

  @action
  toggleApplyWithLinkedinBasic(value) {
    set(this.current.company, 'applyWithLinkedinBasic', value);
    this.current.company.save();
  }

  @action
  toggleApplyWithSeek(value) {
    if (value && this.missingSeekKey) {
      this.showSeekModal();
    }

    set(this.current.company, 'applyWithSeek', value);
    this.current.company.save();
  }

  @action
  showSeekModal() {
    this._showSeekModal = true;
  }

  @action
  hideSeekModal() {
    this._showSeekModal = false;
  }

  @action
  showLinkedinModal() {
    this._showLinkedinModal = true;
  }

  @action
  hideLinkedinModal() {
    this._showLinkedinModal = false;
  }

  get missingSeekKey() {
    return get(this.current.company, 'seekAdvertiserId') === '';
  }

  get missingMarketingCookies() {
    return (
      this.model.filterBy('isMarketing', true).length === 0 &&
      get(this.current.company, 'applyWithLinkedin')
    );
  }
}
