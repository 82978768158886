import Helper from '@ember/component/helper';

export function formatRound([value], namedArgs) {
  if (!value) {
    return 0;
  }

  let multiplier = 1;

  if (
    namedArgs.toDecimals !== undefined &&
    typeof namedArgs.toDecimals == 'number'
  ) {
    multiplier = Math.pow(10, namedArgs.toDecimals);
  }

  return Math.round(value * multiplier) / multiplier;
}

export default Helper.helper(formatRound);
