import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n  <HighCharts\n    @chartOptions={{this.chartOptions}}\n    @content={{this.chartData}}\n    @hideAnimations={{@hideAnimations}}\n  />\n</div>", {"contents":"<div>\n  <HighCharts\n    @chartOptions={{this.chartOptions}}\n    @content={{this.chartData}}\n    @hideAnimations={{@hideAnimations}}\n  />\n</div>","moduleName":"teamtailor/components/insights/charts/stacked-bar-chart.hbs","parseOptions":{"srcName":"teamtailor/components/insights/charts/stacked-bar-chart.hbs"}});
import Component from '@glimmer/component';
import colors from 'teamtailor/components/insights/charts/colors';
import fontOptions from './font-options';

export default class InsightsChartsStackedBarChart extends Component {
  get height() {
    return this.args.height ? this.args.height : 300;
  }

  get chartData() {
    return this.args.categories.map((name, index) => {
      const data = this.args.data.map((row) => {
        return {
          y: row.value[index],
          color: row.color || colors[row.colorIndex] || row.colors?.[index],
        };
      });

      return { name, data };
    });
  }

  get chartOptions() {
    const plotLines = (this.args.plotLines || []).map((plotLine) => {
      return {
        label: {
          text: plotLine.tooClose ? '' : plotLine.text,
          rotation: 0,
          align: 'center',
          y: -25,
          style: {
            width: 55,
            color: plotLine.textColor,
            ...plotLine.fontOptions,
          },
        },

        color: plotLine.color,
        width: 1,
        dashStyle: 'dash',
        value: plotLine.value,
        zIndex: 3,

        events: {
          mouseover(event) {
            const { chart } = this.axis;
            chart.hoverSpeedToolTip = chart.renderer
              .label(plotLine.text, event.layerX, event.layerY - 20)
              .attr({
                fill: '#34353a',
                stroke: 'white',
              })
              .css({
                ...plotLine.fontOptions,
                color: 'white',
              })
              .add();
            chart.hoverSpeedToolTip.toFront();
          },

          mouseout() {
            const { chart } = this.axis;
            if (chart.hoverSpeedToolTip) {
              chart.hoverSpeedToolTip.destroy();
            }
          },
        },
      };
    });

    const options = {
      chart: {
        type: 'bar',
        height: `${this.height}px`,
        marginTop: plotLines.length > 0 ? 35 : 0,
      },

      title: {
        text: null,
      },

      yAxis: {
        title: {
          text: '',
        },

        plotLines,

        labels: {
          style: fontOptions,
        },
      },

      xAxis: {
        categories: this.args.data.map((a) => a.name),

        labels: {
          style: fontOptions,
        },

        tickLength: 1,
        lineColor: '#eeeff2',
        lineWidth: 1,
      },

      tooltip: {
        followPointer: true,
      },

      legend: false,

      plotOptions: {
        series: {
          stacking: 'normal',
          borderColor: null,
          pointWidth: 20,
        },
      },
    };

    return options;
  }
}
