import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import { OccupationGroupModel } from 'teamtailor/models';

export default class OccupationNameModel extends Model {
  @belongsTo('occupation-group')
  declare occupationGroup: AsyncBelongsTo<OccupationGroupModel>;

  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'occupation-name': OccupationNameModel;
  }
}
