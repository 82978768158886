import Controller from '@ember/controller';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class SettingsController extends Controller {
  @service router;

  get page() {
    return this.model;
  }

  queryParams = ['fromContent'];
  fromContent = false;

  @action
  handleCloseTakeover() {
    if (this.fromContent) {
      this.router.transitionTo('content.index');
    } else {
      const pageType = get(this, 'page.pageType');
      if (pageType === 'post') {
        this.router.transitionTo('content.posts.edit', get(this, 'page.id'));
      } else {
        this.router.transitionTo(
          'content.editor.index',
          'pages',
          get(this, 'page.id')
        );
      }
    }
  }
}
