import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Molecules::FunnelVisualization\n  ...attributes\n  @data={{this.funnelData}}\n  @onShowExportModal={{this.onShowExportModal}}\n  @loading={{or (not this.fetchData.lastSuccessful) this.fetchData.isRunning}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>", {"contents":"<Insights::Molecules::FunnelVisualization\n  ...attributes\n  @data={{this.funnelData}}\n  @onShowExportModal={{this.onShowExportModal}}\n  @loading={{or (not this.fetchData.lastSuccessful) this.fetchData.isRunning}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>","moduleName":"teamtailor/components/insights/widgets/job-funnel.hbs","parseOptions":{"srcName":"teamtailor/components/insights/widgets/job-funnel.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isEqual } from '@ember/utils';
import { percentageOf, dropOffCount } from 'teamtailor/utils/analytics';
import { gql } from '@apollo/client/core';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { underscore } from '@ember/string';

const FUNNEL_QUERY = gql`
  query JobFunnelQuery($dateRange: DateRangeAttributes!, $jobIds: [ID!]) {
    pageviewQuery(dateRange: $dateRange, jobIds: $jobIds) {
      aggregated(groupBy: JOB_ID) {
        jobId
        adVisits: countOccurrences(
          filters: { path: { match: "(/jobs/|/jobs/internal/)([a-z0-9-]*)$" } }
        )
        formVisits: countOccurrences(
          filters: { path: { like: "%/jobs/%/applications/new%" } }
        )
      }
    }
    applications: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: APPLIED
    ) {
      count
    }
  }
`;

const EXPORT_TABLE_COLUMNS = [
  {
    type: 'number',
    propertyName: 'index',
    headerKey: 'insights.common.index',
  },
  {
    type: 'text',
    propertyName: 'name',
    headerKey: 'insights.common.step',
  },
  {
    type: 'number',
    propertyName: 'count',
    headerKey: 'insights.common.count',
  },
  {
    type: 'text',
    propertyName: 'conversionRate',
    headerKey: 'insights.common.conversion_rate',
  },
  {
    type: 'text',
    propertyName: 'dropOff',
    headerKey: 'insights.common.drop_off',
  },
];

export default class InsightsWidgetsJobFunnel extends Component {
  @service insights;
  @service insightsExport;
  @service intl;

  get funnelData() {
    return this.fetchData?.lastSuccessful?.value;
  }

  @action
  onShowExportModal() {
    this.insightsExport.sortDirection = 'asc';
    this.insightsExport.showModal(
      'insights.common.funnel',
      `job-${this.args.job.id}`,
      EXPORT_TABLE_COLUMNS,
      this.retrieveExportData,
      this.args.dateRange,
      { createdAt: this.args.job.createdAt }
    );
  }

  @action
  retrieveExportData(dateRange) {
    return this.queryForData(dateRange).then((result) => {
      const {
        pageviewQuery: { aggregated: jobs },
        applications: { count: applications },
      } = result;
      const funnel = jobs.find((job) => job.jobId === this.args.job.id);
      const { adVisits, formVisits } = funnel || {};
      const steps = [adVisits, formVisits, applications];
      const stepNames = Object.keys({
        adVisits,
        formVisits,
        applications,
      }).filter((name) => !name.includes('_'));

      return steps
        .map((step, index) => {
          return {
            index,
            name: this.intl.t(
              `insights.molecules.funnel_visualization.${underscore(
                stepNames[index]
              )}`
            ),

            count: step,
            dropOff: isEqual(stepNames.lastObject, stepNames[index])
              ? 'N/A'
              : dropOffCount(step, steps[index + 1]),

            conversionRate: isEqual(stepNames.firstObject, stepNames[index])
              ? 'N/A'
              : `${percentageOf(step, steps[index - 1])}%`,
          };
        })
        .sortBy('index');
    });
  }

  @action
  queryForData(dateRange) {
    const { job } = this.args;

    return this.insights.query({
      query: FUNNEL_QUERY,
      variables: {
        dateRange: dateRange.asObject,
        jobIds: [job.id],
      },

      context: { jobId: job.id },
    });
  }

  fetchData = dropTask(async () => {
    const {
      pageviewQuery: { aggregated: jobs },
      applications: { count: applications },
    } = await this.queryForData(this.args.dateRange);
    const funnel = jobs.find((job) => job.jobId === this.args.job.id);
    const { adVisits, formVisits } = funnel || {};

    return [adVisits, formVisits, applications];
  });
}
