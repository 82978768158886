import ENV from 'teamtailor/config/environment';
import { capitalize } from '@ember/string';

export default function missingMessage(key) {
  if (key) {
    if (ENV.environment !== 'production' || !!ENV.REVIEW_APP) {
      return key;
    } else {
      return capitalize(key.split('.').pop().replace(/_/g, ' '));
    }
  } else {
    return undefined;
  }
}
