import { htmlSafe } from '@ember/template';
import { helper } from '@ember/component/helper';
import linkifyText from 'teamtailor/utils/linkify';

import sanitizeHtml from 'sanitize-html';

const ALLOWED_TAGS_DEFAULTS = [
  'h3',
  'h4',
  'h5',
  'h6',
  'blockquote',
  'p',
  'a',
  'ul',
  'ol',
  'nl',
  'li',
  'b',
  'i',
  'strong',
  'em',
  'strike',
  'code',
  'hr',
  'br',
  'pre',
];

export function simpleFormat(
  [string],
  {
    linkify = true,
    replaceNewLines = true,
    allowedTags = ALLOWED_TAGS_DEFAULTS,
  } = {}
) {
  const sanitizeOptions = {
    allowedTags,

    disallowedTagsMode: 'discard',

    allowedAttributes: {
      '*': ['dir'],
      a: ['href', 'name', 'target', 'rel'],
      img: ['src'],
    },

    selfClosing: [
      'img',
      'br',
      'hr',
      'area',
      'base',
      'basefont',
      'input',
      'link',
      'meta',
    ],

    allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
    allowProtocolRelative: true,
    transformTags: {
      ol: (tagName, attribs) => {
        return {
          tagName,
          attribs: {
            ...attribs,
            class: 'list-decimal list-inside mb-12 ml-8',
          },
        };
      },

      ul: (tagName, attribs) => {
        return {
          tagName,
          attribs: {
            ...attribs,
            class: 'list-disc list-inside mb-12 ml-8',
          },
        };
      },
    },

    allowedClasses: {
      '*': ['list-disc', 'list-decimal', 'list-inside', 'mb-12', 'ml-8'],
    },
  };

  if (linkify) {
    string = linkifyText(string);
  }

  string = string.replace(/\r\n?/g, '\n').trim();

  if (string.length > 0 && replaceNewLines) {
    string = string.replace(/\n\n/g, '</p><p>').replace(/\n/g, '<br>');
  }

  string = sanitizeHtml(`<p>${string}</p>`, sanitizeOptions);
  return htmlSafe(string);
}

export default helper(simpleFormat);
