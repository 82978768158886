import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import EditController from 'teamtailor/controllers/jobs/job/stages/stage-triggers/edit';
import { AllTriggerModels } from 'teamtailor/models/trigger';
import TriggerWebhookModel from 'teamtailor/models/trigger/webhook';
import { get } from 'teamtailor/utils/get';
import { createTrigger } from 'teamtailor/utils/triggers';
import { Transition } from 'teamtailor/utils/type-utils';

export default class StageTriggersNewRoute extends Route {
  @service declare store: Store;

  async model({
    type,
    stageId,
    partnerActivationId,
    subtype,
  }: {
    type: AllTriggerModels['type'];
    stageId: string;
    partnerActivationId: string;
    subtype: string;
  }) {
    const stage = await this.store.findRecord('stage', stageId, {
      backgroundReload: false,
    });
    const { onReject } = this.paramsFor('jobs.job.stages.stage-triggers') as {
      onReject: boolean;
    };
    return createTrigger(
      this,
      stage,
      type,
      onReject,
      partnerActivationId,
      subtype
    );
  }

  resetController(
    controller: EditController,
    isExiting: boolean,
    transition: Transition
  ) {
    super.resetController(controller, isExiting, transition);

    if (isExiting) {
      if (controller.model instanceof TriggerWebhookModel) {
        get(controller.model, 'mappedPartnerCriteria')
          .filterBy('isNew')
          .toArray()
          .invoke('unloadRecord');
      }
    }

    if (get(controller.model, 'isNew')) {
      this.store.unloadRecord(controller.model);
    }
  }
}
