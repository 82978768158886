import { Resize as OriginalResize } from 'ember-animated/motions/resize';

class Resize extends OriginalResize {
  *animate() {
    yield* super.animate();
    if (this.opts.onComplete) {
      this.opts.onComplete();
    }
  }
}

export default function resize(onComplete) {
  return function motionResize(sprite, opts) {
    opts.onComplete = onComplete;

    return new Resize(sprite, opts);
  };
}
