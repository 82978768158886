import {
  hasMany,
  belongsTo,
  attr,
  AsyncHasMany,
  AsyncBelongsTo,
} from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import TriggerModel from 'teamtailor/models/trigger';
import InterviewKitModel from 'teamtailor/models/interview-kit';
import { get } from 'teamtailor/utils/get';
import UserModel from 'teamtailor/models/user';
import MeetingRoomModel from 'teamtailor/models/meeting-room';
import UploadModel from 'teamtailor/models/upload';
import StageModel from 'teamtailor/models/stage';

export default class TriggerSmartScheduleModel extends TriggerModel<'smart-schedule'> {
  @hasMany('user') declare users: AsyncHasMany<UserModel>;
  @hasMany('upload') declare uploads: AsyncHasMany<UploadModel>;
  @belongsTo('user') declare organizer: AsyncBelongsTo<UserModel>;
  @belongsTo('interview-kit')
  declare interviewKit: AsyncBelongsTo<InterviewKitModel>;

  @belongsTo('meeting-room')
  declare meetingRoom: AsyncBelongsTo<MeetingRoomModel>;

  @belongsTo('stage', {
    inverse: 'proceedStageTriggerSmartSchedule',
  })
  declare proceedStage: AsyncBelongsTo<StageModel>;

  @attr('string') declare eventDescription: string;
  @attr('string') declare location: string;
  @attr('number', { defaultValue: 60 }) declare duration: number;
  @attr('string', { defaultValue: '09:00' }) declare fromTime: string;
  @attr('string') declare timeZone: string;
  @attr('string', { defaultValue: '17:00' }) declare toTime: string;
  @attr('string') declare summary: string;
  @attr('boolean', { defaultValue: false }) declare hasVideo: string;
  @attr('string') declare providerName: string;
  @attr('number', { defaultValue: 0 }) declare buffer: number;
  @attr('number', { defaultValue: 30 }) declare startInterval: number;
  @attr('boolean', { defaultValue: true }) declare candidateReminder: boolean;
  @attr('number', { defaultValue: 14 }) declare timeframe: number;
  @attr declare requiredAttendees: number | string;

  get cronofyUsers() {
    return get(this, 'users').filterBy('cronofyAuthorization');
  }

  get haveCronofyUser() {
    return get(this, 'cronofyUsers').length > 0;
  }

  get haveMultipleCronofyUsers() {
    return get(this, 'cronofyUsers').length > 1;
  }

  get triggerContent() {
    return get(this, 'summary');
  }

  get valid() {
    if (
      isEmpty(get(this, 'duration')) ||
      isEmpty(get(this, 'fromTime')) ||
      isEmpty(get(this, 'summary')) ||
      isEmpty(get(this, 'toTime')) ||
      isEmpty(get(this, 'users')) ||
      isEmpty(get(this, 'buffer')) ||
      isEmpty(get(this, 'startInterval')) ||
      !get(this, 'haveCronofyUser') ||
      get(this, 'isMaxParticipantsCountExceeded')
    ) {
      return false;
    } else {
      return true;
    }
  }

  get description() {
    return get(this, 'summary');
  }

  get isMaxParticipantsCountExceeded() {
    const maxVideoMeetingParticipantsCount = 4;
    const numberOfUsers = get(this, 'users').toArray().length;
    if (
      get(this, 'hasVideo') &&
      numberOfUsers >= maxVideoMeetingParticipantsCount &&
      get(this, 'providerName') === 'teamtailor'
    ) {
      return true;
    } else {
      return false;
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'trigger/smart-schedule': TriggerSmartScheduleModel;
  }
}
