import Modifier from 'ember-modifier';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { registerDestructor } from '@ember/destroyable';

function cleanup(instance) {
  instance.element?.removeEventListener('click', instance.onClick, true);
}

export default class AppRefresherModifier extends Modifier {
  @service appRefresher;

  element;

  @action onClick(event) {
    if (!this.isSimpleClick(event)) {
      return true;
    }

    if (this.appRefresher.shouldRefresh) {
      event.preventDefault();
      event.stopPropagation();
      return (window.location = this.element.href);
    }

    return event;
  }

  // copied from ember internals
  isSimpleClick(event) {
    let modifier =
      event.shiftKey || event.metaKey || event.altKey || event.ctrlKey;
    let secondaryClick = event.which > 1;

    return !modifier && !secondaryClick;
  }

  constructor(owner, args) {
    super(owner, args);
    registerDestructor(this, cleanup);
  }

  modify(element) {
    this.element = element;
    element.addEventListener('click', this.onClick, true);
  }
}
