import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { get, setProperties } from '@ember/object';

export default class IndexRoute extends Route {
  @service ttAlert;
  @service intl;
  @service user;
  @service permissions;
  @service accessLevels;

  queryParams = {
    page: { refreshModel: true },
    per_page: { refreshModel: true },
    query: { refreshModel: true },
    role: { refreshModel: true },
    department_id: { refreshModel: true },
    location_id: { refreshModel: true },
    two_factor_authentication_activated: { refreshModel: true },
    single_sign_on_activated: { refreshModel: true },
    view: { refreshModel: false },
  };

  beforeModel(model, transition) {
    if (!get(this, 'user.admin')) {
      get(this, 'ttAlert').error(
        this.intl.t('errors.dont_have_access_to_page')
      );
      transition.abort();
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    if (this.permissions.has('user/create')) {
      this.accessLevels.loadAll();
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      setProperties(controller, {
        showBulk: false,
        selectAll: false,
        deselectedEmployeeIds: [],
        selectedEmployeeIds: [],
      });
    }
  }
}
