import ArrayProxy from '@ember/array/proxy';

export default function toggleInList<T = unknown>(
  list: ArrayProxy<T> | Array<T>,
  item: T
) {
  if (list.includes(item)) {
    list.removeObject(item);
  } else {
    list.pushObject(item);
  }
}
