import { get } from 'teamtailor/utils/get';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { hash } from 'rsvp';

class CustomFormSettingsRoute extends ScrollToTopRoute {
  async model() {
    const channelActivation = this.modelFor(
      'settings.integrations.channel-activation'
    );

    return hash({
      channelActivation,
      channel: get(channelActivation, 'channel'),
    });
  }
}

export default CustomFormSettingsRoute;
