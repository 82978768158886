import Controller from '@ember/controller';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-mixins
import SearchQuery from 'teamtailor/mixins/search-query';
import { computed, get, set, setProperties, action } from '@ember/object';
import {
  alias,
  equal,
  gt,
  filter,
  sort,
  not,
  or,
} from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';

export default Controller.extend(SearchQuery, {
  server: service(),
  reports: service(),
  router: service(),
  intl: service(),
  user: service(),
  store: service(),
  flashMessages: service(),
  current: service(),

  exportModal: false,

  showFilterSidebar: computedLocalStorage(
    Boolean,
    'Requisitions.showFilterSidebar',
    false
  ),

  status: '',
  include_archived: false,
  department_id: '',
  location_id: '',
  user_id: '',
  recruiter_id: '',
  requisition_archive_reason_id: '',
  assignee: '',
  page: 1,
  per_page: 20,
  split_approvers: false,
  showBulk: false,
  selectAll: false,

  init() {
    this._super(...arguments);
    set(this, 'deselectedRequisitionIds', []);
    set(this, 'selectedRequisitionIds', []);
  },

  selectedCount: computed(
    'selectAll',
    'totalCount',
    'deselectedRequisitionIds.length',
    'selectedRequisitionIds.length',
    function () {
      if (this.selectAll) {
        return this.totalCount - this.deselectedRequisitionIds.length;
      } else {
        return this.selectedRequisitionIds.length;
      }
    }
  ),

  exportParams: computed(
    'query',
    'status',
    'include_archived',
    'department_id',
    'location_id',
    'user_id',
    'recruiter_id',
    'requisition_archive_reason_id',
    'split_approvers',
    function () {
      const params = [
        'query',
        'status',
        'include_archived',
        'department_id',
        'location_id',
        'user_id',
        'recruiter_id',
        'requisition_archive_reason_id',
        'split_approvers',
      ];

      return params.reduce((acc, param) => {
        if (get(this, param)) {
          acc[param] = get(this, param);
        }

        return acc;
      }, {});
    }
  ),

  template: null,
  creatingJobForRequisition: null,

  hasStatusEmpty: equal('status', ''),
  hasLocationEmpty: equal('location_id', ''),
  hasDepartmentEmpty: equal('department_id', ''),
  hasAssigneeEmpty: equal('assignee', ''),
  hasUserIdEmpty: equal('user_id', ''),
  hasRecruiterIdEmpty: equal('recruiter_id', ''),
  hasRequisitionArchiveReasonIdEmpty: equal(
    'requisition_archive_reason_id',
    ''
  ),

  hasLocation: not('hasLocationEmpty'),
  hasDepartment: not('hasDepartmentEmpty'),
  hasNotStatusEmpty: not('hasStatusEmpty'),
  hasNotAssigneeEmpty: not('hasAssigneeEmpty'),
  hasUserId: not('hasUserIdEmpty'),
  hasRecruiterId: not('hasRecruiterIdEmpty'),
  hasRequisitionArchiveReasonId: not('hasRequisitionArchiveReasonIdEmpty'),

  filterSelected: or(
    'hasLocation',
    'hasDepartment',
    'hasUserId',
    'hasRecruiterId',
    'hasRequisitionArchiveReasonId',
    'hasNotAssigneeEmpty',
    'hasNotStatusEmpty',
    'include_archived'
  ),

  toggleFilter: false,

  totalPages: alias('model.meta.total_pages'),
  totalCount: alias('model.meta.total_count'),

  showPagination: gt('totalPages', 1),

  visibleRequisitions: filter('model.@each.jobTitle', function (requisition) {
    return !!get(requisition, 'jobTitle');
  }),

  requisitionSorting: Object.freeze(['createdAt:desc']),
  sortedRequisitions: sort('visibleRequisitions', 'requisitionSorting'),

  selectedUser: computed('store', 'user_id', {
    get() {
      let userId = get(this, 'user_id');
      return userId ? get(this, 'store').peekRecord('user', userId) : null;
    },

    set(key, value) {
      set(this, 'user_id', get(value, 'id'));
      return value;
    },
  }),

  selectedRecruiter: computed('recruiter_id', 'store', {
    get() {
      let recruiterId = get(this, 'recruiter_id');
      return recruiterId
        ? get(this, 'store').findRecord('user', recruiterId)
        : null;
    },

    set(key, value) {
      set(this, 'recruiter_id', get(value, 'id'));
      return value;
    },
  }),

  handleSearchInput: task(function* (value) {
    yield timeout(get(this, 'searchProxyDelay'));

    if (value && value.length) {
      setProperties(this, {
        page: 1,
        query: value,
      });
    } else {
      set(this, 'query', '');
    }
  }).restartable(),

  handleExport: action(async function () {
    const adapter = get(this, 'store').adapterFor('application');
    let url = `${adapter.buildURL('requisitions')}/export`;

    await this.server.request(url, 'GET', { data: this.exportParams });

    this.flashMessages.success(
      this.intl.t('settings.requisition.export_confirmation')
    );

    set(this, 'exportModal', false);
  }),

  clearFilters: action(function () {
    set(this, 'location_id', '');
    set(this, 'department_id', '');
    set(this, 'status', '');
    set(this, 'user_id', '');
    set(this, 'recruiter_id', '');
    set(this, 'assignee', '');
    set(this, 'requisition_archive_reason_id', '');
    set(this, 'include_archived', false);
  }),

  selectItem: action(function (attribute, selected) {
    this.resetPage();
    set(this, attribute, selected ? get(selected, 'id') : '');
  }),

  resetPage: action(function () {
    set(this, 'page', 1);
  }),

  setPage: action(function (page) {
    set(this, 'page', page);
    window.scrollTo(0, 0);
  }),

  toggleShowBulk: action(function () {
    if (this.showBulk) {
      this.clearSelection();
    }

    set(this, 'showBulk', !this.showBulk);
  }),

  toggleSelectAll: action(function () {
    set(this, 'selectAll', !this.selectAll);
    this.deselectedRequisitionIds.clear();
    this.selectedRequisitionIds.clear();
  }),

  clearSelection: action(function () {
    set(this, 'selectAll', false);
    this.deselectedRequisitionIds.clear();
    this.selectedRequisitionIds.clear();
  }),

  refetchRequisitions: action(function () {
    if (this.page !== 1) {
      set(this, 'page', 1);
      this.clearSelection();
    } else {
      this.router.refresh();
    }
  }),
});
