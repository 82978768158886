import { assert } from '@ember/debug';
import { isNone } from '@ember/utils';

export function verifyArg(
  val: string | undefined,
  supportedVals: readonly string[],
  desc: string,
  defaultVal?: string
) {
  assert(
    `\n\n${desc} got "${val}". Supported values are: \n${supportedVals
      .map(
        (s) =>
          `\n   ${
            !isNone(defaultVal) && s === defaultVal
              ? `"${defaultVal}" (default)`
              : `"${s}"`
          }`
      )
      .join(', ')} \n`,
    isNone(val) || supportedVals.includes(val)
  );

  return val ?? defaultVal;
}
