import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import SettingsAutoJoinController from 'teamtailor/controllers/settings/auto-join';
import AutoJoinDomainModel from 'teamtailor/models/auto-join-domain';

export default class SettingsAutoJoinRoute extends ScrollToTopRoute {
  @service declare current: Current;
  @service declare store: Store;

  model() {
    return get(this.current.company, 'autoJoinDomains');
  }

  afterModel(model: AutoJoinDomainModel[]) {
    if (get(model, 'length') === 0) {
      this.store.createRecord('auto-join-domain', {
        company: this.current.company,
      });
    }
  }

  resetController(controller: SettingsAutoJoinController, isExiting: boolean) {
    if (isExiting) {
      const models = controller.model;
      models.filterBy('isNew').invoke('unloadRecord');
      models.filterBy('hasDirtyAttributes').invoke('rollbackAttributes');
    }
  }
}
