import Model, { attr, hasMany, AsyncHasMany } from '@ember-data/model';
import PickedQuestionModel from 'teamtailor/models/picked-question';
import MessageModel from 'teamtailor/models/message';
import QuestionModel from 'teamtailor/models/question';

export default class QuestionnairelModel extends Model {
  @hasMany('picked-question')
  declare pickedQuestions: AsyncHasMany<PickedQuestionModel>;

  @hasMany('message')
  declare messages: AsyncHasMany<MessageModel>;

  @attr('string') declare jobId?: string;

  get questions(): QuestionModel[] {
    return this.pickedQuestions.mapBy('question');
  }

  get sortedQuestions(): QuestionModel[] {
    return this.pickedQuestions.sortBy('rowOrder').mapBy('question');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    questionnaire: QuestionnairelModel;
  }
}
