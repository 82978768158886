import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Modal @isOpen={{@isOpen}} @onClose={{@onClose}} @size=\"small\" as |modal|>\n  <modal.Header @text={{t \"candidates.candidate.bulk_actions.add_tags\"}} />\n  <modal.Main>\n    <Core::TagPicker\n      @taggableType=\"Candidate\"\n      @selected={{this.newTags}}\n      @onSelect={{this.handleTagSelect}}\n      @size=\"large\"\n      @tagLimit={{3}}\n    />\n  </modal.Main>\n  <modal.Footer>\n    <StatusButton\n      @action={{this.tagCandidates}}\n      @isDisabled={{not this.newTags.length}}\n      @text={{t \"candidates.candidate.bulk_actions.add\"}}\n    />\n  </modal.Footer>\n</Core::Modal>", {"contents":"<Core::Modal @isOpen={{@isOpen}} @onClose={{@onClose}} @size=\"small\" as |modal|>\n  <modal.Header @text={{t \"candidates.candidate.bulk_actions.add_tags\"}} />\n  <modal.Main>\n    <Core::TagPicker\n      @taggableType=\"Candidate\"\n      @selected={{this.newTags}}\n      @onSelect={{this.handleTagSelect}}\n      @size=\"large\"\n      @tagLimit={{3}}\n    />\n  </modal.Main>\n  <modal.Footer>\n    <StatusButton\n      @action={{this.tagCandidates}}\n      @isDisabled={{not this.newTags.length}}\n      @text={{t \"candidates.candidate.bulk_actions.add\"}}\n    />\n  </modal.Footer>\n</Core::Modal>","moduleName":"teamtailor/components/bulk-actions/candidates/tag.hbs","parseOptions":{"srcName":"teamtailor/components/bulk-actions/candidates/tag.hbs"}});
import BaseComponent from './base';
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import toggleInList from 'teamtailor/utils/toggle-in-list';

export default class TagComponent extends BaseComponent {
  @tracked newTags = [];

  @action
  handleTagSelect(tag) {
    toggleInList(this.newTags, tag);
  }

  @action
  tagCandidates() {
    let promise = this.postAction(
      'bulk_tag',
      this.postData({ tags: this.newTags.mapBy('name') })
    );

    promise.then(() => {
      this.store.peekAll('candidate').forEach((candidate) => {
        candidate.reload();
      });
      this.newTags.clear();
    });

    this.args.onClose();

    get(this, 'batchJob.batchJobStartedTask').perform(
      promise,
      this.args.selectedCount
    );
  }
}
