import ApplicationInstance from '@ember/application/instance';
import { inject as service } from '@ember/service';
import { cached } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import comparePercentage from 'teamtailor/utils/compare-percentage';
import { FetchPolicy, gql } from '@apollo/client/core';
import activityKeys from 'teamtailor/components/insights/charts/activity-keys';
import ReportAnalyticsRequest, {
  FetchOptions,
} from './report-analytics-request';
import {
  AnalyticsReportBuilder,
  ComparableReport,
  BuildReportArgs,
} from './analytics-report-builder';
import AnalyticsService from 'teamtailor/services/analytics';
import ReportsService from 'teamtailor/services/reports';

import CandidateModel from 'teamtailor/models/candidate';
import DateRange from 'teamtailor/utils/date-range';
import { get } from 'teamtailor/utils/get';
import fetchInBatches from 'teamtailor/utils/insights/fetch-in-batches';
import uniq from 'teamtailor/utils/uniq';

interface UserRow {
  userId: string;

  notes: number;
  sources: number;
  reviews: number;
  messages: number;
  scores: number;
  moves: number;
  rejections: number;
  hires: number;
  interviews: number;
  total: number;

  __typename?: string;

  [key: string]: number | string | undefined;
}

interface RowWithUserArgs {
  container: ApplicationInstance;
  nameOrEmail: string;
  avatarUrl?: string;
  companyName?: string;
}

class RowWithUser {
  @service declare reports: ReportsService;
  @service declare analytics: AnalyticsService;

  declare userId: string;

  notes: number = 0;
  sources: number = 0;
  reviews: number = 0;
  messages: number = 0;
  scores: number = 0;
  moves: number = 0;
  rejections: number = 0;
  hires: number = 0;
  interviews: number = 0;
  total: number = 0;

  __typename?: string;

  nameOrEmail: string = '';
  avatarUrl: string = '';
  container: ApplicationInstance;

  constructor(args: UserRow & RowWithUserArgs) {
    this.container = args.container;
    Object.assign(this, args);
  }

  get user() {
    return {
      id: this.userId,
      nameOrEmail: this.nameOrEmail,
      avatarUrl: this.avatarUrl,
    };
  }

  @cached
  get noteCandidates() {
    return this.fetchCandidates.perform('note');
  }

  @cached
  get reviewedCandidates() {
    return this.fetchCandidates.perform('review');
  }

  @cached
  get sourcedCandidates() {
    return this.fetchCandidates.perform('sourced');
  }

  @cached
  get messagedCandidates() {
    return this.fetchCandidates.perform('message');
  }

  @cached
  get scoredCandidates() {
    return this.fetchCandidates.perform('score');
  }

  @cached
  get movedCandidates() {
    return this.fetchCandidates.perform('moved');
  }

  @cached
  get rejectedCandidates() {
    return this.fetchCandidates.perform('rejected');
  }

  @cached
  get hiredCandidates() {
    return this.fetchCandidates.perform('hired');
  }

  @cached
  get interviewedCandidates() {
    return this.fetchCandidates.perform('interview_created');
  }

  fetchCandidates = task(async (type: string) => {
    return new ReportAnalyticsRequest({
      container: this.container,
      query: gql`
        query UserActivityCandidatesQuery(
          $dateRange: DateRangeAttributes!
          $jobIds: [ID!]
          $eventType: EventTypeEnum!
          $userId: ID!
          $companyIds: [ID!]
        ) {
          eventQuery(
            dateRange: $dateRange
            jobIds: $jobIds
            eventTypes: [$eventType]
            filters: [{ userId: { equals: $userId } }]
            companyIds: $companyIds
          ) {
            candidates: aggregated(groupBy: [CANDIDATE_ID]) {
              id: candidateId
            }
          }
        }
      `,
      callback: (result) =>
        result?.eventQuery?.candidates?.map((c: CandidateModel) => c.id),

      extraVariables: {
        userId: this.userId,
        eventType: type.toUpperCase(),
      },
    }).fetch();
  });
}

const query = gql`
  query UserActivityQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      filters: { userId: { exists: true } }
      companyIds: $companyIds
    ) {
      total: count
      byUser: aggregated(groupBy: [USER_ID]) {
        userId

        notes: countOccurrences(filters: { eventType: { equals: NOTE } })
        sources: countOccurrences(filters: { eventType: { equals: SOURCED } })
        reviews: countOccurrences(filters: { eventType: { equals: REVIEW } })
        messages: countOccurrences(filters: { eventType: { equals: MESSAGE } })
        scores: countOccurrences(filters: { eventType: { equals: SCORE } })
        moves: countOccurrences(filters: { eventType: { equals: MOVED } })
        rejections: countOccurrences(
          filters: { eventType: { equals: REJECTED } }
        )
        hires: countOccurrences(filters: { eventType: { equals: HIRED } })
        interviews: countOccurrences(
          filters: { eventType: { equals: INTERVIEW_CREATED } }
        )
      }
    }
  }
`;

interface TeamActivityReportArgs {
  container: ApplicationInstance;
  rows: RowWithUser[];
  compareModel?: TeamActivityReport;
}

class TeamActivityReport implements ComparableReport {
  container!: ApplicationInstance;
  rows: RowWithUser[] = [];
  compareModel?: TeamActivityReport;

  constructor(args: TeamActivityReportArgs) {
    Object.assign(this, args);
  }

  get totalActivities() {
    return this.rows.map((row) => row.total).reduce((a, b) => a + b, 0);
  }

  @comparePercentage('totalActivities', 'compareModel.totalActivities')
  declare totalActivitiesCompare: number | undefined;
}

function formatRow(
  container: ApplicationInstance,
  data: UserRow,
  nameOrEmail: string,
  avatarUrl?: string,
  companyName?: string
) {
  if (data.__typename) {
    return new RowWithUser(
      Object.assign({}, data, {
        container,
        nameOrEmail,
        avatarUrl,
        companyName,
      })
    );
  } else {
    let total = 0;

    activityKeys
      .map(({ name }) => name)
      .forEach((type) => {
        total += (data[type] as number) || 0;
      });

    return new RowWithUser(
      Object.assign(data, {
        container,
        nameOrEmail,
        avatarUrl,
        total,
        companyName,
      })
    );
  }
}

type UserType = {
  id: string;
  nameOrEmail: string;
  avatarUrl?: string;
  company?: {
    id: string;
    name: string;
  };
};

type UsersResponseType = {
  users: UserType[];
};

export function buildReport(reportArgs: BuildReportArgs) {
  const { container, compareOptions } = reportArgs;
  return new AnalyticsReportBuilder<TeamActivityReport, TeamActivityReportArgs>(
    container,
    {
      query: async (options: FetchOptions = {}) => {
        return fetch(container, options);
      },

      compareOptions,

      createReport: (queryResult: TeamActivityReportArgs) => {
        return new TeamActivityReport(queryResult);
      },
    }
  );
}

export async function fetch(
  container: ApplicationInstance,
  options: FetchOptions = {}
): Promise<TeamActivityReportArgs> {
  let dateRange: DateRange | undefined, fetchPolicy: FetchPolicy | undefined;

  if (options.dateRange) {
    dateRange = options.dateRange;
  }

  const resultingRows: UserRow[] = await new ReportAnalyticsRequest({
    container,
    query,
    callback: (result) => result?.eventQuery?.byUser || [],
  }).fetch({
    dateRange,
    fetchPolicy,
  });

  const resultingRowsWithTotal = resultingRows.map((obj) => {
    const total = Object.keys(obj)
      .filter((key) => key !== '__typename' && key !== 'userId')
      .reduce((sum, key) => sum + <number>obj[key], 0);

    return { ...obj, total };
  });

  const analyticsService = container.lookup('service:analytics');
  const intlService = container.lookup('service:intl');
  const apolloService = container.lookup('service:apollo');
  const currentService = container.lookup('service:current');

  const userIds = uniq(
    resultingRowsWithTotal.map((row) => row.userId.toString())
  );

  const users = await fetchInBatches<UserType, UsersResponseType>(
    userIds,
    (ids: string[]) =>
      apolloService.query({
        query: gql`
          query UsersQuery(
            $filter: UsersFilterInput
            $userId: ID!
            $companyIds: [ID!]
          ) {
            users(
              filter: $filter
              userScope: { userId: $userId }
              groupCompanyIds: $companyIds
            ) {
              id
              nameOrEmail
              avatarUrl
              company {
                id
                name
              }
            }
          }
        `,
        variables: {
          filter: { ids },
          userId: get(currentService.user, 'id'),
          companyIds: analyticsService.availableCompanyIds,
        },
      }),
    (acc, response) => {
      return acc.concat(response.users);
    }
  );

  const deletedUserString = intlService.t('common.deleted_user');
  const notAvailableString = intlService.t(
    'components.data_table.not_available'
  );

  const rows = resultingRowsWithTotal.map((row) => {
    const user = users.find((user: UserType) => user.id === row.userId);
    return formatRow(
      container,
      row,
      user?.nameOrEmail || deletedUserString,
      user?.avatarUrl,
      user?.company?.name || notAvailableString
    );
  });

  return {
    container,
    rows,
  };
}
