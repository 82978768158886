import { attr, belongsTo } from '@ember-data/model';
import TriggerModel from 'teamtailor/models/trigger';
import SurveyModel from 'teamtailor/models/survey';
import { get } from 'teamtailor/utils/get';

export default class TriggerSurveyModel extends TriggerModel<'survey'> {
  @attr('string')
  declare formId: string;

  @belongsTo('form', { async: false })
  declare form: SurveyModel;

  get valid() {
    return !!this.formId;
  }

  get survey() {
    return this.form;
  }

  get description() {
    return get(this.survey, 'name');
  }

  get triggerContent() {
    return this.description;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'trigger/survey': TriggerSurveyModel;
  }
}
