import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"c-loading-spinner__bounce1\"></div>\n<div class=\"c-loading-spinner__bounce2\"></div>\n<div class=\"c-loading-spinner__bounce3\"></div>", {"contents":"<div class=\"c-loading-spinner__bounce1\"></div>\n<div class=\"c-loading-spinner__bounce2\"></div>\n<div class=\"c-loading-spinner__bounce3\"></div>","moduleName":"teamtailor/components/loading-spinner.hbs","parseOptions":{"srcName":"teamtailor/components/loading-spinner.hbs"}});
import Component from '@ember/component';
import { set, get, computed } from '@ember/object';
import { warn } from '@ember/debug';

const validSizes = ['small', 'default', 'medium', 'large', 'x-large'];

export default Component.extend({
  classNameBindings: [
    ':c-loading-spinner',
    'sizeClass',
    'isVerticalCenter:c-loading-spinner__vertical-center',
    'themeClass',
  ],

  theme: 'default',
  isVerticalCenter: false,

  themeClass: computed('theme', function () {
    return `c-loading-spinner--${get(this, 'theme')}`;
  }),

  size: computed('_size', {
    get() {
      return get(this, '_size') || 'default';
    },

    set(key, value) {
      if (validSizes.includes(value)) {
        set(this, '_size', value);
        return value;
      } else {
        warn(`Must specify some of the valid sizes: ${validSizes}`, false, {
          id: 'Unknown size',
        });
      }
    },
  }),

  sizeClass: computed('size', function () {
    return `c-loading-spinner--${get(this, 'size')}`;
  }),
});
