import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"body-text-xs flex w-full flex-col space-y-2 overflow-x-scroll py-24\"\n>\n  <div class=\"relative flex flex-row space-x-2\">\n    <div local-class=\"leftColumn\">\n    </div>\n    {{#each @heatmapTable.headers as |columnHeader|}}\n      <Insights::Atoms::HeatmapTableHeader @header={{columnHeader}} />\n    {{/each}}\n  </div>\n  {{#each @heatmapTable.rows as |row|}}\n    <div class=\"relative flex flex-row space-x-2\">\n      <div class=\"h-64\" local-class=\"leftColumn\">\n        <span class=\"flex size-full items-center\">\n          {{row.header}}\n        </span>\n      </div>\n      {{#each row.cells as |cell|}}\n        <Insights::Atoms::HeatmapTableCell @cell={{cell}} />\n      {{/each}}\n    </div>\n  {{/each}}\n</div>", {"contents":"<div\n  class=\"body-text-xs flex w-full flex-col space-y-2 overflow-x-scroll py-24\"\n>\n  <div class=\"relative flex flex-row space-x-2\">\n    <div local-class=\"leftColumn\">\n    </div>\n    {{#each @heatmapTable.headers as |columnHeader|}}\n      <Insights::Atoms::HeatmapTableHeader @header={{columnHeader}} />\n    {{/each}}\n  </div>\n  {{#each @heatmapTable.rows as |row|}}\n    <div class=\"relative flex flex-row space-x-2\">\n      <div class=\"h-64\" local-class=\"leftColumn\">\n        <span class=\"flex size-full items-center\">\n          {{row.header}}\n        </span>\n      </div>\n      {{#each row.cells as |cell|}}\n        <Insights::Atoms::HeatmapTableCell @cell={{cell}} />\n      {{/each}}\n    </div>\n  {{/each}}\n</div>","moduleName":"teamtailor/components/insights/molecules/heatmap-table.hbs","parseOptions":{"srcName":"teamtailor/components/insights/molecules/heatmap-table.hbs"}});
import Component from '@glimmer/component';
import { HeatmapTable } from 'teamtailor/components/insights/molecules/heatmap-table-types';

type Args = {
  heatmapTable: HeatmapTable;
};

export default class InsightsMoleculesHeatmapTable extends Component<Args> {}
