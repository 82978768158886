// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { set } from '@ember/object';
import { Transition } from 'teamtailor/utils/type-utils';
import Controller from '@ember/controller';

export default class WidgetsRoute extends ScrollToTopRoute {
  queryParams = {
    tab: { refreshModel: false },
  };

  resetController(
    controller: Controller,
    isExiting: boolean,
    transition: Transition
  ) {
    super.resetController(controller, isExiting, transition);
    set(controller, 'tab', 'job-list');
  }
}
