import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"root\">\n  {{#if this.hasHexCode}}\n    <div\n      local-class=\"color\"\n      style={{color-style \"background-color\" @color}}\n    ></div>\n  {{/if}}\n  <span>{{@color}}</span>\n</div>", {"contents":"<div local-class=\"root\">\n  {{#if this.hasHexCode}}\n    <div\n      local-class=\"color\"\n      style={{color-style \"background-color\" @color}}\n    ></div>\n  {{/if}}\n  <span>{{@color}}</span>\n</div>","moduleName":"teamtailor/components/question-answer/favourite-color.hbs","parseOptions":{"srcName":"teamtailor/components/question-answer/favourite-color.hbs"}});
import Component from '@glimmer/component';

const REGEX = /^#[0-9A-F]{6}$/i;

export default class QuestionAnswerFavouriteColor extends Component {
  get hasHexCode() {
    return REGEX.test(this.args.color);
  }
}
