import EmberObject, { get, set, computed } from '@ember/object';
import { filterBy, alias } from '@ember/object/computed';
import moment from 'moment-timezone';

export default EmberObject.extend({
  selectedSlots: filterBy('slots', 'selected', true),
  availableSlots: filterBy('slots', 'available', true),
  availableSlotCount: alias('availableSlots.length'),

  isLastDayOfWeek: computed('date', function () {
    const date = get(this, 'date');
    return date && moment(date).isoWeekday() === 7;
  }),

  isToday: computed('date', function () {
    const date = get(this, 'date');
    return moment().isSame(date, 'day');
  }),

  groupedSelectedSlots: computed(
    'selectedSlots.@each.{start,end}',
    function () {
      return get(this, 'selectedSlots').reduce(
        (selectedTimeSlots, timeSlot) => {
          let lastTimeSlot = get(selectedTimeSlots, 'lastObject');

          if (
            lastTimeSlot &&
            get(lastTimeSlot, 'end') === get(timeSlot, 'start')
          ) {
            set(lastTimeSlot, 'end', timeSlot.end);
          } else {
            selectedTimeSlots.pushObject(timeSlot);
          }

          return selectedTimeSlots;
        },
        []
      );
    }
  ),
});
