import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';

export default class EventsController extends Controller {
  @service declare current: Current;

  queryParams = ['page'];

  get events() {
    return this.model;
  }

  get auditLog() {
    return this.current.company.auditLog;
  }

  get currentPage() {
    return this.events.meta.current_page;
  }

  get totalPages() {
    return this.events.meta.total_pages;
  }

  get showPagination() {
    return this.totalPages > 1;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.audit-log.events': EventsController;
  }
}
