import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class={{class-names\n    \"c-search\"\n    (if this.isActive \"c-search--active\")\n    (if this.isDark \"c-search--dark\")\n  }}\n  ...attributes\n>\n  <label class=\"c-search__label\" for=\"search-input\">\n    <Icon @icon=\"magnifying-glass\" class=\"icon\" />\n  </label>\n  <input\n    id=\"search-input\"\n    class=\"c-search__input\"\n    placeholder={{this.placeholder}}\n    type=\"search\"\n    value={{@value}}\n    {{on \"focusin\" this.handleOnFocusIn}}\n    {{on \"focusout\" this.handleOnFocusOut}}\n    {{on \"input\" this.valChange}}\n    {{did-insert this.handleInsertInput}}\n  />\n\n  {{#if (and @value @allowClear)}}\n    <ButtonGroup::Button @icon=\"circle-xmark\" @onClick={{this.clearInput}} />\n  {{/if}}\n</div>", {"contents":"<div\n  class={{class-names\n    \"c-search\"\n    (if this.isActive \"c-search--active\")\n    (if this.isDark \"c-search--dark\")\n  }}\n  ...attributes\n>\n  <label class=\"c-search__label\" for=\"search-input\">\n    <Icon @icon=\"magnifying-glass\" class=\"icon\" />\n  </label>\n  <input\n    id=\"search-input\"\n    class=\"c-search__input\"\n    placeholder={{this.placeholder}}\n    type=\"search\"\n    value={{@value}}\n    {{on \"focusin\" this.handleOnFocusIn}}\n    {{on \"focusout\" this.handleOnFocusOut}}\n    {{on \"input\" this.valChange}}\n    {{did-insert this.handleInsertInput}}\n  />\n\n  {{#if (and @value @allowClear)}}\n    <ButtonGroup::Button @icon=\"circle-xmark\" @onClick={{this.clearInput}} />\n  {{/if}}\n</div>","moduleName":"teamtailor/components/table-instant-search.hbs","parseOptions":{"srcName":"teamtailor/components/table-instant-search.hbs"}});
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';

interface TableInstantSearchArgs {
  value: string;
  theme: 'dark' | 'light';
  onchange: (value: string) => void;
  allowClear?: boolean;
}

export default class TableInstantSearchComponent extends Component<TableInstantSearchArgs> {
  @tracked isFocus = false;
  @tracked inputElement: HTMLInputElement | null = null;

  get emptyValue() {
    return this.args.value === '';
  }

  get isDark() {
    return this.args.theme === 'dark';
  }

  get hasValue() {
    return this.args.value !== '';
  }

  get isActive() {
    return this.isFocus || this.hasValue;
  }

  @action
  valChange(event: InputEvent) {
    const target = event.target as HTMLInputElement;
    this.args.onchange(target.value);
  }

  @action
  clearInput() {
    this.args.onchange('');
    this.inputElement?.focus();
  }

  @action
  handleOnFocusOut() {
    set(this, 'isFocus', false);
  }

  @action
  handleOnFocusIn() {
    set(this, 'isFocus', true);
  }

  @action
  handleInsertInput(element: HTMLInputElement) {
    this.inputElement = element;

    next(() => {
      this.inputElement?.focus();
    });
  }
}
