export const blocketEmploymentTypes = [
  { id: 1, name: 'Heltid' },
  { id: 2, name: 'Deltid' },
  { id: 3, name: 'Extrajobb' },
  { id: 4, name: 'Säsongsjobb' },
  { id: 5, name: 'Franchise' },
  { id: 6, name: 'Tillsvidareanställd' },
  { id: 7, name: 'Projekt- / Visstidsanställd' },
  { id: 8, name: 'Trainee' },
  { id: 9, name: 'Internship / Praktik' },
  { id: 10, name: 'Examensarbete' },
  { id: 11, name: 'Vikariat' },
  { id: 12, name: 'Frilans' },
];

export const reedWorkingHours = [
  { code: '1', label: 'Full time' },
  { code: '2', label: 'Part time' },
  { code: '3', label: 'Full or Part time' },
];
export const reedJobTypes = [
  { code: '1', label: 'Permanent' },
  { code: '2', label: 'Contract' },
  { code: '4', label: 'Temporary' },
];

export const apecTravelAreas = [
  { code: 'AUCUN', label: 'Pas de déplacement' },
  { code: 'DEPARTEMENT', label: 'Départmentale' },
  { code: 'REGIONAL', label: 'Régionale' },
  { code: 'NATIONAL', label: 'Nationale' },
  { code: 'UE', label: 'Union Européenne' },
  { code: 'HORS_UE', label: 'Internationale' },
];

export const apecRemoteWorkTypes = [
  { code: 'PARTIEL_POSSIBLE', label: 'Travail à distance partiel' },
  { code: 'PONCTUEL_AUTORISE', label: 'Travail à distance ponctuel' },
  { code: 'TOTAL_POSSIBLE', label: 'Travail à distance complet' },
];

export const apecExperienceLevels = [
  { code: '1', label: "Tous niveaux d'expériences acceptés" },
  { code: '2', label: 'Aucune expérience exigée' },
  { code: '3', label: 'Minimum 1 an' },
  { code: '4', label: 'Minimum 2 ans' },
  { code: '5', label: 'Minimum 3 ans' },
  { code: '6', label: 'Minimum 4 ans' },
  { code: '7', label: 'Minimum 5 ans' },
  { code: '8', label: 'Minimum 6 ans' },
  { code: '9', label: 'Minimum 7 ans' },
  { code: '10', label: 'Minimum 8 ans' },
  { code: '11', label: 'Minimum 9 ans' },
];

export const apecJobStatuses = [
  { code: 'CADRE_PRIVE', label: 'Cadre Prive' },
  { code: 'CADRE_PUBLIC', label: 'Cadre Public' },
  { code: 'AGENT_DE_MAITRISE', label: 'Agent de Maitrise' },
];

export const apecJobTypes = [
  { code: '5', label: 'CDD' },
  { code: '1', label: 'CDI' },
  { code: '8', label: "Mission d''intérim" },
  { code: '6', label: "CDD - Alternance – Contrat d''apprentissage" },
  { code: '7', label: 'CDD - Alternance – Contrat de professionnalisation' },
  { code: '2', label: "CDI - Alternance – Contrat d''apprentissage" },
  { code: '3', label: 'CDI - Alternance – Contrat de professionnalisation' },
  { code: '4', label: 'CDI interimaire' },
];

export const apecNeedsDurationForJobTypeCode = [
  '2',
  '3',
  '5',
  '4',
  '6',
  '7',
  '8',
];

export const jobnetDrivingLicenses = [
  'Nothing',
  'A1',
  'A',
  'B',
  'BE',
  'C',
  'CE',
  'D',
  'DE',
  'A10',
  'AMa',
  'AMb',
  'A2',
  'C1',
  'D1',
  'C1E',
  'D1E',
  'Taxi',
  'Traktor',
  'Truck',
  'BusErhverv',
  'DigitalFartskriver',
  'EUGodsErhverv',
  'EUBusErhverv',
];

export const jobnetJobTypes = [
  'Normal',
  'HotJob',
  'Flexjob',
  'EarlyRetirement',
  'NormalAndEarlyRetiree',
];

export const ejobsCareerLevels = [
  { code: '10', label: 'No experience' },
  { code: '3', label: 'Entry-Level (< 2 years)' },
  { code: '4', label: 'Mid-Level (2-5 years)' },
  { code: '6', label: 'Senior-Level (> 5 years)' },
  { code: '5', label: 'Manager / Executive' },
];

export const ejobsAdTypes = [
  { code: '334', label: 'Business Ad, weekly update (30 days)' },
  { code: '434', label: 'Business Ad, daily update (30 days) ' },
  { code: '534', label: 'Premium Ad, weekly update (30 days)' },
  { code: '634', label: 'Premium Ad, daily update (30 days)' },
  { code: '9', label: 'Standard ad (30 days)' },
  { code: '41', label: 'Standard ad, daily update (30 days)' },
];
