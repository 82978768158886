export const STATUS_COLOR_MAP = {
  active: 'lime',
  archived: 'red',
  awaiting: 'yellow',
  changed: 'cerise',
  draft: 'yellow',
  expired: 'orange',
  inactive: 'red',
  error: 'red',
  paused: 'light-blue',
  pending: 'amber',
  pending_approval: 'amber',
  published: 'lime',
  scheduled: 'cyan',
  unlisted: 'zinc',
  unknown: 'zinc',
  unsaved: 'zinc',
  template: 'zinc',
} as const;

export const ALLOWED_STATUSES = Object.keys(
  STATUS_COLOR_MAP
) as (keyof typeof STATUS_COLOR_MAP)[];
