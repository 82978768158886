import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ApiKeysController extends Controller {
  @service ttAlert;
  @service store;
  @service intl;
  @service flipper;
  @service flashMessages;

  @tracked newScope = 'open';
  @tracked showModal = false;
  @tracked newName = '';
  @tracked newWrite = false;
  @tracked newRead = false;

  get savedApiKeys() {
    return this.model.filterBy('isNew', false);
  }

  get sortedApiKeys() {
    return this.savedApiKeys.sortBy('createdAt').reverse();
  }

  get selectedNewScopeLabel() {
    switch (this.newScope) {
      case 'open':
        return this.intl.t('settings.integrations.public');
      case 'internal':
        return this.intl.t('settings.integrations.internal');
      case 'admin':
        return this.intl.t('settings.integrations.api_keys.admin');
      case 'analytics':
        return this.intl.t('settings.integrations.api_keys.analytics');
    }

    return '';
  }

  @action
  create() {
    let model = this.store.createRecord('api-key', {
      scope: this.newScope,
      name: this.newName,
      write: this.newWrite,
      read: this.newRead,
    });
    let promise = model.save();
    promise.then(() => {
      this.newName = '';
      this.newWrite = false;
      this.newRead = false;
      this.newScope = 'open';
      this.showModal = false;
    });
    return promise;
  }

  @action
  delete(apiKey) {
    this.ttAlert.confirm(
      this.intl.t('settings.integrations.api_keys.are_you_sure_deleting'),
      () => {
        apiKey.destroyRecord().then(() => {
          this.flashMessages.success(
            this.intl.t('settings.integrations.api_keys.api_key_removed')
          );
        });
      },
      () => {}
    );
  }

  @action
  toggleModal() {
    this.showModal = !this.showModal;
  }

  @action
  copy(apiKey) {
    navigator.clipboard.writeText(apiKey);
    this.flashMessages.success(this.intl.t('common.copied_to_clipboard'));
  }
}
