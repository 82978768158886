import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import { timeout, restartableTask } from 'ember-concurrency';
import TtAlertService from 'teamtailor/services/tt-alert';
import IntlService from 'ember-intl/services/intl';
import UserModel from 'teamtailor/models/user';
import TagModel from 'teamtailor/models/tag';
import { tracked } from '@glimmer/tracking';
import ImageModel from 'teamtailor/models/image';
import Store from '@ember-data/store';
import { aniMate } from 'ember-ani-mate';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import PickedImageModel from 'teamtailor/models/picked-image';
import { AsyncBelongsTo } from '@ember-data/model';
import Current from 'teamtailor/services/current';
import FlipperService from 'teamtailor/services/flipper';

const VISIBLITY_OPTIONS = ['all', 'career_site', 'connect'];

export default class EditPostController extends Controller {
  @service declare current: Current;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare ttAlert: TtAlertService;
  @service declare store: Store;
  @service declare flipper: FlipperService;

  @tracked pickingCoverImage = false;
  @tracked focusMode = false;
  @tracked firstRender = true;
  @tracked declare scrollEl?: HTMLElement;

  wysywigEditorId = 'wysywig-editor';

  get now(): Date {
    return new Date();
  }

  get status(): string {
    if (this.model.isPublished) {
      return 'published';
    } else {
      return 'draft';
    }
  }

  get canBePromoted(): boolean {
    return !!this.model.users.length;
  }

  get publishButtonError(): string | undefined {
    if (!this.canBePromoted) {
      if (!this.model.users.length) {
        return this.intl.t('content.posts.edit.errors.author');
      }
    }

    return undefined;
  }

  get visibilityOptions() {
    return VISIBLITY_OPTIONS.map((option: string) => {
      return {
        name: this.intl.t(`content.posts.edit.visibility.${option}`),
        value: option,
      };
    });
  }

  @action
  runMouseInteractionTask(): void {
    if (this.focusMode) {
      this.mouseInterationTask.perform();
    }
  }

  saveTask = restartableTask(async (debounceTime = 500) => {
    await timeout(debounceTime);
    await this.model.save();
  });

  mouseInterationTask = restartableTask(async () => {
    await timeout(5000);
  });

  @action
  handleOpen(): void {
    this.firstRender = false;

    document.addEventListener('mousemove', this.runMouseInteractionTask);
  }

  @action
  async sidebarIn(element: HTMLDivElement): Promise<void> {
    await aniMate.from(element, { marginRight: -300, opacity: 0 }, 600);
    await aniMate.to(element, { opacity: 1 }, 200);
  }

  @action
  async sidebarOut(element: HTMLDivElement): Promise<void> {
    await aniMate.to(
      element,
      { marginRight: -300, opacity: 0 },
      { duration: 500, delay: 100 }
    );
  }

  @action
  async headerIn(element: HTMLDivElement): Promise<void> {
    element.style.marginTop = '-48px';
    await aniMate.from(
      element,
      { marginTop: -48, opacity: 0 },
      { duration: 600, delay: 100 }
    );
    await aniMate.to(element, { opacity: 1, marginTop: 0 }, 400);
  }

  @action
  async headerOut(element: HTMLDivElement): Promise<void> {
    await aniMate.to(
      element,
      { marginTop: -48, opacity: 0 },
      { duration: 500, delay: 550 }
    );
  }

  @action
  async bgIn(element: HTMLDivElement): Promise<void> {
    await aniMate.from(element, { opacity: 0 }, { duration: 1100, delay: 100 });
    await aniMate.to(element, { opacity: 1 }, 250);
  }

  @action
  async bgOut(element: HTMLDivElement): Promise<void> {
    await aniMate.to(element, { opacity: 0 }, 1000);
  }

  @action
  handleClose() {
    this.saveTask.perform(0);

    this.firstRender = true;
    this.focusMode = false;

    document.removeEventListener('mousemove', this.runMouseInteractionTask);

    this.router.transitionTo('content.index');
  }

  @action
  handleSave() {
    this.saveTask.perform();
  }

  @action
  handleTitleEnter() {
    const editable = document
      .getElementById(this.wysywigEditorId)
      ?.querySelector<HTMLElement>('[contenteditable]');

    if (editable) {
      editable.focus();
    }
  }

  @action
  handleDelete() {
    this.ttAlert.confirm(
      this.intl.t('content.posts.edit.delete.confirm_delete_post'),
      () => {
        this.model.pagePublication
          .destroyRecord()
          .then(() => this.router.transitionTo('content.index'));
      },
      undefined,
      {
        confirmButtonText: this.intl.t('content.posts.edit.delete.delete_post'),
      }
    );
  }

  @action
  handleInsertHeader(element: HTMLElement) {
    const observer = new IntersectionObserver(
      ([e]) => {
        element.classList.toggle('stuck', (e?.intersectionRatio || 0) < 1);
      },
      { threshold: [1] }
    );

    observer.observe(element);
  }

  @action
  async handlePublish() {
    if (!this.model.isPublished) {
      await this.model.pagePublication.publish();
      this.model.unpublishedChanges = false;
    } else if (this.model.isPublished && this.model.unpublishedChanges) {
      await this.model.pagePublication.promoteChanges();
      this.model.unpublishedChanges = false;
    }
  }

  @action
  async handleUnpublish(): Promise<void> {
    await this.model.pagePublication.unpublish();
  }

  @action
  handleUsersChange(user: UserModel): void {
    if (this.model.users.includes(user)) {
      this.model.users.removeObject(user);
    } else {
      this.model.users.pushObject(user);
    }
  }

  @action
  handleTagsChange(tag: TagModel): void {
    toggleInList(this.model.tags, tag);
    this.saveTask.perform(0);
  }

  @action
  async handlePickCoverImage(image: AsyncBelongsTo<ImageModel>) {
    let pickedImage: PickedImageModel | undefined = await this.model
      .pickedImage;
    if (!pickedImage) {
      pickedImage = await this.store.createRecord('picked-image', {
        page: this.model,
      });
    }

    pickedImage.image = image;
    await pickedImage.save();

    this.pickingCoverImage = false;
    this.model.unpublishedChanges = true;
  }

  @action
  handleDeleteCoverImage() {
    this.model.pickedImage.destroyRecord();
    this.model.unpublishedChanges = true;
  }

  @action
  handleVisibilityChange({ value }: { value: string }) {
    this.model.visibility = value;
    this.handleSave();
  }
}

declare module '@ember/controller' {
  interface Registry {
    'content.posts.edit': EditPostController;
  }
}
