import BaseProperty from './properties/base-property';
import BaseQuery from './base-query';
import SelectedFilter from './filters/selected-filter';
import uniq from 'teamtailor/utils/uniq';

export default class CombinedQuery extends BaseQuery {
  constructor(
    groupBys: BaseProperty[],
    properties: BaseProperty[],
    filters: SelectedFilter[],
    sortProperty?: BaseProperty,
    sortDirection?: 'asc' | 'desc',
    limit?: number,
    page?: number
  ) {
    super(
      'combined',
      groupBys,
      properties,
      filters,
      sortProperty,
      sortDirection,
      limit,
      page
    );
  }

  get query() {
    if (this.properties.length === 0) return undefined;

    const eventTypes = uniq(
      this.groupBys.map((property) => property.eventTypes).flat()
    );
    const args = [
      this.groupByStatement,
      this.orderStatement,
      this.limitStatement,
      this.pageStatement,
    ].filter((arg) => arg !== undefined);
    const eventTypesArg =
      eventTypes.length > 0 ? `, eventTypes: [${eventTypes.join(', ')}]` : '';
    const filterArg = this.filterStatement ? `, ${this.filterStatement}` : '';

    return `
    combinedQuery(dateRange: $dateRange, companyIds: $companyIds${eventTypesArg}${filterArg}) {
      ${
        this.limitStatement
          ? `totalAggregatedCount(${this.groupByStatement})`
          : ''
      }
      aggregated${args.length > 0 ? `(${args.join(',')})` : ''} {
        ${this.propertyStatements}
      }
    }
  `;
  }
}
