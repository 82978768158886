import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import CompanyModel from 'teamtailor/models/company';
import UserModel from 'teamtailor/models/user';

export default class Current extends Service.extend({}) {
  @tracked declare company: CompanyModel;
  @tracked declare user: UserModel;
}

declare module '@ember/service' {
  interface Registry {
    current: Current;
  }
}
