import CandidateModalController from 'teamtailor/controllers/candidate-modal';
import { VideoMeetingTokenModel } from 'teamtailor/models';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';

export default class VideoMeetingCandidateController extends CandidateModalController {
  @tracked videoMeetingToken?: VideoMeetingTokenModel;

  get candidate() {
    return this.model;
  }

  get videoMeetingCandidates() {
    return get(this.videoMeetingToken, 'candidates');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'video-meeting.candidate': VideoMeetingCandidateController;
  }
}
