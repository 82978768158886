import Controller from '@ember/controller';
import { action } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class MarketplaceChannelsIndexChannelIndeedController extends Controller {
  @service current;
  @service user;
  @service router;
  @service store;

  createChannelActivationAndRedirectToSettings = task(async () => {
    const channel = this.store.peekRecord('channel', this.model.id);
    const channelActivation = get(
      this.current.company,
      'channelActivations'
    ).createRecord({
      channel,
    });
    const savedchannelActivation = await channelActivation.save();

    await timeout(2000);
    this.router.transitionTo(
      'settings.integrations.channel-activation.indeed',
      savedchannelActivation
    );
  });

  @action
  handleClose() {
    this.router.transitionTo('marketplace');
  }
}
