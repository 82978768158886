import TranslationModel from 'teamtailor/models/translation';
import { attr, belongsTo } from '@ember-data/model';

export default class TranslationTermsOfServiceSettingModel extends TranslationModel {
  @attr('string') connectText;
  @attr('string') connectTextFutureJobs;
  @attr('string') text;
  @attr('string') textFutureJobs;
  @belongsTo termsOfServiceSetting;
}
