import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';

import {
  AVAILABLE_CATEGORIES,
  DEFAULT_BACKGROUND_COLOR,
} from 'teamtailor/constants/tag';
import IntlService from 'ember-intl/services/intl';
import { CompanyModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import TaggingCountModel from './tagging-count';

export default class TagModel extends Model {
  @service declare intl: IntlService;

  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @belongsTo('tagging-count')
  declare taggingCount: AsyncBelongsTo<TaggingCountModel>;

  @attr('string') bgColor?: string | null;
  @attr('string') declare rawName: string;
  @attr('string') declare name: string;

  @attr('boolean', { defaultValue: false })
  declare availableOnCandidates: boolean;

  @attr('boolean', { defaultValue: false })
  declare availableOnCannedResponses: boolean;

  @attr('boolean', { defaultValue: false }) declare availableOnImages: boolean;
  @attr('boolean', { defaultValue: false })
  declare availableOnInterviewKits: boolean;

  @attr('boolean', { defaultValue: false }) declare availableOnJobs: boolean;
  @attr('boolean', { defaultValue: false }) declare availableOnPages: boolean;
  @attr('boolean', { defaultValue: false }) declare availableOnPosts: boolean;
  @attr('boolean', { defaultValue: false })
  declare availableOnQuestions: boolean;

  get backgroundColor() {
    return this.bgColor ?? DEFAULT_BACKGROUND_COLOR;
  }

  set backgroundColor(value) {
    this.bgColor = value === DEFAULT_BACKGROUND_COLOR ? null : value;
  }

  get categories() {
    const categories: string[] = AVAILABLE_CATEGORIES.reduce(
      (acc: string[], category) => {
        const categoryKey = `availableOn${category}` as keyof TagModel;
        if (get(this, categoryKey)) {
          acc.push(
            this.intl.t(
              `settings.tag_manager.categories.${category.toLowerCase()}`
            )
          );
        }

        return acc;
      },
      []
    );

    return categories.join(', ');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    tag: TagModel;
  }
}
