import Model, { attr, hasMany, type AsyncHasMany } from '@ember-data/model';
import { BlocketMunicipalityModel } from 'teamtailor/models';

export default class BlocketRegionModel extends Model {
  @hasMany('blocket-municipality')
  declare blocketMunicipalities: AsyncHasMany<BlocketMunicipalityModel>;

  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'blocket-region': BlocketRegionModel;
  }
}
