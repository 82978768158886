import BaseProperty, { BasePropertyOptions } from './base-property';
import { EventTypeEnum } from '../event-types';
import ExistsFilter from '../filters/exists';
import EqualsFilter from '../filters/equals';

export type DeviceTypeEnum =
  | 'desktop'
  | 'mobile'
  | 'tablet'
  | 'bot'
  | 'unknown';

export type CombinedEnums = EventTypeEnum | DeviceTypeEnum;

interface EnumPropertyOptions extends BasePropertyOptions {
  enumValues: CombinedEnums[];
}

export default class EnumProperty extends BaseProperty {
  enumValues: CombinedEnums[];

  constructor(options: EnumPropertyOptions) {
    super({
      ...options,
      type: 'enum',
      groupByAddsExistsFilter: true,
    });
    this.enumValues = options.enumValues;
    this.operators = [new ExistsFilter(), new EqualsFilter()];
  }
}
