import Model, {
  belongsTo,
  hasMany,
  attr,
  type AsyncBelongsTo,
  type AsyncHasMany,
  type SyncHasMany,
} from '@ember-data/model';
import { CompanyModel, PostModel, TagModel } from 'teamtailor/models';

export default class BlogModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @hasMany('post') declare posts: AsyncHasMany<PostModel>;
  @hasMany('tag', { async: false }) declare tags: SyncHasMany<TagModel>;

  @attr('string') declare description: string;
  @attr('string') declare disqusShortname: string;
  @attr('string') declare navigationName: string;
  @attr('string') declare title: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    blog: BlogModel;
  }
}
