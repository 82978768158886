import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::TagPicker\n  @onSelect={{@onSelect}}\n  @selected={{this.selectedTags.value}}\n  @disableCreate={{true}}\n  @tagLimit={{3}}\n  @isSubmenu={{true}}\n  @taggableType={{@taggableType}}\n  @parentApi={{@parentApi}}\n/>", {"contents":"<Core::TagPicker\n  @onSelect={{@onSelect}}\n  @selected={{this.selectedTags.value}}\n  @disableCreate={{true}}\n  @tagLimit={{3}}\n  @isSubmenu={{true}}\n  @taggableType={{@taggableType}}\n  @parentApi={{@parentApi}}\n/>","moduleName":"teamtailor/components/fancy-filters/tag-picker.hbs","parseOptions":{"srcName":"teamtailor/components/fancy-filters/tag-picker.hbs"}});
import Component from '@glimmer/component';
import TagModel from 'teamtailor/models/tag';
import { trackedFunction } from 'ember-resources/util/function';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';

interface FancyFiltersTagPickerArgs {
  selected: (TagModel | string)[];
  taggableType: string;
}

export default class FancyFiltersTagPicker extends Component<FancyFiltersTagPickerArgs> {
  @service declare store: Store;

  fetchedTags: TagModel[] = [];

  selectedTags = trackedFunction(this, async () => {
    if (isEmpty(this.tagNames)) {
      return this.args.selected;
    }

    if (isEmpty(this.fetchedTags)) {
      await this.fetchMissingTags();
    }

    return this.args.selected.map((selected) => {
      if (this.tagNames.includes(selected)) {
        return this.fetchedTags.findBy('name', selected);
      } else {
        return selected;
      }
    });
  });

  get tagNames() {
    return this.args.selected.filter((tag) => {
      return typeof tag === 'string';
    });
  }

  async fetchMissingTags() {
    const response = await this.store.query('tag', {
      name: this.tagNames,
      taggableType: this.args.taggableType,
    });
    this.fetchedTags = response.toArray();
  }
}
