import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class SettingsExternalRecruitmentRecruitersNewRoute extends Route {
  @service current;
  @service store;
  @service accessLevels;

  async model() {
    await get(this.current.company, 'recruitingFirms');
    await this.accessLevels.loadAll();
    let recruiter = this.store.createRecord('user', {
      company: this.current.company,
      role: 'external_recruiter',
      accessLevelIds: [
        this.accessLevels.accessLevelWithDefault('external_recruiter').id,
      ],
    });

    return recruiter;
  }

  deactivate() {
    let cModel = this.controller.model.recruiter;
    if (cModel.isNew) {
      cModel.deleteRecord();
    }
  }
}
