import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  attrs: {
    activity: { serialize: false },
    createdAt: { serialize: false },
    note: { serialize: false },
    fileUrl: { serialize: false },
    fileFileName: { serialize: false },
    isFileInfected: { serialize: false },
    internal: { serialize: false },
  },

  serialize() {
    const json = this._super(...arguments);

    if (!json.temporary_file_url) {
      delete json.temporary_file_url;
    }

    delete json.user_id;
    delete json.job_offer_template_id;

    return json;
  },
});
