import Model, {
  attr,
  hasMany,
  belongsTo,
  type SyncHasMany,
} from '@ember-data/model';

export default class UptrailRegionModel extends Model {
  @hasMany('uptrail-region', { async: false, inverse: 'parent' })
  declare children: SyncHasMany<UptrailRegionModel>;

  @belongsTo('uptrail-region', { async: false, inverse: 'children' })
  declare parent: UptrailRegionModel;

  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'uptrail-region': UptrailRegionModel;
  }
}
