import Route from '@ember/routing/route';
import { get, setProperties } from '@ember/object';

const careersiteLayouts = [
  'jobs',
  'jobs-list',
  'cover',
  'people',
  'gallery',
  'video',
];

export default class LayoutRoute extends Route {
  model() {
    return this.modelFor('content.editor.section');
  }

  afterModel(model) {
    let name = get(model, 'name');
    if (get(model, 'name') === 'jobs' && get(model, 'page.isJobPage')) {
      name = 'jobs-list';
    }

    if (careersiteLayouts.indexOf(name) === -1) return;

    const lookupName = `content.editor.section.${name}.layout`;
    setProperties(this, {
      controllerName: lookupName,
      templateName: lookupName,
    });
  }
}
