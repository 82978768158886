import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    auditLog: { serialize: false },
    termsOfServiceSetting: { serialize: false },
    dataPrivacySetting: { serialize: false },
    applyButtonTextDefault: { serialize: false },
    approvalSetting: { serialize: false },
    smsSetting: { serialize: false },
    departmentHeadlineDefault: { serialize: false },
    color: { serialize: false },
    design: { serialize: false },
    draftDesign: { serialize: false },
    paths: { serialize: false },
    blog: { serialize: false },
    customer: { serialize: false },
    completedCareerSite: { serialize: false },
    templateId: { serialize: false },
    instagram: { serialize: false },
    instagramExpired: { serialize: false },
    intercomId: { serialize: false },
    pusherChannels: { serialize: false },
    pickedQuestions: {
      key: 'picked_questions_attributes',
      serialize: 'records',
      deserialize: 'ids',
    },

    subdomainUrl: { serialize: false },
    wallet: { serialize: false },
    autoJoinDomains: {
      key: 'auto_join_domains_attributes',
      serialize: 'records',
      deserialize: 'ids',
    },

    twilio: { serialize: false },
    domain: { serialize: false },
    requisitionSetting: { serialize: false },
    linkedinClientId: { serialize: false },
    enterpriseCalendarEnabled: { serialize: false },
    parentHasEnterpriseCalendar: { serialize: false },
    parentHasActiveSingleSignOn: { serialize: false },
    styleguideUrl: { serialize: false },
    missingPaymentReason: { serialize: false },
  },
});
