import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable require-media-caption }}\n<video\n  id={{@videoId}}\n  class={{@videoClasses}}\n  {{did-insert this.onInsert}}\n  ...attributes\n></video>", {"contents":"{{! template-lint-disable require-media-caption }}\n<video\n  id={{@videoId}}\n  class={{@videoClasses}}\n  {{did-insert this.onInsert}}\n  ...attributes\n></video>","moduleName":"teamtailor/components/video-meeting/video-element.hbs","parseOptions":{"srcName":"teamtailor/components/video-meeting/video-element.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

import { AudioVideoFacade } from 'amazon-chime-sdk-js';
import {
  AteendeeIdsToTilesMap,
  RemoteParticipantScreenShare,
} from 'teamtailor/utils/video-meetings/utils';

interface VideoMeetingVideoElementArgs {
  audioVideoFacade?: AudioVideoFacade;
  tileId?: number;
  shouldUnbindVideoElement?: boolean;
  attendeeId?: string;
  tilesMap?: AteendeeIdsToTilesMap;
  remoteParticipantScreenShare?: RemoteParticipantScreenShare;
}

export default class VideoMeetingVideoElement extends Component<VideoMeetingVideoElementArgs> {
  attendeeId: string | undefined;

  willDestroy(): void {
    super.willDestroy();
    if (this.args.shouldUnbindVideoElement && this.attendeeId) {
      let tileId: number | undefined;

      if (this.args.remoteParticipantScreenShare) {
        tileId = this.args.remoteParticipantScreenShare.tileId;
      } else if (this.args.tilesMap) {
        tileId = this.args.tilesMap[this.attendeeId];
      }

      if (tileId) {
        this.args.audioVideoFacade?.unbindVideoElement(tileId);
      }
    }
  }

  @action
  onInsert() {
    this.attendeeId = this.args.attendeeId;
  }
}
