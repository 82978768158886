export default {
  "grid": "Index__grid_14dh88",
  "sidebar": "Index__sidebar_14dh88",
  "recruit": "Index__recruit_14dh88",
  "relationships": "Index__relationships_14dh88",
  "enterprise": "Index__enterprise_14dh88",
  "security": "Index__security_14dh88",
  "iconBox": "Index__iconBox_14dh88",
  "nav": "Index__nav_14dh88",
  "buttonGroup": "Index__buttonGroup_14dh88",
  "btn": "Index__btn_14dh88",
  "content": "Index__content_14dh88",
  "main": "Index__main_14dh88",
  "body": "Index__body_14dh88",
  "footer": "Index__footer_14dh88",
  "longDescription": "Index__longDescription_14dh88"
};
