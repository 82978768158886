import { inject as service } from '@ember/service';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get, set } from '@ember/object';

export default class GetCreditsRoute extends ScrollToTopRoute {
  @service current;
  @service store;

  setupController(controller, model) {
    super.setupController(controller, model);
    let packages = get(this.current.company, 'wallet.packages');
    set(controller, 'currentPackage', packages[1]);
    set(controller, 'options', packages);
  }

  beforeModel() {
    return get(this.current.company, 'wallet');
  }

  model() {
    let packages = get(this.current.company, 'wallet.packages');
    return this.store.createRecord('credit-transaction', {
      credits: packages[1].credits,
      code: packages[1].code,
    });
  }
}
