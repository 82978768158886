import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  SyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import CompanyModel from './company';
import TranslationCustomFieldModel from './translation/custom-field';
import CustomFieldOptionModel from './custom-field/option';
import { get } from 'teamtailor/utils/get';
import { getFieldByType } from 'teamtailor/constants/custom-fields';
import Server from 'teamtailor/services/server';
import { inject as service } from '@ember/service';

export type FieldType =
  | 'CustomField::Text'
  | 'CustomField::Number'
  | 'CustomField::Select'
  | 'CustomField::MultiSelect'
  | 'CustomField::Checkbox'
  | 'CustomField::Date'
  | 'CustomField::Email'
  | 'CustomField::Phone'
  | 'CustomField::Url';

export default abstract class CustomFieldModel extends Model {
  @service declare server: Server;

  @belongsTo('company')
  declare company: AsyncBelongsTo<CompanyModel>;

  @hasMany('translation/custom-field')
  declare translations: AsyncHasMany<TranslationCustomFieldModel>;

  @hasMany('custom-field/option', { async: false })
  declare options: SyncHasMany<CustomFieldOptionModel>;

  @attr('string') declare name: string;
  @attr('string') declare apiName: string;
  @attr('boolean') declare isGlobal: boolean;
  @attr('boolean', { defaultValue: false }) declare isPrivate: boolean;
  @attr('boolean', { defaultValue: false }) declare isFeatured: boolean;
  @attr('boolean', { defaultValue: true }) declare isSearchable: boolean;
  @attr('boolean', { defaultValue: false }) declare isHidden: boolean;
  @attr('boolean', { defaultValue: false }) declare isExternal: boolean;
  @attr('boolean', { defaultValue: false }) declare isRequired: boolean;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('string', { defaultValue: 'Candidate' })
  declare ownerType: 'Candidate' | 'Job';

  @attr('string') declare type: FieldType;
  @attr('number') declare usageCount: number;

  @attr('string') declare languageCode: string;

  get hasPickedCustomFields() {
    return this.usageCount > 0;
  }

  @attr('number') declare rowOrder: number;
  @attr('number') declare rowOrderPosition: number;

  @attr('boolean', { defaultValue: false }) declare hasValue: boolean;

  get isFilterable() {
    const filterableTypes = ['CustomField::Select'];
    return filterableTypes.includes(this.type);
  }

  get icon() {
    return getFieldByType(this.type)?.icon;
  }

  get translationKey() {
    return getFieldByType(this.type)?.tKey;
  }

  get inputType() {
    return getFieldByType(this.type)?.inputType;
  }

  async getRangeValues() {
    return this.server.memberAction<{ range: [CustomFieldOptionModel] }>(this, {
      action: 'get_range_values',
      method: 'GET',
    });
  }

  tidyOptions() {
    get(this, 'options')
      .toArray()
      .forEach((o) => {
        if (!o.id) {
          o.unloadRecord();
        }
      });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'custom-field': CustomFieldModel;
  }
}
