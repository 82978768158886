import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { get } from 'teamtailor/utils/get';
import {
  ChannelActivationModel,
  ChannelModel,
  PartnerActivationModel,
  PartnerModel,
} from 'teamtailor/models';

export default class SandboxActivationModel extends Model {
  @belongsTo('channel', { async: false })
  channel?: ChannelModel;

  @belongsTo('channel-activation')
  declare channelActivation: AsyncBelongsTo<ChannelActivationModel>;

  @belongsTo('partner') declare partner: AsyncBelongsTo<PartnerModel>;
  @belongsTo('partner-activation')
  declare partnerActivation: AsyncBelongsTo<PartnerActivationModel>;

  @attr('string') declare feedUrl: string;
  @attr('string') declare partnerKey: string;
  @attr('string') declare webhookSignature: string;

  get partnerModel() {
    return get(this, this.partnerType);
  }

  get partnerType() {
    return this.channel ? 'channel' : 'partner';
  }

  get providerKey() {
    return get(this.partnerActivation, 'providerKey');
  }

  get publishStatus() {
    const inMarketplace =
      this.partnerType === 'partner'
        ? get(this.partner, 'inMarketplace')
        : !get(this.channel, 'unlisted');
    return inMarketplace ? 'Published' : 'Not Published';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'sandbox-activation': SandboxActivationModel;
  }
}
