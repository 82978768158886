import { Options } from 'highcharts';

const defaultOptions: Options = {
  chart: {
    height: 250,
    type: 'pie',
  },

  title: {
    text: undefined,
  },

  legend: {
    enabled: false,
  },

  tooltip: {
    headerFormat: '',
    pointFormat:
      '<span style="margin-right: 4px; color:{point.color}">●</span> {point.name}: <b>{point.y}</b><br/>',
  },

  plotOptions: {
    pie: {
      innerSize: '50%',
    },
  },
};

const reportOptions: Options = {
  chart: {
    height: 320,
    type: 'pie',
  },

  title: {
    text: undefined,
  },

  exporting: {
    enabled: false,
  },

  legend: {
    enabled: false,
  },

  credits: {
    enabled: false,
  },

  tooltip: {
    headerFormat: '',
    pointFormat:
      '<span style="margin-right: 4px; color:{point.color}">●</span> {point.name}: <b>{point.y}</b><br/>',
  },

  plotOptions: {
    pie: {
      innerSize: '50%',
      size: 290,
      slicedOffset: 30,
      allowPointSelect: false,
      cursor: 'pointer',
    },
  },
};

const rejectJobReportOptions: Options = {
  chart: {
    height: 250,
    type: 'pie',
  },

  title: {
    text: undefined,
  },

  legend: {
    enabled: false,
  },

  tooltip: {
    headerFormat: '',
    pointFormat:
      '<span style="margin-right: 4px; color:{point.color}">●</span> {point.name}: <b>{point.y}</b><br/>',
  },

  plotOptions: {
    pie: {
      size: '157%',
      innerSize: '50%',
    },
  },
};

const sourcesOptions: Options = {
  chart: {
    height: 210,
    type: 'pie',
  },

  title: {
    text: undefined,
  },

  legend: {
    enabled: false,
  },

  tooltip: {
    headerFormat: '',
    pointFormat:
      '<span style="margin-right: 4px; color:{point.color}">●</span> {point.name}: <b>{point.y}</b><br/>',
  },

  plotOptions: {
    pie: {
      size: '190%',
      innerSize: '50%',
    },
  },
};

const devicesOptions: Options = {
  chart: {
    height: 420,
    width: 420,
    type: 'pie',
  },

  title: {
    text: undefined,
  },

  legend: {
    enabled: false,
  },

  tooltip: {
    headerFormat: '',
    pointFormat:
      '<span style="margin-right: 4px; color:{point.color}">●</span> {point.name}: <b>{point.y}</b><br/>',
  },

  plotOptions: {
    pie: {
      size: '100%',
      innerSize: '50%',
    },
  },
};

const npsJobReportOptions: Options = {
  chart: {
    height: 190,
    width: 185,
    type: 'pie',
  },

  title: {
    text: undefined,
  },

  legend: {
    enabled: false,
  },

  tooltip: {
    headerFormat: '',
    pointFormat:
      '<span style="margin-right: 4px; color:{point.color}">●</span> {point.name}: <b>{point.y}</b><br/>',
  },

  plotOptions: {
    pie: {
      size: '200%',
      innerSize: '50%',
    },
  },
};

export default defaultOptions;
export {
  npsJobReportOptions,
  rejectJobReportOptions,
  sourcesOptions,
  devicesOptions,
  reportOptions,
};
