import { get } from 'teamtailor/utils/get';
import PermissionsRoute from 'teamtailor/routes/permissions';
import { inject as service } from '@ember/service';

export default class NurtureCampaignRoute extends PermissionsRoute {
  @service router;
  @service store;

  requiredPermissions = 'nurture_campaign/update';
  noAccessEntityNameKey = 'nurture_campaigns.nurture_campaign.this_campaign';

  model({ nurture_campaign_id }) {
    return this.store.findRecord('nurture-campaign', nurture_campaign_id);
  }

  afterModel(model, transition) {
    if (get(model, 'isDraft')) {
      if (transition.from) {
        this.router.transitionTo('nurture-campaigns.edit.schedule', model);
      } else {
        this.router.replaceWith('nurture-campaigns.edit.schedule', model);
      }
    }
  }
}
