import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<CompareCandidates::Cell\n  @classNames=\"align-top\"\n  @index={{@index}}\n  @pinnedCount={{@pinnedCount}}\n  @jobApplicationId={{@jobApplication.id}}\n  @offset={{@offset}}\n  @isHidden={{@isHidden}}\n  @isHovered={{@isHovered}}\n  @isContentTruncated={{this.showMoreButton}}\n  @showFullContent={{this.showAllNotes}}\n  @toggleContent={{this.toggleShowAllNotes}}\n>\n  {{#if this.notes.length}}\n\n    {{#if this.showAllNotes}}\n      <div class=\"flex max-h-[20vh] flex-col gap-8 overflow-auto\">\n        {{#each this.notes as |note|}}\n          <CompareCandidates::Note @note={{note}} @truncate={{false}} />\n        {{/each}}\n      </div>\n\n    {{else}}\n      <CompareCandidates::Note @note={{this.firstNote}} @truncate={{true}} />\n    {{/if}}\n\n  {{/if}}\n</CompareCandidates::Cell>", {"contents":"<CompareCandidates::Cell\n  @classNames=\"align-top\"\n  @index={{@index}}\n  @pinnedCount={{@pinnedCount}}\n  @jobApplicationId={{@jobApplication.id}}\n  @offset={{@offset}}\n  @isHidden={{@isHidden}}\n  @isHovered={{@isHovered}}\n  @isContentTruncated={{this.showMoreButton}}\n  @showFullContent={{this.showAllNotes}}\n  @toggleContent={{this.toggleShowAllNotes}}\n>\n  {{#if this.notes.length}}\n\n    {{#if this.showAllNotes}}\n      <div class=\"flex max-h-[20vh] flex-col gap-8 overflow-auto\">\n        {{#each this.notes as |note|}}\n          <CompareCandidates::Note @note={{note}} @truncate={{false}} />\n        {{/each}}\n      </div>\n\n    {{else}}\n      <CompareCandidates::Note @note={{this.firstNote}} @truncate={{true}} />\n    {{/if}}\n\n  {{/if}}\n</CompareCandidates::Cell>","moduleName":"teamtailor/components/compare-candidates/notes-list.hbs","parseOptions":{"srcName":"teamtailor/components/compare-candidates/notes-list.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import JobApplicationModel from 'teamtailor/models/job-application';
import CandidateModel from 'teamtailor/models/candidate';

type NotesListArgs = {
  jobApplication: JobApplicationModel;
};

export default class CompareCandidatesNotesList extends Component<NotesListArgs> {
  @tracked showAllNotes = false;

  @action
  toggleShowAllNotes() {
    this.showAllNotes = !this.showAllNotes;
  }

  get candidate() {
    return this.args.jobApplication.candidate as unknown as CandidateModel;
  }

  get notes() {
    const notes = get(this.candidate, 'notes' as keyof CandidateModel);
    if (!notes) {
      return [];
    }

    return notes.toArray().reverse();
  }

  get firstNote() {
    return this.notes[0];
  }

  get showMoreButton() {
    return (
      this.notes.length > 1 ||
      (this.firstNote && this.firstNote.note.length > 200)
    );
  }
}
