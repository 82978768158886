type DelayOption = {
  value: number | 'custom';
  title: string;
};

export enum DelayUnit {
  Minutes = 'minutes',
  Hours = 'hours',
  Days = 'days',
}

export const delayOptions = [
  { value: 0, title: 'constants.delay.no_delay' },
  { value: 15, title: 'constants.delay.fifteen_minutes' },
  { value: 30, title: 'constants.delay.thirty_minutes' },
  { value: 60, title: 'constants.delay.hour' },
  { value: 90, title: 'constants.delay.hours_and_half' },
  { value: 60 * 2, title: 'constants.delay.two_hours' },
  { value: 60 * 4, title: 'constants.delay.four_hours' },
  { value: 60 * 8, title: 'constants.delay.eight_hours' },
  { value: 60 * 24, title: 'constants.delay.one_day' },
  { value: 60 * 48, title: 'constants.delay.two_days' },
  { value: 'custom', title: 'constants.delay.custom_time' },
] as const satisfies ReadonlyArray<DelayOption>;

export const delayUnitOptions = [
  DelayUnit.Minutes,
  DelayUnit.Hours,
  DelayUnit.Days,
] as const;

export const applyOptions = [
  { value: 'all', title: 'constants.apply.all_candidates' },
  { value: 'ignoreSourced', title: 'constants.apply.exclude_sourced' },
  { value: 'ignoreInternal', title: 'constants.apply.exclude_internal' },
  {
    value: 'ignoreSourcedAndInternal',
    title: 'constants.apply.exclude_sourced_and_internal',
  },
] as const;
