import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<section\n  id={{this.id}}\n  class=\"group relative -mx-[30px] mb-48 px-[30px] [&>.code-block]:rounded-6 [&>div>div>table_td:first-child]:pl-0 [&>div>div>table_th:first-child]:pl-0\"\n  {{did-insert this.mounted}}\n>\n  {{#if (has-block \"title\")}}\n    <a\n      href={{concat \"#\" this.id}}\n      tabindex=\"-1\"\n      aria-hidden=\"true\"\n      class=\"absolute -top-[5px] left-0 hidden p-[5px] group-hover:block\"\n    >\n      <span class=\"font-emoji\">🦄</span>\n    </a>\n    <h2>\n      {{yield to=\"title\"}}\n    </h2>\n  {{/if}}\n\n  {{#if (has-block \"description\")}}\n    <p class=\"body-text\">{{yield to=\"description\"}}</p>\n  {{/if}}\n\n  {{yield to=\"code\"}}\n</section>", {"contents":"<section\n  id={{this.id}}\n  class=\"group relative -mx-[30px] mb-48 px-[30px] [&>.code-block]:rounded-6 [&>div>div>table_td:first-child]:pl-0 [&>div>div>table_th:first-child]:pl-0\"\n  {{did-insert this.mounted}}\n>\n  {{#if (has-block \"title\")}}\n    <a\n      href={{concat \"#\" this.id}}\n      tabindex=\"-1\"\n      aria-hidden=\"true\"\n      class=\"absolute -top-[5px] left-0 hidden p-[5px] group-hover:block\"\n    >\n      <span class=\"font-emoji\">🦄</span>\n    </a>\n    <h2>\n      {{yield to=\"title\"}}\n    </h2>\n  {{/if}}\n\n  {{#if (has-block \"description\")}}\n    <p class=\"body-text\">{{yield to=\"description\"}}</p>\n  {{/if}}\n\n  {{yield to=\"code\"}}\n</section>","moduleName":"teamtailor/components/unicorn/section.hbs","parseOptions":{"srcName":"teamtailor/components/unicorn/section.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface UnicornSectionSignature {
  Args: object;
}

export default class UnicornNavigationComponent extends Component<UnicornSectionSignature> {
  @service declare router: RouterService;

  @tracked
  el: HTMLElement | undefined;

  get id(): string {
    if (this.el !== undefined) {
      let index = 0;
      this.el.parentNode?.querySelectorAll('section').forEach((el, i) => {
        if (el === this.el) {
          index = i;
        }
      });

      return `${this.router.currentRouteName}-${index}`;
    }

    return '';
  }

  @action
  mounted(element: HTMLElement) {
    this.el = element;
  }
}
