import Controller from '@ember/controller';
import { action, get } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { CUSTOM_FORM_CHANNELS } from 'teamtailor/constants/custom-form-channels';

export default class MarketplaceChannelsIndexChannelAlwaysIncludedController extends Controller {
  @service current;
  @service user;
  @service router;
  @service store;

  createChannelActivation = task(async () => {
    await timeout(2000);
    const channel = this.store.peekRecord('channel', this.model.id);
    const channelActivation = get(
      this.current.company,
      'channelActivations'
    ).createRecord({
      activated: true,
      channel,
    });

    channelActivation
      .save()
      .then((savedChannelActivation) =>
        this.redirectAfterSave(channel, savedChannelActivation)
      );
  });

  redirectAfterSave(channel, channelActivation) {
    if (channel.hasCustomSettings) {
      const route = CUSTOM_FORM_CHANNELS.includes(channel.internalName)
        ? 'custom_form_settings'
        : channel.internalName;
      this.router.transitionTo(
        `settings.integrations.channel-activation.${route}`,
        channelActivation
      );
    }
  }

  @action
  handleClose() {
    this.router.transitionTo('marketplace');
  }
}
