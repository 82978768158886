export function jsonToBase64(json) {
  const bytes = encodeURIComponent(JSON.stringify(json)).replace(
    /%([0-9A-F]{2})/g,
    (match, p1) => String.fromCharCode(`0x${p1}`)
  );

  const base64 = btoa(bytes);

  return base64;
}

export function base64ToJson(base64) {
  try {
    const bytes = atob(base64).split('');

    const json = JSON.parse(
      decodeURIComponent(
        bytes
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join('')
      )
    );

    return json;
  } catch (err) {
    const json = JSON.parse(atob(base64));

    return json;
  }
}
