import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Meeting::EventRows::Base\n  @allProblems={{@allProblems}}\n  @event={{@event}}\n  @eventIndex={{@eventIndex}}\n  @icon={{@icon}}\n  @title={{@title}}\n  @pathFilter=\"visibility\"\n>\n  <Core::Select\n    @appearance=\"tertiary\"\n    @options={{this.options}}\n    @optionName=\"label\"\n    @selected={{find-by \"id\" @event.visibility this.options}}\n    @onSelect={{this.onSelect}}\n    @isDisabled={{@readonly}}\n  />\n\n</Meeting::EventRows::Base>", {"contents":"<Meeting::EventRows::Base\n  @allProblems={{@allProblems}}\n  @event={{@event}}\n  @eventIndex={{@eventIndex}}\n  @icon={{@icon}}\n  @title={{@title}}\n  @pathFilter=\"visibility\"\n>\n  <Core::Select\n    @appearance=\"tertiary\"\n    @options={{this.options}}\n    @optionName=\"label\"\n    @selected={{find-by \"id\" @event.visibility this.options}}\n    @onSelect={{this.onSelect}}\n    @isDisabled={{@readonly}}\n  />\n\n</Meeting::EventRows::Base>","moduleName":"teamtailor/components/meeting/event-rows/visibility.hbs","parseOptions":{"srcName":"teamtailor/components/meeting/event-rows/visibility.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { MeetingEventClass } from 'teamtailor/classes/meetings';
import { IMeetingEventVisibility } from 'teamtailor/components/meeting/types';
import { action } from '@ember/object';

type Value = {
  id: IMeetingEventVisibility;
  label: string;
};

interface Args {
  event: MeetingEventClass;
}

export default class VisibilityComponent extends Component<Args> {
  @service declare intl: IntlService;

  get options(): [Value, Value, Value] {
    return [
      {
        id: 'default',
        label: this.intl.t(
          'components.meetings.event_scheduler.visibility_options.default'
        ),
      },
      {
        id: 'no_candidate_names_in_invite',
        label: this.intl.t(
          'components.meetings.event_scheduler.visibility_options.no_candidate_names_in_invite'
        ),
      },
      {
        id: 'private',
        label: this.intl.t(
          'components.meetings.event_scheduler.visibility_options.private'
        ),
      },
    ];
  }

  @action
  onSelect(value: Value) {
    this.args.event.visibility = value.id;
  }
}
