import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Form::Field\n  class=\"mb-32\"\n  @as=\"core/select\"\n  @size=\"large\"\n  @searchField=\"name\"\n  @selected={{this.selectedFilterDepartment}}\n  @options={{this.current.company.sortedDepartments}}\n  @optionName=\"name\"\n  @onSelect={{this.handleSetDepartment}}\n  @placeholder={{t \"search.department.all_departments\"}}\n  @searchPlaceholder={{t \"search.department.search_department\"}}\n  @isDisabled={{this.isSelectDisabled}}\n/>", {"contents":"<Core::Form::Field\n  class=\"mb-32\"\n  @as=\"core/select\"\n  @size=\"large\"\n  @searchField=\"name\"\n  @selected={{this.selectedFilterDepartment}}\n  @options={{this.current.company.sortedDepartments}}\n  @optionName=\"name\"\n  @onSelect={{this.handleSetDepartment}}\n  @placeholder={{t \"search.department.all_departments\"}}\n  @searchPlaceholder={{t \"search.department.search_department\"}}\n  @isDisabled={{this.isSelectDisabled}}\n/>","moduleName":"teamtailor/components/section-connect-form.hbs","parseOptions":{"srcName":"teamtailor/components/section-connect-form.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import { get } from 'teamtailor/utils/get';

export default class SectionConnectForm extends Component {
  @service current;
  @service store;

  get section() {
    return this.args.model;
  }

  get isSelectDisabled() {
    return get(this, 'section.department.id');
  }

  get selectedFilterDepartment() {
    if (!this.section.pickedDepartmentId) return undefined;
    return this.store.findRecord('department', this.section.pickedDepartmentId);
  }

  @action
  handleSetDepartment(department) {
    const departmentId = department ? department.id : null;
    set(this, 'section.pickedDepartmentId', departmentId);
  }

  @action
  didInsertForm() {
    if (
      !this.section.pickedDepartmentId &&
      get(this, 'section.department.id')
    ) {
      set(
        this,
        'section.pickedDepartmentId',
        get(this, 'section.department.id')
      );
    }
  }
}
