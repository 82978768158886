import { getRobohashUrl } from 'teamtailor/utils/robohash';
import Component from '@glimmer/component';

export default class EmployeeDashboardUserImageBaseComponent extends Component {
  get userImageUrl() {
    return this.user.picture.appUserAvatar.url;
  }

  get imageUrl() {
    return this.userImageUrl ? this.userImageUrl : getRobohashUrl(this.user.id);
  }
}
