import Model, { belongsTo, hasMany, attr } from '@ember-data/model';

export default class UserTemplateModel extends Model {
  @belongsTo company;
  @hasMany('defaultNotificationConfig') defaultNotificationConfigs;

  @attr('string') role;
  @attr('raw', { defaultValue: () => ['no_access'] }) accessLevels;
  @attr('boolean') visible;
  @attr('boolean') hideEmail;
  @attr('boolean') hidePhone;
  @attr('string') signature;
  @attr('string') locale;
  @attr('string') timeFormat;
  @attr('string') timeZone;
  @attr('string') startOfWeekDay;

  get showEmail() {
    return !this.hideEmail;
  }

  get showPhone() {
    return !this.hidePhone;
  }

  set showEmail(value) {
    this.hideEmail = !value;
  }

  set showPhone(value) {
    this.hidePhone = !value;
  }
}
