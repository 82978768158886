import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { get, set } from '@ember/object';

export default class NurtureCampaignsRoute extends Route {
  @service pusher;
  @service user;
  @service store;

  channel = null;

  activate() {
    get(this, 'pusher')
      .channel(get(this, 'user.notificationsChannel'))
      .then((channel) => {
        set(this, 'channel', channel);
        channel.bind('nurture-campaign-updated', (nurtureCampaignId) => {
          this.store.peekRecord('nurture-campaign', nurtureCampaignId);
        });
      });
  }

  deactivate() {
    let channel = get(this, 'channel');

    if (channel) {
      channel.unbind('nurture-campaign-updated');
    }
  }
}
