const Messages = {
  inclusion: JSON.stringify({
    key: 'validations.inclusion',
    args: { description: '{description}' },
  }),

  exclusion: JSON.stringify({
    key: 'validations.exclusion',
    args: { description: '{description}' },
  }),

  invalid: JSON.stringify({
    key: 'validations.invalid',
    args: { description: '{description}' },
  }),

  confirmation: JSON.stringify({
    key: 'validations.confirmation',
    args: {
      description: '{description}',
      on: '{on}',
    },
  }),

  accepted: JSON.stringify({
    key: 'validations.accepted',
    args: { description: '{description}' },
  }),

  email: JSON.stringify({
    key: 'validations.email',
    args: { description: '{description}' },
  }),

  emailTaken: JSON.stringify({
    key: 'validations.emailTaken',
    args: { description: '{description}' },
  }),

  empty: JSON.stringify({
    key: 'validations.empty',
    args: { description: '{description}' },
  }),

  blank: JSON.stringify({
    key: 'validations.blank',
    args: { description: '{description}' },
  }),

  present: JSON.stringify({
    key: 'validations.present',
    args: { description: '{description}' },
  }),

  collection: JSON.stringify({
    key: 'validations.collection',
    args: { description: '{description}' },
  }),

  singular: JSON.stringify({
    key: 'validations.singular',
    args: { description: '{description}' },
  }),

  tooLong: JSON.stringify({
    key: 'validations.tooLong',
    args: {
      description: '{description}',
      max: '{max}',
    },
  }),

  tooShort: JSON.stringify({
    key: 'validations.tooLong',
    args: {
      description: '{description}',
      min: '{min}',
    },
  }),

  between: JSON.stringify({
    key: 'validations.between',
    args: {
      description: '{description}',
      min: '{min}',
      max: '{max}',
    },
  }),

  before: JSON.stringify({
    key: 'validations.before',
    args: {
      description: '{description}',
      before: '{before}',
    },
  }),

  onOrBefore: JSON.stringify({
    key: 'validations.onOrBefore',
    args: {
      description: '{description}',
      onOrBefore: '{onOrBefore}',
    },
  }),

  after: JSON.stringify({
    key: 'validations.after',
    args: {
      description: '{description}',
      after: '{after}',
    },
  }),

  onOrAfter: JSON.stringify({
    key: 'validations.onOrAfter',
    args: {
      description: '{description}',
      onOrAfter: '{onOrAfter}',
    },
  }),

  wrongDateFormat: JSON.stringify({
    key: 'validations.wrongDateFormat',
    args: {
      description: '{description}',
      format: '{format}',
    },
  }),

  wrongLength: JSON.stringify({
    key: 'validations.wrongLength',
    args: {
      description: '{description}',
      is: '{is}',
    },
  }),

  notANumber: JSON.stringify({
    key: 'validations.notANumber',
    args: { description: '{description}' },
  }),

  notAnInteger: JSON.stringify({
    key: 'validations.notAnInteger',
    args: { description: '{description}' },
  }),

  greaterThan: JSON.stringify({
    key: 'validations.greaterThan',
    args: {
      description: '{description}',
      gt: '{gt}',
    },
  }),

  greaterThanOrEqualTo: JSON.stringify({
    key: 'validations.greaterThanOrEqualTo',
    args: {
      description: '{description}',
      gte: '{gte}',
    },
  }),

  equalTo: JSON.stringify({
    key: 'validations.equalTo',
    args: {
      description: '{description}',
      is: '{is}',
    },
  }),

  lessThan: JSON.stringify({
    key: 'validations.lessThan',
    args: {
      description: '{description}',
      lt: '{lt}',
    },
  }),

  lessThanOrEqualTo: JSON.stringify({
    key: 'validations.lessThanOrEqualTo',
    args: {
      description: '{description}',
      lte: '{lte}',
    },
  }),

  otherThan: JSON.stringify({
    key: 'validations.otherThan',
    args: {
      description: '{description}',
      value: '{value}',
    },
  }),

  odd: JSON.stringify({ key: 'validations.odd', description: '{description}' }),

  even: JSON.stringify({
    key: 'validations.even',
    args: { description: '{description}' },
  }),

  positive: JSON.stringify({
    key: 'validations.positive',
    args: { description: '{description}' },
  }),

  multipleOf: JSON.stringify({
    key: 'validations.multipleOf',
    args: {
      description: '{description}',
      multipleOf: '{multipleOf}',
    },
  }),

  date: JSON.stringify({
    key: 'validations.date',
    args: { description: '{description}' },
  }),

  phone: JSON.stringify({
    key: 'validations.phone',
    args: { description: '{description}' },
  }),

  url: JSON.stringify({
    key: 'validations.url',
    args: { description: '{description}' },
  }),

  unique: JSON.stringify({
    key: 'validations.unique',
    args: { description: '{description}' },
  }),

  regex: JSON.stringify({
    key: 'validations.regex',
    args: { description: '{description}' },
  }),

  optionRequired: JSON.stringify({
    key: 'validations.optionRequired',
  }),
};

export default Messages;
