import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { get } from 'teamtailor/utils/get';
import SingleSignOnModel from 'teamtailor/models/single-sign-on';

export default class SsoUserAttributeMappingsModel extends Model {
  @attr('date') declare createdAt: string;
  @attr('date') declare updatedAt: string;

  @attr('string') declare sourceKey: string;
  @attr('string') declare sourceValue: string;
  @attr('string') declare targetKey: string;
  @attr('string') declare targetValue: string;

  @belongsTo('single-sign-on')
  declare singleSignOn: AsyncBelongsTo<SingleSignOnModel>;

  get targetKeyTranslation(): string | undefined {
    const attributes = get(this.singleSignOn, 'availableUserAttributes');

    return attributes.findBy('key', this.targetKey)?.translation;
  }

  get targetValueTranslation(): string | undefined {
    const attributes = get(this.singleSignOn, 'availableUserAttributes');

    return attributes
      .findBy('key', this.targetKey)
      ?.values?.findBy('value', this.targetValue)?.translation;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'sso-user-attribute-mapping': SsoUserAttributeMappingsModel;
  }
}
