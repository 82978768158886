import Model, { AsyncBelongsTo, belongsTo, attr } from '@ember-data/model';
import { get } from 'teamtailor/utils/get';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import {
  isSameDay,
  startOfDay,
  today,
  tomorrow,
} from 'teamtailor/utils/due-date';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';
import TodoCountersService from 'teamtailor/services/todo-counters';
import {
  CandidateModel,
  JobModel,
  JobApplicationModel,
  UserModel,
  TodoTemplateModel,
} from 'teamtailor/models';

const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD');

export default class TodoModel extends Model {
  @service declare todoCounters: TodoCountersService;

  @belongsTo('candidate') declare candidate: AsyncBelongsTo<CandidateModel>;
  @belongsTo('user', { inverse: 'assignedTodos' })
  declare assignee: AsyncBelongsTo<UserModel>;

  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;
  @belongsTo('job') declare job: AsyncBelongsTo<JobModel>;
  @belongsTo('job-application')
  declare jobApplication: AsyncBelongsTo<JobApplicationModel>;

  @belongsTo('todo-template')
  declare todoTemplate: AsyncBelongsTo<TodoTemplateModel>;

  @attr('string') declare value: string;
  @attr('string') declare description: string;
  @attr('date') declare completedAt: Date | null;
  @attr('string') declare due: string | null;

  @attr('boolean') declare _destroy: boolean;
  @attr('number') declare relativeDueDays: number;

  @tracked createdNow = false;
  @tracked completedRecently = false;

  get completed() {
    return !!this.completedAt;
  }

  get hasDueDate() {
    return !!this.due;
  }

  get dueGroup() {
    if (this.completed && !this.completedRecently) {
      return 'completed';
    }

    const due = startOfDay(this.due);
    if (due.getTime() <= today.getTime()) {
      return 'today';
    } else if (isSameDay(due, tomorrow)) {
      return 'tomorrow';
    }

    return 'upcoming';
  }

  async toggleCompletion() {
    if (this.completed) {
      this.completedAt = null;
      if (this.completedRecently) {
        this.completedRecently = false;
      }
    } else {
      this.createdNow = false;
      this.completedAt = new Date();
      this.completedRecently = true;

      later(() => {
        this.completedRecently = false;
      }, 3000);
    }

    await this.save();
    this.todoCounters.handleToggledTodo(this);
  }

  setDueDate(dueDate: Date) {
    const previousDueGroup = get(this, 'dueGroup');
    this.due = formatDate(dueDate);
    const newDueGroup = get(this, 'dueGroup');
    if (previousDueGroup !== newDueGroup) {
      this.todoCounters.modifyDueGroupCount(previousDueGroup, -1);
      this.todoCounters.modifyDueGroupCount(newDueGroup, 1);
    }

    return this.save();
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    todo: TodoModel;
  }
}
