import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"tt-form-group\"\n  {{did-insert this.didInsert}}\n  {{did-update this.handleLanguageChange @selectedLanguageId}}\n>\n  <Core::Form::Field\n    @label={{concat (t \"jobs.edit.posting.template.title\") \" *\"}}\n    @popover={{t \"jobs.edit.posting.template.description\"}}\n    as |field|\n  >\n    <field.Select\n      @selected={{@adTemplate}}\n      @options={{this.adTemplates}}\n      @onSelect={{@handleAdTemplateChange}}\n      @optionName=\"title\"\n      @size=\"large\"\n      as |Item option|\n    >\n      <Item\n        @text={{concat\n          option.title\n          (if option.isDefault (concat \" (\" (t \"common.default\") \")\"))\n        }}\n      />\n    </field.Select>\n  </Core::Form::Field>\n</div>", {"contents":"<div\n  class=\"tt-form-group\"\n  {{did-insert this.didInsert}}\n  {{did-update this.handleLanguageChange @selectedLanguageId}}\n>\n  <Core::Form::Field\n    @label={{concat (t \"jobs.edit.posting.template.title\") \" *\"}}\n    @popover={{t \"jobs.edit.posting.template.description\"}}\n    as |field|\n  >\n    <field.Select\n      @selected={{@adTemplate}}\n      @options={{this.adTemplates}}\n      @onSelect={{@handleAdTemplateChange}}\n      @optionName=\"title\"\n      @size=\"large\"\n      as |Item option|\n    >\n      <Item\n        @text={{concat\n          option.title\n          (if option.isDefault (concat \" (\" (t \"common.default\") \")\"))\n        }}\n      />\n    </field.Select>\n  </Core::Form::Field>\n</div>","moduleName":"teamtailor/components/jobs/ad-template-form.hbs","parseOptions":{"srcName":"teamtailor/components/jobs/ad-template-form.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import Store from '@ember-data/store';
import PageModel from 'teamtailor/models/page';
import { restartableTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

type AdTemplateFormArgs = {
  selectedLanguageId?: string;
  handleAdTemplateChange: (adTemplate?: PageModel) => void;
  adTemplate?: PageModel;
  adTemplateId?: string;
  isJobTemp: boolean;
};

export default class JobsAdTemplateFormComponent extends Component<AdTemplateFormArgs> {
  @service declare current: Current;
  @service declare store: Store;

  @tracked adTemplates: PageModel[] = [];
  @tracked initialLanguage = true;

  get defaultTemplate(): PageModel | undefined {
    return this.adTemplates.findBy('isDefault', true);
  }

  setAdTemplate(): void {
    if (
      !this.initialLanguage ||
      (this.args.isJobTemp && !this.args.adTemplateId)
    ) {
      this.args.handleAdTemplateChange(this.defaultTemplate);
    }
  }

  fetchAdTemplates = restartableTask(async () => {
    const pages: PageModel[] = await this.store.query('page', {
      career_site_id: this.args.selectedLanguageId,
      view: 'templates',
    });

    this.adTemplates = pages.filterBy('isActive', true).sortBy('createdAt');

    this.setAdTemplate();
    this.initialLanguage = false;
  });

  @action
  didInsert(): void {
    this.fetchAdTemplates.perform();
  }

  @action
  handleLanguageChange(): void {
    this.fetchAdTemplates.perform();
  }
}
