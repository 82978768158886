import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class FormBlocksBaseComponent extends Component {
  @service intl;
  @service iframePreview;

  get company() {
    return this.current.company;
  }

  get model() {
    return this.args.model;
  }

  @action
  async saveAndReload() {
    if (this.model.hasDirtyAttributes) {
      await this.model.save();
      this.reload();
    }
  }

  reload() {
    if (this.model.enabled) {
      this.iframePreview.reloadSection(this.model.id);
    } else {
      this.iframePreview.removeSection(this.model.id);
    }
  }

  @action
  async handleAttributeChange() {
    await this.saveAndReload();
  }
}
