import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n  <AnimatedContainer>\n    {{#animated-if @showAddTodo duration=250 use=this.transition}}\n      <Blur::Footer local-class=\"root\">\n        <div local-class=\"content\">\n          <Todo::Edit\n            local-class=\"addTodo\"\n            @onCancel={{@onHideAddTodo}}\n            @onSubmit={{@onSubmit}}\n            @asCreateForm={{true}}\n          />\n        </div>\n      </Blur::Footer>\n    {{/animated-if}}\n  </AnimatedContainer>\n  <Toolbox::Todos::Toggle\n    local-class=\"toggle\n    {{if @showAddTodo 'fadeOut' 'fadeIn'}}\"\n    @scopeFilters={{@scopeFilters}}\n    @selectedSortingOrder={{@selectedSortingOrder}}\n    @selectedScopeFilter={{@selectedScopeFilter}}\n    @showCompleted={{@showCompleted}}\n    {{did-update @onScopeUpdated false @selectedScopeFilter}}\n  />\n</div>", {"contents":"<div>\n  <AnimatedContainer>\n    {{#animated-if @showAddTodo duration=250 use=this.transition}}\n      <Blur::Footer local-class=\"root\">\n        <div local-class=\"content\">\n          <Todo::Edit\n            local-class=\"addTodo\"\n            @onCancel={{@onHideAddTodo}}\n            @onSubmit={{@onSubmit}}\n            @asCreateForm={{true}}\n          />\n        </div>\n      </Blur::Footer>\n    {{/animated-if}}\n  </AnimatedContainer>\n  <Toolbox::Todos::Toggle\n    local-class=\"toggle\n    {{if @showAddTodo 'fadeOut' 'fadeIn'}}\"\n    @scopeFilters={{@scopeFilters}}\n    @selectedSortingOrder={{@selectedSortingOrder}}\n    @selectedScopeFilter={{@selectedScopeFilter}}\n    @showCompleted={{@showCompleted}}\n    {{did-update @onScopeUpdated false @selectedScopeFilter}}\n  />\n</div>","moduleName":"teamtailor/components/toolbox/todos/footer.hbs","parseOptions":{"srcName":"teamtailor/components/toolbox/todos/footer.hbs"}});
import Component from '@glimmer/component';
import move from 'ember-animated/motions/move';
import { easeOut, easeIn } from 'ember-animated/easings/cosine';

export default class extends Component {
  *transition({ insertedSprites, removedSprites }) {
    for (let sprite of removedSprites) {
      sprite.applyStyles({ 'z-index': '8' });
      sprite.endAtPixel({
        x: sprite.absoluteInitialBounds.x,
        y: sprite.absoluteInitialBounds.y + window.outerHeight,
      });
      yield move(sprite, { easing: easeIn });
    }

    for (let sprite of insertedSprites) {
      sprite.applyStyles({ 'z-index': '10' });
      sprite.startAtPixel({
        x: sprite.absoluteFinalBounds.x,
        y: sprite.absoluteFinalBounds.y + window.outerHeight,
      });
      yield move(sprite, { easing: easeOut });
    }
  }
}
