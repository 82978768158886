export const DEFAULT_SIZE = '8';
export const DEFAULT_POSITION = '-1';
export const ALLOWED_SIZES = ['6', DEFAULT_SIZE, '10', '12', '14'] as const;

export const VALUES_BASED_ON_USER_IMAGE_SIZE = {
  '20': { size: '6', position: '-1' },
  '24': { size: '8', position: '-1' },
  '28': { size: '8', position: '-1' },
  '32': { size: '10', position: '-2' },
  '36': { size: '10', position: '-2' },
  '48': { size: '12', position: '-2' },
  '64': { size: '12', position: '-2' },
  '80': { size: '12', position: '-2' },
  '96': { size: '14', position: '-2' },
  '136': { size: '14', position: '-2' },
  '160': { size: '14', position: '-2' },
};

export type AllowedSizes = (typeof ALLOWED_SIZES)[number];
