import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.nextItem}}\n  <div class=\"job-form-next mb-48\">\n    <LinkTo\n      @route={{concat \"jobs.edit.\" this.nextItem.route}}\n      data-test-job-edit-next-button\n    >\n      <span>\n        <small>\n          {{t \"common.next_step\"}}\n        </small>\n        {{t this.nextItem.nextItemKey}}\n      </span>\n      <Icon @icon=\"circle-chevron-right\" @size=\"24\" />\n    </LinkTo>\n  </div>\n{{/if}}", {"contents":"{{#if this.nextItem}}\n  <div class=\"job-form-next mb-48\">\n    <LinkTo\n      @route={{concat \"jobs.edit.\" this.nextItem.route}}\n      data-test-job-edit-next-button\n    >\n      <span>\n        <small>\n          {{t \"common.next_step\"}}\n        </small>\n        {{t this.nextItem.nextItemKey}}\n      </span>\n      <Icon @icon=\"circle-chevron-right\" @size=\"24\" />\n    </LinkTo>\n  </div>\n{{/if}}","moduleName":"teamtailor/components/jobs/next-link.hbs","parseOptions":{"srcName":"teamtailor/components/jobs/next-link.hbs"}});
import Component from '@glimmer/component';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { JobsEditNavigationItem } from 'teamtailor/utils/jobs-edit-navigation-item';

interface JobsNextLinkComponentArgs {
  items: JobsEditNavigationItem[];
}

export default class JobsNextLinkComponent extends Component<JobsNextLinkComponentArgs> {
  @service declare router: RouterService;

  get nextItem(): JobsEditNavigationItem | undefined {
    const currentItemIndex = this.args.items.findIndex((item) => {
      const possibleRoutes = [
        `jobs.edit.${item.route}`,
        `jobs.edit.${item.route}.index`,
      ];

      return possibleRoutes.includes(this.router.currentRouteName);
    });

    return currentItemIndex > -1
      ? this.args.items[currentItemIndex + 1]
      : undefined;
  }
}
