import { v1 as uuid } from 'ember-uuid';
import { camelize, underscore } from '@ember/string';

export const OTHER_OPTION = '__OTHER__';

export const DEFAULT_SCALE_MAX_VALUE = 5;

export const SCALE_OPTIONS = [
  {
    value: 5,
    name: '1-5',
  },
  {
    value: 10,
    name: '1-10',
  },
];

export const SCALE_LABEL_TYPES = {
  START: 'start',
  END: 'end',
};

export const FORM_TYPE_MAP = {
  radio: 'FormQuestion::Choice',
  checkbox: 'FormQuestion::Checkbox',
  text: 'FormQuestion::Text',
  textarea: 'FormQuestion::TextArea',
  number: 'FormQuestion::Number',
  date: 'FormQuestion::Date',
  scale: 'FormQuestion::Scale',
};

export function questionsToFormFields(questions, includeId = true) {
  return questions.map((q, index) => {
    return {
      ...(q.id && includeId ? { id: q.id } : {}),
      ...(q.formAnswers
        ? { answers: formAnswersToAnswers(q.formAnswers, includeId) }
        : []),

      uuid: q.uuid,
      label: q.text,
      sortIndex: parseInt(q.sortIndex || q.sort_index || index),
      type: Object.keys(FORM_TYPE_MAP).find(
        (key) => FORM_TYPE_MAP[key] === q.type
      ),

      options: [
        ...(q.schema?.alternatives?.map((a) => ({
          id: a.id || uuid(),
          label: a.title,
        })) || []),
        ...(q.schema?.other
          ? [
              {
                id: OTHER_OPTION,
                label: 'Other...',
              },
            ]
          : []),
      ],

      ...(q.schema?.show_if ? { show_if: q.schema.show_if } : {}),
      ...(q.schema?.maximum_value
        ? { maximum_value: q.schema.maximum_value }
        : {}),

      ...(q.schema?.beginning_label
        ? { beginning_label: q.schema.beginning_label }
        : {}),

      ...(q.schema?.end_label ? { end_label: q.schema.end_label } : {}),
      ...(q.isRequired !== undefined ? { required: q.isRequired } : {}),
      ...(q.isScaleType ? { scaleChangeDisabled: q.hasAnswers } : {}),
    };
  });
}

const formAnswersToAnswers = (formAnswers, includeId = true) => {
  return formAnswers?.map((answer) => {
    const answerObj = {};
    answer = answer.serialize({ includeId });
    Object.keys(answer).forEach(
      (key) => (answerObj[camelize(key)] = answer[key])
    );
    return answerObj;
  });
};

export const formFieldsToQuestions = (fields) => {
  return fields?.map((q, index) => {
    return {
      ...(q.uuid ? { uuid: q.uuid } : {}),
      ...(q.id ? { id: q.id } : {}),
      ...(q.required !== undefined ? { is_required: q.required } : {}),
      ...(q.answers
        ? { form_answers_attributes: answersToFormAnswers(q.answers) }
        : {}),

      sort_index: q.sortIndex || index,
      text: q.label,
      type: FORM_TYPE_MAP[q.type] || 'FormQuestion::Text',
      schema: {
        alternatives: q.options
          ?.filter((value) => value.id !== OTHER_OPTION)
          .map((option) => ({
            id: option.id,
            title: option.label,
          })),

        ...(q.options?.map((q) => q.id).includes(OTHER_OPTION)
          ? {
              other: {
                title: q.options.find((q) => q.id === OTHER_OPTION).label,
              },
            }
          : {}),

        ...(q.show_if ? { show_if: q.show_if } : {}),
        ...(q.maximum_value ? { maximum_value: q.maximum_value } : {}),
        ...(q.beginning_label ? { beginning_label: q.beginning_label } : {}),
        ...(q.end_label ? { end_label: q.end_label } : {}),
      },
    };
  });
};

const answersToFormAnswers = (answers) => {
  return answers?.map((answer) => {
    const answerObj = {};
    Object.keys(answer).forEach(
      (key) => (answerObj[underscore(key)] = answer[key])
    );
    return answerObj;
  });
};

export function requisitionFormToFormBuilderFields(form) {
  const stringsToObjs = (options) => {
    const isStringList = typeof options[0] === 'string';

    if (!isStringList) return options;

    return options.map((o) => ({
      id: uuid(),
      label: o,
    }));
  };

  const convertShowIf = (show_if, newForm) => {
    const refUuid = show_if.ref_uuid || show_if.ref_id;
    const refOldField = form.find(
      (f) => f.uuid === refUuid || f.id === refUuid
    );
    const refNewField = newForm.find((f) => f.uuid === refUuid);

    // options are list of strings, instead of list of objects
    const needsConversion = typeof refOldField?.options[0] === 'string';
    const option = refNewField?.options.find((o) => o.label === show_if.value);

    if (!option) return {};

    const value = needsConversion ? option.id : show_if.value;

    return {
      show_if: {
        ref_uuid: refUuid,
        value,
      },
    };
  };

  if (!form) return null;

  let newForm = form.map((q) => ({
    ...q,
    uuid: q.uuid || q.id,
    ...(q.options ? { options: stringsToObjs(q.options) } : {}),
  }));

  let newFormWithConditionals = newForm.map((q) => ({
    ...q,
    ...(q.show_if ? convertShowIf(q.show_if, newForm) : {}),
  }));

  return newFormWithConditionals;
}
