import Helper from '@ember/component/helper';
import {
  BADGE_COLOR_MAP,
  DEFAULT_BADGE_COLOR,
} from 'teamtailor/constants/badge-colors';

const VALID_BADGE_KEYS = Object.keys(BADGE_COLOR_MAP);

export function getBadgeColor(key) {
  if (VALID_BADGE_KEYS.includes(key)) {
    return BADGE_COLOR_MAP[key];
  } else {
    return DEFAULT_BADGE_COLOR;
  }
}

export default Helper.helper(function (params) {
  return getBadgeColor(...params);
});
