import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{t\n  \"components.activity_item.reviewed\"\n  userName=(or this.activity.user.nameOrEmail (t \"common.deleted_user\"))\n  htmlSafe=true\n}}\n\n{{#if (or this.job this.isProfile)}}\n  <ActivityItem::CandidateLink\n    @activity={{this.activity}}\n    @anonymous={{this.anonymous}}\n    @isProfile={{this.isProfile}}\n    @job={{this.job}}\n    @tab={{\"review-feed\"}}\n  />\n{{/if}}\n<StarRatings @value={{this.activity.actionData.rating}} @size=\"20\" />\n{{#if this.hasReviewComment}}\n  <blockquote class=\"mt-8 border-l-[3px] border-neutral-medium pl-8\">\n    {{simple-format this.activity.actionData.comment}}\n  </blockquote>\n{{/if}}", {"contents":"{{t\n  \"components.activity_item.reviewed\"\n  userName=(or this.activity.user.nameOrEmail (t \"common.deleted_user\"))\n  htmlSafe=true\n}}\n\n{{#if (or this.job this.isProfile)}}\n  <ActivityItem::CandidateLink\n    @activity={{this.activity}}\n    @anonymous={{this.anonymous}}\n    @isProfile={{this.isProfile}}\n    @job={{this.job}}\n    @tab={{\"review-feed\"}}\n  />\n{{/if}}\n<StarRatings @value={{this.activity.actionData.rating}} @size=\"20\" />\n{{#if this.hasReviewComment}}\n  <blockquote class=\"mt-8 border-l-[3px] border-neutral-medium pl-8\">\n    {{simple-format this.activity.actionData.comment}}\n  </blockquote>\n{{/if}}","moduleName":"teamtailor/components/activity-item/review.hbs","parseOptions":{"srcName":"teamtailor/components/activity-item/review.hbs"}});
import { get } from 'teamtailor/utils/get';
import Base from './base';

export default class ReviewComponent extends Base {
  get hasReviewComment() {
    return !!get(this.activity, 'actionData.comment');
  }
}
