import { Options } from 'highcharts';
import colors from 'teamtailor/components/insights/charts/colors';

export const defaultOptions: Options = {
  plotOptions: {
    series: {
      shadow: false,
    },
  },

  global: {
    timezoneOffset: new Date().getTimezoneOffset(),
  },

  credits: {
    enabled: false,
  },
};

defaultOptions.colors = colors;

defaultOptions.chart = {
  backgroundColor: 'transparent',
  style: {
    fontFamily:
      '"Nunito-Light", AvenirNext-Regular, "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;',

    fontSize: '14px',
  },
};

defaultOptions.xAxis = {
  lineColor: '#78868E',
  labels: {
    style: {
      color: '#78868E',
    },
  },
};

defaultOptions.yAxis = {
  lineWidth: 1,
  lineColor: 'transparent',
  gridLineColor: '#d2dddf',
  title: {
    style: {
      color: '#78868E',
    },
  },

  labels: {
    style: {
      color: '#78868E',
    },
  },
};

defaultOptions.title = {
  text: undefined,
};

defaultOptions.plotOptions = {
  bar: {
    groupPadding: 0.2,
  },

  areaspline: {
    marker: {
      enabled: false,
      symbol: 'circle',
      radius: 2,
      states: {
        hover: {
          enabled: true,
        },
      },
    },

    fillColor: {
      linearGradient: { x1: 0, y1: 0, x2: 0, y2: 400 },
      stops: [
        [0, colors[11]],
        [1, colors[12]],
      ],
    },

    lineColor: '#ffffff',
    lineWidth: 2,
  },

  pie: {
    borderColor: 'transparent',
    dataLabels: {
      enabled: false,
    },

    showInLegend: true,
    slicedOffset: 30,
    size: 230,
    borderRadius: 0,
  },

  scatter: {
    marker: {
      symbol: 'circle',
      radius: 5,
    },
  },

  series: {
    states: {
      hover: {
        lineWidthPlus: 0,
      },
    },
  },
};

defaultOptions.legend = {
  align: 'left',
  x: 100,
  symbolRadius: 6,
  itemStyle: {
    color: '#78868E',
  },
};

defaultOptions.tooltip = {
  backgroundColor: '#314253',
  borderColor: '#314253',
  shadow: false,
  style: {
    color: '#fff',
    padding: '10',
    fontWeight: 'bold',
  },
};
