import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<@table.row\n  @route={{if (has-permission \"form/results\") \"surveys.results\" \"surveys.edit\"}}\n  @id={{@survey.id}}\n  as |row|\n>\n  <row.column>\n    {{@survey.name}}\n    {{#if @survey.archived}}\n      <Core::Badge @text={{t \"common.archived\"}} as |b|>\n        <b.icon @icon=\"trash-can\" />\n      </Core::Badge>\n    {{/if}}\n  </row.column>\n  <row.column>\n    <Core::Badge\n      @text={{if @survey.open (t \"surveys.open\") (t \"surveys.anonymous\")}}\n    />\n  </row.column>\n  <row.column>\n    {{@survey.sends}}\n  </row.column>\n  <row.column>\n    {{@survey.responses}}\n  </row.column>\n  <row.column>\n    {{concat this.responseRate \"%\"}}\n  </row.column>\n</@table.row>", {"contents":"<@table.row\n  @route={{if (has-permission \"form/results\") \"surveys.results\" \"surveys.edit\"}}\n  @id={{@survey.id}}\n  as |row|\n>\n  <row.column>\n    {{@survey.name}}\n    {{#if @survey.archived}}\n      <Core::Badge @text={{t \"common.archived\"}} as |b|>\n        <b.icon @icon=\"trash-can\" />\n      </Core::Badge>\n    {{/if}}\n  </row.column>\n  <row.column>\n    <Core::Badge\n      @text={{if @survey.open (t \"surveys.open\") (t \"surveys.anonymous\")}}\n    />\n  </row.column>\n  <row.column>\n    {{@survey.sends}}\n  </row.column>\n  <row.column>\n    {{@survey.responses}}\n  </row.column>\n  <row.column>\n    {{concat this.responseRate \"%\"}}\n  </row.column>\n</@table.row>","moduleName":"teamtailor/components/surveys/row.hbs","parseOptions":{"srcName":"teamtailor/components/surveys/row.hbs"}});
import Component from '@glimmer/component';

export default class SurveysRow extends Component {
  get responseRate() {
    const { sends, responses } = this.args.survey;

    if (sends > 0) {
      return Math.round((100 * responses) / sends);
    }

    return 0;
  }
}
