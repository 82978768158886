import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { set, action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { later } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';

export default class CookiePolicyController extends Controller {
  @service intl;
  @service current;
  @service flashMessages;

  @tracked edit = false;

  get cookiePolicyPageLink() {
    return get(this.current.company, 'paths').cookie_url;
  }

  @action
  save() {
    let promise = this.current.company.save();

    promise.then(
      () => {
        later(() => {
          set(this, 'edit', false);
          get(this, 'flashMessages').success(
            this.intl.t('settings.cookie_policy.success_message')
          );
        }, 500);
      },
      () => {
        get(this, 'flashMessages').error(
          this.intl.t('settings.cookie_policy.error_message')
        );
      }
    );

    return promise;
  }

  @action
  cancel() {
    get(this, 'model')?.rollbackAttributes?.();
    this.edit = false;
  }
}
