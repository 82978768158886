import Controller from '@ember/controller';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import jsonToCsvFile from 'teamtailor/utils/json-to-csv-file';
import AnalyticsService from 'teamtailor/services/analytics';
import FlipperService from 'teamtailor/services/flipper';
import IntlService from 'ember-intl/services/intl';
import {
  DataRow,
  formatData,
  inflateStageTypeRows,
  removeEmptyInProcess,
} from 'teamtailor/utils/insights/pipeline-conversion';
import Store from '@ember-data/store';

export default class AnalyticsOverviewPipelineConversionsController extends Controller {
  @service declare analytics: AnalyticsService;
  @service declare flipper: FlipperService;
  @service declare intl: IntlService;
  @service declare store: Store;

  @tracked sortDirection = this.defaultSortDirection;
  @tracked sortProperty = this.defaultSortProperty;

  get defaultSortProperty() {
    return 'stageType';
  }

  get defaultSortDirection() {
    return 'desc';
  }

  get data() {
    if (!this.model.pipelineConversion.value) {
      return [];
    }

    let rows = [...this.model.pipelineConversion.value.rows];
    const stageTypes = this.store.peekAll('stage-type').toArray();

    rows = inflateStageTypeRows(rows, stageTypes);
    return removeEmptyInProcess(
      formatData(rows, (row) => this.intl.t(`job.stage_types.${row.stageType}`))
    );
  }

  @action
  handleExport(): void {
    const rows: DataRow[] = this.data;
    const formatRows = (row: DataRow) => ({
      stage_type: row.displayName,
      reached_stage: row.numberOfApplicantsPassed,
      conversion_between_stages: row.conversionDown,
      conversion_from_inbox: row.conversionFromInbox,
      rejected_in_stage: row.rejectedString,
      not_moved: row.stayed,
    });

    const reportDate = moment(this.analytics.endDate).format('YYYYMMDD');

    jsonToCsvFile(
      `conversions-${reportDate}-for-cohort`,
      this.analytics.startDate,
      this.analytics.endDate,
      rows,
      formatRows
    );
  }

  @action
  handleSort(sortBy: string): void {
    if (this.sortProperty === sortBy) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortDirection = 'desc';
      this.sortProperty = sortBy;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'analytics.overview.pipeline-conversions': AnalyticsOverviewPipelineConversionsController;
  }
}
