import RESTSerializer from '@ember-data/serializer/rest';

export default class UnsplashPhoto extends RESTSerializer {
  normalizeQueryResponse(store, primaryModelClass, payload) {
    payload.unsplash_photos.forEach((photo) => {
      photo.type = 'unsplash-photo';
      photo.authorLink = photo.user.links.html;
      photo.authorName = photo.user.name;
      photo.previewUrl = photo.urls.small;
      photo.downloadLocation = photo.links.download_location;
      photo.alt = photo.alt_description || photo.description;
    });

    return super.normalizeQueryResponse(...arguments);
  }
}
