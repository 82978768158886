import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { IntlService } from 'ember-intl';
import { RequisitionModel } from 'teamtailor/models';

export default class RequisitionStatus extends Helper {
  @service declare intl: IntlService;

  compute([requisition]: [RequisitionModel]) {
    if (requisition.isDraft) {
      return this.intl.t('requisitions.list.draft');
    }

    if (requisition.isCompleted) {
      return this.intl.t('requisitions.list.completed');
    }

    if (requisition.status === 'approved') {
      return this.intl.t('requisitions.list.approved');
    }

    if (requisition.status === 'rejected') {
      return this.intl.t('requisitions.list.rejected');
    }

    return this.intl.t('requisitions.list.steps', {
      stepsLength: requisition.steps.length,
      awaitingStep: requisition.approvedSteps.length + 1,
    });
  }
}
