import { InlineToolbar, SelectionUtils } from '@editorjs/editorjs';
import { autoUpdate, computePosition, flip, offset } from '@floating-ui/dom';

type AutoUpdateData = {
  destroy?: () => void;
  selectionRect?: DOMRect;
};

export class CustomInlineToolbar extends InlineToolbar {
  declare autoUpdateData?: AutoUpdateData;
  declare nodes: { wrapper: HTMLElement };

  rectsAreSame(a?: DOMRect, b?: DOMRect) {
    return !!(
      a &&
      b &&
      !(['x', 'y', 'width', 'height'] as const).find((key) => a[key] !== b[key])
    );
  }

  close() {
    this.autoUpdateData?.destroy?.();

    super.close();
  }

  move() {
    const selectionIsInBlock = !!window
      .getSelection()
      ?.anchorNode?.parentElement?.closest('.ce-block');

    if (
      selectionIsInBlock &&
      !this.rectsAreSame(
        this.autoUpdateData?.selectionRect,
        SelectionUtils.rect
      )
    ) {
      const out: AutoUpdateData = { selectionRect: SelectionUtils.rect };

      this.autoUpdateData?.destroy?.();

      Object.assign(this.nodes.wrapper.style, {
        position: 'absolute',
        width: 'max-content',
        top: 0,
        left: 0,
        transform: 'initial',
      });

      out.destroy = autoUpdate(
        {
          getBoundingClientRect: () => out.selectionRect!,
        },
        this.nodes.wrapper,
        async () => {
          const position = await computePosition(
            {
              getBoundingClientRect: () => out.selectionRect!,
            },
            this.nodes.wrapper,
            {
              placement: 'bottom',
              middleware: [offset(5), flip()],
            }
          );
          this.nodes.wrapper.style.left = `${position.x}px`;
          this.nodes.wrapper.style.top = `${position.y}px`;
        },
        { ancestorScroll: false }
      );

      this.autoUpdateData = out;
    }
  }
}
