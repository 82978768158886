import ApplicationSerializer from './application';
export default class JobApplicationSerializer extends ApplicationSerializer {
  attrs = {
    externalJobTitle: { key: 'job_title', serialize: false },
    jobInternalName: { serialize: false },
    rowOrder: { serialize: false },
    npsResponses: { serialize: false },
    willBeDeletedAt: { serialize: false },
  };

  serialize() {
    let json = super.serialize(...arguments);

    if (json.cover_letter === null) {
      delete json.cover_letter;
    }

    return json;
  }
}
