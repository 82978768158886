import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import ActivityModel from 'teamtailor/models/activity';
import NoteModel from 'teamtailor/models/note';
import UserModel from 'teamtailor/models/user';

export default class ReactionModel extends Model {
  @belongsTo('user', { async: false })
  declare user: UserModel;

  @belongsTo('activity', { inverse: 'reactions' })
  declare action: AsyncBelongsTo<ActivityModel>;

  @belongsTo('note', { inverse: 'reactions' })
  declare note: AsyncBelongsTo<NoteModel>;

  @attr('date') declare createdAt: Date;
  @attr('string') declare emoji: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    reaction: ReactionModel;
  }
}
