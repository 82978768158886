import { TableColumn } from 'teamtailor/components/table/container';

export const TABLE_COLUMNS: TableColumn[] = [
  {
    label: 'settings.job_offer_templates.template_name',
    sortable: false,
    sortOrder: 1,
  },
  {
    label: 'settings.job_offer_templates.items',
    sortable: false,
    sortOrder: 2,
  },
  {
    label: 'settings.job_offer_templates.created',
    sortable: false,
    sortOrder: 3,
  },
  {
    label: '',
    sortable: false,
    sortOrder: 4,
  },
];
