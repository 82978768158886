import Store from '@ember-data/store';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import SettingsRequisitionsFormController from 'teamtailor/controllers/settings/requisitions/form';
import { RequisitionSettingModel } from 'teamtailor/models';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import Current from 'teamtailor/services/current';
import { requisitionFormToFormBuilderFields } from 'teamtailor/utils/form-builder';
import { Transition } from 'teamtailor/utils/type-utils';

export default class FormRoute extends ScrollToTopRoute {
  @service declare current: Current;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare store: Store;

  model() {
    return this.store.peekRecord(
      'requisition-setting',
      this.current.company.requisitionSetting.id
    );
  }

  setupController(
    controller: SettingsRequisitionsFormController,
    model: RequisitionSettingModel,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);

    const fields = requisitionFormToFormBuilderFields(model.customForm);

    controller.fields = fields;
  }

  resetController(
    controller: SettingsRequisitionsFormController,
    isExiting: boolean
  ) {
    if (isExiting && controller.model) {
      controller.model.rollbackAttributes();
    }
  }
}
