import Helper from '@ember/component/helper';
import { action } from '@ember/object';

// avoid ui jump because of removing scrollbars with overflow: hidden (when there were scrollbars before)
export default class HideScrollbarWithoutJump extends Helper {
  didSetModalBodyScrollPadding = false;
  didSetModalBodyOverflowHidden = false;

  @action removeModalBodyStyle() {
    document.body.style.paddingRight = '';
    delete document.body.dataset.isScrollbarPadded;

    this.didSetModalBodyScrollPadding = false;
  }

  @action compute() {
    const scrollbarWidth = window.innerWidth - document.body.clientWidth;
    // if its a modal on modal we dont need to do this, and especially not remove overflow hidden in willDestroy
    if (scrollbarWidth) {
      if (!document.body.dataset.isScrollbarPadded) {
        document.body.style.paddingRight = `${scrollbarWidth}px`;
        document.body.dataset.isScrollbarPadded = 'true';

        this.didSetModalBodyScrollPadding = true;
      }
    }

    if (!document.body.dataset.isOverflowHidden) {
      document.body.dataset.isOverflowHidden = 'true';
      document.body.classList.add('overflow-hidden');
      this.didSetModalBodyOverflowHidden = true;
    }
  }

  willDestroy() {
    super.willDestroy();
    if (this.didSetModalBodyScrollPadding) {
      this.removeModalBodyStyle();
    }

    if (this.didSetModalBodyOverflowHidden) {
      document.body.classList.remove('overflow-hidden');
      delete document.body.dataset.isOverflowHidden;
      this.didSetModalBodyOverflowHidden = false;
    }
  }
}
