import Model, {
  type AsyncBelongsTo,
  type AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { CompanyModel, JobModel, UserModel } from '.';

export default class RecruitingFirmModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @hasMany('job') declare jobs: AsyncHasMany<JobModel>;
  @hasMany('user') declare recruiters: AsyncHasMany<UserModel>;

  @attr('string') declare name: string;
  @attr('number') declare recruitersCount: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'recruiting-firm': RecruitingFirmModel;
  }
}
