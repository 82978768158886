export enum FileUploadStatus {
  UPLOADING = 'uploading',
  DONE = 'done',
  ERROR = 'error',
}

export interface FileUpload {
  fileName: string;
  temporaryUrl?: string;
  status: FileUploadStatus;
}
