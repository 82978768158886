import config from 'teamtailor/config/environment';
import { get } from 'teamtailor/utils/get';

const s3Regex = /:\/\/([\w-]+)[^/]+\/(.*)/i;

class Versions {
  versions = null;
  globalVersion = null;

  setTransformationVersions(versions, globalVersion) {
    this.versions = versions;
    this.globalVersion = globalVersion;
  }

  versionedTransformation(transformation) {
    const version =
      (this.versions && this.versions[transformation]) || this.globalVersion;

    return version ? `${transformation}-v${version}` : transformation;
  }
}

// Singleton, versions set from boot data
export const ImageUrlVersions = new Versions();

export default function imageUrl(image, transformation) {
  if (!image) {
    return null;
  }

  transformation = ImageUrlVersions.versionedTransformation(transformation);

  if (typeof image === 'string') {
    return s3Url(image, transformation);
  }

  const s3Image = get(image, 's3Image');

  if (s3Image) {
    return s3Url(s3Image, transformation);
  }

  return null;
}

function s3Url(url, transformation) {
  let [, bucket, key] = url.match(s3Regex);
  return `${config.CLOUDFRONT_IMAGES_HOST}/images/s3/${bucket}/${transformation}/${key}`;
}
