import ApplicationInstance from '@ember/application/instance';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';
import { gql } from '@apollo/client/core';
import ReportAnalyticsRequest from './report-analytics-request';
import IntlService from 'ember-intl/services/intl';

const QUERY = gql`
  query RejectReasons(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [REJECTED]
      companyIds: $companyIds
    ) {
      aggregated(
        groupBy: [
          REJECT_REASON_ID
          REJECT_REASON_REASON
          REJECT_REASON_REJECTED_BY_COMPANY
        ]
      ) {
        rejectReasonId
        name: rejectReasonReason
        rejectedByCompany: rejectReasonRejectedByCompany
        numberOfRejects: count

        candidateIds: collect(field: CANDIDATE_ID)
        applicationIds: collect(field: JOB_APPLICATION_ID)
      }
    }
  }
`;

interface Result {
  eventQuery:
    | {
        aggregated: ResponseRow[];
      }
    | undefined;
}

interface ResponseRow {
  rejectReasonId: string;
  name: string;
  rejectedByCompany: boolean;
  numberOfRejects: number;
  candidateIds: string[];
  applicationIds: string[];
}

interface RejectReasonRow {
  rejectReasonId: string;
  name: string;
  rejectedByCompany: boolean;
  numberOfRejects: number;
  candidateIds: string[];
  applicationIds: string[];
  rejectType?: string;
  translatedRejectType?: string;
}

const REJECT_TYPE_CANDIDATE = 'candidate';
const REJECT_TYPE_COMPANY = 'company';
const REJECT_TYPE_UNKNOWN = 'unknown';

function getRejectType(row: ResponseRow) {
  switch (row.rejectedByCompany) {
    case true:
      return REJECT_TYPE_COMPANY;

    case false:
      return REJECT_TYPE_CANDIDATE;

    default:
      return REJECT_TYPE_UNKNOWN;
  }
}

function getTranslatedlRejectType(intl: IntlService, row: ResponseRow) {
  const type = getRejectType(row);
  return type === REJECT_TYPE_COMPANY
    ? intl.t('common.company')
    : intl.t(`insights.common.${type}`);
}

interface DataObjectArgs {
  container: ApplicationInstance;
  _rows: ResponseRow[];
}

class DataObject {
  @service declare intl: IntlService;

  container: ApplicationInstance;
  _rows: ResponseRow[] = [];

  constructor(args: DataObjectArgs) {
    this.container = args.container;
    this._rows = args._rows;
  }

  get totalRejects() {
    return this.rows
      .map((row) => row.numberOfRejects)
      .reduce((acc, a) => acc + a, 0);
  }

  get totalRejectedByThem() {
    return this.rejectedByThem
      .map((row) => row.numberOfRejects)
      .reduce((acc, a) => acc + a, 0);
  }

  get totalRejectedByUs() {
    return this.rejectedByUs
      .map((row) => row.numberOfRejects)
      .reduce((acc, a) => acc + a, 0);
  }

  get totalRejectedUnknown() {
    return this.rejectedUnknown
      .map((row) => row.numberOfRejects)
      .reduce((acc, a) => acc + a, 0);
  }

  get rejectedByUs() {
    return this.reducedRows.filter(
      (row) => row.rejectType === REJECT_TYPE_COMPANY
    );
  }

  get rejectedByThem() {
    return this.reducedRows.filter(
      (row) => row.rejectType === REJECT_TYPE_CANDIDATE
    );
  }

  get rejectedUnknown() {
    return this.reducedRows.filter(
      (row) => row.rejectType === REJECT_TYPE_UNKNOWN
    );
  }

  get reducedRows(): RejectReasonRow[] {
    return ([...this.rows] as RejectReasonRow[]).reduce(
      (acc: RejectReasonRow[], row: RejectReasonRow) => {
        const item = acc.find(
          (a) => a.name === row.name && a.rejectType === row.rejectType
        );
        if (item) {
          item.applicationIds = [...item.applicationIds, ...row.applicationIds];
          item.candidateIds = [...item.candidateIds, ...row.candidateIds];

          set(
            item,
            'numberOfRejects',
            item.numberOfRejects + row.numberOfRejects
          );
        } else {
          acc.push({ ...row });
        }

        return acc;
      },
      []
    );
  }

  get rows(): RejectReasonRow[] {
    return this._rows.map((row: ResponseRow) => ({
      rejectReasonId: row.rejectReasonId,
      name: row.name,
      rejectedByCompany: row.rejectedByCompany,
      numberOfRejects: row.numberOfRejects,
      rejectType: getRejectType(row),
      translatedRejectType: getTranslatedlRejectType(this.intl, row),
      applicationIds: row.applicationIds,
      candidateIds: row.candidateIds,
    }));
  }
}

export default class RejectReasonsReport {
  container: ApplicationInstance;

  constructor({ container }: { container: ApplicationInstance }) {
    this.container = container;
  }

  fetch = task(async (): Promise<DataObject> => {
    const _rows: ResponseRow[] | undefined = await new ReportAnalyticsRequest({
      container: this.container,
      query: QUERY,
      callback: (result?: Result) => result?.eventQuery?.aggregated,
    }).fetch();

    return new DataObject({ container: this.container, _rows: _rows || [] });
  });

  fetchTask = trackedTask(this, this.fetch);
}
