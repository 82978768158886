import Editor, { Action } from '../editor';

import * as icons from '../icons';

export default class Media {
  editor: Editor;

  constructor(editor: Editor) {
    this.editor = editor;
  }

  async getActions(): Promise<Action[]> {
    return [
      {
        id: 'size',
        icon: icons.arrowUpSmallBig,
        actions: await this.buildImageSizeActions(),
      },
    ];
  }

  async buildImageSizeActions(): Promise<Action[]> {
    const currentSize = (await this.editor.currentBlock?.save())?.data
      .imageSize;

    return [
      {
        id: 'default',
        icon: currentSize === 'default' ? icons.check : undefined,
        onClick: this.imageSizeAction('default'),
      },
      {
        id: 'large',
        icon: currentSize === 'large' ? icons.check : undefined,
        onClick: this.imageSizeAction('large'),
      },
      {
        id: 'full',
        icon: currentSize === 'full' ? icons.check : undefined,
        onClick: this.imageSizeAction('full'),
      },
    ];
  }

  imageSizeAction(imageSize: string) {
    return async () => {
      if (this.editor.currentBlock) {
        const savedBlock = await this.editor.currentBlock.save();
        if (savedBlock) {
          this.editor.blocks.update(this.editor.currentBlock.id, {
            ...savedBlock.data,
            imageSize,
          });
        }
      }

      this.editor.toolbar.close();
    };
  }
}
