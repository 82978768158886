import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @candidate.candidateStatus}}\n  <div class=\"flex flex-row gap-4\">\n    {{#each @candidate.candidateStatus as |status|}}\n      <Core::Badge\n        @color={{badge-color (compute (fn this.color status))}}\n        @text={{t\n          (compute (fn this.text status))\n          inTimeFromNow=(format-date (compute (fn this.date status)))\n        }}\n        @size={{@size}}\n      />\n    {{/each}}\n  </div>\n{{/if}}", {"contents":"{{#if @candidate.candidateStatus}}\n  <div class=\"flex flex-row gap-4\">\n    {{#each @candidate.candidateStatus as |status|}}\n      <Core::Badge\n        @color={{badge-color (compute (fn this.color status))}}\n        @text={{t\n          (compute (fn this.text status))\n          inTimeFromNow=(format-date (compute (fn this.date status)))\n        }}\n        @size={{@size}}\n      />\n    {{/each}}\n  </div>\n{{/if}}","moduleName":"teamtailor/components/candidate-status.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-status.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import {
  STATUS_COLOR,
  STATUS_TEXT,
} from 'teamtailor/constants/candidate-status';
import CandidateModel, { CandidateStatus } from 'teamtailor/models/candidate';
import { get } from 'teamtailor/utils/get';

type Args = {
  candidate: CandidateModel;
};

export default class CandidateStatusComponent extends Component<Args> {
  @action
  color(status: CandidateStatus) {
    return STATUS_COLOR[status];
  }

  @action
  text(status: CandidateStatus) {
    return STATUS_TEXT[status];
  }

  @action
  date(status: CandidateStatus) {
    if (status === 'will_be_deleted') {
      return get(this.args.candidate, 'willBeDeletedAt');
    }

    if (status === 'application_will_be_deleted') {
      return get(this.args.candidate, 'jobApplicationWillBeDeletedAt');
    }
  }
}
