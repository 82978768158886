import Model, {
  belongsTo,
  hasMany,
  attr,
  AsyncHasMany,
  AsyncBelongsTo,
} from '@ember-data/model';
import { AuditEventModel, CompanyModel } from 'teamtailor/models';
import { inject as service } from '@ember/service';
import Server from 'teamtailor/services/server';

export default class AuditLogModel extends Model {
  @service declare server: Server;

  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @hasMany('audit-event') declare events: AsyncHasMany<AuditEventModel>;

  @attr('number', { defaultValue: 15 }) declare retentionTimeInDays: number;
  @attr('string') declare exportUrl: string;

  async exportEvents() {
    return this.server.memberAction(this, {
      action: 'export_events',
      method: 'GET',
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'audit-log': AuditLogModel;
  }
}
