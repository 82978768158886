import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import WebhookSubscription from 'teamtailor/models/webhook-subscription';

export default class SettingsWebhookEditRoute extends ScrollToTopRoute {
  @service declare store: Store;

  model(params: { webhook_id: string }): Promise<WebhookSubscription> {
    return this.store.findRecord('webhook-subscription', params.webhook_id);
  }
}
