import MeetingNewRoute from 'teamtailor/routes/meetings/new';

export default class CandidatesCandidateMeetingNewRoute extends MeetingNewRoute {
  controllerName = 'meetings.new';
  templateName = 'meetings.new';

  meetingRouteRouteName = 'candidates.candidate.meetings';
  candidateRouteRouteName = 'candidates.candidate';
  jobRouteRouteName = '';
}
