import Model, { attr } from '@ember-data/model';

import { sectionIcon } from 'teamtailor/helpers/section-icon';

export default class BlockLayoutModel extends Model {
  @attr('string')
  declare displayName: string;

  @attr('string')
  declare _name: string;

  get name() {
    return this._name || this.displayName;
  }

  set name(val) {
    this._name = val;
  }

  @attr('string')
  declare blockName: string;

  @attr('string')
  declare mobilePreview: string;

  @attr('string')
  declare desktopPreview: string;

  @attr('string')
  declare previewUrl: string;

  @attr('string')
  declare careerSitePreviewPath: string;

  get blockIcon(): string {
    return sectionIcon(this.blockName).name;
  }

  get blockIconStyle(): string {
    return sectionIcon(this.blockName).style;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'block-layout': BlockLayoutModel;
  }
}
