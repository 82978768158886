import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @notClickable}}\n  {{#each this.starsArray as |starId|}}\n    <span>\n      <Icon @icon=\"star\" @style={{if (includes starId this.values) \"solid\"}} />\n    </span>\n  {{/each}}\n{{else}}\n  <div class=\"flex flex-row items-center justify-evenly pb-16\">\n    {{#each this.starsArray as |starId|}}\n      <span role=\"button\" {{on \"click\" (fn this.setInputValues starId)}}>\n        <Icon\n          @icon=\"star\"\n          @style={{if (includes starId this.values) \"solid\"}}\n        />\n      </span>\n    {{/each}}\n  </div>\n{{/if}}", {"contents":"{{#if @notClickable}}\n  {{#each this.starsArray as |starId|}}\n    <span>\n      <Icon @icon=\"star\" @style={{if (includes starId this.values) \"solid\"}} />\n    </span>\n  {{/each}}\n{{else}}\n  <div class=\"flex flex-row items-center justify-evenly pb-16\">\n    {{#each this.starsArray as |starId|}}\n      <span role=\"button\" {{on \"click\" (fn this.setInputValues starId)}}>\n        <Icon\n          @icon=\"star\"\n          @style={{if (includes starId this.values) \"solid\"}}\n        />\n      </span>\n    {{/each}}\n  </div>\n{{/if}}","moduleName":"teamtailor/components/fancy-filters/rating-picker.hbs","parseOptions":{"srcName":"teamtailor/components/fancy-filters/rating-picker.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import AnalyticsService from 'teamtailor/services/analytics';

interface RatingPickerArgs {
  onSelection: (value: unknown) => void;
  value: string | string[] | number[];
}

export default class RatingPickerComponent extends Component<RatingPickerArgs> {
  @service declare analytics: AnalyticsService;

  get starsArray(): number[] {
    return [1, 2, 3, 4, 5];
  }

  get values(): number[] {
    if (isEmpty(this.args.value)) {
      return [];
    }

    let values = this.args.value;

    if (!Array.isArray(values)) {
      values = [values];
    }

    return values.map((v: string | number) => Number(v));
  }

  @action setInputValues(value: number): void {
    const ratings = this.values;
    const existingValue = ratings.includes(value);

    if (existingValue) {
      ratings.removeObject(value);
      this.args.onSelection(ratings);
    } else {
      ratings.pushObject(value);
      this.args.onSelection(ratings);
    }
  }
}
