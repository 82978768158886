import Model, { attr } from '@ember-data/model';
import Raw from 'teamtailor/transforms/raw';

export default class TwilioModel extends Model {
  @attr('string') declare city: string;
  @attr('string') declare street: string;
  @attr('string') declare region: string;
  @attr('string') declare postalCode: string;
  @attr('string') declare isoCountry: string;
  @attr('string') declare phoneNumber: string;
  @attr('string') declare numberCountry: string;

  @attr('raw', {
    defaultValue() {
      return {};
    },
  })
  declare identity: Raw;

  @attr('raw') declare documents: Raw;

  @attr('boolean') declare verifyDocuments: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    twilio: TwilioModel;
  }
}
