import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default class SettingsExternalRecruitmentRecruitersDeleteController extends Controller {
  @service router;

  @tracked
  isOpen = true;

  @alias('model.nameOrEmail') name;

  initController() {
    this.isOpen = true;
  }

  @action
  handleDelete() {
    this.model.destroyRecord().then(() => {
      this.isOpen = false;
      this.router.transitionTo('settings.external-recruitment.recruiters');
    });
  }

  @action
  handleClose() {
    this.router.transitionTo('settings.external-recruitment.recruiters');
  }
}
