import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import JobsJobCompareCandidatesCandidateController from 'teamtailor/controllers/jobs/job/compare-candidates/candidate';
import { Transition } from 'teamtailor/utils/type-utils';
import CandidateModel from 'teamtailor/models/candidate';
import JobModel from 'teamtailor/models/job';

export default class JobsJobCompareCandidatesCandidate extends Route {
  @service declare store: Store;

  setupController(
    controller: JobsJobCompareCandidatesCandidateController,
    model: CandidateModel,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);

    const job = this.modelFor('jobs.job') as JobModel;

    const jobApplication = model
      .hasMany('jobApplications')
      .value()
      ?.findBy('jobId', Number(job.id));

    if (jobApplication) {
      controller.jobApplication = jobApplication;
    }
  }
}
