import TriggerSerializer from 'teamtailor/serializers/trigger';

export default TriggerSerializer.extend({
  attrs: {
    stage: { serialize: 'ids' },
    uploads: {
      key: 'uploads_attributes',
      serialize: 'records',
      deserialize: 'ids',
    },
  },

  serialize(snapshot) {
    let [commonJson, extras] = this.extractCommonJson(
      this._super(...arguments)
    );

    let { uploads_attributes, ...data } = extras;

    return Object.assign(commonJson, {
      id: commonJson?.id ?? snapshot.record?.id,
      data,
      uploads_attributes,
    });
  },
});
