import Model, {
  attr,
  hasMany,
  SyncHasMany,
  belongsTo,
} from '@ember-data/model';
import { get } from 'teamtailor/utils/get';
import FormQuestionModel from './form-question';
import CompanyModel from './company';
import { FormBuilderField } from 'teamtailor/utils/form-builder';

export default class ReferenceTemplateModel extends Model {
  @hasMany('form-question', { async: false })
  declare formQuestions: SyncHasMany<FormQuestionModel>;

  @belongsTo('company', { async: false })
  declare company: CompanyModel;

  @attr('number') declare numberOfQuestions: number;
  @attr('string') declare name: string;
  @attr('string') declare introText: string;
  @attr('date') declare createdAt: Date | null;
  @attr('raw') formQuestionsRaw?: FormBuilderField[];

  get sortedFormQuestions() {
    return get(this, 'formQuestions').sortBy('sortIndex');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'reference-template': ReferenceTemplateModel;
  }
}
