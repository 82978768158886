import ContentEditorIndexController from 'teamtailor/controllers/content/editor/index';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Current from 'teamtailor/services/current';
import ContentEditorService from 'teamtailor/services/content-editor';
import IframePreviewService from 'teamtailor/services/iframe-preview';
import IntlService from 'ember-intl/services/intl';
import RouterService from '@ember/routing/router-service';
import TtAlertService from 'teamtailor/services/tt-alert';
import FlipperService from 'teamtailor/services/flipper';
import Owner from '@ember/owner';
import {
  BlockLayoutModel,
  DepartmentModel,
  ImageModel,
  PageModel,
  PagePublicationModel,
  PickedQuestionModel,
  QuestionModel,
  RoleModel,
  SectionModel,
} from 'teamtailor/models';
import EditorRoute from 'teamtailor/routes/content/editor';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import { get } from 'teamtailor/utils/get';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';
import Store from '@ember-data/store';
import {
  SubTypeClassNames,
  classToSubType,
} from 'teamtailor/serializers/section';
import BowserService from 'teamtailor/services/bowser';
import { scrollToPromise } from 'teamtailor/utils/scroll-to-promise';
import { debounce } from '@ember/runloop';
import { restartableTask } from 'ember-concurrency';
import ConnectSettingModel from 'teamtailor/models/connect-setting';
import TranslationDepartmentModel from 'teamtailor/models/translation/department';
import { AllSectionModels } from 'teamtailor/models/section';
import IntercomService from 'teamtailor/services/intercom';

export default class ContentEditorController extends Controller {
  declare model: ModelFrom<EditorRoute>;

  queryParams = [
    'preview',
    'responsive',
    'orientation',
    'mode',
    'overrideIframeSrc',
  ];

  @service declare current: Current;
  @service declare contentEditor: ContentEditorService;
  @service declare iframePreview: IframePreviewService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare ttAlert: TtAlertService;
  @service declare flipper: FlipperService;
  @service declare store: Store;
  @service declare bowser: BowserService;
  @service declare intercom: IntercomService;

  constructor(owner: Owner) {
    super(owner);
    this.preview = this.contentEditor.preview;
    this.responsive = this.contentEditor.responsive;
    this.mode = this.contentEditor.mode;
  }

  @tracked aFigurePickerisOpen = false;
  @tracked preview;
  @tracked responsive;
  @tracked mode;

  @tracked declare selectedDepartment?: DepartmentModel;
  @tracked declare selectedRole?: RoleModel;
  @tracked declare connectSettings?: ConnectSettingModel;
  @tracked declare currentDepartmentConnectSettings?: ConnectSettingModel;
  @tracked declare generalConnectSettings?: ConnectSettingModel;
  @tracked declare selectedConnectBlock?: string;
  @tracked declare refresh?: string;

  @tracked declare editorHeaderEl: HTMLElement;

  @tracked declare overrideIframeSrc?: string;

  @controller('content.editor.index')
  declare contentEditorIndexController: ContentEditorIndexController;

  @tracked hoveredSection?:
    | AllSectionModels
    | DS.PromiseObject<AllSectionModels>;

  @tracked _hoveredSectionData?: {
    idOrName?: string;
    subTypeClassName?: SubTypeClassNames;
  };

  scrollState?: { el: Element; controller: AbortController };

  get hoveredSectionData():
    | { idOrName?: string; subTypeClassName?: SubTypeClassNames }
    | undefined {
    return this._hoveredSectionData;
  }

  set hoveredSectionData(
    newData:
      | {
          idOrName?: string;
          subTypeClassName?: SubTypeClassNames;
          scroll?: false | ScrollBehavior;
        }
      | undefined
  ) {
    if (newData?.idOrName) {
      const { idOrName, scroll = true, subTypeClassName } = newData;

      const modelName = classToSubType(subTypeClassName);

      if (['header', 'footer', 'posts'].includes(idOrName)) {
        this.hoveredSection =
          idOrName === 'posts'
            ? this.contentEditorIndexController.fixedSections.find(
                (section) => get(section, 'name') === 'posts'
              )
            : undefined;
        this._hoveredSectionData = {
          idOrName,
        };
      } else {
        this.store
          .findRecord(modelName, idOrName, {
            backgroundReload: false,
          })
          .then((section) => {
            this.hoveredSection = section;
            this._hoveredSectionData = {
              idOrName,
              subTypeClassName,
            };
          });
      }

      if (scroll) {
        debounce(this, 'scrollToSection', idOrName, scroll, 50);
      }
    } else {
      this.hoveredSection = undefined;
      this._hoveredSectionData = undefined;
    }
  }

  get editorType() {
    return this.contentEditor.editorType;
  }

  get sections() {
    return this.page?.sections;
  }

  get addButtonAvailable() {
    return (
      this.router.currentRouteName === 'content.editor.index' &&
      !this.page?.isConnectPage
    );
  }

  get onGlobalDesign() {
    return this.router.currentRouteName === 'content.editor.global-design';
  }

  get page() {
    return this.model.pageRecord instanceof PageModel
      ? this.model.pageRecord
      : null;
  }

  publishPage(pagePublication: PagePublicationModel, onSuccess?: () => void) {
    const promise = pagePublication.publish();
    if (onSuccess) {
      promise.then(onSuccess);
    }

    return promise;
  }

  async getConnectWelcomeMessage() {
    const careerSite = await get(this.page, 'careerSite');

    if (this.selectedDepartment) {
      if (careerSite?.isDefault) {
        return this.selectedDepartment.connectWelcomeMessage;
      } else {
        const translations = await this.selectedDepartment.translations;
        const departmentTranslation = translations.find(
          (translation: TranslationDepartmentModel) =>
            translation.languageCode === careerSite?.languageCode
        );

        return departmentTranslation?.connectWelcomeMessage;
      }
    }

    return careerSite?.connectWelcomeMessage;
  }

  resetConnectData() {
    this.selectedDepartment = undefined;
    this.selectedRole = undefined;
    this.selectedConnectBlock = undefined;
    this.refresh = undefined;
    this.fetchConnectSettings.perform();
    this.cleanupConnectMediaItems();
  }

  cleanupConnectMediaItems() {
    this.connectSettings?.connectMediaItems.forEach((mediaItem) => {
      if (mediaItem.isNew) {
        mediaItem.rollbackAttributes();
      }
    });
  }

  fetchConnectSettings = restartableTask(async () => {
    const connectSettings = await this.store.query('connect-setting', {
      page_id: this.page?.id,
      department_id: this.selectedDepartment?.id,
      role_id: this.selectedRole?.id,
    });

    if (connectSettings.length) {
      this.connectSettings = connectSettings[0];
    } else {
      const welcomeMessage = await this.getConnectWelcomeMessage();
      this.connectSettings = this.store.createRecord('connect-setting', {
        page: this.page,
        department: this.selectedDepartment,
        role: this.selectedRole,
        welcomeMessage,
        pickedQuestions: this.selectedRole
          ? this.selectedRole.pickedQuestions
          : this.selectedDepartment?.pickedQuestions,
      });

      this.connectSettings.save();
    }

    if (this.selectedDepartment && !this.selectedRole) {
      this.currentDepartmentConnectSettings = this.connectSettings;
    }
  });

  @action async scrollToSection(
    idOrName: string,
    scroll: boolean | ScrollBehavior
  ) {
    const blockItemEl = document.querySelector(
      `[data-section-id-or-name="${idOrName}"]`
    );

    if (blockItemEl && blockItemEl !== this.scrollState?.el) {
      const { scrollTop } = this.contentEditorIndexController.el!;

      const listRect =
        this.contentEditorIndexController.el!.getBoundingClientRect();

      const listTop = listRect.top - scrollTop;
      const blockRect = blockItemEl.getBoundingClientRect()!;
      const blockCenterScrollTop = Math.max(
        blockRect.top - listTop - listRect.height / 2 + blockRect.height / 2,
        0
      );

      if (this.scrollState) {
        this.scrollState.controller.abort();
      }

      this.scrollState = {
        controller: new AbortController(),
        el: blockItemEl,
      };
      const { signal } = this.scrollState.controller;

      await scrollToPromise(
        this.contentEditorIndexController.el!,
        blockCenterScrollTop,
        {
          signal,
          behavior: typeof scroll === 'string' ? scroll : 'smooth',
        }
      );

      this.scrollState = undefined;
    }
  }

  @action
  async onChangeSectionLayout(
    sectionArg: Promise<SectionModel>,
    block_layout: BlockLayoutModel
  ) {
    const section = await sectionArg;
    set(section, 'layout', block_layout.name);
    await section.save();
    this.iframePreview.reloadSection(section.id);
  }

  @action
  async handlePromoteChanges() {
    if (this.page?.unpublishedChanges) {
      const { previewUrl } = this.page;
      await this.page.pagePublication.promoteChanges();
      this.page.rollbackAttributes();
      set(this.page, 'previewUrl', previewUrl);

      if (this.connectSettings) {
        this.connectSettings.changedFields = [];
      }

      const { sections } = this.page;
      sections?.forEach((section) =>
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        section.sectionChange?.then?.((sectionChange) => {
          if (sectionChange) {
            // @ts-expect-error this transitionTo api in records is deprecated?
            sectionChange._internalModel.transitionTo('deleted.saved');
          }
        })
      );
    }

    if (
      get(
        get(get(this.page, 'careerSite'), 'careerSiteHeader'),
        'unpublishedChanges'
      )
    ) {
      await (
        await get(get(this.page, 'careerSite'), 'careerSiteHeader')
      )?.publish();
    }
  }

  @action
  async handlePublishDesign() {
    return (await get(this.current.company, 'draftDesign'))?.publish();
  }

  @action
  async handleDiscardChanges() {
    if (this.page?.unpublishedChanges) {
      await this.page.pagePublication.discardChanges();
      await this.page.sections?.reload();
      if (this.page.isConnectPage) {
        this.resetConnectData();
      }

      this.iframePreview.reloadIframe();

      if (this.router.currentRouteName !== 'content.editor.index') {
        this.router.transitionTo('content.editor.index');
      }
    }
  }

  @action
  handlePromote(route: string) {
    const page = this.page!;
    const { pagePublication } = page;
    const isPagePublished = pagePublication.publishedAt;
    const pageTitle = page.displayTitle;

    if (isPagePublished) {
      this.router.transitionTo(route, page.id);
    } else {
      this.ttAlert.customConfirm({
        title: this.intl.t('content.editor.publish_first'),
        text: this.intl.t('content.editor.do_you_want_to_publish_page', {
          pageTitle,
        }),

        confirmButtonText: this.intl.t('common.yes'),
        cancelButtonText: this.intl.t('common.no'),
        confirmCallback: () => {
          pagePublication.publish().then(() => {
            this.router.transitionTo(route, page);
          });
        },

        cancelCallback: () => {},
      });
    }
  }

  @action
  routeToBlockLibrary(position: unknown) {
    this.router.transitionTo('content.editor.block-library', {
      queryParams: { position },
    });
  }

  @action
  handleConnectSelectionChange(
    type: 'department' | 'role',
    selection: DepartmentModel | RoleModel | undefined
  ) {
    if (type === 'department') {
      this.selectedDepartment = selection as DepartmentModel | undefined;
      this.selectedRole = undefined;
    } else {
      this.selectedRole = selection as RoleModel | undefined;
      this.selectedConnectBlock = undefined;
    }

    this.fetchConnectSettings.perform();
  }

  @action
  async handleConnectSettingsChange(
    changedField: string,
    cleanupPickQuestions = false,
    shouldForceRefresh = false
  ) {
    if (this.connectSettings) {
      if (
        this.connectSettings.changedFields &&
        !this.connectSettings.changedFields.includes(changedField)
      ) {
        this.connectSettings.changedFields = [
          ...this.connectSettings.changedFields,
          changedField,
        ];
      }

      await this.connectSettings.save();
      if (cleanupPickQuestions) {
        this.connectSettings.pickedQuestions.forEach(
          (pickedQuestion: PickedQuestionModel) => {
            // eslint-disable-next-line
            // @ts-ignore
            if (pickedQuestion.isNew) {
              pickedQuestion.destroyRecord();
            }
          }
        );
      }

      if (shouldForceRefresh) {
        this.forceRefresh();
      } else {
        this.iframePreview.reloadIframe();
      }
    }
  }

  @action
  handleRemoveConnectSettingPickedQuestion(
    pickedQuestion: PickedQuestionModel
  ) {
    pickedQuestion.deleteRecord();
    this.handleConnectSettingsChange('picked-questions', false, true);
  }

  @action
  handleSelectConnectSettingPickedQuestion(question: QuestionModel) {
    this.connectSettings?.pickedQuestions.createRecord({
      question,
      rowOrderPosition: this.connectSettings.pickedQuestions.length,
    });
    this.handleConnectSettingsChange('picked-questions', true, true);
  }

  @action
  async handlePickImage(image: ImageModel) {
    if (this.connectSettings) {
      const existingCover = await this.connectSettings.cover;
      if (existingCover) {
        await existingCover.destroyRecord();
      }

      const cover = this.store.createRecord('picked-image', {
        image,
        ownerType: 'ConnectSetting',
        ownerId: this.connectSettings.id,
      });
      await cover.save();
      await this.handleConnectSettingsChange('cover');
    }
  }

  @action
  async handleRemoveImage() {
    const cover = await this.connectSettings?.cover;
    cover?.destroyRecord();
    await this.handleConnectSettingsChange('cover');
  }

  @action
  forceRefresh() {
    this.refresh = Date.now().toString();
  }
}

declare module '@ember/controller' {
  interface Registry {
    'content.editor': ContentEditorController;
  }
}
