import { Promise as EmberPromise } from 'rsvp';
import Service, { inject as service } from '@ember/service';
import { guidFor } from '@ember/object/internals';
import { get } from 'teamtailor/utils/get';

export default class BulkApiService extends Service {
  @service store;
  @service notifications;
  @service flashMessages;

  get channel() {
    return get(this.notifications, 'channel');
  }

  post(action, data, scope = 'candidates', resolve_after_request = false) {
    let store = get(this, 'store');
    let adapter = store.adapterFor('application');
    let url = `${adapter.buildURL(scope)}/${action}`;
    let channel = get(this, 'channel');

    return new EmberPromise((resolve, reject) => {
      let eventName = `bulk-action-${guidFor(resolve)}`;

      channel.bind(eventName, (data) => {
        resolve(data);
        channel.unbind(eventName);
      });

      data.event_name = eventName;
      adapter
        .ajax(url, 'POST', { data: { bulkData: data } })
        .then((data) => {
          if (resolve_after_request) {
            resolve(data);
          }
        })
        .catch(({ errors = [] }) => {
          if (errors[0] && errors[0].status === 409) {
            this.flashMessages.error(errors[0].detail);
          }

          reject();
        });
    });
  }
}
