import EditorJS, { BlockAPI } from '@editorjs/editorjs/types';
import { EditorConfig } from '@editorjs/editorjs/types/configs/editor-config';
import { Blocks } from '@editorjs/editorjs/types/api/blocks';
import { Toolbar } from '@editorjs/editorjs/types/api/toolbar';

export interface Translations {
  [key: string]: string;
}

export interface Action {
  id: string;
  icon?: string;
  actions?: Action[];
  onClick?: () => void;
}

export default class Editor {
  editorJs: EditorJS;
  configuration: EditorConfig;

  constructor(editorJs: EditorJS) {
    this.configuration = editorJs.configuration;
    this.editorJs = editorJs;
  }

  get blocks(): Blocks {
    return this.editorJs.blocks;
  }

  get toolbar(): Toolbar {
    return this.editorJs.toolbar;
  }

  get emit(): EditorJS['emit'] {
    return this.editorJs.emit;
  }

  get caret(): EditorJS['caret'] {
    return this.editorJs.caret;
  }

  get holder(): HTMLElement | undefined {
    return typeof this.configuration.holder === 'string'
      ? document.getElementById(this.configuration.holder)!
      : this.configuration.holder;
  }

  get currentBlock(): BlockAPI | undefined {
    const index = this.editorJs.blocks.getCurrentBlockIndex();
    const block = this.editorJs.blocks.getBlockByIndex(index);
    if (block) {
      return block;
    }

    return undefined;
  }
}
