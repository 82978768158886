import { validatePresence } from 'ember-changeset-validations/validators';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import { getProperties } from '@ember/object';
import { TagModel } from 'teamtailor/models';

const validatePurposeIsSet = () => {
  return (
    key: string,
    _newValue: unknown,
    _oldValue: unknown,
    changes: unknown,
    tag: TagModel
  ) => {
    const values = getProperties(
      tag,
      'availableOnCandidates',
      'availableOnJobs',
      'availableOnPosts',
      'availableOnImages',
      'availableOnQuestions',
      'availableOnCannedResponses',
      'availableOnInterviewKits',
      'availableOnPages'
    );

    const {
      availableOnCandidates,
      availableOnJobs,
      availableOnPosts,
      availableOnImages,
      availableOnQuestions,
      availableOnCannedResponses,
      availableOnInterviewKits,
      availableOnPages,
    } = Object.assign(values, changes);

    return (
      availableOnCandidates ||
      availableOnJobs ||
      availableOnPosts ||
      availableOnImages ||
      availableOnQuestions ||
      availableOnCannedResponses ||
      availableOnInterviewKits ||
      availableOnPages ||
      buildMessage(key, { type: 'optionRequired' })
    );
  };
};

export default {
  name: validatePresence(true),
  purpose: validatePurposeIsSet(),
};
