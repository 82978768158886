import MeetingNewRoute from 'teamtailor/routes/meetings/new';

export default class CandidatesJobMeetingNewRoute extends MeetingNewRoute {
  controllerName = 'meetings.new';
  templateName = 'meetings.new';

  meetingRouteRouteName = 'jobs.job.stages.index.candidate.meetings';
  candidateRouteRouteName = 'jobs.job.stages.index.candidate';
  jobRouteRouteName = 'jobs.job';
}
