/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BlockToolConstructable,
  ToolboxConfigEntry,
} from '@editorjs/editorjs/types';
import {
  computePosition,
  detectOverflow,
  offset,
  MiddlewareArguments,
} from '@floating-ui/dom';

import Editor from './editor';
import Menu from './menu';

import { createElement, buildSVGElement } from './utils';

interface ToolCategories {
  assistant: string[];
  basics: string[];
  media: string[];
}

interface ToolItem {
  title: string;
  toolName: string;
  svg?: SVGElement;
  data?: object;
}

interface Translations {
  [key: string]: string;
}

const toolCategories: ToolCategories = {
  assistant: ['aiBlock'],
  basics: ['paragraph', 'heading', 'list', 'code', 'quote', 'delimiter'],

  media: ['image', 'gif', 'video'],
};

export enum ToolboxEvent {
  Opened = 'toolbox-opened',
  Closed = 'toolbox-closed',
  BlockAdded = 'toolbox-block-added',
}

export default class Toolbox extends Menu {
  popover?: HTMLElement;
  trigger?: HTMLElement;

  constructor(
    editor: Editor,
    translations: Translations,
    enableAssistant: boolean
  ) {
    super(editor, translations);

    this.popover =
      this.editor.holder?.querySelector<HTMLElement>('.ce-popover') ||
      undefined;
    this.trigger =
      this.editor.holder?.querySelector<HTMLElement>('.ce-toolbar__plus') ||
      undefined;

    if (this.popover) {
      this.popover.classList.add('p-0', 'divide-y', 'divide-zinc-200');

      // Remove default toolbox items
      while (this.popover.firstElementChild) {
        this.popover.firstElementChild.remove();
      }

      if (enableAssistant) {
        this.popover.appendChild(this.buildSection('assistant'));
      }

      this.popover.appendChild(this.buildSection('basics'));
      this.popover.appendChild(this.buildSection('media'));

      const observer = new MutationObserver(this.handleMutationEvent);
      observer.observe(this.popover, {
        attributes: true,
      });
    }
  }

  get toolNames(): string[] {
    return Object.values(toolCategories).reduce(
      (acc, toolName) => acc.concat(toolName),
      []
    );
  }

  get items(): ToolItem[] {
    const items: ToolItem[] = [];

    const tools = this.editor.configuration.tools || {};
    const filteredTools = Object.keys(tools).filter((toolName) =>
      this.toolNames.includes(toolName)
    );

    filteredTools.forEach((toolName) => {
      const { toolbox } = tools[toolName] as BlockToolConstructable;

      const pushItem = (item: ToolboxConfigEntry) => {
        items.push({
          title: item.title || '',
          svg: item.icon ? buildSVGElement(item.icon) : undefined,
          toolName,
          data: item.data,
        });
      };

      if (Array.isArray(toolbox)) {
        toolbox.forEach(pushItem);
      } else if (toolbox) {
        pushItem(toolbox);
      }
    });

    return items.sort(
      (a: ToolItem, b: ToolItem) =>
        this.toolNames.indexOf(a.toolName) - this.toolNames.indexOf(b.toolName)
    );
  }

  static overflow = {
    name: 'middleware',
    async fn(middlewareArguments: MiddlewareArguments) {
      const boundary =
        document.querySelector('#wysiwyg-editor-boundary') || undefined;
      const overflow = await detectOverflow(middlewareArguments, {
        boundary,
      });

      if (overflow.bottom > 0) {
        return {
          y: middlewareArguments.y - overflow.bottom - 8,
        };
      }

      return {};
    },
  };

  handleMutationEvent = (mutations: MutationRecord[]) => {
    mutations.forEach(async (mutation) => {
      if (mutation.attributeName === 'class') {
        const { popover, trigger } = this;
        const element = (mutation.target as HTMLElement).classList.contains(
          'ce-popover--opened'
        );

        const isOpen = element && popover && trigger;

        if (isOpen) {
          const { x, y } = await computePosition(trigger, popover, {
            placement: 'right-start',
            middleware: [Toolbox.overflow, offset(8)],
          });

          Object.assign(popover.style, {
            left: `${x}px`,
            top: `${y}px`,
          });
        }
      }
    });
  };

  buildSection(id: keyof ToolCategories): HTMLElement {
    const sectionClasses = ['flex', 'flex-col', 'px-16', 'py-8', 'text-14'];

    const section = document.createElement('div');
    section.classList.add(...sectionClasses);

    section.appendChild(
      createElement(
        'div',
        ['font-semibold', 'my-4'],
        (this.translations as any)[id] || id
      )
    );

    this.buildItems(id).forEach((item) => {
      section.appendChild(item);
    });

    return section;
  }

  buildItems(category: keyof ToolCategories): HTMLButtonElement[] {
    const items: HTMLButtonElement[] = [];

    const toolNames = toolCategories[category];

    toolNames.forEach((toolName) => {
      const itemsToAdd = this.items.filter(
        (item) => item.toolName === toolName
      );

      itemsToAdd.forEach((item) => {
        items.push(
          this.buildItemElement(toolName, item.title, item.data, item.svg)
        );
      });
    });

    return items;
  }

  buildItemElement(
    toolName: string,
    label: string,
    data: any = {},
    svg?: SVGElement
  ): HTMLButtonElement {
    const itemElement = document.createElement('button');
    itemElement.classList.add(
      'flex',
      'items-center',
      'px-8',
      'py-4',
      '-mx-8',
      'rounded-6',
      'hover:bg-neutral-weak'
    );

    if (svg) {
      const svgElement = svg.cloneNode(true) as SVGElement;
      svgElement.classList.add('w-16', 'h-16');
      itemElement.appendChild(svgElement);
    }

    const labelElement = document.createElement('span');
    labelElement.classList.add('ml-8');
    labelElement.innerText = label;
    itemElement.appendChild(labelElement);

    itemElement.addEventListener('click', () => {
      this.insertNewBlock(toolName, data);
    });

    return itemElement;
  }

  insertNewBlock(toolName: string, data: any = {}): void {
    const currentBlockIndex = this.editor.blocks.getCurrentBlockIndex();
    const currentBlock = this.editor.blocks.getBlockByIndex(currentBlockIndex);

    if (!currentBlock) {
      return;
    }

    const index = currentBlock.isEmpty
      ? currentBlockIndex
      : currentBlockIndex + 1;

    const newBlock = this.editor.blocks.insert(
      toolName,
      data,
      undefined,
      index,
      true
    );

    this.editor.caret.setToBlock(index);

    this.editor.emit(ToolboxEvent.BlockAdded, {
      block: newBlock,
      data,
    });

    this.editor.toolbar.close();
  }
}
