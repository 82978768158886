import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import jsonToCsvFile from 'teamtailor/utils/json-to-csv-file';

export default class IndexController extends Controller {
  @service analytics;
  @service apollo;
  @service user;

  @tracked sortDirection = 'desc';
  @tracked sortProperty = 'pageviews';

  get sortedRows() {
    if (this.sortDirection === 'desc') {
      return this.model.rows.sortBy(this.sortProperty).reverse();
    } else {
      return this.model.rows.sortBy(this.sortProperty);
    }
  }

  @action
  handleExport() {
    const rows = this.model.rows.map((row) => ({
      job_id: row.jobId,
      job_title: row.jobTitle,
      pageviews: row.pageviews,
      candidates: row.numberOfCandidates,
      applications: row.numberOfApplications,
      averageRating: row.averageRating?.toFixed(1),
      sourced_applications: row.numberOfSourcedApplications,
      status: row.jobStatus || 'deleted',
    }));

    jsonToCsvFile(
      'job-overview',
      this.analytics.startDate,
      this.analytics.endDate,
      rows
    );
  }

  @action
  handleSort(sortProperty) {
    if (this.sortProperty === sortProperty) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortDirection = 'desc';
      this.sortProperty = sortProperty;
    }
  }
}
