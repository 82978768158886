import { attr, belongsTo, hasMany } from '@ember-data/model';
import { get, set } from '@ember/object';

import { MappedTypes, QUESTION_TYPES } from 'teamtailor/constants/question';

import OrganizableModel from 'teamtailor/models/organizable';

export default class QuestionModel extends OrganizableModel {
  @attr('raw') alternatives;
  @attr('raw') questionData;
  @attr('date') createdAt;
  @attr('string') title;
  @attr('string') description;
  @attr('string') type;
  @attr('boolean') multiple;
  @attr('string') singleLine;
  @attr('string') startWith;
  @attr('string') endWith;
  @attr('string') unit;
  @attr('string') toHuman;
  @attr('string') languageCode;
  @attr('string') group;
  @attr('boolean') isPrivate;
  @attr('boolean') addedByCopilot;
  @attr('boolean') createdByCopilot;

  @belongsTo('company', { async: true }) company;
  @belongsTo('question-detail', { async: true, inverse: 'question' })
  questionDetail;

  @belongsTo('scorecard-criterium', { async: true }) scorecardCriterium;

  @hasMany('tag', { async: true }) tags;
  @hasMany('translation/question') translations;

  get formData() {
    return get(this.questionData, 'alternatives');
  }

  set formData(alternatives) {
    const ids = alternatives.rejectBy('title', '').mapBy('id');
    const translations = get(this, 'translations') ?? [];

    // Empty values are removed on base model in the backend
    // This just keeps translations in sync when saving.
    translations.forEach((translation) => {
      const alts = get(translation, 'formData').filter((a) => {
        return ids.includes(a.id);
      });
      set(translation, 'formData', alts);
    });

    set(this.questionData, 'alternatives', alternatives);
    set(this, 'alternatives', alternatives.mapBy('title').join('\n'));
  }

  get simpleType() {
    return get(this, 'type')?.split(':').lastObject?.toLowerCase();
  }

  get alternativesArrayForChoices() {
    return get(this.questionData, 'alternatives');
  }

  get titleWithType() {
    return `${get(this, 'title')} (${get(this, 'toHuman')})`;
  }

  get titleWithTags() {
    return `${get(this, 'title')} (${get(this, 'tags').mapBy('name')})`;
  }

  get isBoolean() {
    return this.type === MappedTypes.boolean;
  }

  get isChoice() {
    return this.type === MappedTypes.choice;
  }

  get isRange() {
    return this.type === MappedTypes.range;
  }

  get isText() {
    return this.type === MappedTypes.text;
  }

  get isNumber() {
    return this.type === MappedTypes.number;
  }

  get isDate() {
    return this.type === MappedTypes.date;
  }

  get isVideo() {
    return this.type === MappedTypes.video;
  }

  get isFile() {
    return this.type === MappedTypes.file;
  }

  get isCustom() {
    return this.type === 'Question::Preset::Custom';
  }

  get isFavourites() {
    return this.type === 'Question::Preset::Favourites';
  }

  get isSong() {
    return this.type === 'Question::Preset::Song';
  }

  get isDefault() {
    return this.group === 'default';
  }

  get isPreset() {
    return this.group === 'preset';
  }

  get isCandidate() {
    return this.group === 'candidate';
  }

  get isMultipleChoice() {
    return this.isChoice && this.multiple;
  }

  get isSingleChoice() {
    return this.isChoice && !this.multiple;
  }

  get icon() {
    return (
      Object.entries(QUESTION_TYPES).find(
        ([type]) => type === this.simpleType
      )?.[1]?.icon || ''
    );
  }
}
