import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (not-eq @sortObject.value \"rowOrder\")}}\n  <button\n    type=\"button\"\n    class=\"body-text flex justify-center\"\n    {{on \"click\" (fn @updateSortDirection this.oppositeSortDirection)}}\n  >\n    <Stage::IndicatorItem\n      @icon={{this.sortDirectionIcon}}\n      @tooltip={{t\n        \"components.stage_list.sort_tooltip\"\n        sortValueText=@sortObject.label\n        sortDirectionText=this.sortDirectionText\n      }}\n    />\n  </button>\n{{/if}}", {"contents":"{{#if (not-eq @sortObject.value \"rowOrder\")}}\n  <button\n    type=\"button\"\n    class=\"body-text flex justify-center\"\n    {{on \"click\" (fn @updateSortDirection this.oppositeSortDirection)}}\n  >\n    <Stage::IndicatorItem\n      @icon={{this.sortDirectionIcon}}\n      @tooltip={{t\n        \"components.stage_list.sort_tooltip\"\n        sortValueText=@sortObject.label\n        sortDirectionText=this.sortDirectionText\n      }}\n    />\n  </button>\n{{/if}}","moduleName":"teamtailor/components/stage/sort-direction.hbs","parseOptions":{"srcName":"teamtailor/components/stage/sort-direction.hbs"}});
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { sortObject } from './types';

interface SortDirectionArgs {
  sortObject: sortObject;
  sortDirection: string;
}

export default class SortDirection extends Component<SortDirectionArgs> {
  @service declare intl: IntlService;

  get oppositeSortDirection(): string {
    return this.args.sortDirection === 'desc' ? 'asc' : 'desc';
  }

  get sortDirectionText(): string {
    return this.args.sortDirection === 'desc'
      ? this.intl.t('components.stage_list.sort_desc')
      : this.intl.t('components.stage_list.sort_asc');
  }

  get sortDirectionIcon(): string {
    return this.args.sortDirection === 'desc'
      ? 'arrow-down-z-a'
      : 'arrow-down-a-z';
  }
}
