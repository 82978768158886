import {
  validatePresenceOfEmailIfNoLogin,
  validatePresenceOfNameIfNoLogin,
} from 'teamtailor/validators/user';

import validateRegex from 'teamtailor/validators/regex';
import { validateLength } from 'ember-changeset-validations/validators';

const urlRegex =
  /(^$)|(^(http|https):\/\/[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(([0-9]{1,5})?\/.*)?$)/;
const emailRegex = /.+@.+/;

export default {
  name: [validateLength({ max: 255 }), validatePresenceOfNameIfNoLogin()],
  title: validateLength({ max: 255 }),
  email: validateRegex({
    pattern: emailRegex,
    messageKey: 'email',
  }),

  inviteEmail: [
    validateRegex({
      pattern: emailRegex,
      messageKey: 'email',
    }),
    validatePresenceOfEmailIfNoLogin(),
  ],

  twitterProfile: [
    validateRegex({
      pattern: urlRegex,
      messageKey: 'url',
    }),
  ],

  facebookProfile: [
    validateRegex({
      pattern: urlRegex,
      messageKey: 'url',
    }),
  ],

  linkedinProfile: [
    validateRegex({
      pattern: urlRegex,
      messageKey: 'url',
    }),
  ],

  instagramProfile: [
    validateRegex({
      pattern: urlRegex,
      messageKey: 'url',
    }),
  ],

  otherProfile: [
    validateRegex({
      pattern: urlRegex,
      messageKey: 'url',
    }),
  ],
};
