export default {
  "root": "AddReaction__root_1intyn",
  "root--isHovering": "AddReaction__root--isHovering_1intyn",
  "root--hasNoReactions": "AddReaction__root--hasNoReactions_1intyn",
  "emoji": "AddReaction__emoji_1intyn",
  "emojiWrapper": "AddReaction__emojiWrapper_1intyn",
  "emojiWrapper--hasReactions": "AddReaction__emojiWrapper--hasReactions_1intyn",
  "emojiWrapper--show": "AddReaction__emojiWrapper--show_1intyn",
  "toggleBtn": "AddReaction__toggleBtn_1intyn"
};
