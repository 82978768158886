import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { get, action } from '@ember/object';

export default class JobsController extends Controller {
  @service intl;
  @service router;

  get requisition() {
    return this.model;
  }

  get disabledCreateJobButton() {
    return (
      get(this, 'requisition.isCompleted') ||
      get(this, 'requisition.archivedAt')
    );
  }

  get hiresTitle() {
    const { hiredCount, numberOfOpenings } = this.requisition;

    if (hiredCount && numberOfOpenings) {
      return this.intl.t('employees.index.hires_count_of', {
        hiredCount,
        numberOfOpenings,
      });
    } else if (hiredCount) {
      return this.intl.t('employees.index.hires_count', { hiredCount });
    } else {
      return this.intl.t('employees.index.hires');
    }
  }

  @action
  showJobTemplatesModal() {
    this.router.transitionTo(
      'jobs.requisition.jobs.job-templates',
      get(this, 'requisition.id')
    );
  }
}
