import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import FromRoute from 'teamtailor/routes/content/index/page/settings/sharing-image';
import ImageModel from 'teamtailor/models/image';

export default class ContentIndexPageSettingsSharingImage extends Controller {
  declare model: ModelFrom<FromRoute>;

  @service declare store: Store;

  @action
  handlePickImage(image: ImageModel) {
    const sharingImage = get(this.model, 'sharingImage');

    const pickedImage = this.store.createRecord('picked-image', {
      sharingImage,
    });

    set(pickedImage, 'image', image);
    set(sharingImage, 'pickedImage', pickedImage);
  }

  @action
  handleRemoveImage() {
    const pickedImage = get(this.model.sharingImage, 'pickedImage');
    pickedImage?.deleteRecord();
    set(this.model.sharingImage, 'pickedImage', null);
  }

  @action
  save() {
    this.model.save();
  }
}

declare module '@ember/controller' {
  interface Registry {
    'content.index.page.settings.sharing-image': ContentIndexPageSettingsSharingImage;
  }
}
