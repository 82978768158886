import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    meetingEventAttendees: {
      key: 'meeting_event_attendees_attributes',
      serialize: 'records',
    },
  },
});
