import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import { trackedFunction } from 'ember-resources/util/function';
import sortByRowOrder from 'teamtailor/utils/sort-by-row-order';
import CandidateModalController from 'teamtailor/controllers/candidate-modal';

export default class CandidateController extends CandidateModalController {
  @service appRefresher;

  @tracked jobApplication = null;
  @tracked stage = null;
  @tracked showRejected = false;

  @tracked job;
  @tracked candidateIds;
  @tracked browseModelsTotal = 0;

  params = {};

  _browseModelsCache = [];

  get candidate() {
    return this.model;
  }

  get isSortingManually() {
    const sortValue = get(this.stage, 'sortValue');
    return sortValue === 'rowOrder' || !sortValue;
  }

  get browseModels() {
    return this._browseModels.value;
  }

  get filteredCandidateIds() {
    return this.candidateIds;
  }

  get isAnonymousStage() {
    let isAnonymousSuggestedCandidate = false;

    if (this.job.stages.findBy('inbox')?.anonymous) {
      const candidateSuggestions = get(
        this.job,
        'jobDetail.candidateSuggestions'
      );

      isAnonymousSuggestedCandidate =
        candidateSuggestions?.find((suggestion) => {
          return get(suggestion, 'candidate.id') === get(this.candidate, 'id');
        }) !== undefined;
    }

    return isAnonymousSuggestedCandidate || get(this.stage, 'anonymous');
  }

  _browseModels = trackedFunction(this, async () => {
    let jobApplications = (await get(this.stage, 'jobApplications')).filter(
      (jobApplication) => {
        return this.showRejected
          ? get(jobApplication, 'isRejected')
          : get(jobApplication, 'isNotRejected');
      }
    );

    if (this.candidateIds && this.candidateIds.length) {
      jobApplications = jobApplications.filter((jobApplication) => {
        return this.candidateIds.includes(get(jobApplication, 'candidateId'));
      });
    }

    if (jobApplications.length > this._browseModelsCache.length) {
      if (this.isSortingManually) {
        jobApplications = sortByRowOrder(jobApplications);
      }

      this._browseModelsCache = [
        ...this._browseModelsCache,
        ...jobApplications,
      ].filter((value, index, array) => array.indexOf(value) === index);

      await get(this.stage, 'jobApplicationCount');

      const loadedCount = this.showRejected
        ? get(this.stage, 'rejectedJobApplicationsCount')
        : get(this.stage, 'activeJobApplicationsCount');
      const stageCount = this.showRejected
        ? get(this.stage, 'rejectedCount')
        : get(this.stage, 'activeCount');

      this.browseModelsTotal = this.filteredCandidateIds
        ? loadedCount
        : stageCount;
    }

    return this._browseModelsCache;
  });

  loadMoreJobApplications = () => {
    this.stage.fetchJobApplicationsTask.perform({
      rejected: this.showRejected,
      reload: false,
      filter: this.params,
    });
  };

  @action
  handleModalClose() {
    if (this.jobApplication) {
      this.jobApplication.reload();
    }

    this.appRefresher.transitionOrNavigateTo('jobs.job.stages.index');
  }
}
