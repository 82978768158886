import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<DataTable::Cell\n  @data={{@data}}\n  @onMouseEnterColumn={{@onMouseEnterColumn}}\n  @onMouseExitColumn={{@onMouseExitColumn}}\n  @hovering={{@hovering}}\n  @lastRow={{@lastRow}}\n  class={{unless this.validValue \"zero\"}}\n>\n  {{#if this.validValue}}\n    {{this.percentage}}%\n  {{else}}\n    {{t \"components.data_table.not_available\"}}\n  {{/if}}\n</DataTable::Cell>", {"contents":"<DataTable::Cell\n  @data={{@data}}\n  @onMouseEnterColumn={{@onMouseEnterColumn}}\n  @onMouseExitColumn={{@onMouseExitColumn}}\n  @hovering={{@hovering}}\n  @lastRow={{@lastRow}}\n  class={{unless this.validValue \"zero\"}}\n>\n  {{#if this.validValue}}\n    {{this.percentage}}%\n  {{else}}\n    {{t \"components.data_table.not_available\"}}\n  {{/if}}\n</DataTable::Cell>","moduleName":"teamtailor/components/data-table/percentage.hbs","parseOptions":{"srcName":"teamtailor/components/data-table/percentage.hbs"}});
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';

type Args = {
  data: {
    value: number;
  };
};

export default class DataTablePercentageComponent extends Component<Args> {
  get value() {
    return this.args.data.value;
  }

  get validValue() {
    if (isNaN(this.value) || this.value === Infinity) {
      return false;
    } else {
      return !isEmpty(this.value);
    }
  }

  get percentage() {
    return (this.value * 100).toFixed(2);
  }
}
