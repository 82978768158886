export const getOriginalPromotionPriceForCountry = (channel) => {
  if (channel.internalName === 'indeed') {
    return channel.priceEur;
  }

  const { countryForPromotion } = channel;

  switch (countryForPromotion) {
    case 'Sweden':
      return channel.priceSek;

    case 'United Kingdom':
      return channel.priceGbp;

    case 'Denmark':
      return channel.priceDkk;

    case 'United States':
      return channel.priceUsd;

    default:
      return channel.priceEur;
  }
};

export const getDiscountedPromotionPriceForCountry = (channel) => {
  const { countryForPromotion } = channel;

  switch (countryForPromotion) {
    case 'Sweden':
      return channel.discountPriceSek;

    case 'United Kingdom':
      return channel.discountPriceGbp;

    case 'Denmark':
      return channel.discountPriceDkk;

    case 'United States':
      return channel.discountPriceUsd;

    default:
      return channel.discountPriceEur;
  }
};
