import Model, { attr } from '@ember-data/model';

export default class LocaleModel extends Model {
  @attr('string') declare name: string;
  @attr('string') declare code: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    locale: LocaleModel;
  }
}
