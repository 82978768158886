import TagQueryService from 'teamtailor/services/tag-query';

export default class ImageTagsService extends TagQueryService {
  taggableType = 'Image';
}

declare module '@ember/service' {
  interface Registry {
    'image-tags': ImageTagsService;
  }
}
