import Model, { attr } from '@ember-data/model';
import { get } from 'teamtailor/utils/get';
import { isEmpty } from '@ember/utils';
import Current from 'teamtailor/services/current';
import { inject as service } from '@ember/service';

export default class MeetingRoomResourceModel extends Model {
  @service declare current: Current;

  @attr('string') declare name: string;
  @attr('string') declare generatedName: string;
  @attr('string') declare email: string;

  get meetingRoom() {
    return get(this.current.company, 'meetingRooms').find(
      (meetingRoom) => meetingRoom.resourceEmail === this.email
    );
  }

  get label() {
    return this.name;
  }

  get subtitle() {
    return this.generatedName !== this.name ? this.generatedName : '';
  }

  get hasMeetingRoom() {
    return !!this.meetingRoom && !isEmpty(this.meetingRoom);
  }

  get isActive() {
    return this.hasMeetingRoom && get(this.meetingRoom, 'bookable');
  }

  get isPending() {
    return this.isActive && !get(this.meetingRoom, 'resourceId');
  }

  get resourceName() {
    return this.generatedName || this.name;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'meeting-room-resource': MeetingRoomResourceModel;
  }
}
