export const DEFAULT_SIZE = '20';
export const ALLOWED_SIZES = [
  '8',
  '10',
  '12',
  '14',
  '16',
  DEFAULT_SIZE,
  '24',
  '36',
  '48',
  '58',
  '76',
] as const;

export type AllowedSizes = (typeof ALLOWED_SIZES)[number];

export const DEFAULT_STYLE = 'regular';
export const ALLOWED_STYLES = [
  'solid',
  DEFAULT_STYLE,
  'brands',
  'kit',
  'light',
  'duotone',
] as const;

export const SIZE_CLASSES = {
  8: 'size-8 text-[8px]',
  10: 'size-10 text-[10px]',
  12: 'size-12 text-[10px]',
  14: 'size-14 text-[12px]',
  16: 'size-16 text-[14px]',
  20: 'size-20 text-[16px]',
  24: 'size-24 text-[20px]',
  36: 'size-36 text-[28px]',
  48: 'size-48 text-[38px]',
  58: 'size-[58px] text-[48px]',
  76: 'size-76 text-[72px]',
};
