import { get } from 'teamtailor/utils/get';
import { hash } from 'rsvp';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';

export default class AutomaticDeletionRoute extends ScrollToTopRoute {
  @service current;
  @service store;

  async model() {
    return hash({
      automaticDeletionSetting:
        (await get(this.current.company, 'automaticDeletionSetting')) ||
        this.store.createRecord('automatic-deletion-setting'),

      willBeDeletedCount: await this.store.query('candidate', {
        root: [{ will_be_deleted_at: { exists: 'yes' } }],
        per_page: 1,
      }),
    });
  }
}
