import PermissionsRoute from 'teamtailor/routes/permissions';
import { inject as service } from '@ember/service';

export default class NewRoute extends PermissionsRoute {
  @service current;
  @service store;

  requiredPermissions = 'nurture_campaign/create';
  noAccessEntityNameKey = 'nurture_campaigns.title';

  model() {
    return this.store.createRecord('nurture-campaign', {
      company: this.current.company,
    });
  }
}
