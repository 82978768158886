import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<DataTable::Cell\n  @data={{@data}}\n  @onMouseEnterColumn={{@onMouseEnterColumn}}\n  @onMouseExitColumn={{@onMouseExitColumn}}\n  @hovering={{@hovering}}\n  @lastRow={{@lastRow}}\n>\n  <span>\n    {{this.durationString}}\n  </span>\n\n  <EmberTooltip @text={{lowercase this.tooltipString}} />\n</DataTable::Cell>", {"contents":"<DataTable::Cell\n  @data={{@data}}\n  @onMouseEnterColumn={{@onMouseEnterColumn}}\n  @onMouseExitColumn={{@onMouseExitColumn}}\n  @hovering={{@hovering}}\n  @lastRow={{@lastRow}}\n>\n  <span>\n    {{this.durationString}}\n  </span>\n\n  <EmberTooltip @text={{lowercase this.tooltipString}} />\n</DataTable::Cell>","moduleName":"teamtailor/components/data-table/duration.hbs","parseOptions":{"srcName":"teamtailor/components/data-table/duration.hbs"}});
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import round from 'teamtailor/utils/round';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

type Args = {
  data: {
    value: string;
  };
};

export default class DataTableDurationComponent extends Component<Args> {
  @service declare intl: IntlService;

  get durationString() {
    return round(this.duration.asDays());
  }

  get seconds() {
    return this.args.data.value;
  }

  get tooltipString() {
    const days = Math.round(this.duration.asDays());
    const hours = Math.round(this.duration.asHours());
    const minutes = Math.round(this.duration.asMinutes());
    const { seconds } = this;

    if (days > 0) {
      return this.intl.t('common.x_days', { count: days });
    } else if (hours > 0) {
      return this.intl.t('common.x_hours', { count: hours });
    } else if (minutes > 0) {
      return this.intl.t('common.x_minutes', { count: minutes });
    } else {
      return this.intl.t('common.x_seconds', { count: seconds });
    }
  }

  get duration() {
    return moment.duration(this.seconds, 'seconds');
  }
}
