import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Molecules::TopActivities\n  ...attributes\n  class=\"flex\"\n  @activitySummary={{this.activitySummary}}\n  @loading={{or (not this.activitySummary) this.isRunning}}\n  @onShowExportModal={{this.onShowExportModal}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>", {"contents":"<Insights::Molecules::TopActivities\n  ...attributes\n  class=\"flex\"\n  @activitySummary={{this.activitySummary}}\n  @loading={{or (not this.activitySummary) this.isRunning}}\n  @onShowExportModal={{this.onShowExportModal}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>","moduleName":"teamtailor/components/insights/widgets/user-top-activities.hbs","parseOptions":{"srcName":"teamtailor/components/insights/widgets/user-top-activities.hbs"}});
import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { gql } from '@apollo/client/core';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';

const DATA_QUERY = gql`
  query UserTopActivitiesQuery($dateRange: DateRangeAttributes!, $userId: ID!) {
    user: eventQuery(
      dateRange: $dateRange
      filters: { userId: { equals: $userId } }
    ) {
      activitySummary: aggregated {
        moves: countOccurrences(filters: { eventType: { equals: MOVED } })
        hires: countOccurrences(filters: { eventType: { equals: HIRED } })
        rejections: countOccurrences(
          filters: { eventType: { equals: REJECTED } }
        )
        referrals: countOccurrences(
          filters: { eventType: { equals: REFERRAL } }
        )
        sources: countOccurrences(filters: { eventType: { equals: SOURCED } })
        notes: countOccurrences(filters: { eventType: { equals: NOTE } })
        messages: countOccurrences(filters: { eventType: { equals: MESSAGE } })
        reviews: countOccurrences(filters: { eventType: { equals: REVIEW } })
        scores: countOccurrences(filters: { eventType: { equals: SCORE } })
        interviews: countOccurrences(
          filters: { eventType: { equals: INTERVIEW_CREATED } }
        )
      }
    }
  }
`;

const EXPORT_TABLE_COLUMNS = [
  {
    type: 'text',
    propertyName: 'userId',
    headerKey: 'common.user_id',
  },
  {
    type: 'number',
    propertyName: 'moves',
    headerKey: 'common.moves',
  },
  {
    type: 'number',
    propertyName: 'hires',
    headerKey: 'common.hires',
  },
  {
    type: 'number',
    propertyName: 'rejections',
    headerKey: 'common.rejections',
  },
  {
    type: 'number',
    propertyName: 'sources',
    headerKey: 'common.sources',
  },
  {
    type: 'number',
    propertyName: 'notes',
    headerKey: 'common.notes',
  },
  {
    type: 'number',
    propertyName: 'messages',
    headerKey: 'common.messages',
  },
  {
    type: 'number',
    propertyName: 'reviews',
    headerKey: 'common.reviews',
  },
];

export default class InsightsWidgetsUserTopActivities extends Component {
  @service insights;
  @service store;
  @service insightsExport;

  get activitySummary() {
    return this.fetchData.lastSuccessful?.value?.activitySummary;
  }

  get isRunning() {
    return this.fetchData.isRunning;
  }

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      'insights.users.common.referrals',
      `employee-${this.args.user.id}`,
      EXPORT_TABLE_COLUMNS,
      this.retrieveExportData,
      this.args.dateRange
    );
  }

  @action
  retrieveExportData(dateRange) {
    return this.queryForData(dateRange).then((data) => [
      data.user.activitySummary.firstObject,
    ]);
  }

  @action
  queryForData(dateRange) {
    const { user } = this.args;
    const userId = get(user, 'id');

    return this.insights.query({
      query: DATA_QUERY,
      variables: {
        dateRange: dateRange.asObject,
        userId,
      },
    });
  }

  fetchData = dropTask(async () => {
    const { user: userResponse } = await this.queryForData(this.args.dateRange);
    const { activitySummary } = userResponse;

    return { activitySummary: activitySummary.firstObject };
  });
}
