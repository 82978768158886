export const chartTypes = (intl) => [
  {
    value: 'doughnut',
    text: intl.t(
      'content.editor.section.infographic.chart_type_options.doughnut'
    ),
  },
  {
    value: 'line',
    text: intl.t('content.editor.section.infographic.chart_type_options.line'),
  },
  {
    value: 'area',
    text: intl.t('content.editor.section.infographic.chart_type_options.area'),
  },
  {
    value: 'bar',
    text: intl.t('content.editor.section.infographic.chart_type_options.bar'),
  },
];
