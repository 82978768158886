import Model, { attr } from '@ember-data/model';

export default class ConnectedCalendarModel extends Model {
  @attr('boolean', { defaultValue: false })
  declare calendarIntegratedConferencingAvailable: boolean;

  @attr('boolean', { defaultValue: false }) declare useForInserts: boolean;
  @attr('string') declare calendarId: string;
  @attr('string') declare email: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'connected-calendar': ConnectedCalendarModel;
  }
}
