import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { get } from 'teamtailor/utils/get';

import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import Store from '@ember-data/store';
import TtAlertService from 'teamtailor/services/tt-alert';
import IntlService from 'ember-intl/services/intl';
import RouterService from '@ember/routing/router-service';
import FlashMessageService from 'teamtailor/services/flash-message';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import FromRoute from 'teamtailor/routes/settings/integrations/channel-activation/oauth';

export default class SettingsIntegrationsPartnerActivationOauthController extends Controller {
  declare model: ModelFrom<FromRoute>;

  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;

  get channel() {
    return get(get(this.model, 'channel'), 'content');
  }

  handleOauthActivation = task(async (callback: () => void) => {
    await callback();
  });

  @action async onOauthSuccess({
    authorization: data,
  }: {
    authorization: { type: string; id: string };
  }) {
    try {
      const authorization = this.store.createRecord(
        'authorization',
        Object.assign(data)
      );

      await authorization.save();

      set(this.model, 'authorization', authorization);

      await this.model.save();

      this.flashMessages.success(
        this.intl.t('marketplace.channel_reconnected', {
          subject: this.channel?.name,
        })
      );
      return this.router.transitionTo(
        'settings.integrations.marketplace-activations'
      );
    } catch (err) {
      return this.flashMessages.error(
        this.intl.t('errors.something_went_wrong')
      );
    }
  }

  @action onOauthFailure() {
    this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
  }

  @action
  delete() {
    this.ttAlert.customConfirm({
      title: this.intl.t('common.are_you_sure'),
      text: this.intl.t(
        'settings.integrations.partner_activations.deactivate_alert.text'
      ),

      confirmButtonText: this.intl.t(
        'settings.integrations.partner_activations.deactivate_alert.confirm'
      ),

      cancelButtonText: this.intl.t('common.cancel'),
      confirmCallback: async () => {
        await this.model.destroyRecord();
        this.flashMessages.success(
          this.intl.t(
            'settings.integrations.partner_activations.deactivate_alert.partner_deleted'
          )
        );
        this.router.transitionTo('settings.general');
      },
    });
  }

  @action
  closeModal() {
    this.router.transitionTo('settings.integrations.marketplace-activations');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.integrations.channel-activation.oauth': SettingsIntegrationsPartnerActivationOauthController;
  }
}
