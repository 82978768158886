import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n  <DatetimePicker\n    @inputClass=\"hidden\"\n    @value={{this.delayTimestamp}}\n    @enableTime={{false}}\n    @inline={{true}}\n    @range={{true}}\n    @maxDate={{this.maxDate}}\n    @minDate={{or @minDate this.minDate}}\n    @class={{local-class \"flatpickrCalendar\"}}\n    @onChange={{this.changeDate}}\n    @clearEnabled={{this.delayTimestamp}}\n    @verticalPosition={{true}}\n    @onClear={{fn this.changeDate null}}\n    @position=\"above\"\n  />\n\n  <div local-class=\"footer\"></div>\n</div>", {"contents":"<div>\n  <DatetimePicker\n    @inputClass=\"hidden\"\n    @value={{this.delayTimestamp}}\n    @enableTime={{false}}\n    @inline={{true}}\n    @range={{true}}\n    @maxDate={{this.maxDate}}\n    @minDate={{or @minDate this.minDate}}\n    @class={{local-class \"flatpickrCalendar\"}}\n    @onChange={{this.changeDate}}\n    @clearEnabled={{this.delayTimestamp}}\n    @verticalPosition={{true}}\n    @onClear={{fn this.changeDate null}}\n    @position=\"above\"\n  />\n\n  <div local-class=\"footer\"></div>\n</div>","moduleName":"teamtailor/components/insights/date-picker-button/date-range.hbs","parseOptions":{"srcName":"teamtailor/components/insights/date-picker-button/date-range.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, setProperties } from '@ember/object';
import { isPresent } from '@ember/utils';
import moment from 'moment-timezone';

export default class InsightsDatePickerButtonDateRange extends Component {
  maxDate = new Date();
  defaultMinDate = moment('2016-06-01').toDate();

  @tracked selectedStartDate = null;
  @tracked selectedEndDate = null;

  @action
  changeDate([startDate, endDate]) {
    if (isPresent(startDate) && isPresent(endDate)) {
      this.args.onUpdate(startDate, endDate);
    }

    setProperties(this, {
      selectedStartDate: startDate,
      selectedEndDate: endDate,
    });
  }
}
