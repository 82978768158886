import Model, {
  belongsTo,
  attr,
  hasMany,
  AsyncBelongsTo,
  SyncHasMany,
} from '@ember-data/model';
import { inject as service } from '@ember/service';
import UserModel from 'teamtailor/models/user';
import { get } from 'teamtailor/utils/get';
import VideoRoomModel from 'teamtailor/models/video-room';
import MeetingEventLocationModel from 'teamtailor/models/meeting-event-location';
import { ChatMessage } from 'teamtailor/utils/video-meetings/utils';

export default class VideoMeetingModel extends Model {
  @service declare user: UserModel;

  @belongsTo('meeting-event-location', { async: true })
  declare meetingEventLocation: AsyncBelongsTo<MeetingEventLocationModel>;

  @belongsTo('user', { async: true })
  declare copilotSummaryUpdatedBy: AsyncBelongsTo<UserModel>;

  @hasMany('video-room', { async: false })
  declare videoRooms: SyncHasMany<VideoRoomModel>;

  @attr('string') declare appName: string;
  @attr('string') declare copilotSummary: string;
  @attr('string') declare meetingDuration: string;
  @attr('string') declare meetingUrl: string;
  @attr('string') declare providerName: string;
  @attr('string') declare roomName: string;
  @attr('string') declare roomSid: string;
  @attr('string') declare uuid: string;
  @attr('string') declare videoUrl: string;
  @attr('date') declare copilotSummaryUpdatedAt: Date;
  @attr declare chatHistory: ChatMessage[];

  get isTeamtailor(): boolean {
    return get(this, 'providerName') === 'teamtailor';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'video-meeting': VideoMeetingModel;
  }
}
