import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';

import {
  CandidateModel,
  JobApplicationModel,
  JobModel,
  JobDetailModel,
} from 'teamtailor/models';
import Server from 'teamtailor/services/server';

type AddToJobResponse = {
  job_applications: { active_stage_id: string }[];
};

export default class CandidateSuggestionModel extends Model {
  @service declare server: Server;

  @belongsTo('job') declare job: AsyncBelongsTo<JobModel>;
  @belongsTo('job-detail')
  declare jobDetail: AsyncBelongsTo<JobDetailModel>;

  @belongsTo('candidate', { async: false })
  declare candidate: CandidateModel;

  @belongsTo('job-application')
  declare jobApplication: AsyncBelongsTo<JobApplicationModel>;

  @attr('boolean') declare discarded: boolean;
  @attr('boolean') declare visible: boolean;
  @attr('number') declare score: number;
  @attr('string') declare text: string;
  @attr('date') declare createdAt: Date;

  async addToJob() {
    const response = await this.server.memberAction<AddToJobResponse>(this, {
      action: 'add_to_job',
    });

    this.store.pushPayload(response);

    // Need to refetch stage count for job application to show up
    // Same as done in Stage model
    const stageId = response.job_applications[0]?.active_stage_id;
    if (stageId) {
      this.store.findRecord('stage-job-application-count', stageId);
    }
  }

  async discard() {
    const response = await this.server.memberAction<AddToJobResponse>(this, {
      action: 'discard',
    });

    this.store.pushPayload(response);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'candidate-suggestion': CandidateSuggestionModel;
  }
}
