import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class RatingsController extends Controller {
  @service current;

  @action
  ratingPlaceholder(index) {
    switch (index) {
      case 0:
        return 'candidate.ratings.very_poor';
      case 1:
        return 'candidate.ratings.poor';
      case 2:
        return 'candidate.ratings.ok';
      case 3:
        return 'candidate.ratings.good';
      case 4:
        return 'candidate.ratings.very_good';
    }
  }

  @action
  updateRating(index, event) {
    this.current.company.ratingDescriptions[index] = event.target.value;
  }

  @action
  save() {
    return this.current.company.save();
  }
}
