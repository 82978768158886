import Controller from '@ember/controller';
import { set, action, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class ScorecardController extends Controller {
  @service current;
  @service store;

  get availableCriteria() {
    return get(this.current.company, 'scorecardCriteria').filter(
      (x) => get(this, 'pickedCriteria').indexOf(x) === -1
    );
  }

  get pickedCriteria() {
    return get(this.model.jobDetail, 'scorecardPicks').mapBy(
      'scorecardCriterium'
    );
  }

  get availablePersonalityCriteria() {
    return this.availableCriteria.filterBy('topic', 'personality');
  }

  get availableSkillsCriteria() {
    return this.availableCriteria.filterBy('topic', 'skills');
  }

  @action
  removeScorecardPick(scorecardPick) {
    if (get(scorecardPick, 'isDeleted')) {
      scorecardPick.rollbackAttributes();
    } else {
      scorecardPick.deleteRecord();
    }
  }

  @action
  selectCriterium(scorecardCriterium) {
    let pick = get(this, 'store').createRecord('scorecardPick', {
      scorecardCriterium,
      jobDetail: get(this, 'model.jobDetail'),
    });
    get(this, 'model.jobDetail.scorecardPicks').addObject(pick);
    set(this, 'skillsSelection', undefined);
  }

  @action
  createPersonalityCriterium(criteriumName) {
    let criterium = get(this, 'store').createRecord('scorecard-criterium', {
      name: criteriumName,
      topic: 'personality',
      company: this.current.company,
    });
    criterium.save();
    this.selectCriterium(criterium);
  }

  @action
  createSkillsCriterium(criteriumName) {
    let criterium = get(this, 'store').createRecord('scorecard-criterium', {
      name: criteriumName,
      topic: 'skills',
      company: this.current.company,
    });
    criterium.save();
    this.selectCriterium(criterium);
  }

  @action
  handleDropCriterium({
    draggedItem,
    sourceList,
    sourceIndex,
    targetList,
    targetIndex,
  }) {
    if (sourceIndex === targetIndex) {
      return;
    }

    sourceList.removeAt(sourceIndex);
    targetList.insertAt(targetIndex, draggedItem);

    targetList.forEach((item, index) => {
      setProperties(item, {
        rowOrderPosition: index,
        rowOrder: index,
      });
    });
  }
}
