import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import LocationModel from 'teamtailor/models/location';
import VideoMeetingModel from 'teamtailor/models/video-meeting';
import MeetingEventModel from 'teamtailor/models/meeting-event';

type CustomLocation = {
  description: string;
};
export default class MeetingEventLocationModel extends Model {
  @belongsTo('meeting-event')
  declare meetingEvent: AsyncBelongsTo<MeetingEventModel>;

  @belongsTo('location')
  declare location: AsyncBelongsTo<LocationModel>;

  @belongsTo('video-meeting')
  declare videoMeeting: AsyncBelongsTo<VideoMeetingModel>;

  @attr
  declare customLocation?: CustomLocation;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'meeting-event-location': MeetingEventLocationModel;
  }
}
