import Helper from '@ember/component/helper';
import { FilterOption } from 'teamtailor/components/fancy-filters';

export function fancyFilterTriggerIconStyle([filterObject]: FilterOption[]):
  | string
  | void {
  if (filterObject?.triggerIconStyle) {
    return filterObject.triggerIconStyle;
  } else if (filterObject?.iconStyle) {
    return filterObject.iconStyle;
  } else {
    return;
  }
}

export default Helper.helper(fancyFilterTriggerIconStyle);
