import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.filteredStageTriggers.length}}\n  <div class=\"flex flex-col gap-12\">\n    {{#each this.filteredStageTriggers as |trigger_|}}\n      <StageTrigger\n        @onClick={{fn @onClick trigger_}}\n        {{on \"mouseenter\" (fn @onMouseEnter trigger_)}}\n        @job={{@job}}\n        @trigger_={{trigger_}}\n        @isDisabled={{not (has-permission \"trigger/update\")}}\n      />\n    {{/each}}\n  </div>\n{{/if}}", {"contents":"{{#if this.filteredStageTriggers.length}}\n  <div class=\"flex flex-col gap-12\">\n    {{#each this.filteredStageTriggers as |trigger_|}}\n      <StageTrigger\n        @onClick={{fn @onClick trigger_}}\n        {{on \"mouseenter\" (fn @onMouseEnter trigger_)}}\n        @job={{@job}}\n        @trigger_={{trigger_}}\n        @isDisabled={{not (has-permission \"trigger/update\")}}\n      />\n    {{/each}}\n  </div>\n{{/if}}","moduleName":"teamtailor/components/stage-triggers-list.hbs","parseOptions":{"srcName":"teamtailor/components/stage-triggers-list.hbs"}});
import { get } from 'teamtailor/utils/get';
import Component from '@glimmer/component';

export default class StageTriggersListComponent extends Component {
  get stageTriggers() {
    return this.args.stage.triggers;
  }

  get visibleTriggers() {
    const stageTriggers = get(this, 'stageTriggers');
    return stageTriggers.filter(
      (trigger) =>
        !get(trigger, 'isNew') &&
        !get(trigger, 'isDeleted') &&
        !get(trigger, 'hideOnStage')
    );
  }

  get sortedStageTriggers() {
    return this.visibleTriggers.sortBy(
      'visibleTriggers',
      'delayJobFor',
      'created_at'
    );
  }

  get filteredStageTriggers() {
    return get(this, 'sortedStageTriggers').filterBy(
      'onReject',
      this.args.onReject
    );
  }
}
