import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes>\n  <div class=\"body-text-s-medium mb-8 text-neutral\">\n    {{t \"insights.molecules.schedule_report_modal.email_time\"}}\n  </div>\n\n  <div class=\"flex items-center gap-8\">\n    <Meeting::TimeInput\n      @date={{this.time}}\n      @tzid={{this.tzid}}\n      @onChange={{this.setTime}}\n    />\n    <Core::TimeZonePicker\n      @key=\"id\"\n      @selected={{this.tzid}}\n      @onSelect={{this.selectTimeZone}}\n      @icon=\"globe\"\n      @isFullWidth={{true}}\n      class=\"border-none shadow-none\"\n    />\n  </div>\n</div>", {"contents":"<div ...attributes>\n  <div class=\"body-text-s-medium mb-8 text-neutral\">\n    {{t \"insights.molecules.schedule_report_modal.email_time\"}}\n  </div>\n\n  <div class=\"flex items-center gap-8\">\n    <Meeting::TimeInput\n      @date={{this.time}}\n      @tzid={{this.tzid}}\n      @onChange={{this.setTime}}\n    />\n    <Core::TimeZonePicker\n      @key=\"id\"\n      @selected={{this.tzid}}\n      @onSelect={{this.selectTimeZone}}\n      @icon=\"globe\"\n      @isFullWidth={{true}}\n      class=\"border-none shadow-none\"\n    />\n  </div>\n</div>","moduleName":"teamtailor/components/insights/molecules/schedule-report-modal/time-picker.hbs","parseOptions":{"srcName":"teamtailor/components/insights/molecules/schedule-report-modal/time-picker.hbs"}});
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import ScheduledReport from 'teamtailor/models/scheduled-report';
import { get } from 'teamtailor/utils/get';
import moment, { Moment } from 'moment-timezone';

type Args = {
  scheduledReport: ScheduledReport;
};

export default class InsightsScheduleReportModalTimePicker extends Component<Args> {
  @service declare store: Store;

  get time() {
    const [hour, minute] = get(
      this.args.scheduledReport.settings,
      'time'
    ).split(':');
    return moment()
      .tz(this.tzid)
      .hour(parseInt(hour || '0'))
      .minute(parseInt(minute || '0'));
  }

  get tzid() {
    return get(this.args.scheduledReport.settings, 'tzid');
  }

  @action
  setTime(time: Moment) {
    set(this.args.scheduledReport.settings, 'time', time.format('HH:mm'));
  }

  @action
  selectTimeZone(tzid: string) {
    set(this.args.scheduledReport.settings, 'tzid', tzid);
  }
}
