export default {
  "spot": "Spot__spot_17bp29",
  "image": "Spot__image_17bp29",
  "position": "Spot__position_17bp29",
  "position--1": "Spot__position--1_17bp29",
  "position--2": "Spot__position--2_17bp29",
  "position--3": "Spot__position--3_17bp29",
  "name": "Spot__name_17bp29",
  "count": "Spot__count_17bp29"
};
