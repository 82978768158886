import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"group bg-base-grey-25\"\n  local-class={{class-names\n    \"item\"\n    item--selected=(or @picked @image.isPicked)\n    item--selectable=this.selectable\n  }}\n  tabindex={{if this.selectable 0 -1}}\n  data-masonry-list-image=\"true\"\n  data-test-masonry-list-image\n  {{on \"click\" this.handleClick}}\n  {{on \"focus\" @onFocus}}\n  style={{this.imageStyle}}\n>\n  <img\n    src={{this.imageUrl}}\n    alt={{or @image.alt @image.filename}}\n    class=\"h-auto w-full\"\n  />\n\n  <div local-class=\"inner\">\n    {{yield}}\n  </div>\n</div>", {"contents":"<div\n  class=\"group bg-base-grey-25\"\n  local-class={{class-names\n    \"item\"\n    item--selected=(or @picked @image.isPicked)\n    item--selectable=this.selectable\n  }}\n  tabindex={{if this.selectable 0 -1}}\n  data-masonry-list-image=\"true\"\n  data-test-masonry-list-image\n  {{on \"click\" this.handleClick}}\n  {{on \"focus\" @onFocus}}\n  style={{this.imageStyle}}\n>\n  <img\n    src={{this.imageUrl}}\n    alt={{or @image.alt @image.filename}}\n    class=\"h-auto w-full\"\n  />\n\n  <div local-class=\"inner\">\n    {{yield}}\n  </div>\n</div>","moduleName":"teamtailor/components/media-library/masonry-list-image.hbs","parseOptions":{"srcName":"teamtailor/components/media-library/masonry-list-image.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import imageUrl from 'teamtailor/utils/image-url';
import { argDefault } from 'teamtailor/utils/arg-default';
import { htmlSafe } from '@ember/template';

export default class MediaLibraryMasonryListImageComponent extends Component {
  @service flashMessages;
  @service intl;

  @argDefault selectable = true;

  get imageUrl() {
    return (
      this.args.image.previewUrl || imageUrl(this.args.image, 'media_preview')
    );
  }

  get imageStyle() {
    const { width, height } = this.args.image;
    return htmlSafe(`aspect-ratio: ${width} / ${height};`);
  }

  @action
  removeImage() {
    this.args.onRemoveImage(this.args.image);
  }

  @action
  handleClick() {
    if (!this.selectable) {
      return;
    }

    if (!this.args.canPickImage && !this.args.picked) {
      this.flashMessages.error(
        this.intl.t(
          'components.media_library.list_image.maximum_images_exceeded'
        )
      );
    } else {
      this.args.onPickImage(this.args.image);
    }
  }
}
