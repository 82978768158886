import { assert } from '@ember/debug';
import Service from '@ember/service';
import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';

interface IAuthorizationData {
  exp: number;
  expiresAt: string;
  internalName: string;
  provider: string;
  refreshToken: string;
  token: string;
  uid: string;
  username?: string;
}

export default class ExternalIntegrationOauthService extends Service {
  saveAuth(authData = ''): void {
    if (!authData) return;

    const [type, token] = authData.split(':');
    if (type === '#oauth') {
      assert('token must exist', token);
      this.add(token);
    }
  }

  getAuthorizationData(): IAuthorizationData | null {
    const data = localStorage.getItem('partner_oauth');
    if (!data) return null;
    return jwtDecode(data);
  }

  hasValidOauthData(): boolean {
    const partnerOauth = localStorage.getItem('partner_oauth');
    if (!partnerOauth) return false;

    const decoded = jwtDecode<IAuthorizationData>(partnerOauth);

    const expired = decoded.exp
      ? moment.unix(decoded.exp).isBefore(moment())
      : true;

    if (expired) {
      this.clear();
      return false;
    }

    return true;
  }

  add(jwtToken: string): void {
    this.clear();
    localStorage.setItem('partner_oauth', jwtToken);
  }

  clear(): void {
    localStorage.removeItem('partner_oauth');
  }
}

declare module '@ember/service' {
  interface Registry {
    'external-integration-oauth': ExternalIntegrationOauthService;
  }
}
