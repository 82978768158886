import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Jobs::Collaborate::List\n  @models={{this.sortedActivities}}\n  @readOnlyChannel={{true}}\n  @hideOnlineBeacon={{true}}\n  @pinnedChannel={{true}}\n/>", {"contents":"<Jobs::Collaborate::List\n  @models={{this.sortedActivities}}\n  @readOnlyChannel={{true}}\n  @hideOnlineBeacon={{true}}\n  @pinnedChannel={{true}}\n/>","moduleName":"teamtailor/components/toolbox/global-comments/pinned/activities.hbs","parseOptions":{"srcName":"teamtailor/components/toolbox/global-comments/pinned/activities.hbs"}});
import Component from '@glimmer/component';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { trackedFunction } from 'ember-resources/util/function';
import ActivityModel from 'teamtailor/models/activity';

type Args = {
  channelId: string;
  actionIds: string[];
};

export default class GlobalCommentsPinnedActivities extends Component<Args> {
  @service declare store: Store;

  resolveActivitiesResponses = trackedFunction(this, async () => {
    const activities: ActivityModel[] = await this.store.query('activity', {
      job_id: this.args.channelId,
      action_ids: this.args.actionIds,
      collaborate: true,
    });
    return activities;
  });

  get activities(): ActivityModel[] {
    return this.resolveActivitiesResponses.value || [];
  }

  get sortedActivities(): ActivityModel[] {
    return [...this.activities].sort(
      (a: ActivityModel, b: ActivityModel) =>
        new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
    );
  }
}
