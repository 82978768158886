import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import SettingsRequisitionsIndexEditController from 'teamtailor/controllers/settings/requisitions/index/edit';
import { RequisitionFlowModel } from 'teamtailor/models';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';

export default class SettingsRequisitionsIndexNewRoute extends Route {
  @service declare store: Store;
  @service declare current: Current;
  @service declare intl: IntlService;

  controllerName = 'settings.requisitions.index.edit';

  model() {
    const parentModel = this.modelFor(
      'settings.requisitions'
    ) as RequisitionFlowModel[];

    const hasDefaultFlow = parentModel.findBy('isDefault', true);

    return this.store.createRecord('requisition-flow', {
      company: this.current.company,
      isDefault: !hasDefaultFlow,
      title: hasDefaultFlow
        ? ''
        : this.intl.t('settings.requisitions.default_flow'),
    });
  }

  afterModel(model: RequisitionFlowModel) {
    this.store.createRecord('requisition-flow-step', {
      stepIndex: 0,
      requisitionFlow: model,
    });
  }

  resetController(
    controller: SettingsRequisitionsIndexEditController,
    isExiting: boolean
  ) {
    if (isExiting) {
      get(controller.model, 'steps')
        .toArray()
        .filterBy('isNew')
        .invoke('unloadRecord');

      if (get(controller.model, 'isNew')) {
        controller.model.unloadRecord();
      }
    }
  }
}
