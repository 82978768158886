import { attr } from '@ember-data/model';
import Section from './section';
import { get } from 'teamtailor/utils/get';

export default class ContentBlockInfographicModel extends Section {
  @attr('string') declare name: string;

  get description() {
    return get(this, 'header');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'content-block-infographic': ContentBlockInfographicModel;
  }
}
