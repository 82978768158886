import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';

export default class ContentEditorFooterRoute extends Route {
  @service iframePreview;

  model() {
    const page = this.modelFor('content.editor').pageRecord;
    return get(page, 'pagePublication.careerSite');
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    get(this, 'iframePreview').scrollToSection({
      section: 'footer',
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      get(controller, 'careerSite').rollbackAttributes();
    }
  }
}
