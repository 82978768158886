interface NavigatorUserAData {
  platform?: string;
}

interface Navigator {
  userAgentData?: NavigatorUserAData;
}

const checkIfMac = (): boolean => {
  if (typeof navigator === 'undefined') {
    return false;
  }

  const { userAgentData } = navigator as Navigator;

  if (userAgentData) {
    return userAgentData.platform?.toLowerCase().includes('mac') || false;
  } else {
    return /Mac/i.test(navigator.userAgent);
  }
};

const isMac = checkIfMac();

export default isMac;
