import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<DataTable::Cell\n  @data={{@data}}\n  @onMouseEnterColumn={{@onMouseEnterColumn}}\n  @onMouseExitColumn={{@onMouseExitColumn}}\n  @hovering={{@hovering}}\n  @lastRow={{@lastRow}}\n  class=\"!whitespace-normal\"\n>\n  <span\n    title={{this.textContent}}\n    class=\"block {{unless this.isExpanded 'overflow-hidden' ''}}\"\n  >\n    {{this.textValue}}\n  </span>\n\n  {{#if this.isContentTruncated}}\n    <Button\n      class=\"mt-8 flex\"\n      @size=\"small\"\n      @text={{if\n        this.isExpanded\n        (t \"components.data_table.show_less\")\n        (t \"components.data_table.show_more\")\n      }}\n      @onClick={{this.toggle}}\n      @appearance=\"secondary\"\n    />\n  {{/if}}\n</DataTable::Cell>", {"contents":"<DataTable::Cell\n  @data={{@data}}\n  @onMouseEnterColumn={{@onMouseEnterColumn}}\n  @onMouseExitColumn={{@onMouseExitColumn}}\n  @hovering={{@hovering}}\n  @lastRow={{@lastRow}}\n  class=\"!whitespace-normal\"\n>\n  <span\n    title={{this.textContent}}\n    class=\"block {{unless this.isExpanded 'overflow-hidden' ''}}\"\n  >\n    {{this.textValue}}\n  </span>\n\n  {{#if this.isContentTruncated}}\n    <Button\n      class=\"mt-8 flex\"\n      @size=\"small\"\n      @text={{if\n        this.isExpanded\n        (t \"components.data_table.show_less\")\n        (t \"components.data_table.show_more\")\n      }}\n      @onClick={{this.toggle}}\n      @appearance=\"secondary\"\n    />\n  {{/if}}\n</DataTable::Cell>","moduleName":"teamtailor/components/data-table/truncated-text.hbs","parseOptions":{"srcName":"teamtailor/components/data-table/truncated-text.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

type Args = {
  data: {
    value: string;
  };
};

export default class DataTableCandidateMessageComponent extends Component<Args> {
  @tracked isExpanded = false;

  get isContentTruncated(): boolean {
    return this.textContent.length > 140;
  }

  get textValue(): string {
    if (this.isExpanded) {
      return this.textContent;
    }

    return this.isContentTruncated ? this.truncatedText : this.textContent;
  }

  get truncatedText(): string {
    return `${this.textContent.substring(0, 140)}...`;
  }

  get textContent() {
    return this.args.data.value || '';
  }

  @action
  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
