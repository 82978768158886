import { helper } from '@ember/component/helper';

export function formatTime(time: number): string {
  const hours = Math.floor(time / 3600)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((time % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor(time % 60)
    .toString()
    .padStart(2, '0');

  if (Number(hours) > 0) {
    return `${hours}:${minutes}:${seconds}`;
  } else {
    return `${minutes}:${seconds}`;
  }
}

export function timeStamp([time]: [number]): string {
  return formatTime(time);
}

export default helper(timeStamp);
