import ApplicationInstance from '@ember/application/instance';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { gql } from '@apollo/client/core';
import ReportAnalyticsRequest, {
  FetchOptions,
} from './report-analytics-request';
import {
  AnalyticsReportBuilder,
  BuildReportArgs,
} from './analytics-report-builder';
import moment from 'moment-timezone';
import IntlService from 'ember-intl/services/intl';
import preloadMissingModels from 'teamtailor/utils/insights/preload-missing-models';
import JobModel from 'teamtailor/models/job';
import { get } from 'teamtailor/utils/get';
import fetchInBatches from 'teamtailor/utils/insights/fetch-in-batches';
import uniq from 'teamtailor/utils/uniq';

const QUERY = gql`
  query NPSOverviewQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $limit: Int
    $companyIds: [ID!]
  ) {
    eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [NPS_RESPONSE]
      distinctBy: {
        fields: [JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
      companyIds: $companyIds
    ) {
      responses: rows(
        limit: $limit
        orderBy: { field: TIMESTAMP, desc: true }
      ) {
        candidateId
        jobId
        timestamp: keenTimestamp
        npsResponseScore
        npsResponseFeedback
        stageIsReject
        stageName
      }
    }
  }
`;

interface Result {
  eventQuery:
    | {
        responses: NpsResponseRow[];
      }
    | undefined;
}

interface NpsResponseRow {
  candidateId: string;
  jobId: string;
  timestamp: string;
  npsResponseScore: string;
  npsResponseFeedback: string;
  stageIsReject: boolean;
  stageName: string;
}

type CandidateType = {
  id: string;
  nameOrEmail?: string;
  avatarUrl?: string;
  initials?: string;
  color?: string;
  avatarImage?: {
    candidatePictureUrl: string;
  };
  company?: {
    id: string;
    name: string;
  };
};
interface RowWithJob {
  candidateId: number;
  jobId: number;
  candidate: CandidateType;
  jobTitle: string;
  timestamp: string;
  npsResponseScore: number;
  npsResponseFeedback: string;
  stageIsReject: boolean;
  stageName: string;
  deleted?: boolean;
}

export interface NpsRow extends RowWithJob {
  date: string;
  id: number;
  hasFeedback: boolean;
  displayStageName: string;
  companyName?: string;
}

const fetchJobs = async (
  container: ApplicationInstance,
  rows: NpsResponseRow[] | undefined
): Promise<RowWithJob[]> => {
  if (!rows) {
    return [];
  }

  const analyticsService = container.lookup('service:analytics');
  const store = container.lookup('service:store');
  const intl = container.lookup('service:intl');

  const jobIds = uniq(rows.map((row) => row.jobId));

  const existingJobs = await store.peekAll('job');
  const missingJobs = (await preloadMissingModels('job', jobIds, store, {
    groupAnalytics: analyticsService.hasEnabledGroupAnalytics,
  })) as JobModel[];
  const jobs = [...existingJobs, ...missingJobs];

  return rows.map((row) => {
    const job = jobs.find((job: JobModel) => job.id === row.jobId);

    return {
      ...row,
      npsResponseScore: parseInt(row.npsResponseScore),
      candidateId: parseInt(row.candidateId),
      jobId: parseInt(row.jobId),
      jobTitle: job?.title || intl.t('insights.common.deleted_job'),
      candidate: {
        id: row.candidateId,
      },
    };
  });
};

type CandidatesResponseType = {
  candidates: CandidateType[];
};

const fetchCandidates = async (
  container: ApplicationInstance,
  rows: RowWithJob[]
): Promise<RowWithJob[]> => {
  const analyticsService = container.lookup('service:analytics');
  const apolloService = container.lookup('service:apollo');
  const currentService = container.lookup('service:current');

  const candidateIds = uniq(rows.map((row) => row.candidateId.toString()));

  const candidates = await fetchInBatches<
    CandidateType,
    CandidatesResponseType
  >(
    candidateIds,
    (ids: string[]) =>
      apolloService.query({
        query: gql`
          query CandidatesQuery(
            $userId: ID!
            $candidateIds: [ID!]
            $companyIds: [ID!]
          ) {
            candidates(
              ids: $candidateIds
              userScope: { userId: $userId }
              groupCompanyIds: $companyIds
            ) {
              id
              nameOrEmail
              initials
              color
              avatarImage {
                candidatePictureUrl
              }
              company {
                id
                name
              }
            }
          }
        `,
        variables: {
          candidateIds: ids,
          userId: get(currentService.user, 'id'),
          companyIds: analyticsService.availableCompanyIds,
        },
      }),
    (acc, response) => {
      return acc.concat(response.candidates);
    }
  );

  return rows.map((row) => {
    const candidate = candidates.find(
      (candidate: CandidateType) =>
        candidate.id.toString() === row.candidateId.toString()
    );

    return {
      ...row,
      candidate: candidate || { ...row.candidate, deleted: true },
    };
  });
};

interface NpsResponsesReportArgs {
  container: ApplicationInstance;
  _rows: RowWithJob[];
}
class NpsResponsesReport {
  @service declare intl: IntlService;

  container: ApplicationInstance;
  _rows: RowWithJob[];

  constructor(args: NpsResponsesReportArgs) {
    this.container = args.container;
    this._rows = args._rows;
  }

  get rows(): NpsRow[] {
    return this._rows.map((row: RowWithJob) => ({
      ...row,
      date: moment(row.timestamp).format('YYYY-MM-DD'),
      id: row.candidateId,
      hasFeedback: isPresent(row.npsResponseFeedback),
      companyName:
        row.candidate.company?.name ||
        this.intl.t('components.data_table.not_available'),

      displayStageName: row.stageIsReject
        ? this.intl.t(`insights.common.rejected_stage`, {
            stageName: row.stageName,
          })
        : row.stageName,
    }));
  }
}

interface NpsOverviewFetchOptions extends FetchOptions {
  limit?: number;
}

export function buildReport(args: BuildReportArgs) {
  const { container } = args;
  return new AnalyticsReportBuilder<NpsResponsesReport, RowWithJob[]>(
    container,
    {
      query: async (options: FetchOptions = {}) => {
        return fetch(container, options);
      },

      createReport: (queryResult: RowWithJob[]) => {
        return new NpsResponsesReport({
          container,
          _rows: queryResult,
        });
      },
    }
  );
}

export async function fetch(
  container: ApplicationInstance,
  options: NpsOverviewFetchOptions = {}
): Promise<RowWithJob[]> {
  const results = await new ReportAnalyticsRequest({
    container,
    query: QUERY,
    extraVariables: {
      limit: options.limit,
    },

    callback: (result?: Result) => result?.eventQuery,
  }).fetch(options);

  if (!results) {
    return [];
  }

  let _rows = await fetchJobs(container, results?.responses);
  return fetchCandidates(container, _rows);
}
