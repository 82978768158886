import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes {{did-insert this.setElement}}>\n  {{#if @label}}\n    <span class=\"quick-navigation__label\" {{did-insert this.setLabelElement}}>\n      {{@label}}\n    </span>\n  {{/if}}\n  {{#each @items as |item|}}\n    <QuickNavigation::Item\n      @selected={{eq item @selectedItem}}\n      @action={{fn @onItemClicked item}}\n      @item={{item}}\n      @onHover={{fn @onSelectedItemChanged item}}\n      @onSelected={{this.handleSelectedItem}}\n    />\n  {{else}}\n    <div class=\"quick-navigation__no-results\">\n      <i>\n        {{@emptyStateMessage}}\n      </i>\n    </div>\n  {{/each}}\n</div>", {"contents":"<div ...attributes {{did-insert this.setElement}}>\n  {{#if @label}}\n    <span class=\"quick-navigation__label\" {{did-insert this.setLabelElement}}>\n      {{@label}}\n    </span>\n  {{/if}}\n  {{#each @items as |item|}}\n    <QuickNavigation::Item\n      @selected={{eq item @selectedItem}}\n      @action={{fn @onItemClicked item}}\n      @item={{item}}\n      @onHover={{fn @onSelectedItemChanged item}}\n      @onSelected={{this.handleSelectedItem}}\n    />\n  {{else}}\n    <div class=\"quick-navigation__no-results\">\n      <i>\n        {{@emptyStateMessage}}\n      </i>\n    </div>\n  {{/each}}\n</div>","moduleName":"teamtailor/components/quick-navigation/list.hbs","parseOptions":{"srcName":"teamtailor/components/quick-navigation/list.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import elementOffset from 'teamtailor/utils/element-offset';

export default class QuickNavigationComponent extends Component {
  @service quickNavigation;

  element = null;
  labelElement = null;

  @action
  handleSelectedItem(selectedItem) {
    const container = this.element?.parentElement;

    if (!container?.clientHeight || !selectedItem?.clientHeight) {
      return;
    }

    const childOffsetFromTop =
      elementOffset(selectedItem).top - elementOffset(container).top;

    const itemHeight = selectedItem.clientHeight;
    const parentHeight = container.clientHeight;

    const isAboveParent = childOffsetFromTop < 0;
    const isBelowParent = childOffsetFromTop + itemHeight > parentHeight;

    if (isAboveParent) {
      container.scrollTop = container.scrollTop + childOffsetFromTop;
      return;
    }

    if (isBelowParent) {
      const labelHeight = this.labelElement
        ? this.labelElement.clientHeight
        : 0;

      const delta = parentHeight - (childOffsetFromTop + itemHeight);

      container.scrollTop = container.scrollTop - delta + labelHeight;
    }
  }

  @action
  setElement(element) {
    this.element = element;
  }

  @action
  setLabelElement(element) {
    this.labelElement = element;
  }
}
