import { ApolloLink, HttpLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import Store from '@ember-data/store';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import ApolloService from 'ember-apollo-client/services/apollo';
import moment from 'moment-timezone';
import InsightsJwt from 'teamtailor/utils/insights-jwt';
import { tracked } from 'tracked-built-ins';
import FlipperService from './flipper';
import Current from './current';

export default class AnalyticsRequestService extends ApolloService {
  @service private declare current: Current;
  @service private declare store: Store;
  @service private declare flipper: FlipperService;

  @tracked tokens: { [key: string]: InsightsJwt } = {};

  public get host(): string | undefined {
    return undefined;
  }

  link(): ApolloLink {
    const authMiddleware = setContext(async (_request, context) => {
      const token = await this.getToken(context)?.token;

      Object.assign(context, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return context;
    });

    const wentLiveAtMiddleware = new ApolloLink((operation, next) => {
      const wentLiveAt = get(this.current.company, 'went_live_at');

      if (isPresent(operation.variables.dateRange) && isPresent(wentLiveAt)) {
        operation.variables.dateRange.startDate = moment
          .max(
            moment(wentLiveAt),
            moment(operation.variables.dateRange?.startDate)
          )
          .format('YYYY-MM-DD');
      }

      return next(operation);
    });

    const httpLink = new HttpLink({
      uri: `${this.host}/graphql`,
      fetch,
    });

    return ApolloLink.from([wentLiveAtMiddleware, authMiddleware, httpLink]);
  }

  getToken = ({
    jobId,
    sharedReportToken,
    resourceType,
  }: {
    jobId: string;
    sharedReportToken: string;
    resourceType?: string;
  }): InsightsJwt | undefined => {
    const tokenKey = this.getTokenKey({ jobId });

    if (
      isEmpty(this.tokens[tokenKey]) &&
      isPresent(jobId) &&
      !get(this.current.user, 'admin')
    ) {
      this.tokens[tokenKey] = new InsightsJwt(
        this.store.adapterFor('application'),
        'job',
        jobId
      );
    } else if (isEmpty(this.tokens[tokenKey]) && isPresent(sharedReportToken)) {
      this.tokens[tokenKey] = new InsightsJwt(
        this.store.adapterFor('shared-report'),
        'insights-report',
        sharedReportToken
      );
    } else if (isEmpty(this.tokens[tokenKey])) {
      this.tokens[tokenKey] = new InsightsJwt(
        this.store.adapterFor('application'),
        resourceType
      );
    }

    return this.tokens[tokenKey];
  };

  getTokenKey({ jobId }: { jobId: string }): string {
    if (get(this.current.user, 'admin')) {
      return 'admin';
    } else {
      return `job-${jobId}`;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'analytics-request': AnalyticsRequestService;
  }
}
