import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.shouldShowTransitionMessage}}\n  <AlertMessage @marginY={{true}} @type=\"info\">\n    <p>{{html-safe\n        (t \"reports.old_pageview_info\" date=this.formattedTransitionDate)\n      }}</p>\n  </AlertMessage>\n{{/if}}", {"contents":"{{#if this.shouldShowTransitionMessage}}\n  <AlertMessage @marginY={{true}} @type=\"info\">\n    <p>{{html-safe\n        (t \"reports.old_pageview_info\" date=this.formattedTransitionDate)\n      }}</p>\n  </AlertMessage>\n{{/if}}","moduleName":"teamtailor/components/insights/pageview-transition-message.hbs","parseOptions":{"srcName":"teamtailor/components/insights/pageview-transition-message.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { getClickhousePageviewsTransitionDate } from 'teamtailor/classes/analytics/report-analytics-request';
import AnalyticsService from 'teamtailor/services/analytics';
import FlipperService from 'teamtailor/services/flipper';

export default class PageviewTransitionMessage extends Component {
  @service declare analytics: AnalyticsService;
  @service declare flipper: FlipperService;

  get transitionDate() {
    return getClickhousePageviewsTransitionDate(this.flipper);
  }

  get formattedTransitionDate(): string {
    return moment(this.transitionDate).format('D MMM YYYY');
  }

  get shouldShowTransitionMessage(): boolean {
    const { startDate, endDate } = this.analytics;
    return (
      moment(this.transitionDate).isBetween(startDate, endDate) ||
      moment(this.transitionDate).isSame(endDate)
    );
  }
}
