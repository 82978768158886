import Controller from '@ember/controller';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SettingsPartnerHubNewController extends Controller {
  @service intl;
  @service router;
  @service ttAlert;
  @service flashMessages;

  @action
  async save() {
    try {
      await this.model.save();
      this.flashMessages.success(
        this.intl.t('settings.partner_hub.partner_updated')
      );
      this.router.transitionTo('settings.partner_hub');
    } catch (error) {
      this.flashMessages.error(
        this.intl.t('settings.partner_hub.could_not_update_partner')
      );
    }
  }

  @action
  async handleDelete() {
    try {
      await this.model.partnerModel.destroyRecord();
      this.flashMessages.success(
        this.intl.t('settings.partner_hub.partner_deleted')
      );
      this.router.transitionTo('settings.partner_hub');
    } catch (error) {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
    }
  }

  @action
  async delete() {
    this.ttAlert.confirm(
      this.intl.t('settings.partner_hub.delete_partner'),
      await this.handleDelete()
    );
  }
}
