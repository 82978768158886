import AverageRating from './average-rating';

export interface RowWithAverageRating {
  averageRating: AverageRating;
}

export type SortDirection = 'desc' | 'asc';

export default function sortOnAverageRating(
  rows: RowWithAverageRating[],
  sortDirection: SortDirection
) {
  const unfinished = rows.filter((value) => {
    return !value.averageRating.hasFetchedRating;
  });

  if (unfinished.length > 0) {
    return rows;
  }

  if (sortDirection === 'desc') {
    return rows.sortBy('averageRating.value').reverse();
  }

  return rows.sortBy('averageRating.value');
}
