import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class={{class-names\n    \"flex flex-shrink-0 items-center justify-center rounded-full\"\n    this.sizeClasses\n    (if\n      this.isPending\n      \"body-text-s-medium bg-decorative-amber-weak text-decorative-orange-weak\"\n      \"body-text-medium\"\n    )\n    (if this.isPromoter \"bg-decorative-green-weak text-decorative-green-weak\")\n    (if this.isPassive \"bg-decorative-zinc-weak text-decorative-zinc-weak\")\n    (if this.isDetractor \"bg-decorative-red-weak text-decorative-red-weak\")\n  }}\n  data-test-nps-response-item-score\n>\n  {{if this.isPending \"N/A\" @score}}\n</div>", {"contents":"<div\n  class={{class-names\n    \"flex flex-shrink-0 items-center justify-center rounded-full\"\n    this.sizeClasses\n    (if\n      this.isPending\n      \"body-text-s-medium bg-decorative-amber-weak text-decorative-orange-weak\"\n      \"body-text-medium\"\n    )\n    (if this.isPromoter \"bg-decorative-green-weak text-decorative-green-weak\")\n    (if this.isPassive \"bg-decorative-zinc-weak text-decorative-zinc-weak\")\n    (if this.isDetractor \"bg-decorative-red-weak text-decorative-red-weak\")\n  }}\n  data-test-nps-response-item-score\n>\n  {{if this.isPending \"N/A\" @score}}\n</div>","moduleName":"teamtailor/components/nps-response-score.hbs","parseOptions":{"srcName":"teamtailor/components/nps-response-score.hbs"}});
import Component from '@glimmer/component';
import {
  isDetractorScore,
  isPassiveScore,
  isPromoterScore,
} from 'teamtailor/utils/nps';

interface NpsResponseScoreSignature {
  score: number | null;
  size?: 'small' | 'medium';
}

export default class NpsResponseScore extends Component<NpsResponseScoreSignature> {
  get score(): number | null {
    return this.args.score;
  }

  get sizeClasses() {
    switch (this.args.size) {
      case 'small':
        return 'size-32';
      case 'medium':
        return 'size-40';
      default:
        return 'size-40';
    }
  }

  get isPending() {
    return this.score === null;
  }

  get isDetractor() {
    if (this.isPending) return false;

    return isDetractorScore(this.score!);
  }

  get isPromoter() {
    if (this.isPending) return false;

    return isPromoterScore(this.score!);
  }

  get isPassive() {
    if (this.isPending) return false;

    return isPassiveScore(this.score!);
  }
}
