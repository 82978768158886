import { AsyncBelongsTo } from '@ember-data/model';
import Service from '@ember/service';
import { findAll } from 'ember-data-resources';
import { QuestionModel } from 'teamtailor/models';
import TranslationQuestionModel from 'teamtailor/models/translation/question';
import { get } from 'teamtailor/utils/get';

export default class QuestionTranslationsService extends Service {
  _questionsTranslations = findAll(this, 'translation/question');

  get questions() {
    return (this._questionsTranslations.records ??
      []) as TranslationQuestionModel[];
  }

  getQuestionTranslations(
    question: QuestionModel | AsyncBelongsTo<QuestionModel>
  ) {
    if (!this.questions.length) {
      return [];
    }

    return this.questions.filter(
      (translatedResource) =>
        translatedResource.belongsTo('question').id() === get(question, 'id')
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    'question-translations': QuestionTranslationsService;
  }
}
