import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { MonsterJobCategoryModel } from 'teamtailor/models';

export default class MonsterJobOccupationModel extends Model {
  @belongsTo('monster-job-category')
  declare monsterJobCategory: AsyncBelongsTo<MonsterJobCategoryModel>;

  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'monster-job-occupation': MonsterJobOccupationModel;
  }
}
