import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<p class=\"mb-0\">\n  {{t \"surveys.survey_sent_via_email\" headerText=this.headerText htmlSafe=true}}\n</p>\n\n<div {{did-insert this.handleInsertElement}}>\n  <Core::SurveyPicker\n    @options={{this.company.surveys}}\n    @selected={{this.selectedSurvey}}\n    @onSelect={{this.changeSurvey}}\n  />\n</div>\n\n<TriggerAdvancedOptions @model={{@model}} />", {"contents":"<p class=\"mb-0\">\n  {{t \"surveys.survey_sent_via_email\" headerText=this.headerText htmlSafe=true}}\n</p>\n\n<div {{did-insert this.handleInsertElement}}>\n  <Core::SurveyPicker\n    @options={{this.company.surveys}}\n    @selected={{this.selectedSurvey}}\n    @onSelect={{this.changeSurvey}}\n  />\n</div>\n\n<TriggerAdvancedOptions @model={{@model}} />","moduleName":"teamtailor/components/trigger-survey-form.hbs","parseOptions":{"srcName":"teamtailor/components/trigger-survey-form.hbs"}});
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import TriggerFormBaseComponent from 'teamtailor/components/trigger-form-base';
import { get } from 'teamtailor/utils/get';

export default class TriggerSurveyFormComponent extends TriggerFormBaseComponent {
  @service current;

  get selectedId() {
    return this.model.formId;
  }

  get selectedSurvey() {
    return get(this.company, 'surveys').findBy('id', this.selectedId);
  }

  get company() {
    return this.current.company;
  }

  @action
  changeSurvey(survey) {
    set(this.model, 'formId', survey.id);
  }

  @action
  handleInsertElement() {
    get(this.company, 'surveys').reload();
  }
}
