import Service, { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import FlashMessageService from 'teamtailor/services/flash-message';
import PusherService from 'teamtailor/services/pusher';
import IntlService from 'ember-intl/services/intl';
import VideoMeetingService from 'teamtailor/services/video-meeting';
import RouterService from '@ember/routing/router-service';
import config from 'teamtailor/config/environment';
import { bind } from '@ember/runloop';

type RouteModel = object | string | number;

export default class AppRefresher extends Service.extend({}) {
  @service() declare flashMessages: FlashMessageService;
  @service() declare pusher: PusherService;
  @service() declare intl: IntlService;
  @service() declare videoMeeting: VideoMeetingService;
  @service() declare router: RouterService;

  startedAt = moment();
  runningAppVersion = config.APP.version;
  forceRefresh = false;

  get deployedAppVersion() {
    return this._deployedAppVersion || this.runningAppVersion;
  }

  set deployedAppVersion(value: string) {
    this._deployedAppVersion = value;
  }

  get newVersion() {
    return this.deployedAppVersion !== this.runningAppVersion;
  }

  get shouldRefresh() {
    if (this.videoMeeting.isJoined || config.environment === 'development') {
      return false;
    }

    return (
      window.navigator.onLine &&
      (this.forceRefresh ||
        this.newVersion ||
        this.startedAt.isBefore(moment().subtract(1, 'hour')))
    );
  }

  reloadPage() {
    document
      .querySelector('body')
      ?.classList.add('transition-opacity', 'duration-500', 'opacity-0');
    window.location.reload();
  }

  transitionOrNavigateTo(
    routeName: string,
    ...args: [
      options?: {
        queryParams: object;
      }
    ]
  ): string;

  transitionOrNavigateTo(
    routeName: string,
    ...args: [
      models: RouteModel,
      options?: {
        queryParams: object;
      }
    ]
  ): string;

  transitionOrNavigateTo(
    routeName: string,
    ...args: [
      modelsA: RouteModel,
      modelsB: RouteModel,
      options?: {
        queryParams: object;
      }
    ]
  ): string;

  transitionOrNavigateTo(
    routeName: string,
    ...args: [
      modelsA: RouteModel,
      modelsB: RouteModel,
      modelsC: RouteModel,
      options?: {
        queryParams: object;
      }
    ]
  ): string;

  transitionOrNavigateTo(
    routeName: string,
    ...args: [
      modelsA: RouteModel,
      modelsB: RouteModel,
      modelsC: RouteModel,
      modelsD: RouteModel,
      options?: {
        queryParams: object;
      }
    ]
  ): string;

  transitionOrNavigateTo(
    routeName: string,
    ...args:
      | RouteModel[]
      | [...RouteModel[], { queryParams: object } | undefined]
      | any // eslint-disable-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-redundant-type-constituents
  ) {
    if (this.shouldRefresh) {
      return (window.location.href = this.router.urlFor(routeName, ...args));
    } else {
      return this.router.transitionTo(routeName, ...args);
    }
  }

  transitionOrRedirectTo(
    args: Parameters<typeof this.transitionOrNavigateTo>,
    newTab = false
  ) {
    if (newTab) {
      window.open(this.router.urlFor(...args), '_blank');
    } else {
      this.transitionOrNavigateTo(...args);
    }
  }

  setupDeployNotifications() {
    this.pusher.subscribe('deploy-notifications').then((channel) => {
      channel.bind('deploy', (appVersion) => {
        this.deployedAppVersion = appVersion;
        if (this.shouldRefresh) {
          this.flashMessages.notice(
            this.intl.t('services.app_refresher.update_available'),
            {
              sticky: true,
              onDestroy: bind(this, this.reloadPage),
            }
          );
        }
      });
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'app-refresher': AppRefresher;
  }
}
