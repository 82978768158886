import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<fieldset\n  class=\"no-global-styles flex flex-col gap-4\"\n  disabled={{@isDisabled}}\n  ...attributes\n>\n  {{#if @legend}}\n    <legend class=\"body-text-s-medium contents cursor-default\">\n      {{! flex gap doesnt works on legend elements, hence display: contents and extra span. Remove when browser fix this}}\n      <span>\n        {{@legend}}\n      </span>\n    </legend>\n  {{/if}}\n  <div\n    class=\"gap-8\n      {{cn\n        (if (eq this.columns 1) 'flex flex-col ')\n        (if (eq this.columns 2) 'grid grid-cols-2 items-start')\n      }}\"\n  >\n    {{yield\n      (hash RowError=(component \"core/form/field/error\" colSpan=this.columns))\n    }}\n  </div>\n  {{#if @tooltip}}\n    <EmberTooltip @text={{@tooltip}} />\n  {{/if}}\n</fieldset>", {"contents":"<fieldset\n  class=\"no-global-styles flex flex-col gap-4\"\n  disabled={{@isDisabled}}\n  ...attributes\n>\n  {{#if @legend}}\n    <legend class=\"body-text-s-medium contents cursor-default\">\n      {{! flex gap doesnt works on legend elements, hence display: contents and extra span. Remove when browser fix this}}\n      <span>\n        {{@legend}}\n      </span>\n    </legend>\n  {{/if}}\n  <div\n    class=\"gap-8\n      {{cn\n        (if (eq this.columns 1) 'flex flex-col ')\n        (if (eq this.columns 2) 'grid grid-cols-2 items-start')\n      }}\"\n  >\n    {{yield\n      (hash RowError=(component \"core/form/field/error\" colSpan=this.columns))\n    }}\n  </div>\n  {{#if @tooltip}}\n    <EmberTooltip @text={{@tooltip}} />\n  {{/if}}\n</fieldset>","moduleName":"teamtailor/components/core/form/fieldset.hbs","parseOptions":{"srcName":"teamtailor/components/core/form/fieldset.hbs"}});
import Component from '@glimmer/component';

type Args = {
  columns?: 1 | 2;
};

export default class FormFieldsetComponent extends Component<Args> {
  get columns() {
    return parseInt(this.args.columns ?? 1);
  }
}
