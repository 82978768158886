import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ol class=\"flex flex-1 flex-col pb-10 pt-8\">\n  {{#each @chatMessages as |chatMessage|}}\n    <li class={{class-names \"group/item relative px-12 py-12\"}}>\n      <VideoMeeting::Chat::Message\n        @chatMessage={{chatMessage}}\n        @attendee={{this.getAttendee chatMessage.user_id}}\n        @multipleCandidateMeeting={{@multipleCandidateMeeting}}\n      />\n    </li>\n  {{/each}}\n</ol>", {"contents":"<ol class=\"flex flex-1 flex-col pb-10 pt-8\">\n  {{#each @chatMessages as |chatMessage|}}\n    <li class={{class-names \"group/item relative px-12 py-12\"}}>\n      <VideoMeeting::Chat::Message\n        @chatMessage={{chatMessage}}\n        @attendee={{this.getAttendee chatMessage.user_id}}\n        @multipleCandidateMeeting={{@multipleCandidateMeeting}}\n      />\n    </li>\n  {{/each}}\n</ol>","moduleName":"teamtailor/components/video-meeting/chat/message-list.hbs","parseOptions":{"srcName":"teamtailor/components/video-meeting/chat/message-list.hbs"}});
import Component from '@glimmer/component';
import { VideoMeetingUser } from 'teamtailor/utils/video-meetings/utils';

type Args = {
  attendeesWithDetails: VideoMeetingUser[];
};

export default class VideoMeetingChatMessageList extends Component<Args> {
  getAttendee = (user_id: number | null) => {
    return this.args.attendeesWithDetails.find((a) => a.id === user_id);
  };
}
