import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class={{class-names\n    \"flex flex-wrap\"\n    (if (eq this.gapSize \"small\") \"gap-4\")\n    (if (eq this.gapSize \"medium\") \" gap-8\")\n  }}\n  ...attributes\n>\n  {{yield}}\n</div>", {"contents":"<div\n  class={{class-names\n    \"flex flex-wrap\"\n    (if (eq this.gapSize \"small\") \"gap-4\")\n    (if (eq this.gapSize \"medium\") \" gap-8\")\n  }}\n  ...attributes\n>\n  {{yield}}\n</div>","moduleName":"teamtailor/components/badge-cluster.hbs","parseOptions":{"srcName":"teamtailor/components/badge-cluster.hbs"}});
import Component from '@glimmer/component';

import { verifyArg } from 'teamtailor/utils/verify-arg';

const DEFAULT_SIZE = 'small';
const ALLOWED_SIZES = [DEFAULT_SIZE, 'medium'] as const;

type AllowedSizes = (typeof ALLOWED_SIZES)[number];

interface BadgeClusterArgs {
  gapSize?: AllowedSizes;
}

export default class BadgeCluster extends Component<BadgeClusterArgs> {
  get gapSize(): AllowedSizes {
    verifyArg(
      this.args.gapSize,
      ALLOWED_SIZES,
      'Badge cluster @gapSize',
      DEFAULT_SIZE
    );

    return this.args.gapSize ?? DEFAULT_SIZE;
  }
}
