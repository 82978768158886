import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { argDefault } from 'teamtailor/utils/arg-default';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';
import Current from 'teamtailor/services/current';
import AssetStructureModel from 'teamtailor/models/asset-structure';

import DepartmentModel from 'teamtailor/models/department';
import RoleModel from 'teamtailor/models/role';
import RegionModel from 'teamtailor/models/region';
import LocationModel from 'teamtailor/models/location';

type SettingsAssetOrganizationArgs = {
  assetStructure?: AssetStructureModel | null;
};

export default class SettingsAssetOrganizationComponent extends Component<SettingsAssetOrganizationArgs> {
  @service declare store: Store;
  @service declare current: Current;

  @tracked allSelected = true;

  @argDefault
  assetStructure?: SettingsAssetOrganizationArgs['assetStructure'] = null;

  @action
  onDepartmentSelect(department: DepartmentModel | null) {
    if (this.selectedDepartment !== department) {
      this.assetStructure?.setDepartment(department);
    }
  }

  @action
  onRoleSelect(role: RoleModel | null) {
    if (this.selectedRole !== role) {
      this.assetStructure?.setRole(role);
    }
  }

  @action
  onRegionSelect(region: RegionModel | null) {
    if (this.selectedRegion !== region) {
      this.assetStructure?.setRegion(region);
    }
  }

  @action
  onLocationSelect(location: LocationModel | null) {
    if (this.selectedLocation !== location) {
      this.assetStructure?.setLocation(location);
    }
  }

  clearSelections() {
    this.onDepartmentSelect(null);
    this.onRoleSelect(null);
    this.onRegionSelect(null);
    this.onLocationSelect(null);
  }

  @action
  onUpdateSelection(allSelected: boolean): void {
    this.allSelected = allSelected;

    if (allSelected) {
      this.clearSelections();
    }
  }

  get availableEverywhere(): boolean {
    if (this.anyValueSelected) {
      return false;
    }

    return this.allSelected;
  }

  get selectedDepartment(): DepartmentModel | undefined | null {
    const departmentId = get(this.assetStructure, 'departmentId');
    return isPresent(departmentId)
      ? this.departments.findBy('id', departmentId)
      : null;
  }

  get selectedRole(): RoleModel | undefined | null {
    const roleId = get(this.assetStructure, 'roleId');
    return isPresent(roleId) && this.isRoleSelectEnabled
      ? this.selectedDepartment?.roles.findBy('id', roleId)
      : null;
  }

  get selectedRegion(): RegionModel | undefined | null {
    const regionId = get(this.assetStructure, 'regionId');
    return isPresent(regionId) ? this.regions.findBy('id', regionId) : null;
  }

  get selectedLocation(): LocationModel | undefined | null {
    const locationId = get(this.assetStructure, 'locationId');
    const regionId = get(this.assetStructure, 'regionId');
    return isPresent(locationId) &&
      this.isLocationSelectEnabled &&
      isPresent(regionId)
      ? this.selectedRegion?.locations.findBy('id', locationId)
      : this.locations.findBy('id', locationId);
  }

  get anyValueSelected(): boolean {
    return isPresent(
      this.selectedDepartment ||
        this.selectedRole ||
        this.selectedRegion ||
        this.selectedLocation
    );
  }

  get isRoleSelectEnabled(): boolean {
    if (this.selectedDepartment?.roles) {
      return this.selectedDepartment.roles.length > 0;
    }

    return false;
  }

  get isLocationSelectEnabled(): boolean {
    return (
      isPresent(this.filteredLocations) && this.filteredLocations.length > 0
    );
  }

  get isRegionsSelectEnabled(): boolean {
    return this.regions.length > 0;
  }

  get filteredLocations(): LocationModel[] {
    if (this.selectedRegion) {
      return this.selectedRegion.locations.toArray();
    } else {
      return this.locations;
    }
  }

  get regions(): RegionModel[] {
    const regions = get(this.current.company, 'regions').toArray(); // sortedRegionsWithLocations
    return regions;
  }

  get locations() {
    return get(this.current.company, 'locations').slice();
  }

  get departments() {
    return get(this.current.company, 'departmentsWithoutNoDepartment').slice();
  }
}
