import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { set, action } from '@ember/object';
import { get } from 'teamtailor/utils/get';

export default class SettingsGroupSiteController extends Controller {
  @service intl;
  @service current;
  @service flashMessages;

  @action
  handleVisibilityChange(groupSiteCompany) {
    set(
      groupSiteCompany,
      'hiddenOnCareerSite',
      !get(groupSiteCompany, 'hiddenOnCareerSite')
    );

    groupSiteCompany.save();
  }

  @action
  dragEnd({ sourceList, sourceIndex, targetList, targetIndex }) {
    if (sourceList === targetList && sourceIndex === targetIndex) {
      return;
    }

    const groupSiteCompany = sourceList.objectAt(sourceIndex);

    const rowOrder =
      targetIndex < targetList.length - 1
        ? targetList[targetIndex].rowOrder - 1
        : targetList[targetList.length - 1].rowOrder + 1;
    set(groupSiteCompany, 'rowOrder', rowOrder);
    set(groupSiteCompany, 'rowOrderPosition', targetIndex);

    groupSiteCompany
      .save()
      .then(() => {
        get(this, 'flashMessages')
          .clearMessages()
          .success(this.intl.t('settings.group_site.success_message'));
      })
      .catch(() => {
        get(this, 'flashMessages').error(
          this.intl.t('settings.group_site.error_message')
        );
      });
  }
}
