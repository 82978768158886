import { isPresent } from '@ember/utils';
import { FilterValue, IOperator } from 'teamtailor/components/fancy-filters';
import { JSONFilter } from 'teamtailor/components/fancy-filters/candidate-filters-json';
import BaseOperator from './base-operator';

export default class EqualsOperator extends BaseOperator implements IOperator {
  specialTypes = [
    'polar',
    'average_rating',
    'date',
    'bool',
    'ReviewedBy',
    'radio',
    'application_stage_polar',
  ];

  groupedName?: string;
  staticValue?: string;

  constructor(
    name: string,
    not = false,
    groupedName?: string,
    staticValue?: string
  ) {
    super(name, not);
    this.groupedName = groupedName;
    this.staticValue = staticValue;
  }

  public get hideInput(): boolean {
    return !!(this.groupedName && this.staticValue);
  }

  public isValidValue = (value?: FilterValue): boolean => {
    return !!(this.groupedName && this.staticValue) || isPresent(value);
  };

  public asJSON(): JSONFilter {
    if (!this.filter) {
      throw new Error('Filter is not set');
    }

    if (this.groupedName && this.staticValue) {
      return {
        [this.groupedName]: {
          equals: this.staticValue,
        },
      };
    }

    const { serializedValue } = this.filter;

    return {
      [this.filter.name]: {
        [this.operatorName]: serializedValue,
      },
    };
  }
}

export function prepareEqualsOperator(
  shouldBeEqual = true
): () => EqualsOperator {
  return () => new EqualsOperator('equals', !shouldBeEqual);
}

export function prepareStaticEqualsOperator(
  name: string,
  value: string,
  shouldExist = true
): () => EqualsOperator {
  return () => new EqualsOperator('equals', !shouldExist, name, value);
}
