import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span\n  ...attributes\n  class={{class-names\n    \"inline-flex flex-shrink-0 rounded-full\"\n    (if (eq this.size \"small\") \"size-6\")\n    (if (eq this.size \"medium\") \"size-8\")\n    (if (eq this.size \"large\") \"size-12\")\n    (if this.isMultipleColor \"-rotate-[30deg]\" this.bgColor)\n  }}\n  style={{if this.isMultipleColor this.multipleColorStyle}}\n>\n  {{#if @tooltip}}\n    <EmberTooltip @text={{@tooltip}} @side={{or @tooltipSide \"top\"}} />\n  {{/if}}\n</span>", {"contents":"<span\n  ...attributes\n  class={{class-names\n    \"inline-flex flex-shrink-0 rounded-full\"\n    (if (eq this.size \"small\") \"size-6\")\n    (if (eq this.size \"medium\") \"size-8\")\n    (if (eq this.size \"large\") \"size-12\")\n    (if this.isMultipleColor \"-rotate-[30deg]\" this.bgColor)\n  }}\n  style={{if this.isMultipleColor this.multipleColorStyle}}\n>\n  {{#if @tooltip}}\n    <EmberTooltip @text={{@tooltip}} @side={{or @tooltipSide \"top\"}} />\n  {{/if}}\n</span>","moduleName":"teamtailor/components/core/color-dot.hbs","parseOptions":{"srcName":"teamtailor/components/core/color-dot.hbs"}});
import Component from '@glimmer/component';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import {
  ALLOWED_COLORS,
  ALLOWED_VARIANTS,
  DECORATIVE_COLORS,
  DEFAULT_DECORATIVE_VARIANT,
  STANDARD_COLORS,
} from 'teamtailor/constants/core/color-dot';
import { bgColor } from 'teamtailor/utils/theme-color';

const DEFAULT_SIZE = 'large';
const ALLOWED_SIZES = ['small', 'medium', DEFAULT_SIZE];

const ALLOWED_ALERT_VARIANTS = ['strong'];

const DEFAULT_COLOR = 'zinc';

type AllowedSizes = (typeof ALLOWED_SIZES)[number];
type AllowedVariants = (typeof ALLOWED_VARIANTS)[number];
type AllowedColors = (typeof ALLOWED_COLORS)[number];

export type Args = {
  color: AllowedColors | AllowedColors[];
  size?: AllowedSizes;
  variant?: AllowedVariants;
};

export default class CoreColorDotComponent extends Component<Args> {
  get color(): string {
    [this.args.color].flat().forEach((color) => {
      verifyArg(color, ALLOWED_COLORS, 'ColorDot @color', DEFAULT_COLOR);
    });

    return (
      (Array.isArray(this.args.color) ? this.args.color[0] : this.args.color) ||
      DEFAULT_COLOR
    );
  }

  get isMultipleColor(): boolean {
    return Array.isArray(this.args.color);
  }

  get size(): string {
    verifyArg(this.args.size, ALLOWED_SIZES, 'ColorDot @size', DEFAULT_SIZE);
    return this.args.size ?? DEFAULT_SIZE;
  }

  isDecorative(color = this.color): boolean {
    return DECORATIVE_COLORS.includes(color);
  }

  isStandard(color = this.color): boolean {
    return STANDARD_COLORS.includes(color);
  }

  get bgColor(): string {
    return bgColor(this.color, this.variant());
  }

  get multipleColorStyle() {
    if (this.isMultipleColor) {
      const colors = this.args.color as AllowedColors[];
      const sliceCount = colors.length;
      const sliceAngle = 360 / sliceCount;

      let gradientString = colors
        .map((color, index) => {
          let colorVar = color;

          if (!this.isStandard(color)) {
            const prefixText = this.isDecorative(color) ? '-decorative' : '';
            const variant = this.variant(color);
            const variantText = variant ? `-${variant}` : '';

            colorVar = `var(--color-background${prefixText}-${color}${variantText})`;
          }

          const rotation = (multiplier: number) => {
            return multiplier * sliceAngle;
          };

          return `${colorVar} ${rotation(index)}deg ${rotation(index + 1)}deg`;
        })
        .join(', ');

      return `background: conic-gradient(${gradientString});`;
    }
  }

  variant(color = this.color) {
    if (this.isStandard(color)) {
      return undefined;
    } else if (this.isDecorative(color)) {
      return this.decorativeVariant;
    } else {
      return this.alertVariant;
    }
  }

  get decorativeVariant(): string {
    verifyArg(
      this.args.variant,
      ALLOWED_VARIANTS,
      'ColorDot @variant for decorative color',
      DEFAULT_DECORATIVE_VARIANT
    );

    return this.args.variant || DEFAULT_DECORATIVE_VARIANT;
  }

  get alertVariant() {
    verifyArg(
      this.args.variant,
      ALLOWED_ALERT_VARIANTS,
      'ColorDot @variant for alert color'
    );

    return this.args.variant || undefined;
  }
}
