import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<LinkTo\n  @query={{hash toolbox=(concat-comma-separated \"global_comments\" @jobId)}}\n  class=\"mx-16 flex flex-row items-center rounded-8 bg-neutral-weak px-8 py-4 hover:bg-neutral-medium\"\n  {{this.setActionIdsForJobCollaborations}}\n>\n  <span class=\"body-text-s text-neutral\">{{@name}}</span>\n\n  {{#if @showButton}}\n    <Button\n      @size=\"small\"\n      @appearance=\"secondary\"\n      @text={{t \"global_comments.mark_as_read\"}}\n      @onClick={{this.markChannelAsRead}}\n      class=\"ml-auto\"\n    />\n  {{/if}}\n</LinkTo>", {"contents":"<LinkTo\n  @query={{hash toolbox=(concat-comma-separated \"global_comments\" @jobId)}}\n  class=\"mx-16 flex flex-row items-center rounded-8 bg-neutral-weak px-8 py-4 hover:bg-neutral-medium\"\n  {{this.setActionIdsForJobCollaborations}}\n>\n  <span class=\"body-text-s text-neutral\">{{@name}}</span>\n\n  {{#if @showButton}}\n    <Button\n      @size=\"small\"\n      @appearance=\"secondary\"\n      @text={{t \"global_comments.mark_as_read\"}}\n      @onClick={{this.markChannelAsRead}}\n      class=\"ml-auto\"\n    />\n  {{/if}}\n</LinkTo>","moduleName":"teamtailor/components/toolbox/global-comments/read-only/channel-name-divider.hbs","parseOptions":{"srcName":"teamtailor/components/toolbox/global-comments/read-only/channel-name-divider.hbs"}});
import Component from '@glimmer/component';
import NotificationsService from 'teamtailor/services/notifications';
import { inject as service } from '@ember/service';
import { modifier } from 'ember-modifier';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

type Args = {
  jobId: string;
  name: string;
  showButton?: boolean;
};

export default class ChannelNameDividerComponent extends Component<Args> {
  @service declare notifications: NotificationsService;

  @tracked actionIds: string[] = [];

  setActionIdsForJobCollaborations = modifier(() => {
    if (this.notifications.unreadMentions) {
      this.actionIds = this.notifications.unreadMentions[this.args.jobId] || [];
    }
  });

  @action
  async markChannelAsRead(event: MouseEvent) {
    this.notifications.markAsRead({
      actionIds: this.actionIds,
    });

    event.preventDefault();
    event.stopPropagation();
  }
}
