import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { RequisitionModel } from 'teamtailor/models';

export default class JobsRequisitionApprovalFlowRoute extends Route {
  @service declare store: Store;

  model() {
    return this.modelFor('jobs.requisition') as RequisitionModel;
  }
}
