import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import { capitalize } from '@ember/string';
import uniq from 'teamtailor/utils/uniq';

const contentBlocks = [
  'custom',
  'faq',
  'grid',
  'infographic',
  'list',
  'picture',
  'testimonial',
  'text',
  'timeline',
  'value',
];

export default class EditorBlockLibraryController extends Controller {
  @service current;
  @service contentEditor;
  @service flipper;
  @service iframePreview;
  @service intl;
  @service router;
  @service store;

  get page() {
    return this.model?.page;
  }

  get sections() {
    return this.page?.sections;
  }

  get sectionTypes() {
    let allowedSections = [
      'blog',
      'connect',
      'cta',
      'custom',
      'departments-list',
      'faq',
      'gallery',
      'grid',
      'hero',
      'infographic',
      'jobs',
      'linked',
      'list',
      'locations-list',
      'numbers',
      'people',
      'picture',
      'social-links',
      'social',
      'stories',
      'testimonial',
      'text',
      'timeline',
      'video',
    ];

    if (this.page) {
      if (!this.page.isPostListPage) {
        allowedSections.push('cover');
      }

      if (this.page?.canHaveHeroSection) {
        allowedSections.push('hero');
      }

      if (this.page.isHomePage) {
        allowedSections = allowedSections.concat([
          'locations-map',
          'perks',
          'workplace',
        ]);

        if (this.current.company.groupSite) {
          allowedSections = allowedSections.concat(['group_jobs', 'companies']);
        }
      }

      if (this.page.isTemplatePage) {
        allowedSections = allowedSections.concat(['perks', 'workplace']);
      }
    }

    return allowedSections;
  }

  get sectionTypesAllowingMultiple() {
    const sectionTypesAllowingMultiple = [
      'cta',
      'connect',
      'custom',
      'faq',
      'gallery',
      'grid',
      'infographic',
      'linked',
      'locations-map',
      'numbers',
      'picture',
      'social-links',
      'stories',
      'testimonial',
      'text',
      'timeline',
      'list',
      'video',
    ];

    if (this.modelType === 'page') {
      sectionTypesAllowingMultiple.push('hero');
    }

    return sectionTypesAllowingMultiple;
  }

  get sectionTypesInUse() {
    return this.sections.map((section) => section.name);
  }

  get sectionTypesNotUsed() {
    return this.sectionTypes.filter(
      (type) => !this.sectionTypesInUse.includes(type)
    );
  }

  get sectionTypesAvailable() {
    const types = [
      ...this.sectionTypesNotUsed,
      ...this.sectionTypesAllowingMultiple,
    ];
    const sortedTypes = types.sort();
    return uniq(['linked', ...sortedTypes]);
  }

  get sortedSections() {
    return this.sections
      .rejectBy('page.id')
      .filterBy('isNew', false)
      .sortBy('rowOrder');
  }

  getContentBlockType(section) {
    if (contentBlocks.includes(section)) {
      return `ContentBlock::${capitalize(this.toCamelCase(section))}`;
    } else {
      return null;
    }
  }

  getSectionType(section) {
    if (contentBlocks.includes(section)) {
      return `content_block_${section}`;
    } else {
      return 'section';
    }
  }

  toCamelCase(section) {
    return section.replace(/([-][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '')
    );
  }

  @action
  async newSection({
    blockName,
    layoutName,
    mockSection,
    parentBlockId,
    position,
  }) {
    let params = {
      name: blockName,
      layout: layoutName,
      enabled: true,
      type: this.getContentBlockType(blockName),
    };
    let section, parent;

    if (position) {
      params.rowOrderPosition = position;
    }

    if (this.page.isDepartmentPage) {
      params.pickedDepartmentId = this.page.pagePublication.ownerId;
    } else if (this.page.isLocationPage) {
      params.pickedLocationId = this.page.pagePublication.ownerId;
    }

    if (parentBlockId) {
      parent = await this.store.findRecord('section', parentBlockId);
      params.parent = parent;
    }

    params = { ...params, [this.page.constructor.modelName]: this.page };

    if (mockSection) {
      for (const [key, value] of Object.entries(params)) {
        set(mockSection, key, value);
      }

      section = await mockSection.save();
    } else {
      section = await this.store
        .createRecord(this.getSectionType(blockName), params)
        .save();
    }

    section.pickedImages.forEach((pi) => pi.rollbackAttributes());

    this.router.transitionTo(
      'content.editor.section',
      parent ? parent : section
    );

    this.iframePreview.reloadSection(section.id);
    return section;
  }

  @action routeBack() {
    this.router.transitionTo('content.editor');
  }
}
