import { OTHER_OPTION } from 'teamtailor/utils/form-builder';

const EEO_US = [
  {
    uuid: 'ethnicity',
    label: 'Your race:',
    type: 'radio',
    options: [
      {
        id: 'hispanic_or_latino',
        label: 'Hispanic or Latino',
      },
      {
        id: 'white',
        label: 'White',
      },
      {
        id: 'black',
        label: 'Black or African American',
      },
      {
        id: 'pacific_islanders',
        label: 'Native Hawaiian or Other Pacific Islander',
      },
      {
        id: 'asian',
        label: 'Asian',
      },
      {
        id: 'native_american',
        label: 'Native American or Alaska Native',
      },
      {
        id: 'two_or_more',
        label: 'Two or more races',
      },
      {
        id: 'no_answer_for_race',
        label: 'I do not wish to provide this information',
      },
    ],
  },
  {
    uuid: 'gender',
    label: 'Your gender:',
    type: 'radio',
    options: [
      {
        id: 'male',
        label: 'Male',
      },
      {
        id: 'female',
        label: 'Female',
      },
      {
        id: 'no_answer_for_gender',
        label: 'I do not wish to provide this information',
      },
    ],
  },
  {
    uuid: 'veteran_status',
    label: 'Veteran status:',
    type: 'checkbox',
    options: [
      {
        id: 'not_applicable',
        label: 'Not applicable',
      },
      {
        id: 'vietnam',
        label: 'Vietnam Era Veteran',
      },
      {
        id: 'armed_forces',
        label: 'Armed Forces Service Medal Veteran',
      },
      {
        id: 'recently_separated',
        label: 'Recently Separated Veteran',
      },
      {
        id: 'other_protected',
        label: 'Other Protected Veteran',
      },
      {
        id: 'no_answer_for_veteran_status',
        label: 'I do not wish to provide this information',
      },
    ],
  },
];

const EEO_UK = [
  {
    uuid: 'ethnicity',
    label: 'Your ethnicity:',
    options: [
      {
        id: 'asian',
        label: 'Asian or Asian British',
      },
      {
        id: 'black',
        label: 'Black, African, Caribbean or Black British',
      },
      {
        id: 'mixed',
        label: 'Mixed or Multiple ethnic groups',
      },
      {
        id: 'white',
        label: 'White',
      },
      {
        id: 'other-ethnic',
        label: 'Other ethnic group',
      },
    ],

    type: 'radio',
  },
  {
    uuid: 'ethnicity-asian',
    label: 'If Asian, please specify your background:',
    options: [
      {
        id: 'indian',
        label: 'Indian',
      },
      {
        id: 'pakistani',
        label: 'Pakistani',
      },
      {
        id: 'bangladeshi',
        label: 'Bangladeshi',
      },
      {
        id: 'chinese',
        label: 'Chinese',
      },
      {
        id: OTHER_OPTION,
        label: 'Other...',
      },
    ],

    show_if: {
      ref_uuid: 'ethnicity',
      value: 'asian',
    },

    type: 'radio',
  },
  {
    uuid: 'ethnicity-black',
    label: 'If Black, please specify your background:',
    options: [
      {
        id: 'african',
        label: 'African',
      },
      {
        id: 'caribbean',
        label: 'Caribbean',
      },
      {
        id: OTHER_OPTION,
        label: 'Other...',
      },
    ],

    show_if: {
      ref_uuid: 'ethnicity',
      value: 'black',
    },

    type: 'radio',
  },
  {
    uuid: 'ethnicity-mixed',
    label: 'If Mixed, please specify your background:',
    options: [
      {
        id: 'white-asian',
        label: 'White & Asian',
      },
      {
        id: 'white-african',
        label: 'White & Black African',
      },
      {
        id: 'white-caribbean',
        label: 'White & Black Caribbean',
      },
      {
        id: OTHER_OPTION,
        label: 'Other...',
      },
    ],

    show_if: {
      ref_uuid: 'ethnicity',
      value: 'mixed',
    },

    type: 'radio',
  },
  {
    uuid: 'ethnicity-white',
    label: 'If White, please specify your background:',
    options: [
      {
        id: 'british',
        label: 'English, Welsh, Scottish, Northern Irish or British',
      },
      {
        id: 'irish',
        label: 'Irish',
      },
      {
        id: 'irish-traveller',
        label: 'Gypsy or Irish Traveller',
      },
      {
        id: OTHER_OPTION,
        label: 'Other...',
      },
    ],

    show_if: {
      ref_uuid: 'ethnicity',
      value: 'white',
    },

    type: 'radio',
  },

  {
    uuid: 'ethnicity-other',
    label: 'If from other ethnic group, please specify your background:',
    options: [
      {
        id: 'arab',
        label: 'Arab',
      },
      {
        id: OTHER_OPTION,
        label: 'Other...',
      },
    ],

    show_if: {
      ref_uuid: 'ethnicity',
      value: 'other-ethnic',
    },

    type: 'radio',
  },

  {
    uuid: 'religion',
    label: 'Your religion or belief:',
    options: [
      {
        id: 'buddhist',
        label: 'Buddhist',
      },
      {
        id: 'christian',
        label: 'Christian',
      },
      {
        id: 'hindu',
        label: 'Hindu',
      },
      {
        id: 'jewish',
        label: 'Jewish',
      },
      {
        id: 'muslim',
        label: 'Muslim',
      },
      {
        id: 'sikh',
        label: 'Sikh',
      },
      {
        id: 'none',
        label: 'None',
      },
      {
        id: 'religion-no-say',
        label: 'Prefer not to say',
      },
      {
        id: OTHER_OPTION,
        label: 'Other',
      },
    ],

    type: 'radio',
  },
  {
    uuid: 'age',
    label: 'Your age:',
    options: [
      {
        id: 'a18-25',
        label: '18-25',
      },
      {
        id: 'a26-34',
        label: '26-34',
      },
      {
        id: 'a35-49',
        label: '35-49',
      },
      {
        id: 'a50plus',
        label: '50+',
      },
      {
        id: 'age-no-say',
        label: 'Prefer not to say',
      },
    ],

    type: 'radio',
  },
  {
    uuid: 'gender',
    label: 'Your gender & gender identity:',
    options: [
      {
        id: 'female',
        label: 'Female',
      },
      {
        id: 'male',
        label: 'Male',
      },
      {
        id: 'gender-no-say',
        label: 'Prefer not to say',
      },
      {
        id: OTHER_OPTION,
        label: 'Other...',
      },
    ],

    type: 'radio',
  },
  {
    uuid: 'trans',
    label: 'Do you identify as trans?',
    options: [
      {
        id: 'yes',
        label: 'Yes',
      },
      {
        id: 'no',
        label: 'No',
      },
      {
        id: 'trans-no-say',
        label: 'Prefer not to say',
      },
    ],

    type: 'radio',
  },
  {
    uuid: 'sexual-orientation',
    label: 'Sexual orientation:',
    options: [
      {
        id: 'bisexual',
        label: 'Bisexual',
      },
      {
        id: 'heterosexual',
        label: 'Heterosexual',
      },
      {
        id: 'homosexual',
        label: 'Homosexual',
      },
      {
        id: 'sexual-no-say',
        label: 'Prefer not to say',
      },
      {
        id: OTHER_OPTION,
        label: 'Other...',
      },
    ],

    type: 'radio',
  },
  {
    uuid: 'disability',
    label: 'Disability:',
    options: [
      {
        id: 'none',
        label: 'None',
      },
      {
        id: 'disability-no-say',
        label: 'Prefer not to say',
      },
      {
        id: OTHER_OPTION,
        label: 'Yes (specify)',
      },
    ],

    type: 'radio',
  },
];

const templates = [
  {
    id: 'eeo-uk',
    name: 'Equal Opportunities (UK)',
    color: 'amber',
    description:
      'The aim of this survey is to ensure that no job applicant or employee receives less favourable treatment because of age, disability, gender reassignment, marriage and civil partnership, pregnancy or maternity, race, religion or belief, sex or sexual orientation.',

    form: EEO_UK,
  },
  {
    id: 'eeo-us',
    name: 'EEO (US)',
    color: 'emerald',
    description:
      'Our company is an equal employment opportunity/affirmative action employer. It does not discriminate on the basis of race, color, national origin, sex, religion, ancestry, age, sexual orientation, marital status, disability, veteran status, citizenship status, or any other protected characteristic. Certain laws and regulations regarding equal employment opportunity, and/or affirmative action direct us to compile, maintain, and report certain information on employees. In order to comply with these laws and regulations, we are requesting your cooperation in completing this voluntary EEO Self-Identification Form.',

    form: EEO_US,
  },
];

export default templates;
