import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { AllTriggerModels } from 'teamtailor/models/trigger';
import { inject as service } from '@ember/service';
import Server from 'teamtailor/services/server';
export default class JobApplicationTriggerModel extends Model {
  @service declare server: Server;

  @attr('string') declare jobApplicationId: string;
  @attr('string') declare status:
    | 'pending'
    | 'failed'
    | 'succeeded'
    | 'cancelled';

  @attr('date') declare scheduledAt?: Date;
  @belongsTo('trigger', {
    async: true,
    polymorphic: true,
  })
  declare trigger: AsyncBelongsTo<AllTriggerModels>;

  get isPending() {
    return this.status === 'pending' && !!this.scheduledAt;
  }

  async triggerNow() {
    return this.server.memberAction(this, {
      action: 'trigger_now',
      method: 'POST',
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'job-application-trigger': JobApplicationTriggerModel;
  }
}
