import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BadgeCluster @gapSize=\"medium\" ...attributes>\n  {{#if @isPrivate}}\n    <Core::Badge @text={{t \"settings.questions.is_private\"}} @color=\"cerise\" />\n  {{/if}}\n\n  {{#if @type}}\n    <Core::Badge @text={{t this.text}} as |b|>\n      <b.icon @icon={{this.icon}} />\n    </Core::Badge>\n  {{/if}}\n\n  {{#if @addedByCopilot}}\n    <Core::Badge\n      @text={{t \"recruiter_copilot.added_by_copilot\"}}\n      @tooltip={{t \"recruiter_copilot.added_question\"}}\n      @color=\"purple\"\n      as |b|\n    >\n      <b.icon @icon=\"sparkles\" />\n    </Core::Badge>\n  {{else if @createdByCopilot}}\n    <Core::Badge\n      @text={{t \"recruiter_copilot.created_by_copilot\"}}\n      @tooltip={{t \"recruiter_copilot.created_question\"}}\n      @color=\"purple\"\n      as |b|\n    >\n      <b.icon @icon=\"sparkles\" />\n    </Core::Badge>\n  {{/if}}\n</BadgeCluster>", {"contents":"<BadgeCluster @gapSize=\"medium\" ...attributes>\n  {{#if @isPrivate}}\n    <Core::Badge @text={{t \"settings.questions.is_private\"}} @color=\"cerise\" />\n  {{/if}}\n\n  {{#if @type}}\n    <Core::Badge @text={{t this.text}} as |b|>\n      <b.icon @icon={{this.icon}} />\n    </Core::Badge>\n  {{/if}}\n\n  {{#if @addedByCopilot}}\n    <Core::Badge\n      @text={{t \"recruiter_copilot.added_by_copilot\"}}\n      @tooltip={{t \"recruiter_copilot.added_question\"}}\n      @color=\"purple\"\n      as |b|\n    >\n      <b.icon @icon=\"sparkles\" />\n    </Core::Badge>\n  {{else if @createdByCopilot}}\n    <Core::Badge\n      @text={{t \"recruiter_copilot.created_by_copilot\"}}\n      @tooltip={{t \"recruiter_copilot.created_question\"}}\n      @color=\"purple\"\n      as |b|\n    >\n      <b.icon @icon=\"sparkles\" />\n    </Core::Badge>\n  {{/if}}\n</BadgeCluster>","moduleName":"teamtailor/components/settings/questions/question-type.hbs","parseOptions":{"srcName":"teamtailor/components/settings/questions/question-type.hbs"}});
import Component from '@glimmer/component';
import { QuestionType, QUESTION_TYPES } from 'teamtailor/constants/question';
interface SettingsQuestionsQuestionTypeArgs {
  type: QuestionType;
  isPivate?: boolean;
}

export default class SettingsQuestionsQuestionType extends Component<SettingsQuestionsQuestionTypeArgs> {
  get text() {
    return QUESTION_TYPES[this.args.type].translationKey;
  }

  get icon() {
    return QUESTION_TYPES[this.args.type].icon;
  }
}
