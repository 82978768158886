import Component from '@glimmer/component';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class extends Component {
  @service blockAdjustments;

  get isDisabled() {
    return (
      this.blockAdjustments.editingAttribute &&
      this.blockAdjustments.editingAttribute !== this.args.attribute
    );
  }

  get value() {
    return get(this, `args.model.${this.args.attribute}`);
  }

  set value(value) {
    set(this, `args.model.${this.args.attribute}`, value);
  }
}
