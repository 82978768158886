import Service, { inject as service } from '@ember/service';
import anonymals from 'teamtailor/utils/anonymals';
import AssetMapService from './asset-map';

export default class AnonymalsService extends Service {
  @service declare assetMap: AssetMapService;

  fromSeed(seed: string) {
    const anonymal = anonymals.fromSeed(seed);
    return {
      ...anonymal,
      iconUrl: this.assetMap.resolve(
        `assets/images/anonymals/${anonymal.animal}.svg`
      ),
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    anonymals: AnonymalsService;
  }
}
