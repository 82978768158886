export const TABLE_COLUMNS = [
  {
    sortable: false,
    width: 50,
  },
  {
    label: 'components.nurture_campaign_recipients.table.name',
    sortable: false,
    width: 250,
  },

  {
    label: 'components.nurture_campaign_recipients.table.current_touchpoint',
    sortable: true,
    sortColumn: 'touchpoint',
    defaultSortOrder: 'asc',
    width: 200,
  },
  {
    label: 'components.nurture_campaign_recipients.table.next_touchpoint_on',
    sortable: true,
    sortColumn: 'next_step_at',
    defaultSortOrder: 'asc',
    width: 200,
  },
  {
    label: 'components.nurture_campaign_recipients.table.added_on',

    sortable: true,
    sortColumn: 'created_at',
    defaultSortOrder: 'asc',
    width: 200,
  },
  {
    label: 'common.status',

    sortable: true,
    sortColumn: 'status',
    defaultSortOrder: 'asc',
    width: 130,
  },
];

export const LOADING_COLUMNS = [
  {
    hasOnlyAvatar: true,
  },
  {
    width: '2xl',
  },
  {
    width: 'm',
  },
  {
    width: 'm',
  },
  {
    width: 'm',
  },
  {
    width: 's',
  },
];
