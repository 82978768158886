import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Modal @isOpen={{@isOpen}} @onClose={{@onClose}} @size=\"small\" as |modal|>\n  <modal.Header @text={{t \"components.download_link.virus_detected_title\"}} />\n  <modal.Main as |main|>\n    <main.Section>\n      {{t \"components.download_link.virus_detected_description\"}}\n    </main.Section>\n  </modal.Main>\n  <modal.Footer>\n\n    <Button\n      @appearance=\"tertiary\"\n      @onClick={{@onClose}}\n      @text={{t \"components.download_link.virus_cancel_download\"}}\n    />\n    <StatusButton\n      @action={{@download}}\n      @text={{t \"components.download_link.virus_confirm_download\"}}\n      @isDestructive={{true}}\n    />\n  </modal.Footer>\n</Core::Modal>", {"contents":"<Core::Modal @isOpen={{@isOpen}} @onClose={{@onClose}} @size=\"small\" as |modal|>\n  <modal.Header @text={{t \"components.download_link.virus_detected_title\"}} />\n  <modal.Main as |main|>\n    <main.Section>\n      {{t \"components.download_link.virus_detected_description\"}}\n    </main.Section>\n  </modal.Main>\n  <modal.Footer>\n\n    <Button\n      @appearance=\"tertiary\"\n      @onClick={{@onClose}}\n      @text={{t \"components.download_link.virus_cancel_download\"}}\n    />\n    <StatusButton\n      @action={{@download}}\n      @text={{t \"components.download_link.virus_confirm_download\"}}\n      @isDestructive={{true}}\n    />\n  </modal.Footer>\n</Core::Modal>","moduleName":"teamtailor/components/candidate-modal-new/main/section/documents/document/virus-modal/index.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-modal-new/main/section/documents/document/virus-modal/index.hbs"}});
import Component from '@glimmer/component';

interface CandidateModalNewMainDocumentVirusModalComponentArgs {
  isOpen: () => boolean;
  onClose: () => void;
}

export default class CandidateModalNewMainSectionDocumentDocumentVirusModalComponent extends Component<CandidateModalNewMainDocumentVirusModalComponentArgs> {}
