import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CandidateChatController extends Controller {
  @service store;

  get page() {
    return this.model;
  }

  get messageWidget() {
    return this.page.messageWidget;
  }

  @action
  async createCustomMessageWidget() {
    const messageWidget = this.store.createRecord('message-widget', {
      page: this.page,
      enabled: true,
    });
    await messageWidget.save();
  }

  @action
  async handleToggleMessageWidget() {
    this.page.messageWidget.toggleProperty('enabled');
    await this.save();
  }

  @action
  async createAndDisableMessageWidget() {
    const messageWidget = this.store.createRecord('message-widget', {
      page: this.page,
      enabled: false,
    });
    await messageWidget.save();
  }

  @action
  async save() {
    const messageWidget = await this.messageWidget;
    return messageWidget.save();
  }

  @action
  async destroyMessageWidget() {
    const messageWidget = await this.model.messageWidget;
    return messageWidget.destroyRecord();
  }
}
