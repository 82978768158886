import MeetingShowRoute from 'teamtailor/routes/meetings/events/show';

export default class JobsJobActivityCandidateMeetingShowRoute extends MeetingShowRoute {
  controllerName = 'meetings.events.show';
  templateName = 'meetings.events.show';

  meetingRouteRouteName =
    'nurture-campaigns.nurture-campaign.recipients.candidate.meetings';

  candidateRouteRouteName =
    'nurture-campaigns.nurture-campaign.recipients.candidate';

  jobRouteRouteName = '';
}
