import { AsyncBelongsTo } from '@ember-data/model';
import SectionModel from 'teamtailor/models/section';
import CurrentService from 'teamtailor/services/current';
import Store from '@ember-data/store';
import Component from '@glimmer/component';
import { action, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import RouterService from '@ember/routing/router-service';
import TtAlertService from 'teamtailor/services/tt-alert';
import IframePreviewService from 'teamtailor/services/iframe-preview';
import IntlService from 'ember-intl/services/intl';
import FilthyContent from 'teamtailor/services/filthy-content';
import { SectionItemModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';

export type BaseComponentArgs<T extends SectionModel = SectionModel> = {
  model: AsyncBelongsTo<T>;
};

export default class BaseComponent<
  ParentArgs extends BaseComponentArgs
> extends Component<ParentArgs> {
  @service declare router: RouterService;
  @service declare ttAlert: TtAlertService;
  @service declare iframePreview: IframePreviewService;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare current: CurrentService;
  @service declare filthyContent: FilthyContent;

  isAddClicked = false;

  @tracked draggingEnabled = false;
  @tracked focusedItem: SectionItemModel | null = null;

  get company() {
    return this.current.company;
  }

  get hasSectionItemChanges() {
    return !!get(this.args.model, 'sectionItems')?.filterBy(
      'hasDirtyAttributes'
    ).length;
  }

  get sortedItems() {
    return get(this.args.model, 'itemsArray');
  }

  get showTitleInput() {
    return (
      isEmpty(get(this.args.model, 'titleType')) ||
      get(this.args.model, 'titleType') !== 'none'
    );
  }

  get showSubtitleInput() {
    return (
      isEmpty(get(this.args.model, 'titleType')) ||
      get(this.args.model, 'titleType') === 'title-subtitle'
    );
  }

  @action async reload(saveFirst = false) {
    if (saveFirst) {
      await (await this.args.model)?.save();
    }

    const sectionId = get(this.args.model, 'id');
    this.iframePreview.reloadSection(sectionId);
  }

  insertItemAt(
    indexPosition: number,
    params: Pick<
      SectionItemModel,
      'modelType' | 'rowOrderPosition' | 'modelId' | 'section'
    >
  ) {
    get(this.args.model, 'sortedItems')?.forEach((item, index) => {
      const newPosition = indexPosition >= index ? index + 1 : index;
      item.rowOrderPosition = newPosition;
    });

    params.rowOrderPosition = indexPosition;
    const item = this.store.createRecord('section-item', params);
    return item.save();
  }

  removeItemAt(index: number) {
    const item = get(this.args.model, 'sortedItems')?.[index];
    return item?.destroyRecord();
  }

  async setModelAsSectionItem(
    index: number,
    model?: SectionModel,
    modelType?: string
  ) {
    const modelId = model?.id;
    const items = get(this.args.model, 'sortedItems');

    if ((items?.length || 0) > index) {
      await this.removeItemAt(index);
    }

    if (modelId) {
      await this.insertItemAt(index, {
        modelType,
        modelId,
        section: this.args.model,
      });
    }

    this.reload();
  }

  async addSectionItem(itemType: string, save = false) {
    const sectionItem = await this.store.createRecord('section-item', {
      section: this.args.model,
      itemType,
    });

    if (save) {
      await sectionItem.save();
      this.reload();
    }

    this.focusedItem = sectionItem;
  }

  @action
  addNewItem(type: string) {
    this.isAddClicked = true;
    this.router.transitionTo(`content.editor.section.${type}.new`);
  }

  hideSaveButton?: boolean;

  @action
  deleteItem(item: SectionItemModel) {
    this.hideSaveButton = true;
    this.ttAlert.confirm(
      this.intl.t('content.editor.confirm_delete_section_item'),
      async () => {
        let newArray = get(this.args.model, 'itemsArray')?.rejectBy(
          'id',
          item.id
        );
        newArray = newArray?.map((item, index) => {
          setProperties(item, { /* index, */ rowOrderPosition: index });
          return item;
        });

        get(this.args.model, 'items')?.setObjects(newArray!);

        (await this.args.model)?.save().then(() => {
          this.reload();
          this.hideSaveButton = false;
        });
      },
      () => {}
    );
  }

  @action
  async handleDragEnd({
    draggedItem,
    sourceList,
    sourceIndex,
    targetIndex,
    targetList,
  }: {
    draggedItem: SectionItemModel;
    sourceList: SectionItemModel[];
    sourceIndex: number;
    targetList: SectionItemModel[];
    targetIndex: number;
  }) {
    if (sourceIndex === targetIndex) {
      return;
    }

    sourceList.removeAt(sourceIndex);
    targetList.insertAt(targetIndex, draggedItem);

    targetList.forEach((item, index) => {
      item.rowOrderPosition = index;
    });

    get(this.args.model, 'items')?.setObjects(targetList);

    await (await this.args.model)?.save();
    this.reload();
  }

  @action
  async handleSectionItemDragEnd({
    draggedItem,
    sourceIndex,
    targetIndex,
  }: {
    draggedItem: SectionItemModel;
    sourceList: SectionItemModel[];
    sourceIndex: number;
    targetList: SectionItemModel[];
    targetIndex: number;
  }) {
    if (sourceIndex === targetIndex) {
      return;
    }

    draggedItem.rowOrderPosition = targetIndex;

    const sectionItems = get(this.args.model, 'sortedItems')?.toArray();
    sectionItems?.removeObject(draggedItem);
    sectionItems?.insertAt(targetIndex, draggedItem);

    sectionItems?.forEach((item, index) => (item.rowOrderPosition = index));

    await draggedItem.save();
    this.reload();
  }

  @action
  async saveCustomName(customName: string, section: SectionModel) {
    await section.setCustomName({ custom_name: customName });
  }

  @action
  async handleNewSectionItem(itemType: string) {
    this.addSectionItem(itemType);
  }

  @action
  handleSetItemFocus(sectionItem: SectionItemModel) {
    this.focusedItem = sectionItem;
  }

  @action
  handleClearItemFocus() {
    this.focusedItem = null;
  }
}
