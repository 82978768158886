import Store from '@ember-data/store';
import { A } from '@ember/array';
import Router from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Intl from 'ember-intl/services/intl';
import SettingsInterviewKitsEditController from 'teamtailor/controllers/settings/interview-kits/edit';
import InterviewKitModel from 'teamtailor/models/interview-kit';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get } from 'teamtailor/utils/get';
import { Transition } from 'teamtailor/utils/type-utils';
import { AddonFeature } from 'types/features';

export default class SettingsInterviewKitsEditRoute extends ScrollToTopRoute {
  @service declare router: Router;
  @service declare intl: Intl;
  @service declare store: Store;

  requiredFeature: AddonFeature = 'interview_kit';

  model(params: { interview_kit_id: string }) {
    return this.store.findRecord('interview-kit', params.interview_kit_id);
  }

  afterModel(model: InterviewKitModel) {
    if (get(model, 'isHidden')) {
      this.router.transitionTo('settings.interview-kits');
    }
  }

  resetController(
    controller: SettingsInterviewKitsEditController,
    isExiting: boolean,
    transition: Transition
  ) {
    super.resetController(controller, isExiting, transition);

    if (isExiting) {
      controller.rollbackChanges();
    }
  }

  activate() {
    this.router.on('routeWillChange', this.onRouterWillChange);
  }

  deactivate() {
    this.router.off('routeWillChange', this.onRouterWillChange);
  }

  onRouterWillChange = (transition: Transition) => {
    if (!transition.to.find((route) => route.name === this.routeName)) {
      const interviewKit = this.controller
        .model as SettingsInterviewKitsEditController['model'];

      /* eslint-disable-next-line new-cap */
      const unsavedModels = A([
        interviewKit,
        ...interviewKit.pickedQuestions.toArray(),
        ...interviewKit.scorecardPicks.toArray(),
      ]).filter((model) => {
        return get(model, 'isNew') || get(model, 'hasDirtyAttributes');
      });

      if (unsavedModels.length) {
        if (confirm(this.intl.t('common.unsaved_changes_confirm'))) {
          unsavedModels.invoke('rollbackAttributes');
        } else {
          transition.abort();
        }
      }
    }
  };
}
