export const LOADING_COLUMNS = [
  {
    width: '2xl',
  },
  {
    width: 'm',
    contentType: 'core/badge',
  },
  {
    width: 'm',
  },
  {
    width: 'm',
  },
];
