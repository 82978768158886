import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { get, set, action } from '@ember/object';
import { later } from '@ember/runloop';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import { tracked } from '@glimmer/tracking';

export default class SettingsTeamsNewController extends Controller {
  @service store;
  @service intl;
  @service router;
  @service flashMessages;

  successMessage = 'settings.teams.team_created';

  get team() {
    return this.model;
  }

  @tracked teamUsers = null;
  @tracked departmentsOrRoles = null;

  resetTeamRelationships() {
    set(this, 'team.roles', []);
    set(this, 'team.departments', []);
  }

  @action
  handleSelectLocation(location) {
    toggleInList(get(this, 'team.locations'), location);
  }

  handlePickUser(user) {
    this.teamUsers.pushObject(user);
  }

  handleDropUser(user) {
    this.teamUsers.removeObject(user);
  }

  @action
  toggleTeamMember(user) {
    if (this.teamUsers.findBy('id', user.id)) {
      this.handleDropUser(user);
    } else {
      this.handlePickUser(user);
    }
  }

  @action
  addDepartmentOrRole() {
    const dr = this.store.createRecord('department-or-role', {
      team: get(this, 'team'),
    });

    this.departmentsOrRoles.addObject(dr);
  }

  @action
  saveTeam() {
    this.resetTeamRelationships();
    set(this, 'team.users', this.teamUsers);
    this.departmentsOrRoles.forEach((dr) => {
      if (get(dr, 'role.id')) {
        get(this, 'team.roles').addObject(
          this.store.peekRecord('role', get(dr, 'role.id'))
        );
      } else if (get(dr, 'department.id')) {
        get(this, 'team.departments').addObject(
          this.store.peekRecord('department', get(dr, 'department.id'))
        );
      }
    });

    return this.team.save().then(() => {
      later(() => {
        this.flashMessages.success(this.intl.t(this.successMessage));
        this.router.transitionTo('settings.teams');
      }, 200);
    });
  }

  @action
  onDeleteDepartmentOrRole(dr) {
    this.departmentsOrRoles.removeObject(dr);
    dr.deleteRecord();
  }
}
