import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { CUSTOM_FORM_CHANNELS } from 'teamtailor/constants/custom-form-channels';
import { get } from 'teamtailor/utils/get';

export default class ActivateController extends Controller {
  @service flipper;
  @service intl;
  @service current;
  @service router;

  queryParams = ['currency'];

  @tracked selectedActivationType = this.model.defaultActivationType;
  @tracked providerKeyData = {};

  get supportMessage() {
    return this.intl.t('marketplace.use_existing_agreement', {
      name: get(this, 'model.name'),
    });
  }

  get showActivationForm() {
    return (
      !this.model.allowedPaymentTypes ||
      this.model.allowedPaymentTypes === 'partner' ||
      this.selectedActivationType === 'existing_agreement'
    );
  }

  get supportSubject() {
    return this.intl.t('marketplace.activate_subject', {
      subject: get(this, 'model.name'),
    });
  }

  get buttonIsDisabled() {
    switch (get(this, 'model.templateName')) {
      case 'stepstone_de':
        return (
          this.selectedActivationType === 'existing_agreement' &&
          !this.stepstoneCredentialsPresent
        );
      case 'ams':
        return (
          isEmpty(get(this, 'channelActivation.amsSenderId')) ||
          isEmpty(get(this.current.company, 'organizationNumber'))
        );
      case 'finn':
        return isEmpty(get(this, 'channelActivation.finnClientId'));
      case 'blocket':
        return isEmpty(get(this.current.company, 'organizationNumber'));

      case 'monster':
        return (
          this.selectedActivationType === 'existing_agreement' &&
          !this.monsterCredentialsPresent
        );

      default:
        return false;
    }
  }

  get hasCustomForm() {
    return CUSTOM_FORM_CHANNELS.includes(this.model.internalName);
  }

  get stepstoneCredentialsPresent() {
    return (
      this.channelActivation.stepstoneDeOrganisationId &&
      this.channelActivation.stepstoneDeSenderId
    );
  }

  get monsterCredentialsPresent() {
    return (
      get(this, 'channelActivation.monsterUsername') &&
      get(this, 'channelActivation.monsterPassword')
    );
  }

  get isExistingAgreement() {
    return this.selectedActivationType === 'existing_agreement';
  }

  get isAgreedWithPartner() {
    return this.model.priceForCountry === null;
  }

  get isFreeChannel() {
    return this.model.priceForCountry === 0;
  }

  @action
  async save() {
    try {
      await this.current.company.save();
      this.channelActivation.adapterAuthorizationToken = this.providerKeyData;
      this.channelActivation.existingAgreement = this.isExistingAgreement;
      await this.channelActivation.save();
    } catch (e) {
      return Promise.reject();
    }

    this.router.transitionTo('marketplace.channels.index.channel');
    return undefined;
  }

  @action
  handleClose() {
    return this.router.transitionTo('marketplace.channels.index.channel');
  }

  redirectAfterSave(channel, channelActivation) {
    if (channel.activationConfig && channelActivation) {
      return this.router.transitionTo(
        `settings.integrations.channel-activation`,
        channelActivation
      );
    }

    return this.router.transitionTo(
      `settings.integrations.marketplace-activations`
    );
  }
}
