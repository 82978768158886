export function startOfDay(d) {
  let date;
  if (typeof d === 'string') {
    date = new Date(`${d}T00:00:00`);
  } else {
    date = new Date(d?.getTime());
    date.setHours(0, 0, 0, 0);
  }

  return date;
}

export const today = startOfDay(new Date());

export const tomorrow = startOfDay(
  new Date(new Date().setDate(today.getDate() + 1))
);

export const yesterday = startOfDay(
  new Date(new Date().setDate(today.getDate() - 1))
);

export const nextWeek = startOfDay(
  new Date(new Date().setDate(today.getDate() + 7))
);

export const nextMonday = (() => {
  let date = startOfDay(new Date());
  date.setDate(date.getDate() + 7);
  date.setDate(date.getDate() - date.getDay() + 1);
  return date;
})();

export const twoMonths = startOfDay(
  new Date(new Date().setMonth(today.getMonth() + 2))
);

export const threeMonths = startOfDay(
  new Date(new Date().setMonth(today.getMonth() + 3))
);

export const sixMonths = startOfDay(
  new Date(new Date().setMonth(today.getMonth() + 6))
);

export const oneYear = startOfDay(
  new Date(new Date().setMonth(today.getMonth() + 12))
);

export function isSameDay(d1, d2) {
  let date1 = startOfDay(d1);
  let date2 = startOfDay(d2);
  return date1.getTime() === date2.getTime();
}

export const DEFAULT_SCHEDULE_OPTIONS = ['today', 'tomorrow', 'nextMonday'];

export const ALLOWED_SCHEDULE_OPTIONS = DEFAULT_SCHEDULE_OPTIONS.concat([
  'twoMonths',
  'threeMonths',
  'sixMonths',
  'oneYear',
]);

export const SCHEDULE_OPTIONS_WITH_ATTRIBUTES = [
  {
    name: 'today',
    icon: 'calendar-empty',
    iconStyle: 'kit',
    iconClass: '!text-green-600',
    value: today,
  },
  {
    name: 'tomorrow',
    icon: 'sun-bright',
    iconClass: '!text-teal-600',
    value: tomorrow,
  },
  {
    name: 'nextMonday',
    icon: 'calendar-next',
    iconStyle: 'kit',
    iconClass: '!text-cyan-600',
    value: nextMonday,
  },
  {
    name: 'twoMonths',
    icon: 'circle-2',
    iconClass: '!text-cyan-600',
    value: twoMonths,
  },
  {
    name: 'threeMonths',
    icon: 'circle-3',
    iconClass: '!text-light-blue-600',
    value: threeMonths,
  },
  {
    name: 'sixMonths',
    icon: 'circle-6',
    iconClass: '!text-indigo-600',
    value: sixMonths,
  },
  {
    name: 'oneYear',
    icon: 'earth-americas',
    iconClass: '!text-violet-600',
    value: oneYear,
    format: 'D MMM YYYY',
  },
];
