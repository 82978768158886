import { inject as service } from '@ember/service';
import { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import CandidateModalController from 'teamtailor/controllers/candidate-modal';
import { get } from 'teamtailor/utils/get';

export default class CandidateController extends CandidateModalController {
  @service infinity;
  @service router;
  @service flipper;
  @service current;

  @controller('candidates.segment')
  segmentController;

  backRoute = 'candidates.segment';

  get browseCandidates() {
    return get(this.segmentController, 'candidates');
  }

  @action
  loadMoreCandidates() {
    get(this, 'infinity').infinityLoad(this.browseCandidates);
  }

  @action
  onCandidateMerge(candidateIdsToRemove = []) {
    if (this.segmentController.candidates) {
      const candidatesToRemove = this.segmentController.candidates.filter(
        (candidate) => {
          return candidateIdsToRemove.includes(get(candidate, 'id'));
        }
      );

      this.segmentController.candidates.removeObjects(candidatesToRemove);
    }
  }
}
