import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { wait } from 'ember-animated';
import {
  CannedResponseModel,
  InterviewKitModel,
  JobModel,
  QuestionModel,
} from 'teamtailor/models';

export default class PickedQuestionModel extends Model {
  @belongsTo('canned-response')
  declare cannedResponse: AsyncBelongsTo<CannedResponseModel>;

  @belongsTo('interview-kit')
  declare interviewKit: AsyncBelongsTo<InterviewKitModel>;

  @belongsTo('job') declare job: AsyncBelongsTo<JobModel>;
  @belongsTo('question', { async: true })
  declare question: AsyncBelongsTo<QuestionModel>;

  @attr('boolean', { defaultValue: false }) declare booleanAnswer: boolean;
  @attr('boolean', { defaultValue: false }) declare isQualifying: boolean;
  @attr('boolean', { defaultValue: false }) declare mandatory: boolean;
  @attr('number') declare rangeMaxAnswer: number;
  @attr('number') declare rangeMinAnswer: number;
  @attr('number') declare rangeSingleAnswer: number;
  @attr('number') declare rowOrder: number;
  @attr('number') declare rowOrderPosition: number;
  @attr('number') declare singleChoiceAnswer: number;
  @attr('string') declare ownerId: string;
  @attr('string') declare ownerType: string;
  @attr('string') declare questionId: string;
  @attr('string', { defaultValue: 'above' }) declare rangeOperator: string;
  @attr('string', { defaultValue: 'all' }) declare multipleChoiceType: string;
  @attr({
    defaultValue() {
      return [];
    },
  })
  declare multipleChoiceAnswers: string[];

  get position() {
    const { rowOrderPosition, rowOrder } = this;
    return isEmpty(rowOrderPosition) ? rowOrder : rowOrderPosition;
  }

  get fromConnect() {
    return this.ownerType === 'Company';
  }

  @action
  loadAsyncRelationships(timeout = 10000) {
    const thisModel = this as InstanceType<typeof PickedQuestionModel>;

    return Promise.race([
      thisModel.belongsTo('question').load(),
      wait(timeout),
    ]);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'picked-question': PickedQuestionModel;
  }
}
