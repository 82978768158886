import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { set } from '@ember/object';
import { isPresent } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import Current from 'teamtailor/services/current';
import IntercomService from 'teamtailor/services/intercom';
import TtAlertService from 'teamtailor/services/tt-alert';
import AnalyticsService from 'teamtailor/services/analytics';
import IntlService from 'ember-intl/services/intl';
import PermissionsService from 'teamtailor/services/permissions';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { Transition } from 'teamtailor/utils/type-utils';
import { AllModels, JobModel } from 'teamtailor/models';
import AnalyticsController from 'teamtailor/controllers/analytics';
import { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import ArrayProxy from '@ember/array/proxy';

type AnalyticsRouteModelParams = {
  jobId?: string;
  startDate?: string;
  endDate?: string;
};

@classic
export default class AnalyticsRoute extends Route {
  @service declare current: Current;
  @service declare intercom: IntercomService;
  @service declare ttAlert: TtAlertService;
  @service declare analytics: AnalyticsService;
  @service declare intl: IntlService;
  @service declare permissions: PermissionsService;
  @service declare router: RouterService;
  @service declare store: Store;

  queryParams = {
    startDate: { refreshModel: true },
    endDate: { refreshModel: true },
    jobId: { refreshModel: true },
  };

  beforeModel(transition: Transition) {
    const { fromJob } = transition.to.queryParams;
    const { jobId } = transition.to.queryParams;

    if (jobId && isPresent(fromJob) && isPresent(jobId)) {
      this.router.transitionTo('jobs.job.analytics', jobId);
    }
  }

  model(params: AnalyticsRouteModelParams) {
    const { jobId = null, startDate = null, endDate = null } = params;

    this.analytics.setStartDate(startDate);
    this.analytics.setEndDate(endDate);
    this.analytics.jobId = jobId;

    if (jobId) {
      return this.store.findRecord('job', jobId);
    } else {
      return null;
    }
  }

  async afterModel(model: JobModel | null, transition: Transition) {
    const user = get(this.current, 'user');
    const promises: (AsyncHasMany<AllModels> | AsyncBelongsTo<AllModels>)[] = [
      get(this.current.company, 'partnerActivations'),
    ];
    if (model) {
      promises.pushObjects([
        get(model, 'jobDetail'),
        get(model, 'user'),
        get(model, 'teamMembers'),
      ]);
    }

    return RSVP.all(promises as ArrayProxy<AllModels>[]).then(() => {
      if (
        get(this, 'permissions').has('analytics/access') ||
        (model &&
          (get(model.user, 'id') === get(user, 'id') ||
            get(model, 'teamMembers').mapBy('id').includes(get(user, 'id'))))
      ) {
        // has access
      } else {
        get(this, 'ttAlert').error(
          this.intl.t('reports.dont_have_access_to_analytics')
        );
        transition.abort();
      }
    });
  }

  setupController(
    controller: AnalyticsController,
    model: JobModel | null,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);

    get(this, 'intercom').trackEvent('viewed-analytics');
  }

  resetController(controller: AnalyticsController, isExiting: boolean) {
    if (isExiting) {
      set(controller, 'startDate', null);
      set(controller, 'endDate', null);

      this.analytics.setStartDate(null);
      this.analytics.setEndDate(null);
      this.analytics.jobId = null;
    }
  }
}
