import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import { assert } from '@ember/debug';

export default class ToUrlHelper extends Helper {
  @service('browser/document') document;
  @service router;

  compute(_, { route, models, query }) {
    if (models?.length > 0) {
      assert('The `@models` argument must be an array.', Array.isArray(models));
    }

    const nextRoute = route || this.router.currentRouteName;

    const queryParamsOnly = !nextRoute;
    if (queryParamsOnly) {
      const url = new URL(this.document.location.href);
      const search = new URLSearchParams(query).toString();
      return `${url.pathname}?${search}`;
    }

    const queryParams = query ?? {};
    if (models?.length > 0) {
      return this.router.urlFor(nextRoute, ...models, { queryParams });
    } else {
      return this.router.urlFor(nextRoute, { queryParams });
    }
  }
}
