import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"px-16 pt-16\">\n  <AlertMessage @type=\"warning\">\n    <:content>\n      {{t\n        \"candidates.candidate.candidate_modal.we_found\"\n        candidatesCount=@candidate.duplicateCandidates.length\n        htmlSafe=true\n      }}\n      <button\n        type=\"button\"\n        class=\"text-action hover:text-pink-600\"\n        {{on \"click\" (fn (mut this.showDuplicateMerger) true)}}\n      >\n        {{t \"candidates.candidate.candidate_modal.fix_this\"}}\n      </button>\n    </:content>\n  </AlertMessage>\n</div>\n\n<CandidateMerger\n  @isOpen={{this.showDuplicateMerger}}\n  @onClose={{this.toggleCandidateMergerModal}}\n  @candidates={{this.candidatesForMerger}}\n  @onMerge={{this.handleCandidateMerge}}\n  @primaryCandidate={{if\n    (eq @candidate.duplicateCandidates.length 1)\n    (readonly this.candidate)\n  }}\n/>", {"contents":"<div class=\"px-16 pt-16\">\n  <AlertMessage @type=\"warning\">\n    <:content>\n      {{t\n        \"candidates.candidate.candidate_modal.we_found\"\n        candidatesCount=@candidate.duplicateCandidates.length\n        htmlSafe=true\n      }}\n      <button\n        type=\"button\"\n        class=\"text-action hover:text-pink-600\"\n        {{on \"click\" (fn (mut this.showDuplicateMerger) true)}}\n      >\n        {{t \"candidates.candidate.candidate_modal.fix_this\"}}\n      </button>\n    </:content>\n  </AlertMessage>\n</div>\n\n<CandidateMerger\n  @isOpen={{this.showDuplicateMerger}}\n  @onClose={{this.toggleCandidateMergerModal}}\n  @candidates={{this.candidatesForMerger}}\n  @onMerge={{this.handleCandidateMerge}}\n  @primaryCandidate={{if\n    (eq @candidate.duplicateCandidates.length 1)\n    (readonly this.candidate)\n  }}\n/>","moduleName":"teamtailor/components/candidate-modal-new/main/alert/duplicates.hbs","parseOptions":{"srcName":"teamtailor/components/candidate-modal-new/main/alert/duplicates.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { CandidateModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import BulkApiService from 'teamtailor/services/bulk-api';

interface Args {
  candidate: CandidateModel;
  onClose: () => void;
  onCandidateMerge?: (merge_candidates_id: string[]) => void;
}

export default class extends Component<Args> {
  @tracked showDuplicateMerger = false;
  @service declare bulkApi: BulkApiService;

  get candidate() {
    return this.args.candidate;
  }

  get candidatesForMerger() {
    return [this.candidate, ...this.candidate.duplicateCandidates.toArray()];
  }

  @action
  handleCandidateMerge(
    selectedCandidates: CandidateModel[],
    primaryCandidate: CandidateModel
  ) {
    const primary_candidate_id = get(primaryCandidate, 'id');
    const merge_candidates_id = selectedCandidates.mapBy('id');
    const selectedCandidateIds = [primary_candidate_id].concat(
      merge_candidates_id
    );

    const promise = this.bulkApi.post('bulk_merge', {
      selectedCandidateIds,
      primary_candidate_id,
      merge_candidates_id,
    });

    promise.then(() => {
      primaryCandidate.reload();
      selectedCandidates.forEach((candidate) => {
        get(candidate, 'jobApplications').forEach((jobApplication) => {
          jobApplication.unloadRecord();
        });
        candidate.unloadRecord();
      });

      get(primaryCandidate, 'duplicateCandidates').reload();
    });

    this.showDuplicateMerger = false;
    this.args.onClose();

    if (this.args.onCandidateMerge) {
      this.args.onCandidateMerge(merge_candidates_id);
    }

    return promise;
  }

  @action
  toggleCandidateMergerModal() {
    this.showDuplicateMerger = !this.showDuplicateMerger;
  }
}
