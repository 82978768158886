import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class RequisitionUpdateService extends Service {
  @tracked isEditing = false;
}

declare module '@ember/service' {
  interface Registry {
    'requisition-update-service': RequisitionUpdateService;
  }
}
