import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @imageUrl}}\n  <img\n    alt={{t \"components.media_library.form_field.selected_image\"}}\n    src={{@imageUrl}}\n    class=\"mb-8 h-auto max-h-[270px] w-full object-contain\"\n  />\n{{/if}}\n<Button\n  @text={{if\n    @imageUrl\n    (t \"dashboard.widgets.image.change_image\")\n    (t \"dashboard.widgets.image.choose_image\")\n  }}\n  @appearance=\"secondary\"\n  @size=\"small\"\n  @onClick={{this.handleMediaLibrary}}\n/>\n\n<MediaLibrary::Modal\n  @isOpen={{this.isPickingImage}}\n  @onClose={{this.handleMediaLibrary}}\n  @onPickImage={{this.handlePickImage}}\n/>", {"contents":"{{#if @imageUrl}}\n  <img\n    alt={{t \"components.media_library.form_field.selected_image\"}}\n    src={{@imageUrl}}\n    class=\"mb-8 h-auto max-h-[270px] w-full object-contain\"\n  />\n{{/if}}\n<Button\n  @text={{if\n    @imageUrl\n    (t \"dashboard.widgets.image.change_image\")\n    (t \"dashboard.widgets.image.choose_image\")\n  }}\n  @appearance=\"secondary\"\n  @size=\"small\"\n  @onClick={{this.handleMediaLibrary}}\n/>\n\n<MediaLibrary::Modal\n  @isOpen={{this.isPickingImage}}\n  @onClose={{this.handleMediaLibrary}}\n  @onPickImage={{this.handlePickImage}}\n/>","moduleName":"teamtailor/components/media-library/form-field.hbs","parseOptions":{"srcName":"teamtailor/components/media-library/form-field.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import ImageModel from 'teamtailor/models/image';

type MediaLibraryFormFieldArgs = {
  onPickImage: (image: ImageModel) => void;
};

export default class MediaLibraryFormFieldComponent extends Component<MediaLibraryFormFieldArgs> {
  @tracked isPickingImage = false;

  @action
  handleMediaLibrary(): void {
    this.isPickingImage = !this.isPickingImage;
  }

  @action
  handlePickImage(image: ImageModel): void {
    this.args.onPickImage(image);
    this.isPickingImage = false;
  }
}
