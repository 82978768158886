import imageUrl from 'teamtailor/utils/image-url';
import { get } from 'teamtailor/utils/get';
import { CompanyModel, ImageModel } from 'teamtailor/models';

export const createSmartLink = async (
  company: CompanyModel,
  {
    image,
    imgHtml,
    title,
    url,
  }: { image?: ImageModel | null; imgHtml?: string; title: string; url: string }
) => {
  let img: ImageModel | null | undefined = image;

  if (!img && !imgHtml) {
    const design = await get(company, 'design');
    img = await get(design, 'logotype');
  }

  const imageHtml = img
    ? `<img class="smart-link__img" src="${imageUrl(img, 'logotype')}"/>`
    : '';

  const imageStyle =
    img || imgHtml ? 'grid grid-cols-[48px_auto] gap-8' : 'flex';

  return `<span><figure contenteditable="false"><a class="smart-link ${imageStyle}" href="${url}" target="_blank">${
    imgHtml || imageHtml
  }<span class="smart-link__title">${title}</span><span class="smart-link__chevron hidden">&rsaquo;</span></a></figure></span>`;
};
