import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default class SectionItem extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  payloadKeyFromModelName() {
    return 'content_block_section_item';
  }
}
