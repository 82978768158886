import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class={{class-names \"flex-shrink-0 rounded\" this.sizeClasses}}\n  data-size={{this.size}}\n  ...attributes\n>\n  {{#if this.imageUrl}}\n    <img\n      src={{this.imageUrl}}\n      alt={{@partner.name}}\n      class=\"size-full rounded\"\n    />\n  {{else}}\n    <div\n      class={{class-names\n        (concat \"w-\" @size)\n        (concat \"h-\" @size)\n        \"flex items-center justify-center rounded\"\n        (user-background @partner.id)\n      }}\n    >\n      <Icon @icon=\"robot\" />\n    </div>\n  {{/if}}\n  {{#if @tooltip}}\n    <EmberTooltip @side={{this.tooltipSide}} @text={{@tooltip}} />\n  {{/if}}\n</div>\n{{yield}}", {"contents":"<div\n  class={{class-names \"flex-shrink-0 rounded\" this.sizeClasses}}\n  data-size={{this.size}}\n  ...attributes\n>\n  {{#if this.imageUrl}}\n    <img\n      src={{this.imageUrl}}\n      alt={{@partner.name}}\n      class=\"size-full rounded\"\n    />\n  {{else}}\n    <div\n      class={{class-names\n        (concat \"w-\" @size)\n        (concat \"h-\" @size)\n        \"flex items-center justify-center rounded\"\n        (user-background @partner.id)\n      }}\n    >\n      <Icon @icon=\"robot\" />\n    </div>\n  {{/if}}\n  {{#if @tooltip}}\n    <EmberTooltip @side={{this.tooltipSide}} @text={{@tooltip}} />\n  {{/if}}\n</div>\n{{yield}}","moduleName":"teamtailor/components/partner-image.hbs","parseOptions":{"srcName":"teamtailor/components/partner-image.hbs"}});
import Component from '@glimmer/component';
import { PartnerModel } from 'teamtailor/models';
import {
  DEFAULT_SIZE,
  SIZE_CLASSES,
  ALLOWED_SIZES,
  AllowedSizes,
} from 'teamtailor/constants/avatar';
import { argDefault } from 'teamtailor/utils/arg-default';
import { verifyArg } from 'teamtailor/utils/verify-arg';
import { get } from 'teamtailor/utils/get';

interface PartnerImageArgs {
  partner: PartnerModel;
  size?: AllowedSizes;
  tooltipSide?: string;
}

export default class PartnerImage extends Component<PartnerImageArgs> {
  @argDefault tooltipSide = 'top';

  get sizeClasses() {
    return SIZE_CLASSES[this.size];
  }

  get size(): AllowedSizes {
    verifyArg(
      this.args.size,
      ALLOWED_SIZES,
      'PartnerImage @size',
      DEFAULT_SIZE
    );

    return this.args.size ?? DEFAULT_SIZE;
  }

  get title(): string {
    return get(this.args.partner, 'name');
  }

  get imageUrl() {
    return get(this.args.partner, 'logotypeSquare');
  }
}
