const activityKeys = [
  { name: 'moves', colorIndex: 0 },
  { name: 'notes', colorIndex: 1 },
  { name: 'rejections', colorIndex: 2 },
  { name: 'messages', colorIndex: 3 },
  { name: 'sources', colorIndex: 4 },
  { name: 'reviews', colorIndex: 5 },
  { name: 'scores', colorIndex: 6 },
  { name: 'hires', colorIndex: 7 },
  { name: 'interviews', colorIndex: 8 },
];

export default activityKeys;
