export default function () {
  this.transition(
    this.hasClass('to-down-and-up'),
    this.toValue(true),
    this.use('toDown'),
    this.reverse('toUp')
  );

  this.transition(
    this.toRoute(function (routeName) {
      return /(?=.*meetings)(?!.*loading).*/.test(routeName);
    }),
    this.includingInitialRender(),
    this.use('crossFade', { duration: 250 })
  );

  // Features --->
  this.transition(
    // Don't use fade since it wil cause fading in to be slow.
    // Looks like it delays render with the same amount of time as the animation duration.
    this.hasClass('feature-modal'),
    this.use('crossFade', { duration: 200 })
  );
  this.transition(
    this.hasClass('crossFadeModelList'),
    this.use('crossFade', { duration: 200 })
  );
  this.transition(
    this.hasClass('feature-to-left'),
    this.use('toLeft', { duration: 200 })
  );
  this.transition(
    this.hasClass('feature-to-right'),
    this.use('toRight', { duration: 200 })
  );
  this.transition(
    this.fromRoute([
      'features.show.feature.index.index',
      'features.show.feature.index.success',
    ]),
    this.toRoute(['features.show.feature.index.deactivate']),
    this.use('mdModal', {}, 'mdEffect3'),
    this.reverse('fade', { duration: 150 })
  );
  this.transition(
    this.hasClass('feature-success'),
    this.toValue(true),
    this.use('wait', 200, { then: 'toUp' }, { duration: 200 })
  );
  // Features <---
}
