import Route from '@ember/routing/route';
import JobModel from 'teamtailor/models/job';
import PromotionModel from 'teamtailor/models/promotion';

export default class EmployeeShareJobIndex extends Route {
  async model(): Promise<PromotionModel> {
    const job = this.modelFor('employee.share-job') as JobModel;
    return job.createTrackableLink();
  }
}
