import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  role=\"button\"\n  class=\"c-tshirt-splash__column cursor-pointer\"\n  local-class=\"giftAnimation\"\n  id=\"lottie-animation-container\"\n  {{on \"click\" this.openGift}}\n  {{did-insert this.didInsertComponent}}\n></div>", {"contents":"<div\n  role=\"button\"\n  class=\"c-tshirt-splash__column cursor-pointer\"\n  local-class=\"giftAnimation\"\n  id=\"lottie-animation-container\"\n  {{on \"click\" this.openGift}}\n  {{did-insert this.didInsertComponent}}\n></div>","moduleName":"teamtailor/components/gift/animation.hbs","parseOptions":{"srcName":"teamtailor/components/gift/animation.hbs"}});
import { action, set, get } from '@ember/object';
import Component from '@glimmer/component';

const LOOP_START_FRAME = 100;
const LOOP_FRAME_LENGTH = 119;
const LOOP_END_FRAME = 220;
const UNWRAP_FRAME_LENGTH = 120;

export default class extends Component {
  giftAnimation = null;
  stopAnimation = false;

  loadAnimations(lottie) {
    this.giftAnimation = lottie.loadAnimation({
      container: document.getElementById('lottie-animation-container'),
      renderer: 'svg',
      path: '/assets/lottie-animations/gift.json',
      loop: true,
      autoplay: false,
    });

    this.giftAnimation.addEventListener('DOMLoaded', () => {
      this.giftAnimation.play();
      this.maybeGoToLoop();
    });
  }

  maybeGoToLoop() {
    this.giftAnimation.onEnterFrame = ({ currentTime }) => {
      if (parseInt(currentTime) === LOOP_START_FRAME) {
        this.giftAnimation.playSegments(
          [LOOP_START_FRAME, LOOP_END_FRAME],
          true
        );
        this.maybeFinishAnimation();
      }
    };
  }

  maybeFinishAnimation() {
    this.giftAnimation.onEnterFrame = ({ currentTime }) => {
      if (
        parseInt(currentTime) === LOOP_FRAME_LENGTH &&
        get(this, 'stopAnimation')
      ) {
        if (this.args.onLoopComplete) {
          this.args.onLoopComplete();
        }

        this.giftAnimation.loop = false;
        this.giftAnimation.playSegments(
          [LOOP_END_FRAME, LOOP_END_FRAME + UNWRAP_FRAME_LENGTH],
          false
        );

        this.giftAnimation.addEventListener('complete', () => {
          this.giftAnimation.goToAndStop(
            LOOP_END_FRAME + UNWRAP_FRAME_LENGTH,
            true
          );

          if (this.args.onAnimationComplete) {
            this.args.onAnimationComplete();
          }
        });
      }
    };
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.giftAnimation.destroy();
  }

  @action
  openGift() {
    set(this, 'stopAnimation', true);
  }

  @action
  async didInsertComponent() {
    const lottie = await import('lottie-web');
    this.loadAnimations(lottie);
  }
}
