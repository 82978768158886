import {
  prepareEqualsOperator,
  prepareStaticEqualsOperator,
} from 'teamtailor/utils/operators/equals-operator';
import { prepareExistsOperator } from 'teamtailor/utils/operators/exists-operator';
import { prepareMatchesOperator } from 'teamtailor/utils/operators/matches-operator';
import { prepareAnyOperator } from 'teamtailor/utils/operators/any-operator';
import { prepareAllOperator } from 'teamtailor/utils/operators/all-operator';
import { prepareRangeOperator } from 'teamtailor/utils/operators/range-operator';

import { getStageTypeColor } from 'teamtailor/helpers/stage-type-color';
import { getBadgeColor } from 'teamtailor/helpers/badge-color';

import { STATUSES } from 'teamtailor/constants/partner-results';
import { NO_DEPARTMENT_ID } from 'teamtailor/models/department';
import config from 'teamtailor/config/environment';
import { isEmpty, isPresent } from '@ember/utils';

import CustomFieldFilter from 'teamtailor/utils/fancy-filters/custom-field';
import ContainerFilter from 'teamtailor/utils/fancy-filters/container';

import { QUESTION_TYPES } from 'teamtailor/constants/question';
import { get } from 'teamtailor/utils/get';
import uniqBy from 'teamtailor/utils/uniq-by';

function createShortString(name) {
  return {
    name,
    type: 'string',
    operators: [
      prepareEqualsOperator(),
      prepareMatchesOperator(),
      prepareMatchesOperator(false),
      prepareExistsOperator(),
      prepareExistsOperator(false),
    ],

    options: null,
  };
}

function createLongString(name) {
  return {
    name,
    type: 'string',
    operators: [
      prepareMatchesOperator(),
      prepareMatchesOperator(false),
      prepareExistsOperator(false),
      prepareExistsOperator(),
    ],

    options: null,
  };
}

export function createSelect(store, name, type, id, options, modelName = null) {
  function deserializeRawValue(value) {
    if (!Array.isArray(value)) {
      value = [value];
    }

    return value?.map((v) => {
      if (name === 'department_id' && isEmpty(v)) {
        v = NO_DEPARTMENT_ID;
      }

      return v;
    });
  }

  return {
    name,
    type,
    modelName,
    operators: [prepareAnyOperator(), prepareAnyOperator(false)],
    deserializeRawValue,
    deserialize(value) {
      let finalValue = deserializeRawValue(value);

      finalValue = finalValue.map((v) => {
        if (options) {
          return options.find((option) => option[id] === v);
        }

        return store.findRecord(modelName, v);
      });

      return finalValue;
    },

    serialize: (value) => {
      if (!Array.isArray(value)) {
        value = [value];
      }

      return value?.map((v) => {
        if (name === 'department_id' && v[id] === NO_DEPARTMENT_ID) {
          return '';
        }

        return v ? v[id] : '';
      });
    },

    options,
  };
}

function createMultiSelect(
  store,
  name,
  type,
  id,
  options,
  modelName = null,
  peekRecord = false
) {
  function deserializeRawValue(value) {
    if (isPresent(value) && !Array.isArray(value)) {
      return [value];
    }

    return value;
  }

  return {
    name,
    type,
    options,
    modelName,
    deserializeRawValue,
    operators: [
      prepareAllOperator(),
      prepareAnyOperator(),
      prepareAllOperator(false),
      prepareAnyOperator(false),
    ],

    deserialize: (value) => {
      let finalValue = deserializeRawValue(value);

      return finalValue.map((v) => {
        if (modelName) {
          if (peekRecord && id === 'id') {
            return store.peekRecord(modelName, v);
          } else if (id === 'id') {
            return store.findRecord(modelName, v);
          } else {
            return store.queryRecord(modelName, {
              [id]: v,
            });
          }
        } else if (id && Array.isArray(options)) {
          return options.find((option) => option[id] === v);
        }

        return v;
      });
    },

    serialize: (value) => {
      return (value || []).map((v) => v[id] || v);
    },
  };
}

function createRadioSelect(name, type, options) {
  return {
    name,
    type,
    options,
    translateValue: true,
    operators: [prepareEqualsOperator()],
  };
}

function createDate(name) {
  return {
    name,
    type: 'date',
    operators: [
      prepareRangeOperator('less_than'),
      prepareRangeOperator('greater_than'),
      prepareRangeOperator('rounded_equals'),
      prepareRangeOperator('relative_more_than', true),
      prepareRangeOperator('relative_less_than', true),
      prepareExistsOperator(),
      prepareExistsOperator(false),
    ],
  };
}

function createPolar(name, type = 'polar') {
  return {
    name,
    type,
    options: ['yes', 'no'],
    translateValue: true,
    deserializeRawValue: (rawValue) => {
      if (rawValue === null) {
        return 'any';
      }

      if (rawValue === true) {
        return 'yes';
      }

      if (rawValue === false) {
        return 'no';
      }

      return rawValue;
    },

    operators: [prepareEqualsOperator()],
  };
}

export function createStatusBoolean(name, { type, dot }) {
  return {
    ...createPolar(name, type),
    dot,
  };
}

function createRange(name, start, end, icon = undefined) {
  return {
    name,
    type: 'range',
    operators: [
      prepareRangeOperator('rounded_equals'),
      prepareRangeOperator('less_than'),
      prepareRangeOperator('greater_than'),
      prepareRangeOperator('between'),
      prepareExistsOperator(),
      prepareExistsOperator(false),
    ],

    icon,
    options: [start, end],
  };
}

export function createNested(name, { id, filter }) {
  return Object.assign(filter, {
    nested: { name, id },
  });
}

function createExists(type) {
  return {
    type,
    operators: [prepareExistsOperator(), prepareExistsOperator(false)],
    options: null,
  };
}

export function createQuestion(store, question) {
  let typeFilter, createMethod;

  switch (question.type) {
    case 'Question::Boolean':
      typeFilter = createPolar('boolean');
      typeFilter.options = ['yes', 'no', 'any'];
      break;
    case 'Question::Choice':
      if (question.multiple) {
        createMethod = createMultiSelect;
      } else {
        createMethod = createSelect;
      }

      typeFilter = createMethod(
        store,
        'choices',
        '[Alternative]',
        'id',
        question.alternativesArrayForChoices
      );

      break;
    case 'Question::Range':
      typeFilter = createRange(
        'range',
        question.questionData.start_with,
        question.questionData.end_with
      );
      break;
    case 'Question::Text':
      typeFilter = createShortString('text');
      break;
    case 'Question::Number':
      typeFilter = createRange('number', undefined, undefined, 'hashtag');
      break;
    case 'Question::Date':
      typeFilter = createDate('date');
      break;
    case 'Question::Video':
    case 'Question::File':
      typeFilter = createExists('file');
      break;
    default:
      return false;
  }

  return {
    ...typeFilter,
    doNotTranslateName: true,
    translatedName: question.title,
    icon: QUESTION_TYPES[question.simpleType]?.icon,
  };
}

export function createCustomFieldFilter(store, customField) {
  let typeFilter, createMethod;

  switch (customField.type) {
    case 'CustomField::Checkbox':
      typeFilter = createPolar('boolean_value');
      typeFilter.options = ['yes', 'no', 'any'];
      break;
    case 'CustomField::Number':
      typeFilter = createRange('numeric_value_long');
      break;
    case 'CustomField::Select':
    case 'CustomField::MultiSelect':
      if (customField.type === 'CustomField::Select') {
        createMethod = createSelect;
      } else {
        createMethod = createMultiSelect;
      }

      typeFilter = createMethod(
        store,
        'options',
        '[Option]',
        'id',
        customField.options,
        'custom-field/option',
        true
      );
      break;
    case 'CustomField::Date':
      typeFilter = createDate('date_value');
      break;
    case 'CustomField::Text':
    case 'CustomField::Email':
    case 'CustomField::Phone':
    case 'CustomField::Url':
      typeFilter = createShortString('string_value');
      break;
  }

  return Object.assign(new CustomFieldFilter(customField), typeFilter);
}

const customFieldsGenerator = (category) => (store) => {
  const company = store.peekAll('company').firstObject;
  const customFields = company.searchableCandidateCustomFields;
  return createCategory(
    category,
    ...customFields.map((customField) => {
      return createNested('custom_field', {
        id: customField.id,
        filter: createCustomFieldFilter(store, customField),
      });
    })
  );
};

function createPartner(id, partnerActivation) {
  let parentFilter;

  const childFilters = [
    createNested('partner', {
      id,
      filter: createRadioSelect('status', 'radio', STATUSES),
    }),
    createNested('partner', {
      id,
      filter: createRange('assessment_score', 0, 100),
    }),
  ];

  parentFilter = createNested('partner', {
    id,
    filter: new ContainerFilter(partnerActivation.partnerName, childFilters),
  });

  parentFilter = {
    ...parentFilter,
    image: get(partnerActivation.partner, 'logotypeSquare'),
    doNotTranslateName: true,
    translatedName: partnerActivation.partnerName,
  };

  childFilters.forEach((filter) => {
    filter.parent = parentFilter;
    filter.hideIcon = true;
  });

  return [parentFilter, ...childFilters];
}

const loadQuestions = async (store, container, page, query) => {
  if (container.subFiltersAllLoaded) {
    return;
  }

  const questions = await store.query('question', {
    page,
    query,
    per_page: 30,
  });

  if (questions.length > 0) {
    const filters = questionsToFilters(store, questions);
    container.addFilters(filters);
  }

  if (questions.meta.page >= questions.meta.total_pages) {
    container.subFiltersAllLoaded = true;
  }
};

const questionsToFilters = (store, questions) => {
  return questions.map((question) => {
    return createNested('question', {
      id: question.id,
      filter: createQuestion(store, question),
    });
  });
};

const questionGenerator = (category) => (store) => {
  const loadedQuestions = store.peekAll('question');
  const childFilters = questionsToFilters(store, loadedQuestions);

  const parentFilter = createNested('question', {
    filter: new ContainerFilter(
      'select_question',
      childFilters,
      async (container, page, query) => {
        await loadQuestions(store, container, page, query);
      }
    ),
  });

  childFilters.forEach((filter) => (filter.parent = parentFilter));
  return createCategory(category, parentFilter, ...childFilters);
};

const partnerGenerator = (category) => (store) => {
  const partnerActivations = uniqBy(
    store.peekAll('partner-activation'),
    'partnerName'
  );

  return createCategory(
    category,
    ...partnerActivations.reduce((acc, partnerActivation) => {
      return [
        ...acc,
        ...createPartner(partnerActivation.partnerId, partnerActivation),
      ];
    }, [])
  );
};

const stageTypeSelectGenerator = (category) => (store) => {
  const stageTypes = uniqBy(store.peekAll('stage-type'), 'category').sort(
    (a, b) => a.orderIndex - b.orderIndex
  );

  return createCategory(
    category,
    createNested('job', {
      filter: createSelect(
        store,
        'stage_type',
        '[StageType]',
        'category',
        stageTypes
      ),
    }),
    {
      ...createStatusBoolean('rejected', {
        type: 'application_stage_polar',
        dot: getStageTypeColor('rejected', 'text'),
      }),

      operators: [
        prepareStaticEqualsOperator('rejected', 'yes'),
        prepareStaticEqualsOperator('rejected', 'yes', false),
      ],
    }
  );
};

function createDynamicCategory(category, generator) {
  return generator(category);
}

export function createCategory(category, ...filters) {
  return filters.map((filter) =>
    Object.assign(filter, {
      category,
    })
  );
}

function createRatingSelect(name) {
  return {
    name,
    type: 'average_rating',
    operators: [prepareEqualsOperator()],
    options: null,
    icon: 'star',
    triggerIconStyle: 'solid',
  };
}

export const initializeAvailableFilters = (store) => {
  return [
    ...createCategory(
      'candidate_details',
      createShortString('first_name'),
      createShortString('last_name'),
      createShortString('email'),
      createShortString('phone'),
      createShortString('referring_url'),
      createLongString('resume_text'),
      createLongString('cover_letters'),
      createLongString('pitch'),
      createLongString('notes'),
      createSelect(
        store,
        'language_code',
        '[Language]',
        'value',
        config.supportedLocales
      ),
      createSelect(
        store,
        'department_id',
        '[Department]',
        'id',
        undefined,
        'department'
      ),
      createSelect(store, 'role_id', '[Role]', 'id', undefined, 'role'),
      createMultiSelect(store, 'tags', '[CandidateTag]', 'name'),
      createMultiSelect(
        store,
        'location_ids',
        '[Location]',
        'id',
        undefined,
        'location'
      ),
      createNested('job', {
        filter: createSelect(store, 'job_ids', '[Job]', 'id', undefined, 'job'),
      }),
      createDynamicCategory('candidate_details', customFieldsGenerator)
    ),
    ...createCategory(
      'candidate_status',
      createStatusBoolean('applied', { dot: 'zinc' }),
      createStatusBoolean('connected', { dot: 'purple' }),
      createStatusBoolean('sourced', { dot: 'teal' }),
      createStatusBoolean('lead', { dot: 'cyan' }),
      createStatusBoolean('referred', { dot: 'cerise' }),
      createStatusBoolean('internal', { dot: 'indigo' })
    ),
    createDynamicCategory('application_stage_type', stageTypeSelectGenerator),

    ...createCategory(
      'dates',
      createDate('connected_at'),
      createDate('created_at'),
      createDate('last_activity_at'),
      createDate('sourced_at'),
      createDate('last_application_at'),
      createDate('consent_expires_at'),
      createDate('consent_missing_at'),
      createDate('consent_future_jobs_at'),
      createDate('data_requested_at'),
      createDate('removal_requested_at'),
      createDate('will_be_deleted_at')
    ),
    ...createCategory(
      'rating',
      createRatingSelect('average_rating'),
      createRadioSelect('reviewed_by', 'ReviewedBy', [
        'me',
        'not-me',
        'not-rated',
      ])
    ),

    createDynamicCategory('partner_results', partnerGenerator),

    createDynamicCategory('answers_questions', questionGenerator),
  ];
};

export const initializeExternalRecruiterFilters = (store) => [
  ...createCategory(
    'external_recruiter',
    createStatusBoolean('sourced_external', {
      dot: getBadgeColor('sourced_external'),
    }),
    createSelect(
      store,
      'external_recruiter_id',
      '[ExternalRecruiter]',
      'id',
      undefined,
      'user'
    ),
    createSelect(
      store,
      'recruiting_firm_id',
      '[RecruitingFirm]',
      'id',
      undefined,
      'recruiting_firm'
    )
  ),
];

const getLoadedQuestions = (ids, store) => {
  return ids.map((id) => store.peekRecord('question', id)).compact();
};

export const getQuestionIdsFromRoot = (root) => {
  return root.reduce((ids, filter) => {
    const questionId =
      filter.question?.question_id || filter.not?.question?.question_id;
    if (questionId) {
      ids.push(questionId);
    }

    return ids;
  }, []);
};

export const preloadMissingQuestions = async (
  store,
  availableFilters,
  params
) => {
  const container = availableFilters.findBy('name', 'select_question');
  if (!container || !params?.root) {
    return;
  }

  await preloadParamQuestions(store, params);
  const ids = getQuestionIdsFromRoot(params.root);
  const loadedQuestions = getLoadedQuestions(ids, store);

  if (loadedQuestions.length > 0) {
    const filters = questionsToFilters(store, loadedQuestions);
    container.addFilters(filters);

    const missingFilters = filters.filter((filter) => {
      return isNotInAvailableFilters(availableFilters, filter);
    });

    if (missingFilters.length > 0) {
      availableFilters.pushObjects(missingFilters);
    }
  }
};

const isNotInAvailableFilters = (availableFilters, filter) => {
  return !availableFilters.find(
    (existingFilter) =>
      JSON.stringify(existingFilter.nested) === JSON.stringify(filter.nested)
  );
};

const preloadParamQuestions = async (store, params) => {
  if (!params?.root) {
    return;
  }

  const ids = getQuestionIdsFromRoot(params.root);
  const loadedQuestions = getLoadedQuestions(ids, store);
  const loadedQuestionIds = loadedQuestions.mapBy('id');
  const missingQuestionIds = ids.filter(
    (id) => !loadedQuestionIds.includes(id)
  );

  if (missingQuestionIds.length > 0) {
    await store.query('question', { ids });
  }
};

export const loadAvailableFilters = async (store, params) => {
  await Promise.allSettled([
    store.findAll('customField', { reload: true }),
    store.findAll('partner-activation'),
    preloadParamQuestions(store, params),
  ]);

  return initializeAvailableFilters(store).reduce((acc, filter) => {
    if (typeof filter === 'function') {
      acc = [...acc, ...filter(store)];
    } else {
      acc.push(filter);
    }

    return acc;
  }, []);
};
