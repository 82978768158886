import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import LocationModel from 'teamtailor/models/location';
import ImageModel from 'teamtailor/models/image';
import PageModel from 'teamtailor/models/page';
import ImageWithSettingModel from 'teamtailor/models/image-with-setting';
import SectionItemModel from 'teamtailor/models/section-item';
import { get } from 'teamtailor/utils/get';
import ConnectMediaItemModel from './connect-media-item';

export default class PickedImageModel extends Model {
  @attr('number') declare rowOrder: number;
  @attr('number') declare rowOrderPosition: number;
  @attr('string') declare imageUrl: string;
  @attr('string') declare section_id: string;
  @attr('string') declare ownerId: string;
  @attr('string') declare ownerType: string;

  @belongsTo('image') declare image: AsyncBelongsTo<ImageModel>;

  @belongsTo('image-with-setting')
  declare imageWithSetting: AsyncBelongsTo<ImageWithSettingModel>;

  @belongsTo('section-item')
  declare sectionItem: AsyncBelongsTo<SectionItemModel>;

  @belongsTo('connect-media-item')
  declare connectMediaItem: AsyncBelongsTo<ConnectMediaItemModel>;

  @belongsTo('page', { async: true }) declare page: AsyncBelongsTo<PageModel>;
  @belongsTo('location') declare location: AsyncBelongsTo<LocationModel>;

  get imageOrTemplateUrl(): string | undefined {
    const s3Image = get(this.image, 's3Image');

    if (s3Image) {
      return s3Image;
    } else {
      return this.imageUrl;
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'picked-image': PickedImageModel;
  }
}
