import CareerSiteLanguageBaseRoute from 'teamtailor/routes/career-site-language-base';
import { set, get, action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CannedResponsesNewRoute extends CareerSiteLanguageBaseRoute {
  @service router;
  @service store;

  model() {
    return this.store.createRecord('canned-response', {
      languageCode: this.defaultLanguageCode,
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    set(controller, 'isOpen', true);

    const options = {
      department: null,
      role: null,
      region: null,
      location: null,
    };

    this.store.createRecord('asset-structure', {
      options,
      organizable: model,
    });
  }

  resetController(controller, isExiting) {
    let model = get(controller, 'model');
    if (isExiting && get(model, 'isNew')) {
      model.destroyRecord();
    }

    return this.store.findAll('canned-response');
  }

  @action
  modalClosed() {
    this.router.transitionTo('settings.canned_responses');
  }
}
