import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';

export default class EditRoute extends ScrollToTopRoute {
  @service store;

  async model({ cookie_detail_id }) {
    const cookieDetail = await this.store.findRecord(
      'cookie-detail',
      cookie_detail_id
    );

    return cookieDetail;
  }
}
