import Model, { belongsTo, attr } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import { get } from 'teamtailor/utils/get';

export default class Answer extends Model {
  @belongsTo('candidate') candidate;
  @belongsTo('interview') interview;
  @belongsTo('picked-question', { async: false }) pickedQuestion;
  @belongsTo('question') question;
  @belongsTo('questionnaire') questionnaire;
  @belongsTo('upload') upload;
  @belongsTo('user') user;

  @attr('boolean', { allowNull: true }) boolean;
  @attr('string') choicesString;
  @attr('boolean') copilotGenerated;
  @attr('raw') data;
  @attr('string') date;
  @attr('string') newUploadUrl;
  @attr('number') number;
  @attr('number') range;
  @attr('string') text;
  @attr('date') updatedAt;
  @attr('string') videoToken;

  get choices() {
    return typeof this.choicesString === 'string'
      ? this.choicesString
          .split(',')
          .map((value) => parseInt(value, 10))
          .filter((value) => !isNaN(value))
      : [];
  }

  set choices(value) {
    this.choicesString = value.join(',');
  }

  get rowOrder() {
    return this.pickedQuestion.rowOrder;
  }

  get jobId() {
    switch (this.ownerType) {
      case 'Job':
        return this.ownerId;
      case 'JobApplication':
        return get(this.pickedQuestion, 'job.id');
      case 'Questionnaire':
        return get(this.questionnaire, 'jobId');
      default:
        return null;
    }
  }

  get ownerId() {
    return get(this.pickedQuestion, 'ownerId');
  }

  get ownerType() {
    return get(this.pickedQuestion, 'ownerType');
  }

  get answerValue() {
    if (!isEmpty(this.boolean)) {
      return this.boolean;
    } else if (!isEmpty(this.range)) {
      return this.range;
    } else if (!isEmpty(this.text)) {
      return this.text;
    } else if (!isEmpty(this.videoToken)) {
      return this.videoToken;
    } else if (!isEmpty(this.date)) {
      return this.date;
    } else if (!isEmpty(this.number)) {
      return this.number;
    } else if (this.choices?.length > 0) {
      return this.choices;
    }
  }

  get emptyAnswer() {
    return isEmpty(this.answerValue) && this.boolean !== false;
  }

  get questionId() {
    return get(this.question, 'id');
  }

  get firstChoice() {
    return this.choices?.[0];
  }

  set firstChoice(value) {
    this.choices = [value];
  }
}
