import Model, { attr } from '@ember-data/model';

export default class GroupSiteCompanyModel extends Model {
  @attr('boolean', { defaultValue: false }) declare hiddenOnCareerSite: boolean;
  @attr('number') declare companyId: number;
  @attr('number') declare groupSiteId: number;
  @attr('number') declare rowOrder: number;
  @attr('number') declare rowOrderPosition: number;
  @attr('string') declare name: string;

  get isMainCompany() {
    return this.groupSiteId === this.companyId;
  }

  get visibleOnCareerSite() {
    return !this.hiddenOnCareerSite;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'group-site-company': GroupSiteCompanyModel;
  }
}
