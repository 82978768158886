import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#unless (is-empty this.value)}}\n  <div\n    class={{class-names\n      \"flex w-full flex-col items-center pb-12 pt-12 first:rounded-l-6 last:rounded-r-6\"\n      (if\n        (and (eq this.value.category \"in_process\") (eq this.value.count 0))\n        \"hidden\"\n      )\n      (if\n        (and this.value.category (not-eq this.value.category \"in_process\"))\n        (stage-type-color-class this.value.category (array \"bg\" \"text\"))\n        \"bg-decorative-zinc-weak text-decorative-zinc-weak\"\n      )\n    }}\n  >\n    <div class=\"font-bold\">\n      {{if (and this.value.count (not @isEmpty)) this.value.count 0}}\n    </div>\n    <div>\n      {{t (concat \"job.stage_types.\" this.value.category)}}\n    </div>\n  </div>\n{{/unless}}", {"contents":"{{#unless (is-empty this.value)}}\n  <div\n    class={{class-names\n      \"flex w-full flex-col items-center pb-12 pt-12 first:rounded-l-6 last:rounded-r-6\"\n      (if\n        (and (eq this.value.category \"in_process\") (eq this.value.count 0))\n        \"hidden\"\n      )\n      (if\n        (and this.value.category (not-eq this.value.category \"in_process\"))\n        (stage-type-color-class this.value.category (array \"bg\" \"text\"))\n        \"bg-decorative-zinc-weak text-decorative-zinc-weak\"\n      )\n    }}\n  >\n    <div class=\"font-bold\">\n      {{if (and this.value.count (not @isEmpty)) this.value.count 0}}\n    </div>\n    <div>\n      {{t (concat \"job.stage_types.\" this.value.category)}}\n    </div>\n  </div>\n{{/unless}}","moduleName":"teamtailor/components/insights/molecules/stage-types/stats-item.hbs","parseOptions":{"srcName":"teamtailor/components/insights/molecules/stage-types/stats-item.hbs"}});
import Component from '@glimmer/component';
import { camelize } from '@ember/string';

interface CountObj {
  count: number;
  category: string;
}

interface Args {
  stageTypeName: string;
  stageTypesStats?: { [key: string]: CountObj };
}

export default class StatsItem extends Component<Args> {
  get value() {
    const { stageTypeName } = this.args;
    const stats = this.args.stageTypesStats;

    return stats?.[`${camelize(stageTypeName)}Count`];
  }
}
