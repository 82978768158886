import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';

import PipelineOverviewReportFetcher from 'teamtailor/classes/analytics/report-pipeline-overview';
import AnalyticsService from 'teamtailor/services/analytics';

export default class extends Route {
  @service declare analytics: AnalyticsService;

  model() {
    return {
      pipelineOverview: new PipelineOverviewReportFetcher({
        container: getOwner(this),
        options: {
          fetchByStageName: true,
        },
      }).fetchTask,
    };
  }
}
