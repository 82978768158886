import QuestionModel from 'teamtailor/models/question';
import ScorecardCriteriumModel from 'teamtailor/models/scorecard-criterium';
import { get } from 'teamtailor/utils/get';
import InterviewKitModel from 'teamtailor/models/interview-kit';
import { next } from '@ember/runloop';

export enum NESTED_TYPES {
  QUESTION = 'question',
  COMPETENCE = 'competence',
}

export type OrderedCompetence = {
  id: string;
  type: NESTED_TYPES;
  model?: QuestionModel | ScorecardCriteriumModel;
  children?: OrderedCompetence[];
};

export type OrderedCompetenceParent = OrderedCompetence & {
  children: OrderedCompetence[];
};

const getFirstTimeNestedItems = (
  interviewKit: InterviewKitModel
): OrderedCompetence[] => {
  const questions = interviewKit.sortedQuestions.map(
    (question: QuestionModel): OrderedCompetence => {
      return {
        model: question,
        type: NESTED_TYPES.QUESTION,
        id: get(question, 'id'),
        children: [],
      };
    }
  );

  const competences = interviewKit.sortedScorecardCriteria.map(
    (scorecardCriterium: ScorecardCriteriumModel): OrderedCompetence => {
      return {
        model: scorecardCriterium,
        type: NESTED_TYPES.COMPETENCE,
        id: get(scorecardCriterium, 'id'),
        children: [],
      };
    }
  );

  next(() => {
    if (questions.length || competences.length) {
      interviewKit.competenceOrder = [...questions, ...competences];
    }
  });
  return [...questions, ...competences];
};

const findOrderedCompetence = (
  items: OrderedCompetence[],
  type: NESTED_TYPES,
  id?: string,
  name?: string
): OrderedCompetence | OrderedCompetenceParent | undefined => {
  return items.find((item: OrderedCompetence) => {
    let modelName: string | undefined;

    if (item.model instanceof QuestionModel) {
      modelName = get(item.model, 'title');
    } else if (item.model instanceof ScorecardCriteriumModel) {
      modelName = get(item.model, 'name');
    }

    const sameId = id && item.id === id;
    const sameName = name && modelName === name;

    return item.type === type && (sameId || sameName);
  });
};

const updateOrderedCompetenceIds = (items: OrderedCompetence[]): void => {
  items.forEach((item: OrderedCompetence) => {
    const modelId = get(item.model, 'id');

    if (!item.id && modelId) {
      item.id = modelId;
    }

    if (item.children) {
      updateOrderedCompetenceIds(item.children);
    }
  });
};

const findAndRemoveOrderedCompetence = (
  items: OrderedCompetence[],
  type: NESTED_TYPES,
  id?: string,
  name?: string
): void => {
  const competence = findOrderedCompetence(items, type, id, name);

  if (competence) {
    items.removeObject(competence);
  } else {
    items.forEach((item: OrderedCompetence) => {
      findAndRemoveOrderedCompetence(item.children || [], type, id, name);
    });
  }
};

const modelToOrderedCompetence = (
  type: NESTED_TYPES,
  model: QuestionModel | ScorecardCriteriumModel
): OrderedCompetence => {
  return {
    model,
    type,
    id: get(model, 'id'),
    children: type === NESTED_TYPES.COMPETENCE ? [] : undefined,
  };
};

const getNestedItemModel = (
  interviewKit: InterviewKitModel,
  item: OrderedCompetence
) => {
  return item.type === NESTED_TYPES.QUESTION
    ? interviewKit.questions.findBy('id', item.id)
    : interviewKit.scorecardCriteria.findBy('id', item.id);
};

const setNestedModels = (
  interviewKit: InterviewKitModel,
  items: OrderedCompetence[]
): void => {
  items.forEach((item) => {
    if (!item.model) {
      item.model = getNestedItemModel(interviewKit, item);
    }

    if (item.children) {
      setNestedModels(interviewKit, item.children);
    }
  });
};

export default {
  findAndRemoveOrderedCompetence,
  findOrderedCompetence,
  updateOrderedCompetenceIds,
  getFirstTimeNestedItems,
  getNestedItemModel,
  modelToOrderedCompetence,
  setNestedModels,
};
