import Route from '@ember/routing/route';
import Job from 'teamtailor/models/job';

type ModelResponse = {
  job: Job;
};

export default class CalendarRoute extends Route {
  async model(): Promise<ModelResponse> {
    const parentModel = await this.modelFor('jobs.job');

    return {
      job: parentModel,
    };
  }
}
