import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';

export default class PromotionBaseClass extends Component {
  @service store;

  get promotion() {
    return this.args.promotion;
  }

  @action
  resetCategories(pathToReset) {
    set(this, pathToReset, null);
  }

  @action
  selectObjectKey(path, keyName, obj) {
    set(this, path, obj[keyName]);
  }
}
