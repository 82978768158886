import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ActivityItem::CandidateLink\n  @activity={{this.activity}}\n  @anonymous={{this.anonymous}}\n  @isProfile={{this.isProfile}}\n  @job={{this.job}}\n  @fallbackText={{t \"components.activity_item.candidate\"}}\n/>\n{{t\n  this.answerTranslationKey\n  activity=this.activity.actionData.meeting_event_summary\n  htmlSafe=true\n}}", {"contents":"<ActivityItem::CandidateLink\n  @activity={{this.activity}}\n  @anonymous={{this.anonymous}}\n  @isProfile={{this.isProfile}}\n  @job={{this.job}}\n  @fallbackText={{t \"components.activity_item.candidate\"}}\n/>\n{{t\n  this.answerTranslationKey\n  activity=this.activity.actionData.meeting_event_summary\n  htmlSafe=true\n}}","moduleName":"teamtailor/components/activity-item/meeting-event-candidate-status-changed.hbs","parseOptions":{"srcName":"teamtailor/components/activity-item/meeting-event-candidate-status-changed.hbs"}});
import { inject as service } from '@ember/service';
import Base from './base';
import { get } from 'teamtailor/utils/get';

export default class CalendarComponent extends Base {
  @service timeFormat;

  get answerTranslationKey() {
    return `components.activity_item.meeting_answers.${get(
      this.activity,
      'actionData.meeting_event_invite_status'
    )}`;
  }
}
