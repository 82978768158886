import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';

export default class ApplyWithRoute extends ScrollToTopRoute {
  @service store;

  async model() {
    return this.store.findAll('cookie-detail');
  }
}
