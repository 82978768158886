export const CUSTOM_WIDGET_COLORS = [
  'gray',
  'green',
  'turquoise',
  'indigo',
  'blue',
  'purple',
  'cerise',
  'coral',
  'orange',
  'yellow',
  'gold',
];

export const EMPLOYEE_CUSTOM_WIDGET_COLORS = [
  'company',
  'dodger-blue-10',
  'cerise-10',
  'coral-10',
  'base-grey-13',
  'electric-indigo-10',
  'lime-10',
  'neon-carrot-10',
  'purple-pizzazz-10',
  'turquoise-10',
  'supernova-10',
];

export const DEFAULT_CUSTOM_WIDGET_SETTINGS = Object.freeze({
  name: 'custom',
  emoji: '📣',
  color: 'yellow',
  target: 'dashboard',
});

export const DEFAULT_SEGMENT_WIDGET_SETTINGS = Object.freeze({
  name: 'segment',
  emoji: '🧩',
  color: 'zinc',
  target: 'dashboard',
});

export const DEFAULT_CUSTOM_IMAGE_WIDGET_SETTINGS = Object.freeze({
  name: 'image',
  target: 'dashboard',
  color: 'indigo',
  emoji: '🖼',
  options: {},
});

export const DEFAULT_CUSTOM_VIDEO_WIDGET_SETTINGS = Object.freeze({
  name: 'video',
  color: 'cyan',
  target: 'dashboard',
  emoji: '📹',
  options: {
    autoplay: false,
    displayTitle: true,
    displayContent: true,
    displayUserInfo: true,
  },
});

export const DEFAULT_EMPLOYEE_CUSTOM_WIDGET_SETTINGS = Object.freeze({
  ...DEFAULT_CUSTOM_WIDGET_SETTINGS,
  name: 'employee-custom',
  emoji: '🦜',
  color: 'company',
  target: 'employee_dashboard',
});

export const DEFAULT_EMPLOYEE_VIDEO_WIDGET_SETTINGS = Object.freeze({
  ...DEFAULT_CUSTOM_VIDEO_WIDGET_SETTINGS,
  name: 'employee-video',
  target: 'employee_dashboard',
  color: 'electric-indigo-10',
  emoji: '📹',
  options: {
    autoplay: false,
    displayTitle: true,
    displayContent: true,
    displayUserInfo: true,
  },
});

export const DEFAULT_EMPLOYEE_IMAGE_WIDGET_SETTINGS = Object.freeze({
  ...DEFAULT_CUSTOM_IMAGE_WIDGET_SETTINGS,
  name: 'employee-image',
  target: 'employee_dashboard',
});

export const CUSTOM_WIDGET_SETTINGS = [
  DEFAULT_CUSTOM_WIDGET_SETTINGS,
  DEFAULT_SEGMENT_WIDGET_SETTINGS,
  DEFAULT_CUSTOM_VIDEO_WIDGET_SETTINGS,
  DEFAULT_CUSTOM_IMAGE_WIDGET_SETTINGS,
  DEFAULT_EMPLOYEE_CUSTOM_WIDGET_SETTINGS,
  DEFAULT_EMPLOYEE_VIDEO_WIDGET_SETTINGS,
  DEFAULT_EMPLOYEE_IMAGE_WIDGET_SETTINGS,
];
