import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class IndexRoute extends Route {
  @service errorMessage;
  @service router;

  beforeModel() {
    this.router.replaceWith('jobs.edit.posting');
  }
}
