import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import Current from 'teamtailor/services/current';

export default class SettingsShareLinksController extends Controller {
  @service declare current: Current;
}

declare module '@ember/controller' {
  interface Registry {
    'settings.share-links': SettingsShareLinksController;
  }
}
