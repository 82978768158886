import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n  <div class=\"mb-36\">\n    <AlertMessage @marginY={{true}} @type=\"info\">\n      <p>\n        {{t\n          \"settings.integrations.partner_activations.default_activation.partner_activated\"\n        }}</p>\n      <ExternalLink\n        @href=\"https://support.teamtailor.com/en/articles/1475768-triggers\"\n        @title={{t\n          \"settings.integrations.partner_activations.default_activation.how_triggers_work\"\n        }}\n      />\n    </AlertMessage>\n  </div>\n  <div>\n    <Partner::ConfigFields::Base\n      @field={{this.providerKeyField}}\n      @webhookData={{this.providerKey}}\n      @valueChanged={{@valueChanged}}\n    />\n  </div>\n</div>", {"contents":"<div>\n  <div class=\"mb-36\">\n    <AlertMessage @marginY={{true}} @type=\"info\">\n      <p>\n        {{t\n          \"settings.integrations.partner_activations.default_activation.partner_activated\"\n        }}</p>\n      <ExternalLink\n        @href=\"https://support.teamtailor.com/en/articles/1475768-triggers\"\n        @title={{t\n          \"settings.integrations.partner_activations.default_activation.how_triggers_work\"\n        }}\n      />\n    </AlertMessage>\n  </div>\n  <div>\n    <Partner::ConfigFields::Base\n      @field={{this.providerKeyField}}\n      @webhookData={{this.providerKey}}\n      @valueChanged={{@valueChanged}}\n    />\n  </div>\n</div>","moduleName":"teamtailor/components/partner/default-activation-config.hbs","parseOptions":{"srcName":"teamtailor/components/partner/default-activation-config.hbs"}});
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class PartnerDefaultActivationConfigComponent extends Component {
  @service intl;

  @alias('args.partnerActivation.partner') partner;
  @alias('args.partnerActivation.providerKey') providerKey;
  @alias('partner.website') website;

  get partnerName() {
    return this.partner?.name || 'partner';
  }

  get initValue() {
    return this.providerKey?.default_key;
  }

  get providerKeyField() {
    return {
      type: 'text',
      required: 'true',
      id: 'default_key',
      value: this.providerKey,
      description: this.intl.t(
        'settings.integrations.partner_activations.default_activation.key_description',
        { partnerName: this.partnerName }
      ),

      label: 'Integration key',
    };
  }
}
