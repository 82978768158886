import { attr } from '@ember-data/model';
import TriggerModel from 'teamtailor/models/trigger';

export default class TriggerRestrictModel extends TriggerModel<'restrict'> {
  @attr('date') declare created_at: Date;

  get valid() {
    return true;
  }

  get description() {
    return this.intl.t('models.trigger.restrict.to_candidate');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'trigger/restrict': TriggerRestrictModel;
  }
}
