import {
  AudioVideoFacade,
  DefaultModality,
  RealtimeAttendeePositionInFrame,
  VideoFxConfig,
  VideoPreference,
  VideoPreferences,
  VideoPriorityBasedPolicy,
  VideoSource,
} from 'amazon-chime-sdk-js';

export type MeetingData = {
  meeting: {
    Meeting: {
      MeetingId: string;
      MeetingArn: string;
      [key: string]: string;
    };
  };
  attendee: {
    Attendee: {
      AttendeeId: string;
      ExternalUserId: string;
    };
  };
};

export type VideoMeetingUser = {
  id: number;
  nameOrEmail: string;
  picture_url: string;
  title: string;
  is_organizer: boolean;
};

export type VideoConfig = {
  width: number;
  height: number;
  frameRate: number;
};

export type MeetingAttendee = {
  externalUserId: string;
  attendeeId: string;
  name?: string;
};

export type RemoteParticipantScreenShare = {
  attendeeId: string;
  baseAttendeeId: string;
  tileId: number;
};

export type VideoDimensions = {
  height: number | null;
  width: number | null;
};

export type AttendeeIdPresenceHandler = (
  attendeeId: string,
  present: boolean,
  externalUserId?: string,
  dropped?: boolean,
  posInFrame?: RealtimeAttendeePositionInFrame | null
) => void;

export type AteendeeIdsToTilesMap = { [key: string]: number };

export type DeviceFacingMode = 'environment' | 'user';

export type ChatMessage = {
  message: string;
  attendee_name: string;
  timestamp: number;
  user_id?: number;
};

export function bindChimeTileToVideoElement(
  element: HTMLElement,
  audioVideoFacade: AudioVideoFacade,
  attendeeId: string,
  tileId: number
) {
  const videoElement = element.querySelector(`#video-${attendeeId}`) as
    | HTMLVideoElement
    | undefined;

  if (!videoElement) {
    return;
  }

  audioVideoFacade.bindVideoElement(tileId, videoElement);
}

export function getMediaConstraints(
  isDesktop: boolean,
  deviceId?: string,
  facingMode?: DeviceFacingMode
) {
  return {
    width: { ideal: getVideoConfig(isDesktop).width },
    height: { ideal: getVideoConfig(isDesktop).height },
    ...(deviceId !== undefined && { deviceId }),
    ...(facingMode !== undefined && { facingMode: { ideal: facingMode } }),
  };
}

export function getVideoConfig(isDesktop: boolean): VideoConfig {
  return isDesktop ? desktopVideoConfig : mobileVideoConfig;
}

const desktopVideoConfig = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

const mobileVideoConfig = {
  width: 640,
  height: 480,
  frameRate: 24,
};

type ImageBackground = {
  name: string;
  imageUrl: string;
};

type ColorBackground = {
  name: string;
  color: string;
};

type NoBackground = {
  name: string;
};

export type Background = ImageBackground | ColorBackground | NoBackground;

export const BACKGROUNDS: string[] = [
  'office1',
  'beach1',
  'office2',
  'space',
  'beach2',
  'lights',
  'abstract',
];

export const DefaultVideoFxConfig: VideoFxConfig = {
  backgroundBlur: {
    isEnabled: false,
    strength: 'high',
  },

  backgroundReplacement: {
    isEnabled: false,
    backgroundImageURL: undefined,
    defaultColor: undefined,
  },
};

export function getImage(index: number): string {
  return `https://static.teamtailor-cdn.com/images/video_backgrounds/image_${
    index + 1
  }.jpg`;
}

export function getChosenBackground(
  backgroundName: string,
  videoFxConfig: VideoFxConfig,
  companyColor: string
): VideoFxConfig {
  if (backgroundName === 'company_color') {
    videoFxConfig.backgroundReplacement.backgroundImageURL = undefined;
    videoFxConfig.backgroundReplacement.defaultColor = companyColor;
  } else {
    const index = BACKGROUNDS.indexOf(backgroundName);
    videoFxConfig.backgroundReplacement.backgroundImageURL = getImage(index);
    videoFxConfig.backgroundReplacement.defaultColor = undefined;
  }

  return videoFxConfig;
}

export function updateVideoPreferences(
  priorityBasedDownlinkPolicy: VideoPriorityBasedPolicy,
  videoSources: VideoSource[]
): void {
  const videoPreferences = VideoPreferences.prepare();

  const screenShareSource = videoSources.find((videoSource) =>
    new DefaultModality(videoSource.attendee.attendeeId).hasModality(
      DefaultModality.MODALITY_CONTENT
    )
  );

  for (const videoSource of videoSources) {
    const { attendeeId } = videoSource.attendee;
    let priority = 1;

    if (screenShareSource) {
      priority = screenShareSource.attendee.attendeeId === attendeeId ? 1 : 2;
    }

    videoPreferences.add(new VideoPreference(attendeeId, priority));
  }

  priorityBasedDownlinkPolicy.chooseRemoteVideoSources(
    videoPreferences.build()
  );
}
