import BaseRoute from 'teamtailor/routes/base';
import { inject as service } from '@ember/service';
import { runInDebug } from '@ember/debug';
import { Transition } from 'teamtailor/utils/type-utils';
import TtAlertService from 'teamtailor/services/tt-alert';
import RouterService from '@ember/routing/router-service';
import IntlService from 'ember-intl/services/intl';
import PermissionsService from 'teamtailor/services/permissions';
import FlashMessageService from 'teamtailor/services/flash-message';
import ArrayProxy from '@ember/array/proxy';

type Conditions = {
  or?: ArrayProxy<string>;
  and?: ArrayProxy<string>;
};

export default class PermissionsRoute extends BaseRoute {
  @service declare intl: IntlService;
  @service declare ttAlert: TtAlertService;
  @service declare router: RouterService;
  @service declare permissions: PermissionsService;
  @service declare flashMessages: FlashMessageService;

  requiredPermissions = {};
  noAccessEntityNameKey = 'mixins.before_model_role_access.this_page';

  checkPermissions(conditions: Conditions | string): boolean {
    if (typeof conditions === 'string') {
      return this.permissions.has(conditions);
    } else if (conditions.or && Array.isArray(conditions.or)) {
      return !!conditions.or.find((permission) =>
        this.checkPermissions(permission)
      );
    } else if (conditions.and && Array.isArray(conditions.and)) {
      return conditions.and.every((permission) =>
        this.checkPermissions(permission)
      );
    }

    runInDebug(() => {
      console.error('missing handler for conditions: ', conditions); // eslint-disable-line no-console
    });
    return false;
  }

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    if (!this.checkPermissions(this.requiredPermissions)) {
      const message = this.intl.t(
        'mixins.before_model_role_access.dont_have_access_to',
        {
          entityName: this.intl.t(this.noAccessEntityNameKey),
        }
      );

      if (transition.from) {
        this.ttAlert.error(message);
        transition.abort();
      } else {
        this.flashMessages.error(message);
        this.router.replaceWith('dashboard');
      }
    }
  }
}
