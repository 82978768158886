import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Editor::Adjustments::Forms::Wrapper @visible={{@visible}}>\n  <Editor::Adjustments::Blocks::Jobs\n    @model={{@model}}\n    @onChange={{this.saveAndReload}}\n    @showNonPreviewAdjustments={{true}}\n  />\n</Editor::Adjustments::Forms::Wrapper>", {"contents":"<Editor::Adjustments::Forms::Wrapper @visible={{@visible}}>\n  <Editor::Adjustments::Blocks::Jobs\n    @model={{@model}}\n    @onChange={{this.saveAndReload}}\n    @showNonPreviewAdjustments={{true}}\n  />\n</Editor::Adjustments::Forms::Wrapper>","moduleName":"teamtailor/components/editor/adjustments/forms/blocks/jobs.hbs","parseOptions":{"srcName":"teamtailor/components/editor/adjustments/forms/blocks/jobs.hbs"}});
import FormBaseComponent from './base';
import { get } from 'teamtailor/utils/get';

export default class JobsAdjustmentsFormComponent extends FormBaseComponent {
  reload() {
    if (get(this, 'model.page.isJobListPage')) {
      this.iframePreview.reloadIframe();
    } else {
      super.reload();
    }
  }
}
