import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield this}}\n\n<Meeting::ConfirmDialog\n  @header={{t \"components.meetings.event_scheduler.cancel_meeting\"}}\n  @description={{t\n    \"components.meetings.event_scheduler.cancel_meeting_description\"\n  }}\n  @isOpen={{this.showConfirmDialog}}\n  @onClose={{this.closeConfirmDialog}}\n  @onConfirm={{this.confirmCancelMeeting}}\n/>", {"contents":"{{yield this}}\n\n<Meeting::ConfirmDialog\n  @header={{t \"components.meetings.event_scheduler.cancel_meeting\"}}\n  @description={{t\n    \"components.meetings.event_scheduler.cancel_meeting_description\"\n  }}\n  @isOpen={{this.showConfirmDialog}}\n  @onClose={{this.closeConfirmDialog}}\n  @onConfirm={{this.confirmCancelMeeting}}\n/>","moduleName":"teamtailor/components/meeting/edit-context.hbs","parseOptions":{"srcName":"teamtailor/components/meeting/edit-context.hbs"}});
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import PreparedMessageClass from 'teamtailor/classes/prepared-message';
import UPDATE_MEETING_MUTATION from 'teamtailor/gql/update-meeting-mutation.graphql';

import {
  ICandidate,
  IJobApplication,
  IUser,
} from 'teamtailor/components/meeting/types';
import RouterService from '@ember/routing/router-service';
import { IGraphQLErrorResponse } from 'teamtailor/utils/meetings/error-handling';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import ErrorClass from 'teamtailor/classes/error-class';
import { JobClass } from 'teamtailor/classes/job';
import {
  CandidateAttendeeClass,
  IMeetingRecord,
  MeetingEventListClass,
} from 'teamtailor/classes/meetings';
import { MeetingRoomAttendeeClass } from 'teamtailor/classes/meetings/meeting-room-attendee';
import IntlService from 'ember-intl/services/intl';
import FlashMessageService from 'teamtailor/services/flash-message';
import Store from '@ember-data/store';
import Component from '@glimmer/component';
import { Response } from 'teamtailor/services/meeting-loader-edit-graphql';

interface Args {
  data: Response;
  close: (meetingId?: string, meetingEventId?: string) => void;
  loading: boolean;
  onCancelEvent?: () => void;
}

export default class EditContext extends Component<Args> {
  @service declare apollo: TeamtailorApolloService;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;
  @service declare store: Store;

  @tracked showConfirmDialog = false;
  @tracked confirmDialogOpen = false;
  @tracked scrollDistance = 0;

  @tracked _loading = false;

  get loading(): boolean {
    return this.args.loading || this._loading;
  }

  get meetingId(): string {
    return this.args.data.meetingId;
  }

  get meetingEventList(): MeetingEventListClass {
    return this.args.data.meetingEvents;
  }

  get meetingRooms(): MeetingRoomAttendeeClass[] | undefined {
    return this.args.data.meetingRooms;
  }

  get errors(): ErrorClass {
    return this.args.data.errors;
  }

  get preparedMessage(): PreparedMessageClass | undefined {
    return this.args.data.preparedMessage;
  }

  get candidate(): ICandidate | undefined {
    return this.meetingEventList.meetingEvents[0]?.meetingEventAttendees
      ?.candidates[0];
  }

  get candidates(): CandidateAttendeeClass[] | undefined {
    return this.meetingEventList.meetingEvents[0]?.meetingEventAttendees
      ?.candidateAttendees;
  }

  get currentUser(): IUser {
    return this.args.data.user;
  }

  get job(): JobClass {
    return this.args.data.jobClass;
  }

  get jobApplications(): IJobApplication[] {
    return this.args.data.jobApplications;
  }

  meetingAttributes(): IMeetingRecord {
    return {
      meetingEventsAttributes: this.meetingEventList.toAttributes(),
      preparedMessageAttributes: this.preparedMessage
        ? this.preparedMessage.toAttributes()
        : undefined,

      jobId: this.job.job ? this.job.job.id : '',
    };
  }

  isCancellingAllEvents(): boolean {
    return this.meetingAttributes().meetingEventsAttributes.every(
      (e) => e._destroy
    );
  }

  @action
  handleScroll({ target }: Event): void {
    if (target instanceof HTMLElement) {
      this.scrollDistance = target.scrollTop;
    }
  }

  @action
  sendCloseAction(): void {
    if (this.meetingEventList.hasChanges()) {
      this.confirmDialogOpen = true;
    } else {
      this.args.close();
    }
  }

  @action
  closeCancelDialog(): void {
    this.confirmDialogOpen = false;
  }

  @action
  cancelAndClose(): void {
    this.confirmDialogOpen = false;
    this.args.close();
  }

  @action
  showCancelMeetingDialogue(): void {
    this.showConfirmDialog = true;
  }

  @action
  closeConfirmDialog(): void {
    if (this.showConfirmDialog) {
      this.showConfirmDialog = false;
    }
  }

  @action
  async confirmCancelMeeting(): Promise<void> {
    this._loading = true;
    this.meetingEventList.meetingEvents.forEach((e) =>
      this.meetingEventList.remove(e)
    );
    await this.save();
    if (this.args.onCancelEvent) {
      this.args.onCancelEvent();
    }

    this._loading = false;
  }

  @action
  async save(): Promise<void> {
    const variables = {
      meetingId: this.meetingId,
      meetingAttributes: this.meetingAttributes(),
    };

    return (
      this.apollo
        .mutate({
          mutation: UPDATE_MEETING_MUTATION,
          variables,
        })
        // ... not really unknown but we only care about not being undefined.
        // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
        .then((response: unknown | undefined) => {
          if (response) {
            this.store.query('meeting-event', {
              filter: {
                candidate_ids: this.candidates?.map((c) => c.candidate?.id),
              },
            }); // not sure why this query is not needed on new to update ember store.

            this.store.findRecord('meeting', this.meetingId);

            this.errors.reset();
            this.closeConfirmDialog();
            this.args.close(
              this.meetingId,
              this.meetingEventList.meetingEvents[0]?.id
            );
            this.flashMessages.success(
              this.intl.t('components.meeting_updated_successfully')
            );
          }
        })
        .catch(({ errors }: IGraphQLErrorResponse) => {
          if (errors) {
            this.errors.update(errors);
          }

          throw Error(this.intl.t('errors.something_went_wrong'));
        })
    );
  }
}
