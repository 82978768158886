export default {
  "root": "Viewer__root_1x3meh",
  "workWeek": "Viewer__workWeek_1x3meh",
  "calendarViewer": "Viewer__calendarViewer_1x3meh",
  "selfScheduleAuto": "Viewer__selfScheduleAuto_1x3meh",
  "selfSchedule": "Viewer__selfSchedule_1x3meh",
  "icon-move": "Viewer__icon-move_1x3meh",
  "icon-add": "Viewer__icon-add_1x3meh",
  "header": "Viewer__header_1x3meh",
  "left": "Viewer__left_1x3meh",
  "right": "Viewer__right_1x3meh",
  "weekPagination": "Viewer__weekPagination_1x3meh",
  "weekPagination--label": "Viewer__weekPagination--label_1x3meh",
  "weekPagination--button": "Viewer__weekPagination--button_1x3meh",
  "actions": "Viewer__actions_1x3meh",
  "users": "Viewer__users_1x3meh",
  "loader": "Viewer__loader_1x3meh"
};
