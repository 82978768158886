import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Molecules::StageTypes::SummaryCard\n  ...attributes\n  @stats={{this.stats}}\n  @averageTimes={{this.averageTimes}}\n  @job={{@job}}\n  @loading={{or (not this.fetchData.lastSuccessful) this.fetchData.isRunning}}\n  {{did-insert (perform this.fetchData)}}\n/>", {"contents":"<Insights::Molecules::StageTypes::SummaryCard\n  ...attributes\n  @stats={{this.stats}}\n  @averageTimes={{this.averageTimes}}\n  @job={{@job}}\n  @loading={{or (not this.fetchData.lastSuccessful) this.fetchData.isRunning}}\n  {{did-insert (perform this.fetchData)}}\n/>","moduleName":"teamtailor/components/insights/widgets/job-stats.hbs","parseOptions":{"srcName":"teamtailor/components/insights/widgets/job-stats.hbs"}});
import Component from '@glimmer/component';
import DateRange from 'teamtailor/utils/date-range';
import { dropTask } from 'ember-concurrency';
import { gql } from '@apollo/client/core';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import moment from 'moment-timezone';

const QUERY = gql`
  query JobAverageTimesQuery($dateRange: DateRangeAttributes!, $jobIds: [ID!]) {
    eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [HIRED]
      distinctBy: {
        fields: [EVENT_TYPE, JOB_APPLICATION_ID]
        order: { field: TIMESTAMP, desc: true }
      }
    ) {
      averageTimes: aggregated {
        timeToHire: average(field: TIME_TO_HIRE)
      }
      hires: rows {
        timestamp: keenTimestamp
      }
    }
  }
`;

export default class InsightsWidgetsJobStats extends Component {
  @service insights;

  get averageTimes() {
    return this.fetchData.lastSuccessful?.value || {};
  }

  get stats() {
    const { job } = this.args;

    let { inProcessCount, rejectedCount } = job;
    let stageTypesCounts = Object.values(job.stageTypesCounts).reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );
    let totalApplicants = inProcessCount + rejectedCount + stageTypesCounts;

    return {
      totalStats: {
        totalApplicants,
        rejectedCount,
        totalJobs: job.totalJobs,
      },

      stageTypesStats: {
        inboxCount: {
          count: job.stageTypesCounts.inbox_count,
          category: 'inbox',
        },

        inProcessCount: {
          count: inProcessCount,
          category: 'in_process',
        },

        interviewCount: {
          count: job.stageTypesCounts.interview_count,
          category: 'interview',
        },

        screeningCount: {
          count: job.stageTypesCounts.screening_count,
          category: 'screening',
        },

        offerCount: {
          count: job.stageTypesCounts.offer_count,
          category: 'offer',
        },

        hiredCount: {
          count: job.stageTypesCounts.hired_count,
          category: 'hired',
        },
      },
    };
  }

  async queryForData() {
    const { job } = this.args;
    const dateRange = new DateRange(job.publishedAt, new Date());

    const jobDetail = await job.jobDetail;

    const {
      eventQuery: { averageTimes, hires },
    } = await this.insights.query({
      query: QUERY,
      variables: {
        dateRange: dateRange.asObject,
        jobIds: [job.id],
      },

      context: { jobId: job.id },
    });

    return {
      ...(averageTimes?.firstObject || {}),
      timeToFill: this.calculateTimeToFill(jobDetail.jobStartedAt, hires),
    };
  }

  fetchData = dropTask(async () => {
    return this.queryForData();
  });

  calculateTimeToFill(jobStartedAt, hires) {
    if (isEmpty(jobStartedAt) || isEmpty(hires)) {
      return null;
    }

    const timeToHiresInSeconds = hires
      .map((hire) => hire.timestamp)
      .map((timestamp) => moment(timestamp))
      .map((timestamp) => moment.duration(timestamp.diff(jobStartedAt)))
      .map((duration) => duration.as('seconds'));

    return timeToHiresInSeconds.reduce((acc, i) => acc + i) / hires.length;
  }
}
