import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class EditRoute extends Route {
  @service router;
  @service store;

  model(params) {
    return this.store.findRecord('trigger', params.trigger_id);
  }

  @action
  modalClosedAction() {
    this.router.transitionTo('jobs.edit.stages');
  }
}
