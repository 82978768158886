import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Input\n  type=\"time\"\n  required={{this.required}}\n  disabled={{this.disabled}}\n  @size={{@size}}\n  ...attributes\n  {{on \"blur\" this.handleBlur}}\n  {{did-insert this.onInsertElement}}\n  {{did-update this.onUpdateElement}}\n/>", {"contents":"<Core::Input\n  type=\"time\"\n  required={{this.required}}\n  disabled={{this.disabled}}\n  @size={{@size}}\n  ...attributes\n  {{on \"blur\" this.handleBlur}}\n  {{did-insert this.onInsertElement}}\n  {{did-update this.onUpdateElement}}\n/>","moduleName":"teamtailor/components/time-picker.hbs","parseOptions":{"srcName":"teamtailor/components/time-picker.hbs"}});
import Component from '@glimmer/component';
import { get, action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import moment from 'moment-timezone';
import { argDefault } from 'teamtailor/utils/arg-default';
import { inject as service } from '@ember/service';

// https://caniuse.com/#feat=input-datetime
// https://stackoverflow.com/a/10199306/2014510
function nativeTimeInputSupported() {
  const input = document.createElement('input');
  input.setAttribute('type', 'time');

  const notValid = 'not-a-time';
  input.setAttribute('value', notValid);

  return input.value !== notValid;
}

export default class TimePicker extends Component {
  @argDefault required = false;
  @argDefault disabled = false;

  @service current;

  handleReceivedTime(element) {
    if (element) {
      let time;
      if (this.args.time.indexOf('T') > 0) {
        time = moment(this.args.time)
          .tz(this.current.company.timeZoneIdentifier)
          .format('HH:mm');
      } else {
        time = this.args.time;
      }

      element.value = time;
    }
  }

  formatInput(input) {
    let time = input.match(/(\d{1,2})\D?(\d\d)?\s*(p?)/i);

    if (!time) {
      return [12, 0];
    }

    let hours = parseInt(time[1], 10);
    if (hours === 12 && !isEmpty(time[3])) {
      hours = 0;
    } else {
      hours += hours < 12 && time[3] ? 12 : 0;
    }

    return [hours, parseInt(time[2], 10) || 0];
  }

  timeChanged(value) {
    if (!isEmpty(value)) {
      let [hours, minutes] = this.formatInput(value);
      hours = hours < 10 ? (hours = `0${hours}`) : hours;
      minutes = minutes < 10 ? (minutes = `0${minutes}`) : minutes;
      return `${hours}:${minutes}`;
    }

    return null;
  }

  @action
  handleBlur(event) {
    const { value } = event.target;

    if (this.useCustomFormatter) {
      const formattedTime = this.timeChanged(value);
      get(this.args, 'onChange')(formattedTime);
    } else {
      get(this.args, 'onChange')(value);
    }
  }

  @action
  onInsertElement(element) {
    this.element = element;
    this.useCustomFormatter = !nativeTimeInputSupported();

    this.handleReceivedTime(element);
  }

  @action
  onUpdateElement(element) {
    this.handleReceivedTime(element);
  }
}
