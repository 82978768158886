import TriggerSerializer from 'teamtailor/serializers/trigger';
import { get } from 'teamtailor/utils/get';

export default TriggerSerializer.extend({
  attrs: {
    stage: { serialize: 'ids' },
    pickedQuestions: {
      serialize: 'records',
      deserialize: 'ids',
    },
  },

  serialize(snapshot, options) {
    let [commonJson, extras] = this.extractCommonJson(
      this._super(snapshot, options)
    );

    let { partner_activation_id, picked_questions, moves, ...data } = extras;

    moves = snapshot.hasMany('moves').map((moveSnapshot) => {
      let moveData = moveSnapshot.serialize();

      moveData.id = moveSnapshot.id;

      let proceedStageSnapshot = moveSnapshot.belongsTo('proceedStage');

      if (proceedStageSnapshot && proceedStageSnapshot.record) {
        moveData.proceed_stage_id = proceedStageSnapshot.id;
        moveData.proceed_stage_name = get(proceedStageSnapshot.record, 'name');
      }

      return moveData;
    });

    return Object.assign(commonJson, {
      data,
      partner_activation_id,
      picked_questions_attributes: picked_questions,
      moves_attributes: moves,
    });
  },
});
