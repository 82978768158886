import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<a\n  href={{this.href}}\n  target={{this.target}}\n  title={{this.title}}\n  class=\"group flex items-center gap-4 truncate\"\n  {{on \"click\" this.click}}\n  ...attributes\n>\n  {{#if this.isFileInfected}}\n    <EmberTooltip @text={{t \"components.download_link.virus_detected\"}} />\n  {{/if}}\n  {{#if this.icon}}\n    <Icon @icon={{this.icon}} @size={{@iconSize}} class={{@iconClass}} />\n  {{/if}}\n  <span class={{@titleClass}}>\n    {{this.title}}\n  </span>\n</a>", {"contents":"<a\n  href={{this.href}}\n  target={{this.target}}\n  title={{this.title}}\n  class=\"group flex items-center gap-4 truncate\"\n  {{on \"click\" this.click}}\n  ...attributes\n>\n  {{#if this.isFileInfected}}\n    <EmberTooltip @text={{t \"components.download_link.virus_detected\"}} />\n  {{/if}}\n  {{#if this.icon}}\n    <Icon @icon={{this.icon}} @size={{@iconSize}} class={{@iconClass}} />\n  {{/if}}\n  <span class={{@titleClass}}>\n    {{this.title}}\n  </span>\n</a>","moduleName":"teamtailor/components/download-link.hbs","parseOptions":{"srcName":"teamtailor/components/download-link.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class DownloadLink extends Component {
  @service server;
  @service ttAlert;
  @service intl;

  get href() {
    return this.args.href || '#';
  }

  get target() {
    return this.args.target || '_blank';
  }

  get icon() {
    if (this.args.isFileInfected !== undefined) {
      return this.args.isFileInfected
        ? 'triangle-exclamation'
        : this.args.icon || 'paperclip';
    } else {
      return this.args.icon === false ? false : this.args.icon || 'paperclip';
    }
  }

  get title() {
    return this.args.title || 'Download';
  }

  get url() {
    return this.args.url || null;
  }

  get isFileInfected() {
    return this.args.isFileInfected || null;
  }

  download() {
    let newWindow = window.open();
    this.server.request(this.url).then(({ url }) => {
      newWindow.location.href = url;
    });
  }

  @action
  click(event) {
    event.preventDefault();

    if (this.isFileInfected) {
      this.ttAlert.confirm(
        this.intl.t('components.download_link.virus_detected_description'),
        () => {
          this.download();
        },
        () => {},
        {
          title: this.intl.t('components.download_link.virus_detected_title'),
          cancelButtonText: this.intl.t(
            'components.download_link.virus_cancel_download'
          ),

          confirmButtonText: this.intl.t(
            'components.download_link.virus_confirm_download'
          ),
        }
      );
    } else {
      this.download();
    }
  }
}
