import { inject as service } from '@ember/service';
import { set, action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { later } from '@ember/runloop';
import Controller from '@ember/controller';
import IntlService from 'ember-intl/services/intl';
import TtAlertService from 'teamtailor/services/tt-alert';
import Current from 'teamtailor/services/current';
import RouterService from '@ember/routing/router-service';
import FlashMessageService from 'teamtailor/services/flash-message';
import {
  CandidateModel,
  JobApplicationModel,
  LocationModel,
} from 'teamtailor/models';

export default class IndexController extends Controller {
  @service declare intl: IntlService;
  @service declare ttAlert: TtAlertService;
  @service declare current: Current;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;

  declare model: CandidateModel;

  newUploadFilename = undefined;

  queryParams = ['jobId'];
  jobId?: JobApplicationModel['id'];

  get jobApplication() {
    const jobApplications = get(this.candidate, 'jobApplications');

    if (!jobApplications.length) {
      return null;
    }

    return jobApplications.find((jobApp) => {
      return get(jobApp.job, 'id') === this.jobId;
    });
  }

  get candidate() {
    return get(this, 'model');
  }

  get isAnonymousStage() {
    if (!this.jobApplication) {
      return false;
    }

    return get(this.jobApplication.stage, 'anonymous');
  }

  get candidateHasResume() {
    return (
      get(this.candidate, 'resume') ||
      get(this.candidate, 'pdfOrOriginalResumeFileName')
    );
  }

  get pictureChanged() {
    const changedAttributes = get(this, 'candidate').changedAttributes();
    const pictureChanged =
      Object.keys(changedAttributes).indexOf('pictureCache');

    return pictureChanged !== -1;
  }

  get resumeChanged() {
    const candidate = get(this, 'candidate');
    const changedAttributes = candidate.changedAttributes();
    const resumeChanged = Object.keys(changedAttributes).indexOf('resume');

    return resumeChanged !== -1;
  }

  get oldResumeName() {
    if (!get(this, 'resumeChanged')) {
      return undefined;
    }

    const candidate = get(this, 'candidate');
    const changedAttributes = candidate.changedAttributes();
    return changedAttributes.pdfOrOriginalResumeFileName?.at(0) || '';
  }

  get pickedLocations() {
    if (get(this.candidate, 'isDeleted')) {
      return [];
    } else {
      const nonDeletedPickedLocations = get(
        this.candidate,
        'pickedLocations'
      ).filter((pickedLocation) => !pickedLocation.isDeleted);
      const locations = nonDeletedPickedLocations.map(
        (pickedLocation) => pickedLocation.location
      );

      return locations.filter(
        (item, index, array) =>
          array.findIndex((e) => get(e, 'id') === get(item, 'id')) === index
      );
    }
  }

  @action
  async toggleLocation(location: LocationModel) {
    const pickedLocations = await get(this.candidate, 'pickedLocations');

    const pickedLocation = pickedLocations.find(
      (pickedLocation) =>
        pickedLocation.belongsTo('location').id() === get(location, 'id')
    );

    if (pickedLocation) {
      if (get(pickedLocation, 'isNew')) {
        pickedLocation.unloadRecord();
      } else if (get(pickedLocation, 'isDeleted')) {
        pickedLocation.rollbackAttributes();
      } else {
        pickedLocations.forEach((p) => {
          if (p.belongsTo('location').id() === get(location, 'id')) {
            p.deleteRecord();
          }
        });
      }
    } else {
      pickedLocations.createRecord({ location });
    }
  }

  @action
  addResume(temporaryFileUrl: string, filename: string) {
    set(this.candidate, 'resume', temporaryFileUrl);
    set(this.candidate, 'pdfOrOriginalResumeFileName', filename);
  }

  @action
  rollbackResume() {
    const candidate = get(this, 'candidate');
    const changedAttributes = candidate.changedAttributes();
    const oldFilename =
      changedAttributes.pdfOrOriginalResumeFileName?.at(0) || null;

    set(this.candidate, 'resume', '');
    set(this.candidate, 'pdfOrOriginalResumeFileName', oldFilename);
  }

  @action
  removeResume() {
    set(this.candidate, 'deleteResume', true);
    set(this.candidate, 'pdfOrOriginalResumeFileName', '');
  }

  @action
  handleImageUploaded(pictureUrl: string) {
    set(this.candidate, 'pictureCache', pictureUrl);
  }

  @action
  rollbackPicture() {
    if (!get(this.candidate, 'pictureCache')) {
      set(this.candidate, 'picture', '');
      set(this.candidate, 'removePicture', true);
    } else {
      set(this.candidate, 'pictureCache', '');
    }
  }

  @action
  async saveCandidate() {
    const candidate = get(this, 'candidate');

    const dirtyJobApplications = get(candidate, 'jobApplications').filter(
      (jobApplication) => jobApplication.hasDirtyAttributes
    );

    const dirtyPickedLocations = get(candidate, 'pickedLocations').filter(
      (pickedLocation) => pickedLocation.hasDirtyAttributes
    );

    const deletedPickedLocations = get(candidate, 'pickedLocations').filter(
      (pickedLocation) => pickedLocation.isDeleted
    );

    await Promise.all(dirtyJobApplications.map((m) => m.save()));
    await Promise.all(dirtyPickedLocations.map((m) => m.save()));
    await Promise.all(deletedPickedLocations.map((m) => m.save()));

    return candidate.save().then(() => {
      window.history.back();
    });
  }

  @action
  destroyCandidate() {
    const candidateName = get(this.candidate, 'nameOrEmail');

    get(this, 'ttAlert').confirm(
      this.intl.t(
        'candidates.candidate.candidate_modal.are_you_sure_deleting_candidate',
        { candidateName }
      ),
      () => {
        get(this, 'candidate')
          .destroyRecord()
          .then(() => {
            get(this.candidate, 'jobApplications').forEach((ja) => {
              ja.deleteRecord();
            });
            later(() => {
              get(this, 'flashMessages').success(
                this.intl.t('candidates.candidate.edit.index.candidate_deleted')
              );
            }, 100);

            const jobId = get(this, 'jobId');
            if (jobId) {
              this.router.transitionTo('jobs.job.stages', jobId);
            } else {
              this.router.transitionTo('candidates.segment', 'all');
            }
          });
      }
    );
  }

  @action
  handleSourcedChange() {
    const sourcedAt = get(this.candidate, 'sourcedAt');
    set(this.candidate, 'sourcedAt', sourcedAt ? undefined : new Date());
  }
}

declare module '@ember/controller' {
  interface Registry {
    'candidates.candidate.edit': IndexController;
  }
}
