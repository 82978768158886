import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import CustomReportRequest, {
  buildFromSerializedFormat,
  fetchSchema,
  SerializedFilter,
} from 'teamtailor/utils/custom-report-request';
import {
  CustomReportType,
  CustomReportProperty,
  CustomReportRollups,
} from 'teamtailor/utils/custom-report-schema';
import { base64ToJson } from 'teamtailor/utils/base-64';
import AnalyticsService from 'teamtailor/services/analytics';
import Store from '@ember-data/store';
import AnalyticsCustomReportsSnapshotsNewController from 'teamtailor/controllers/analytics/custom-reports/snapshots/new';
import { getOwner } from '@ember/application';

interface QueryParams {
  template: string;
  reportType: CustomReportType;
  full?: boolean;
}

export default class extends Route {
  @service declare store: Store;
  @service declare analytics: AnalyticsService;

  queryParams = {
    reportType: {
      refreshModel: true,
      as: 'type',
    },

    template: {
      refreshModel: true,
    },

    full: {
      refreshModel: true,
    },
  };

  async model({ template, reportType, full }: QueryParams) {
    const schema = await fetchSchema(this.store);
    const value =
      template && template !== ''
        ? (base64ToJson(template) as {
            reportType: CustomReportType;
            properties: string[];
            filters: SerializedFilter[];
            rollups: CustomReportRollups;
          })
        : undefined;

    const request = value
      ? await buildFromSerializedFormat(
          getOwner(this),
          this.store,
          this.analytics.hasEnabledGroupAnalytics,
          schema,
          !full,
          value
        )
      : new CustomReportRequest(
          getOwner(this),
          !full,
          reportType,
          schema[reportType].properties.filter(
            (a: CustomReportProperty) => a.default
          )
        );

    const customReport = this.store.createRecord('custom-report', {
      ...request.serialize,
      rollups: value?.rollups || {},
    });

    return { schema, request, customReport };
  }

  resetController(
    controller: AnalyticsCustomReportsSnapshotsNewController,
    isExiting: boolean
  ) {
    if (isExiting && controller.model.customReport.isNew) {
      controller.model.customReport.unloadRecord();
    }
  }

  afterModel() {
    this.store.findAll('department');
  }
}
