import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import CandidateModel from './candidate';
import JobModel from './job';
import JobApplicationModel from './job-application';
import LocationModel from './location';
import PromotionModel from './promotion';

export default class PickedLocationModel extends Model {
  @attr('number') declare rowOrder: number;
  @attr('number') declare rowOrderPosition: number;
  @attr('string') declare ownerId: string;
  @attr('string') declare createdBy: string;
  @attr('string') declare _destroy: string;

  @belongsTo('location') declare location: AsyncBelongsTo<LocationModel>;
  @belongsTo('job') declare job: AsyncBelongsTo<JobModel>;
  @belongsTo('job-application')
  declare jobApplication: AsyncBelongsTo<JobApplicationModel>;

  @belongsTo('candidate') declare candidate: AsyncBelongsTo<CandidateModel>;
  @belongsTo('promotion') declare promotion: AsyncBelongsTo<PromotionModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'picked-location': PickedLocationModel;
  }
}
