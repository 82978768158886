import { get } from 'teamtailor/utils/get';
import UserModel from 'teamtailor/models/user';
import ActivityModel from 'teamtailor/models/activity';
import NoteModel from 'teamtailor/models/note';
import ReactionModel from 'teamtailor/models/reaction';

const getExistingReaction = (
  target: ActivityModel | NoteModel,
  user: UserModel,
  emoji: string
) => {
  const userId = get(user, 'id');

  return target.reactions.find((reaction: ReactionModel) => {
    if (reaction.emoji !== emoji) {
      return false;
    }

    const reactionUser = get(reaction, 'user');
    return get(reactionUser, 'id') === userId;
  });
};

export default function toggleReaction(
  target: ActivityModel | NoteModel,
  user: UserModel,
  emoji: string
) {
  const existingReaction = getExistingReaction(target, user, emoji);
  if (existingReaction) {
    return existingReaction.destroyRecord();
  } else {
    const reaction = target.reactions.createRecord({
      user,
      createdAt: new Date(),
      emoji,
    });

    return reaction.save().catch(() => {
      reaction.rollbackAttributes();
    });
  }
}
