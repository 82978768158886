import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

const INTERVAL = 600000;
export default class TableService extends Service {
  @tracked refreshJobsTable = false;
  @tracked refreshEmployeesTable = false;

  constructor(owner?: object) {
    super(owner);
    setInterval(() => {
      if (!this.refreshJobsTable) {
        this.refreshJobsTable = true;
      }

      if (!this.refreshEmployeesTable) {
        this.refreshEmployeesTable = true;
      }
    }, INTERVAL);
  }
}

declare module '@ember/service' {
  interface Registry {
    table: TableService;
  }
}
