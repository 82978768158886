import moment from 'moment-timezone';
import { FilterValue, IOperator } from 'teamtailor/components/fancy-filters';
import { JSONFilter } from 'teamtailor/components/fancy-filters/candidate-filters-json';
import BaseOperator from './base-operator';

export type RangeNameArguments =
  | 'less_than'
  | 'greater_than'
  | 'greater_than_or_equal_to'
  | 'relative_less_than'
  | 'relative_more_than'
  | 'equals'
  | 'rounded_equals'
  | 'between';

export default class RangeOperator extends BaseOperator implements IOperator {
  specialTypes = ['date', 'relative_date', 'assessment_score'];

  private relative = false;

  constructor(name: RangeNameArguments, relative = false) {
    super(name, false);
    this.relative = relative;
  }

  public asJSON(): JSONFilter {
    if (!this.filter) {
      throw new Error('Filter is not set');
    }

    switch (this.name) {
      case 'between':
        return this.betweenQuery;
      case 'less_than':
        return this.lessThanQuery;
      case 'greater_than':
      case 'greater_than_or_equal_to':
        return this.greaterThanQuery;
      case 'relative_less_than':
        return this.relativeLessThanQuery;
      case 'relative_more_than':
        return this.relativeMoreThanQuery;
      case 'equals':
      case 'rounded_equals':
        return this.equalQuery;
      default:
        throw new Error('unknown range operator name');
    }
  }

  private get betweenQuery(): JSONFilter {
    if (!this.filter) {
      throw new Error('Filter is not set');
    }

    return {
      [this.filter.name]: {
        between: this.filter.value,
      },
    };
  }

  private get equalQuery(): JSONFilter {
    if (!this.filter) {
      throw new Error('Filter is not set');
    }

    let equals: number | string | undefined;

    if (this.filter.filterType === 'date') {
      equals = moment(this.filter.value as Date).format('YYYY-MM-DD');
    } else {
      equals = Math.floor(this.filter.value as number);
    }

    return {
      [this.filter.name]: {
        [this.name]: equals,
      },
    };
  }

  private get lessThanQuery(): JSONFilter {
    if (!this.filter) {
      return {};
    }

    if (this.filterType === 'date') {
      return {
        [this.filter.name]: {
          less_than: moment(this.filter.value as Date).format('YYYY-MM-DD'),
        },
      };
    }

    return {
      [this.filter.name]: {
        less_than: this.filter.value,
      },
    };
  }

  private get relativeLessThanQuery(): JSONFilter {
    if (!this.filter) {
      return {};
    }

    return {
      [this.filter.name]: {
        relative_less_than: this.filter.value,
      },
    };
  }

  private get relativeMoreThanQuery(): JSONFilter {
    if (!this.filter) {
      return {};
    }

    return {
      [this.filter.name]: {
        relative_more_than: this.filter.value,
      },
    };
  }

  private get greaterThanQuery(): JSONFilter {
    if (!this.filter) {
      return {};
    }

    let value: FilterValue | undefined;
    if (this.filterType === 'date') {
      value = moment(this.filter.value as Date).format('YYYY-MM-DD');
    } else {
      value = this.filter.value;
    }

    return {
      [this.filter.name]: {
        [this.name]: value,
      },
    };
  }

  public get filterType() {
    if (this.relative) {
      return `relative_${this.filter?.filterType}`;
    }

    if (
      this.filter?.filterType === 'range' &&
      this.operatorName !== 'between'
    ) {
      return 'integer';
    }

    return this.filter?.filterType;
  }

  public formatValue(val?: FilterValue): FilterValue | undefined {
    let value = val;

    switch (this.filter?.filterType) {
      case 'date':
        value = this.formatDateValue(value);

        break;
      case 'range':
        value = this.formatRangeValue(value);

        break;
      default:
        break;
    }

    return value;
  }

  private formatDateValue(val?: FilterValue): FilterValue | undefined {
    let value = val;

    if (
      this.relative &&
      parseInt(value as string, 10).toString() !== value?.toString()
    ) {
      const today = moment().startOf('day');
      const date = moment(value?.toString());
      value = Math.max(today.diff(date, 'days'), 1);
    } else if (
      !this.relative &&
      parseInt(value as string, 10).toString() === value?.toString()
    ) {
      value = moment()
        .subtract(parseInt(value as string, 10), 'days')
        .format('YYYY-MM-DD');
    }

    return value;
  }

  private formatRangeValue(val?: FilterValue): FilterValue | undefined {
    let value = val;

    if (this.operatorName !== 'between' && Array.isArray(value)) {
      if (this.operatorName.includes('less_than')) {
        value = value[1] as number;
      } else {
        value = value[0] as number;
      }
    } else if (this.operatorName === 'between' && !Array.isArray(value)) {
      const min = parseInt(this.filter?.options[0] || '0', 10);
      const max = parseInt(this.filter?.options[1] || '100', 10);
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      const intValue = parseInt((value || '0').toString(), 10);
      if (intValue > min + (max - min) / 2) {
        value = [min, intValue];
      } else {
        value = [intValue, max];
      }
    }

    return value;
  }
}

export function prepareRangeOperator(
  name: RangeNameArguments,
  relative = false
): () => RangeOperator {
  return () => new RangeOperator(name, relative);
}
