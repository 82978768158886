import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { sort } from '@ember/object/computed';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';

export default class extends Controller {
  @service store;

  @tracked modalIsOpen = false;
  @tracked loadingSurveyId = null;
  @tracked archived = false;

  @computedLocalStorage(Boolean, 'Surveys.index.showFilterSidebar', false)
  showFilterSidebar;

  queryParams = ['archived'];

  sorting = ['createdAt:desc'];
  @sort('model', 'sorting') sortedSurveys;

  get hasFiltersSelected() {
    return this.archived === true;
  }

  @action
  async newSurvey() {
    this.modalIsOpen = true;
  }

  @action
  onClose() {
    this.modalIsOpen = false;
  }
}
