import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Dashboard::Widgets::Base::Wrapper\n  @isEditing={{this.isEditing}}\n  @draggable={{this.draggable}}\n  @showTitle={{false}}\n  @hideColor={{true}}\n  @pickedDashboardWidget={{@pickedDashboardWidget}}\n  @onRemoveWidget={{@onRemoveWidget}}\n  @bodyClass=\"!mx-0 !pb-0\"\n  as |Wrapper|\n>\n  <Wrapper.body>\n    <div\n      class=\"relative aspect-[9/16]\"\n      {{did-insert this.handleDidInsertWidget}}\n    >\n      {{#if this.getStories.isRunning}}\n        <Dashboard::Widgets::LoadingStories />\n      {{else}}\n        {{#if this.hasStories}}\n          <Dashboard::Widgets::StoriesList @stories={{this.stories}} />\n        {{else}}\n          <Dashboard::Widgets::StoriesEmpty />\n        {{/if}}\n      {{/if}}\n    </div>\n    <Dashboard::Widgets::StoriesPromotion />\n  </Wrapper.body>\n</Dashboard::Widgets::Base::Wrapper>", {"contents":"<Dashboard::Widgets::Base::Wrapper\n  @isEditing={{this.isEditing}}\n  @draggable={{this.draggable}}\n  @showTitle={{false}}\n  @hideColor={{true}}\n  @pickedDashboardWidget={{@pickedDashboardWidget}}\n  @onRemoveWidget={{@onRemoveWidget}}\n  @bodyClass=\"!mx-0 !pb-0\"\n  as |Wrapper|\n>\n  <Wrapper.body>\n    <div\n      class=\"relative aspect-[9/16]\"\n      {{did-insert this.handleDidInsertWidget}}\n    >\n      {{#if this.getStories.isRunning}}\n        <Dashboard::Widgets::LoadingStories />\n      {{else}}\n        {{#if this.hasStories}}\n          <Dashboard::Widgets::StoriesList @stories={{this.stories}} />\n        {{else}}\n          <Dashboard::Widgets::StoriesEmpty />\n        {{/if}}\n      {{/if}}\n    </div>\n    <Dashboard::Widgets::StoriesPromotion />\n  </Wrapper.body>\n</Dashboard::Widgets::Base::Wrapper>","moduleName":"teamtailor/components/dashboard/widgets/stories.hbs","parseOptions":{"srcName":"teamtailor/components/dashboard/widgets/stories.hbs"}});
import WidgetClass from './base/widget-class';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { tracked } from 'tracked-built-ins';
import { action } from '@ember/object';
import Store from '@ember-data/store';
import { task } from 'ember-concurrency';
import StoryModel from 'teamtailor/models/story';

export default class StoriesWidget extends WidgetClass {
  @service declare store: Store;
  @tracked stories: StoryModel[] = [];

  get hasStories() {
    return isPresent(this.stories);
  }

  getStories = task(async () => {
    this.stories = await this.store.query('story', { limit: 5 });
  });

  @action
  handleDidInsertWidget() {
    this.getStories.perform();
  }
}
