import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';

export default class SettingsTagsRoute extends ScrollToTopRoute {
  @service router;

  queryParams = {
    sortDirection: { refreshModel: true },
    sortColumn: { refreshModel: true },
    tagged_type: { refreshModel: true },
    color: { refreshModel: true },
    query: { refreshModel: true },
  };

  activate() {
    this.router.on('routeDidChange', this.onRouterDidChange);
  }

  deactivate() {
    this.router.off('routeDidChange', this.onRouterDidChange);
  }

  onRouterDidChange = (transition) => {
    if (transition.from?.name === 'settings.tags.index.new') {
      this.controller.page = 1;
    }
  };
}
