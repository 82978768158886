import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n  (hash\n    open=this.open\n    openAsync=this.openAsync\n    eventId=this.eventId\n    state=this.state\n  )\n}}", {"contents":"{{yield\n  (hash\n    open=this.open\n    openAsync=this.openAsync\n    eventId=this.eventId\n    state=this.state\n  )\n}}","moduleName":"teamtailor/components/auth-popup.hbs","parseOptions":{"srcName":"teamtailor/components/auth-popup.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import authPopup from 'teamtailor/utils/auth-promise-window';
import ENV from 'teamtailor/config/environment';
import { v1 as uuid } from 'ember-uuid';
import {
  task,
  timeout,
  waitForProperty,
  waitForEvent,
} from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

const EVENT_ORIGIN = `${ENV.httpOrHttps}://${ENV.RAILS_HOST}`;
const BASE_URL = `${ENV.httpOrHttps}://${ENV.RAILS_HOST}`;

export default class AuthPopupComponent extends Component {
  @tracked state;

  constructor() {
    super(...arguments);

    this.eventId = uuid();
  }

  get url() {
    if (this.args.provider) {
      return `${BASE_URL}/auth/${this.args.provider}?event_id=${this.eventId}${
        this.args.params ? `&${this.args.params}` : ''
      }`;
    } else {
      return this.args.url;
    }
  }

  checkClosed = task(async () => {
    while (this.authWindow && !this.authWindow.closed) {
      // eslint-disable-next-line no-await-in-loop
      await timeout(1000);
    }

    await timeout(500);
    if (this.openTask.isRunning) {
      this.openTask.cancelAll();
      this.args.handleFailure?.();
      this.state = 'default';
    }
  });

  openAsyncTask = task(async (key) => {
    this.openTask.perform('');
    await waitForProperty(this, key);
    this.authWindow.location = this.url;
  });

  openTask = task(async (url) => {
    this.state = 'loading';
    this.authWindow = authPopup(url, `Authorize ${this.args.provider}`);
    this.checkClosed.perform();

    let authorizationEvent;
    while (authorizationEvent == null) {
      // eslint-disable-next-line no-await-in-loop
      let event = await waitForEvent(window, 'message');
      if (
        event.origin === EVENT_ORIGIN &&
        event.data &&
        event.data.eventId === this.eventId
      ) {
        authorizationEvent = event;
      }
    }

    this.checkClosed.cancelAll();
    this.authWindow.close();
    this.args.handleSuccess?.(authorizationEvent.data);
    this.state = 'default';
  });

  @action
  open() {
    this.openTask.perform(this.url);
  }

  @action
  openAsync(key) {
    this.openAsyncTask.perform(key);
  }
}
