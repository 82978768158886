export default {
  "root": "Button__root_1ytjkr",
  "fullWidth": "Button__fullWidth_1ytjkr",
  "icon": "Button__icon_1ytjkr",
  "appearance--primary": "Button__appearance--primary_1ytjkr",
  "active": "Button__active_1ytjkr",
  "appearance--primaryDestructive": "Button__appearance--primaryDestructive_1ytjkr",
  "appearance--secondary": "Button__appearance--secondary_1ytjkr",
  "appearance--inGroup": "Button__appearance--inGroup_1ytjkr",
  "positionInGroup--alone": "Button__positionInGroup--alone_1ytjkr",
  "positionInGroup--alone-no-rounded-l": "Button__positionInGroup--alone-no-rounded-l_1ytjkr",
  "positionInGroup--leftMost": "Button__positionInGroup--leftMost_1ytjkr",
  "positionInGroup--rightMost": "Button__positionInGroup--rightMost_1ytjkr",
  "appearance--tertiary": "Button__appearance--tertiary_1ytjkr",
  "appearance--tertiaryDestructive": "Button__appearance--tertiaryDestructive_1ytjkr",
  "size--medium": "Button__size--medium_1ytjkr",
  "icon--left": "Button__icon--left_1ytjkr",
  "icon--right": "Button__icon--right_1ytjkr",
  "size--inGroup": "Button__size--inGroup_1ytjkr",
  "size--large": "Button__size--large_1ytjkr",
  "size--small": "Button__size--small_1ytjkr",
  "iconOnly": "Button__iconOnly_1ytjkr"
};
