import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';

export default class ReferenceTemplatesRoute extends ScrollToTopRoute {
  @service declare current: Current;
  @service declare router: RouterService;
  @service declare store: Store;

  beforeModel() {
    if (!this.current.company.hasFeature('references')) {
      this.router.replaceWith('settings.index');
    }
  }

  model() {
    return this.store.findAll('reference-template');
  }
}
