import { dropTask } from 'ember-concurrency';
import Controller from '@ember/controller';
import { action, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import RouterService from '@ember/routing/router-service';
import FlashMessageService from 'teamtailor/services/flash-message';
import TtAlertService from 'teamtailor/services/tt-alert';
import IntlService from 'ember-intl/services/intl';
import downloadCustomReport from 'teamtailor/utils/download-custom-report';
import AnalyticsService from 'teamtailor/services/analytics';
import { modifier } from 'ember-modifier';
import { helper } from '@ember/component/helper';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import { ChangedAttributes } from 'ember-data';

export function hasTrueDirtyAttributes(changedAttributes: ChangedAttributes) {
  let changed = false;
  Object.keys(changedAttributes).forEach((key) => {
    const attribute = changedAttributes[key];
    if (
      attribute &&
      attribute[0] !== undefined &&
      JSON.stringify(attribute[0]) !== JSON.stringify(attribute[1])
    ) {
      changed = true;
    }
  });

  return changed;
}

export default class AnalyticsCustomReportsSnapshotsEditController extends Controller {
  @service declare router: RouterService;
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;
  @service declare analytics: AnalyticsService;

  queryParams = ['full'];

  @tracked full = false;
  @tracked fullReportPending = false;
  @tracked rows: any[] = [];

  @tracked showScheduleReportModal = false;

  get changedAttributes() {
    if (this.customReport.hasDirtyAttributes) {
      return this.customReport.changedAttributes();
    }

    return {};
  }

  hasChanges = helper(([changedAttributes]: [ChangedAttributes]) => {
    return hasTrueDirtyAttributes(changedAttributes);
  });

  get editing() {
    return !this.full;
  }

  set editing(value: boolean) {
    this.full = !value;
  }

  get customReport() {
    return this.model.customReport;
  }

  get request() {
    return this.model.request;
  }

  get schema() {
    return this.model.schema;
  }

  get hasSelectedProperties(): boolean {
    return this.request.properties.length > 0;
  }

  onSave = dropTask(async () => {
    this.editing = false;
    await this.customReport.save();
    this.flashMessages.success(this.intl.t('custom_report.show.changes_saved'));
  });

  @action
  onGenerateFull() {
    this.fullReportPending = true;
    this.full = true;
  }

  @action
  onUpdatePropertyRollup(property: string, value: string) {
    const { customReport } = this;
    customReport.rollups = { ...customReport.rollups, [property]: value };
  }

  @action
  download() {
    downloadCustomReport(this.rows, this.request.properties, this.intl);
  }

  @action
  onDelete() {
    this.ttAlert.confirm(
      this.intl.t('custom_report.are_you_sure_deleting_report', {
        name: this.customReport.name,
      }),
      async () => {
        await this.customReport.destroyRecord();
        this.router.replaceWith('analytics.custom-reports.index');
      }
    );
  }

  @action
  toggleScheduleReportModal() {
    this.showScheduleReportModal = !this.showScheduleReportModal;
  }

  @action
  toggleEdit() {
    this.editing = !this.editing;
  }

  handleRequestChange = modifier(
    (_element, [serializedRequest]: [{ [key: string]: any }]) => {
      setProperties(this.customReport, serializedRequest);
    }
  );
}

declare module '@ember/controller' {
  interface Registry {
    'analytics.custom-reports.snapshots.edit': AnalyticsCustomReportsSnapshotsEditController;
  }
}
