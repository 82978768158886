import {
  FilterValue,
  IOperator,
  ParseRawOptions,
} from 'teamtailor/components/fancy-filters';
import { JSONFilter } from 'teamtailor/components/fancy-filters/candidate-filters-json';
import BaseOperator from './base-operator';

export default class AnyOperator extends BaseOperator implements IOperator {
  public parseValue(rawValue: string | string[]): FilterValue | undefined {
    return (
      super.parseValue(rawValue) || this.filter?.filter?.deserialize?.(null)
    );
  }

  public parseRawValue(
    json: JSONFilter,
    options: ParseRawOptions = {}
  ): string[] {
    const value = super.parseRawValue(json, options);

    if (value === undefined || value === null) {
      return [];
    }

    if (!Array.isArray(value)) {
      return [value];
    }

    return value;
  }
}

export function prepareAnyOperator(shouldInclude = true): () => AnyOperator {
  return () => new AnyOperator('any', !shouldInclude);
}
