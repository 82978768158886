import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { hash } from 'rsvp';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class SettingsPartnerHubNewRoute extends ScrollToTopRoute {
  @service store;

  queryParams = {
    type: { refreshModel: true },
  };

  model({ type }) {
    const attributes = {
      partner: {
        category: 'assessment',
        webhook: true,
        capabilityCandidateResults: true,
        capabilityTrigger: true,
        activationType: 'customer',
        webhookActivationConfig: JSON.stringify({ config: { fields: [] } }),
      },

      channel: {
        templateName: 'teamtailor',
        activationType: 'customer',
        activationConfig: JSON.stringify({ config: { fields: [] } }),
        presenterName: 'Channels::Teamtailor::Presenter',
        unlisted: true,
        allowedPaymentTypes: 'partner',
      },
    };

    const record = this.store.createRecord(type, attributes[type]);
    return hash({
      sandboxActivation: this.store.createRecord('sandbox-activation', {
        [type]: record,
      }),

      activation: this.store.createRecord(`${type}-activation`),
    });
  }

  resetController(controller) {
    Object.values(get(controller, 'model')).forEach((prop) =>
      prop?.rollbackAttributes()
    );
  }
}
