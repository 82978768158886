import { IOperator } from 'teamtailor/components/fancy-filters';
import BaseOperator from './base-operator';

export default class LikeOperator extends BaseOperator implements IOperator {
  get stringifiedValue(): string {
    const value = this.sanitize(this.filter?.serializedValue);
    if (Array.isArray(value)) {
      return `[${value
        .map((val) =>
          typeof val === 'string' ? `"%${val.toLowerCase()}%"` : val
        )
        .join(', ')}]`;
    }

    return typeof value === 'string'
      ? `"%${value.toLowerCase()}%"`
      : value?.toString() || '';
  }
}

export function prepareLikeOperator(shouldMatch = true): () => LikeOperator {
  if (shouldMatch) {
    return () => new LikeOperator('like');
  }

  return () => new LikeOperator('not_like');
}
