import Helper from '@ember/component/helper';
import { get } from 'teamtailor/utils/get';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { CandidateModel } from 'teamtailor/models';
import { AsyncBelongsTo } from '@ember-data/model';

export default class AnonymiseText extends Helper {
  @service declare intl: IntlService;

  compute([anonymous, candidate, text]: [
    boolean,
    CandidateModel | AsyncBelongsTo<CandidateModel> | null,
    string
  ]) {
    if (!anonymous || !candidate || !text) {
      return text;
    }

    return text.replace(
      buildRegex(
        get(candidate, 'firstName'),
        get(candidate, 'lastName'),
        get(candidate, 'email')
      ),
      this.intl.t('components.message_item.masked_in_anonymous_mode')
    );
  }
}

const escapeRegex = (regexp: string | null) => {
  return regexp?.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
};

const buildRegex = (
  firstName: string | null,
  lastName: string | null,
  email: string | null
) => {
  let regex = `(${escapeRegex(email)}`;

  if (firstName && lastName) {
    regex += `|${escapeRegex(firstName)} ${escapeRegex(lastName)}`;
  }

  if (firstName) {
    regex += `|${escapeRegex(firstName)}`;
  }

  if (lastName) {
    regex += `|${escapeRegex(lastName)}`;
  }

  regex += ')';

  return RegExp(regex, 'ig');
};
