import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import CompanyModel from './company';
import { inject as service } from '@ember/service';
import Server from 'teamtailor/services/server';

export default class WebhookSubscriptionModel extends Model {
  @service declare server: Server;

  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @attr('string') declare label: string;
  @attr('string') declare webhookUrl: string;
  @attr('string') declare signatureKey: string;
  @attr('raw') declare subscriptions?: string[];

  async getResources() {
    return this.server.collectionAction({
      modelName: WebhookSubscriptionModel.modelName,
      action: 'webhook_resources',
      method: 'GET',
    }) as Promise<{ resources: string[] }>;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'webhook-subscription': WebhookSubscriptionModel;
  }
}
