import Model, { belongsTo, attr, type AsyncBelongsTo } from '@ember-data/model';
import { CompanyModel, UserModel } from 'teamtailor/models';

export default class PrivacyPolicyModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;

  @attr('string') declare body: string;
  @attr('boolean') declare custom: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'privacy-policy': PrivacyPolicyModel;
  }
}
