import Service from '@ember/service';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { bool } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';

export default class ErrorMessageService extends Service {
  @tracked _errorType;

  @bool('_errorType') hasError;

  get fullscreenErrorComponent() {
    if (this._errorType) {
      return `fullscreen-error/${this._errorType}`;
    } else {
      return null;
    }
  }

  notFound() {
    this._errorType = 'not-found';
  }

  generic() {
    this._errorType = 'generic';
  }

  noAccess() {
    this._errorType = 'no-access';
  }
}
