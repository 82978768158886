/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import layout from 'teamtailor/components/stage-vertical-collection';

/**
 *
 * Copy of the component code from 'ember-drag-sort/components/drag-sort-list'
 *
 */

// ----- Ember modules -----
import { inject as service } from '@ember/service';
import { not, reads } from '@ember/object/computed';
import { computed, get, set, observer } from '@ember/object';
import { next } from '@ember/runloop';
import { A as array } from '@ember/array';
import uniqBy from 'teamtailor/utils/uniq-by';

// ----- Ember addons -----

export default Mixin.create({
  // ----- Arguments -----
  items: undefined,
  group: undefined,
  draggingEnabled: true,
  childClass: '',
  childTagName: 'div',
  handle: null,

  isHorizontal: false,
  isRtl: false,

  dragEndAction: undefined,
  determineForeignPositionAction: undefined,

  // ----- Services -----
  dragSort: service(),

  // ----- Overridden properties -----
  layout,
  classNameBindings: [
    ':dragSortList',
    'draggingEnabled:-draggingEnabled',
    'isHorizontal:-horizontal',
    'isVertical:-vertical',
    'isRtl:-rtl',
    'isDragging:-isDragging',
    'isDraggingOver:-isDraggingOver',
    'isExpanded2:-isExpanded',
    'isEmpty:-isEmpty',
  ],

  // ----- Static properties -----

  // ----- Aliases -----
  sourceList: reads('dragSort.sourceList'),
  targetList: reads('dragSort.targetList'),
  sourceIndex: reads('dragSort.sourceIndex'),
  draggedItem: reads('dragSort.draggedItem'),
  lastDragEnteredList: reads('dragSort.lastDragEnteredList'),
  isVertical: not('isHorizontal'),

  // ----- Computed properties -----
  isDragging: computed('dragSort.{isDragging,group}', 'group', function () {
    const isDragging = get(this, 'dragSort.isDragging');
    const group = get(this, 'group');
    const groupFromService = get(this, 'dragSort.group');

    return isDragging && group === groupFromService;
  }),

  isDraggingOver: computed('isDragging', 'items', 'targetList', function () {
    const isDragging = get(this, 'isDragging');
    const items = get(this, 'items');
    const targetList = get(this, 'targetList');

    return isDragging && items === targetList;
  }),

  isExpanded: computed(
    'isDragging',
    'isEmpty',
    'isOnlyElementDragged',
    function () {
      const isDragging = get(this, 'isDragging');
      const isEmpty = get(this, 'isEmpty');
      const isOnlyElementDragged = get(this, 'isOnlyElementDragged');

      return isDragging && (isEmpty || isOnlyElementDragged);
    }
  ),

  isExpanded2: reads('isExpanded'),

  isEmpty: computed('items.[]', function () {
    const count = get(this, 'items.length');

    return !count;
  }),

  isOnlyElementDragged: computed(
    'items.length',
    'items',
    'sourceList',
    'sourceIndex',
    function () {
      const count = get(this, 'items.length');
      const items = get(this, 'items');
      const sourceList = get(this, 'sourceList');
      const sourceIndex = get(this, 'sourceIndex');

      return count === 1 && items === sourceList && !sourceIndex;
    }
  ),

  // ----- Overridden methods -----
  dragEnter(event) {
    // Ignore irrelevant drags
    if (!get(this, 'dragSort.isDragging')) return;

    // Ignore irrelevant groups
    const group = get(this, 'group');
    const activeGroup = get(this, 'dragSort.group');
    if (group !== activeGroup) return;

    event.stopPropagation();

    // Ignore duplicate events (explanation: https://github.com/lolmaus/jquery.dragbetter#what-this-is-all-about )
    const items = get(this, 'items');
    const lastDragEnteredList = get(this, 'lastDragEnteredList');
    if (items === lastDragEnteredList) return;

    this.dragEntering(event);

    if (get(this, 'determineForeignPositionAction')) {
      this.forceDraggingOver();
    }
  },

  dragOver(event) {
    // This event is only used for placing the dragged element into the end of a horizontal list
    if (get(this, 'isVertical')) {
      return;
    }

    // Ignore irrelevant drags
    if (
      !get(this, 'dragSort.isDragging') ||
      get(this, 'determineForeignPositionAction')
    )
      return;

    const group = get(this, 'group');
    const activeGroup = get(this, 'dragSort.group');

    if (group !== activeGroup) return;

    event.stopPropagation();

    this.isDraggingOverHorizontal(event);
  },

  // ----- Custom methods -----
  dragEntering(event) {
    const group = get(this, 'group');
    const items = get(this, 'items');
    const dragSort = get(this, 'dragSort');
    set(dragSort, 'targetListModel', this.stage);
    const isHorizontal = get(this, 'isHorizontal');
    let targetIndex = 0;

    if (isHorizontal) {
      targetIndex = this.getClosestHorizontalIndex(this, event);
      set(dragSort, 'isDraggingUp', false);
    }

    dragSort.dragEntering({ group, items, isHorizontal, targetIndex });
  },

  getClosestHorizontalIndex(event) {
    // Calculate which item is closest and make that the target
    const itemsNodeList = get(this, 'element').querySelectorAll(
      '.dragSortItem'
    );
    const draggableItems = array(Array.prototype.slice.call(itemsNodeList));
    const positions = array(
      draggableItems.map((draggableItem) =>
        draggableItem.getBoundingClientRect()
      )
    );
    const rows = uniqBy(positions, 'top').mapBy('top').sort();
    const currentRowPosition = rows.filter((row) => row < event.clientY).pop();
    const closestItem = positions.filterBy('top', currentRowPosition).pop();

    return closestItem ? positions.indexOf(closestItem) : 0;
  },

  forceDraggingOver() {
    const determineForeignPositionAction = get(
      this,
      'determineForeignPositionAction'
    );

    const group = get(this, 'group');
    const items = get(this, 'items');
    const itemsLength = get(items, 'length');
    const draggedItem = get(this, 'draggedItem');
    const sourceList = get(this, 'sourceList');
    const dragSort = get(this, 'dragSort');

    let isDraggingUp = true;

    let index =
      items === sourceList
        ? items.indexOf(draggedItem) + 1
        : determineForeignPositionAction({ draggedItem, items });

    if (index >= itemsLength) {
      index = itemsLength - 1;
      isDraggingUp = false;
    }

    dragSort.draggingOver({ group, index, items, isDraggingUp });
  },

  isDraggingOverHorizontal(event) {
    const dragSort = get(this, 'dragSort');
    const group = get(this, 'group');
    const items = get(this, 'items');
    const index = this.getClosestHorizontalIndex(this, event);
    const isDraggingUp = false;

    dragSort.draggingOver({ group, index, items, isDraggingUp });
  },

  // ----- Observers -----
  // eslint-disable-next-line ember/no-observers
  setIsExpanded2: observer('isExpanded', function () {
    // The delay is necessary for HTML class to update with a delay.
    // Otherwise, dragging is finished immediately.
    next(() => {
      if (get(this, 'isDestroying') || get(this, 'isDestroyed')) return;

      set(this, 'isExpanded2', get(this, 'isExpanded'));
    });
  }),
});
