import Model, {
  belongsTo,
  hasMany,
  attr,
  AsyncHasMany,
  SyncHasMany,
  AsyncBelongsTo,
} from '@ember-data/model';
import { dropTask } from 'ember-concurrency';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';

import CopilotService from 'teamtailor/services/copilot';
import Current from 'teamtailor/services/current';
import { relationsRecordRemover } from 'teamtailor/utils/record-remover';
import { MappedTypes } from 'teamtailor/constants/question';
import { get } from 'teamtailor/utils/get';
import OrderedListHelper, {
  OrderedCompetence,
  OrderedCompetenceParent,
  NESTED_TYPES,
} from 'teamtailor/utils/competence/ordered-list-helpers';

import TagModel from 'teamtailor/models/tag';
import PickedInterviewKitModel from 'teamtailor/models/picked-interview-kit';
import ScorecardPickModel from 'teamtailor/models/scorecard-pick';
import PickedQuestionModel from 'teamtailor/models/picked-question';
import CompanyModel from 'teamtailor/models/company';
import JobModel from 'teamtailor/models/job';
import QuestionModel from 'teamtailor/models/question';
import ScorecardCriteriumModel from 'teamtailor/models/scorecard-criterium';
import StageModel from 'teamtailor/models/stage';
import TranslationInterviewKitModel from 'teamtailor/models/translation/interview-kit';

import applyDefaultPickedQuestionValues from 'teamtailor/utils/apply-default-picked-question-values';

export default class InterviewKitModel extends Model {
  @service declare copilot: CopilotService;
  @service declare current: Current;

  @attr('string')
  declare name: string;

  @attr('string')
  declare instructions: string;

  @attr('boolean')
  declare isHidden: boolean;

  @attr('string')
  declare languageCode: string;

  @attr('string')
  declare textInstructions: string;

  @attr('raw')
  declare pickedInterviewKitIds: number[];

  @attr('date')
  declare createdAt: Date;

  @attr('boolean')
  declare hasInterviews: boolean;

  @attr('raw', { defaultValue: () => [] })
  declare competenceOrder: OrderedCompetence[];

  get hasInstructions(): boolean {
    return !isEmpty(this.textInstructions);
  }

  @hasMany('tag', { async: true })
  declare tags: AsyncHasMany<TagModel>;

  get tagNames(): string[] {
    return this.tags.mapBy('name');
  }

  @hasMany('picked-interview-kit')
  declare pickedInterviewKit: AsyncHasMany<PickedInterviewKitModel>;

  @hasMany('scorecard-pick', { async: false })
  declare scorecardPicks: SyncHasMany<ScorecardPickModel>;

  @hasMany('picked-question', { async: false })
  declare pickedQuestions: SyncHasMany<PickedQuestionModel>;

  @hasMany('stage')
  declare stages: AsyncHasMany<StageModel>;

  @hasMany('translation/interview-kit')
  declare translations: AsyncHasMany<TranslationInterviewKitModel>;

  get questions(): QuestionModel[] {
    return this.pickedQuestions.mapBy('question');
  }

  get sortedQuestions(): QuestionModel[] {
    return this.pickedQuestions.sortBy('rowOrder').mapBy('question');
  }

  get scorecardCriteria(): ScorecardCriteriumModel[] {
    return this.scorecardPicks.mapBy('scorecardCriterium');
  }

  get sortedScorecardCriteria(): ScorecardCriteriumModel[] {
    return this.scorecardPicks.sortBy('rowOrder').mapBy('scorecardCriterium');
  }

  @belongsTo('company')
  declare company: AsyncBelongsTo<CompanyModel>;

  @belongsTo('job')
  declare job: AsyncBelongsTo<JobModel>;

  get personalityPicks(): ScorecardPickModel[] {
    return this.scorecardPicks.filterBy('topic', 'personality');
  }

  get skillPicks(): ScorecardPickModel[] {
    return this.scorecardPicks.filterBy('topic', 'skills');
  }

  get numberOfQuestions(): number {
    return this.pickedQuestions.slice().length;
  }

  get numberOfScorecards(): number {
    return this.scorecardPicks.slice().length;
  }

  deleteNewAssociations(): void {
    relationsRecordRemover(this, 'scorecard-pick', 'picked-question');
  }

  get nestedInterviewItems(): OrderedCompetence[] {
    const { competenceOrder } = this;

    if (isEmpty(competenceOrder)) {
      return OrderedListHelper.getFirstTimeNestedItems(this);
    }

    this.competenceOrder.forEach((item) => {
      if (!item.model) {
        item.model = OrderedListHelper.getNestedItemModel(this, item);
      }
    });

    OrderedListHelper.setNestedModels(this, this.competenceOrder);

    return this.competenceOrder;
  }

  addPickedQuestion(
    question: QuestionModel,
    parentCompetence?: OrderedCompetenceParent
  ) {
    this.pickedQuestions.createRecord(
      applyDefaultPickedQuestionValues({
        question,
        ownerType: 'InterviewKit',
        ownerId: get(this, 'id'),
        questionId: get(question, 'id'),
        interviewKit: this,
      })
    );

    const orderedCompetence = OrderedListHelper.modelToOrderedCompetence(
      NESTED_TYPES.QUESTION,
      question
    );

    if (parentCompetence) {
      parentCompetence.children.pushObject(orderedCompetence);
    } else {
      this.competenceOrder.pushObject(orderedCompetence);
    }

    this.updateQuestionsRowOrderPosition();
  }

  removePickedQuestion(pickedQuestion: PickedQuestionModel) {
    OrderedListHelper.findAndRemoveOrderedCompetence(
      this.competenceOrder,
      NESTED_TYPES.QUESTION,
      pickedQuestion.questionId,
      get(pickedQuestion.question, 'title')
    );
    this.pickedQuestions.removeObject(pickedQuestion);
  }

  addScorecardPick(scorecardCriterium: ScorecardCriteriumModel) {
    const scorecardPick = this.store.createRecord('scorecard-pick', {
      scorecardCriterium,
      interviewKit: this,
    });

    this.scorecardPicks.addObject(scorecardPick);

    this.competenceOrder.pushObject(
      OrderedListHelper.modelToOrderedCompetence(
        NESTED_TYPES.COMPETENCE,
        scorecardCriterium
      )
    );

    this.updateScorecardPickRowOrderPositions();
  }

  removeScorecardPick(scorecardPick: ScorecardPickModel) {
    const scorecardCriterium = get(scorecardPick, 'scorecardCriterium');

    const competence = this.competenceOrder.find((item: OrderedCompetence) => {
      if (item.type !== NESTED_TYPES.COMPETENCE) {
        return false;
      }

      const id = get(scorecardCriterium, 'id');
      const name = get(scorecardCriterium, 'name');
      const model = item.model as ScorecardCriteriumModel;

      // ID is not always available, e.g when the scorecard criterium is generated by Copilot and not saved yet
      if (id) {
        return item.id === id;
      } else {
        return get(model, 'name') === name;
      }
    });

    if (competence) {
      (competence as OrderedCompetenceParent).children.forEach((item) => {
        const pickedQuestion = this.getPickedQuestionByOrderedItem(item);

        if (pickedQuestion) {
          this.pickedQuestions.removeObject(pickedQuestion);
        }
      });

      this.competenceOrder.removeObject(competence);
    }

    this.scorecardPicks.removeObject(scorecardPick);
    this.copilot.destroyInterviewKitQuestions(get(scorecardCriterium, 'name'));
  }

  updateRowOrderPositions(all = false) {
    if (all) {
      this.updateScorecardPickRowOrderPositions();
    }

    this.updateQuestionsRowOrderPosition();
  }

  updateScorecardPickRowOrderPositions(): void {
    this.competenceOrder
      .filterBy('type', NESTED_TYPES.COMPETENCE)
      .forEach((item, index) => {
        const pick = this.getScorecardPickByOrderedItem(item);
        if (pick) {
          pick.rowOrderPosition = index;
        }
      });
  }

  updateQuestionsRowOrderPosition(): void {
    const flattened = this.competenceOrder.reduce(
      (array: OrderedCompetence[], item: OrderedCompetence) => {
        if (item.type === NESTED_TYPES.QUESTION) {
          array.push(item);
        }

        if (item.children) {
          array.pushObjects([...item.children]);
        }

        return array;
      },
      []
    );

    flattened.forEach((item, index) => {
      const pick = this.getPickedQuestionByOrderedItem(item);
      if (pick) {
        pick.rowOrderPosition = index;
      }
    });
  }

  getScorecardPickByOrderedItem(
    item: OrderedCompetence
  ): ScorecardPickModel | undefined {
    return this.scorecardPicks.findBy('scorecardCriterium.id', item.id);
  }

  getPickedQuestionByOrderedItem(
    item: OrderedCompetence
  ): PickedQuestionModel | undefined {
    return this.pickedQuestions.findBy('question.id', item.id);
  }

  suggestQuestions = dropTask(
    async (
      jobId?: string,
      scorecardCriteriumId?: string,
      skill?: string,
      trait?: string
    ) => {
      const addedQuestionTitles = this.questions.mapBy('title');
      const { toolCalls, languageCode } =
        await this.copilot.generateInterviewKitQuestions(
          jobId,
          scorecardCriteriumId,
          skill,
          trait,
          addedQuestionTitles
        );

      await Promise.all(
        toolCalls.map(async (toolCall) => {
          if (toolCall.name === 'create_new_question') {
            const orderedCompetenceParent = this.nestedInterviewItems.findBy(
              'model.id',
              scorecardCriteriumId
            ) as OrderedCompetenceParent | undefined;

            const questionType = (type: string) => {
              switch (type) {
                case 'text':
                  return MappedTypes.text;
                case 'yes_or_no':
                  return MappedTypes.boolean;
                case 'date':
                  return MappedTypes.date;
                case 'number':
                  return MappedTypes.number;
                case 'range':
                  return MappedTypes.range;
                case 'multiple_choice':
                case 'single_choice':
                  return MappedTypes.choice;
                default:
                  return MappedTypes.text;
              }
            };

            const questionArguments = toolCall.arguments;

            if (orderedCompetenceParent && questionArguments.type) {
              const question = this.store.createRecord('question', {
                type: questionType(questionArguments.type),
                languageCode,
                alternatives: questionArguments.alternatives?.join('\n') || [],
                startWith: questionArguments.range?.start_with,
                endWith: questionArguments.range?.end_with,
                multiple: questionArguments.type === 'multiple_choice',
                singleLine: false,
                questionData: {
                  alternatives:
                    questionArguments.alternatives?.map(
                      (alternative, index) => ({
                        id: index + 1,
                        title: alternative,
                      })
                    ) || [],

                  end_with: questionArguments.range?.end_with,
                  start_with: questionArguments.range?.start_with,
                  unit: questionArguments.range?.unit,
                },

                title: questionArguments.title,
                scorecardCriterium: orderedCompetenceParent.model,
                createdByCopilot: true,
              });

              this.addPickedQuestion(question, orderedCompetenceParent);
            }
          } else if (
            toolCall.name === 'pick_existing_question' &&
            toolCall.arguments.question_id
          ) {
            const question = await this.store.findRecord(
              'question',
              toolCall.arguments.question_id
            );

            const orderedCompetenceParent = this.nestedInterviewItems.findBy(
              'model.id',
              scorecardCriteriumId
            ) as OrderedCompetenceParent | undefined;

            question.addedByCopilot = true;

            if (orderedCompetenceParent) {
              this.addPickedQuestion(question, orderedCompetenceParent);
            }
          }
        })
      );
    }
  );
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'interview-kit': InterviewKitModel;
  }
}
