import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import moment from 'moment-timezone';
import flatten from 'teamtailor/utils/flatten';
import computeFullErrorMessages from 'teamtailor/utils/full-error-messages';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import uniqBy from 'teamtailor/utils/uniq-by';

export default class JobModel extends Model {
  @service intl;
  @service server;
  @service flipper;

  @belongsTo('company') company;
  @belongsTo('company', { inverse: 'templates' }) templateCompany;
  @belongsTo('job-detail', { inverse: 'job' }) jobDetail;
  @belongsTo('department') department;
  @belongsTo('location') location;
  @belongsTo('location') fallbackLocation;
  @belongsTo('page', { inverse: null }) adTemplate;
  @belongsTo('job-highlight') jobHighlight;
  @belongsTo('role') role;
  @belongsTo('team') team;
  @belongsTo('user') user;
  @belongsTo('user', { inverse: 'recruiterJobs' }) recruiter;
  @belongsTo('requisition') requisition;
  @belongsTo('overdue-job-application') overdueJobApplication;
  @hasMany('approval') approvals;
  @hasMany('activity') activities;
  @hasMany('location') locations;
  @hasMany('page') pages;
  @hasMany('promotion') promotions;
  @hasMany('scorecard-comments') scorecardComments;
  @hasMany('partner-result') partnerResults;
  @hasMany('tag') tags;

  @attr mailbox;
  @attr('boolean') allowCandidatePicker;
  @attr('number') minSalary;
  @attr('number') maxSalary;
  @attr('string') applyUrl;
  @attr('boolean') defaultTemplate;
  @attr('array') cachedJobTags;
  @attr('date') createdAt;
  @attr('string') endDate;
  @attr('number') hiredCount;
  @attr('string') humanStatus;
  @attr('number') inProcessCount;
  @attr('number') inboxCount;
  @attr('boolean', { defaultValue: false }) internal;
  @attr('string') internalUrl;
  @attr('date') lastJobApplicationAt;
  @attr('string') permalink;
  @attr('boolean', { defaultValue: false }) pinned;
  @attr('string') pusherChannel;
  @attr('number') rejectedCount;
  @attr('string') startDate;
  @attr('string') status;
  @attr('boolean', { defaultValue: false }) template;
  @attr('string') templateName;
  @attr('string') externalTitle;
  @attr('string') internalName;
  @attr('boolean') transparentRecruiting;
  @attr('number') unreadApplications;
  @attr('string') url;
  @attr('string') uuid;
  @attr('boolean') userHasAccess;
  @attr('boolean') demoData;
  @attr('string') remoteStatus;
  @attr('boolean') suggestedStageEnabled;
  @attr('string') employmentType;
  @attr('string') employmentLevel;
  @attr('raw') stageTypesCounts;
  @attr('number') suggestedCandidates;
  @attr('raw') excludeFromFeeds;
  @attr('string', {
    defaultValue: 'monthly',
  })
  salaryTimeUnit;

  @attr('string', {
    defaultValue: 'EUR',
  })
  currency;

  get teamMembers() {
    return get(get(this, 'jobDetail'), 'teamMembers');
  }

  get visiblePickedCustomFields() {
    return get(this, 'pickedCustomFields').filterBy('isHidden', false);
  }

  get isNotDemoData() {
    return !this.demoData;
  }

  get assetStructureContext() {
    const locations = get(this, 'locationsWithFallback');
    const locationIds =
      locations && locations.length
        ? locations.map((location) => {
            return get(location, 'id');
          })
        : null;

    return {
      department: get(this.department, 'id'),
      role: get(this.role, 'id'),
      region: null,
      location: locationIds,
    };
  }

  get locationsWithFallback() {
    return get(this, 'locations.length')
      ? get(this, 'locations')
      : [get(this, 'fallbackLocation')].filter(Boolean);
  }

  get recruitingFirms() {
    return get(get(this, 'jobDetail'), 'recruitingFirms');
  }

  get notTemplate() {
    return !this.template;
  }

  get stages() {
    return get(get(this, 'jobDetail'), 'stages');
  }

  get approved() {
    return get(get(this, 'jobDetail'), 'approved');
  }

  get languageCode() {
    return get(get(this, 'jobDetail'), 'languageCode');
  }

  get sortedLocations() {
    return get(this, 'locations')
      .toArray()
      .sort((a, b) =>
        get(a, 'nameOrCity').localeCompare(get(b, 'nameOrCity'), 'sv')
      );
  }

  get fullErrorMessages() {
    return computeFullErrorMessages(this, this.intl);
  }

  get templateTitle() {
    return isEmpty(get(this, 'templateName'))
      ? get(this, 'title')
      : get(this, 'templateName');
  }

  get title() {
    return isEmpty(get(this, 'internalName'))
      ? get(this, 'externalTitle')
      : get(this, 'internalName');
  }

  get publishedAt() {
    return get(this, 'startDate') || get(this, 'createdAt');
  }

  get isTemp() {
    return this.status === 'temp';
  }

  get isDraft() {
    return this.status === 'draft';
  }

  get isOpen() {
    return this.status === 'open';
  }

  get isUnlisted() {
    return this.status === 'unlisted';
  }

  get isArchived() {
    return this.status === 'archived';
  }

  get isAwaiting() {
    return this.status === 'awaiting';
  }

  get notArchived() {
    return !this.isArchived;
  }

  get notUnlisted() {
    return !this.isUnlisted;
  }

  get isNotDraft() {
    return !this.isDraft;
  }

  get isNotRemote() {
    return this.remoteStatus !== 'remote';
  }

  get isRemote() {
    return !this.isNotRemote;
  }

  get interviewKits() {
    return get(get(this, 'jobDetail'), 'interviewKits');
  }

  get pickedCustomFields() {
    return get(get(this, 'jobDetail'), 'pickedCustomFields');
  }

  get isAwaitingApproval() {
    return (
      get(this, 'company.activatedApprovals') &&
      get(this, 'status') === 'awaiting'
    );
  }

  get isAwaitingPublish() {
    return !get(this, 'company.activatedApprovals') && get(this, 'isAwaiting');
  }

  get isExpired() {
    let endDate = get(this, 'endDate');
    return !isEmpty(endDate) && moment(endDate).isBefore(new Date(), 'day');
  }

  get isNotExpired() {
    return !this.isExpired;
  }

  get isPending() {
    if (get(this, 'isAwaitingApproval')) {
      return true;
    }

    let startDate = get(this, 'startDate');
    return !isEmpty(startDate) && moment(startDate).isAfter(new Date(), 'day');
  }

  get isNotPending() {
    return !this.isPending;
  }

  get isPublished() {
    return this.isOpen && this.isNotExpired && this.isNotPending;
  }

  get isPublic() {
    return !this.internal;
  }

  get hiredStage() {
    return get(this, 'stages')?.find((stage) => stage.hired);
  }

  get titleAndStatus() {
    if (get(this, 'isOpen')) {
      return get(this, 'title');
    } else {
      return `${get(this, 'title')} (${get(this, 'status')})`;
    }
  }

  get shouldUseApprovals() {
    return get(this, 'company.activatedApprovals') && get(this, 'notTemplate');
  }

  get isPendingApproval() {
    return !get(this, 'approved') && get(this, 'shouldUseApprovals');
  }

  get hasOnlyOneLocation() {
    return get(this, 'locations') && get(this, 'locations.length') === 1;
  }

  get searchString() {
    return [
      get(this, 'title'),
      get(this, 'location.nameOrCity'),
      get(this, 'department.name'),
    ].join(' ');
  }

  get unrestrictedTeamMembers() {
    return get(this, 'teamMembers').filter((user) => {
      return ['recruiter', 'recruitment_admin', 'admin'].includes(
        get(user, 'role')
      );
    });
  }

  get teamMembersAndRecruiter() {
    let users = [];
    let user = get(this, 'recruiter');
    if (user && get(user, 'nameOrEmail')) {
      users.pushObject(user);
    }

    users.pushObjects(get(this, 'teamMembersWithoutRecruiter').toArray());

    return users;
  }

  get teamMembersWithoutRecruiter() {
    let users = [];

    if (get(this, 'teamMembers.isFulfilled')) {
      users.pushObjects(get(this, 'teamMembers').toArray());
    }

    if (get(this, 'team.users.isFulfilled')) {
      users.pushObjects(get(this, 'team.users').toArray());
    }

    if (get(this, 'team.user.isFulfilled')) {
      users.pushObject(get(this, 'team.user'));
    }

    return users;
  }

  get recruitingFirmRecruiters() {
    return flatten(
      get(this, 'recruitingFirms').map((rf) => {
        return get(rf, 'recruiters');
      })
    );
  }

  get _usersWhoCanBeMentioned() {
    return flatten([
      get(this, 'teamMembersAndRecruiter'),
      get(this, 'company.allAdminsAndRecruitmentAdmins'),
      get(this, 'recruitingFirmRecruiters'),
    ]);
  }

  get usersWhoCanBeMentioned() {
    return uniqBy(get(this, '_usersWhoCanBeMentioned'), 'id');
  }

  get careerSite() {
    const careerSites = get(this, 'company.careerSites');

    return isEmpty(careerSites)
      ? null
      : careerSites.findBy('languageCode', get(this, 'languageCode'));
  }

  get isPromotable() {
    const allowedToPromote =
      get(this.company, 'customer') || get(this.company, 'sandbox');

    return (
      this.isPublished &&
      this.isPublic &&
      this.isNotDemoData &&
      allowedToPromote
    );
  }

  get isPromotableForInternalChannels() {
    return (
      (this.isPublished || this.isUnlisted || this.internal) &&
      this.isNotDemoData
    );
  }

  get templateNameWithTags() {
    return `${get(this, 'templateName')} (${get(this, 'tags').mapBy('name')})`;
  }

  get templateNameWithFallback() {
    if (this.defaultTemplate) {
      return this.intl.t('models.job.default_template');
    } else {
      return this.templateName ? this.templateName : this.title;
    }
  }

  get activeJobApplicationsCount() {
    return this.stages.reduce(
      (sum, stage) => sum + stage.activeJobApplicationsCount,
      0
    );
  }

  get totalJobApplicationsCount() {
    return this.activeJobApplicationsCount + (this.rejectedCount || 0);
  }

  get canHire() {
    if (get(this.flipper, 'requisition_block_hire')) {
      const requisition = get(this, 'requisition');
      if (requisition) {
        return !get(requisition, 'isHiringComplete');
      }
    }

    return true;
  }

  async copyJob(data) {
    return this.server.memberAction(this, {
      action: 'new_from_template',
      method: 'POST',
      options: {
        data,
      },
    });
  }

  async archive(rejectOptions = {}) {
    const response = await this.server.memberAction(this, {
      action: 'archive',
      options: { data: { reject_options: rejectOptions } },
    });
    this.store.pushPayload(response);
  }

  async unlist() {
    const response = await this.server.memberAction(this, { action: 'unlist' });
    this.store.pushPayload(response);
  }

  async createTrackableLink() {
    const response = await this.server.memberAction(this, {
      action: 'find_or_create_employee_share_link',
      method: 'POST',
    });
    this.store.pushPayload('promotion', response);
    return this.store.peekRecord('promotion', response.promotion.id);
  }

  async refreshJobApplications() {
    this.stages.forEach(async (stage) => {
      await stage.refreshCounts();
      stage.jobApplications.forEach(async (ja) => {
        await ja.reload();
      });
    });
  }
}
