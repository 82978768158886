import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class Partner extends Model {
  @service intl;
  @service current;
  @service server;

  @hasMany('partner-activation', { async: true }) partnerActivations;
  @belongsTo('picked-video') pickedVideo;

  @attr('raw') images;
  @attr('string') internalName;
  @attr('string') video;
  @attr('string') baseUrl;
  @attr('boolean') inMarketplace;
  @attr('string') name;
  @attr('boolean') webhook;
  @attr('string', {
    defaultValue: 'webhook',
  })
  webhookNoun;

  @attr('string') webhookVerb;
  @attr('string') logotype;
  @attr('carrierwave-cache') logotypeCache;
  @attr('string') logotypeSquare;
  @attr('carrierwave-cache') logotypeSquareCache;
  @attr('string') logotypeWhite;
  @attr('carrierwave-cache') logotypeWhiteCache;
  @attr('string') description;
  @attr('string') category;
  @attr('string') website;
  @attr('string') pitch;
  @attr('boolean') capabilityAssessments;
  @attr('boolean') capabilityCandidateManual;
  @attr('boolean') capabilityCandidateResults;
  @attr('boolean') capabilityTrigger;
  @attr('string') apiKeyScope;
  @attr('string', {
    defaultValue: 'contact_support',
  })
  activationType;

  @attr('string') email;
  @attr('string') supportContact;
  @attr('string') integrationDescription;
  @attr('string') supportArticleUrl;
  @attr('boolean') createdByTeamtailor;
  @attr('raw') webhookActivationConfig;
  @attr('raw') countries;

  get activatedAt() {
    return get(this, 'partnerActivations.firstObject.createdAt');
  }

  get isActivated() {
    return !!this.activatedAt;
  }

  get partnerActivation() {
    return get(this, 'partnerActivations').find((partnerActivation) => {
      return (
        get(partnerActivation, 'company.uuid') ===
        get(this, 'current.company.uuid')
      );
    });
  }

  async getCategories() {
    return this.server.collectionAction({
      modelName: Partner.modelName,
      action: 'categories',
      method: 'GET',
    });
  }

  get categoryHumanName() {
    const category = get(this, 'category');
    switch (category) {
      case 'assessment':
        return this.intl.t('models.partner.assessment');
      case 'e_signing':
        return 'E-Signing';
      case 'hr_system':
        return this.intl.t('models.partner.hr_system');
      case 'referrals':
        return this.intl.t('models.partner.referrals');
      case 'candidate_experience':
        return this.intl.t('models.partner.candidate_experience');
      case 'reference_checking':
        return this.intl.t('models.partner.references');
      case 'recommendations':
        return this.intl.t('models.partner.recommendation');
      case 'background_checks':
        return this.intl.t('models.partner.background_checks');
      case 'video_interviews':
        return this.intl.t('models.partner.video_interviews');
      case 'employer_branding':
        return this.intl.t('models.partner.employer_branding');
      case 'crm':
        return this.intl.t('models.partner.crm');
      case 'onboarding':
        return this.intl.t('models.partner.onboarding');
      case 'other':
        return this.intl.t('models.partner.other');
      case 'chatbot':
        return this.intl.t('models.partner.chatbot');
      case 'interviews':
        return this.intl.t('models.partner.interviews');
      case 'sourcing':
        return this.intl.t('models.partner.sourcing');
      case 'remote_hiring':
        return this.intl.t('models.partner.remote_hiring');
      default:
        throw new Error(`Unknown partner category ${category}`);
    }
  }

  get isAssessment() {
    return get(this, 'category') === 'assessment';
  }

  get slug() {
    return get(this, 'name').toLowerCase().replace(/ /g, '-');
  }

  get shouldListInMarketplace() {
    return this.inMarketplace || this.partnerActivation;
  }
}
