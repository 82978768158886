import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  ...attributes\n  local-class={{class-names \"root\" root--fadeLeft=this.showLeftFade}}\n  {{did-insert this.handleInsertScrollElement}}\n>\n  {{#each @filters as |filter index|}}\n    {{#if (eq (inc index) @filters.length)}}\n      <div local-class=\"archivedFilterSeparator\"></div>\n    {{/if}}\n    <Toolbox::Messages::Filter::Button\n      @isSelected={{eq filter.value @selectedFilter}}\n      @translationKey={{filter.translationKey}}\n      @query={{concat-comma-separated\n        \"messages\"\n        @filter\n        filter.value\n        @conversationId\n      }}\n      {{did-insert this.handleFilter (eq filter.value @selectedFilter)}}\n      {{did-update this.handleFilter (eq filter.value @selectedFilter)}}\n    />\n  {{/each}}\n</div>", {"contents":"<div\n  ...attributes\n  local-class={{class-names \"root\" root--fadeLeft=this.showLeftFade}}\n  {{did-insert this.handleInsertScrollElement}}\n>\n  {{#each @filters as |filter index|}}\n    {{#if (eq (inc index) @filters.length)}}\n      <div local-class=\"archivedFilterSeparator\"></div>\n    {{/if}}\n    <Toolbox::Messages::Filter::Button\n      @isSelected={{eq filter.value @selectedFilter}}\n      @translationKey={{filter.translationKey}}\n      @query={{concat-comma-separated\n        \"messages\"\n        @filter\n        filter.value\n        @conversationId\n      }}\n      {{did-insert this.handleFilter (eq filter.value @selectedFilter)}}\n      {{did-update this.handleFilter (eq filter.value @selectedFilter)}}\n    />\n  {{/each}}\n</div>","moduleName":"teamtailor/components/toolbox/messages/filter/list.hbs","parseOptions":{"srcName":"teamtailor/components/toolbox/messages/filter/list.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class FilterButtonsList extends Component {
  @tracked showLeftFade = false;

  scrollElement = null;
  scrollLeftMax = null;

  handleScroll = () => {
    if (this.scrollElement.scrollLeft && !this.showLeftFade) {
      this.showLeftFade = true;
    } else if (!this.scrollElement.scrollLeft && this.showLeftFade) {
      this.showLeftFade = false;
    }
  };

  @action
  handleInsertScrollElement(element) {
    this.scrollElement = element;
    this.scrollLeftMax = element.scrollWidth - element.offsetWidth;
    element.addEventListener('scroll', this.handleScroll);
  }

  @action
  handleFilter(e, [isSelected]) {
    if (isSelected) {
      e.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }
}
