import md5 from 'blueimp-md5';
import { capitalize } from '@ember/string';
import JSBI from 'jsbi';
import ANIMALS from './anonymals/animals';
import COLORS from './anonymals/colors';

const SORTED_ANIMALS = ANIMALS.sort((a, b) =>
  a.length === b.length ? a.localeCompare(b) : a.length - b.length
);
const SORTED_COLORS = Object.keys(COLORS).sort((a, b) =>
  a.length === b.length ? a.localeCompare(b) : a.length - b.length
);
const MULTIPLY = JSBI.BigInt('36413321723440003717');
const TOTAL_COMBINATIONS = JSBI.BigInt(ANIMALS.length * SORTED_COLORS.length);

const getHash = (seed) => {
  let hash = md5(seed);
  let bigint = JSBI.BigInt(`0x${hash}`);
  return JSBI.multiply(bigint, MULTIPLY);
};

const fromSeed = (seed) => {
  if (!seed) {
    return {};
  }

  let hash = getHash(seed);
  let index = JSBI.toNumber(JSBI.remainder(hash, TOTAL_COMBINATIONS));
  let animal = SORTED_ANIMALS[index % SORTED_ANIMALS.length];
  index = Math.floor(index / SORTED_ANIMALS.length);
  let colorName = SORTED_COLORS[index % SORTED_COLORS.length];
  let colorHex = COLORS[colorName];

  return {
    animal,
    colorName,
    colorHex,
    name: `${capitalize(colorName)} ${capitalize(animal)}`,
  };
};

const getAnimal = (seed) => {
  return fromSeed(seed).animal;
};

const getName = (seed) => {
  return fromSeed(seed).name;
};

const getHexColor = (seed) => {
  return fromSeed(seed).colorHex;
};

export default {
  getName,
  getAnimal,
  fromSeed,
  getHexColor,
};
