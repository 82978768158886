import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#each this.colors as |color|}}\n  <div class=\"flex flex-col gap-6\">\n    <span\n      class=\"h-40 w-60 rounded\"\n      style={{html-safe (concat \"background-color:\" color.hex)}}\n    ></span>\n    <div class=\"flex flex-col px-2\">\n      <span class=\"font-medium\">{{color.weight}}</span>\n      <span class=\"font-mono lowercase\">{{color.hex}}</span>\n    </div>\n  </div>\n{{/each}}", {"contents":"{{#each this.colors as |color|}}\n  <div class=\"flex flex-col gap-6\">\n    <span\n      class=\"h-40 w-60 rounded\"\n      style={{html-safe (concat \"background-color:\" color.hex)}}\n    ></span>\n    <div class=\"flex flex-col px-2\">\n      <span class=\"font-medium\">{{color.weight}}</span>\n      <span class=\"font-mono lowercase\">{{color.hex}}</span>\n    </div>\n  </div>\n{{/each}}","moduleName":"teamtailor/components/unicorn/design-tokens/spectrum.hbs","parseOptions":{"srcName":"teamtailor/components/unicorn/design-tokens/spectrum.hbs"}});
import Component from '@glimmer/component';
import { tokenColors } from '@teamtailor/design-tokens';

interface UnicornSpectrumArgs {
  color: keyof typeof tokenColors;
}

export default class UnicornSpectrum extends Component<UnicornSpectrumArgs> {
  get colors() {
    const weight = tokenColors[this.args.color];
    if (typeof weight === 'string') {
      return [{ hex: weight, weight: '500' }];
    }

    const colors = [];
    for (const w in weight) {
      colors.push({ hex: (weight as Record<string, string>)[w], weight: w });
    }

    return colors;
  }
}
