import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Modal @isOpen={{@isOpen}} @onClose={{@onClose}} @size=\"small\" as |modal|>\n  <modal.Header @text={{t \"candidates.candidate.bulk_actions.add_to_job\"}} />\n  <modal.Main as |main|>\n    <main.Section>\n      <FormLabel @label={{t \"job.title\"}}>\n        <JobPicker\n          @selected={{this.assignToJob}}\n          @onSelect={{fn (mut this.assignToJob)}}\n          @size=\"large\"\n          @isFullWidth=\"true\"\n        />\n      </FormLabel>\n    </main.Section>\n  </modal.Main>\n  <modal.Footer>\n    <StatusButton\n      @isDisabled={{not this.assignToJob}}\n      @action={{this.assignCandidates}}\n      @text={{t \"candidates.candidate.bulk_actions.add\"}}\n    />\n  </modal.Footer>\n</Core::Modal>", {"contents":"<Core::Modal @isOpen={{@isOpen}} @onClose={{@onClose}} @size=\"small\" as |modal|>\n  <modal.Header @text={{t \"candidates.candidate.bulk_actions.add_to_job\"}} />\n  <modal.Main as |main|>\n    <main.Section>\n      <FormLabel @label={{t \"job.title\"}}>\n        <JobPicker\n          @selected={{this.assignToJob}}\n          @onSelect={{fn (mut this.assignToJob)}}\n          @size=\"large\"\n          @isFullWidth=\"true\"\n        />\n      </FormLabel>\n    </main.Section>\n  </modal.Main>\n  <modal.Footer>\n    <StatusButton\n      @isDisabled={{not this.assignToJob}}\n      @action={{this.assignCandidates}}\n      @text={{t \"candidates.candidate.bulk_actions.add\"}}\n    />\n  </modal.Footer>\n</Core::Modal>","moduleName":"teamtailor/components/bulk-actions/candidates/assign.hbs","parseOptions":{"srcName":"teamtailor/components/bulk-actions/candidates/assign.hbs"}});
import BaseComponent from './base';
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AssignComponent extends BaseComponent {
  @tracked assignToJob;

  @action
  assignCandidates() {
    let promise = this.postAction(
      'bulk_assign',
      this.postData({ job_id: get(this, 'assignToJob.id') })
    );

    promise.then(() => {
      this.store.peekAll('candidate').forEach((candidate) => {
        if (candidate.hasMany('activities').value() !== null) {
          get(candidate, 'jobApplications').reload();
        } else {
          candidate.reload();
        }
      });
    });

    this.args.onClose();

    get(this, 'batchJob.batchJobStartedTask').perform(
      promise,
      this.args.selectedCount
    );
  }
}
