import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"question\">\n  <Icon @icon={{this.questionIcon}} class=\"mr-8\" />\n  <span class=\"grow-1\">{{this.question.title}}</span>\n  {{#if this.canDestroy}}\n    <button\n      type=\"button\"\n      local-class=\"remove\"\n      {{on \"click\" this.handleDestroy}}\n    ><Icon @icon=\"xmark\" /></button>\n  {{/if}}\n</div>", {"contents":"<div local-class=\"question\">\n  <Icon @icon={{this.questionIcon}} class=\"mr-8\" />\n  <span class=\"grow-1\">{{this.question.title}}</span>\n  {{#if this.canDestroy}}\n    <button\n      type=\"button\"\n      local-class=\"remove\"\n      {{on \"click\" this.handleDestroy}}\n    ><Icon @icon=\"xmark\" /></button>\n  {{/if}}\n</div>","moduleName":"teamtailor/components/prepared-message/questions.hbs","parseOptions":{"srcName":"teamtailor/components/prepared-message/questions.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { IQuestion } from 'teamtailor/components/meeting/types';

interface IPreparedMessageQuestionArgs {
  question: IQuestion;
  removeQuestion?(question: IQuestion): void;
}

export default class PreparedMessageQuestionComponent extends Component<IPreparedMessageQuestionArgs> {
  get canDestroy(): boolean {
    return 'removeQuestion' in this.args;
  }

  get question(): IQuestion {
    return this.args.question;
  }

  get questionIcon(): string {
    switch (this.question.type) {
      case 'Question::Boolean':
        return 'circle-check';
      case 'Question::Choice':
        return 'ballot-check';
      case 'Question::Range':
        return 'sliders';
      case 'Question::Text':
        return 'font';
      case 'Question::Video':
        return 'video';
      case 'Question::File':
        return 'file';
      default:
        return 'font';
    }
  }

  @action
  handleDestroy(): void {
    if (this.args.removeQuestion) {
      this.args.removeQuestion(this.args.question);
    }
  }
}
