import { underscore } from '@ember/string';

export interface JobsEditNavigationItem {
  route: string;
  icon: string;
  navItemKey: string;
  nextItemKey: string;
}

const JOBS_EDIT_MENU_ITEMS: { [k: string]: JobsEditNavigationItem } = {
  requisition: item('requisition', 'clipboard-list'),
  posting: item('posting', 'font', 'job_posting'),
  'application-form': item('application-form', 'file-lines', 'application'),
  'chat-widget': item(
    'chat-widget',
    'comments',
    'candidate_interaction',
    'jobs.edit.chat_widget.title'
  ),

  stages: item('stages', 'columns-3'),
  evaluation: item('evaluation', 'clipboard-list-check'),
  'hiring-team': item('hiring-team', 'users'),
  'external-recruiters': item('external-recruiters', 'house-person-return'),
  scorecard: item('scorecard', 'thumbs-up', 'interview_scorecard'),
  template: item(
    'template',
    'circle-info',
    'template_instructions',
    'job.template'
  ),
};

function item(
  route: string,
  icon: string,
  navItemKey: null | string = null,
  nextItemKey: null | string = null
): JobsEditNavigationItem {
  navItemKey = `components.job.${navItemKey ?? underscore(route)}`;
  nextItemKey = nextItemKey || navItemKey;
  return {
    route,
    icon,
    navItemKey,
    nextItemKey,
  };
}

export function getMenuItem(
  key: string,
  shouldDisplay = true
): JobsEditNavigationItem | undefined {
  return shouldDisplay ? JOBS_EDIT_MENU_ITEMS[key] : undefined;
}
