import classic from 'ember-classic-decorator';
import { sort } from '@ember/object/computed';
import Controller from '@ember/controller';
import { get, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';

@classic
export default class QuestionsController extends Controller {
  search = null;

  @computed('search', 'model.@each.title')
  get filteredQuestions() {
    if (isEmpty(get(this, 'search'))) {
      return get(this, 'model');
    } else {
      let search = get(this, 'search').toLowerCase();
      return get(this, 'model').filter((question) => {
        return get(question, 'title').toLowerCase().indexOf(search) > -1;
      });
    }
  }

  sorting = ['title'];

  @sort('filteredQuestions', 'sorting')
  sortedQuestions;
}
