import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { get, set } from '@ember/object';
import { task } from 'ember-concurrency';
import { reject } from 'rsvp';
import ENV from 'teamtailor/config/environment';

export default class DomainController extends Controller {
  @service store;
  @service intl;
  @service flashMessages;

  isEditing = false;
  extHost = ENV.EXT_HOST;

  get saveButtonText() {
    if (this.saveDomain.isRunning) {
      return this.intl.t('settings.domain.saving');
    } else if (this.model.inProgress) {
      return this.intl.t('settings.domain.processing');
    } else if (this.model.hasFailed) {
      return this.intl.t('settings.domain.try_again');
    } else {
      return this.intl.t('common.save');
    }
  }

  saveDomain = task(
    {
      drop: true,
    },
    async () => {
      set(this, 'isEditing', false);

      set(this, 'model.redirectToCname', false);
      set(this, 'model.enabled', false);
      set(this, 'model.status', 'setup_cname');
      set(this, 'model.statusNumber', 0);

      await get(this, 'model')
        .save()
        .catch(() => {
          get(this, 'flashMessages').error(
            this.intl.t('settings.domain.error_message')
          );
          return reject();
        });
    }
  );
}
