import Service from '@ember/service';
import { cancel, later } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';

export default class TickService extends Service {
  @tracked currentTime = null;
  intervalSeconds = 60;

  constructor() {
    super(...arguments);
    this.tick();
  }

  tick() {
    this.currentTime = new Date();
    this.scheduledTick = later(this, this.tick, this.intervalSeconds * 1000);
  }

  willDestroy() {
    cancel(this.scheduledTick);
  }
}
