import Route from '@ember/routing/route';
import { get, set } from '@ember/object';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';

export default class SettingsRoute extends Route {
  @service current;
  @service store;

  model() {
    return new RSVP.Promise((resolve) => {
      get(this.current.company, 'blog')
        .then(resolve)
        .catch(() => {
          let blog = this.store.createRecord('blog', {
            company: this.current.company,
          });
          set(this.current.company, 'blog', blog);
          resolve(blog);
        });
    });
  }
}
