import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"c-question-filter\">\n  <div class=\"c-question-filter__title\">\n    {{this.question.title}}\n  </div>\n  <button\n    type=\"button\"\n    class=\"c-question-filter__close\"\n    title={{t \"questions.filters.remove_question\"}}\n    {{on \"click\" this.remove}}\n  >\n    <Icon @icon=\"xmark\" @size=\"16\" />\n  </button>\n  {{#if this.question.type}}\n    {{component\n      this.componentName\n      filter=this.filter\n      question=this.question\n      updateFilter=this.updateFilter\n    }}\n  {{/if}}\n</div>", {"contents":"<div class=\"c-question-filter\">\n  <div class=\"c-question-filter__title\">\n    {{this.question.title}}\n  </div>\n  <button\n    type=\"button\"\n    class=\"c-question-filter__close\"\n    title={{t \"questions.filters.remove_question\"}}\n    {{on \"click\" this.remove}}\n  >\n    <Icon @icon=\"xmark\" @size=\"16\" />\n  </button>\n  {{#if this.question.type}}\n    {{component\n      this.componentName\n      filter=this.filter\n      question=this.question\n      updateFilter=this.updateFilter\n    }}\n  {{/if}}\n</div>","moduleName":"teamtailor/components/question-filter.hbs","parseOptions":{"srcName":"teamtailor/components/question-filter.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';

export default class QuestionFilterComponent extends Component {
  get filter() {
    return this.args.filter;
  }

  get job() {
    return this.args.job;
  }

  get questions() {
    return this.args.questions;
  }

  get filters() {
    return this.args.filters;
  }

  get isChoice() {
    return get(this, 'shortName') === 'choice';
  }

  get isBoolean() {
    return get(this, 'shortName') === 'boolean';
  }

  get isRange() {
    return get(this, 'shortName') === 'range';
  }

  get question() {
    return get(this, 'questions').findBy('id', get(this, 'filter.question_id'));
  }

  get shortName() {
    if (get(this, 'question.type')) {
      return get(this, 'question.type').substring(10).toLowerCase();
    }
  }

  get componentName() {
    return `question-filter-${get(this, 'shortName')}`;
  }

  @action
  updateFilter(data) {
    get(this, 'filters').removeObject(get(this, 'filter'));
    get(this, 'filters').pushObject(
      Object.assign(get(this, 'filter'), { ...data })
    );
  }

  @action
  remove() {
    if (this.args.onRemove) {
      this.args.onRemove();
    } else {
      get(this, 'filters').removeObject(get(this, 'filter'));
    }
  }
}
