import { hash } from 'rsvp';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class EnterpriseCalendarRoute extends ScrollToTopRoute {
  @service pusher;
  @service user;
  @service store;
  @service current;
  @service ttAlert;
  @service intl;
  @service router;

  channel = null;

  queryParams = {
    query: { refreshModel: true },
    page: { refreshModel: true },
    sortColumn: { refreshModel: true },
    sortDirection: { refreshModel: true },
  };

  get company() {
    return this.current.company;
  }

  beforeModel() {
    if (!this.company.hasFeature('enterprise_calendar')) {
      this.ttAlert.error(this.intl.t('errors.dont_have_access_to_page'));
      this.router.transitionTo('settings.general');
    }
  }

  model({ sortColumn, sortDirection, query, page }) {
    return hash({
      users: this.store.query('user', {
        sort_column: sortColumn,
        sort_direction: sortDirection,
        query,
        page,
        allowed_to_login: true,
      }),

      authorizations: this.store.findAll('authorization', { reload: true }),
    });
  }

  afterModel() {
    this.setupPusher();
  }

  async setupPusher() {
    try {
      this.channel = await this.pusher.subscribe(
        get(this, 'user.notificationsChannel')
      );
    } catch {
      return;
    }

    this.channel.bind(
      'enterprise-connect-authorization',
      async (authorization) => {
        this.store.pushPayload(authorization);
      }
    );
    this.channel.bind(
      'enterprise-connect-authorization-error',
      async (error) => {
        this.controllerFor(
          'settings.enterprise-calendar'
        ).enterpriseConnectAuthorizationError(error);
      }
    );
  }

  deactivate() {
    if (this.channel) {
      this.channel.unbind('enterprise-connect-authorization');
      this.channel.unbind('enterprise-connect-authorization-error');
    }
  }
}
