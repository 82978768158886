import Route from '@ember/routing/route';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import templates from 'teamtailor/utils/survey-templates';

export default class NewSurveyRoute extends Route {
  @service store;
  @service server;
  @service intl;

  async beforeModel() {
    const adapter = this.store.adapterFor('application');
    const url = `${adapter.buildURL('surveys')}/get_email_default_values`;

    this.emailValues = await this.server.request(url, 'GET');
  }

  model(queryParams = {}) {
    const { template: templateId } = queryParams;

    let template = templateId && templates.find((t) => t.id === templateId);
    const formType =
      templateId && templateId.match(/eeo-(.)*/) ? 'eeo' : 'survey';

    return this.store.createRecord('survey', {
      name: template && template.name,
      introText: template && template.description,
      formQuestionsRaw: template && template.form,
      outroTitle: this.intl.t('surveys.heading_text'),
      outroText: this.intl.t('surveys.thank_you_message_text'),
      formType,
      options: {
        displayAnswersOnCandidatePage: false,
        ...this.emailValues,
      },
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    set(controller, 'fields', model.formQuestionsRaw);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      set(controller, 'template', null);
      set(controller, 'fields', []);

      if (controller.model.isNew) {
        controller.model.destroyRecord();
      }
    }
  }
}
