import Controller from '@ember/controller';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SettingsExternalRecruitmentRecruitersEditController extends Controller {
  @service flashMessages;
  @service intl;
  @service router;

  @action
  handleModalClose() {
    this.router.transitionTo('settings.external-recruitment.recruiters');
  }

  @action
  async handleSaveExternalRecruiter(model) {
    await model.validate();
    if (get(model, 'isValid')) {
      if (get(model, 'recruitingFirm.isNew')) {
        await get(model, 'recruitingFirm').save();
      }

      await model.save();
      this.router.transitionTo('settings.external-recruitment.recruiters');
    } else {
      throw new Error('Validation failed');
    }
  }
}
