import Transform from '@ember-data/serializer/transform';

export default class CarrierWaveCacheTransform extends Transform {
  deserialize() {
    return null;
  }

  serialize(deserialized?: string) {
    if (deserialized) {
      return deserialized.split('/tmp/').pop();
    } else {
      return null;
    }
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'carrierwave-cache': CarrierWaveCacheTransform;
  }
}
