import MeetingNewRoute from 'teamtailor/routes/meetings/new';

export default class JobsJobActivityCandidateMeetingNewRoute extends MeetingNewRoute {
  controllerName = 'meetings.new';
  templateName = 'meetings.new';

  meetingRouteRouteName =
    'nurture-campaigns.nurture-campaign.recipients.candidate.meetings';

  candidateRouteRouteName =
    'nurture-campaigns.nurture-campaign.recipients.candidate';

  jobRouteRouteName = '';
}
