import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { setProperties } from '@ember/object';
import JobsJobCompareCandidates from 'teamtailor/controllers/jobs/job/compare-candidates';
import JobApplicationModel from 'teamtailor/models/job-application';
import { Transition } from 'teamtailor/utils/type-utils';

export default class JobsJobCompareCandidate extends Route {
  @service declare store: Store;

  queryParams = {
    ids: {
      refreshModel: true,
    },
  };

  private fetchApplicants(ids: string) {
    const applicants = this.store
      .peekAll('job-application')
      .filter((application) => {
        return ids.split(',').includes(application.id);
      });

    if (!applicants.length) {
      return this.store.query('job-application', {
        rejected: false,
        ids: ids.split(','),
      });
    }

    return applicants;
  }

  model(params: { ids?: string; stage_id?: string }) {
    if (params.ids) {
      return this.fetchApplicants(params.ids);
    }

    return [];
  }

  async setupController(
    controller: JobsJobCompareCandidates,
    model: JobApplicationModel[],
    transition: Transition
  ): Promise<void> {
    super.setupController(controller, model, transition);
    const job = await model.firstObject?.job;
    if (job) {
      controller.job = job;
    }
  }

  resetController(controller: JobsJobCompareCandidates, isExiting: boolean) {
    if (isExiting) {
      setProperties(controller, {
        hiddenCandidates: [],
        showingHiddenCandidates: false,
        isBulkSelectActive: false,
        pinnedModels: [],
        revealedAnonymousJobApplications: [],
        selectedJobApplications: [],
      });
    }
  }
}
