import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"body-text-s\">\n  <EmberTooltip @text={{moment-from-now @createdAt}} />\n  <span>\n    {{#if this.createdThisYear}}\n      {{moment-format @createdAt \"DD MMM\"}}\n    {{else}}\n      {{moment-format @createdAt \"DD MMM ’YY\"}}\n    {{/if}}\n  </span>\n</div>", {"contents":"<div class=\"body-text-s\">\n  <EmberTooltip @text={{moment-from-now @createdAt}} />\n  <span>\n    {{#if this.createdThisYear}}\n      {{moment-format @createdAt \"DD MMM\"}}\n    {{else}}\n      {{moment-format @createdAt \"DD MMM ’YY\"}}\n    {{/if}}\n  </span>\n</div>","moduleName":"teamtailor/components/jobs/created-at.hbs","parseOptions":{"srcName":"teamtailor/components/jobs/created-at.hbs"}});
import Component from '@glimmer/component';

type JobsCreatedAtArgs = {
  createdAt: Date;
};

export default class JobsCreatedAtComponent extends Component<JobsCreatedAtArgs> {
  get createdThisYear(): boolean {
    return this.args.createdAt.getFullYear() === new Date().getFullYear();
  }
}
