import Model, { belongsTo } from '@ember-data/model';

import AssetStructureModel from 'teamtailor/models/asset-structure';

export default class OrganizableModel extends Model {
  @belongsTo('asset-structure', { async: false })
  declare assetStructure: AssetStructureModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    organizable: OrganizableModel;
  }
}
