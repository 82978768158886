import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import IntlService from 'ember-intl/services/intl';
import RouterService from '@ember/routing/router-service';
import FlashMessageService from 'teamtailor/services/flash-message';

export default class SettingsPolicyController extends Controller {
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;

  @action
  async handleSave(): Promise<void> {
    await this.model.save();

    try {
      this.router.transitionTo('settings.policy.index');
      later(() => {
        this.flashMessages.success(
          this.intl.t('settings.privacy_policy.success_message')
        );
      }, 500);
    } catch (e) {
      this.flashMessages.error(
        this.intl.t('settings.privacy_policy.error_message')
      );
    }
  }

  @action
  handleCancel(): void {
    this.model.rollbackAttributes();
    this.router.transitionTo('settings.policy.index');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.policy.edit': SettingsPolicyController;
  }
}
