export default {
  "wrapper": "Index__wrapper_4abzb3",
  "option": "Index__option_4abzb3",
  "radio": "Index__radio_4abzb3",
  "input": "Index__input_4abzb3",
  "select": "Index__select_4abzb3",
  "other": "Index__other_4abzb3",
  "addOptionContainer": "Index__addOptionContainer_4abzb3",
  "new": "Index__new_4abzb3"
};
