import { inject as service } from '@ember/service';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { set, setProperties } from '@ember/object';
import { isPresent } from '@ember/utils';
import { get } from 'teamtailor/utils/get';

const ALLOWED_ROUTES = ['employees.new.index', 'employees.new.permissions'];

export default class NewRoute extends ScrollToTopRoute {
  @service current;
  @service intl;
  @service permissions;
  @service store;
  @service accessLevels;
  @service flipper;
  @service router;

  async model() {
    await this.accessLevels.loadAll();

    const { company } = this.current;

    const defaultRole = this.permissions.has('user/create', 'conditional')
      ? 'user'
      : 'no_access';

    let user = this.store.createRecord('user', {
      company,
      role: defaultRole,

      accessLevelIds: this.accessLevels.accessLevelIdsWithDefault(defaultRole),
    });

    if (company.hasFeature('career_site_languages')) {
      set(user, 'languageCode', company.defaultCareerSite?.languageCode);
    }

    return get(this.current.company, 'userTemplates').then((userTemplates) => {
      const userTemplate = get(userTemplates, 'firstObject');
      if (isPresent(userTemplate)) {
        const addonsList = ['designer'];
        let templateRole = null;
        let templateAddons = null;

        templateRole = get(userTemplate, 'accessLevels').find((accessLevel) => {
          return !addonsList.includes(accessLevel);
        });

        templateAddons = get(userTemplate, 'accessLevels').filter(
          (accessLevel) => {
            return addonsList.includes(accessLevel);
          }
        );

        setProperties(user, {
          role: templateRole,
          accessLevelIds: this.accessLevels.accessLevelIdsWithDefault(
            templateRole,
            templateAddons
          ),

          visible: get(userTemplate, 'visible'),
          hideEmail: get(userTemplate, 'hideEmail'),
          hidePhone: get(userTemplate, 'hidePhone'),
          signature: get(userTemplate, 'signature'),
          locale: get(userTemplate, 'locale'),
          timeFormat: get(userTemplate, 'timeFormat'),
          timeZone: get(userTemplate, 'timeZone'),
          startOfWeekDay: get(userTemplate, 'startOfWeekDay'),
        });
      }

      return user;
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    get(this.current.company, 'userTemplates').then((userTemplates) => {
      const userTemplate = get(userTemplates, 'firstObject');
      if (isPresent(userTemplate)) {
        const addonsList = ['designer'];
        const templateAddons = get(userTemplate, 'accessLevels').filter(
          (accessLevel) => {
            return addonsList.includes(accessLevel);
          }
        );

        set(controller, 'selectedAddons', templateAddons);
      }
    });
  }

  activate() {
    this.router.on('routeWillChange', this.onRouterWillChange);
  }

  deactivate() {
    this.router.off('routeWillChange', this.onRouterWillChange);
  }

  onRouterWillChange = (transition) => {
    if (!transition.to.find((route) => route.name === this.routeName)) {
      if (ALLOWED_ROUTES.includes(transition.to.name)) {
        return;
      }

      let controller = get(this, 'controller');
      if (
        !get(controller, 'isSaving') &&
        get(controller, 'model.isNew') &&
        !confirm(this.intl.t('common.unsaved_changes_confirm'))
      ) {
        get(controller, 'model').destroyRecord();
        transition.abort();
      }
    }
  };
}
