import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex h-full flex-1 flex-col pb-16 pt-10\">\n  {{#if this.isLoading}}\n    <LoadingSpinner />\n  {{else}}\n    {{#if (is-empty this.channels)}}\n      <EmptyState\n        @header={{t \"global_comments.read_only.title\"}}\n        @emoji=\"🚜\"\n        @color=\"yellow\"\n      >\n        {{t \"global_comments.read_only.description\"}}\n      </EmptyState>\n    {{else}}\n      {{#each this.channels as |channel|}}\n        <Toolbox::GlobalComments::Pinned::Channel\n          @channel={{channel}}\n          @activitiesIds={{this.channelActivities channel.id}}\n        />\n      {{/each}}\n    {{/if}}\n  {{/if}}\n</div>", {"contents":"<div class=\"flex h-full flex-1 flex-col pb-16 pt-10\">\n  {{#if this.isLoading}}\n    <LoadingSpinner />\n  {{else}}\n    {{#if (is-empty this.channels)}}\n      <EmptyState\n        @header={{t \"global_comments.read_only.title\"}}\n        @emoji=\"🚜\"\n        @color=\"yellow\"\n      >\n        {{t \"global_comments.read_only.description\"}}\n      </EmptyState>\n    {{else}}\n      {{#each this.channels as |channel|}}\n        <Toolbox::GlobalComments::Pinned::Channel\n          @channel={{channel}}\n          @activitiesIds={{this.channelActivities channel.id}}\n        />\n      {{/each}}\n    {{/if}}\n  {{/if}}\n</div>","moduleName":"teamtailor/components/toolbox/global-comments/pinned/index.hbs","parseOptions":{"srcName":"teamtailor/components/toolbox/global-comments/pinned/index.hbs"}});
import Component from '@glimmer/component';
import { trackedFunction } from 'ember-resources/util/function';
import { ActivityModel } from 'teamtailor/models';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { action } from '@ember/object';

type Args = {
  activities: Record<string, ActivityModel[]> | null;
};

export default class GlobalCommentsPinned extends Component<Args> {
  @service declare store: Store;

  resolveChannelsResponse = trackedFunction(this, async () => {
    if (this.channelsIds.length === 0) return [];
    const sortedJobs = await this.store.query('job', {
      ids: this.channelsIds,
    });
    return sortedJobs;
  });

  get isLoading() {
    return this.args.activities === null;
  }

  get channelsIds() {
    if (this.args.activities) {
      return Object.keys(this.args.activities);
    } else {
      return [];
    }
  }

  get channels() {
    return this.resolveChannelsResponse.value || [];
  }

  @action
  channelActivities(id: string) {
    if (this.args.activities) {
      return this.args.activities[id];
    }

    return [];
  }
}
