import Route from '@ember/routing/route';
import recordRemover from 'teamtailor/utils/record-remover';
import { inject as service } from '@ember/service';

export default class EmployeeDashboardWidgetEditRoute extends Route {
  @service store;

  model(params) {
    const { dashboard_widget_id } = params;

    return this.store.findRecord('dashboard-widget', dashboard_widget_id);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      const { model } = controller;

      if (
        !model.isDestroyed &&
        !model.isDestroying &&
        model.hasDirtyAttributes
      ) {
        model.rollbackAttributes();
      }

      recordRemover(this.store, 'picked-dashboard-widget');
      recordRemover(this.store, 'dashboard-widget');
    }
  }
}
