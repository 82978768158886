import {
  FilterOperator,
  FilterOption,
  FilterType,
  NestedOptions,
} from 'teamtailor/components/fancy-filters';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import EmberObject from '@ember/object';
import CustomFieldOptionModel from 'teamtailor/models/custom-field/option';

export default class BaseFilter extends EmberObject implements FilterOption {
  @service declare intl: IntlService;

  translationPrefix?: string;

  name = '';
  category?: string;
  color?: string;
  icon?: string;
  triggerIconStyle?: string;
  iconStyle?: string;
  type: FilterType = 'string';
  operators: FilterOperator[] = [];
  options?: CustomFieldOptionModel[];
  nested?: NestedOptions;
  doNotTranslateName = false;

  _translatedName?: string;
  get translatedName(): string {
    if (this.doNotTranslateName) {
      return this._translatedName || this.name;
    }

    let keyName = this.name;
    if (this.nested?.name) {
      keyName = `${this.nested.name}.${keyName}`;
    }

    return this.intl.t(`${this.translationPrefix}.${keyName}`);
  }

  set translatedName(value: string) {
    this._translatedName = value;
  }
}
