import Service from '@ember/service';
import { TrackedMap } from 'tracked-built-ins';

export default class FilthyContent extends Service {
  filthySections = new TrackedMap<string, boolean>();

  isFilthy(sectionId?: string) {
    // eslint-disable-next-line ember/use-ember-get-and-set
    return this.filthySections.get(sectionId!);
  }

  setFilthy(sectionId: string) {
    // eslint-disable-next-line ember/use-ember-get-and-set
    this.filthySections.set(sectionId, true);
  }

  setClean(sectionId: string) {
    // eslint-disable-next-line ember/use-ember-get-and-set
    this.filthySections.set(sectionId, false);
  }
}

declare module '@ember/service' {
  interface Registry {
    'filthy-content': FilthyContent;
  }
}
