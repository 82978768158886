import Route from '@ember/routing/route';
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';

import PipelineSpeedReportFetcher from 'teamtailor/classes/analytics/report-pipeline-speed';
import AnalyticsService from 'teamtailor/services/analytics';

export default class SpeedRoute extends Route {
  @service declare analytics: AnalyticsService;

  model() {
    return {
      speed: new PipelineSpeedReportFetcher({ container: getOwner(this) })
        .fetchTask,
    };
  }
}
