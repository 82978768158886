import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { setProperties } from '@ember/object';
import { Promise } from 'rsvp';
import ENV from 'teamtailor/config/environment';

const INVITATIONS_ENDPOINT = `https://tt.${ENV.HTTP_HOST}/invitations`;

export default class InvitationsRoute extends Route {
  @service flashMessages;
  @service router;
  @service translations;
  @service server;

  async beforeModel() {
    return this.translations.setLocale('en');
  }

  model(params) {
    return Promise.all([
      import('zxcvbn').then((module) => module.default),
      this.server
        .request(`${INVITATIONS_ENDPOINT}/${params.invitation_id}`)
        .then((response) => {
          if (response.ssoLoginUrl) {
            window.location.href = response.ssoLoginUrl;
            // Never resolve to show loader until location is changed
            return new Promise(() => {});
          }

          return response;
        })
        .catch(() => {
          this.flashMessages.error('Your invitation code is invalid.');
          this.router.transitionTo('login');
        }),
    ]);
  }

  setupController(
    controller,
    [
      zxcvbn,
      { id, company, currentLogin, loginCompanies, user, ssoRelatedCompany },
    ]
  ) {
    setProperties(controller, {
      zxcvbn,
      invitationId: id,
      company,
      currentLogin,
      loginCompanies,
      user,
      ssoRelatedCompany,
    });
  }

  sessionInvalidated() {} // Do nothing in here, redirects are handled in application route
}
