import Model, {
  attr,
  belongsTo,
  hasMany,
  type AsyncBelongsTo,
  type AsyncHasMany,
} from '@ember-data/model';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import {
  DepartmentModel,
  JobModel,
  LocationModel,
  RoleModel,
  UserModel,
} from 'teamtailor/models';
import Server from 'teamtailor/services/server';

export default class TeamModel extends Model {
  @service declare server: Server;

  @belongsTo('user', { inverse: 'managerTeams' })
  declare user: AsyncBelongsTo<UserModel>;

  @hasMany('department') declare departments: AsyncHasMany<DepartmentModel>;
  @hasMany('job') declare jobs: AsyncHasMany<JobModel>;
  @hasMany('location') declare locations: AsyncHasMany<LocationModel>;
  @hasMany('role') declare roles: AsyncHasMany<RoleModel>;
  @hasMany('user') declare users: AsyncHasMany<UserModel>;

  @attr('string', { defaultValue: '👍' }) declare emoji: string;
  @attr('string') declare name: string;
  @attr('string') declare username: string;

  @tracked count: number | null = null;

  async candidateCount() {
    const response = await this.server.memberAction<{ count: number }>(this, {
      action: 'candidate_count',
      method: 'GET',
    });

    this.count = response.count;
  }

  getCandidateCount = dropTask(async () => {
    await this.candidateCount();
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    team: TeamModel;
  }
}
