import Model, { attr } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { TaskInstance } from 'ember-concurrency';
import Raw from 'teamtailor/transforms/raw';

export default class UnsplashPhotoModel extends Model {
  @attr('number') declare width: number;
  @attr('number') declare height: number;
  @attr('string') declare color: string;
  @attr('string') declare authorLink: string;
  @attr('string') declare authorName: string;
  @attr('string') declare previewUrl: string;
  @attr('string') declare downloadLocation: string;
  @attr('string') declare alt: string;
  @attr('raw') declare meta: Raw;
  @attr('boolean') declare isUploading: boolean;

  @tracked tasks: TaskInstance<unknown>[] = [];

  get isPicked() {
    return !isEmpty(this.tasks);
  }

  get isRunning() {
    if (isEmpty(this.tasks)) {
      return false;
    }

    return this.tasks.isAny('isRunning', true);
  }

  get isFailed() {
    if (isEmpty(this.tasks)) {
      return false;
    }

    return this.tasks.isAny('isFailed', true);
  }

  get isSuccessful() {
    if (isEmpty(this.tasks)) {
      return false;
    }

    return this.tasks.isEvery('isSuccessful', true);
  }

  download() {
    const adapter = this.store.adapterFor('unsplash-photo');
    return adapter.download(this.downloadLocation);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'unsplash-photo': UnsplashPhotoModel;
  }
}
