export default {
  "root": "Options__root_14iv13",
  "dropdownTrigger": "Options__dropdownTrigger_14iv13",
  "dropdownTriggerActive": "Options__dropdownTriggerActive_14iv13",
  "dropdownItem": "Options__dropdownItem_14iv13",
  "dropdownItem--spaceBetween": "Options__dropdownItem--spaceBetween_14iv13",
  "icon": "Options__icon_14iv13",
  "switch": "Options__switch_14iv13",
  "borderTop": "Options__borderTop_14iv13"
};
