import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

export default class TemplatesController extends Controller {
  @service router;
  @service permissions;
  @service current;

  @tracked instantlyCreateJob = false;

  @action
  modalClosedAction() {
    return this.router.transitionTo('jobs.index');
  }

  loadTemplates = task(async () => {
    await get(this.current.company, 'templates');

    const canManageTemplates =
      this.permissions.has('job_template/update') === 'full';
    const templates = get(this.current.company, 'availableJobTemplates');

    if (!canManageTemplates && !templates.length) {
      this.instantlyCreateJob = true;
      get(this.current.company, 'defaultJobTemplate').copyJob();
    }
  });
}
