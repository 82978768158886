import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';

export default class ContentEditorGlobalDesignRoute extends Route {
  @service current;

  model() {
    return get(this.current.company, 'draftDesign');
  }
}
