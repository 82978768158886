import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isSingleCheckbox}}\n  <Core::Checkbox\n    @id={{@field.uuid}}\n    @label={{@field.label}}\n    @checked={{@value}}\n    @onChange={{fn this.onChange @field.uuid}}\n  />\n{{else}}\n  {{#each @field.options as |option|}}\n    <Core::Checkbox\n      @id={{option.id}}\n      @label={{option.label}}\n      @checked={{includes option.id this.selectedOptions}}\n      @onChange={{fn this.onChange option.id}}\n    />\n  {{/each}}\n{{/if}}", {"contents":"{{#if this.isSingleCheckbox}}\n  <Core::Checkbox\n    @id={{@field.uuid}}\n    @label={{@field.label}}\n    @checked={{@value}}\n    @onChange={{fn this.onChange @field.uuid}}\n  />\n{{else}}\n  {{#each @field.options as |option|}}\n    <Core::Checkbox\n      @id={{option.id}}\n      @label={{option.label}}\n      @checked={{includes option.id this.selectedOptions}}\n      @onChange={{fn this.onChange option.id}}\n    />\n  {{/each}}\n{{/if}}","moduleName":"teamtailor/components/form-builder/default-field/read/checkbox.hbs","parseOptions":{"srcName":"teamtailor/components/form-builder/default-field/read/checkbox.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class FormFieldReadCheckbox extends Component {
  @tracked selectedOptions = this.args.value || [];

  get isSingleCheckbox() {
    return !this.args.field.options?.length;
  }

  @action
  onChange(optionId, checked) {
    if (this.isSingleCheckbox) {
      this.args.onChange(checked);
      return;
    }

    if (checked) {
      this.selectedOptions = [...this.selectedOptions, optionId];
    } else {
      this.selectedOptions = this.selectedOptions.filter(
        (_optionId) => _optionId !== optionId
      );
    }

    this.args.onChange(this.selectedOptions);
  }
}
