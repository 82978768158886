import Model, { belongsTo, attr, type AsyncBelongsTo } from '@ember-data/model';
import CompanyModel from './company';

export default class PhoneNumberModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @attr('string') declare e164: string;
  @attr('string') declare country: string;
  @attr('string') declare national: string;
  @attr('string') declare international: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'phone-number': PhoneNumberModel;
  }
}
