import TriggerSerializer from 'teamtailor/serializers/trigger';
import TriggerHireQualitySurveyModel from 'teamtailor/models/trigger/hire-quality-survey';

export default class TriggerHireQualitySurveySerializer extends TriggerSerializer {
  serialize(snapshot: { record: TriggerHireQualitySurveyModel }) {
    const json = super.serialize(snapshot);
    const [commonJson, extras] = this.extractCommonJson(json);
    const { ...data } = extras;

    return Object.assign(commonJson, {
      id: commonJson?.id ?? snapshot.record.id,
      data,
    });
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'trigger/hire-quality-survey': TriggerHireQualitySurveySerializer;
  }
}
