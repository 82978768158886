import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Molecules::Widget\n  ...attributes\n  @title={{t \"insights.widgets.nps_responses.title\"}}\n  @showMoreLink={{@showMoreLink}}\n  @scrollable={{gt @data.length 0}}\n  @count={{@totalCount}}\n  @empty={{eq @data.length 0}}\n  @emptyEmoji=\"🦦\"\n  {{in-viewport onEnter=(perform this.fetchCandidates)}}\n>\n  <:content>\n    {{#if this.loading}}\n      <LoadingSpinner />\n    {{else}}\n      {{#each this.responses as |row|}}\n        <Insights::Molecules::NpsResponsesRow @data={{row}} />\n      {{/each}}\n    {{/if}}\n  </:content>\n</Insights::Molecules::Widget>", {"contents":"<Insights::Molecules::Widget\n  ...attributes\n  @title={{t \"insights.widgets.nps_responses.title\"}}\n  @showMoreLink={{@showMoreLink}}\n  @scrollable={{gt @data.length 0}}\n  @count={{@totalCount}}\n  @empty={{eq @data.length 0}}\n  @emptyEmoji=\"🦦\"\n  {{in-viewport onEnter=(perform this.fetchCandidates)}}\n>\n  <:content>\n    {{#if this.loading}}\n      <LoadingSpinner />\n    {{else}}\n      {{#each this.responses as |row|}}\n        <Insights::Molecules::NpsResponsesRow @data={{row}} />\n      {{/each}}\n    {{/if}}\n  </:content>\n</Insights::Molecules::Widget>","moduleName":"teamtailor/components/insights/widgets/nps-responses.hbs","parseOptions":{"srcName":"teamtailor/components/insights/widgets/nps-responses.hbs"}});
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { gql } from '@apollo/client/core';
import ApolloService from 'ember-apollo-client/services/apollo';
import { NpsRow } from 'teamtailor/classes/analytics/report-nps-overview';
import Current from 'teamtailor/services/current';
import AnalyticsService from 'teamtailor/services/analytics';

import { dropTask } from 'ember-concurrency';

type Args = {
  data?: NpsRow[];
  loading?: boolean;
};

type ApolloCandidate = {
  id: string;
  nameOrEmail?: string;
  initials?: string;
  color?: string;
  avatarImage: {
    candidatePictureUrl?: string;
  };
  company?: {
    id: string;
    name: string;
  };
};

interface CandidateQueryResponse {
  candidates: ApolloCandidate[];
}

export default class NpsResponsesWidget extends Component<Args> {
  @service declare analytics: AnalyticsService;
  @service declare intl: IntlService;
  @service declare current: Current;
  @service declare apollo: ApolloService;

  get loading() {
    return this.args.loading || this.fetchCandidates.isRunning;
  }

  get candidates() {
    return this.fetchCandidates.lastSuccessful?.value;
  }

  get responses() {
    if (!this.args.data) {
      return [];
    }

    const { candidates } = this;

    if (!candidates) {
      return this.args.data;
    }

    return this.args.data.map((row) => {
      const candidate = candidates.find(
        (candidate) => candidate.id.toString() === row.candidateId.toString()
      );

      return {
        ...row,
        candidate: candidate || row.candidate,
      };
    });
  }

  fetchCandidates = dropTask(async () => {
    const candidateIds = this.args.data?.map((row) => row.candidate.id) || [];
    const { candidates } = await this.apollo.query<CandidateQueryResponse>({
      query: gql`
        query NpsResponseWidgetCandidates(
          $candidateIds: [ID!]!
          $userId: ID!
          $companyIds: [ID!]
        ) {
          candidates(
            ids: $candidateIds
            userScope: { userId: $userId }
            groupCompanyIds: $companyIds
          ) {
            id
            nameOrEmail
            initials
            color
            avatarImage {
              candidatePictureUrl
            }
            company {
              id
              name
            }
          }
        }
      `,
      variables: {
        candidateIds,
        userId: this.current.user.id,
        companyIds: this.analytics.availableCompanyIds,
      },
    });

    return candidates;
  });
}
