import moment from 'moment-timezone';

export default function formatDate(date, localeArgs = {}, showYear = false) {
  let intl, languageCode;
  if (localeArgs) {
    intl = localeArgs.intl;
    languageCode = localeArgs.languageCode;

    if (intl && languageCode) {
      moment.locale(languageCode);
    }
  }

  if (!date) return '';

  return moment(date).calendar(null, {
    sameDay: `[${intl ? intl.t('common.today') : 'Today'}]`,
    nextDay: `[${intl ? intl.t('common.tomorrow') : 'Tomorrow'}]`,
    nextWeek: 'dddd',
    lastDay: `[${intl ? intl.t('common.yesterday') : 'Yesterday'}]`,
    lastWeek: intl
      ? intl.t('common.last_weekday', { weekday: 'dddd' })
      : '[Last] dddd',

    sameElse: `Do MMM${showYear ? ' YYYY' : ''}`,
  });
}
