import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class FormBase extends Component {
  @tracked fields = this.args.fields;

  constructor(owner, args) {
    super(owner, args);
    this.makeFieldsUnmodifiable(args.fields);
  }

  get acceptanceMessage() {
    return this.fields.findBy('label', 'acceptance_message').answers[0];
  }

  get rejectionMessage() {
    return this.fields.findBy('label', 'rejection_message').answers[0];
  }

  get acceptanceMessageValue() {
    return this.acceptanceMessage.textValue;
  }

  get rejectionMessageValue() {
    return this.rejectionMessage.textValue;
  }

  set acceptanceMessageValue(value) {
    this.acceptanceMessage.textValue = value;
  }

  set rejectionMessageValue(value) {
    this.rejectionMessage.textValue = value;
  }

  makeFieldsUnmodifiable(fields) {
    const salaryField = fields.findBy('label', 'salary');
    if (salaryField) {
      salaryField.unmodifiable = true;
    }

    const startDateField = fields.findBy('label', 'start_date');
    if (startDateField) {
      startDateField.labelUnmodifiable = true;
      startDateField.icon = 'calendar';
      startDateField.iconStyle = 'kit';
    }
  }

  @action
  onUpdateField(fieldUuid, data) {
    if (
      data.label &&
      get(this.args, 'form.unmodifiableFields').includes(data.label)
    ) {
      data.label = '';
    }

    const field = this.fields.findBy('uuid', fieldUuid);
    Object.assign(field, data);
    this.args.onUpdateFields(this.fields);
  }

  @action
  onFileUploaded(temporaryFileUrl, filename) {
    get(this.args, 'form.uploads').createRecord({
      temporaryFileUrl,
      fileFileName: filename,
    });
  }

  @action
  onRemoveUpload(upload) {
    if (upload.isNew) {
      upload.destroyRecord();
    } else {
      upload.deleteRecord();
    }
  }
}
