import Store from '@ember-data/store';
import LocationModel from 'teamtailor/models/location';
import { get } from 'teamtailor/utils/get';
import IntlService from 'ember-intl/services/intl';

type grouppedLocations = {
  [key: string]: LocationModel[];
};

const formatLocationOptions = (
  options: LocationModel[],
  intl: IntlService,
  regionId?: string
) => {
  if (regionId) {
    return options
      .slice()
      .sort((a, b) => a.nameOrCity.localeCompare(b.nameOrCity, 'sv'));
  } else {
    let formattedLocations = [];
    const locationsWithRegion = options.filter(
      (location) => !location.withoutRegion
    );

    if (locationsWithRegion.length) {
      const groupedLocations = groupLocationsByRegion(locationsWithRegion);

      Object.keys(groupedLocations)
        .sort((a, b) => a.localeCompare(b, 'sv'))
        .forEach((key) => {
          formattedLocations.push({
            groupName: key,
            options: groupedLocations[key],
          });
        });
    }

    const locationsWithoutRegion = options.filter(
      (location) => location.withoutRegion
    );
    if (locationsWithoutRegion.length) {
      formattedLocations.push({
        groupName: intl.t('common.other_locations'),
        options: locationsWithoutRegion.sort((a, b) =>
          a.nameOrCity.localeCompare(b.nameOrCity, 'sv')
        ),
      });
    }

    return formattedLocations;
  }
};

const groupLocationsByRegion = (
  locations: LocationModel[]
): grouppedLocations => {
  return locations.reduce((acc: grouppedLocations, location) => {
    const regionName = get(location.region, 'name');
    if (regionName in acc && acc[regionName]) {
      acc[regionName]?.push(location);
    } else {
      acc[regionName] = [location];
    }

    return acc;
  }, {});
};

const fetchLocations = async (
  store: Store,
  { name_or_city, page }: { name_or_city: string; page: number },
  regionId?: string
) => {
  const params = {
    page: page || 1,
    per_page: 25,
    name_or_city,
    sort_column: regionId ? null : 'region',
    with_fallback_sorting: true,
    region_id: regionId,
  };

  return store.query('location', params);
};

export { formatLocationOptions, fetchLocations };
