import classic from 'ember-classic-decorator';
import { equal } from '@ember/object/computed';
import Service from '@ember/service';

@classic
export default class ContentEditorService extends Service {
  preview = 'responsive';
  responsive = false;
  mode = 'regular';
  editorType = null;

  @equal('mode', 'wide')
  isWide;
}
