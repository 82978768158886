import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class SurveysRoute extends Route {
  @service intl;
  @service permissions;
  @service router;
  @service ttAlert;

  beforeModel() {
    if (
      !(
        this.permissions.has('form/create') ||
        this.permissions.has('form/update') ||
        this.permissions.has('form/results')
      )
    ) {
      this.ttAlert.error(this.intl.t('errors.dont_have_access_to_page'));
      this.router.transitionTo('dashboard');
    }
  }

  @action
  loading(transition) {
    const controller = this.controllerFor('surveys.index');

    if (controller) {
      const { survey_id } = transition.to.params;
      controller.loadingSurveyId = survey_id;

      transition.finally(() => {
        controller.loadingSurveyId = null;
      });
    }
  }
}
