import Controller, { inject as controller } from '@ember/controller';
import { action, set } from '@ember/object';
import { alias, gt } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import classic from 'ember-classic-decorator';
import {
  TABLE_COLUMNS,
  TABLE_COLUMNS_SSO,
  TABLE_LOADING_COLUMNS,
  TABLE_LOADING_COLUMNS_SSO,
} from 'teamtailor/constants/employee';
import { get } from 'teamtailor/utils/get';

@classic
export default class EmployeesListController extends Controller {
  @service current;

  get columns() {
    let columns = TABLE_COLUMNS;
    if (get(this.current.company, 'singleSignOnActive')) {
      columns = columns.concat(TABLE_COLUMNS_SSO);
    }

    return columns;
  }

  get loadingColumns() {
    let loading_columns = TABLE_LOADING_COLUMNS;
    if (get(this.current.company, 'singleSignOnActive')) {
      loading_columns = loading_columns.concat(TABLE_LOADING_COLUMNS_SSO);
    }

    return loading_columns;
  }

  @controller('employees.index')
  employeesController;

  @alias('employeesController.page')
  page;

  @alias('employeesController.view')
  view;

  @alias('employeesController.fetchEmployees') fetchEmployees;

  @alias('fetchEmployees.last.value.meta.total_pages') totalPages;

  @gt('totalPages', 1)
  showPagination;

  @action
  setPage(page) {
    set(this, 'page', page);
    window.scrollTo(0, 0);
  }
}
