import Model, { AsyncBelongsTo, belongsTo } from '@ember-data/model';
import CompanyModel from './company';
import SectionModel from './section';
import SectionItemModel from './section-item';
import VideoModel from './video';
import MessageWidgetModel from './message-widget';
import ConnectMediaItemModel from './connect-media-item';

export default class PickedVideoModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('section', { polymorphic: true })
  declare section: AsyncBelongsTo<SectionModel | null>;

  @belongsTo('connect-media-item')
  declare connectMediaItem: AsyncBelongsTo<ConnectMediaItemModel>;

  @belongsTo('section-item')
  declare sectionItem: AsyncBelongsTo<SectionItemModel | null>;

  @belongsTo('video', { async: false }) declare video: VideoModel;
  @belongsTo('message-widget')
  declare messageWidget: AsyncBelongsTo<MessageWidgetModel | null>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'picked-video': PickedVideoModel;
  }
}
