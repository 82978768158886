import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SettingsExternalRecruitmentRecruitersDeleteRoute extends Route {
  @service store;

  model(params) {
    return this.store.findRecord('user', params.external_recruiter_id);
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.initController();
  }
}
