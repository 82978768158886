import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import Controller from '@ember/controller';

interface QueryParams {
  todo_template_id: string;
}

export default class EditRoute extends ScrollToTopRoute {
  @service declare store: Store;

  model(params: QueryParams) {
    return this.store.findRecord('todo-template', params.todo_template_id);
  }

  resetController(controller: Controller, isExiting: boolean) {
    const { model } = controller;
    if (isExiting && !model.isDeleted) {
      model.rollbackAttributes();
    }
  }
}
