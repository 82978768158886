import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import Controller from '@ember/controller';

interface QueryParams {
  page_id: string;
}

export default class PostsEditRoute extends Route {
  @service declare store: Store;

  async model({ page_id }: QueryParams) {
    return this.store.findRecord('page', page_id, { reload: true });
  }

  resetController(controller: Controller, isExiting: boolean) {
    const { model } = controller;
    if (isExiting && !model.isDeleted) {
      model.rollbackAttributes();
    }
  }
}
