import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

export default class EmployeeHighlightJobRoute extends Route {
  @service user;
  @service store;

  async model(params) {
    const job = await this.store.findRecord('job', params.job_id);
    const jobHighlight = await get(job, 'jobHighlight');

    return jobHighlight
      ? jobHighlight
      : this.store.createRecord('job-highlight', {
          job,
          user: get(this, 'user.content'),
        });
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.isOpen = true;
  }
}
