import Helper from '@ember/component/helper';
import {
  STAGE_TYPE_COLORS,
  DEFAULT_STAGE_COLOR,
} from 'teamtailor/constants/stage-type-colors';

export function getStageTypeColor(category) {
  return STAGE_TYPE_COLORS[category] || DEFAULT_STAGE_COLOR;
}

export default Helper.helper(function (params) {
  return getStageTypeColor(...params);
});
