import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import JobModel from 'teamtailor/models/job';

export default class EmployeeShareJob extends Route {
  @service declare store: Store;

  queryParams = {
    job_id: {
      refreshModel: true,
    },
  };

  async model(params: { job_id: string }): Promise<JobModel> {
    return this.store.findRecord('job', params.job_id);
  }
}
