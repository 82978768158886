export const arrowsRetweet =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M328 368h-176V145.9l63.03 63.03C219.7 213.7 225.8 216 232 216s12.28-2.344 16.97-7.031c9.375-9.375 9.375-24.56 0-33.94l-104-104c-9.375-9.375-24.56-9.375-33.94 0l-104 104c-9.375 9.375-9.375 24.56 0 33.94s24.56 9.375 33.94 0L104 145.9V392C104 405.3 114.8 416 128 416h200c13.25 0 24-10.75 24-24S341.3 368 328 368zM632.1 303c-9.375-9.375-24.56-9.375-33.94 0L536 366.1V120C536 106.8 525.3 96 512 96H312C298.8 96 288 106.8 288 120S298.8 144 312 144h176v222.1l-63.03-63.03C420.3 298.3 414.2 296 408 296s-12.28 2.344-16.97 7.031c-9.375 9.375-9.375 24.56 0 33.94l104 104c9.375 9.375 24.56 9.375 33.94 0l104-104C642.3 327.6 642.3 312.4 632.1 303z"/></svg>';
export const arrowUpSmallBig =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M480 256h-160c-17.67 0-32 14.33-32 32v160c0 17.67 14.33 32 32 32h160c17.67 0 32-14.33 32-32V288C512 270.3 497.7 256 480 256zM464 432h-128v-128h128V432zM320 192h96c17.6 0 32-14.4 32-32V64c0-17.6-14.4-32-32-32h-96c-17.6 0-32 14.4-32 32v96C288 177.6 302.4 192 320 192zM336 80h64v64h-64V80zM145.6 39.37c-9.062-9.82-26.19-9.82-35.25 0L14.38 143.4c-9 9.758-8.406 24.96 1.344 33.94C20.35 181.7 26.19 183.8 32 183.8c6.469 0 12.91-2.594 17.62-7.719L104 117.1v338.9C104 469.2 114.8 480 128 480s24-10.76 24-24.02V117.1l54.37 58.95C215.3 185.8 230.5 186.5 240.3 177.4C250 168.4 250.6 153.2 241.6 143.4L145.6 39.37z"/></svg>';
export const bulletedList =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 64C81.67 64 96 78.33 96 96C96 113.7 81.67 128 64 128C46.33 128 32 113.7 32 96C32 78.33 46.33 64 64 64zM496 80C504.8 80 512 87.16 512 96C512 104.8 504.8 112 496 112H176C167.2 112 160 104.8 160 96C160 87.16 167.2 80 176 80H496zM496 240C504.8 240 512 247.2 512 256C512 264.8 504.8 272 496 272H176C167.2 272 160 264.8 160 256C160 247.2 167.2 240 176 240H496zM496 400C504.8 400 512 407.2 512 416C512 424.8 504.8 432 496 432H176C167.2 432 160 424.8 160 416C160 407.2 167.2 400 176 400H496zM64 288C46.33 288 32 273.7 32 256C32 238.3 46.33 224 64 224C81.67 224 96 238.3 96 256C96 273.7 81.67 288 64 288zM64 384C81.67 384 96 398.3 96 416C96 433.7 81.67 448 64 448C46.33 448 32 433.7 32 416C32 398.3 46.33 384 64 384z"/></svg>';
export const check =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M440.1 103C450.3 112.4 450.3 127.6 440.1 136.1L176.1 400.1C167.6 410.3 152.4 410.3 143 400.1L7.029 264.1C-2.343 255.6-2.343 240.4 7.029 231C16.4 221.7 31.6 221.7 40.97 231L160 350.1L407 103C416.4 93.66 431.6 93.66 440.1 103V103z"/></svg>';
export const chevronRight =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M113.3 47.41l183.1 191.1c4.469 4.625 6.688 10.62 6.688 16.59s-2.219 11.97-6.688 16.59l-183.1 191.1c-9.152 9.594-24.34 9.906-33.9 .7187c-9.625-9.125-9.938-24.38-.7187-33.91l168-175.4L78.71 80.6c-9.219-9.5-8.906-24.78 .7187-33.91C88.99 37.5 104.2 37.82 113.3 47.41z"/></svg>';
export const code =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M414.9 31.11L270.9 495.1C266.1 507.8 253.5 514.8 240.9 510.9C228.2 506.1 221.1 493.5 225.1 480.9L369.1 16.89C373 4.226 386.5-2.852 399.1 1.077C411.8 5.006 418.9 18.45 414.9 31.11V31.11zM504.4 118.5L632.4 238.5C637.3 243 640 249.4 640 255.1C640 262.6 637.3 268.1 632.4 273.5L504.4 393.5C494.7 402.6 479.6 402.1 470.5 392.4C461.4 382.7 461.9 367.6 471.6 358.5L580.9 255.1L471.6 153.5C461.9 144.4 461.4 129.3 470.5 119.6C479.6 109.9 494.7 109.4 504.4 118.5V118.5zM168.4 153.5L59.09 255.1L168.4 358.5C178.1 367.6 178.6 382.7 169.5 392.4C160.4 402.1 145.3 402.6 135.6 393.5L7.585 273.5C2.746 268.1 0 262.6 0 255.1C0 249.4 2.746 243 7.585 238.5L135.6 118.5C145.3 109.4 160.4 109.9 169.5 119.6C178.6 129.3 178.1 144.4 168.4 153.5V153.5z"/></svg>';
export const delimiter =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M616 280H24C10.75 280 0 269.3 0 256S10.75 232 24 232h592c13.25 0 24 10.72 24 23.97S629.3 280 616 280z"/></svg>';
export const down =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M377.4 296.6l-168 176C204.8 477.3 198.6 480 192 480s-12.84-2.688-17.38-7.438l-168-176C-2.5 286.1-2.156 271.8 7.438 262.6c9.5-9.156 24.75-8.812 33.94 .8125L168 396.1V56.02c0-13.25 10.75-24.01 23.1-24.01S216 42.77 216 56.02v340.1l126.6-132.7c9.156-9.625 24.41-9.969 33.94-.8125C386.2 271.8 386.5 286.1 377.4 296.6z"/></svg>';
export const gif =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M512 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h448c35.35 0 64-28.65 64-64V96C576 60.65 547.3 32 512 32zM528 416c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V96c0-8.822 7.178-16 16-16h448c8.822 0 16 7.178 16 16V416zM464 164h-88c-11.05 0-20 8.953-20 20v144c0 11.05 8.953 20 20 20s20-8.953 20-20V276H448c11.05 0 20-8.953 20-20S459 236 448 236h-52v-32H464c11.05 0 20-8.953 20-20S475 164 464 164zM304 164c-11.05 0-20 8.953-20 20v144c0 11.05 8.953 20 20 20s20-8.953 20-20v-144C324 172.1 315 164 304 164zM240 244H182.8c-11.05 0-20 8.953-20 20s8.953 20 20 20H220v13.73c-20.47 14.67-55.2 12.61-72.77-4.969C137.4 282.9 132 269.9 132 256s5.406-26.94 15.23-36.77c19.66-19.67 52.7-19.67 72.36 0c7.797 7.812 20.45 7.812 28.28 0c7.812-7.797 7.812-20.47 0-28.28c-34.75-34.75-94.17-34.78-128.9 0C101.6 208.3 92 231.4 92 256s9.578 47.67 26.95 65.05C136.1 338.2 160.7 348 186.5 348c25.83 0 50.47-9.828 67.59-26.95C257.9 317.3 260 312.2 260 306.9V264C260 252.1 251 244 240 244z"/></svg>';
export const heading2 =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M296 64C282.8 64 272 74.75 272 88V224h-224V88C48 74.75 37.25 64 24 64S0 74.75 0 88v336C0 437.3 10.75 448 24 448s24-10.75 24-24V272h224v152C272 437.3 282.8 448 296 448S320 437.3 320 424V88C320 74.75 309.3 64 296 64zM616 400.2h-162.2l125.7-114.8c51.39-45.5 57.39-122.7 13.64-175.7c-21.81-26.44-52.56-42.47-86.58-45.13c-34-2.562-66.73 8.344-92.33 30.94l-25.66 22.66c-9.938 8.781-10.88 23.94-2.094 33.88c8.766 9.938 23.94 10.91 33.88 2.125l25.66-22.69c15.77-13.91 36.2-20.59 56.78-19.06c20.94 1.656 39.88 11.53 53.33 27.84c26.73 32.41 22.95 81.38-8.719 109.4l-171.6 156.8c-7.312 6.688-9.766 17.16-6.188 26.41c3.578 9.219 12.47 15.31 22.38 15.31h224c13.25 0 24-10.75 24-24S629.3 400.2 616 400.2z"/></svg>';
export const heading3 =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M296 64C282.8 64 272 74.75 272 88V224h-224V88C48 74.75 37.25 64 24 64S0 74.75 0 88v336C0 437.3 10.75 448 24 448s24-10.75 24-24V272h224v152C272 437.3 282.8 448 296 448S320 437.3 320 424V88C320 74.75 309.3 64 296 64zM534.6 223.4h-34.13l115.5-118.9c6.719-6.906 8.641-17.19 4.891-26.06c-3.766-8.875-12.47-14.66-22.11-14.66L400 63.94c-13.25 0-24 10.59-24 23.84s10.75 24 24 24h141.1l-115.5 118.9c-6.719 6.906-8.641 17.19-4.891 26.06c3.766 8.875 12.47 14.66 22.11 14.66h90.91c31.66 0 57.41 28.78 57.41 64.19s-25.75 64.19-57.41 64.19h-77.11c-15.52 0-29.41-11.22-34.55-27.94c-3.906-12.66-17.23-19.81-30-15.88c-12.67 3.906-19.78 17.31-15.88 30c11.38 36.97 43.69 61.81 80.42 61.81h77.11C592.7 447.8 640 397.5 640 335.6S592.7 223.4 534.6 223.4z"/></svg>';
export const image =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M152 120c-26.51 0-48 21.49-48 48s21.49 48 48 48s48-21.49 48-48S178.5 120 152 120zM447.1 32h-384C28.65 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM463.1 409.3l-136.8-185.9C323.8 218.8 318.1 216 312 216c-6.113 0-11.82 2.768-15.21 7.379l-106.6 144.1l-37.09-46.1c-3.441-4.279-8.934-6.809-14.77-6.809c-5.842 0-11.33 2.529-14.78 6.809l-75.52 93.81c0-.0293 0 .0293 0 0L47.99 96c0-8.822 7.178-16 16-16h384c8.822 0 16 7.178 16 16V409.3z"/></svg>';
export const imageDefault =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M24 64C37.25 64 48 74.75 48 88V424C48 437.3 37.25 448 24 448C10.75 448 0 437.3 0 424V88C0 74.75 10.75 64 24 64zM240.1 135C250.3 144.4 250.3 159.6 240.1 168.1L177.9 232H462.1L399 168.1C389.7 159.6 389.7 144.4 399 135C408.4 125.7 423.6 125.7 432.1 135L536.1 239C546.3 248.4 546.3 263.6 536.1 272.1L432.1 376.1C423.6 386.3 408.4 386.3 399 376.1C389.7 367.6 389.7 352.4 399 343L462.1 280H177.9L240.1 343C250.3 352.4 250.3 367.6 240.1 376.1C231.6 386.3 216.4 386.3 207 376.1L103 272.1C93.66 263.6 93.66 248.4 103 239L207 135C216.4 125.7 231.6 125.7 240.1 135H240.1zM640 424C640 437.3 629.3 448 616 448C602.7 448 592 437.3 592 424V88C592 74.75 602.7 64 616 64C629.3 64 640 74.75 640 88V424z"/></svg>';
export const imageFull =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M512 255.1c0 6.755-2.844 13.09-7.844 17.62l-88 80.05C411.5 357.8 405.8 359.9 400 359.9c-13.27 0-24-10.76-24-24c0-6.534 2.647-13.04 7.844-17.78l42.07-38.28H280v146l38.25-42.1c4.715-5.2 11.21-7.849 17.74-7.849c13.23 0 24.01 10.71 24.01 24.03c0 5.765-2.061 11.55-6.25 16.15l-80 88.06C269.2 509.2 262.8 512 256 512s-13.22-2.846-17.75-7.849l-80-88.06c-4.189-4.603-6.25-10.39-6.25-16.15c0-13.38 10.83-24.03 23.1-24.03c6.526 0 13.02 2.649 17.75 7.849L232 425.9V279.8H86.09l42.07 38.28c5.196 4.735 7.844 11.24 7.844 17.78c0 13.22-10.71 24-24 24c-5.781 0-11.53-2.064-16.16-6.254l-88-80.05C2.844 269.1 0 262.7 0 255.1c0-6.755 2.844-13.37 7.844-17.9l88-80.05C100.5 153.8 106.2 151.8 112 151.8c13.26 0 23.99 10.74 23.99 23.99c0 6.534-2.647 13.04-7.844 17.78L86.09 231.8H232V85.8L193.8 127.9C189 133.1 182.5 135.7 175.1 135.7c-13.16 0-23.1-10.66-23.1-24.03c0-5.765 2.061-11.55 6.25-16.15l80-88.06C242.8 2.502 249.4 0 256 0s13.22 2.502 17.75 7.505l80 88.06c4.189 4.603 6.25 10.39 6.25 16.15c0 13.35-10.81 24.03-24 24.03c-6.531 0-13.03-2.658-17.75-7.849L280 85.8v146h145.9l-42.07-38.28c-5.196-4.735-7.844-11.24-7.844-17.78c0-13.25 10.74-23.99 23.98-23.99c5.759 0 11.55 2.061 16.18 6.242l88 80.05C509.2 242.6 512 249.2 512 255.1z"/></svg>';
export const imageLarge =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M512 256c0 6.688-2.812 13.09-7.719 17.62l-104 96C395.7 373.9 389.8 376 384 376c-6.469 0-12.91-2.594-17.62-7.719c-9-9.75-8.406-24.94 1.344-33.91L426.6 280H85.38l58.91 54.38c9.75 8.969 10.34 24.16 1.344 33.91C140.9 373.4 134.5 376 128 376c-5.812 0-11.66-2.094-16.28-6.375l-104-96C2.813 269.1 0 262.7 0 256s2.812-13.09 7.719-17.62l104-96C121.5 133.3 136.7 134 145.6 143.7c9 9.75 8.406 24.94-1.344 33.91L85.38 232h341.3l-58.91-54.38c-9.75-8.969-10.34-24.16-1.344-33.91C375.3 134 390.5 133.3 400.3 142.4l104 96C509.2 242.9 512 249.3 512 256z"/></svg>';
export const numberedList =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M55.1 56.04C55.1 42.78 66.74 32.04 79.1 32.04H111.1C125.3 32.04 135.1 42.78 135.1 56.04V176H151.1C165.3 176 175.1 186.8 175.1 200C175.1 213.3 165.3 224 151.1 224H71.1C58.74 224 47.1 213.3 47.1 200C47.1 186.8 58.74 176 71.1 176H87.1V80.04H79.1C66.74 80.04 55.1 69.29 55.1 56.04V56.04zM118.7 341.2C112.1 333.8 100.4 334.3 94.65 342.4L83.53 357.9C75.83 368.7 60.84 371.2 50.05 363.5C39.26 355.8 36.77 340.8 44.47 330.1L55.59 314.5C79.33 281.2 127.9 278.8 154.8 309.6C176.1 333.1 175.6 370.5 153.7 394.3L118.8 432H152C165.3 432 176 442.7 176 456C176 469.3 165.3 480 152 480H64C54.47 480 45.84 474.4 42.02 465.6C38.19 456.9 39.9 446.7 46.36 439.7L118.4 361.7C123.7 355.9 123.8 347.1 118.7 341.2L118.7 341.2zM520 72C533.3 72 544 82.75 544 96C544 109.3 533.3 120 520 120H248C234.7 120 224 109.3 224 96C224 82.75 234.7 72 248 72H520zM520 232C533.3 232 544 242.7 544 256C544 269.3 533.3 280 520 280H248C234.7 280 224 269.3 224 256C224 242.7 234.7 232 248 232H520zM520 392C533.3 392 544 402.7 544 416C544 429.3 533.3 440 520 440H248C234.7 440 224 429.3 224 416C224 402.7 234.7 392 248 392H520z"/></svg>';
export const quote =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M424 72C437.3 72 448 82.75 448 96C448 109.3 437.3 120 424 120H24C10.75 120 0 109.3 0 96C0 82.75 10.75 72 24 72H424zM424 232C437.3 232 448 242.7 448 256C448 269.3 437.3 280 424 280H152C138.7 280 128 269.3 128 256C128 242.7 138.7 232 152 232H424zM128 416C128 402.7 138.7 392 152 392H424C437.3 392 448 402.7 448 416C448 429.3 437.3 440 424 440H152C138.7 440 128 429.3 128 416zM0 248C0 234.7 10.75 224 24 224C37.25 224 48 234.7 48 248V424C48 437.3 37.25 448 24 448C10.75 448 0 437.3 0 424V248z"/></svg>';
export const text =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M448 56v80C448 149.3 437.3 160 424 160S400 149.3 400 136V80h-152v352h48c13.25 0 24 10.75 24 24S309.3 480 296 480h-144C138.8 480 128 469.3 128 456s10.75-24 24-24h48v-352H48v56C48 149.3 37.25 160 24 160S0 149.3 0 136v-80C0 42.75 10.75 32 24 32h400C437.3 32 448 42.75 448 56z"/></svg>';
export const trash =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M160 400C160 408.8 152.8 416 144 416C135.2 416 128 408.8 128 400V192C128 183.2 135.2 176 144 176C152.8 176 160 183.2 160 192V400zM240 400C240 408.8 232.8 416 224 416C215.2 416 208 408.8 208 400V192C208 183.2 215.2 176 224 176C232.8 176 240 183.2 240 192V400zM320 400C320 408.8 312.8 416 304 416C295.2 416 288 408.8 288 400V192C288 183.2 295.2 176 304 176C312.8 176 320 183.2 320 192V400zM317.5 24.94L354.2 80H424C437.3 80 448 90.75 448 104C448 117.3 437.3 128 424 128H416V432C416 476.2 380.2 512 336 512H112C67.82 512 32 476.2 32 432V128H24C10.75 128 0 117.3 0 104C0 90.75 10.75 80 24 80H93.82L130.5 24.94C140.9 9.357 158.4 0 177.1 0H270.9C289.6 0 307.1 9.358 317.5 24.94H317.5zM151.5 80H296.5L277.5 51.56C276 49.34 273.5 48 270.9 48H177.1C174.5 48 171.1 49.34 170.5 51.56L151.5 80zM80 432C80 449.7 94.33 464 112 464H336C353.7 464 368 449.7 368 432V128H80V432z"/></svg>';
export const up =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M6.625 215.5l168-176C179.2 34.7 185.4 32.02 192 32.02s12.84 2.688 17.38 7.438l168 176c9.125 9.594 8.781 24.78-.8125 33.94c-9.5 9.156-24.75 8.812-33.94-.8125L216 115.9V456c0 13.25-10.75 23.1-23.1 23.1S168 469.3 168 456V115.9l-126.6 132.7C32.22 258.2 16.97 258.5 7.438 249.4C-2.156 240.2-2.5 225 6.625 215.5z"/></svg>';
export const video =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M557.6 102.3c-11.53-7.406-25.88-8.391-38.28-2.688L416 147V128c0-35.35-28.65-64-64-64H64C28.65 64 0 92.65 0 128v256c0 35.35 28.65 64 64 64h288c35.35 0 64-28.65 64-64v-19.02l103.3 47.36c5.344 2.453 11.03 3.672 16.69 3.672c7.531 0 15.02-2.141 21.59-6.359C569.1 402.3 576 389.7 576 375.1V136C576 122.3 569.1 109.8 557.6 102.3zM368 384c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V128c0-8.822 7.178-16 16-16h288c8.822 0 16 7.178 16 16V384zM528 363.5L416 312.2V199.8l112-51.33V363.5z"/></svg>';
export const robot = (args: { class: string } = { class: '' }) =>
  `<svg class="${args.class}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="currentColor" d="M352 400c0 8.836-7.164 16-16 16h-32C295.2 416 288 408.8 288 400C288 391.2 295.2 384 304 384h32C344.8 384 352 391.2 352 400zM240 384H192c-8.836 0-16 7.162-16 16C176 408.8 183.2 416 192 416h48C248.8 416 256 408.8 256 400C256 391.2 248.8 384 240 384zM172 260C172 222.5 202.5 192 240 192c37.5 0 68 30.5 68 68c0 37.49-30.5 68-68 68C202.5 328 172 297.5 172 260zM220 260c0 11.04 8.955 20 20 20c11.05 0 20-8.955 20-20C260 248.1 251 240 240 240C228.1 240 220 248.1 220 260zM468 260c0 37.49-30.58 67.1-68.07 67.1c-.0254 0 .0254 0 0 0s.0254 0 0 0c-37.5 0-68.07-30.5-68.07-67.1c0-37.5 30.58-67.1 68.07-67.1c.0254 0-.0254 0 0 0s-.0254 0 0 0C437.4 192 468 222.5 468 260zM419.9 260c0-11.02-8.916-19.94-19.93-19.99C388.9 240.1 380 248.1 380 260c0 11.02 8.916 19.94 19.93 19.99C410.9 279.9 419.9 271 419.9 260zM448 384h-48c-8.836 0-16 7.162-16 16c0 8.836 7.164 16 16 16H448c8.836 0 16-7.164 16-16C464 391.2 456.8 384 448 384zM544 176v256c0 44.18-35.82 80-80 80h-288C131.8 512 96 476.2 96 432v-256C96 131.8 131.8 96 176 96h120V24C296 10.75 306.8 0 320 0s24 10.75 24 24V96h120C508.2 96 544 131.8 544 176zM496 176c0-17.64-14.36-32-32-32h-288c-17.64 0-32 14.36-32 32v256c0 17.64 14.36 32 32 32h288c17.64 0 32-14.36 32-32V176zM592.1 192H576v192h16.08C609.8 384 624 369.7 624 352V224C624 206.3 609.7 192 592.1 192zM16 224v128c0 17.67 14.26 32 31.94 32H64V192H48.03C30.36 192 16 206.3 16 224z"/></svg>`;
export const sparkles = (args: { class: string } = { class: '' }) =>
  `<svg class="${args.class}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"  role="img" focusable="false" aria-hidden="true" data-icon="sparkles" data-prefix="far" id="ember7010" class="svg-inline--fa fa-sparkles fa-fw ember-view"><path fill="currentColor" d="M327.5 85.19L384 64L405.2 7.491C406.9 2.985 411.2 0 416 0C420.8 0 425.1 2.985 426.8 7.491L448 64L504.5 85.19C509 86.88 512 91.19 512 96C512 100.8 509 105.1 504.5 106.8L448 128L426.8 184.5C425.1 189 420.8 192 416 192C411.2 192 406.9 189 405.2 184.5L384 128L327.5 106.8C322.1 105.1 320 100.8 320 96C320 91.19 322.1 86.88 327.5 85.19V85.19zM176 73.29C178.6 67.63 184.3 64 190.6 64C196.8 64 202.5 67.63 205.1 73.29L257.8 187.3L371.8 240C377.5 242.6 381.1 248.3 381.1 254.6C381.1 260.8 377.5 266.5 371.8 269.1L257.8 321.8L205.1 435.8C202.5 441.5 196.8 445.1 190.6 445.1C184.3 445.1 178.6 441.5 176 435.8L123.3 321.8L9.292 269.1C3.627 266.5 0 260.8 0 254.6C0 248.3 3.627 242.6 9.292 240L123.3 187.3L176 73.29zM166.9 207.5C162.1 217.8 153.8 226.1 143.5 230.9L92.32 254.6L143.5 278.2C153.8 282.1 162.1 291.3 166.9 301.6L190.6 352.8L214.2 301.6C218.1 291.3 227.3 282.1 237.6 278.2L288.8 254.6L237.6 230.9C227.3 226.1 218.1 217.8 214.2 207.5L190.6 156.3L166.9 207.5zM405.2 327.5C406.9 322.1 411.2 320 416 320C420.8 320 425.1 322.1 426.8 327.5L448 384L504.5 405.2C509 406.9 512 411.2 512 416C512 420.8 509 425.1 504.5 426.8L448 448L426.8 504.5C425.1 509 420.8 512 416 512C411.2 512 406.9 509 405.2 504.5L384 448L327.5 426.8C322.1 425.1 320 420.8 320 416C320 411.2 322.1 406.9 327.5 405.2L384 384L405.2 327.5z"></path></svg>`;
