import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class SettingsIntegrationsPartnerActivationActivateController extends Controller {
  @service ttAlert;
  @service intl;
  @service router;
  @service flashMessages;

  @tracked validForm = true;

  providerKeyData = {};

  get disabledSubmit() {
    return !this.validForm;
  }

  get installed() {
    return this.model.activationConfig.some(
      (field) => field?.required && field?.value
    );
  }

  get buttonText() {
    const text = this.installed ? 'update' : 'install';
    return this.intl.t(`settings.integrations.partner_activations.${text}`);
  }

  @action
  onValueChanged(key, value) {
    this.providerKeyData[key] = value;
    this.validForm = this.model.activationConfig.isEvery('isValid');
  }

  @action
  async save() {
    this.model.channelActivation.adapterAuthorizationToken =
      this.providerKeyData;
    try {
      await this.model.channelActivation.save();
      this.flashMessages.success(
        this.intl.t('settings.integrations.channel_activations.channel_updated')
      );
      this.router.transitionTo('settings.integrations.marketplace-activations');
    } catch ({ errors }) {
      errors.forEach((error) => {
        this.flashMessages.error(error?.detail);
      });
    }
  }

  @action
  clearProviderKeyData() {
    this.providerKey = {};
  }

  @action
  closeModal() {
    this.router.transitionTo('settings.integrations.marketplace-activations');
  }
}
