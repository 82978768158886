export const TRIGGER_COLORS = {
  message: 'yellow',
  note: 'rose',
  nps: 'emerald',
  tag: 'purple',
  share: 'cyan',
  webhook: 'orange',
  'smart-move': 'zinc',
  'smart-schedule': 'lime',
  todo: 'light-blue',
  prediction: 'orange',
  survey: 'teal',
  'ask-for-feedback': 'amber',
  restrict: 'blue',
  nurture: 'indigo',
  'hire-quality-survey': 'yellow',
};
