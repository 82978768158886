import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import MeetingRoomController from 'teamtailor/controllers/settings/meeting-rooms';

export default class MeetingRoomsRoute extends ScrollToTopRoute {
  @service declare store;

  async model() {
    const meetingRoomResources = await this.store.findAll(
      'meeting-room-resource'
    );
    const meetingRooms = this.store.findAll('meeting-room');
    const authorizations = await this.store
      .findAll('authorization', { reload: true })
      .then((authorizations) => authorizations.toArray());

    return {
      meetingRoomResources,
      meetingRooms,
      authorizations,
    };
  }

  resetController(_controller: MeetingRoomController, isExiting: boolean) {
    if (isExiting) {
      this.store.unloadAll('meeting-room-resource');
    }
  }
}
