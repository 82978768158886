export default {
  "root": "ChoiceCard__root_6twy5a",
  "outerMargin": "ChoiceCard__outerMargin_6twy5a",
  "label": "ChoiceCard__label_6twy5a",
  "check": "ChoiceCard__check_6twy5a",
  "checkIcon": "ChoiceCard__checkIcon_6twy5a",
  "input--checkbox": "ChoiceCard__input--checkbox_6twy5a",
  "input--radio": "ChoiceCard__input--radio_6twy5a",
  "check--switch": "ChoiceCard__check--switch_6twy5a",
  "input--switch": "ChoiceCard__input--switch_6twy5a",
  "check--checkbox": "ChoiceCard__check--checkbox_6twy5a"
};
