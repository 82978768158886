import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    url: { serialize: false },
    daysToExpiration: { serialize: false },
    isExpiredOrRevoked: { serialize: false },
    sharedItems: {
      serialize: 'records',
      deserialize: 'ids',
    },
  },
});
