import { CandidateModel, JobApplicationModel } from 'teamtailor/models';
import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class HiresController extends Controller {
  get requisition() {
    return this.model;
  }

  @action
  jobApplicationId(candidate: CandidateModel) {
    const jobApplications = candidate.jobApplications.slice();
    const requisitionJobIds = this.requisition.hasMany('jobs').ids();

    const firstJobApplication = jobApplications
      .filter((jobApplication: JobApplicationModel) => {
        return jobApplication.userHasAccessToJob;
      })
      .find((jobApplication: JobApplicationModel) =>
        requisitionJobIds.includes(jobApplication.jobId)
      );

    return firstJobApplication ? firstJobApplication.id : null;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'jobs.requisition.hires': HiresController;
  }
}
