import Model, { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { BlocketCategoryModel } from 'teamtailor/models';

export default class BlocketSubcategoryModel extends Model {
  @belongsTo('blocket-category')
  declare blocketCategory: AsyncBelongsTo<BlocketCategoryModel>;

  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'blocket-subcategory': BlocketSubcategoryModel;
  }
}
