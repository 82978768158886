import MeetingNewRoute from 'teamtailor/routes/meetings/new';

export default class AnalyticsOverviewTeamActivityCandidateMeetingsNewRoute extends MeetingNewRoute {
  controllerName = 'meetings.new';
  templateName = 'meetings.new';

  meetingRouteRouteName = 'analytics.overview.team-activity.candidate.meetings';
  candidateRouteRouteName = 'analytics.overview.team-activity.candidate';

  queryParams = {
    candidateId: {
      refreshModel: true,
    },

    jobId: {
      refreshModel: true,
      as: 'meetingJobId',
    },

    bulkOptions: {
      refreshModel: true,
    },
  };
}
