import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import { later } from '@ember/runloop';
import BowserService from 'teamtailor/services/bowser';

export default class TranquilityController extends Controller {
  @service declare router: RouterService;
  @service declare bowser: BowserService;

  @tracked overlayHidden = true;

  get overlayHiddenForSafari(): boolean {
    return this.overlayHidden && this.bowser.isSafari;
  }

  @action
  onClose(): void {
    this.hideOverlay();

    later(() => {
      this.router.transitionTo('dashboard');
    }, 500);
  }

  @action
  showOverlay(): void {
    this.overlayHidden = false;
  }

  @action
  hideOverlay(): void {
    this.overlayHidden = true;
  }

  @action
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      event.preventDefault();
      this.onClose();
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'dashboard.tranquility': TranquilityController;
  }
}
