import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import InfinityService from 'ember-infinity/addon/services/infinity';

export default class IndexRoute extends ScrollToTopRoute {
  @service declare infinity: InfinityService;

  queryParams = {
    role: { refreshModel: true },
    query: { refreshModel: true },
    sortColumn: { refreshModel: true },
    sortDirection: { refreshModel: true },
  };

  model({
    query,
    role,
    sortColumn,
    sortDirection,
  }: {
    query: string;
    role: string;
    sortColumn: string;
    sortDirection: string;
  }) {
    return this.infinity.model('todo-template', {
      startingPage: 1,
      perPage: 15,
      query,
      role,
      sort_column: sortColumn,
      sort_direction: sortDirection,
    });
  }
}
