import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::Modal @isOpen={{@isOpen}} @onClose={{@onClose}} @size=\"small\" as |modal|>\n  <modal.Header @text={{t \"nurture_campaigns.add_to_campaign.title\"}} />\n  <modal.Main>\n    <NurtureCampaign::Selector @handleChange={{this.handleChange}} />\n  </modal.Main>\n  <modal.Footer>\n    <StatusButton\n      @action={{this.handleAdd}}\n      @isDisabled={{eq this.selectedNurtureCampaign null}}\n      @text={{t \"common.add\"}}\n    />\n  </modal.Footer>\n</Core::Modal>", {"contents":"<Core::Modal @isOpen={{@isOpen}} @onClose={{@onClose}} @size=\"small\" as |modal|>\n  <modal.Header @text={{t \"nurture_campaigns.add_to_campaign.title\"}} />\n  <modal.Main>\n    <NurtureCampaign::Selector @handleChange={{this.handleChange}} />\n  </modal.Main>\n  <modal.Footer>\n    <StatusButton\n      @action={{this.handleAdd}}\n      @isDisabled={{eq this.selectedNurtureCampaign null}}\n      @text={{t \"common.add\"}}\n    />\n  </modal.Footer>\n</Core::Modal>","moduleName":"teamtailor/components/modals/add-candidate-to-nurture-campaign.hbs","parseOptions":{"srcName":"teamtailor/components/modals/add-candidate-to-nurture-campaign.hbs"}});
import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AddCandidateToNurtureCampaignComponent extends Component {
  @service store;

  @tracked selectedNurtureCampaign = null;

  @action
  handleAdd() {
    this.args.onSelect(get(this, 'selectedNurtureCampaign.nurtureCampaign'));
  }

  @action
  handleChange(nurtureCampaign) {
    this.selectedNurtureCampaign = nurtureCampaign;
  }
}
