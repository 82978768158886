import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { isEmpty } from '@ember/utils';
import RSVP from 'rsvp';
import PermissionsRoute from 'teamtailor/routes/permissions';

const getTextFromHTML = (html) => {
  const span = document.createElement('span');
  span.innerHTML = html;
  return span.textContent || span.innerText;
};

export default class EditRoute extends PermissionsRoute {
  @service current;
  @service intl;
  @service store;
  @service router;

  requiredPermissions = {
    or: ['job/create', 'job/update'],
  };

  beforeModel() {
    super.beforeModel(...arguments);

    return RSVP.all([
      get(this.current.company, 'approvalSetting'),
      get(this.current.company, 'teams'),
    ]);
  }

  model({ job_id }) {
    return this.store.findRecord('job', job_id);
  }

  async afterModel(model) {
    if (model.belongsTo('jobDetail').value()) {
      await model.belongsTo('jobDetail').reload();
    } else {
      await model.belongsTo('jobDetail').load();
    }
  }

  resetController(controller) {
    set(controller, 'showStatusButtons', false);
  }

  activate() {
    this.router.on('routeWillChange', this.onRouterWillChange);
  }

  deactivate() {
    this.router.off('routeWillChange', this.onRouterWillChange);
  }

  onRouterWillChange = async (transition) => {
    if (!transition.to.find((route) => route.name === this.routeName)) {
      const model = get(this, 'controller.model');

      if (model.isDeleted) {
        return;
      }

      const jobDetail = await get(model, 'jobDetail');
      const dirtyModelAttributes = jobDetail.changedAttributes();
      const bodyChanges = dirtyModelAttributes.body;
      delete dirtyModelAttributes.body;

      let dirtyBody = false;

      if (!isEmpty(bodyChanges)) {
        const [before, after] = bodyChanges.map((body) =>
          getTextFromHTML(body).replace('undefined', '').replace(/\n/g, '')
        );

        dirtyBody = before !== after;
      }

      let pickedInterviewKits = get(model, 'jobDetail.pickedInterviewKits');
      const dirtyPickedInterviewKits =
        pickedInterviewKits.isAny('isNew') ||
        pickedInterviewKits.isAny('isDeleted') ||
        pickedInterviewKits.isAny('hasDirtyAttributes');

      if (
        model.hasDirtyAttributes ||
        isEmpty(dirtyModelAttributes) ||
        dirtyBody ||
        dirtyPickedInterviewKits
      ) {
        if (confirm(this.intl.t('common.unsaved_changes_confirm'))) {
          model.rollbackAttributes();
          model.jobDetail.content.rollbackAttributes();
          model.jobDetail.content.pickedInterviewKits.forEach((kit) =>
            kit.rollbackAttributes()
          );
        } else {
          transition.abort();
        }
      }
    }
  };
}
