import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  attrs: {
    paths: { serialize: false },
    allowedToLogin: { serialize: false },
    intercomId: { serialize: false },
    jobsCreated: { serialize: false },
    userInvitation: { embedded: 'always' },
    login: { embedded: 'always' },

    accessLevels: { serialize: false },
  },
});
