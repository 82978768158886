import { inject as service } from '@ember/service';
import DS from 'ember-data'; // eslint-disable-line ember/use-ember-data-rfc-395-imports
import { set, get } from '@ember/object';
import UserModel from 'teamtailor/models/user';
import ApplicationInstance from '@ember/application/instance';
import Store from '@ember-data/store';
import 'ember-cached-decorator-polyfill';
import { cached } from '@glimmer/tracking';

class UserService extends DS.PromiseObject<UserModel> {
  declare content: UserModel;

  @service() declare store: Store;
  isServiceFactory = true;

  @cached
  get promise() {
    return get(this, 'store')
      .queryRecord('user', { current: true })
      .then((user: UserModel) => {
        set(this, 'content', user);
        return user;
      });
  }
}

export { UserService };

export function initialize(application: ApplicationInstance) {
  application.register('service:user', UserService, {
    instantiate: true,
    singleton: true,
  });
}

export default {
  name: 'user-service',
  after: 'ember-data',
  initialize,
};
