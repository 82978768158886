import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @tags}}\n  <div\n    class={{class-names\n      \"flex min-w-0 max-w-full items-center gap-4\"\n      flex-wrap=@skipTruncate\n    }}\n    ...attributes\n  >\n    {{#each this.visibleTags as |tag|}}\n      <Core::Tag\n        @text={{tag.name}}\n        @color={{tag.backgroundColor}}\n        @size=\"small\"\n        class={{unless @skipTruncate \"truncate\"}}\n      />\n    {{/each}}\n\n    {{#if this.hasMore}}\n      <Core::Badge\n        @size=\"small\"\n        @text={{concat \"+\" (subtract @tags.length this.limit)}}\n      />\n    {{/if}}\n  </div>\n{{/if}}", {"contents":"{{#if @tags}}\n  <div\n    class={{class-names\n      \"flex min-w-0 max-w-full items-center gap-4\"\n      flex-wrap=@skipTruncate\n    }}\n    ...attributes\n  >\n    {{#each this.visibleTags as |tag|}}\n      <Core::Tag\n        @text={{tag.name}}\n        @color={{tag.backgroundColor}}\n        @size=\"small\"\n        class={{unless @skipTruncate \"truncate\"}}\n      />\n    {{/each}}\n\n    {{#if this.hasMore}}\n      <Core::Badge\n        @size=\"small\"\n        @text={{concat \"+\" (subtract @tags.length this.limit)}}\n      />\n    {{/if}}\n  </div>\n{{/if}}","moduleName":"teamtailor/components/tag-group.hbs","parseOptions":{"srcName":"teamtailor/components/tag-group.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { argDefault } from 'teamtailor/utils/arg-default';
import TagModel from 'teamtailor/models/tag';
import { isArray } from '@ember/array';
import TimeFormatService from 'teamtailor/services/time-format';

interface TagGroupArgs {
  tags: TagModel[];
  limit?: number;
  tooltipSide?: string;
  compact?: boolean;
}

const DEFAULT_LIMIT = 2;

export default class UserGroup extends Component<TagGroupArgs> {
  @service declare current: Current;
  @service declare timeFormat: TimeFormatService;

  @argDefault compact = true;
  @argDefault limit = DEFAULT_LIMIT;

  get tags() {
    return isArray(this.args.tags) ? this.args.tags : [this.args.tags];
  }

  get visibleTags(): TagModel[] {
    if (this.limit) {
      return this.tags.slice(0, this.limit);
    } else {
      return this.tags;
    }
  }

  get hasMore(): boolean {
    return this.limit > 0 && this.tags.length > this.limit;
  }

  get visibleTagNames(): string {
    const oxfordCommaRegex = /,(?=[^,]+$)/;

    return new Intl.ListFormat(this.timeFormat.locale)
      .format(this.visibleTags.mapBy('name'))
      .replace(oxfordCommaRegex, '');
  }
}
