import { computed } from '@ember/object';
import { get } from 'teamtailor/utils/get';

export function calculateScore(scores: number[]) {
  const total = scores.length;
  const promoters = scores.filter(isPromoterScore);
  const detractors = scores.filter(isDetractorScore);

  const promotersPercentage = (promoters.length / total) * 100;
  const detractorsPercentage = (detractors.length / total) * 100;
  return promotersPercentage - detractorsPercentage;
}

export function isPromoterScore(score: number) {
  return score > 8;
}

export function isPassiveScore(score: number) {
  return score < 9 && score > 6;
}

export function isDetractorScore(score: number) {
  return score < 7;
}

// cleanup_when_done: combined_nps_page, new_candidate_modal -->
export function scoreTextColorClass(score: number) {
  switch (true) {
    case isPromoterScore(score):
      return 'text-slime';
    case isDetractorScore(score):
      return 'text-venetian-red';
    default:
      return 'text-base-grey-13';
  }
}

export function computedIsPromoterScore(key: string) {
  return computed(key, function () {
    return isPromoterScore(get(this, key));
  });
}

export function computedIsPassiveScore(key: string) {
  return computed(key, function () {
    return isPassiveScore(get(this, key));
  });
}

export function computedIsDetractorScore(key: string) {
  return computed(key, function () {
    return isDetractorScore(get(this, key));
  });
}
// end_cleanup_when_done
