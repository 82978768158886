import { action } from '@ember/object';
import Controller from '@ember/controller';
import {
  TABLE_COLUMNS,
  TABLE_LOADING_COLUMNS,
} from 'teamtailor/constants/todo-template';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
import { tracked } from '@glimmer/tracking';
import { timeout, restartableTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import Intl from 'ember-intl/services/intl';

type Role = {
  id: string;
};

export default class IndexController extends Controller {
  @service declare intl: Intl;

  queryParams = [
    'query',
    'role',
    {
      sortColumn: 'sort_column',
      sortDirection: 'sort_direction',
    },
  ];

  @tracked searchProxy?: string;
  @tracked query?: string;
  @tracked role?: string;

  filterAttributes = ['name'];
  sorting = 'name';

  @computedLocalStorage(
    Boolean,
    'SettingsTodoTemplatesIndex.showFilterSidebar',
    false
  )
  declare showFilterSidebar: boolean;

  get loadingColumns() {
    return TABLE_LOADING_COLUMNS;
  }

  get columns() {
    return TABLE_COLUMNS;
  }

  get listItems() {
    return this.model;
  }

  get sortedTodoTemplates() {
    return this.listItems.sortBy(this.sorting);
  }

  get totalCount() {
    return this.model?.meta?.total_count;
  }

  handleSearchInput = restartableTask(async (value: string) => {
    this.searchProxy = value;
    await timeout(750);
    this.query = value;
  });

  @action
  handleInsertSearch() {
    this.searchProxy = this.query;
  }

  @action
  handleFilterRoleChange(role: Role | null) {
    this.role = role?.id;
  }

  @action
  getTranslatedRole(role: string) {
    return this.intl.t(`user.${role}`);
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.todo-templates.index': IndexController;
  }
}
