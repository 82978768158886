import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"attachment\">\n  <div local-class=\"file\">\n    {{#if this.isUploading}}\n      <LoadingSpinner @size=\"small\" />\n    {{else if (eq this.fileType \"unknown\")}}\n      <Icon @icon=\"bars-irregular\" @style=\"kit\" @size=\"20\" />\n    {{else}}\n      <span>{{this.fileType}}</span>\n    {{/if}}\n\n    {{#if this.canDestroy}}\n      <button\n        type=\"button\"\n        local-class=\"remove\"\n        {{on \"click\" this.handleDestroy}}\n      ><Icon @icon=\"xmark\" /></button>\n    {{/if}}\n  </div>\n  <div local-class=\"filename\">{{this.truncatedNameIfNeeded}}</div>\n</div>", {"contents":"<div local-class=\"attachment\">\n  <div local-class=\"file\">\n    {{#if this.isUploading}}\n      <LoadingSpinner @size=\"small\" />\n    {{else if (eq this.fileType \"unknown\")}}\n      <Icon @icon=\"bars-irregular\" @style=\"kit\" @size=\"20\" />\n    {{else}}\n      <span>{{this.fileType}}</span>\n    {{/if}}\n\n    {{#if this.canDestroy}}\n      <button\n        type=\"button\"\n        local-class=\"remove\"\n        {{on \"click\" this.handleDestroy}}\n      ><Icon @icon=\"xmark\" /></button>\n    {{/if}}\n  </div>\n  <div local-class=\"filename\">{{this.truncatedNameIfNeeded}}</div>\n</div>","moduleName":"teamtailor/components/prepared-message/attachments.hbs","parseOptions":{"srcName":"teamtailor/components/prepared-message/attachments.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { PickedUploadFields } from 'teamtailor/classes/prepared-message';
import { assert } from '@ember/debug';

interface IPreparedMessageAttachmentArgs {
  attachment: PickedUploadFields;
  removeAttachment?(attachment: PickedUploadFields): void;
}

export default class PreparedMessageAttachmentComponent extends Component<IPreparedMessageAttachmentArgs> {
  get canDestroy(): boolean {
    return 'removeAttachment' in this.args;
  }

  get truncatedNameIfNeeded(): string {
    const { fileFileName } = this.args.attachment;

    if (fileFileName.length > 20) {
      return `${fileFileName.substring(0, 10)}...${fileFileName.substring(
        fileFileName.length - 7
      )}`;
    }

    return fileFileName;
  }

  get fileType(): string {
    const { fileFileName } = this.args.attachment;

    const match = fileFileName.match(/\.([0-9a-z]{1,5})(?:[?#]|$)/i);
    if (match) {
      assert('match[1] must exist', match[1]);
      return match[1];
    }

    return 'unknown';
  }

  get isUploading(): boolean {
    return !!this.args.attachment.isUploading;
  }

  @action
  handleDestroy(): void {
    if (this.args.removeAttachment) {
      this.args.removeAttachment(this.args.attachment);
    }
  }
}
