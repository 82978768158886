import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { StageModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';

export default class JobsEditStagesQuickRejectSettings extends Route {
  @service declare store: Store;
  @service declare router: RouterService;

  model(params: { stage_id: string }) {
    return this.store.findRecord('stage', params.stage_id);
  }

  async redirect(stage: StageModel) {
    const quickRejectSetting = await get(stage, 'quickRejectSetting');

    if (quickRejectSetting?.id) {
      return this.router.replaceWith(
        'jobs.edit.stages.quick-reject-settings.edit',
        quickRejectSetting.id
      );
    } else {
      return this.router.replaceWith(
        'jobs.edit.stages.quick-reject-settings.new'
      );
    }
  }
}
