import { setProperties } from '@ember/object';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';

export default class StagesRoute extends ScrollToTopRoute {
  resetController(controller, isExiting) {
    if (isExiting) {
      setProperties(controller, {
        currentTrigger: null,
        currentTriggerStage: null,
        showAddStageModal: false,
      });
    }
  }
}
