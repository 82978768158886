import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Button\n  @icon=\"linkedin\"\n  @iconStyle=\"brands\"\n  @onClick={{this.handleClick}}\n  @text={{@buttonText}}\n/>", {"contents":"<Button\n  @icon=\"linkedin\"\n  @iconStyle=\"brands\"\n  @onClick={{this.handleClick}}\n  @text={{@buttonText}}\n/>","moduleName":"teamtailor/components/share-button/linkedin.hbs","parseOptions":{"srcName":"teamtailor/components/share-button/linkedin.hbs"}});
import ShareButton from 'teamtailor/components/share-button/share-button';
export default class extends ShareButton {
  shareURL = 'https://www.linkedin.com/shareArticle';

  buildUrl() {
    let url = this.shareURL;
    url += '?mini=true';
    url += `&url=${encodeURIComponent(this.args.url)}`;
    url += `&title=${this.args.title}`;
    url += `&summary=${this.args.text}`;
    return url;
  }
}
