import Controller from '@ember/controller';

import { ModelFrom } from 'teamtailor/utils/type-utils';
import FromRoute from 'teamtailor/routes/meetings';

export default class MeetingsController extends Controller {
  declare model: ModelFrom<FromRoute>;
  public modalKeyboardPriority = 1;
}

declare module '@ember/controller' {
  interface Registry {
    meetings: MeetingsController;
  }
}
