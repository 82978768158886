import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';

export default class extends Controller {
  @service router;
  @service current;
  @service intl;
  @service ttAlert;
  @service flashMessages;

  get cookieSettings() {
    return this.model.cookieSettings;
  }

  get customCookiePolicy() {
    return this.cookieSettings.customCookiePolicy;
  }

  get cookieDetails() {
    return this.model.cookieDetails.filterBy('isNew', false);
  }

  get analyticsCookies() {
    return this.cookieDetails.filterBy('isAnalytics');
  }

  get marketingCookies() {
    return this.cookieDetails.filterBy('isMarketing');
  }

  get preferencesCookies() {
    return this.cookieDetails.filterBy('isPreferences');
  }

  get strictlyNecessaryCookies() {
    return this.cookieDetails.filterBy('isNecessary');
  }

  get cookiePolicyPageLink() {
    return get(this.current.company, 'paths').cookie_url;
  }

  @action
  createCookieDetail(type) {
    const queryParams = { type };

    this.router.transitionTo(
      'settings.cookies.new',
      this.current.company.uuid,
      { queryParams }
    );
  }

  settingsUpdatedMessage() {
    this.flashMessages.success(
      this.intl.t('settings.cookies.settings_updated')
    );
  }

  @action
  deleteCookieDetail(cookie) {
    const { ttAlert, intl } = this;
    const message = intl.t('components.cookie_form.confirm');
    ttAlert.confirm(message, async () => {
      await cookie.destroyRecord();
    });
  }

  @action
  async toggleCookieDetail(cookie, enabled) {
    cookie.enabled = enabled;

    if (enabled) {
      this.cookieSettings.disabledTogglableCookies.removeObject(cookie.id);
      if (!this.cookieSettings.enabledTogglableCookies.includes(cookie.id)) {
        this.cookieSettings.enabledTogglableCookies.pushObject(cookie.id);
      }
    } else {
      if (!this.cookieSettings.disabledTogglableCookies.includes(cookie.id)) {
        this.cookieSettings.disabledTogglableCookies.pushObject(cookie.id);
      }
    }

    await this.cookieSettings.save();
    this.settingsUpdatedMessage();
  }

  @action
  async toggleCookieAlert(cookieSettings, showCookieConsent) {
    cookieSettings.showCookieConsent = showCookieConsent;
    await cookieSettings.save();
    this.settingsUpdatedMessage();
  }

  @action
  async updateCookieSettings(cookieSettings, variant) {
    cookieSettings.variant = variant;
    await cookieSettings.save();
    this.settingsUpdatedMessage();
  }

  @action
  async updateContactPerson(cookieSettings, user) {
    cookieSettings.cookieContactUser = user;
    await cookieSettings.save();
    this.settingsUpdatedMessage();
  }
}
