import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isLoading}}\n  <LoadingSpinner />\n{{else}}\n  <Jobs::Collaborate::List\n    @models={{this.sortedUnreadActivities}}\n    @readOnlyChannel={{true}}\n    @disablePinnedMessage={{true}}\n    @destroyActivity={{this.destroyActivity}}\n    @hideOnlineBeacon={{true}}\n  />\n{{/if}}", {"contents":"{{#if this.isLoading}}\n  <LoadingSpinner />\n{{else}}\n  <Jobs::Collaborate::List\n    @models={{this.sortedUnreadActivities}}\n    @readOnlyChannel={{true}}\n    @disablePinnedMessage={{true}}\n    @destroyActivity={{this.destroyActivity}}\n    @hideOnlineBeacon={{true}}\n  />\n{{/if}}","moduleName":"teamtailor/components/toolbox/global-comments/unread/unread-activities.hbs","parseOptions":{"srcName":"teamtailor/components/toolbox/global-comments/unread/unread-activities.hbs"}});
import Component from '@glimmer/component';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { trackedFunction } from 'ember-resources/util/function';
import ActivityModel from 'teamtailor/models/activity';

type Args = {
  jobId: string;
  latestJobCommentReadDateAt: Date;
  isLoading?: boolean;
};

export default class UnreadActivitiesComponent extends Component<Args> {
  @service declare store: Store;

  resolveUnreadActivityResponses = trackedFunction(this, async () => {
    const activities: ActivityModel[] = await this.store.query('activity', {
      job_id: this.args.jobId,
      collaborate: true,
      filter_mentioned_actions: true,
    });
    const jobActivities = activities.slice();

    const unreadComments = jobActivities.filter(
      (activity) => activity.createdAt >= this.args.latestJobCommentReadDateAt
    );
    return unreadComments;
  });

  get isLoading() {
    return (
      this.args.isLoading || this.resolveUnreadActivityResponses.value === null
    );
  }

  get sortedUnreadActivities(): ActivityModel[] {
    return this.resolveUnreadActivityResponses.value || [];
  }
}
