import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import UserModel from 'teamtailor/models/user';
import { inject as service } from '@ember/service';
import CustomReportModel from 'teamtailor/models/custom-report';
import InsightsReport from 'teamtailor/models/insights-report';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import Store from '@ember-data/store';

/* eslint-disable @typescript-eslint/naming-convention */
export class IntervalSettings {
  @tracked declare type: string;
  @tracked declare value: number;
  @tracked days: number[] = [];
  @tracked dates: number[] = [];
  @tracked months: number[] = [];
}

export class PeriodSettings {
  @tracked declare type?: string;
  @tracked declare value?: number;
}

export class Settings {
  @tracked declare time: string;
  @tracked declare tzid: string;
  @tracked declare interval: IntervalSettings;
  @tracked declare period: PeriodSettings;
}

/* eslint-enable @typescript-eslint/naming-convention */

export const defaultSettings: Settings = {
  time: '00:00',
  tzid: Intl.DateTimeFormat().resolvedOptions().timeZone,
  interval: {
    type: 'weekly',
    value: 1,
    days: [],
    dates: [],
    months: [],
  },

  period: {},
};

export default class ScheduledReportModel extends Model {
  @service declare store: Store;

  @attr('string') declare subject: string | null;

  @belongsTo('custom-report')
  declare customReport?: AsyncBelongsTo<CustomReportModel>;

  @belongsTo('insights-report')
  declare insightsReport?: AsyncBelongsTo<InsightsReport>;

  @attr('raw') declare settings: Settings;

  @attr('string') declare status: string;
  @attr('date') declare lastSentAt: Date;

  @attr('date') declare nextSendAt?: Date;

  @attr('date') declare updatedAt: Date;
  @attr('date') declare createdAt: Date;
  @attr('array', { defaultValue: () => [] })
  declare externalRecipients: string[];

  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;
  @hasMany('user') declare recipients: AsyncHasMany<UserModel>;

  get nextSendDates(): string[] {
    return (
      (!this.fetchNextSendDates.isRunning &&
        this.fetchNextSendDates.lastSuccessful?.value?.next_send_dates) ||
      []
    );
  }

  get isActive() {
    return !this.isNew && this.status !== 'disabled';
  }

  fetchNextSendDates = task(async () => {
    const adapter = this.store.adapterFor('application');
    const url = `${adapter.buildURL('scheduled-report')}/next_send_dates`;
    const { nextSendAt } = this;

    return adapter.ajax(url, 'POST', {
      data: { settings: this.settings, next_send_at: nextSendAt },
    }) as Promise<{ next_send_dates: string[] }>;
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'scheduled-report': ScheduledReportModel;
  }
}
