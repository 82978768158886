import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';

export default class SettingsExternalRecruitmentRecruitersRoute extends ScrollToTopRoute {
  @service router;

  queryParams = ['page', 'query', 'recruitingFirmId'];

  activate() {
    this.router.on('routeWillChange', this.onRouterWillChange);
  }

  deactivate() {
    this.router.off('routeWillChange', this.onRouterWillChange);
  }

  onRouterWillChange = (transition) => {
    if (!transition.to.find((route) => route.name === this.routeName)) {
      if (
        transition.from.name === 'settings.external-recruitment.recruiters.new'
      ) {
        const { query } = this.controller;
        this.controller.query = query;
      }
    }
  };
}
