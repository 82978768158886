import Model, { attr } from '@ember-data/model';

export default class AutomaticDeletionSettingModel extends Model {
  @attr('boolean', { defaultValue: false })
  declare inactiveCandidatesActivated: boolean;

  @attr('number') declare inactiveCandidatesDays: number | undefined;

  @attr('boolean', { defaultValue: false })
  declare missingPurposeCandidatesActivated: boolean;

  @attr('number') declare missingPurposeCandidatesDays: number | undefined;

  @attr('boolean', { defaultValue: false })
  declare removalRequestsActivated: boolean;

  @attr('number') declare removalRequestsDays: number | undefined;

  @attr('boolean', { defaultValue: false })
  declare gdprConsentActivated: boolean;

  @attr('number') declare gdprConsentDays: number | undefined;

  @attr('boolean', { defaultValue: false })
  declare rejectedJobApplicationsActivated: boolean;

  @attr('number') declare rejectedJobApplicationsDays: number | undefined;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'automatic-deletion-setting': AutomaticDeletionSettingModel;
  }
}
