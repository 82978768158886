import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n  <HighCharts\n    @chartOptions={{this.chartOptions}}\n    @hideAnimations={{@hideAnimations}}\n  />\n</div>", {"contents":"<div>\n  <HighCharts\n    @chartOptions={{this.chartOptions}}\n    @hideAnimations={{@hideAnimations}}\n  />\n</div>","moduleName":"teamtailor/components/insights/charts/bar-chart.hbs","parseOptions":{"srcName":"teamtailor/components/insights/charts/bar-chart.hbs"}});
import Component from '@glimmer/component';
import fontOptions from './font-options';
import { Options, SeriesOptionsType } from 'highcharts';

type DataType = {
  name: string;
  value: number[];
  color?: string;
  colorIndex?: number;
  colors?: string[];
};

type Args = {
  height?: number;
  categories: string[];
  data: DataType[];
  direction?: 'horizontal' | 'vertical';
  stacked?: boolean;
  showZeros?: boolean;
};

export default class InsightsChartsStackedBarChart extends Component<Args> {
  get height() {
    return this.args.height ? this.args.height : 300;
  }

  get horizontal() {
    return this.args.direction === 'horizontal';
  }

  get stacked() {
    return !!this.args.stacked;
  }

  get chartOptions() {
    let i = 0;

    const series: SeriesOptionsType[] = this.args.categories.map(
      (name, index) => {
        const data = this.args.data.map((row, i) => {
          return [i, row.value[index]];
        });

        return {
          type: this.horizontal ? 'bar' : 'column',
          name,
          data,
        };
      }
    );

    const { data, showZeros } = this.args;

    const names = data.map((a) => a.name);

    const options: Options = {
      chart: {
        type: this.horizontal ? 'bar' : 'column',
        height: `${this.height}px`,
        marginTop: 0,
      },

      title: {
        text: undefined,
      },

      yAxis: {
        title: {
          text: '',
        },

        labels: {
          style: fontOptions,
        },
      },

      xAxis: {
        labels: {
          style: fontOptions,
          formatter(ctx): string {
            i++;
            if (ctx.isFirst) {
              i = 0;
            }

            return names[i] || '';
          },
        },

        tickInterval: 1,
        lineColor: '#eeeff2',
        lineWidth: 1,
      },

      series,

      tooltip: {
        followPointer: true,
        shared: true,
        formatter(): string {
          return (
            this.points
              ?.filter((p) => showZeros || p.y !== 0)
              .reduce(
                (s, point) =>
                  `${s}<br /><span style="display: inline-block; margin-right: 4px; color: ${point.color}">\u25CF</span> ${point.series.name}: ${point.y}`,
                `<span>${names[(this.x || 0) as number]}</span>`
              ) || ''
          );
        },
      },

      legend: {
        enabled: false,
      },

      plotOptions: {
        series: {
          stacking: this.stacked ? 'normal' : undefined,
          borderColor: undefined,
        },

        column: {
          maxPointWidth: 12,
        },

        bar: {
          maxPointWidth: 12,
        },
      },
    };

    return options;
  }
}
