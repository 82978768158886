import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (and this.googleFontUrl this.googleFontUrlValid)}}\n  <link href={{this.googleFontUrl}} rel=\"stylesheet\" type=\"text/css\" />\n{{/if}}\n\n{{#if this.typekitId}}\n  {{! template-lint-disable no-forbidden-elements }}\n  <script\n    onload=\"try { Typekit.load(); } catch (e) {}\"\n    type=\"text/javascript\"\n    src={{concat \"//use.typekit.net/\" this.typekitId \".js\"}}\n  ></script>\n{{/if}}", {"contents":"{{#if (and this.googleFontUrl this.googleFontUrlValid)}}\n  <link href={{this.googleFontUrl}} rel=\"stylesheet\" type=\"text/css\" />\n{{/if}}\n\n{{#if this.typekitId}}\n  {{! template-lint-disable no-forbidden-elements }}\n  <script\n    onload=\"try { Typekit.load(); } catch (e) {}\"\n    type=\"text/javascript\"\n    src={{concat \"//use.typekit.net/\" this.typekitId \".js\"}}\n  ></script>\n{{/if}}","moduleName":"teamtailor/components/company-fonts.hbs","parseOptions":{"srcName":"teamtailor/components/company-fonts.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class CompanyFonts extends Component {
  @service current;

  get googleFontUrl() {
    return get(this.current.company, 'design.googleFontUrl');
  }

  get googleFontUrlValid() {
    return Boolean(this.googleFontUrl.includes('https://fonts.googleapis.com'));
  }

  get typekitId() {
    return get(this.current.company, 'design.typekitId');
  }
}
