import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  ...attributes\n  local-class=\"paginationPills\"\n  {{did-update this.handlePageUpdate @activePage}}\n>\n  {{#each this.dots as |page|}}\n    <button\n      type=\"button\"\n      local-class=\"pill{{if (eq page this.activePage) ' pill--active'}}\"\n      {{on \"click\" (fn this.handleClick page)}}\n    >\n    </button>\n  {{/each}}\n</div>", {"contents":"<div\n  ...attributes\n  local-class=\"paginationPills\"\n  {{did-update this.handlePageUpdate @activePage}}\n>\n  {{#each this.dots as |page|}}\n    <button\n      type=\"button\"\n      local-class=\"pill{{if (eq page this.activePage) ' pill--active'}}\"\n      {{on \"click\" (fn this.handleClick page)}}\n    >\n    </button>\n  {{/each}}\n</div>","moduleName":"teamtailor/components/pagination-pills.hbs","parseOptions":{"srcName":"teamtailor/components/pagination-pills.hbs"}});
import Component from '@glimmer/component';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class PaginationPillsComponent extends Component {
  @tracked _activePage = 1;

  @computed('args.dotCount')
  get dots() {
    return this.args.dotCount > 1
      ? [...Array(this.args.dotCount).keys()].map((i) => i + 1)
      : [];
  }

  @computed('_activePage', 'args.activePage')
  get activePage() {
    return this.args.activePage ? this.args.activePage : this._activePage;
  }

  handlePageUpdate = (_, [page]) => {
    this._activePage = page || 1;
  };

  @action
  handleClick(page) {
    this._activePage = page;
    if (this.args.onChange) {
      this.args.onChange(page);
    }
  }
}
