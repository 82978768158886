import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { JobOfferApprovalFlowModel } from 'teamtailor/models';
import Current from 'teamtailor/services/current';
import SettingsJobOfferApprovalFlowsEditController from 'teamtailor/controllers/settings/job-offer-approval-flows/edit';
import { get } from 'teamtailor/utils/get';

export default class SettingsJobOfferApprovalFlowsNewRoute extends Route {
  @service declare store: Store;
  @service declare current: Current;
  @service declare intl: IntlService;

  controllerName = 'settings.job-offer-approval-flows.edit';

  model() {
    const parentModel = this.modelFor(
      'settings.job-offer-approval-flows'
    ) as JobOfferApprovalFlowModel[];

    const hasDefaultFlow = parentModel.findBy('isDefault', true);

    return this.store.createRecord('job-offer-approval-flow', {
      company: this.current.company,
      isDefault: !hasDefaultFlow,
      title: hasDefaultFlow
        ? ''
        : this.intl.t('settings.requisitions.default_flow'),
    });
  }

  afterModel(model: JobOfferApprovalFlowModel) {
    this.store.createRecord('requisition-flow-step', {
      stepIndex: 0,
      jobOfferApprovalFlow: model,
    });
  }

  async resetController(
    controller: SettingsJobOfferApprovalFlowsEditController,
    isExiting: boolean
  ) {
    if (isExiting) {
      const steps = await get(controller.model, 'steps');

      steps.toArray().forEach((step) => {
        step.pickedUsers = [];
        step.rollbackAttributes();
      });

      if (get(controller.model, 'isNew')) {
        controller.model.unloadRecord();
      }
    }
  }
}
