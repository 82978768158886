import Controller from '@ember/controller';

import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { marketingTypesInMarketplace } from 'teamtailor/constants/marketplace';
import uniq from 'teamtailor/utils/uniq';

const ALL_COUNTRIES = 'All countries';

export default class MarketplaceIndexController extends Controller {
  queryParams = ['country', 'search', 'category', 'show'];

  @service intl;
  @service router;
  @service current;
  @tracked country = null;
  @tracked search = null;
  @tracked category = null;
  @tracked show = null;
  @tracked model;

  shouldFilterAvailable(modelId, shouldList) {
    if (!this.current.company.sandbox) shouldList;

    return (
      shouldList ||
      this.current.company.sandboxActivations.findBy('partnerModel.id', modelId)
    );
  }

  get availableChannels() {
    return this.model.channels
      .filter(
        (channel) =>
          marketingTypesInMarketplace.includes(channel.marketingType) &&
          this.shouldFilterAvailable(
            channel.id,
            channel.shouldListInMarketplace
          )
      )
      .sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  get availablePartners() {
    return this.model.partners
      .filter((partner) =>
        this.shouldFilterAvailable(partner.id, partner.shouldListInMarketplace)
      )
      .sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  get filteredPartnerList() {
    const filteredPartners = this.filterList(this.availablePartners, 'partner');
    if (this.show === 'partner' && this.category) {
      return filteredPartners.filter(
        (partner) => this.category === partner.category
      );
    }

    return filteredPartners;
  }

  get filteredChannelList() {
    const filteredChannels = this.getFilteredChannels(
      this.filterList(this.availableChannels, 'channel')
    );

    if (this.show === 'channel' && this.category) {
      return filteredChannels.filter(
        (channel) => this.category === channel.marketingType
      );
    }

    return filteredChannels;
  }

  get channelCategory() {
    if (!this.category) {
      return this.intl.t('marketplace.all_channels');
    }

    return this.intl.t(`marketplace.types.${this.category}`);
  }

  get partnerCategory() {
    if (!this.category) {
      return this.show === 'partner'
        ? this.intl.t('marketplace.all_partners')
        : this.intl.t('common.all');
    }

    return this.filteredPartnerList.firstObject.categoryHumanName;
  }

  get defaultCountry() {
    return get(this.current.company, 'headquarters.company');
  }

  get availableCountries() {
    let countries = uniq(
      []
        .concat(...this.model.channels.mapBy('countries'))
        .addObject(this.defaultCountry)
        .filter(Boolean)
    )
      .compact()
      .sort()
      .removeObject('Global');
    countries.unshift(ALL_COUNTRIES);
    return countries;
  }

  get pickedCountry() {
    return this.country || this.defaultCountry;
  }

  @action
  filterList(model, modelName) {
    if (this.show && modelName !== this.show) return [];
    if (this.search) {
      const filtered = model.filter((item) => {
        return item.name?.toLowerCase().includes(this.search.toLowerCase());
      });

      return filtered;
    }

    return model;
  }

  @action
  handleCountryChange(chosenCountry) {
    this.router.transitionTo({ queryParams: { country: chosenCountry } });
  }

  @action
  getFilteredChannels(channels) {
    if (!this.country || this.country === ALL_COUNTRIES) return channels;

    return channels.filter((channel) =>
      channel.countries.includes(this.country)
    );
  }

  @action
  onSearch(value) {
    this.router.transitionTo({ queryParams: { search: value } });
  }

  @action
  onReset() {
    this.router.transitionTo({ queryParams: { search: null } });
  }
}
