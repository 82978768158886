import Route from '@ember/routing/route';
import { set } from '@ember/object';

export default class EditRoute extends Route {
  model({ perk_id: perkId }) {
    const section = this.modelFor('content.editor.section.perks');
    return section.perks.findBy('id', perkId);
  }

  setupController(controller) {
    super.setupController(...arguments);

    const section = this.modelFor('content.editor.section.perks');
    set(controller, 'section', section);
  }

  deactivate() {
    const model = this.modelFor('content.editor.section.perks.edit');

    if (model.hasDirtyAttributes) {
      model.rollbackAttributes();
    }
  }
}
