import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

// This route only exists to redirect to the correct sub-route
// and prevent the user from ending up on this index route.
// Seems to be a routing bug in Ember that can happen when using the router service.
export default class SettingsSecurityIndex extends Route {
  @service declare router: RouterService;

  redirect() {
    this.router.replaceWith('settings.security.email-password');
  }
}
