import { attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import TriggerModel from 'teamtailor/models/trigger';

export default class TriggerHireQualitySurveyModel extends TriggerModel<'hire-quality-survey'> {
  @attr('number', { defaultValue: 6 }) declare evaluationPeriod: number;
  @attr('string', { defaultValue: 'months' })
  declare evaluationPeriodUnit: string;

  get valid(): boolean {
    return (
      isPresent(this.evaluationPeriod) &&
      this.evaluationPeriod > 0 &&
      isPresent(this.evaluationPeriodUnit)
    );
  }

  get convertedEvaluationPeriodUnit(): string {
    return this.evaluationPeriodUnit === 'days'
      ? 'weeks'
      : this.evaluationPeriodUnit;
  }

  get convertedEvaluationPeriod(): number {
    return this.convertedEvaluationPeriodUnit === 'weeks'
      ? this.evaluationPeriod / 7
      : this.evaluationPeriod;
  }

  get description() {
    return this.intl.t('models.trigger.hire_quality_survey.send_in', {
      month: get(this, 'convertedEvaluationPeriod'),
      unit: get(this, 'convertedEvaluationPeriodUnit'),
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'trigger/hire-quality-survey': TriggerHireQualitySurveyModel;
  }
}
