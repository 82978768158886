import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import { isEmpty } from '@ember/utils';

export default class JobsJobPromoteChannelActivateRoute extends Route {
  @service current;
  @service store;
  @service router;
  @service permissions;

  model(params) {
    const channel = this.modelFor('jobs.job.promote.channel');

    let { channelActivation } = channel;

    if (isEmpty(channelActivation)) {
      channelActivation = get(
        this.current.company,
        'channelActivations'
      ).createRecord({
        activated: true,
      });
      channelActivation.channel = channel;
    }

    return RSVP.hash({
      channel,
      channelActivation,
      currency: params?.currency,
    });
  }

  async afterModel(model) {
    if (model.channel.isSocial && this.permissions.has('promote/manage')) {
      await model.channelActivation.save();
      return this.router.transitionTo('jobs.job.promote.pick', model.channel, {
        queryParams: {
          currency: model.currency,
        },
      });
    }
  }

  deactivate() {
    const { channelActivation } = this.modelFor('jobs.job.promote.channel');
    if (channelActivation?.hasDirtyAttributes) {
      channelActivation.rollbackAttributes();
    }
  }
}
