import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class extends Controller {
  @service router;

  @action onClose() {
    this.router.transitionTo('settings.partner_hub');
  }

  get isChannel() {
    return this.model.partnerType === 'channel';
  }
}
