import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Meeting::FilteredError\n  @allProblems={{@allProblems}}\n  @warnings={{this.warnings}}\n  @pathFilter={{concat \"meeting_events[\" @eventIndex \"].\" @pathFilter \"\"}}\n>\n  <Core::LabeledGrid::Row\n    @label={{@title}}\n    @icon={{@icon}}\n    @iconStyle={{@iconStyle}}\n  >\n    {{yield}}\n  </Core::LabeledGrid::Row>\n</Meeting::FilteredError>", {"contents":"<Meeting::FilteredError\n  @allProblems={{@allProblems}}\n  @warnings={{this.warnings}}\n  @pathFilter={{concat \"meeting_events[\" @eventIndex \"].\" @pathFilter \"\"}}\n>\n  <Core::LabeledGrid::Row\n    @label={{@title}}\n    @icon={{@icon}}\n    @iconStyle={{@iconStyle}}\n  >\n    {{yield}}\n  </Core::LabeledGrid::Row>\n</Meeting::FilteredError>","moduleName":"teamtailor/components/meeting/event-rows/base.hbs","parseOptions":{"srcName":"teamtailor/components/meeting/event-rows/base.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  MeetingEventClass,
  VideoMeetingLocationClass,
} from 'teamtailor/classes/meetings';
import { IGraphQLErrorProblem } from 'teamtailor/utils/meetings/error-handling';
import IntlService from 'ember-intl/services/intl';

interface Args {
  event: MeetingEventClass;
  allProblems: IGraphQLErrorProblem[];
  eventIndex: number;
  readOnly: boolean;
  isPastEvent: boolean;
}

export default class MeetingEventRowsBase extends Component<Args> {
  @service declare intl: IntlService;

  get event(): MeetingEventClass {
    return this.args.event;
  }

  get warnings(): IGraphQLErrorProblem[] {
    const warnings = [];

    const attendeeCount = this.event.selfSchedule
      ? this.event.meetingEventAttendees.users.length + 1
      : this.event.meetingEventAttendees.count;

    if (
      (this.event.meetingEventLocation as VideoMeetingLocationClass | undefined)
        ?.videoMeeting?.providerName === 'teamtailor' &&
      attendeeCount > 4
    ) {
      warnings.push({
        path: [
          'meeting_events',
          this.args.eventIndex,
          'meeting_event_location',
        ],

        explanation: this.intl.t(
          'components.meetings.event_scheduler.warnings.teamtailor_attendee_limit'
        ),
      });
      warnings.push({
        path: ['meeting_events', this.args.eventIndex, 'users'],
        explanation: this.intl.t(
          'components.meetings.event_scheduler.warnings.teamtailor_attendee_limit'
        ),
      });
    }

    if (this.args.isPastEvent && !this.args.readOnly) {
      warnings.push({
        path: ['meeting_events', this.args.eventIndex, 'booked_slot'],
        explanation: this.intl.t(
          'components.meetings.event_scheduler.warnings.past_event'
        ),
      });
    }

    return warnings;
  }
}
