import { get } from 'teamtailor/utils/get';
import Store from '@ember-data/store';
import RequisitionFlowModel from 'teamtailor/models/requisition-flow';
import JobOfferApprovalFlowModel from 'teamtailor/models/job-offer-approval-flow';
import RequisitionStepModel from 'teamtailor/models/requisition-step';
import RequisitionFlowStepModel from 'teamtailor/models/requisition-flow-step';

interface cloneFlowFromStepsArgs {
  store: Store;
  steps: RequisitionStepModel[];
  modelName: 'requisition-flow' | 'job-offer-approval-flow';
}

export function cloneFlowFromSteps({
  store,
  steps,
  modelName,
}: cloneFlowFromStepsArgs): RequisitionFlowModel | JobOfferApprovalFlowModel {
  let approvalSteps: RequisitionFlowStepModel[];

  if (steps.length > 0) {
    approvalSteps = steps.map((_step) => {
      const approvers = get(_step, 'requisitionStepVerdicts')
        .map((verdict) => {
          const userId = get(verdict.user, 'id');
          if (userId) {
            return store.peekRecord('user', userId);
          } else {
            return undefined;
          }
        })
        .filter((user) => !!user);

      return store.createRecord('requisition-flow-step', {
        stepIndex: get(_step, 'stepIndex'),
        approvalPolicy: get(_step, 'approvalPolicy'),
        isEditable: get(_step, 'isEditable'),
        users: approvers,
      });
    });
  } else {
    approvalSteps = [
      store.createRecord('requisition-flow-step', { stepIndex: 0 }),
    ];
  }

  return store.createRecord(modelName, {
    title: 'Custom flow',
    steps: approvalSteps,
  });
}

interface cloneStepsFromFlowArgs {
  store: Store;
  flowSteps: RequisitionFlowStepModel[];
  usersField?: 'users' | 'pickedUsers';
}

export function cloneStepsFromFlow({
  store,
  flowSteps,
  usersField = 'pickedUsers',
}: cloneStepsFromFlowArgs): RequisitionStepModel[] {
  return flowSteps
    .filter((_step) => !get(_step, 'isDeleted'))
    .map((_step) => {
      const verdicts = get(_step, usersField).map((user) => {
        return store.createRecord('requisition-step-verdict', {
          user,
        });
      });

      return store.createRecord('requisition-step', {
        stepIndex: get(_step, 'stepIndex'),
        approvalPolicy: get(_step, 'approvalPolicy'),
        isEditable: get(_step, 'isEditable'),
        requisitionStepVerdicts: verdicts,
      });
    });
}
