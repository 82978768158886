import BaseJobOfferRoute from './base-route';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { get, setProperties } from '@ember/object';

export default class NewJobOfferRoute extends BaseJobOfferRoute {
  @service current;
  @service store;

  candidateRouteName = 'jobs.job.stages.index.candidate';

  model() {
    const { jobApplicationId } = this.paramsFor(this.candidateRouteName);

    let jobApplication = null;

    const { user } = this.current;
    const candidate = this.modelFor(this.candidateRouteName);
    const job = this.modelFor('jobs.job');

    if (jobApplicationId) {
      jobApplication = get(candidate, 'jobApplications').find(
        (jobApplication) => jobApplication.id === jobApplicationId
      );
    } else {
      jobApplication = get(candidate, 'jobApplications').find(
        (jobApplication) => jobApplication.jobId === parseInt(job.id)
      );
    }

    const jobOfferForm = this.store.createRecord('job-offer-form');
    const jobOffer = this.store.createRecord('job-offer', { users: [user] });

    setProperties(jobOffer, {
      jobApplication,
      jobOfferForm,
      user,
      candidate,
    });

    return jobOffer;
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    const displayJobPicker = !model.jobApplication.isTruthy;

    setProperties(controller, {
      displayJobPicker,
      fields: model.formFields,
    });

    if (!displayJobPicker) {
      controller.setSalaryFromJob();
      if (this.jobOfferApprovalsEnabled) {
        controller.setApprovalFlow();
      }
    }
  }

  async resetController(controller, isExiting) {
    if (isExiting) {
      let model = get(controller, 'jobOffer');
      if (model.isNew || model.status === 'preview') {
        model.destroyRecord();
      } else {
        const steps = await model.approvalSteps;
        if (isPresent(steps)) {
          for (const step of steps.slice()) {
            step.rollbackAttributes();
          }
        }
      }

      controller.fields = [];
    }
  }

  get jobOfferApprovalsEnabled() {
    return get(this.current.company, 'jobOfferApprovalsEnabled');
  }
}
