import Modifier from 'ember-modifier';
import { action } from '@ember/object';
import { registerDestructor } from '@ember/destroyable';
import ClipboardJS from 'clipboard';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import FlashMessageService from 'teamtailor/services/flash-message';

function cleanup(instance: CopyValueModifier) {
  const { clipboard } = instance;
  clipboard?.destroy();
}

type OnClick = ((event: ClipboardJS.Event | MouseEvent) => void) | undefined;

interface ModifierSignature {
  Element: HTMLElement;
  Args: {
    Positional: [OnClick];
    Named: { value: 'string' };
  };
}

export default class CopyValueModifier extends Modifier<ModifierSignature> {
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;

  declare onClick?: OnClick;
  declare clipboard?: ClipboardJS;
  declare elem: HTMLElement;

  modify(elem: HTMLElement, [onClick]: [OnClick], { value = '' } = {}) {
    this.onClick = onClick;
    this.elem = elem;

    this.elem.setAttribute('data-clipboard-text', value);
    const clipboard = new ClipboardJS(this.elem, { container: this.elem });

    this.clipboard = clipboard;
    this.clipboard.on('success', this.handleCopy.bind(this));

    registerDestructor(this, cleanup);
  }

  @action handleCopy(event: ClipboardJS.Event | MouseEvent) {
    this.flashMessages.success(this.intl.t('common.copied_to_clipboard'));

    this.onClick?.(event);
  }
}
