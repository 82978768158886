import BaseProperty from './properties/base-property';
import ModelProperty from './properties/model-property';
import EnumProperty from './properties/enum-property';
import EventType from './event-types';
import DateProperty from './properties/date-property';
import CountProperty from './properties/count-property';
import AverageProperty from './properties/average-property';
import CountOccurrencesProperty from './properties/count-occurrences-property';
import CollectProperty from './properties/collect-property';
import MaxProperty from './properties/max-property';
import MinProperty from './properties/min-property';
import SumProperty from './properties/sum-property';
import DistinctCountProperty from './properties/distinct-count-property';
import { formatSecondsAsDecimalDays } from 'teamtailor/helpers/format-seconds-as-decimal-days';
import CustomFormatProperty from './properties/custom-format-property';
import CountryModel from 'teamtailor/models/country';
import AggregatedProperty from './properties/aggregated-property';
import Store from '@ember-data/store';
import SharedReportStoreService from 'teamtailor/services/shared-report-store';
import IntlService from 'ember-intl/services/intl';
import { CustomFieldModel } from 'teamtailor/models';
import createCustomFieldProperty from './properties/custom-field-property';

export const initializeProperties = async (
  store: Store | SharedReportStoreService,
  groupAnalytics: boolean,
  intl: IntlService,
  loadCustomFields: boolean = false
) => {
  const properties = [
    new DateProperty({
      store,
      id: 'date',
      icon: 'calendar',
      translationKey: 'insights.reports.builder.properties.date',
      queryTypes: ['event', 'pageview'],
      groupable: true,
      allowAsBaseForChartTypes: ['line', 'bar', 'table'],
    }),
    new DateProperty({
      store,
      id: 'week',
      icon: 'calendar',
      translationKey: 'insights.reports.builder.properties.week',
      queryTypes: ['event', 'pageview'],
      groupable: true,
      allowAsBaseForChartTypes: ['line', 'bar', 'table'],
    }),
    new DateProperty({
      store,
      id: 'month',
      icon: 'calendar',
      translationKey: 'insights.reports.builder.properties.month',
      queryTypes: ['event', 'pageview'],
      groupable: true,
      allowAsBaseForChartTypes: ['line', 'bar', 'table'],
    }),
    new DateProperty({
      store,
      id: 'year',
      icon: 'calendar',
      translationKey: 'insights.reports.builder.properties.year',
      queryTypes: ['event', 'pageview'],
      groupable: true,
      allowAsBaseForChartTypes: ['line', 'bar', 'table'],
    }),

    new ModelProperty({
      store,
      groupAnalytics,
      id: 'user_id',
      modelType: 'user',
      model: 'user',
      icon: 'user',
      displayProperty: 'nameOrEmail',
      translationKey: 'insights.reports.builder.properties.user',
      queryTypes: ['event'],
      groupable: true,
      allowAsBaseForChartTypes: ['bar', 'pie', 'table'],
    }),
    new ModelProperty({
      store,
      groupAnalytics,
      id: 'job_id',
      modelType: 'job',
      model: 'job',
      icon: 'briefcase-blank',
      displayProperty: 'title',
      translationKey: 'insights.reports.builder.properties.job',
      queryTypes: ['event', 'pageview'],
      groupable: true,
      allowAsBaseForChartTypes: ['bar', 'pie', 'table'],
    }),
    new ModelProperty({
      store,
      groupAnalytics,
      id: 'requisition_id',
      modelType: 'requisition',
      model: 'requisition',
      icon: 'clipboard-list',
      displayProperty: 'jobTitle',
      translationKey: 'insights.reports.builder.properties.requisition',
      queryTypes: ['event', 'pageview'],
      groupable: true,
      allowAsBaseForChartTypes: ['bar', 'pie', 'table'],
    }),
    new ModelProperty({
      store,
      groupAnalytics,
      id: 'department_id',
      modelType: 'department',
      model: 'department',
      icon: 'users',
      displayProperty: 'name',
      translationKey: 'insights.reports.builder.properties.department',
      queryTypes: ['event', 'pageview'],
      groupable: true,
      allowAsBaseForChartTypes: ['bar', 'pie', 'table'],
    }),
    new ModelProperty({
      store,
      groupAnalytics,
      id: 'location_id',
      modelType: 'location',
      model: 'location',
      icon: 'location-dot',
      displayProperty: 'nameOrCity',
      translationKey: 'insights.reports.builder.properties.location',
      queryTypes: ['event', 'pageview'],
      groupable: true,
      groupByAddsExistsFilter: true,
      allowAsBaseForChartTypes: ['bar', 'pie', 'table'],
    }),
    new ModelProperty({
      store,
      groupAnalytics,
      id: 'promotion_id',
      modelType: 'promotion',
      model: 'promotion',
      icon: 'bullhorn',
      displayProperty: 'humanName',
      translationKey: 'insights.reports.builder.properties.promotion',
      queryTypes: ['event', 'pageview'],
      eventQueryName: 'jobApplicationPromotionId',
      eventQueryEnum: 'PROMOTION_ID',
      pageviewQueryEnum: 'PROMOTION',
      groupable: true,
      allowAsBaseForChartTypes: ['bar', 'pie', 'table'],
    }),
    new BaseProperty({
      store,
      id: 'reject_reason_reason',
      icon: 'ban',
      translationKey: 'insights.reports.builder.properties.reject_reason',
      queryTypes: ['event'],
      groupable: true,
      groupByAddsExistsFilter: true,
      eventTypes: [EventType.REJECTED],
      allowAsBaseForChartTypes: ['bar', 'pie', 'table'],
      aggregatedProperties: [
        new CollectProperty({
          store,
          id: 'reject_reason',
          icon: 'ban',
          field: 'REJECT_REASON_REASON',
          filters: `{ eventType: { equals: ${EventType.REJECTED} } }`,
          category: 'rejected',

          eventTypes: [EventType.REJECTED],
          queryTypes: ['event'],
        }),
      ],
    }),
    new ModelProperty({
      store,
      groupAnalytics,
      id: 'page_id',
      modelType: 'page',
      model: 'page',
      icon: 'link',
      displayProperty: 'titleWithFallback',
      translationKey: 'insights.reports.builder.properties.page',
      queryTypes: ['pageview'],
      groupable: true,
      allowAsBaseForChartTypes: ['bar', 'pie', 'table'],
    }),
    new BaseProperty({
      store,
      id: 'stage_type',
      icon: 'briefcase-blank',
      translationKey: 'insights.reports.builder.properties.stage_type',
      queryTypes: ['event'],
      groupable: true,
      groupByAddsExistsFilter: true,
      allowAsBaseForChartTypes: ['bar', 'pie', 'table'],
    }),
    new BaseProperty({
      store,
      id: 'source',
      icon: 'link',
      translationKey: 'insights.reports.builder.properties.source',
      queryTypes: ['event', 'pageview'],
      groupable: true,
      groupByAddsExistsFilter: true,
      eventQueryName: 'jobApplicationSource',
      eventQueryEnum: 'JOB_APPLICATION_SOURCE',
      eventTypes: [EventType.APPLIED],
      allowAsBaseForChartTypes: ['bar', 'pie', 'table'],
    }),
    new EnumProperty({
      store,
      id: 'device_type',
      icon: 'laptop-mobile',
      translationKey: 'insights.reports.builder.properties.device_type',
      queryTypes: ['event', 'pageview'],
      groupable: true,
      groupByAddsExistsFilter: true,
      eventQueryName: 'jobApplicationDeviceType',
      eventQueryEnum: 'DEVICE_TYPE',
      enumValues: ['desktop', 'mobile', 'tablet', 'bot', 'unknown'],
      eventTypes: [EventType.APPLIED],
      allowAsBaseForChartTypes: ['bar', 'pie', 'table'],
    }),
    new ModelProperty({
      store,
      groupAnalytics,
      id: 'nurture_campaign_id',
      modelType: 'nurture_campaign',
      model: 'nurture-campaign',
      icon: 'message-heart',
      iconStyle: 'kit',
      displayProperty: 'displayName',
      translationKey: 'insights.reports.builder.properties.nurture_campaign',
      queryTypes: ['event'],
      groupable: true,
      groupByAddsExistsFilter: true,
      eventTypes: [
        EventType.NURTURE_CAMPAIGN_RECIPIENT_CREATED,
        EventType.NURTURE_CAMPAIGN_EXITED,
        EventType.NURTURE_CAMPAIGN_STEP_SENT,
      ],

      aggregatedProperties: [],
      allowAsBaseForChartTypes: ['bar', 'pie', 'table'],
    }),

    new BaseProperty({
      store,
      id: 'nurture_campaign_exit_reason',
      icon: 'message-heart',
      iconStyle: 'kit',
      translationKey:
        'insights.reports.builder.properties.nurture_campaign_exit_reason',

      queryTypes: ['event'],
      eventTypes: [EventType.NURTURE_CAMPAIGN_EXITED],
      groupable: true,
      groupByAddsExistsFilter: true,
      allowAsBaseForChartTypes: ['bar', 'pie', 'table'],
      aggregatedProperties: [
        new CollectProperty({
          store,
          id: 'nurture_campaign_exit_reason',
          icon: 'message-heart',
          iconStyle: 'kit',
          category: 'nurture_campaign',
          field: 'NURTURE_CAMPAIGN_EXIT_REASON',
          filters: `{ eventType: { equals: ${EventType.NURTURE_CAMPAIGN_EXITED} } }`,

          eventTypes: [EventType.NURTURE_CAMPAIGN_EXITED],
          queryTypes: ['event'],
        }),
      ],
    }),

    new BaseProperty({
      store,
      id: 'path',
      icon: 'link',
      translationKey: 'insights.reports.builder.properties.path',
      queryTypes: ['pageview'],
      groupable: true,
      groupByAddsExistsFilter: true,
      allowAsBaseForChartTypes: ['bar', 'pie', 'table'],
    }),

    new CustomFormatProperty({
      store,
      id: 'geolocation_country',
      icon: 'location-dot',
      translationKey: 'insights.reports.builder.properties.geolocation_country',
      queryTypes: ['pageview'],
      groupable: true,
      groupByAddsExistsFilter: true,
      allowAsBaseForChartTypes: ['bar', 'pie', 'table'],
      preload: async () => {
        const countries = store.peekAll('country');
        if (!countries.length) {
          await store.findAll('country');
        }

        return Promise.resolve();
      },

      needsPreload: true,
      customFormatter: (value) => {
        const countries = store.peekAll('country') as CountryModel[];

        const country = countries.find((c) => c.code === value);
        return country ? country.name : value;
      },
    }),
    new BaseProperty({
      store,
      id: 'geolocation_city',
      icon: 'location-dot',
      translationKey: 'insights.reports.builder.properties.geolocation_city',

      queryTypes: ['pageview'],
      groupable: true,
      groupByAddsExistsFilter: true,
      allowAsBaseForChartTypes: ['bar', 'pie', 'table'],
    }),

    new BaseProperty({
      store,
      id: 'job_offer_status',
      translationKey: `insights.reports.builder.properties.job_offer_status`,

      queryTypes: ['event'],
      groupable: true,
      icon: 'handshake',

      eventQueryName: 'eventType',
      eventQueryEnum: 'EVENT_TYPE',

      basePropertyValueTranslationKey: 'insights.reports.builder.properties',

      eventTypes: [
        EventType.JOB_OFFER_ACCEPTED,
        EventType.JOB_OFFER_CREATED,
        EventType.JOB_OFFER_DECLINED,
        EventType.JOB_OFFER_SENT,
      ],
    }),

    new BaseProperty({
      store,
      id: 'stage_type_moved_from',
      translationKey: `insights.reports.builder.properties.stage_type_moved_from`,

      queryTypes: ['event'],
      groupable: true,
      icon: 'arrow-right',

      eventQueryName: 'stageFromType',
      eventQueryEnum: 'FROM_STAGE_TYPE',

      groupByAddsExistsFilter: true,
      basePropertyValueTranslationKey: 'job.stage_types',

      eventTypes: [EventType.MOVED],
    }),

    new BaseProperty({
      store,
      id: 'stage_type_moved_to',
      translationKey: `insights.reports.builder.properties.stage_type_moved_to`,

      queryTypes: ['event'],
      groupable: true,
      icon: 'arrow-right',

      eventQueryName: 'stageType',
      eventQueryEnum: 'STAGE_TYPE',

      groupByAddsExistsFilter: true,
      basePropertyValueTranslationKey: 'job.stage_types',

      eventTypes: [EventType.MOVED],
    }),

    new ModelProperty({
      store,
      groupAnalytics,
      id: 'candidate_tag_id',
      modelType: 'tag',
      model: 'tag',

      translationKey: `insights.reports.builder.properties.candidate_tag`,

      queryTypes: ['event'],
      groupable: true,
      icon: 'tag',
      displayProperty: 'name',

      eventQueryName: 'candidateTagName',
      eventQueryEnum: 'CANDIDATE_TAG_NAME',

      aggregatedProperties: [
        new DistinctCountProperty({
          store,
          id: 'candidate_tag_id',
          icon: 'tag',
          category: 'candidate_status',

          customDistinctKey: 'CANDIDATE_ID',

          onlyCompatibleWithParent: true,

          propertyTranslationKey:
            'insights.reports.builder.properties.tagged_candidates',

          queryTypes: ['event'],
        }),
      ],
    }),

    new ModelProperty({
      store,
      groupAnalytics,
      id: 'job_tag_id',
      modelType: 'tag',
      model: 'tag',

      translationKey: `insights.reports.builder.properties.job_tag`,

      queryTypes: ['event'],
      groupable: true,
      icon: 'tag',
      displayProperty: 'name',
    }),

    // End of groupables

    new ModelProperty({
      store,
      groupAnalytics,
      id: 'sourced_candidate',
      modelType: 'candidate',
      model: 'candidate',
      displayProperty: 'nameOrEmail',
      translationKey: 'insights.reports.builder.properties.sourced_candidates',
      queryTypes: ['event'],
      aggregatedProperties: [
        new CountOccurrencesProperty({
          store,
          id: 'sourced_candidates',
          icon: 'circle-user',
          category: 'candidate_source',
          filters: `{ eventType: { equals: ${EventType.SOURCED.type} }, jobApplicationId: { exists: false } }`,

          eventTypes: [EventType.SOURCED],
          queryTypes: ['event'],
        }),
        new CollectProperty({
          store,
          id: 'sourced_candidates',
          icon: 'circle-user',
          category: 'candidate_source',
          field: 'CANDIDATE_ID',
          filters: `{ eventType: { equals: ${EventType.SOURCED.type} }, jobApplicationId: { exists: false } }`,

          eventTypes: [EventType.SOURCED],
          queryTypes: ['event'],
        }),
      ],
    }),
    new ModelProperty({
      store,
      groupAnalytics,
      id: 'sourced_to_job',
      modelType: 'candidate',
      model: 'candidate',
      displayProperty: 'nameOrEmail',
      translationKey: 'insights.reports.builder.properties.sourced_to_job',
      queryTypes: ['event'],
      aggregatedProperties: [
        new CountOccurrencesProperty({
          store,
          id: 'sourced_to_job',
          icon: 'circle-user',
          category: 'candidate_source',
          filters: `{ eventType: { equals: ${EventType.SOURCED.type} }, jobApplicationId: { exists: true } }`,

          eventTypes: [EventType.SOURCED],
          queryTypes: ['event'],
        }),
        new CollectProperty({
          store,
          id: 'sourced_to_job',
          icon: 'circle-user',
          category: 'candidate_source',
          field: 'CANDIDATE_ID',
          filters: `{ eventType: { equals: ${EventType.SOURCED.type} }, jobApplicationId: { exists: true } }`,

          eventTypes: [EventType.SOURCED],
          queryTypes: ['event'],
        }),
      ],
    }),
    new BaseProperty({
      store,
      id: 'messages',
      translationKey: 'insights.reports.builder.properties.messages',
      queryTypes: ['event'],
      aggregatedProperties: [
        new CountOccurrencesProperty({
          store,
          id: 'messages',
          icon: 'messages',
          category: 'user_activity',
          filters: `{ eventType: { equals: ${EventType.MESSAGE.type} } }`,

          eventTypes: [EventType.MESSAGE],
          queryTypes: ['event'],
        }),
      ],
    }),
    new ModelProperty({
      store,
      groupAnalytics,
      id: 'rejected',
      modelType: 'candidate',
      model: 'candidate',
      displayProperty: 'nameOrEmail',
      translationKey: `insights.reports.builder.properties.rejected`,
      queryTypes: ['event'],
      aggregatedProperties: [
        new CountOccurrencesProperty({
          store,
          id: 'rejected_by_company',
          icon: 'ban',
          category: 'rejected',
          filters: `{ eventType: { equals: ${EventType.REJECTED} }, rejectReasonRejectedByCompany: { equals: true } }`,
          propertyTranslationKey: `insights.reports.builder.properties.rejected_by_company`,
          eventTypes: [EventType.REJECTED],
          queryTypes: ['event'],
        }),
        new CountOccurrencesProperty({
          store,
          id: 'rejected_by_candidate',
          icon: 'ban',
          category: 'rejected',
          filters: `{ eventType: { equals: ${EventType.REJECTED} }, rejectReasonRejectedByCompany: { equals: false } }`,
          propertyTranslationKey: `insights.reports.builder.properties.rejected_by_candidate`,
          eventTypes: [EventType.REJECTED],
          queryTypes: ['event'],
        }),
        new CollectProperty({
          store,
          id: 'rejected_by_company',
          icon: 'ban',
          category: 'rejected',
          field: 'CANDIDATE_ID',
          filters: `{ eventType: { equals: ${EventType.REJECTED} }, rejectReasonRejectedByCompany: { equals: true } }`,

          propertyTranslationKey:
            'insights.reports.builder.properties.by_company',

          eventTypes: [EventType.REJECTED],
          queryTypes: ['event'],
        }),
        new CollectProperty({
          store,
          id: 'rejected_by_candidate',
          icon: 'ban',
          category: 'rejected',
          field: 'CANDIDATE_ID',
          filters: `{ eventType: { equals: ${EventType.REJECTED} }, rejectReasonRejectedByCompany: { equals: false } }`,

          propertyTranslationKey:
            'insights.reports.builder.properties.by_candidate',

          eventTypes: [EventType.REJECTED],
          queryTypes: ['event'],
        }),
      ],
    }),

    ...[
      {
        eventType: EventType.APPLIED,
        category: 'candidate_source',
        icon: 'user',
      },
      { eventType: EventType.NOTE, category: 'user_activity', icon: 'note' },
      { eventType: EventType.SCORE, category: 'user_activity', icon: 'user' },
      {
        eventType: EventType.HIRED,
        category: 'candidate_status',
        icon: 'party-horn',
      },
      {
        eventType: EventType.MOVED,
        category: 'candidate_status',
        icon: 'arrow-right',
      },
      {
        eventType: EventType.REFERRAL,
        category: 'candidate_source',
        icon: 'referral',
        iconStyle: 'kit',
      },
      {
        eventType: EventType.CONNECTED,
        category: 'candidate_status',
        icon: 'handshake',
      },
      {
        eventType: EventType.NURTURE_CAMPAIGN_RECIPIENT_CREATED,
        category: 'nurture_campaign',
        icon: 'message-heart',
        iconStyle: 'kit',
      },
      {
        eventType: EventType.NURTURE_CAMPAIGN_EXITED,
        category: 'nurture_campaign',
        icon: 'message-heart',
        iconStyle: 'kit',
      },
      {
        eventType: EventType.NURTURE_CAMPAIGN_STEP_SENT,
        category: 'nurture_campaign',
        icon: 'message-heart',
        iconStyle: 'kit',
      },
    ].map(
      ({
        eventType,
        category,
        icon,
        iconStyle,
      }: {
        eventType: EventType;
        category: string;
        icon: string;
        iconStyle?: string;
      }) => {
        const id = eventType.toString().toLowerCase();
        return new ModelProperty({
          store,
          groupAnalytics,
          id,
          modelType: 'candidate',
          model: 'candidate',
          displayProperty: 'nameOrEmail',
          translationKey:
            id === 'applied'
              ? `insights.reports.builder.properties.applicants`
              : `insights.reports.builder.properties.${id}`,

          queryTypes: ['event'],
          aggregatedProperties: [
            new CountOccurrencesProperty({
              store,
              id,
              icon,
              iconStyle,
              category,
              propertyTranslationKey:
                id === 'applied'
                  ? `insights.reports.builder.properties.applied`
                  : undefined,

              filters: `{ eventType: { equals: ${eventType.type} } }`,
              eventTypes: [eventType],
              queryTypes: ['event'],
            }),
            new CollectProperty({
              store,
              id,
              icon,
              iconStyle,
              category,

              field: 'CANDIDATE_ID',
              filters: `{ eventType: { equals: ${eventType.type} } }`,

              eventTypes: [eventType],
              queryTypes: ['event'],
            }),
          ],
        });
      }
    ),
    ...['TIME_TO_HIRE', 'TIME_TO_REJECT', 'TIME_IN_STAGE'].map(
      (name: string) => {
        const id = name.toLowerCase();
        return new BaseProperty({
          store,
          id,
          translationKey: `insights.reports.builder.properties.${id}`,
          queryTypes: ['event'],
          aggregatedProperties: [
            new AverageProperty({
              store,
              id,
              icon: 'clock',
              category: 'time',
              eventTypes: [
                EventType.MOVED,
                EventType.HIRED,
                EventType.REJECTED,
              ],

              queryTypes: ['event'],
              customFormat: (value: number) =>
                formatSecondsAsDecimalDays([value, 2]),
            }),
            new MaxProperty({
              store,
              id,
              icon: 'clock',
              category: 'time',
              eventTypes: [
                EventType.MOVED,
                EventType.HIRED,
                EventType.REJECTED,
              ],

              queryTypes: ['event'],
              customFormat: (value: number) =>
                formatSecondsAsDecimalDays([value, 2]),
            }),
            new MinProperty({
              store,
              id,
              icon: 'clock',
              category: 'time',
              eventTypes: [
                EventType.MOVED,
                EventType.HIRED,
                EventType.REJECTED,
              ],

              queryTypes: ['event'],
              customFormat: (value: number) =>
                formatSecondsAsDecimalDays([value, 2]),
            }),
            new SumProperty({
              store,
              id,
              icon: 'clock',
              category: 'time',
              eventTypes: [
                EventType.MOVED,
                EventType.HIRED,
                EventType.REJECTED,
              ],

              queryTypes: ['event'],
              customFormat: (value: number) =>
                formatSecondsAsDecimalDays([value, 2]),
            }),
          ],
        });
      }
    ),
    new BaseProperty({
      store,
      id: 'review_rating',
      translationKey: 'insights.reports.builder.properties.review_rating',
      queryTypes: ['event'],
      aggregatedProperties: [
        new AverageProperty({
          store,
          id: 'review_rating',
          icon: 'star',
          category: 'user_activity',
          queryTypes: ['event'],
          eventTypes: [EventType.REVIEW],
        }),
        new MinProperty({
          store,
          id: `review_rating`,
          icon: 'star',
          category: 'user_activity',
          eventTypes: [EventType.REVIEW],
          queryTypes: ['event'],
        }),
        new MaxProperty({
          store,
          id: `review_rating`,
          icon: 'star',
          category: 'user_activity',
          eventTypes: [EventType.REVIEW],
          queryTypes: ['event'],
        }),
        new CountProperty({
          store,
          id: 'review_rating',
          icon: 'star',
          category: 'user_activity',
          queryTypes: ['event'],
          eventTypes: [EventType.REVIEW],
        }),
      ],
    }),

    new BaseProperty({
      store,
      id: 'nps_score',
      translationKey: 'insights.reports.builder.properties.nps_response_score',
      queryTypes: ['event'],
      aggregatedProperties: [
        new AverageProperty({
          store,
          id: 'nps_score',
          category: 'candidate_activity',
          queryTypes: ['event'],
          eventTypes: [EventType.NPS_RESPONSE],
          incompatibleBaseProperties: ['user'],
        }),
        new MinProperty({
          store,
          id: `nps_score`,
          category: 'candidate_activity',
          eventTypes: [EventType.NPS_RESPONSE],
          queryTypes: ['event'],
          incompatibleBaseProperties: ['user'],
        }),
        new MaxProperty({
          store,
          id: `nps_score`,
          category: 'candidate_activity',
          eventTypes: [EventType.NPS_RESPONSE],
          queryTypes: ['event'],
          incompatibleBaseProperties: ['user'],
        }),
        new CountProperty({
          store,
          id: 'nps_score',
          category: 'candidate_activity',
          queryTypes: ['event'],
          eventTypes: [EventType.NPS_RESPONSE],
          incompatibleBaseProperties: ['user'],
        }),
      ],
    }),

    ...[
      EventType.JOB_PUBLISHED,
      EventType.JOB_ARCHIVED,
      EventType.JOB_UNLISTED,
    ].map((eventType: EventType) => {
      const id = eventType.toString().toLowerCase();
      return new ModelProperty({
        store,
        groupAnalytics,
        id,
        modelType: 'job',
        model: 'job',
        displayProperty: 'title',
        translationKey: `insights.reports.builder.properties.${id}`,

        queryTypes: ['event'],
        aggregatedProperties: [
          new CountOccurrencesProperty({
            store,
            id,
            icon: 'briefcase-blank',
            category: 'job',
            filters: `{ eventType: { equals: ${eventType.type} } }`,
            eventTypes: [eventType],
            queryTypes: ['event'],
          }),
          new CollectProperty({
            store,
            id,
            icon: 'briefcase-blank',
            category: 'job',
            field: 'JOB_ID',
            filters: `{ eventType: { equals: ${eventType.type} } }`,

            eventTypes: [eventType],
            queryTypes: ['event'],
          }),
        ],
      });
    }),

    ...[
      EventType.JOB_OFFER_ACCEPTED,
      EventType.JOB_OFFER_CREATED,
      EventType.JOB_OFFER_DECLINED,
      EventType.JOB_OFFER_SENT,
    ]
      .map((eventType: EventType) => {
        const id = eventType.toString().toLowerCase();
        return [
          new ModelProperty({
            store,
            groupAnalytics,
            id,
            modelType: 'job',
            model: 'job',
            displayProperty: 'title',
            translationKey: `insights.reports.builder.properties.${id}`,

            queryTypes: ['event'],
            aggregatedProperties: [
              new CountOccurrencesProperty({
                store,
                id,
                icon: 'handshake',
                category: 'job_offer',
                filters: `{ eventType: { equals: ${eventType.type} } }`,
                eventTypes: [eventType],
                queryTypes: ['event'],
              }),
              new CollectProperty({
                store,
                id,
                icon: 'handshake',
                category: 'job_offer',
                field: 'JOB_ID',
                filters: `{ eventType: { equals: ${eventType.type} } }`,

                propertyTranslationKey:
                  'insights.reports.builder.properties.job',

                eventTypes: [eventType],
                queryTypes: ['event'],
              }),
            ],
          }),
          new ModelProperty({
            store,
            groupAnalytics,
            id: `${id}_candidate`,
            modelType: 'candidate',
            model: 'candidate',
            displayProperty: 'nameOrEmail',
            translationKey: `insights.reports.builder.properties.${id}`,

            queryTypes: ['event'],
            aggregatedProperties: [
              new CollectProperty({
                store,
                id: `${id}_candidate`,
                icon: 'handshake',
                category: 'job_offer',
                field: 'CANDIDATE_ID',
                filters: `{ eventType: { equals: ${eventType.type} } }`,

                propertyTranslationKey:
                  'insights.reports.builder.properties.candidate',

                eventTypes: [eventType],
                queryTypes: ['event'],
              }),
            ],
          }),
        ];
      })
      .flat(),

    new BaseProperty({
      store,
      id: 'visits',
      translationKey: 'insights.reports.builder.properties.visits',
      queryName: 'session_id',
      queryTypes: ['pageview'],
      aggregatedProperties: [
        new AggregatedProperty({
          store,
          id: 'visits',
          type: 'distinct-count',
          columnType: 'number',
          translationKey:
            'insights.reports.builder.aggregated_properties.count',

          icon: 'arrow-pointer',
          category: 'audience',
          queryTypes: ['pageview'],
          pageviewQueryName: 'visits',
          combinedQueryName: 'visits',
        }),
      ],
    }),

    new BaseProperty({
      store,
      id: 'pageviews',
      translationKey: 'insights.reports.builder.properties.pageviews',
      queryTypes: ['pageview'],
      aggregatedProperties: [
        new AggregatedProperty({
          store,
          id: 'pageviews',
          type: 'count',
          columnType: 'number',
          translationKey:
            'insights.reports.builder.aggregated_properties.count',

          icon: 'eye',
          category: 'audience',
          queryTypes: ['pageview'],
          pageviewQueryName: 'pageviews',
          combinedQueryName: 'pageviews',
        }),
      ],
    }),

    new BaseProperty({
      store,
      id: 'hire_quality_response_recommended',
      translationKey: `insights.reports.builder.properties.hire_quality_response_recommended`,

      queryTypes: ['event'],
      groupable: true,
      icon: 'star-user',
      iconStyle: 'kit',

      basePropertyValueTranslationKey:
        'insights.reports.builder.properties.hire_quality_response',

      eventTypes: [EventType.HIRE_QUALITY_RESPONSE],

      aggregatedProperties: [
        new CountOccurrencesProperty({
          store,
          id: 'hire_quality_response_recommended',
          icon: 'star-user',
          iconStyle: 'kit',
          category: 'user_activity',
          filters: `{ eventType: { equals: ${EventType.HIRE_QUALITY_RESPONSE.type} } }`,

          eventTypes: [EventType.HIRE_QUALITY_RESPONSE],
          queryTypes: ['event'],
        }),
        new CollectProperty({
          store,
          id: 'hire_quality_response_recommended_candidate',
          icon: 'star-user',
          iconStyle: 'kit',
          category: 'user_activity',
          field: 'CANDIDATE_ID',
          filters: `{ eventType: { equals: ${EventType.HIRE_QUALITY_RESPONSE.type} } }`,

          eventTypes: [EventType.HIRE_QUALITY_RESPONSE],
          queryTypes: ['event'],
        }),
        new CollectProperty({
          store,
          id: 'hire_quality_response_recommended_user',
          icon: 'star-user',
          iconStyle: 'kit',
          category: 'user_activity',
          field: 'USER_ID',
          filters: `{ eventType: { equals: ${EventType.HIRE_QUALITY_RESPONSE.type} } }`,

          eventTypes: [EventType.HIRE_QUALITY_RESPONSE],
          queryTypes: ['event'],
        }),
      ],
    }),

    new BaseProperty({
      store,
      id: 'hire_quality_response_id',
      translationKey:
        'insights.reports.builder.properties.hire_quality_response',

      queryTypes: ['event'],
      aggregatedProperties: [
        new DistinctCountProperty({
          store,
          id: 'hire_quality_response_recommended_true',
          category: 'user_activity',
          icon: 'star-user',
          iconStyle: 'kit',
          propertyTranslationKey:
            'insights.reports.builder.properties.hire_quality_response_recommended_true',

          filters: `{ eventType: { equals: ${EventType.HIRE_QUALITY_RESPONSE.type} }, hireQualityResponseRecommended: { equals: true } }`,

          eventTypes: [EventType.HIRE_QUALITY_RESPONSE],
          queryTypes: ['event'],
        }),
        new DistinctCountProperty({
          store,
          id: 'hire_quality_response_recommended_false',
          category: 'user_activity',
          icon: 'star-user',
          iconStyle: 'kit',
          propertyTranslationKey:
            'insights.reports.builder.properties.hire_quality_response_recommended_false',

          filters: `{ eventType: { equals: ${EventType.HIRE_QUALITY_RESPONSE.type} }, hireQualityResponseRecommended: { equals: false } }`,

          eventTypes: [EventType.HIRE_QUALITY_RESPONSE],
          queryTypes: ['event'],
        }),
      ],
    }),
  ];

  if (groupAnalytics) {
    properties.push(
      new ModelProperty({
        store,
        groupAnalytics,
        id: 'company_id',
        modelType: 'company',
        model: 'company',
        icon: 'building',
        displayProperty: 'name',
        translationKey: 'insights.reports.builder.properties.company',
        queryTypes: ['event', 'pageview'],
        groupable: true,
        allowAsBaseForChartTypes: ['line', 'bar', 'pie', 'table'],
      })
    );
  }

  if (loadCustomFields) {
    const customFields = (
      await store.query('custom-field', { groupAnalytics })
    ).toArray() as CustomFieldModel[];

    customFields.forEach((customField: CustomFieldModel) => {
      const property = createCustomFieldProperty(
        store,
        groupAnalytics,
        customField
      );

      properties.push(property);
    });
  }

  return properties.map((property) => {
    property.translate(intl);
    return property;
  });
};
