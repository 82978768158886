export const CUSTOM_FORM_CHANNELS = [
  'ams',
  'apec',
  'blocket',
  'blocket',
  'caterer_global',
  'caterer',
  'cv_library',
  'cv_online_ee',
  'cv_online_lt',
  'cv_online_lv',
  'cwjobs',
  'dice',
  'ejobs',
  'finn',
  'hainesattract',
  'hellowork',
  'jobborse',
  'jobsite',
  'jobteaser',
  'monster_ca',
  'monster_de',
  'monster_fr',
  'monster_ie',
  'monster_lu',
  'monster_nl',
  'monster_uk',
  'monster_us',
  'monster',
  'naukri',
  'oikotie',
  'reed',
  'stepstone_de',
  'stepstone_sverige',
  'totaljobs',
  'uptrail',
  'indeed_always_included',
] as const;

export const NON_EXISITNG_AGREEMENT_ADAPTER_CHANNELS = ['jobly_fi'];
