import Route from '@ember/routing/route';
import { getOwner } from '@ember/application';
import PipelineConversionReportFetcher from 'teamtailor/classes/analytics/report-pipeline-conversions';

export default class ConversionsRoute extends Route {
  model() {
    return {
      pipelineConversion: new PipelineConversionReportFetcher({
        container: getOwner(this),
      }).fetchTask,
    };
  }
}
