import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"common.tags\"}}\n  </span>\n\n  <Core::TagPicker\n    @nullOption={{t \"search.all\"}}\n    @isFullWidth={{true}}\n    @onSelect={{this.onChange}}\n    @taggableType={{or @taggableType \"Job\"}}\n    @placeholder={{t \"jobs.index.all_tags\"}}\n    @selected={{if @hasChangedValue this.selected.value}}\n    @disableCreate={{true}}\n    @isMultiple={{false}}\n  />\n</label>", {"contents":"<label class=\"no-global-styles flex cursor-pointer flex-col gap-4\">\n  <span class={{cn \"body-text-s-medium self-start\"}}>\n    {{t \"common.tags\"}}\n  </span>\n\n  <Core::TagPicker\n    @nullOption={{t \"search.all\"}}\n    @isFullWidth={{true}}\n    @onSelect={{this.onChange}}\n    @taggableType={{or @taggableType \"Job\"}}\n    @placeholder={{t \"jobs.index.all_tags\"}}\n    @selected={{if @hasChangedValue this.selected.value}}\n    @disableCreate={{true}}\n    @isMultiple={{false}}\n  />\n</label>","moduleName":"teamtailor/components/sidebar/filters/tags.hbs","parseOptions":{"srcName":"teamtailor/components/sidebar/filters/tags.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { trackedFunction } from 'ember-resources/util/function';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import TagModel from 'teamtailor/models/tag';

export interface Args {
  selected: string;
  onChange: (tag: TagModel | null) => void;
}

export default class extends Component<Args> {
  @service declare store: Store;

  selected = trackedFunction(this, async () => {
    if (!this.args.selected) {
      return null;
    }

    const tag = await this.store.findRecord('tag', this.args.selected);
    return tag;
  });

  @action
  onChange(tag: TagModel): void {
    if (tag.id === this.args.selected) {
      this.args.onChange(null);
    } else {
      this.args.onChange(tag);
    }
  }
}
