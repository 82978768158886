import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

export default class SnapchatController extends Controller {
  @service intercom;
  @service router;
  @service store;
  @service flashMessages;

  queryParams = ['currency'];

  @tracked selectedBudget = null;
  @tracked currency = null;

  get promotion() {
    return get(this, 'model.promotion');
  }

  get page() {
    return get(this, 'model.page');
  }

  get channel() {
    return get(this, 'model.channel');
  }

  @action
  handleBudgetChange(budget) {
    this.selectedBudget = budget;
    if (budget === 'custom') {
      this.openIntercom();
    }
  }

  @action
  pick() {
    const price = get(this, 'selectedBudget.amount');
    const currency = get(this, 'currency');
    const channel = get(this, 'channel');
    const page = get(this, 'page');
    const invoiceAddress = get(this, 'promotion.invoiceAddress');

    this.store
      .createRecord('promotion', {
        invoiceAddress,
        channel,
        page,
        price,
        currency,
      })
      .save()
      .then(() => {
        this.router.transitionTo('content.editor.index');
        get(this, 'flashMessages').success('Your promotion has been created');
      });
  }

  @action
  handleTakeoverCloseAction() {
    this.router.transitionTo('content.editor.index');
  }

  openIntercom(event) {
    const message = get(this, 'message') ? `${get(this, 'message')}\n\n` : null;
    event.preventDefault();
    get(this, 'intercom').showNewMessage(message);
  }
}
