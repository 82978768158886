import BaseJobOfferRoute from './base-route';
import { get, setProperties } from '@ember/object';
import { questionsToFormFields } from 'teamtailor/utils/form-builder';
import { inject as service } from '@ember/service';

export default class EditJobOfferRoute extends BaseJobOfferRoute {
  @service store;

  model(params) {
    return this.store.findRecord('jobOffer', params.job_offer_id);
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    setProperties(controller, {
      fields: questionsToFormFields(
        get(model, 'jobOfferForm.sortedFormQuestions').filter((q) => !q.isNew)
      ),
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.jobOffer.rollbackAttributes();
      controller.fields = [];
    }
  }
}
