import Model, {
  belongsTo,
  attr,
  hasMany,
  AsyncBelongsTo,
  AsyncHasMany,
} from '@ember-data/model';
import SectionModel from './section';
import TagModel from './tag';

export default class ImageModel extends Model {
  @belongsTo('section') declare section: AsyncBelongsTo<SectionModel>;
  @hasMany('tag', { async: true }) declare tags: AsyncHasMany<TagModel>;

  @attr('string') declare alt: string;
  @attr('date') declare createdAt: Date;
  @attr('string') declare filename: string;
  @attr('number') declare height: number;
  @attr('number') declare width: number;
  @attr('string') declare s3Image: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    image: ImageModel;
  }
}
