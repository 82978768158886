import TranslationModel from 'teamtailor/models/translation';
import {
  AsyncBelongsTo,
  AsyncHasMany,
  SyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import CannedResponseModel from 'teamtailor/models/canned-response';
import TagModel from 'teamtailor/models/tag';
import QuestionModel from 'teamtailor/models/question';

export default class TranslationCannedResponseModel extends TranslationModel {
  @attr('string') declare languageCode: string;
  @attr('string') declare body: string;
  @attr('string') declare subject: string;
  @belongsTo('canned-response')
  declare cannedResponse: AsyncBelongsTo<CannedResponseModel>;

  @hasMany('tag') declare tags: AsyncHasMany<TagModel>;
  @hasMany('question', { async: false })
  declare questions: SyncHasMany<QuestionModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'translation/canned-response': TranslationCannedResponseModel;
  }
}
