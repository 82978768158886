import Store from '@ember-data/store';
import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import JobsRequisitionApprovalFlowEditRoute from 'teamtailor/routes/jobs/requisition/approval-flow/edit';
import Current from 'teamtailor/services/current';
import { cloneStepsFromFlow } from 'teamtailor/utils/approval-flows';
import { get } from 'teamtailor/utils/get';
import { ModelFrom } from 'teamtailor/utils/type-utils';

export default class JobsRequisitionApprovalFlowEditController extends Controller {
  declare model: ModelFrom<JobsRequisitionApprovalFlowEditRoute>;

  @service declare current: Current;
  @service declare router: RouterService;
  @service declare store: Store;

  get company() {
    return this.current.company;
  }

  @action
  async handleSave() {
    const clonedSteps = await get(this.model.flow, 'steps');
    const requisitionSteps = cloneStepsFromFlow({
      store: this.store,
      flowSteps: clonedSteps.toArray(),
    });

    const requisitionStepsBeforeSave = get(
      this.model.requisition,
      'steps'
    ).toArray();

    try {
      set(this.model.requisition, 'steps', requisitionSteps);
      await this.model.requisition.updateFlow();
      requisitionStepsBeforeSave.toArray().invoke('unloadRecord');
      this.handleExiting();
    } catch {
      set(this.model.requisition, 'steps', requisitionStepsBeforeSave);
    }
  }

  @action
  handleExiting() {
    this.router.transitionTo('jobs.requisition.approval-flow');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'jobs.requisition.approval-flow.edit': JobsRequisitionApprovalFlowEditController;
  }
}
