import Service, { inject as service } from '@ember/service';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import fuzzysort from 'fuzzysort';

export default class SearchableRoutesService extends Service {
  @service intl;
  @service store;

  allRoutes = [];

  fetchRoutes = dropTask(async () => {
    if (this.allRoutes.length) return;

    const adapter = this.store.adapterFor('application');
    const url = adapter.buildURL('searchable-routes');
    let routes = await adapter.ajax(url);
    this.allRoutes.setObjects(routes);
  });

  @computed('intl.locale', 'allRoutes.[]')
  get routes() {
    return this.allRoutes.map((route) => {
      if (typeof route.translationKey === 'string') {
        route.translationKey = [route.translationKey];
      }

      route.title = this.intl.t(route.translationKey[0]);
      route.searchable = fuzzysort.prepare(
        `${route.translationKey
          .map((tk) => this.intl.t(tk).toLowerCase())
          .join(' ')}`
      );

      route.type = 'page';
      return route;
    });
  }
}
