import Store from '@ember-data/store';
import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import { LocationModel } from 'teamtailor/models';
import FlashMessageService from 'teamtailor/services/flash-message';
import TtAlertService from 'teamtailor/services/tt-alert';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import SeettingsLocationsEditController from 'teamtailor/routes/settings/locations/edit';
import { allSettled, task } from 'ember-concurrency';
import TranslationLocationModel from 'teamtailor/models/translation/location';
import { get } from 'teamtailor/utils/get';
import Current from 'teamtailor/services/current';

export default class SettingsLocationsEditController extends Controller {
  declare model: ModelFrom<SeettingsLocationsEditController>;

  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;
  @service declare current: Current;

  get defaultLanguageCode() {
    const careerSite = get(this.current.company, 'defaultCareerSite');
    return get(careerSite, 'languageCode');
  }

  get titleText() {
    return this.intl.t('settings.locations.edit_location', {
      name: this.model.nameOrCity,
      htmlSafe: true,
    });
  }

  saveTranslation = task(async (model: TranslationLocationModel) => {
    return model.save();
  });

  @action
  onChange(location: LocationModel) {
    set(this, 'model', location);
  }

  @action
  async handleSave() {
    try {
      this.model.save();
    } catch {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
      return Promise.reject();
    }

    // Note: We must exclude default language from translations or else the previously updated page publication will be overwrittena
    const translationTasks = get(this.model, 'translations')
      .filter((t) => t.languageCode !== this.defaultLanguageCode)
      .map((t) => {
        return this.saveTranslation.perform(t);
      });

    const result = await allSettled(translationTasks);

    if (result.isAny('state', 'rejected')) {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
      return Promise.reject();
    } else {
      this.flashMessages.success(
        this.intl.t('settings.locations.location_updated')
      );
      this.router.replaceWith('settings.locations.index');
      return Promise.resolve();
    }
  }

  @action
  handleDestroy() {
    this.ttAlert.confirm(
      this.intl.t('settings.locations.are_you_sure_deleting'),
      () => {
        this.model
          .destroyRecord()
          .then(() => {
            this.flashMessages.success(
              this.intl.t('settings.locations.location_deleted')
            );
            this.router.transitionTo('settings.locations.index');
          })
          .catch(() => {
            this.flashMessages.error(
              this.intl.t('common.something_went_wrong')
            );
          });
      }
    );
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.locations.edit': SettingsLocationsEditController;
  }
}
