import Component from '@glimmer/component';
import { action } from '@ember/object';
import { ConnectMediaItemModel } from 'teamtailor/models';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import TtAlertService from 'teamtailor/services/tt-alert';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';

type MediaItemArgs = {
  Args: {
    mediaItem: ConnectMediaItemModel;
    onSave: () => Promise<void>;
  };
};

export default class BaseMediaComponent extends Component<MediaItemArgs> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare ttAlert: TtAlertService;

  @tracked showImageLibrary = this.args.mediaItem.isNew;

  get mediaItem() {
    return this.args.mediaItem;
  }

  @action
  handleDelete() {
    this.ttAlert.confirm(
      this.intl.t('content.editor.section.items.delete_confirm'),
      () => {
        this.args.mediaItem.deleteRecord();
        this.args.onSave();
      },
      () => {},
      {
        confirmButtonText: this.intl.t('common.delete'),
      }
    );
  }
}
