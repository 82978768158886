import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';

export default class DepartmentSettingsRoute extends Route {
  model() {
    return get(
      this.modelFor('content.index.page'),
      'pagePublication.careerSite.content'
    );
  }
}
