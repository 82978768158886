export type CompetenceWeightsType = {
  low: number;
  medium: number;
  high: number;
};

export const COMPETENCE_WEIGHTS: CompetenceWeightsType = {
  low: 20,
  medium: 50,
  high: 80,
};
