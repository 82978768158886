import Model, { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import JobModel from './job';
import InterviewKitModel from './interview-kit';
import StageModel from './stage';

export default class PickedInterviewKitModel extends Model {
  @attr('number')
  declare rowOrder: number;

  @attr('number')
  declare rowOrderPosition: number | undefined;

  @attr('string')
  declare interviewKitId: string;

  @attr('boolean', { defaultValue: false })
  declare isHidden: boolean;

  @belongsTo('job', { async: true })
  declare job: AsyncBelongsTo<JobModel>;

  @belongsTo('stage', { async: true })
  declare stage: AsyncBelongsTo<StageModel>;

  @belongsTo('interview-kit', { async: true })
  declare interviewKit: AsyncBelongsTo<InterviewKitModel>;

  get position(): number {
    const { rowOrderPosition, rowOrder } = this;
    return rowOrderPosition === undefined ? rowOrder : rowOrderPosition;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'picked-interview-kit': PickedInterviewKitModel;
  }
}
