import { TableColumn } from 'teamtailor/components/table/container';

export const TABLE_COLUMNS: TableColumn[] = [
  {
    label: 'settings.canned_responses.template_name',
    sortable: true,
    sortColumn: 'name',
    defaultSortOrder: 'asc',
    sortOrder: 1,
  },
  {
    label: 'settings.canned_responses.subject',
    sortable: true,
    sortColumn: 'subject',
    defaultSortOrder: 'asc',
    sortOrder: 2,
  },
  {
    label: 'settings.canned_responses.default_templates',
    sortable: false,
    sortOrder: 3,
  },
  {
    label: 'common.tags',
    sortable: false,
    sortOrder: 5,
  },
];

export const TABLE_LOADING_COLUMNS = [
  { width: 'm' },
  { width: 's' },
  { width: 's' },
  { width: 's' },
];
