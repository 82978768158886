import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<header\n  ...attributes\n  class={{class-names\n    \"flex min-w-0 flex-[0_0_64px] items-center gap-8 border-b border-transparent border-b-neutral bg-canvas px-24\"\n    (if (and @isLoading (not this.showOnLoad)) \"h-0 basis-0 overflow-hidden\")\n  }}\n>\n  {{#if this.channelOrPartner.logotype}}\n    <div>\n      <img\n        src={{image-url this.channelOrPartner.logotype \"logotype_square\"}}\n        alt={{this.channelOrPartner.name}}\n        class=\"h-24\"\n      />\n    </div>\n  {{/if}}\n\n  {{#if this.text}}\n    <h1\n      class={{class-names \"heading-4 truncate max-[640px]:whitespace-normal\"}}\n    >\n      {{this.text}}\n    </h1>\n  {{/if}}\n  {{yield}}\n</header>", {"contents":"<header\n  ...attributes\n  class={{class-names\n    \"flex min-w-0 flex-[0_0_64px] items-center gap-8 border-b border-transparent border-b-neutral bg-canvas px-24\"\n    (if (and @isLoading (not this.showOnLoad)) \"h-0 basis-0 overflow-hidden\")\n  }}\n>\n  {{#if this.channelOrPartner.logotype}}\n    <div>\n      <img\n        src={{image-url this.channelOrPartner.logotype \"logotype_square\"}}\n        alt={{this.channelOrPartner.name}}\n        class=\"h-24\"\n      />\n    </div>\n  {{/if}}\n\n  {{#if this.text}}\n    <h1\n      class={{class-names \"heading-4 truncate max-[640px]:whitespace-normal\"}}\n    >\n      {{this.text}}\n    </h1>\n  {{/if}}\n  {{yield}}\n</header>","moduleName":"teamtailor/components/core/modal/header.hbs","parseOptions":{"srcName":"teamtailor/components/core/modal/header.hbs"}});
import Component from '@glimmer/component';
import ChannelModel from 'teamtailor/models/channel';
import PartnerModel from 'teamtailor/models/partner';
import { get } from 'teamtailor/utils/get';

interface CoreModalMainArgs {
  channel?: ChannelModel;
  partner?: PartnerModel;
  text?: string;
  showOnLoad?: boolean;
}

export default class CoreModalHeader extends Component<CoreModalMainArgs> {
  get showOnLoad() {
    return this.args.showOnLoad ?? !!this.text;
  }

  get channelOrPartner() {
    return this.args.channel ?? this.args.partner;
  }

  get text() {
    return this.args.text ?? get(this.channelOrPartner, 'name');
  }
}
