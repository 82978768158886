import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Molecules::ConversionRate\n  ...attributes\n  @data={{array this.applications this.sessions}}\n  @dates={{this.dates}}\n  @conversionRate={{this.conversionRate}}\n  @dateRange={{@dateRange}}\n  @previousConversionRate={{this.previousConversionRate}}\n  @onShowExportModal={{this.onShowExportModal}}\n  @currentSessions={{this.currentSessions}}\n  @previousSessions={{this.previousSessions}}\n  @currentApplications={{this.currentApplications}}\n  @previousApplications={{this.previousApplications}}\n  @loading={{or (not this.fetchData.lastSuccessful) this.fetchData.isRunning}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>", {"contents":"<Insights::Molecules::ConversionRate\n  ...attributes\n  @data={{array this.applications this.sessions}}\n  @dates={{this.dates}}\n  @conversionRate={{this.conversionRate}}\n  @dateRange={{@dateRange}}\n  @previousConversionRate={{this.previousConversionRate}}\n  @onShowExportModal={{this.onShowExportModal}}\n  @currentSessions={{this.currentSessions}}\n  @previousSessions={{this.previousSessions}}\n  @currentApplications={{this.currentApplications}}\n  @previousApplications={{this.previousApplications}}\n  @loading={{or (not this.fetchData.lastSuccessful) this.fetchData.isRunning}}\n  {{did-insert (perform this.fetchData)}}\n  {{did-update (perform this.fetchData) @dateRange}}\n/>","moduleName":"teamtailor/components/insights/widgets/job-visits.hbs","parseOptions":{"srcName":"teamtailor/components/insights/widgets/job-visits.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { percentageOf } from 'teamtailor/utils/analytics';
import { gql } from '@apollo/client/core';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';

const VISITS_QUERY = gql`
  query JobVisitsQuery(
    $dateRange: DateRangeAttributes!
    $previousDateRange: DateRangeAttributes!
    $jobIds: [ID!]
  ) {
    currentApplications: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [APPLIED]
    ) {
      count
      perDate: aggregated(groupBy: DATE) {
        date
        count
      }
    }

    previousApplications: eventQuery(
      dateRange: $previousDateRange
      jobIds: $jobIds
      eventTypes: [APPLIED]
    ) {
      count
    }

    currentPageviews: pageviewQuery(dateRange: $dateRange, jobIds: $jobIds) {
      sessions: distinctCount(
        field: SESSION_ID
        filters: { path: { match: "(/jobs/|/jobs/internal/)([a-z0-9-]*)$" } }
      )
      perDate: aggregated(groupBy: DATE) {
        date
        pageviews: count
        sessions: distinctCount(
          field: SESSION_ID
          filters: { path: { match: "(/jobs/|/jobs/internal/)([a-z0-9-]*)$" } }
        )
      }
    }

    previousPageviews: pageviewQuery(
      dateRange: $previousDateRange
      jobIds: $jobIds
    ) {
      sessions: distinctCount(
        field: SESSION_ID
        filters: { path: { match: "(/jobs/|/jobs/internal/)([a-z0-9-]*)$" } }
      )
    }
  }
`;

const LONG_PERIOD_VISITS_QUERY = gql`
  query LongPeriodJobVisitsQuery(
    $dateRange: DateRangeAttributes!
    $previousDateRange: DateRangeAttributes!
    $jobIds: [ID!]
  ) {
    currentApplications: eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [APPLIED]
    ) {
      count
      perDate: aggregated(groupBy: ISO_YEAR_ISO_WEEK) {
        isoYearIsoWeek
        count
      }
    }

    previousApplications: eventQuery(
      dateRange: $previousDateRange
      jobIds: $jobIds
      eventTypes: [APPLIED]
    ) {
      count
    }

    currentPageviews: pageviewQuery(dateRange: $dateRange, jobIds: $jobIds) {
      sessions: distinctCount(
        field: SESSION_ID
        filters: { path: { match: "(/jobs/|/jobs/internal/)([a-z0-9-]*)$" } }
      )

      perDate: aggregated(groupBy: ISO_YEAR_ISO_WEEK) {
        isoYearIsoWeek
        pageviews: count
        sessions: distinctCount(
          field: SESSION_ID
          filters: { path: { match: "(/jobs/|/jobs/internal/)([a-z0-9-]*)$" } }
        )
      }
    }

    previousPageviews: pageviewQuery(
      dateRange: $previousDateRange
      jobIds: $jobIds
    ) {
      sessions: distinctCount(
        field: SESSION_ID
        filters: { path: { match: "(/jobs/|/jobs/internal/)([a-z0-9-]*)$" } }
      )
    }
  }
`;

const EXPORT_TABLE_COLUMNS = [
  { type: 'text', propertyName: 'date', headerKey: 'common.date' },
  {
    type: 'number',
    propertyName: 'sessions',
    headerKey: 'insights.common.visits',
  },
  {
    type: 'number',
    propertyName: 'pageviews',
    headerKey: 'insights.common.pageviews',
  },
  {
    type: 'number',
    propertyName: 'applications',
    headerKey: 'insights.common.applications',
  },
];

function parseVisits(
  dateSpan,
  applicationsPerDate,
  visitsPerDate,
  longPeriod = false
) {
  let visits = [];
  if (longPeriod) {
    const firstDayOfWeekFilter = (value, index, array) => {
      const yearWeek = parseInt(`${value.year()}${value.isoWeek()}`);
      const isFirstDateOfWeek =
        array.indexOf(
          array.find((v) => {
            const comparisonYearWeek = parseInt(`${v.year()}${v.isoWeek()}`);
            return comparisonYearWeek === yearWeek;
          })
        ) === index;
      return isFirstDateOfWeek;
    };

    visits = dateSpan.filter(firstDayOfWeekFilter).map((date) => {
      const isoYearIsoWeek = parseInt(`${date.year()}${date.isoWeek()}`);
      const visitsRow = visitsPerDate.find(
        (r) => isoYearIsoWeek === r.isoYearIsoWeek
      );
      const applicationsRow = applicationsPerDate.find(
        (r) => isoYearIsoWeek === r.isoYearIsoWeek
      );

      return {
        date: date.isoWeekday(1).format('YYYY-MM-DD'),
        applications: applicationsRow?.count || 0,
        pageviews: visitsRow?.pageviews || 0,
        sessions: visitsRow?.sessions || 0,
      };
    });
  } else {
    visits = dateSpan.map((date) => {
      const visitsRow = visitsPerDate.find((r) => date.isSame(r.date));
      const applicationsRow = applicationsPerDate.find((r) =>
        date.isSame(r.date)
      );
      return {
        date: date.format('YYYY-MM-DD'),
        applications: applicationsRow?.count || 0,
        pageviews: visitsRow?.pageviews || 0,
        sessions: visitsRow?.sessions || 0,
      };
    });
  }

  return visits;
}

export default class InsightsWidgetsJobVisits extends Component {
  @service insights;
  @service insightsExport;

  get sessions() {
    return this.fetchData?.lastSuccessful?.value?.sessions;
  }

  get applications() {
    return this.fetchData?.lastSuccessful?.value?.applications;
  }

  get dates() {
    return this.fetchData?.lastSuccessful?.value?.dates;
  }

  get conversionRate() {
    return this.fetchData?.lastSuccessful?.value?.conversionRate;
  }

  get previousConversionRate() {
    return this.fetchData?.lastSuccessful?.value?.previousConversionRate;
  }

  get currentSessions() {
    return this.fetchData?.lastSuccessful?.value?.currentSessions;
  }

  get previousSessions() {
    return this.fetchData?.lastSuccessful?.value?.previousSessions;
  }

  get currentApplications() {
    return this.fetchData?.lastSuccessful?.value?.currentApplications;
  }

  get previousApplications() {
    return this.fetchData?.lastSuccessful?.value?.previousApplications;
  }

  @action
  onShowExportModal() {
    this.insightsExport.showModal(
      'insights.molecules.conversion_rate.title',
      `job-${this.args.job.id}`,
      EXPORT_TABLE_COLUMNS,
      this.retrieveExportData,
      this.args.dateRange,
      { createdAt: this.args.job.createdAt }
    );
  }

  @action
  retrieveExportData(dateRange) {
    return this.queryForData(dateRange).then((result) =>
      parseVisits(
        this.args.dateRange.dateSpan,
        result.currentApplications.perDate,
        result.currentPageviews.perDate
      ).filter(
        (row) => row.sessions > 0 || row.pageviews > 0 || row.applications > 0
      )
    );
  }

  @action
  queryForData(dateRange, longPeriod = false) {
    const { job } = this.args;

    return this.insights.query({
      query: longPeriod ? LONG_PERIOD_VISITS_QUERY : VISITS_QUERY,
      variables: {
        dateRange: dateRange.asObject,
        previousDateRange: dateRange.previousDateRange.asObject,
        jobIds: [job.id],
      },

      context: { jobId: job.id },
    });
  }

  fetchData = dropTask(async () => {
    const longPeriod = this.args.dateRange.dateSpan.length > 91;
    const {
      currentApplications: {
        count: currentApplications,
        perDate: applicationsPerDate,
      },
      previousApplications: { count: previousApplications },
      currentPageviews: { sessions: currentSessions, perDate: visitsPerDate },
      previousPageviews: { sessions: previousSessions },
    } = await this.queryForData(this.args.dateRange, longPeriod);

    let visits = parseVisits(
      this.args.dateRange.dateSpan,
      applicationsPerDate,
      visitsPerDate,
      longPeriod
    );

    const sortedVisits = visits
      .toArray()
      .sort((a, b) => moment(a.date).unix() - moment(b.date).unix());

    return {
      sessions: sortedVisits.map((row) => row.sessions),
      applications: sortedVisits.map((row) => row.applications),
      dates: sortedVisits.map((row) => row.date),
      conversionRate: percentageOf(currentApplications, currentSessions),
      previousConversionRate: percentageOf(
        previousApplications,
        previousSessions
      ),

      currentApplications,
      previousApplications,
      currentSessions,
      previousSessions,
    };
  });
}
