import {
  FilterOperator,
  FilterOption,
  FilterType,
  NestedOptions,
} from 'teamtailor/components/fancy-filters';
import IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import EmberObject from '@ember/object';

export default class FancyFilterOption
  extends EmberObject
  implements FilterOption
{
  @service declare intl: IntlService;

  translationPrefix?: string;

  name = '';
  parent?: FilterOption;
  category?: string;
  color?: string;
  icon?: string;
  triggerIconStyle?: string;
  iconStyle?: string;
  type: FilterType = 'string';
  operators: FilterOperator[] = [];
  options?: any[];
  nested?: NestedOptions;
  doNotTranslateName = false;
  ignoreParent = true;

  _translatedName?: string;
  get translatedName(): string {
    if (this.doNotTranslateName) {
      return this._translatedName || this.name;
    }

    let keyName = this.name;
    if (this.nested?.name) {
      keyName = `${this.nested.name}.${keyName}`;
    }

    let suffix = '';
    if (this.parent) {
      if (this.ignoreParent) {
        suffix = `.without_parent`;
      } else {
        suffix = `.with_parent`;
      }
    }

    return (
      this.intl.t(`${this.translationPrefix}.${keyName}${suffix}`, {
        parentName:
          !this.ignoreParent && this.parent
            ? this.parent.translatedName
            : undefined,
      }) || this.name
    );
  }

  set translatedName(value: string) {
    this._translatedName = value;
  }
}
