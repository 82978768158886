import Model, {
  AsyncHasMany,
  SyncHasMany,
  attr,
  hasMany,
} from '@ember-data/model';
import CandidateModel from 'teamtailor/models/candidate';
import VideoRoomModel from 'teamtailor/models/video-room';
import { inject as service } from '@ember/service';
import Server from 'teamtailor/services/server';
import VideoMeetingToken from 'teamtailor/adapters/video-meeting-token';
import {
  ChatMessage,
  VideoMeetingUser,
} from 'teamtailor/utils/video-meetings/utils';

export default class VideoMeetingTokenModel extends Model {
  @service declare server: Server;

  @attr('string')
  declare token: string | null;

  @attr('string')
  declare companyName: string;

  @attr('string')
  declare userId: string;

  @attr('string')
  declare logotypeUrl: string | null;

  @attr('string')
  declare companyColor: string;

  @attr('string')
  declare companyButtonTextColor: string;

  @attr('string')
  declare jobImageUrl: string | null;

  @attr('boolean')
  declare recordingAddonEnabled: boolean;

  @attr('boolean')
  declare newProvider: boolean;

  @attr('string')
  declare summary: string;

  @attr('date')
  declare bookedSlotStartsAt: Date;

  @attr('date')
  declare bookedSlotEndsAt: Date;

  @attr('string')
  declare tzid: string;

  @attr('boolean')
  declare multipleCandidateMeeting: boolean;

  @hasMany('video-room', { async: false })
  declare videoRooms: SyncHasMany<VideoRoomModel>;

  @attr('raw')
  declare attendeesWithDetails: VideoMeetingUser[];

  @hasMany('candidate', { async: true })
  declare candidates: AsyncHasMany<CandidateModel>;

  async fetchToken(data: { name: string | null }) {
    return this.server.memberAction(this, {
      action: 'token',
      method: 'POST',
      options: { data },
    });
  }

  async fetchMeetingData(data: { name: string | null }) {
    return this.server.memberAction(this, {
      action: 'create_meeting_data',
      method: 'POST',
      options: {
        data,
      },
    });
  }

  async deleteMeeting(data: { meeting_id: string | undefined }) {
    return this.server.memberAction(this, {
      action: 'delete_meeting',
      method: 'POST',
      options: {
        data,
      },
    });
  }

  async setRecordingStatus(
    meetingId: string,
    data: { action_name: string; sid?: string; meeting_arn?: string }
  ) {
    const adapter = new VideoMeetingToken();
    return this.server.request(
      adapter.buildUpdateRecordingUrl(meetingId),
      'POST',
      {
        data,
      }
    );
  }

  async updateChatHistory(data: ChatMessage) {
    return this.server.memberAction(this, {
      action: 'update_chat_history',
      method: 'POST',
      options: {
        data,
      },
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'video-meeting-token': VideoMeetingTokenModel;
  }
}
