import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { keyResponder, onKey } from 'ember-keyboard';
import { tracked } from '@glimmer/tracking';
import { argDefault } from 'teamtailor/utils/arg-default';
import { restartableTask, timeout } from 'ember-concurrency';

@keyResponder
export default class MediaLibraryManagerBaseComponent extends Component {
  @service store;
  @service ttAlert;
  @service infinity;

  @argDefault multiple = false;
  @argDefault query = '';

  @tracked pickedImages = [];
  @tracked images = [];
  keyboardPriority = 1;

  get multiPickerIsShowing() {
    return this.pickedImages.length > 0;
  }

  get canPickImage() {
    return this.args.currentAvailableImages === undefined
      ? true
      : this.args.currentAvailableImages - this.pickedImages.length > 0;
  }

  get queryParams() {
    return {
      query: this.query.trim(),
    };
  }

  get isSearching() {
    return this.query !== '';
  }

  @onKey('KeyC')
  clearSelected() {
    if (this.multiPickerIsShowing) {
      this.pickedImages = [];
    }
  }

  handleSearchInput = restartableTask(async (query = null) => {
    if (this.isSearching) {
      await timeout(500);
    }

    if (typeof query === 'string') {
      this.query = query;
    }

    await this.fetchImages.perform();
  });

  @action
  handlePickImage(image) {
    if (this.multiple) {
      if (this.pickedImages.includes(image)) {
        this.pickedImages.removeObject(image);
      } else {
        this.pickedImages.addObject(image);
      }
    } else if (this.args.onPickImage) {
      this.args.onPickImage(image);
    }
  }

  @action
  handleUsePickedImages() {
    if (!this.args.onPickImage) {
      return;
    }

    this.pickedImages.forEach((image) => {
      this.args.onPickImage(image);
    });
  }
}
