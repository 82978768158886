import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ActivityItem::CandidateLink\n  @activity={{this.activity}}\n  @anonymous={{this.anonymous}}\n  @isProfile={{this.isProfile}}\n  @job={{this.job}}\n  @fallbackText={{t \"components.activity_item.candidate\"}}\n/>\n{{t\n  \"components.activity_item.selected_for_at_date\"\n  activity=this.activity.actionData.meeting_event_summary\n  date=this.formattedDate\n  htmlSafe=true\n}}", {"contents":"<ActivityItem::CandidateLink\n  @activity={{this.activity}}\n  @anonymous={{this.anonymous}}\n  @isProfile={{this.isProfile}}\n  @job={{this.job}}\n  @fallbackText={{t \"components.activity_item.candidate\"}}\n/>\n{{t\n  \"components.activity_item.selected_for_at_date\"\n  activity=this.activity.actionData.meeting_event_summary\n  date=this.formattedDate\n  htmlSafe=true\n}}","moduleName":"teamtailor/components/activity-item/meeting-event-self-schedule-picked-time.hbs","parseOptions":{"srcName":"teamtailor/components/activity-item/meeting-event-self-schedule-picked-time.hbs"}});
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import Base from './base';
import { get } from 'teamtailor/utils/get';

export default class CalendarComponent extends Base {
  @service timeFormat;

  get tzid() {
    return get(this.activity, 'actionData.meeting_event_tzid');
  }

  get formattedDate() {
    if (!this.tzid) {
      // for older actions without tzid
      return moment
        .parseZone(get(this.activity, 'actionData.meeting_event_starts_at'))
        .format(`DD MMM YYYY ${this.timeFormat.format}`);
    }

    return moment(get(this.activity, 'actionData.meeting_event_starts_at'))
      .tz(this.tzid)
      .format(`DD MMM YYYY ${this.timeFormat.format} (z)`);
  }
}
