import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"mb-8 {{@class}}\">\n  <HighCharts\n    @chartOptions={{this.chartOptions}}\n    @content={{this.chartData}}\n    @hideAnimations={{@hideAnimations}}\n  />\n</div>\n\n<Insights::Atoms::DateGutter\n  class=\"px-8\"\n  @startDate={{@startDate}}\n  @endDate={{@endDate}}\n/>", {"contents":"<div class=\"mb-8 {{@class}}\">\n  <HighCharts\n    @chartOptions={{this.chartOptions}}\n    @content={{this.chartData}}\n    @hideAnimations={{@hideAnimations}}\n  />\n</div>\n\n<Insights::Atoms::DateGutter\n  class=\"px-8\"\n  @startDate={{@startDate}}\n  @endDate={{@endDate}}\n/>","moduleName":"teamtailor/components/insights/charts/nps-moving-average-chart.hbs","parseOptions":{"srcName":"teamtailor/components/insights/charts/nps-moving-average-chart.hbs"}});
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import Highcharts from 'highcharts';

interface FixedLengthArray<L extends number, T> extends ArrayLike<T> {
  length: L;
}

type Colors = {
  positiveBorder: string;
  negativeBorder: string;
  positiveBackground: string;
  negativeBackground: string;
};

type Args = {
  colors?: Colors;
  series?: string[];
  data?: FixedLengthArray<2, FixedLengthArray<2, number>[]>;

  startDate: string;
  endDate: string;

  height?: string;
};

export default class InsightsChartsNpsMovingAverageChart extends Component<Args> {
  get chartColors() {
    return (
      this.args.colors || {
        positiveBorder: '#f43f85',
        negativeBorder: '#f43f85',
        positiveBackground: '#ffffff',
        negativeBackground: '#ffffff',
      }
    );
  }

  get chartData() {
    if (!this.args.series?.length || !this.args.data?.length) return [];
    return [
      {
        name: this.args.series[0],
        data: this.args.data[0],
      },
    ];
  }

  get chartType() {
    return 'area';
  }

  get chartOptions() {
    const { chartColors } = this;

    const pointInterval = 24 * 36e5;
    const { startDate, series, data } = this.args;

    const formatTooltip = (ctx: Highcharts.TooltipFormatterContextObject) => {
      if (!data?.length || !series?.length) return '';

      const date = moment(startDate).add(ctx.x, 'milliseconds').toDate();
      const x = (typeof ctx.x === 'string' ? parseFloat(ctx.x) : ctx.x) || 0;
      const dataIndex = data[0]!
        .map((r: FixedLengthArray<2, number>) => r[0])
        .indexOf(x / pointInterval);
      return ctx.points?.reduce((s: string, point) => {
        series.forEach((series, index) => {
          if (index === 0) {
            s += `<br /><span class="inline-block mr-4" style="color: ${chartColors.positiveBorder}">\u25CF</span> ${point.series.name}: ${point.y}`;
          } else {
            s += `<br /><span class="inline-block mr-4" style="color: ${chartColors.negativeBorder}">\u25CF</span> ${series}: ${data[index]?.[dataIndex]}`;
          }
        });
        return s;
      }, `<span>${moment(date).format('YYYY-MM-DD')}</span>`);
    };

    const options = {
      chart: {
        type: this.chartType,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingTop: 20,

        spacingRight: 0,
        height: this.args.height || '200px',
      },

      title: {
        text: '',
      },

      legend: {
        enabled: false,
      },

      yAxis: {
        title: {
          text: '',
        },

        min: -100,
        max: 100,

        gridLineColor: '#eeeff2',

        labels: {
          enabled: true,
          padding: 0,
          overflow: 'allow',
          distance: 0,
          x: 15,
          y: -5,
          style: {
            fontSize: '11px',
            color: '#999',
          },
        },
      },

      xAxis: {
        type: 'datetime',
        labels: {
          enabled: false,
        },

        tickLength: 1,
        lineColor: '#eeeff2',
        lineWidth: 1,
      },

      tooltip: {
        // eslint-disable-next-line
        // @ts-ignore
        formatter() {
          // eslint-disable-next-line
          // @ts-ignore
          return formatTooltip(this);
        },

        padding: 8,

        crosshairs: {
          color: '#eeeff2',
          dashStyle: 'solid',
        },

        shared: true,
      },

      plotOptions: {
        line: {
          states: {
            hover: {
              lineWidth: 1,
            },
          },
        },

        series: {
          relativeXValue: true,
          pointStart: moment(startDate).toDate().getUTCMilliseconds(),

          pointInterval,

          label: {
            connectorAllowed: false,
          },

          marker: {
            enabled: false,
          },
        },

        area: {
          color: chartColors.positiveBorder,
          lineWidth: 2,
          fillColor: chartColors.positiveBackground,
          negativeColor: chartColors.negativeBorder,
          negativeFillColor: chartColors.negativeBackground,
        },
      },
    };

    return options;
  }
}
