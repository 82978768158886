import { helper } from '@ember/component/helper';

export function formatTime(time: number): string {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);
  let formattedTime = '';

  if (hours > 0) {
    formattedTime += `${hours}h `;
  }

  if (minutes > 0) {
    formattedTime += `${minutes}m `;
  }

  formattedTime += `${seconds}s`;

  return formattedTime.trim();
}

export function recordingTimeFormatter([time]: [number]): string {
  return formatTime(time);
}

export default helper(recordingTimeFormatter);
