import BaseProperty, { BasePropertyOptions } from './base-property';

interface CustomFormatPropertyOptions extends BasePropertyOptions {
  customFormatter: (value: any) => Promise<string>;
  preload?: (values: any[]) => Promise<void>;
  needsPreload?: boolean;
}

export default class CustomFormatProperty extends BaseProperty {
  customFormatter: (value: any) => Promise<string>;

  preload?: (values: any[]) => Promise<void>;

  needsPreload?: boolean = false;

  constructor(options: CustomFormatPropertyOptions) {
    super({
      ...options,
      type: 'customFormat',
    });

    this.preload = options.preload;
    this.needsPreload = options.needsPreload;
    this.customFormatter = options.customFormatter;
  }

  format(value: any) {
    return this.customFormatter(value);
  }
}
