import ChannelActivationBaseController from './channel-activation-base';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { timeout, task } from 'ember-concurrency';

export default class SettingsIntegrationsChannelActivationIndeedController extends ChannelActivationBaseController {
  @service flashMessages;
  @service intl;

  @computed('model.{indeedEmail,indeedPhone}')
  get isDisabled() {
    return isEmpty(this.model.indeedEmail) || isEmpty(this.model.indeedPhone);
  }

  activate = task(async () => {
    await timeout(2000);

    this.model.activated = true;
    this.model
      .save()
      .then(() =>
        this.flashMessages.success(
          this.intl.t('settings.indeed.indeed_activated')
        )
      );
  });
}
