import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import UserModel from 'teamtailor/models/user';

export default class EmployeesProfileSecurityActivate2fa extends Route {
  @service declare router: RouterService;

  model(): UserModel {
    return this.modelFor('employees.profile');
  }

  afterModel(model: UserModel) {
    if (model.login?.twoFactorActivated) {
      this.router.transitionTo('employees.profile.security.disable-2fa');
    }
  }
}
