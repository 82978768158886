import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import CandidateModalController from 'teamtailor/controllers/candidate-modal';

export default class IndexController extends CandidateModalController {
  @service permissions;

  @action
  handleModalClose() {
    if (this.permissions.has('candidate/list')) {
      this.router.transitionTo('candidates.segment', 'all', {
        queryParams: { q: null },
      });
    } else {
      this.router.transitionTo('dashboard');
    }
  }
}
