import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    partner: {
      serialize: 'records',
      deserialize: 'ids',
      key: 'partner_attributes',
    },

    channel: {
      serialize: 'records',
      deserialize: 'ids',
      key: 'channel_attributes',
    },
  },
});
