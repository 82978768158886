import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { action, computed } from '@ember/object';
import moment from 'moment-timezone';
import { get } from 'teamtailor/utils/get';

@classic
export default class ReportsController extends Controller {
  @service analytics;
  @service router;

  queryParams = ['departmentId', 'fromJob', 'filters'];

  @controller
  application;

  @computed('router.currentRouteName')
  get currentRoute() {
    return get(this, 'router.currentRouteName').replace('analytics.', '');
  }

  @alias('analytics.startDateParam')
  startDate;

  @alias('analytics.endDateParam')
  endDate;

  @alias('analytics.jobId')
  jobId;

  @alias('analytics.departmentId')
  departmentId;

  @alias('analytics.locationId')
  locationId;

  @alias('analytics.fromJob')
  fromJob;

  @alias('analytics.encodedFilters') filters;

  @computed('startDateAsJs')
  get toOldStartDate() {
    return moment(get(this, 'startDateAsJs')).isBefore('2016-06-01');
  }

  @computed('departmentId', 'startDateAsJs')
  get incompleteDepartmentData() {
    return (
      moment(get(this, 'startDateAsJs')).isBefore('2018-04-08') &&
      get(this, 'departmentId')
    );
  }

  @computed('locationId', 'startDateAsJs')
  get incompleteLocationData() {
    return (
      moment(get(this, 'startDateAsJs')).isBefore('2018-04-08') &&
      get(this, 'locationId')
    );
  }

  yesterday() {
    return moment().subtract(1, 'days').startOf('day');
  }

  @alias('analytics.startDate')
  startDateAsJs;

  @alias('analytics.endDate')
  endDateAsJs;

  @action
  navigationChanged(routeName) {
    this.router.transitionTo(routeName);
  }
}
