import Route from '@ember/routing/route';
import PromotionsController from 'teamtailor/controllers/promotions';
import Transition from '@ember/routing/-private/transition';

export default class Promotions extends Route {
  setupController(
    controller: PromotionsController,
    model: ReturnType<Promotions['model']>,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);
    controller.fetchJobs.perform();
  }
}
