import Helper from '@ember/component/helper';

export default Helper.helper(function (status) {
  switch (status[0]) {
    case 'new':
      return 'inbox';
    case 'made_offer':
      return 'inbox';
    case 'reviewing':
      return 'reviewing';
    case 'phonescreened':
      return 'reviewing';
    case 'scheduling_interview':
      return 'reviewing';
    case 'interviewed':
      return 'reviewing';
    case 'checking_references':
      return 'reviewing';
    case 'put_on_hold':
      return 'reviewing';
    case 'not_a_fit':
      return 'rejected';
    case 'declined_offer':
      return 'rejected';
    case 'not_qualified':
      return 'rejected';
    case 'hired':
      return 'inbox';
    default:
      return 'lol';
  }
});
