export const ORB_PATHS = [
  {
    fill: 'white',
    small:
      'M470.511 299.008C535.747 298.463 600.92 326.365 623.884 387.473C650.261 457.665 637.737 540.141 578.276 585.78C514.217 634.947 436.073 617.352 372.169 567.983C312.837 522.145 298.084 460.048 324.821 389.971C347.395 330.807 407.231 299.536 470.511 299.008Z',

    large:
      'M356.082 204.005C459.709 158.472 568.621 162.966 647 244.5C737.03 338.154 787.501 473.284 723.902 586.632C655.385 708.745 495.777 680.92 360.154 646.1C234.232 613.771 191.271 578.367 185.996 448.53C181.543 338.912 255.56 248.174 356.082 204.005Z',
  },
  {
    fill: 'white',
    small:
      'M480.144 314.049C546.45 315.416 605.341 356.065 626.248 418.832C647.59 482.904 629.859 556.916 573.5 594.32C522.423 628.218 460.333 597.454 410.153 562.25C356.414 524.549 296.401 475.477 314.189 412.399C333.449 344.1 409.018 312.583 480.144 314.049Z',

    large:
      'M567.012 224.093C674.402 263.751 747.466 363.48 746.049 477.949C744.604 594.799 659.106 656.126 546 685.5C443.495 712.121 374.306 676.484 312.371 590.578C246.043 498.58 175.917 384.441 240.631 291.302C310.703 190.454 451.813 181.551 567.012 224.093Z',
  },
  {
    fill: 'white',
    small:
      'M464.795 301.029C542.34 299.311 595.534 374.803 613.374 450.478C627.27 509.429 583.867 560.172 533.92 594.26C489.613 624.499 435.132 599.711 391.027 569.177C343.646 536.375 321.078 512.621 335.133 456.641C353.327 384.178 390.279 302.679 464.795 301.029Z',

    large:
      'M537.244 190.545C666.569 226.084 717.286 377.627 709.338 511.973C703.147 616.628 678.81 639.254 579.167 671.067C490.776 699.288 318.223 670.393 260.284 597.917C198.04 520.056 194.279 470.345 245.308 384.455C311.362 273.276 412.972 156.394 537.244 190.545Z',
  },
  {
    filter: true,
    fill: 'white',
    small:
      'M505.439 326.83C558.89 331.058 600.851 367.508 618.738 417.985C638.458 473.634 640.706 540.279 593.814 576.221C543.841 614.524 475.574 602.729 423.073 567.96C360.843 526.746 294.99 464.862 319.098 394.285C342.68 325.248 432.623 321.071 505.439 326.83Z',

    large:
      'M379.865 220.337C467.772 195.989 609.411 203.518 666.773 272.462C730.011 348.469 742.334 523.453 687.798 607.115C629.678 696.276 555.61 680.401 451.829 656.208C328.812 627.531 188.23 568.474 186.595 443.599C184.996 321.448 260.11 253.506 379.865 220.337Z',
  },
] as const;
