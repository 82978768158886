import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import CompanyPageService from 'teamtailor/services/company-page';
import IntercomService from 'teamtailor/services/intercom';
import TranquilityController from 'teamtailor/controllers/dashboard/tranquility';

export default class TranquilityRoute extends Route {
  @service declare store: Store;
  @service declare companyPage: CompanyPageService;
  @service declare intercom: IntercomService;

  afterModel() {
    this.intercom.hideWidget();
  }

  resetController(controller: TranquilityController, isExiting: boolean) {
    if (isExiting) {
      controller.hideOverlay();
      this.intercom.showWidget();
    }
  }
}
