export const DEFAULT_SIZE = '24';
export const ALLOWED_SIZES = [
  '20',
  DEFAULT_SIZE,
  '28',
  '32',
  '36',
  '48',
  '64',
  '80',
  '96',
  '136',
  '160',
] as const;

export type AllowedSizes = (typeof ALLOWED_SIZES)[number];

export const SIZE_CLASSES = {
  20: 'size-20 text-[9px]',
  24: 'size-24 text-10',
  28: 'size-28 text-10',
  32: 'size-32 text-12',
  36: 'size-36 text-12',
  48: 'size-48 text-16',
  64: 'size-64 text-20',
  80: 'size-[80px] text-25',
  96: 'size-[96px] text-32',
  136: 'size-[136px] text-44',
  160: 'size-[160px] text-44',
};
