type CodeActivityMap = {
  [key: string]: string;
};

export const CODE_ACTIVITY_MAP: CodeActivityMap = {
  questionnaire: 'activity-item/questionnaire',
  interview_added: 'activity-item/interview-added',
  created: 'activity-item/created',
  share: 'activity-item/share',
  referred: 'activity-item/referred',
  referral_hired: 'activity-item/referral-hired',
  restricted: 'activity-item/restricted',
  unrestricted: 'activity-item/unrestricted',
  note: 'activity-item/note',
  review: 'activity-item/review',
  stage: 'activity-item/stage',
  tags: 'activity-item/tags',
  rejected: 'activity-item/rejected',
  survey_sent: 'activity-item/survey',
  assessment: 'activity-item/assessment',
  nps_response: 'activity-item/nps-response',
  candidate_merged: 'activity-item/candidate-merged',
  status: 'activity-item/status',
  webhook_sent: 'activity-item/webhook',
  scorecard: 'activity-item/scorecard',
  approval: 'activity-item/approval',
  upload: 'activity-item/upload',
  todo_assigned: 'activity-item/todo-assigned',
  lead: 'activity-item/lead',
  unsubscribed: 'activity-item/unsubscribed',
  reminder_sent: 'activity-item/reminder-sent',
  partner_result: 'activity-item/partner-result',
  updated_profile: 'activity-item/updated-profile',
  hire_quality_response: 'activity-item/hire-quality-response',

  sourced: 'activity-item/sourced',
  sourced_removed: 'activity-item/sourced',

  job_open: 'activity-item/job',
  job_unlisted: 'activity-item/job',
  job_archived: 'activity-item/job',
  job_internal: 'activity-item/job',
  job_public: 'activity-item/job',

  job_offer_accepted: 'activity-item/job-offer',
  job_offer_declined: 'activity-item/job-offer',
  job_offer_opened: 'activity-item/job-offer',
  job_offer_sent: 'activity-item/job-offer',

  job_offer_flow_verdict_requested: 'activity-item/job-offer-flow',
  job_offer_flow_verdict_reminder: 'activity-item/job-offer-flow',
  job_offer_flow_verdict_approved: 'activity-item/job-offer-flow',
  job_offer_flow_approved: 'activity-item/job-offer-flow',
  job_offer_flow_rejected: 'activity-item/job-offer-flow',

  email: 'activity-item/message',
  message: 'activity-item/message',
  sms: 'activity-item/message',
  sms_opt_out: 'activity-item/message',

  connected: 'activity-item/connect-updated',
  moved: 'activity-item/connect-updated',

  smart_moved: 'activity-item/smart-moved',
  prediction_moved: 'activity-item/smart-moved',

  reference_given: 'activity-item/reference-given',

  requisition_rejected: 'activity-item/requisition',
  requisition_approved: 'activity-item/requisition',
  requisition_verdict_approved: 'activity-item/requisition',
  requisition_verdict_requested: 'activity-item/requisition',
  requisition_verdict_reminder: 'activity-item/requisition',
  requisition_verdict_user_changed: 'activity-item/requisition',
  requisition_completed: 'activity-item/requisition',
  requisition_archived: 'activity-item/requisition',
  requisition_unarchived: 'activity-item/requisition',
  requisition_created: 'activity-item/requisition',
  requisition_updated: 'activity-item/requisition',
  requisition_changed_recruiter: 'activity-item/requisition',

  internal: 'activity-item/internal',
  internal_removed: 'activity-item/internal',

  nurture_campaign_added: 'activity-item/nurture-campaign',
  nurture_campaign_recipient_paused: 'activity-item/nurture-campaign',
  nurture_campaign_recipient_activated: 'activity-item/nurture-campaign',
  nurture_campaign_exited: 'activity-item/nurture-campaign',

  feature_enabled: 'activity-item/feature',
  feature_disabled: 'activity-item/feature',

  consent_missing: 'activity-item/consent',
  consent_extended: 'activity-item/consent',
  consent_extended_future_jobs: 'activity-item/consent',
  consent_extend_request: 'activity-item/consent',
  consent_requested: 'activity-item/consent',
  consent_given: 'activity-item/consent',

  linkedin: 'activity-item/linkedin',
  linkedin_rsc_error: 'activity-item/linkedin',

  removal_request: 'activity-item/candidate-request',
  data_request: 'activity-item/candidate-request',
  data_request_dismissed: 'activity-item/candidate-request',
  data_request_handled: 'activity-item/candidate-request',

  meeting_event_candidate_invited:
    'activity-item/meeting-event-candidate-invited',

  meeting_event_sent_self_schedule:
    'activity-item/meeting-event-sent-self-schedule',

  meeting_event_cancelled: 'activity-item/meeting-event-cancelled',

  meeting_event_self_schedule_picked_time:
    'activity-item/meeting-event-self-schedule-picked-time',

  meeting_event_candidate_status_changed:
    'activity-item/meeting-event-candidate-status-changed',

  meeting_event_self_schedule_no_time:
    'activity-item/meeting-event-self-schedule-no-time',

  trigger_cancelled: 'activity-item/trigger-cancelled',

  candidates_will_be_deleted_reminder:
    'activity-item/candidates_will_be_deleted_reminder',

  copilot_meeting_summary: 'activity-item/copilot-meeting-summary',

  copilot_resume_summary: 'activity-item/copilot-resume-summary',

  copilot_interview_kit_questions_feedback: 'activity-item/interview-added',
};
