import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { timeout, restartableTask } from 'ember-concurrency';
import { set, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import IntlService from 'ember-intl/services/intl';
import IntercomService from 'teamtailor/services/intercom';
import Current from 'teamtailor/services/current';
import TtAlertService from 'teamtailor/services/tt-alert';
import FlashMessageService from 'teamtailor/services/flash-message';
import Store from '@ember-data/store';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import MeetingRoomsRoute from 'teamtailor/routes/settings/meeting-rooms';
import {
  AuthorizationModel,
  MeetingRoomModel,
  MeetingRoomResourceModel,
} from 'teamtailor/models';

export default class MeetingRoomController extends Controller {
  declare model: ModelFrom<MeetingRoomsRoute>;

  @service declare intl: IntlService;
  @service declare intercom: IntercomService;
  @service declare store: Store;
  @service declare current: Current;
  @service declare ttAlert: TtAlertService;
  @service declare flashMessages: FlashMessageService;

  @tracked searchTerm = '';
  @tracked meetingRoomResourceError = false;

  get meetingRoomsWithoutResource() {
    return this.model.meetingRooms.filter(
      (meetingRoom) =>
        !this.meetingRoomResources.some(
          (resource: MeetingRoomResourceModel) =>
            resource.email === meetingRoom.resourceEmail
        )
    );
  }

  get companyCronofyBusinessConnect() {
    return this.model.authorizations.findBy('isCronofyBusinessConnect', true);
  }

  get companyHasEnterpriseConnect() {
    return get(
      this.current.company,
      'hasAnyCronofyServiceAccountAuthorization'
    );
  }

  get meetingRoomResources() {
    if (this.searchTerm) {
      return this.findRooms();
    }

    return this.model.meetingRoomResources;
  }

  get meetingRoomsThatNeedsSync() {
    return this.model.meetingRooms.filter((meetingRoom) => {
      let resource = this.meetingRoomResources.find(
        (resource: MeetingRoomResourceModel) =>
          resource.email === meetingRoom.resourceEmail
      );
      return (
        resource &&
        (resource.name !== meetingRoom.name ||
          resource.resourceName !== meetingRoom.resourceName)
      );
    });
  }

  findRooms() {
    let searchTerms = this.searchTerm.split(' ').filter((s) => !!s);
    if (searchTerms.length > 0) {
      return this.model.meetingRoomResources.filter((room) => {
        return searchTerms.some(
          (s) =>
            room.name.toLowerCase().includes(s.toLowerCase()) ||
            room.generatedName.toLowerCase().includes(s.toLowerCase())
        );
      });
    }

    return this.model.meetingRoomResources;
  }

  setSearchTermTask = restartableTask(async (value) => {
    await timeout(300);
    set(this, 'searchTerm', value);
  });

  async saveMeetingRoom(meetingRoom: MeetingRoomModel) {
    await meetingRoom.save();
    meetingRoom.waitForResourceIdTask.perform();
  }

  @action
  async toggleMeetingRoom(
    meetingRoom?: MeetingRoomModel,
    meetingRoomResource?: MeetingRoomResourceModel
  ) {
    if (meetingRoom) {
      meetingRoom.toggleProperty('bookable');

      if (meetingRoom.bookable || meetingRoom.hasUpcomingCalendarEvents) {
        this.saveMeetingRoom(meetingRoom);
      } else {
        await meetingRoom.destroyRecord();
      }
    } else if (meetingRoomResource) {
      meetingRoom = this.store.createRecord('meeting-room', {
        name: meetingRoomResource.name,
        resourceEmail: meetingRoomResource.email,
        resourceName: meetingRoomResource.resourceName,
      });

      this.saveMeetingRoom(meetingRoom);
    }
  }

  @action
  deleteAuthorization(authorization: AuthorizationModel) {
    this.ttAlert.customConfirm({
      title: this.intl.t('settings.meeting_rooms.disconnect_confirm.title'),
      text: this.intl.t('settings.meeting_rooms.disconnect_confirm.text'),
      cancelButtonText: this.intl.t('common.no'),
      confirmButtonClass: 'btn-danger',
      confirmButtonText: this.intl.t(
        'settings.meeting_rooms.disconnect_confirm.title'
      ),

      confirmCallback: () => {
        authorization.destroyRecord().then(() => {
          this.model.authorizations.removeObject(authorization);
          this.store.unloadAll('meeting-room');
          this.store.unloadAll('meeting-room-resource');
        });
      },
    });
  }

  @action
  onCronofyConnectSuccess(data: { authorization: Record<string, unknown> }) {
    let authorization = this.store.createRecord('authorization', {
      ...data.authorization,
      company: this.current.company,
    });

    return authorization.save().then(() => {
      this.model.authorizations.pushObject(authorization);
      this.intercom.trackEvent('connected-business-connect');

      this.flashMessages.success(
        this.intl.t('settings.meeting_rooms.connected')
      );
    });
  }

  @action
  syncMeetingRoomNames() {
    this.meetingRoomsThatNeedsSync.forEach((meetingRoom) => {
      let resource = this.meetingRoomResources.find(
        (resource: MeetingRoomResourceModel) =>
          resource.email === meetingRoom.resourceEmail
      );
      if (resource) {
        meetingRoom.name = resource.name;
        meetingRoom.resourceName = resource.resourceName;
        meetingRoom.save();
      }
    });
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.meeting-rooms': MeetingRoomController;
  }
}
