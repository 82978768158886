export type PageIconType = {
  icon: string;
  size: string;
  color?: string;
};

interface PageMapType {
  [key: string]: PageIconType;
}

export const PAGE_TYPE_MAP: PageMapType = {
  career: {
    icon: 'house-heart',
    size: '16',
    color: 'amber',
  },

  departments: {
    icon: 'building',
    size: '16',
    color: 'blue',
  },

  locations: {
    icon: 'location-dot',
    size: '20',
    color: 'green',
  },

  campaigns: {
    icon: 'bullhorn',
    size: '16',
    color: 'cyan',
  },

  pages: {
    icon: 'file',
    size: '16',
    color: 'red',
  },

  language: {
    icon: 'globe',
    size: '24',
  },

  templates: {
    icon: 'layer-group',
    size: '20',
    color: 'purple',
  },

  post: {
    icon: 'feather-pointed',
    size: '20',
    color: 'orange',
  },

  connect: {
    icon: 'circle-nodes',
    size: '16',
    color: 'rose',
  },
};

export const ALLOWED_TYPES = Object.keys(PAGE_TYPE_MAP);

export const DEFAULT_PAGE_TYPE = {
  icon: 'circle-question',
  size: '20',
} as PageIconType;
