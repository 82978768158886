export default {
  "root": "Heading__root_1h9ofj",
  "regular": "Heading__regular_1h9ofj",
  "medium": "Heading__medium_1h9ofj",
  "small": "Heading__small_1h9ofj",
  "section": "Heading__section_1h9ofj",
  "topicName": "Heading__topicName_1h9ofj",
  "light": "Heading__light_1h9ofj",
  "chartCompare": "Heading__chartCompare_1h9ofj"
};
