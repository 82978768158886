import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.activity.isHireQualityResponse}}\n  {{#if this.isCreated}}\n    {{t\n      \"components.activity_item.hire_quality_response.created\"\n      userName=this.activity.user.nameOrEmail\n    }}\n  {{else if this.isSent}}\n    {{t\n      \"components.activity_item.hire_quality_response.sent\"\n      userName=this.activity.user.nameOrEmail\n    }}\n  {{else if this.isAnswered}}\n    {{t\n      \"components.activity_item.hire_quality_response.answered\"\n      userName=this.activity.user.nameOrEmail\n    }}\n  {{else if this.isCanceled}}\n    {{t \"components.activity_item.hire_quality_response.canceled\"}}\n  {{/if}}\n{{/if}}", {"contents":"{{#if this.activity.isHireQualityResponse}}\n  {{#if this.isCreated}}\n    {{t\n      \"components.activity_item.hire_quality_response.created\"\n      userName=this.activity.user.nameOrEmail\n    }}\n  {{else if this.isSent}}\n    {{t\n      \"components.activity_item.hire_quality_response.sent\"\n      userName=this.activity.user.nameOrEmail\n    }}\n  {{else if this.isAnswered}}\n    {{t\n      \"components.activity_item.hire_quality_response.answered\"\n      userName=this.activity.user.nameOrEmail\n    }}\n  {{else if this.isCanceled}}\n    {{t \"components.activity_item.hire_quality_response.canceled\"}}\n  {{/if}}\n{{/if}}","moduleName":"teamtailor/components/activity-item/hire-quality-response.hbs","parseOptions":{"srcName":"teamtailor/components/activity-item/hire-quality-response.hbs"}});
import Component from '@glimmer/component';

export default class HireQualityResponseComponent extends Component {
  get activity() {
    return this.args.activity;
  }

  get status() {
    return this.activity?.actionData?.status;
  }

  get isCreated() {
    return this.status === 'created';
  }

  get isSent() {
    return this.status === 'sent';
  }

  get isAnswered() {
    return this.status === 'answered';
  }

  get isCanceled() {
    return this.status === 'canceled';
  }
}
