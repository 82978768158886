import Controller from '@ember/controller';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import { action } from '@ember/object';
import { dropTask, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Sentry from '@sentry/ember';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import SessionService from 'ember-simple-auth/services/session';
import { bind, later } from '@ember/runloop';
import RouterService from '@ember/routing/router-service';

import Video, {
  isSupported,
  LocalParticipant,
  Room,
  ConnectOptions,
  VP8CodecSettings,
  RemoteParticipant,
  LocalTrackPublication,
  LocalVideoTrack,
  LocalAudioTrack,
  TwilioError,
  LocalTrackOptions,
  createLocalVideoTrack,
  createLocalAudioTrack,
} from 'twilio-video';

import {
  AudioVideoObserver,
  ConsoleLogger,
  DefaultBrowserBehavior,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSession,
  MeetingSessionConfiguration,
  VideoTileState,
  RealtimeAttendeePositionInFrame,
  MeetingSessionStatus,
  DefaultActiveSpeakerPolicy,
  DefaultModality,
  VideoFxConfig,
  VideoFxProcessor,
  DefaultVideoTransformDevice,
  VideoPriorityBasedPolicy,
  VideoSource,
} from 'amazon-chime-sdk-js';

import FromRoute from 'teamtailor/routes/video-meetings';
import BowserService from 'teamtailor/services/bowser';
import IntlService from 'ember-intl/services/intl';
import {
  AteendeeIdsToTilesMap,
  AttendeeIdPresenceHandler,
  DeviceFacingMode,
  MeetingAttendee,
  MeetingData,
  VideoConfig,
  getMediaConstraints,
  getVideoConfig,
  RemoteParticipantScreenShare,
  updateVideoPreferences,
  DefaultVideoFxConfig,
  BACKGROUNDS,
  getChosenBackground,
  Background,
  getImage,
} from 'teamtailor/utils/video-meetings/utils';
import Server from 'teamtailor/services/server';
import { registerDestructor } from '@ember/destroyable';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';

export type DeviceOption = {
  label?: string;
  kind: string;
  deviceId: string;
  index: number;
};

type TrackKind = 'audio' | 'video';

export enum RecordingStatus {
  START = 'start',
  STOP = 'stop',
}

export default class VideoMeetingsController extends Controller {
  queryParams = ['internal', { videoRecording: 'video_recording' }];

  @service declare intl: IntlService;
  @service declare bowser: BowserService;
  @service declare router: RouterService;
  @service declare session: SessionService;
  @service declare server: Server;
  @service declare pusher: PusherService;

  declare model: ModelFrom<FromRoute>;
  declare previousVideoEnabled?: boolean;
  declare previousVideoDevice?: DeviceOption;

  @tracked internal = false;
  @tracked isRecordingAddonEnabled = this.model.recordingAddonEnabled;

  @tracked videoRecording = false;
  @tracked error: TwilioError | Error | undefined;
  @tracked remoteParticipants: RemoteParticipant[] | MeetingAttendee[] = [];
  @tracked showConsentModal = false;
  @tracked isRecording = false;
  @tracked hasTriggeredRecording = false;
  @tracked hasTriggeredBlur = false;
  @tracked hasTriggeredBackground = false;
  @tracked backgroundSelected = 'None';

  @tracked declare room: Room | null;
  @tracked declare localParticipant?: LocalParticipant | MeetingAttendee;
  @tracked declare dominantParticipant?: RemoteParticipant | MeetingAttendee;
  @tracked declare previousDominantParticipant?:
    | RemoteParticipant
    | MeetingAttendee;

  @tracked declare devices?: DeviceOption[];
  @tracked declare videoTrack?: LocalVideoTrack | MediaStream;
  @tracked declare audioTrack?: LocalAudioTrack | MediaStream;

  // Chime
  @tracked declare meetingData?: MeetingData;
  @tracked declare deviceController?: DefaultDeviceController;
  @tracked declare logger: ConsoleLogger;
  @tracked declare meetingSession?: MeetingSession;
  @tracked tilesMap: AteendeeIdsToTilesMap = {};
  @tracked declare remoteParticipantScreenShare?: RemoteParticipantScreenShare;
  @tracked videoFxConfig: VideoFxConfig = DefaultVideoFxConfig;
  @tracked declare videoFxProcessor?: VideoFxProcessor;
  @tracked videoTransformDevice: DefaultVideoTransformDevice | null = null;
  @tracked declare innerDevice?: string;
  declare priorityBasedDownlinkPolicy: VideoPriorityBasedPolicy;
  @tracked isVideoFxProcessorSupported: boolean | null = null;
  @tracked lobby = false;
  declare pusherChannel: PusherChannel | null;
  @tracked lobbyAdmissionNotification = false;
  @tracked meetingOpen = false;
  @tracked joining = false;
  @tracked countdownValue = 5;
  @tracked isCandidateCardVisible = false;

  constructor(owner: undefined) {
    super(owner);

    registerDestructor(this, () => {
      this.teardownPusher();
    });
  }

  pusherChannelName() {
    return `presence-video-meeting-${this.model.id}`;
  }

  teardownPusher() {
    if (this.pusherChannel) {
      this.pusherChannel.unsubscribe();
      this.pusherChannel = null;
      this.meetingOpen = false;
    }
  }

  pusherSubscribe(name: string) {
    this.pusher.subscribe(this.pusherChannelName()).then((channel) => {
      this.pusherChannel = channel;
      if (this.isAuthenticatedUser) {
        this.setupLetCandidateIn();
        this.setupOpenMeeting();
      } else {
        this.setupJoinOpenMeeting(name);
        this.setupJoinClosedOngoingMeeting();
        this.setupJoinClosedEmptyMeeting();
      }
    });
  }

  setupLetCandidateIn() {
    this.pusherChannel?.bind('client-let-me-in', () => {
      this.admitFromLobby();
    });
  }

  setupOpenMeeting() {
    this.pusherChannel?.bind('client-open-meeting', () => {
      this.lobbyAdmissionNotification = false;
      this.meetingOpen = true;
    });
  }

  setupJoinOpenMeeting(name: string) {
    this.pusherChannel?.bind('client-open-meeting', () => {
      this.joining = true;
      this.enterMeeting(name);
    });
  }

  setupJoinClosedOngoingMeeting() {
    this.pusherChannel?.bind('pusher:subscription_succeeded', () => {
      this.pusherChannel?.trigger('client-let-me-in', {});
    });
  }

  setupJoinClosedEmptyMeeting() {
    this.pusherChannel?.bind('pusher:member_added', () => {
      this.pusherChannel?.trigger('client-let-me-in', {});
    });
  }

  admitFromLobby() {
    if (this.meetingOpen) {
      this.pusherChannel?.trigger('client-open-meeting', {});
    } else {
      this.lobbyAdmissionNotification = true;
    }
  }

  enterMeeting(name: string) {
    if (this.pusherChannel) {
      this.pusherChannel.unbind('client-open-meeting');
      this.pusherChannel.unbind('pusher:member_added');
      this.pusherChannel.unbind('pusher:subscription_succeeded');
      this.enterMeetingCountdown(name);
    }
  }

  @action
  letUserIn() {
    if (this.pusherChannel) {
      this.pusherChannel.trigger('client-open-meeting', {});
      this.meetingOpen = true;
      this.lobbyAdmissionNotification = false;
      this.joining = true;
      this.enterMeetingCountdown();
    }
  }

  enterMeetingCountdown(name: string | null = null): void {
    later(
      this,
      () => {
        if (this.countdownValue > 0) {
          this.countdownValue--;
          this.enterMeetingCountdown(name);
        } else {
          this.joining = false;
          this.countdownValue = 5;
          if (!this.isAuthenticatedUser) {
            this.connectChimeMeetingSession.perform(name);
          }
        }
      },
      1000
    );
  }

  get isAuthenticatedUser(): boolean {
    return !!this.model.userId;
  }

  get isSupported(): boolean {
    if (this.isNewProvider) {
      const defaultBrowserBehavior = new DefaultBrowserBehavior();
      return defaultBrowserBehavior.isSupported();
    } else {
      return isSupported;
    }
  }

  get controllerHandler() {
    return this.audioVideoFacade ?? this.deviceController;
  }

  get isDesktop(): boolean {
    return ['desktop', 'tv'].includes(this.bowser.platform.type || '');
  }

  get interviewLayout(): boolean {
    return this.router.currentRouteName.includes('video-meeting.candidate');
  }

  get videoCodecSettings(): VP8CodecSettings {
    return { codec: 'VP8', simulcast: true };
  }

  get connectOptions(): ConnectOptions {
    return {
      name: this.model.id,
      audio: true,
      dominantSpeaker: true,
      maxAudioBitrate: 16000,
      networkQuality: true,
      preferredVideoCodecs: [this.videoCodecSettings],
      bandwidthProfile: {
        video: {
          mode: 'presentation',
          dominantSpeakerPriority: 'standard',
        },
      },
    };
  }

  get videoConfig(): VideoConfig {
    return getVideoConfig(this.isDesktop);
  }

  get connected(): boolean {
    if (this.isNewProvider) {
      return (
        !isEmpty(this.meetingData) &&
        !isEmpty(this.audioVideoFacade) &&
        !isEmpty(this.localParticipant)
      );
    } else {
      return (
        !isEmpty(this.model.token) &&
        !isEmpty(this.room) &&
        !isEmpty(this.localParticipant)
      );
    }
  }

  get currentVideoInputDeviceId(): string | undefined {
    if (!this.videoTrack) {
      return undefined;
    }

    if (this.isNewProvider) {
      return (
        this.innerDevice ??
        (<MediaStream>this.videoTrack).getVideoTracks()[0]?.getSettings()
          .deviceId
      );
    } else {
      return (<LocalVideoTrack>this.videoTrack).mediaStreamTrack.getSettings()
        .deviceId;
    }
  }

  get currentAudioInputDeviceId(): string | undefined {
    if (!this.audioTrack) {
      return undefined;
    }

    if (this.isNewProvider) {
      return (<MediaStream>this.audioTrack).getAudioTracks()[0]?.getSettings()
        .deviceId;
    } else {
      return (
        (<LocalAudioTrack>this.audioTrack).mediaStreamTrack.getSettings()
          .deviceId || 'default'
      );
    }
  }

  get currentAudioInputDevice(): DeviceOption | undefined {
    if (!this.currentAudioInputDeviceId) {
      return undefined;
    }

    return this.audioInputDevices.findBy(
      'deviceId',
      this.currentAudioInputDeviceId
    );
  }

  get currentVideoInputDevice(): DeviceOption | undefined {
    if (!this.currentVideoInputDeviceId) {
      return undefined;
    }

    return this.videoInputDevices.findBy(
      'deviceId',
      this.currentVideoInputDeviceId
    );
  }

  get audioInputDevices(): DeviceOption[] | [] {
    if (!this.devices) {
      return [];
    }

    return this.devices.filter(
      (device) =>
        device.kind === 'audioinput' &&
        !device.label?.includes('ZoomAudioDevice')
    );
  }

  get videoInputDevices(): DeviceOption[] | [] {
    if (!this.devices) {
      return [];
    }

    return this.devices.filter(
      (device) =>
        device.kind === 'videoinput' &&
        !device.label?.includes('ZoomVideoDevice')
    );
  }

  get canJoin(): boolean {
    return !isEmpty(this.audioTrack);
  }

  get backgrounds(): Background[] {
    const backgrounds: Background[] = [];

    backgrounds.push({ name: 'None' });
    for (const [index, name] of BACKGROUNDS.entries()) {
      backgrounds.push({ name, imageUrl: getImage(index) });
    }

    backgrounds.push({ name: 'company_color', color: this.model.companyColor });
    return backgrounds;
  }

  handleError(error: TwilioError | Error): void {
    this.model.token = null;
    this.error = error;
  }

  playConnectedSoundTask = dropTask(async () => {
    await timeout(500);
    const audioElement = <HTMLMediaElement>(
      document.getElementById('meeting-join-audio')
    );
    audioElement.volume = 0.3;
    audioElement.play();
  });

  connect = dropTask(async (name: string | null) => {
    const { connect } = Video;

    if (!this.model.token) {
      const { token } = (await this.model.fetchToken({ name })) as {
        token: string | null;
      };
      this.model.token = token;
    }

    try {
      if (!this.model.token) {
        throw new Error('Token could not be fetched');
      }

      if (!this.audioTrack) {
        throw new Error('Missing audio track');
      }

      this.room = await connect(this.model.token, {
        tracks: [
          <LocalVideoTrack>this.videoTrack,
          <LocalAudioTrack>this.audioTrack,
        ].compact(),

        ...this.connectOptions,
      });

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (this.room) {
        this.localParticipant = this.room.localParticipant;
        this.room.participants.forEach((participant) => {
          this.participantConnected(participant, false);
        });
        this.room.on(
          'participantConnected',
          bind(this, this.participantConnected)
        );
        this.room.on(
          'participantDisconnected',
          bind(this, this.participantDisconnected)
        );
        this.room.on(
          'dominantSpeakerChanged',
          bind(this, this.dominantSpeakerChanged)
        );
        this.room.on('disconnected', bind(this, this.disconnected));

        if (this.internal) {
          window.parent.postMessage('video-has-joined', '*');
        }

        if (!this.isDesktop) {
          document.addEventListener(
            'visibilitychange',
            bind(this, this.handleVisibilityChange)
          );
        }

        this.room.on(
          'recordingStarted',
          bind(this, () => this.handleRecordingStatus(RecordingStatus.START))
        );
        this.room.on(
          'recordingStopped',
          bind(this, () => this.handleRecordingStatus(RecordingStatus.STOP))
        );

        if (this.room.isRecording) {
          this.showConsentModal = true;
          this.isRecording = true;
        }
      }
    } catch (error) {
      this.handleError(error as TwilioError);
    }
  });

  participantConnected = (
    participant: RemoteParticipant | MeetingAttendee,
    audio = true
  ) => {
    if (this.isNewProvider) {
      (<MeetingAttendee[]>this.remoteParticipants).pushObject(
        <MeetingAttendee>participant
      );
    } else {
      (<RemoteParticipant[]>this.remoteParticipants).pushObject(
        <RemoteParticipant>participant
      );
    }

    if (audio) {
      this.playConnectedSoundTask.perform();
    }
  };

  participantDisconnected = (
    participant: RemoteParticipant | MeetingAttendee
  ) => {
    if (this.isNewProvider) {
      (<MeetingAttendee[]>this.remoteParticipants).removeObject(
        <MeetingAttendee>participant
      );
    } else {
      (<RemoteParticipant[]>this.remoteParticipants).removeObject(
        <RemoteParticipant>participant
      );
    }
  };

  dominantSpeakerChanged = (participant?: RemoteParticipant) => {
    this.dominantParticipant = participant;
    if (this.dominantParticipant) {
      this.previousDominantParticipant = this.dominantParticipant;
    }
  };

  disconnect = (): void => {
    if (this.room) {
      this.room.disconnect();
      this.removeVisibilityEventListener();
    } else if (this.isNewProvider) {
      this.disconnectChimeMeetingSession();
    }
  };

  disconnected = (_room: Room, error?: TwilioError): void => {
    if (error) {
      Sentry.setContext('user', {
        name: (<LocalParticipant>this.localParticipant).identity,
        room: this.model.id,
      });
      Sentry.captureException(error);
      this.handleError(error);
    }

    this.model.token = null;
    this.remoteParticipants = [];
    this.resetTracks();

    if (this.internal) {
      window.parent.postMessage('video-has-ended', '*');
    }

    if (this.hasTriggeredRecording && this.isRecording) {
      this.handleRecordingStatus(RecordingStatus.STOP);
    }

    this.removeVisibilityEventListener();
  };

  handleVisibilityChange = async () => {
    if (this.isNewProvider) {
      if (document.visibilityState === 'hidden') {
        this.audioVideoFacade?.stopLocalVideoTile();
        await this.audioVideoFacade?.stopVideoInput();
        this.previousVideoDevice = this.currentVideoInputDevice;
      } else {
        if (this.previousVideoDevice) {
          await this.audioVideoFacade?.startVideoInput(
            this.previousVideoDevice.deviceId
          );
          this.audioVideoFacade?.startLocalVideoTile();
          this.previousVideoDevice = undefined;
        }
      }
    } else {
      if (document.visibilityState === 'hidden') {
        if (this.videoTrack && this.localParticipant) {
          this.previousVideoEnabled = (<LocalVideoTrack>(
            this.videoTrack
          )).isEnabled;
          this.previousVideoDevice = this.currentVideoInputDevice;
          (<LocalVideoTrack>this.videoTrack).stop();
          (<LocalParticipant>this.localParticipant).unpublishTrack(
            <LocalVideoTrack>this.videoTrack
          );
        }
      } else {
        if (this.previousVideoEnabled && this.localParticipant) {
          this.videoTrack = await this.replaceVideoTrack(
            this.previousVideoDevice?.deviceId
          );

          await (<LocalParticipant>this.localParticipant).publishTrack(
            this.videoTrack
          );
        }

        this.previousVideoEnabled = undefined;
        this.previousVideoDevice = undefined;
      }
    }
  };

  removeVisibilityEventListener = () => {
    if (!this.isDesktop) {
      document.removeEventListener(
        'visibilitychange',
        this.handleVisibilityChange
      );
    }
  };

  resetTracks(): void {
    if (!this.localParticipant) {
      return;
    }

    if (!this.isNewProvider) {
      (<LocalParticipant>this.localParticipant).tracks.forEach(
        (publication: LocalTrackPublication) => {
          publication.unpublish();
          const { track } = publication;

          if ('stop' in track) {
            track.stop();
          }

          if ('detach' in track) {
            track.detach().forEach((element: HTMLElement) => element.remove());
          }
        }
      );
    }

    this.videoTrack = undefined;
    this.audioTrack = undefined;
  }

  createVideoTrack = async (
    deviceId?: string
  ): Promise<LocalVideoTrack | MediaStream | undefined> => {
    const newTrackOptions = <LocalTrackOptions>{
      deviceId,
      ...this.videoConfig,
    };
    try {
      if (this.isNewProvider) {
        if (this.videoInputDevices[0]?.deviceId && this.deviceController) {
          this.deviceController.chooseVideoInputQuality(
            this.videoConfig.width,
            this.videoConfig.height,
            this.videoConfig.frameRate
          );
          return await this.deviceController.startVideoInput(
            this.videoTransformDevice ?? this.videoInputDevices[0].deviceId
          );
        }
      } else {
        return <LocalVideoTrack>(
          await this.createLocalTrack(createLocalVideoTrack, newTrackOptions)
        );
      }
    } catch (_error) {
      return undefined;
    }
  };

  createAudioTrack = async (
    deviceId?: string
  ): Promise<LocalAudioTrack | MediaStream | undefined> => {
    const newTrackOptions = <LocalTrackOptions>{
      deviceId,
    };
    try {
      if (this.isNewProvider) {
        if (this.audioInputDevices[0]?.deviceId && this.deviceController) {
          return await this.deviceController.startAudioInput(
            this.audioInputDevices[0].deviceId
          );
        }
      } else {
        return <LocalAudioTrack>(
          await this.createLocalTrack(createLocalAudioTrack, newTrackOptions)
        );
      }
    } catch (_error) {
      return undefined;
    }
  };

  createLocalTrack = (
    createTrackFunction:
      | typeof createLocalVideoTrack
      | typeof createLocalAudioTrack,
    trackOptions: LocalTrackOptions
  ): Promise<LocalVideoTrack | LocalAudioTrack> => {
    return createTrackFunction(trackOptions);
  };

  replaceAudioTrack = (deviceId?: string): Promise<LocalAudioTrack> => {
    return this.replaceLocalTrack(
      this.createAudioTrack,
      <LocalAudioTrack>this.audioTrack,
      deviceId
    ) as Promise<LocalAudioTrack>;
  };

  replaceVideoTrack = (deviceId?: string): Promise<LocalVideoTrack> => {
    return this.replaceLocalTrack(
      this.createVideoTrack,
      <LocalVideoTrack>this.videoTrack,
      deviceId
    ) as Promise<LocalVideoTrack>;
  };

  replaceLocalTrack = async (
    createTrackFunction: this['createAudioTrack'] | this['createVideoTrack'],
    currentTrack?: LocalVideoTrack | LocalAudioTrack,
    deviceId?: string
  ): Promise<LocalVideoTrack | LocalAudioTrack | undefined> => {
    let shouldDisableTrack = false;

    if (currentTrack) {
      if (this.localParticipant) {
        this.unpublishTracks(currentTrack.kind);
      }

      shouldDisableTrack = !currentTrack.isEnabled;

      currentTrack.detach().forEach((element: HTMLElement) => {
        element.remove();
      });
    }

    const newTrack = <LocalVideoTrack | LocalAudioTrack | undefined>(
      await createTrackFunction(deviceId)
    );

    if (newTrack && shouldDisableTrack) {
      newTrack.disable();
    }

    if (this.localParticipant && this.connected && newTrack) {
      (<LocalParticipant>this.localParticipant).publishTrack(newTrack);
    }

    return newTrack;
  };

  unpublishTracks = (trackType: TrackKind): void => {
    (<LocalParticipant>this.localParticipant).tracks.forEach(
      (publication: LocalTrackPublication) => {
        if (publication.kind === trackType) {
          publication.unpublish();
        }
      }
    );
  };

  setDevices = (devices: MediaDeviceInfo[]): DeviceOption[] => {
    const deviceOptionLabel = (
      deviceId: string,
      kind: string,
      index: number,
      label?: string
    ): string => {
      if (
        deviceId === 'default' &&
        !label?.toLowerCase()?.includes('default')
      ) {
        return this.intl.t('components.video_meeting.device.default', {
          label,
        });
      } else if (label) {
        return label;
      } else {
        return this.intl.t(`components.video_meeting.device.${kind}`, {
          index,
        });
      }
    };

    this.devices = devices.map(({ label, deviceId, kind }, index) => ({
      label: deviceOptionLabel(deviceId, kind, index, label),
      deviceId,
      kind,
      index: index + 1,
    }));

    return this.devices;
  };

  getDevices = async (): Promise<void> => {
    if (this.isNewProvider) {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: this.videoConfig.width },
            height: { ideal: this.videoConfig.height },
          },
        });
      } catch (_error) {
        undefined;
      }

      if (!this.deviceController) {
        this.setDeviceController();
      }
    }

    const devices = await navigator.mediaDevices.enumerateDevices();
    await this.setDevices(devices);

    if (this.videoInputDevices.length > 0) {
      if (this.isNewProvider || !this.videoTrack) {
        this.videoTrack = await this.createVideoTrack();
      }
    }

    if (this.audioInputDevices.length > 0) {
      if (this.isNewProvider || !this.audioTrack) {
        this.audioTrack = await this.createAudioTrack();
      }
    }
  };

  @action
  handleClearError() {
    this.error = undefined;

    if (this.isNewProvider) {
      this.router.refresh('video-meetings');
    }
  }

  @action
  async initializeJoin(): Promise<void> {
    navigator.mediaDevices.addEventListener('devicechange', this.getDevices);
    await this.getDevices();
    await this.initializeVideoProcess();
  }

  @action
  async initializeVideoProcess(): Promise<void> {
    if (this.isNewProvider) {
      this.isVideoFxProcessorSupported = await VideoFxProcessor.isSupported(
        this.logger
      );
      if (this.isVideoFxProcessorSupported) {
        await this.createVideoProcessor();
      }
    }
  }

  @action
  handleJoin(name: string, evt: SubmitEvent): void {
    if (this.isNewProvider) {
      this.isCandidateCardVisible = this.interviewLayout;
      this.pusherSubscribe(name);
      if (this.isAuthenticatedUser || this.meetingOpen) {
        this.connectChimeMeetingSession.perform(name);
      } else {
        evt.preventDefault();
        this.lobby = true;
      }
    } else {
      this.connect.perform(name);
    }
  }

  @action
  async handleChangeVideoInputDevice(
    deviceOption?: DeviceOption,
    facingMode?: DeviceFacingMode
  ): Promise<void> {
    if (this.isNewProvider) {
      this.innerDevice = deviceOption?.deviceId; // To keep track of the inner device used for the unblur
      const mediaConstraints = getMediaConstraints(
        this.isDesktop,
        deviceOption?.deviceId,
        facingMode
      );
      if (this.videoTransformDevice) {
        this.videoTransformDevice =
          this.videoTransformDevice.chooseNewInnerDevice(mediaConstraints);
      }

      const device = this.videoTransformDevice ?? mediaConstraints;
      if (this.controllerHandler) {
        this.videoTrack = await this.controllerHandler.startVideoInput(device);
      }
    } else {
      this.videoTrack = await this.replaceVideoTrack(deviceOption?.deviceId);
    }
  }

  @action
  async handleChangeAudioInputDevice(
    deviceOption?: DeviceOption
  ): Promise<void> {
    if (this.isNewProvider && deviceOption) {
      if (this.controllerHandler) {
        this.audioTrack = await this.controllerHandler.startAudioInput(
          deviceOption.deviceId
        );
      }
    } else {
      this.audioTrack = await this.replaceAudioTrack(deviceOption?.deviceId);
    }
  }

  @action
  handleRecordingStatus(status: RecordingStatus): void {
    this.showConsentModal =
      status === RecordingStatus.START && !this.hasTriggeredRecording;

    this.isRecording = status === RecordingStatus.START;

    if (status === RecordingStatus.STOP) {
      this.hasTriggeredRecording = false;
    }
  }

  setDeviceController() {
    this.logger = new ConsoleLogger('MyLogger', LogLevel.OFF);
    this.deviceController = new DefaultDeviceController(this.logger);
  }

  connectChimeMeetingSession = dropTask(async (name: string | null) => {
    try {
      if (!this.audioTrack) {
        throw new Error('Missing audio track');
      }

      if (!this.deviceController) {
        throw new Error('Missing device controller');
      }

      try {
        const { meeting, attendee } = (await this.model.fetchMeetingData({
          name,
        })) as MeetingData;
        this.meetingData = { meeting, attendee };
        this.localParticipant = {
          attendeeId: attendee.Attendee.AttendeeId,
          externalUserId: attendee.Attendee.ExternalUserId,
          name: name ?? this.intl.t('components.video_meeting.guest'),
        };
      } catch (error) {
        throw new Error('There was an error initializing the meeting');
      }

      const configuration = new MeetingSessionConfiguration(
        this.meetingData.meeting,
        this.meetingData.attendee
      );

      this.priorityBasedDownlinkPolicy = new VideoPriorityBasedPolicy(
        this.logger
      );
      configuration.videoDownlinkBandwidthPolicy =
        this.priorityBasedDownlinkPolicy;

      const defaultBrowserBehavior = new DefaultBrowserBehavior();
      if (defaultBrowserBehavior.isSimulcastSupported()) {
        configuration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers = true;
      }

      this.meetingSession = new DefaultMeetingSession(
        configuration,
        this.logger,
        this.deviceController
      );

      // eslint-disable-next-line ember/no-observers
      this.meetingSession.audioVideo.addObserver(this.getChimeObserver());
      this.setupSubscribeToAttendeeIdPresenceHandler();
      this.setupSubscribeToActiveSpeakerDetector();

      this.meetingSession.audioVideo.start();

      if (this.internal) {
        window.parent.postMessage('video-has-joined', '*');
      }

      if (!this.isDesktop) {
        document.addEventListener(
          'visibilitychange',
          bind(this, this.handleVisibilityChange)
        );
      }
    } catch (error) {
      this.error = error as Error;
    }
  });

  disconnectChimeMeetingSession() {
    this.handleLeavingChimeMeeting();

    this.teardownPusher();

    if (this.internal) {
      window.parent.postMessage('video-has-ended', '*');
      this.meetingOpen = false;
    } else {
      this.lobby = false;
    }

    if (this.hasTriggeredRecording && this.isRecording) {
      this.handleRecordingStatus(RecordingStatus.STOP);

      this.model.setRecordingStatus(this.model.id, {
        action_name: RecordingStatus.STOP,
        sid: this.meetingData?.meeting.Meeting.MeetingId,
        meeting_arn: this.meetingData?.meeting.Meeting.MeetingArn,
      });
    }

    this.resetMeetingParams();
    this.removeVisibilityEventListener();
  }

  getChimeObserver(): AudioVideoObserver {
    return {
      videoTileDidUpdate: (tileState: VideoTileState) => {
        if (!tileState.boundAttendeeId || !tileState.tileId) {
          return;
        }

        if (tileState.isContent) {
          const baseAttendeeId = new DefaultModality(
            tileState.boundAttendeeId
          ).base();
          if (
            baseAttendeeId !==
            (<MeetingAttendee>this.localParticipant).attendeeId
          ) {
            this.remoteParticipantScreenShare = {
              attendeeId: tileState.boundAttendeeId.replace('#', '-'),
              baseAttendeeId,
              tileId: tileState.tileId,
            };

            this.isCandidateCardVisible = false;
            return;
          }
        }

        this.tilesMap = {
          ...this.tilesMap,
          [tileState.boundAttendeeId]: tileState.tileId,
        };
      },

      videoTileWasRemoved: (tileId) => {
        if (this.remoteParticipantScreenShare?.tileId === tileId) {
          this.remoteParticipantScreenShare = undefined;
          this.isCandidateCardVisible = true;
        }
      },

      remoteVideoSourcesDidChange: (videoSources: VideoSource[]) => {
        updateVideoPreferences(this.priorityBasedDownlinkPolicy, videoSources);
      },

      audioVideoDidStop: (sessionStatus: MeetingSessionStatus) => {
        if (sessionStatus.isFailure()) {
          Sentry.setContext('user', {
            name: (<MeetingAttendee>this.localParticipant).externalUserId,
            meetingId: this.model.id,
          });
          let errorMessage =
            'There was an error that caused the meeting to stop. Please try refreshing the page.';
          const userMeetingError = new Error(errorMessage);

          if (sessionStatus.toString) {
            errorMessage = sessionStatus.toString();
          }

          const sentryMeetingError = new Error(errorMessage);
          Sentry.captureException(sentryMeetingError);
          this.handleError(userMeetingError);
        }

        this.disconnectChimeMeetingSession();
      },
    };
  }

  get audioVideoFacade() {
    return this.meetingSession?.audioVideo;
  }

  get isNewProvider() {
    return this.model.newProvider;
  }

  setupSubscribeToAttendeeIdPresenceHandler(): void {
    this.audioVideoFacade?.realtimeSubscribeToAttendeeIdPresence(
      this.attendeeIdPresenceHandler
    );
  }

  setupSubscribeToActiveSpeakerDetector(): void {
    this.audioVideoFacade?.subscribeToActiveSpeakerDetector(
      new DefaultActiveSpeakerPolicy(),
      this.activeSpeakerHandler
    );
  }

  tearDownSubscribeToAttendeeIdPresenceHandler(): void {
    this.audioVideoFacade?.realtimeUnsubscribeToAttendeeIdPresence(
      this.attendeeIdPresenceHandler
    );
  }

  tearDownSubscribeToActiveSpeakerDetector(): void {
    this.audioVideoFacade?.unsubscribeFromActiveSpeakerDetector(
      this.activeSpeakerHandler
    );
  }

  handleLeavingChimeMeeting(): void {
    this.audioVideoFacade?.stopLocalVideoTile();
    this.tearDownSubscribeToAttendeeIdPresenceHandler();
    this.tearDownSubscribeToActiveSpeakerDetector();
    if (this.remoteParticipants.length === 0) {
      this.model.deleteMeeting({
        meeting_id: this.meetingData?.meeting.Meeting.MeetingId,
      });
    }
  }

  resetMeetingParams(): void {
    this.resetTracks();
    this.meetingData = undefined;
    this.meetingSession = undefined;
    this.localParticipant = undefined;
    this.remoteParticipants = [];
    this.tilesMap = {};
    this.remoteParticipantScreenShare = undefined;
  }

  get attendeeIdPresenceHandler(): AttendeeIdPresenceHandler {
    return (
      attendeeId: string,
      present: boolean,
      externalUserId?: string,
      _dropped?: boolean,
      _posInFrame?: RealtimeAttendeePositionInFrame | null
    ) => {
      const isAttendeeSharingScreen = new DefaultModality(
        attendeeId
      ).hasModality(DefaultModality.MODALITY_CONTENT);

      if (
        (<MeetingAttendee>this.localParticipant).attendeeId === attendeeId ||
        isAttendeeSharingScreen
      ) {
        return;
      }

      const meetingAttendee = {
        attendeeId,
        externalUserId: externalUserId ?? '',
      };

      const isMediaRecordingAttendee = externalUserId
        ? externalUserId.includes('aws:MediaPipeline')
        : false;

      if (present) {
        if (isMediaRecordingAttendee) {
          this.handleRecordingStatus(RecordingStatus.START);
        } else {
          this.participantConnected(meetingAttendee);
        }
      } else {
        if (isMediaRecordingAttendee) {
          this.handleRecordingStatus(RecordingStatus.STOP);
        } else {
          this.audioVideoFacade?.realtimeUnsubscribeFromVolumeIndicator(
            attendeeId
          );
          const attendeeToRemove = this.remoteParticipants.findBy(
            'attendeeId',
            attendeeId
          );

          if (attendeeToRemove) {
            this.participantDisconnected(attendeeToRemove);
          }
        }
      }
    };
  }

  activeSpeakerHandler = (attendeeIds: string[]): void => {
    const dominantSpeakerId = attendeeIds[0];

    const dominantparticipant = this.remoteParticipants.findBy(
      'attendeeId',
      dominantSpeakerId
    );

    this.dominantParticipant = dominantparticipant;
    if (this.dominantParticipant) {
      this.previousDominantParticipant = dominantparticipant;
    }
  };

  async createVideoProcessor(): Promise<void> {
    this.videoFxProcessor = await VideoFxProcessor.create(
      this.logger,
      this.videoFxConfig
    );
  }

  @action
  createDefaultVideoTransformDevice(): void {
    if (this.currentVideoInputDeviceId && this.videoFxProcessor) {
      if (this.innerDevice === undefined) {
        this.innerDevice = this.currentVideoInputDeviceId;
      }

      this.videoTransformDevice = new DefaultVideoTransformDevice(
        this.logger,
        this.innerDevice,
        [this.videoFxProcessor]
      );
    }
  }

  @action
  async handleBlurBackground(): Promise<void> {
    if (!this.hasTriggeredBlur) {
      this.videoFxConfig.backgroundBlur.isEnabled = true;
      this.disableBackground();
      await this.createVideoProcessor();
      this.createDefaultVideoTransformDevice();
    } else {
      this.videoTransformDevice = null;
    }

    await this.startVideoTransformDevice();

    this.hasTriggeredBlur = !this.hasTriggeredBlur;
  }

  @action
  async handleVirtualBackground(backgroundName: string): Promise<void> {
    if (backgroundName === 'None') {
      this.disableBackground();
    } else {
      if (!this.hasTriggeredBackground) {
        this.enableBackground();
        await this.setBackground(backgroundName);
      } else {
        if (this.backgroundSelected !== backgroundName) {
          await this.setBackground(backgroundName);
        } else {
          this.disableBackground();
        }
      }
    }

    await this.startVideoTransformDevice();
  }

  @action
  enableBackground(): void {
    this.videoFxConfig.backgroundBlur.isEnabled = false;
    this.videoFxConfig.backgroundReplacement.isEnabled = true;
    this.hasTriggeredBlur = false;
    this.hasTriggeredBackground = true;
  }

  @action
  disableBackground(): void {
    this.videoFxConfig.backgroundReplacement.isEnabled = false;
    this.backgroundSelected = 'None';
    this.videoTransformDevice = null;
    this.hasTriggeredBackground = false;
    this.hasTriggeredBlur = false;
  }

  @action
  async setBackground(backgroundName: string): Promise<void> {
    this.videoFxConfig = getChosenBackground(
      backgroundName,
      this.videoFxConfig,
      this.model.companyColor
    );
    // Create video processor eveytime we change the background
    await this.createVideoProcessor();
    this.createDefaultVideoTransformDevice();
    this.backgroundSelected = backgroundName;
  }

  @action
  async startVideoTransformDevice(): Promise<void> {
    const device = this.videoTransformDevice ?? this.innerDevice;

    if (device && this.controllerHandler) {
      if (this.videoTransformDevice) {
        this.videoTrack = await this.controllerHandler.startVideoInput(device);
      } else {
        this.videoTrack = await this.controllerHandler.startVideoInput(device);
      }
    }
  }

  @action
  toggleCandidateCard(): void {
    if (this.interviewLayout) {
      this.isCandidateCardVisible = !this.isCandidateCardVisible;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'video-meetings': VideoMeetingsController;
  }
}
