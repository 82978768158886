import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { buildReport as buildAudienceVisitorsReport } from 'teamtailor/classes/analytics/report-audience-visitors';
import { getOwner } from '@ember/application';

export default class VisitorsRoute extends Route {
  @service analytics;

  model() {
    const container = getOwner(this);
    return {
      visitors: buildAudienceVisitorsReport({ container }).buildTask,
    };
  }
}
