import Model, { attr } from '@ember-data/model';

export default class CopilotSettingModel extends Model {
  @attr('boolean') declare enabled: boolean;
  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'copilot-setting': CopilotSettingModel;
  }
}
