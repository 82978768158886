import Service from '@ember/service';

export default class FormStateService extends Service {
  map = {};

  readState(key) {
    return this.map[key];
  }

  saveState(key, value) {
    this.map[key] = value;
  }

  clearState(key) {
    delete this.map[key];
  }
}
