import Controller from '@ember/controller';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line ember/no-mixins
import ContentBlockLayoutControllerMixin from 'teamtailor/mixins/content-block/layout-controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class LayoutController extends Controller.extend(
  ContentBlockLayoutControllerMixin
) {
  @service iframePreview;
  @service current;

  @action
  reloadSection() {
    return this.iframePreview.reloadSection(this.model.id);
  }
}
