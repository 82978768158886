import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label\n  class={{class-names\n    \"body-text-s-medium m-0 flex-1 rounded-6 px-8 py-2 text-center leading-[18px]\"\n    (if\n      (eq @selectedValue @value)\n      (concat this.textColor \" \" this.bgColor)\n      \"text-neutral-medium hover:bg-neutral-hover\"\n    )\n  }}\n  ...attributes\n>\n  {{@text}}\n  <input\n    type=\"radio\"\n    class=\"sr-only\"\n    name={{@name}}\n    value={{@value}}\n    checked={{eq @selectedValue @value}}\n    {{on \"change\" this.onChange}}\n  />\n</label>", {"contents":"<label\n  class={{class-names\n    \"body-text-s-medium m-0 flex-1 rounded-6 px-8 py-2 text-center leading-[18px]\"\n    (if\n      (eq @selectedValue @value)\n      (concat this.textColor \" \" this.bgColor)\n      \"text-neutral-medium hover:bg-neutral-hover\"\n    )\n  }}\n  ...attributes\n>\n  {{@text}}\n  <input\n    type=\"radio\"\n    class=\"sr-only\"\n    name={{@name}}\n    value={{@value}}\n    checked={{eq @selectedValue @value}}\n    {{on \"change\" this.onChange}}\n  />\n</label>","moduleName":"teamtailor/components/core/toggle-switch/button.hbs","parseOptions":{"srcName":"teamtailor/components/core/toggle-switch/button.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { bgColor, textColor } from 'teamtailor/utils/theme-color';

export interface Args {
  text: string;
  selected: boolean;
  value?: string;
  onChange?: (value: unknown) => void;
  color?: string;
  colorVariant?: string;
}

export default class CoreToggleSwitchButtonComponent extends Component<Args> {
  get bgColor(): string {
    return bgColor(this.args.color ?? 'action', this.colorVariant);
  }

  get textColor(): string {
    return textColor(this.args.color ?? 'action', this.colorVariant);
  }

  get colorVariant(): string {
    return this.args.colorVariant || 'strong';
  }

  @action
  onChange(event: Event) {
    if (event.target instanceof HTMLInputElement && event.target.checked) {
      this.args.onChange?.(this.args.value);
    }
  }
}
