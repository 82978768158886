import MeetingEditRoute from 'teamtailor/routes/meetings/edit';

export default class JobsJobActivityCandidateMeetingEditRoute extends MeetingEditRoute {
  controllerName = 'meetings.edit';
  templateName = 'meetings.edit';

  meetingRouteRouteName =
    'nurture-campaigns.nurture-campaign.recipients.candidate.meetings';

  candidateRouteRouteName =
    'nurture-campaigns.nurture-campaign.recipients.candidate';

  jobRouteRouteName = '';
}
