export default {
  "header": "Sidebar__header_1kr11z",
  "headerTitle": "Sidebar__headerTitle_1kr11z",
  "options": "Sidebar__options_1kr11z",
  "filters": "Sidebar__filters_1kr11z",
  "isVisible": "Sidebar__isVisible_1kr11z",
  "filterList": "Sidebar__filterList_1kr11z",
  "filterBtn": "Sidebar__filterBtn_1kr11z",
  "search": "Sidebar__search_1kr11z",
  "searchLabel": "Sidebar__searchLabel_1kr11z",
  "searchInput": "Sidebar__searchInput_1kr11z",
  "active": "Sidebar__active_1kr11z",
  "searchFilterDropdown": "Sidebar__searchFilterDropdown_1kr11z",
  "container": "Sidebar__container_1kr11z",
  "containerScroll": "Sidebar__containerScroll_1kr11z",
  "badge": "Sidebar__badge_1kr11z",
  "loadingWrapper": "Sidebar__loadingWrapper_1kr11z",
  "footer": "Sidebar__footer_1kr11z"
};
